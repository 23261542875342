import React, { useCallback } from "react";
import { Control, Controller } from "react-hook-form";
import { BaseInput } from "src/components";
import { ProviderContactFormType } from "src/pages/provider/provider-types";

type Props = {
  control: Control<ProviderContactFormType>;
  errors?: any;
};

const BillingContactForm = ({ control, errors }: Props) => {
  const errorText = useCallback(
    (errorsData) => {
      if (errorsData.billingContact) {
        let error = errorsData.billingContact;

        if (error?.name) {
          return <p className="validation-text">{"필수입력 항목입니다"}</p>;
        }
        if (error?.contact) {
          return <p className="validation-text">{error?.contact?.message}</p>;
        }
        if (error?.email) {
          return <p className="validation-text">{error?.email?.message}</p>;
        }
      }
    },
    [errors],
  );

  return (
    <div>
      <article className="contents-container__divide-top">
        <div className="contents-container__sub-title mb20">
          <h2>세금 계산서 발행</h2>
        </div>
        <section className="contents-container__1200">
          <table className="inner-table" width="100%">
            <thead>
              <tr>
                <th>
                  <span>No</span>
                </th>
                <th>
                  <span>이름</span>
                </th>
                <th>
                  <span>연락처</span>
                </th>
                <th>
                  <span className="required">이메일</span>
                </th>
                <th>
                  <span>비고</span>
                </th>
                <th>
                  <span></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="100">
                  <div className="text-center">
                    <span>{1}</span>
                  </div>
                </td>
                <td width="100">
                  <Controller
                    control={control}
                    name={`billingContact.name`}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return <BaseInput onChange={onChange} value={value} name={name} />;
                    }}
                  ></Controller>{" "}
                </td>
                <td width="150">
                  <div className="text-center">
                    <Controller
                      control={control}
                      name={`billingContact.contact`}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return <BaseInput onChange={onChange} value={value} name={name} />;
                      }}
                    ></Controller>
                  </div>
                </td>
                <td width="200">
                  <div className="text-center minmax200">
                    <Controller
                      control={control}
                      name={`billingContact.email`}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return <BaseInput onChange={onChange} value={value} name={name} />;
                      }}
                    ></Controller>
                  </div>
                </td>
                <td width="auto">
                  <div className="text-center ">
                    <Controller
                      control={control}
                      name={`billingContact.description`}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return <BaseInput onChange={onChange} value={value} name={name} />;
                      }}
                    ></Controller>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {errorText(errors)}
        </section>
      </article>
    </div>
  );
};

export default BillingContactForm;
