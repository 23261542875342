import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { IPartnerMember } from "src/types/partner-members";
import { ApiPath } from "..";
import { ApiResponse } from "../public-types";
import { IGetPartnerMemberListParams, PaPartnerMemberPrivileges } from "./types";

// CPA11. 파트너 회원 목록 페이지 조회
export const getPartnerMemberList = async (
  axios: AxiosInstance,
  params?: IGetPartnerMemberListParams,
): Promise<AxiosResponse<ApiResponse<{ content: IPartnerMember[] }>>> => {
  const _params: any = { ...params };

  delete _params.orders;

  params?.orders?.forEach((order, index) => {
    _params[`sort.orders[${index}].property`] = order.property;
    _params[`sort.orders[${index}].direction`] = order.direction;
  });

  return await axios.get(ApiPath.partnerMembers, {
    params: _params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

// CPA12. 파트너 회원 조회
export const getPartnerMember = async (
  axios: AxiosInstance,
  params?: {
    id: string;
  },
): Promise<AxiosResponse<ApiResponse<{ member: IPartnerMember }>>> => {
  if (!params?.id) throw new Error("id is required. (params.id)");

  return await axios.get(`${ApiPath.partnerMembers}/${params.id}`, {
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

// CPA13. 파트너 회원 소속 파트너 목록 수정 처리
export const putPartnerMember = async (
  axios: AxiosInstance,
  params?: {
    id: string;
    partnerIds: string[];
  },
): Promise<AxiosResponse<ApiResponse<{ member: IPartnerMember }>>> => {
  if (!params?.id) throw new Error("id is required. (params.id)");

  return await axios.patch(`${ApiPath.partnerMembers}/${params.id}/partners`, {
    partnerIds: params.partnerIds,
  });
};

//CPA14. 파트너 회원 권한 목록 조회
export const getPartnerPrivileges = async (
  axios: AxiosInstance,
  params?: {
    id: string;
  },
): Promise<AxiosResponse<ApiResponse<{ privileges: PaPartnerMemberPrivileges }>>> => {
  if (!params?.id) throw new Error("id is required. (params.id)");

  return await axios.get(`${ApiPath.partnerMembersPrivileges.replace("{memberId}", params.id)}`, {
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

// CPA15. 파트너 회원 소속 파트너 추가 처리
export const postPartnerMemberAsync = async (
  axios: AxiosInstance,
  params?: {
    memberId: string;
    partnerIds: string[];
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  if (!params?.memberId) throw new Error("memberId is required. (params.memberId)");

  return await axios.post(`${ApiPath.partnerMembers}/${params.memberId}/partners`, {
    partnerIds: params.partnerIds || [],
  });
};

// CPA15. 파트너 회원 소속 파트너 삭제 처리
export const deletePartnerMemberAsync = async (
  axios: AxiosInstance,
  params?: {
    memberId: string;
    partnerId: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  if (!params?.memberId) throw new Error("memberId is required. (params.memberId)");

  return await axios.delete(
    `${ApiPath.partnerMembers}/${params.memberId}/partners/${params.partnerId}`,
  );
};

// CPA17. 파트너 회원 소속 파트너 수정 처리
export const putPartnerMemberAsync = async (
  axios: AxiosInstance,
  params?: {
    id: string;
    partnerIds: string[];
    phoneNumber: string | null;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  if (!params?.id) throw new Error("id is required. (params.memberId)");

  return await axios.put(`${ApiPath.partnerMembers}/${params.id}`, {
    partnerIds: params.partnerIds || [],
    phoneNumber: params.phoneNumber,
  });
};
