import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getPartner, patchPartnerESignLink, patchPartnerStatus } from "src/api/partner";
import { BaseButton, BaseModal, BaseToggle, ContentsTitle, LoadingBar } from "src/components";
import { useTitleOperation } from "src/pages/hooks";
import { Partner } from "src/types/partner";
import {
  getPartnerEditPage,
  getPartnerListPage,
  getPartnerPageBreadCrumb,
  sortFeaturesByName,
} from "../utils";
import PartnerEsign from "./PartnerEsign";
import PartnerFeature from "./PartnerFeature";
import PartnerAuth from "./PartnerAuth";
import PartnerParking from "./PartnerParking";
import { Modal } from "src/api/public-types";
import PartnerLog from "./PartnerLog";
const tabs = [
  { value: "basicInfo", label: "기본정보", disabled: false },
  { value: "feature", label: "feature", disabled: false },
  { value: "authorities", label: "파트너 회원 권한", disabled: false },
  { value: "esign", label: "전자서명", disabled: false },
  { value: "parking", label: "주차 연동", disabled: false },
  { value: "log", label: "로그", disabled: false },
];
const STATUS_MAP = {
  STANDBY: {
    label: "대기",
    color: "gray",
    next: "ACTIVE",
  },
  ACTIVE: {
    label: "활성",
    color: "green",
    next: "SHUTDOWN",
  },
  SHUTDOWN: {
    label: "중지",
    color: "red",
    next: undefined,
  },
};
interface BaseData {
  [key: string]: any;
}

function PartnerDetail() {
  const { executeAsync: getPartnerAsync, loading: getPartnerLoading } = useApiOperation(getPartner);
  const { executeAsync: patchPartnerStatusAsync, loading: patchPartnerStatusLoading } =
    useApiOperation(patchPartnerStatus);
  const { executeAsync: patchPartnerESignLinkAsync, loading: patchPartnerESignLinkLoading } =
    useApiOperation(patchPartnerESignLink);
  const [partner, setPartner] = useState<Partner>();
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });
  const [modal, setModal] = useState({
    success: {
      visible: false,
      message: "",
    },
    fail: {
      visible: false,
      message: "",
    },
  });

  const navigate = useNavigate();
  const location = useLocation();

  const urlParmas = useParams();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string; disabled: boolean }) => {
      // if (!tab.disabled) {
      const newQueryParams = { ...queryParams };
      // if (tab.value === "parking") {
      //   const availableParking = partner?.features?.find(
      //     (features) => features.name === "FEATURE_PARKING_KPS",
      //   )?.active;
      //   if (!availableParking) {
      //     setAlertModal({ isOpen: true, message: "FEATURE_PARKING_KPS feature가 비활성입니다" });
      //     return;
      //   }
      // }
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
      // }
    },
    [navigate, location, queryParams, partner],
  );

  const handleContentsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement;

    const nextButton = target.closest(".next-btn") as HTMLElement;

    if (nextButton) {
      const nextStatus = nextButton.dataset.nextStatus;
      handleNextStatusButtonClick(nextStatus as Partner["status"]);
    }
  };

  const fetchPartner = async () => {
    const result = await getPartnerAsync({ id: urlParmas.id! });

    if (result.status === 200) {
      setPartner(result.data.data.partner);
      if (result.data.data.partner.electronicSignatureIntegrationType !== "TRUE") {
        tabs[1].disabled = true;
      }
    } else {
      setModal({
        ...modal,
        fail: {
          visible: true,
          message: result.data.meta.errorMessage!,
        },
      });
    }
  };

  const hanldeSuccessModalOkClick = () => {
    setModal({
      ...modal,
      success: {
        visible: false,
        message: "",
      },
    });

    fetchPartner();
  };

  const checkTabsDisabled = useMemo(() => {
    const temp = _.cloneDeep(tabs);
    if (partner && partner.electronicSignatureIntegrationType !== "TRUE") {
      temp[1].disabled = true;
    }
    if (partner && partner.electronicSignatureIntegrationType === "TRUE") {
      temp[1].disabled = false;
    }

    return temp;
  }, [partner]);

  const handleNextStatusButtonClick = async (status: Partner["status"]) => {
    const result = await patchPartnerStatusAsync({
      id: urlParmas.id!,
      status,
    });

    if (result.status >= 200 && result.status < 300) {
      setModal({
        ...modal,
        success: {
          visible: true,
          message: "상태가 변경되었습니다.",
        },
      });
    } else {
      setModal({
        ...modal,
        fail: {
          visible: true,
          message: result.data.meta.errorMessage!,
        },
      });
    }
  };

  const handleGoListButtonClick = () => navigate(getPartnerListPage());

  const handleGoEditButtonClick = () =>
    navigate(getPartnerEditPage(urlParmas.id!, String(activeTab?.value)));

  const handleFailModalOkClick = () => {
    setModal({
      ...modal,
      fail: {
        visible: false,
        message: "",
      },
    });
  };

  const handleModalClose = () => {
    setModal({
      ...modal,
      fail: {
        visible: false,
        message: "",
      },
    });
  };

  useEffect(() => {
    fetchPartner();
  }, []);
  useTitleOperation(partner?.name);

  const DETAIL_MAP = {
    id: {
      label: "id",
      render: (value: string) => <p>{value}</p>,
    },
    code: {
      label: "파트너 코드",
      render: (value: string) => <p>{value}</p>,
    },
    name: {
      label: "파트너 이름",
      render: (value: string) => <p>{value}</p>,
    },
    useStartDate: {
      label: "이용 시작일",
      render: (value: string) => <p>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</p>,
    },
    useEndDate: {
      label: "이용 종료일",
      render: (value: string) => <p>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</p>,
    },
    contractUrl: {
      label: "계약서 URL",
      render: (value: string) => (
        <a href={value} className="text-hilight">
          {value}
        </a>
      ),
    },
    status: {
      label: "상태",
      render: (value: string) => {
        const status = STATUS_MAP[value as keyof typeof STATUS_MAP];
        const next = STATUS_MAP[status.next as keyof typeof STATUS_MAP];

        return (
          <div className={`flex-center`}>
            <div className={`color-chip ${status.color} mr8`}>{status.label}</div>
            {next && (
              <div className="next-btn" data-next-status={status.next}>
                <BaseButton title={next.label} />
              </div>
            )}
          </div>
        );
      },
    },
    statusDate: {
      label: "상태 적용일",
      render: (value: string) => <p>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</p>,
    },
    createdDate: {
      label: "생성일",
      render: (value: string) => <p>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</p>,
    },
    lastModifiedDate: {
      label: "최종 수정일",
      render: (value: string) => <p>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</p>,
    },
    lastModifiedBy: {
      label: "최종 작성자",
      render: (value: string) => (
        <a href={`mailto:${value}`} className="text-hilight">
          {value}
        </a>
      ),
    },
  };

  return (
    <div>
      <ContentsTitle
        title="파트너 정보 상세"
        tabs={checkTabsDisabled}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={getPartnerPageBreadCrumb(["list", "detail"], urlParmas.id!)}
        clickBreadCrumb={(crumb: { value: string; label: string }) => navigate(crumb.value)}
      />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          {activeTab?.value === "basicInfo" && (
            <div className="contents-container__wrap-contents" onClick={handleContentsClick}>
              <h2>기본 정보</h2>
              {partner &&
                Object.keys(DETAIL_MAP).map((key) => {
                  const detail = DETAIL_MAP[key as keyof typeof DETAIL_MAP];
                  const { features, ...baseData } = partner;
                  const partnerData = (baseData as BaseData)?.[key];
                  return (
                    <section className="contents-container__grid" key={detail.label}>
                      <div className="contents-container__grid-index">
                        <p>{detail.label}</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="minmax400">
                          {baseData ? detail.render(partnerData) : {}}
                        </div>
                      </div>
                    </section>
                  );
                })}
            </div>
          )}
          {activeTab?.value === "esign" && <PartnerEsign />}
          {activeTab?.value === "feature" && (
            <PartnerFeature
              features={sortFeaturesByName(partner?.features || [])}
              setPartner={setPartner}
            />
          )}
          {activeTab?.value === "authorities" && <PartnerAuth />}
          {activeTab?.value === "log" && <PartnerLog />}
          {activeTab?.value === "parking" && (
            <PartnerParking features={sortFeaturesByName(partner?.features || [])} />
          )}
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title={"목록"}
                onClick={handleGoListButtonClick}
                className="color-white size-large"
              />
            </div>
            {activeTab?.value !== "authorities" &&
              activeTab?.value !== "feature" &&
              activeTab?.value !== "log" && (
                <div className="right-area">
                  <BaseButton
                    title={"수정"}
                    onClick={handleGoEditButtonClick}
                    className="size-large"
                  />
                </div>
              )}
          </div>
        </div>
        <BaseModal
          isOpen={modal.success.visible}
          btnRightTitle={"확인"}
          onClick={hanldeSuccessModalOkClick}
        >
          <p>{modal.success.message}</p>
        </BaseModal>
        {alertModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnRightTitle={"확인"}
            onClick={() => setAlertModal({ isOpen: false })}
          >
            <p>{alertModal.message}</p>
          </BaseModal>
        )}

        <BaseModal
          isOpen={modal.fail.visible}
          btnLeftTitle={"취소"}
          btnRightTitle={"확인"}
          onClick={handleFailModalOkClick}
          onClose={handleModalClose}
        >
          <div>
            <p>상태를 변경할 수 없습니다.</p>
            <p>{modal.fail.message}</p>
          </div>
        </BaseModal>
      </div>
    </div>
  );
}
export default PartnerDetail;
