import qs from "qs";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContentsTitle } from "src/components";
import { PagePath } from "src/pages/product/details";

import BasicInfo from "./BasicInfo";
import AccessGroupLog from "./AccessGroupLog";

const AccessGroupDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const tabs = [
    { value: "basicInfo", label: "기본정보" },
    { value: "log", label: "로그" },
  ];

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      newQueryParams.tab = tab.value;

      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      // console.log("clickBreadCrumb", crumb);
      if (crumb.value === "product") {
        navigate(PagePath.product.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "partnerMember", label: "파트너 회원" }];
  if (activeTab) {
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  return (
    <div>
      <ContentsTitle
        title="파트너회원"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />

      <div className="contents-container__scroll">
        {activeTab?.value === "basicInfo" && <BasicInfo />}
        {activeTab?.value === "log" && <AccessGroupLog />}
      </div>
    </div>
  );
};

export default AccessGroupDetail;
