import { AxiosInstance, AxiosResponse } from "axios";
import { BuildingListParams } from "../building/building-types";
import { ApiResponse, Sort } from "../public-types";
import { AuditLogDto } from "./log-type";
import { ApiPath } from "..";
import qs from "qs";

/* 
  CPL01. 감사 로그 목록 페이지 조회
*/
export async function getAuditLogsAsync(
  axios: AxiosInstance,
  params?: {
    path: string; // 경로 정책 참고
    sort: Sort;
  },
): Promise<AxiosResponse<ApiResponse<{ content: AuditLogDto[] }>>> {
  if (!params?.path) {
    throw Error("감사로그 path가 지정되지 않았습니다");
  }
  return await axios.get<ApiResponse<{ content: AuditLogDto[] }>>(ApiPath.log.list, {
    params: {
      startsWithPath: params?.path,
      sort: params.sort,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}
