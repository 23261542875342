import qs from "qs";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";

// 필요한 데이터 관리자메모 컴포넌트에 전달
// 1. serviceId
// - 상세페이지 url에 있는 id 전달

// 2. serviceType
// - 관리자 메모 등록시 관련된 타입 - CT_PROVIDER, CT_CONTRACT_MANAGE, CT_CONTRACT

const ProviderAdminMemo = () => {
  const params = useParams();
  const serviceId = params.id; // 상세페이지의 ID 를 전달 / 프로바이더 등등

  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 프로바이더 id
  const providerId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  return (
    <div>
      {/* 관리자 메모 컴포넌트화 */}
      <AdminMemoIntegration
        serviceId={serviceId !== undefined ? Number(serviceId) : Number(providerId)}
        serviceType={ServiceTypes.SERVICE_PROVIDER}
        viewOnly={false}
      />
    </div>
  );
};

export default ProviderAdminMemo;
