import qs from "qs";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";

const OrgAdminMemoTab = () => {
  const params = useParams();
  const serviceId = params.id; // 상세페이지의 ID 를 전달
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 공간상품 id
  const orgId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  return (
    <AdminMemoIntegration
      serviceId={serviceId !== undefined ? Number(serviceId) : Number(orgId)}
      serviceType={ServiceTypes.SERVICE_ORGANIZATION}
      viewOnly={false}
    />
  );
};

export default OrgAdminMemoTab;
