import { Sort, UnionCmdType } from "../public-types";

// 상담관리 상태
export const QuestionStatus = {
  UNRECOGNIZED: "STATUS_UNRECOGNIZED", //정의되지 않은 상태 DEFAULT
  WAIT: "QUESTION_WAIT", //대기
  OPEN: "QUESTION_OPEN", //접수
  PROGRESS: "QUESTION_PROCEEDING", // 처리중
  COMPLETE: "QUESTION_COMPLETE", //답변완료
  HOLD: "QUESTION_HOLD", //보류
  CANCELED: "QUESTION_CANCELED", // 취소
} as const;

// 상담관리 상태 - 유니온 타입
export type QuestionStatusUnionType = typeof QuestionStatus[keyof typeof QuestionStatus];

export const QuestionSite = {
  UNRECOGNIZED: "SITE_UNRECOGNIZED", //정의되지 않은 상태 DEFAULT
  TAAP: "SITE_TAAP", //Taap
  COURT: "SITE_COURT_337", //신도림 핀포인트
  PNPT: "SITE_PNPT", //핀포인트 공식 홈페이지
  NAVER: "SITE_NAVER", //네이버
  EXTERNAL: "SITE_EXTERNAL",
  SITE_ETC: "SITE_ETC",
  SITE_TAAP_SPACE: "SITE_TAAP_SPACE",
  SITE_HOMEPAGE: "SITE_HOMEPAGE",
} as const;

export type QuestionSiteUnionType = typeof QuestionSite[keyof typeof QuestionSite] | "";

/**
 * TODO 상담관리 유형 - 삭제 예정인지 확인 필요*/

export const QuestionType = {
  QNA: "QUESTIONTYPE_QNA", // 1:1 문의
  QUOTECONTACT: "QUESTIONTYPE_QUOTECONTACT", //견적문의
  COUNSELING: "QUESTIONTYPE_COUNSELING", //상담신청
  TOUR: "QUESTIONTYPE_TOUR", // 투어신청
  VOC_NOMAL: "QUESTIONTYPE_VOC_NORMAL", // voc 일반
  VOC_WORKORDER: "QUESTIONTYPE_VOC_WORKORDER", // voc 워크오더
  QUESTIONTYPE_RESERVATION: "QUESTIONTYPE_RESERVATION",
} as const;

// 1:1문의 / 견적문의 / 상담신청 / 투어신청
export type QuestionUnionType = typeof QuestionType[keyof typeof QuestionType] | "";

// 상담관리 구분
export const QuestionCategory = {
  OFFLINE: "QUESTIONCATEGORY_OFFLINE",
  ONLINE: "QUESTIONCATEGORY_ONLINE",
} as const;

// 상담관리 구분
export type QuestionCategoryUnionType = typeof QuestionCategory[keyof typeof QuestionCategory] | "";

export const CsCategoryType = {
  VOC: "VoC",
  CS: "문의",
} as const;

export type CsCategoryTypeUnionType = typeof CsCategoryType[keyof typeof CsCategoryType] | "";

// 상담관리 답변타입
export type QuestionAnswerType = "ANSWER_UNRECOGNIZED" | "ANSWER_NORMAL" | "";

// 상담관리 알림톡 메세지 타입
export type QuestionNotifyMessageType = "MESSAGE_EMAIL" | "MESSAGE_KAKAOTALK";

export type QuestionQueryParams = {
  page?: number;
  size?: number;
  searchType?: string; //검색유형
  keywordList?: string;
  startDate?: string | Date;
  endDate?: string | Date;
  questionCategoryList?: QuestionCategoryUnionType; //등록방식
  questionTypeList?: QuestionUnionType | string[];
  siteList?: QuestionSiteUnionType; //유입경로
  statusList?: QuestionStatusUnionType | string; // 상태
  csTypeId?: string; // Voc 유형 id
  csItemId?: string; //voc 항목 id
  csCategoryType?: string; //구분 (voc/문의)
  sort?: Sort;
  buildingId?: string;
  buildingName?: string;
  ids?: string; // CS ID ( 콤마로 구분 )
  partnerId?: string; //센트럴 전용
};

/**상담관리 등록 타입 */
export interface QuestionAddType {
  // questionCategory?: QuestionCategoryUnionType; // 등록방식
  id?: string;
  questionType?: QuestionUnionType; // 유형
  site?: QuestionSiteUnionType; // 유입경로
  memberNo?: string; // 회원번호
  reporterName?: string; // 접수자 일음
  reporterPhone?: string | null; // 접수자 연락처
  reporterEmail?: string; // 접수자 이메일
  description?: string; // 문의내용
  isSendAlarm?: boolean; //알림톡 발송여부
  assigneeEmail?: string; //담당자 이메일
  cost?: number; // 비용
  csCategoryType?: CsCategoryTypeUnionType; // 구분
  csTypeId?: string; //VoC유형ID
  csTypeName?: string; //VoC유형명
  csItemId?: string; //VoC항목ID
  summary?: string; // 제목 - voc 항목명을 넣어주세요.
  buildingId?: string | null; //csCategoryType이 VOC 인 경우 건물ID 필수
  buildingName?: string;
  contentType?: string;
  externalReferer?: string;
  siteSummary?: string; // 상세 유입경로
  answerGubun?: string; // 답변 방식
  floorNum?: number;
}

export interface ConnectedInfo {
  id: string;
  connectedType: ConnectedType;
  connectedId: string;
}

/**상담관리 상세 타입 */
export interface QuestionDetailModel {
  id?: string;
  partnerId?: string;
  questionCategory?: QuestionCategoryUnionType; //등록방식 (온라인 / 오프라인)
  site?: QuestionSiteUnionType; //유입경로
  memberNo?: string; // 회원번호
  reporterName?: string; // 접수자 이름
  reporterPhone?: string | null; // 접수자 번호
  reporterEmail?: string; // 접수자 이메일
  description?: string; // 문의 내용
  isSendAlarm?: boolean; // 알람발송 여부
  assigneeEmail?: string; //담당자 이메일
  assigneeName?: string; //담당자 이름
  status?: QuestionStatusUnionType; // 상태
  isReopened?: Boolean; // 문의자 정보변경
  answerList?: QuestionAnswerModel[]; // 답변등록 목록
  cost?: number; // 요금
  csTypeId?: string; //		VoC유형 ID
  csTypeName?: string; //		VoC유형명
  csItemId?: string | null; //		VoC항목 ID
  csCategoryType?: CsCategoryTypeUnionType; //		구분
  buildingId?: string | null; //		건물ID
  summary?: string; //		제목
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  questionType?: string;
  externalReferer?: string | null;
  siteSummary?: string;
  floorNum?: number | null;
  answerGubun?: "1" | "2" | "3";
  expense?: number; //지출
  referenceEmail?: string; //콤마 스트링 "aa@gmail.com,bb@gmail.com,cc@gmail.com"
  contentType?: "text/plain" | "text/html";
  connectedInfoList?: ConnectedInfo[];
}

export interface QuestionListModel {
  id: string; //	상담문의 ID
  partnerId: string; //	파트너ID
  questionCategory: QuestionCategoryUnionType; //등록방식
  questionType: QuestionUnionType; //유형 - 삭제예정
  site: QuestionSiteUnionType;
  csTypeId: string; //Y	VoC유형 ID
  csTypeName: string; //	VoC유형명
  csItemId: string; //	VoC항목 ID
  csCategoryType: string; //	구분
  buildingId: string; //	건물ID
  buildingName?: string; // 건물명 -  FE에서 추가한 별도 타입
  summary: string; //	제목
  memberNo: string; //	회원번호
  reporterName: string; //	접수자 이름
  reporterPhone: string; //Y	접수자 연락처
  reporterEmail: string; //	접수자 이메일
  description: string; //	문의내용
  cost: number; //	금액
  status: QuestionStatusUnionType;
  isReopened: string; //	재처리요청여부
  isSendAlarm: string; //	알림톡발송여부
  assigneeName: string; //	담당자명
  assigneeEmail: string; //	담당자 이메일
  isDeleted: string; //	삭제여부
  createdDate: string; //	생성일
  createdBy: string; //	등록ID
  modifiedDate: string; //	수정일
  modifiedBy: string; //	수정ID
  answerCnt: number;
  expense?: number;
  answerList?: QuestionAnswerModel[]; // 답변 리스트 FE에서 추가한 별도 타입
}

export interface QuestionListResponse {
  content: Array<QuestionListModel>;
}

// 담당자 선택 query
export interface QuestionManagersQuery {
  page?: number;
  size?: number;
  sort?: Sort;
  containsEmail?: string;
  blocked?: boolean;
}
// 상담관리 답변등록 등록 타입
export interface QuestionAnswerAddType {
  answerType: QuestionAnswerType;
  description: string;
}

// 상담관리 답변등록 상세
export interface QuestionAnswerModel {
  id: string;
  answerType: QuestionAnswerType;
  description: string;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
  sendEmailDate: string | null;
  sendKakaotalkDate: string | null;
}

export type ConnectedType =
  | "CS_CONNECTED_UNRECOGNIZED"
  | "CS_CONNECTED_CONTRACT"
  | "CS_CONNECTED_WORKORDER";

export interface QuestionConnectedInfoListQuery {
  questionId?: string;
  connectedType?: ConnectedType;
  connectedId?: string;
}

export interface ConnectedInfo {
  id: string;
  questionId: string;
  connectedType: ConnectedType;
  connectedId: string;
}

export interface QuestionConnectedInfoListResponse {
  content: Array<ConnectedInfo>;
}

export interface QuestionConnectedInfoListRequest {
  connectedInfoList: {
    id?: string; //삭제 시 필수
    questionId?: string; //상담관리 ID
    connectedType: ConnectedType; //연결 타입
    connectedId: string; //연결ID
    cmdType: UnionCmdType; //C or D만 사용
  }[];
}
