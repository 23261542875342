import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { BaseButton, BaseDatePicker, BaseModal, BaseTextarea, LoadingBar } from "src/components";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import { PagePath } from "src/pages/product/details";
import TextAttribute from "./components/TextAttribure";
import moment from "moment";
import { MemberRestrictionEdit } from "src/api/member/member-types";
import { useApiOperation } from "src/api/hooks";
import {
  getMemberRestriction,
  memberRestrictionAdd,
  memberRestrictionEdit,
} from "src/api/member/member-api";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { YmdFormat } from "src/utils";

type FormData = {
  startDateTime: Date;
  endDateTime: Date;
  comment: string;
};

// 등록
const MemberRestrictionForm = ({ basicInfo }: any) => {
  const navigate = useNavigate();
  const { memberId } = useParams();
  // console.log("basicInfo", basicInfo, "memberId", memberId);
  const parameter = useLocation();

  const qsp = qs.parse(parameter.search);
  // console.log(qsp);

  const [body, setBody] = useState<MemberRestrictionEdit>({
    memberId: basicInfo?.id,
    inclusiveStartDate: "",
    inclusiveEndDate: "",
    comment: "",
  });

  //저장 확인 팝업
  const [openFlag, setOpenFlag] = useState(false);
  const [confirmFlag, setConfirmFlag] = useState(false);
  //
  const [restriction, setRestriction] = useState<any>();
  const [id, setId] = useState<any>(qsp.id || undefined);
  const [title, setTitle] = useState("이용 제한 등록");

  //저장 API
  const { loading, executeAsync: restrictionAdd } = useApiOperation(memberRestrictionAdd);

  const {
    loading: loadingDetail,

    executeAsync: restrictionDetail,
  } = useApiOperation(getMemberRestriction);

  const {
    loading: updateLoading,

    executeAsync: restrictionEdit,
  } = useApiOperation(memberRestrictionEdit);

  //리스트 페이지 이동
  const goRestricionList = useCallback(() => {
    const path =
      PagePath.restriction.list.replace(":memberId", basicInfo?.id.toString()) +
      "?tab=MemberRestrictionInfo";
    navigate(path);
  }, [basicInfo, navigate]);

  const goConfirm = useCallback(() => {
    setConfirmFlag(true);
  }, []);

  const {
    register,
    control,
    handleSubmit,

    setValue,
    // formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      startDateTime: undefined,
      endDateTime: undefined,
      comment: "",
    },
  });

  const goRestricionDetail = useCallback(
    (id: number) => {
      if (id !== undefined) {
        const detailPath = `${
          PagePath.restriction.detail
            .replace(":id", id.toString())
            .replace(":memberId", basicInfo?.id.toString()) + "?tab=MemberRestrictionInfo"
        }`;

        navigate(detailPath);
      }
    },
    [basicInfo, navigate],
  );

  const goDetail = useCallback(() => {
    setOpenFlag(false);
    goRestricionDetail(id);
  }, [goRestricionDetail, id]);

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    const requiredMessage = "필수 입력 항목입니다";
    register("startDateTime", {
      required: requiredMessage,
      // maxLength: { value: 30, message: "30자 이상 입력 불가능 합니다." },
    });
    register("endDateTime", {
      required: requiredMessage,
    });
    register("comment", {
      required: requiredMessage,
    });
  }, [register]);

  useEffect(() => {
    if (basicInfo?.id && id) {
      restrictionDetail({ memberId: Number(basicInfo?.id), id: Number(id) }).then((response) => {
        const { restriction }: any = response!.data!.data!;
        setRestriction(restriction);
        // console.log(restriction);
        setBody(restriction);
        setTitle("이용제한수정");
        const checkStart = moment(restriction.inclusiveStartDate);
        const checkEnd = moment(restriction.inclusiveEndDate);
        // console.log(checkStart, checkEnd);

        setValue("startDateTime", checkStart.toDate());
        setValue("endDateTime", checkEnd.toDate());
        setValue("comment", restriction.comment);
      });
    }
  }, [basicInfo, id, restrictionDetail, setValue]);

  useEffect(() => {
    if (body.memberId && body.id) {
      //EDIT
      // console.log('edit',body.memberId);

      restrictionEdit(body).then((response) => {
        // console.log(response);
        if (response?.data?.data!) {
          const { restriction }: any = response?.data?.data;
          if (restriction) {
            //go detail
            setId(restriction?.id!);
          }
        }
      });
    } else {
      //ADD
      // console.log("add", body);
      if (basicInfo && body.memberId) {
        restrictionAdd(body || undefined)
          .then((response) => {
            // console.log('add return', response.data.data);
            const { restriction }: any = response?.data?.data;
            //go detail
            setId(restriction?.id!);
          })
          .catch((error) => {});
      }
    }
  }, [body, setBody, restrictionAdd, goRestricionDetail, restrictionEdit]);

  const onSubmit = useCallback(
    async (data: FormData, e?: any) => {
      console.log("onSubmit data", data, e);

      e.preventDefault();
      console.log(basicInfo!.id, ";;;;;;");

      const makeSendParmas: any = {
        ...body,
        ...{
          inclusiveStartDate: moment(data.startDateTime).format(YmdFormat.WITH_TIME_ZONE),
          inclusiveEndDate: moment(data.endDateTime).format(YmdFormat.WITH_TIME_ZONE),
          comment: data.comment,
          memberId: basicInfo!.id,
        },
      };

      setBody(makeSendParmas);

      e.target.reset();
      setOpenFlag(true);
      return false;
    },
    [basicInfo, body],
  );

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);

    e.preventDefault();

    return false;
  };

  // if (error) {
  //   return <div>에러가 발생했습니다</div>;
  // }

  return (
    <>
      <LoadingBar visible={loading || loadingDetail || updateLoading} />
      <div className="contents-container__wrap detail-usage-restriction">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="contents-container__wrap-contents">
            <ContentsIdSection
              title={title}
              id={basicInfo?.id}
              noTitle="회원번호"
              no={basicInfo?.memberNo}
            />
            <section>
              <div className="contents-container__sub-title">
                <h2>회원정보</h2>
              </div>
              <TextAttribute title={"id"} value={basicInfo?.id} />
              <TextAttribute title={"회원번호"} value={basicInfo?.memberNo} />
              <TextAttribute title={"휴대폰번호"} value={basicInfo?.phoneNumber} />
              <TextAttribute title={"이메일"} value={basicInfo?.email} />
            </section>

            <section className="contents-container__divide-top">
              <div className="contents-container__sub-title">
                <h2>이용 제한 내용</h2>
              </div>

              <div className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">이용 제한 기간</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className=" flex-row justify-contents-start flex-center mb10">
                    <div className="minmax50 font14 no-wrap">시작일</div>
                    <div className="minmax200">
                      <Controller
                        control={control}
                        name="startDateTime"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <BaseDatePicker
                            selectedDate={value}
                            setDate={onChange}
                            errorText={error?.message}
                            name={name}
                            type={"full"}
                          />
                        )}
                      ></Controller>
                    </div>
                    <div className="minmax50 font14 no-wrap ml20">종료일</div>
                    <div className="minmax200 ">
                      <Controller
                        control={control}
                        name="endDateTime"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <BaseDatePicker
                            selectedDate={value}
                            setDate={onChange}
                            errorText={error?.message}
                            name={name}
                            type={"full"}
                          />
                        )}
                      ></Controller>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">이용 제한 시유</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400 flex-center mb10">
                    <Controller
                      control={control}
                      name="comment"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseTextarea
                          name={name}
                          value={value}
                          onChange={onChange}
                          maxLength={1000}
                          errorText={error?.message}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </div>
              {id && (
                <>
                  <TextAttribute
                    title={"생성일"}
                    value={moment(restriction?.createdDate).format(YmdFormat.FULL)}
                  />
                  <TextAttribute
                    title={"최종 수정일"}
                    value={moment(restriction?.lastModifiedDate).format(YmdFormat.FULL)}
                  />
                  <TextAttribute title={"최종 작성자"} value={restriction?.lastModifiedBy} />
                </>
              )}
            </section>
          </div>
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title="목록으로"
                onClick={() => {
                  id ? goConfirm() : goRestricionList();
                }}
                className="color-white size-large"
              />
            </div>
            <div className="right-area">
              <BaseButton title="저장" type="submit" className="color-black size-large" />
            </div>
          </div>
        </form>
        {openFlag && (
          <BaseModal isOpen={openFlag} btnRightTitle="확인" onClick={() => goDetail()}>
            <span>저장 되었습니다.</span>
          </BaseModal>
        )}
        {confirmFlag && (
          <BaseModal
            isOpen={confirmFlag}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={() => goRestricionList()}
            onClose={() => setConfirmFlag(false)}
          >
            <div>
              <span>작성중인 내용이 있습니다.</span>
              <span>계속할까요?</span>
            </div>
          </BaseModal>
        )}
      </div>
    </>
  );
};

export default MemberRestrictionForm;
