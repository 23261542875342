import { BuildingModel } from "../building/building-types";
import { HeatingType, MediaFile, MetaData, Sort, Status } from "../public-types";

// 공간상품 상태
export enum ProductStatus {
  ENABLED = "ENABLED", // 공개
  DISABLED = "DISABLED", // 비공개
  DRAFT = "DRAFT", // 이용중
  CANCELED = "CANCELED", // 관리자 등에 의해 사용중지(취소) 된 경우
  LINK_ENABLED = "LINK_ENABLED", // 링크공개
  EXPIRED = "EXPIRED", // 기간만료
}

// 공간상품 타입
export enum ProductType {
  F = "FULL_COURT",
  O = "OPEN_COURT",
  T = "TIME_COURT",
  N = "NON_RESIDENT_OFFICE", // 비상주 오피스
}

// 공간상품 이용안내 타입
export const ProductGuideTypes = {
  UNRECOGNIZED: "PRODUCT_GUIDE_UNRECOGNIZED",
  PRINTER: "PRODUCT_GUIDE_PRINTER",
  WIFI_CUSTOMER: "PRODUCT_GUIDE_WIFI_CUSTOMER",
  WIFI_VISITOR: "PRODUCT_GUIDE_WIFI_VISITOR",
  PARKINGLOT: "PRODUCT_GUIDE_PARKINGLOT",
  ETC: "PRODUCT_GUIDE_ETC",
  ETC2: "PRODUCT_GUIDE_ETC2",
  PARKINGLOT2: "PRODUCT_GUIDE_PARKINGLOT2",
  GUIDE_WIFI: "PRODUCT_GUIDE_WIFI",
} as const;

// ProductGuideTypes Union 타입
export type ProductGuideType = typeof ProductGuideTypes[keyof typeof ProductGuideTypes];

export interface ProductTest {
  id: string;
}

export interface Test extends ProductTest {
  name: string;
}

// 공간상품 - 안내 - 사용안내 타입
// export type ProductGuideType =
//   | "PRODUCT_GUIDE_UNRECOGNIZED" // 정의되지 않은 타입
//   | "PRODUCT_GUIDE_PRINTER" // 복합기
//   | "PRODUCT_GUIDE_WIFI_CUSTOMER" // 구버전 고객용 네트워크
//   | "PRODUCT_GUIDE_WIFI_VISITOR" // 구버전 방문자용 네트워크
//   | "PRODUCT_GUIDE_PARKINGLOT" // 구버전 주차
//   | "PRODUCT_GUIDE_ETC" // 구버전 기타
//   | "PRODUCT_GUIDE_ETC2" //사용안내 일반
//   | "PRODUCT_GUIDE_PARKINGLOT2" //사용안내 주차장
//   | "PRODUCT_GUIDE_WIFI"; // 사용안내 네트워크

// 공간상품 수정 response data
export interface ProductUpdateResponseData {
  content?: number; //old
  // content?: Array<ProductGuide>;
}

export interface ProductUpdateResponseData2 {
  content?: Array<ProductGuide>;
}

// 공간상품 기본정보 등록
export interface ProductAddModel {
  productBuildingList: Array<ProductBuilding>; // 공간상품-건물 매핑 정보 ( 건물/호실 +공용공간 )
  buildingList?: BuildingModel[]; // 공간상품에 등록된 건물정보 ( 서버에서 내려주는 값 )
  productName?: string; // 공간상품이름
  introduce?: string; // 한줄소개
  productDirectionList?: Array<MetaData>; // 향
  description?: string; // 설명
  questionPhone?: string; // 문의연락처
  questionStartTime?: string; // 문의시작시간
  questionEndTime?: string; // 문의종료시간
  locationCode?: string;
  colorName?: string;
  vocUrl?: string;
  productCategory?: string;
  partnerId?: string;
}

// 공간상품 상품정보 등록
export interface ProductInfoAddModel {
  providerId?: string; //프로바이더 ID
  afterStatus?: Status; //신청/계약 후 비공개 처리 여부
  productType?: string; //상품타입
  minLeasePeriod?: number | string; //최소계약기간
  maxLeasePeriod?: number | string; //최대계약기간
  moveInAvailDate?: string; //입주가능일자
  deposit?: number | string; //보증금
  earnest?: number | string; //계약금
  balanceFullPaymentDate?: number | string; //잔금일자
  saleDeposit?: number | string; //할인보증금
  saleEarnest?: number | string; //할인계약금
  isRightAwayMoveIn?: boolean; // 즉시 입주 여부
  rentalCostList?: Array<MetaData>; //이용료
  saleRentalCostList?: Array<MetaData>; //이용료 할인
  isInformPopup?: boolean; //이용료 안내팝업 설정여부
  informPopupDesc?: string; //이용료 안내팝업 내용
  isImpositionFee?: boolean; // 관리비 여부
  maintenanceFee?: number | string; //관리비
  productMaintenanceFeeList?: Array<MetaData>; //관리비 내역
  useNums?: number | string; //사용인원
  isDeskChairIncluded?: boolean; //책상_의자포함여부
  maxNums?: number | string; //계정 최대 인원
  totalSeatNums?: number | string; //총좌석수
  useStartTime?: string; //사용가능시작시간
  useEndTime?: string; //사용가능종료시간
}

// 공간상품 공용공간 등록
export interface ProductCommonSpaceAddModel {
  parkingLotFreeDescription?: string; // 무료 주차 설명
  parkingLotPayDescription?: string; // 유료 주차 설명
  // meetingRoomMaxReservTimeMonthFlag?: boolean;
  meetingRoomMaxReservTimeMonth?: number; // 회의실 한달 최대 예약가능 시간
  meetingRoomMaxReservTimeDay?: number; // 회의실 하루 최대 예약가능 시간
  meetingRoomDescription?: string; // 회의실 추가 설명
  deskMaxReservTimeMonth?: number; // 좌석 한달 최대 예약가능 시간
  deskMaxReservTimeDay?: number; // 좌석 하루 최대 예약가능 시간
  deskDescription?: string; // 좌석 추가 설명
  refreshRoomMaxReservTimeMonth?: number; // 편의시설 한달 최대 예약가능 시간
  refreshRoomMaxReservTimeDay?: number; // 편의시설 하루 최대 예약가능 시간
  refreshRoomDescription?: string; // 편의시설 추가 설명
  isParkingLot?: boolean; // 주차 사용 여부
  isMeetingRoom?: boolean; // 회의실 사용 여부
  isRefreshRoom?: boolean; // 편의시설 사용 여부
  isDesk?: boolean; // 좌석 사용 여부
}

// 공간상품 시설/서비스 등록
export interface ProductFacilityAddModel {
  productAirConditioner?: ProductAirConditioner; // 공조기
  productToiletList?: Array<MetaData>; // 화장실
  productPrinter?: ProductPrinter; // 복합기
  productFreeInternet?: ProductFreeInternet; // 무료 인터넷
  productOptionalFacilityList?: Array<MetaData>; // 추가 시설/서비스
  etcService?: string; // 기타시설서비스
}

/* 
  공간상품 기본정보 수정
*/
export interface ProductEditModel extends ProductAddModel {
  // id: number;
  id: number | string;
}

/* 
  공간상품 시설/서비스 수정
*/
export interface ProductFacilityEditModel extends ProductFacilityAddModel {
  id: number | string; // product id
}

/* 
  공간상품 안내 수정
*/
export interface ProductGuideEditModel {
  guides: Array<ProductGuideContainer>;
}

export interface ProductCommonSpaceEditModel extends ProductCommonSpaceAddModel {
  id: number | string;
}

export type ProductCategory = "PRODUCT_CATEGORY_NORMAL" | "PRODUCT_CATEGORY_MANAGEMENT";

/* 
  공간상품 상세
*/
export interface ProductModel
  extends ProductAddModel,
    ProductInfoAddModel,
    ProductFacilityAddModel,
    ProductCommonSpaceAddModel {
  id: number;
  imagePath?: string;
  productType?: string;
  deposit?: string;
  rent?: string;
  address?: string;
  isDisplayed?: string;
  isDeleted?: string;
  createdBy?: string;
  createdDate?: string;
  modifiedBy?: string;
  modifiedDate?: string;
  buildingName?: string;
  productRentValue1: string;
  productRentType: string;
  status?: string;
  refreshRoomMaxReservTimeMonthIsFlag: boolean;
  refreshRoomMaxReservTimeDayIsFlag: boolean;
  isUsed: boolean;
  vocUrl?: string;
  productCategory: ProductCategory;
  partnerId?: string;
  providerName?: string;
}

// 공간상품 상세 조회 response data
export interface ProductDetailType {
  content: ProductModel;
}

// 공간상품 목록 조회 parameter
export interface ProductListParams {
  page?: number;
  size?: number;
  sort?: Sort;
  keyword?: string;
  id?: string;
}

// 공간상품 목록 조회 response data
export interface ProductListData {
  content: Array<ProductModel>;
}

// 공간상품 기본정보 등록 - 공간상품-건물 매핑 정보 ( 건물/호실 +공용공간 )
export interface ProductBuilding {
  id: number | string; // 건물 id
  productBuildingFloorList?: Array<ProductBuildingFloor>; // 건물 층
  productBuildingCommonFacility?: ProductBuildingCommonFacility; // 공용공간
}

// 공간상품-건물 매핑 정보 ( 건물/호실 +공용공간 ) > 공용공간
export interface ProductBuildingCommonFacility {
  meetingRoomList?: Array<string>; // 건물 미팅룸 ID
  refreshRoomList?: Array<string>; // 건물 리프레쉬룸 ID
  deskList?: Array<string>; // 건물 1인 데스크 ID
}

// 공간상품 기본정보 등록 - 건물/호실 중 층
export interface ProductBuildingFloor {
  id: number;
  floorNum?: number;
  productBuildingRoomList: Array<ProductBuildingRoom>;
}

// 공간상품 기본정보 등록 - 건물/호실 중 층의 호실
export interface ProductBuildingRoom {
  id: number;

  isPrimary: boolean;
}

// 공간상품 시설/서비스 - 공조기,
export interface ProductAirConditioner {
  heatingType: HeatingType; // 난방타입
  heatingTypeDesc?: string; // 난방타입설명
  isAirConditionerProvided?: boolean; // 공조기제공여부
  airConditionerDescription?: string; // 공조기 추가 설명
  airConditionerInfoList?: Array<MetaData>; // 공조기 기기정보
}

// 공간상품 시설/서비스 - 복합기
export interface ProductPrinter {
  isPrinterProvided?: boolean; // 복합기 제공여부
  printerDescription?: string; // 복합기 추가 설명
  printerOptionList?: Array<MetaData>; // 복합기 옵션
}

// 공간상품 시설/서비스 - 무료인터넷
export interface ProductFreeInternet {
  isFreeInternetProvided?: boolean; // 무료인터넷 제공여부
  freeInternetDescription?: string; // 무료인터넷 추가 설명
  freeInternetOptionList?: Array<MetaData>; // 무료인터넷 옵션
}

// 공간상품 안내 - 사용안내 구버전
export interface ProductGuideContainer {
  buildingId?: number; // 건물 id
  productGuidePrinterList?: Array<ProductGuide>; // 복합기
  productGuideNetworkList?: Array<ProductGuide>; // 네트워크
  productGuideParkinglotList?: Array<ProductGuide>; // 주차
  productGuideEtcList?: Array<ProductGuide>; // 기타
}

// 공간상품 이용안내 등록/수정 타입 - NEW
export interface ProductGuideUpdateForm {
  productGuide?: NewProductGuide;
}

// 공간상품 안내 - 사용안내 - 공통 - 구버전
export interface ProductGuide {
  id?: string; // 사용안내 ID
  productGuideType?: ProductGuideType; // 사용안내 타입
  location?: string; // 위치
  driverUrl?: string; // 복합기 드라이버 다운로드 주소
  description?: string; // 설명
  customerWifiList?: Array<ProductGuideNetwork>; // 고객용 네트워크
  visitorWifiList?: Array<ProductGuideNetwork>; // 방문자용 네트워크
}

//
// 공간상품 안내 - 사용안내 - 공통 - NEW 버전
export interface NewProductGuide {
  id?: string; // 사용안내 ID
  productId?: string;
  buildingId?: string;
  productGuideType?: ProductGuideType; // 사용안내 타입
  location?: string; // 위치
  driverUrl?: string; // 복합기 드라이버 다운로드 주소
  description?: string; // 설명
  customerWifiList?: Array<ProductGuideNetworkCatalog>;
  visitorWifiList?: Array<ProductGuideNetworkCatalog>;
  networkCatalogList?: Array<ProductGuideNetworkCatalog>; //네트워크 목록
  orderNums?: number | string; //정렬순서(가중치, desc)
  isDisplayed?: boolean; //노출여부
  subject?: string;
}

// CPP16. 공간상품 사용안내 목록조회 request 타입 - New
export interface ProductGuideListRequest {
  productId: number;
  query: {
    buildingId: number;
    isDisplayed: boolean;
  };
}

// CPP16. 공간상품 사용안내 목록조회 response 타입
export interface ProductGuideListType {
  id?: number;
  productGuideType?: ProductGuideType;
  orderNums?: number;
  isDisplayed?: boolean;
  subject?: string;
  buildingId?: number;
  productId?: number;
}

export interface ProductGuideListResponse {
  content: [ProductGuideListType];
}

// 공간상품 안내 - 사용안내 - 네트워크
export interface ProductGuideNetwork {
  id?: string; // 사용안내 네트워크 ID
  orderNums?: number; // 순서
  wifiName?: string; // Wifi
  wifiPassword?: string; // Wifi Password
  productGuideNetworkCatalogId?: string; //사용안내 네트워크 목록 ID
}

// 상세 네트워크 리스트
export interface ProductGuideNetworkCatalog {
  id?: string; // 사용안내 네트워크 목록 ID
  isOpened?: boolean; //false: 비공개 네트워크(고객용) true: 공개용 네트워크(방문자용)
  productGuideId?: string; // 상품안내 ID
  location?: string; //위치
  productGuideNetworkList: Array<ProductGuideNetwork>; //상세 네트워크 리스트
  description?: string; //설명
}

// 공간상품 사용안내 상세조회 response data
export interface ProductGuideDetailResponseData {
  content?: Array<ProductGuideContainer>;
}

export interface ProductMediaFileList {
  content?: Array<MediaFile>;
}
