import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ContractStep } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { getNoticeAsync, postNoticeAsync, putNoticeAsync } from "src/api/notice/notice-api";
import {
  NoticeBuildingDto,
  NoticeContractDto,
  NoticeMediaModel,
  NoticeModel,
  NoticeType,
} from "src/api/notice/notice-types";
import {
  BaseButton,
  BaseDatePicker,
  BaseInput,
  BaseModal,
  BaseRadio,
  BaseTextarea,
  BaseToggle,
  ContentsIdSection,
  ContentsTitle,
} from "src/components";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { YmdFormat } from "src/utils";
import { Modal, NoticeFormData } from "../notice-types";
import BuildingSelectModal from "./components/BuildingSelectModal";
import ContractSelectModal from "./components/ContractSelectModal";
import { getContractApply } from "src/api/contract/contract-api";
import { getBuildingsAsync } from "src/api/building/building-api";
import MarkdownEditor from "src/components/MarkdownEditor";
import NoticeDetail from "../details/NoticeDetail";

/*
  공지사항 등록 or 수정
*/
const INVITEABLE_CONTRACT_STEP = [
  ContractStep.CONTRACT_ACCEPT,
  ContractStep.USE_APPROVAL,
  ContractStep.USE_PROGRESS,
  ContractStep.TERMINATE_RECEIVED,
];

type Contract = {
  contractId: number;
  contractApplyNumber: string;
  contractStep?: ContractStep;
  spaceProductName: string;
};
type Building = {
  buildingId: number;
  buildingName: string;
};

const NoticeForm = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 토스트
  const { openToast } = useToastContext();

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      console.log("clickBreadCrumb", crumb);
      if (crumb.value === "notice") {
        navigate(PagePath.notice.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "notice", label: "공지사항" }];

  // 공지사항 상세 조회 (수정일 경우)
  const noticeId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  const [notice, setNotice] = useState<NoticeModel | undefined>();
  const [isContractSelectModalOpen, setIsContractSelectModalOpen] = useState({ isOpen: false });
  const [isBuildingSelectModalOpen, setIsBuildingSelectModalOpen] = useState({ isOpen: false });
  const [contract, setContract] = useState<Contract[]>([]);
  const [buildings, setBuildings] = useState<Building[]>([]);

  const { executeAsync: getNotice } = useApiOperation(getNoticeAsync);
  const { executeAsync: postNotice } = useApiOperation(postNoticeAsync);
  const { executeAsync: putNotice } = useApiOperation(putNoticeAsync);
  const { executeAsync: getContractList } = useApiOperation(getContractApply);
  const { executeAsync: getBuildingList } = useApiOperation(getBuildingsAsync);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });
  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 작성취소 모달
  const [uncreateModal, setUncreateModal] = useState<Modal>({
    isOpen: false,
  });

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    watch,
    formState: { errors },
  } = useForm<NoticeFormData>({
    defaultValues: {
      contracts: [],
      buildings: [],
      title: "",
      content: "",
      type: "",
      isDisplayed: false,
      displayStartDate: "",
      displayEndDate: "",
    },
  });

  const fetchApi = useCallback(
    async (noticeId) => {
      const { data } = await getNotice({ noticeId });

      if (data.data.notice) {
        const notice = data.data.notice;
        setNotice(notice);
        setValue("title", notice.title);
        setValue("type", notice.type);
        setValue("content", notice.content);
        setValue("isDisplayed", notice.isDisplayed);

        if (notice.displayEndDate && notice.displayStartDate) {
          setValue("displayStartDate", notice.displayStartDate);
          setValue("displayEndDate", notice.displayEndDate);
        }
        if (notice.contracts && notice.contracts.length > 0) {
          const contractNumberList: Array<number> = notice.contracts.map(
            (contract: NoticeContractDto) => contract.contractId,
          );
          const { data: contractList } = await getContractList({
            page: 0,
            size: 99,
            contractIds: contractNumberList.join(","),
          }); // 계약목록 조회

          if (contractList.data.content) {
            const contracts = contractList.data.content;

            const newContracts = contracts.map((contract: any) => {
              let tempContracts: Contract = {
                contractId: 0,
                contractApplyNumber: "",
                spaceProductName: "",
              };
              tempContracts.contractId = contract.contractId;
              tempContracts.contractApplyNumber = contract.contractApplyNumber;
              tempContracts.spaceProductName = contract.spaceProductName;
              return tempContracts;
            });

            setContract(newContracts);
          }
        }
        if (notice.buildings && notice.buildings.length > 0) {
          const buildingNumberList: Array<number> = notice.buildings.map(
            (building: NoticeBuildingDto) => building.buildingId,
          );
          const { data: buildingList } = await getBuildingList({
            page: 0,
            size: 99,
            id: buildingNumberList.join(","),
          }); // 건물목록 조회
          if (buildingList.data.content) {
            const buildings = buildingList.data.content;
            const newBuildings = buildings.map((building: any) => {
              let tempBuildings = {
                buildingId: 0,
                buildingName: "",
              };
              tempBuildings.buildingId = building.id;
              tempBuildings.buildingName = building.buildingName;
              return tempBuildings;
            });
            setBuildings(newBuildings);
          }
        }
      }
    },
    [getNotice, getContractList, getBuildingList, setValue],
  );

  useEffect(() => {
    if (noticeId) {
      fetchApi(noticeId);
    }
  }, [fetchApi, noticeId]);

  // // 수정시 api 에서 받아온 notice 정보로 setValue 처리
  // useEffect(() => {
  //   if (notice) {
  //     Object.entries(notice).forEach(([name, value]: any) => {
  //       setValue(name, value);
  //     });
  //   }
  // }, [notice, setValue]);

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    const requiredMessage = "필수입력항목입니다";
    register("title", {
      required: requiredMessage,
    });
    register("content", {
      required: requiredMessage,
    });
    register("type", {
      required: requiredMessage,
    });
    register("displayStartDate", {
      required: requiredMessage,
    });
    register("displayEndDate", {
      required: requiredMessage,
    });
  }, [register]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = useCallback(
    async (data: any, e?: any) => {
      e.preventDefault();
      let noticeData: NoticeFormData = {
        title: "",
        contracts: [],
        buildings: [],
        content: "",
        type: "",
        isDisplayed: false,
        displayStartDate: "",
        displayEndDate: "",
      };

      let tempNoticeData = { ...noticeData, ...data };

      tempNoticeData.displayStartDate = moment(data.displayStartDate).format(
        YmdFormat.WITH_TIME_ZONE,
      );
      tempNoticeData.displayEndDate =
        moment(data.displayEndDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00";
      // 등록일때 무조건 C타입
      if (!noticeId) {
        tempNoticeData.contracts = contract?.map((item: Contract) => {
          return {
            contractId: Number(item.contractId),
            cmdType: "C",
          };
        });
        tempNoticeData.buildings = buildings?.map((item: Building) => {
          return {
            buildingId: Number(item.buildingId),
            cmdType: "C",
          };
        });
      }

      // 수정일때 C or D 타입
      if (noticeId) {
        const originNoticeContractsNumberList: Array<number> =
          notice?.contracts?.map((item: NoticeContractDto) => Number(item.contractId)) || [];
        const originNoticeBuildingsNumberList: Array<number> =
          notice?.buildings?.map((item: NoticeBuildingDto) => Number(item.buildingId)) || [];
        const contractNumberList: Array<number> =
          contract?.map((item) => Number(item.contractId)) || [];
        const buildingNumberList: Array<number> =
          buildings?.map((item) => Number(item.buildingId)) || [];
        const addedContract = contract?.filter(
          (contractItem) =>
            !originNoticeContractsNumberList?.includes(Number(contractItem.contractId)),
        );
        const addedBuilding = buildings?.filter(
          (buildingItem) =>
            !originNoticeBuildingsNumberList?.includes(Number(buildingItem.buildingId)),
        );
        const deletedContractOrigin = notice?.contracts?.filter((contract: NoticeContractDto) => {
          return !contractNumberList?.includes(Number(contract.contractId));
        });
        const deletedBuildingOrigin = notice?.buildings?.filter((building: NoticeBuildingDto) => {
          return !buildingNumberList?.includes(Number(building.buildingId));
        });

        // 추가되는항목
        if (addedContract) {
          const addedContractList = addedContract.map((item) => {
            return {
              contractId: Number(item.contractId),
              cmdType: "C",
            };
          });
          tempNoticeData.contracts = [...tempNoticeData.contracts, ...addedContractList];
        }

        if (addedBuilding.length > 0) {
          const addedBuildingList = addedBuilding.map((item) => {
            return {
              buildingId: Number(item.buildingId),
              cmdType: "C",
            };
          });
          tempNoticeData.buildings = [...tempNoticeData.buildings, ...addedBuildingList];
        }

        // 제거되는 항목
        if (deletedContractOrigin) {
          const deletedContractList = deletedContractOrigin.map((item: NoticeContractDto) => {
            return {
              id: item.id,
              cmdType: "D",
            };
          });
          tempNoticeData.contracts = [...tempNoticeData.contracts, ...deletedContractList];
        }

        if (deletedBuildingOrigin) {
          const deletedBuildingList = deletedBuildingOrigin.map((item: NoticeBuildingDto) => {
            return {
              id: item.id,
              cmdType: "D",
            };
          });
          tempNoticeData.buildings = [...tempNoticeData.buildings, ...deletedBuildingList];
        }
      }

      const { displayDate, ...payload } = tempNoticeData;

      if (noticeId) {
        // 수정
        setConfirmModal({
          isOpen: true,
          message: "수정하시겠습니까?",
          type: "editNotice",
          payload: payload,
        });
      } else {
        // 등록
        setConfirmModal({
          isOpen: true,
          message: "저장하시겠습니까?",
          type: "addNotice",
          payload: payload,
        });
      }

      e.target.reset();
      // return false;
    },
    [noticeId, contract, buildings, notice],
  );

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    // console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  const onAddedContractSelectModal = (
    contractList: {
      contractId: number;
      contractApplyNumber: string;
      spaceProductName: string;
    }[],
  ) => {
    if (!contractList) return;
    setContract(contractList);
    setIsContractSelectModalOpen({ isOpen: false });
  };
  const onAddedBuildingSelectModal = (
    buildingList: {
      buildingId: number;
      buildingName: string;
    }[],
  ) => {
    if (!buildingList) return;
    setBuildings(buildingList);
    setIsBuildingSelectModalOpen({ isOpen: false });
  };

  // 데이터를 하나라도 입력했는지?
  const isChangedData = () => {
    const defaultValues: NoticeFormData = {
      title: "",
      content: "",
      type: "",
      isDisplayed: false,
      displayStartDate: "",
      displayEndDate: "",
      contracts: [],
      buildings: [],
    };

    const stringifyDefaultValues = JSON.stringify(defaultValues);
    const stringifyData = JSON.stringify(getValues());

    return stringifyDefaultValues === stringifyData ? false : true;
  };

  const handleDeleteContract = (id: number) => {
    const filteredDeletedContract = contract!.filter((contract) => contract.contractId !== id);
    setContract(filteredDeletedContract);
  };

  const handleDeleteBuilding = (id: number) => {
    const filteredDeletedBuilding = buildings!.filter((building) => building.buildingId !== id);
    setBuildings(filteredDeletedBuilding);
  };

  const changePartnerText = () => {
    if (!noticeId && !notice) {
      return "all";
    } else if (noticeId && Number(notice?.partnerId) > 0) {
      return "핀포인트 (pinpoint)";
    } else if (noticeId && Number(notice?.partnerId) === 0) {
      return "all";
    } else return "-";
  };

  useTitleOperation(notice?.title);
  return (
    <div>
      <ContentsTitle title="공지사항" breadCrumbs={breadCrumbs} clickBreadCrumb={clickBreadCrumb} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="contents-container__wrap-contents">
              <ContentsIdSection title="공지사항 정보" id={noticeId} />
              <article className="contents-container__1200">
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="">파트너</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>{changePartnerText()}</p>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">공지 유형</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <Controller
                      control={control}
                      name="type"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <div className="flex-row flex-center-start">
                              <BaseRadio
                                key={NoticeType.SPACE}
                                id={NoticeType.SPACE}
                                name={name}
                                value={NoticeType.SPACE}
                                label={"공간 이용"}
                                checked={value === NoticeType.SPACE}
                                onChange={(_, e) => onChange(e.target.value)}
                                className="mr16"
                              />
                              <BaseRadio
                                key={NoticeType.SERVICE}
                                id={NoticeType.SERVICE}
                                name={name}
                                value={NoticeType.SERVICE}
                                label={"서비스 이용"}
                                checked={value === NoticeType.SERVICE}
                                onChange={(_, e) => onChange(e.target.value)}
                              />
                            </div>
                            {error && <p className="validation-text">{error.message}</p>}
                          </>
                        );
                      }}
                    />
                  </div>
                </section>

                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="">신청/계약</p>
                  </div>
                  <div className="contents-container__grid-contents contents-container__1200">
                    <div className="flex-row flex-center-start">
                      <BaseButton
                        type="button"
                        className="color-white size-medium"
                        title="선택"
                        onClick={() => setIsContractSelectModalOpen({ isOpen: true })}
                      />
                      <span className="ml8 text-primary3">
                        {contract.length <= 0
                          ? "*공지할 계약을 지정할 수 있습니다. (미선택시 전체 노출)"
                          : ""}
                      </span>
                    </div>
                    <div className="flex-files pt10">
                      {contract?.map((item) => (
                        <div className="flex-files__wrap" key={item.contractId}>
                          <p>{item.spaceProductName}</p>{" "}
                          <div
                            className="delete-btn-x"
                            onClick={() => handleDeleteContract(item.contractId)}
                          ></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <div className="flex-center">
                      <p className="">건물</p>
                    </div>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-start">
                      <BaseButton
                        type="button"
                        className="color-white size-medium"
                        title="선택"
                        onClick={() => setIsBuildingSelectModalOpen({ isOpen: true })}
                      />
                      <span className="ml8 text-primary3">
                        {buildings.length <= 0
                          ? "*공지할 건물을 지정할 수 있습니다. (미선택시 전체 노출)"
                          : ""}
                      </span>
                    </div>
                    <div className="flex-files pt10">
                      {buildings?.map((item) => (
                        <div className="flex-files__wrap" key={item.buildingId}>
                          <p>{item.buildingName}</p>{" "}
                          <div
                            className="delete-btn-x"
                            onClick={() => handleDeleteBuilding(item.buildingId)}
                          ></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>

                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">제목</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax500">
                      <Controller
                        control={control}
                        name="title"
                        render={({
                          field: { onChange, value, name, ref },
                          fieldState: { error },
                        }) => (
                          <BaseInput
                            inputRef={ref}
                            placeholder="제목을 입력해 주세요"
                            className="mr8"
                            type="text"
                            onChange={onChange}
                            value={value}
                            name={name}
                            errorText={error?.message}
                          />
                        )}
                      />
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">내용</p>
                  </div>
                  <div className="contents-container__grid-contents contents-container__1200">
                    <div>
                      {(!noticeId || getValues("content") === "") && (
                        <Controller
                          control={control}
                          name="content"
                          render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                            return (
                              <MarkdownEditor
                                value={value}
                                onChange={onChange}
                                errorText={error?.message}
                              />
                            );
                          }}
                        ></Controller>
                      )}

                      {noticeId && getValues("content") && (
                        <Controller
                          control={control}
                          name="content"
                          defaultValue={""}
                          render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                            return (
                              <MarkdownEditor
                                value={value}
                                onChange={onChange}
                                errorText={error?.message}
                              />
                            );
                          }}
                        ></Controller>
                      )}
                    </div>
                  </div>
                </section>

                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="">공개 여부</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-start">
                      <Controller
                        control={control}
                        name="isDisplayed"
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <BaseToggle
                              checked={value}
                              onChange={(value: boolean) => {
                                onChange(value);
                              }}
                              name={name}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">공개 기간</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-start">
                      <div className="minmax200">
                        <Controller
                          control={control}
                          name="displayStartDate"
                          render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                            return (
                              <BaseDatePicker
                                timeIntervals={10}
                                type="date"
                                selectedDate={value ? moment(value).toDate() : null}
                                setDate={onChange}
                                name={name}
                              />
                            );
                          }}
                        />
                      </div>
                      <span className="mx10">~</span>
                      <div className="minmax200">
                        <Controller
                          control={control}
                          name="displayEndDate"
                          render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                            return (
                              <BaseDatePicker
                                timeIntervals={10}
                                type="date"
                                selectedDate={value ? moment(value).toDate() : null}
                                setDate={onChange}
                                name={name}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                    {errors?.displayStartDate?.message || errors?.displayEndDate?.message ? (
                      <p className="validation-text">
                        {errors?.displayStartDate?.message || errors?.displayEndDate?.message}
                      </p>
                    ) : null}
                  </div>
                </section>
              </article>
            </div>
            {/* 버튼영역 */}
            <div className="contents-container__btn-wrap">
              <div className="left-area">
                <BaseButton
                  title="목록으로"
                  className="color-white size-large"
                  onClick={() => {
                    if (isChangedData()) {
                      setUncreateModal({
                        message: "작성중인 내용이 있습니다. 계속 할까요?",
                        isOpen: true,
                      });
                    } else {
                      if (noticeId) {
                        // 수정화면에서는 목록으로 바로이동
                        navigate(PagePath.notice.list);
                      } else {
                        // 등록화면에서는 목록에서 진입했기 때문에 뒤로가기
                        navigate(-1);
                      }
                    }
                  }}
                />
              </div>
              <div className="right-area">
                <BaseButton type="submit" title="저장" className=" size-large" />
              </div>
            </div>
          </form>
          {isContractSelectModalOpen.isOpen && (
            <ContractSelectModal
              onCanceled={() => setIsContractSelectModalOpen({ isOpen: false })}
              onAdded={onAddedContractSelectModal}
              defaultChecked={contract ? contract : []}
              contractStepFilter={INVITEABLE_CONTRACT_STEP}
            />
          )}
          {isBuildingSelectModalOpen.isOpen && (
            <BuildingSelectModal
              onCanceled={() => setIsBuildingSelectModalOpen({ isOpen: false })}
              onAdded={onAddedBuildingSelectModal}
              defaultChecked={buildings ? buildings : []}
            />
          )}
          {/* 취소, 확인 버튼이 있는 confirm 모달 */}
          {confirmModal.isOpen && (
            <BaseModal
              isOpen={confirmModal.isOpen}
              btnLeftTitle={
                confirmModal.type === "addNotice" || confirmModal.type === "editNotice"
                  ? "취소"
                  : "머무르기"
              }
              btnRightTitle={
                confirmModal.type === "addNotice" || confirmModal.type === "editNotice"
                  ? "확인"
                  : "나가기"
              }
              onClick={async () => {
                if (confirmModal.type === "addNotice") {
                  setConfirmModal({ isOpen: false });
                  const { data } = await postNotice({ notice: confirmModal.payload! });
                  if (data.data) {
                    navigate(PagePath.notice.list);
                  }
                } else if (confirmModal.type === "editNotice") {
                  setConfirmModal({ isOpen: false });
                  const { data } = await putNotice({
                    notice: confirmModal.payload!,
                    id: String(noticeId)!,
                  });
                  if (data.data) {
                    navigate(`${PagePath.notice.detail.replace(":id", String(noticeId))}`);
                  }
                } else if (confirmModal.type === "checkChanged") {
                  if (noticeId) {
                    setConfirmModal({ isOpen: false });
                    navigate(`${PagePath.notice.detail.replace(":id", String(noticeId))}`);
                  } else {
                    setConfirmModal({ isOpen: false });
                    navigate(`${PagePath.notice.list}`);
                  }
                }
              }}
              onClose={() => setConfirmModal({ isOpen: false })}
            >
              {confirmModal.message}
            </BaseModal>
          )}

          {/* 확인버튼만 있는 alert 모달 */}
          <BaseModal
            isOpen={alertModal.isOpen}
            btnRightTitle="확인"
            onClick={() => setAlertModal({ isOpen: false })}
          >
            <p>{alertModal.message}</p>
          </BaseModal>

          {/* 저장된것이 있을때 확인하는 모달 */}
          <BaseModal
            isOpen={uncreateModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={() => {
              if (noticeId) {
                // 수정화면에서는 목록으로 바로이동
                navigate(PagePath.notice.list);
              } else {
                // 등록화면에서는 목록에서 진입했기 때문에 뒤로가기
                navigate(-1);
              }
            }}
            onClose={() => setUncreateModal({ isOpen: false })}
          >
            <p>{uncreateModal.message}</p>
          </BaseModal>
        </div>
      </div>
    </div>
  );
};

export default NoticeForm;
