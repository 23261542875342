import React, { useCallback, useEffect, useState } from "react";
import {
  BuildingCommonFacilityDeskGroupModel,
  BuildingModel,
  CommonFacilityModel,
} from "src/api/building/building-types";
import { getEslProductDetailAsync } from "src/api/esl/esl-api";
import { EslProduct } from "src/api/esl/esl-types";
import { useApiOperation } from "src/api/hooks";
import { ProductModel } from "src/api/product/product-types";
import { BaseTooltip } from "src/components";
import ImagesDetail from "src/components/ImagesDetail";
import { numberToStringWithComma, showHourMinute } from "src/utils";

type Props = {
  productDetail?: ProductModel;
};

interface EslLabel extends EslProduct {
  locationCode: string; // 공간유형 채번
}

const DeskComp = ({ productDetail }: Props) => {
  // ESL 데이터 상세 조회 api
  const { executeAsync: getEslProductDetail } = useApiOperation(getEslProductDetailAsync, {
    doNotErrorHandleModal: true,
  });

  // 조회한 ESL 데이터 목록
  const [eslLabels, setEslLabels] = useState<Array<EslLabel>>([]);

  // esl 라벨 목록 조회
  const fetchAllLabels = useCallback(async (locationCodes: Array<string>) => {
    try {
      if (!locationCodes || locationCodes.length === 0) {
        throw Error("locationCodes.length === 0");
      }

      const eslLabels: Array<EslLabel> = [];
      for (let i = 0; i < locationCodes.length; i++) {
        const locationCode = locationCodes[i];
        const result = await getEslProductDetail({ productId: locationCode });
        if (result?.data?.data && result.status >= 200 && result.status <= 299) {
          if (result?.data?.data?.product) {
            // esl 라벨이 연동되어있을 경우만 조회됨
            eslLabels.push({
              ...result?.data?.data?.product,
              ...{
                locationCode,
              },
            });
          }
        }
      }

      setEslLabels(eslLabels);
    } catch (error) {
      // 에러 발생해도 무시
      console.debug("fetchAllLabels error", error);
    }
  }, []);

  // 수정시 api 에서 받아온 building 정보로 setValue 처리
  useEffect(() => {
    let desks: CommonFacilityModel[] = [];
    const deskList = productDetail?.buildingList?.map((item) =>
      item.buildingCommonFacility?.deskSpace?.deskGroupList?.map((desk) => desk.deskList),
    );

    if (deskList) {
      for (let j = 0; j < deskList.length; j++) {
        const deskArr = deskList[j];

        if (deskArr) {
          for (let k = 0; k < deskArr.length; k++) {
            deskArr[k]?.forEach((desk) => {
              desks.push(desk);
            });
          }
        }
      }

      if (desks) {
        // locaiotn code 로 라벨 목록 조회
        const locationCodes: Array<string> = [];
        for (let i = 0; i < desks.length; i++) {
          const locationCode = desks[i]?.locationCode;
          if (!!locationCode) {
            locationCodes.push(locationCode);
          }
        }
        if (locationCodes.length > 0) {
          fetchAllLabels(locationCodes);
        }
      }
    }
  }, [fetchAllLabels]);

  return (
    <div className="contents-container__divide-bottom">
      <section className="contents-container__grid contents-container__1200">
        <div className="contents-container__grid-index">
          <p className="">좌석 선택</p>
        </div>
        <div className="contents-container__grid-contents">
          {productDetail?.buildingList &&
            productDetail?.buildingList.map((building: BuildingModel) => {
              //
              const productBuilding = building;

              const deskGroup = building.buildingCommonFacility?.deskSpace;

              const checkDeskLength = deskGroup?.deskGroupList?.every(
                (item: BuildingCommonFacilityDeskGroupModel) => item.deskList?.length === 0,
              );

              if (!checkDeskLength)
                return (
                  <div key={productBuilding.id}>
                    <div className="pt20 ">
                      <div className="index-tab">
                        <span>{productBuilding.buildingName}</span>
                      </div>
                    </div>

                    {deskGroup?.deskGroupList &&
                      deskGroup.deskGroupList.map((room: BuildingCommonFacilityDeskGroupModel) => {
                        if (room.deskList && room.deskList?.length > 0)
                          return (
                            <div key={room.id} className="border-gray">
                              <div className="pa16">
                                <div className="flex-center mb10">
                                  <div className="minmax140">
                                    <span>위치</span>
                                  </div>
                                  <span>
                                    {`${room.isGround ? "지상" : "지하"} ${room.floorNum}`}층
                                  </span>
                                </div>
                                <div className="flex-center mb10">
                                  <div className="minmax140">
                                    <span>좌석 그룹 명</span>
                                  </div>
                                  <span>{room.groupName}</span>
                                </div>
                                <div className="flex-center">
                                  <div className="minmax140">
                                    <span>설명</span>
                                  </div>
                                  <span>{room.description}</span>
                                </div>
                              </div>
                              <table className="inner-table" width="100%">
                                <thead>
                                  <tr>
                                    <th>
                                      <span>No</span>
                                    </th>
                                    <th>
                                      <span>좌석 명</span>
                                    </th>
                                    <th>
                                      <span>ESL</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {room.deskList &&
                                    room.deskList.map((desk: CommonFacilityModel, idx: number) => {
                                      const findLabel = eslLabels.find(
                                        (eslLabel: EslLabel) =>
                                          eslLabel.locationCode === desk.locationCode,
                                      );
                                      return (
                                        <tr key={desk.id}>
                                          <td>
                                            <div className="flex-center-center no-wrap">
                                              <span>{idx + 1}</span>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="flex-center-center no-wrap">
                                              <span>{desk.facilityName}</span>
                                            </div>
                                          </td>
                                          <td width="100">
                                            <div className="flex-center-center no-wrap">
                                              <div
                                                className={
                                                  findLabel === undefined ? "not-available" : ""
                                                }
                                              >
                                                {findLabel !== undefined ? (
                                                  <BaseTooltip
                                                    contents={"입력"}
                                                    tooltip={(
                                                      findLabel?.assignedLabelCodes || []
                                                    ).join(",")}
                                                    type={"normal"}
                                                  />
                                                ) : (
                                                  <span>미입력</span>
                                                )}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          );
                      })}
                    <div className="contents-container__grid-contents mb30">
                      {deskGroup?.mediaList && deskGroup.mediaList.length > 0 ? (
                        <>
                          <div className="contents-container__grid-index mt20 mb10">
                            <p>배치도</p>
                          </div>
                          <ImagesDetail images={deskGroup.mediaList} />
                        </>
                      ) : (
                        <>
                          <div className="contents-container__grid-index mt20 mb10">
                            <p>배치도</p>
                          </div>
                          <p className="font13">현재 등록된 배치도가 없습니다</p>
                        </>
                      )}
                    </div>
                  </div>
                );
            })}
        </div>
      </section>
      {/* Court 소개 내용 */}
      <section className="contents-container__grid contents-container__1200">
        <div className="contents-container__grid-index">
          <p>Court 소개 내용</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <div className="minmax140">
              <span>설명</span>
            </div>

            <p>{productDetail?.deskDescription}</p>
          </div>
        </div>
      </section>
      {/* 예약 설정 */}
      <section className="contents-container__grid contents-container__1200">
        <div className="contents-container__grid-index">
          <p className="">Taap 예약 설정</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start mb10">
            <div className="minmax140">
              <span>총 예약 시간/월</span>
            </div>

            <p>{productDetail?.deskMaxReservTimeMonth !== -1 ? "제한있음" : "제한없음"}</p>

            {productDetail?.deskMaxReservTimeMonth !== -1 && (
              <>
                <span className="font13 text-primary5 mr10">{`( 매월 총`}</span>
                <div className="">
                  <p>{numberToStringWithComma(productDetail?.deskMaxReservTimeMonth)}</p>
                </div>
                <span className="font13 text-primary5 ml10">{`분 [${showHourMinute(
                  String(productDetail?.deskMaxReservTimeMonth),
                )}] 예약 가능)`}</span>{" "}
              </>
            )}
          </div>
          <div className="flex-row flex-center-start">
            <div className="minmax140">
              <span>예약 최대 시간/일</span>
            </div>

            <p>{productDetail?.deskMaxReservTimeDay !== -1 ? "제한있음" : "제한없음"}</p>

            {productDetail?.deskMaxReservTimeDay !== -1 && (
              <>
                <span className="font13 text-primary5 mr10">{`( 매월 총`}</span>
                <div className="">
                  <p>{numberToStringWithComma(productDetail?.deskMaxReservTimeDay)}</p>
                </div>
                <span className="font13 text-primary5 ml10">{`분 [${showHourMinute(
                  String(productDetail?.deskMaxReservTimeDay),
                )}] 예약 가능)`}</span>{" "}
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default DeskComp;
