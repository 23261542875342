import React from "react";
import { Cell } from "react-table";
import { ProductModel } from "src/api/product/product-types";
import { getKoreanCost } from "src/utils";

const ProductRentalCostCell = (props: Cell<ProductModel>) => {
  const rental = props?.row.original?.productRentValue1;

  return (
    <div>
      <span>{getKoreanCost(Number(rental))}</span>
      <span> / </span>
      {props.row.original.productRentType === "PRODUCT_RENT_MONTH_1" ? (
        <span>1개월</span>
      ) : (
        <span>10분</span>
      )}
    </div>
  );
};

export default ProductRentalCostCell;
