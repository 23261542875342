import { useAuth0 } from "@auth0/auth0-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { clipboard } from "@toss/utils";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Memo, ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { getMediaFileListAsync } from "src/api/file/file-api";
import { MediaFile } from "src/api/public-types";
import useAdminMemoState from "src/recoil/adminMemo/hooks";
import { useModal } from "src/recoil/modalState/hook";

import { BaseTooltip } from "../BaseTooltip";
import useAdminMemo from "../adminMemoIntegration/useAdminMemo";
import BaseFileUpload from "../mediaFile/BaseFileUpload";
import AdminMemoFormV3 from "./AdminMemoFormV3";
import { useToast } from "src/recoil/toast/hook";
import { useApiOperation } from "src/api/hooks";
import { useLocation } from "react-router-dom";

type Props = {
  memo: Memo;
  partnerId: string;
};

const AdminMemoListV3 = ({ memo, partnerId }: Props) => {
  const location = useLocation();

  const [medias, setMedias] = useState<MediaFile[]>([]);
  const { user } = useAuth0();
  // 편집 삭제 가능 유무(작성자 본인의 메모만 삭제/수정가능)
  const editAllowed = user?.email === memo.createdBy;
  const { globalIsEdit, setGlobalIsEdit } = useAdminMemoState();
  const { remove } = useAdminMemo();
  const [isMemoListItemEdit, setIsMemoListItemEdit] = useState(false);
  const { setBaseModal } = useModal();
  const queryCache = useQueryClient();
  const { openToast } = useToast();

  useEffect(() => {
    //언마운트 시점에 setGlobalIsEdit(false);
    return () => {
      if (isMemoListItemEdit) {
        setGlobalIsEdit(false);
      }
    };
  }, [isMemoListItemEdit]);

  useEffect(() => {
    //globalIsEdit가 false 라면 편집창 닫기
    if (!globalIsEdit) {
      setIsMemoListItemEdit(false);
    }
  }, [globalIsEdit]);

  // text 내부에서 url를 추출해서 a 태그로 변환
  // url 끝 공백 전까지 추출
  const memoTextInnerHTML =
    memo.memo?.replace(
      /(https:\/\/\S+)/g,
      (url: any) => `<a style={{color:#007df0;}} href="${url}" target="_blank">${url}</a>`,
    ) ?? "-";

  // 파일 목록
  const { executeAsync: getMediaFileList } = useApiOperation(getMediaFileListAsync);

  /** 첨부파일 목록 api*/
  useQuery({
    queryKey: ["getAdminMemoFile", String(memo.id)],
    queryFn: async () =>
      getMediaFileList({
        contentsList: [{ serviceId: Number(memo.id), serviceType: ServiceTypes.SERVICE_ADMINMEMO }],
      }).then((res) => {
        setMedias(res.data.data.content);
        return res;
      }),
    enabled: !!memo.id,
  });

  const handleRemoveMemo = async (memo: Memo) => {
    await remove([Number(memo.id)]);

    //어드민 메모 목록 재검증
    queryCache.invalidateQueries({
      queryKey: ["getAdminMemoList"],
    });
  };

  const hashLinkCopy = async (id: string) => {
    const href = window.location.href;
    let result = false;

    if (href.includes("#")) {
      result = await clipboard.writeText(
        window.location.href.substring(0, window.location.href.indexOf("#")) + "#" + id,
      );
    } else {
      result = await clipboard.writeText(window.location.href + "#" + id);
    }

    if (result) {
      openToast({ content: "링크를 복사했습니다", isSystemToast: true });
    } else {
      openToast({ content: "링크 복사를 실패했습니다", isSystemToast: true });
    }
  };

  return (
    <>
      {!isMemoListItemEdit && (
        <div
          id={memo.id?.toString()}
          className={`memo-row BD6 py20 w-full`}
          style={{ background: location.hash === "#" + String(memo.id) ? "#f8f8fa" : "" }}
        >
          <div className="minmax660 d-flex justify-contents-between linkWarp pr10">
            <div className="d-flex flex-column gap10 minmax620">
              <div>
                <span
                  className="pre-formatted innerUrl"
                  dangerouslySetInnerHTML={{ __html: memoTextInnerHTML }}
                />
                {memo.isCentralOnly === false && (
                  <span
                    style={{
                      width: 59,
                      height: 24,
                      background: "#26A68712",
                      fontSize: 12,
                      color: "#26A687",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: 10,
                    }}
                  >
                    Ctrlroom
                  </span>
                )}
              </div>

              <BaseFileUpload
                mode="memo"
                isOnlyView={true}
                limit={10}
                maxWidth={555}
                fileList={medias ?? []}
                containerClass={clsx({
                  "add-file": medias.length > 0,
                  "d-none": medias.length === 0,
                })}
              />
            </div>

            <BaseTooltip
              // type="normal"
              tooltip="메모 링크 복사"
              touchIcon={
                <div
                  onClick={() => hashLinkCopy(String(memo.id))}
                  className="ic-link cursor-pointer"
                ></div>
              }
            />
          </div>
          <div className="minmax80">
            <BaseTooltip contents={memo.id?.toString() ?? "-"} />
          </div>
          <div className="minmax160 text-center">
            <BaseTooltip contents={memo.modifiedBy ?? "-"} />
          </div>
          <div className="minmax80 text-center">
            <BaseTooltip contents={memo.modifiedDate ?? "-"} type="date" />
          </div>
          {editAllowed && (
            <div
              className="base-edit-btn"
              style={{ width: 20, height: 20, minWidth: 20 }}
              onClick={() => {
                if (globalIsEdit === false) {
                  setGlobalIsEdit(true);
                  setIsMemoListItemEdit(true);
                } else {
                  setBaseModal({
                    isOpen: true,
                    title: `이 메모가 지워집니다.\n그래도 취소하겠습니까?`,
                    btnLeftTitle: "취소",
                    btnRightTitle: "확인",
                    onClick: () => {
                      setGlobalIsEdit(() => false);
                      setBaseModal({ isOpen: false });
                    },
                  });
                }
              }}
            ></div>
          )}

          {editAllowed && (
            <div
              onClick={() =>
                setBaseModal({
                  isOpen: true,
                  title: "이 메모를 삭제하시겠습니까?",
                  btnLeftTitle: "취소",
                  btnRightTitle: "확인",
                  onClick: async () => {
                    await handleRemoveMemo(memo);
                    setBaseModal({ isOpen: false });
                  },
                })
              }
              className="base-trash-btn color-bg-gray"
              style={{ width: 20, height: 20, minWidth: 20 }}
            ></div>
          )}
        </div>
      )}

      {isMemoListItemEdit && (
        <div className="input-warp">
          <AdminMemoFormV3
            partnerId={partnerId}
            serviceId={+memo.serviceId}
            serviceType={memo.serviceType as any}
            isMemoListItemEdit={isMemoListItemEdit}
            setIsMemoListItemEdit={setIsMemoListItemEdit}
            memoId={memo.id?.toString()}
            memo={memo.memo}
            medias={medias}
            isCentralOnly={memo.isCentralOnly}
          />
        </div>
      )}
    </>
  );
};

export default AdminMemoListV3;
