import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { BaseModal } from "src/components";
import ScheduleInfoColumns from "./columns/OverdueInfoColumns";

const OverdueInfo = ({
  callList,
  contractBaseInfo,
  basicInfo,
  overdueList,
  setLoadingBar,
  scheduleId,
}: any) => {
  const params = useParams();
  const [checkTax, setCheckTax] = useState(false);
  useEffect(() => {
    if (overdueList.length > 0) {
      overdueList.forEach((overdue: any) => {
        // overdue.taxAmount = 1;
        if (overdue.taxAmount && Number(overdue.taxAmount) > 0) {
          setCheckTax(true);
        }
      });
    }
  }, [overdueList]);

  // const navigate = useNavigate();
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } =
    useTable(
      {
        columns: ScheduleInfoColumns,
        data: overdueList,
        callList,
        contractBaseInfo,
        setLoadingBar,
        basicInfo,
        scheduleId,
      },
      useBlockLayout,
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect,
      useSticky,
    );
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  return (
    <article className="pb20">
      <div>
        <div className="contents-container__sub-title">
          <h2>연체료 빌링 현황</h2>
        </div>
        <div className="mt20">
          <div {...getTableProps()} className="base-table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                        {/* <div className="ic_sort"></div> */}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()} className="body">
              {rows.map((row: any) => {
                prepareRow(row);
                return (
                  <div
                    {...row.getRowProps()}
                    className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                  >
                    {row.cells.map((cell: any) => {
                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              {rows.length === 0 && (
                <div className="base-table__tr" style={{ width: tableWidth }}>
                  <div className="base-table__td w-100 text-center">
                    <div className="w-100">
                      <span>데이터가 없습니다.</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              {overdueList &&
                overdueList.length > 0 &&
                footerGroups.map((group) => {
                  return (
                    <div {...group.getFooterGroupProps()}>
                      {group.headers.map((column) => (
                        <div
                          {...column.getFooterProps()}
                          className="base-table__td base-table-footer"
                        >
                          {column.render("Footer")}
                        </div>
                      ))}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <BaseModal isOpen={checkTax} btnRightTitle={"확인"} onClick={() => setCheckTax(false)}>
          <div>
            <p>연체현황에 부가세가 0이 아닌 데이터가 있습니다.</p>
            <p>관리자에게 문의하세요 [계약 ID: {params?.id} ]</p>
          </div>
        </BaseModal>
      </div>
    </article>
  );
};

export default OverdueInfo;
