import moment from "moment";
import { forwardRef, useEffect } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import { BaseInput, BaseDatePicker } from "src/components";
import { Partner } from "src/types/partner";

interface IPartnerForm {
  control: Control<Partner>;
  minDate?: string;
  maxDate?: string;
}

type Ref = HTMLFormElement;

const PARTNER_ADD_FORM_MAP = {
  code: {
    label: "파트너 코드",
    render: (data: IPartnerForm) => (
      <Controller
        control={data.control}
        render={({ field, fieldState }) => (
          <BaseInput
            value={field.value}
            onChange={field.onChange}
            errorText={fieldState.error?.message}
          />
        )}
        name={"code"}
        rules={{
          required: {
            value: true,
            message: "필수입력항목입니다.",
          },
          pattern: {
            value: /^[a-zA-Z0-9_-]{0,20}$/,
            message: "영문, 숫자, 특수문자(-, _)만 입력 가능합니다. (20자 이내)",
          },
        }}
      />
    ),
    required: true,
  },
  name: {
    label: "파트너 명",
    render: (data: IPartnerForm) => (
      <Controller
        control={data.control}
        render={({ field, fieldState }) => (
          <BaseInput
            value={field.value}
            onChange={field.onChange}
            errorText={fieldState.error?.message}
          />
        )}
        name={"name"}
        rules={{
          required: {
            value: true,
            message: "필수입력항목입니다.",
          },
          pattern: {
            value: /^.{0,30}$/,
            message: "30자 이내로 입력해주세요.",
          },
        }}
      />
    ),
    required: true,
  },
  useStartDate: {
    label: "이용 시작일",
    render: (data: IPartnerForm) => (
      <Controller
        control={data.control}
        render={({ field, fieldState }) => {
          return (
            <BaseDatePicker
              setDate={field.onChange}
              selectedDate={field.value ? moment(field.value).toDate() : undefined}
              errorText={fieldState.error?.message}
              maxDate={data.maxDate ? moment(data.maxDate).toDate() : undefined}
            />
          );
        }}
        name={"useStartDate"}
        rules={{
          required: {
            value: true,
            message: "필수입력항목입니다.",
          },
        }}
      />
    ),
    required: true,
  },
  useEndDate: {
    label: "이용 종료일",
    render: (data: IPartnerForm) => (
      <Controller
        control={data.control}
        render={({ field, fieldState }) => {
          return (
            <BaseDatePicker
              setDate={field.onChange}
              selectedDate={field.value ? moment(field.value).toDate() : undefined}
              errorText={fieldState.error?.message}
              minDate={data.minDate ? moment(data.minDate).toDate() : undefined}
            />
          );
        }}
        name={"useEndDate"}
        rules={{
          required: {
            value: true,
            message: "필수입력항목입니다.",
          },
        }}
      />
    ),
    required: true,
  },
  contractUrl: {
    label: "계약서 URL",
    render: (data: IPartnerForm) => (
      <Controller
        control={data.control}
        render={({ field, fieldState }) => (
          <BaseInput
            value={field.value}
            onChange={field.onChange}
            errorText={fieldState.error?.message}
          />
        )}
        name={"contractUrl"}
        rules={{
          pattern: {
            value: /^(https?:\/\/[^\s]{1,200})$/,
            message: "URL 형식이 올바르지 않습니다. (http:// 또는 https://로 시작해야 합니다.)",
          },
        }}
      />
    ),
    required: false,
  },
};

interface IProps {
  onSubmit: (data: Partner) => void;
  defaultValue?: Partner;
}
function PartnerForm({ onSubmit, defaultValue }: IProps, ref: React.Ref<Ref>) {
  const { control, handleSubmit, watch, setValue } = useForm<Partner>();

  useEffect(() => {
    setValue("code", defaultValue?.code || "");
    setValue("name", defaultValue?.name || "");
    setValue("useStartDate", defaultValue?.useStartDate || "");
    setValue("useEndDate", defaultValue?.useEndDate || "");
    setValue("contractUrl", defaultValue?.contractUrl || "");
  }, [defaultValue]);

  const minDate = watch("useStartDate");
  const maxDate = watch("useEndDate");

  return (
    <form className="contents-container__wrap-contents" onSubmit={handleSubmit(onSubmit)} ref={ref}>
      <h2>파트너 정보</h2>
      {Object.keys(PARTNER_ADD_FORM_MAP).map((key) => {
        const section = PARTNER_ADD_FORM_MAP[key as keyof typeof PARTNER_ADD_FORM_MAP];

        return (
          <section className="contents-container__grid" key={key}>
            <div className="contents-container__grid-index">
              <p className={`${section.required && "required"}`}>{section.label}</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                {section.render({
                  control,
                  minDate: key === "useEndDate" ? minDate : undefined,
                  maxDate: key === "useStartDate" ? maxDate : undefined,
                })}
              </div>
            </div>
          </section>
        );
      })}
    </form>
  );
}
export default forwardRef<Ref, IProps>(PartnerForm);
