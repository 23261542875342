import { filter } from "lodash";

import { forwardRef, useEffect, useRef, useState } from "react";
import {
  HeaderGroup,
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { Order } from "src/api/public-types";
import { BasePagination } from "./BasePagination";

interface Props {
  data: Array<any>;
  columns: Array<any>;
  pageIndex: number;
  totalPages: number;
  goPage: Function;
  setOrders: Function;
  orders?: Array<Order>;
  disabledSortHeaders?: Array<string>; // 정렬이 불가능한 컬럼이 있을 경우 header accesor 값 배열로 전달
  selected?: any;
  wasSeleted?: any;
  useMultiSelect?: boolean;
  useSingleSelect?: boolean;
}

const PAGE_SIZE = 20;

export const BaseTableWithSelect = (props: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      initialState: { pageSize: PAGE_SIZE },
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
    (hooks) => {
      // console.log('props.useMultiSelect',props.useMultiSelect,'/ props.useSingleSelect',props.useSingleSelect);
      if (props.useMultiSelect !== undefined && props.useMultiSelect !== false) {
        hooks.visibleColumns.push((columns: any) => [
          // Let's make a column for selection
          {
            id: "selectionCheck",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox id={"checkboxHeader"} {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox id={row.id} {...row.getToggleRowSelectedProps()} />
              </div>
            ),
            width: 100,
          },
          ...columns,
        ]);
      }
      // console.log('여키 탑니까?useMultiSelect')

      if (props.useSingleSelect !== undefined && props.useSingleSelect === true) {
        hooks.visibleColumns.push((columns: any) => [
          // Let's make a column for selection
          {
            id: "selectionRadio",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }) => {
              let currentState = row.getToggleRowSelectedProps();
              // console.log('currentState',currentState);
              const org: any = row.original;

              if (props?.wasSeleted.id === org.id) {
                // console.log('props?.wasSeleted',props?.wasSeleted,'row.original',row.original);
                currentState.checked = true;
              }
              return (
                <IndeterminateRadiobox
                  {...currentState}
                  id={row.id}
                  onClick={() => {
                    //모든 라디오 초기화
                    toggleAllRowsSelected(false);
                    //현재 선택한 것 true
                    toggleRowSelected(row.id, !currentState.checked);
                    // console.log("v", row.original);
                    props.selected(row.original);
                  }}
                />
              );
            },
            width: 100,
          },
          ...columns,
        ]);
      }
    },
  );

  const IndeterminateRadiobox = forwardRef(({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        {props.useSingleSelect && (
          // <input type="radio" className="d-block" ref={resolvedRef} {...rest} />
          <div className={`d-flex`}>
            <input
              id={rest.id}
              type="radio"
              name="test"
              ref={resolvedRef}
              disabled={false}
              {...rest}
            ></input>
            <label htmlFor={rest.id} className="d-flex">
              <div className="base-radio"></div>
            </label>
          </div>
        )}
      </>
    );
  });

  const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        {props.useMultiSelect && (
          // <input type="checkbox" className="d-block" ref={resolvedRef} {...rest} />
          <div className={`d-flex`}>
            <input
              id={rest.id}
              type="checkbox"
              name="test"
              ref={resolvedRef}
              disabled={false}
              {...rest}
            ></input>
            <label htmlFor={rest.id} className="d-flex">
              <div className="base-checkbox"></div>
            </label>
          </div>
        )}
      </>
    );
  });

  // 정렬이 불가능한 header 인지 여부
  const isDisabledSortHeader = (header: HeaderGroup): boolean => {
    let isDisabledSortHeader = false;
    if (props?.disabledSortHeaders && props?.disabledSortHeaders.length > 0) {
      isDisabledSortHeader = props.disabledSortHeaders.includes(header.id);
    }
    return isDisabledSortHeader;
  };

  // header sort component
  const renderHeaderSortComponent = (header: HeaderGroup) => {
    if (isDisabledSortHeader(header)) return null;

    if (props?.orders && props.orders && props.orders.length > 0) {
      const finedHeader = props.orders.find(
        (order: { property: string; direction: string }) => order.property === header.id,
      );
      if (finedHeader) {
        if (finedHeader.direction === "ASC") {
          return <div className="ic_sort down"></div>;
        } else if (finedHeader.direction === "DESC") {
          return <div className="ic_sort up"></div>;
        }
      }
    }
    return <div className="ic_sort"></div>;
  };

  const clickHeaderSort = (header: HeaderGroup) => {
    // 새로운 정렬
    let newOrders = props.orders || [];

    if (props?.orders && props.orders && props.orders.length > 0) {
      const finedHeader = props.orders.find(
        (order: { property: string; direction: string }) => order.property === header.id,
      );

      if (finedHeader) {
        // 기존의 정렬 삭제
        newOrders = filter(props.orders, function (currentObject) {
          return currentObject.property !== finedHeader.property;
        });
      }

      let newDirection = null;
      if (finedHeader?.direction) {
        // 기존 정렬 방향이 있으면
        if (finedHeader?.direction === "DESC") {
          // 내림차순이면 오름차순으로 변경
          newDirection = "ASC";
        } else {
          // 오름차순이면 정렬 해제이기 때문에 기존 정렬삭제되서 처리할게 없음
        }
      } else {
        // 기존 정렬 방향이 없으면 내림차순으로 변경
        newDirection = "DESC";
      }

      if (newDirection) {
        newOrders = [...newOrders, { property: header.id, direction: newDirection }];
      }
    } else {
      // 기존정렬이 없으면 해당 header 내림차순으로 변경
      newOrders = [{ property: header.id, direction: "DESC" }];
    }
    props.setOrders(newOrders);
  };

  return (
    <div className="contents-container__table">
      <div {...getTableProps()} className="base-table sticky">
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
              {headerGroup.headers.map((header) => {
                return (
                  <div
                    {...header.getHeaderProps(
                      header.id !== "rowChecked" ? header.getSortByToggleProps() : undefined,
                    )}
                    className="base-table__th"
                    onClick={() => {
                      if (!isDisabledSortHeader(header)) {
                        clickHeaderSort(header);
                      }
                    }}
                  >
                    {header.render("Header")}
                    <span>{renderHeaderSortComponent(header)}</span>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="body">
          {page.map((row) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="base-table__tr">
                {row.cells.map((cell) => {
                  return (
                    <div {...cell.getCellProps()} className="base-table__td">
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <BasePagination
        pageIndex={props.pageIndex}
        totalPages={props.totalPages}
        goPage={(pageIndex: number) => {
          if (props.goPage) {
            props.goPage(pageIndex);
          }
        }}
      />
    </div>
  );
};
