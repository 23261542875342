import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Cell } from "react-table";
import { MgmtOfficeModel, MgmtOfficerType } from "src/api/building/building-types";
import {
  editBuildingManagementAsync,
  postBuildingManagementAsync,
} from "src/api/building/management-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseCheckbox, BaseInput, BaseModal } from "src/components";
import { Modal, RoleType, UnionOfficerType } from "src/pages/building/building-types";
import { useLoadingBarContext, useToastContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { onlyNumber } from "src/utils";
import { FrontMgmtOfficeModel, FrontMgmtOfficerType, officerTypeList } from "../management-type";
import OfficerViewTable from "./officer-table/OfficerViewTable";

type Props = {
  managementDetail?: MgmtOfficeModel;
  frontOriginalData?: FrontMgmtOfficeModel;
  buildingId?: string;
  getBuildingManagement: Function;
};

const ManagementOfficeManagerSection = ({
  managementDetail,
  frontOriginalData,
  buildingId,
  getBuildingManagement,
}: Props) => {
  //
  const { setLoadingBar } = useLoadingBarContext();
  // 토스트
  const { openToast } = useToastContext();

  const { openErrorModal } = useErrorModalContext();

  // 경고 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 컨펌 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  // 관리처 관리 등록
  const { executeAsync: postBuildingManagement } = useApiOperation(postBuildingManagementAsync);

  // 관리처 관리 수정
  const { executeAsync: editBuildingManagement } = useApiOperation(editBuildingManagementAsync);

  // useForm default
  const defaultValues = useMemo(() => {
    const officeData: FrontMgmtOfficeModel = {
      officerList: [
        {
          name: "",
          phone: "",
          isPrimary: true,
          roleType: "ROLE_MANAGER",
          officerTypeList: [],
          description: "",
          cmdType: "C",
        },
      ],
    };

    return officeData;
  }, []);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm<FrontMgmtOfficeModel>({
    defaultValues: defaultValues,
  });
  // 업무파트 convert
  const getOfficeWorkType = useCallback((workType: string) => {
    if (workType) {
      for (let i = 0; i < officerTypeList.length; i++) {
        const type = officerTypeList[i];
        if (workType === type.value) {
          return type.label;
        }

        if (workType === "OFFICER_UNRECOGNIZED") {
          return "미선택";
        }
      }
    }
  }, []);

  const temp = getValues();

  // 담당자 columns
  const OfficerColumns: any[] = useMemo(() => {
    return [
      {
        Header: "이름",
        accessor: "name",
        width: 120,
      },

      {
        Header: "연락처",
        accessor: "phone",
        width: 120,
        Cell: ({ value }: Cell<MgmtOfficerType>) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "매니저",
        accessor: "roleType",
        width: 120,
        Cell: ({ row, value }: Cell<MgmtOfficerType>) => {
          const isPrimary = row.original.isPrimary;

          return (
            <div className="flex-center-center">
              {isPrimary && (
                <BaseCheckbox id={"b"} name={"b"} className="chip-case mr4" label="대표" disabled />
              )}

              {value === RoleType.MANAGER ? (
                <BaseCheckbox id={"b2"} name={"b2"} className="chip-case" label="매니저" disabled />
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      {
        Header: "업무파트",
        accessor: "officerTypeList",
        width: 250,
        Cell: ({ row, value }: Cell<MgmtOfficerType>) => {
          const workTypes = value;

          return workTypes.map((type: string) => {
            return (
              <div key={type}>
                <BaseCheckbox
                  id={``}
                  name={``}
                  className="chip-case mr4"
                  label={getOfficeWorkType(type)}
                  disabled
                />
              </div>
            );
          });
        },
      },
      {
        Header: "세부 업무내용",
        accessor: "description",
        width: 250,
      },
    ];
  }, [getOfficeWorkType, setValue]);
  //

  useEffect(() => {
    // 연락처 목록에 대표가 이미 있을 시 , 등록 폼의 대표 false
    if (managementDetail?.officerList) {
      const officeList = managementDetail.officerList;
      const isPrimary = officeList.some((item) => item.isPrimary === true);
      if (isPrimary) {
        setValue("officerList.0.isPrimary", false);
        setValue("officerList.0.roleType", "ROLE_USER");
      }
    }
  }, [managementDetail, setValue]);

  // 유효성 확인
  const formValidation = useCallback(() => {
    let requiredMessage = "필수입력 항목입니다";
    // 연락처 패턴
    const patterns = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/;

    // 담당자 유효성
    register(`officerList`, {
      validate: {
        required: (officers?: MgmtOfficerType[]) => {
          let result = true;
          let message = requiredMessage;

          // 기존 담당자중 대표여부 확인
          const primaryOfficer = managementDetail?.officerList?.find(
            (officer: MgmtOfficerType) => officer.isPrimary === true,
          );

          officers?.forEach((officer) => {
            // 이름 유효성
            if (!officer?.name) {
              result = false;
            } else if (!officer?.phone) {
              result = false;
            } else if (!patterns.test(String(officer?.phone))) {
              result = false;
              message = "휴대폰 번호 형식에 맞게 입력해주세요.";
            } else if (!primaryOfficer?.isPrimary && !officer?.isPrimary) {
              result = false;
              message = "1명 이상의 대표 매니저가 필요합니다.";
            } else if (officer.isPrimary === true && officer.roleType !== "ROLE_MANAGER") {
              result = false;
              message = "대표 선택 시 매니저 선택은 필수입니다.";
            } else if (
              officer?.isPrimary === false &&
              officer?.roleType === "ROLE_USER" &&
              officer?.officerTypeList?.length === 0
            ) {
              result = false;
              message = "업무파트를 선택해주세요.";
            }
          });

          return result || message;
        },
      },
    });
  }, [managementDetail?.officerList, register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  // 수정 데이터 만들기
  const makeRequestEditData = useCallback(
    (data: FrontMgmtOfficeModel) => {
      // 인자로 넘어온 data와 original 데이터를 비교해서 변경된 것만 editData에 추가
      const original = frontOriginalData;

      let editData = {};

      const isOfficerList = _.isEqual(original?.officerList, data.officerList);

      const officerList = data.officerList;

      // 담당자 수정부분
      if (!isOfficerList) {
        editData = {
          ...editData,
          officerList: officerList?.map((item: FrontMgmtOfficerType) => {
            let officerData = { ...item };
            // 등록 또는 삭제시 item 리턴

            if (officerData.cmdType === "C" || officerData.cmdType === "D") {
              if (officerData.officerTypeList && officerData.officerTypeList.length < 1) {
                return (officerData = {
                  ...officerData,
                  officerTypeList: ["OFFICER_UNRECOGNIZED"],
                });
              }

              return officerData;
            }
            // 수정 시
            else {
              // 매니저 선택 ok / 업무선택 미선택 시 "OFFICER_UNRECOGNIZED" 전달
              if (officerData.officerTypeList && officerData.officerTypeList.length < 1) {
                return (officerData = {
                  ...officerData,
                  officerTypeList: ["OFFICER_UNRECOGNIZED"],
                  cmdType: "U",
                });
              }
              // 업무파트 미선택이었는데  선택후 저장시 "OFFICER_UNRECOGNIZED" 삭제
              else if (officerData.officerTypeList && officerData.officerTypeList.length > 1) {
                return (officerData = {
                  ...officerData,
                  officerTypeList: officerData.officerTypeList.filter(
                    (value) => value !== "OFFICER_UNRECOGNIZED",
                  ),
                  cmdType: "U",
                });
              }

              return { ...officerData, cmdType: "U" };
            }
          }),
        };
      }
      return editData;
    },
    [frontOriginalData],
  );

  // 컨펌모달 저장 클릭시 등록/수정
  const onClickConfirmModal = useCallback(
    async (data: FrontMgmtOfficeModel) => {
      //
      setLoadingBar(true);
      // id null 이면 신규등록 / 아니면 수정
      if (managementDetail?.id === null) {
        const postData: MgmtOfficeModel = {
          officerList: data?.officerList?.map((office) => {
            // 관리처 등록시 업무파트 미선택이면 "OFFICER_UNRECOGNIZED" 추가
            if (office.officerTypeList && office.officerTypeList.length < 1) {
              return { ...office, officerTypeList: ["OFFICER_UNRECOGNIZED"] };
            }
            return office;
          }),
        };

        console.log("postData", postData);

        // 등록
        const response = await postBuildingManagement({ office: postData });
        if (response.status >= 200 && response.status <= 200) {
          openToast(`정상적으로 ${"등록"}되었습니다.`);
          setConfirmModal({ isOpen: false });
        }
      } else {
        // 수정 api 통신
        const editData = makeRequestEditData(data);
        const response = await editBuildingManagement({
          id: String(managementDetail?.id),
          office: editData,
        });
        if (response.status >= 200 && response.status <= 200) {
          openToast(`정상적으로 ${"수정"}되었습니다.`);
          setConfirmModal({ isOpen: false });
        } else {
          const errorCode = response.data.meta.errorCode;
          if (errorCode && errorCode === "ePR0517") {
            openErrorModal("진행중인 워크시트가 있습니다.", errorCode);
          }
        }
      }
      reset();
      await getBuildingManagement(String(buildingId));

      setLoadingBar(false);
    },
    [
      buildingId,
      editBuildingManagement,
      getBuildingManagement,
      makeRequestEditData,
      managementDetail?.id,
      postBuildingManagement,
    ],
  );

  // 유효성 검사후 저장
  const onSubmit = useCallback(
    async (data?: FrontMgmtOfficeModel, e?: any) => {
      e.preventDefault();

      const officerList = managementDetail?.officerList?.filter((item) => item.isDeleted === false);
      // 경고창 모달 부분

      // 새로 등록된 담당자
      const willBeOfficer: FrontMgmtOfficerType = data?.officerList![0] || {};

      // 기존 등록된 연락처
      const havePhoneOfficer = officerList?.find((item) => item.phone === willBeOfficer.phone);

      // 새로 등록될 연락처와 기존에 등록된 연락처가 겹칠경우
      if (
        willBeOfficer.phone === havePhoneOfficer?.phone &&
        willBeOfficer.id !== havePhoneOfficer?.id
      ) {
        setAlertModal({ isOpen: true, message: "이미 등록된 연락처입니다." });
        return;
      }

      //

      if (data?.officerList)
        setConfirmModal({ isOpen: true, message: "저장하시겠습니까?", payload: data });
    },
    [managementDetail?.officerList],
  );

  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);

  return (
    <article className="contents-container__divide-top">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>담당자</h2>
          </div>
          <table className="inner-table" width="100%">
            <thead>
              <tr>
                <th>
                  <div>
                    <span className="required">이름(닉네임)</span>
                  </div>
                </th>
                <th>
                  <span className="required">연락처</span>
                </th>
                <th>
                  <span className="required">매니저</span>
                </th>
                <th>
                  <span className="required">업무파트</span>
                </th>
                <th>
                  <span className="no-wrap">세부 업무내용</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {defaultValues.officerList?.map((office: FrontMgmtOfficerType, officeIdx: number) => (
                <tr key={office.cmdType}>
                  <td width="120px">
                    <div className="flex-center-center">
                      <Controller
                        control={control}
                        name={`officerList.0.name`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseInput
                              onChange={(text: string) => onChange(text)}
                              value={value}
                              name={name}
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </td>
                  <td width="120px">
                    <div className="flex-center-center">
                      <Controller
                        control={control}
                        name={`officerList.0.phone`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseInput onChange={onChange} value={onlyNumber(value)} name={name} />
                          );
                        }}
                      ></Controller>
                    </div>
                  </td>
                  <td width="120px">
                    <div className="flex-center-center">
                      <Controller
                        control={control}
                        name={`officerList.0.isPrimary`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseCheckbox
                              id={name}
                              name={`${name}0`}
                              className="chip-case mr4"
                              label="대표"
                              checked={value}
                              onChange={(checked: boolean) => {
                                onChange(checked);
                              }}
                            />
                          );
                        }}
                      ></Controller>

                      <Controller
                        control={control}
                        name={`officerList.0.roleType`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseCheckbox
                              id={name}
                              name={name}
                              className="chip-case mr4"
                              label="매니저"
                              checked={value === RoleType.MANAGER ? true : false}
                              onChange={(checked: boolean) => {
                                if (checked === true) {
                                  setValue(`officerList.0.roleType`, RoleType.MANAGER, {
                                    shouldDirty: true,
                                  });
                                } else {
                                  setValue(`officerList.0.roleType`, RoleType.USER, {
                                    shouldDirty: true,
                                  });
                                }
                              }}
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </td>
                  <td width="300px ">
                    <div className="flex-center-center mr4">
                      {officerTypeList.map(
                        (type: { value: UnionOfficerType; label: string }, typeIdx: number) => (
                          <div key={type.value}>
                            <Controller
                              control={control}
                              name={`officerList.0.officerTypeList`}
                              render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                              }) => {
                                return (
                                  <BaseCheckbox
                                    id={`${name}${typeIdx}`}
                                    name={`${name}${typeIdx}`}
                                    className="chip-case mr4"
                                    checked={value?.includes(type.value)}
                                    label={type.label}
                                    onChange={(checked: boolean) => {
                                      let officeTypeArray: UnionOfficerType[] =
                                        watch(`officerList.0.officerTypeList`) || [];

                                      if (checked) {
                                        officeTypeArray.push(type.value);
                                      } else {
                                        officeTypeArray = officeTypeArray.filter(
                                          (value) => value !== type.value,
                                        );
                                      }
                                      setValue(`officerList.0.officerTypeList`, officeTypeArray, {
                                        shouldDirty: true,
                                      });
                                    }}
                                  />
                                );
                              }}
                            ></Controller>
                          </div>
                        ),
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="flex-center-center">
                      <Controller
                        control={control}
                        name={`officerList.0.description`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseInput
                              onChange={onChange}
                              value={value}
                              name={name}
                              maxLength={1000}
                              placeholder="세부업무를 입력해주세요."
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="validation-text">{errors?.officerList && errors.officerList?.message}</p>
        </div>
      </form>
      <div className="mt20">
        <OfficerViewTable
          columns={OfficerColumns}
          data={managementDetail?.officerList?.filter((item) => item.isDeleted !== true) || []}
        />
      </div>
      <>
        <BaseModal
          isOpen={confirmModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => {
            confirmModal.payload && onClickConfirmModal(confirmModal.payload);
            setConfirmModal({ isOpen: false });
          }}
        >
          <p>{confirmModal.message}</p>
        </BaseModal>
        <BaseModal
          isOpen={alertModal.isOpen}
          btnRightTitle="확인"
          onClick={() => setAlertModal({ isOpen: false })}
        >
          <p className="pre-formatted">{alertModal.message}</p>
        </BaseModal>
      </>
    </article>
  );
};

export default ManagementOfficeManagerSection;
