import moment from "moment";
import React from "react";
import { Control, Controller, UseFormReturn } from "react-hook-form";
import { BaseDatePicker, BaseInput, BaseRadio, BaseTextarea, BaseToggle } from "src/components";
import { BaseInputNumberFormat } from "src/components/BaseInputNumberFormat";
import { inputPriceFormat, numberToStringWithComma } from "src/utils";
import { FormData } from "./ProductInfoForm";

/* 
  Service Court
 */

type Props = {
  control: Control<any>;
  watch: UseFormReturn<FormData>["watch"];
  errors: any;
  setValue: Function;
  type: string;
};

const TimeCourt = ({ control, watch, errors, setValue, type }: Props) => {
  return (
    <article className="contents-container__divide-top pb30">
      <div className="contents-container__sub-title">
        <h2>Service Court</h2>
      </div>
      {/* 총 좌석 수 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">총 좌석 수</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <div className="minmax80 ml10">
              <Controller
                control={control}
                name="totalSeatNums"
                render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                  return <BaseInput type="number" name={name} value={value} onChange={onChange} />;
                }}
              ></Controller>
            </div>
            <span className="ml10 mr16">좌석</span>
          </div>

          {errors?.totalSeatNums && (
            <p className="validation-text">{errors?.totalSeatNums?.message}</p>
          )}
        </div>
      </section>
      {/* 이용료  */}
      <section className="contents-container__grid contents-container__1200">
        <div className="contents-container__grid-index">
          <p className="required">이용료</p>
        </div>
        <div className="contents-container__grid-contents">
          <table className="inner-table">
            <thead>
              <tr>
                <th>
                  <div>기준일</div>
                </th>
                <th>
                  <div>금액(부가세 별도 / 원)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span className="ml10 mr16 text-center">10분</span>
                  </div>
                </td>
                <td>
                  <div className="minmax220 flex-center no-wrap">
                    <Controller
                      control={control}
                      name="rentalCostList" //
                      render={({
                        field: { onChange, value, name, ref },
                        fieldState: { error },
                      }) => {
                        return (
                          <BaseInputNumberFormat
                            inputRef={ref}
                            type="text"
                            onChange={(text: string) => {
                              if (value) {
                                value[0].value1 = text;
                                onChange(value);
                              }
                            }}
                            value={inputPriceFormat(value[0].value1)}
                            name={name}
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {errors?.rentalCostList && (
            <p className="validation-text">{errors?.rentalCostList?.message}</p>
          )}
        </div>
      </section>

      {/* 이용료 할인 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>이용료 할인</p>
        </div>
        <div className="contents-container__grid-contents">
          <Controller
            control={control}
            name="isUseSaleRentalCost"
            render={({ field: { onChange, name, value } }) => {
              return (
                <>
                  <div>
                    <BaseToggle onChange={onChange} checked={value ? true : false} />
                  </div>
                  {value && (
                    <table className="inner-table">
                      <thead>
                        <tr>
                          <th>
                            <div>기준일</div>
                          </th>
                          <th>
                            <div>금액(부가세 별도 / 원)</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="minmax180 flex-center-center no-wrap">
                              <span className="ml10 mr16 text-center">10분</span>
                            </div>
                          </td>
                          <td>
                            <div className="minmax220 flex-center no-wrap">
                              <Controller
                                control={control}
                                name="saleRentalCostList" //
                                render={({
                                  field: { onChange, value, name, ref },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <BaseInputNumberFormat
                                      inputRef={ref}
                                      type="text"
                                      onChange={(text: string) => {
                                        if (value) {
                                          value[0].value1 = text;
                                          onChange(value);
                                        }
                                      }}
                                      value={inputPriceFormat(value[0]?.value1)}
                                      name={name}
                                    />
                                  );
                                }}
                              ></Controller>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </>
              );
            }}
          ></Controller>
        </div>
      </section>

      {/* 이용료 안내 팝업 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">이용료 안내 팝업</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <Controller
              control={control}
              name="isInformPopup"
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <>
                    <BaseRadio
                      id={"isInformPopupFalse"}
                      name={name}
                      label="설정 안 함"
                      className="mr16"
                      value={"false"}
                      checked={!value && true}
                      onChange={() => {
                        onChange(false);
                      }}
                    />
                    <BaseRadio
                      id={"isInformPopupTrue"}
                      name={name}
                      label="설정"
                      value={"true"}
                      checked={value && true}
                      onChange={() => {
                        onChange(true);
                      }}
                    />
                  </>
                );
              }}
            ></Controller>
          </div>

          <div className="minmax400">
            <Controller
              control={control}
              name="informPopupDesc" //
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseTextarea
                    className="mr8"
                    onChange={onChange}
                    value={value}
                    name={name}
                    maxLength={1000}
                    readonly={watch && watch("isInformPopup") === true ? false : true}
                  />
                );
              }}
            ></Controller>
          </div>
          {watch && watch("isInformPopup") === true && errors?.informPopupDesc && (
            <p className="validation-text">{errors?.informPopupDesc?.message}</p>
          )}
        </div>
      </section>

      {/* 이용가능시간 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">이용가능시간</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400 flex-row flex-center-start ">
            <Controller
              control={control}
              name="useStartTime"
              render={({ field: { onChange, name, value } }) => {
                return (
                  <BaseDatePicker
                    timeIntervals={10}
                    type="time"
                    selectedDate={value ? moment(value).toDate() : null}
                    setDate={onChange}
                    name={name}
                  />
                );
              }}
            ></Controller>

            <span className="font14 mx10"> ~ </span>
            <Controller
              control={control}
              name="useEndTime"
              render={({ field: { onChange, name, value } }) => {
                return (
                  <BaseDatePicker
                    timeIntervals={10}
                    type="time"
                    selectedDate={value ? moment(value).toDate() : null}
                    setDate={onChange}
                    name={name}
                  />
                );
              }}
            ></Controller>
          </div>
          {(errors?.useStartTime || errors?.useEndTime) && (
            <p className="validation-text">
              {errors?.useStartTime?.message || errors?.useEndTime?.message}
            </p>
          )}
        </div>
      </section>
    </article>
  );
};
export default TimeCourt;
