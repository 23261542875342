const TextAttribute = ({ title, value }: any) => {
  return (
    <>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>{title} </p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <p>{value}</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default TextAttribute;
