import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getProductDetailAsync } from "src/api/product/product-api";
import {
  deleteProductGuideAsync,
  getProductGuideListAysnc,
} from "src/api/product/product-guide-api";
import {
  ProductGuideListRequest,
  ProductGuideListType,
  ProductGuideType,
  ProductModel,
} from "src/api/product/product-types";
import { BaseButton, BaseModal, BaseSelect, ContentsIdSection } from "src/components";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext } from "src/pages/hooks";
import { Modal } from "../../product-types";
import ProductGuideTypeDetail from "./components/ProductGuideTypeDetail";
import ProductGuideTypeList from "./components/ProductGuideTypeList";

/* 
  공간상품 > 상세 > 안내
*/
const GuideDetail = ({ productDetail }: { productDetail: ProductModel }) => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const courtUrl = useMemo(() => {
    return process.env.REACT_APP_COURT_BASSEURL;
  }, []);

  // 등록된 이용안내 리스트
  const [guideList, setGuideList] = useState<Array<ProductGuideListType>>([]);

  // 공간상품 상세
  const [product, setProduct] = useState<ProductModel | null>(null);

  // 건물 대표 id
  const [isBuildingPrimaryId, setIsBuildingPrimaryId] = useState("");

  const [deleteModal, setDeleteModal] = useState<Modal>({ isOpen: false });

  // 공간상품 사용안내 삭제

  const { executeAsync: deleteProductGuide } = useApiOperation(deleteProductGuideAsync);

  // 공간상품 이용안내 id 추출
  const guideId = useMemo(() => {
    return queryParams.guideId && String(queryParams.guideId);
  }, [queryParams.guideId]);

  // 공간상품 이용안내 타입 추출
  const productGuideType = useMemo(() => {
    const currentProductGuideType = queryParams.productGuideType as ProductGuideType;
    return currentProductGuideType || "PRODUCT_GUIDE_UNRECOGNIZED";
  }, [queryParams.productGuideType]);

  // path variable 에서 productId 추출
  const productId = useMemo(() => {
    const paths = location.pathname.split("/");
    const pathProductId = paths[paths.length - 1];
    return Number(pathProductId);
  }, [location]);

  // 건물 선택옵션
  const buildingOptions = useMemo(() => {
    const options = product?.buildingList?.map((building: BuildingModel) => {
      return {
        value: String(building.id),
        label: String(building.buildingName),
      };
    });
    return options;
  }, [product?.buildingList]);

  // 공간상품 상세 조회 api
  const { executeAsync: getProduct } = useApiOperation(getProductDetailAsync);

  // 공간상품 사용안내 리스트 api
  const { executeAsync: getProductGuideList } = useApiOperation(getProductGuideListAysnc);

  // 이용안내 리스트 가져오기 - 건물 select id 가져오면서 호출 (guideBuildingPrimaryId)
  const getProductGuideListApi = useCallback(
    async (productId: number, buildingId: number) => {
      setLoadingBar(true);

      const queryData = {
        buildingId,
        isDisplayed: false,
      };

      const guideParamData: ProductGuideListRequest = {
        productId,
        query: queryData,
      };

      const response = await getProductGuideList(guideParamData);

      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content.sort(
          (a, b) => Number(b?.orderNums) - Number(a?.orderNums),
        );
        setGuideList(result);
      }
      setLoadingBar(false);
    },
    [getProductGuideList, setLoadingBar],
  );

  // 건물 리스트 select setState
  const guideBuildingPrimaryId = useCallback(
    (buildings: Array<BuildingModel>) => {
      // 대표인 건물 찾기 (건물 내 층/호실에 isPrimary 값이 있다)

      let isPrimaryId = "";

      buildings?.forEach((building: BuildingModel) => {
        const floors = building.buildingFloorList; // 건물 층 리스트
        floors?.forEach((floor) => {
          const rooms = floor.buildingRoomList; // 건물 층 안에 호실 리스트
          const buildingId = building.id;

          // isPrimary 여부 확인
          const isPrimary = rooms?.some((room) => room.isPrimary === true);
          if (isPrimary) {
            setIsBuildingPrimaryId(String(buildingId));
            isPrimaryId = String(buildingId);
            return isPrimaryId;
          } else {
            // isPrimary 가 없으면 첫번째 빌딩 노출
            const firstBuildingId = buildings[0].id;
            setIsBuildingPrimaryId(String(firstBuildingId));
            isPrimaryId = String(firstBuildingId);
            return isPrimaryId;
          }

          // url에 builidngId 가 있으면 setValue - form 영역 진입시 선택한 건물 유지 필요
        });
      });

      if (queryParams.buildingId) {
        setIsBuildingPrimaryId(String(queryParams.buildingId));
      }

      // getProductGuideListApi(Number(productId), Number(isPrimaryId));
    },
    [queryParams.buildingId],
  );

  // 공간상품 상세조회 후 데이터 바인딩
  const fetchProduct = useCallback(
    async (productId: number) => {
      setLoadingBar(true);
      setProduct(null);
      const buildings = productDetail.buildingList;

      guideBuildingPrimaryId(buildings || []);
      setProduct(productDetail || null);
      setLoadingBar(false);
    },
    [getProduct, guideBuildingPrimaryId, setLoadingBar],
  );

  // 공간상품 이용안내 삭제 api
  const deleteProductGuideApi = useCallback(
    async (deleteId: string) => {
      if (deleteId) {
        const response = await deleteProductGuide({
          productId: Number(productId),
          ids: [deleteId],
        });

        if (response.status >= 200 && response.status <= 299) {
          navigate(
            `${PagePath.product.detail.replace(
              ":id",
              String(productId),
            )}?tab=guide&buildingId=${isBuildingPrimaryId}`,
          );
        }
      }
    },
    [deleteProductGuide, isBuildingPrimaryId, navigate, productId],
  );

  useEffect(() => {
    fetchProduct(productId);
  }, [productId, fetchProduct]);

  useEffect(() => {
    if (productId && isBuildingPrimaryId)
      getProductGuideListApi(Number(productId), Number(isBuildingPrimaryId));
  }, [getProductGuideListApi, isBuildingPrimaryId, productId]);

  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents">
        <div className="">
          <ContentsIdSection title="이용안내" id={productId} />
        </div>

        <article className="contents-container__1200">
          <div className="contents-container__sub-title">
            <div className="pb4">{/* <h2>{building.buildingName}</h2> */}</div>
          </div>
          <section className="contents-container__grid contents-container__1200">
            <div className="contents-container__grid-index">
              <p>URL</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <div className="font14 text-purple text-hilight">
                  <a
                    target={"_blank"}
                    href={`${courtUrl}/front/court/product/${productId}/public/guides`}
                    rel="noreferrer"
                  >{`${courtUrl}/front/court/product/${productId}/public/guides`}</a>
                </div>
              </div>
            </div>
          </section>
          <section className="contents-container__grid contents-container__1200">
            <div className="contents-container__grid-index">
              <p>VOC 링크</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <div className="font14 text-purple ">
                  <a
                    className="text-hilight"
                    target={"_blank"}
                    href={`${product?.vocUrl}`}
                    rel="noreferrer"
                  >
                    {product?.vocUrl}
                  </a>
                  <p className="contents-container__sub-title-info font12 mt5 ml20">
                    VOC 링크 주소는 기본정보 탭에서 수정할 수 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </section>
          {buildingOptions?.length === 0 ? (
            <section className="contents-container__grid contents-container__1200">
              <div className="contents-container__grid-index">
                <p>건물</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax140">
                    <p>데이터가 없습니다.</p>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <>
              <section className="contents-container__grid contents-container__1200 ">
                <div className="contents-container__grid-index">
                  <p>건물</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <div className="minmax140">
                      <BaseSelect
                        className="minmax200"
                        isDisabled={
                          (buildingOptions?.length === 1 && true) || !!queryParams.guideId
                        }
                        value={isBuildingPrimaryId}
                        stateOptions={buildingOptions || []}
                        setStateValue={(value: string) => {
                          const guideDetailPath = `${PagePath.product.detail.replace(
                            ":id",
                            String(productId),
                          )}?tab=guide&buildingId=${value}
                          `;
                          navigate(guideDetailPath);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>

              <section>
                {/* {!queryParams.productGuideType ? ( */}
                {!queryParams.guideId ? //건물 select box & 이용안내 list &  +항목추가, +네트워크 추가, +주차장 추가 영역
                // <ProductGuideTypeList
                //   guideList={guideList}
                //   buildingId={isBuildingPrimaryId}
                //   productId={productId}
                // />
                null : (
                  // 공간상품 이용안내 항목별 상세 페이지
                  <ProductGuideTypeDetail
                    productGuideType={productGuideType}
                    guideId={guideId || ""}
                  />
                )}
              </section>
            </>
          )}
        </article>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            className="color-white size-large"
            onClick={() => {
              navigate(PagePath.product.list);
            }}
          />
        </div>
        <div className="right-area">
          {guideId && (
            <>
              <BaseButton
                title="삭제"
                className="color-white size-large mr10"
                onClick={() => {
                  setDeleteModal({ isOpen: true, message: "삭제하시겠습니까?" });
                }}
              />
              <BaseButton
                title="수정"
                className="size-large"
                onClick={() => {
                  const formPath = `${PagePath.product.form}?id=${productId}&tab=guide&buildingId=${isBuildingPrimaryId}&guideId=${guideId}&productGuideType=${queryParams.productGuideType}`;
                  navigate(formPath);
                }}
              />
            </>
          )}
        </div>
      </div>
      <BaseModal
        isOpen={deleteModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClick={() => {
          deleteProductGuideApi(String(guideId) || "");
        }}
        onClose={() => setDeleteModal({ isOpen: false })}
      >
        <p>{deleteModal.message}</p>
      </BaseModal>
    </div>
  );
};
export default GuideDetail;
