import React, { useEffect, useState } from "react";
import { useApiOperation } from "src/api/hooks";
import { getPartnerList } from "src/api/partner";
import { BaseButton, BaseModal } from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import { Partner } from "src/types/partner";

type Props = {
  partners: Partner[];
  type?: "DETAIL" | "FORM"; // 상세, 수정혹은등록
  handleDeletePartner?: Function;
};

/*
 * 출입그룹 관리 > 상세, 수정, 등록 > 파트너 목록 테이블
 */

const PartnersTable = ({ partners, type = "DETAIL", handleDeletePartner }: Props) => {
  

  return (
    <article className="contents-container__1200 mb30">
      <section className="contents-container__1200">
        <table className="inner-table access-group-table mt10">
          <thead>
            <tr>
              <th>
                <span>id</span>
              </th>
              <th>
                <span>파트너 코드</span>
              </th>
              <th>
                <span>파트너 이름</span>
              </th>
              {type === "FORM" && <th></th>}
            </tr>
          </thead>
          <tbody>
            {partners.length > 0 ? (
              partners.map((partner: Partner, idx: number) => (
                <tr key={partner.id}>
                  <>
                    <td>
                      <div className="text-center">
                        <span>{partner.id}</span>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <p>{partner.code}</p>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <p>{partner.name}</p>
                      </div>
                    </td>
                    {type === "FORM" && handleDeletePartner && (
                      <td>
                        <button
                          className="base-trash-btn"
                          onClick={() => handleDeletePartner(partner.id!)}
                        ></button>
                      </td>
                    )}
                  </>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>
                  <p className="flex-center-center w-100">데이터가 없습니다</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      
    </article>
  );
};

export default PartnersTable;
