import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useApiOperation } from "src/api/hooks";
import { v4 as uuidv4 } from "uuid";
import {
  getIotRegisteredDeviceControlLabelsAsync,
  getIotRegisteredDeviceLabelsAsync,
  putIotRegisteredDeviceControlLabelsAsync,
  putIotRegisteredDeviceLabelsAsync,
} from "src/api/iot/iot-api";
import { PaIotLabel } from "src/api/iot/iot-types";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal, BaseTextarea } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { useLoadingBarContext } from "src/pages/hooks";

type Props = {
  registeredDeviceId: string;
  setIsLabelModalOpen: Dispatch<SetStateAction<boolean>>;
  setLabels?: Dispatch<SetStateAction<PaIotLabel[]>>;
  types: "DEVICE" | "CONTROL"; // 기기의 Labels인지 제어의 Labels인지 분기하기 위한 타입
  controlId?: string; // 제어 아이디
  fetchListApi?: any;
};
type FieldArray = {
  labels: PaIotLabel[];
};
export const LabelsModal = ({
  registeredDeviceId,
  setIsLabelModalOpen,
  setLabels,
  types,
  controlId,
  fetchListApi,
}: Props) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<Modal>({ isOpen: false });
  const [labelJSON, setLabelJSON] = useState<string>("");
  const { executeAsync: getIotRegisteredDeviceLabels } = useApiOperation(
    getIotRegisteredDeviceLabelsAsync,
  );
  const { executeAsync: putIotRegisteredDeviceLabels } = useApiOperation(
    putIotRegisteredDeviceLabelsAsync,
  );
  const { executeAsync: getIotRegisteredDeviceControlLabels } = useApiOperation(
    getIotRegisteredDeviceControlLabelsAsync,
  );
  const { executeAsync: putIotRegisteredDeviceControlLabels } = useApiOperation(
    putIotRegisteredDeviceControlLabelsAsync,
  );
  const { setLoadingBar } = useLoadingBarContext();

  const initialLabels: PaIotLabel[] = useMemo(() => [{ name: "", value: "" }], []);

  const {
    setValue,
    register,
    control,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldArray>({
    defaultValues: {
      // labels : []
    },
  });
  const {
    fields: lableList,
    append,
    remove,
    update,
  } = useFieldArray({
    control: control,
    name: "labels",
  });

  const renderResponseData = async () => {
    const { data: labelData, status: labelStatus } = await getIotRegisteredDeviceLabels({
      registeredDeviceId,
    });
    if (labelStatus >= 200 && labelStatus < 300) {
      setLabels && setLabels(labelData.data?.labels);
    }
  };

  const setData = (status: number, labels: PaIotLabel[]) => {
    if (status >= 200 && status < 300) {
      if (labels.length > 0) {
        setValue("labels", labels);
        setLabelJSON(JSON.stringify(labels, null, 2));
      } else {
        setValue("labels", initialLabels);
      }
    }
  };

  useEffect(() => {
    const fetchApi = async (registeredDeviceId: string) => {
      setLoadingBar(true);
      if (types === "DEVICE") {
        const { data, status } = await getIotRegisteredDeviceLabels({
          registeredDeviceId,
        });
        setData(status, data.data?.labels);
      } else if (types === "CONTROL" && controlId) {
        const { data, status } = await getIotRegisteredDeviceControlLabels({
          registeredDeviceId,
          controlId,
        });
        setData(status, data.data?.labels);
      }
      setLoadingBar(false);
    };

    if (registeredDeviceId) {
      fetchApi(registeredDeviceId);
    }
  }, []);

  const editLabels = async (labels: PaIotLabel[]) => {
    setLoadingBar(true);
    const payload = labels ? labels : [];
    if (types === "DEVICE") {
      const { status } = await putIotRegisteredDeviceLabels({
        registeredDeviceId,
        labels: payload,
      });
      if (status >= 200 && status < 300) {
        await renderResponseData();
        setIsConfirmModalOpen({ isOpen: false });
        setIsLabelModalOpen(false);
      } else {
        setIsConfirmModalOpen({ isOpen: false });
      }
    } else if (types === "CONTROL" && controlId) {
      const { status } = await putIotRegisteredDeviceControlLabels({
        registeredDeviceId,
        labels: payload,
        controlId,
      });
      if (status >= 200 && status < 300) {
        setIsConfirmModalOpen({ isOpen: false });
        setIsLabelModalOpen(false);
      } else {
        setIsConfirmModalOpen({ isOpen: false });
      }
    }
    setLoadingBar(false);
  };
  const handleChangeKeyValue = () => {
    try {
      const parsingLabels = JSON.parse(labelJSON);
      if (typeof parsingLabels === "object") {
        setValue("labels", parsingLabels);
      }
    } catch (e) {
      setIsConfirmModalOpen({
        isOpen: true,
        message: "올바른 JSON 형식이 아닙니다.",
        type: "checkJSON",
      });
    }
  };
  const handleChangeJSON = () => {
    try {
      const stringifyLabels = JSON.stringify(getValues("labels"), null, 2);
      setLabelJSON(stringifyLabels);
    } catch (e) {
      return false;
    }
  };

  const onSubmit = (data: FieldArray) => {
    setIsConfirmModalOpen({
      isOpen: true,
      message: "키 값으로 수정됩니다. 수정하시겠습니까?",
      payload: data.labels,
    });
  };
  return (
    <BaseAbstractModal isOpen={true} className="labels-modal">
      <form className="abstract-form-scroll" onSubmit={handleSubmit(onSubmit)}>
        <section className="base-abstract-modal__title">
          <h1>Labels</h1>
        </section>
        <section className="base-abstract-modal__contents">
          <div className="container">
            <div className="container__left">
              <div className="key-value-title">
                <h3 className="minmax250">키</h3>
                <div className="flex-center">
                  <h3 className="minmax220">값</h3>
                  <button
                    type="button"
                    className="base-add-btn ml10"
                    onClick={() => {
                      append(initialLabels);
                    }}
                  ></button>
                </div>
              </div>
              <div className="key-value-line">
                {lableList.map((lable: PaIotLabel, index: number) => {
                  return (
                    <div className="flex-center mb10" key={uuidv4() + lable.name}>
                      <div className="flex-center minmax250">
                        <Controller
                          control={control}
                          name={`labels.${index}.name`}
                          render={({
                            field: { onChange, value, name },
                            fieldState: { error, isDirty },
                          }) => {
                            return <BaseInput value={value} onChange={onChange} />;
                          }}
                        ></Controller>
                        <div className="minmax30 font14 pb3 flex-center-center">:</div>
                      </div>
                      <div className="flex-center">
                        <Controller
                          control={control}
                          name={`labels.${index}.value`}
                          render={({
                            field: { onChange, value, name },
                            fieldState: { error, isDirty },
                          }) => {
                            return (
                              <BaseInput className="minmax220" value={value} onChange={onChange} />
                            );
                          }}
                        ></Controller>
                        <button
                          type="button"
                          className="base-erase-btn ml10"
                          onClick={() => {
                            remove(index);
                          }}
                        ></button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="container__center">
              <button type="button" className="to-left" onClick={handleChangeKeyValue}></button>
              <button type="button" className="to-right" onClick={handleChangeJSON}></button>
            </div>
            <div className="container__right">
              <h3>JSON으로 확인</h3>
              <BaseTextarea
                value={labelJSON}
                height={200}
                className="mt20 pre-formatted"
                onChange={(value: string) => {
                  setLabelJSON(value);
                }}
              ></BaseTextarea>
            </div>
          </div>
        </section>
        <section className="base-abstract-modal__btn-wrap ">
          <BaseButton
            title={"취소"}
            className="color-white flex-center-center"
            onClick={() => setIsLabelModalOpen(false)}
          />
          <BaseButton title={"확인"} type="submit" className="flex-center-center" />
        </section>
        {isConfirmModalOpen.isOpen && (
          <BaseModal
            isOpen={true}
            btnLeftTitle={isConfirmModalOpen.type === "checkJSON" ? "" : "취소"}
            btnRightTitle={isConfirmModalOpen.type === "checkJSON" ? "확인" : "수정"}
            onClose={() => setIsConfirmModalOpen({ isOpen: false })}
            onClick={
              isConfirmModalOpen.type === "checkJSON"
                ? () => {
                    setIsConfirmModalOpen({ isOpen: false });
                  }
                : () => {
                    editLabels(isConfirmModalOpen.payload);
                    fetchListApi(registeredDeviceId);
                  }
            }
          >
            <p>{isConfirmModalOpen.message}</p>
          </BaseModal>
        )}
      </form>
    </BaseAbstractModal>
  );
};
