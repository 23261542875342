import { ReactChild } from "react";
import { MediaServiceType, MetaGroupType } from "src/api/public-types";

// modal 공통
export type Modal = {
  isOpen: boolean;
  children?: ReactChild;
  message?: string;
  type?: string;
  payload?: any;
};

export interface BuildingFloorAndHo {
  // roomId: number;
  // buildingName: string;
  // roomNum: number;
  // floorNum: string;
  // leasableAreaNet: number;
  // leasableArea: number;
  // rateOfUse: number;
  // buildingId: number;
  // floorId: number;
  // isPrimary: boolean;
  roomId: string;
  buildingName: string | undefined;
  roomNum: number;
  floorNum: number;
  leasableAreaNet: string;
  leasableArea: string;
  rateOfUse: number;
  buildingId: string;
  floorId: string;
  isPrimary: boolean;
}

export type PropertyType = {
  floorId: number;
  floorNum: number;
  isPrimary: boolean;
  leasableArea: string;
  leasableAreaNet: string;
  roomId: number;
  roomNum: number;
  buildingId: number;
};

// 공간상품 상품정보 이용료

export enum ProductRent {
  MONTH = "PRODUCT_RENT_MONTH_1",
  HOUR = "PRODUCT_RENT_HOUR_1",
  MIUNUTE = "PRODUCT_RENT_MINUTE_10",
}

// 공간상품 상품정보 이용료 할인
export enum ProductRentDiscount {
  MONTH = "PRODUCT_RENT_DISCOUNT_MONTH_1",
  HOUR = "PRODUCT_RENT_DISCOUNT_HOUR_1",
  MIUNUTE = "PRODUCT_RENT_DISCOUNT_MINUTE_10",
}

// 공간상품 이용료
export const productRent = [{ metaItem: ProductRent.MONTH, value1: "", orderNums: 1 }]; //1개월

export const productTimeRent = [{ metaItem: ProductRent.MIUNUTE, value1: "", orderNums: 1 }]; //타임코트 10분

//  공간상품 이용료 할인
export const productSaleRent = [{ metaItem: ProductRentDiscount.MONTH, value1: "" }]; // 1개월
export const productTimeSaleRent = [{ metaItem: ProductRentDiscount.MIUNUTE, value1: "" }]; //타임코트 10분

// 공간상품 상품정보 관리비
export const productMaintainceFee = [
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_1",
    value1: "true",
    orderNums: 1,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_2",
    value1: "true",
    orderNums: 2,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_3",
    value1: "true",
    orderNums: 3,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_4",
    value1: "true",
    orderNums: 4,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_5",
    value1: "true",
    orderNums: 5,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_6",
    value1: "true",
    orderNums: 6,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_7",
    value1: "true",
    orderNums: 7,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_8",
    value1: "true",
    orderNums: 8,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_9",
    value1: "true",
    orderNums: 9,
  },
];

// 공간상품 기본정보 방향
export const productDirections = [
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_E",
      value1: "false",
      orderNums: 1,
    },
    label: "동",
  },
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_W",
      value1: "false",
      orderNums: 2,
    },
    label: "서",
  },
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_S",
      value1: "false",
      orderNums: 3,
    },
    label: "남",
  },
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_N",
      value1: "false",
      orderNums: 4,
    },
    label: "북",
  },
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_SE",
      value1: "false",
      orderNums: 5,
    },
    label: "남동",
  },
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_SW",
      value1: "false",
      orderNums: 6,
    },
    label: "남서",
  },
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_NE",
      value1: "false",
      orderNums: 7,
    },
    label: "북동",
  },
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_NW",
      value1: "false",
      orderNums: 8,
    },
    label: "북서",
  },
];

export interface ImageRequestModal {
  serviceId: number;
  mediaServiceTypes: Array<MediaServiceType>;
}

// 공간상품 이미지 리스트 전체 불러오기
export const productMediaServiceType: Array<MediaServiceType> = [
  "MEDIA_PRODUCT_OFFICE",
  "MEDIA_PRODUCT_FLOOR_PLAN",
  "MEDIA_PRODUCT_MEETING_ROOM",
  "MEDIA_PRODUCT_DESK",
  "MEDIA_PRODUCT_REFRESH_ROOM",
  "MEDIA_PRODUCT_TOILET",
  "MEDIA_PRODUCT_PARKING_LOT",
  "MEDIA_PRODUCT_FACILITY",
  "MEDIA_PRODUCT_ETC",
];

export const PRODUCT_CATEGORY_MAP = {
  PRODUCT_CATEGORY_NORMAL: "일반",
  PRODUCT_CATEGORY_MANAGEMENT: "관리비 계약 상품",
};
