import React, { useCallback } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldNamesMarkedBoolean,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  useWatch,
} from "react-hook-form";
import { BaseCheckbox, BaseInput, BaseRadio } from "src/components";
import { AirConditionerInfo, FacilityFormData } from "../facility-types";

type Props = {
  control: Control<FacilityFormData>;
  getValues: UseFormGetValues<FacilityFormData>;
  setValue: UseFormSetValue<FacilityFormData>;
  defaultValues: FacilityFormData;
  errors: FieldErrors;
  trigger: UseFormTrigger<FacilityFormData>;
  dirtyFields: FieldNamesMarkedBoolean<FacilityFormData>;
};

/* 
  공간상품 > 등록 or 수정 > 시설/서비스 > 공조기 컴포넌트
*/
const AirConditionerSection = ({
  control,
  getValues,
  setValue,
  defaultValues,
  errors,
  trigger,
  dirtyFields,
}: Props) => {
  // 공조기 기기정보
  const airConditionerInfoList: Array<AirConditionerInfo> = useWatch({
    control,
    name: "airConditionerInfoList",
  });

  // 공조기 기기정보 체크박스 선택 변경
  const changeCheckedAirConditionerInfo = useCallback(
    (metaItem: string, checked: boolean) => {
      const newAirConditionerInfoList = [...airConditionerInfoList];
      for (let i = 0; i < newAirConditionerInfoList.length; i++) {
        if (newAirConditionerInfoList[i].metaItem === metaItem) {
          if (checked) {
            newAirConditionerInfoList[i].checked = checked;
          } else {
            newAirConditionerInfoList[i].checked = checked;
            newAirConditionerInfoList[i].value = undefined;
          }
        }
      }
      setValue("airConditionerInfoList", newAirConditionerInfoList, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [airConditionerInfoList, setValue],
  );

  // 공조기 기기정보 value 변경
  const changeValueAirConditionerInfo = useCallback(
    (metaItem: string, value: any) => {
      const newAirConditionerInfoList = [...airConditionerInfoList];
      for (let i = 0; i < newAirConditionerInfoList.length; i++) {
        if (newAirConditionerInfoList[i].metaItem === metaItem) {
          newAirConditionerInfoList[i].value = value;
        }
      }
      setValue("airConditionerInfoList", newAirConditionerInfoList, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [airConditionerInfoList, setValue],
  );

  return (
    <section className="contents-container__grid contents-container__1200 mb30">
      <div className="contents-container__grid-index">
        <p className="required">공조기</p>
      </div>
      <div className="contents-container__grid-contents">
        <table className="inner-table" width="100%">
          <tbody>
            <tr>
              <td className="bg-gray text-center" width="140">
                <span className="text-primary3 no-wrap">난방 타입</span>
              </td>
              <td>
                <div className="flex-center px16">
                  <Controller
                    control={control}
                    name="heatingType"
                    render={({ field: { onChange, value: heatingTypeValue, name } }) => (
                      <>
                        <BaseRadio
                          id="CENTRAL_AIR_CONDITIONER"
                          name={name}
                          value="CENTRAL_AIR_CONDITIONER"
                          label="중앙난방"
                          className="mr16"
                          checked={heatingTypeValue === "CENTRAL_AIR_CONDITIONER"}
                          onChange={(checked: boolean, e) => {
                            onChange(e.target.value);
                            // 직접입력값 reset
                            setValue("heatingTypeDesc", "", {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                          }}
                        />
                        <BaseRadio
                          id="INDIVIDUAL_AIR_CONDITIONER"
                          name={name}
                          value="INDIVIDUAL_AIR_CONDITIONER"
                          label="개별난방"
                          className="mr16"
                          checked={heatingTypeValue === "INDIVIDUAL_AIR_CONDITIONER"}
                          onChange={(checked: boolean, e) => {
                            onChange(e.target.value);
                            // 직접입력값 reset
                            setValue("heatingTypeDesc", "", {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                          }}
                        />
                        <BaseRadio
                          id="OPTIONAL"
                          name={name}
                          value="OPTIONAL"
                          label="중앙난방+개별난방"
                          className="mr16"
                          checked={heatingTypeValue === "OPTIONAL"}
                          onChange={(checked: boolean, e) => {
                            onChange(e.target.value);
                            // 직접입력값 reset
                            setValue("heatingTypeDesc", "", {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                          }}
                        />
                        <BaseRadio
                          id="DIRECT_HEATING_AIR_CONDITIONER"
                          name={name}
                          value="DIRECT_HEATING_AIR_CONDITIONER"
                          label="직접입력"
                          className="mr16"
                          checked={heatingTypeValue === "DIRECT_HEATING_AIR_CONDITIONER"}
                          onChange={(checked: boolean, e) => {
                            onChange(e.target.value);
                          }}
                        />
                        <div className="minmax140">
                          <Controller
                            control={control}
                            name="heatingTypeDesc"
                            render={({ field: { onChange, value, name } }) => (
                              <BaseInput
                                readonly={heatingTypeValue !== "DIRECT_HEATING_AIR_CONDITIONER"}
                                onChange={onChange}
                                value={value}
                                name={name}
                              />
                            )}
                          ></Controller>
                        </div>
                      </>
                    )}
                  ></Controller>
                </div>
              </td>
            </tr>
            <tr>
              <td className="bg-gray text-center" width="140">
                <span className="text-primary3 no-wrap">기기</span>
              </td>
              <td>
                <div className="flex-center px16">
                  <Controller
                    control={control}
                    name="isAirConditionerProvided"
                    render={({
                      field: { onChange, value: isAirConditionerProvidedValue, name },
                    }) => (
                      <>
                        <BaseRadio
                          id="isAirConditionerProvided_TRUE"
                          name={name}
                          value={"true"}
                          label="제공"
                          className="mr16"
                          checked={Boolean(String(isAirConditionerProvidedValue) === "true")}
                          onChange={(checked: boolean, e) => {
                            onChange(e.target.value);
                            if (dirtyFields?.isAirConditionerProvided) {
                              // 제공여부 값이 바뀐적이 있거나 validation 이 작동한적이있으면 기기정보 validation trigger
                              trigger("airConditionerInfoList");
                            }
                          }}
                        />
                        <BaseRadio
                          id="isAirConditionerProvided_FALSE"
                          name={name}
                          value={"false"}
                          label="제공하지 않음"
                          checked={Boolean(String(isAirConditionerProvidedValue) === "false")}
                          onChange={(checked: boolean, e) => {
                            onChange(e.target.value);
                            // 기기정보 reset
                            setValue(
                              "airConditionerInfoList",
                              defaultValues.airConditionerInfoList,
                              {
                                shouldDirty: true,
                                shouldValidate: true,
                              },
                            );
                            // 추가설명 reset
                            setValue("airConditionerDescription", "");
                          }}
                        />
                      </>
                    )}
                  ></Controller>
                </div>
              </td>
            </tr>
            {String(getValues("isAirConditionerProvided")) === "true" && (
              <>
                <tr className="auto-height">
                  <td className="bg-gray text-center" width="140">
                    <span className="text-primary3 no-wrap">기기 정보</span>
                  </td>
                  <td className="auto">
                    {airConditionerInfoList.map((info: AirConditionerInfo, index: number) => {
                      return (
                        <div key={index.toString()} className="h-40 flex-center pl16">
                          <div className="minmax200">
                            <BaseCheckbox
                              id={`${info.metaItem}_metaItem_${index}`}
                              name={`${info.metaItem}_metaItem_${index}`}
                              label={info.label}
                              checked={info.checked}
                              onChange={(checked: boolean) =>
                                changeCheckedAirConditionerInfo(info.metaItem, checked)
                              }
                            />
                          </div>
                          {/* 직접 입력일 경우 value 입력 레이아웃 다름 */}
                          {info.metaItem !== "PRODUCT_AIR_CONDITIONER_5" ? (
                            <>
                              <div className="minmax80 mr10">
                                <BaseInput
                                  type="number"
                                  value={info.value}
                                  onChange={(inputValue: any) =>
                                    changeValueAirConditionerInfo(info.metaItem, inputValue)
                                  }
                                  name={`${info.metaItem}_value_${index}`}
                                  readonly={!info.checked}
                                />
                              </div>
                              <span>대</span>
                            </>
                          ) : (
                            <div className="w-100">
                              <BaseInput
                                value={info.value}
                                onChange={(inputValue: any) =>
                                  changeValueAirConditionerInfo(info.metaItem, inputValue)
                                }
                                name={`${info.metaItem}_value_${index}`}
                                readonly={!info.checked}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="bg-gray text-center" width="140">
                    <span className="text-primary3 no-wrap">추가 설명</span>
                  </td>
                  <td>
                    <div className="w-100 pl16">
                      <Controller
                        control={control}
                        name="airConditionerDescription"
                        render={({ field: { onChange, value, name } }) => (
                          <BaseInput value={value} onChange={onChange} name={name} />
                        )}
                      ></Controller>
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
        {/* validation error message */}
        {errors && (errors.heatingTypeDesc || errors.airConditionerInfoList) && (
          <p className="validation-text mb20">
            {errors.heatingTypeDesc?.message || errors.airConditionerInfoList?.message}
          </p>
        )}
      </div>
    </section>
  );
};
export default AirConditionerSection;
