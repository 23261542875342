import React, { useCallback } from "react";
import { Control, UseFormGetValues, UseFormSetValue, useWatch } from "react-hook-form";
import { BaseInput, BaseRadio } from "src/components";
import { FacilityFormData, OptionalFacility } from "../facility-types";

type Props = {
  control: Control<FacilityFormData>;
  getValues: UseFormGetValues<FacilityFormData>;
  setValue: UseFormSetValue<FacilityFormData>;
};

/* 
  공간상품 > 등록 or 수정 > 시설/서비스 > 추가 시설/서비스 컴포넌트
*/
const OptionalFacilitySection = ({ control, getValues, setValue }: Props) => {
  // 추가 시설/서비스
  const productOptionalFacilityList: Array<OptionalFacility> = useWatch({
    control,
    name: "productOptionalFacilityList",
  });

  // 제공여부 변경
  const changeIsProvidedAirConditionerInfo = useCallback(
    (metaItem: string, isProvided: boolean) => {
      const newProductOptionalFacilityList = [...productOptionalFacilityList];
      for (let i = 0; i < newProductOptionalFacilityList.length; i++) {
        if (newProductOptionalFacilityList[i].metaItem === metaItem) {
          newProductOptionalFacilityList[i].isProvided = isProvided;
        }
      }
      setValue("productOptionalFacilityList", newProductOptionalFacilityList, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [productOptionalFacilityList, setValue],
  );

  // 추가설명 변경
  const changeDescriptionAirConditionerInfo = useCallback(
    (metaItem: string, value: any) => {
      const newProductOptionalFacilityList = [...productOptionalFacilityList];
      for (let i = 0; i < newProductOptionalFacilityList.length; i++) {
        if (newProductOptionalFacilityList[i].metaItem === metaItem) {
          newProductOptionalFacilityList[i].description = value;
        }
      }
      setValue("productOptionalFacilityList", newProductOptionalFacilityList, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [productOptionalFacilityList, setValue],
  );

  return (
    <section className="contents-container__grid contents-container__1200 mb30">
      <div className="contents-container__grid-index">
        <p className="required">추가 시설/서비스</p>
      </div>
      <div className="contents-container__grid-contents">
        <table className="inner-table" width="100%">
          <thead>
            <tr>
              <th>
                <span>종류</span>
              </th>
              <th>
                <span>제공여부</span>
              </th>
              <th>
                <span>추가 설명</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {productOptionalFacilityList.map((optional: OptionalFacility, index: number) => (
              <tr key={index.toString()}>
                <td width="120" className="text-center">
                  <span>{optional.label}</span>
                </td>
                <td width="220">
                  <div className="flex-center-center">
                    <BaseRadio
                      id={`${optional.metaItem}_TRUE`}
                      name={optional.metaItem}
                      value={"true"}
                      label="제공"
                      className="mr16"
                      checked={Boolean(String(optional.isProvided) === "true")}
                      onChange={(checked: boolean, e) =>
                        changeIsProvidedAirConditionerInfo(optional.metaItem, true)
                      }
                    />
                    <BaseRadio
                      id={`${optional.metaItem}_FALSE`}
                      name={optional.metaItem}
                      value={"false"}
                      label="제공하지 않음"
                      checked={Boolean(String(optional.isProvided) === "false")}
                      onChange={(checked: boolean, e) => {
                        // 추가설명 reset
                        changeDescriptionAirConditionerInfo(optional.metaItem, "");
                        // 제공여부 변경
                        changeIsProvidedAirConditionerInfo(optional.metaItem, false);
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div className="w-100">
                    <BaseInput
                      value={optional.description}
                      onChange={(inputValue: any) =>
                        changeDescriptionAirConditionerInfo(optional.metaItem, inputValue)
                      }
                      name={`${optional.metaItem}_description_${index}`}
                      readonly={!optional.isProvided}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};
export default OptionalFacilitySection;
