type TextValue = {
  title: string;
  value: string;
  mb?: string;
}

const TextAttribute = ({title, value, mb}: TextValue) => {
  let classname = 'contents-container__grid ';
  if(mb){
    classname = classname + mb;
  }else{
    classname = classname + 'mb10';
  }
  return (
    <>
      <div className={classname}>
            <div className="contents-container__grid-index">
              <p>{title}</p>
            </div>
            <div className="contents-container__grid-contents">
              <p>{value}</p>
            </div>
          </div>
    </>
  )
}

export default TextAttribute