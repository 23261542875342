import moment from "moment";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import PagePath from "src/pagePath.json";
import { formatPhoneNumber, YmdFormat } from "src/utils";

const ChargeMntType = ({
  application,
  memberInfo,
  spaceProduct,
  productDetail,
  providerDetail,
  partner,
  rooms,
}: any) => {
  const goMemberDetail = (id: number) => {
    let path = "";
    if (id) {
      path = PagePath.member.detail.replace(":id", id!.toString());
    }
    return path;
  };
  const goProductDetail = (id: number) => {
    let path = "";
    if (id) {
      path = PagePath.product.detail.replace(":id", id!.toString());
    }
    return path;
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <article className="pb20">
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>신청자 정보</h2>
          </div>
          <div className="flex-center">
            {/* 회원번호 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>회원번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  <Link
                    to={goMemberDetail(memberInfo?.id)}
                    className="text-hilight"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {memberInfo?.memberNo}
                  </Link>
                </p>
              </div>
            </section>

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>휴대폰</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{formatPhoneNumber(memberInfo?.phoneNumber)}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 이메일 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>이메일</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{memberInfo?.email}</p>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>신청자명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>작업해야합니다.</p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>상품 정보</h2>
          </div>
          <div className="flex-center">
            {/* 상품 id */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>상품 id</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  <Link
                    to={goProductDetail(spaceProduct?.id)}
                    className="text-hilight"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {spaceProduct?.id}
                  </Link>
                </p>
              </div>
            </section>
            {/* 보증금 */}

            <section className="contents-container__grid not-available">
              <div className="contents-container__grid-index">
                <p>보증금(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>0</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 상품명 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>상품구분</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {productDetail?.productCategory === "PRODUCT_CATEGORY_NORMAL"
                    ? "일반"
                    : "관리비 상품"}
                </p>
              </div>
            </section>

            <section className="contents-container__grid not-available">
              <div className="contents-container__grid-index">
                <p>계약금(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>0</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 상품명 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>상품명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{spaceProduct?.productName}</p>
              </div>
            </section>
            {/* 계약금 */}

            {/* 잔금 */}

            <section className="contents-container__grid not-available">
              <div className="contents-container__grid-index">
                <p>잔금(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>0</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 타입 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>타입</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {productDetail?.productType === "MAINTENANCE_FEE" && "관리비"}
                </p>
              </div>
            </section>

            {/* 월 이용료 */}

            <section className="contents-container__grid not-available">
              <div className="contents-container__grid-index">
                <p>
                  월 이용료
                  <br />
                  (부가세 포함 / 원)
                </p>
              </div>
              <div className="contents-container__grid-contents">
                <p>0</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>프로바이더 명</p>
              </div>
              <div className="contents-container__grid-contents">
                {productDetail?.providerId ? (
                  <div>
                    <Link
                      to={PagePath.provider.detail.replace(":id", productDetail?.providerId)}
                      target="_blank"
                      className="text-hilight"
                    >
                      {providerDetail?.provider?.providerName}
                    </Link>
                  </div>
                ) : (
                  <p>-</p>
                )}
              </div>
            </section>
          </div>
        </div>
      </article>

      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>건물 정보</h2>
          </div>

          {rooms !== undefined &&
            rooms.length > 0 &&
            rooms.map((room: any, index: number) => {
              return (
                <div className="flex-center" key={index}>
                  {/* 이용 시작 */}
                  <section className="contents-container__grid mr20">
                    <div className="contents-container__grid-index">
                      <p>건물 id/건물 명</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p className="ellipsis">{room.buildingId + " / " + room.buildingName}</p>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>층/호실</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>
                        {(room.isGround ? "지하" : "지상") +
                          " " +
                          room.floorName +
                          " | " +
                          room.roomNum +
                          "호"}
                      </p>
                    </div>
                  </section>
                </div>
              );
            })}
        </div>
      </article>

      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>신청기간</h2>
          </div>
          <div className="flex-center">
            {/* 이용 시작 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>{"신청 시작"}</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{moment(application?.useStartTime).format(YmdFormat.FULL)}</p>
              </div>
            </section>
            {/* 계약기간(MM) */}

            {/* 이용종료 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>신청 종료</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{moment(application?.useEndTime).format(YmdFormat.FULL)}</p>
              </div>
            </section>
          </div>

          
        </div>
      </article>
    </>
  );
};

export default ChargeMntType;
