import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { Modal, Order, PageMeta, SearchType, Status } from "src/api/public-types";
import { WorkOrderListAsync } from "src/api/workOrder/workOrder-api";
import { WorkOrderListModel, WorkOrderQueryParams } from "src/api/workOrder/workOrder-types";
import { BaseButton, BaseDatePicker, BaseInput, BaseSelect, BaseTable } from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { Partner } from "src/types/partner";
import { searchTypes, workOrderPriorityOptions, workOrderStatus } from "../workOrder-types";
import WorkOrderListColumn from "./columns/WorkOrderListColumn";

const WorkOrderList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const queryParams: WorkOrderQueryParams = useMemo(() => {
    const _queryParams: WorkOrderQueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }

    return _queryParams;
  }, [location.search]);

  const [workOrders, setWorkOrders] = useState<Array<WorkOrderListModel>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [params, setParams] = useState<WorkOrderQueryParams>({ ...queryParams });

  // 선택된 파트너 정보
  const [partner, setPartner] = useState<Partner>();

  const [partnerModal, setPartnerModal] = useState<Modal>({
    isOpen: false,
  });

  //  워크오더 목록 api
  const { executeAsync: getWorkOrderList } = useApiOperation(WorkOrderListAsync);

  // 정산정보 목록 api
  const { executeAsync: getProviers } = useApiOperation(getProviderList);

  // 워크오더 목록
  const getWorkOrderListApi = useCallback(
    async (workOrderParams) => {
      setLoadingBar(true);

      const response = await getWorkOrderList(workOrderParams);

      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content;
        let isProviderId = false;

        // 배열에 providerId 데이터가 있는지 확인
        if (result.length > 0 && Object.keys(result[0]).includes("providerId")) {
          isProviderId = true;
        }
        // providerId 데이터가 있으면 providerName 데이터 추가
        if (isProviderId) {
          const providerIds = result.map((item) => item.providerId);

          // providerIds 에서 중복id 및 null 제거
          const removedDuplications = providerIds.filter(
            (num, i) => num !== null && providerIds.indexOf(num) === i,
          );
          // providerName 추가하기
          const { data: providers } = await getProviers({
            providerId: removedDuplications.join(", "),
          });

          const newWorkOrders = result.map((workOrder) => {
            providers.data.content.forEach((provider) => {
              if (String(workOrder.providerId) === String(provider.providerId)) {
                workOrder = { ...workOrder, mgmtOfficeName: provider.providerName };
              }
            });
            return workOrder;
          });

          setWorkOrders(newWorkOrders || []);
        } else {
          setWorkOrders(result);
        }

        setPageMeta(response.data.meta.pageMeta);
      }
      setLoadingBar(false);
    },
    [getProviers, getWorkOrderList],
  );

  useEffect(() => {
    getWorkOrderListApi(queryParams);
  }, [getWorkOrderListApi, queryParams]);

  // 쿼리파라미터 정보와 함께 네비게이션

  const navigateWithQueryParams = useCallback(
    (passParams?: WorkOrderQueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + newQueryParamStr);
    },
    [navigate, location.pathname, params, queryParams],
  );

  return (
    <div className="page-product-list">
      {/* 로딩바 */}
      {/* <ContentsTitle title="워크오더" /> */}
      <div className="contents-container__search-wrap">
        <div className="left-area">
          {/* <p className="mr10">
              전체 <span>{pageMeta?.totalElements || 0}</span>
            </p> */}
          <div className="minmax200 mr8 cursor-pointer">
            <BaseInput
              readonly
              placeholder="파트너"
              value={params.partnerName && params.partnerName}
              onSearchClick={() => setPartnerModal({ isOpen: true })}
              onClearClick={() => {
                const editedParams = { ...params };
                delete editedParams.partnerId;
                delete editedParams.partnerName;
                setParams(editedParams);
              }}
            />
          </div>
          <div className="minmax150 mr8">
            <BaseSelect
              placeholder="상태"
              stateOptions={workOrderStatus}
              value={params.statusList}
              setStateValue={(statusList: Status) => {
                setParams({ ...params, statusList });
              }}
            />
          </div>

          <div className="minmax120 mr8">
            <BaseSelect
              placeholder="우선순위"
              stateOptions={workOrderPriorityOptions}
              setStateValue={(priority: number) => {
                setParams({ ...params, priority });
              }}
              value={params.priority}
            />
          </div>

          <div className="minmax120 mr8">
            <BaseDatePicker
              setDate={(date: string) => {
                if (date !== "" && date !== null && date !== "Invalid date") {
                  let start = moment(date).format("YYYY-MM-DD") + "T00:00:00+09:00";
                  setParams({
                    ...params,
                    startDate: String(start),
                    endDate: String(start).replace("T00:00:00+09:00", "T23:59:59+09:00"),
                  });
                }
              }}
              selectedDate={(params.startDate && moment(params.startDate).toDate()) || null}
              placeholder="시작일"
            />
          </div>
          <div className="mr8">
            <span>~</span>
          </div>
          <div className="minmax120 mr8">
            <BaseDatePicker
              setDate={(date: string) => {
                if (date !== "" && date !== null && date !== "Invalid date") {
                  let end = moment(date).format("YYYY-MM-DD") + "T23:59:59+09:00";

                  setParams({ ...params, endDate: String(end) });
                }
              }}
              selectedDate={(params.endDate && moment(params.endDate).toDate()) || null}
              placeholder="종료일"
              minDate={moment(params.startDate).toDate()}
              disabled={!params.startDate ? true : false}
            />
          </div>
          <div className="minmax120 mr8">
            <BaseSelect
              placeholder="검색 대상"
              value={params.searchType}
              stateOptions={searchTypes}
              setStateValue={(searchType: SearchType) => setParams({ ...params, searchType })}
            />
          </div>

          <BaseInput
            type="text"
            value={params.keywordList || ""}
            className="mr8"
            placeholder="검색어를 입력해주세요"
            onChange={(keywordList: string) => {
              setParams({ ...params, keywordList });
            }}
            onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
            onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
          />
        </div>
      </div>
      <BaseTable
        data={workOrders}
        pageIndex={Number(params?.page || 0)}
        totalPages={pageMeta?.totalPages || 0}
        goPage={(page: number) => {
          navigateWithQueryParams({ page }, "pagination");
        }}
        orders={params?.sort?.orders}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } }, "search");
          }
        }}
        disabledSortHeaders={[
          // "workOrderType",
          "summary",
          "endDate",
          // "isReopened",
          // "status",
          // "workOrderPriority",
          "mgmtOfficerName",
          "createdBy",
          "delay",
          "buildingName",
          "workOrderSheetGroupId",
          "costSum",
        ]}
        columns={WorkOrderListColumn}
      />{" "}
      {partnerModal.isOpen && (
        <PartnerSelectModal
          onCanceled={() => setPartnerModal({ isOpen: false })}
          onAdded={(partner) => {
            setPartner(partner[0]);
            setPartnerModal({ isOpen: false });
            setParams({
              ...params,
              partnerId: String(partner[0].id),
              partnerName: String(partner[0].name),
            });
          }}
          defaultValues={[]}
        />
      )}
    </div>
  );
};

export default WorkOrderList;
