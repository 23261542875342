import React, { useCallback, useEffect, useState } from "react";
import { BuildingModel, CommonFacilityModel } from "src/api/building/building-types";
import { getEslProductDetailAsync } from "src/api/esl/esl-api";
import { EslProduct } from "src/api/esl/esl-types";
import { useApiOperation } from "src/api/hooks";
import { ProductModel } from "src/api/product/product-types";
import { MediaFile } from "src/api/public-types";
import { BaseTooltip, ResizedImage } from "src/components";
import { numberToStringWithComma, showHourMinute } from "src/utils";

type Props = {
  productDetail?: ProductModel;
};

interface EslLabel extends EslProduct {
  locationCode: string; // 공간유형 채번
}

const s3Url = process.env.REACT_APP_S3_BASEURL;

const sampleImg =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/No_Cross.svg/1024px-No_Cross.svg.png";

const RefreshRoomComp = ({ productDetail }: Props) => {
  // ESL 데이터 상세 조회 api
  const { executeAsync: getEslProductDetail } = useApiOperation(getEslProductDetailAsync, {
    doNotErrorHandleModal: true,
  });

  // 조회한 ESL 데이터 목록
  const [eslLabels, setEslLabels] = useState<Array<EslLabel>>([]);

  // esl 라벨 목록 조회
  const fetchAllLabels = useCallback(async (locationCodes: Array<string>) => {
    try {
      if (!locationCodes || locationCodes.length === 0) {
        throw Error("locationCodes.length === 0");
      }

      const eslLabels: Array<EslLabel> = [];
      for (let i = 0; i < locationCodes.length; i++) {
        const locationCode = locationCodes[i];
        const result = await getEslProductDetail({ productId: locationCode });
        if (result?.data?.data && result.status >= 200 && result.status <= 299) {
          if (result?.data?.data?.product) {
            // esl 라벨이 연동되어있을 경우만 조회됨
            eslLabels.push({
              ...result?.data?.data?.product,
              ...{
                locationCode,
              },
            });
          }
        }
      }

      setEslLabels(eslLabels);
    } catch (error) {
      // 에러 발생해도 무시
      console.debug("fetchAllLabels error", error);
    }
  }, []);

  // 수정시 api 에서 받아온 building 정보로 setValue 처리
  useEffect(() => {
    let refreshes: CommonFacilityModel[] = [];
    const refreshRoomList = productDetail?.buildingList?.map(
      (item) => item.buildingCommonFacility?.refreshRoomList,
    );

    if (refreshRoomList) {
      for (let j = 0; j < refreshRoomList.length; j++) {
        const meetingArr = refreshRoomList[j];

        if (meetingArr) {
          for (let k = 0; k < meetingArr.length; k++) {
            const meetingItem = meetingArr[k];

            refreshes.push(meetingItem);
          }
        }
      }
    }

    if (refreshes) {
      // locaiotn code 로 라벨 목록 조회
      const locationCodes: Array<string> = [];
      for (let i = 0; i < refreshes.length; i++) {
        const locationCode = refreshes[i]?.locationCode;
        if (!!locationCode) {
          locationCodes.push(locationCode);
        }
      }
      if (locationCodes.length > 0) {
        fetchAllLabels(locationCodes);
      }
    }
  }, [fetchAllLabels]);

  return (
    <div className="contents-container__divide-bottom">
      <section className="contents-container__grid contents-container__1200">
        <div className="contents-container__grid-index">
          <p className="">편의시설 선택</p>
        </div>
        <div className="contents-container__grid-contents">
          {productDetail?.buildingList &&
            productDetail?.buildingList.map((building: BuildingModel) => {
              const productBuilding = building;

              const refreshRooms = building.buildingCommonFacility?.refreshRoomList;

              return (
                <div key={productBuilding.id}>
                  {refreshRooms && refreshRooms.length > 0 && (
                    <div className="pt8 mb10">
                      <div className="index-tab">
                        <span>{productBuilding.buildingName}</span>
                      </div>
                      <table className="inner-table" width="100%">
                        <thead>
                          <tr>
                            <th>
                              <span>이미지</span>
                            </th>
                            <th>
                              <span>편의시설 명</span>
                            </th>
                            <th>
                              <span>위치</span>
                            </th>
                            <th>
                              <span>기자재</span>
                            </th>
                            <th>
                              <span>설명</span>
                            </th>
                            <th>
                              <span>ESL</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {refreshRooms &&
                            refreshRooms.map((refresh) => {
                              let mediaList: Array<MediaFile> = [];

                              const pramaryImage = refresh?.mediaList;
                              pramaryImage?.forEach((item) => {
                                if (item.isPrimary === true) {
                                  mediaList.push(item);
                                }
                              });

                              let mediaFile =
                                mediaList.length > 0
                                  ? mediaList[0]
                                  : refresh?.mediaList && refresh?.mediaList[0];

                              const findLabel = eslLabels.find(
                                (eslLabel: EslLabel) =>
                                  eslLabel.locationCode === refresh.locationCode,
                              );

                              return (
                                <tr key={refresh.id}>
                                  <td width="60">
                                    <div
                                      className="flex-center-center no-wrap"
                                      onClick={() => {
                                        // 이미지 원본 링크 새창 열기
                                        if (mediaFile?.key) {
                                          window.open(s3Url + mediaFile.key, "_blank");
                                        }
                                      }}
                                    >
                                      {mediaFile?.key ? (
                                        <ResizedImage
                                          url={s3Url + mediaFile.key}
                                          className="inner-table__img"
                                          alt="편의시설 이미지"
                                        />
                                      ) : (
                                        <img
                                          src={sampleImg}
                                          className="inner-table__img"
                                          alt="편의시설 이미지"
                                        />
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="flex-center-center no-wrap">
                                      <span>{refresh.facilityName}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="flex-center-center no-wrap">
                                      <span>
                                        {refresh.isGround ? "지상" : "지하"}
                                        {refresh.floorNum}층
                                      </span>
                                    </div>
                                  </td>
                                  <td width="70">
                                    <div className="flex-center-center no-wrap">
                                      <div
                                        className={
                                          refresh?.facilityList?.length === 0 ? "not-available" : ""
                                        }
                                      >
                                        {refresh.facilityList?.length &&
                                        refresh?.facilityList?.length > 0 ? (
                                          <BaseTooltip
                                            contents={"입력"}
                                            tooltip={refresh
                                              .facilityList!.map((value: string) => value)
                                              .join(",")}
                                            type={"normal"}
                                          />
                                        ) : (
                                          <span>미입력</span>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td width="70">
                                    <div className="flex-center-center no-wrap">
                                      <div
                                        className={
                                          refresh.description === "" ? "not-available" : ""
                                        }
                                      >
                                        {refresh.description !== "" ? (
                                          <BaseTooltip
                                            contents={"입력"}
                                            tooltip={refresh.description}
                                            type={"normal"}
                                          />
                                        ) : (
                                          <span>미입력</span>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td width="100">
                                    <div className="flex-center-center no-wrap">
                                      <div
                                        className={findLabel === undefined ? "not-available" : ""}
                                      >
                                        {findLabel !== undefined ? (
                                          <BaseTooltip
                                            contents={"입력"}
                                            tooltip={(findLabel?.assignedLabelCodes || []).join(
                                              ",",
                                            )}
                                            type={"normal"}
                                          />
                                        ) : (
                                          <span>미입력</span>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </section>
      {/* Court 소개 내용 */}
      <section className="contents-container__grid contents-container__1200">
        <div className="contents-container__grid-index">
          <p>Court 소개 내용</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <div className="minmax140">
              <span>추가 설명</span>
            </div>
            <p>{productDetail?.refreshRoomDescription}</p>
          </div>
        </div>
      </section>
      {/* 예약 설정 */}
      <section className="contents-container__grid contents-container__1200">
        <div className="contents-container__grid-index">
          <p className="">Taap 예약 설정</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start mb10">
            <div className="minmax140">
              <span>총 예약 시간/월</span>
            </div>

            <p>{productDetail?.refreshRoomMaxReservTimeMonth !== -1 ? "제한있음" : "제한없음"}</p>

            {productDetail?.refreshRoomMaxReservTimeMonth !== -1 && (
              <>
                <span className="font13 text-primary5 mr10">{`( 매월 총`}</span>
                <div className="">
                  <p>{numberToStringWithComma(productDetail?.refreshRoomMaxReservTimeMonth)}</p>
                </div>
                <span className="font13 text-primary5 ml10">{`분 [${showHourMinute(
                  String(productDetail?.refreshRoomMaxReservTimeMonth),
                )}] 예약 가능)`}</span>{" "}
              </>
            )}
          </div>
          <div className="flex-row flex-center-start">
            <div className="minmax140">
              <span>예약 최대 시간/일</span>
            </div>

            <p>{productDetail?.refreshRoomMaxReservTimeDay !== -1 ? "제한있음" : "제한없음"}</p>

            {productDetail?.refreshRoomMaxReservTimeDay !== -1 && (
              <>
                <span className="font13 text-primary5 mr10">{`( 매월 총`}</span>
                <div className="">
                  <p>{numberToStringWithComma(productDetail?.refreshRoomMaxReservTimeDay)}</p>
                </div>
                <span className="font13 text-primary5 ml10">{`분 [${showHourMinute(
                  String(productDetail?.refreshRoomMaxReservTimeDay),
                )}] 예약 가능)`}</span>{" "}
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default RefreshRoomComp;
