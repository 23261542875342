import _ from "lodash";
import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getScheduleRoundNOverdueList } from "src/api/billingSchedule/billingSchedule-api";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { contract_bill_user_errors } from "src/api/public-types";
import { BaseButton, BaseSelect, ContentsIdSection } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { PagePath } from "src/pages/product/details";
import MaintenanceBillingSection from "./components/MaintenanceBillingSection";
import MaintenanceLateFeeSection from "./components/MaintenanceLateFeeSection";

const MaintenanaceBilling = ({
  scheduleMntList,
  contractBaseInfo,
  basicInfo,
  stopObj,
  editable,
}: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const [provider, setProvider] = useState<any>([{ label: "", value: "" }]);
  const [selected, setSelected] = useState("0");
  const [roundList, setRoundList] = useState<any>([]);
  const [overdueList, setOverdueList] = useState<any>([]);
  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundNOverdueList, {
    doNotErrorHandleModal: true,
  });

  const callList = useCallback(
    async (id: string) => {
      if (Number(id) !== 0) {
        setLoadingBar(true);
        const response: any = await getRoundList({ scheduleId: Number(id) });
        //console.log("response", response.data.data);
        if (response && response.status >= 200 && response.status <= 299) {
          const sorted = _.sortBy(response.data.data.content, ["bill.billOrder"]);

          const normal = sorted.filter((sort) => {
            if (sort.bill.isOverdueBill === false) {
              return sort;
            }
          });
          const overdues = sorted.filter((sort) => {
            if (sort.bill.isOverdueBill) {
              return sort;
            }
          });
          //console.log("normal", normal);
          setRoundList(normal);
          setOverdueList(overdues);
        } else {
          let message = errorMessageHandler(
            response.status,
            response.data.meta.errorCode,
            contract_bill_user_errors,
          );
          let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
          openErrorModal(message, code);
        }
        setLoadingBar(false);
      }
    },

    [getRoundList, openErrorModal, setLoadingBar],
  );

  useEffect(() => {
    if (scheduleMntList !== undefined && scheduleMntList.length > 0) {
      const options: any = [];
      //console.log("scheduleMntList", scheduleMntList);
      scheduleMntList.map((schedule: any, index: number) => {
        const option = {
          label: schedule.providerName || "test",
          value: schedule.scheduleId,
        };
        options.push(option);
      });
      setProvider(options);
      setSelected(scheduleMntList[0].scheduleId);
      callList(scheduleMntList[0].scheduleId);
    }
  }, [scheduleMntList]);

  return (
    <div className="contents-container__wrap detail-basic-info">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection title="관리비 빌링 현황" noTitle="신청번호" />
        <div className="minmax140">
          <BaseSelect
            stateOptions={provider}
            value={selected}
            setStateValue={async (value: string) => {
              await callList(value);
            }}
          />
        </div>

        <article className="pb20">
          {/* 관리비 빌링 현황 */}
          <MaintenanceBillingSection
            contractBaseInfo={contractBaseInfo}
            callList={callList}
            setLoadingBar={setLoadingBar}
            roundList={roundList}
            basicInfo={basicInfo}
            stopObj={stopObj}
            editable={editable}
            scheduleId={selected}
          />

          {/* 관리비 연체 현황 */}
          <MaintenanceLateFeeSection
            contractBaseInfo={contractBaseInfo}
            callList={callList}
            setLoadingBar={setLoadingBar}
            roundList={roundList}
            basicInfo={basicInfo}
            stopObj={stopObj}
            editable={editable}
            overdueList={overdueList}
            scheduleId={selected}
          />
        </article>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <BaseButton
            title="목록으로"
            onClick={() => navigate(PagePath.billing.list)}
            className="color-white size-large"
          />
          <BaseButton
            title="상세"
            className="ml8 color-white size-large"
            onClick={() => {
              navigate(
                PagePath.billing.detail.replace(":id", String(params.id) + "?tab=maintenance"),
              );
            }}
          />
        </div>
        <div className="right-area"></div>
      </div>
    </div>
  );
};

export default MaintenanaceBilling;
