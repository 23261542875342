import { Cell } from "react-table";
import { PaPlatformDevice } from "src/api/iot/iot-types";
import { BaseCheckbox } from "src/components";

interface CellProps extends Cell<PaPlatformDevice> {
  checked: boolean;
}

export const ST_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 80,
    Cell: (props: CellProps) => {
      const checkedId = props.row.original.id;
      const isDisabled = props.row.original.registeredDeviceId ? true : false;
      return (
        <div
          data-checked-id={checkedId}
          data-checked={props.value}
          data-disabled={isDisabled}
          className="checkbox"
        >
          <BaseCheckbox id={""} name="" checked={props.value} disabled={isDisabled} />
        </div>
      );
    },
  },
  {
    Header: "SmartThings 계정",
    accessor: "platformAccount.email",
    width: 220,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "Location",
    accessor: "locationName",
    width: 100,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "Device ID",
    accessor: "id",
    width: 300,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "Name",
    accessor: "name",
    width: 100,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "ST-Label",
    accessor: "label",
    width: 100,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "Type",
    accessor: "type",
    width: 100,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
];
export const B_IOT_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 80,
    Cell: (props: CellProps) => {
      const checkedId = props.row.original.id;
      const isDisabled = props.row.original.registeredDeviceId ? true : false;
      return (
        <div
          data-checked-id={checkedId}
          data-checked={props.value}
          data-disabled={isDisabled}
          className="checkbox"
        >
          <BaseCheckbox id={""} name="" checked={props.value} disabled={isDisabled} />
        </div>
      );
    },
  },
  {
    Header: "b.IoT 계정",
    accessor: "platformAccount.email",
    width: 200,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "Device ID",
    accessor: "id",
    width: 300,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "Name",
    accessor: "name",
    width: 220,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "Type",
    accessor: "type",
    width: 200,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
];

export const COLLECTIVE_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 80,
    Cell: (props: CellProps) => {
      const checkedId = props.row.original.id;
      const isDisabled = props.row.original.registeredDeviceId ? true : false;
      return (
        <div
          data-checked-id={checkedId}
          data-checked={props.value}
          data-disabled={isDisabled}
          className="checkbox"
        >
          <BaseCheckbox id={""} name="" checked={props.value} disabled={isDisabled} />
        </div>
      );
    },
  },
  {
    Header: "제어 구분",
    accessor: "method",
    width: 80,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "제어명",
    accessor: "displayName",
    width: 140,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "ComponentId",
    accessor: "componentId",
    width: 150,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "Capability Id",
    accessor: "capabilityId",
    width: 170,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "Command",
    accessor: "command",
    width: 130,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: (props: CellProps) => <p className="no-wrap">Arguments</p>,
    accessor: "arguments",
    width: 80,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "설명",
    accessor: "description",
    width: 150,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
];
