import { Cell } from "react-table";
import { MemberModel } from "src/api/member/member-types";
import {
  MemberJoinDateCell,
  MemberOrganizationCell,
  MemberStatusCell,
  MemberUidCell,
} from "../components/";
import { formatPhoneNumber } from "src/utils";

/* 
  공간상품 테이블 columns
*/
const MemberColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 100,
    Cell: (props: Cell<MemberModel>) => <MemberUidCell {...props} />,
  },
  {
    Header: "회원번호",
    accessor: "memberNo",
    width: 100,
    Cell: (props: Cell<MemberModel>) => <MemberUidCell {...props} />,
  },
  {
    Header: "닉네임",
    accessor: "nickname",
    width: 100,
    Cell: ({ value }: Cell<MemberModel>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{value ? value : "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "휴대폰 번호",
    accessor: "phoneNumber",
    width: 150,
    Cell: ({ value }: Cell<MemberModel>) => {
      return (
        <div className="w-100">
          <p>{value ? formatPhoneNumber(value) : "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "이메일",
    accessor: "email",
    width: 200,
    Cell: (props: Cell<MemberModel>) => (
      <div className="w-100 text-left">
        <MemberUidCell {...props} />
      </div>
    ),
  },
  {
    Header: "이메일 인증",
    accessor: "emailVerified",
    width: 100,
    Cell: ({ row }: Cell<MemberModel>) => {
      const emailStates = row.original.states;

      const emailVerified = emailStates.some((item) => item.status === "EMAIL_VERIFIED");
      return (
        <div>
          <p>{emailVerified === true ? "인증" : "미인증"}</p>
        </div>
      );
    },
  },
  {
    Header: "가입일",
    accessor: "states",
    width: 140,
    Cell: (props: Cell<MemberModel>) => <MemberJoinDateCell {...props} />,
  },
  {
    Header: "계정상태",
    accessor: "accountState", // api 스펙에 없음
    Cell: (props: Cell<MemberModel>) => <MemberStatusCell {...props} />,
  },
];

export default MemberColumns;
