import { Control, Controller, useFieldArray, UseFormRegister } from "react-hook-form";
import { BaseInput, BaseMobileNumberInput } from "src/components";
import { validateCarNumber, validateMobileNumber } from "src/utils";
import { Visitor, VisitorFormData } from "../../visitor-types";

type Props = {
  control: Control<VisitorFormData>;
  register: UseFormRegister<VisitorFormData>;
};

/* 
  방문자 > 방문자 초대 (등록, 수정) > 방문자 등록 목록 컴포넌트
*/
const VisitorsSection = ({ control, register }: Props) => {
  // 방문자 목록
  const {
    fields: visitors,
    append: appendVisitor,
    remove: removeVisitor,
    update: updateVisitor,
  } = useFieldArray({
    control,
    name: "visitors",
  });

  return (
    <>
      {visitors.map((visitor: Visitor & { id: string }, index: number) => (
        <section key={visitor.id} className="flex-start mb10">
          <div className="border-gray w-fit pa20">
            <div className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">이름</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <div className="flex-center ">
                    <div className="minmax200 mr10">
                      <Controller
                        control={control}
                        name={`visitors.${index}.visitorName`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <BaseInput
                            maxLength={20}
                            onChange={onChange}
                            value={value}
                            name={name}
                            readonly={visitor.contractVisitorId !== "0"}
                            errorText={error?.message}
                          />
                        )}
                      ></Controller>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">휴대폰</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <div className="flex-center ">
                    <div className="minmax200 mr10">
                      <Controller
                        control={control}
                        name={`visitors.${index}.visitorMobileNumber`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <BaseMobileNumberInput
                            maxLength={13}
                            onChange={onChange}
                            value={value}
                            name={name}
                            readonly={visitor.contractVisitorId !== "0"}
                            errorText={error?.message}
                          />
                        )}
                      ></Controller>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contents-container__grid mb0">
              <div className="contents-container__grid-index">
                <p>차량</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <div className="flex-center ">
                    <div className="minmax200 mr10">
                      <Controller
                        control={control}
                        name={`visitors.${index}.visitorCarNumber`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <BaseInput
                            maxLength={8}
                            onChange={onChange}
                            value={value}
                            name={name}
                            readonly={visitor.contractVisitorId !== "0"}
                            errorText={error?.message}
                          />
                        )}
                      ></Controller>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {index === 0 && (
            <button
              type="button"
              className="base-add-btn ml20 mt16"
              onClick={() => {
                appendVisitor({
                  contractVisitorId: "0", // 신규추가일경우 0으로
                  visitorName: "",
                  visitorMobileNumber: "",
                  visitorCarNumber: "",
                });
                register(`visitors.${visitors.length}.visitorName`, {
                  required: "필수입력항목입니다",
                });
                register(`visitors.${visitors.length}.visitorMobileNumber`, {
                  validate: {
                    visitorMobileNumber: (visitorMobileNumber?: string) => {
                      return validateMobileNumber(visitorMobileNumber);
                    },
                  },
                });
                register(`visitors.${visitors.length}.visitorCarNumber`, {
                  validate: {
                    visitorCarNumber: (visitorCarNumber?: string) => {
                      return validateCarNumber(visitorCarNumber);
                    },
                  },
                });
              }}
            ></button>
          )}

          {(index > 0 || visitors.length === 1) && (
            <button
              type="button"
              className={`base-erase-btn mt16 ${index > 0 ? "ml20" : "ml10"}`}
              onClick={() => {
                if (index === 0) {
                  updateVisitor(0, {
                    contractVisitorId: "0", // 신규추가
                    visitorName: "",
                    visitorMobileNumber: "",
                    visitorCarNumber: "",
                  });
                } else {
                  removeVisitor(index);
                }
              }}
            ></button>
          )}
        </section>
      ))}
    </>
  );
};
export default VisitorsSection;
