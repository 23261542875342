import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import {
  SearchParams,
  searchStatusOption,
  searchTypeOption,
} from "src/api/billingSchedule/billing-type";
import { getScheduleList } from "src/api/billingSchedule/billingSchedule-api";
import { ContractStep } from "src/api/contract/contract-types";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { getOrgList } from "src/api/organization/org-api";
import { MbOrg } from "src/api/organization/org-types";
import { PageMeta, Select, contract_bill_user_errors } from "src/api/public-types";
import {
  BaseDatePicker,
  BaseInput,
  BasePagination,
  BaseRadio,
  BaseSelect,
  BaseTooltip
} from "src/components";
import BaseMultiSelect from "src/components/BaseMultiSelect";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import SearchBuildingPopup from "src/pages/commonPopup/SearchBuildingPopup";
import SearchOrgPopup from "src/pages/commonPopup/SearchOrgPopup";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { YmdFormat } from "src/utils";
import BillIngScheduleColumns from "./columns/Columns";

const searchStatusOption2: Select[] = [
  { label: "전체", value: "" },
  // { label: "신청접수", value: ContractStep.APPLY_RECEIVED },
  { label: "신청취소", value: ContractStep.APPLY_CANCEL },
  { label: "신청확인", value: ContractStep.APPLY_CONFIRM },
  { label: "계약예정", value: ContractStep.CONTRACT_PLAN },
  { label: "계약체결", value: ContractStep.CONTRACT_ACCEPT },
  { label: "이용승인", value: ContractStep.USE_APPROVAL },
  { label: "이용중", value: ContractStep.USE_PROGRESS },
  { label: "이용완료", value: ContractStep.USE_COMPLETE },
  { label: "해지접수", value: ContractStep.TERMINATE_RECEIVED },
  { label: "해지완료", value: ContractStep.TERMINATE_COMPLETE },
];
const orgColumns: any = [
  {
    Header: "선택",
    accessor: "isSelected",
    width: 80,
    Cell: ({ row, setSelectedOrg }: any) => {
      const changeTrigger = useCallback(() => {
        setSelectedOrg(row.original);
      }, [row.original, setSelectedOrg]);

      return (
        <BaseRadio
          id={`selector${row.original.id}`}
          name={"isSelected"}
          onChange={changeTrigger}
          value={row.original.isSeleted}
        />
      );
    },
  },
  {
    Header: "id",
    accessor: "id",
    width: 80,
  },
  {
    Header: "법인명/상호",
    accessor: "name",
    width: 300,
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left"><BaseTooltip contents={value} /></div>;
    },
  },
];
const BillingScheduleList = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { executeAsync } = useApiOperation(getScheduleList, { doNotErrorHandleModal: true });
  const { executeAsync: getOrgListApi } = useApiOperation(getOrgList);
  const [data, setData] = useState<MbOrg[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [visible, setVisible] = useState(false);
  const [partners, setPartners] = useState<any>([]);
  const [building, setBuilding] = useState<any>({ buildingName: "" });
  const [isBuildingOpen, setIsBuildingOpen] = useState(false);
  const [org, setOrg] = useState<any>({ name: "" });
  const [isOrgOpen, setIsOrgOpen] = useState(false);

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParam: SearchParams = useMemo(() => {
    // console.log('location.search',location.search);
    const _queryParams: SearchParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "modifiedDate", direction: "DESC" }],
      };
    }

    return _queryParams;
  }, [location.search]);

  // console.log('qs params',queryParams);
  const [params, setParams] = useState<any>({ ...queryParam });

  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (passParams?: SearchParams) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true, encode: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, params],
  );

  const settingBuilding = useCallback(
    (bd: any) => {
      setIsBuildingOpen(false);
      setBuilding(bd);
      setParams({ ...params, ...{ buildingId: bd.id, buildingName: bd.buildingName } });
      console.log(bd);
    },
    [params],
  );

  const settingOrg = useCallback(
    (org: any) => {
      setIsOrgOpen(false);
      setOrg(org);
      setParams({ ...params, ...{ mbOrganizationId: org.id, orgName: org.name } });
    },
    [params],
  );

  const settingPartners = useCallback(
    (partners: any) => {
      //console.log("partners", partners);
      setPartners(partners[0]);
      setParams({ ...params, ...{ partnerId: partners[0].id, partnerName: partners[0].name } });
      setVisible(false);
    },
    [params],
  );
  const closePartnerPopup = () => {
    setVisible(false);
  };

  const fetchList = useCallback(
    async (params: any) => {
      setLoadingBar(true);
      let send: any = _.cloneDeep(params);

      if (send.searchStartTime) {
        send.searchStartTime = send.searchStartTime.replace(" ", "+");
      }
      if (send.searchEndTime) {
        send.searchEndTime = send.searchEndTime.replace(" ", "+");
      }
      if (send.buildingName === "") {
        delete send.buildingId;
      }
      if (send.orgName === "") {
        delete send.mbOrganizationId;
      }
      const response: any = await executeAsync(send);

      if (response.status >= 200 && response.status <= 299) {
        let callIds = "";
        let orgList: any = [];
        response.data.data.content.map((item: any) => {
          if (item.mbOrganizationId !== "0") {
            if (callIds !== "") {
              callIds = callIds + "," + item.mbOrganizationId;
            } else {
              callIds = item.mbOrganizationId;
            }
          }
        });

        if (callIds !== "") {
          const res: any = await getOrgListApi({ id: callIds });
          orgList = res.data.data.content;
          console.info("res", res);
        }

        response.data.data.content.map((item: any) => {
          const finded = orgList.find(
            (org: any) => String(org.id) === String(item.mbOrganizationId),
          );
          if (finded) {
            item.mbOrganizationName = finded.name;
          }

          return item;
        });
        console.log("size ===", response.data.data.content.length);
        setData(response.data.data.content);
        const { pageMeta: pm } = response.data.meta;
        setPageMeta(pm);
      } else {
        let message = errorMessageHandler(
          response.status,
          response.data.meta.errorCode,

          contract_bill_user_errors,
        );
        let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
        openErrorModal(message, code);
      }
      setLoadingBar(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [executeAsync],
  );
  console.log("data ===", data.length);
  useEffect(() => {
    fetchList(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: BillIngScheduleColumns,
      data: data,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useSticky,
    usePagination,
    useRowSelect,
  );

  const returnContractSteps = useMemo(() => {
    let rtn: any = [];
    // console.log("params.contractStep", params.contractStep);
    if (params.contractStep !== undefined && params.contractStep !== "") {
      rtn = params.contractStep.split(",");
    }

    return rtn;
  }, [params.contractStep]);

  return (
    <>
      <div className="page-product-list">
        {/* 로딩바 */}
        {/* <ContentsTitle title="빌링 스케쥴" /> */}
        <div className="contents-container__search-wrap">
          <div className="left-area">
            {/* <p>
                전체 <span>{pageMeta?.totalElements || 0}</span>
              </p> */}
            <div className="minmax140 mr8">
              <BaseInput
                type="text"
                value={decodeURIComponent(params?.partnerName || "")}
                placeholder="파트너명"
                readonly={true}
                onKeyUp={() => setVisible(true)}
                onClearClick={() => {
                  const temp = _.cloneDeep(params);
                  delete temp.partnerName;
                  delete temp.partnerId;
                  setParams({ ...temp });
                }}
                onSearchClick={() => setVisible(true)}
              />
              {visible && (
                <PartnerSelectModal
                  defaultValues={partners || []}
                  onAdded={settingPartners}
                  onCanceled={closePartnerPopup}
                />
              )}
            </div>
            <div className="minmax140 mr8">
              <BaseInput
                type="text"
                value={decodeURIComponent(params?.buildingName || "")}
                placeholder="건물 선택"
                onChange={(keyword: string) => {
                  const temp = _.cloneDeep(building);
                  if (temp) {
                    temp.buildingName = keyword;
                    setBuilding(temp);
                  } else {
                    const temp2: any = {
                      buildingName: keyword,
                    };
                    setBuilding(temp2);
                  }
                  setParams({ ...params, ...{ buildingName: keyword } });
                }}
                onKeyUp={() => setIsBuildingOpen(true)}
                onSearchClick={() => setIsBuildingOpen(true)}
              />
              <SearchBuildingPopup
                isOpen={isBuildingOpen}
                buildingName={params?.buildingName}
                onClick={settingBuilding}
                onClose={() => setIsBuildingOpen(false)}
              />
            </div>
            <div className="minmax140 mr8">
              <BaseInput
                type="text"
                value={decodeURIComponent(params?.orgName || "")}
                placeholder="사업자 선택"
                onChange={(keyword: string) => {
                  const temp = _.cloneDeep(org);
                  if (temp) {
                    temp.name = keyword;
                    setOrg(temp);
                  } else {
                    const temp2: any = {
                      name: keyword,
                    };
                    setOrg(temp2);
                  }
                  setParams({ ...params, ...{ orgName: keyword } });
                }}
                onKeyUp={() => setIsOrgOpen(true)}
                onSearchClick={() => setIsOrgOpen(true)}
              />
              <SearchOrgPopup
                isOpen={isOrgOpen}
                name={params?.orgName}
                columns={orgColumns}
                onClick={settingOrg}
                onClose={() => setIsOrgOpen(false)}
              />
            </div>

            <div className="minmax240 flex-center mr8">
              <BaseDatePicker
                name="searchStartTime"
                type="date"
                selectedDate={
                  params.searchStartTime &&
                  params.searchStartTime !== null &&
                  params.searchStartTime !== ""
                    ? moment(params.searchStartTime).toDate()
                    : null
                }
                setDate={(searchStartTime: string) => {
                  //console.log("searchStartTime", searchStartTime);
                  let select = "";
                  if (
                    searchStartTime !== "" &&
                    searchStartTime !== null &&
                    searchStartTime !== "Invalid date"
                  ) {
                    select =
                      moment(searchStartTime).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00";
                  }
                  //console.log("select", select);
                  setParams({
                    ...params,
                    ...{ searchStartTime: select },
                  });
                }}
              />
              <span className="mx8 font14">~</span>
              <BaseDatePicker
                name="searchEndTime"
                type="date"
                selectedDate={
                  params.searchEndTime && params.searchEndTime !== ""
                    ? moment(params.searchEndTime).toDate()
                    : null
                }
                setDate={(searchEndTime: string) => {
                  let select = "";
                  if (
                    searchEndTime !== "" &&
                    searchEndTime !== null &&
                    searchEndTime !== "Invalid date"
                  ) {
                    select =
                      moment(searchEndTime).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00";
                  }

                  //console.log("select", select);
                  setParams({
                    ...params,
                    ...{
                      searchEndTime: select,
                    },
                  });
                }}
              />
            </div>
            <div className="minmax120 mr8">
              <BaseMultiSelect
                placeholder="계약상태"
                stateOptions={searchStatusOption2}
                value={returnContractSteps || []}
                setStateValue={(options: Array<{ value: string; label: string }>) => {
                  let status = "";
                  options.map((obj: { value: string; label: string }) => {
                    if (status === "") {
                      status = obj.value;
                    } else {
                      status += "," + obj.value;
                    }
                  });
                  let dump = _.cloneDeep(params);
                  dump.page = 0;
                  dump.contractStep = status;
                  navigateWithQueryParams(dump);
                }}
              />
            </div>
            <div className="minmax120 mr8">
              <BaseSelect
                value={String(params.isOverdue)}
                placeholder="연체상태"
                stateOptions={[
                  { label: "전체", value: "" },
                  { label: "연체", value: "true" },
                  { label: "정상", value: "false" },
                ]}
                setStateValue={(isOverdue: string) => {
                  if (isOverdue !== "") {
                    let flag = isOverdue === "true" ? true : false;
                    setParams({ ...params, isOverdue: flag });
                  } else {
                    const temp = _.cloneDeep(params);
                    delete temp.isOverdue;
                    setParams({ ...temp });
                  }
                }}
              />
            </div>
            <div className="minmax120 mr8">
              <BaseSelect
                value={params.billPayStatus}
                placeholder="청구 상태"
                stateOptions={searchStatusOption}
                setStateValue={(billPayStatus: string) => setParams({ ...params, billPayStatus })}
              />
            </div>
            <div className="minmax140 mr8">
              <BaseSelect
                value={params.searchType}
                stateOptions={searchTypeOption}
                setStateValue={(searchType: string) => setParams({ ...params, searchType })}
              />
            </div>
            <div className="minmax220 mr16">
              <BaseInput
                type="text"
                value={decodeURIComponent(params?.searchValue || "")}
                placeholder="검색어를 입력해주세요"
                onChange={(searchValue: string) => setParams({ ...params, searchValue })}
                onKeyUp={() => navigateWithQueryParams({ page: 0 })}
                onSearchClick={() => navigateWithQueryParams({ page: 0 })}
              />
            </div>
          </div>
          <div className="right-area"></div>
        </div>

        {/* <BaseTable
            data={data}
            columns={BillIngScheduleColumns}
            pageIndex={Number(pageMeta?.pageRequest.page) || 0}
            totalPages={Number(pageMeta?.totalPages) || 0}
            goPage={(page: number) => {
              navigateWithQueryParams({ page });
            }}
            disabledSortHeaders={["imagePath"]}
            orders={params.sort?.orders}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                navigateWithQueryParams({ sort: { orders } });
              }
            }}
          /> */}
        {data !== undefined && (
          // <BaseTable
          //   data={data}
          //   columns={BillIngScheduleColumns}
          //   pageIndex={Number(params?.page) || 0}
          //   totalPages={Number(pageMeta?.totalPages) || 0}
          //   totalElements={pageMeta?.totalElements || 0}
          //   goPage={(page: number) => {
          //     navigateWithQueryParams({ ...params, ...{ page } });
          //   }}
          //   disabledSortHeaders={["imagePath"]}
          //   orders={params.sort?.orders}
          //   setOrders={(orders?: Array<Order>) => {
          //     if (orders) {
          //       navigateWithQueryParams({ ...params, ...{ sort: { orders } } });
          //     }
          //   }}
          // />
          <div className="contents-container__table two-depth-header">
            {/* 세부 부과항목 */}
            {/* 2depth 테이블 */}
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header: any) => {
                      // sticky 기준을 맞추기 위해 rowspan 처럼 보이는 헤더는 무조건 columns를 1개 가지고 있어야함.
                      // 스티키가 아닌 rowspan 헤더 케이스
                      if (
                        !header.parent &&
                        header.depth === 0 &&
                        header.columns?.length === 1 &&
                        !header.sticky
                      ) {
                        return (
                          <div {...header.getHeaderProps()} className="base-table__th">
                            <div className="w-100 no-parent" style={{ zIndex: 2 }}>
                              {header.render("Header")}
                            </div>
                          </div>
                        );
                      } else if (header.sticky === "left" || header.sticky === "right") {
                        // 스티키 상태인 rowspan 케이스
                        return (
                          <div
                            {...header.getHeaderProps()}
                            className="base-table__th"
                            style={{ ...header.getHeaderProps().style, zIndex: 4 }}
                          >
                            <div className="w-100 sticky-parent" style={{ zIndex: 2 }}>
                              {header.render("Header")}
                            </div>
                            {/* <div className="ic_sort"></div> */}
                          </div>
                        );
                      } else {
                        return (
                          <div {...header.getHeaderProps()} className="base-table__th">
                            <div className=" w-100 ">{header.render("Header")}</div>
                          </div>
                        );
                      }
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.map((row: any) => {
                  prepareRow(row);

                  return (
                    <div
                      {...row.getRowProps()}
                      className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <BasePagination
              pageIndex={Number(params.page) || 0}
              totalPages={Number(pageMeta?.totalPages) || 0}
              goPage={(page: number) => {
                navigateWithQueryParams({ ...params, ...{ page } });
              }}
            ></BasePagination>
          </div>
        )}
      </div>
    </>
  );
};
export default BillingScheduleList;
