import { useNavigate, useParams } from "react-router-dom";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { BaseButton, ContentsIdSection } from "src/components";
import pagePath from "src/pagePath.json";
import ScheduleInfoColumns from "./columns/ScheduleInfoColumns";
import OverdueInfo from "./OverdueInfo";

const ScheduleInfo = ({
  basicInfo,
  callList,
  contractBaseInfo,
  roundList,
  scheduleId,
  overdueList,
  setLoadingBar,
  stopObj,
}: any) => {
  const params = useParams();
  // console.log('roundList',roundList.length);
  const navigate = useNavigate();
  // console.log("overdueList", overdueList);
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, page, prepareRow } =
    useTable(
      {
        columns: ScheduleInfoColumns,
        data: roundList,
        initialState: { pageSize: 1000 },
        contractBaseInfo,
        callList,
        setLoadingBar,
        basicInfo,
        stopObj,
        scheduleId,
      },
      useBlockLayout,
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect,
      useSticky,
    );
  return (
    <div className="contents-container__wrap detail-basic-info">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection
          title="이용료 빌링/연체 현황"
          id={basicInfo?.contractId}
          noTitle="신청번호"
          no={contractBaseInfo?.contractApplyNumber}
          // status={basicInfo?.step}
        />

        <article className="pb20">
          <div>
            <div className="contents-container__sub-title">
              <h2>이용료 빌링 현황</h2>
            </div>
            <div className="mt20">
              <div {...getTableProps()} className="base-table sticky">
                <div className="header">
                  {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                      {headerGroup.headers.map((header) => {
                        return (
                          <div {...header.getHeaderProps()} className="base-table__th">
                            {header.render("Header")}
                            {/* <div className="ic_sort"></div> */}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
                <div {...getTableBodyProps()} className="body">
                  {page.map((row: any) => {
                    prepareRow(row);
                    return (
                      <div
                        {...row.getRowProps()}
                        className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                      >
                        {row.cells.map((cell: any) => {
                          return (
                            <div {...cell.getCellProps()} className="base-table__td">
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <div>
                  {roundList &&
                    roundList.length > 0 &&
                    footerGroups.map((group) => {
                      return (
                        <div {...group.getFooterGroupProps()}>
                          {group.headers.map((column) => (
                            <div
                              {...column.getFooterProps()}
                              className="base-table__td base-table-footer"
                            >
                              {column.render("Footer")}
                            </div>
                          ))}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </article>
        <OverdueInfo
          basicInfo={basicInfo}
          overdueList={overdueList}
          callList={callList}
          contractBaseInfo={contractBaseInfo}
          setLoadingBar={setLoadingBar}
          scheduleId={scheduleId}
        />
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <BaseButton
            title="목록으로"
            onClick={() => navigate(pagePath.billing.list)}
            className="color-white size-large mr10"
          />
          <BaseButton
            title="상세"
            className="color-white size-large"
            onClick={() => {
              navigate(pagePath.billing.detail.replace(":id", String(params.id) + "?tab=schedule"));
            }}
          />
        </div>
        <div className="right-area"></div>
      </div>
    </div>
  );
};

export default ScheduleInfo;
function openErrorModal(message: string, code: any) {
  throw new Error("Function not implemented.");
}
