import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import {
  getContractApplicationInfo,
  getContractBuildingRooms,
  getContractDetailBasicInfo,
  getContractInfo,
  getContractOperationInfo,
  getContractProductSnapshotDetailPopup,
} from "src/api/contract/contract-api";
import { ContractDetailBasicInfo, ContractStep } from "src/api/contract/contract-types";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { contract_bill_user_errors } from "src/api/public-types";
import { BaseModal, ContentsTitle } from "src/components";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { checkContractStatus } from "../contract-util";
import ContractApplication from "./components/application/ContractApplication";
import ContractBasicInfo from "./components/basicInfo/ContractBasicInfo";
import ContractDetail from "./components/contractDetail/ContractDetail";
import ContractFormManage from "./components/formManage/ContractFormManage";
const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "application", label: "신청" },
  { value: "contract", label: "계약" },
  { value: "manage", label: "운영" },
];

const ContractDetailMain = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [basicInfo, setBasicInfo] = useState<ContractDetailBasicInfo>();
  const [application, setApplication] = useState<any>();
  const [contract, setContract] = useState<any>();
  const [rooms, setRooms] = useState<any>();

  const [isOperationCheck, setIsOperationCheck] = useState(false);

  const [message, setMessage] = useState("");

  const [spaceProductType, setSpaceProductType] = useState("");
  const [productDetail, setProductDetail] = useState<any>();
  // const [step, setStep] = useState("");
  const { executeAsync } = useApiOperation(getContractDetailBasicInfo, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getApplication } = useApiOperation(getContractApplicationInfo, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getContract } = useApiOperation(getContractInfo, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getOperation } = useApiOperation(getContractOperationInfo, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getRooms } = useApiOperation(getContractBuildingRooms);

  const { executeAsync: getSnapshot } = useApiOperation(getContractProductSnapshotDetailPopup);

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  const getSnapShotData = useCallback(
    async (contractId: number) => {
      return await getSnapshot({ id: Number(contractId) });
    },
    [getSnapshot],
  );

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    async (tab: { value: string; label: string }) => {
      // console.log("clickTab", tab);
      const newQueryParams = { ...queryParams };

      if (
        tab.value === "manage" &&
        (basicInfo?.contractStep === ContractStep.APPLY_RECEIVED ||
          basicInfo?.contractStep === ContractStep.APPLY_CONFIRM)
      ) {
        setIsOperationCheck(true);
        setMessage("현재 계약 상태에서는 운영 데이터가 생성되지 않아 운영탭을 열람할 수 없습니다.");
        return;
      } else {
        if (basicInfo?.contractStep === ContractStep.APPLY_CANCEL && tab.value === "manage") {
          let res: any = await getOperation({ id: Number(id) });
          if (res.status >= 200 && res.status <= 299) {
            if (newQueryParams?.tab) {
              delete newQueryParams.tab;
            }
            newQueryParams.tab = tab.value;
            // query parameter 에 tab 추가
            const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
            navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
          } else {
            setIsOperationCheck(true);
            setMessage(
              "현재 계약 상태에서는 운영 데이터가 생성되지 않아 운영탭을 열람할 수 없습니다.",
            );
            return;
          }
        } else {
          if (newQueryParams?.tab) {
            delete newQueryParams.tab;
          }
          newQueryParams.tab = tab.value;
          // query parameter 에 tab 추가
          const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
          navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
        }
      }
    },
    [queryParams, basicInfo?.contractStep, getOperation, id, navigate, location.pathname],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      // console.log("clickBreadCrumb", crumb);
      if (crumb.value === "contract") {
        navigate(PagePath.contract.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "contract", label: "신청/계약" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  const callBasicData = useCallback(
    async (id: number) => {
      setLoadingBar(true);
      const response: any = await executeAsync({ id: Number(id) });

      if (response.status >= 200 && response.status <= 299) {
        response.data.data.step = checkContractStatus(response.data.data.contractStep);

        setSpaceProductType(response.data.data.spaceProductType);
        setBasicInfo(response.data.data);
      } else {
        let message = errorMessageHandler(
          response.status,
          response.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;

        openErrorModal(message, code);
      }

      const res2: any = await getRooms({ contractId: id });
      // console.log("res2", res2);

      if (res2.status > 199 && res2.status < 300) {
        setRooms(res2.data.data.content);
      } else {
        let message = errorMessageHandler(
          res2.status,
          res2.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = res2.data.meta.errorCode ? res2.data.meta.errorCode : res2.status;

        openErrorModal(message, code);
      }

      const snapshot: any = await getSnapShotData(Number(id));
      if (snapshot.status >= 200 && snapshot.status <= 299) {
        let productData: any = {};
        let rentalCostList: any = [];

        console.log("snapShotResponse", snapshot);
        const filteredProduct = snapshot.data.data.content.filter(
          (product: any) => product.domain === "product",
        );
        const rentalCost = snapshot.data.data.content.find(
          (product: any) => product.domain === "rentalCost",
        );

        filteredProduct.forEach((pr: any) => {
          productData[pr.attrName] = pr.attrValue;
          productData.id = pr.domainId;
        });
        if (rentalCost) {
          rentalCostList.push({ value1: rentalCost.attrValue });
          productData.rentalCostList = rentalCostList;
        }
        console.log("productData", productData);
        setProductDetail(productData);
      } else {
        let message = errorMessageHandler(
          snapshot.status,
          snapshot.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = snapshot.data.meta.errorCode ? snapshot.data.meta.errorCode : snapshot.status;

        openErrorModal(message, code);
      }

      const res: any = await getApplication({ id: Number(id) });
      console.log("response", res);
      if (res.status >= 200 && res.status <= 299) {
        setApplication(res.data.data);
      } else {
        let message = errorMessageHandler(
          res.status,
          res.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.status;

        openErrorModal(message, code);
      }

      const re: any = await getContract({ id: Number(id) });
      if (re.status >= 200 && re.status <= 299) {
        if (re.data.data.scheduleList && re.data.data.scheduleList.length > 0) {
          const rental: any = re.data.data.scheduleList.find(
            (obj: any) => obj.supplyType === "RENTAL",
          );
          re.data.data.schedule = rental;
          re.data.data.scheduleList = re.data.data.scheduleList.filter((obj: any) => {
            if (obj.supplyType !== "RENTAL") {
              obj.isModifyOpen = false;
              return obj;
            }
          });
        }
        re.data.data.scheduleList.forEach((sc: any) => {
          sc.isOverdueOpen = false;
        });
        // console.log("responseDetail.data.data", re.data.data);
        setContract(re.data.data);
      } else {
        let message = errorMessageHandler(
          re.status,
          re.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = re.data.meta.errorCode ? re.data.meta.errorCode : re.status;

        openErrorModal(message, code);
      }

      setLoadingBar(false);
    },
    [executeAsync, getApplication, getContract, openErrorModal, setLoadingBar],
  );

  useEffect(() => {
    if (id) {
      callBasicData(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // title 에 건물명 바인딩
  let contractApplyNumber;
  if (basicInfo?.contractApplyNumber) {
    contractApplyNumber = basicInfo?.contractApplyNumber;
  }
  useTitleOperation(contractApplyNumber);

  return (
    <div className="page-contract-detail">
      <ContentsTitle
        title="신청/계약"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />
      <div className="contents-container__scroll">
        {activeTab?.value === "basicInfo" && (
          <ContractBasicInfo
            basicInfo={basicInfo}
            application={application}
            contract={contract}
            spaceProductType={spaceProductType}
          />
        )}
        {activeTab?.value === "application" && (
          <ContractApplication
            basicInfo={basicInfo}
            application={application}
            spaceProductType={spaceProductType}
            rooms={rooms}
          />
        )}
        {activeTab?.value === "contract" && (
          <ContractDetail
            basicInfo={basicInfo}
            application={application}
            contract={contract}
            setContract={setContract}
            productDetail={productDetail}
            spaceProductType={spaceProductType}
          />
        )}
        {/* 운영 */}
        {activeTab?.value === "manage" && (
          <ContractFormManage
            basicInfo={basicInfo}
            application={application}
            spaceProductType={spaceProductType}
          />
        )}
        {/* {activeTab?.value === "adminMemo" && (
          <AdminMemoIntegration
            serviceId={Number(basicInfo?.contractId)}
            serviceType={ServiceTypes.SERVICE_CONTRACT}
            viewOnly={false}
          />
        )} */}
      </div>

      <BaseModal
        isOpen={isOperationCheck}
        btnRightTitle="확인"
        onClick={() => setIsOperationCheck(false)}
      >
        <p>{message}</p>
      </BaseModal>
    </div>
  );
};

export default ContractDetailMain;
