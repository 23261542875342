import moment from "moment";
import qs from "qs";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getScheduleRoundList,
  getScheduleRoundNOverdueList,
  getScheduleStop,
} from "src/api/billingSchedule/billingSchedule-api";
import { changeContractStatus } from "src/api/contract/contract-api";
import { ContractStep } from "src/api/contract/contract-types";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { contract_bill_user_errors } from "src/api/public-types";
import { BaseButton, BaseModal, ContentsIdSection } from "src/components";
import pagePath from "src/pagePath.json";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { YmdFormat } from "src/utils";
import { getContractMemoList } from "src/api/contract/contract-api";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";

const ContractBasicInfo = ({ basicInfo, application, contract, spaceProductType }: any) => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const { executeAsync: changeCs } = useApiOperation(changeContractStatus, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundNOverdueList, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: scheduleStopCheck } = useApiOperation(getScheduleStop, {
    doNotErrorHandleModal: true,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );
  const [isSaved, setIsSaved] = useState(false);
  //취소 제어 플래그
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  //신청확인 제어 플래그
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  //계약일시
  const [isPlanOpen, setIsPlanOpen] = useState(false);
  const [isPlanOpen2, setIsPlanOpen2] = useState(false);
  //사업자정보
  const [isOrg, setIsOrg] = useState(false);
  //계약정보 항목
  const [isContractMedia, setIsContractMedia] = useState(false);
  //계약체결알림톡
  const [isContractTalk, setIsContractTalk] = useState(false);
  //이용승인 알림톡
  const [isUseTalk, setIsUseTalk] = useState(false);
  //이용승인전 잔금
  const [isUseConfirm, setIsUseConrirm] = useState(false);
  //해지접수
  const [isTerminateTalk, setIsTerminateTalk] = useState(false);
  //빌링 연체 체크
  const [isBillOverdue, setBillOverdue] = useState(false);
  //이용중 알림톡
  const [isUse, setIsUse] = useState(false);
  //이용중 실패 알림톡
  const [isUseFail, setIsUseFail] = useState(false);
  //이용중
  const [isUseProgressTalk, setIsUseProgressTalk] = useState(false);
  //해지완료 알림톡
  const [isTerminateCompleteTalk, setIsTerminateCompleteTalk] = useState(false);
  const [isTerminateCompleteTalkCheckStop, setIsTerminateCompleteTalkCheckStop] = useState(false);
  //빌링 체크
  const [isBillingCheck, setBillingCheck] = useState(false);

  const changeStatus = useCallback(
    async (status: string) => {
      setLoadingBar(true);
      setIsCancelOpen(false);
      setIsConfirmOpen(false);
      setIsPlanOpen(false);
      setIsPlanOpen2(false);
      setIsContractMedia(false);
      setIsContractTalk(false);
      setIsUseTalk(false);
      setIsUseConrirm(false);
      setIsTerminateTalk(false);
      setBillOverdue(false);
      setIsUseProgressTalk(false);
      setIsTerminateCompleteTalk(false);
      setBillingCheck(false);

      const res: any = await changeCs({
        contractId: params.id ? params.id : queryParams.id,
        contractStep: status,
      });
      // console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        // console.log("성공");
        setIsSaved(true);
      } else {
        let message = errorMessageHandler(
          res.status,
          res.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.status;
        openErrorModal(message, code);
      }
      setLoadingBar(false);
    },
    [changeCs, openErrorModal, params.id, setLoadingBar],
  );
  const refresh = useCallback(() => {
    setIsSaved(true);
    const path = pagePath.contract.detail.replace(":id", params!.id!) + "?tab=basicInfo";
    navigate(path);
  }, [navigate, params]);

  const changeStatusCancel = useCallback(() => {
    //TODO 상태인지 체크
    changeStatus(ContractStep.APPLY_CANCEL);
  }, [changeStatus]);

  const changeStatusConfirm = useCallback(() => {
    //TODO 상태인지 체크
    changeStatus(ContractStep.APPLY_CONFIRM);
  }, [changeStatus]);
  const changeStatusPlan = useCallback(() => {
    //TODO 상태인지 체크
    changeStatus(ContractStep.CONTRACT_PLAN);
  }, [changeStatus]);
  const checkApplyDate = useCallback((value: string) => {
    //TODO 계약일시 입력 상태인지 체크
    // console.log('value',value);
    if (value !== "") {
      setIsPlanOpen(true);
    } else {
      setIsPlanOpen2(true);
    }
  }, []);

  const checkContractApply = useCallback(() => {
    if (
      contract?.earnestPayStatus !== "PAY_WAIT" &&
      contract?.contractMediaList &&
      contract?.contractMediaList?.length !== 0
    ) {
      setIsContractTalk(true);
    } else {
      setIsContractMedia(true);
    }
  }, [contract]);

  const changeStatusUseConfirm = useCallback(() => {
    if (spaceProductType !== "TIME_COURT") {
      // 입력 상태인지 체크
      if (contract?.balancePayStatus === "PAY_WAIT" || contract?.earnestPayStatus === "PAY_WAIT") {
        setIsUseConrirm(true);
      } else {
        setIsUseTalk(true);
      }
    } else {
      setIsUseTalk(true);
    }
  }, [contract, spaceProductType]);
  const changeStatusTerminateConfirm = useCallback(async () => {
    setLoadingBar(true);
    console.log("contract", contract);
    let checkOverdueBill = [];
    let payCheck = [];
    //TODO 빌링 상태 체크
    console.log("contract!.scheduleList?.[0]?.scheduleId", contract!.schedule?.scheduleId);
    const res: any = await getRoundList({ scheduleId: contract!.schedule?.scheduleId });
    if (res.status >= 200 && res.status <= 299) {
      const billRounds = res.data.data.content;
      console.log("billRounds", billRounds);
      const today = moment().format(YmdFormat.YYYY_MM_DD);

      if (billRounds) {
        checkOverdueBill = billRounds.filter((round: any) => round.bill.isOverdueBill);
        payCheck = billRounds.filter(
          (round: any) =>
            round.bill.isOverdueBill === false &&
            moment(moment(round.bill.billIssueDate).format(YmdFormat.YYYY_MM_DD)).isBefore(today),
        );
      }
    } else {
      let message = errorMessageHandler(
        res.status,
        res.data.meta.errorCode,
        contract_bill_user_errors,
      );
      let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.status;
      return openErrorModal(message, code);
    }

    console.log("payCheck", payCheck, "checkOverdueBill", checkOverdueBill);
    let pay = false;
    payCheck.map((bill: any) => {
      if (
        bill.bill.billPayStatus !== "PAYMENT_SUCCESS" &&
        bill.bill.billPayStatus !== "PAYMENT_FREE"
      ) {
        pay = true;
      }
    });
    let overdue = false;
    checkOverdueBill.map((bill: any) => {
      if (
        bill.bill.billPayStatus !== "PAYMENT_SUCCESS" &&
        bill.bill.billPayStatus !== "PAYMENT_FREE"
      ) {
        overdue = true;
      }
    });

    //TODO 계약일시 입력 상태인지 체크
    if (pay || overdue) {
      setBillOverdue(true);
    } else {
      setIsTerminateTalk(true);
    }
    setLoadingBar(false);
  }, [contract, getRoundList, openErrorModal, setLoadingBar]);

  //해지완료 변경 시 체크
  const changeStatusTerminateComplete = useCallback(async () => {
    setLoadingBar(true);
    const res: any = await getRoundList({ scheduleId: contract!.schedule?.scheduleId });
    let billRounds: any = [];

    if (res.status >= 200 && res.status <= 299) {
      billRounds = res.data.data.content;
      console.log("billRounds", billRounds);
    } else {
      let message = errorMessageHandler(
        res.status,
        res.data.meta.errorCode,
        contract_bill_user_errors,
      );
      let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.status;
      return openErrorModal(message, code);
    }
    // 빌링스케쥴 중지여부 확인
    const stopCheck: any = await scheduleStopCheck({
      scheduleId: contract?.schedule?.scheduleId,
    });

    console.log(stopCheck.data.data);
    if (stopCheck.status >= 200 && stopCheck.status <= 299) {
      //중지일 경우 오늘 부터 중지 전까지 회차가 모두 수납완료인지 확인

      if (stopCheck.data.data.isContractCancel === "true") {
        console.log("중지상태입니다.");
        // setBillingCheck(true);
        const checkContractCancelStartOrder = billRounds.filter(
          (round: any) =>
            Number(round.bill.billOrder) < Number(stopCheck.data.data.contractCancelStartOrder) &&
            round.bill.billPayStatus !== "PAYMENT_SUCCESS" &&
            round.bill.billPayStatus !== "PAYMENT_FREE",
        );
        console.log("checkContractCancelStartOrder", checkContractCancelStartOrder);
        if (checkContractCancelStartOrder.length > 0) {
          setIsTerminateCompleteTalkCheckStop(true);
        } else {
          setBillingCheck(true);
        }
      } else {
        console.log("중지상태가 아닙니다.");
        //중지가 아닐 경우 모든 회차 검사
        const checkAllBill = billRounds.filter(
          (round: any) =>
            round.bill.billPayStatus !== "PAYMENT_SUCCESS" &&
            round.bill.billPayStatus !== "PAYMENT_FREE",
        );
        console.log("checkAllBill", checkAllBill);
        if (checkAllBill.length > 0) {
          setIsTerminateCompleteTalk(true);
        } else {
          setBillingCheck(true);
        }
      }
    } else {
      setIsTerminateCompleteTalk(true);
    }
    setLoadingBar(false);
  }, [setLoadingBar, getRoundList, contract, scheduleStopCheck, openErrorModal]);

  //이용중 변경 시 이용 시작일 체크
  const checkStartDate = useCallback(() => {
    // console.log(basicInfo, application, contract);
    const today = moment();

    if (moment(contract.contractStartTime).isSameOrBefore(today)) {
      // console.log("contract.contractStartTime", moment(contract.contractStartTime).format(YmdFormat.FULL), moment().format(YmdFormat.FULL));
      setIsUse(true);
    } else {
      setIsUseFail(true);
    }
  }, [contract]);

  const changeStatusUseProgress = useCallback(() => {
    //TODO 상태인지 체크
    changeStatus(ContractStep.USE_PROGRESS);
  }, [changeStatus]);

  const changeStatusApply = useCallback(() => {
    //TODO 상태인지 체크
    changeStatus(ContractStep.CONTRACT_ACCEPT);
  }, [changeStatus]);

  // console.log('basicInfo',basicInfo);
  /**
 * 신청 접수 : 신청취소, 신청확인
  취소 : 없음
  신청확인 : 신청취소, 계약예정
  계약예정 : 신청취소, 계약체결
  계약체결 : 신청취소, 이용승인
  이용승인 : 신청취소,
  이용중: 해지접수
  해지접수 : 이용중, 해지완료
  해지완료: 없음
  이용완료: 없음
 * @param status 
 * @returns 
 */
  const statusBtnShow = useCallback(
    (status: string) => {
      const apply_cancel = (
        <BaseButton
          title="신청취소"
          className="color-white mr8"
          onClick={() => {
            setIsCancelOpen(true);
          }}
        />
      );
      if (spaceProductType === "TIME_COURT") {
        switch (status) {
          case ContractStep.APPLY_RECEIVED:
            return <></>;
          case ContractStep.APPLY_CANCEL:
            return <></>;
          case ContractStep.APPLY_CONFIRM:
            return (
              <>
                {apply_cancel}
                <BaseButton
                  title="이용승인"
                  className="color-white mr8"
                  onClick={() => changeStatusUseConfirm()}
                />
              </>
            );
          case ContractStep.CONTRACT_PLAN:
            return <></>;
          case ContractStep.CONTRACT_ACCEPT:
            return <></>;
          case ContractStep.USE_APPROVAL:
            return <>{apply_cancel}</>;
          case ContractStep.USE_PROGRESS:
            return <></>;
          case ContractStep.USE_COMPLETE:
            return <></>;
          case ContractStep.TERMINATE_RECEIVED:
            return <></>;
          case ContractStep.TERMINATE_COMPLETE:
            return <></>;
        }
      } else {
        switch (status) {
          case ContractStep.APPLY_RECEIVED:
            return (
              <>
                {apply_cancel}
                <BaseButton
                  title="신청확인"
                  className="color-white mr8"
                  onClick={() => {
                    setIsConfirmOpen(true);
                  }}
                />
              </>
            );
          case ContractStep.APPLY_CANCEL:
            return <></>;
          case ContractStep.APPLY_CONFIRM:
            return (
              <>
                {apply_cancel}
                <BaseButton
                  title="계약예정"
                  className="color-white mr8"
                  onClick={() => checkApplyDate(contract?.contractDate)}
                />
              </>
            );
          case ContractStep.CONTRACT_PLAN:
            return (
              <>
                {apply_cancel}
                <BaseButton
                  title="계약체결"
                  className="color-white mr8"
                  onClick={() => checkContractApply()}
                />
              </>
            );
          case ContractStep.CONTRACT_ACCEPT:
            return (
              <>
                {apply_cancel}
                <BaseButton
                  title="이용승인"
                  className="color-white mr8"
                  onClick={() => changeStatusUseConfirm()}
                />
              </>
            );
          case ContractStep.USE_APPROVAL:
            return <>{apply_cancel}</>;
          case ContractStep.USE_PROGRESS:
            return (
              <>
                <BaseButton
                  title="해지접수"
                  className="color-white mr8"
                  onClick={() => changeStatusTerminateConfirm()}
                />
              </>
            );
          case ContractStep.USE_COMPLETE:
            return <></>;
          case ContractStep.TERMINATE_RECEIVED:
            return (
              <>
                <BaseButton
                  title="이용중"
                  className="color-white mr8"
                  onClick={() => {
                    setIsUseProgressTalk(true);
                  }}
                />
                <BaseButton
                  title="해지완료"
                  className="color-white mr8"
                  onClick={() => changeStatusTerminateComplete()}
                />
              </>
            );
          case ContractStep.TERMINATE_COMPLETE:
            return <></>;
        }
      }
    },
    [
      changeStatusTerminateComplete,
      changeStatusTerminateConfirm,
      changeStatusUseConfirm,
      checkApplyDate,
      checkContractApply,
      contract?.contractDate,
      spaceProductType,
    ],
  );

  return (
    <>
      <div className="contents-container__wrap detail-basic-info">
        <div className="contents-container__wrap-contents">
          <ContentsIdSection
            title="기본정보"
            id={basicInfo?.contractId}
            noTitle="신청번호"
            no={basicInfo?.contractApplyNumber}
            status={basicInfo?.step}
          />
          <article>
            <div className="contents-container__1200">
              <div className="contents-container__sub-title">
                <h2>기본정보</h2>
              </div>
              <div className="flex-center">
                {/* id */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>id</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>{basicInfo?.contractId}</p>
                  </div>
                </section>
                {/* 생성일시 */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>생성일시</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>{moment(basicInfo?.createdDate).format(YmdFormat.FULL)}</p>
                  </div>
                </section>
              </div>
              <div className="flex-center">
                {/* 신청/계약번호 */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>신청 번호</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>{basicInfo?.contractApplyNumber}</p>
                  </div>
                </section>
                {/* 최종 수정일시 */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>최종 수정일시</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>{moment(basicInfo?.modifiedDate).format(YmdFormat.FULL)}</p>
                  </div>
                </section>
              </div>
              <div className="flex-center">
                {/* 상태 */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>상태</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-start">
                      <p className={"mr8"}>{basicInfo?.step}</p>
                      {statusBtnShow(basicInfo?.contractStep)}
                    </div>
                  </div>
                </section>
                {/* 최종 작성자 */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>최종 작성자</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>{basicInfo?.modifiedBy}</p>
                  </div>
                </section>
              </div>
            </div>
          </article>
          <article>
            <AdminMemoIntegration
              serviceId={Number(basicInfo.id)}
              serviceType={ServiceTypes.SERVICE_CONTRACT}
              viewOnly={false}
            />
          </article>
        </div>

        <div className="contents-container__btn-wrap">
          <div className="left-area d-flex">
            <BaseButton
              title="목록으로"
              onClick={() => {
                delete queryParams.id;
                navigate(
                  pagePath.contract.list +
                    "?" +
                    decodeURIComponent(
                      qs.stringify(queryParams, { allowDots: true, encode: true }),
                    ),
                );
              }}
              className="color-white size-large"
            />
          </div>
          <div className="right-area"></div>
        </div>
      </div>
      {/* 신청취소 */}
      <BaseModal
        isOpen={isCancelOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => {
          setIsCancelOpen(false);
        }}
        onClick={() => changeStatusCancel()}
      >
        <div>
          <p>신청취소 하시겠습니까?</p>
          <p>취소로 변경 전, 계약자에게 계약금 환불 정책에 대해 안내해 주세요.</p>
        </div>
      </BaseModal>
      {/* 신청확인 */}
      <BaseModal
        isOpen={isConfirmOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => {
          setIsConfirmOpen(false);
        }}
        onClick={() => changeStatusConfirm()}
      >
        <div>
          <p>신청확인으로 변경 하시겠습니까?</p>
        </div>
      </BaseModal>
      {/* 계약예정 */}
      <BaseModal
        isOpen={isPlanOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => {
          setIsPlanOpen(false);
        }}
        onClick={() => changeStatusPlan()}
      >
        <div>
          <p>계약예정으로 변경하시겠습니까?</p>
          <p>계약자에게 등록하신 계약 일시가 알림톡으로 발신됩니다.</p>
        </div>
      </BaseModal>
      {/* 계약예정 벨리데이션*/}
      <BaseModal
        isOpen={isPlanOpen2}
        btnRightTitle="확인"
        onClick={() => {
          setIsPlanOpen2(false);
        }}
      >
        <div>
          <p>계약예정으로 변경할 수 없습니다.</p>
          <p>계약 예정으로 변경 하시려면, 계약 일시 입력이 필요합니다.</p>
        </div>
      </BaseModal>
      {/* 회사정보 벨리데이션*/}
      <BaseModal
        isOpen={isOrg}
        btnRightTitle="확인"
        onClick={() => {
          setIsOrg(false);
        }}
      >
        <div>
          <p>사업자 정보를 먼저 입력해주세요</p>
        </div>
      </BaseModal>
      {/* 계약정보 항목 벨리데이션*/}
      <BaseModal
        isOpen={isContractMedia}
        btnRightTitle="확인"
        onClick={() => {
          setIsContractMedia(false);
        }}
      >
        <div>
          <p>계약체결로 변경할 수 없습니다.</p>
          <p>먼저 계약탭에서 계약정보의 계약금을 결제완료로 설정 및 계약서를 등록해 주세요</p>
        </div>
      </BaseModal>
      {/* 계약체결 알림톡 안내*/}
      <BaseModal
        isOpen={isContractTalk}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => {
          setIsContractTalk(false);
        }}
        onClick={() => changeStatusApply()}
      >
        <div>
          <p>계약체결로 변경하시겠습니까?</p>
          <p>계약자에게 계약체결 완료 알림톡이 발신됩니다.</p>
        </div>
      </BaseModal>
      {/* 이용승인 알림톡 안내*/}
      <BaseModal
        isOpen={isUseTalk}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => {
          setIsUseTalk(false);
        }}
        onClick={() => changeStatus("USE_APPROVAL")}
      >
        <div>
          <p>이용승인으로 변경하시겠습니까?</p>
          <p>계약자에게 이용승인 완료 알림톡이 발신됩니다.</p>
        </div>
      </BaseModal>
      {/* 이용승인 알림톡 안내*/}
      <BaseModal
        isOpen={isUseConfirm}
        btnRightTitle="확인"
        onClick={() => {
          setIsUseConrirm(false);
        }}
      >
        <div>
          <p>이용승인으로 변경할 수 없습니다.</p>
          <p>먼저 계약탭에서 계약정보의 보증금을 결제완료로 설정해 주세요.</p>
        </div>
      </BaseModal>
      <BaseModal
        isOpen={isUse}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => {
          setIsUse(false);
        }}
        onClick={() => changeStatus(ContractStep.USE_PROGRESS)}
      >
        <p>이용중으로 즉시 변경하시겠습니까?</p>
      </BaseModal>
      {/* 이용증 실패 안내*/}
      <BaseModal
        isOpen={isUseFail}
        btnRightTitle="확인"
        onClick={() => {
          setIsUseFail(false);
        }}
      >
        <div>
          <p>이용중으로 변경할 수 없습니다.</p>
          <p>이용시작일이 도래하지 않았습니다.</p>
        </div>
      </BaseModal>

      {/* 해지접수 알림톡 안내*/}
      <BaseModal
        isOpen={isTerminateTalk}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => {
          setIsTerminateTalk(false);
        }}
        onClick={() => changeStatus(ContractStep.TERMINATE_RECEIVED)}
      >
        <div>
          <p>해지접수로 변경하시겠습니까?</p>
          <p>계약자에게 계약 해지접수 알림톡이 발신됩니다.</p>
        </div>
      </BaseModal>
      {/* 연체상태 체크 안내*/}
      <BaseModal
        isOpen={isBillOverdue}
        btnRightTitle="확인"
        onClick={() => {
          setBillOverdue(false);
        }}
      >
        <div>
          <p>해지접수로 변경할 수 없습니다.</p>
          <p>
            빌링스케줄의 빌링/연체현황에 미납이 있습니다. 먼저 미납에 대해 결제 요청 및 수납처리를
            진행해 주세요.
          </p>
        </div>
      </BaseModal>

      {/* 이용중 변경*/}
      <BaseModal
        isOpen={isUseProgressTalk}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => {
          setIsUseProgressTalk(false);
        }}
        onClick={() => changeStatusUseProgress()}
      >
        <div>
          <p>이용중으로 변경하시겠습니까?</p>
          <p>이용중으로 변경 시 빌링스케줄의 중지 처리가 모두 해제됩니다.</p>
        </div>
      </BaseModal>
      {/* 해지완료 안내*/}
      <BaseModal
        isOpen={isTerminateCompleteTalk}
        btnRightTitle="확인"
        onClick={() => {
          setIsTerminateCompleteTalk(false);
        }}
      >
        <div>
          <p>해지완료로 변경할 수 없습니다.</p>
          <p>빌링스케줄의 빌링/연체현황에서 해당 회차의 상태를 중지로 변경해 주세요.</p>
        </div>
      </BaseModal>
      {/* 해지완료 안내*/}
      <BaseModal
        isOpen={isTerminateCompleteTalkCheckStop}
        btnRightTitle="확인"
        onClick={() => {
          setIsTerminateCompleteTalkCheckStop(false);
        }}
      >
        <div>
          <p>해지완료로 변경할 수 없습니다.</p>
          <p>
            빌링스케줄의 빌링/연체현황에 미납이 있습니다. 먼저 미납에 대해 결제 요청 및 수납처리를
            진행해 주세요.
          </p>
        </div>
      </BaseModal>
      {/* 해지완료 알림톡 안내*/}
      <BaseModal
        isOpen={isBillingCheck}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => {
          setBillingCheck(false);
        }}
        onClick={() => changeStatus(ContractStep.TERMINATE_COMPLETE)}
      >
        <div>
          <p>해지완료로 변경하시겠습니까?</p>
          <p>계약자에게 해지완료 알림톡이 발신됩니다.</p>
        </div>
      </BaseModal>
      <BaseModal isOpen={isSaved} btnRightTitle="확인" onClick={() => refresh()}>
        <div>
          <p>상태가 변경되었습니다.</p>
        </div>
      </BaseModal>
    </>
  );
};

export default ContractBasicInfo;
