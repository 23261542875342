import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { BizType, MbOrg } from "src/api/organization/org-types";
import { BaseTooltip } from "src/components/BaseTooltip";
import { PagePath } from "src/pages/product/details";
import { companyRegistrationHypen, corpHypen } from "src/utils";

const OrgColumns: any = [
  {
    Header: "id",
    accessor: "id",
    width: 100,
    Cell: (props: Cell<MbOrg>) => {
      const detailPath = `${PagePath.organization.detail.replace(
        ":id",
        `${props.row.original.id}`,
      )}`;
      return (
        <>
          <Link to={detailPath} className="text-hilight">
            {props.value}
          </Link>
        </>
      );
    },
  },
  {
    Header: "구분",
    accessor: "classifications",
    width: 130,
    Cell: ({ row, value }: Cell<MbOrg>) => {
      const changeKorean = value.map((item: string) => {
        return item === "GENERAL_BIZ" ? "일반 사업자" : "소유주";
      });

      return changeKorean.join(", ") || "-";
    },
  },
  {
    Header: "법인명/상호",
    accessor: "name",
    width: 180,
    Cell: (props: Cell<MbOrg>) => {
      return (
        <div className="w-100 text-left">
            <p>{props.value}</p>
        </div>
      );
    },
  },
  {
    Header: "사업 형태",
    accessor: "bizType",
    width: 150,
    Cell: (props: Cell<MbOrg>) => {
      return (
        <>
          <div>{props.value === BizType.CORP ? "법인사업자" : "개인사업자"}</div>
        </>
      );
    },
  },
  {
    Header: "사업자 등록번호",
    accessor: "bizRegNo",
    width: 140,
    Cell: (props: Cell<MbOrg>) => {
      return (
        <>
          <div>{companyRegistrationHypen(props.value)}</div>
        </>
      );
    },
  },
  {
    Header: "법인 등록번호",
    accessor: "corRegNo",
    width: 180,
    Cell: (props: Cell<MbOrg>) => {
      return (
        <>
          <div>{corpHypen(props.value)}</div>
        </>
      );
    },
  },
  {
    Header: "생성일",
    accessor: "createdDate",
    width: 100,
    Cell: (props: Cell<MbOrg>) => {
      return (
        <>
          <BaseTooltip contents={props.value} type={"date"} />
        </>
      );
    },
  },
  {
    Header: "최종 수정일",
    accessor: "lastModifiedDate",
    width: 100,
    Cell: (props: Cell<MbOrg>) => {
      return (
        <>
          <BaseTooltip contents={props.value} type={"date"} />
        </>
      );
    },
  },
  {
    Header: "최종 작성자",
    accessor: "lastModifiedBy",
    width: 160,
    Cell: (props: Cell<MbOrg>) => {
      return (
        <div className="w-100 text-left ellipsis2">
            <p>{props.value}</p>
        </div>
      );
    },
  },
  {
    Header: "파트너",
    accessor: "partnerId",
    width: 100,
    Cell: (props: Cell<MbOrg>) => {
      return (
        <>
          <a
            className="text-hilight "
            href={`${PagePath.partner.detail.replace(":id", props.value)}`}
            target="_blank"
            rel="noreferrer"
          >{props.value}</a>
        </>
      );
    },
  },
];

export default OrgColumns;
