import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { getBuildingsAsync } from "src/api/building/building-api";
import { useApiOperation } from "src/api/hooks";
import { ProductModel } from "src/api/product/product-types";
import { PageMeta } from "src/api/public-types";
import { BaseInput, BaseModal, BasePagination, BaseRadio } from "src/components";

const columnHeader: any = [
  {
    Header: "",
    accessor: "isSelected",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row, setSelected }: any) => {
      const changeTrigger = useCallback(() => {
        setSelected(row.original);
      }, [row.original, setSelected]);
      return (
        <div>
          <BaseRadio
            id={`selector${row.original.id}`}
            name={"isSelected"}
            onChange={changeTrigger}
          />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "id",
    // sticky: "left",
    width: 70,
  },

  {
    Header: "건물명",
    accessor: "buildingName", // api 스펙에 없음
    width: 300,
  },
];
type Props = {
  isOpen: boolean;
  buildingName: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const SearchBuildingPopup = (props: Props) => {
  const [selected, setSelected] = useState<any>();

  const [products, setProducts] = useState<ProductModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync);
  const [keyword, setKeyword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const fetchBuilding = useCallback(
    async (param: any) => {
      return await getBuildings(param);
    },
    [getBuildings],
  );
  const [params, setParams] = useState<any>({
    page: 0,
    size: 20,
    keyword: "",
    // status: 'ENABLED',
    sort: {
      orders: [
        {
          property: "id",
          direction: "DESC",
        },
      ],
    },
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columnHeader,
      data: products,
      setSelected,
    },

    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );

  const onClickData = useCallback(async () => {
    if (props.onClick) props.onClick(selected);
  }, [props, selected]);

  const callList = useCallback(
    async (param: any) => {
      const response: any = await fetchBuilding(param);
      if (response.status > 199 && response.status < 300) {
        for (let i = 0; i < response?.data?.data?.content.length; i++) {
          response.data.data.content[i] = Object.assign(response.data.data.content[i], {
            isSelected: false,
          });
        }
        setProducts(response?.data?.data?.content || []);
        setPageMeta(response?.data?.meta?.pageMeta);
      }
    },
    [fetchBuilding],
  );

  useEffect(() => {
    if (props.isOpen) {
      let prs = _.cloneDeep(params);
      prs.page = 0;
      prs.keyword =
        props.buildingName !== undefined && props.buildingName !== ""
          ? decodeURIComponent(props.buildingName)
          : "";
      props.buildingName !== undefined && props.buildingName !== ""
        ? setKeyword(decodeURIComponent(props.buildingName))
        : setKeyword("");
      callList(prs);
      setParams(prs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <div>
      <BaseModal
        className="dialog-modal"
        isOpen={props.isOpen}
        btnRightTitle="선택"
        btnLeftTitle="취소"
        onClick={() => onClickData()}
        onClose={props.onClose}
      >
        <>
          <div className="modal-title">
            <div className="left-area">
              <span>건물 선택</span>
            </div>
            <div className="right-area">
              <div className="minmax260">
                <BaseInput
                  type="text"
                  value={keyword || ""}
                  placeholder="검색어를 입력해주세요"
                  onKeyUp={() => {
                    callList({ ...params, ...{ keyword }, ...{ page: 0 } });
                    setParams({ ...params, ...{ keyword }, ...{ page: 0 } });
                  }}
                  onChange={(value: string) => {
                    setKeyword(value);
                  }}
                  onSearchClick={() => {
                    callList({ ...params, ...{ keyword }, ...{ page: 0 } });
                    setParams({ ...params, ...{ keyword }, ...{ page: 0 } });
                  }}
                />
              </div>
            </div>
          </div>
          {/* table */}
          <div className="contents-container__table">
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <div
                      {...row.getRowProps()}
                      className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <BasePagination
              pageIndex={params.page || 0}
              totalPages={pageMeta?.totalPages || 0}
              goPage={(page: number) => {
                setParams({ ...params, ...{ page } });
                callList({ ...params, ...{ page } });
              }}
            />
          </div>
        </>
      </BaseModal>
      <BaseModal isOpen={error} btnRightTitle={"확인"} onClick={() => setError(false)}>
        <p>{message}</p>
      </BaseModal>
    </div>
  );
};

export default SearchBuildingPopup;
