import moment from "moment";
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { CsItemModel } from "src/api/voc/voc-types";
import { BaseTooltip } from "src/components";
import { PagePath } from "src/pages/product/details";
import { YmdFormat } from "src/utils";

const VocColumns = [
  {
    Header: "No",
    accessor: "id",
    width: 80,
    Cell: ({ value }: Cell<CsItemModel>) => {
      const detailPath = `${PagePath.voc.detail.replace(":id", value)}`;

      return (
        <Link to={detailPath} className="text-underline">
          {value}
        </Link>
      );
    },
  },

  {
    Header: "유형",
    accessor: "csTypeName",
    width: 155,
    Cell: ({ value }: Cell<CsItemModel>) => {
      return <span className="text-start w-100">{value}</span>;
    },
  },
  {
    Header: "항목",
    accessor: "csItemName",
    width: 370,
    Cell: ({ value }: Cell<CsItemModel>) => {
      return <span className="text-start w-100">{value}</span>;
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 155,
    Cell: ({ value }: Cell<CsItemModel>) => {
      return (
        <BaseTooltip
          className="text-left w-100"
          contents={moment(value).format(YmdFormat.YYYY_MM_DD_HH_MM)}
          type="date"
        />
      );
    },
  },
  {
    Header: "최종 수정자",
    accessor: "modifiedBy",
    width: 155,
    Cell: ({ value }: Cell<CsItemModel>) => <span className="text-start w-100">{value}</span>,
  },
  {
    Header: "파트너",
    accessor: "partnerId",
    width: 80,
    Cell: ({ value }: Cell<CsItemModel>) => {
      return (
        <Link
          target={"_blank"}
          to={PagePath.partner.detail.replace(":id", value)}
          className="text-hilight"
        >
          {value}
        </Link>
      );
    },
  },
];

export default VocColumns;
