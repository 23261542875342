import qs from "qs";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "src/api/public-types";
import { BaseModal, ContentsTitle } from "src/components";
import PagePath from "src/pagePath.json";
import IotAdminMemo from "../detail/adminMemo/IotAdminMemo";
import BasicInfoDetail from "src/pages/iot/detail/basicInfo/BasicInfoDetail";
import Control from "./control/Control";
import Partner from "./partner/Partner";
import Log from "./log/Log";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "control", label: "제어" },
  { value: "partner", label: "파트너" },
  { value: "memo", label: "관리자 메모" },
  { value: "log", label: "로그" },
];

/* 
  Iot > 상세
 */
const IotDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "iot") {
        // 목록으로 이동
        navigate(PagePath.iot.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "iot", label: "Iot" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  return (
    <div className="page-iot">
      <ContentsTitle
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />
      {/* 탭 */}
      <div className="contents-container__scroll">
        {/* 기본정보 */}
        {activeTab?.value === "basicInfo" && <BasicInfoDetail />}

        {/* 제어 */}
        {activeTab?.value === "control" && <Control />}

        {/* 파트너 */}
        {activeTab?.value === "partner" && <Partner />}

        {/* 관리자 메모 */}
        {activeTab?.value === "memo" && <IotAdminMemo />}
        {/* 관리자 메모 */}
        {activeTab?.value === "log" && <Log />}
      </div>
    </div>
  );
};
export default IotDetail;
