import { ContractStep } from "src/api/contract/contract-types";
import { PagePath } from "src/pages/product/details";
import { IMenu, IMenuListStatus } from "../types";

const BASE_PATH = `${PagePath.contract.list}?page=0&size=20&sort.orders[0].property=contractManageId&sort.orders[0].direction=DESC&searchValue=&contractStep=`;

const CONTRACT_MENU_LIST: IMenuListStatus[] = [
  {
    title: "전체",
    count: -1,
    path: BASE_PATH,
    backgroundColor: "bg-dashboard-card",
    status: "",
  },
  {
    title: "신청접수",
    count: -1,
    path: BASE_PATH + ContractStep.APPLY_RECEIVED,
    backgroundColor: "Default",
    status: ContractStep.APPLY_RECEIVED,
  },
  {
    title: "신청취소",
    count: -1,
    path: BASE_PATH + ContractStep.APPLY_CANCEL,
    backgroundColor: "Default",
    status: ContractStep.APPLY_CANCEL,
  },
  {
    title: "신청확인",
    count: -1,
    path: BASE_PATH + ContractStep.APPLY_CONFIRM,
    backgroundColor: "Default",
    status: ContractStep.APPLY_CONFIRM,
  },
  {
    title: "계약예정",
    count: -1,
    path: BASE_PATH + ContractStep.CONTRACT_PLAN,
    backgroundColor: "Default",
    status: ContractStep.CONTRACT_PLAN,
  },
  {
    title: "계약체결",
    count: -1,
    path: BASE_PATH + ContractStep.CONTRACT_ACCEPT,
    backgroundColor: "Default",
    status: ContractStep.CONTRACT_ACCEPT,
  },

  {
    title: "이용승인",
    count: -1,
    path: BASE_PATH + ContractStep.USE_APPROVAL,
    backgroundColor: "Default",
    status: ContractStep.USE_APPROVAL,
  },
  {
    title: "이용중",
    count: -1,
    path: BASE_PATH + ContractStep.USE_PROGRESS,
    backgroundColor: "Default",
    status: ContractStep.USE_PROGRESS,
  },
  {
    title: "이용완료",
    count: -1,
    path: BASE_PATH + ContractStep.USE_COMPLETE,
    backgroundColor: "Default",
    status: ContractStep.USE_COMPLETE,
  },
  {
    title: "해지접수",
    count: -1,
    path: BASE_PATH + ContractStep.TERMINATE_RECEIVED,
    backgroundColor: "Default",
    status: ContractStep.TERMINATE_RECEIVED,
  },
  {
    title: "해지완료",
    count: -1,
    path: BASE_PATH + ContractStep.TERMINATE_COMPLETE,
    backgroundColor: "Default",
    status: ContractStep.TERMINATE_COMPLETE,
  },
];

export { CONTRACT_MENU_LIST };
