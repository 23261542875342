import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "src/api/public-types";
import { BaseModal } from "./BaseModal";

// 계정 로그인시 권한여부 확인 - admin 이 아니면 로그아웃 처리

const CENTRAL_ADMIN = "CENTRAL_ADMIN";

const UserAuthority = () => {
  const { getIdTokenClaims, logout, isAuthenticated } = useAuth0();
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const checkAdminLogin = useCallback(async () => {
    const response = await getIdTokenClaims();
    const roles: string[] = response?.central_roles; // role 확인 (어드민인지 아닌지)

    // 로그인이 되었는데 roles 에 CENTRAL_ADMIN 없을 때
    if (isAuthenticated && !roles?.includes(CENTRAL_ADMIN)) {
      setAlertModal({
        isOpen: true,
        message: "권한이 없습니다.\n서비스기획팀에 문의하세요.",
      });
    }
  }, [getIdTokenClaims, isAuthenticated]);

  useEffect(() => {
    checkAdminLogin();
  }, [checkAdminLogin]);

  return (
    <div>
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle={"확인"}
        onClick={() => {
          logout({
            returnTo: `${window.location.origin}`,
          });
        }}
      >
        <p className="pre-formatted">{alertModal.message}</p>
      </BaseModal>
    </div>
  );
};

export default UserAuthority;
