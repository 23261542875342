import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import {
  editProviderBasicInfoAsync,
  postProviderBasicInfoAsync,
  providerDetailAsync,
} from "src/api/provider/provider-api";
import {
  ProviderAddModel,
  ProviderBasicInfoAddModel,
  ProviderBasicInfoEditModel,
  ProviderContactModel,
} from "src/api/provider/provider-types";
import { BaseButton, BaseModal, ContentsIdSection } from "src/components";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { Modal } from "src/pages/product/product-types";
import { Partner } from "src/types/partner";
import ProviderBasicForm from "../components/ProviderBasicForm";

const BasicInfoForm = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();
  // 토스트
  const { openToast } = useToastContext();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const [isPayletter, setIsPayletter] = useState(false);

  const providerId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  const [submitData, setSubmitData] = useState<ProviderBasicInfoAddModel>();

  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 선택된 파트너 정보
  const [partner, setPartner] = useState<Partner>();

  // 프로바이더 상세
  const [providerDetail, setProviderDetail] = useState<ProviderAddModel>();

  // 연락처 삭제 array
  const [deletedContact, setDeletedContact] = useState<Array<ProviderContactModel>>([]);

  // 프로바이더 등록
  const { executeAsync: postProviderBasicInfoApi } = useApiOperation(postProviderBasicInfoAsync);

  //  프로바이더 수정
  const { executeAsync: editProviderApi } = useApiOperation(editProviderBasicInfoAsync);

  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  // 파트너 상세조회
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  // 프로바이더 등록 default state
  const defaultValues = useMemo(() => {
    const providerPostData: ProviderBasicInfoAddModel = {
      provider: {
        providerName: "",
        representative: "",
        businessRegistrationNumber: "",
        address: "",
        isDeleted: false,
        bizType: "",
        bizClass: "",
        supplyType: "",
      },

      contractMediaList: [],
    };

    return providerPostData;
  }, []);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<ProviderBasicInfoAddModel>({
    defaultValues,
  });

  // 파트너 api
  const fetchPartner = async (id: string) => {
    setLoadingBar(true);
    const response = await getPartnerAsync({ id });

    if (response.status >= 200 && response.status <= 299) {
      const result = response.data.data.partner;
      setPartner(result);
    }
    setLoadingBar(false);
  };

  // 프로바이더 상세 조회 후 setValue 처리
  const providerDetailApi = useCallback(
    async (id: string) => {
      setLoadingBar(true);

      const response = await getProviderDetail({ providerId: id });
      if (response.status >= 200 || response.status <= 299) {
        const detail: ProviderAddModel = response.data.data;
        setProviderDetail(detail);
        if (detail) {
          // 프로바이더 basic 부분
          setValue("provider", detail.provider);
          setValue("contractMediaList", detail.contractMediaList);
          fetchPartner(String(detail.provider?.partnerId));
        }
      }
      setLoadingBar(false);
    },
    [getProviderDetail, setValue],
  );

  useEffect(() => {
    if (providerId) {
      providerDetailApi(String(providerId));
    }
  }, [providerDetailApi, providerId]);
  useTitleOperation(providerDetail?.provider?.providerName);

  //사업자 등록번호 검증
  const checkValidateOrgNumber = useCallback((number: string) => {
    let numberMap: number[] = number
      .replace(/-/gi, "")
      .split("")
      .map(function (d) {
        return parseInt(d, 10);
      });

    // Front 에서는 10자리 숫자 입력까지만 validation

    if (numberMap.length === 10) {
      return true;
    }

    // if (numberMap.length === 10) {
    //   let keyList: number[] = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    //   let chk: number = 0;

    //   keyList.forEach(function (d, i) {
    //     chk += d * numberMap[i];
    //   });

    //   chk += (keyList[8] * numberMap[8]) / 10;

    //   console.log(`체크`, Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10);

    //   return Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10;
    // }

    return false;
  }, []);

  //  유효성 확인
  const formValidation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";

    // 프로바이더 명
    register("provider.providerName", {
      required: { value: true, message: requiredMessage },
    });

    // 사업자 등록번호
    register("provider.businessRegistrationNumber", {
      // required: { value: true, message: requiredMessage },
      validate: {
        requried: (businessNumber) => {
          let result = true;
          let message = "";
          let validateBusinessNumber = checkValidateOrgNumber(String(businessNumber));

          console.log("validateBusinessNumber", validateBusinessNumber);
          if (!businessNumber) {
            result = false;
            message = requiredMessage;
          } else if (!validateBusinessNumber) {
            result = false;
            message = "올바르지 않은 등록 번호입니다.";
          }

          return result || message;
        },
      },
    });

    //  대표자 성명
    register("provider.representative", {
      required: { value: true, message: requiredMessage },
    });

    // 주소
    register("provider.address", {
      required: { value: true, message: requiredMessage },
    });

    // 구분
    register("provider.supplyType", {
      required: { value: true, message: requiredMessage },
    });

    // 파트너
    register("provider.partnerId", {
      required: { value: true, message: requiredMessage },
    });

    // 사업자 등록증 사본
    register("contractMediaList", {
      validate: {
        requried: (value) => {
          let result = true;
          let message = "";

          if (!value?.length) {
            message = "필수등록 항목입니다";
            result = false;
          }

          return result || message;
        },
      },
    });
  }, [isPayletter, watch]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  // 유효성 검사후 저장
  const onSubmit = useCallback(
    async (data?: ProviderAddModel, e?: any) => {
      e.preventDefault();
      setSubmitData(data);

      // 사업자등록증 삭제 후 post
      let newContractMedia: Array<any> = [];
      if (data && data.contractMediaList?.length) {
        for (let i = 0; i < data.contractMediaList.length; i++) {
          const media = data.contractMediaList[i];

          newContractMedia.push({
            ...media,
            serviceType: "CT_PROVIDER",
            serviceTag: "BUSINESS_LICENSE",
            isPrimary: true,
            orderNums: 0,
          });
        }
        data.contractMediaList = newContractMedia;
      }

      // 연락처 삭제

      if (data?.providerContactList) {
        data.providerContactList = [...data?.providerContactList, ...deletedContact];
      }

      console.log("create", data);
      setConfirmModal({ message: "프로바이더 기본정보 저장하시겠습니까?", isOpen: true });
    },
    [deletedContact],
  );

  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);

  // 정상 등록/수정 진행 (팝업 확인)
  const onClickConfirmModal = async (data: ProviderAddModel) => {
    setLoadingBar(true);

    if (providerId) {
      const updateData: ProviderBasicInfoEditModel = {
        ...data,
        providerId,
      };

      const editResponse = await editProviderApi(updateData);

      if (editResponse.status >= 200 && editResponse.status <= 299) {
        openToast(`정상적으로 ${providerId ? "수정" : "등록"}되었습니다.`);
        navigate(PagePath.provider.detail.replace(":id", `${editResponse.data.data.providerId}`));
      }
    } else {
      const response = await postProviderBasicInfoApi(data);

      if (response.status >= 200 && response.status <= 299) {
        openToast(`정상적으로 ${providerId ? "수정" : "등록"}되었습니다.`);
        navigate(PagePath.provider.detail.replace(":id", `${response.data.data.providerId}`));
      }
    }

    setLoadingBar(false);
  };

  return (
    <div className="contents-container__wrap detail-basic-info">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__wrap-contents">
          <ContentsIdSection title="기본정보" id={providerId} />

          {/* 프로바이더 기본정보 */}
          <ProviderBasicForm
            providerId={String(providerId)}
            setPartner={setPartner}
            partner={partner}
            errors={errors}
            control={control}
            setValue={setValue}
            reset={reset}
            providerDetail={providerDetail}
          />

          {/* 버튼영역 */}
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title="목록으로"
                className="color-white size-large"
                onClick={() => {
                  if (providerId) {
                    navigate(PagePath.provider.list);
                  } else {
                    navigate(PagePath.provider.list);
                  }
                }}
              />
            </div>
            <div className="right-area">
              <BaseButton type="submit" title="저장" className="size-large" />
            </div>
          </div>
        </div>
      </form>
      <>
        <BaseModal
          isOpen={confirmModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => {
            submitData && onClickConfirmModal(submitData);
            setConfirmModal({ isOpen: false });
          }}
        >
          <p>{confirmModal.message}</p>
        </BaseModal>
      </>
    </div>
  );
};

export default BasicInfoForm;
