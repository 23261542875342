import { useContext } from "react";
import { ErrorModalContext } from "src/contexts/ErrorModalContext";

/* 
  에러 모달 context hook

  사용예시
  import useErrorModalContext from "src/pages/hooks/error-modal-context";
  const { openErrorModal } = useErrorModalContext();

  openErrorModal();
  openErrorModal("에러가 발생했습니다.");
*/
export default function useErrorModalContext() {
  return useContext(ErrorModalContext);
}
