/* eslint-disable array-callback-return */
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getContractFacilityList,
  getContractOperationInfo,
  getProductFacilityList,
} from "src/api/contract/contract-api";
import { ContractStep } from "src/api/contract/contract-types";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { contract_bill_user_errors } from "src/api/public-types";
import { BaseButton, BaseTooltip } from "src/components";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import pagePath from "src/pagePath.json";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { numberToStringWithComma, showHourMinute } from "src/utils";

const ContractFormManage = ({ basicInfo, application, spaceProductType }: any) => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );
  const [operation, setOperation] = useState<any>();

  const { executeAsync: getOperation } = useApiOperation(getContractOperationInfo, {
    doNotErrorHandleModal: true,
  });
  // 운영-공용공간 조회
  const { executeAsync: getOperationFacility } = useApiOperation(getContractFacilityList, {
    doNotErrorHandleModal: true,
  });
  // 건물-상품 공용공간 조회
  const { executeAsync: getProductFacilitList } = useApiOperation(getProductFacilityList, {
    doNotErrorHandleModal: true,
  });

  const callOperation = useCallback(async () => {
    if (params?.id && basicInfo && basicInfo!.contractStep) {
      setLoadingBar(true);
      const prViewFacilities: any = [];
      const ctViewFacilities: any = [];
      const getFacilities: any = await getOperationFacility({ id: Number(params.id) });
      const res: any = await getOperation({ id: Number(params.id) });
      let callIds: string = "";
      // console.log("getFacilities", getFacilities.data.data.content.prProductFacility);
      getFacilities.data.data.content.prProductFacility.forEach(
        (ctFacility: any, index: number) => {
          if (index > 0 && index < getFacilities.data.data.content.prProductFacility.length - 1) {
            callIds += String(ctFacility.id) + ",";
          } else if (index === getFacilities.data.data.content.prProductFacility.length - 1) {
            callIds += String(ctFacility.id);
          } else {
            callIds += String(ctFacility.id) + ",";
          }
        },
      );
      // console.log("callIds", callIds);

      if (res.status >= 200 && res.status <= 299) {
        const length = res.data.data.content.productManage.clientSecretValue.length;
        res.data.data.content.productManage.clientSecretValueBlocked = "*".repeat(length);

        const prFacilityDatasContent: any = await getProductFacilitList({
          searchType: "ID",
          keywordList: callIds,
          size: 9999,
        });
        const prFacilityDatas = prFacilityDatasContent.data.data.content;
        // console.log(res.data.data.content);

        // console.log("prFacilityDatas", prFacilityDatas);
        let response = getFacilities.data.data.content;

        if (prFacilityDatas) {
          response?.ctProductFacility.forEach((ctFacility: any) => {
            const finded = prFacilityDatas.find(
              (prFacility: any) => String(prFacility.id) === String(ctFacility.id),
            );
            // console.log("finded", finded);
            if (finded) {
              // console.log("prFacility.id, ctFacility.id", finded.id, ctFacility.id, finded.commonFacilityType);
              ctFacility.commonFacilityType = finded.commonFacilityType;
              ctFacility.buildingName = finded.buildingName;
              ctFacility.facilityName = finded.facilityName;
              ctFacility.isGround = finded.isGround;
              ctFacility.floorNum = finded.floorNum;
              ctFacility.buildingCommonFacilityDeskGroupName =
                finded.buildingCommonFacilityDeskGroupName
                  ? finded.buildingCommonFacilityDeskGroupName
                  : "";
              ctFacility.facilityMaxPeopleNums = finded.facilityMaxPeopleNums;
              ctViewFacilities.push(ctFacility);
            }
          });
          response?.prProductFacility.forEach((ctFacility: any) => {
            const finded = prFacilityDatas.find(
              (prFacility: any) => String(prFacility.id) === String(ctFacility.id),
            );
            // console.log("finded", finded);
            if (finded) {
              // console.log("prFacility.id, ctFacility.id", finded.id, ctFacility.id, finded.commonFacilityType);
              ctFacility.commonFacilityType = finded.commonFacilityType;
              ctFacility.buildingName = finded.buildingName;
              ctFacility.facilityName = finded.facilityName;
              ctFacility.isGround = finded.isGround;
              ctFacility.floorNum = finded.floorNum;
              ctFacility.buildingCommonFacilityDeskGroupName =
                finded.buildingCommonFacilityDeskGroupName
                  ? finded.buildingCommonFacilityDeskGroupName
                  : "";
              ctFacility.facilityMaxPeopleNums = finded.facilityMaxPeopleNums;
              prViewFacilities.push(ctFacility);
            }
          });
        }

        // console.log("response", response);
        res.data.data.content.ctProductFacility = response.ctProductFacility;
        res.data.data.content.prProductFacility = response.prProductFacility;
        // console.log("res", res);
        setOperation(res.data.data.content);
      } else {
        let message = errorMessageHandler(
          res.status,
          res.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.staus;
        openErrorModal(message, code);
      }

      setLoadingBar(false);
    }
  }, [
    params.id,
    basicInfo,
    setLoadingBar,
    getOperationFacility,
    getOperation,
    getProductFacilitList,
    openErrorModal,
  ]);

  useEffect(() => {
    if (basicInfo) {
      callOperation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicInfo]);

  const moveUserList = useCallback(() => {
    return pagePath.user.list + "?contractApplyNumber=" + basicInfo?.contractApplyNumber;
  }, [basicInfo?.contractApplyNumber]);

  const moveVisitorList = useCallback(() => {
    return pagePath.visitor.list + "?contractApplyNumber=" + basicInfo?.contractApplyNumber;
  }, [basicInfo?.contractApplyNumber]);

  const moveProductAccessGroup = useCallback(() => {
    return pagePath.product.detail.replace(":id", operation?.spaceProductId) + "?tab=accessGroup";
  }, [operation?.spaceProductId]);

  const moveEdit = useCallback(() => {
    delete queryParams.id;
    const path =
      pagePath.contract.form +
      "?id=" +
      params.id +
      "&" +
      decodeURIComponent(qs.stringify(queryParams, { allowDots: true, encode: true }));
    navigate(path);
  }, [navigate, params.id, queryParams]);

  const moveList = useCallback(() => {
    delete queryParams.id;
    const path =
      pagePath.contract.list +
      "?" +
      decodeURIComponent(qs.stringify(queryParams, { allowDots: true, encode: true }));
    navigate(path);
  }, [navigate, queryParams]);

  return (
    <div className="contents-container__wrap detail-basic-info">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection
          title="운영"
          id={basicInfo?.contractId}
          noTitle="신청번호"
          no={basicInfo?.contractApplyNumber}
          status={basicInfo?.step}
        />
        <article className="pb20">
          <div className="contents-container__1200 ">
            <div className="contents-container__sub-title">
              <h2>이용그룹</h2>
            </div>
            <div className="flex-center">
              {/* 이용자 수 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="no-wrap">
                    <span className="font14">이용자 수(현재/최대)</span>
                  </p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start ">
                    <Link
                      to={moveUserList()}
                      target="_blank"
                      className="font14 text-hilight no-wrap"
                    >
                      이용자 목록
                    </Link>
                    <div className="ml16">
                      <p className="font14">{operation?.productManage?.memberNums}</p>
                    </div>
                    <span className="mx10">명 /</span>
                    <div className="">
                      <p className="font14">{operation?.productManage?.memberMaxNums}</p>
                    </div>
                    <span className="ml10">명</span>
                  </div>
                </div>
              </section>
            </div>
            <div className="flex-center">
              {/* 방문자 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>방문자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    <Link to={moveVisitorList()} target="_blank" className="text-hilight font14">
                      방문자 목록
                    </Link>
                  </p>
                </div>
              </section>
            </div>
          </div>
        </article>

        <article className="contents-container__divide-top">
          <div className="contents-container__1200 ">
            <div className="contents-container__sub-title">
              <h2>공용 공간 / 시간(월) 할당</h2>
            </div>

            <section className="contents-container__grid pb20">
              <div className="contents-container__grid-index">
                <p>캘린더 연동</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <div className="minmax180">
                    <p className="font14">
                      {operation?.productManage?.isUseCalendar ? "연동" : "연동 안함"}
                    </p>
                  </div>
                </div>
                {operation?.productManage?.isUseCalendar && (
                  <div className={operation?.productManage?.isUseCalendar ? "" : " not-available"}>
                    {operation?.productManage?.calendarType === "GC" && (
                      <>
                        <div className="flex-row flex-center-start ">
                          <div className="minmax180">
                            <p className="font14">Calendar Type</p>
                          </div>
                          <div className="">
                            <p className="font14">GC</p>
                          </div>
                        </div>
                        <div className="flex-row flex-center-start ">
                          <div className="minmax180">
                            <p className="font14">Dummy User Email</p>
                          </div>
                          <div className="">
                            <p className="font14">{operation?.productManage?.dummyUserEmail}</p>
                          </div>
                        </div>
                      </>
                    )}
                    {operation?.productManage?.calendarType === "OC" && (
                      <>
                        <div className="flex-row flex-center-start ">
                          <div className="minmax180">
                            <p className="font14">Calendar Type</p>
                          </div>
                          <div className="">
                            <p className="font14">OC</p>
                          </div>
                        </div>
                        <div className="flex-row flex-center-start ">
                          <div className="minmax180">
                            <p className="font14">Dummy User Email</p>
                          </div>
                          <div className="">
                            <p className="font14">{operation?.productManage?.dummyUserEmail}</p>
                          </div>
                        </div>
                        <div className="flex-row flex-center-start ">
                          <div className="minmax180">
                            <p className="font14">Tenant ID</p>
                          </div>
                          <div className="">
                            <p className="font14">{operation?.productManage?.tenantId}</p>
                          </div>
                        </div>
                        <div className="flex-row flex-center-start ">
                          <div className="minmax180">
                            <p className="font14">Client ID</p>
                          </div>
                          <div className="">
                            <p className="font14">{operation?.productManage?.clientId}</p>
                          </div>
                        </div>
                        <div className="flex-row flex-center-start ">
                          <div className="minmax180">
                            <p className="font14">Client Secret Value</p>
                          </div>
                          <div className="">
                            <p className="font14">
                              {operation?.productManage?.clientSecretValueBlocked}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </section>
            {/* 미팅룸 할당 */}
            <section className="contents-container__grid pb20">
              <div className="contents-container__grid-index">
                <p>회의실 예약</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <div className="minmax180">
                    <p className="font14">
                      {operation?.productManage?.isUseMeetingRoom ? "제공" : "제공 안함"}
                    </p>
                  </div>
                </div>
                <div className={operation?.productManage?.isUseMeetingRoom ? "" : " not-available"}>
                  <div className="flex-row flex-center-start ">
                    <div className="minmax180">
                      <p className="font14">총 예약 시간/월</p>
                    </div>
                    <div className="">
                      <p className="font14">
                        {operation?.productFacilityUsedTime?.meetingRoomMonthlyUsedTime || 0}
                      </p>
                    </div>
                    <span className="mx10">분</span>
                    <span className="">
                      {operation?.productFacilityUsedTime?.meetingRoomMonthlyUsedTime > 0
                        ? " ( " +
                          showHourMinute(
                            operation?.productFacilityUsedTime?.meetingRoomMonthlyUsedTime,
                          ) +
                          " ) "
                        : ""}
                    </span>
                    <span className="mx10">/</span>
                    <div className="">
                      <p className="font14">
                        {operation?.productManage?.meetingRoomTotalTime !== -1
                          ? numberToStringWithComma(
                              operation?.productManage?.meetingRoomTotalTime,
                            ) + "분"
                          : ""}
                      </p>
                    </div>
                    <span className="ml10">
                      {operation?.productManage?.meetingRoomTotalTime >= 0
                        ? " ( " +
                          showHourMinute(operation?.productManage?.meetingRoomTotalTime) +
                          " ) "
                        : showHourMinute(operation?.productManage?.meetingRoomTotalTime)}
                    </span>
                  </div>
                  <div className="flex-row flex-center-start ">
                    <div className="minmax180">
                      <p className="font14">예약 최대 시간/일</p>
                    </div>
                    <div className="">
                      <p className="font14">
                        {operation?.productFacilityUsedTime?.meetingRoomDailyUsedTime || 0}
                      </p>
                    </div>

                    <span className="mx10">분</span>
                    <span className="">
                      {operation?.productFacilityUsedTime?.meetingRoomDailyUsedTime > 0
                        ? " ( " +
                          showHourMinute(
                            operation?.productFacilityUsedTime?.meetingRoomDailyUsedTime,
                          ) +
                          " ) "
                        : ""}
                    </span>
                    <span className="mx10">/</span>
                    <div className="">
                      <p className="font14">
                        {operation?.productManage?.meetingRoomDailyTime !== -1
                          ? numberToStringWithComma(
                              operation?.productManage?.meetingRoomDailyTime,
                            ) + "분"
                          : ""}
                      </p>
                    </div>
                    <span className="ml10">
                      {operation?.productManage?.meetingRoomDailyTime >= 0
                        ? " ( " +
                          showHourMinute(operation?.productManage?.meetingRoomDailyTime) +
                          " ) "
                        : showHourMinute(operation?.productManage?.meetingRoomDailyTime)}
                    </span>
                  </div>
                  <table className="inner-table mt10">
                    <thead>
                      <tr>
                        <th className="minmax70 text-center">
                          <span>ID</span>
                        </th>
                        <th className="minmax100 text-center">
                          <span>회의실 명</span>
                        </th>
                        <th className="minmax80 text-center">
                          <span>정원</span>
                        </th>

                        <th className="minmax90 text-center">
                          <span>지상/지하</span>
                        </th>
                        <th className="minmax90 text-center">
                          <span>위치</span>
                        </th>
                        <th className="minmax100 text-center">
                          <span>건물</span>
                        </th>
                        <th className="minmax250 text-center">
                          <span>리소스 이메일</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {operation?.ctProductFacility.map((mr: any, index: number) => {
                        if (mr.commonFacilityType === "MEETING_ROOM" && mr.isDeleted === false) {
                          return (
                            <tr key={index}>
                              <td className="text-center">
                                <span>{mr.id}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.facilityName}</span>
                              </td>

                              <td className="text-center">
                                <span>{mr.facilityMaxPeopleNums}인</span>
                              </td>

                              <td className="text-center">
                                <span>{mr.isGround ? "지상" : "지하"}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.floorNum + "층"}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.buildingName}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.resourceEmail ? mr.resourceEmail : "-"}</span>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            {/* 리프레시룸 예약 할당 */}
            <section className="contents-container__grid pb20">
              <div className="contents-container__grid-index">
                <p>편의시설 예약</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <div className="minmax180">
                    <p className="font14">
                      {operation?.productManage?.isUseRefreshRoom ? "제공" : "제공 안함"}
                    </p>
                  </div>
                </div>
                <div className={operation?.productManage?.isUseRefreshRoom ? "" : " not-available"}>
                  <div className="flex-row flex-center-start ">
                    <div className="minmax180">
                      <p className="font14">총 예약 시간/월</p>
                    </div>
                    <div className="">
                      <p className="font14">
                        {operation?.productFacilityUsedTime?.refreshRoomMonthlyUsedTime || 0}
                      </p>
                    </div>
                    <span className="mx10">분</span>
                    <span className="">
                      {operation?.productFacilityUsedTime?.refreshRoomMonthlyUsedTime > 0
                        ? " ( " +
                          showHourMinute(
                            operation?.productFacilityUsedTime?.refreshRoomMonthlyUsedTime,
                          ) +
                          " ) "
                        : ""}
                    </span>
                    <span className="mx10">/</span>
                    <div className="">
                      <p className="font14">
                        {operation?.productManage?.refreshRoomTotalTime !== -1
                          ? numberToStringWithComma(
                              operation?.productManage?.refreshRoomTotalTime,
                            ) + "분"
                          : ""}
                      </p>
                    </div>
                    <span className="ml10">
                      {operation?.productManage?.refreshRoomTotalTime >= 0
                        ? " ( " +
                          showHourMinute(operation?.productManage?.refreshRoomTotalTime) +
                          " ) "
                        : showHourMinute(operation?.productManage?.refreshRoomTotalTime)}
                    </span>
                  </div>
                  <div className="flex-row flex-center-start ">
                    <div className="minmax180">
                      <p className="font14">예약 최대 시간/일</p>
                    </div>
                    <div className="">
                      <p className="font14">
                        {operation?.productFacilityUsedTime?.refreshRoomDailyUsedTime || 0}
                      </p>
                    </div>
                    <span className="mx10">분</span>
                    <span className="">
                      {operation?.productFacilityUsedTime?.refreshRoomDailyUsedTime > 0
                        ? " ( " +
                          showHourMinute(
                            operation?.productFacilityUsedTime?.refreshRoomDailyUsedTime,
                          ) +
                          " ) "
                        : ""}
                    </span>
                    <span className="mx10">/</span>
                    <div className="">
                      <p className="font14">
                        {operation?.productManage?.refreshRoomDailyTime !== -1
                          ? numberToStringWithComma(
                              operation?.productManage?.refreshRoomDailyTime,
                            ) + "분"
                          : ""}
                      </p>
                    </div>
                    <span className="ml10">
                      {operation?.productManage?.refreshRoomDailyTime >= 0
                        ? " ( " +
                          showHourMinute(operation?.productManage?.refreshRoomDailyTime) +
                          " ) "
                        : showHourMinute(operation?.productManage?.refreshRoomDailyTime)}
                    </span>
                  </div>
                  <table className="inner-table mt10">
                    <thead>
                      <tr>
                        <th className="minmax70 text-center">
                          <span>ID</span>
                        </th>
                        <th className="minmax100 text-center">
                          <span>편의시설 명</span>
                        </th>
                        <th className="minmax140 text-center">
                          <span>설명</span>
                        </th>
                        <th className="minmax90 text-center">
                          <span>지상/지하</span>
                        </th>
                        <th className="minmax90 text-center">
                          <span>위치</span>
                        </th>
                        <th className="minmax100 text-center">
                          <span>건물</span>
                        </th>
                        {/* <th className="minmax100 text-center">
                          <span>리소스 이메일</span>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {operation?.ctProductFacility.map((mr: any, index: number) => {
                        if (mr.commonFacilityType === "REFRESH_ROOM" && mr.isDeleted === false) {
                          return (
                            <tr key={index}>
                              <td className="text-center">
                                <span>{mr.id}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.facilityName}</span>
                              </td>

                              <td className="text-center">
                                <div className="minmax220">
                                  <span className="ellipsis">
                                    {mr.description ? (
                                      <BaseTooltip contents={mr.description} />
                                    ) : (
                                      "-"
                                    )}
                                  </span>
                                </div>
                              </td>
                              <td className="text-center">
                                <span>{mr.isGround ? "지상" : "지하"}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.floorNum + "층"}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.buildingName}</span>
                              </td>
                              {/* <td className="text-center">
                                <span>{mr.resourceEmail ? mr.resourceEmail : "-"}</span>
                              </td> */}
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            {/* 1인 데스크 할달 */}

            <section className="contents-container__grid pb20">
              <div className="contents-container__grid-index">
                <p>좌석 예약</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <div className="minmax180">
                    <p className="font14">
                      {operation?.productManage?.isUseDesk ? "제공" : "제공 안함"}
                    </p>
                  </div>
                </div>
                <div className={operation?.productManage?.isUseDesk ? "" : " not-available"}>
                  <div className="flex-row flex-center-start ">
                    <div className="minmax180">
                      <p className="font14">총 예약 시간/월</p>
                    </div>
                    <div className="">
                      <p className="font14">
                        {operation?.productFacilityUsedTime?.deskMonthlyUsedTime || 0}
                      </p>
                    </div>
                    <span className="mx10">분</span>
                    <span className="">
                      {operation?.productFacilityUsedTime?.deskMonthlyUsedTime > 0
                        ? " ( " +
                          showHourMinute(operation?.productFacilityUsedTime?.deskMonthlyUsedTime) +
                          " ) "
                        : ""}
                    </span>
                    <span className="mx10">/</span>
                    <div className="">
                      <p className="font14">
                        {operation?.productManage?.deskTotalTime !== -1
                          ? numberToStringWithComma(operation?.productManage?.deskTotalTime) + "분"
                          : ""}
                      </p>
                    </div>
                    <span className="ml10">
                      {operation?.productManage?.deskTotalTime >= 0
                        ? " ( " + showHourMinute(operation?.productManage?.deskTotalTime) + " ) "
                        : showHourMinute(operation?.productManage?.deskTotalTime)}
                    </span>
                  </div>
                  <div className="flex-row flex-center-start ">
                    <div className="minmax180">
                      <p className="font14">예약 최대 시간/일</p>
                    </div>
                    <div className="">
                      <p className="font14">
                        {operation?.productFacilityUsedTime?.deskDailyUsedTime || 0}
                      </p>
                    </div>
                    <span className="mx10">분</span>
                    <span className="">
                      {operation?.productFacilityUsedTime?.deskDailyUsedTime > 0
                        ? " ( " +
                          showHourMinute(operation?.productFacilityUsedTime?.deskDailyUsedTime) +
                          " ) "
                        : ""}
                    </span>
                    <span className="mx10">/</span>
                    <div className="">
                      <p className="font14">
                        {operation?.productManage?.deskDailyTime !== -1
                          ? numberToStringWithComma(operation?.productManage?.deskDailyTime) + "분"
                          : ""}
                      </p>
                    </div>
                    <span className="ml10">
                      {operation?.productManage?.deskDailyTime >= 0
                        ? " ( " + showHourMinute(operation?.productManage?.deskDailyTime) + " ) "
                        : showHourMinute(operation?.productManage?.deskDailyTime)}
                    </span>
                  </div>
                  <table className="inner-table mt10">
                    <thead>
                      <tr>
                        <th className="minmax70 text-center">
                          <span>ID</span>
                        </th>
                        <th className="minmax100 text-center">
                          <span>좌석 그룹명</span>
                        </th>
                        <th className="minmax100 text-center">
                          <span>좌석 명</span>
                        </th>

                        <th className="minmax140 text-center">
                          <span>설명</span>
                        </th>
                        <th className="minmax90 text-center">
                          <span>지상/지하</span>
                        </th>
                        <th className="minmax90 text-center">
                          <span>위치</span>
                        </th>
                        <th className="minmax100 text-center">
                          <span>건물</span>
                        </th>
                        {/* <th className="minmax100 text-center">
                          <span>리소스 이메일</span>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {operation?.ctProductFacility.map((mr: any, index: number) => {
                        if (mr.commonFacilityType === "DESK" && mr.isDeleted === false) {
                          return (
                            <tr key={index}>
                              <td className="text-center">
                                <span>{mr.id}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.buildingCommonFacilityDeskGroupName}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.facilityName}</span>
                              </td>

                              <td className="text-center">
                                <div className="minmax220">
                                  <span className="ellipsis">
                                    {mr.description ? (
                                      <BaseTooltip contents={mr.description} />
                                    ) : (
                                      "-"
                                    )}
                                  </span>
                                </div>
                              </td>
                              <td className="text-center">
                                <span>{mr.isGround ? "지상" : "지하"}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.floorNum + "층"}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.buildingName}</span>
                              </td>
                              {/* <td className="text-center">
                                <span>{mr.resourceEmail ? mr.resourceEmail : "-"}</span>
                              </td> */}
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
            <section className="contents-container__grid pb20">
              <div className="contents-container__grid-index">
                <p>방문자 초대</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <div className="minmax180">
                    <p className="font14">
                      {operation?.productManage?.isUseVisitorInvite ? "제공" : "제공 안함"}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </article>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <BaseButton
            title="목록으로"
            onClick={() => moveList()}
            className="color-white size-large"
          />
        </div>
        <div className="right-area">
          <BaseButton
            title="수정"
            disabled={
              basicInfo?.contractStep === "APPLY_RECEIVED" ||
              basicInfo?.contractStep === "APPLY_CANCEL" ||
              basicInfo?.contractStep === "APPLY_CONFIRM" ||
              basicInfo?.contractStep === "TERMINATE_COMPLETE" ||
              basicInfo?.contractStep === "USE_COMPLETE"
            }
            onClick={() => moveEdit()}
            className="size-large"
          />
        </div>
      </div>
    </div>
  );
};
export default ContractFormManage;
