import qs from "qs";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getBuildingAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getProductDetailAsync } from "src/api/product/product-api";
import { ProductModel } from "src/api/product/product-types";
import { BaseButton } from "src/components";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import pagePath from "src/pagePath.json";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import OnlyNormalProductAllow from "../../components/OnlyNormalProductAllow";
import ProductParkingSection from "../../components/ProductParkingSection";
import { DeskMediaType } from "../../forms/commonSpaceForm/CommonSpaceForm";
import DeskComp from "./components/DeskComp";
import MeetingRoomComp from "./components/MeetingRoomComp";
import RefreshRoomComp from "./components/RefreshRoomComp";

const CommonSpace = ({ product }: { product: ProductModel }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoadingBar } = useLoadingBarContext();
  const param = useParams();

  const s3Url = process.env.REACT_APP_S3_BASEURL;

  const sampleImg =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/No_Cross.svg/1024px-No_Cross.svg.png";

  const params = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
  });

  const productId = Number(param.id);

  // TODO 건물 들의 상세 내역을 조회
  const { executeAsync: getBuildingCommonSpace } = useApiOperation(getBuildingAsync);

  // const [buildingDetail, setBuildingDetail] = useState<BuildingModel>();

  const [deskMedia, setDeskMedia] = useState<Array<DeskMediaType>>([]);

  const [productDetail, setProductDetail] = useState<ProductModel>();

  // 공간상품 상세 api
  const getProductBasicDetail = useCallback(async () => {
    setProductDetail(product);
  }, [productId, setLoadingBar]);

  useTitleOperation(productDetail?.productName);

  useEffect(() => {
    if (productId) {
      getProductBasicDetail();
    } else {
    }
  }, [getProductBasicDetail, productId]);

  //
  // 건물 상세 API
  const getBuildingDetail = useCallback(async () => {
    if (productDetail) {
      const productBuildings = productDetail.buildingList;
      let deskMediaList: DeskMediaType[] = [];

      if (productBuildings) {
        for (let i = 0; i < productBuildings.length; i++) {
          const productBuilding = productBuildings[i];

          const response = await getBuildingCommonSpace({ buildingId: productBuilding.id });

          if (response.status >= 200 && response.status <= 299) {
            const bd: BuildingModel = response.data.data.content.building;

            deskMediaList = [
              ...deskMediaList,
              { id: bd.id, media: bd?.buildingCommonFacility?.deskSpace?.mediaList || [] },
            ];

            setDeskMedia(deskMediaList);
          }
        }
      }
    }
  }, [getBuildingCommonSpace, productDetail]);

  useEffect(() => {
    if (productDetail) {
      getBuildingDetail();
    }
  }, [getBuildingDetail, productDetail]);

  const goEditFrom = () => {
    const path = pagePath.product.form + "?id=" + productId + "&tab=" + params.tab;
    navigate(path);
  };

  return (
    <div className="contents-container__wrap">
      <div>
        <div className="contents-container__wrap-contents">
          <ContentsIdSection title="공용공간" id={productId!} />
          {/* 주차 */}
          <article>
            <div className="contents-container__sub-title">
              <div className="minmax140 pb4">
                <h2>주차</h2>
              </div>
              {!productDetail?.isParkingLot && <span className="font14">데이터가 없습니다.</span>}
            </div>

            {/* 토글을 닫으면 contents-container__divide-bottom 영역이 노출되지 않음 */}
            {productDetail?.isParkingLot && (
              <div className="contents-container__divide-bottom">
                {/* 상세 설명 */}
                <section className="contents-container__grid contents-container__1200">
                  <div className="contents-container__grid-index">
                    <p className="">상세 설명</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <table className="inner-table" width="100%">
                      <thead>
                        <tr>
                          <th>
                            <span>구분</span>
                          </th>
                          <th>
                            <span>설명</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="100">
                            <div className="flex-center-center no-wrap">
                              <span>무료</span>
                            </div>
                          </td>
                          <td>
                            <div className="flex-center no-wrap">
                              <span>{productDetail.parkingLotFreeDescription}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td width="100">
                            <div className="flex-center-center no-wrap">
                              <span>유료</span>
                            </div>
                          </td>
                          <td>
                            <div className="flex-center no-wrap">
                              <span>{productDetail.parkingLotPayDescription}</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>

                {/* 주차장 정보 */}
                <section className="contents-container__grid contents-container__1200">
                  <div className="contents-container__grid-index">
                    <p>주차장 정보</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    {productDetail?.buildingList?.map((building: BuildingModel) => {
                      return (
                        <div className="pt8 mb10" key={building.id}>
                          <div className="index-tab">
                            <span>{building.buildingName}</span>
                          </div>
                          <ProductParkingSection building={building} />
                        </div>
                      );
                    })}
                  </div>
                </section>
              </div>
            )}
          </article>
          {/* 회의실 */}
          <article>
            <div className="contents-container__sub-title">
              <div className="minmax140 pb4">
                <h2>회의실</h2>
              </div>
              {!productDetail?.isMeetingRoom && <span className="font14">데이터가 없습니다.</span>}
            </div>

            {/* 토글을 닫으면 contents-container__divide-bottom 영역이 노출되지 않음 */}
            {productDetail?.isMeetingRoom && <MeetingRoomComp productDetail={productDetail} />}
          </article>

          {/*  */}
          {/* 좌석 선택 */}
          <article>
            <div className="contents-container__sub-title">
              <div className="minmax140 pb4">
                <h2>좌석</h2>
              </div>
              {!productDetail?.isDesk && <span className="font14">데이터가 없습니다.</span>}
            </div>

            {/* 토글을 닫으면 contents-container__divide-bottom 영역이 노출되지 않음 */}
            {productDetail?.isDesk && <DeskComp productDetail={productDetail} />}
          </article>
          {/* 리프레시 룸 */}
          <article>
            <div className="contents-container__sub-title">
              <div className="minmax140 pb4">
                <h2>편의시설</h2>
              </div>
              {!productDetail?.isRefreshRoom && <span className="font14">데이터가 없습니다.</span>}
            </div>

            {/* 구분선 없음, 토글시 아래 영역이 노출되지 않음 */}
            {productDetail?.isRefreshRoom && <RefreshRoomComp productDetail={productDetail} />}
          </article>
        </div>
        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              className="color-white size-large"
              onClick={() => navigate(pagePath.product.list)}
            />
          </div>
          <div className="right-area">
            {/* <BaseButton title="수정" onClick={() => goEditFrom()} className=" size-large" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CommonSpace;
