import moment from "moment";
import { useEffect, useState } from "react";
import { ArticleSection } from "./ArticleSection";
import { CommonDateNUser } from "src/api/public-types";
import BaseSection from "./BaseSection";
import { YmdFormat } from "src/utils";

/**
 * 등록정보 공용 컴포넌트
 * @param obj any
 * @returns
 */
const extractRelevantData = (obj: any): CommonDateNUser => {
  return {
    createdBy: obj?.createdBy ?? "",
    modifiedBy: obj?.modifiedBy ?? "",
    createdDate: obj?.createdDate ?? "",
    modifiedDate: obj?.modifiedDate ?? "",
  };
};

const RegistrationInformation = ({ data }: any) => {
  const [infoData, setInfoData] = useState<CommonDateNUser>({
    createdBy: "",
    modifiedBy: "",
    createdDate: "",
    modifiedDate: "",
  });

  useEffect(() => {
    const extractedData = extractRelevantData(data);
    setInfoData(extractedData);
  }, [data]);

  return (
    <ArticleSection
      title={"등록 정보"}
      children={
        <>
          <div className="flex-center">
            <BaseSection
              title={"최초 등록일"}
              value={
                infoData?.createdDate ? moment(infoData?.createdDate).format(YmdFormat.FULL) : "-"
              }
            />
            <BaseSection
              title={"최초 작성자"}
              value={infoData?.createdBy ? infoData?.createdBy : "-"}
            />
          </div>
          <div className="flex-center">
            <BaseSection
              title={"최종 수정일"}
              value={
                infoData?.modifiedDate ? moment(infoData?.modifiedDate).format(YmdFormat.FULL) : "-"
              }
            />
            <BaseSection
              title={"최종 작성자"}
              value={infoData?.modifiedBy ? infoData?.modifiedBy : "-"}
            />
          </div>
        </>
      }
    />
  );
};

export default RegistrationInformation;
