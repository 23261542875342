import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { getBuildingAsync } from "src/api/building/building-api";
import {
  BuildingFloorModel,
  BuildingModel,
  BuildingRoomModel,
} from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import {
  createProductAsync,
  getProductDetailAsync,
  updateProductAsync,
} from "src/api/product/product-api";
import {
  ProductAddModel,
  ProductBuilding,
  ProductCategory,
  ProductEditModel,
  ProductModel,
} from "src/api/product/product-types";
import { MetaData } from "src/api/public-types";
import {
  BaseButton,
  BaseDatePicker,
  BaseInput,
  BaseModal,
  BaseRadio,
  BaseTextarea,
  ContentsIdSection,
} from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import { Partner } from "src/types/partner";
import { onlyNumberAndSign } from "src/utils";
import BuildingFloorHoModal from "../../components/BuildingFloorHoModal";
import ProductBuildingListModal from "../../components/ProductBuildingListModal";
import BuildingAndHoTable from "../../modalPopup/table/BuildingAndHoTable";
import {
  BuildingFloorAndHo,
  Modal,
  productDirections,
  PRODUCT_CATEGORY_MAP,
  PropertyType,
} from "../../product-types";
import ProductDirectionCheckBox from "./ProductDirectionCheckBox";

export type ProductFormData = {
  productCategory: ProductCategory;
  productName?: string;
  introduce?: string;
  productDirectionList?: Array<MetaData>;
  description?: string;
  colorName?: string;
  questionPhone?: string;
  questionStartTime?: Date;
  questionEndTime?: Date;
  buildingList?: Array<BuildingFloorAndHo>;
  locationCode?: string;
  vocUrl?: string;
  partnerId?: string;
};

// 테마색상 리스트
//https://www.figma.com/file/xcR215pWONPlCdVaxa5fIN/%5BTaap%5D-ver.-1.0?node-id=5930%3A36583
const themeColors = [
  "redg01",
  "redg02",
  "redg03",
  "redg04",
  "greyg01",
  "greeng01",
  "greeng02",
  "blueg01",
  "blueg02",
  "blueg03",
];

interface IProps {
  product: ProductModel;
  changeProductCategory: (category: ProductCategory) => void;
}

/* 
  공간상품 등록 > 등록 or 수정 > 기본 정보
 */
const BasicInfoForm = ({ product: productDetail, changeProductCategory }: IProps) => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();
  // 토스트
  const { openToast } = useToastContext();

  const navigate = useNavigate();
  const location = useLocation();

  const [selectedBuilding, setSelectedBuilding] = useState<BuildingModel | null>(null); // 모달에서 선택한 빌딩
  const [selectedRooms, setSelectedRooms] = useState<Array<PropertyType>>([]); // 모달에서 가져온 층,호 리스트
  const [selectedRow, setSelectedRow] = useState({});
  const [buildingAndHo, setBuildingAndHo] = useState<Array<BuildingFloorAndHo>>([]);
  const [submitData, setSubmitData] = useState<ProductFormData>();
  const [partner, setPartner] = useState<Partner>();

  // 모달 useState
  const [buildingModal, setBuildingModal] = useState<Modal>({ isOpen: false }); //빌딩 팝업 모달
  const [floorHoModal, setFloorHoModal] = useState<Modal>({ isOpen: false });
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });
  const [cancelModal, setCancelModal] = useState<Modal>({
    isOpen: false,
  });
  const [partnerSelectModal, setPartnerSelectModal] = useState<Modal>({
    isOpen: false,
  });
  const [formResetModal, setFormResetModal] = useState({
    isOpen: false,
    okCallback: () => {},
  });

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // react Hook Form 부분
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<ProductFormData>({
    defaultValues: {
      productCategory: "PRODUCT_CATEGORY_NORMAL",
      productName: "",
      introduce: "",
      productDirectionList: [],
      description: "",
      colorName: "",
      questionPhone: "",
      buildingList: [],
      locationCode: "",
      vocUrl: "",
    },
  });

  // -------------------------------------------

  // 공간상품 상세 조회 (수정일 경우)
  const productId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;
  // const [productDetail, setProductDetail] = useState<ProductModel>();

  useTitleOperation(productDetail?.productName);

  // -------------------------------------------

  // 공간상품 등록 Api
  const { executeAsync: createProduct } = useApiOperation(createProductAsync);
  //
  // 공간상품 수정 Api

  const { executeAsync: updateProduct } = useApiOperation(updateProductAsync);

  // 건물 상세 조회 api hook
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  // 공간상품 상세 get Api
  // const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync);

  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  //
  // 수정 시, 건물 층 호실 대표여부 (isPrimary) checked
  const isPrimaryBuildingChecked = useCallback((table, id?) => {
    let pri = {};

    const primaryIdx = table.findIndex((item: BuildingFloorAndHo) => item.isPrimary === true);
    const primaryItem = table.find((item: BuildingFloorAndHo) => item.isPrimary === true);

    if (id) {
      let wasPrimary = Object.assign(pri, { [primaryIdx]: true });

      if (!primaryItem) {
        wasPrimary = Object.assign(pri, { 0: true });
      }
      setSelectedRow(wasPrimary);
    } else {
      const wasPrimary = Object.assign(pri, { 0: true });
      setSelectedRow(wasPrimary);
    }
  }, []);

  // 공간상품 상세 불러와서 setValue 하기
  const getProductBasicDetail = useCallback(async () => {
    if (productId) {
      // setLoadingBar(true);
      // const response = await getProductDetail({ productId });

      const result = productDetail;

      if (result) {
        Object.entries(result).forEach(([name, value]: any) => {
          if (name === "buildingList") {
            if (value) {
              const buildingArr: Array<{ building: BuildingModel }> = value;

              let newArr: Array<BuildingFloorAndHo> = [];
              // 건물/호실 테이블 데이터
              buildingArr?.forEach((item) => {
                const building = item?.building;

                const floorList = building?.buildingFloorList;
                floorList?.forEach((floor: BuildingFloorModel) => {
                  const roomList = floor?.buildingRoomList;

                  roomList?.forEach((room: BuildingRoomModel) => {
                    let data: BuildingFloorAndHo = {
                      buildingId: String(building.id),
                      buildingName: building.buildingName,
                      floorId: String(floor.id),
                      floorNum: Number(floor.floorNum),
                      roomId: String(room.id),
                      roomNum: Number(room.roomNum),
                      isPrimary: Boolean(room.isPrimary),
                      leasableArea: String(room.leasableArea),
                      leasableAreaNet: String(room.leasableAreaNet),
                      rateOfUse: (Number(room.leasableAreaNet) / Number(room?.leasableArea)) * 100,
                    };
                    newArr.push(data);
                  });
                });
              });
              setBuildingAndHo(newArr);
              setValue("buildingList", newArr);
              isPrimaryBuildingChecked(newArr, productId);
            }
          } else if (name === "questionPhone") {
            // 문의 연락처
            if (value) {
              setValue("questionPhone", value);
            }
          } else if (name === "productDirectionList") {
            // 향
            if (value) {
              setValue("productDirectionList", value);
            }
          } else if (name === "questionStartTime") {
            // 문의 시간 (시작)""
            if (value) {
              setValue("questionStartTime", moment(value, "hh:mm").toDate());
            }
          } else if (name === "questionEndTime") {
            // 문의 시간 (종료)
            if (value) {
              setValue("questionEndTime", moment(value, "hh:mm").toDate());
            }
          } else {
            setValue(name, value);
          }
        });
      }

      // setProductDetail(result);
      // setLoadingBar(false);
    }
  }, [productId, productDetail, setLoadingBar, setValue, isPrimaryBuildingChecked]);

  useEffect(() => {
    getProductBasicDetail();
  }, [getProductBasicDetail]);

  // 카테고리 선택할 때 상위 컴포넌트에 알려줌
  const watchedProductCategory = watch("productCategory");

  useEffect(() => {
    changeProductCategory(watchedProductCategory);
    setValue("buildingList", []);
    setSelectedRooms([]);
    setBuildingAndHo([]);
  }, [watchedProductCategory]);

  useEffect(() => {
    (async () => {
      if (productDetail?.partnerId === undefined) return;
      if (productDetail.partnerId === "0") return;

      const result = await getPartnerAsync({
        id: productDetail.partnerId,
      });

      if (result.status >= 200 && result.status <= 299) {
        setPartner(result.data.data.partner);
      }
    })();
  }, [productDetail]);

  //

  // 건물의 상세조회 후 location code Get
  const getLocationCode = useCallback(
    async (primaryId?) => {
      if (primaryId) {
        const { data } = await getBuilding({ buildingId: Number(primaryId) });
        const building: BuildingModel = data?.data?.content?.building;
        if (building?.locationCode) {
          setValue("locationCode", building.locationCode);
        }
      }
    },
    [getBuilding, setValue],
  );

  useEffect(() => {
    if (selectedBuilding?.id) {
      getLocationCode(selectedBuilding?.id);
    }
  }, [getLocationCode, selectedBuilding?.id]);

  //
  //  유효성 검사
  const formVaildation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";
    register("productName", {
      required: { value: true, message: requiredMessage },
      maxLength: { value: 30, message: "30자 이상 입력 불가능 합니다." },
    });

    register("colorName", {
      required: { value: true, message: requiredMessage },
    });
    register("introduce", {
      required: { value: true, message: requiredMessage },
      maxLength: { value: 100, message: "100자 이상 입력 불가능 합니다." },
    });
    register("description", {
      required: { value: true, message: requiredMessage },
    });
    register("questionPhone", {
      required: { value: true, message: requiredMessage },
    });

    register("questionStartTime", {
      required: { value: true, message: requiredMessage },
    });
    register("questionEndTime", {
      required: { value: true, message: requiredMessage },
    });
    register("buildingList", {
      required: { value: true, message: "필수선택 항목입니다" },
    });
    register("vocUrl", {
      validate: {
        required: (value) => {
          let message = requiredMessage;
          const pattern = /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi;
          const isValidate = pattern.test(String(value));
          if (!isValidate) message = "http:// 또는 https:// 로 시작하는 값을 입력해주세요.";
          return isValidate || message;
        },
      },
    });
  }, [register]);

  //
  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    formVaildation();
  }, [formVaildation]);

  //
  // validation 통과 후 submit 될때 실행
  const onSubmit = useCallback(async (data: ProductFormData, e?: any) => {
    e.preventDefault();
    // 건물 대표선택 모두 false시 경고모달
    if (data.buildingList && data.buildingList.length > 0) {
      const isPrimaryResult = data.buildingList.every((item) => item.isPrimary === false);
      if (isPrimaryResult === true) {
        setAlertModal({ isOpen: true, message: "건물/호실 대표선택이 되지 않았습니다." });
      }
    }

    setSubmitData(data);

    setConfirmModal({ message: "공간상품 기본정보 저장하시겠습니까?", isOpen: true });
  }, []);

  // 저장 / 수정
  const onClickConfirmModal = async (data: ProductFormData) => {
    setLoadingBar(true);

    // viewTable 데이터 -> 서버 productBuildingList 용으로 변환

    const table = data.buildingList;

    const changeBuildings: Array<ProductBuilding> = _.chain(table)
      .groupBy("buildingId")
      .map(function (v, i) {
        return {
          id: Number(_.get(_.find(v, "buildingId"), "buildingId")),
          productBuildingFloorList: _.chain(v)
            .groupBy("floorId")
            .map((v, i) => {
              return {
                id: Number(i),
                productBuildingRoomList: v.map((a) => {
                  return {
                    id: Number(a.roomId),
                    isPrimary: a.isPrimary,
                  };
                }),
              };
            })
            .value(),
        };
      })
      .orderBy(["buildingId"])
      .value();

    const basicInfoData: ProductAddModel = {
      productName: data.productName,
      introduce: data.introduce,
      description: data.description,
      questionPhone: data.questionPhone,
      colorName: data.colorName,
      questionStartTime: moment(data.questionStartTime).format("HH:mm"),
      questionEndTime: moment(data.questionEndTime).format("HH:mm"),
      productDirectionList: data.productDirectionList,
      productBuildingList: changeBuildings, // 공간상품에 등록할 건물ID
      locationCode: data.locationCode,
      vocUrl: data.vocUrl,
      productCategory: data.productCategory,
      partnerId: data.partnerId,
    };

    if (productId) {
      // 수정
      const updateData: ProductEditModel = {
        ...basicInfoData,
        id: productId,
      };

      //
      const updateResponse = await updateProduct({ product: updateData });

      if (updateResponse.status >= 200 && updateResponse.status <= 299) {
        const result = updateResponse.data?.data?.content;
        openToast(`정상적으로 ${productId ? "수정" : "등록"}되었습니다.`);
        navigate(PagePath.product.detail.replace(":id", `${result}`));
      }
    } else {
      // 등록

      const response = await createProduct({ product: basicInfoData });

      if (response.status >= 200 && response.status <= 299) {
        const result = response.data?.data?.content;
        openToast(`정상적으로 ${productId ? "수정" : "등록"}되었습니다.`);

        navigate(PagePath.product.detail.replace(":id", `${result}`));
      } else {
        setLoadingBar(false);
        setConfirmModal({ isOpen: false });
        return;
      }
    }
    setLoadingBar(false);
    return false;
  };

  console.log(`selected`, selectedRooms);
  //
  //
  // ============== 공간상품 처음 등록 시 건물/층 목록 ==============
  // 서버에서 가져온 properties와 층/호실 팝업에서 선택한 selectedRooms를 비교해 ID 값이 일치하는 것만 리턴
  const onDrawViewTable = useCallback(
    (rooms: Array<PropertyType>) => {
      const viewArr: Array<BuildingFloorAndHo> = []; // view 용

      rooms.forEach((item) => {
        const viewData = {
          buildingId: String(selectedBuilding?.id),
          roomId: String(item.roomId),
          buildingName: selectedBuilding?.buildingName,
          floorNum: item.floorNum,
          roomNum: item.roomNum,
          leasableAreaNet: item.leasableAreaNet,
          leasableArea: item.leasableArea,
          isPrimary: false,
          floorId: String(item.floorId),
          rateOfUse: (Number(item.leasableAreaNet) / Number(item.leasableArea)) * 100,
        };

        viewArr.push(...buildingAndHo, viewData);
      });
      setBuildingAndHo(viewArr);

      // 이미 있는 리스트는 제거
      const filteredViewTable = viewArr.filter((item: BuildingFloorAndHo, i: number) => {
        return (
          viewArr.findIndex((item2: BuildingFloorAndHo, j: number) => {
            return item.roomId === item2.roomId;
          }) === i
        );
      });

      setValue("buildingList", filteredViewTable);
      isPrimaryBuildingChecked(filteredViewTable);
    },
    [
      setValue,
      isPrimaryBuildingChecked,
      selectedBuilding?.id,
      selectedBuilding?.buildingName,
      buildingAndHo,
    ],
  );

  const handleClickBuildingListSelectButton = () => {
    if (partner === undefined) {
      setAlertModal({
        isOpen: true,
        message: "파트너를 먼저 선택해주세요.",
      });

      return;
    }

    const buildingList = getValues("buildingList");
    if (buildingList === undefined) return;

    if (
      buildingList.length > 0 &&
      productDetail.productCategory === "PRODUCT_CATEGORY_MANAGEMENT"
    ) {
      setAlertModal({
        isOpen: true,
        message:
          "이미 건물/호실을 등록하였습니다. \n 다른 건물/호실을 등록하실 경우 등록된 호실/건물을 삭제해주세요.",
      });
    } else {
      setBuildingModal({ isOpen: true });
    }
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = useCallback((errors: any, e?: any) => {
    return false;
  }, []);

  const handleClickPartnerSelectButton = () => {
    setPartnerSelectModal({ isOpen: true });
  };

  const handleClickResetFormOkButton = () => {
    reset();

    formVaildation();

    formResetModal.okCallback();

    setFormResetModal({
      okCallback: () => {},
      isOpen: false,
    });
  };

  const handleClickResetFormCancelButton = () => {
    setFormResetModal({
      ...formResetModal,
      isOpen: false,
    });
  };

  const onAddedPartnerSelectModal = (parterList: Partner[]) => {
    const newPartenr = parterList[0];
    if (partner !== undefined && partner?.id !== newPartenr.id) {
      setFormResetModal({
        okCallback: () => {
          setPartner(newPartenr);
          setValue("partnerId", newPartenr.id);
        },
        isOpen: true,
      });
    } else {
      setPartner(newPartenr);
      setValue("partnerId", newPartenr.id);
    }

    setPartnerSelectModal({
      isOpen: false,
    });
  };
  useTitleOperation(productDetail?.productName);

  return (
    <div className="contents-container__wrap">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__wrap-contents">
          <ContentsIdSection title="기본정보" id={productId} />

          {/* 구분 */}
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>구분</p>
            </div>
            <div className="contents-container__grid-contents">
              {productId === undefined ? (
                <Controller
                  control={control}
                  name="productCategory"
                  render={({ field }) => {
                    return (
                      <div className="flex-row flex-center-start">
                        <BaseRadio
                          id={"productCategoryNormal"}
                          name={"productCategory"}
                          label="일반"
                          onChange={(e) => {
                            setValue("productCategory", "PRODUCT_CATEGORY_NORMAL");
                          }}
                          checked={field.value === "PRODUCT_CATEGORY_NORMAL"}
                          value="PRODUCT_CATEGORY_NORMAL"
                          className="mr16"
                        />
                        <BaseRadio
                          id={"productCategoryManagement"}
                          name={"productCategory"}
                          onChange={(e) => {
                            setValue("productCategory", "PRODUCT_CATEGORY_MANAGEMENT");
                          }}
                          checked={field.value === "PRODUCT_CATEGORY_MANAGEMENT"}
                          label={"관리비 계약 상품"}
                        />
                      </div>
                    );
                  }}
                />
              ) : (
                <div className="minmax400">
                  <p>{PRODUCT_CATEGORY_MAP[productDetail?.productCategory!]}</p>
                </div>
              )}
            </div>
          </section>
          {/* 파트너 */}
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">파트너</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                {partner?.id && <span className="mr16">{`${partner?.name} (${partner?.id})`}</span>}
                {!productId && (
                  <Controller
                    name="partnerId"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "필수입력 항목입니다",
                      },
                    }}
                    render={({ field, fieldState }) => {
                      return (
                        <div>
                          <BaseButton
                            title="파트너 선택"
                            className="color-white"
                            onClick={handleClickPartnerSelectButton}
                          />
                          {fieldState.error && (
                            <p className="validation-text pt8">{fieldState.error?.message}</p>
                          )}
                        </div>
                      );
                    }}
                  ></Controller>
                )}
              </div>
            </div>
          </section>

          {/* 건물/호실 */}
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">건물/호실</p>
            </div>
            <div className="contents-container__grid-contents">
              <BaseButton
                title="선택"
                className="color-white"
                onClick={handleClickBuildingListSelectButton}
              />
            </div>
          </section>

          {/*기본정보 페이지 내에 건물/호실 테이블 */}
          <div className="contents-container__grid-contents">
            <div>
              <Controller
                control={control}
                name="buildingList"
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  return (
                    <BuildingAndHoTable
                      viewTable={value || []}
                      setViewTable={onChange}
                      errorText={error?.message}
                      selectedRow={selectedRow}
                      onSelectedRowsChange={setSelectedRow}
                      setBuildingAndHo={setBuildingAndHo}
                      isPrimaryBuildingChecked={isPrimaryBuildingChecked}
                      getLocationCode={getLocationCode}
                      // setValue={setValue}
                      getValues={getValues}
                    />
                  );
                }}
              ></Controller>
            </div>
          </div>

          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">공간상품명</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <Controller
                  control={control}
                  name="productName"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                    return (
                      <BaseInput
                        className="mr8"
                        type="text"
                        onChange={onChange}
                        value={value}
                        name={name}
                        errorText={error?.message}
                      />
                    );
                  }}
                ></Controller>
              </div>
            </div>
          </div>

          {productDetail?.productCategory === "PRODUCT_CATEGORY_NORMAL" && (
            <>
              <div className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">Location code</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <Controller
                      control={control}
                      name="locationCode"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseInput
                            className="mr8"
                            type="text"
                            onChange={onChange}
                            value={value}
                            name={name}
                            errorText={error?.message}
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </div>

              {/* 테마 색상 */}
              <div className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">테마 색상</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row d-flex justify-contents-start flex-start product-theme">
                    <Controller
                      control={control}
                      name="colorName"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <div className={`product-theme__selected ${value}`}></div>
                            <div className="product-theme__colors">
                              {themeColors.map((colorCode: string) => (
                                <div
                                  key={colorCode}
                                  className={`colors ${colorCode}`}
                                  onClick={() => onChange(colorCode)}
                                ></div>
                              ))}
                            </div>
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                  <p className="font12 text-primary5">* Taap 홈 화면 상단 상품 영역에 적용됩니다.</p>
                  {errors.colorName && <p className="validation-text">{`필수선택 항목입니다`}</p>}
                </div>
              </div>
              <div className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">1줄 소개</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <Controller
                      control={control}
                      name="introduce"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseInput
                          className="mr8"
                          type="text"
                          onChange={onChange}
                          value={value}
                          name={name}
                          errorText={error?.message}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </div>
              <div className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>향</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row justify-contents-start align-items-center">
                    <Controller
                      control={control}
                      name="productDirectionList"
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <>
                            {productDirections.map((direction, index: number) => {
                              return (
                                <ProductDirectionCheckBox
                                  key={index}
                                  productDirection={direction.value}
                                  label={direction.label}
                                  name={name}
                                  onChange={onChange}
                                  value={value}
                                />
                              );
                            })}
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </div>
              <div className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">설명</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <Controller
                      control={control}
                      name="description"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseTextarea
                          className="mr8"
                          value={value}
                          name={name}
                          onChange={onChange}
                          errorText={error?.message}
                          maxLength={1000}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </div>

              {/* 문의 연락처 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">문의 연락처</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400 flex-row flex-center">
                    <Controller
                      control={control}
                      name="questionPhone"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseInput
                          type="text"
                          maxLength={13}
                          onChange={onChange}
                          value={onlyNumberAndSign(value)}
                          name={name}
                        />
                      )}
                    ></Controller>
                  </div>
                  {errors && errors.questionPhone?.message ? (
                    <p className="validation-text">필수입력 항목입니다</p>
                  ) : (
                    ""
                  )}
                </div>
              </section>

              {/* 문의 시간 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">문의 시간</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400 flex-row align-items-center">
                    <Controller
                      control={control}
                      name="questionStartTime"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseDatePicker
                            timeIntervals={10}
                            type="time"
                            selectedDate={value}
                            setDate={onChange}
                            name={name}
                          />
                        );
                      }}
                    ></Controller>

                    <span className="font14 mx10">~</span>

                    <Controller
                      control={control}
                      name="questionEndTime"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseDatePicker
                          className="mr10"
                          timeIntervals={10}
                          type="time"
                          selectedDate={value}
                          setDate={onChange}
                          name={name}
                        />
                      )}
                    ></Controller>
                  </div>
                  {errors &&
                  (errors.questionStartTime?.message || errors.questionEndTime?.message) ? (
                    <p className="validation-text">필수입력 항목입니다</p>
                  ) : (
                    ""
                  )}
                </div>
              </section>

              {/* VOC 링크 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">VOC 링크</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400 flex-row flex-center">
                    <Controller
                      control={control}
                      name="vocUrl"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseInput
                          type="text"
                          onChange={onChange}
                          value={`${value}`}
                          name={name}
                          errorText={error?.message}
                          placeholder="Taap > 이용안내 1:1 문의 터치 시 연결될 링크를 입력해주세요."
                        />
                      )}
                    ></Controller>
                  </div>
                  {errors && errors.questionPhone?.message ? (
                    <p className="validation-text">필수입력 항목입니다</p>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            </>
          )}
        </div>

        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              className="color-white size-large"
              onClick={() => {
                if (productId) {
                  // 수정화면에서는 목록으로 바로이동
                  navigate(PagePath.product.list);
                } else {
                  // 등록화면에서는 목록에서 진입했기 때문에 뒤로가기
                  navigate(PagePath.product.list);
                }
              }}
            />
          </div>
          <div className="right-area">
            <BaseButton type="submit" title="저장" className="size-large" />
          </div>
        </div>
      </form>

      <>
        {/* 컨펌 모달 */}
        <BaseModal
          isOpen={confirmModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => {
            setConfirmModal({ isOpen: false });
            submitData && onClickConfirmModal(submitData);
          }}
        >
          <p>{confirmModal.message}</p>
        </BaseModal>

        {/*  빌딩 선택 팝업 */}
        <BaseModal
          isOpen={buildingModal.isOpen}
          className="dialog-modal"
          btnRightTitle="선택"
          btnLeftTitle="취소"
          onClose={() => {
            setCancelModal({ isOpen: true });
          }}
          onClick={() => {
            if (selectedBuilding?.id) {
              setFloorHoModal({ isOpen: true });
            }
          }}
        >
          <ProductBuildingListModal setSelectedBuilding={setSelectedBuilding} partner={partner!} />
        </BaseModal>

        {/*건물의  층 / 호실 선택 팝업*/}
        <BaseModal
          isOpen={floorHoModal.isOpen}
          className="dialog-modal"
          btnLeftTitle="취소"
          btnRightTitle="선택"
          onClose={() => {
            setSelectedRooms([]);
            setFloorHoModal({ isOpen: false });
            // setBuildingId(0);
          }}
          btnRightDisable={selectedRooms.length < 1}
          onClick={() => {
            setFloorHoModal({ isOpen: false });
            setBuildingModal({ isOpen: false });
            onDrawViewTable(selectedRooms);
          }}
        >
          <BuildingFloorHoModal
            setSelectedRooms={setSelectedRooms}
            selectedBuildingId={selectedBuilding?.id}
            floorHoTable={getValues("buildingList")} // was checked 확인
            productCategory={getValues("productCategory")}
          />
        </BaseModal>

        {/* 건물 선택 팝업 취소 */}
        <BaseModal
          isOpen={cancelModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClick={() => {
            setBuildingModal({ isOpen: false });
            setCancelModal({ isOpen: false });
          }}
          onClose={() => setCancelModal({ isOpen: false })}
        >
          <p>"건물/호실 선택을 취소하시겠습니까?"</p>
        </BaseModal>

        {/* 건물 대표여부 체크 경고 */}
        <BaseModal
          isOpen={alertModal.isOpen}
          btnRightTitle="확인"
          onClick={() => {
            setAlertModal({ isOpen: false });
          }}
        >
          <div>
            {alertModal.message?.split("\n").map((text) => (
              <p key={text} className="mb8">
                {text}
              </p>
            ))}
          </div>
        </BaseModal>

        {/* 파트너 재선택 경고 */}
        <BaseModal
          isOpen={formResetModal.isOpen}
          onClick={handleClickResetFormOkButton}
          onClose={handleClickResetFormCancelButton}
          btnLeftTitle="취소"
          btnRightTitle="확인"
        >
          <div>
            <p>파트너를 변경하시면 입력하신 정보가 초기화됩니다.</p>
          </div>
        </BaseModal>

        {partnerSelectModal.isOpen && (
          <PartnerSelectModal
            onCanceled={() =>
              setPartnerSelectModal({
                isOpen: false,
              })
            }
            onAdded={onAddedPartnerSelectModal}
            defaultValues={partner ? [partner] : []}
          />
        )}
      </>
    </div>
  );
};

export default BasicInfoForm;
