import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { postPartner } from "src/api/partner";
import { ContentsTitle, BaseButton, BaseModal } from "src/components";
import { Partner } from "src/types/partner";
import { PartnerForm } from "../components";
import { getPartnerListPage, getPartnerPageBreadCrumb } from "../utils";

function PartnerAdd() {
  const { executeAsync: postPartnerAsync } = useApiOperation(postPartner);
  const navigate = useNavigate();

  const [modal, setModal] = useState({
    success: {
      visible: false,
    },
    fail: {
      visible: false,
      message: "",
    },
  });

  const onSubmit = async (data: Partner) => {
    const result = await postPartnerAsync(data);

    if (result.status >= 200 && result.status < 300) {
      setModal({
        ...modal,
        success: {
          visible: true,
        },
      });
    } else {
      setModal({
        ...modal,
        fail: {
          visible: true,
          message: result.data.meta.errorMessage!,
        },
      });
    }
  };

  const handleClickSuccessModalOkButton = () => navigate(getPartnerListPage());
  const handleClickFailModalOkButton = () =>
    setModal({ ...modal, fail: { visible: false, message: "" } });
  const handleClickFailModalCancelButton = () =>
    setModal({ ...modal, fail: { visible: false, message: "" } });
  const handleClickGoListButton = () => navigate(getPartnerListPage());

  const formRef = useRef<HTMLFormElement>(null);

  const handleSaveButtonClick = () =>
    formRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );

  return (
    <div>
      <ContentsTitle
        title="파트너 정보 등록"
        breadCrumbs={getPartnerPageBreadCrumb(["list", "add"])}
        clickBreadCrumb={(crumb: { value: string; label: string }) => navigate(crumb.value)}
      />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <PartnerForm onSubmit={onSubmit} ref={formRef} />
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title={"목록"}
                onClick={handleClickGoListButton}
                className="color-white size-large"
              />
            </div>
            <div className="right-area">
              <BaseButton title={"저장"} onClick={handleSaveButtonClick} className="size-large" />
            </div>
          </div>
        </div>
        <BaseModal
          isOpen={modal.success.visible}
          onClick={handleClickSuccessModalOkButton}
          btnRightTitle="확인"
        >
          <p>저장 되었습니다.</p>
        </BaseModal>
        <BaseModal
          isOpen={modal.fail.visible}
          onClose={handleClickFailModalCancelButton}
          onClick={handleClickFailModalOkButton}
          btnRightTitle="확인"
          btnLeftTitle="취소"
        >
          <div>
            <p>저장을 할 수 없습니다.</p>
            <p></p>
          </div>
        </BaseModal>
      </div>
    </div>
  );
}
export default PartnerAdd;
