
const TableLongTextCell = ({title, value}:any) =>{
  return (
    <>
      <tr>
        <td width="180" >
          <div className="flex-center pl16">
            <span className="text-primary3">{title}</span>
          </div>
        </td>
        <td>
          <div className="w-100 flex-center-start text-left">
            {value}
          </div>
        </td>
      </tr>
    </>
  )
}
export default TableLongTextCell;