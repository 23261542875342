import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { addContractApplication, getContractDetailPopup } from "src/api/contract/contract-api";
import { errorDataHandler, errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { getMemberDetail } from "src/api/member/member-api";
import { getPartner } from "src/api/partner";
import { getProductDetailAsync } from "src/api/product/product-api";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderAddModel } from "src/api/provider/provider-types";
import { building_product_errors, contract_bill_user_errors } from "src/api/public-types";
import { BaseButton, BaseModal, ContentsIdSection } from "src/components";
import pagePath from "src/pagePath.json";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { YmdFormat } from "src/utils";
import ChargeMntType from "./component/ChargeMntType";
import FOType from "./component/FOType";
import ServiceType from "./component/ServiceType";
import useTitleOperation from "src/pages/hooks/title-operation";

type ContractSaveModel = {
  contractManage: {
    partnerId: number;
    mbOrganizationId: number;
    spaceProductId: number;
    spaceProductType: string;
    spaceProductName: string;
  };
  contract: {
    contractApplyType?: string;
    useStartTime: string;
    useEndTime: string;
    useTerm: number;
    applicantName: string;
    applicantCorporation: string;
    isAgreeTerms?: boolean;
  };
  contractMember: {
    inviteMobileNumber?: string;
    memberNo?: string;
  };
};

interface ContractFormModel extends ContractSaveModel {
  startTime?: string;
  endTime?: string;
}

const ContractApplication = ({
  basicInfo,
  application,
  setApplication,
  spaceProductType,
  rooms,
}: any) => {
  let id = 0;
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { executeAsync: getMemberInfo } = useApiOperation(getMemberDetail, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getSpaceProduct } = useApiOperation(getProductDetailAsync, {
    doNotErrorHandleModal: true,
  });

  const { executeAsync: addApplication } = useApiOperation(addContractApplication, {
    doNotErrorHandleModal: true,
  });
  const [memberInfo, setMemberInfo] = useState<any>({});
  const [spaceProduct, setSpaceProduct] = useState<any>({});
  const [productDetail, setProductDetail] = useState<any>();
  const [mflag, setMflag] = useState(false);
  const [pflag, setPflag] = useState(false);
  const [oneHour, setOneHour] = useState(false);
  const [memberError, setMemberError] = useState("");
  const [productError, setProductError] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [saved, setSaved] = useState(false);
  const [savedId, setSavedId] = useState(0);
  const [providerDetail, setProviderDetail] = useState<ProviderAddModel>();
  const [saveData, setSaveData] = useState<ContractFormModel>({
    contractManage: {
      partnerId: 0,
      mbOrganizationId: 0,
      spaceProductId: 0,
      spaceProductType: "",
      spaceProductName: "",
    },
    contract: {
      contractApplyType: "CONTRACT",
      useStartTime: "",
      useEndTime: "",
      useTerm: 0,
      applicantName: "",
      applicantCorporation: "",
      isAgreeTerms: true,
    },
    contractMember: {
      inviteMobileNumber: "",
      memberNo: "",
    },
  });

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    // formState: { errors },
  } = useForm<ContractFormModel>({
    defaultValues: { ...saveData },
  });

  const [selectedPeriodOption, setSelectedPeriodOptions] = useState([]);
  const [partner, setPartner] = useState<any>({});
  const [partnerError, setPartnerError] = useState("");
  const [errors, setErrors] = useState<any>();
  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);
  const { executeAsync: getSnapshot } = useApiOperation(getContractDetailPopup, {
    doNotErrorHandleModal: true,
  });
  const providerDetailApi = useCallback(
    async (id: string) => {
      setLoadingBar(true);

      const response: any = await getProviderDetail({ providerId: id });

      if (response.status >= 200 || response.status <= 299) {
        const result = response.data.data;
        setProviderDetail(result);
      }
      setLoadingBar(false);
    },
    [getProviderDetail, setLoadingBar],
  );
  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );

  if (queryParams.id) {
    id = Number(queryParams.id);
  }

  // 공산상품 기본정보 디테일 api
  const getProductBasicDetail = useCallback(
    async (productId: number, step?: string) => {
      if (productId) {
        let response: any = {};
        if (step !== "APPLY_CANCEL") {
          // console.info(`step !=='APPLY_CANCEL'`)
          if (step !== "APPLY_RECEIVED" && step !== "APPLY_CONFIRM") {
            // console.info(`step !=='APPLY_RECEIVED' && step !=='APPLY_CONFIRM'`)
            response = await getSnapshot({ id: Number(id) });
          } else {
            // console.info(`step ==='APPLY_RECEIVED' && step ==='APPLY_CONFIRM'`)
            response = await getSpaceProduct({ productId });
          }
        } else {
          // console.info(`step ==='APPLY_CANCEL'`)
          const res1: any = await getSnapshot({ id: Number(id) });
          const res2: any = await getSpaceProduct({ productId });
          response = res2;

          if (res1.data.data.content !== "") {
            // console.info(`res1.data.data.content !==''`)
            response = res1;
          }
        }
        console.log("response", response);
        if (response.status >= 200 && response.status <= 299) {
          const result = response?.data?.data?.content;
          console.log("result", result);
          const options: any = [];
          console.log(result);
          // console.log(result.productType);
          if (result.productType === "FULL_COURT") {
            const min = Number(result.minLeasePeriod) * 12;
            const max = Number(result.maxLeasePeriod) * 12;
            for (let i = min; i <= max; i++) {
              const option = { label: i + "개월", value: i };
              options.push(option);
            }
          }
          if (result.productType === "OPEN_COURT") {
            const min = Number(result.minLeasePeriod);
            const max = Number(result.maxLeasePeriod);
            for (let i = min; i <= max; i++) {
              const option = { label: i + "개월", value: i };
              options.push(option);
            }
          }

          setSelectedPeriodOptions(options);
          setSpaceProduct(response.data.data.content);
          setProductDetail(result);
        } else {
          let { message, code, errorData }: any = errorDataHandler(
            response,
            building_product_errors,
          );

          openErrorModal(message, code, errorData);
        }
      }
    },
    [getSnapshot, getSpaceProduct, openErrorModal, id],
  );

  const fetchPartner = useCallback(
    async (id: string) => {
      const result = await getPartnerAsync({ id });

      if (result.status > 199 && result.status < 300) {
        setPartner(result.data.data.partner);
      } else {
        let { message, code, errorData }: any = errorDataHandler(result, building_product_errors);

        openErrorModal(message, code, errorData);
      }
    },
    [getPartnerAsync, openErrorModal],
  );

  const callContractDetail = useCallback(async () => {
    setLoadingBar(true);
    if (application && application.memberNo !== undefined) {
      const response: any = await getMemberInfo({ id: application.memberNo });
      if (response.status >= 200 && response.status <= 299) {
        setMemberInfo(response.data.data.member);
      } else {
        let message = errorMessageHandler(
          response.status,
          response.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
        openErrorModal(message, code);
      }
    }
    if (application && application.spaceProductId !== undefined) {
      await getProductBasicDetail(application.spaceProductId, String(id));
    }
    if (application && application.partnerId !== undefined) {
      const response: any = await fetchPartner(application.partnerId);
      console.log("partner", response);
      if (response.status > 199 && response.status < 300) {
        if (response.data.data) {
          console.log("response.data.data", response.data.data);
          setPartner(response.data.data);
        }
      } else {
        let message = errorMessageHandler(
          response.status,
          response.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
        openErrorModal(message, code);
      }
    }
    if (application) {
      // console.log(application);
      const start = application.useStartTime;
      const end = application.useEndTime;

      setValue("contract.useStartTime", start);
      setValue("contract.useEndTime", end);
      setValue("contract.useTerm", application.useTerm);
      if (spaceProductType === "TIME_COURT") {
        setValue("startTime", start);
        setValue("endTime", end);
      }
    }
    setLoadingBar(false);
  }, [
    application,
    fetchPartner,
    getMemberInfo,
    getProductBasicDetail,
    id,
    openErrorModal,
    setLoadingBar,
    setValue,
    spaceProductType,
  ]);

  useEffect(() => {
    callContractDetail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  const makeContract = useCallback(
    async (data: ContractSaveModel) => {
      setLoadingBar(true);
      const res: any = await addApplication({ ...data });
      console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        setSaved(true);
        setSavedId(res.data.data.contractId);
      } else {
        let message = errorMessageHandler(
          res.status,
          res.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.status;
        openErrorModal(message, code);
      }
      setLoadingBar(false);
    },
    [addApplication, openErrorModal, setLoadingBar],
  );

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: ContractSaveModel, e?: any) => {
    e.preventDefault();

    if (
      partner === undefined ||
      partner?.id === undefined ||
      data.contractManage.partnerId === undefined ||
      data.contractManage.partnerId === 0
    ) {
      setPartnerError("파트너 정보는 필수입니다.");
      return;
    } else {
      setPartnerError("");
    }
    if (spaceProduct === undefined || spaceProduct.id === undefined) {
      setProductError("상품정보는 필수입니다.");
      return;
    }

    if (memberInfo === undefined || memberInfo.id === undefined) {
      setMemberError("신청자 정보는 필수입니다.");
      return;
    }

    let sd = _.cloneDeep(data);
    let useEndTime = data.contract.useEndTime;
    if (productDetail?.productType !== "TIME_COURT") {
      // console.log("data.contract.useEndTime", data.contract.useEndTime);
      useEndTime =
        moment(data.contract.useEndTime, "YYYY-MM-DD").format(YmdFormat.YYYY_MM_DD) +
        " 23:59:59.999";
      sd.contract.useEndTime = moment(useEndTime, "YYYY-MM-DD HH:mm:ss.SSS").format(
        YmdFormat.WITH_TIME_ZONE,
      );
    } else {
      sd.contract.useEndTime = moment(useEndTime, "YYYY-MM-DD HH:mm").format(
        YmdFormat.WITH_TIME_ZONE,
      );

      const st = moment(data.contract.useStartTime);
      const ed = moment(sd.contract.useEndTime);

      let duration = moment.duration(ed.diff(st)).asMinutes();

      if (Math.floor(duration / 60) < 1) {
        setOneHour(true);
        return;
      }
    }
    sd.contract.useStartTime = moment(data.contract.useStartTime).format(YmdFormat.WITH_TIME_ZONE);

    sd.contractManage.spaceProductId = Number(sd.contractManage.spaceProductId);

    if (spaceProductType === "TIME_COURT") {
      sd.contract.useTerm = 0;
    }

    setSaveData({ ...sd });
    setConfirm(true);
  };

  const save = useCallback(
    (data: ContractSaveModel) => {
      makeContract(data);
    },
    [makeContract],
  );

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errs: any, e?: any) => {
    e.preventDefault();
    console.log("onError errors", errs);
    if (partner.id === undefined) {
      errs.contractManage = {
        ...errs.contractManage,
        ...{
          partnerId: {
            type: "required",
            message: "파트너 정보는 필수입니다.",
          },
        },
      };

      // setProductError("상품정보는 필수입니다.");
    }
    if (spaceProduct.id === undefined) {
      errs.contractManage = {
        ...errs.contractManage,
        ...{
          spaceProductId: {
            type: "required",
            message: "상품 정보는 필수입니다.",
          },
        },
      };

      // setProductError("상품정보는 필수입니다.");
    }

    if (memberInfo.id === undefined) {
      errs.contractMember = {
        memberNo: {
          type: "required",
          message: "신청자 정보는 필수입니다.",
        },
      };
    }

    if (getValues("contract.useTerm") === 0) {
      errs.contract = {
        ...errs.contract,
        ...{
          useTerm: {
            type: "required",
            ref: { name: "contract.useTerm" },
            message: "필수입력항목입니다",
          },
        },
      };
    }

    setErrors(errs);
    return false;
  };

  const moveDetail = useCallback(
    (id: number) => {
      setSaved(false);
      delete queryParams.id;
      const path =
        pagePath.contract.detail.replace(":id", String(id)) +
        "?" +
        decodeURIComponent(qs.stringify(queryParams, { allowDots: true, encode: true }));
      navigate(path);
    },
    [navigate, queryParams],
  );

  return (
    <div className="contents-container__wrap detail-basic-info">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__wrap-contents">
          <ContentsIdSection
            title="신청"
            id={basicInfo?.contractId}
            noTitle="신청번호"
            no={basicInfo?.contractApplyNumber}
            status={basicInfo?.step}
          />
          {productDetail === undefined &&
            productDetail?.productType !== "TIME_COURT" &&
            productDetail?.productType !== "DIGITAL_PRODUCT" && (
              <FOType
                basicInfo={basicInfo}
                application={application}
                setApplication={setApplication}
                spaceProductType={spaceProductType}
                rooms={rooms}
                memberInfo={memberInfo}
                setMemberInfo={setMemberInfo}
                spaceProduct={spaceProduct}
                setSpaceProduct={setSpaceProduct}
                productDetail={productDetail}
                setProductDetail={setProductDetail}
                saveData={saveData}
                setSaveData={setSaveData}
                providerDetail={providerDetail}
                setProviderDetail={setProviderDetail}
                register={register}
                control={control}
                getValues={getValues}
                setValue={setValue}
                reset={reset}
                selectedPeriodOption={selectedPeriodOption}
                setSelectedPeriodOptions={setSelectedPeriodOptions}
                partner={partner}
                setPartner={setPartner}
                partnerError={partnerError}
                setPartnerError={setPartnerError}
                errors={errors}
              />
            )}
          {productDetail !== undefined && productDetail?.productType === "TIME_COURT" && (
            <ServiceType
              basicInfo={basicInfo}
              application={application}
              setApplication={setApplication}
              spaceProductType={spaceProductType}
              rooms={rooms}
              memberInfo={memberInfo}
              setMemberInfo={setMemberInfo}
              spaceProduct={spaceProduct}
              setSpaceProduct={setSpaceProduct}
              productDetail={productDetail}
              setProductDetail={setProductDetail}
              saveData={saveData}
              setSaveData={setSaveData}
              providerDetail={providerDetail}
              setProviderDetail={setProviderDetail}
              register={register}
              control={control}
              getValues={getValues}
              setValue={setValue}
              selectedPeriodOption={selectedPeriodOption}
              setSelectedPeriodOptions={setSelectedPeriodOptions}
              partner={partner}
              setPartner={setPartner}
            />
          )}
          {productDetail !== undefined && productDetail?.productType === "MAINTENANCE_FEE" && (
            <ChargeMntType
              basicInfo={basicInfo}
              application={application}
              setApplication={setApplication}
              spaceProductType={spaceProductType}
              rooms={rooms}
              memberInfo={memberInfo}
              setMemberInfo={setMemberInfo}
              spaceProduct={spaceProduct}
              setSpaceProduct={setSpaceProduct}
              productDetail={productDetail}
              setProductDetail={setProductDetail}
              saveData={saveData}
              setSaveData={setSaveData}
              providerDetail={providerDetail}
              setProviderDetail={setProviderDetail}
              register={register}
              control={control}
              getValues={getValues}
              setValue={setValue}
              selectedPeriodOption={selectedPeriodOption}
              setSelectedPeriodOptions={setSelectedPeriodOptions}
              partner={partner}
              setPartner={setPartner}
            />
          )}
        </div>
        <div className="contents-container__btn-wrap">
          <div className="left-area d-flex">
            <BaseButton
              title="목록으로"
              onClick={() => {
                delete queryParams.id;
                navigate(
                  pagePath.contract.list +
                    "?" +
                    decodeURIComponent(
                      qs.stringify(queryParams, { allowDots: true, encode: true }),
                    ),
                );
              }}
              className="color-white size-large"
            />
            {id !== 0 && (
              <BaseButton
                title="상세"
                className="color-white size-large ml10"
                onClick={() => moveDetail(id)}
              />
            )}
          </div>
          <div className="right-area">
            {id === 0 && (
              <BaseButton
                title="저장"
                disabled={
                  (spaceProductType === "TIME_COURT" && id !== 0) ||
                  basicInfo?.contractStep === "USE_APPROVAL" ||
                  basicInfo?.contractStep === "USE_PROGRESS" ||
                  basicInfo?.contractStep === "USE_COMPLETE" ||
                  basicInfo?.contractStep === "TERMINATE_RECEIVED" ||
                  basicInfo?.contractStep === "TERMINATE_COMPLETE" ||
                  basicInfo?.contractStep === "APPLY_CANCEL"
                }
                type="submit"
                className="size-large"
              />
            )}
          </div>
        </div>
      </form>
      <BaseModal
        isOpen={confirm}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClick={(e?: any) => {
          save(saveData);
        }}
        onClose={() => setConfirm(false)}
      >
        <p>저장 하시겠습니까?</p>
      </BaseModal>
      <BaseModal isOpen={saved} btnRightTitle="확인" onClick={() => moveDetail(savedId)}>
        <p>저장 되었습니다.</p>
      </BaseModal>
      <BaseModal isOpen={oneHour} btnRightTitle="확인" onClick={() => setOneHour(false)}>
        <p>최소 이용시간은 1시간입니다.</p>
      </BaseModal>
    </div>
  );
};

export default ContractApplication;
