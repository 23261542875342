import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ComponentType } from "react";

interface ProtectedRouteProps {
  component: ComponentType;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
}) => {
  const Element = withAuthenticationRequired(component, {
    onRedirecting: () => <div>로딩 중 ...</div>,
  });

  return <Element />;
};
