import { PartnerFeatures } from "src/types/partner";
import { PagePath } from "../product/details";
import { PageType } from "./types";

export const getPartnerListPage = () => PagePath.partner.list;
export const getPartnerDetailPage = (id: string, tab?: string) => {
  if (tab) {
    return `${PagePath.partner.detail.replace(":id", id)}?tab=${tab}`;
  }
  return `${PagePath.partner.detail.replace(":id", id)}`;
};
export const getPartnerEditPage = (id: string, tab?: string) => {
  if (tab) {
    return `${PagePath.partner.edit.replace(":id", id)}?tab=${tab}`;
  }
  return `${PagePath.partner.edit.replace(":id", id)}`;
};
export const getPartnerAddPage = () => PagePath.partner.add;

export const getPartnerPageBreadCrumb = (pageTypes: PageType[], id?: string, tab?: string) => {
  const breadCrumb = pageTypes.map((pageType) => {
    let tabs = tab ? tab : "basicInfo";
    switch (pageType) {
      case "list":
        return {
          label: "파트너 목록",
          value: getPartnerListPage(),
        };
      case "detail":
        return {
          label: "파트너 상세",
          value: getPartnerDetailPage(id!, tabs),
        };
      case "edit":
        return {
          label: "파트너 수정",
          value: getPartnerEditPage(id!, tabs),
        };
      case "add":
        return {
          label: "파트너 등록",
          value: getPartnerAddPage(),
        };
      default:
        return {
          label: "",
          value: "",
        };
    }
  });

  return breadCrumb;
};

export const sortFeaturesByName = (features: PartnerFeatures[]) => {
  const sortedFeatures = features.slice().sort((a, b) => {
    return String(a.name).localeCompare(String(b.name));
  });
  return sortedFeatures;
};
