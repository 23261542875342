import qs from "qs";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseModal, ContentsTitle } from "src/components";
import PagePath from "src/pagePath.json";
import { Modal } from "../building-types";
import BasicInfoForm from "./basicInfoForm/BasicInfoForm";
import FacilityForm from "./facilityForm/FacilityForm";
import ImageForm from "./imageForm/ImageForm";
import SeatingChartForm from "./seatingChartForm/SeatingChartForm";
import PropertyForm from "./propertyForm/PropertyForm";
import BuildingAdminMemo from "../details/buildingAdminMemo/BuildingAdminMemo";
import ManagementOfficeForm from "./mgmtOffice/ManagementOfficeForm";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "property", label: "프로퍼티" },
  { value: "facility", label: "공용공간" },
  { value: "seatingChart", label: "좌석 배치도" },
  { value: "image", label: "이미지" }, // TODO: MVP 이후
  { value: "mgmtOffice", label: "관리처" },
  { value: "memo", label: "관리자 메모" },
];

/* 
  건물 > 등록 or 수정
 */
const BuildingForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      // 기본정보 가 작성되지 않은 상태에서, 다른 탭으로 이동하려고 할 경우.
      if (tab.value !== "basicInfo" && !queryParams.id) {
        setModal({
          isOpen: true,
          message: "기본정보가 저장되지 않았습니다.",
        });
        return;
      }
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "building") {
        // 건물 목록으로 이동
        navigate(PagePath.building.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "building", label: "건물" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  const [modal, setModal] = useState<Modal>({
    isOpen: false,
  });

  return (
    <div className="page-product-access">
      <ContentsTitle
        title="건물"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />
      {/* 탭 */}
      <div className="contents-container__scroll">
        {/* 기본정보 */}
        {activeTab?.value === "basicInfo" && <BasicInfoForm />}
        {/* 프로퍼티 */}
        {activeTab?.value === "property" && <PropertyForm />}
        {/* 공용공간 */}
        {activeTab?.value === "facility" && <FacilityForm />}
        {/* 이미지 */}
        {activeTab?.value === "image" && <ImageForm />}
        {/* 좌석 배치도 */}
        {activeTab?.value === "seatingChart" && <SeatingChartForm />}
        {/* 관리처 */}
        {activeTab?.value === "mgmtOffice" && <ManagementOfficeForm />}
        {/* 관리자 메모 */}
        {activeTab?.value === "memo" && <BuildingAdminMemo />}
      </div>
      <BaseModal
        isOpen={modal.isOpen}
        btnRightTitle={"확인"}
        onClick={() => setModal({ ...modal, isOpen: false })}
      >
        <p>{modal.message}</p>
      </BaseModal>
    </div>
  );
};
export default BuildingForm;
