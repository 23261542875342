import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "..";
import { ApiResponse } from "../public-types";
import {
  CsLabels,
  CsServiceItemModel,
  CsServiceItemsParams,
  VocModelList,
  VocOptionsType,
  VocQueryParams,
} from "./voc-types";

// /**REQ09. VoC유형 목록 */
// export const getVocOptionList = async (
//   axios: AxiosInstance,
//   params?: {
//     csCategoryType: "VoC" | "문의" | "";
//   },
// ): Promise<AxiosResponse<ApiResponse<{ content: VocOptionsType[] }>>> => {
//   return await axios.get(ApiPath.csItem.options, { params });
// };

/**CEQ11. VoC항목 목록 */
export const getVocListAsync = async (
  axios: AxiosInstance,
  params?: VocQueryParams,
): Promise<AxiosResponse<ApiResponse<VocModelList>>> => {
  return await axios.get(ApiPath.csItem.list, {
    params,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { allowDots: true, encode: true });
    },
  });
};

/** REQ13. 서비스별VoC항목 목록 */
export const getCsServiceItemsAsync = async (
  axios: AxiosInstance,
  params?: CsServiceItemsParams,
): Promise<AxiosResponse<ApiResponse<{ content: CsServiceItemModel[] }>>> => {
  return axios.get(ApiPath.csItem.service.list, {
    params,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { allowDots: true, encode: true });
    },
  });
};

/**CEQ09. VoC유형 목록*/
export const getVocOptionList = async (
  axios: AxiosInstance,
  params?: {
    csCategoryType: "VoC" | "문의" | "";
  },
): Promise<AxiosResponse<ApiResponse<{ content: VocOptionsType[] }>>> => {
  return await axios.get(ApiPath.csItem.options, { params });
};

/*
  (CEQ17) VoC 항목 레이블 목록
*/
export async function getCsItemLabelsAsync(
  axios: AxiosInstance,
  params?: {
    csItemId: number;
  },
): Promise<AxiosResponse<ApiResponse<{ labels: CsLabels[] }>>> {
  if (!params?.csItemId) {
    throw Error("params.csItemId is not found");
  }

  const path = ApiPath.csItem.labels.replace("{csItemId}", params?.csItemId?.toString());
  return await axios.get<ApiResponse<{ labels: CsLabels[] }>>(path, {
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/*
   (CEQ18) VoC 항목 레이블 등록
*/
export async function putCsItemLabelsAsync(
  axios: AxiosInstance,
  params?: {
    csItemId: number;
    labels: CsLabels[];
  },
): Promise<AxiosResponse<ApiResponse<{ content: { labels: CsLabels[] } }>>> {
  if (!params?.csItemId) {
    throw Error("params.csItemId is not found");
  }

  const path = "/api/central/et/csItems/:csItemId/csItemLabels".replace(
    ":csItemId",
    params?.csItemId?.toString(),
  );
  return await axios.post<ApiResponse<{ content: { labels: CsLabels[] } }>>(path, {
    labels: params.labels,
    paramsSerializer: (params: CsLabels[]) =>
      qs.stringify(params, { allowDots: true, encode: true }),
  });
}
