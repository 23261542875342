import qs from "qs";
import React, { useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import AdminMemo from "src/components/adminmemo/AdminMemo";

const IotAdminMemo = () => {
  const params = useParams();
  const serviceId = params.id; // 상세페이지의 ID 를 전달
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const registeredDeviceId: number | undefined = queryParams?.id
    ? Number(queryParams.id)
    : Number(serviceId);

  return (
    <div>
      <AdminMemoIntegration
        serviceId={Number(registeredDeviceId)}
        serviceType={ServiceTypes.SERVICE_IOT_DEVICE}
        viewOnly={false}
      />
    </div>
  );
};

export default IotAdminMemo;
