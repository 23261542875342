import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ContractUseRequestList } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { getMemberDetail } from "src/api/member/member-api";
import { MemberModel } from "src/api/member/member-types";
import { Order } from "src/api/public-types";
import { getContractUseRequests } from "src/api/user/user-api";
import { BaseButton, BaseTooltip, ContentsIdSection } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { MemberDetailsTable } from "src/pages/member/components/MemberDetailsTable";
import { PagePath } from "src/pages/product/details";

const MemberColumns = [
  {
    Header: "no",
    accessor: "contractMemberId",
    width: 70,
    Cell: ({ row }: any) => <span>{row.index + 1}</span>,
  },
  {
    Header: "신청번호",
    accessor: "contractManageId",
    width: 100,
    Cell: ({ row }: any) => {
      const contractNumber = row.original.contractId;
      const applyNumber = row.original.contractApplyNumber;
      const contractDetail = `${PagePath.contract.detail.replace(":id", `${contractNumber}`)}`;

      return (
        <div>
          <Link to={contractDetail} target="_blank" className="text-hilight">
            {applyNumber || ""}
          </Link>
        </div>
      );
    },
  },
  {
    Header: "이용 권한",
    accessor: "isContractor",
    width: 100,
    Cell: ({ row }: any) => {
      const isAdmin = row.original.isAdmin;
      const isContractor = row.original.isContractor;

      if (!isAdmin && !isContractor) {
        return <span>이용자</span>;
      }

      if (!isAdmin && isContractor) {
        return <span>계약자</span>;
      }
      if (isAdmin && !isContractor) {
        return <span>관리자</span>;
      }
      if (isAdmin && isContractor) {
        return <span>계약자, 관리자</span>;
      }
    },
  },
  {
    Header: "상품명",
    accessor: "spaceProductName",
    width: 200,
  },
  {
    Header: "이용 시작일",
    accessor: "contractStartTime",
    width: 100,
    Cell: ({ row }: any) => {
      return <BaseTooltip contents={row?.original?.contractStartTime} type="date" />;
    },
  },
  {
    Header: "신청/계약 상태",
    accessor: "contractStep",
    width: 110,
    Cell: ({ row }: any) => {
      const contractStep = row?.original?.contractStep;
      if (contractStep === "APPLY_RECEIVED") {
        return <span>신청접수</span>;
      }
      if (contractStep === "APPLY_CANCEL") {
        return <span>신청취소</span>;
      }
      if (contractStep === "APPLY_CONFIRM") {
        return <span>신청확인</span>;
      }
      if (contractStep === "CONTRACT_PLAN") {
        return <span>계약예정</span>;
      }
      if (contractStep === "CONTRACT_ACCEPT") {
        return <span>계약체결</span>;
      }
      if (contractStep === "USE_APPROVAL") {
        return <span>이용승인</span>;
      }
      if (contractStep === "USE_PROGRESS") {
        return <span>이용중</span>;
      }
      if (contractStep === "USE_COMPLETE") {
        return <span>이용완료</span>;
      }
      if (contractStep === "TERMINATE_RECEIVED") {
        return <span>해지접수</span>;
      }
      if (contractStep === "TERMINATE_COMPLETE") {
        return <span>해지완료</span>;
      }
    },
  },

  {
    Header: "이용자 상태",
    accessor: "memberStatus",
    width: 90,
    Cell: ({ row }: any) => {
      const requestStatus = row?.original?.memberStatus;
      if (requestStatus === "MEMBER_USE") {
        return <span>사용</span>;
      } else if (requestStatus === "MEMBER_INVITE") {
        return <span>초대</span>;
      } else if (requestStatus === "MEMBER_DELETE") {
        return <span>삭제</span>;
      } else {
        return <span>사용종료</span>;
      }
    },
  },
];

type QueryParams = {
  page?: number;
  size?: number;
  // searchType?: string;
  // searchValue?: string;
  memberNo?: string;
};

const UseProduct = ({}) => {
  const param = useParams();
  const memberId = param.id;
  const navigate = useNavigate();

  const [useProductData, setUseProductData] = useState<ContractUseRequestList[]>([]);
  const [params, setParams] = useState<any>();
  const { setLoadingBar } = useLoadingBarContext();

  const [basicInfo, setBasicInfo] = useState<MemberModel>();

  const location = useLocation();

  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.memberNo) {
      _queryParams.memberNo = String(basicInfo?.memberNo);
    }

    return _queryParams;
  }, [basicInfo?.memberNo, location.search]);

  const { executeAsync: getUseProducts } = useApiOperation(getContractUseRequests);

  //계정연결 조회 API
  const { executeAsync: getMemberDetailAsync } = useApiOperation(getMemberDetail);

  const getMemberDetailApi = useCallback(async (id: string) => {
    if (memberId) {
      setLoadingBar(true);
      const response = await getMemberDetailAsync({ id });
      if (response.status >= 200 && response.status <= 299) {
        const memberData = response?.data?.data?.member;

        setBasicInfo(memberData || null);
      }
      setLoadingBar(false);
    }
  }, []);
  useEffect(() => {
    getMemberDetailApi(String(memberId));
  }, [getMemberDetailApi, memberId]);

  const callUseProducts = useCallback(async () => {
    setLoadingBar(true);

    const response = await getUseProducts(queryParams);

    if (response.status >= 200 && response.status <= 299) {
      const result = response?.data?.data?.content;

      setUseProductData(result);
      setLoadingBar(false);
    } else {
      setLoadingBar(false);
    }
  }, [getUseProducts, queryParams, setLoadingBar]);

  useEffect(() => {
    if (basicInfo?.memberNo !== undefined) {
      callUseProducts();
    }
  }, [basicInfo?.memberNo, callUseProducts]);

  return (
    <div className="contents-container__wrap detail-basic-info">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection
          title="상품 이용 내역"
          id={basicInfo?.id}
          noTitle="회원번호"
          no={basicInfo?.memberNo}
        />

        <div className="mt20">
          <MemberDetailsTable
            data={useProductData}
            columns={MemberColumns}
            orders={params?.orders}
            disabledSortHeaders={[
              "contractMemberId",
              "contractManageId",
              "isContractor",
              "spaceProductName",
              "contractStartTime",
              "contractEndTime",
              "memberStatus",
              "contractStep",
            ]}
            setOrders={(orders?: Array<Order>) => {
              setParams({ orders });
            }}
          />
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            onClick={() => navigate(PagePath.member.list)}
            className="color-white size-large"
          />
        </div>
        <div className="right-area"></div>
      </div>
    </div>
  );
};

export default UseProduct;
