import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import {
  ProviderAddModel,
  ProviderBasicInfoAddModel,
  ProviderMediaFile,
} from "src/api/provider/provider-types";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal, BaseRadio } from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import { Partner } from "src/types/partner";
import { companyRegistrationHypen } from "src/utils";
import ContractUpload from "./ContractUpload";

const checkBizRegNoLink = process.env.REACT_APP_BIZ_REG_CHECK_URL || "";

type Props = {
  control?: Control<ProviderBasicInfoAddModel>;
  register?: UseFormRegister<ProviderBasicInfoAddModel>;
  errors?: any;
  providerDetail?: ProviderAddModel;
  // contractMediaList?: Array<ProviderMediaFile>;
  providerId?: string;
  setValue: UseFormSetValue<ProviderBasicInfoAddModel>;
  reset: UseFormReset<ProviderBasicInfoAddModel>;
  setPartner: Dispatch<SetStateAction<Partner | undefined>>;
  partner: Partner | undefined;
};

const ProviderBasicForm = ({
  control,
  // contractMediaList,
  providerDetail,
  providerId,
  errors,
  setValue,
  reset,
  partner,
  setPartner,
}: Props) => {
  const [document, setDocument] = useState<ProviderMediaFile[]>([]);

  // 선택된 파트너 정보
  // const [partner, setPartner] = useState<Partner>();

  // 파트너 목록 노출 모달
  const [partnerModal, setPartnerModal] = useState<Modal>({
    isOpen: false,
  });

  // 파트너 변경 경고 모달
  const [partnerChangeModal, setPartnerChangeModal] = useState<Modal>({
    isOpen: false,
  });

  //
  //
  useEffect(() => {
    if (providerDetail?.contractMediaList && providerDetail.contractMediaList?.length > 0) {
      setDocument(providerDetail.contractMediaList);
    }
  }, [providerDetail]);
  return (
    <article className="pb30 contents-container__1200">
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">파트너</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <span className={partner && `mr10`}>
              {partner && `${partner?.name} (${partner.code})`}
            </span>
            {!Boolean(Number(providerId)) && (
              <BaseButton
                title="파트너 선택"
                className="color-white"
                onClick={() => {
                  if (partner) {
                    setPartnerModal({
                      isOpen: true,
                      message: "파트너를 변경하시면 입력하신 정보가 초기화됩니다.",
                    });
                  } else {
                    setPartnerModal({ isOpen: true });
                  }
                }}
              />
            )}
          </div>
          <p className="validation-text">{errors.provider?.partnerId?.message}</p>
        </div>
      </section>

      {/* 구분 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">구분</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <span>
              {Boolean(Number(providerId)) &&
                providerDetail?.provider?.supplyType === "RENTAL" &&
                "프로바이더"}
              {Boolean(Number(providerId)) &&
                providerDetail?.provider?.supplyType === "MNT" &&
                "관리처"}
            </span>
            {!Boolean(Number(providerId)) && (
              <Controller
                control={control}
                name={`provider.supplyType`}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  return (
                    <div className="flex-center ">
                      {}
                      <BaseRadio
                        id={"provider"}
                        name={name}
                        label={"프로바이더"}
                        checked={value === "RENTAL"}
                        className="mr20"
                        onChange={(checked: boolean) => {
                          onChange(checked);
                          if (checked) {
                            setValue("provider.supplyType", "RENTAL", { shouldDirty: true });
                          }
                        }}
                        readOnly={value !== "" && true}
                      />
                      <BaseRadio
                        id={"manamgement"}
                        name={name}
                        label={"관리처"}
                        checked={value === "MNT"}
                        onChange={(checked: boolean) => {
                          onChange(checked);
                          if (checked) {
                            setValue("provider.supplyType", "MNT", { shouldDirty: true });
                          }
                        }}
                        readOnly={value !== ""}
                      />
                    </div>
                  );
                }}
              ></Controller>
            )}
          </div>
          <p className="validation-text">{errors.provider?.supplyType?.message}</p>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">법인명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.providerName`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="사업자등록증의 법인명(법인사업자) 또는 성명(개인임대사업자)을 적어주세요."
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">사업자 등록번호</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.businessRegistrationNumber`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={(text: string) => onChange(text)}
                    value={companyRegistrationHypen(String(value))}
                    maxLength={12}
                    name={name}
                    errorText={error && error.message}
                  />
                );
              }}
            ></Controller>
          </div>
          <p>
            <span className="text-red">
              사업자 등록번호를 조회하여 사업자의 상태를 확인 후 등록해주세요.
            </span>
            <a
              href={checkBizRegNoLink}
              // onClick={goOutLink}
              target="_blank"
              className="ml10 text-hilight"
              rel="noreferrer"
            >
              휴/폐업 조회
            </a>
          </p>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">대표자 성명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.representative`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="개인임대사업자의 경우 사업자등록증의 성명을 적어주세요."
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">주소</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.address`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="사업자등록증의 사업장 소재지를 적어주세요."
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">업태</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.bizType`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="사업자등록증의 업태를 적어주세요."
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">종목</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.bizClass`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="사업자등록증의 종목을 적어주세요."
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">사업자등록증 사본</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-center-start flex-row">
            <div className="flex-center mr16">
              <Controller
                control={control}
                name="contractMediaList"
                render={({ field: { onChange, name, value }, fieldState: { error } }) => (
                  <ContractUpload
                    name={name}
                    setPrivateFiles={(file: ProviderMediaFile[]) => {
                      setDocument(file);
                      onChange(file);
                    }}
                    privateFiles={document}
                    errorText={error && error.message}
                    limit={1}
                    maxSize={10}
                    accept={".png, .jpg, .pdf"}
                  />
                )}
              ></Controller>
            </div>
          </div>
        </div>
      </section>
      <BaseModal
        isOpen={partnerChangeModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClick={() => {
          reset();
          setPartner(partnerChangeModal.payload);
          setValue("provider.partnerId", partnerChangeModal.payload.id);
          setPartnerChangeModal({ isOpen: false });
          setPartnerModal({ isOpen: false });
        }}
        onClose={() => setPartnerChangeModal({ isOpen: false })}
      >
        {partnerChangeModal.message}
      </BaseModal>

      {partnerModal.isOpen && (
        <PartnerSelectModal
          onCanceled={() => setPartnerModal({ isOpen: false })}
          onAdded={(selected) => {
            const selectedPartner = selected[0];

            if (partner && partner.id !== selectedPartner.id) {
              setPartnerChangeModal({
                isOpen: true,
                message: "파트너를 변경하시면 입력하신 정보가 초기화됩니다.",
                payload: selectedPartner,
              });
              setPartnerModal({ isOpen: false });
            } else {
              setPartner(selectedPartner);
              setValue("provider.partnerId", selectedPartner.id);
              setPartnerModal({ isOpen: false });
            }
          }}
          defaultValues={partner !== undefined ? [partner] : []}
        />
      )}
    </article>
  );
};

export default ProviderBasicForm;
