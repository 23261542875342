import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getOrgAdminMemoAsync, getOrgDetail } from "src/api/organization/org-api";
import {
  BizType,
  MbOrg,
  MbOrgDetail,
  MbOrgMedia,
  MediaType,
  OrgAdminMemoModel,
  TaxType,
} from "src/api/organization/org-types";
import { PageMeta, Sort } from "src/api/public-types";
import { BaseButton, ContentsIdSection, LoadingBar } from "src/components";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import ImagesDetail from "../../components/ImagesDetail";
import TextAttribute from "../../components/TextAttribure";
import TextNFileDownload from "../../components/TextNFileDownload";
import ReadOnlyOrgAdminMemoTable from "../adminMemo/components/ReadOnlyOrgAdminMemoTable";
import { Partner } from "src/types/partner";
import { getPartner } from "src/api/partner";
import { companyRegistrationHypen, corpHypen, formatPhoneNumber } from "src/utils";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";

type QueryParams = {
  page?: number;
  size?: number;
  sort?: Sort;
};

const OrgBaseInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: orgId } = useParams();

  const { setLoadingBar } = useLoadingBarContext();
  const classname = "minmax300";
  const [logo, setLogo] = useState<MbOrgMedia[]>([]);
  const [proofs, setProofs] = useState<MbOrgMedia[]>([]);
  const [partner, setPartner] = useState<Partner>();

  // 메모 목록
  const [memoList, setMemoList] = useState<Array<OrgAdminMemoModel>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  // 상세 데이터
  const [detail, setDetail] = useState<MbOrgDetail>();

  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    if (!_queryParams.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams.size) {
      _queryParams.size = 3;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "adminNote.id", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, [location]);

  const { executeAsync: getAdminMemo } = useApiOperation(getOrgAdminMemoAsync); // 관리자메모 리스트
  const { executeAsync: getOrganizationDetail } = useApiOperation(getOrgDetail);
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner); // 파트너 조회

  // 관리자메모 목록
  const getAdminMemoApi = useCallback(
    async (orgId: number) => {
      setLoadingBar(true);
      const response = await getAdminMemo({ orgId, queryParams: queryParams });
      if (response.status >= 200 && response.status <= 299) {
        setMemoList(response.data.data.content);
        setPageMeta(response.data.meta.pageMeta);
      }
      setLoadingBar(false);
    },
    [getAdminMemo, queryParams, setLoadingBar],
  );

  // 파트너 조회
  const fetchPartner = async (id: string) => {
    setLoadingBar(true);
    const response = await getPartnerAsync({ id });
    if (response.status >= 200 && response.status <= 299) {
      const partner = response.data.data.partner;
      setPartner(partner);
    }
    setLoadingBar(false);
  };

  const checkFileType = useCallback((mediaList: any) => {
    if (mediaList && mediaList.length > 0) {
      const pfs = mediaList.filter((media: any) => media.type === MediaType.PROOF);
      const lg = mediaList.filter((media: any) => media.type === MediaType.LOGO);
      setLogo(lg);
      setProofs(pfs);
    }
  }, []);

  const goList = useCallback(() => {
    navigate(PagePath.organization.list);
  }, [navigate]);

  const goEditPage = useCallback(() => {
    const page = PagePath.organization.form + "?id=" + orgId!.toString();
    navigate(page);
  }, [navigate, orgId]);

  const findTaxType = useCallback((taxType: string, bizType: string) => {
    let rtn = "";
    switch (taxType) {
      case TaxType.GENERAL:
        if (bizType === "SP") {
          rtn = "일반 과세";
        } else {
          rtn = "과세";
        }
        break;
      case TaxType.SIMPLIFIED_A:
        rtn = "간이(세금계산서 발급형)";
        break;
      case TaxType.SIMPLIFIED_B:
        rtn = "간이(세금계산서 미발급형)";
        break;
      case TaxType.FREE:
        rtn = "면세";
        break;
      case TaxType.ETC:
        rtn = "기타";
        break;
    }
    return rtn;
  }, []);

  useEffect(() => {
    getOrganizationDetail({ id: Number(orgId) }).then((response) => {
      setLoadingBar(true);
      if (response.status >= 200 && response.status < 300) {
        const result = response.data.data;
        setDetail(result);
        getAdminMemoApi(Number(result.organization.id));
        checkFileType(result?.mediaList);
        fetchPartner(result?.organization?.partnerId);
      }
      setLoadingBar(false);
    });
  }, [orgId, checkFileType, getOrganizationDetail, getAdminMemoApi]);
  useTitleOperation(detail?.organization?.name);

  return (
    <>
      <div className="contents-container__wrap detail-basic-info">
        <div className="contents-container__wrap-contents">
          <ContentsIdSection title="기본정보" id={detail?.organization.id} />
          <section className="user-basic-info">
            <div className="user-info-container">
              <ImagesDetail logo={logo} />
            </div>
          </section>
          <ContentsIdSection title="사업자 상세 정보" />

          {/* 유저 기본정보 */}
          <section className="user-basic-info">
            <div className="user-info-container">
              <div className="flex-center">
                {/* id */}
                <TextAttribute title={"id"} value={detail?.organization.id} classname={classname} />
              </div>
              <div className="flex-center">
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>구분</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>
                      {detail?.organization.classifications
                        .map((classification) =>
                          classification === "GENERAL_BIZ" ? "일반 사업자" : "소유주",
                        )
                        .join(", ")}
                    </p>
                  </div>
                </section>{" "}
              </div>
              <div className="flex-center">
                <TextAttribute
                  title={"사업자 형태"}
                  value={
                    detail?.organization.bizType === BizType.CORP
                      ? "법인 사업자"
                      : detail?.organization.bizType === BizType.SP
                      ? "개인 사업자"
                      : ""
                  }
                />
              </div>
              <div className="flex-center">
                <TextAttribute
                  title={"법인명/상호"}
                  value={detail?.organization.name}
                  classname={classname}
                />
              </div>
              <div className="flex-center">
                <TextAttribute
                  title={"사업자 등록번호"}
                  value={companyRegistrationHypen(detail?.organization.bizRegNo || "-")}
                  classname={classname}
                />
              </div>
              {detail?.organization.bizType === BizType.CORP && (
                <div className="flex-center">
                  <TextAttribute
                    title={"법인 등록번호"}
                    value={corpHypen(detail?.organization.corRegNo)}
                    classname={classname}
                  />
                </div>
              )}
              <div className="flex-center">
                <TextAttribute
                  title={"대표자/성명"}
                  value={detail?.organization.ceoName}
                  classname={classname}
                />
              </div>
              <div className="flex-center">
                <TextAttribute
                  title={"업태"}
                  value={detail?.organization.bizCondition}
                  classname={classname}
                />
              </div>
              <div className="flex-center">
                <TextAttribute
                  title={"종목"}
                  value={detail?.organization.bizItem}
                  classname={classname}
                />
              </div>
              <div className="flex-center">
                <TextAttribute
                  title={"과세 유형"}
                  value={findTaxType(
                    String(detail?.organization.taxType),
                    String(detail?.organization.bizType),
                  )}
                  classname={classname}
                />
              </div>
              <div className="flex-center">
                <TextAttribute
                  title={"사업장 주소지"}
                  value={detail?.organization.address}
                  classname={classname}
                />
              </div>
              <div className="flex-center">
                <TextNFileDownload
                  title={"사업자 증빙 서류"}
                  fileList={proofs}
                  classname={classname}
                />
              </div>
              <div className="flex-center">
                <TextAttribute
                  title={"이메일"}
                  value={detail?.organization.email}
                  classname={classname}
                />
              </div>
              <div className="flex-center">
                <TextAttribute
                  title={"휴대폰"}
                  value={
                    detail?.organization.phoneNumber
                      ? formatPhoneNumber(detail?.organization.phoneNumber)
                      : "-"
                  }
                  classname={classname}
                />
              </div>
              <div className="flex-center">
                <TextAttribute
                  title={"생성일"}
                  value={moment(detail?.organization.createdDate).format("YYYY-MM-DD HH:mm")}
                  classname={classname}
                />
              </div>
              <div className="flex-center">
                <TextAttribute
                  title={"최종 수정일"}
                  value={moment(detail?.organization.lastModifiedDate).format("YYYY-MM-DD HH:mm")}
                  classname={classname}
                />
              </div>
              <div className="flex-center">
                <TextAttribute
                  title={"최종 작성자"}
                  value={detail?.organization.lastModifiedBy}
                  classname={classname}
                />
              </div>
              <div className="flex-center">
                <TextAttribute
                  title={"파트너"}
                  value={partner ? `${partner?.name}(${partner?.code})` : ""}
                  classname={classname}
                />
              </div>
            </div>
          </section>

          <AdminMemoV3
            serviceId={Number(detail?.organization.id)}
            serviceType={ServiceTypes.SERVICE_ORGANIZATION}
            partnerId={partner?.id!}
          ></AdminMemoV3>
        </div>

        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              onClick={() => goList()}
              className="color-white size-large"
            />
          </div>
          <div className="right-area"></div>
        </div>
      </div>
    </>
  );
};

export default OrgBaseInfo;
