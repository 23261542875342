import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { getContractApply } from "src/api/contract/contract-api";
import { ContractManageList, ContractStep } from "src/api/contract/contract-types";
import useApiOperation from "src/api/hooks/api-operation";
import {
  BaseButton,
  BaseCheckbox,
  BaseInput,
  BaseModal,
  BasePagination,
  BaseSelect,
} from "src/components";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { PagePath } from "src/pages/product/details";

interface Contract {
  contractId: number;
  contractApplyNumber: string;
  contractStep?: ContractStep;
  contractStepFilter?: any;
  spaceProductName: string;
}

interface Props {
  onCanceled: () => void;
  onAdded: (selected: Contract[]) => void;
  defaultChecked: Contract[];
  contractStepFilter?: ContractStep[];
}

interface CellProps extends Cell<Contract> {
  checked: boolean;
}
const changeTextContractStepChip = (type: string) => {
  switch (type) {
    case ContractStep.APPLY_RECEIVED:
      return <div className="chip gray">신청접수</div>;
    case ContractStep.APPLY_CANCEL:
      return <div className="chip red">신청취소</div>;
    case ContractStep.APPLY_CONFIRM:
      return <div className="chip gray">신청확인</div>;
    case ContractStep.CONTRACT_PLAN:
      return <div className="chip gray">계약예정</div>;
    case ContractStep.CONTRACT_ACCEPT:
      return <div className="chip gray">계약체결</div>;
    case ContractStep.USE_APPROVAL:
      return <div className="chip green">이용승인</div>;
    case ContractStep.USE_PROGRESS:
      return <div className="chip green">이용중</div>;
    case ContractStep.USE_COMPLETE:
      return <div className="chip red">이용완료</div>;
    case ContractStep.TERMINATE_RECEIVED:
      return <div className="chip red">해지접수</div>;
    case ContractStep.TERMINATE_COMPLETE:
      return <div className="chip red">해지완료</div>;
    default:
      return "-";
  }
};
const CONTRACT_TABLE_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 80,
    Cell: (props: CellProps) => {
      const contractApplyNumber = props.row.cells[2].value;
      const finded = props?.row?.original?.contractStepFilter.find(
        (step: string) => step === props.row.original.contractStep,
      );
      let disabled = false;

      if (finded === undefined) {
        disabled = true;
      }
      return (
        <div
          data-data-id={contractApplyNumber}
          data-checked={props.value}
          className="checkbox"
          data-disabled={disabled}
        >
          <BaseCheckbox id={""} name={""} checked={props.value} disabled={disabled} />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "contractId",
    width: 80,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "신청번호",
    accessor: "contractApplyNumber",
    width: 150,
    Cell: (props: CellProps) => {
      const id = props.row.cells[1].value;
      const detailPath = PagePath.contract.detail.replace(":id", id);
      return (
        <Link to={detailPath} className="text-hilight" target="_blank" rel="noreferrer">
          {props.value}
        </Link>
      );
    },
  },
  {
    Header: "상품명",
    accessor: "spaceProductName",
    width: 225,
    Cell: (props: CellProps) => <div className="w-100">{props.value ? props.value : "-"}</div>,
  },
  {
    Header: "법인명/상호",
    accessor: "mbOrganizationName",
    width: 175,
    Cell: (props: CellProps) => <div className="w-100">{props.value ? props.value : "-"}</div>,
  },
  {
    Header: "계약자명",
    accessor: "applicantName",
    width: 100,
    Cell: (props: CellProps) => <div className="w-100">{props.value ? props.value : "-"}</div>,
  },
  {
    Header: "상태",
    accessor: "contractStep",
    width: 90,
    Cell: (props: CellProps) => {
      return changeTextContractStepChip(props.value);
    },
  },
];



function ContractSelectModal({ onCanceled, onAdded, defaultChecked, contractStepFilter }: Props) {
  const [page, setPage] = useState({ current: 0, total: 0, totalElements: 0 });
  const [contractList, setContractList] = useState<ContractManageList[]>([]);
  const [selectedContractList, setSelectedContractList] = useState<Contract[]>(defaultChecked);
  const { handleSubmit, getValues, control } = useForm<{
    searchWord: string;
    availableFilterList: string;
  }>();

  const searchFormRef = useRef<HTMLFormElement>(null);

  const contractListTableData = contractList.map((contract) => {
    const selectedIdList = selectedContractList?.map((contract) => contract.contractApplyNumber);

    return {
      checked: selectedIdList.includes(contract.contractApplyNumber),
      ...contract,
      contractStepFilter,
    };
  });

  const { executeAsync: getContractApplyAsync } = useApiOperation(getContractApply);

  const search = ({
    searchWord,
    contractStep,
    page,
  }: {
    page: number;
    searchWord?: string;
    contractStep?: string;
  }) => {
    fetchContractList({ page, searchWord, contractStep });
  };

  const onSubmit = ({ searchWord }: { searchWord: string }) =>
    search({ page: 0, searchWord, contractStep: getValues("availableFilterList") });
  const goPage = (nextPage: number) =>
    search({
      page: nextPage,
      searchWord: getValues("searchWord"),
      contractStep: getValues("availableFilterList"),
    });

  const fetchContractList = async ({
    searchWord,
    contractStep,
    page,
  }: {
    page: number;
    searchWord?: string;
    contractStep?: string;
  }) => {
    const result = await getContractApplyAsync({
      page,
      searchValue: searchWord,
      size: 20,
      contractStep,
      sort: {
        orders: [
          {
            property: "contractManageId",
            direction: "DESC",
          },
        ],
      },
    });

    setContractList(result.data.data.content);

    setPage({
      current: result.data.meta.pageMeta?.pageRequest.page || 0,
      total: result.data.meta.pageMeta?.totalPages || 0,
      totalElements: result.data.meta.pageMeta?.totalElements || 0,
    });
  };
  const selectContract = (contractApplyNumber: string) => {
    const selectedContract = contractList.find(
      (contract) => String(contract.contractApplyNumber) === contractApplyNumber,
    );
    setSelectedContractList([
      ...selectedContractList,
      {
        contractId: selectedContract?.contractId || 0,
        contractApplyNumber: selectedContract?.contractApplyNumber || "",
        spaceProductName: selectedContract?.spaceProductName || "",
        contractStep: selectedContract?.contractStep as ContractStep,
      },
    ]);
  };
  const unSelectContract = (contractApplyNumber: string) => {
    const filteredContractList = selectedContractList.filter(
      (contract) => String(contract.contractApplyNumber) !== contractApplyNumber,
    );
    setSelectedContractList(filteredContractList);
  };
  const _onAdded = () => onAdded(selectedContractList);

  const handleSearchClick = () => {
    searchFormRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );
  };

  const handleCheckboxClick = (checkbox: HTMLDivElement) => {
    const dataId = checkbox.dataset.dataId;
    const disabled = Boolean(String(checkbox.dataset.disabled) === "true");
    const checked = Boolean(checkbox.dataset.checked === "true");
    if (disabled !== undefined && !disabled) {
      if (!checked) {
        selectContract(dataId!);
      } else {
        unSelectContract(dataId!);
      }
    }
  };

  const handleModalContentsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;
    const checkbox = target.closest(".checkbox") as HTMLDivElement;
    if (checkbox) handleCheckboxClick(checkbox);
  };

  useEffect(() => {
    fetchContractList({
      page: 0,
      contractStep: "",
      searchWord: "",
    });
  }, []);

  return (
    <BaseModal isOpen={true} className="dialog-modal">
      <div>
        <div className="modal-title">
          <div className="left-area">
            <span>신청/계약 선택</span>
          </div>
          <form className="right-area" onSubmit={handleSubmit(onSubmit)} ref={searchFormRef}>
            <Controller
              name="availableFilterList"
              control={control}
              render={({ field }) => (
                <BaseSelect
                  className="mr8 minmax140"
                  value={field.value}
                  stateOptions={[
                    {
                      label: "전체",
                      value: "",
                    },
                    {
                      label: "사용가능",
                      value: contractStepFilter?.join(",") || "",
                    },
                  ]}
                  setStateValue={(value: string) => {
                    search({ page: 0, contractStep: value, searchWord: getValues("searchWord") });
                    field.onChange(value);
                  }}
                />
              )}
            />
            <Controller
              name="searchWord"
              control={control}
              render={({ field }) => (
                <BaseInput
                  placeholder="검색어를 입력하세요"
                  value={field.value}
                  onChange={field.onChange}
                  onSearchClick={handleSearchClick}
                  onKeyUp={handleSearchClick}
                  // clearable
                />
              )}
            />
          </form>
        </div>
        <div className="base-modal__contents" onClick={handleModalContentsClick}>
          <ViewDataTable columns={CONTRACT_TABLE_COLUMNS} data={contractListTableData} />
          <BasePagination pageIndex={page.current} totalPages={page.total} goPage={goPage} />
        </div>
        <div className="base-modal__btn-wrap">
          <BaseButton
            title={"취소"}
            className="color-white no-outline text-primary3 mr10"
            onClick={onCanceled}
          />
          <BaseButton title={"선택 추가"} onClick={_onAdded} />
        </div>
      </div>
    </BaseModal>
  );
}
export default ContractSelectModal;
