import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse, Order, Sort } from "../public-types";
import {
  AddSmartThingsControlPayload,
  CommandPart,
  PaIotLabel,
  PaPlatformAccount,
  PaPlatformDevice,
  PaRegisteredDevice,
  PaRegisteredDeviceControl,
  RegisteredPartner,
} from "./iot-types";
import { ApiPath } from "..";
import qs from "qs";
import { Partner } from "../partner/type";

/* 
  CPI01. IoT 플랫폼 디바이스 등록 처리
*/
export async function postIotRegisteredDeviceAsync(
  axios: AxiosInstance,
  params?: {
    platformAccount: PaPlatformAccount;
    platformDeviceId: string;
    displayName: string;
  },
): Promise<AxiosResponse<ApiResponse<{ device: PaRegisteredDevice[] }>>> {
  return await axios.post<ApiResponse<{ device: PaRegisteredDevice[] }>>(ApiPath.iot.add, {
    ...params,
    paramsSerializer: (params: any) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}
/*
  CPI02. IoT 등록 디바이스 목록 페이지 조회
*/
export async function getIotRegisteredDeviceListAsync(
  axios: AxiosInstance,
  params?: {
    id?: string;
    page?: number;
    size?: number;
    status?: string;
    keyword?: string;
    sort?: {
      orders?: Array<Order>;
    };
    partnerId?: string;
    partnerName?: string;
    containsDisplayName?: string;
    containsPlatformAccountEmail?: string;
    containsPlatformDeviceId?: string;
    containsPlatformDeviceLabel?: string;
    containsPlatformDeviceName?: string;
    search001?: string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: PaRegisteredDevice[] }>>> {
  return await axios.get<ApiResponse<{ content: PaRegisteredDevice[] }>>(ApiPath.iot.list, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/*
  CPI03. IoT 등록 디바이스 수정 처리
*/
export async function patchRegisteredDeviceAsync(
  axios: AxiosInstance,
  params?: {
    registeredDeviceId: string | number;
    internalDisplayName: string;
    platformDevice?: { id: string; platformAccount: { platform: string; principalName: string } };
  },
): Promise<AxiosResponse<ApiResponse<{ device: PaRegisteredDevice }>>> {
  if (!params) {
    throw Error("params is not found");
  }
  const path = ApiPath.iot.edit.replace("{registeredDeviceId}", String(params.registeredDeviceId));
  return await axios.patch<ApiResponse<{ device: PaRegisteredDevice }>>(
    path,
    params.platformDevice
      ? {
          internalDisplayName: params.internalDisplayName,
          platformDevice: params.platformDevice,
        }
      : {
          internalDisplayName: params.internalDisplayName,
        },
  );
}

/*
  CPI04. IoT 등록 디바이스 설명 조회
 */
export async function getIotRegisteredDeviceDescriptionAsync(
  axios: AxiosInstance,
  params?: {
    registeredDeviceId?: string | number;
  },
): Promise<AxiosResponse<ApiResponse<{ result: string }>>> {
  if (!params?.registeredDeviceId) {
    throw Error("registeredDeviceId is not found");
  }
  return await axios.get<ApiResponse<{ result: string }>>(
    ApiPath.iot.detail.replace("{registeredDeviceId}", String(params.registeredDeviceId)),
  );
}
/*
  CPI05. IoT 등록 디바이스 라벨 목록 조회
 */
export async function getIotRegisteredDeviceLabelsAsync(
  axios: AxiosInstance,
  params?: {
    registeredDeviceId: string | number;
  },
): Promise<AxiosResponse<ApiResponse<{ labels: PaIotLabel[] }>>> {
  if (!params?.registeredDeviceId) {
    throw Error("registeredDeviceId is not found");
  }
  return await axios.get<ApiResponse<{ labels: PaIotLabel[] }>>(
    ApiPath.iot.labels.replace("{registeredDeviceId}", String(params.registeredDeviceId)),
  );
}
/*
  CPI06. IoT 등록 디바이스 라벨 목록 저장
 */
export async function putIotRegisteredDeviceLabelsAsync(
  axios: AxiosInstance,
  params?: {
    registeredDeviceId: string | number;
    labels: PaIotLabel[];
  },
): Promise<AxiosResponse<ApiResponse<{ labels: PaIotLabel[] }>>> {
  if (!params?.registeredDeviceId) {
    throw Error("registeredDeviceId is not found");
  }
  if (!params?.labels) {
    throw Error("labels is not found");
  }
  return await axios.put<ApiResponse<{ labels: PaIotLabel[] }>>(
    ApiPath.iot.labels.replace("{registeredDeviceId}", String(params.registeredDeviceId)),
    {
      labels: params.labels,
    },
  );
}
/* 
  CPI11. IoT 등록 디바이스 제어 목록 조회
*/
export async function getIotRegisteredDeviceControlListAsync(
  axios: AxiosInstance,
  params?: {
    registeredDeviceId: string | number;
    sort?: {
      orders?: Array<Order>;
    };
  },
): Promise<AxiosResponse<ApiResponse<{ controls: PaRegisteredDeviceControl[] }>>> {
  if (!params) {
    throw Error("registeredDeviceId is not found");
  }

  return await axios.get<ApiResponse<{ controls: PaRegisteredDeviceControl[] }>>(
    ApiPath.iot.controlList.replace("{registeredDeviceId}", String(params.registeredDeviceId)),
    {
      params: { sort: params.sort },
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  );
}

/*
  CPI12. IoT 등록 디바이스 제어 생성 처리
*/
export async function postRegisteredDeviceControlAsync(
  axios: AxiosInstance,
  params?: {
    registeredDeviceId: string | number;
    payload: AddSmartThingsControlPayload;
  },
): Promise<AxiosResponse<ApiResponse<{ control: PaRegisteredDeviceControl }>>> {
  if (!params) {
    throw Error("params not found");
  }
  const path = ApiPath.iot.addControl.replace(
    "{registeredDeviceId}",
    String(params.registeredDeviceId),
  );
  return await axios.post<ApiResponse<{ control: PaRegisteredDeviceControl }>>(
    path,
    params.payload,
    {
      paramsSerializer: (params: any) => qs.stringify(params, { allowDots: true }),
    },
  );
}

/*
  CPI13. IoT 등록 디바이스 제어 수정 처리
*/
export async function patchRegisteredDeviceControlAsync(
  axios: AxiosInstance,
  params?: {
    registeredDeviceId: string | number;
    controlId: string | number;
    displayName: string;
  },
): Promise<AxiosResponse<ApiResponse<{ control: PaRegisteredDeviceControl }>>> {
  if (!params) {
    throw Error("params not found");
  }
  const replaceDeviceId = ApiPath.iot.editControl.replace(
    "{registeredDeviceId}",
    String(params.registeredDeviceId),
  );
  const path = replaceDeviceId.replace("{controlId}", String(params.controlId));

  return await axios.patch<ApiResponse<{ control: PaRegisteredDeviceControl }>>(
    path,
    {
      displayName: params.displayName,
    },
    {
      paramsSerializer: (params: any) => qs.stringify(params, { allowDots: true }),
    },
  );
}

/*
  CPI14. IoT 등록 디바이스 제어 삭제 처리
*/
export async function deleteRegisteredDeviceControlAsync(
  axios: AxiosInstance,
  params?: {
    registeredDeviceId: string | number;
    controlId: string | number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  if (!params?.registeredDeviceId || !params.controlId) {
    throw Error("registeredDeviceId or controlId is not found");
  }
  const replaceRegisteredDeviceId = ApiPath.iot.deleteControl.replace(
    "{registeredDeviceId}",
    String(params.registeredDeviceId),
  );
  const path = replaceRegisteredDeviceId.replace("{controlId}", String(params.controlId));

  return await axios.delete<ApiResponse<any>>(path);
}

/*
CPI15. IoT 등록 디바이스 제어 실행 처리
*/
export async function postRegisteredDeviceControlExecuteAsync(
  axios: AxiosInstance,
  params?: {
    registeredDeviceId: string | number;
    controlId: string | number;
    payload?: { arguments: string[] };
  },
): Promise<AxiosResponse<ApiResponse<{ result: string }>>> {
  if (!params?.registeredDeviceId || !params.controlId) {
    throw Error("registeredDeviceId or controlId is not found");
  }
  const replaceRegisteredDeviceId = ApiPath.iot.executeControl.replace(
    "{registeredDeviceId}",
    String(params.registeredDeviceId),
  );
  const path = replaceRegisteredDeviceId.replace("{controlId}", String(params.controlId));

  return await axios.post<ApiResponse<{ result: string }>>(
    path,
    {
      ...params.payload,
    },
    {
      paramsSerializer: (params: any) => qs.stringify(params, { allowDots: true }),
    },
  );
}

/*
  CPI16. IoT 등록 디바이스 제어라벨 목록 조회
 */
export async function getIotRegisteredDeviceControlLabelsAsync(
  axios: AxiosInstance,
  params?: {
    registeredDeviceId: string | number;
    controlId: string;
  },
): Promise<AxiosResponse<ApiResponse<{ labels: PaIotLabel[] }>>> {
  if (!params?.registeredDeviceId || !params?.controlId) {
    throw Error("registeredDeviceId or controlId is not found");
  }
  const path = ApiPath.iot.controlLabels
    .replace("{registeredDeviceId}", String(params.registeredDeviceId))
    .replace("{controlId}", String(params.controlId));

  return await axios.get<ApiResponse<{ labels: PaIotLabel[] }>>(path);
}

/*
  CPI17. IoT 등록 디바이스 제어라벨 목록 저장
 */
export async function putIotRegisteredDeviceControlLabelsAsync(
  axios: AxiosInstance,
  params?: {
    registeredDeviceId: string | number;
    controlId: string;
    labels: PaIotLabel[];
  },
): Promise<AxiosResponse<ApiResponse<{ labels: PaIotLabel[] }>>> {
  if (!params?.registeredDeviceId || !params?.controlId) {
    throw Error("registeredDeviceId or controlId is not found");
  }
  const path = ApiPath.iot.controlLabels
    .replace("{registeredDeviceId}", String(params.registeredDeviceId))
    .replace("{controlId}", String(params.controlId));

  return await axios.put<ApiResponse<{ labels: PaIotLabel[] }>>(path, {
    labels: params.labels,
  });
}
/*
  CPI21. IoT 플랫폼 계정 목록 조회
*/
export async function getIotPlatformAccountListAsync(
  axios: AxiosInstance,
): Promise<AxiosResponse<ApiResponse<{ accounts: PaPlatformAccount[] }>>> {
  return await axios.get<ApiResponse<{ accounts: PaPlatformAccount[] }>>(
    ApiPath.iot.platformAccountList,
  );
}

/*
  CPI22. IoT 플랫폼 디바이스 목록 조회
*/
export async function getIotPlatformDeviceListAsync(
  axios: AxiosInstance,
  params?: {
    page?: number;
    size?: number;
    sort?: {
      orders?: Array<Order>;
    };
    email?: string;
    platform?: string;
  },
): Promise<AxiosResponse<ApiResponse<{ devices: PaPlatformDevice[] }>>> {
  return await axios.get<ApiResponse<{ devices: PaPlatformDevice[] }>>(
    ApiPath.iot.platformDeviceList,
    {
      params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  );
}

/*
  CPI31. IoT 등록 디바이스 파트너 목록 조회
*/
export async function getIoTRegisteredDevicePartnerListAsync(
  axios: AxiosInstance,
  params?: {
    registeredDeviceId: string | number;
    sort: Sort;
  },
): Promise<AxiosResponse<ApiResponse<{ partners: RegisteredPartner[] }>>> {
  if (!params) {
    throw Error("params not found");
  }
  return await axios.get<ApiResponse<{ partners: RegisteredPartner[] }>>(
    ApiPath.iot.partnerList.replace("{registeredDeviceId}", String(params.registeredDeviceId)),
    {
      params: { sort: params.sort },
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  );
}

/*
  CPI32. IoT 등록 디바이스 파트너 목록 수정 처리
*/
export async function putIoTRegisteredDevicePartnerAsync(
  axios: AxiosInstance,
  params?: {
    registeredDeviceId: string | number;
    partnerIds: string[];
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  if (!params) {
    throw Error("params not found");
  }
  const path = ApiPath.iot.editPartner.replace(
    "{registeredDeviceId}",
    String(params.registeredDeviceId),
  );

  return await axios.put<ApiResponse<any>>(path, {
    partnerIds: params.partnerIds,
  });
}

/*
  CPI33. IoT 등록 디바이스 파트너 목록 삭제 처리
*/
export async function deleteRegisteredDevicePartnerAsync(
  axios: AxiosInstance,
  params?: {
    registeredDeviceId: string | number;
    partnerId: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  if (!params) {
    throw Error("params not found");
  }
  const replaceRegisteredDeviceId = ApiPath.iot.deletePartner.replace(
    "{registeredDeviceId}",
    String(params.registeredDeviceId),
  );
  const path = replaceRegisteredDeviceId + `?partnerId=${params.partnerId}`;

  return await axios.delete<ApiResponse<any>>(path);
}
