const TableTextCellWithSwitch = ({ title, product }: any) => {
  let text = "";
  console.log(
    "product?.productAirConditioner?.heatingType",
    product?.productAirConditioner?.heatingType,
  );
  switch (product?.productAirConditioner?.heatingType) {
    case "HEATING_UNRECOGNIZED":
      text = "정의되지 않은 타입";
      break;
    case "CENTRAL_AIR_CONDITIONER":
      text = "중앙냉난방";
      break;
    case "INDIVIDUAL_AIR_CONDITIONER":
      text = "개별냉난방";
      break;
    case "DIRECT_HEATING_AIR_CONDITIONER":
      text = product?.productAirConditioner?.heatingTypeDesc || "";
      break;
  }
  console.log(text);
  return (
    <>
      <tr>
        <td width="180">
          <div className="flex-center pl16">
            <span className="text-primary3">{title}</span>
          </div>
        </td>
        <td>
          <div className="w-100 flex-center-start">{text}</div>
        </td>
      </tr>
    </>
  );
};
export default TableTextCellWithSwitch;
