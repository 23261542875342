import qs from "qs";
import { PagePath } from "../../src/pages/product/details";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseButton } from "./BaseButton";

// typeof BaseButton
interface Props extends Omit<React.ComponentProps<typeof BaseButton>, "title"> {
  title?: string;
}

function GoToListButton({ title = "목록으로", className }: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const menu = location.pathname.split("/")?.[3];
  const to = PagePath.base + menu;

  const goNavigate = useCallback(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true, allowDots: true });
    // const queryParams = qsParse(location.search);

    const listParse =
      queryParams.list && qs.parse(String(queryParams?.list) as any, { allowDots: true });
    const newTo = listParse
      ? `${to}?${decodeURIComponent(qs.stringify(listParse, { allowDots: true }))}`
      : to;
    console.log(`queryParams`, queryParams);
    console.log(`listParse`, listParse);
    navigate(newTo);
  }, [location.search, to]);

  return (
    <BaseButton
      title={title} //
      onClick={() => {
        goNavigate();
      }}
      className={`color-white size-large ${className}`}
    />
  );
}

export default GoToListButton;
