import qs from "qs";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentsTitle } from "src/components";
import PagePath from "src/pagePath.json";
import BasicInfoDetail from "./basicInfoDetail/BasicInfoDetail";
import BuildingImagesDetail from "./buildingImagesDetail/BuildingImagesDetail";
import FacilityDetail from "./facilityDetail/FacilityDetail";
import SeatingChartDetail from "./seatingChartDetail/SeatingChartDetail";
import PropertyDetail from "./propertyDetail/PropertyDetail";
import BuildingAdminMemo from "./buildingAdminMemo/BuildingAdminMemo";
import MgmtOfficeDetail from "./mgmtOfficeDetail/MgmtOfficeDetail";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "property", label: "프로퍼티" },
  { value: "facility", label: "공용공간" },
  { value: "seatingChart", label: "좌석 배치도" },
  { value: "image", label: "이미지" }, // TODO: MVP 이후
  { value: "mgmtOffice", label: "관리처" },
  // { value: "memo", label: "관리자 메모" },
];

/* 
  건물 > 상세
 */
const BuildingDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "building") {
        // 건물 목록으로 이동
        navigate(PagePath.building.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "building", label: "건물" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  return (
    <div className="page-product-access">
      <ContentsTitle
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />
      {/* 탭 */}
      <div className="contents-container__scroll">
        {/* 기본정보 */}
        {activeTab?.value === "basicInfo" && <BasicInfoDetail />}
        {/* 프로퍼티 */}
        {activeTab?.value === "property" && <PropertyDetail />}
        {/* 공용공간 */}
        {activeTab?.value === "facility" && <FacilityDetail />}
        {/* 이미지 */}
        {activeTab?.value === "image" && <BuildingImagesDetail />}
        {/* 좌석 배치도 */}
        {activeTab?.value === "seatingChart" && <SeatingChartDetail />}
        {/* 설정 */}
        {activeTab?.value === "mgmtOffice" && <MgmtOfficeDetail />}
        {/* 관리자 메모 */}
        {/* {activeTab?.value === "memo" && <BuildingAdminMemo />} */}
      </div>
    </div>
  );
};
export default BuildingDetail;
