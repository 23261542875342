import React from "react";
import { ISection } from "../types";
import DashboardMenu from "./DashboardMenu";

function DashboardSection({ title, menuList }: ISection) {
  return (
    <section>
      <h2 className="font16 text-primary3">{title}</h2>
      <div className="dashboard-grid">
        {menuList.map((menu) => (
          <DashboardMenu {...menu} key={JSON.stringify(menu)} />
        ))}
      </div>
    </section>
  );
}

export default DashboardSection;
