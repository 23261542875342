import { addMonths } from "date-fns";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { getScheduleRoundNOverdueList } from "src/api/billingSchedule/billingSchedule-api";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { getOrgDetail } from "src/api/organization/org-api";
import { MbOrgDetail, MbOrgMedia } from "src/api/organization/org-types";
import { contract_bill_user_errors, Select } from "src/api/public-types";
import {
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
  BaseInput,
  BaseModal,
  BaseRadio,
  BaseSelect,
  BaseTooltip,
} from "src/components";
import { BaseInputNumberFormat } from "src/components/BaseInputNumberFormat";
import pagePath from "src/pagePath.json";
import SearchContractMemberPopup from "src/pages/commonPopup/SearchContractMemberPopup";
import SearchOrgPopup from "src/pages/commonPopup/SearchOrgPopup";
import { useLoadingBarContext, useToastContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { numberToStringWithComma, YmdFormat } from "src/utils";
import TextNFileDownload from "../modal/components/TextNFileDownload";
import ModifyAmountModal from "../modal/ModifyAmountModal";
import SnapShotModal from "../modal/SnapShotModal";
import FileUpload from "./FileUpload";
import OverdueFeePopup from "./OverdueFeePopup";
interface ContractMedia {
  id?: number;
  serviceId: number;
  serviceType: string;
  serviceTag: string;
  contentType: string;
  filename: string;
  fileSize: number;
  fileStorageType: string;
  key: string;
  isPrimary: boolean;
  orderNums: number;
  isDeleted: boolean;
}
interface BillContactEdit {
  billContactId?: number;
  contractId?: number;
  receiveNotiType?: string;
  contact?: string;
  email?: string;
  description?: string;
  isDeleted?: boolean;

  errorTextContact?: string;
  errorTextEmail?: string;
}
const FOType = ({
  control,
  getValues,
  setValue,
  watch,
  setError,
  register,
  unregister,
  selectedPeriodOption,
  spaceProductType,
  organization,
  contractBasic,
  contractDetail,
  billMessageList,
  bi,
  proof,
  document,
  require,
  talkOfficialdoc,
  taxInVoice,
  member,
  viewContractMember,
  setChangeContractMember,
  setViewContractMember,
  setOrganization,
  setProof,
  setBi,
  setTaxInVoice,
  setTalkOfficialdoc,
  setBillMessageList,
  setRequire,
  setDocument,
  setContract,
  messageList,
  setMessageList,
  scheduleList,
  setScheduleList,
  contractApplication,
  provider,
}: any) => {
  const [textGrey] = useState("contents-container__grid ");
  const { setLoadingBar } = useLoadingBarContext();

  const { openErrorModal } = useErrorModalContext();

  const [isSnapShotOpen, setIsSnapShotOpen] = useState(false);
  const [isModifyAmountOpen, setIsModifyAmountOpen] = useState(false);

  const [isChangeContractor, setIsChangeContractor] = useState(false);
  const [isOrgPop, setIsOrgPop] = useState(false);
  const [isError, setIsError] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [radioIdx, setRadioIdx] = useState<number[]>();
  const [mntRadioIdx, setMntRadioIdx] = useState<number[]>();
  const [isOverdueFeeOpen, setIsOverdueFeeOpen] = useState(false);

  const [payStatusOption] = useState([
    { label: "결제대기", value: "PAY_WAIT" },
    { label: "결제완료", value: "PAY_COMPLETE" },
  ]);
  // const [payTypeOption, setPayStypeOption] = useState([
  //   { label: "신용카드", value: "creaditcard" },
  // ]);

  const { executeAsync: getOrganization } = useApiOperation(getOrgDetail);
  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundNOverdueList);

  const goMemberDetail = (id: number) => {
    let path = "";
    if (id) {
      path = pagePath.member.detail.replace(":id", id!.toString());
    }
    return path;
  };
  const onChangeContractor = useCallback(
    async (member) => {
      if (member) {
        setLoadingBar(true);
        setIsChangeContractor(false);
        // console.log("member", member);
        setChangeContractMember(member);
        setViewContractMember(member);
        setLoadingBar(false);
      }
    },
    [setChangeContractMember, setLoadingBar, setViewContractMember],
  );

  const onChangeOrganization = useCallback(
    async (org) => {
      if (org) {
        setLoadingBar(true);
        setIsOrgPop(false);
        const response: any = await getOrganization({ id: Number(org.id) });
        if (response.status >= 200 && response.status <= 299) {
          const mbOrgDetail: MbOrgDetail = response.data.data;

          setOrganization(mbOrgDetail.organization);
          const proofs = mbOrgDetail?.mediaList!.filter(
            (media: MbOrgMedia) => media.type === "PROOF",
          );
          const bi = mbOrgDetail?.mediaList!.filter((media: MbOrgMedia) => media.type !== "PROOF");
          setProof(proofs);
          setBi(bi);
          setValue("contractManage.mbOrganizationId", org.id);
          // organization?.name;

          setValue("schedule.accountInName", org?.name.substr(0, 7));
          const temp = _.cloneDeep(scheduleList);
          temp.forEach((sd: any) => {
            sd.accountInName = org?.name.substr(0, 7);
          });
          setScheduleList(temp);
        } else {
          let message = errorMessageHandler(
            response.status,
            response.data.meta.errorCode,
            contract_bill_user_errors,
          );
          let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
          return openErrorModal(message, code);
        }
        setLoadingBar(false);
      }
    },
    [
      getOrganization,
      openErrorModal,
      scheduleList,
      setBi,
      setLoadingBar,
      setOrganization,
      setProof,
      setScheduleList,
      setValue,
    ],
  );

  const moveOrg = useCallback((id: number) => {
    let rtn = "";
    if (id) {
      rtn = pagePath.organization.detail.replace(":id", String(id));
    }
    return rtn;
  }, []);

  useEffect(() => {
    const baseCount = 11;
    const mntCount = 5;

    const idxArr = [];
    for (let index = 1; index < baseCount + 1; index++) {
      idxArr.push(index);
    }
    setRadioIdx(idxArr);
    const arrayCount = contractDetail?.scheduleList?.length;

    const makeCount = arrayCount * mntCount;
    const idxArr2 = [];
    for (let index = 1; index < makeCount + 1; index++) {
      idxArr2.push(index);
    }
    setMntRadioIdx(idxArr2);
  }, [contractDetail]);

  const checkOverdueList = useCallback(async () => {
    let checkOverdueBill = [];
    let payCheck = [];
    const res: any = await getRoundList({ scheduleId: contractDetail!.schedule?.scheduleId });
    if (res.status >= 200 && res.status <= 299) {
      const billRounds = res.data.data.content;
      console.log("billRounds", billRounds);
      const today = moment().format(YmdFormat.YYYY_MM_DD);

      if (billRounds) {
        checkOverdueBill = billRounds.filter((round: any) => round.bill.isOverdueBill);
        payCheck = billRounds.filter(
          (round: any) =>
            round.bill.isOverdueBill === false &&
            moment(moment(round.bill.billIssueDate).format(YmdFormat.YYYY_MM_DD)).isBefore(today),
        );
      }
    }

    console.log("payCheck", payCheck, "checkOverdueBill", checkOverdueBill);
    let pay = false;
    payCheck.map((bill: any) => {
      if (
        bill.bill.billPayStatus !== "PAYMENT_SUCCESS" &&
        bill.bill.billPayStatus !== "PAYMENT_FREE"
      ) {
        pay = true;
      }
    });
    let overdue = false;
    checkOverdueBill.map((bill: any) => {
      if (
        bill.bill.billPayStatus !== "PAYMENT_SUCCESS" &&
        bill.bill.billPayStatus !== "PAYMENT_FREE"
      ) {
        overdue = true;
      }
    });

    //TODO 계약일시 입력 상태인지 체크
    if (pay !== false || overdue !== false) {
      return false;
    }
    return true;
  }, [contractDetail, getRoundList]);

  return (
    <>
      <article className="pb20">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>{"이용기간"}</h2>
            <p className="contents-container__sub-title-info">
              신청/계약자에게 계약일시 알림톡을 발신하시려면, 상태값을 ‘계약 예정'으로 변경 후
              [저장] 버튼을 클릭해 주세요 (최초 1회)
            </p>
          </div>

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>이용 시작</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax160">
                  <Controller
                    control={control}
                    name="contract.contractStartTime"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <BaseDatePicker
                          readonly={
                            contractBasic?.contractStep === "APPLY_CANCEL" ||
                            contractBasic?.contractStep === "CONTRACT_ACCEPT" ||
                            contractBasic?.contractStep === "USE_APPROVAL" ||
                            contractBasic?.contractStep === "USE_PROGRESS" ||
                            contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                            contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                            contractBasic?.contractStep === "USE_COMPLETE"
                          }
                          name={name}
                          type="date"
                          selectedDate={value ? moment(value).toDate() : null}
                          setDate={(value: string) => {
                            onChange(value);
                            if (spaceProductType !== "TIME_COURT") {
                              const pe = getValues("contract.contractTerm");
                              if (pe) {
                                const start = moment(value).format(YmdFormat.YYYY_MM_DD);
                                const end = moment(start)
                                  .add(pe, "M")
                                  .subtract(1, "days")
                                  .format(YmdFormat.YYYY_MM_DD);
                                setValue("contract.contractEndTime", end);
                                setValue("schedule.scheduleStartTime", value);
                                setValue("schedule.scheduleEndTime", end);
                              }
                            }
                          }}
                          errorText={error && error.message}
                        />
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>기간(MM)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax180">
                  <Controller
                    control={control}
                    name="contract.contractTerm"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <BaseSelect
                          isDisabled={
                            contractBasic?.contractStep === "APPLY_CANCEL" ||
                            contractBasic?.contractStep === "CONTRACT_ACCEPT" ||
                            contractBasic?.contractStep === "USE_APPROVAL" ||
                            contractBasic?.contractStep === "USE_PROGRESS" ||
                            contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                            contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                            contractBasic?.contractStep === "USE_COMPLETE"
                          }
                          name={name}
                          placeholder="선택"
                          value={String(value)}
                          stateOptions={selectedPeriodOption}
                          setStateValue={(value: string) => {
                            if (spaceProductType !== "TIME_COURT") {
                              const date = getValues("contract.contractStartTime");
                              if (date) {
                                const start = moment(date).format(YmdFormat.YYYY_MM_DD);
                                const end = moment(start)
                                  .add(value, "M")
                                  .subtract(1, "days")
                                  .format(YmdFormat.YYYY_MM_DD);

                                const vm = moment(end).format(YmdFormat.YYYY_MM_DD);
                                const be = moment(getValues("schedule.scheduleEndTime")).format(
                                  YmdFormat.YYYY_MM_DD,
                                );
                                if (moment(vm).isBefore(be)) {
                                  setValue("schedule.scheduleEndTime", vm);
                                }

                                setValue("contract.contractEndTime", end);
                              }
                            }
                            onChange(value);
                          }}
                          errorText={error && error.message}
                        />
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
          </div>

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>이용 종료</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax160">
                  <Controller
                    control={control}
                    name="contract.contractEndTime"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <BaseDatePicker
                          readonly={
                            contractBasic?.contractStep === "APPLY_CANCEL" ||
                            contractBasic?.contractStep === "CONTRACT_ACCEPT" ||
                            contractBasic?.contractStep === "USE_APPROVAL" ||
                            contractBasic?.contractStep === "USE_PROGRESS" ||
                            contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                            contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                            contractBasic?.contractStep === "USE_COMPLETE"
                          }
                          name={name}
                          type="date"
                          selectedDate={value ? moment(value).toDate() : null}
                          setDate={(value: string) => {
                            onChange(value);
                            const vm = moment(value).format(YmdFormat.YYYY_MM_DD);
                            const be = moment(getValues("schedule.scheduleEndTime")).format(
                              YmdFormat.YYYY_MM_DD,
                            );
                            if (moment(vm).isBefore(be)) {
                              setValue("schedule.scheduleEndTime", vm);
                            }
                          }}
                          errorText={error && error.message}
                        />
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약일시</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="contract.contractDate"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <div className="minmax180 mr8">
                            <BaseDatePicker
                              readonly={
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "CONTRACT_ACCEPT" ||
                                contractBasic?.contractStep === "USE_APPROVAL" ||
                                contractBasic?.contractStep === "USE_PROGRESS" ||
                                contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "USE_COMPLETE"
                              }
                              name={name}
                              type="full"
                              selectedDate={value ? moment(value).toDate() : null}
                              setDate={onChange}
                              errorText={error && error.message}
                            />
                          </div>
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
          </div>
        </div>
      </article>

      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>빌링 기간</h2>
          </div>

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>빌링 시작</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax160">
                  <Controller
                    control={control}
                    name="schedule.scheduleStartTime"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <BaseDatePicker
                          readonly={
                            contractBasic?.contractStep === "APPLY_CANCEL" ||
                            contractBasic?.contractStep === "CONTRACT_ACCEPT" ||
                            contractBasic?.contractStep === "USE_APPROVAL" ||
                            contractBasic?.contractStep === "USE_PROGRESS" ||
                            contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                            contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                            contractBasic?.contractStep === "USE_COMPLETE"
                          }
                          name={name}
                          type="date"
                          selectedDate={value ? moment(value).toDate() : null}
                          setDate={(value: string) => {
                            onChange(value);
                            let day = Number(moment(value).format("DD"));
                            if (27 < Number(day)) {
                              day = 28;
                            }

                            setValue("schedule.payRegularDay", day);

                            setValue("schedule.taxInvoiceIssueDay", day);
                          }}
                          errorText={error && error.message}
                          minDate={moment(watch("contract.contractStartTime")).toDate()}
                          maxDate={moment(watch("contract.contractEndTime")).toDate()}
                        />
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>빌링 종료</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax160">
                  <Controller
                    control={control}
                    name="schedule.scheduleEndTime"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <BaseDatePicker
                          readonly={
                            contractBasic?.contractStep === "APPLY_CANCEL" ||
                            contractBasic?.contractStep === "CONTRACT_ACCEPT" ||
                            contractBasic?.contractStep === "USE_APPROVAL" ||
                            contractBasic?.contractStep === "USE_PROGRESS" ||
                            contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                            contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                            contractBasic?.contractStep === "USE_COMPLETE"
                          }
                          name={name}
                          type="date"
                          selectedDate={value ? moment(value).toDate() : null}
                          setDate={onChange}
                          errorText={error && error.message}
                          minDate={moment(watch("contract.contractStartTime")).toDate()}
                          maxDate={addMonths(moment(watch("contract.contractEndTime")).toDate(), 0)}
                        />
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>계약자 정보</h2>
            {/* 가이드메시지 수정에서만 노출 */}
            <span className="contents-container__sub-title-info">
              <p>본 계약의 이용자로 초대된 회원에 한해 계약자 변경이 가능합니다.</p>
              <p>계약자 변경 시 계약자명 정보를 변경해 주세요.</p>
              <p>{`계약자 변경 시 <공문 수신> 및 <세금계산서 수신>에 등록된 계약자 연락처는 갱신되지 않습니다. 필요 시 각 항목에서 직접 수정해 주세요.`}</p>
            </span>
          </div>
          <div className="flex-center">
            {/* 사업자 등록번호 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>회원 번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax140 mr8">
                    <Link
                      to={goMemberDetail(viewContractMember?.memberNo)}
                      className="text-hilight"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {viewContractMember?.memberNo}
                    </Link>
                  </div>

                  <div className="minmax90">
                    <BaseButton
                      disabled={
                        contractBasic?.contractStep === "APPLY_RECEIVED" ||
                        contractBasic?.contractStep === "APPLY_CANCEL" ||
                        contractBasic?.contractStep === "USE_COMPLETE" ||
                        contractBasic?.contractStep === "TERMINATE_COMPLETE"
                      }
                      title="변경"
                      onClick={() => {
                        setIsChangeContractor(true);
                      }}
                      className="color-white"
                    />
                    <SearchContractMemberPopup
                      contractManageId={contractBasic?.contractManageId}
                      isOpen={isChangeContractor}
                      onClose={() => setIsChangeContractor(false)}
                      onClick={onChangeContractor}
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* 사업자 유형 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>휴대폰</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{viewContractMember?.inviteMobileNumber}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 사업자 등록번호 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>이메일</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{viewContractMember?.email}</p>
              </div>
            </section>
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>계약자명</p>
              </div>
              <div className="contents-container__grid-contents">
                <Controller
                  control={control}
                  name="contract.applicantName"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                    return (
                      <>
                        <div className="minmax180">
                          <BaseInput
                            readonly={
                              contractBasic?.contractStep === "APPLY_RECEIVED" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "USE_COMPLETE" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE"
                            }
                            name={name}
                            value={value}
                            errorText={error && error.message}
                            onChange={(value: string) => {
                              setValue("contract.applicantName", value);
                            }}
                          />
                        </div>
                      </>
                    );
                  }}
                ></Controller>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>사업자 정보</h2>
            {/* 가이드메시지 수정에서만 노출 */}
            <p className="contents-container__sub-title-info">
              계약자가 사업자인 경우, 세금계산서 발행을 위해 사업자 정보 등록이 필요합니다.
              <br />
              사업자 메뉴에서 사업자 정보를 먼저 등록하셔야 계약에서 사업자 조회 및 등록이
              가능합니다.
            </p>
          </div>
          <div className="flex-center">
            {/* 사업자 등록번호 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>사업자 등록번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax140 mr8">
                    {organization ? (
                      <Link
                        className="text-hilight"
                        to={moveOrg(organization?.id!)}
                        target="_blank"
                      >
                        {organization?.bizRegNo ? organization?.bizRegNo : "-"}
                      </Link>
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                  <div className="minmax90">
                    <BaseButton
                      disabled={
                        contractBasic?.contractStep === "APPLY_RECEIVED" ||
                        contractBasic?.contractStep === "APPLY_CANCEL" ||
                        contractBasic?.contractStep === "USE_COMPLETE" ||
                        contractBasic?.contractStep === "TERMINATE_COMPLETE"
                      }
                      title="조회"
                      onClick={() => {
                        setIsOrgPop(true);
                      }}
                      className="color-white"
                    />
                    <Controller
                      control={control}
                      name="contractManage.mbOrganizationId"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <div className="minmax180 mr8">
                              <BaseInput
                                readonly={
                                  contractBasic?.contractStep === "APPLY_RECEIVED" ||
                                  contractBasic?.contractStep === "APPLY_CANCEL" ||
                                  contractBasic?.contractStep === "USE_COMPLETE" ||
                                  contractBasic?.contractStep === "TERMINATE_COMPLETE"
                                }
                                name={name}
                                type="hidden"
                                value={value}
                                errorText={error && error.message}
                              />
                            </div>
                          </>
                        );
                      }}
                    ></Controller>
                    <SearchOrgPopup
                      isOpen={isOrgPop}
                      partnerId={contractApplication.partnerId}
                      onClose={() => setIsOrgPop(false)}
                      onClick={onChangeOrganization}
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* 사업자 유형 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>사업자 유형</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {organization?.bizType
                    ? organization?.bizType === "CORP"
                      ? "법인사업자"
                      : "일반사업자"
                    : "-"}
                </p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 사업자 등록증 첨부 */}
            <TextNFileDownload
              title={"사업자 등록증 첨부"}
              fileList={proof}
              classname={"minmax300"}
            />
            {/* 법인명/상호 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>법인명/상호</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.name ? organization?.name : "-"}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 사업장 주소 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>사업장 주소</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.address ? organization?.address : "-"}</p>
              </div>
            </section>
            {/* 대표자/성명 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>대표자/성명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.ceoName ? organization?.ceoName : "-"}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 과세 유형 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>과세 유형</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {organization === undefined && "-"}
                  {organization !== undefined &&
                    organization?.taxType === "GENERAL" &&
                    organization?.bizType === "SP" &&
                    "일반"}
                  {organization !== undefined &&
                    organization?.taxType === "GENERAL" &&
                    organization?.bizType !== "SP" &&
                    "과세"}
                  {organization !== undefined &&
                    organization?.taxType! === "SIMPLIFIED_A" &&
                    "간이(세금계산서 발급형)"}
                  {organization !== undefined &&
                    organization?.taxType! === "SIMPLIFIED_B" &&
                    "간이(세금계산서 미발급형)"}
                  {organization !== undefined && organization?.taxType! === "FREE" && "면세"}
                  {organization !== undefined && organization?.taxType! === "ETC" && "기타"}
                </p>
              </div>
            </section>
            {/* 업태 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>업태</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.bizCondition ? organization?.bizCondition : "-"}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            <TextNFileDownload
              title={"CI/BI"}
              fileList={bi}
              classname={"minmax300"}
              titleBrFlag={true}
              title2={"첨부(Taap 출력용)"}
            />
            {/* 종목 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>종목</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.bizItem ? organization?.bizItem : "-"}</p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>계약 정보</h2>
            {/* 가이드메시지 수정에서만 노출 */}
            <span className="contents-container__sub-title-info">
              <p>
                ‘계약체결’로 상태를 변경하기 위해, 우선 ‘계약금_결제완료’로 설정,
                ‘계약서_파일등록’이 필요합니다.
              </p>
              <p>‘이용승인’으로 상태를 변경하기 위해, 우선 ‘잔금_결제완료’로 설정이 필요합니다.</p>
            </span>
          </div>
          <div className="flex-center">
            {/* 계약 번호 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약 번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{contractDetail?.contractNumber ? contractDetail?.contractNumber : "-"}</p>
              </div>
            </section>
            {/* 재계약 상태 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>재계약 상태</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <p className="mr8">-</p>
                  <BaseButton
                    title="재계약생성"
                    disabled={true}
                    onClick={() => {}}
                    className="color-white"
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 계약 데이터 상세 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약 데이터 상세</p>
              </div>
              <div className="contents-container__grid-contents">
                {textGrey !== "" && (
                  <BaseButton
                    disabled={
                      contractBasic?.contractStep === "APPLY_RECEIVED" ||
                      contractBasic?.contractStep === "APPLY_CANCEL" ||
                      contractBasic?.contractStep === "USE_APPROVAL" ||
                      contractBasic?.contractStep === "USE_PROGRESS" ||
                      contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                      contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                      contractBasic?.contractStep === "APPLY_CONFIRM" ||
                      contractBasic?.contractStep === "USE_COMPLETE"
                    }
                    title="스냅샷 보기"
                    className="color-white"
                    onClick={() => setIsSnapShotOpen(true)}
                  />
                )}
                <SnapShotModal
                  isOpen={isSnapShotOpen}
                  contractId={contractDetail?.contractId}
                  onClose={() => {
                    setIsSnapShotOpen(false);
                  }}
                />
              </div>
            </section>
            {/* 계약금 */}
            <section className="contents-container__grid not-available">
              <div className="contents-container__grid-index">
                <p>이전 계약 정보</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>-</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 계약금(원) */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약금(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="contract.earnestAmount"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <div className="minmax120 mr8">
                            <BaseInputNumberFormat
                              readonly={
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "USE_APPROVAL" ||
                                contractBasic?.contractStep === "USE_PROGRESS" ||
                                contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "USE_COMPLETE"
                              }
                              name={name}
                              type="text"
                              value={numberToStringWithComma(
                                Number(_.replace(value, new RegExp(",", "g"), "")),
                              )}
                              onChange={(text: string) => {
                                onChange(text);
                              }}
                              errorText={error?.message}
                            />
                          </div>
                        </>
                      );
                    }}
                  ></Controller>
                  <Controller
                    control={control}
                    name="contract.earnestPayStatus"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <div className="minmax120">
                            <BaseSelect
                              isDisabled={
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "USE_APPROVAL" ||
                                contractBasic?.contractStep === "USE_PROGRESS" ||
                                contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "USE_COMPLETE"
                              }
                              value={value}
                              stateOptions={payStatusOption}
                              setStateValue={onChange}
                            />
                          </div>
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
            {/* 잔금 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>잔금(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="contract.balanceAmount"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <div className="minmax120 mr8">
                            <BaseInputNumberFormat
                              readonly={
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "USE_APPROVAL" ||
                                contractBasic?.contractStep === "USE_PROGRESS" ||
                                contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "USE_COMPLETE"
                              }
                              name={name}
                              type="text"
                              value={numberToStringWithComma(
                                Number(_.replace(value, new RegExp(",", "g"), "")),
                              )}
                              onChange={(text: string) => {
                                onChange(text);
                              }}
                              errorText={error?.message}
                            />
                          </div>
                        </>
                      );
                    }}
                  ></Controller>
                  <Controller
                    control={control}
                    name="contract.balancePayStatus"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <div className="minmax120">
                            <BaseSelect
                              isDisabled={
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "USE_APPROVAL" ||
                                contractBasic?.contractStep === "USE_PROGRESS" ||
                                contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "USE_COMPLETE"
                              }
                              value={value}
                              stateOptions={payStatusOption}
                              setStateValue={onChange}
                            />
                          </div>
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
          </div>
          <div className="flex-start">
            {/* 계약서 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약서</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <Controller
                    control={control}
                    name="documents"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <FileUpload
                        title={"파일찾기"}
                        name={name}
                        setPrivateFiles={(file: ContractMedia[]) => {
                          setDocument(file);
                          onChange(file);
                        }}
                        privateFiles={document}
                        errorText={error?.message}
                        limit={10}
                        maxSize={10}
                        accept={".png, .jpg, .pdf"}
                        disabled={
                          contractBasic?.contractStep === "APPLY_CANCEL" ||
                          contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                          contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                          contractBasic?.contractStep === "USE_COMPLETE"
                        }
                      />
                    )}
                  ></Controller>
                </div>
                <p className="font12 text-primary5">
                  * 문서를 카메라로 찍거나 스캔하여 업로드해주세요.
                  <br />
                  * 업로드 가능 이미지 파일타입은 PNG, JPG, PDF입니다.
                  <br />* 업로드 용량은 개당 10MB, 최대 10개까지 가능합니다.
                </p>
              </div>
            </section>
            {/* 계약 필요 서류 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약 필요 서류</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <Controller
                    control={control}
                    name="requires"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <FileUpload
                        disabled={
                          contractBasic?.contractStep === "APPLY_CANCEL" ||
                          contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                          contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                          contractBasic?.contractStep === "USE_COMPLETE"
                        }
                        name={name}
                        title={"파일찾기"}
                        setPrivateFiles={(file: ContractMedia[]) => {
                          setRequire(file);
                          onChange(file);
                        }}
                        privateFiles={require}
                        errorText={error?.message}
                        limit={10}
                        maxSize={10}
                        accept={".png, .jpg, .pdf"}
                      />
                    )}
                  ></Controller>
                </div>
                <p className="font12 text-primary5">
                  * 문서를 카메라로 찍거나 스캔하여 업로드해주세요.
                  <br />
                  * 업로드 가능 이미지 파일타입은 PNG, JPG, PDF입니다.
                  <br />* 업로드 용량은 개당 10MB, 최대 10개까지 가능합니다.
                </p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>이용료 결제 정보</h2>
            {/* 가이드메시지 수정에서만 노출 */}
            <span className="contents-container__sub-title-info">
              <p>청구일 기준으로 10일 전에 이용료 납부 요청 공문이 발신됩니다.</p>
              <p>청구일 변경일이 10일 이내인 경우, 익월부터 청구일 변경이 적용됩니다.</p>
              <p>결제금액 : 계약 시 협의된 최종 월 이용료(부가세 미포함)를 입력해 주세요.</p>
              <p>
                연체수수료의 기본설정은 연 18% 일할적용입니다. 변경 필요 시 ‘계약체결’ 전에 [연체
                요율 관리]에서 변경해 주세요.
              </p>
            </span>
          </div>
          <div className="flex-center">
            {/* 빌링스케줄id */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>빌링스케줄id</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <Link
                    to={pagePath.billing.detail.replace(":id", contractDetail?.contractId)}
                    target="_blank"
                    className="text-hilight"
                  >
                    {contractDetail?.contractId}
                  </Link>
                </div>
              </div>
            </section>
            {/* 결제 타입 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>자동 결제/수납</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="schedule.isAutoBilling"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <BaseRadio
                            disabled={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            id={String(radioIdx?.[0])}
                            name={name}
                            label="적용"
                            value="true"
                            className="mr16"
                            checked={String(value) === "true" ? true : false}
                            onChange={(e) => {
                              onChange(e);
                              setValue("schedule.isAutoBilling", true);
                              // setValue("schedule.isAutoSend", true);
                            }}
                          />
                          <BaseRadio
                            disabled={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            id={String(radioIdx?.[1])}
                            name={name}
                            label="적용안함"
                            value="false"
                            checked={String(value) === "false" ? true : false}
                            onChange={(e) => {
                              onChange(e);
                              setValue("schedule.isAutoBilling", false);
                              // setValue("schedule.isTaxInvoiceIssue", false);
                              // setValue("schedule.isAutoSend", false);
                            }}
                          />
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <div className="flex-center font14">
                  정기 청구일
                  <BaseTooltip
                    contents={""}
                    tooltip={"청구일자가 주말/공휴일이면 가장 빠른 영업일로 적용됩니다."}
                    type={"normal"}
                    className={"tooltip__trigger-icon"}
                  />
                </div>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax100">
                    <Controller
                      control={control}
                      name="schedule.payRegularDay"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <BaseSelect
                              isDisabled={
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE"
                              }
                              name={name}
                              placeholder={""}
                              value={value}
                              stateOptions={[
                                { label: "1일", value: 1 },
                                { label: "2일", value: 2 },
                                { label: "3일", value: 3 },
                                { label: "4일", value: 4 },
                                { label: "5일", value: 5 },
                                { label: "6일", value: 6 },
                                { label: "7일", value: 7 },
                                { label: "8일", value: 8 },
                                { label: "9일", value: 9 },
                                { label: "10일", value: 10 },
                                { label: "11일", value: 11 },
                                { label: "12일", value: 12 },
                                { label: "13일", value: 13 },
                                { label: "14일", value: 14 },
                                { label: "15일", value: 15 },
                                { label: "16일", value: 16 },
                                { label: "17일", value: 17 },
                                { label: "18일", value: 18 },
                                { label: "19일", value: 19 },
                                { label: "20일", value: 20 },
                                { label: "21일", value: 21 },
                                { label: "22일", value: 22 },
                                { label: "23일", value: 23 },
                                { label: "24일", value: 24 },
                                { label: "25일", value: 25 },
                                { label: "26일", value: 26 },
                                { label: "27일", value: 27 },
                                { label: "28일", value: 28 },
                              ]}
                              setStateValue={(value: number) => {
                                setValue("schedule.payRegularDay", value);
                              }}
                            />
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                  <div className="ml10 minmax140">
                    <Controller
                      control={control}
                      name="schedule.payRegularMonth"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <BaseSelect
                              isDisabled={
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE"
                              }
                              name={name}
                              placeholder={""}
                              value={value}
                              stateOptions={[
                                { label: "전월 기준", value: "PREV_MONTH" },
                                { label: "당월 기준", value: "CURR_MONTH" },
                                { label: "익월 기준", value: "NEXT_MONTH" },
                              ]}
                              setStateValue={(value: string) => {
                                setValue("schedule.payRegularMonth", value);
                              }}
                            />
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </div>
            </section>

            {/* 결제금액(원) */}

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>결제금액(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <div className="minmax140 mr8">
                    <Controller
                      control={control}
                      name="contract.payAmount"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <BaseInputNumberFormat
                              readonly={
                                // contractBasic?.contractStep === "APPLY_RECEIVED" ||
                                // contractBasic?.contractStep === "APPLY_CANCEL" ||
                                // contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "CONTRACT_ACCEPT" ||
                                // contractBasic?.contractStep === "CONTRACT_CONFIRM" ||
                                contractBasic?.contractStep === "USE_APPROVAL" ||
                                contractBasic?.contractStep === "USE_PROGRESS" ||
                                contractBasic?.contractStep === "USE_COMPLETE" ||
                                contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE"
                              }
                              name={name}
                              type="text"
                              value={numberToStringWithComma(
                                Number(_.replace(value, new RegExp(",", "g"), "")),
                              )}
                              onChange={onChange}
                            />
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                  <BaseButton
                    disabled={
                      contractBasic?.contractStep === "APPLY_RECEIVED" ||
                      contractBasic?.contractStep === "APPLY_CANCEL" ||
                      contractBasic?.contractStep === "APPLY_CONFIRM" ||
                      contractBasic?.contractStep === "CONTRACT_PLAN"
                    }
                    title="회차별 금액 수정"
                    className="color-white"
                    onClick={() => setIsModifyAmountOpen(true)}
                  />
                  <ModifyAmountModal
                    isOpen={isModifyAmountOpen}
                    onClose={() => {
                      setIsModifyAmountOpen(false);
                    }}
                    onClick={() => {
                      setIsModifyAmountOpen(false);
                    }}
                    scheduleId={contractDetail?.schedule.scheduleId}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <div className="flex-center font14">
                  납부 기한
                  <BaseTooltip
                    contents={""}
                    tooltip={"납부기한이 주말/공휴일이면 가장 빠른 영업일로 적용됩니다."}
                    type={"normal"}
                    className={"tooltip__trigger-icon"}
                  />
                </div>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax90 font14 mr0">청구일 기준 </div>
                  <div className="minmax100 ml0 flex-row flex-center-start">
                    <Controller
                      control={control}
                      name="schedule.payDueDays"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <div className="font14 mr10">+ </div>
                            <BaseInput
                              readonly={
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE"
                              }
                              name={name}
                              placeholder={""}
                              value={value}
                              onChange={(value: number) => {
                                setValue("schedule.payDueDays", value);
                              }}
                            />
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                  <div className="minmax80 font14 ml5">일</div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>관리비 청구 유형</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="contract.isMntContain"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <BaseRadio
                            disabled={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            name={name}
                            label="관리비 포함"
                            value="true"
                            checked={String(value) === "true" ? true : false}
                            id={String(radioIdx?.[2])}
                            onChange={(value: boolean) => {
                              onChange(true);
                              setValue("contract.isMntContain", true);
                            }}
                          />
                          <BaseRadio
                            className="ml5"
                            disabled={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            name={name}
                            label="관리비 별도"
                            value="false"
                            checked={String(value) === "false" ? true : false}
                            id={String(radioIdx?.[3])}
                            onChange={(value: boolean) => {
                              onChange(false);
                              setValue("contract.isMntContain", false);
                            }}
                          />
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 결제 타입 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>결제 타입</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="schedule.contractPayType"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <BaseRadio
                            disabled={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            id={String(radioIdx?.[4])}
                            name={name}
                            label="카드결제형"
                            value="PAY"
                            className="mr16"
                            checked={value === "PAY" ? true : false}
                            onChange={async (e) => {
                              if (
                                provider.providerPayletter.isVerifiedAutoPaymentKey === false ||
                                provider.providerPayletter.isVerifiedAutoSearchKey === false ||
                                provider.providerPayletter.isVerifiedPaymentKey === false ||
                                provider.providerPayletter.isVerifiedSearchKey === false
                              ) {
                                setTitle("카드결제형으로 변경할 수 없습니다.");
                                setMessage(
                                  "해당 상품의 정산정보(프로바이더)는 카드결제형을 지원하지 않습니다. ",
                                );
                                setIsError(true);
                                return;
                              }
                              const flag = await checkOverdueList();

                              if (flag) {
                                onChange(e);
                                setValue("schedule.contractPayType", "PAY");
                                setValue(
                                  "schedule.payRegularDay",
                                  contractDetail?.schedule?.payRegularDay,
                                );
                                setValue("schedule.isTaxInvoiceIssue", false);
                                const dump = _.cloneDeep(taxInVoice);
                                if (dump.length > 0) {
                                  let sendBeDelete = [];
                                  for (let i = 0; i < dump.length; i++) {
                                    if (dump[i].billContactId !== undefined) {
                                      if (dump[i].billContactId !== 0) {
                                        dump[i].isDeleted = true;
                                        sendBeDelete.push(dump[i]);
                                      }
                                    }
                                  }

                                  setTaxInVoice(sendBeDelete);
                                }
                                setValue("schedule.regularPgcode", "creditcard");
                                setError("schedule.taxInvoiceIssueDay", {
                                  type: "custom",
                                  message: "",
                                });
                                setError("schedule.regularPgcode", {
                                  type: "custom",
                                  message: "",
                                });
                                unregister("schedule.taxBillReceiveEmail");
                              } else {
                                openErrorModal("연체금액이 있어 변경할 수 없습니다.");
                              }
                            }}
                          />
                          <BaseRadio
                            disabled={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            id={String(radioIdx?.[5])}
                            name={name}
                            label="세금계산서 발행"
                            value="TAX"
                            checked={value === "PAY" ? false : true}
                            onChange={async (e) => {
                              if (
                                provider.providerPopbill.isVerifiedAccountInfo === false ||
                                provider.providerPopbill.isVerifiedBankAccount === false
                              ) {
                                setTitle("계좌 입금 조회 및 입금 자동 매핑 대상이 아닙니다.");
                                setMessage(
                                  "해당 상품의 정산정보(프로바이더)는 계좌 입금 조회 및 입금 자동 매핑을 지원하지 않습니다.",
                                );
                                setIsError(true);
                              }
                              const flag = await checkOverdueList();
                              if (flag) {
                                onChange(e);

                                setValue("schedule.isAutoSend", true);
                                setValue(
                                  "schedule.taxInvoiceIssueDay",
                                  getValues("schedule.payRegularDay"),
                                );
                                const firstLine = {
                                  billContactId: 0,
                                  contractId: contractDetail.contractId,
                                  receiveNotiType: "TAX_INVOICE",
                                  contact: "",
                                  email: member.email,
                                  description: "",
                                  isDeleted: false,
                                  errorTextContact: "",
                                  errorTextEmail: "",
                                };
                                setTaxInVoice([firstLine]);
                                setValue("schedule.contractPayType", "TAX");
                                setValue("schedule.regularPgcode", "banktransfer");
                                setError("schedule.payRegularDay", {
                                  type: "custom",
                                  message: "",
                                });
                                // setError("contract.pgcode", {
                                //   type: "custom",
                                //   message: "",
                                // });
                                register("schedule.taxBillReceiveEmail");
                              } else {
                                openErrorModal("연체금액이 있어 변경할 수 없습니다.");
                              }
                            }}
                          />
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>결제 방법</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax140">
                  {watch("schedule.contractPayType") === "PAY" ? (
                    <p>{"신용카드"}</p>
                  ) : (
                    <Controller
                      control={control}
                      name="schedule.regularPgcode"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseSelect
                            isDisabled={
                              contractBasic?.contractStep === "APPLY_RECEIVED" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "USE_COMPLETE" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE"
                            }
                            name={name}
                            placeholder="선택"
                            value={String(value)}
                            stateOptions={[
                              { label: "계좌이체", value: "banktransfer", isDisabled: false },
                              // { label: "CMS", value: "cms", isDisabled: true },
                              // {
                              //   label: "가상걔좌",
                              //   value: "virtualaccount",
                              //   isDisabled: true,
                              // },
                            ]}
                            setStateValue={(value: string) => {
                              if (spaceProductType !== "TIME_COURT") {
                                setValue("contract.pgcode", value);
                              }
                              onChange(value);
                            }}
                            errorText={error && error.message}
                          />
                        );
                      }}
                    ></Controller>
                  )}
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 공문 자동발송 */}
            {/* <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>공문 자동발송</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="schedule.isAutoSend"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <BaseRadio
                            id={String(radioIdx?.[6])}
                            disabled={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            name={name}
                            label="발송"
                            value="true"
                            className="mr16"
                            checked={String(value) === "true" ? true : false}
                            onChange={() => {
                              onChange(true);
                            }}
                          />
                          <BaseRadio
                            disabled={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            id={String(radioIdx?.[7])}
                            name={name}
                            label="미발송"
                            value="false"
                            checked={String(value) === "false" ? true : false}
                            onChange={() => {
                              onChange(false);
                              if (getValues("schedule.contractPayType") === "PAY") {
                                unregister("schedule.taxBillReceiveEmail");
                              } else {
                                register("schedule.taxBillReceiveEmail");
                                setError("schedule.taxBillReceiveEmail", {
                                  type: "custom",
                                  message: "",
                                });
                              }
                            }}
                          />
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section> */}
            {/* 공문 자동발송 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>세금계산서 자동 발행</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="schedule.isTaxInvoiceIssue"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <BaseRadio
                            id={String(radioIdx?.[8])}
                            disabled={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE" ||
                              watch("schedule.contractPayType") === "PAY"
                            }
                            name={name}
                            label="발행"
                            value="true"
                            className="mr16"
                            checked={String(value) === "true" ? true : false}
                            onChange={() => {
                              if (provider.providerPopbill.isVerifiedTaxInvoice === false) {
                                setTitle("세금계산서 자동발행으로 변경할 수 없습니다.");
                                setMessage(
                                  "해당 상품의 정산정보(프로바이더)는 세금계산서 자동발행을 지원하지 않습니다.",
                                );
                                setIsError(true);

                                return;
                              }
                              onChange(true);
                            }}
                          />
                          <BaseRadio
                            disabled={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE" ||
                              watch("schedule.contractPayType") === "PAY"
                            }
                            id={String(radioIdx?.[9])}
                            name={name}
                            label="발행 안함"
                            value="false"
                            checked={String(value) === "false" ? true : false}
                            onChange={() => {
                              onChange(false);
                              if (getValues("schedule.contractPayType") === "PAY") {
                                unregister("schedule.taxBillReceiveEmail");
                              } else {
                                register("schedule.taxBillReceiveEmail");
                                setError("schedule.taxBillReceiveEmail", {
                                  type: "custom",
                                  message: "",
                                });
                              }
                            }}
                          />
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>공문 종류</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  {messageList?.map((list: any, index: number) => {
                    // let classN = "ml10";

                    if (list.messageMedia !== "KAKAOTALK") {
                      return (
                        <div key={index}>
                          <BaseCheckbox
                            className={`mb10 `}
                            name={"billMessageList_1_" + index}
                            checked={list.isSend}
                            id={String(radioIdx?.[10]) + "billMessageList_1_" + index}
                            label={list.messageLabel}
                            onChange={(value: boolean) => {
                              const dump = _.cloneDeep(messageList);
                              dump.forEach((message: any, idx: number) => {
                                if (list.messageId === message.messageId) {
                                  message.isSend = value;
                                }
                                return message;
                              });

                              setMessageList(dump);
                            }}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 공문 자동발송 */}

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>세금계산서 발행일</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax100">
                    <Controller
                      control={control}
                      name="schedule.taxInvoiceIssueDay"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <BaseSelect
                              isDisabled={
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE" ||
                                watch("schedule.contractPayType") === "PAY"
                              }
                              name={name}
                              placeholder={""}
                              value={value}
                              stateOptions={[
                                { label: "1일", value: 1 },
                                { label: "2일", value: 2 },
                                { label: "3일", value: 3 },
                                { label: "4일", value: 4 },
                                { label: "5일", value: 5 },
                                { label: "6일", value: 6 },
                                { label: "7일", value: 7 },
                                { label: "8일", value: 8 },
                                { label: "9일", value: 9 },
                                { label: "10일", value: 10 },
                                { label: "11일", value: 11 },
                                { label: "12일", value: 12 },
                                { label: "13일", value: 13 },
                                { label: "14일", value: 14 },
                                { label: "15일", value: 15 },
                                { label: "16일", value: 16 },
                                { label: "17일", value: 17 },
                                { label: "18일", value: 18 },
                                { label: "19일", value: 19 },
                                { label: "20일", value: 20 },
                                { label: "21일", value: 21 },
                                { label: "22일", value: 22 },
                                { label: "23일", value: 23 },
                                { label: "24일", value: 24 },
                                { label: "25일", value: 25 },
                                { label: "26일", value: 26 },
                                { label: "27일", value: 27 },
                                { label: "28일", value: 28 },
                              ]}
                              setStateValue={(value: number) => {
                                setValue("schedule.taxInvoiceIssueDay", value);
                              }}
                              errorText={error?.message}
                            />
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                  <div className="ml10 minmax140">
                    <Controller
                      control={control}
                      name="schedule.taxInvoiceIssueMonth"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <BaseSelect
                              isDisabled={
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE" ||
                                watch("schedule.contractPayType") === "PAY"
                              }
                              name={name}
                              placeholder={""}
                              value={value}
                              stateOptions={[
                                { label: "전월 기준", value: "PREV_MONTH" },
                                { label: "당월 기준", value: "CURR_MONTH" },
                                { label: "익월 기준", value: "NEXT_MONTH" },
                              ]}
                              setStateValue={(value: string) => {
                                setValue("schedule.taxInvoiceIssueMonth", value);
                              }}
                            />
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>알림톡 종류</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  {messageList?.map((list: any, index: number) => {
                    // let classN = "ml10";

                    if (list.messageMedia === "KAKAOTALK") {
                      return (
                        <div key={index}>
                          <BaseCheckbox
                            className={`mb10 `}
                            name={"talkMessageList_2_" + index}
                            checked={list.isSend}
                            id={String(radioIdx?.[11]) + "talkMessageList_2_" + index}
                            label={list.messageLabel}
                            onChange={(value: boolean) => {
                              const dump = _.cloneDeep(messageList);
                              dump.forEach((message: any, idx: number) => {
                                if (list.messageId === message.messageId) {
                                  message.isSend = value;
                                }
                                return message;
                              });

                              setMessageList(dump);
                            }}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 결제 상태 */}
            {/* <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>결제 상태</p>
              </div>
              <div className="contents-container__grid-contents">
                <p className="mr16">
                  {contractDetail?.schedule?.billPayStatus === "PAYMENT_READY" && "결제대기"}
                  {contractDetail?.schedule?.billPayStatus === "PAYMENT_SUCCESS" && "성공"}
                  {contractDetail?.schedule?.billPayStatus === "PAYMENT_FAIL" && "실패"}
                  {contractDetail?.schedule?.billPayStatus === "PAYMENT_CANCEL" && "취소"}
                  {contractDetail?.schedule?.billPayStatus === "CONTRACT_CANCEL" && "해지"}
                  {(contractDetail?.schedule?.billPayStatus === undefined ||
                    contractDetail?.schedule?.billPayStatus === "") &&
                    "-"}
                </p>
              </div>
            </section> */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>입금자명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax160">
                    <Controller
                      control={control}
                      name="schedule.accountInName"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <BaseInput
                              readonly={
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE" ||
                                watch("schedule.contractPayType") === "PAY"
                              }
                              // maxLength={7}
                              name={name}
                              value={value}
                              onChange={(value: string) => {
                                const maxByte = 14;
                                var strValue = value;
                                var strLen = strValue.length;
                                var totalByte = 0;
                                var len = 0;
                                var oneChar = "";
                                var str2 = "";

                                for (var i = 0; i < strLen; i++) {
                                  oneChar = strValue.charAt(i);
                                  if (escape(oneChar).length > 4) {
                                    totalByte += 2;
                                  } else {
                                    totalByte++;
                                  }

                                  // 입력한 문자 길이보다 넘치면 잘라내기 위해 저장
                                  if (totalByte <= maxByte) {
                                    len = i + 1;
                                  }
                                }

                                // 넘어가는 글자는 자른다.
                                if (totalByte > maxByte) {
                                  openErrorModal(maxByte / 2 + "자를 초과 입력 할 수 없습니다.");
                                } else {
                                  setValue("schedule.accountInName", value);
                                  onChange(value);
                                }
                              }}
                              // errorText={errorAccountInName}
                            />
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>연체 수수료(%)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <BaseButton
                    title="연체 요율 관리"
                    className="color-white"
                    disabled={
                      contractBasic?.contractStep === "APPLY_RECEIVED" ||
                      contractBasic?.contractStep === "APPLY_CANCEL"
                    }
                    onClick={() => setIsOverdueFeeOpen(true)}
                  />
                  <OverdueFeePopup
                    isOpen={isOverdueFeeOpen}
                    overdueChargeStandard={contractDetail?.schedule?.overdueChargeStandard}
                    overdueList={contractDetail?.schedule?.overdueList}
                    onClick={(editedData: any) => {
                      setIsOverdueFeeOpen(false);
                      setValue("schedule.overdueChargeStandard", editedData.overdueChargeStandard);
                      setValue("schedule.overdueList", editedData.overdueList);
                    }}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </article>
      {contractDetail?.isMntContain === false && (
        <article className="contents-container__divide-top">
          <div className="contents-container__1200">
            <div className="contents-container__sub-title">
              <h2>관리비 결제 정보</h2>
            </div>
            {scheduleList?.map((sd: any, idx: number) => {
              return (
                <div className="ml20  contents-container__divide-top" key={idx}>
                  <div className="flex-center">
                    {/* 빌링스케줄id */}
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p>프로바이더 id</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="flex-row flex-center-start">
                          <Link
                            to={pagePath.provider.detail.replace(":id", sd.providerId)}
                            target="_blank"
                            className="text-hilight"
                          >
                            {sd.providerId}
                          </Link>
                        </div>
                      </div>
                    </section>
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p>관리처 명</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <p>{sd.providerName}</p>
                      </div>
                    </section>
                  </div>
                  <div className="flex-center">
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p>결제 타입</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="flex-row flex-center-start">
                          <BaseRadio
                            disabled={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            name={"contractPayType_" + idx}
                            label="세금계산서"
                            value="TAX"
                            checked={sd.contractPayType === "TAX" ? true : false}
                            id={"chargeMnt" + String(mntRadioIdx?.[0 + idx * 8])}
                            onChange={(value: boolean) => {
                              const temp = _.cloneDeep(scheduleList);
                              temp.forEach((schedule: any) => {
                                if (schedule.scheduleId === sd.scheduleId) {
                                  schedule.contractPayType = "TAX";
                                }
                              });
                              setScheduleList(temp);
                            }}
                          />
                          {/* <BaseRadio
                            className="ml5"
                            disabled={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            name={"contractPayType_" + idx}
                            checked={sd.contractPayType === "PAY" ? true : false}
                            label="현금영수증"
                            value="PAY"
                            id={"chargeMnt" + String(mntRadioIdx?.[1 + idx * 8])}
                          /> */}
                        </div>
                      </div>
                    </section>

                    {/* 결제금액(원) */}

                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p>결제금액(원)</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="flex-row flex-center-start ">
                          <BaseButton
                            disabled={
                              contractBasic?.contractStep === "APPLY_RECEIVED" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "CONTRACT_PLAN"
                            }
                            title="회차별 금액 수정"
                            className="color-white"
                            onClick={() => {
                              const temp = _.cloneDeep(scheduleList);
                              temp.forEach((schedule: any) => {
                                if (schedule.scheduleId === sd.scheduleId) {
                                  sd.isModifyOpen = true;
                                }
                              });
                              setScheduleList(temp);
                            }}
                          />
                          <ModifyAmountModal
                            isOpen={sd.isModifyOpen}
                            onClose={() => {
                              const temp = _.cloneDeep(scheduleList);
                              temp.forEach((schedule: any) => {
                                if (schedule.scheduleId === sd.scheduleId) {
                                  sd.isModifyOpen = false;
                                }
                              });
                              setScheduleList(temp);
                            }}
                            onClick={() => {
                              const temp = _.cloneDeep(scheduleList);
                              temp.forEach((schedule: any) => {
                                if (schedule.scheduleId === sd.scheduleId) {
                                  sd.isModifyOpen = true;
                                }
                              });
                              setScheduleList(temp);
                            }}
                            scheduleId={contractDetail?.schedule?.scheduleId}
                          />
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="flex-center">
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p>결제 방법</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="minmax140">
                          <BaseSelect
                            isDisabled={
                              contractBasic?.contractStep === "APPLY_RECEIVED" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE"
                            }
                            name={"regularPgcode_" + idx}
                            placeholder="선택"
                            value={String(sd.regularPgcode)}
                            stateOptions={[
                              // { label: "신용카드", value: "creditcard", isDisabled: false },
                              { label: "계좌이체", value: "banktransfer", isDisabled: false },
                              // { label: "CMS", value: "cms", isDisabled: true },
                              // {
                              //   label: "가상걔좌",
                              //   value: "virtualaccount",
                              //   isDisabled: true,
                              // },
                            ]}
                            setStateValue={(value: string) => {
                              const temp = _.cloneDeep(scheduleList);
                              temp.forEach((schedule: any) => {
                                if (schedule.scheduleId === sd.scheduleId) {
                                  schedule.regularPgcode = value;
                                }
                              });
                              setScheduleList(temp);
                            }}
                          />
                        </div>
                      </div>
                    </section>
                    {/* 결제 상태 */}
                    {/* <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p>결제 상태</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <p className="mr16">
                          {sd.billPayStatus === "PAYMENT_READY" && "결제대기"}
                          {sd?.billPayStatus === "PAYMENT_SUCCESS" && "성공"}
                          {sd?.billPayStatus === "PAYMENT_FAIL" && "실패"}
                          {sd?.billPayStatus === "PAYMENT_CANCEL" && "취소"}
                          {sd?.billPayStatus === "CONTRACT_CANCEL" && "해지"}
                          {(sd?.billPayStatus === undefined || sd?.billPayStatus === "") && "-"}
                        </p>
                      </div>
                    </section> */}
                  </div>
                  <div className="flex-center">
                    {/* 공문 자동발송 */}
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p>공문 자동발송</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="flex-row flex-center-start">
                          <>
                            <BaseRadio
                              id={"chargeMnt" + String(mntRadioIdx?.[2 + idx * 8])}
                              disabled={
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE"
                              }
                              name={"chargeMnt" + idx}
                              label="발송"
                              value="true"
                              className="mr16"
                              checked={String(sd.isAutoSend) === "true" ? true : false}
                              onChange={() => {
                                const temp = _.cloneDeep(scheduleList);
                                temp.forEach((schedule: any) => {
                                  if (schedule.scheduleId === sd.scheduleId) {
                                    schedule.isAutoSend = true;
                                  }
                                });
                                setScheduleList(temp);
                              }}
                            />
                            <BaseRadio
                              disabled={
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE"
                              }
                              id={"chargeMnt" + String(mntRadioIdx?.[3 + idx * 8])}
                              name={"chargeMnt" + idx}
                              label="미발송"
                              value="true"
                              checked={String(sd.isAutoSend) === "false" ? true : false}
                              onChange={() => {
                                const temp = _.cloneDeep(scheduleList);
                                temp.forEach((schedule: any) => {
                                  if (schedule.scheduleId === sd.scheduleId) {
                                    schedule.isAutoSend = false;
                                  }
                                });
                                setScheduleList(temp);
                              }}
                            />
                          </>
                        </div>
                      </div>
                    </section>

                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p>입금자명</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="flex-row flex-center-start">
                          <div className="minmax160">
                            <BaseInput
                              readonly={
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE" ||
                                watch("schedule.contractPayType") === "PAY"
                              }
                              maxLength={7}
                              name={`schedule.accountInName`}
                              value={sd.accountInName}
                              onChange={(value: string) => {
                                const temp = _.cloneDeep(scheduleList);
                                temp.forEach((schedule: any) => {
                                  if (schedule.scheduleId === sd.scheduleId) {
                                    schedule.accountInName = value;
                                  }
                                });
                                setScheduleList(temp);
                              }}
                              // errorText={billContact.errorTextEmail}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="flex-center">
                    {/* 공문 자동발송 */}
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p>공문 종류</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div>
                          {sd.messageList?.map((list: any, index: number) => {
                            // let classN = "ml10";

                            if (list.messageMedia !== "KAKAOTALK") {
                              return (
                                <div key={index}>
                                  <BaseCheckbox
                                    className={`mb10 `}
                                    name={idx + "_sdBillMessageList_" + index}
                                    checked={list.isSend}
                                    id={
                                      "chargeMnt" +
                                      String(mntRadioIdx?.[4 + idx * 8]) +
                                      "billMessageList" +
                                      index
                                    }
                                    label={list.messageLabel}
                                    value={list.messageType}
                                    onChange={(value: boolean) => {
                                      const contractTemp = _.cloneDeep(scheduleList);

                                      contractTemp.forEach((schedule: any, i: number) => {
                                        schedule.messageList.forEach((message: any, j: number) => {
                                          if (list.messageId === message.messageId) {
                                            message.isSend = value;
                                          }
                                          return message;
                                        });
                                        return schedule;
                                      });

                                      setScheduleList(contractTemp);
                                    }}
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </section>

                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p>연체 수수료(%)</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <BaseButton
                          title="연체 요율 관리"
                          className="color-white"
                          disabled={
                            contractBasic?.contractStep === "APPLY_RECEIVED" ||
                            contractBasic?.contractStep === "APPLY_CANCEL" ||
                            contractBasic?.contractStep === "APPLY_CONFIRM" ||
                            contractBasic?.contractStep === "CONTRACT_PLAN"
                          }
                          onClick={() => {
                            console.log("dddd");
                            const temp = _.cloneDeep(scheduleList);
                            temp.forEach((schedule: any) => {
                              if (schedule.scheduleId === sd.scheduleId) {
                                schedule.isOverdueOpen = true;
                              }
                            });

                            setScheduleList(temp);
                          }}
                        />
                        <OverdueFeePopup
                          isOpen={sd.isOverdueOpen}
                          overdueChargeStandard={sd?.overdueChargeStandard}
                          overdueList={sd?.overdueList}
                          onClick={(editedData: any) => {
                            const temp = _.cloneDeep(scheduleList);
                            temp.forEach((schedule: any) => {
                              if (schedule.scheduleId === sd.scheduleId) {
                                schedule.isOverdueOpen = false;
                                schedule.overdueChargeStandard = editedData.overdueChargeStandard;
                                schedule.overdueList = editedData.overdueList;
                              }
                            });

                            setScheduleList(temp);
                            // console.log("editedData", editedData);
                          }}
                        />
                      </div>
                    </section>
                  </div>
                  <div className="flex-center">
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p>알림톡 종류</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div>
                          {sd.messageList?.map((list: any, index: number) => {
                            // let classN = "ml10";

                            if (list.messageMedia === "KAKAOTALK") {
                              return (
                                <div key={index}>
                                  <BaseCheckbox
                                    className={`mb10 `}
                                    name={idx + "_sdbillMessageList" + index}
                                    checked={list.isSend}
                                    id={
                                      "chargeMnt" +
                                      String(mntRadioIdx?.[5 + idx * 8]) +
                                      "billMessageList" +
                                      index
                                    }
                                    label={list.messageLabel}
                                    onChange={(value: boolean) => {
                                      const contractTemp = _.cloneDeep(scheduleList);

                                      contractTemp.forEach((schedule: any, i: number) => {
                                        schedule.messageList.forEach((message: any, j: number) => {
                                          if (list.messageId === message.messageId) {
                                            message.isSend = value;
                                          }
                                          return message;
                                        });
                                        return schedule;
                                      });

                                      setScheduleList(contractTemp);
                                    }}
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              );
            })}
          </div>
        </article>
      )}
      <article className="contents-container__divide-top">
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>공문 수신</h2>
          </div>

          {talkOfficialdoc.length > 0 &&
            talkOfficialdoc.map((billContact: BillContactEdit, index: number) => {
              if (billContact.isDeleted === false) {
                return (
                  <div className="flex-center" key={index}>
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p className={"required"}>공문/수신 이메일</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="minmax240">
                          <BaseInput
                            readonly={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            name={`billContact.email${index}`}
                            value={billContact.email}
                            onChange={(value: string) => {
                              const dump = _.cloneDeep(talkOfficialdoc);
                              const adds = dump.map((talk: any, idx: number) => {
                                if (idx === index) {
                                  talk.errorTextEmail = "";
                                  talk.email = value;
                                }
                                return talk;
                              });
                              setTalkOfficialdoc(adds);
                            }}
                            errorText={billContact.errorTextEmail}
                          />
                        </div>
                      </div>
                    </section>
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p className={"required"}>휴대폰</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="minmax240 flex-row flex-center-start">
                          <BaseInput
                            readonly={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            name={`billContact.contact${index}`}
                            value={billContact.contact}
                            onChange={(value: string) => {
                              const dump = _.cloneDeep(talkOfficialdoc);
                              const adds = dump.map((talk: any, idx: number) => {
                                if (idx === index) {
                                  talk.errorTextContact = "";
                                  talk.contact = value;
                                }
                                return talk;
                              });
                              setTalkOfficialdoc(adds);
                            }}
                            errorText={billContact.errorTextContact}
                          />
                          {index === 0 && (
                            <button
                              disabled={watch("schedule.isAutoSend") === false}
                              className="base-add-btn ml10"
                              onClick={(e: any) => {
                                e.preventDefault();
                                const dump = _.cloneDeep(talkOfficialdoc);
                                const filterd = dump.filter(
                                  (bill: any) => bill.isDeleted === false,
                                );

                                if (filterd.length < 5) {
                                  const anotherLine = {
                                    billContactId: 0,
                                    contractId: contractDetail?.contractId,
                                    receiveNotiType: "TALK_OFFICIALDOC",
                                    contact: "",
                                    email: "",
                                    description: "",
                                    isDeleted: false,
                                    errorText: "",
                                  };

                                  dump.push(anotherLine);
                                  setTalkOfficialdoc(dump);
                                }
                                return;
                              }}
                            />
                          )}
                          {index > 0 && (
                            <button
                              title="삭제"
                              disabled={watch("schedule.isAutoSend") === false}
                              onClick={(e: any) => {
                                e.preventDefault();
                                let dump = _.cloneDeep(talkOfficialdoc);
                                const finded = dump.find((bill: any, idx: number) => {
                                  if (idx === index && Number(bill.billContactId) === 0) {
                                    return bill;
                                  }
                                });

                                if (finded) {
                                  dump = dump.filter((bill: any, idx: number) => idx !== index);
                                } else {
                                  dump.forEach((bill: any, idx: number) => {
                                    if (idx === index && Number(bill.billContactId) !== 0) {
                                      bill.isDeleted = true;
                                    }
                                  });
                                }

                                setTalkOfficialdoc(dump);

                                return;
                              }}
                              className="base-erase-btn size-large ml10"
                            />
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                );
              }
            })}
        </div>
      </article>

      {watch("schedule.contractPayType") !== "PAY" && (
        <article className="contents-container__divide-top">
          <div className="contents-container__1200">
            <div className="contents-container__sub-title">
              <h2>세금계산서 수신</h2>
            </div>

            {taxInVoice.map((billContact: BillContactEdit, index: number) => {
              if (billContact.isDeleted === false) {
                return (
                  <div className="flex-center" key={index}>
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p className={"required"}>세금계산서 수신 이메일</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="minmax240 flex-row flex-center-start">
                          <BaseInput
                            readonly={
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE" ||
                              watch("schedule.contractPayType") === "PAY"
                            }
                            name={`billContact.email${index}`}
                            value={billContact.email}
                            onChange={(value: string) => {
                              const dump = _.cloneDeep(taxInVoice);
                              const adds = dump.map((talk: any, idx: number) => {
                                if (idx === index) {
                                  talk.errorTextEmail = "";
                                  talk.email = value;
                                }
                                return talk;
                              });
                              setTaxInVoice(adds);
                            }}
                            errorText={billContact.errorTextEmail}
                          />
                          {index === 0 && (
                            <button
                              disabled={watch("schedule.contractPayType") === "PAY"}
                              className="base-add-btn ml10"
                              onClick={(e: any) => {
                                e.preventDefault();
                                const dump = _.cloneDeep(taxInVoice);
                                const filterd = dump.filter(
                                  (bill: any) => bill.isDeleted === false,
                                );

                                if (filterd.length < 3) {
                                  const anotherLine = {
                                    billContactId: 0,
                                    contractId: contractDetail?.contractId,
                                    receiveNotiType: "TAX_INVOICE",
                                    contact: "",
                                    email: "",
                                    description: "",
                                    isDeleted: false,
                                    errorText: "",
                                  };

                                  dump.push(anotherLine);
                                  setTaxInVoice(dump);
                                }
                                return;
                              }}
                            />
                          )}
                          {index > 0 && (
                            <button
                              title="삭제"
                              disabled={watch("schedule.contractPayType") === "PAY"}
                              onClick={(e: any) => {
                                e.preventDefault();
                                let dump = _.cloneDeep(taxInVoice);
                                const finded = dump.find((bill: any, idx: number) => {
                                  if (idx === index && Number(bill.billContactId) === 0) {
                                    return bill;
                                  }
                                });
                                if (finded) {
                                  dump = dump.filter((bill: any, idx: number) => idx !== index);
                                } else {
                                  dump.forEach((bill: any, idx: number) => {
                                    if (idx === index && Number(bill.billContactId) !== 0) {
                                      bill.isDeleted = true;
                                    }
                                  });
                                }
                                setTaxInVoice(dump);

                                return;
                              }}
                              className="base-erase-btn size-large ml10"
                            />
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                );
              }
            })}
          </div>
        </article>
      )}
      <BaseModal isOpen={isError} btnRightTitle="확인" onClick={() => setIsError(false)}>
        <div>
          <p>{title}</p>
          <p>{message}</p>
        </div>
      </BaseModal>
    </>
  );
};

export default FOType;
