import React from "react";
import { ProductModel } from "src/api/product/product-types";
import { MetaData } from "src/api/public-types";

type Props = {
  product: ProductModel | null;
};

/* 
  공간상품 > 상세 > 시설/서비스 > 화장실 컴포넌트
*/
const RestroomsSection = ({ product }: Props) => {
  const productToiletList: Array<MetaData> = (product?.productToiletList || []).sort(
    (a: MetaData, b: MetaData) => Number(a.orderNums || 0) - Number(b.orderNums || 0),
  );
  return (
    <section className="contents-container__grid contents-container__1200">
      <div className="contents-container__grid-index">
        <p className="">화장실</p>
      </div>

      <div className="contents-container__grid-contents">
        <table className="inner-table" width="100%">
          <thead>
            <tr>
              <th>
                <span>위치</span>
              </th>
              <th>
                <span>구분</span>
              </th>
              <th>
                <span>설명</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {productToiletList.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <div className="w-100 text-center">
                    <span>데이터가 없습니다.</span>
                  </div>
                </td>
              </tr>
            )}
            {productToiletList.map((metaData: MetaData, index: number) => {
              return (
                <tr key={index}>
                  <td width="120">
                    <div className="flex-center-center">
                      {metaData.metaItem === "PRODUCT_TOILET_1" ||
                      metaData.metaItem === "PRODUCT_TOILET_3"
                        ? "실내"
                        : "실외"}
                    </div>
                  </td>
                  <td width="220">
                    <div className="flex-center-center">
                      {metaData.metaItem === "PRODUCT_TOILET_1" ||
                      metaData.metaItem === "PRODUCT_TOILET_2"
                        ? "남녀 분리"
                        : "남녀 통합"}
                    </div>
                  </td>
                  <td>
                    <div className="w-100 px16">{metaData?.value1 || ""}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};
export default RestroomsSection;
