import { Link } from "react-router-dom";
import PagePath from "src/pagePath.json";

const roomsByOwnerListColumns:any = [
  {
    Header: "id",
    accessor: "buildingId",
    // sticky: "left",
    width: 200,
    Cell: ({ value }: any) => {
      const path =
        PagePath.building.detail.replace(":id", value)
      return (
        <>
          <Link to={path} target="_blank" className="text-hilight">
            {value}
          </Link>
        </>
      );
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 270,
    Cell: ({ value }: any) => {
      return (
        <>
            {value}
        </>
      );
    },
  },
  {
    Header: "층",
    accessor: "floorNum",
    width: 235,
    Cell: ({value}: any) => {
      return(
      <span>
        {`${value > 0 ? "지상" : "지하"} ${Math.abs(value)}층`}
      </span>  
      )
    },
  },
  {
    Header: "호실",
    accessor: "roomNum",
    width: 235,
    Cell: ({ value }: any) => {
      return (
        <>
        {value}
        </>
      );
    },
  },
];
export default roomsByOwnerListColumns;
