import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { BaseInput, BaseModal, BaseRadio } from "src/components";

type Props = {
  overdueChargeStandard: string;
  overdueList: any;
  isOpen: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const OverdueFeePopup = (props: Props) => {
  const [type, setType] = useState("");

  const [single, setSingle] = useState<any>([]);
  const [period, setPeriod] = useState<any>([]);

  const clickBtn = useCallback(() => {
    let check: any = [];
    if (type === "PERIOD") {
      check = period.filter((pe: any) => pe.overdueRates !== "" && pe.overdueDays !== "");
    } else {
      check = period.filter(
        (pe: any) => pe.overdueRates !== "" && pe.overdueDays !== "" && pe.overdueId !== "",
      );
    }

    if (props.onClick) {
      const rtn: any = { overdueChargeStandard: type, overdueList: [...single, ...check] };
      props.onClick(rtn);
    }
  }, [period, props, single, type]);

  useEffect(() => {
    if (props.isOpen && props.overdueList) {
      setType(props.overdueChargeStandard);

      const sg: any = props.overdueList.filter(
        (overdue: any) => overdue.overdueChargeStandard === "SINGLE",
      );
      const pr: any = props.overdueList.filter(
        (overdue: any) => overdue.overdueChargeStandard !== "SINGLE",
      );
      setSingle(sg);
      setPeriod(pr);
      // console.log(sg, pr);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div>
      <BaseModal
        className="dialog-modal"
        isOpen={props.isOpen}
        btnRightTitle="확인"
        onClick={() => clickBtn()}
      >
        <>
          <div className="modal-title">
            <div className="left-area align-items-center">
              <span>연체 요율 관리</span>
            </div>
            <div className="right-area"></div>
          </div>
          <article className="contents-container__divide-top">
            <div className="contents-scroll">
              <section className="flex-center-start mb20">
                <div className="minmax140">
                  <p className="text-primary3">부과기준</p>
                </div>

                <div className="flex-row flex-center-start">
                  <BaseRadio
                    id="temp"
                    name="tempName"
                    label="일할"
                    value="SINGLE"
                    className="mr10"
                    checked={type === "SINGLE" ? true : false}
                    onChange={(value: boolean) => {
                      if (value) {
                        setType("SINGLE");
                      }
                    }}
                    disabled={true}
                  />
                  <BaseRadio
                    id="temp2"
                    name="tempName"
                    label="기간별"
                    value="PERIOD"
                    checked={type === "PERIOD" ? true : false}
                    onChange={(value: boolean) => {
                      if (value) {
                        setType("PERIOD");
                        if (period.length === 0) {
                          const temp = _.cloneDeep(period);

                          const addObj = {
                            overdueChargeStandard: "PERIOD",
                            overdueDays: "",
                            overdueId: "",
                            overdueRates: "",
                            scheduleId: single[0].scheduleId,
                          };
                          temp.push(addObj);

                          setPeriod(temp);
                        }
                      }
                    }}
                    disabled={true}
                  />
                </div>
              </section>

              <section className="flex-center-start mb20">
                <div className="minmax140">
                  <p className="text-primary3">연체 요율(%)</p>
                </div>

                <div className="minmax140">
                  {type === "SINGLE" && (
                    <BaseInput
                      type="number"
                      value={single[0].overdueRates}
                      onChange={(value: string) => {
                        const temp: any = _.cloneDeep(single);
                        temp[0].overdueRates = value;
                        setSingle(temp);
                      }}
                      readonly={true}
                    />
                  )}
                  {type === "PERIOD" &&
                    period &&
                    period.length > 0 &&
                    period.map((of: any, index: number) => {
                      if (of.isDeleted === false) {
                        return (
                          <div className="flex-center" key={index}>
                            <div className="minmax120">
                              <BaseInput
                                type="number"
                                maxLength={365}
                                readonly={true}
                                name={"overdueDays_" + index}
                                value={String(of?.overdueDays)}
                                onChange={(value: string) => {
                                  const temp: any = _.cloneDeep(period);

                                  const newTemp: any = temp.map((overdue: any, idx: number) => {
                                    if (index === idx) {
                                      overdue.overdueDays = value;
                                    }
                                    return overdue;
                                  });
                                  setPeriod(newTemp);
                                }}
                              />
                            </div>
                            <div className="minmax130 ml5">
                              <BaseInput
                                type="number"
                                readonly={true}
                                name={"overdueRates_" + index}
                                value={String(of?.overdueRates)}
                                onChange={(value: string) => {
                                  const temp: any = _.cloneDeep(period);
                                  const newTemp: any = temp.map((overdue: any, idx: number) => {
                                    if (index === idx) {
                                      overdue.overdueRates = value;
                                    }
                                    return overdue;
                                  });
                                  setPeriod(newTemp);
                                }}
                              />
                            </div>
                            {index === 0 && (
                              <button
                                className="base-add-btn ml5"
                                onClick={() => {
                                  const temp = _.cloneDeep(period);

                                  const addObj = {
                                    overdueChargeStandard: "PERIOD",
                                    overdueDays: "",
                                    overdueId: "",
                                    overdueRates: "",
                                    scheduleId: single[0].scheduleId,
                                  };
                                  temp.push(addObj);

                                  setPeriod(temp);
                                }}
                                // disabled={true}
                              />
                            )}
                            {index !== 0 && (
                              <button
                                className="base-erase-btn ml5"
                                onClick={() => {
                                  const temp = _.cloneDeep(period);

                                  const newTemp = temp.filter(
                                    (obj: any, idx: number) => idx !== index,
                                  );

                                  setPeriod(newTemp);
                                }}
                                disabled={true}
                              />
                            )}
                          </div>
                        );
                      }
                    })}
                </div>
              </section>
            </div>
          </article>
        </>
      </BaseModal>
    </div>
  );
};

export default OverdueFeePopup;
