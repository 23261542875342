
const TextWithBtn = ({ title, value, btnTitle, onClick }: any) => {
  return (
    <>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>{title} </p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax240 flex-center flex-row justify-contents-start">
            <span className="mr20">{value}</span>
            {/* <BaseButton title={btnTitle} className="color-white" onClick={onClick} /> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default TextWithBtn;
