import React from "react";

interface BaseButtonState {
  title: string;
  onClick?: Function;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

export const BaseButton = (props: BaseButtonState) => {
  const onPropsClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <>
      <button
        disabled={props.disabled ? Boolean(props.disabled) : false}
        type={props?.type || "button"}
        className={`base-btn ${props.className ? props.className : ""}`}
        onClick={onPropsClick}
      >
        {props.title}
      </button>
    </>
  );
};
