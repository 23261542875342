import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApiOperation from "src/api/hooks/api-operation";
import { getProductDetailAsync } from "src/api/product/product-api";
import { ProductModel } from "src/api/product/product-types";
import { ContentsTitle, LoadingBar } from "src/components";
import PagePath from "src/pagePath.json";
import OnlyNormalProductAllow from "./components/OnlyNormalProductAllow";
import { BasicInfoDetail, FacilityDetail, GuideDetail, ProductInfoDetail } from "./details";
import ProductAdminMemo from "./details/adminMemo/ProductAdminMemo";
import CommonSpace from "./details/commonSpace/CommonSpace";
import ProductImagesDetail from "./details/productImages/ProductImagesDetail";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "productInfo", label: "상품정보" },
  { value: "facility", label: "시설/서비스" },
  { value: "commonSpace", label: "공용공간" },
  { value: "image", label: "이미지" },
  { value: "guide", label: "이용안내" },
  // { value: "oldGuide", label: "안내 (old)" },
  // { value: "memo", label: "관리자 메모" },
];

/* 
  공간상품 > 상세
 */
const ProductDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [product, setProduct] = useState<ProductModel>();

  // 공간상품 상세 조회 api
  const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync);

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      // 기존 old newQueryParamStr
      // const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      const newQueryParamStr = `tab=${newQueryParams.tab}`;

      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "product") {
        // 공간상품 목록으로 이동
        navigate(PagePath.product.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "product", label: "공간상품" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  const urlParams = useParams();

  useEffect(() => {
    (async () => {
      if (!urlParams.id) return;
      // if (!(activeTab?.value === "basicInfo" || activeTab?.value === "productInfo")) return;

      const result = await getProductDetail({ productId: Number(urlParams.id) });

      if (result.status >= 200 && result.status < 300) {
        setProduct(result.data.data.content);
      }
    })();
  }, [queryParams]);

  if (!product) return <LoadingBar visible={true} />;

  return (
    <div className="page-product-access">
      <ContentsTitle
        title="공간상품"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />
      {/* 탭 */}
      <div className="contents-container__scroll">
        {!(
          activeTab?.value === "basicInfo" ||
          activeTab?.value === "productInfo" ||
          activeTab?.value === "memo"
        ) && product?.productCategory === "PRODUCT_CATEGORY_MANAGEMENT" ? (
          <OnlyNormalProductAllow />
        ) : (
          <div>
            {/* 기본정보 */}
            {activeTab?.value === "basicInfo" && <BasicInfoDetail product={product!} />}
            {/* 상품정보 */}
            {activeTab?.value === "productInfo" && <ProductInfoDetail product={product!} />}
            {/* 시설/서비스 */}
            {activeTab?.value === "facility" && <FacilityDetail productDetail={product!} />}
            {/* 공용공간 */}
            {activeTab?.value === "commonSpace" && <CommonSpace product={product!} />}
            {/* 이미지 */}
            {activeTab?.value === "image" && <ProductImagesDetail />}
            {/* 이용안내 */}
            {activeTab?.value === "guide" && <GuideDetail productDetail={product!} />}
            {/* 안내 (구버전)*/}
            {/* {activeTab?.value === "oldGuide" && <OldGuideDetail productDetail={product!} />} */}
            {/* 관리자메모 */}
            {/* {activeTab?.value === "memo" && <ProductAdminMemo />} */}
          </div>
        )}
      </div>
    </div>
  );
};
export default ProductDetail;
