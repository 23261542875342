import React, { Dispatch, SetStateAction, useState } from "react";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { Link } from "react-router-dom";
import { ProviderAddModel, ProviderPayletterModel } from "src/api/provider/provider-types";
import { BaseInput, BaseToggle } from "src/components";
import AutoPayletterDetail from "./AutoPayletterDetail";
import BasicPayletterDetail from "./BasicPayletterDetail";

type Props = { providerPayletter?: ProviderPayletterModel };

const ProviderPayletterDetail = ({ providerPayletter }: Props) => {
  return (
    <article className="contents-container__divide-top">
      <div className="contents-container__sub-title flex-column">
        <div className="flex-center">
          <h2>카드결제 지원</h2>
        </div>
      </div>
      {providerPayletter?.clientId ? (
        <section className="contents-container__1200">
          <BasicPayletterDetail providerPayletter={providerPayletter} />
          <AutoPayletterDetail providerPayletter={providerPayletter} />
        </section>
      ) : (
        <p className="font14">입력된 정보가 없습니다</p>
      )}
    </article>
  );
};

export default ProviderPayletterDetail;
