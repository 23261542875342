import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MgmtOfficeModel } from "src/api/building/building-types";
import {
  deleteBuildingManagementAsync,
  getBuildingManagementAsync,
} from "src/api/building/management-api";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseModal, ContentsIdSection } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import MgmtOfficerSectionDetail from "./components/MgmtOfficerSectionDetail";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import MgmtContactSectionDetail from "./components/MgmtContactSectionDetail.tsx";

const MgmtOfficeDetail = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();

  const navigate = useNavigate();
  const params = useParams();

  const buildingId = useMemo(() => {
    return params.id;
  }, [params.id]);

  const [deleteModal, setDeleteModal] = useState<Modal>({ isOpen: false });

  // 정산정보 상세
  const [providerDetail, setProviderDetail] = useState<ProviderModel>();

  // 정산정보 상세 api
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  // 관리처 상세
  const [managementDetail, setManagementDetail] = useState<MgmtOfficeModel>();

  // 관리처 관리 상세
  const { executeAsync: getBuildingManagement } = useApiOperation(getBuildingManagementAsync);

  // 관리처 삭제
  const { executeAsync: deleteBuildingManagement } = useApiOperation(deleteBuildingManagementAsync);

  // 정산정보 상세 불러오기
  const getProviderDetailApi = async (providerId: string) => {
    if (providerId) {
      setLoadingBar(true);
      const response = await getProviderDetail({ providerId: providerId });
      if (response.status >= 200 && response.status <= 299) {
        setProviderDetail(response.data.data.provider);
      }
    }
    setLoadingBar(false);
  };
  // 관리처 관리 상세정보 api
  const getBuildingManagementApi = useCallback(
    async (id: string) => {
      setLoadingBar(true);

      const response = await getBuildingManagement({ buildingId: String(id) });

      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content;
        setManagementDetail(result);

        if (result.providerId) {
          await getProviderDetailApi(String(result.providerId));
        }
      }

      setLoadingBar(false);
    },
    [getBuildingManagement],
  );

  useEffect(() => {
    getBuildingManagementApi(String(buildingId));
  }, [buildingId, getBuildingManagementApi]);

  // 관리처관리 삭제 api
  const deleteBuildingManagementApi = useCallback(
    async (id: string) => {
      setLoadingBar(true);

      const response = await deleteBuildingManagement({ ids: [id] });

      if (response.status >= 200 && response.status <= 200) {
        setDeleteModal({ isOpen: false });
        await getBuildingManagementApi(String(buildingId));
      } else {
        const errorCode = response.data.meta.errorCode;
        const errorMessage = `현재 계약중인 건물의 관리처는 삭제 할 수 없습니다.`;
        if (errorCode === "ePR0517") {
          openErrorModal(errorMessage, errorCode);
        }
      }
      setLoadingBar(false);
    },
    [buildingId, deleteBuildingManagement, getBuildingManagementApi],
  );

  return (
    <div className="contents-container__wrap detail-basic-info">
      {!!managementDetail?.id ? (
        <div className="contents-container__wrap-contents ">
          <ContentsIdSection title="관리처" id={buildingId} />
          {/* 관리처 명*/}
          <article>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">관리처 명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span>{providerDetail && providerDetail.providerName}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">업무시간</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">
                    {managementDetail?.startTime} ~ {managementDetail?.endTime}
                  </span>
                </div>
              </div>
            </section>
          </article>
          {/* 연락처 */}
          <MgmtContactSectionDetail managementDetail={managementDetail || {}} />
          {/* 담당자 */}
          <MgmtOfficerSectionDetail managementDetail={managementDetail || {}} />
        </div>
      ) : (
        <h2 className="contents-container__wrap-contents ">등록된 관리처가 없습니다</h2>
      )}

      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            className="color-white size-large"
            onClick={() => navigate(PagePath.building.list)}
          />
        </div>
      </div>
      {deleteModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClick={() => {
            deleteBuildingManagementApi(String(managementDetail?.id));
            setDeleteModal({ isOpen: false });
          }}
          onClose={() => setDeleteModal({ isOpen: false })}
        >
          {deleteModal.message}
        </BaseModal>
      )}
    </div>
  );
};

export default MgmtOfficeDetail;
