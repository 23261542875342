import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useApiOperation } from "src/api/hooks";
import {
  getVisitorAccessAsync,
  getVisitorCardAsync,
  putVisitorSyncAsync,
  postVisitorCardAsync,
  getVisitorEventsAsync,
  putVisitorActiveAsync,
  putVisitorDeactiveAsync,
} from "src/api/access/ac-api";
import { BaseButton, BaseModal, BaseToggle, ContentsIdSection } from "src/components";
import {
  CardModel,
  UserAccessModel,
  UserAccessGroupModel,
  UserCardModel,
  EventModel,
} from "src/api/access/ac-types";
import { useLoadingBarContext, useToastContext } from "src/pages/hooks";
import { UserEventColumns } from "src/pages/member/detail/components/accessInfo/columns/UserEventColumns";
import { Modal } from "src/pages/building/building-types";
import moment from "moment";
import { PagePath } from "src/pages/product/details";
import AccessGroupDetailPopup from "src/pages/product/components/AccessGroupDetailPopup";
import VisitorEventListModal from "./components/VisitorEventListModal";

const VisitorAccessInfo = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 토스트
  const { openToast } = useToastContext();

  // 네비게이션
  const navigate = useNavigate();
  const location = useLocation();

  // 파라미터
  const params = useParams();

  // params 에서 visitorId 추출
  const visitorId = useMemo(() => {
    return Number(params.id);
  }, [params]);

  // 방문자액세스 정보
  const [userAccess, setUserAccess] = useState<UserAccessModel | undefined>();

  // CSN 상세
  const [userCard, setUserCard] = useState<CardModel | undefined>();

  // 출입 내역 목록
  const [events, setEvents] = useState<EventModel[]>([]);

  // 방문자액세스 정보 api hook
  const { executeAsync: getUserAccess } = useApiOperation(getVisitorAccessAsync);

  // CSN 상세 api hook
  const { executeAsync: getUserCard } = useApiOperation(getVisitorCardAsync);

  // 권한 업데이트 api hook
  const { executeAsync: syncUserAccess } = useApiOperation(putVisitorSyncAsync);

  // CNS 재발급 api hook
  const { executeAsync: renewUserCard } = useApiOperation(postVisitorCardAsync);

  // 출입 내역 목록 api hook
  const { executeAsync: getEvents } = useApiOperation(getVisitorEventsAsync);

  // 방문자액세스 활성화
  const { executeAsync: activeUser } = useApiOperation(putVisitorActiveAsync);

  // 방문자액세스 비활성화
  const { executeAsync: deactiveUser } = useApiOperation(putVisitorDeactiveAsync);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 출입내역 팝업
  const [showEventModal, setShowEventModal] = useState<boolean>(false);

  // Modal 확인 버튼 클릭
  const clickModalConfirm = async () => {
    // 모달 닫기
    setConfirmModal({ isOpen: false });

    // 권한 업데이트
    if (confirmModal.type === "SYNC_USER") {
      await syncUserAccess({ visitorId });
      openToast(`정상적으로 업데이트 되었습니다.`);
      await fetchUserAccess(visitorId);
    }

    // CSN 재발급
    if (confirmModal.type === "RENEW_CARD") {
      await renewUserCard({ visitorId });
      openToast(`정상적으로 재발급 되었습니다.`);
      await fetchUserAccess(visitorId);
      await fetchUserCard(visitorId);
    }

    // 상태 변경
    if (confirmModal.type === "TOGGLE_DISABLED") {
      let disabled = confirmModal.payload as boolean;
      if (disabled) {
        await deactiveUser({ visitorId });
        openToast(`비활성화 되었습니다`);
      } else {
        await activeUser({ visitorId });
        openToast(`활성화 되었습니다`);
      }

      await fetchUserAccess(visitorId);
    }
  };

  // 출입내역 테이블
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns: UserEventColumns,
      data: events,
      initialState: { pageSize: 1000 },
    },
    useBlockLayout,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const fetchUserAccess = useCallback(
    async (visitorId: number) => {
      setLoadingBar(true);
      const { data } = await getUserAccess({ visitorId });
      setUserAccess(data?.data?.user || null);
      setLoadingBar(false);
    },
    [getUserAccess, setLoadingBar],
  );

  const fetchUserCard = useCallback(
    async (visitorId: number) => {
      setLoadingBar(true);
      const { data } = await getUserCard({ visitorId });
      setUserCard(data?.data?.qr || null);
      setLoadingBar(false);
    },
    [getUserCard, setLoadingBar],
  );

  const fetchUserEvents = useCallback(
    async (visitorId: number) => {
      setLoadingBar(true);
      const { data } = await getEvents({ visitorId, page: 0, size: 10 });
      setEvents(data?.data?.rows || null);
      setLoadingBar(false);
    },
    [getEvents, setLoadingBar],
  );

  // 출입그룹 상세 모달
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);

  // 출입그룹 상세 키값
  const [accessGroupId, setAccessGroupId] = useState(0);
  const [apiServerId, setApiServerId] = useState(0);

  useEffect(() => {
    if (visitorId) {
      fetchUserAccess(visitorId);
      fetchUserCard(visitorId);
      fetchUserEvents(visitorId);
    }
  }, [fetchUserAccess, fetchUserCard, fetchUserEvents, visitorId]);

  return (
    <div className="contents-container__wrap detail-usage-restriction">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection title="액세스 권한" id={visitorId} noTitle="방문자번호" no={visitorId} />
        <section>
          <div className="contents-container__grid mb10">
            <div className="contents-container__grid-index">
              <p>CSN</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <BaseButton
                  title="권한 업데이트"
                  className="color-block"
                  onClick={() => {
                    setConfirmModal({
                      isOpen: true,
                      message: "정말 업데이트 하시겠습니까?",
                      type: "SYNC_USER",
                      payload: "MANUAL",
                    });
                  }}
                />
                {userAccess && (
                  <BaseButton
                    title="CSN 재발급"
                    className="color-block ml10"
                    onClick={() => {
                      setConfirmModal({
                        isOpen: true,
                        message: "정말 업데이트 하시겠습니까?",
                        type: "RENEW_CARD",
                        payload: "MANUAL",
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="contents-container__grid mb10">
            <div className="contents-container__grid-index">
              <p>CSN 생성일시</p>
            </div>
            <div className="contents-container__grid-contents">
              {userAccess && <p>{moment(userCard?.creationDate).format("YYYY-MM-DD HH:mm:ss")}</p>}
            </div>
          </div>
        </section>
        {/* BS2 사용자 정보 */}
        <section className="contents-container__divide-top">
          <div className="contents-container__sub-title">
            <h2>BS2 사용자 정보</h2>
          </div>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>UID</p>
            </div>
            <div className="contents-container__grid-contents">
              <p>{userAccess?.id || "-"}</p>
            </div>
          </div>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>CSN</p>
            </div>
            <div className="contents-container__grid-contents">
              {userAccess?.cards.map((item: UserCardModel) => {
                return <p key={item.id}>{item.cardSerialNumber}</p>;
              })}
            </div>
          </div>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>출입그룹</p>
            </div>
            <div className="contents-container__grid-contents">
              <table className="inner-table" width="600">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>출입그룹명</th>
                    <th>상세정보</th>
                    <th>BS링크</th>
                  </tr>
                </thead>
                <tbody>
                  {userAccess?.accessGroups.map((item: UserAccessGroupModel, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="flex-center-center">{item.accessGroupId}</div>
                        </td>
                        <td>
                          <div className="flex-center-center">{item.accessGroupName}</div>
                        </td>
                        <td className="flex-center-center">
                          <BaseButton
                            title="상세 정보"
                            className="color-white"
                            onClick={() => {
                              setAccessGroupId(item.accessGroupId);
                              setApiServerId(item.apiServerId);
                              setShowDetailModal(true);
                            }}
                          />
                        </td>
                        <td>
                          <div className="flex-center-center">
                            <a
                              href={
                                item.apiServerEndpoint +
                                `/#/access-control/ac-group/detail/` +
                                item.accessGroupId
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-hilight"
                            >
                              BS2 링크
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>출입기간</p>
            </div>
            <div className="contents-container__grid-contents">
              {userAccess && (
                <>
                  <p>
                    {moment(userAccess?.startDatetime).format("YYYY-MM-DD HH:mm:ss")}
                    <span> ~ </span>
                    {moment(userAccess?.expiryDatetime).format("YYYY-MM-DD HH:mm:ss")}
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>상태</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                {userAccess && (
                  <BaseToggle
                    checked={!userAccess?.disabled}
                    onChange={(value: boolean) => {
                      if (value) {
                        setConfirmModal({
                          isOpen: true,
                          message: "정말 활성 처리하시겠습니까?",
                          type: "TOGGLE_DISABLED",
                          payload: false,
                        });
                      } else {
                        setConfirmModal({
                          isOpen: true,
                          message: "정말 비활성 처리하시겠습니까?",
                          type: "TOGGLE_DISABLED",
                          payload: true,
                        });
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
        {/* 출입 내역(최근10건) */}
        <section className="contents-container__divide-top">
          <div className="contents-container__sub-title">
            <h2>출입 내역(최근10건)</h2>
            <BaseButton
              title="전체 출입 내역"
              className="color-white"
              onClick={() => setShowEventModal(true)}
            />
            {visitorId && (
              <VisitorEventListModal
                visitorId={visitorId}
                showModal={showEventModal}
                setShowModal={setShowEventModal}
              />
            )}
          </div>
          <div className="mt20 inner-tab-table">
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                          <div className="ic_sort"></div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {page.map((row: any) => {
                  prepareRow(row);

                  return (
                    <div
                      {...row.getRowProps()}
                      className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            onClick={() => navigate(PagePath.member.list)}
            className="color-white size-large"
          />
        </div>
        <div className="right-area">{/* <BaseButton title="수정" className="size-large" /> */}</div>
      </div>

      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
      >
        <p>{confirmModal.message}</p>
      </BaseModal>

      {/* 확인버튼만 있는 alert 모달 */}
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => setAlertModal({ isOpen: false })}
      >
        <p>{alertModal.message}</p>
      </BaseModal>

      <AccessGroupDetailPopup
        showModal={showDetailModal}
        setShowModal={setShowDetailModal}
        accessGroupId={accessGroupId}
        apiServerId={apiServerId}
      />
    </div>
  );
};

export default VisitorAccessInfo;
