import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { AccessDeviceModel } from "src/api/access/ac2-types";
import { BaseTooltip } from "src/components";
import { PagePath } from "src/pages/product/details";

const AccessGroupListColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: (props: Cell<AccessDeviceModel>) => {
      const path = PagePath.accessGroup.detail.replace(":id", props.value);
      return (
        <Link to={path} className="text-hilight">
          {props.value}
        </Link>
      );
    },
  },
  {
    Header: "출입그룹 이름",
    accessor: "accessGroupName",
    width: 220,
    Cell: (props: Cell<AccessDeviceModel>) => {
      return <div className="w-100 text-left"><p>{props.value}</p></div>;
    },
  },
  {
    Header: "장치 수",
    accessor: "devices",
    width: 120,
    Cell: (props: Cell<AccessDeviceModel>) => <>{props.value.length}</>,
  },
  {
    Header: "방문자 허용 여부",
    accessor: "visitorAccessYn",
    width: 160,
    Cell: (props: Cell<AccessDeviceModel>) => {
      return <>{props.value ? "O" : "X"}</>;
    },
  },
  {
    Header: "생성일",
    accessor: "createdDate",
    width: 160,
    Cell: (props: Cell<AccessDeviceModel>) => {
      return (
        <>
          <BaseTooltip contents={props.value} type={"date"} />
        </>
      );
    },
  },
  {
    Header: "최근수정일",
    accessor: "lastModifiedDate",
    width: 160,
    Cell: (props: Cell<AccessDeviceModel>) => {
      return (
        <>
          <BaseTooltip contents={props.value} type={"date"} />
        </>
      );
    },
  },
  {
    Header: "파트너",
    accessor: "partnerIds",
    width: 140,
    Cell: (props: Cell<AccessDeviceModel>) => {
      const partnerLength = props.value.length;
      const partnerId = props.value[0]
      const path = PagePath.partner.detail.replace(":id", partnerId)

      const renderPartner = () => {
        if (partnerLength === 1) {
          const partnerId = props.value[0]
          const path = PagePath.partner.detail.replace(":id", partnerId)
          return (
            <Link to={path} className="text-hilight">
              {props.value}
            </Link>
          );
        } else if (partnerLength > 1) {
          return (
            <>
              <Link to={path} target="_blank" className="text-hilight mr4">
                {props.value[0]}
              </Link>
              외 {partnerLength - 1}
            </>
          );
        } else {
          return <span>-</span>;
        }
      };
      return <>{renderPartner()}</>;
    },
  },
];

export default AccessGroupListColumns;
