import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getProductDetailAsync } from "src/api/product/product-api";
import { postProductfacilityAsync } from "src/api/product/product-facility-api";
import {
  ProductAirConditioner,
  ProductFacilityEditModel,
  ProductFreeInternet,
  ProductModel,
  ProductPrinter,
} from "src/api/product/product-types";
import { MetaData } from "src/api/public-types";
import { BaseButton, BaseModal, BaseTextarea, ContentsIdSection } from "src/components";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import {
  AirConditionerSection,
  FreeInternetSection,
  OptionalFacilitySection,
  PrinterSection,
  RestroomsSection,
} from "./components";
import {
  AirConditionerInfo,
  FacilityFormData,
  FreeInternet,
  Modal,
  OptionalFacility,
  PrinterOption,
  Restroom,
} from "./facility-types";

interface IProps {
  product: ProductModel;
}
/* 
  공간상품 > 등록 or 수정 > 시설/서비스
*/
const FacilityForm = ({ product: productDetail }: IProps) => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 토스트
  const { openToast } = useToastContext();

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 공간상품 id
  const productId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  // 공간상품 상세
  const [product, setProduct] = useState<ProductModel | null>(null);

  // 공간상품 상세 조회 api
  // const { executeAsync: getProduct } = useApiOperation(getProductDetailAsync);

  // 공간상품 시설/서비스 (등록/수정) api
  const { executeAsync: postProductfacility } = useApiOperation(postProductfacilityAsync);

  // 공간상품 상세조회 후 데이터 바인딩
  const fetchProduct = useCallback(
    async (productId: number) => {
      setLoadingBar(true);
      setProduct(null);
      setProduct(productDetail);
      setLoadingBar(false);
    },
    [setLoadingBar],
  );

  useEffect(() => {
    if (productId) {
      fetchProduct(productId);
    }
  }, [productId, fetchProduct]);

  // title 에 공간상품명 바인딩
  let productName;
  if (productId && product?.productName) {
    productName = product?.productName;
  }
  useTitleOperation(productName);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // form defualtValues
  const defaultValues: FacilityFormData = useMemo(() => {
    return {
      heatingType: "CENTRAL_AIR_CONDITIONER",
      heatingTypeDesc: "",
      isAirConditionerProvided: true,
      airConditionerDescription: "",
      airConditionerInfoList: [
        {
          metaItem: "PRODUCT_AIR_CONDITIONER_1",
          label: "천장형 시스템 냉난방기",
          checked: false,
          value: 0,
        },
        {
          metaItem: "PRODUCT_AIR_CONDITIONER_2",
          label: "냉난방기",
          checked: false,
          value: 0,
        },
        {
          metaItem: "PRODUCT_AIR_CONDITIONER_3",
          label: "에어컨",
          checked: false,
          value: 0,
        },
        {
          metaItem: "PRODUCT_AIR_CONDITIONER_4",
          label: "난방기",
          checked: false,
          value: 0,
        },
        {
          metaItem: "PRODUCT_AIR_CONDITIONER_5",
          label: "직접 입력",
          checked: false,
          value: "",
        },
      ],
      restrooms: [
        {
          seq: 1,
          location: "INDOOR",
          type: "SEPARATION",
          description: "",
        },
      ],
      isPrinterProvided: true,
      printerDescription: "",
      printerOptionList: [
        {
          metaItem: "PRODUCT_PRINTER_1",
          label: "출력",
          checked: false,
        },
        {
          metaItem: "PRODUCT_PRINTER_2",
          label: "복사",
          checked: false,
        },
        {
          metaItem: "PRODUCT_PRINTER_3",
          label: "스캔",
          checked: false,
        },
        {
          metaItem: "PRODUCT_PRINTER_4",
          label: "팩스",
          checked: false,
        },
      ],
      isFreeInternetProvided: true,
      freeInternetDescription: "",
      freeInternetOptionList: [
        {
          metaItem: "PRODUCT_FREE_INTERNET_1",
          label: "랜선",
          checked: false,
        },
        {
          metaItem: "PRODUCT_FREE_INTERNET_2",
          label: "와이파이",
          checked: false,
        },
      ],
      productOptionalFacilityList: [
        {
          metaItem: "PRODUCT_OPTIONAL_FACILITY_1",
          label: "공용 라운지",
          isProvided: true,
          description: "",
        },
        {
          metaItem: "PRODUCT_OPTIONAL_FACILITY_2",
          label: "택배/우편물 수령",
          isProvided: true,
          description: "",
        },
        {
          metaItem: "PRODUCT_OPTIONAL_FACILITY_3",
          label: "보안",
          isProvided: true,
          description: "",
        },
        {
          metaItem: "PRODUCT_OPTIONAL_FACILITY_4",
          label: "스낵",
          isProvided: true,
          description: "",
        },
        {
          metaItem: "PRODUCT_OPTIONAL_FACILITY_5",
          label: "인포데스크",
          isProvided: true,
          description: "",
        },
      ],
      etcService: "",
    };
  }, []);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm<FacilityFormData>({
    defaultValues,
  });

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    // 공조기 난방타입 직접입력 값
    register("heatingTypeDesc", {
      validate: {
        // 직접입력 선택시 직접입력 값 입력했는지 확인
        directRequired: (v?: string) => {
          let result = true;
          const message = "난방타입 직접입력 값을 입력해주세요.";
          if (getValues("heatingType") === "DIRECT_HEATING_AIR_CONDITIONER") {
            result = !!v;
          }
          return result || message;
        },
      },
    });
    // 공조기 기기정보
    register("airConditionerInfoList", {
      validate: {
        // 공조기 제공시 공조기 기기정보 하나라도 선택과 입려되어있는지 확인
        allRequired: (v?: Array<AirConditionerInfo>) => {
          let result = true;
          let message = "";
          if (String(getValues("isAirConditionerProvided")) === "true") {
            if ((v || []).every((info: AirConditionerInfo) => !info.checked)) {
              result = false;
              message = "기기 정보를 하나 이상 선택해주세요.";
            } else {
              for (let i = 0; i < (v || []).length; i++) {
                const info: AirConditionerInfo = (v || [])[i];
                if (info.checked) {
                  if (!info.value) {
                    result = false;
                    message = `${info.label}의 값을 입력해주세요.`;
                    break;
                  }
                }
              }
            }
          }
          return result || message;
        },
      },
    });
    // 복합기 옵션
    register("printerOptionList", {
      validate: {
        // 복합기 제공시 옵션 하나라도 선택과 입려되어있는지 확인
        allRequired: (v?: Array<PrinterOption>) => {
          let result = true;
          let message = "";
          if (String(getValues("isPrinterProvided")) === "true") {
            if ((v || []).every((option: PrinterOption) => !option.checked)) {
              result = false;
              message = "옵션을 하나 이상 선택해주세요.";
            }
          }
          return result || message;
        },
      },
    });
    // 무료 인터넷 옵션
    register("freeInternetOptionList", {
      validate: {
        // 무료 인터넷  제공시 옵션 하나라도 선택과 입려되어있는지 확인
        allRequired: (v?: Array<FreeInternet>) => {
          let result = true;
          let message = "";
          if (String(getValues("isFreeInternetProvided")) === "true") {
            if ((v || []).every((option: FreeInternet) => !option.checked)) {
              result = false;
              message = "옵션을 하나 이상 선택해주세요.";
            }
          }
          return result || message;
        },
      },
    });
  }, [register, getValues]);

  // 수정시 api 에서 받아온 product 정보로 setValue 처리
  useEffect(() => {
    if (product) {
      Object.entries(product).forEach(([name, value]: any) => {
        if (name === "productAirConditioner") {
          // 공조기
          const obj: ProductAirConditioner = value || {
            heatingType: defaultValues.heatingType,
            heatingTypeDesc: defaultValues.heatingTypeDesc,
            isAirConditionerProvided: false,
            airConditionerInfoList: [],
            airConditionerDescription: "",
          };
          setValue(
            "heatingType",
            obj.heatingType === "HEATING_UNRECOGNIZED" // 정의되지 않은 타입 일 경우, 기본값으로
              ? defaultValues.heatingType
              : obj.heatingType,
          );
          setValue("heatingTypeDesc", obj.heatingTypeDesc);
          const isAirConditionerProvided: boolean = Boolean(
            String(obj?.isAirConditionerProvided) === "true",
          );
          const metaDatas: Array<MetaData> = obj?.airConditionerInfoList || [];
          if (metaDatas.length > 0) {
            // metaDatas 가 없으면 최초 등록이기 때문에 데이터 바인딩하지 않고, 있을 경우에만 바인딩
            setValue("isAirConditionerProvided", isAirConditionerProvided);
            const airConditionerInfoList: Array<AirConditionerInfo> =
              defaultValues.airConditionerInfoList.map((defaultValue: AirConditionerInfo) => {
                let airConditionerInfo: AirConditionerInfo = defaultValue;
                let findMetaData = metaDatas.find(
                  (metaData: MetaData) => metaData.metaItem === defaultValue.metaItem,
                );
                if (findMetaData) {
                  airConditionerInfo = {
                    id: findMetaData?.id,
                    metaItem: defaultValue.metaItem,
                    label: defaultValue.label,
                    checked: Boolean(String(findMetaData.value1) === "true"),
                    value: findMetaData?.value2,
                  };
                }
                return airConditionerInfo;
              });
            setValue("airConditionerInfoList", airConditionerInfoList);
            setValue("airConditionerDescription", obj?.airConditionerDescription);
          }
        } else if (name === "productToiletList") {
          // 화장실
          const productToiletList: Array<MetaData> = value || [];
          if (productToiletList.length > 0) {
            const restrooms: Array<Restroom> = productToiletList.map(
              (metaData: MetaData, index: number) => {
                const location =
                  metaData.metaItem === "PRODUCT_TOILET_1" ||
                  metaData.metaItem === "PRODUCT_TOILET_3"
                    ? "INDOOR"
                    : "OUTDOOR";
                const type =
                  metaData.metaItem === "PRODUCT_TOILET_1" ||
                  metaData.metaItem === "PRODUCT_TOILET_2"
                    ? "SEPARATION"
                    : "PUBLIC";
                const restroom: Restroom = {
                  seq: index,
                  id: metaData?.id,
                  location,
                  type,
                  description: metaData?.value1,
                };
                return restroom;
              },
            );
            setValue("restrooms", restrooms);
          }
        } else if (name === "productPrinter") {
          // 복합기
          const obj: ProductPrinter = value || {
            isPrinterProvided: false,
            printerOptionList: [],
            printerDescription: "",
          };

          const isPrinterProvided: boolean = Boolean(String(obj?.isPrinterProvided) === "true");
          const metaDatas: Array<MetaData> = obj?.printerOptionList || [];
          if (metaDatas.length > 0) {
            // metaDatas 가 없으면 최초 등록이기 때문에 데이터 바인딩하지 않고, 있을 경우에만 바인딩
            setValue("isPrinterProvided", isPrinterProvided);
            const printerOptionList: Array<PrinterOption> = defaultValues.printerOptionList.map(
              (defaultValue: PrinterOption) => {
                let printerOption: PrinterOption = defaultValue;
                let findMetaData = metaDatas.find(
                  (metaData: MetaData) => metaData.metaItem === defaultValue.metaItem,
                );
                if (findMetaData) {
                  printerOption = {
                    id: findMetaData?.id,
                    metaItem: defaultValue.metaItem,
                    label: defaultValue.label,
                    checked: Boolean(String(findMetaData.value1) === "true"),
                  };
                }
                return printerOption;
              },
            );
            setValue("printerOptionList", printerOptionList);
            setValue("printerDescription", obj?.printerDescription);
          }
        } else if (name === "productFreeInternet") {
          // 무료 인터넷
          const obj: ProductFreeInternet = value || {
            isFreeInternetProvided: false,
            freeInternetOptionList: [],
            freeInternetDescription: "",
          };

          const isFreeInternetProvided: boolean = Boolean(
            String(obj?.isFreeInternetProvided) === "true",
          );
          const metaDatas: Array<MetaData> = obj?.freeInternetOptionList || [];
          if (metaDatas.length > 0) {
            // metaDatas 가 없으면 최초 등록이기 때문에 데이터 바인딩하지 않고, 있을 경우에만 바인딩
            setValue("isFreeInternetProvided", isFreeInternetProvided);
            const freeInternetOptionList: Array<FreeInternet> =
              defaultValues.freeInternetOptionList.map((defaultValue: FreeInternet) => {
                let freeInternet: FreeInternet = defaultValue;
                let findMetaData = metaDatas.find(
                  (metaData: MetaData) => metaData.metaItem === defaultValue.metaItem,
                );
                if (findMetaData) {
                  freeInternet = {
                    id: findMetaData?.id,
                    metaItem: defaultValue.metaItem,
                    label: defaultValue.label,
                    checked: Boolean(String(findMetaData.value1) === "true"),
                  };
                }
                return freeInternet;
              });
            setValue("freeInternetOptionList", freeInternetOptionList);
            setValue("freeInternetDescription", obj?.freeInternetDescription);
          }
        } else if (name === "productOptionalFacilityList") {
          // 추가 시설/서비스
          const metaDatas: Array<MetaData> = value || [];
          if (metaDatas.length > 0) {
            const productOptionalFacilityList: Array<OptionalFacility> =
              defaultValues.productOptionalFacilityList.map((defaultValue: OptionalFacility) => {
                let optionalFacility: OptionalFacility = defaultValue;
                let findMetaData = metaDatas.find(
                  (metaData: MetaData) => metaData.metaItem === defaultValue.metaItem,
                );
                if (findMetaData) {
                  optionalFacility = {
                    id: findMetaData?.id,
                    metaItem: defaultValue.metaItem,
                    label: defaultValue.label,
                    isProvided: Boolean(String(findMetaData.value1) === "true"),
                    description: findMetaData?.value2,
                  };
                }
                return optionalFacility;
              });
            setValue("productOptionalFacilityList", productOptionalFacilityList);
          }
        } else {
          setValue(name, value);
        }
      });
    }
  }, [setValue, product, defaultValues]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: FacilityFormData, e?: any) => {
    e.preventDefault();
    setConfirmModal({ message: "저장하시겠습니까?", isOpen: true, payload: data });
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    // console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  // 공간상품 시설/서비스 저장 (등록/수정)
  const saveProductFacility = useCallback(
    async (submitData: FacilityFormData) => {
      try {
        if (!productId) throw Error("productId is not found.");

        setLoadingBar(true);

        // submitData 를 productFacilityEditModel 로 변환
        const productFacilityEditModel: ProductFacilityEditModel = {
          id: productId,
          productAirConditioner: {
            heatingType: submitData.heatingType,
            heatingTypeDesc: submitData.heatingTypeDesc,
            isAirConditionerProvided: submitData.isAirConditionerProvided,
            airConditionerDescription: submitData.airConditionerDescription,
            airConditionerInfoList: submitData.airConditionerInfoList.map(
              (info: AirConditionerInfo, index: number) => {
                const metaData: MetaData = {
                  id: info?.id,
                  metaGroup: "PRODUCT_AIR_CONDITIONER", // 공조기
                  metaItem: info.metaItem,
                  orderNums: index + 1,
                  value1: String(info.checked),
                  value2: info?.value ? String(info.value) : undefined,
                };
                return metaData;
              },
            ),
          },
          productToiletList: (submitData?.restrooms || []).map(
            (restroom: Restroom, index: number) => {
              let metaItem: string = "PRODUCT_TOILET_";
              if (restroom.location === "INDOOR") {
                metaItem += restroom.type === "SEPARATION" ? "1" : "3";
              } else if (restroom.location === "OUTDOOR") {
                metaItem += restroom.type === "SEPARATION" ? "2" : "4";
              }
              const metaData: MetaData = {
                id: restroom?.id,
                metaGroup: "PRODUCT_TOILET", // 공간상품 화장실
                metaItem,
                orderNums: index + 1,
                value1: restroom.description,
              };
              return metaData;
            },
          ),
          productPrinter: {
            isPrinterProvided: submitData.isPrinterProvided,
            printerDescription: submitData.printerDescription,
            printerOptionList: submitData.printerOptionList.map(
              (option: PrinterOption, index: number) => {
                const metaData: MetaData = {
                  id: option?.id,
                  metaGroup: "PRODUCT_PRINTER", // 복합기
                  metaItem: option.metaItem,
                  orderNums: index + 1,
                  value1: String(option.checked),
                };
                return metaData;
              },
            ),
          },
          productFreeInternet: {
            isFreeInternetProvided: submitData.isFreeInternetProvided,
            freeInternetDescription: submitData.freeInternetDescription,
            freeInternetOptionList: submitData.freeInternetOptionList.map(
              (option: FreeInternet, index: number) => {
                const metaData: MetaData = {
                  id: option?.id,
                  metaGroup: "PRODUCT_FREE_INTERNET", // 무료인터넷
                  metaItem: option.metaItem,
                  orderNums: index + 1,
                  value1: String(option.checked),
                };
                return metaData;
              },
            ),
          },
          productOptionalFacilityList: submitData.productOptionalFacilityList.map(
            (option: OptionalFacility, index: number) => {
              const metaData: MetaData = {
                id: option?.id,
                metaGroup: "PRODUCT_OPTIONAL_FACILITY", // 추가시설/서비스
                metaItem: option.metaItem,
                orderNums: index + 1,
                value1: String(option.isProvided),
                value2: option.description,
              };
              return metaData;
            },
          ),
          etcService: submitData.etcService,
        };

        const { data: upsertResultData } = await postProductfacility({
          productId,
          productFacilityEditModel,
        });

        setLoadingBar(false);

        if (upsertResultData?.data?.content) {
          openToast("정상적으로 저장 되었습니다.");
          const detailPath = `${PagePath.product.detail.replace(
            ":id",
            `${productId}`,
          )}?tab=facility`;
          navigate(detailPath);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [productId, setLoadingBar, openToast, postProductfacility, navigate],
  );

  // Modal 확인 버튼 클릭
  const clickModalConfirm = useCallback(async () => {
    setConfirmModal({ isOpen: false });

    if (!confirmModal?.payload) throw Error("confirmModal?.payload is not found.");

    // 공간상품 시설/서비스 저장
    await saveProductFacility(confirmModal.payload);
  }, [confirmModal, saveProductFacility]);

  return (
    <div className="contents-container__wrap">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__wrap-contents">
          <ContentsIdSection title="시설/서비스" id={productId} />
          {/* 공조기 */}
          <AirConditionerSection
            control={control}
            getValues={getValues}
            setValue={setValue}
            defaultValues={defaultValues}
            errors={errors}
            trigger={trigger}
            dirtyFields={dirtyFields}
          />
          {/* 화장실 */}
          <RestroomsSection control={control} getValues={getValues} setValue={setValue} />
          {/* 복합기 */}
          <PrinterSection
            control={control}
            getValues={getValues}
            setValue={setValue}
            defaultValues={defaultValues}
            errors={errors}
            trigger={trigger}
            dirtyFields={dirtyFields}
          />
          {/* 무료 인터넷 */}
          <FreeInternetSection
            control={control}
            getValues={getValues}
            setValue={setValue}
            defaultValues={defaultValues}
            errors={errors}
            trigger={trigger}
            dirtyFields={dirtyFields}
          />
          {/* 추가 시설/서비스 */}
          <OptionalFacilitySection control={control} getValues={getValues} setValue={setValue} />
          {/* 기타 시설/서비스 */}
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>기타 시설/서비스</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <Controller
                  control={control}
                  name="etcService"
                  render={({ field: { onChange, value, name } }) => (
                    <BaseTextarea
                      name={name}
                      className="mr8"
                      placeholder=""
                      maxLength={1000}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </section>
        </div>
        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              className="color-white size-large"
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="right-area">
            <BaseButton type="submit" title="저장" className="size-large" />
          </div>
        </div>
      </form>

      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
      >
        <p>{confirmModal.message}</p>
      </BaseModal>
    </div>
  );
};
export default FacilityForm;
