import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getPrImageAsync } from "src/api/product/product-api";
import { MediaFile, MediaServiceType } from "src/api/public-types";
import { BaseButton, ContentsIdSection } from "src/components";
import { PagePath } from "..";
import { productMediaServiceType } from "../../product-types";
import ImageCategoryDetail from "./components/ImageCategoryDetail";

const mediaServiceTypes: MediaServiceType[] = productMediaServiceType;

const ProductImagesDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const productId: number | undefined = Number(params.id);

  //========= Space product ============
  const [images, setImages] = useState<Array<MediaFile>>([]);

  const { executeAsync: getProductImages } = useApiOperation(getPrImageAsync);

  // 이미지 불러오기
  const getProductImagesApi = useCallback(async (serviceId: number) => {
    const data = {
      serviceId,
      mediaServiceTypes: productMediaServiceType,
    };

    const response = await getProductImages(data);
    if (response.status >= 200 || response.status < 300) {
      const result = response.data.data.content;

      setImages(result || []);
    }
  }, []);

  useEffect(() => {
    if (productId) {
      getProductImagesApi(productId);
    }
  }, [getProductImagesApi, productId]);

  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection title="이미지" />
        {mediaServiceTypes &&
          mediaServiceTypes.map((type) => (
            <ImageCategoryDetail key={type} type={type} images={images} />
          ))}
        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              className="color-white size-large"
              onClick={() => navigate(PagePath.product.list)}
            />
          </div>
          <div className="right-area">
            {/* <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const formPath = `${PagePath.product.form}?id=${productId}${location.search.replace(
                  "?",
                  "&",
                )}`;
                navigate(formPath);
              }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductImagesDetail;
