import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SpaceProductType } from "src/api/contract/contract-types";
import { BaseButton, BaseCheckbox } from "src/components";
import pagePath from "src/pagePath.json";
import { getHours } from "src/pages/contract/contract-util";
import { findPgCode, formatPhoneNumber, numberToStringWithComma, YmdFormat } from "src/utils";
import TextNFileDownload from "./../modal/components/TextNFileDownload";
import ModifyAmountModal from "./../modal/ModifyAmountModal";
import SnapShotModal from "./../modal/SnapShotModal";

const FOType = ({
  basicInfo,
  organization,
  contract,
  proof,
  bi,
  document,
  require,
  spaceProductType,
}: any) => {
  const [textGrey, setTextGrey] = useState("contents-container__grid ");
  const [isSnapShotOpen, setIsSnapShotOpen] = useState(false);
  const [isModifyAmountOpen, setIsModifyAmountOpen] = useState(false);

  const goMemberDetail = (id: number) => {
    let path = "";
    if (id) {
      path = pagePath.member.detail.replace(":id", id!.toString());
    }
    return path;
  };

  const moveOrg = useCallback((id: number) => {
    let rtn = "";
    if (id) {
      rtn = pagePath.organization.detail.replace(":id", String(id));
    }
    return rtn;
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (spaceProductType === SpaceProductType.TIME_COURT) {
      setTextGrey("contents-container__grid not-available");
    } else {
      setTextGrey("contents-container__grid ");
    }
  }, []);
  // console.log("contract", contract);
  return (
    <>
      <article className="pb20">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>{"이용기간"}</h2>
          </div>
          {/* 이용 시작 */}

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>이용일</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {contract?.contractStartTime
                    ? moment(contract?.contractStartTime).format(YmdFormat.YYYY_MM_DD)
                    : "-"}
                </p>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>이용 시간</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {contract?.contractStartTime && contract?.contractEndTime
                    ? moment(contract?.contractStartTime).format("HH:mm") +
                      " ~ " +
                      moment(contract?.contractEndTime).format("HH:mm") +
                      " (" +
                      getHours(contract?.contractStartTime, contract?.contractEndTime) +
                      ")"
                    : "-"}
                </p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>빌링 기간</h2>
          </div>
          <div className="flex-center">
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>빌링 시작</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <p>N/A</p>
                </div>
              </div>
            </section>
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>빌링 종료</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <p>N/A</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>계약자 정보</h2>
          </div>
          <div className="flex-center">
            {/* 사업자 등록번호 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>회원 번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <Link
                    to={goMemberDetail(contract?.contractorMemberNo)}
                    className="text-hilight"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {contract?.contractorMemberNo}
                  </Link>
                </div>
              </div>
            </section>
            {/* 사업자 유형 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>휴대폰</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{formatPhoneNumber(contract?.contractorPhoneNumber)}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 사업자 등록번호 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>이메일</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{contract?.contractorEmail}</p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>사업자 정보</h2>
          </div>
          <div className="flex-center">
            {/* 사업자 등록번호 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>사업자 등록번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
            {/* 사업자 유형 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>사업자 유형</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 사업자 등록증 첨부 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>사업자 등록증 첨부</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
            {/* 법인명/상호 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>법인명/상호</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 사업장 주소 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>사업장 주소</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
            {/* 대표자/성명 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>대표자/성명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 과세 유형 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>과세 유형</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
            {/* 업태 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>업태</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>CI/BI 첨부(Taap출력용)</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
            {/* 종목 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>종목</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>계약 정보</h2>
          </div>
          <div className="flex-center">
            {/* 계약 번호 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>계약 번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
            {/* 재계약 상태 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>재계약 상태</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 계약 데이터 상세 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>계약 데이터 상세</p>
              </div>
              <div className="contents-container__grid-contents">
                {textGrey !== "" && (
                  <BaseButton
                    title="스냅샷 보기"
                    className="color-white"
                    disabled={
                      spaceProductType === "TIME_COURT" ||
                      basicInfo?.contractStep === "APPLY_RECEIVED" ||
                      basicInfo?.contractStep === "APPLY_CANCEL" ||
                      basicInfo?.contractStep === "APPLY_CONFIRM"
                    }
                    onClick={() => setIsSnapShotOpen(true)}
                  />
                )}
                <SnapShotModal
                  isOpen={isSnapShotOpen}
                  contractId={contract?.contractId}
                  onClose={() => {
                    setIsSnapShotOpen(false);
                  }}
                />
              </div>
            </section>
            {/* 계약금 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>이전 계약 정보</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 계약금(원) */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>계약금(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
            {/* 잔금 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>잔금(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
          </div>
          <div className="flex-start">
            {/* 계약서 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>계약서</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
            {/* 계약 필요 서류 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>계약 필요서류</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>이용료 결제 정보</h2>
          </div>
          <div className="flex-center">
            {/* 결제금액(원) */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>결제금액(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <span className="mr16">
                    {contract?.payAmount ? numberToStringWithComma(contract?.payAmount) : "-"}
                  </span>
                </div>
              </div>
            </section>
          </div>
        </div>
      </article>
    </>
  );
};

export default FOType;
