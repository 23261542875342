import React from "react";

type Props = {
  title?: string;
  tabs?: Array<Tab>;
  activeTab?: Tab;
  clickTab?: Function;
  breadCrumbs?: Array<BreadCrumb>;
  clickBreadCrumb?: Function;
  className?: string;
};

type Tab = {
  value: string;
  label: string;
};

type BreadCrumb = {
  value: string;
  label: string;
};

export const ContentsTitle = ({
  tabs,
  title,
  activeTab,
  clickTab,
  clickBreadCrumb,
  breadCrumbs,
  className,
}: Props) => {
  return (
    <div className={`contents-title ${className ? className : ""}`}>
      <div className="flex-center">
        <div className="contents-title__tab">
          {tabs?.map((tab: Tab, i: number) => {
            return (
              <div
                key={i}
                className={`${activeTab?.value === tab.value ? "active" : ""}`}
                onClick={() => clickTab && clickTab(tab)}
              >
                <span>{tab.label}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="contents-title__bread-crumbs">
        {breadCrumbs &&
          breadCrumbs.map((crumb: BreadCrumb, i: number) => (
            <span key={i} onClick={() => clickBreadCrumb && clickBreadCrumb(crumb)}>
              {crumb.label}
            </span>
          ))}
      </div>
    </div>
  );
};
