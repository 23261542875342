import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addContractApplication } from "src/api/contract/contract-api";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { getMemberDetail } from "src/api/member/member-api";
import { getProductDetailAsync } from "src/api/product/product-api";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { contract_bill_user_errors } from "src/api/public-types";
import { BaseButton, BaseDatePicker, BaseSelect } from "src/components";
import pagePath from "src/pagePath.json";
import SearchMemberPopup from "src/pages/commonPopup/SearchMemberPopup";
import SearchSpaceProductPopup from "src/pages/commonPopup/SearchSpaceProductPopup";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { numberToStringWithComma, YmdFormat } from "src/utils";

const ServiceType = ({
  basicInfo,
  application,
  setApplication,
  spaceProductType,
  rooms,
  memberInfo,
  setMemberInfo,
  spaceProduct,
  setSpaceProduct,
  productDetail,
  setProductDetail,
  saveData,
  setSaveData,
  providerDetail,
  setProviderDetail,
  register,
  control,
  getValues,
  setValue,
  selectedPeriodOption,
  setSelectedPeriodOptions,
  partner,
}: any) => {
  let id = 0;
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const location = useLocation();

  const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync, {
    doNotErrorHandleModal: true,
  });

  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  const [mflag, setMflag] = useState(false);
  const [pflag, setPflag] = useState(false);

  const [memberError, setMemberError] = useState("");
  const [productError, setProductError] = useState("");

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );

  if (queryParams.id) {
    id = Number(queryParams.id);
  }

  const goMemberDetail = (id: number) => {
    let path = "";
    if (id) {
      path = pagePath.member.detail.replace(":id", id!.toString());
    }
    return path;
  };
  const goProductDetail = (id: number) => {
    let path = "";
    if (id) {
      path = pagePath.product.detail.replace(":id", id!.toString());
    }
    return path;
  };

  const onChangeMember = useCallback(
    (member: any) => {
      setMemberInfo(member);
      if (member.organizations.length === 1) {
        setValue("contractManage.mbOrganizationId", member.organizations[0].id);
      }
      setValue("contract.applicantName", "");
      setValue("contract.applicantCorporation", "");
      setValue("contract.isAgreeTerms", true);
      setValue("contractMember.inviteMobileNumber", member.phoneNumber);
      setValue("contractMember.memberNo", member.memberNo);
      setMemberError("");
      setMflag(false);
    },
    [setMemberInfo, setValue],
  );

  const providerDetailApi = useCallback(
    async (id: string) => {
      setLoadingBar(true);

      const response: any = await getProviderDetail({ providerId: id });

      if (response.status >= 200 || response.status <= 299) {
        const result = response.data.data;
        setProviderDetail(result);
      }
      setLoadingBar(false);
    },
    [getProviderDetail, setLoadingBar, setProviderDetail],
  );

  // 공산상품 기본정보 디테일 api
  const getProductBasicDetail = useCallback(
    async (productId: number) => {
      if (productId) {
        const response: any = await getProductDetail({ productId });

        if (response.status >= 200 && response.status <= 299) {
          const result = response?.data?.data?.content;
          await providerDetailApi(result.providerId);
          const options: any = [];
          // console.log(result);
          // console.log(result.productType);
          if (result.productType === "FULL_COURT") {
            const min = Number(result.minLeasePeriod);
            const max = Number(result.maxLeasePeriod);
            for (let i = min; i <= max; i++) {
              const option = { label: i + "개월", value: i };
              options.push(option);
            }
          }
          if (result.productType === "OPEN_COURT") {
            const min = Number(result.minLeasePeriod);
            const max = Number(result.maxLeasePeriod);
            for (let i = min; i <= max; i++) {
              const option = { label: i + "개월", value: i };
              options.push(option);
            }
          }

          setSelectedPeriodOptions(options);

          setProductDetail(result);
        } else {
          let message = errorMessageHandler(
            response.status,
            response.data.meta.errorCode,
            contract_bill_user_errors,
          );
          let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
          openErrorModal(message, code);
        }
      }
    },
    [
      getProductDetail,
      openErrorModal,
      providerDetailApi,
      setProductDetail,
      setSelectedPeriodOptions,
    ],
  );

  const onChangeProduct = useCallback(
    (product: any) => {
      setSpaceProduct(product);
      getProductBasicDetail(product.id);
      setValue("contractManage.spaceProductId", product.id);
      setValue("contractManage.spaceProductType", product.productType);
      setValue("contractManage.spaceProductName", product.productName);
      setPflag(false);
      setProductError("");
    },
    [getProductBasicDetail, setSpaceProduct, setValue],
  );

  useEffect(() => {}, [application]);

  useEffect(() => {
    const requiredMessage = "필수입력항목입니다";

    register("contract.useStartTime", {
      required: requiredMessage,
    });
  }, [productDetail, register]);

  return (
    <div>
      <article className="pb20">
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>신청자 정보</h2>
          </div>
          <div className="flex-center">
            {/* 회원번호 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>회원번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax140 mr8">
                    <Link
                      to={goMemberDetail(memberInfo?.id)}
                      className="text-hilight"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {memberInfo?.memberNo}
                    </Link>
                  </div>
                  <div className="minmax90">
                    {id === 0 && (
                      <>
                        <BaseButton
                          title={memberInfo?.memberNo ? "변경" : "조회"}
                          onClick={() => setMflag(true)}
                          disabled={id !== 0}
                          className="color-white"
                        />
                        {memberError !== "" && <p className="validation-text">{memberError}</p>}
                      </>
                    )}
                    <SearchMemberPopup
                      isOpen={mflag}
                      onClick={onChangeMember}
                      onClose={() => setMflag(false)}
                    />
                  </div>
                </div>
              </div>
            </section>

            {/* 휴대폰 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>휴대폰</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{memberInfo?.phoneNumber}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 이메일 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>이메일</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{memberInfo?.email}</p>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>신청자명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>작업해야함</p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>상품 정보</h2>
          </div>
          <div className="flex-center">
            {/* 상품 id */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>상품 id</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax140 mr8">
                    <Link
                      to={goProductDetail(spaceProduct?.id)}
                      className="text-hilight"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {spaceProduct?.id}
                    </Link>
                  </div>
                  <div className="minmax90">
                    {id === 0 && (
                      <>
                        <BaseButton
                          title="조회"
                          className="color-white"
                          disabled={id !== 0}
                          onClick={() => setPflag(true)}
                        />
                        {productError !== "" && <p className="validation-text">{productError}</p>}
                      </>
                    )}
                    <SearchSpaceProductPopup
                      isOpen={pflag}
                      onClick={onChangeProduct}
                      onClose={() => setPflag(false)}
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* 보증금 */}

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>
                  이용료(원)
                  <br />
                  (부가세 포함 / 원)
                </p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {productDetail?.saleRentalCostList[0]?.value1
                    ? numberToStringWithComma(
                        Number(productDetail?.saleRentalCostList[0]?.value1) +
                          Number(productDetail?.saleRentalCostList[0]?.value1 / 10),
                      )
                    : productDetail?.rentalCostList[0]?.value1
                    ? numberToStringWithComma(
                        Number(productDetail?.rentalCostList[0]?.value1) +
                          Number(productDetail?.rentalCostList[0]?.value1 / 10),
                      )
                    : "0"}
                </p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>상품구분</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>작업해야함</p>
              </div>
            </section>

            {/* 계약금 */}
          </div>
          <div className="flex-center">
            {/* 상품명 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>상품명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{productDetail?.productName}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 상품타입 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>타입</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {productDetail?.productType === "FULL_COURT" && "FULL"}
                  {productDetail?.productType === "OPEN_COURT" && "OPEN"}
                  {productDetail?.productType === "TIME_COURT" && "SERVICE"}
                </p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 프로바이더 명 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>프로바이더 명</p>
              </div>
              <div className="contents-container__grid-contents">
                {productDetail?.providerId ? (
                  <div>
                    <Link
                      to={pagePath.provider.detail.replace(":id", productDetail?.providerId)}
                      target="_blank"
                      className="text-hilight"
                    >
                      {providerDetail?.provider?.providerName}
                    </Link>
                  </div>
                ) : (
                  <p>-</p>
                )}
              </div>
            </section>
          </div>
        </div>
      </article>

      {/* <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>건물 정보</h2>
          </div>

          {rooms !== undefined &&
            rooms.length > 0 &&
            rooms.map((room: any, index: number) => {
              return (
                <div className="flex-center" key={index}>

                  <section className="contents-container__grid mr20">
                    <div className="contents-container__grid-index">
                      <p>건물 id/건물 명</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p className="ellipsis">{room.buildingId + " / " + room.buildingName}</p>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>층/호실</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>
                        {(room.isGround ? "지하" : "지상") +
                          " " +
                          room.floorName +
                          " | " +
                          room.roomNum +
                          "호"}
                      </p>
                    </div>
                  </section>
                </div>
              );
            })}
        </div>
      </article> */}

      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>신청기간</h2>
          </div>
          <div className="flex-center">
            {/* 이용 시작 */}
            {productDetail === undefined || productDetail?.productType !== "TIME_COURT" ? (
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>이용 시작</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax160">
                    <Controller
                      control={control}
                      name="contract.useStartTime"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseDatePicker
                            name={name}
                            type="date"
                            selectedDate={value ? moment(value).toDate() : null}
                            readonly={
                              (spaceProductType === "TIME_COURT" && id !== 0) ||
                              basicInfo?.contractStep === "USE_APPROVAL" ||
                              basicInfo?.contractStep === "USE_PROGRESS" ||
                              basicInfo?.contractStep === "USE_COMPLETE" ||
                              basicInfo?.contractStep === "TERMINATE_RECEIVED" ||
                              basicInfo?.contractStep === "TERMINATE_COMPLETE" ||
                              basicInfo?.contractStep === "APPLY_CANCEL"
                            }
                            setDate={(date: string) => {
                              onChange(date);

                              if (productDetail?.productType !== "TIME_COURT") {
                                const period = getValues("contract.useTerm");
                                if (period) {
                                  const start = moment(date).format(YmdFormat.YYYY_MM_DD);
                                  const end = moment(start)
                                    .add(period, "M")
                                    .subtract(1, "days")
                                    .format(YmdFormat.YYYY_MM_DD);
                                  setValue("contract.useEndTime", end);
                                }
                              }
                            }}
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </section>
            ) : (
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>신청일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax160">
                    <Controller
                      control={control}
                      name="contract.useStartTime"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseDatePicker
                            name={name}
                            type="date"
                            readonly={
                              basicInfo?.contractStep === "USE_APPROVAL" ||
                              basicInfo?.contractStep === "USE_PROGRESS" ||
                              basicInfo?.contractStep === "USE_COMPLETE" ||
                              basicInfo?.contractStep === "TERMINATE_RECEIVED" ||
                              basicInfo?.contractStep === "TERMINATE_COMPLETE" ||
                              basicInfo?.contractStep === "APPLY_CANCEL"
                            }
                            selectedDate={value ? moment(value).toDate() : null}
                            setDate={(date: string) => {
                              onChange(date);
                              if (getValues("startTime") !== "") {
                                const time = moment(getValues("startTime")).format("HH:mm");
                                const full = moment(date).format(YmdFormat.YYYY_MM_DD) + " " + time;
                                setValue(
                                  "contract.useStartTime",
                                  moment(full).format(YmdFormat.FULL),
                                );
                              } else {
                                setValue(
                                  "contract.useStartTime",
                                  moment(date).format(YmdFormat.YYYY_MM_DD),
                                );
                              }

                              if (getValues("endTime") !== "") {
                                const time = moment(getValues("endTime")).format("HH:mm");
                                const full = moment(date).format(YmdFormat.YYYY_MM_DD) + " " + time;
                                setValue(
                                  "contract.useEndTime",
                                  moment(full).format(YmdFormat.FULL),
                                );
                              } else {
                                setValue(
                                  "contract.useEndTime",
                                  moment(date).format(YmdFormat.YYYY_MM_DD),
                                );
                              }

                              // console.log(
                              //   getValues("contract.useStartTime"),
                              //   getValues("contract.useEndTime"),
                              // );
                            }}
                            errorText={error && error.message}
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </section>
            )}

            {productDetail && productDetail?.productType !== "TIME_COURT" ? (
              <section
                className={
                  "contents-container__grid" +
                  (productDetail?.productType !== "TIME_COURT" ? "" : " not-available")
                }
              >
                <div className="contents-container__grid-index">
                  <p>기간(MM)</p>
                </div>
                <div className="minmax120">
                  <Controller
                    control={control}
                    name="contract.useTerm"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <BaseSelect
                          placeholder="선택"
                          value={String(value)}
                          name={name}
                          isDisabled={
                            basicInfo?.contractStep === "USE_APPROVAL" ||
                            basicInfo?.contractStep === "USE_PROGRESS" ||
                            basicInfo?.contractStep === "USE_COMPLETE" ||
                            basicInfo?.contractStep === "TERMINATE_RECEIVED" ||
                            basicInfo?.contractStep === "TERMINATE_COMPLETE" ||
                            basicInfo?.contractStep === "APPLY_CANCEL"
                          }
                          stateOptions={selectedPeriodOption}
                          setStateValue={(value: number) => {
                            if (productDetail?.productType !== "TIME_COURT") {
                              const date = getValues("contract.useStartTime");
                              if (date) {
                                const start = moment(date).format(YmdFormat.YYYY_MM_DD);
                                const end = moment(start)
                                  .add(value, "M")
                                  .subtract(1, "days")
                                  .format(YmdFormat.YYYY_MM_DD);
                                setValue("contract.useEndTime", end);
                              }
                            }
                            onChange(value);
                          }}
                          errorText={error && error.message}
                        />
                      );
                    }}
                  ></Controller>
                </div>
              </section>
            ) : (
              <section className={"contents-container__grid"}>
                <div className="contents-container__grid-index">
                  <p>신청 시간</p>
                </div>

                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start ">
                    <div className="minmax120 mr8">
                      <Controller
                        control={control}
                        name="startTime"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseDatePicker
                              name={name}
                              type="time"
                              timeIntervals={30}
                              readonly={
                                basicInfo?.contractStep === "USE_APPROVAL" ||
                                basicInfo?.contractStep === "USE_PROGRESS" ||
                                basicInfo?.contractStep === "USE_COMPLETE" ||
                                basicInfo?.contractStep === "TERMINATE_RECEIVED" ||
                                basicInfo?.contractStep === "TERMINATE_COMPLETE" ||
                                basicInfo?.contractStep === "APPLY_CANCEL"
                              }
                              selectedDate={value ? moment(value).toDate() : null}
                              setDate={(time: string) => {
                                onChange(time);
                                if (getValues("contract.useStartTime") !== "") {
                                  const st =
                                    moment(getValues("contract.useStartTime")).format(
                                      YmdFormat.YYYY_MM_DD,
                                    ) +
                                    " " +
                                    moment(time).format("HH:mm");
                                  console.log("st", st);

                                  setValue(
                                    "contract.useStartTime",
                                    moment(st, YmdFormat.YYYY_MM_DD_HH_MM).format(YmdFormat.FULL),
                                  );
                                }
                              }}
                              errorText={error && error.message}
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                    <div className="minmax120 mr8">
                      <Controller
                        control={control}
                        name="endTime"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseDatePicker
                              name={name}
                              type="time"
                              timeIntervals={30}
                              readonly={
                                basicInfo?.contractStep === "USE_APPROVAL" ||
                                basicInfo?.contractStep === "USE_PROGRESS" ||
                                basicInfo?.contractStep === "USE_COMPLETE" ||
                                basicInfo?.contractStep === "TERMINATE_RECEIVED" ||
                                basicInfo?.contractStep === "TERMINATE_COMPLETE" ||
                                basicInfo?.contractStep === "APPLY_CANCEL"
                              }
                              selectedDate={value ? moment(value).toDate() : null}
                              setDate={(time: string) => {
                                onChange(time);
                                if (getValues("contract.useEndTime") !== "") {
                                  const ed =
                                    moment(getValues("contract.useEndTime")).format(
                                      YmdFormat.YYYY_MM_DD,
                                    ) +
                                    " " +
                                    moment(time).format("HH:mm");
                                  console.log("ed", ed);

                                  setValue(
                                    "contract.useEndTime",
                                    moment(ed, YmdFormat.YYYY_MM_DD_HH_MM).format(YmdFormat.FULL),
                                  );
                                }
                              }}
                              errorText={error && error.message}
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
          <div className="flex-center">
            {/* 이용종료 */}
            {productDetail?.productType !== "TIME_COURT" && (
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>이용 종료</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax160">
                    <Controller
                      control={control}
                      name="contract.useEndTime"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseDatePicker
                            type="date"
                            name={name}
                            readonly={
                              productDetail
                                ? productDetail?.productType !== "TIME_COURT"
                                  ? true
                                  : false
                                : false
                            }
                            selectedDate={value ? moment(value).toDate() : null}
                            setDate={onChange}
                            errorText={error && error.message}
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </section>
            )}
          </div>
        </div>
      </article>
    </div>
  );
};

export default ServiceType;
