import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DeskGroupModel } from "src/api/building/building-types";
import { getDeskgroupsAsync } from "src/api/building/deskgroup-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseSelect } from "src/components";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext } from "src/pages/hooks";
import { SelectOption, sortingDeskGroupLayoutList } from "../../building-types";
import DeskLayout from "./components/DeskLayout";

/* 
  건물 > 상세 > 좌석 배치도
 */
const SeatingChartDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // path variable
  const { id: buildingId } = useParams();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 좌석 그룹 목록 조회 api
  const { executeAsync: getDeskgroups } = useApiOperation(getDeskgroupsAsync);

  // 좌석 그룹 목록
  const [deskGroups, setDeskGroups] = useState<Array<DeskGroupModel>>([]);

  // 선택되어있는 좌석 그룹 id
  const [selectedDeskGroupId, setSelectedDeskGroupId] = useState<string>("");

  // 좌석 그룹 목록 조회
  const fetchDeskgroups = useCallback(
    async (buildingId: number, deskGroupId?: string) => {
      setLoadingBar(true);
      const { data } = await getDeskgroups({ buildingId });
      const deskGroups = data?.data?.content || [];
      const sortedDeskGroups = sortingDeskGroupLayoutList(deskGroups || []);
      setDeskGroups(sortedDeskGroups);

      if (deskGroupId) {
        setSelectedDeskGroupId(deskGroupId);
      } else {
        if (!selectedDeskGroupId && sortedDeskGroups.length > 0) {
          // 선택되어있는 좌석그룹이 없을 때 첫번째 좌석그룹 선택되어있게
          const firstDeskGroup = sortedDeskGroups[0];
          setSelectedDeskGroupId(firstDeskGroup.id);
        }
      }

      setLoadingBar(false);
    },
    [getDeskgroups, setLoadingBar, selectedDeskGroupId],
  );

  useEffect(() => {
    if (buildingId) {
      fetchDeskgroups(Number(buildingId), String(queryParams?.deskGroupId || ""));
    }
  }, [buildingId, fetchDeskgroups, queryParams]);

  // 건물 좌석 그룹 셀렉트 박스 옵션 목록
  const deskGroupOptions = useMemo(() => {
    const options: Array<SelectOption> = deskGroups.map((deskGroup: DeskGroupModel) => {
      return {
        value: String(deskGroup.id || ""),
        label: String(deskGroup.groupName || ""),
      };
    });
    return options;
  }, [deskGroups]);

  // 선택되어있는 좌석 그룹 상세
  const selectedDeskGroup: DeskGroupModel | undefined = useMemo(() => {
    return deskGroups.find(
      (deskGroup: DeskGroupModel) => String(deskGroup.id) === String(selectedDeskGroupId),
    );
  }, [deskGroups, selectedDeskGroupId]);

  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection title="좌석 배치도" id={buildingId} />

        {deskGroupOptions.length === 0 ? (
          <>
            {/* 등록된 공용공간 좌석 그룹이 없을 경우 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">좌석 그룹</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">데이터가 없습니다.</span>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">좌석 그룹</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-center-start flex-row">
                  <div className="minmax200">
                    <BaseSelect
                      value={selectedDeskGroupId}
                      stateOptions={deskGroupOptions}
                      setStateValue={(value: string) => {
                        const detailPath = `${PagePath.building.detail.replace(
                          ":id",
                          String(buildingId),
                        )}?tab=seatingChart&deskGroupId=${value}`;
                        navigate(detailPath);
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>

            <DeskLayout buildingId={Number(buildingId)} deskGroup={selectedDeskGroup} />
          </>
        )}
      </div>

      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <BaseButton
            title="목록으로"
            className="color-white size-large"
            onClick={() => navigate(PagePath.building.list)}
          />
        </div>
      </div>
    </div>
  );
};
export default SeatingChartDetail;
