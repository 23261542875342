import _ from "lodash";
import qs from "qs";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { getBuildingAsync } from "src/api/building/building-api";
import {
  BuildingCommonFacilityDeskGroupModel,
  BuildingModel,
  BuildingParkingLotModel,
  CommonFacilityModel,
} from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getProductDetailAsync } from "src/api/product/product-api";
import { postCommonfacilityAsync } from "src/api/product/product-commonSpace-api";
import { ProductBuilding, ProductModel } from "src/api/product/product-types";
import { MediaFile } from "src/api/public-types";
import { BaseButton, BaseCheckbox, BaseInput, BaseModal, BaseToggle } from "src/components";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import pagePath from "src/pagePath.json";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import { Modal } from "src/pages/product/product-types";
import ProductParkingSection from "../../components/ProductParkingSection";
import DeskFormComp from "./components/DeskFormComp";
import MeetingRoomFormComp from "./components/MeetingRoomFormComp";
import ParkingLostFormComp from "./components/ParkingLostFormComp";
import RefreshRoomFormComp from "./components/RefreshRoomFormComp";

/*
  건물 주차장
 */
export interface SpaceBuildingParkingLotModel {
  id?: number; // 건물 주차장 ID‚
  buildingId?: number; // 건물_ID
  buildingName?: string; // 건물_ID
  selfPropelledParkingLotNums?: number; // 자주식주차장수
  selfPropelledParkingLotTotalNums?: number; // 자주식주차장대수
  mechanicalParkingLotNums?: number; // 기계식주차장수
  mechanicalParkingLotTotalNums?: number; // 기계식주차장대수
  description?: string; // 설명
}

export type CommonSpaceFormData = {
  productId?: number;
  isParkingLot: boolean;
  isMeetingRoom: boolean;
  isDesk: boolean;
  isRefreshRoom: boolean;
  isParkingLotFree?: boolean;
  isParkingLotPay?: boolean;
  parkingLotFreeDescription?: string;
  parkingLotPayDescription?: string;
  meetingRoomMaxReservTimeMonthFlag?: boolean;
  meetingRoomMaxReservTimeMonth?: number;
  meetingRoomMaxReservTimeDayFlag?: boolean;
  meetingRoomMaxReservTimeDay?: number;
  meetingRoomDescription?: string;
  deskMaxReservTimeMonthIsFlag?: boolean;
  deskMaxReservTimeMonth?: number;
  deskMaxReservTimeDayIsFlag?: boolean;
  deskMaxReservTimeDay?: number;
  deskDescription?: string;
  refreshRoomMaxReservTimeMonthIsFlag?: boolean;
  refreshRoomMaxReservTimeDayIsFlag?: boolean;
  refreshRoomMaxReservTimeMonth?: number;
  refreshRoomMaxReservTimeDay?: number;
  refreshRoomDescription?: string;
  productBuildingList?: Array<ProductBuilding>;
  selectedCommonFacility?: Array<CommonFacilityModel>;
  productBuildingIds?: Array<string>;
};

export type DeskMediaType = {
  id: number;
  media: Array<MediaFile>;
};

interface IProps {
  product: ProductModel;
}

const unLimitedTime = -1;
const showMessage =
  "CTRL ROOM 건물에 등록되지 않은 정보입니다. 입력을 위해서는 먼저 CTRL ROOM 건물에 해당 정보를 입력해주세요.";

const CommonSpaceForm = ({ product }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoadingBar } = useLoadingBarContext();
  const { openToast } = useToastContext();

  const params = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
  });

  const productId = Number(params.id);
  // 공간상품 상세 조회 api
  // const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync);

  // TODO 건물 들의 상세 내역을 조회
  const { executeAsync: getBuildingCommonSpace } = useApiOperation(getBuildingAsync);

  // 공용공간 저장 API

  const { executeAsync: saveCommonSpace } = useApiOperation(postCommonfacilityAsync, {
    doNotErrorHandleModal: true,
  });
  const [parkingLotList, setParkingLotList] = useState<BuildingParkingLotModel[]>([]);
  const [meetingRoomList, setMeetingRoomList] = useState<CommonFacilityModel[]>([]);

  // ======== CommonFacilty 따로 setState ========
  const [deskList, setDeskList] = useState<Array<BuildingCommonFacilityDeskGroupModel>>([]);
  const [deskMedia, setDeskMedia] = useState<Array<DeskMediaType>>([]);
  const [refreshList, setRefreshList] = useState<CommonFacilityModel[]>([]);

  // ============================================

  // 공간상품 상세 조회
  const [productDetail, setProductDetail] = useState<ProductModel>();

  const [totalOption, setTotalOption] = useState<any>([]);
  // const [dayTimeOption, setDayTimeOption] = useState<any>([]);
  const [isNoData, setIsNoData] = useState(false);

  const [submitData, setSubmitData] = useState<CommonSpaceFormData>();

  // 저장 컨펌 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // React Hook Form
  // const methods = useForm<CommonSpaceFormData>({
  const {
    setValue,
    watch,
    getValues,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CommonSpaceFormData>({
    defaultValues: {
      productId,
      isParkingLot: false,
      isMeetingRoom: false,
      isDesk: false,
      isRefreshRoom: false,
      parkingLotFreeDescription: "",
      parkingLotPayDescription: "",
      meetingRoomMaxReservTimeMonthFlag: false,
      meetingRoomMaxReservTimeMonth: 0,
      meetingRoomMaxReservTimeDay: 0,
      meetingRoomDescription: "",
      deskMaxReservTimeMonth: 0,
      deskMaxReservTimeDay: 0,
      deskDescription: "",
      refreshRoomMaxReservTimeMonth: 0,
      refreshRoomMaxReservTimeDay: 0,
      refreshRoomDescription: "",
      productBuildingList: [],
      selectedCommonFacility: [],
      productBuildingIds: [],
    },
  });

  const formVaildation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";

    // 주차장
    register("isParkingLot", {
      validate: {
        required: () => {
          let result = true;
          let message = "";

          if (watch("isParkingLot") === true) {
            if (
              watch("parkingLotFreeDescription") === "" &&
              watch("parkingLotPayDescription") === ""
            ) {
              result = false;
              message = requiredMessage;
            }
          }
          return result || message;
        },
      },
    });

    register("selectedCommonFacility", {
      validate: {
        required: (selectedCommonFacility?: Array<CommonFacilityModel>) => {
          const meetingRooms = (selectedCommonFacility || []).filter(
            (v: CommonFacilityModel) => v.commonFacilityType === "MEETING_ROOM",
          );

          const desks = (selectedCommonFacility || []).filter(
            (v: CommonFacilityModel) => v.commonFacilityType === "DESK",
          );

          const refreshRooms = (selectedCommonFacility || []).filter(
            (v: CommonFacilityModel) => v.commonFacilityType === "REFRESH_ROOM",
          );

          if (watch("isMeetingRoom") === true && meetingRooms.length === 0) {
            return meetingRooms.length > 0 || requiredMessage;
          } else if (watch("isDesk") === true && desks.length === 0) {
            return desks.length > 0 || requiredMessage;
          } else if (watch("isRefreshRoom") === true && refreshRooms.length === 0) {
            return refreshRooms.length > 0 || requiredMessage;
          } else {
            return true;
          }
        },
      },
    });

    // 회의실
    register("isMeetingRoom", {
      validate: {
        required: () => {
          let result = true;
          let message = "";

          if (watch("meetingRoomMaxReservTimeMonthFlag") === true) {
            if (!watch("meetingRoomMaxReservTimeMonth")) {
              result = false;
              message = requiredMessage;
            }
          }
          if (watch("meetingRoomMaxReservTimeDayFlag") === true) {
            if (!watch("meetingRoomMaxReservTimeDay")) {
              result = false;
              message = requiredMessage;
            }
          }

          return result || message;
        },
      },
    });

    //  좌석
    register("isDesk", {
      validate: {
        required: () => {
          let result = true;
          let message = "";

          // 총 시간 true 인데 값 없을때
          if (watch("deskMaxReservTimeMonthIsFlag") === true) {
            if (!watch("deskMaxReservTimeMonth")) {
              result = false;
              message = requiredMessage;
            }
          }

          // 예약 최대 시간 true 인데 값 없을때
          if (watch("deskMaxReservTimeDayIsFlag") === true) {
            if (!watch("deskMaxReservTimeDay")) {
              result = false;
              message = requiredMessage;
            }
          }

          return result || message;
        },
      },
    });

    // 편의시설
    register("isRefreshRoom", {
      validate: {
        required: () => {
          let result = true;
          let message = "";

          if (watch("refreshRoomMaxReservTimeMonthIsFlag") === true) {
            if (!watch("refreshRoomMaxReservTimeMonth")) {
              result = false;
              message = requiredMessage;
            }
          }

          if (watch("refreshRoomMaxReservTimeDayIsFlag") === true) {
            if (!watch("refreshRoomMaxReservTimeDay")) {
              result = false;
              message = requiredMessage;
            }
          }

          return result || message;
        },
      },
    });
  }, []);

  //  Taap 예약설정 기본 시간 세팅
  const getTimeCourtTimeData = useCallback((pDetail: ProductModel) => {
    if (pDetail.productType === "TIME_COURT") {
      //T = 1 * 4 * 1 * 5 = 20
      //F/O = useNums * 4 * 1 * 5 =
      const total = 1 * 4 * 1 * 5 * 60;
      const makeOptions = [];
      const makeOptions2 = [];

      for (let i = 1; i < total + 1; i++) {
        makeOptions.push({ label: String(i), value: String(i) });
      }
      setTotalOption(makeOptions);

      for (let i = 1; i < 13; i++) {
        makeOptions2.push({ label: String(i), value: String(i) });
      }
    } else {
      //F/O = useNums * 4 * 1 * 5 =
      const total = Number(pDetail.useNums) * 4 * 1 * 5 * 60;
      const makeOptions = [];
      const makeOptions2 = [];
      for (let i = 1; i < total + 1; i++) {
        makeOptions.push({ label: String(i), value: String(i) });
      }
      setTotalOption(makeOptions);

      for (let i = 1; i < 13; i++) {
        makeOptions2.push({ label: String(i), value: String(i) });
      }
    }
  }, []);

  //
  //
  // 공간상품 pDetail 정보로 commonFacility 들 setValue

  const setCommonFacility = useCallback((pDetail: ProductModel) => {
    //공간상품 기본정보 상세 조회에 등록 되어 있는 데이터를 폼에 매칭

    const testTotal: Array<CommonFacilityModel> = [];

    pDetail?.buildingList?.forEach((buildingList: BuildingModel) => {
      const building = buildingList;

      // 회의실 setValue

      building?.buildingCommonFacility?.meetingRoomList?.forEach(
        (meetingRoom: CommonFacilityModel) => {
          testTotal.push(meetingRoom);

          setValue("selectedCommonFacility", [...testTotal]);
        },
      );

      // 좌석
      building?.buildingCommonFacility?.deskSpace?.deskGroupList?.forEach(
        (deskGroup: BuildingCommonFacilityDeskGroupModel) => {
          deskGroup?.deskList?.forEach((deskRoom: CommonFacilityModel) => {
            testTotal.push(deskRoom);
            setValue("selectedCommonFacility", [...testTotal]);
          });
        },
      );

      // 휴게실
      building?.buildingCommonFacility?.refreshRoomList?.forEach(
        (refreshRoom: CommonFacilityModel) => {
          testTotal.push(refreshRoom);
          setValue("selectedCommonFacility", [...testTotal]);
        },
      );
    });
  }, []);

  const setCommonSpaceData = useCallback(
    async (pDetail: ProductModel) => {
      let parkingSetter: BuildingParkingLotModel[] = [];
      let meetingRoomSetter: CommonFacilityModel[] = [];
      let deskSetter: BuildingCommonFacilityDeskGroupModel[] = [];
      let deskMediaList: DeskMediaType[] = [];

      let refreshSetter: CommonFacilityModel[] = [];
      const buildings = pDetail?.buildingList;

      if (buildings) {
        for (let i = 0; i < buildings?.length; i++) {
          const building = buildings[i];

          const response = await getBuildingCommonSpace({ buildingId: building.id });

          if (response.status >= 200 && response.status <= 299) {
            const bd: BuildingModel = response.data.data.content.building;

            bd?.buildingCommonFacility?.parkingLotList?.forEach(
              (parkingLot: BuildingParkingLotModel) => {
                const parkingLotModel: SpaceBuildingParkingLotModel = {
                  id: parkingLot.id,
                  buildingId: bd.id,
                  buildingName: bd.buildingName,
                  selfPropelledParkingLotNums: parkingLot.selfPropelledParkingLotNums, // 자주식주차장수
                  selfPropelledParkingLotTotalNums: parkingLot.selfPropelledParkingLotTotalNums, // 자주식주차장수
                  mechanicalParkingLotNums: parkingLot.mechanicalParkingLotNums, // 기계식주차장수
                  mechanicalParkingLotTotalNums: parkingLot.mechanicalParkingLotTotalNums, // 기계식주차장수
                  description: parkingLot.description,
                };
                parkingSetter.push(parkingLotModel);
              },
            );

            //  회의실 세팅
            meetingRoomSetter = [
              ...meetingRoomSetter,
              ...(bd?.buildingCommonFacility?.meetingRoomList || []),
            ];

            // 1인좌석 세팅
            deskSetter = [
              ...deskSetter,
              ...(bd?.buildingCommonFacility?.deskSpace?.deskGroupList || []),
            ];

            deskMediaList = [
              ...deskMediaList,
              { id: bd.id, media: bd?.buildingCommonFacility?.deskSpace?.mediaList || [] },
            ];

            refreshSetter = [
              ...refreshSetter,
              ...(bd?.buildingCommonFacility?.refreshRoomList || []),
            ];
          }
        }

        setCommonFacility(pDetail);
        setDeskMedia(deskMediaList);
        setParkingLotList(parkingSetter);
        setMeetingRoomList(meetingRoomSetter);
        setDeskList(deskSetter);

        setRefreshList(refreshSetter);
      }
    },
    [getBuildingCommonSpace, setCommonFacility],
  );

  //  공용공간 setValue
  const commonSpaceSetValue = useCallback(
    (pDetail: ProductModel) => {
      if (productDetail) {
        if (pDetail.parkingLotFreeDescription !== "") {
          setValue("isParkingLotFree", true);
        }

        if (pDetail.parkingLotPayDescription !== "") {
          setValue("isParkingLotPay", true);
        }

        pDetail.isParkingLot && setValue("isParkingLot", pDetail.isParkingLot ? true : false);
        setValue("parkingLotFreeDescription", pDetail.parkingLotFreeDescription);
        setValue("parkingLotPayDescription", pDetail.parkingLotPayDescription);
        setValue("isMeetingRoom", pDetail.isMeetingRoom ? true : false);

        setValue(
          "meetingRoomMaxReservTimeMonthFlag",
          Number(pDetail.meetingRoomMaxReservTimeMonth) === 0 ||
            Number(pDetail.meetingRoomMaxReservTimeMonth) === unLimitedTime
            ? false
            : true,
        );
        setValue(
          "meetingRoomMaxReservTimeDayFlag",
          Number(pDetail.meetingRoomMaxReservTimeDay) === 0 ||
            Number(pDetail.meetingRoomMaxReservTimeDay) === unLimitedTime
            ? false
            : true,
        );
        setValue(
          "meetingRoomMaxReservTimeMonth",
          Number(pDetail.meetingRoomMaxReservTimeMonth) !== unLimitedTime
            ? Number(pDetail.meetingRoomMaxReservTimeMonth)
            : 0,
        );
        setValue(
          "meetingRoomMaxReservTimeDay",
          Number(pDetail.meetingRoomMaxReservTimeDay) !== unLimitedTime
            ? Number(pDetail.meetingRoomMaxReservTimeDay)
            : 0,
        );
        setValue("meetingRoomDescription", pDetail.meetingRoomDescription);
        setValue("isDesk", pDetail.isDesk ? true : false);
        setValue(
          "deskMaxReservTimeMonthIsFlag",
          Number(pDetail.deskMaxReservTimeMonth) === 0 ||
            Number(pDetail.deskMaxReservTimeMonth) === unLimitedTime
            ? false
            : true,
        );
        setValue(
          "deskMaxReservTimeDayIsFlag",
          Number(pDetail.deskMaxReservTimeDay) === 0 ||
            Number(pDetail.deskMaxReservTimeDay) === unLimitedTime
            ? false
            : true,
        );
        setValue(
          "deskMaxReservTimeMonth",
          Number(pDetail.deskMaxReservTimeMonth) !== unLimitedTime
            ? Number(pDetail.deskMaxReservTimeMonth)
            : 0,
        );
        setValue(
          "deskMaxReservTimeDay",
          Number(pDetail.deskMaxReservTimeDay) !== unLimitedTime
            ? Number(pDetail.deskMaxReservTimeDay)
            : 0,
        );
        setValue("deskDescription", pDetail.deskDescription);
        setValue("isRefreshRoom", pDetail.isRefreshRoom === true ? true : false);
        setValue(
          "refreshRoomMaxReservTimeMonthIsFlag",
          Number(pDetail.refreshRoomMaxReservTimeMonth) === 0 ||
            Number(pDetail.refreshRoomMaxReservTimeMonth) === unLimitedTime
            ? false
            : true,
        );
        setValue(
          "refreshRoomMaxReservTimeDayIsFlag",
          Number(pDetail.refreshRoomMaxReservTimeDay) === 0 ||
            Number(pDetail.refreshRoomMaxReservTimeDay) === unLimitedTime
            ? false
            : true,
        );
        setValue(
          "refreshRoomMaxReservTimeMonth",
          Number(pDetail.refreshRoomMaxReservTimeMonth) !== unLimitedTime
            ? Number(pDetail.refreshRoomMaxReservTimeMonth)
            : 0,
        );
        setValue(
          "refreshRoomMaxReservTimeDay",
          Number(pDetail.refreshRoomMaxReservTimeDay) !== unLimitedTime
            ? Number(pDetail.refreshRoomMaxReservTimeDay)
            : 0,
        );
        setValue("refreshRoomDescription", pDetail.refreshRoomDescription);
      }
    },
    [productDetail],
  );

  useEffect(() => {
    if (productDetail) {
      commonSpaceSetValue(productDetail);
    }
  }, [commonSpaceSetValue, productDetail]);

  // 공산상품 기본정보 디테일 api
  const getProductBasicDetail = useCallback(async () => {
    setLoadingBar(true);
    if (productId) {
      const result = product;
      setCommonSpaceData(result);
      getTimeCourtTimeData(result);
      setProductDetail(result);
      setValue(
        "productBuildingIds",
        result?.buildingList?.map((item) => String(item.id)),
      );
    }
    setLoadingBar(false);
  }, [getTimeCourtTimeData, productId, setCommonSpaceData, setLoadingBar]);

  useEffect(() => {
    if (productId) {
      getProductBasicDetail();
    }
  }, [getProductBasicDetail, productId]);

  //
  //  공간상품 공용공간 수정 api
  const saveAction = useCallback(
    (data: CommonSpaceFormData) => {
      setLoadingBar(true);
      let path = pagePath.product.detail;

      saveCommonSpace({ ...data, id: productId }).then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          openToast(`정상적으로 ${res ? "수정" : "저장"}되었습니다.`);
          path = path.replace(":id", String(productId)) + "?tab=" + params.tab;
          navigate(path);
        } else {
          setConfirmModal({ isOpen: false });
          if (res.data?.meta?.errorCode === "ePR0517") {
            const errorData = res.data?.meta?.errorData;
            let errorText = "";

            for (let i in errorData) {
              errorText = `사용중인 공용공간은 삭제할 수 없습니다.\n 해당 공용공간을 하위 단계에서 먼저 삭제해 주세요. \n ${i}: [${errorData[i]}]`;
            }
            setAlertModal({
              isOpen: true,
              message: errorText || "에러가 발생했습니다.",
            });
          } else {
            setAlertModal({
              isOpen: true,
              message: res.data?.meta?.errorMessage || "에러가 발생했습니다.",
            });
          }
        }
      });
      setLoadingBar(false);
    },
    [navigate, openToast, params.tab, productId, saveCommonSpace, setLoadingBar],
  );

  useEffect(() => {
    formVaildation();
  }, [formVaildation]);

  // 등록 / 수정 실행 부분 - 저장 클릭시 부분 데이터 변환하여 저장
  const onSubmit = useCallback((data: CommonSpaceFormData, e) => {
    e.preventDefault();

    const selectedCommonSpaceList = data.selectedCommonFacility; // 선택한 공용공간 List (회의실/좌석/편의시설)
    const addedBuildingIds = data.productBuildingIds; // 공간상품에 등록된 building 들의 ID

    // 여러 빌딩의 공용공간 checkbox 데이터를 POST 용 데이터 구조로 변환
    const changeCommonSpace = _.chain(selectedCommonSpaceList)
      .groupBy("buildingId")
      .map((v, i) => {
        return {
          id: String(_.get(_.find(v, "buildingId"), "buildingId")),
          productBuildingCommonFacility: {
            meetingRoomList: v
              .filter((meetingRoom) => meetingRoom.commonFacilityType === "MEETING_ROOM")
              .map((room) => String(room.id)),
            refreshRoomList: v
              .filter((refresh) => refresh.commonFacilityType === "REFRESH_ROOM")
              .map((room) => String(room.id)),
            deskList: v
              .filter((desks) => desks.commonFacilityType === "DESK")
              .map((desk) => String(desk.id)),
          },
          //
        };
      })
      .value();

    const checkedCommonSpaceBuildingIds = changeCommonSpace.map((item) => item.id); // 선택한 공용공간의 building Id만 array로 출력

    // 등록되어있는 전체 빌딩 ID(addedBuildingIds) 중 선택되지 않은 id filter
    const exceptBuildingIds = addedBuildingIds?.filter(
      (item) => !checkedCommonSpaceBuildingIds.includes(item),
    );

    // 데이터 저장 시, filtered 된 선택되지 않은 id 도 해당 데이터 구조에 맞춰 함께 POST
    if (exceptBuildingIds && exceptBuildingIds.length > 0) {
      exceptBuildingIds.forEach((num) => {
        changeCommonSpace.push({
          id: num,
          productBuildingCommonFacility: {
            meetingRoomList: [],
            refreshRoomList: [],
            deskList: [],
          },
        });
      });
    }

    // 공용공간 사용여부 false 시 데이터 초기화 (회의실 / 좌석 / 편의시설)
    let parkingLot = data.isParkingLot;
    let useDesk = data.isDesk;
    let useRefresh = data.isRefreshRoom;
    let useMeetingRoom = data.isMeetingRoom;
    let isDeskTimeMonth = data.deskMaxReservTimeMonthIsFlag;
    let isDeskTimeDay = data.deskMaxReservTimeDayIsFlag;
    let isMeetingRoomTimeMonth = data.meetingRoomMaxReservTimeMonthFlag;
    let isMeetingRoomTimeDay = data.meetingRoomMaxReservTimeDayFlag;
    let isRefreshTimeMonth = data.refreshRoomMaxReservTimeMonthIsFlag;
    let isRefreshTimeDay = data.refreshRoomMaxReservTimeDayIsFlag;

    //
    // 주차 사용여부 false
    if (!parkingLot) {
      data.parkingLotFreeDescription = "";
      data.parkingLotPayDescription = "";
    }

    // 좌석 사용여부 false
    if (!useDesk) {
      data.deskMaxReservTimeDay = 0;
      data.deskMaxReservTimeMonth = 0;
      data.deskDescription = "";
      changeCommonSpace.forEach((item) => (item.productBuildingCommonFacility.deskList = []));
    }

    // 좌석 사용여부 true / Taap 예약설정 제약없음 일때,

    if (useDesk && !isDeskTimeMonth) {
      data.deskMaxReservTimeMonth = unLimitedTime;
    }
    if (useDesk && !isDeskTimeDay) {
      data.deskMaxReservTimeDay = unLimitedTime;
    }

    // 편의시설 사용여부 false

    if (!useRefresh) {
      data.refreshRoomMaxReservTimeDay = 0;
      data.refreshRoomMaxReservTimeMonth = 0;
      data.refreshRoomDescription = "";
      changeCommonSpace.forEach(
        (item) => (item.productBuildingCommonFacility.refreshRoomList = []),
      );
    }

    // 휴게 사용여부 true / Taap 예약설정 제약없음 일때,

    if (useRefresh && !isRefreshTimeMonth) {
      data.refreshRoomMaxReservTimeMonth = unLimitedTime;
    }
    if (useRefresh && !isRefreshTimeDay) {
      data.refreshRoomMaxReservTimeDay = unLimitedTime;
    }

    // 회의실 사용여부 false

    if (!useMeetingRoom) {
      data.meetingRoomMaxReservTimeMonth = 0;
      data.meetingRoomMaxReservTimeDay = 0;
      data.meetingRoomDescription = "";

      changeCommonSpace.forEach(
        (item) => (item.productBuildingCommonFacility.meetingRoomList = []),
      );
    }

    // 회의실 사용여부 true / Taap 예약설정 제약없음 일때,

    if (useMeetingRoom && !isMeetingRoomTimeMonth) {
      data.meetingRoomMaxReservTimeMonth = unLimitedTime;
    }
    if (useMeetingRoom && !isMeetingRoomTimeDay) {
      data.meetingRoomMaxReservTimeDay = unLimitedTime;
    }

    const postData = {
      productBuildingList: changeCommonSpace,
      parkingLotFreeDescription: data.parkingLotFreeDescription,
      parkingLotPayDescription: data.parkingLotPayDescription,
      meetingRoomMaxReservTimeMonth: Number(data.meetingRoomMaxReservTimeMonth),
      meetingRoomMaxReservTimeDay: Number(data.meetingRoomMaxReservTimeDay),
      meetingRoomDescription: data.meetingRoomDescription,
      deskMaxReservTimeMonth: Number(data.deskMaxReservTimeMonth),
      deskMaxReservTimeDay: Number(data.deskMaxReservTimeDay),
      deskDescription: data.deskDescription,
      refreshRoomMaxReservTimeMonth: Number(data.refreshRoomMaxReservTimeMonth),
      refreshRoomMaxReservTimeDay: Number(data.refreshRoomMaxReservTimeDay),
      refreshRoomDescription: data.refreshRoomDescription,
      isParkingLot: data.isParkingLot,
      isMeetingRoom: data.isMeetingRoom,
      isRefreshRoom: data.isRefreshRoom,
      isDesk: data.isDesk,
    };

    console.log("create", postData);
    setSubmitData(postData);

    setConfirmModal({ message: "공간상품 공용공간 저장하시겠습니까?", isOpen: true });

    // saveAction(data);
  }, []);

  // validation 통과하지 못하고 error 발생시 실행
  const onError = useCallback((errors: any, e?: any) => {
    console.log(errors);
    return false;
  }, []);

  useTitleOperation(productDetail?.productName);

  //
  // 회의실 - 제공 회의실 value 구하기 (인원수 : 인원수 중복 length)
  const onProvideMeetingRooms = useCallback(() => {
    const selectedCommonSpace = getValues("selectedCommonFacility")?.filter(
      (item) => item.commonFacilityType === "MEETING_ROOM",
    );
    const maxPeopleNums = selectedCommonSpace?.map((item) => item.facilityMaxPeopleNums);

    const peopleNumObj: any = {};
    const newPeopleNumArr = [];

    //회의실 인원수를 forEach 하여 인원수를 key 값 / 중복된 length 를 value 로 넣기
    maxPeopleNums?.forEach((count) => {
      if (count) {
        peopleNumObj[count] = (peopleNumObj[count] || 0) + 1;
      }
    });

    //  인원수 : 인원수 중복 length 된 object를 for문
    for (const key in peopleNumObj) {
      const result = `${key}인 (${peopleNumObj[key]})`;
      newPeopleNumArr.push(result);
    }

    return newPeopleNumArr.map((value, idx: number) => (
      <div key={idx} className="base-chip mr5">
        <span>{value}</span>
      </div>
    ));
  }, []);

  // 체크박스 전체 선택
  const checkedAllItem = useCallback((meetings: CommonFacilityModel[]) => {
    const selectedCommonArray = watch("selectedCommonFacility") || [];
    const selectedIds = selectedCommonArray.map((item) => item.id);
    const meetingsIds = meetings.map((item) => item.id);
    let selected = false;

    selectedCommonArray.forEach((common) => {
      meetings.forEach((meeting) => {
        if (meeting.buildingId === common.buildingId) {
          const checked = meetingsIds.every((item) => selectedIds.includes(item));

          selected = checked;
        }
      });
      return selected;
    });

    return selected;
  }, []);

  return (
    <div className="contents-container__wrap">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__wrap-contents">
          <ContentsIdSection title="공용공간" id={productId!} />
          {/* 주차 */}
          <ParkingLostFormComp
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            setIsNoData={setIsNoData}
            productDetail={productDetail}
            parkingLotList={parkingLotList}
          />
          {/* 회의실 */}
          <MeetingRoomFormComp
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            setIsNoData={setIsNoData}
            productDetail={productDetail}
            totalOption={totalOption}
            getValues={getValues}
            meetingRoomList={meetingRoomList}
            onProvideMeetingRooms={onProvideMeetingRooms}
            checkedAllItem={checkedAllItem}
          />
          {/* 좌석 선택 */}
          <DeskFormComp
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            setIsNoData={setIsNoData}
            productDetail={productDetail}
            getValues={getValues}
            totalOption={totalOption}
            deskList={deskList}
            deskMedia={deskMedia}
            checkedAllItem={checkedAllItem}
          />
          {/* 리프레시 룸 */}
          <RefreshRoomFormComp
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            setIsNoData={setIsNoData}
            productDetail={productDetail}
            getValues={getValues}
            totalOption={totalOption}
            refreshList={refreshList}
            checkedAllItem={checkedAllItem}
          />
        </div>
        <BaseModal
          isOpen={isNoData}
          btnRightTitle="확인"
          onClick={() => {
            setIsNoData(false);
          }}
        >
          {showMessage}
        </BaseModal>
        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              className="color-white size-large"
              onClick={() => navigate(pagePath.product.list)}
            />
          </div>
          <div className="right-area">
            {/* <BaseButton title="저장" type="submit" className=" size-large" /> */}
          </div>
        </div>
      </form>
      <>
        <BaseModal
          isOpen={confirmModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => {
            setConfirmModal({ isOpen: false });
          }}
          onClick={() => submitData && saveAction(submitData)}
        >
          <p>{confirmModal.message}</p>
        </BaseModal>

        {/* 확인버튼만 있는 alert 모달 */}
        <BaseModal
          isOpen={alertModal.isOpen}
          btnRightTitle="확인"
          onClick={() => setAlertModal({ isOpen: false })}
        >
          <p className="pre-formatted">{alertModal.message}</p>
        </BaseModal>
      </>
    </div>
  );
};
export default CommonSpaceForm;
