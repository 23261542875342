import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import {
  ProductModel,
  ProductAccessGroupModel,
  ProductInputModel,
  AccessGroupModel,
} from "src/api/access/ac-types";
import {
  deleteProductAsync,
  getProductAsync,
  putProductAsync,
  syncProductAsync,
} from "src/api/access/ac-api";
import { BaseButton, BaseModal, BaseToggle, ContentsIdSection } from "src/components";
import { useLoadingBarContext, useToastContext } from "src/pages/hooks";
import { Modal } from "src/pages/building/building-types";
import SelectAccessGroupModal from "./modals/SelectAccessGroupModal";
import PagePath from "src/pagePath.json";
import AccessGroupDetailPopup from "../../components/AccessGroupDetailPopup";
import qs from "qs";
import { ProductFormData } from "./access-types";
import { Controller, useFieldArray, useForm } from "react-hook-form";

const AccessGroupForm = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 토스트
  const { openToast } = useToastContext();

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 공간상품 id
  const productId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  // AC상품
  const [product, setProduct] = useState<ProductModel | undefined>();
  const { executeAsync: getProduct } = useApiOperation(getProductAsync);
  const { executeAsync: updateProduct } = useApiOperation(putProductAsync);
  const { executeAsync: deleteProduct } = useApiOperation(deleteProductAsync);
  const { executeAsync: syncProduct } = useApiOperation(syncProductAsync);

  useEffect(() => {
    if (productId) {
      async function fetchProduct(productId: number) {
        setLoadingBar(true);
        const { data } = await getProduct({ id: productId });
        const product = data?.data?.product;

        setProduct(product);
        setLoadingBar(false);
      }

      fetchProduct(productId);
    }
  }, [getProduct, productId, setLoadingBar]);

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 저장할 form data
  const [submitData, setSubmitData] = useState<ProductFormData>();

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm<ProductFormData>({
    defaultValues: {
      name: "",
      comment: "",
      accessGroups: [],
    },
  });

  // 출입그룹 목록
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "accessGroups",
  });

  // 출입그룹 선택 팝업
  const [showSelectModal, setShowSelectModal] = useState(false);

  // 상세정보 팝업
  const [showDetailModal, setShowDetailModal] = useState(false);

  // 출입그룹 선택시
  const onSelectedAccessGroups = useCallback(
    (selectedItems: AccessGroupModel[]) => {
      if (selectedItems.length > 0) {
        selectedItems.forEach((item) => {
          const existing = getValues("accessGroups").some(
            (x) => x.accessGroupId === item.accessGroupId,
          );
          if (!existing) {
            append({
              accessGroupId: item.accessGroupId,
              accessGroupName: item.accessGroupName,
              apiServerId: item.apiServerId,
              apiServerEndpoint: item.apiServerEndpoint,
              visitorAllowed: item.visitorAllowed || false,
            });
          }
        });
      }

      setShowSelectModal(false);
    },
    [append, getValues],
  );

  // 출입그룹 삭제시
  const onRemoveAccessGroup = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  // 수정시 api 에서 받아온 product 정보로 setValue 처리
  useEffect(() => {
    if (product) {
      Object.entries(product).forEach(([name, value]: any) => {
        setValue(name, value);
      });
    }
  }, [product, setValue]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: ProductFormData, e?: any) => {
    e.preventDefault();
    setSubmitData(data);
    setConfirmModal({ message: "저장하시겠습니까?", isOpen: true });
  };

  // Modal 확인 버튼 클릭
  const clickModalConfirm = async () => {
    setConfirmModal({ isOpen: false });

    setLoadingBar(true);

    if (!submitData) {
      throw Error("저장할 data 없음");
    }

    if (productId) {
      const data: ProductFormData = submitData;
      let input: ProductInputModel = {
        name: data.name || `${productId}_액세스그룹`,
        comment: data.comment,
        accessGroups: data.accessGroups,
      };

      const updateResult = await updateProduct({
        id: productId,
        data: input,
      });

      if (updateResult.data.data.product) {
        openToast(`정상적으로 저장 되었습니다.`);
      } else {
        setAlertModal({
          isOpen: true,
          message: updateResult?.data?.meta?.errorMessage || "에러가 발생했습니다.",
        });
      }

      const syncResult = await syncProduct({ id: productId });
      if (!syncResult.data.data.product.succeed) {
        setAlertModal({
          isOpen: true,
          message: "동기화에 실패 하였습니다.",
        });
      }
    }

    setLoadingBar(false);
  };

  // 방문자 허용 토글 변경시
  const handleVisitorAllowedChange = (item: ProductAccessGroupModel) => {
    const currentAccessGroups = getValues("accessGroups");
    currentAccessGroups.forEach((accessGroup) => {
      if (accessGroup.accessGroupId === item.accessGroupId) {
        accessGroup.visitorAllowed = !item.visitorAllowed;
      }
    });

    setValue("accessGroups", currentAccessGroups);
    return currentAccessGroups;
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    // console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  // 출입그룹 상세 키값
  const [accessGroupId, setAccessGroupId] = useState(0);
  const [apiServerId, setApiServerId] = useState(0);

  return (
    <div className="contents-container__wrap detail-usage-restriction">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection title="액세스 그룹 설정" id={productId} />
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <section>
            <div className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>uid</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{product?.id || "없음"}</p>
              </div>
            </div>
            <div className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>엑세스 그룹명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{product?.name || "없음"}</p>
              </div>
            </div>
            <div className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">출입그룹</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="mb10">
                  {/* <BaseButton
                    title="+ 출입그룹 추가"
                    onClick={() => {
                      setShowSelectModal(true);
                    }}
                  /> */}
                  {/* <SelectAccessGroupModal
                    showModal={showSelectModal}
                    setShowModal={setShowSelectModal}
                    onSelectedAccessGroups={onSelectedAccessGroups}
                  /> */}
                </div>
                <table className="inner-table" width="600">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>출입그룹명</th>
                      <th>방문자 허용</th>
                      <th>상세정보</th>
                      <th>BS링크</th>
                      <th>삭제</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Controller
                      control={control}
                      name="accessGroups"
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <>
                            {fields.map((item: ProductAccessGroupModel, index: number) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <div className="flex-center-center">{item.accessGroupId}</div>
                                  </td>
                                  <td>
                                    <div className="flex-center-center">{item.accessGroupName}</div>
                                  </td>
                                  <td>
                                    <div className="flex-center-center">
                                      <BaseToggle
                                        checked={item.visitorAllowed || false}
                                        onChange={() => onChange(handleVisitorAllowedChange(item))}
                                      />
                                    </div>
                                  </td>
                                  <td className="flex-center-center">
                                    <BaseButton
                                      title="상세 정보"
                                      className="color-white"
                                      onClick={() => {
                                        setAccessGroupId(item.accessGroupId);
                                        setApiServerId(item.apiServerId);
                                        setShowDetailModal(true);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <div className="flex-center-center">
                                      <a
                                        href={
                                          item.apiServerEndpoint +
                                          `/#/access-control/ac-group/detail/` +
                                          item.accessGroupId
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-hilight"
                                      >
                                        BS2 링크
                                      </a>
                                    </div>
                                  </td>
                                  <td className="flex-center-center">
                                    {/* <button
                                      type="button"
                                      className="base-trash-btn"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        onRemoveAccessGroup(index);
                                      }}
                                    /> */}
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        );
                      }}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title="목록으로"
                className="color-white size-large"
                onClick={() => {
                  if (productId) {
                    // 수정화면에서는 목록으로 바로이동
                    navigate(PagePath.product.list);
                  } else {
                    // 등록화면에서는 목록에서 진입했기 때문에 뒤로가기
                    navigate(-1);
                  }
                }}
              />
              {/* 삭제는 상세에만 보이는 버튼 */}
              {/* {product && product.id !== 0 && (
                <BaseButton
                  title="삭제"
                  onClick={() => {
                    setConfirmModal({
                      isOpen: true,
                      message: "정말 삭제 하시겠습니까?",
                      type: "DELETE_AC_PRODUCT",
                    });
                  }}
                  className="no-outline color-white size-large text-red ml10"
                />
              )} */}
            </div>
            <div className="right-area">
              <BaseButton type="submit" title="저장" className=" size-large" />
            </div>
          </div>
        </form>
      </div>

      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
      >
        <p>{confirmModal.message}</p>
      </BaseModal>

      {/* 확인버튼만 있는 alert 모달 */}
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => setAlertModal({ isOpen: false })}
      >
        <p>{alertModal.message}</p>
      </BaseModal>

      <AccessGroupDetailPopup
        showModal={showDetailModal}
        setShowModal={setShowDetailModal}
        accessGroupId={accessGroupId}
        apiServerId={apiServerId}
      />
    </div>
  );
};

export default AccessGroupForm;
