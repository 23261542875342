import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { getBuildingAsync } from "src/api/building/building-api";
import { BuildingModel, MgmtOfficerType } from "src/api/building/building-types";
import { getBuildingManagementAsync } from "src/api/building/management-api";
import { useApiOperation } from "src/api/hooks";
import { getMemberDetail } from "src/api/member/member-api";
import { getPartner } from "src/api/partner";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { MediaFile, Modal } from "src/api/public-types";
import {
  WorkOrderDetailAsync,
  WorkOrderEditAsync,
  WorkOrderPostAsync,
  WorkOrderPreviewAsync,
} from "src/api/workOrder/workOrder-api";
import { WorkOrderPreviewRequest, WorkOrderSheetCost } from "src/api/workOrder/workOrder-types";
import {
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
  BaseInput,
  BaseModal,
  BaseSelect,
  BaseTextarea,
  BaseToggle,
  ContentsIdSection,
} from "src/components";
import ImagesUpdate from "src/components/ImagesUpdate";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import { RoleType } from "src/pages/building/building-types";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { Partner } from "src/types/partner";
import { formatPhoneNumber, onChangeKoreanDays, parsedPhoneNumber } from "src/utils/common-util";
import {
  cycleOptions,
  dayOptions,
  FrontWorkOrderSheetCostType,
  FrontWorkOrderUpdateModel,
  RecurrenceType,
  UnionDayOfWeekType,
  WorkOrderMediaType,
  workOrderPriorityOptions,
  WorkOrderStatus,
  workOrderTooltips,
  WorkOrderType,
  workTypeOptions,
} from "../../workOrder-types";
import WorkOrderEditSection from "./WorkOrderEditSection";
import ManagementBuildingModal from "./workOrderModal/managementBuilding/ManagementBuildingModal";
import OfficerModal from "./workOrderModal/officer/OfficerModal";
import ReporterLocationModal from "./workOrderModal/reporter/ReporterLocationModal";
import ReporterModal from "./workOrderModal/reporter/ReporterModal";
import BaseQuestionMarkTooltip from "src/components/BaseQuestionMarkTooltip";

// 워크오더 상태 선택옵션
const workOrderStatusOptions = [
  {
    value: WorkOrderStatus.OPEN,
    label: "접수",
  },
  {
    value: WorkOrderStatus.WAITING,
    label: "확인",
  },
  {
    value: WorkOrderStatus.PROGRESS,
    label: "처리중",
  },
  {
    value: WorkOrderStatus.COMPLETED,
    label: "처리완료",
  },
  {
    value: WorkOrderStatus.CLOSED,
    label: "처리종료",
  },
];

const BasicInfoForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 워크오더 ID
  const workOrderId = useMemo(() => {
    return queryParams?.id ? String(queryParams.id) : undefined;
  }, [queryParams.id]);

  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();
  // 토스트
  const { openToast } = useToastContext();

  // 저장 선택 확인 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  // 워크오더 접수자 / 접수자위치 / 건물 관리처 / 담당자 모달
  const [infoModal, setInfoModal] = useState<Modal>({ isOpen: false });

  // 경고창 팝업
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 파트너 모달
  const [partnerModal, setPartnerModal] = useState<Modal>({
    isOpen: false,
  });

  // 파트너 변경 모달
  const [partnerChangeModal, setPartnerChangeModal] = useState<Modal>({
    isOpen: false,
  });

  // // 워크오더 반복일정 리스트
  const [previewList, setPreviewList] = useState<Array<string>>([]);

  // 건물/관리처 팝업에서 선택한 ID
  const [mgmtOfficeId, setMgmtOfficeId] = useState("");

  // 담당자 팝업에서 선택한 담당자 ID
  const [mgmtOfficerId, setMgmtOfficerId] = useState("");

  // 관리처 담당자 리스트
  const [mgmtOfficerList, setMgmtOfficerList] = useState<Array<MgmtOfficerType>>([]);

  // 팝업에서 선택한 회원 ID
  const [reporterId, setReporterId] = useState("");

  // 관리처 상세 / 건물 상세에서 가져온 건물/관리처 input value
  const [managementName, setManagementName] = useState("");

  // 접수자 input value
  const [reporterPhoneEmail, setReporterPhoneEmail] = useState("");

  // 워크오더 업무내용 이미지
  const [workOrderMedias, setWorkOrderMedias] = useState<Array<MediaFile>>([]);

  // 워크오더 처리내용 이미지
  const [workOrderCompletedMedias, setWorkOrderCompletedMedias] = useState<Array<MediaFile>>([]);

  // 워크오더 처리비용 이미지
  const [workOrderCostMedias, setWorkOrderCostMedias] = useState<Array<MediaFile>>([]);

  // 워크오더 상세 데이터
  const [frontOriginalData, setFrontOriginalData] = useState<FrontWorkOrderUpdateModel>();
  // API 종류

  // 선택된 파트너 정보
  const [partner, setPartner] = useState<Partner>();

  // ==================== API ====================
  // 워크오더 상세 api
  const { executeAsync: getWorkOrderDetail } = useApiOperation(WorkOrderDetailAsync);

  // 관리처 관리 상세
  const { executeAsync: getBuildingManagement } = useApiOperation(getBuildingManagementAsync);

  // 건물 상세 조회 api
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  //회원 상세 조회 API
  const { executeAsync: getMemberDetailAsync } = useApiOperation(getMemberDetail);

  // 워크오더 반복일정 api
  const { executeAsync: getWorkOrderPreview } = useApiOperation(WorkOrderPreviewAsync);

  // 워크오더 등록 api
  const { executeAsync: postWorkOrder } = useApiOperation(WorkOrderPostAsync);

  // 워크오더 수정 api
  const { executeAsync: editWorkOrder } = useApiOperation(WorkOrderEditAsync);

  // 파트너 상세조회
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  // 프로바이더 상세조회
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  // hook-form data
  const defaultValues = useMemo(() => {
    const workOrderFormData: FrontWorkOrderUpdateModel = {
      workOrderType: WorkOrderType.SPACE,
      workOrderPriority: 2,
      mgmtOfficeId: "",
      mgmtOfficerId: "",
      startDate: "",
      endDate: "",
      isRecurrence: false,
      summary: "",
      description: "",
      mediaList: [],
      isSendAlarm: true,
    };

    return workOrderFormData;
  }, []);

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    register,
    resetField,
    reset,
    formState: { dirtyFields, errors },
  } = useForm<FrontWorkOrderUpdateModel>({
    defaultValues,
    mode: "onChange",
  });

  // 파트너 상세 조회
  const fetchPartner = async (id: string) => {
    setLoadingBar(true);
    const response = await getPartnerAsync({ id });

    if (response.status >= 200 && response.status <= 299) {
      const result = response.data.data.partner;
      setPartner(result);
    }
    setLoadingBar(false);
  };

  const onResetUseState = () => {
    reset();
    setMgmtOfficerId("");
    setManagementName("");
    setMgmtOfficeId("");
    setMgmtOfficerList([]);
    setReporterId("");
    setReporterPhoneEmail("");
    setWorkOrderMedias([]);
  };

  // 비회원일 경우 접수자 input 노출
  const reporterInfoText = useCallback((name: string, phone?: string, email?: string) => {
    // if (name && phone) {
    if (name) {
      return `(비회원) ${name}${phone && " / " + formatPhoneNumber(phone)}${
        email && " / " + email
      }`;
    }
  }, []);

  // 접수자 위치 input value 노출
  const reporterLocationText = useCallback(() => {
    const buildingName = watch("reporterBuildingName");
    const buildingAddr = watch("reporterBuildingAddr");

    if (buildingName && buildingAddr) {
      return `${buildingName} (${buildingAddr})`;
    }
  }, [watch]);

  // 담당자 input value 노출
  const officerInfoText = useCallback((officerId: string, officerList: MgmtOfficerType[]) => {
    //
    // 담당자 목록 && 팝업에서 선택된 담당자ID 가 있으면
    if (officerId && officerList) {
      const officer = officerList.find((item) => item.id === officerId);

      let officerValue = "";

      if (officer?.isPrimary) {
        officerValue = `대표매니저 / ${officer.name || "-"} / ${
          formatPhoneNumber(officer.phone || "") || "-"
        }`;
      } else if (!officer?.isPrimary && officer?.roleType === RoleType.MANAGER) {
        officerValue = `매니저 / ${officer.name || "-"} / ${
          formatPhoneNumber(officer.phone || "") || "-"
        }`;
      } else if (!officer?.isPrimary && officer?.roleType === RoleType.USER) {
        officerValue = `${officer?.name || "-"} / ${formatPhoneNumber(officer.phone || "") || "-"}`;
      } else {
        return officerValue;
      }

      return officerValue;
    }
  }, []);

  // 관리처 관리 상세 / 건물 상세 / 프로바이더 상세 api
  const getBuildingManagementApi = useCallback(
    async (mgmtOfficeId: string) => {
      setLoadingBar(true);

      // 관리처 상세 조회 response
      const mgmtBuildingResponse = await getBuildingManagement({ id: mgmtOfficeId });

      if (mgmtBuildingResponse.status >= 200 && mgmtBuildingResponse.status <= 299) {
        const managementBuilding = mgmtBuildingResponse.data.data.content;

        // 관리처 상세에 있는 buildingId
        const buildingId = managementBuilding.buildingId;

        // 관리처 상세에 있는 정산정보 ID
        const providerId = managementBuilding.providerId;

        // buildingId로 건물 상세 조회
        if (buildingId) {
          const buildingResponse = await getBuilding({ buildingId: Number(buildingId) });
          const building: BuildingModel = buildingResponse.data?.data?.content?.building;
          let providerName = "없음";

          // 정산정보 상세에서 providerName 가져와서 표시 (관리처명)
          if (providerId !== null) {
            const { data } = await getProviderDetail({ providerId: String(providerId) });
            if (data) {
              providerName = String(data.data.provider?.providerName);
            }
          }
          // 건물/관리처 input value setState
          const mgmtBuildingName = `${building.buildingName} / ${providerName}`;
          setManagementName(mgmtBuildingName);
        }

        // 담당자 팝업에 보여줄 담당자 목록
        setMgmtOfficerList(managementBuilding.officerList || []);
      }

      setLoadingBar(false);
    },
    [getBuilding, getBuildingManagement, getProviderDetail],
  );

  // 회원 상세 api
  const getMemberDetailApi = useCallback(
    async (id?: string) => {
      setLoadingBar(true);
      let reporter = "";

      if (id) {
        const response = await getMemberDetailAsync({ id });
        if (response.status >= 200 && response.status <= 299) {
          const member = response?.data?.data?.member;
          const findIndexAt = member.email.indexOf("@");
          const emailName = member.email.slice(0, findIndexAt);
          reporter = `(회원) ${formatPhoneNumber(member.phoneNumber || "")} / ${member.email}`;

          setValue("reporterName", member.nickname || emailName);
          setValue("reporterPhone", parsedPhoneNumber(member.phoneNumber) || "");
          setValue("reporterEmail", member.email || "");
          setValue("reporterId", member.memberNo || "");

          setReporterPhoneEmail(reporter);
        }
      }

      setLoadingBar(false);
    },
    [getMemberDetailAsync, setLoadingBar, setValue],
  );

  //워크오더 상세 api - 수정 form set Value
  const getWorkOrderDetailApi = useCallback(
    async (id: string) => {
      if (id) {
        setLoadingBar(true);

        const response = await getWorkOrderDetail({ id: Number(id) });
        if (response.status >= 200 && response.status <= 299) {
          const workOrder = response.data.data.content;

          fetchPartner(String(workOrder.partnerId));

          // 상세 api 에서 내려온 특정 데이터와 변경되는 값 비교
          const dataForCompare: FrontWorkOrderUpdateModel = {
            workOrderType: workOrder.workOrderType,
            workOrderPriority: Number(workOrder.workOrderPriority),
            reporterId: workOrder.reporterId,
            reporterName: workOrder.reporterName,
            reporterPhone: workOrder.reporterPhone,
            reporterEmail: workOrder.reporterEmail,
            reporterBuildingName: workOrder.reporterBuildingName,
            reporterBuildingAddr: workOrder.reporterBuildingAddr,
            mgmtOfficeId: workOrder.mgmtOfficeId,
            mgmtOfficerId: String(workOrder.mgmtOfficerId),
            startDate: workOrder.startDate,
            endDate: workOrder.endDate,
            summary: workOrder.summary,
            description: workOrder.description,
            completionReport: workOrder.completionReport,
            workOrderSheetCostList: workOrder.workOrderSheetCostList,
            mediaList: workOrder.mediaList,
            customerNotification: workOrder.customerNotification,
            status: workOrder.status,
            isReopened: workOrder.isReopened,
            createdDate: workOrder.createdDate,
            workOrderSheetGroupId: workOrder.workOrderSheetGroupId,
            workOrderSheetNo: workOrder.workOrderSheetNo,
            isSendAlarm: workOrder.isSendAlarm,
          };

          setFrontOriginalData(dataForCompare);

          //
          // 워크오더 상세 데이터 setValue
          Object.entries(workOrder).forEach(([name, value]: any) => {
            // if(name === "")
            switch (name) {
              case "mgmtOfficeId":
                setMgmtOfficeId(value);
                setValue(name, value);

                return;
              case "mgmtOfficerId":
                setMgmtOfficerId(value);
                setValue(name, value);

                return;
              case "reporterId":
                setReporterId(value);
                setValue(name, value);

                return;

              case "workOrderSheetCostList":
                if ((value as Array<FrontWorkOrderSheetCostType>) && value.length === 0) {
                  setValue("workOrderSheetCostList", [{ cost: 0, description: "", cmdType: "C" }]);
                } else {
                  const costList = value
                    .sort(
                      (a: FrontWorkOrderSheetCostType, b: FrontWorkOrderSheetCostType) =>
                        Number(a.id) - Number(b.id),
                    )
                    .map((item: WorkOrderSheetCost) => {
                      return { ...item, apiId: item.id };
                    });
                  setValue("workOrderSheetCostList", costList);
                }
                return;
            }

            setValue(name, value);
          });

          // 워크오더 이미지들 setState
          if (workOrder.mediaList && workOrder.mediaList?.length > 0) {
            const workMedias = //업무내용 이미지
              workOrder.mediaList?.filter(
                (media) => media.mediaServiceType === "MEDIA_WORK_ORDER_SHEET",
              ) || [];

            const workCompletedMedias = //처리내용 이미지
              workOrder.mediaList?.filter(
                (media) => media.mediaServiceType === "MEDIA_WORK_ORDER_SHEET_COMPLETED_REPORT",
              ) || [];

            const costMedias = //처리비용 이미지
              workOrder.mediaList?.filter(
                (media) => media.mediaServiceType === "MEDIA_WORK_ORDER_SHEET_COST",
              ) || [];

            setWorkOrderMedias(workMedias);
            setWorkOrderCompletedMedias(workCompletedMedias);
            setWorkOrderCostMedias(costMedias);
          }
        }
      }
      setLoadingBar(false);
    },
    [getWorkOrderDetail, setLoadingBar, setValue],
  );

  // 워크오더 ID가 있으면(수정) 상세 api 호출
  useEffect(() => {
    if (workOrderId) {
      getWorkOrderDetailApi(workOrderId);
    }
  }, [getWorkOrderDetailApi, workOrderId]);

  //
  // 관리처 id || 담당자id 가 있으면 관리처 상세 api 호출
  useEffect(() => {
    // 접수자 id 가 있으면 회원 상세 api 호출
    if (reporterId) {
      getMemberDetailApi(reporterId);
    }
  }, [getMemberDetailApi, reporterId]);

  useEffect(() => {
    if (mgmtOfficeId) {
      getBuildingManagementApi(mgmtOfficeId);
    }
  }, [getBuildingManagementApi, mgmtOfficeId]);

  // 유효성 검사
  const formValidation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";

    //업무 구분
    register("workOrderType", {
      required: { value: true, message: requiredMessage },
    });

    // 우선 순위
    register("workOrderPriority", {
      validate: {
        required: (value) => {
          let isValue = false;
          if (value !== 0) {
            isValue = true;
          }
          return isValue || requiredMessage;
        },
      },
    });

    // 건물/관리처
    register("mgmtOfficeId", {
      required: { value: true, message: requiredMessage },
    });

    // 업무일정
    register("startDate", {
      required: { value: true, message: requiredMessage },
    });
    register("endDate", {
      required: { value: true, message: requiredMessage },
    });

    // 담당자
    register("mgmtOfficerId", {
      required: { value: true, message: requiredMessage },
    });

    // 업무일정

    if (dirtyFields.isRecurrence) {
      const isRecurrence = watch("isRecurrence");
      if (isRecurrence === true) {
        register("recurrenceEndDate", {
          required: { value: true, message: requiredMessage },
        });

        register("recurrenceType", {
          validate: {
            required: (value) => {
              let isRecurrenceType = false;

              if (value) isRecurrenceType = true;

              if (value === RecurrenceType.WEEKLY) {
                register("recurrenceDayOfWeekTypeList", {
                  required: { value: true, message: requiredMessage },
                });
              }
              return isRecurrenceType || requiredMessage;
            },
          },
        });
      }
    }

    // 워크시트 제목
    register("summary", {
      required: { value: true, message: requiredMessage },
    });

    // 워크시트 내용
    register("description", {
      required: { value: true, message: requiredMessage },
    });

    // 파트너
    register("partnerId", {
      required: { value: true, message: requiredMessage },
    });

    // 기획 요청으로 처리내용 필수여부 해제
    // 처리내용
    // if (workOrderId) {
    //   register("completionReport", {
    //     validate: {
    //       required: (value) => {
    //         let isRequired = true;
    //         let message = "";

    //         if (
    //           watch("status") === WorkOrderStatus.OPEN ||
    //           watch("status") === WorkOrderStatus.WAITING
    //         ) {
    //           return isRequired;
    //         } else {
    //           if (!value) {
    //             message = requiredMessage;
    //             isRequired = false;
    //           }
    //         }

    //         return isRequired || message;
    //       },
    //     },
    //   });
    // }
  }, [dirtyFields.isRecurrence, register, watch]);

  //
  // 상세 데이터와 비교 후 변경된 데이터만 추출 -> 수정시 변경된 데이터만 전송
  const makeRequestEditData = useCallback(
    (data: FrontWorkOrderUpdateModel) => {
      const original = frontOriginalData;

      let editData = {};

      // 원본 데이터, 변경된 데이터 비교 -> 원본 데이터와 다르면 true 출력

      // 업무구분
      const isWorkOrderType = _.isEqual(original?.workOrderType, data.workOrderType);

      // 우선 순위
      const isWorkOrderPriority = _.isEqual(
        original?.workOrderPriority,
        Number(data.workOrderPriority),
      );

      // 접수자 id
      const isReporterId = _.isEqual(original?.reporterId, data.reporterId);

      // 접수자 이름
      const isReporterName = _.isEqual(original?.reporterName, data.reporterName);

      // 접수자 전화번호
      const isReporterPhone = _.isEqual(original?.reporterPhone, data.reporterPhone);

      // 접수자 전화번호
      const isReporterEmail = _.isEqual(original?.reporterEmail, data.reporterEmail);

      // 접수자 위치 - 건물
      const isReporterBuildingName = _.isEqual(
        original?.reporterBuildingName,
        data.reporterBuildingName,
      );

      // 접수자 위치 - 주소
      const isReporterBuildingAddr = _.isEqual(
        original?.reporterBuildingAddr,
        data.reporterBuildingAddr,
      );

      // 관리처 id
      const isMgmtOfficeId = _.isEqual(original?.mgmtOfficeId, data.mgmtOfficeId);
      // 관리처 담당자 id
      const isMgmtOfficerId = _.isEqual(original?.mgmtOfficerId, data.mgmtOfficerId);

      // 시작일
      const isStartDate = _.isEqual(original?.startDate, data.startDate);

      // 종료일
      const isEndDate = _.isEqual(original?.endDate, data.endDate);

      // 업무제목
      const isSummary = _.isEqual(original?.summary, data.summary);

      // 업무설명
      const isDescription = _.isEqual(original?.description, data.description);

      // 처리 내용
      const isCompletionReport = _.isEqual(original?.completionReport, data.completionReport);

      // 상태
      const isStatus = _.isEqual(original?.status, data.status);

      // 재처리요청
      const isReopen = _.isEqual(original?.isReopened, data.isReopened);

      const isSendAlarm = _.isEqual(original?.isSendAlarm, data.isSendAlarm);

      // 처리비용 비교
      // 처리비용 데이터가 있으면 데이터들끼리 비교,
      let isWorkOrderSheetCostList = false;
      if (original?.workOrderSheetCostList && original.workOrderSheetCostList.length > 0) {
        //data?.workOrderSheetCostList 안에 apiId 제거하고  original과 비교
        const changedWorkOrderCosts = data?.workOrderSheetCostList?.map(
          (item: FrontWorkOrderSheetCostType) => {
            if (item.cmdType) {
              return {
                id: item.id,
                description: item.description,
                cost: item.cost,
                isDeleted: null,
                cmdType: item.cmdType,
              };
            } else {
              return {
                id: item.id,
                description: item.description,
                cost: item.cost,
                isDeleted: null,
              };
            }
          },
        );

        isWorkOrderSheetCostList = _.isEqual(
          original?.workOrderSheetCostList,
          changedWorkOrderCosts,
        );
      } else {
        // 신규 등록 후 첫 수정시엔 처리비용 데이터가 없으므로 기본값과 비교
        isWorkOrderSheetCostList = _.isEqual(
          [{ cost: 0, description: "", cmdType: "C" }],
          data.workOrderSheetCostList,
        );
      }

      // 고객답변
      const isCustomerNotification = _.isEqual(
        original?.customerNotification,
        data.customerNotification,
      );

      const isMediaList = _.isEqual(
        original?.mediaList?.sort(
          (a: MediaFile, b: MediaFile) => Number(a.id || 0) - Number(b.id || 0),
        ),
        data.mediaList?.sort((a: MediaFile, b: MediaFile) => Number(a.id || 0) - Number(b.id || 0)),
      );

      // =============================================================
      // 변경된 데이터가 있을경우, (false 리턴일시) - editData 에 변경된 데이터 적용
      //
      // 워크오더 타입
      if (!isWorkOrderType) editData = { ...editData, workOrderType: data.workOrderType };

      // 우선순위
      if (!isWorkOrderPriority)
        editData = { ...editData, workOrderPriority: data.workOrderPriority };

      // 상태
      if (!isStatus) {
        editData = { ...editData, status: data.status };
      }

      // 재처리 요청
      if (!isReopen) {
        editData = { ...editData, isReopened: data.isReopened };
      }

      // 접수자 id
      if (!isReporterId) editData = { ...editData, reporterId: data.reporterId };

      // 접수자 이름
      if (!isReporterName) editData = { ...editData, reporterName: data.reporterName };

      //접수자 번호
      if (!isReporterPhone) editData = { ...editData, reporterPhone: data.reporterPhone };

      //접수자 이메일
      if (!isReporterEmail) editData = { ...editData, reporterEmail: data.reporterEmail };

      // 접수자 건물 위치
      if (!isReporterBuildingName)
        editData = { ...editData, reporterBuildingName: data.reporterBuildingName };

      // 접수자 건물 주소
      if (!isReporterBuildingAddr)
        editData = { ...editData, reporterBuildingAddr: data.reporterBuildingAddr };

      // 관리처 id
      if (!isMgmtOfficeId) editData = { ...editData, mgmtOfficeId: data.mgmtOfficeId };

      // 관리처 담당자 id
      if (!isMgmtOfficerId) editData = { ...editData, mgmtOfficerId: data.mgmtOfficerId };
      // 시작일
      if (!isStartDate) editData = { ...editData, startDate: data.startDate };

      // 종료일
      if (!isEndDate) editData = { ...editData, endDate: data.endDate };
      // 제목
      if (!isSummary) editData = { ...editData, summary: data.summary };
      // 업무설명
      if (!isDescription) editData = { ...editData, description: data.description };
      // 처리내용
      if (!isCompletionReport) editData = { ...editData, completionReport: data.completionReport };

      // 고객 답변용
      if (!isCustomerNotification)
        editData = { ...editData, customerNotification: data.customerNotification };

      //  처리비용 부분 수정
      if (!isWorkOrderSheetCostList) {
        // 변경된 데이터값 찾기
        const workOrderSheetCostList = _.differenceWith(
          data.workOrderSheetCostList,
          original?.workOrderSheetCostList || [],
          _.isEqual,
        );

        editData = {
          ...editData,
          workOrderSheetCostList: workOrderSheetCostList.map((item) => {
            // 수정 데이터 request 전 apiId 삭제
            if (item.apiId) delete item.apiId;

            // 처리 금액에 빈 값 수정시 0으로 request
            if (!item.cost === true) {
              item = { ...item, cost: 0 };
            }

            if (!item.cmdType) {
              item = { ...item, cmdType: "U" };
            }
            return item;
          }),
        };
      }

      // 업무알림톡 부분 수정
      if (!isSendAlarm) {
        editData = { ...editData, isSendAlarm: data.isSendAlarm };
      }

      // 이미지 수정부분
      if (!isMediaList) {
        // list 서로 순서맞게 sort 적용
        const dataMedia = data.mediaList?.sort(
          (a: MediaFile, b: MediaFile) => Number(a.id || 0) - Number(b.id || 0),
        );

        const originalMedia = original?.mediaList?.sort(
          (a: MediaFile, b: MediaFile) => Number(a.id || 0) - Number(b.id || 0),
        );

        // 변경된 데이터 찾기
        const mediaList = _.differenceWith(dataMedia, originalMedia || [], _.isEqual);

        //
        // 원본/변경 데이터 비교하여 삭제된 이미지 찾기
        const compareDeleteMedias = _.differenceWith(originalMedia, dataMedia || [], _.isEqual).map(
          (item) => {
            return { ...item, cmdType: "D" };
          },
        );

        if (compareDeleteMedias.length > 0) {
          editData = {
            ...editData,
            mediaList: compareDeleteMedias.map((item: MediaFile) => {
              return { ...item, cmdType: "D" };
            }),
          };
        }

        const newMediaList = [...(mediaList || []), ...compareDeleteMedias];

        editData = {
          ...editData,
          mediaList: newMediaList.map((media: MediaFile) => {
            if (media.cmdType === "C" || media.cmdType === "D") {
              return media;
            } else {
              return { ...media, cmdType: "U" };
            }
          }),
        };
      }

      return editData;
    },
    [frontOriginalData],
  );

  //
  //
  // 컨펌모달 저장 클릭시 등록/수정
  const onClickConfirmModal = useCallback(
    async (data: FrontWorkOrderUpdateModel) => {
      //
      if (data) {
        setLoadingBar(true);
        // 신규 등록
        if (!workOrderId) {
          const response = await postWorkOrder({ workOrderSheet: data });

          if (response.status >= 200 && response.status <= 299) {
            setConfirmModal({ isOpen: false });
            openToast(`정상적으로 ${"등록"}되었습니다.`);
            navigate(
              `${PagePath.workOrder.detail.replace(
                ":id",
                String(response.data.data.content[0]?.id),
              )}`,
            );
          }
        } else {
          const editData = makeRequestEditData(data);
          console.log("editData", editData);

          if (Object.keys(editData).length === 0) {
            setAlertModal({ isOpen: true, message: "변경사항 없이\n 저장할 수 없습니다." });
            setLoadingBar(false);
            return;
          }

          const response = await editWorkOrder({ id: String(data.id), workOrderSheet: editData });
          if (response.status >= 200 && response.status <= 299) {
            setConfirmModal({ isOpen: false });
            openToast(`정상적으로 ${"수정"}되었습니다.`);
            navigate(
              `${PagePath.workOrder.detail.replace(
                ":id",
                String(response.data.data.content[0]?.id),
              )}`,
            );
          }
        }
        setLoadingBar(false);
      }

      setLoadingBar(false);
    },
    [
      editWorkOrder,
      makeRequestEditData,
      navigate,
      openToast,
      postWorkOrder,
      setLoadingBar,
      workOrderId,
    ],
  );

  useEffect(() => {
    formValidation();
  }, [formValidation, dirtyFields]);
  // 유효성 검사 후 저장 실행
  const onSubmit = useCallback(
    async (data: FrontWorkOrderUpdateModel, e?: any) => {
      e.preventDefault();

      // 넘어온 객체 복사
      let updateData = { ...data };

      const mergedMedias = [
        ...workOrderMedias,
        ...workOrderCostMedias,
        ...workOrderCompletedMedias,
      ];

      // 등록할 사진이 있으면 mediaList 추가
      if (mergedMedias.length > 0) {
        const newMediaList = mergedMedias.map((media) => {
          if (!media.id) {
            return {
              ...media,
              cmdType: "C",
            };
          } else {
          }
          return media;
        });

        let addMediaList = {
          ...updateData,
          mediaList: newMediaList,
        };

        updateData = addMediaList;
      }

      if (!updateData.isRecurrence) {
        delete updateData.recurrenceDayOfWeekTypeList;
        delete updateData.recurrenceEndDate;
        delete updateData.recurrenceType;
      }

      setConfirmModal({ isOpen: true, message: "저장하시겠습니까?", payload: updateData });
    },
    [workOrderCompletedMedias, workOrderCostMedias, workOrderMedias],
  );
  // validation 통과하지 못하고 error 발생시 실행
  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);

    return false;
  }, []);

  // 반복설정 -> 반복주기 확인 api
  const getWorkOrderPreviewApi = useCallback(async () => {
    const startDate = watch("startDate"); // 시간일
    const recurrenceEndDate = watch("recurrenceEndDate"); // 반복종료일
    const recurrenceType = watch("recurrenceType"); // 반복종료타입 (주/월/일)
    const isHoliday = watch("isHoliday");
    if (startDate && recurrenceEndDate && recurrenceType) {
      setLoadingBar(true);
      const previewParam: WorkOrderPreviewRequest = {
        recurrenceType: String(recurrenceType),
        startDate: String(startDate),
        recurrenceEndDate: String(recurrenceEndDate),
        isHoliday: Boolean(isHoliday),
      };

      if (dirtyFields.recurrenceType) {
        if (recurrenceType === RecurrenceType.WEEKLY) {
          const newPreviewParam = {
            ...previewParam,
            recurrenceDayOfWeekTypeList: watch("recurrenceDayOfWeekTypeList")?.join(","),
          };

          const response = await getWorkOrderPreview(newPreviewParam);

          if (response.status >= 200 && response.status <= 299) {
            const result = response.data.data.content;
            setPreviewList(result);
          }
        } else {
          const response = await getWorkOrderPreview(previewParam);

          if (response.status >= 200 && response.status <= 299) {
            const result = response.data.data.content;
            setPreviewList(result);
          }
        }
        setLoadingBar(false);
      }
    }
  }, [dirtyFields.recurrenceType, getWorkOrderPreview, setLoadingBar, watch]);
  // const titleLengthChecker = (titleName: string) => {
  //   let name = titleName;
  //   console.log(titleName, titleName.length);
  //   if (titleName && titleName.length > 10) {
  //     name = titleName.substring(0, 9) + "...";
  //   }
  //   return name;
  // };
  // let title = titleLengthChecker(frontOriginalData?.summary || "");

  useTitleOperation(frontOriginalData?.summary);

  return (
    <div className="contents-container__wrap">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__wrap-contents">
          <ContentsIdSection
            title={`워크시트 ${workOrderId ? "수정" : "생성"}`}
            id={workOrderId}
            noTitle={(frontOriginalData?.workOrderSheetGroupId && "그룹id") || ""}
            no={frontOriginalData?.workOrderSheetGroupId || ""}
            thirdTitle="워크시트번호"
            thirdNo={`${frontOriginalData?.workOrderSheetNo?.slice(
              0,
              8,
            )}-${frontOriginalData?.workOrderSheetNo?.slice(8, 13)}`}
          />
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">파트너</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <span className={partner && `mr10`}>
                  {partner && `${partner?.name} (${partner.code})`}
                </span>
                {!workOrderId && (
                  <BaseButton
                    title="파트너 선택"
                    className="color-white"
                    onClick={() => {
                      setPartnerModal({ isOpen: true });
                    }}
                  />
                )}
              </div>
              <p className="validation-text">{errors.partnerId?.message}</p>
            </div>
          </section>

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">업무 구분</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax200">
                <Controller
                  control={control}
                  name={"workOrderType"}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseSelect
                      className="mr8"
                      stateOptions={workTypeOptions}
                      setStateValue={(value: string) => onChange(value)}
                      value={value}
                      name={name}
                      errorText={error?.message}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </section>

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">우선 순위</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax200">
                <Controller
                  control={control}
                  name={"workOrderPriority"}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseSelect
                      className="mr8"
                      stateOptions={workOrderPriorityOptions}
                      setStateValue={(value: string) => onChange(value)}
                      value={value}
                      name={name}
                      errorText={error?.message}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </section>

          {workOrderId && (
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">상태</p>
              </div>
              <div className="contents-container__grid-contents d-flex">
                <div className="minmax200">
                  <Controller
                    control={control}
                    name={"status"}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseSelect
                        className="mr8"
                        stateOptions={workOrderStatusOptions}
                        setStateValue={onChange}
                        value={value}
                        name={name}
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
                {workOrderId && frontOriginalData?.isReopened === false && (
                  <div className="flex-center-center ml20">
                    <Controller
                      control={control}
                      name={"isReopened"}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseCheckbox
                          id={name}
                          name={name}
                          checked={value}
                          label="재처리 요청"
                          onChange={(checked: boolean) => {
                            onChange(checked);
                            setValue("status", WorkOrderStatus.OPEN);
                          }}
                        />
                      )}
                    ></Controller>
                  </div>
                )}
                {workOrderId && frontOriginalData?.isReopened === true && (
                  <div className="flex-center-center ml10">
                    <span className="text-red  border-gray   px10 py5">재처리 요청</span>
                  </div>
                )}
              </div>
            </section>
          )}

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>접수자</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400 flex-row flex-center">
                <BaseInput
                  className="mr8"
                  type="text"
                  readonly
                  placeholder="접수자 조회"
                  value={
                    reporterId
                      ? reporterPhoneEmail
                      : reporterInfoText(
                          watch("reporterName") || "",
                          watch("reporterPhone") || "",
                          watch("reporterEmail") || "",
                        ) || ""
                  }
                />
                <BaseButton
                  title="검색"
                  onClick={() => {
                    setInfoModal({ isOpen: true, type: "REPORTER" });
                  }}
                />
              </div>
            </div>
          </section>

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>접수자 위치</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400 flex-row flex-center">
                <BaseInput
                  className="mr8"
                  type="text"
                  readonly
                  placeholder="접수자 위치 조회"
                  value={reporterLocationText() || ""}
                />
                <BaseButton
                  title={"검색"}
                  onClick={() => {
                    setInfoModal({ isOpen: true, type: "LOCATION" });
                  }}
                />
              </div>
            </div>
          </section>

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">건물/관리처</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400 flex-row flex-center">
                <BaseInput
                  className="mr8"
                  type="text"
                  readonly
                  placeholder="건물/관리처 조회"
                  value={managementName || ""}
                />
                <BaseButton
                  title={"검색"}
                  onClick={() => {
                    if (partner) {
                      setInfoModal({ isOpen: true, type: "BUILDING" });
                    } else {
                      setAlertModal({ isOpen: true, message: "파트너를 먼저 선택해주세요." });
                    }
                  }}
                />
              </div>
              <p className="validation-text">{errors.mgmtOfficeId?.message}</p>
            </div>
          </section>

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">담당자</p>
            </div>
            <div className="flex-center">
              <div className="contents-container__grid-contents">
                <div className="minmax400 flex-row flex-center">
                  <BaseInput
                    className="mr8"
                    type="text"
                    readonly
                    placeholder="담당자 지정"
                    value={
                      mgmtOfficerId &&
                      mgmtOfficerList &&
                      officerInfoText(mgmtOfficerId, mgmtOfficerList)
                    }
                  />
                  <BaseButton
                    title={"검색"}
                    onClick={() => {
                      if (mgmtOfficeId !== "") {
                        setInfoModal({ isOpen: true, type: "OFFICER" });
                      } else {
                        setAlertModal({ isOpen: true, message: "건물/관리처를 선택해주세요." });
                      }
                    }}
                  />
                </div>
                <p className="validation-text">{errors?.mgmtOfficerId?.message}</p>
              </div>
              <div className="ml20 d-flex">
                <Controller
                  control={control}
                  name={"isSendAlarm"}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseCheckbox
                      id={name}
                      name={name}
                      checked={value || false}
                      label="업무 알림톡을 발송하겠습니다."
                      onChange={(checked: boolean) => {
                        onChange(checked);
                        // setValue("status", WorkOrderStatus.OPEN);
                      }}
                    />
                  )}
                ></Controller>
                <BaseQuestionMarkTooltip className="ml3">
                  <ul className="font14 pl20 keep-all">
                    {workOrderTooltips.alam.map((value, idx) => (
                      <li key={idx} className="mb5">
                        {value}
                      </li>
                    ))}
                  </ul>
                </BaseQuestionMarkTooltip>
              </div>
            </div>
          </section>

          <section className="contents-container__grid mb30">
            <div className="contents-container__grid-index">
              <p className="required">업무 일정</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400 flex-row flex-center-start">
                <p className="minmax80  ">시작일</p>
                <Controller
                  control={control}
                  name={"startDate"}
                  render={({ field: { onChange, value, name } }) => (
                    <BaseDatePicker
                      className="minmax180"
                      setDate={(date: string) => {
                        if (date !== "" && date !== null && date !== "Invalid date") {
                          let startDate = moment(date).format("YYYY-MM-DD") + "T00:00:00+09:00";

                          onChange(startDate);
                        }
                      }}
                      minDate={
                        workOrderId
                          ? frontOriginalData && moment(frontOriginalData.createdDate).toDate()
                          : moment().toDate()
                      }
                      selectedDate={(value && moment(value).toDate()) || null}
                      placeholder="업무 시작일을 선택하세요"
                    />
                  )}
                ></Controller>
                {watch("startDate") && (
                  <div className="font16 ml10">
                    <span>{onChangeKoreanDays(watch("startDate"))}</span>
                  </div>
                )}
                <span className="mx10">~</span>
                <p className="minmax80  ">처리 기한</p>

                <Controller
                  control={control}
                  name={"endDate"}
                  render={({ field: { onChange, value, name } }) => (
                    <BaseDatePicker
                      className="minmax180"
                      name={name}
                      minDate={moment(watch("startDate")).toDate()}
                      setDate={(date: string) => {
                        if (date !== "" && date !== null && date !== "Invalid date") {
                          let endDate = moment(date).format("YYYY-MM-DD") + "T23:59:59+09:00";
                          onChange(endDate);
                        }
                      }}
                      selectedDate={(value && moment(value).toDate()) || null}
                      placeholder="업무 종료일을 선택하세요"
                    />
                  )}
                ></Controller>
                {watch("endDate") && (
                  <div className="font16 ml10">
                    <span>{onChangeKoreanDays(watch("endDate"))}</span>
                  </div>
                )}
              </div>
              <p className="validation-text">
                {(errors.startDate || errors.endDate) && "필수입력 항목입니다"}
              </p>

              {/* 워크오더 id 가 있으면 수정상태 -> 반복설정은 첫 등록시만 노출 */}
              {!workOrderId && (
                <div className="minmax400 flex-row flex-center">
                  <div className="d-flex mt20">
                    <p className="mr20">반복 설정</p>
                    <Controller
                      control={control}
                      name={"isRecurrence"}
                      render={({ field: { onChange, value, name } }) => (
                        <BaseToggle
                          onChange={(checked: boolean) => {
                            onChange(checked);

                            if (!checked) {
                              resetField("recurrenceEndDate");
                              resetField("recurrenceType");
                              resetField("recurrenceDayOfWeekTypeList");
                            }
                          }}
                          checked={value}
                          name={name}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              )}

              {/* 반복설정 체크시 노출 */}
              {watch("isRecurrence") === true && (
                <>
                  <div className="minmax800 flex-row flex-center">
                    <div className="flex-center-center mt20 mr20">
                      <p className="minmax80  ">반복 단위</p>
                      <Controller
                        control={control}
                        name={"recurrenceType"}
                        render={({ field: { onChange, value, name } }) => (
                          <BaseSelect
                            className="mr8"
                            name={name}
                            value={value}
                            stateOptions={cycleOptions}
                            setStateValue={(selectedValue: string) => {
                              resetField("recurrenceDayOfWeekTypeList");
                              onChange(selectedValue);
                            }}
                          />
                        )}
                      ></Controller>
                    </div>

                    <div className="flex-center-center mt20">
                      <p className="minmax80  ">반복 종료일</p>
                      <Controller
                        control={control}
                        name={"recurrenceEndDate"}
                        render={({ field: { onChange, value, name } }) => (
                          <BaseDatePicker
                            minDate={moment(watch("startDate")).toDate()}
                            name={name}
                            selectedDate={(value && moment(value).toDate()) || null}
                            setDate={(date: string) => {
                              if (date !== "" && date !== null && date !== "Invalid date") {
                                let recurrenceEndDate =
                                  moment(date).format("YYYY-MM-DD") + "T23:59:59+09:00";
                                onChange(recurrenceEndDate);
                              }
                            }}
                          />
                        )}
                      ></Controller>
                      {watch("startDate") && (
                        <div className="font16 ml10">
                          <span>{onChangeKoreanDays(watch("recurrenceEndDate"))}</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="minmax400 flex-row flex-center">
                    {watch("recurrenceType") === RecurrenceType.WEEKLY && (
                      <div className="d-flex mt20">
                        <p className="minmax80">반복 요일</p>
                        {dayOptions.map((day, idx: number) => (
                          <div key={day.value}>
                            <Controller
                              control={control}
                              name={"recurrenceDayOfWeekTypeList"}
                              render={({ field: { onChange, value, name } }) => (
                                <BaseCheckbox
                                  id={`${day.value}`}
                                  name={`${day.value}`}
                                  className="chip-case mr4"
                                  label={day.label}
                                  checked={value?.some((item) => item === day.value) || false}
                                  disabled={
                                    !watch("isHoliday") &&
                                    (day.value === "SAT" || day.value === "SUN")
                                  }
                                  onChange={(checked: boolean) => {
                                    let weeks: UnionDayOfWeekType[] =
                                      watch("recurrenceDayOfWeekTypeList") || [];

                                    if (checked) {
                                      weeks.push(day.value);
                                    } else {
                                      weeks = weeks.filter((week) => week !== day.value);
                                    }
                                    onChange(weeks);
                                  }}
                                />
                              )}
                            ></Controller>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {(errors.recurrenceEndDate ||
                    errors.recurrenceType ||
                    errors.recurrenceDayOfWeekTypeList) && (
                    <p className="validation-text">필수입력 사항입니다</p>
                  )}

                  <div className="minmax400 flex-row flex-center mt20">
                    <div className="flex-center minmax120">
                      <p className="">주말/공휴일</p>

                      <BaseQuestionMarkTooltip className="ml3">
                        <ul className="font14 pl15 keep-all">
                          {workOrderTooltips.holiday.map((value, idx) => (
                            <li key={idx} className="mb5">
                              {value}
                            </li>
                          ))}
                        </ul>
                      </BaseQuestionMarkTooltip>
                    </div>
                    <div>
                      <Controller
                        control={control}
                        name={"isHoliday"}
                        render={({ field: { onChange, value, name } }) => (
                          <BaseCheckbox
                            label="포함"
                            onChange={(checked) => {
                              onChange(checked);
                              if (watch("recurrenceType") === "WEEKLY" && !checked) {
                                const filteredHoliday = watch(
                                  "recurrenceDayOfWeekTypeList",
                                )?.filter((day) => day !== "SAT" && day !== "SUN");
                                setValue("recurrenceDayOfWeekTypeList", filteredHoliday);
                              }
                            }}
                            id={name}
                            name={name}
                            checked={value || false}
                          />
                        )}
                      ></Controller>
                    </div>
                  </div>

                  <div className="minmax400 flex-row flex-center">
                    <div className="flex-center-center mt20">
                      <p className="minmax80">반복 주기</p>
                      <BaseInput readonly className="mr5" value={previewList.length} />
                      <BaseButton
                        title="확인"
                        onClick={() => {
                          if (
                            dirtyFields.isRecurrence ||
                            dirtyFields.startDate ||
                            dirtyFields.recurrenceEndDate ||
                            dirtyFields.recurrenceType
                          ) {
                            const isRecurrence = watch("isRecurrence"); //반복설정 여부
                            if (isRecurrence) {
                              getWorkOrderPreviewApi();
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                  <p className="font12 mt8 text-blue">
                    *반복 설정으로 생성된 워크시트의 처리 기한은 설정한 '업무 시작일'과 '처리
                    기한'의 일자 차이를 기준으로 자동 설정됩니다.
                  </p>
                </>
              )}
            </div>
          </section>

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">워크시트 제목</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400 flex-row flex-center">
                <Controller
                  control={control}
                  name={"summary"}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseInput
                      className="mr8"
                      placeholder="제목을 입력하세요."
                      onChange={(value: string) => onChange(value)}
                      value={value}
                      name={name}
                      errorText={error?.message}
                      maxLength={45}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </section>

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">워크시트 내용</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <Controller
                  control={control}
                  name={"description"}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseTextarea
                      className="mr8"
                      maxLength={1000}
                      placeholder="상세 내용을 입력하세요."
                      onChange={(value: string) => onChange(value)}
                      value={value || ""}
                      name={name}
                      errorText={error?.message}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </section>

          {/* 이미지 등록 */}
          <section className="contents-container__grid">
            <div className="contents-container__grid-index"></div>
            <div className="contents-container__grid-contents">
              <ImagesUpdate
                fileMaxLength={3}
                mediaFiles={workOrderMedias.filter(
                  (media) => media.mediaServiceType === WorkOrderMediaType.WORK,
                )}
                onChangeMediaFiles={setWorkOrderMedias}
                categoryName={WorkOrderMediaType.WORK}
                mediaServiceType="MEDIA_WORK_ORDER_SHEET"
              />

              {/* <WorkOrderImages /> */}
            </div>
          </section>

          {/* 수정 시 노출되는 영역들 - 처리내용 / 처리 비용 / 고객답변 / 각 이미지 */}
          {workOrderId && (
            <WorkOrderEditSection
              control={control}
              workOrderCompletedMedias={workOrderCompletedMedias}
              setWorkOrderCompletedMedias={setWorkOrderCompletedMedias}
              workOrderCostMedias={workOrderCostMedias}
              setWorkOrderCostMedias={setWorkOrderCostMedias}
              watch={watch}
              workOrderDetail={frontOriginalData}
            />
          )}
        </div>

        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              className="color-white size-large"
              onClick={() => {
                // 등록화면에서는 목록에서 진입했기 때문에 뒤로가기
                navigate("/mng/central/workOrder");
              }}
            />
          </div>
          <div className="right-area">
            {frontOriginalData?.status !== "WORK_ORDER_CANCELED" ? (
              <BaseButton type="submit" title="저장" className="size-large" />
            ) : (
              <p className="text-red font16">취소된 워크오더 입니다.</p>
            )}
          </div>
        </div>
      </form>
      <>
        {/* 저장시 확인 모달 */}
        <BaseModal
          isOpen={confirmModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => {
            onClickConfirmModal(confirmModal.payload && confirmModal.payload);
            setConfirmModal({ isOpen: false });
          }}
        >
          {confirmModal.message}
        </BaseModal>

        <BaseModal
          isOpen={alertModal.isOpen}
          btnRightTitle="확인"
          onClick={() => setAlertModal({ isOpen: false })}
        >
          {alertModal.message}
        </BaseModal>

        {/* 접수자 팝업  */}
        {infoModal.isOpen && infoModal.type === "REPORTER" && (
          <ReporterModal
            setInfoModal={setInfoModal}
            setValue={setValue}
            setReporterId={setReporterId}
            resetField={resetField}
            onSelected={(member) => {
              const reporterId = member.memberNo;
              setReporterId(reporterId);
            }}
          />
        )}

        {/* 접수자 위치 */}
        {infoModal.isOpen && infoModal.type === "LOCATION" && (
          <ReporterLocationModal
            setInfoModal={setInfoModal}
            onSelected={(building) => {
              setValue("reporterBuildingName", building.buildingName);
              setValue("reporterBuildingAddr", building.address);
            }}
          />
        )}

        {/* 건물/관리처 팝업  */}
        {infoModal.isOpen && infoModal.type === "BUILDING" && (
          <ManagementBuildingModal
            setInfoModal={setInfoModal}
            partner={partner}
            onSelected={(building) => {
              if (building) {
                setValue("mgmtOfficeId", building.mgmtOfficeId, {
                  shouldDirty: true,
                  shouldValidate: true,
                });

                setValue("mgmtOfficerId", "");
                setMgmtOfficeId(String(building.mgmtOfficeId));
                setInfoModal({
                  isOpen: false,
                });
              }
            }}
          />
        )}

        {/* 담당자 팝업 */}
        {infoModal.isOpen && infoModal.type === "OFFICER" && (
          <OfficerModal
            control={control}
            mgmtOfficerList={mgmtOfficerList}
            onSelected={(officer) => {
              setValue("mgmtOfficerId", officer.id, {
                shouldDirty: true,
                shouldValidate: true,
              });
              setMgmtOfficerId && setMgmtOfficerId(String(officer.id));
              setInfoModal({
                isOpen: false,
              });
            }}
            setInfoModal={setInfoModal}
          />
        )}

        {/* 파트너 팝업 */}
        {partnerModal.isOpen && (
          <PartnerSelectModal
            onCanceled={() => setPartnerModal({ isOpen: false })}
            onAdded={(selected) => {
              const selectedPartner = selected[0];

              if (partner && partner.id !== selectedPartner.id) {
                setPartnerChangeModal({
                  isOpen: true,
                  message: "파트너를 변경하시면 입력하신 정보가 초기화됩니다.",
                  payload: selectedPartner,
                });
                setPartnerModal({ isOpen: false });
              } else {
                setPartner(selectedPartner);
                setValue("partnerId", selectedPartner.id);
                setPartnerModal({ isOpen: false });
              }
            }}
            defaultValues={partner !== undefined ? [partner] : []}
          />
        )}

        <BaseModal
          isOpen={partnerChangeModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClick={() => {
            onResetUseState();
            setPartner(partnerChangeModal.payload);
            setValue("partnerId", partnerChangeModal.payload.id);
            setPartnerChangeModal({ isOpen: false });
            setPartnerModal({ isOpen: false });
          }}
          onClose={() => setPartnerChangeModal({ isOpen: false })}
        >
          {partnerChangeModal.message}
        </BaseModal>
      </>
    </div>
  );
};

export default BasicInfoForm;
