import qs from "qs";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { UseFormResetField, UseFormSetValue } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  Cell,
  useBlockLayout,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useApiOperation } from "src/api/hooks";
import { getMembers } from "src/api/member/member-api";
import { MemberModel } from "src/api/member/member-types";
import { Modal, Order, PageMeta } from "src/api/public-types";
import { WorkOrderAddModel } from "src/api/workOrder/workOrder-types";
import { BaseButton, BaseInput, BaseModal, BasePagination, BaseSelect } from "src/components";
import { MemberStatusCell } from "src/pages/member/components/MemberStatusCell";
import TableRadioButton from "src/pages/product/components/TableRadioButton";
import {
  reporterOptions,
  ReporterType,
  UnionReporterType,
} from "src/pages/workOrder/workOrder-types";
import { formatPhoneNumber, onlyNumber, parsedPhoneNumber, validateEmail } from "src/utils";

type Props = {
  data?: any;
  setValue: UseFormSetValue<WorkOrderAddModel>;
  setInfoModal: (modal: Modal) => void;
  setReporterId?: Dispatch<SetStateAction<string>>;
  resetField?: UseFormResetField<WorkOrderAddModel>;
  onSelected: (member: MemberModel) => void;
};

export type MemberParams = {
  id?: number | string;
  accountStateStatus?: string;
  keyword?: string;
  searchType?: string;
  containsPhoneNumber?: string;
  containsMemberName?: string;
  containsEmail?: string;
  containsMemberNo?: string;
  containsNickname?: string;
  search001?: string;
  search002?: string;
  notEmptyMemberNo?: boolean;
  page?: number;
  size?: number;
  sort?: {
    orders?: Array<Order>;
  };
  role?: string;
};

const ReporterModal = ({ onSelected, setValue, setInfoModal, setReporterId }: Props) => {
  const location = useLocation();

  const queryParams: MemberParams = useMemo(() => {
    const _queryParams: MemberParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }

    if (!_queryParams?.role) {
      _queryParams.role = "ROLE_MEMBER";
    }

    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, [location.search]);

  const [alertMessage, setAlertMessage] = useState("");

  const [params, setParams] = useState<MemberParams>({ ...queryParams });
  const [members, setMembers] = useState<MemberModel[]>([]); // 회원목록
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [reporter, setReporter] = useState<UnionReporterType | string>(""); // 접수자 유형
  const [pageNum, setPageNum] = useState(0);
  const [guestPhone, setGuestPhone] = useState("");
  const [guestName, setGuestName] = useState("");
  const [guestEmail, setGuestEmail] = useState("");

  //
  // 접수자 팝업 테이블 Columns
  const ReporterColumn: any[] = useMemo(() => {
    return [
      {
        Header: "회원번호",
        accessor: "memberNo",
        width: 150,
        Cell: ({ row }: Cell<MemberModel>) => {
          return (
            <div>
              <span>{row?.original?.memberNo}</span>
            </div>
          );
        },
      },
      {
        Header: "휴대폰 번호",
        accessor: "phoneNumber",
        width: 220,
        Cell: ({ row }: Cell<MemberModel>) => formatPhoneNumber(row.original.phoneNumber),
      },
      {
        Header: "이메일",
        accessor: "email",
        width: 300,
      },
      {
        Header: "계정상태",
        accessor: "accountState",
        width: 100,
        Cell: (props: Cell<MemberModel>) => <MemberStatusCell {...props} />,
      },
    ];
  }, []);

  // 회원목록 api
  const { executeAsync: getMembersApi, loading } = useApiOperation(getMembers);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, selectedFlatRows } =
    useTable(
      {
        columns: ReporterColumn,
        data: members,
      },
      useBlockLayout,
      useGlobalFilter,
      useSortBy,
      useGlobalFilter,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns: Array<any>) => [
          {
            id: "selection",
            width: 70,
            Header: () => <div className="">선택</div>,
            Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
              let currentState = row.getToggleRowSelectedProps();
              return (
                <div className="minmax100">
                  <TableRadioButton
                    id={row.id}
                    onClick={() => {
                      toggleAllRowsSelected(false);
                      toggleRowSelected(row.id, true);
                    }}
                    name="selectedOrg"
                    {...currentState}
                  />
                </div>
              );
            },
          },
          ...columns,
        ]);
      },
    );

  // 비회원 유효성 검사
  const guestReporterValidation = useCallback(
    (name: string, phone: string, email: string) => {
      const patterns = /^010\d{8}$/; //핸드폰 정규식
      const isMobile = patterns.test(phone);

      // 접수자가 비회원일 경우
      if (reporter && reporter === ReporterType.GUEST) {
        if (!name) {
          setAlertMessage("필수입력 항목입니다.");
          return;
        }
        if (phone && !isMobile) {
          setAlertMessage("휴대폰 번호 형식에 맞게 입력해주세요");
          return;
        }
        // - 기획 요청으로 이메일 유효성 적용 X

        if (email && !validateEmail(email)) {
          setAlertMessage("이메일 형식에 맞게 작성해주세요.");
          return;
        }

        // 모든 조건 충족시 실행
        setReporterId && setReporterId("");
        setValue("reporterId", "");
        setValue("reporterName", name);
        setValue("reporterPhone", parsedPhoneNumber(phone) || "");
        setValue("reporterEmail", email);
        setInfoModal({ isOpen: false });
      }
    },
    [reporter, setInfoModal, setReporterId, setValue],
  );

  // 회원목록 api
  useEffect(() => {
    async function fetchMemberList(memberListParams: MemberParams) {
      const { data } = await getMembersApi(memberListParams);
      if (data) {
        setMembers(data?.data?.content || []);
        setPageMeta(data?.meta?.pageMeta);
      }
    }

    let memberListParams: MemberParams = {
      page: queryParams.page,
      size: queryParams.size,
      sort: queryParams.sort,
      role: queryParams.role,
    };

    if (queryParams.searchType && queryParams.searchType !== "") {
      if (queryParams.searchType === "id") {
        memberListParams.id = queryParams.keyword;
      } else if (queryParams.searchType === "containsMemberName") {
        memberListParams.containsMemberName = queryParams.keyword;
      } else if (queryParams.searchType === "containsEmail") {
        memberListParams.containsEmail = queryParams.keyword;
      } else if (queryParams.searchType === "containsPhoneNumber") {
        memberListParams.containsPhoneNumber = queryParams.keyword;
      } else if (queryParams.searchType === "containsMemberNo") {
        memberListParams.containsMemberNo = queryParams.keyword;
      } else if (queryParams.searchType === "containsNickname") {
        memberListParams.containsNickname = queryParams.keyword;
      }
    } else {
      memberListParams.search001 = queryParams.keyword || "";
    }

    // 접수자 유형이 회원일 경우 목록 api 실행
    if (reporter === ReporterType.MEMBER) {
      fetchMemberList(memberListParams);
    }
  }, [getMembersApi, queryParams, reporter]);

  // 팝업내 목록 검색
  const onSearchList = useCallback(
    async (page?: number) => {
      const searchParams: MemberParams = {
        page,
        size: params.size,
        sort: queryParams.sort,
        role: queryParams.role,
      };

      if (params.searchType && params.searchType !== "") {
        if (params.searchType === "containsMemberName") {
          searchParams.containsMemberName = params.keyword;
        } else if (params.searchType === "containsEmail") {
          searchParams.containsEmail = params.keyword;
        } else if (params.searchType === "containsPhoneNumber") {
          searchParams.containsPhoneNumber = params.keyword;
        } else if (params.searchType === "containsMemberNo") {
          searchParams.containsMemberNo = params.keyword;
        }
      } else {
        searchParams.search001 = params.keyword || "";
      }

      const response = await getMembersApi(searchParams);

      if (response.status >= 200 && response.status <= 299) {
        setMembers(response.data.data.content);
        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getMembersApi, params, queryParams],
  );

  // 팝업 회원목록 페이지네이션
  const memberPagenation = useCallback(
    (passParams?: MemberParams) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const page = newQueryParams.page;
      setPageNum(Number(page));
      onSearchList(page);
      setReporter("REPORTER_MEMBER");
    },
    [onSearchList, params],
  );
  if (loading) {
    return <p>로딩중...</p>;
  }
  return (
    <BaseModal
      isOpen={true}
      className="dialog-modal"
      btnLeftTitle="취소"
      btnRightTitle={reporter === ReporterType.MEMBER ? "확인" : ""}
      onClick={() => {
        const selected = selectedFlatRows[0].original;
        onSelected(selected);
        setInfoModal({ isOpen: false });
      }}
      onClose={() => {
        setInfoModal({ isOpen: false });
      }}
    >
      <>
        <div className="modal-title">
          <div className="left-area">
            <span>접수자 조회/입력</span>
          </div>
          <div className="d-flex">
            {reporter === ReporterType.MEMBER && (
              <div className="minmax200 mr10">
                <BaseInput
                  placeholder="검색어를 입력하세요"
                  onChange={(keyword: string) => setParams({ ...params, keyword })}
                  value={params.keyword}
                  onKeyUp={() => memberPagenation({ page: 0 })}
                  onSearchClick={() => memberPagenation({ page: 0 })}
                />
              </div>
            )}

            <div className="minmax150">
              <BaseSelect
                stateOptions={reporterOptions}
                setStateValue={(value: string) => {
                  setReporter(value);
                }}
                value={reporter}
                placeholder="접수자 유형 선택"
              />
            </div>
          </div>
        </div>

        {!reporter ? (
          <p>접수자 유형을 선택하세요.</p>
        ) : reporter === ReporterType.MEMBER ? (
          <div className="contents-container__table">
            <div {...getTableProps()} className="base-table sticky ">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div
                          {...header.getHeaderProps(header.getSortByToggleProps())}
                          className="base-table__th"
                        >
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              {reporter === ReporterType.MEMBER && members.length && members.length > 0 ? (
                <div {...getTableBodyProps()} className="body">
                  {rows.map((row, idx: number) => {
                    prepareRow(row);
                    return (
                      <div {...row.getRowProps()} className="base-table__tr">
                        {row.cells.map((cell) => {
                          return (
                            <div {...cell.getCellProps()} className="base-table__td ">
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <>
                  <p className="mt10">접수자가 없습니다.</p>
                </>
              )}
            </div>
            <div style={{ padding: "-20px" }}>
              <BasePagination
                pageIndex={pageNum || 0}
                totalPages={pageMeta?.totalPages || 0}
                goPage={(page: number) => memberPagenation({ page })}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="ma20  d-flex">
              <div className="minmax100 mr5">
                <BaseInput
                  className=""
                  placeholder="이름"
                  onChange={(text: string) => setGuestName(text)}
                  value={guestName}
                  maxLength={15}
                />
              </div>
              <div className="mr5">
                <BaseInput
                  className=""
                  placeholder="연락처"
                  onChange={(text: string) => setGuestPhone(onlyNumber(text))}
                  value={guestPhone}
                  maxLength={12}
                />
              </div>
              <div className="mr5 minmax200">
                <BaseInput
                  className=""
                  placeholder="이메일"
                  onChange={(text: string) => setGuestEmail(text)}
                  value={guestEmail}
                  maxLength={30}
                />
              </div>
              <div>
                <BaseButton
                  title={"등록"}
                  onClick={() => {
                    guestReporterValidation(guestName || "", guestPhone || "", guestEmail || "");
                  }}
                />
              </div>
            </div>
            <p className="text-blue flex-center-start pl20">
              * 고객 답변 알림톡이 입력한 연락처로 발송되오니 정확하게 입력해 주세요.
            </p>
          </div>
        )}
        <div className="flex-center-start ml20">
          <p className="validation-text">{alertMessage}</p>
        </div>
      </>
    </BaseModal>
  );
};

export default ReporterModal;
