/* eslint-disable array-callback-return */

import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  changeRecieve,
  connectReceive,
  editTaxInvoiceNumber,
  getCreditRelation,
  retryPayment,
} from "src/api/billingSchedule/billingSchedule-api";
import { getProviderAccountCredits } from "src/api/contract/contract-api";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { contract_bill_user_errors } from "src/api/public-types";
import {
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
  BaseInput,
  BaseModal,
  BaseTooltip,
} from "src/components";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { numberToStringWithComma, YmdFormat } from "src/utils";
import StopModal from "../modal/StopModal";

const ScheduleInfoColumns: any = [
  {
    Header: "구분",
    accessor: "bill.billPayType",
    sticky: "left",
    width: 100,
    Cell: ({ value, row }: any) => {
      return <p>{value === "PAY" ? "일반결제" : "세금계산"}</p>;
    },
  },
  {
    Header: "회차",
    accessor: "bill.billOrder",
    sticky: "left",
    width: 70,
    Cell: ({ value, row }: any) => {
      return <div>{value ? value : "-"} </div>;
    },
  },
  {
    Header: "사용연월",
    accessor: "bill.billEndTime",
    sticky: "left",
    width: 90,
    Cell: ({ value, row }: any) => {
      // console.log("row", row.original);
      const useYear = moment(row.original.bill.billStartTime).format(YmdFormat.YYYY_MM);
      return <p>{useYear}</p>;
    },
  },
  {
    Header: "사용기간",
    accessor: "bill.billStartTime",
    // sticky: "left",
    width: 170,
    Cell: ({ value, row }: any) => {
      // console.log("row", row.original);
      const start = moment(row.original.bill.billStartTime).format(YmdFormat.YYYY_MM_DD);
      const end = moment(row.original.bill.billEndTime).format(YmdFormat.YYYY_MM_DD);
      return <p>{start + " ~ " + end}</p>;
    },
    Footer: ({ rows }: any) => {
      return <p>합계 : </p>;
    },
  },
  {
    Header: () => {
      return (
        <p>
          기준금액
          <br />
          (원)
        </p>
      );
    },
    accessor: "bill.baseAmount",
    // sticky: "left",
    width: 120,
    Cell: ({ value }: any) => {
      return <p>{numberToStringWithComma(value)}</p>;
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.map((row: any) => {
        sum = sum + Number(row.original.bill.baseAmount);
      });
      return <p>{numberToStringWithComma(sum)}</p>;
    },
  },
  {
    Header: () => {
      return (
        <p>
          할인/할증
          <br />
          금액(원)
        </p>
      );
    },
    accessor: "bill.adjustAmount",
    // sticky: "left",
    width: 120,
    Cell: ({ value }: any) => {
      return <p>{numberToStringWithComma(value)}</p>;
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.map((row: any) => {
        sum = sum + Number(row.original.bill.adjustAmount);
      });
      return <p>{numberToStringWithComma(sum)}</p>;
    },
  },
  {
    Header: () => {
      return (
        <p>
          청구금액
          <br />
          (원)
        </p>
      );
    },
    accessor: "bill.totalAmount",
    // sticky: "left",
    width: 120,
    Cell: ({ value }: any) => {
      return <p>{numberToStringWithComma(value)}</p>;
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.map((row: any) => {
        sum = sum + Number(row.original.bill.totalAmount);
      });
      return <p>{numberToStringWithComma(sum)}</p>;
    },
  },

  // {
  //   Header: "청구번호",
  //   accessor: "bill.billNumber",
  //   width: 120,
  //   Cell: ({ value, row }: any) => {
  //     return <div>{value ? value : "-"} </div>;
  //   },
  // },
  {
    Header: "청구일자",
    accessor: "bill.billIssueDate",
    // sticky: "left",
    width: 100,
    Cell: ({ value }: any) => {
      return <div>{value ? moment(value).format(YmdFormat.YYYY_MM_DD) : "-"} </div>;
    },
  },
  {
    Header: "납부기한",
    accessor: "bill.billDueDate",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row }: any) => {
      let payLimitDay = row.original.bill.billDueDate;
      return <div>{payLimitDay ? moment(payLimitDay).format(YmdFormat.YYYY_MM_DD) : "-"} </div>;
    },
  },
  {
    Header: () => {
      return (
        <p>
          세금계산서
          <br />
          승인번호
        </p>
      );
    },

    accessor: "billInvoiceList",
    width: 180,
    Cell: ({ value, row, scheduleId, callList }: any) => {
      const [view, setView] = useState(false);
      const [taxInvoice, setTaxInvoice] = useState<any>({ invoiceNumber: "" });
      const [isError, setIsError] = useState(false);
      const [error, setError] = useState("");
      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
      const checker = moment(today).isSameOrAfter(moment(billDate));
      const [original, setOriginal] = useState<any>();

      const { executeAsync: changeTaxInvoice } = useApiOperation(editTaxInvoiceNumber);

      useEffect(() => {
        if (row.original.billInvoiceList !== null && row.original.billInvoiceList.length > 0) {
          const tax_none = row.original.billInvoiceList.find(
            (invoice: any) => invoice.invoiceType === "TAX_PLUS",
          );
          setTaxInvoice(tax_none);
          setOriginal(tax_none);
        }
      }, [row.original.billInvoiceList]);

      const saveTaxInvoice = useCallback(async () => {
        const pattern = /[a-zA-Z0-9]{24}/;
        if (taxInvoice.invoiceNumber === "") {
          setError("국세청 승인번호는 필수 입력 정보입니다.");
          setIsError(true);
          return;
        }

        if (pattern.test(taxInvoice.invoiceNumber) === false) {
          setError("올바르지 않은 국세청 승인번호 형식입니다.");
          setIsError(true);
          return;
        }

        const send = _.cloneDeep(taxInvoice);
        send.billId = row.original.bill.billId;
        send.invoiceType = "TAX_PLUS";

        const response = await changeTaxInvoice({ scheduleId, data: send });

        if (response.status > 199 && response.status < 300) {
          setView(false);
          setError("국세청 승인번호가 변경되었습니다.");
          setIsError(true);
          callList(scheduleId);
        }
      }, [callList, changeTaxInvoice, row.original.bill.billId, scheduleId, taxInvoice]);

      return (
        <div className="d-flex">
          <div className="flex-center">
            <p className="mr10">{taxInvoice ? taxInvoice.invoiceNumber : "-"}</p>
            {checker && (
              <div>
                <BaseButton
                  title="수정"
                  className="color-white size-small"
                  disabled={Number(row.original.bill.totalAmount) === 0}
                  onClick={() => setView(true)}
                />

                <BaseModal
                  className="dialog-modal"
                  isOpen={view}
                  btnLeftTitle="취소"
                  btnRightTitle="확인"
                  onClose={() => {
                    setView(false);

                    setTaxInvoice(original);
                  }}
                  onClick={() => saveTaxInvoice()}
                >
                  <>
                    <div className="modal-title">
                      <div className="left-area">
                        <span>세금계산서 발행 정보 확인</span>
                      </div>
                      <div className="right-area"></div>
                    </div>
                    <div className="contents-scroll">
                      <table className="inner-table" width="100%">
                        <tbody>
                          <tr>
                            <td colSpan={2}>
                              <div className="text-left font12 text-primary5">
                                <p>* 팝빌에서 자동 발행 된 ‘국세청승인번호’ 가 출력됩니다.</p>
                                <p>
                                  * 홈택스를 통해 발행/재발행 시 ‘국세청승인번호(24자리)’ 를 등록
                                  해주세요.
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td width="180">
                              <div className="flex-center ">
                                <span className="text-primary3">국세청 승인번호</span>
                              </div>
                            </td>
                            <td>
                              <div className="py10">
                                <div className="minmax300 mb8">
                                  <BaseInput
                                    name={"taxInvoiceNumber"}
                                    value={String(taxInvoice?.invoiceNumber)}
                                    onChange={(value: string) => {
                                      const temp = _.cloneDeep(taxInvoice);
                                      temp.invoiceNumber = value;
                                      setTaxInvoice(temp);
                                    }}
                                    maxLength={24}
                                    // disabled={true}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                </BaseModal>
              </div>
            )}
          </div>
          <BaseModal isOpen={isError} btnRightTitle="확인" onClick={() => setIsError(false)}>
            <p>{error}</p>
          </BaseModal>
        </div>
      );
    },
  },
  // {
  //   Header: () => {
  //     return (
  //       <p>
  //         계산서
  //         <br />
  //         승인번호
  //       </p>
  //     );
  //   },

  //   accessor: "billInvoice.ntsConfirmNum",
  //   width: 120,
  //   Cell: ({ value, row }: any) => {
  //     return <div>{value ? value : "-"} </div>;
  //   },
  // },
  {
    Header: "전송일자",
    accessor: "sendDate",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row }: any) => {
      let show = "-";
      if (row.original.billInvoice !== null) {
        if (row.original.bill.billPayType === "TAX") {
          // show = row.original.billInvoice.createdDate;
        }
      }
      return show !== "-" ? <BaseTooltip contents={show} type={"date"} /> : show;
    },
  },
  // {
  //   Header: "MgtKey",
  //   accessor: "bill.MgtKey",
  //   // sticky: "left",
  //   width: 100,
  //   Cell: ({ value, row }: any) => {
  //     return <div>{value ? value : "-"} </div>;
  //   },
  // },
  // {
  //   Header: "국세청 승인번호",
  //   accessor: "bill.ntsConfirmNum",
  //   // sticky: "left",
  //   width: 130,
  //   Cell: ({ value, row }: any) => {
  //     return <div>{value ? value : "-"} </div>;
  //   },
  // },
  {
    Header: "tid",
    accessor: "payment.tid",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row }: any) => {
      return <div>{row.original.bill.billPayType === "PAY" && value ? value : "-"} </div>;
    },
  },
  {
    Header: "cid",
    accessor: "payment.cid",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row }: any) => {
      return <div>{row.original.bill.billPayType === "PAY" && value ? value : "-"} </div>;
    },
  },
  {
    Header: "즉시결제",
    accessor: "instantPayment",
    // sticky: "left",
    width: 100,
    Cell: ({
      value,
      row,
      rows,
      callList,
      contractBaseInfo,
      setLoadingBar,
      basicInfo,
      schedule,
      scheduleId,
    }: any) => {
      const [prevStatus, setPrevStatus] = useState<any>("");
      const params = useParams();
      const { openErrorModal } = useErrorModalContext();
      let message = "";

      const [error, setError] = useState("");
      const [isError, setIsError] = useState(false);
      const [isChange, setIsChange] = useState(false);

      const { executeAsync: rtp } = useApiOperation(retryPayment, { doNotErrorHandleModal: true });

      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);

      const retryPay = useCallback(async () => {
        // console.log("row.original.bill.id", row.original);
        const askReturn: any = await rtp({ billId: Number(row.original.bill.billId) });
        if (askReturn.status >= 200 && askReturn.status <= 299) {
          message = "즉시결제가 정상처리 되었습니다.";
          await callList(Number(scheduleId));
          setIsChange(false);
          setError(message);
          setIsError(true);
        } else {
          let message = errorMessageHandler(
            askReturn.status,
            askReturn.data.meta.errorCode,
            contract_bill_user_errors,
          );
          let code = askReturn.data.meta.errorCode
            ? askReturn.data.meta.errorCode
            : askReturn.status;
          openErrorModal(message, code);
        }
      }, [row.original, rtp]);
      const onClickPgnBillKeyCheck = useCallback(() => {
        if (basicInfo.pgcode === "" || basicInfo.billkey === "") {
          setError("사용자가 결제 수단을 아직 등록하지 않아 즉시 결제를 할 수 없는 상태입니다.");
          setIsError(true);
          return;
        }
        setIsChange(true);
      }, [basicInfo]);

      const instantPayment = useCallback(async () => {
        try {
          setLoadingBar(true);
          await retryPay();
        } catch (e) {
          setLoadingBar(false);
        } finally {
          setLoadingBar(false);
        }
      }, [retryPay, setLoadingBar]);

      const checkPrevStatus = useMemo(() => {
        let temp = _.cloneDeep(prevStatus);
        const finded = rows.find((r: any) => Number(r.id) === Number(row.id) - 1);

        if (finded) {
          //console.log(finded.original.bill.billPayStatus);
          temp = finded.original.bill.billPayStatus;
          setPrevStatus(finded.original.bill.billPayStatus);
        }
        return temp;
      }, [prevStatus, row.id, rows]);

      const getBillKey = useMemo(() => {
        let billKey = undefined;
        const find = basicInfo.scheduleList.find((obj: any) => obj.supplyType === "RENTAL");
        if (find) {
          return find.billkey;
        }

        return billKey;
      }, [basicInfo]);

      return (
        <div>
          {row.original.bill.billPayType === "PAY" &&
          moment(today).isSameOrAfter(moment(billDate)) &&
          (row.original.bill.billPayStatus !== "PAYMENT_SUCCESS" ||
            row.original.bill.billPayStatus !== "PAYMENT_FREE") ? (
            <BaseButton
              title="즉시결제"
              className="color-white size-small"
              onClick={() => onClickPgnBillKeyCheck()}
              disabled={
                getBillKey === undefined ||
                row.original.receiptBundle.receiptList.length > 0 ||
                row.original.bill.billPayStatus === "PAYMENT_SUCCESS" ||
                row.original.bill.billPayStatus === "PAYMENT_FAIL" ||
                moment(today).isSameOrBefore(moment(billDate)) ||
                (row.original.bill.billPayStatus === "PAYMENT_READY" &&
                (checkPrevStatus === "" || checkPrevStatus === "PAYMENT_SUCCESS")
                  ? false
                  : true)
              }
              // disabled={true}
            />
          ) : (
            <p>-</p>
          )}
          <BaseModal
            isOpen={isChange}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setIsChange(false)}
            onClick={() => instantPayment()}
          >
            <div>
              <p>즉시 결제하시겠습니까?</p>
            </div>
          </BaseModal>
          <BaseModal isOpen={isError} btnRightTitle="확인" onClick={() => setIsError(false)}>
            <p>{error}</p>
          </BaseModal>
        </div>
      );
    },
  },

  {
    Header: "수납확인",
    accessor: "recieve",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row, rows, callList, contractBaseInfo, setLoadingBar, scheduleId }: any) => {
      const params = useParams();
      const [isDelete, setIsDelete] = useState(false);
      const { openErrorModal } = useErrorModalContext();
      const { executeAsync: changeSuccess } = useApiOperation(changeRecieve, {
        doNotErrorHandleModal: true,
      });
      const { executeAsync: getAccountCredits } = useApiOperation(getProviderAccountCredits, {
        doNotErrorHandleModal: true,
      });
      const { executeAsync: addAccountCredits } = useApiOperation(connectReceive, {
        doNotErrorHandleModal: true,
      });

      const { executeAsync: getAccCredit } = useApiOperation(getCreditRelation, {
        doNotErrorHandleModal: true,
      });

      let message = "";

      const [search, setSearch] = useState({
        billId: Number(row.original.bill.billId),
        scheduleId: scheduleId,
        startDateTime: "",
        endDateTime: "",
      });
      // const sorted = _.sortBy(sample, "trDateTime").reverse();
      const [receiveData, setReceiveData] = useState<any>([]);
      const [error, setError] = useState("");
      const [isChange, setIsChange] = useState(false);
      const [isError, setIsError] = useState(false);
      const [relation, setRelation] = useState<any>([]);
      const [receiveList, setReceiveList] = useState<any>([]);
      const [delReceiveList, setDelReceiveList] = useState<any>([]);
      const [prevStatus, setPrevStatus] = useState<any>("");
      const [etc, setEtc] = useState<any>({});

      const [errorText] = useState("");
      const [errorTax] = useState("");

      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);

      const [sum] = useState(0);
      const [ovSum] = useState(0);

      const fetchAccounts = useCallback(
        async (search: any) => {
          // console.log("search", search);
          const response: any = await getAccountCredits(search);
          //console.log("getAccount", response);
          if (response.status >= 200 && response.status <= 299) {
            response.data.data.content.forEach((accData: any) => (accData.isOpen = false));
            setReceiveData(response.data.data.content);
          }
        },
        [getAccountCredits, setReceiveData],
      );

      const getCredit = useCallback(
        async (tid: any) => {
          const response: any = await getAccCredit({ tid });
          //console.log("getCreadit", response);
          if (response.status >= 200 && response.status <= 299) {
            setRelation(response.data.data.content.creditRelationBundle);
          }
        },
        [getAccCredit],
      );

      const addCredits = useCallback(
        async (credits: any) => {
          return await addAccountCredits({
            contractId: contractBaseInfo.contractId,
            body: credits,
          });
        },
        [addAccountCredits, contractBaseInfo],
      );

      const makeSuccess = useCallback(async () => {
        const temp = _.cloneDeep(receiveList);

        let checkPrice = false;
        temp.forEach((save: any) => {
          if (Number(save.receiptAmount) === 0) {
            checkPrice = true;
          }
        });

        if (checkPrice) {
          setIsError(true);
          setError("입금내역 선택 시 수납 금액은 필수 입력 정보입니다.");
          return;
        }
        //console.log("etc", etc);

        temp.forEach((save: any) => {
          save.trDateTime = moment(save.trDateTime).format(YmdFormat.WITH_TIME_ZONE);
        });

        if (
          etc &&
          etc.receiptAmount !== null &&
          etc.receiptAmount !== undefined &&
          Number(etc.receiptAmount) !== 0 &&
          (etc.trDateTime === null || etc.trDateTime === undefined || String(etc.trDateTime) === "")
        ) {
          setIsError(true);
          setError("기타입금 시 거래일시 입력은 필수입니다.");
          return;
        }

        if (
          etc &&
          etc.trDateTime !== null &&
          etc.trDateTime !== undefined &&
          String(etc.trDateTime) !== "" &&
          (etc.receiptAmount === null ||
            etc.receiptAmount === undefined ||
            Number(etc.receiptAmount) === 0)
        ) {
          setIsError(true);
          setError("기타입금 시 수납 금액 입력은 필수입니다.");
          return;
        }

        if (Number(makeSum) > Number(row.original.receiptBundle.billTotalAmount)) {
          setIsError(true);
          setError("입금 총액은 수납처리 전 잔액보다 클 수 업습니다.");
          return;
        }

        if (
          row.original.bill.billPayType === "PAY" &&
          Number(etc.receiptAmount) !== Number(row.original.receiptBundle.billTotalAmount)
        ) {
          setIsError(true);
          setError("카드결제형은 부분수납을 허용하지 않습니다.");
          return;
        }

        if (etc.receiptAmount && etc.trDateTime) {
          const insertReceipt: any = {
            receiptId: 0,
            billId: row.original.bill.billId,
            acctCreditId: 0,
            paymentId: 0,
            receiptAmount: Number(etc.receiptAmount),
            trDateTime: moment(etc.trDateTime).format(YmdFormat.WITH_TIME_ZONE),
          };
          temp.push(insertReceipt);
        }

        const sendData: any = [...temp, ...delReceiveList];

        // console.log("delReceiveList", delReceiveList);
        // console.log("sendData", sendData);

        if (sendData.length > 0) {
          const send: any = {
            scheduleId: Number(scheduleId),
            body: sendData,
          };
          setIsChange(false);
          // console.log("send", send);
          setLoadingBar(true);
          const response: any = await changeSuccess(send);
          // console.log(response);

          if (response.status >= 200 && response.status <= 299) {
            message = "저장 되었습니다.";
            setIsError(true);
            setError(message);
            await callList(scheduleId);
            firstSettings();
          } else {
            let message = errorMessageHandler(
              response.status,
              response.data.meta.errorCode,
              contract_bill_user_errors,
            );
            let code = response.data.meta.errorCode
              ? response.data.meta.errorCode
              : response.status;
            openErrorModal(message, code);
          }

          setLoadingBar(false);
        } else {
          setIsError(true);
          setError("저장 내용이 없습니다. 수납 내역을 입력해주세요");
          return;
        }
      }, [changeSuccess, setLoadingBar, callList, params]);

      const firstSettings = () => {
        setEtc({ receiptAmount: 0, trDateTime: "" });
        setReceiveList(row.original.receiptBundle.receiptList);
      };

      const checkPrevStatus = useMemo(() => {
        let temp = _.cloneDeep(prevStatus);
        const finded = rows.find((r: any) => Number(r.id) === Number(row.id) - 1);

        if (finded) {
          //console.log(finded.original.bill.billPayStatus);
          temp = finded.original.bill.billPayStatus;
          setPrevStatus(finded.original.bill.billPayStatus);
        }
        return temp;
      }, [prevStatus, row.id, rows]);

      const sortedRecevieList = useMemo(() => {
        const temp = _.cloneDeep(receiveList);
        let sorted: any = [];
        if (temp.length > 0) {
          sorted = _.orderBy(
            temp,
            [(receipt: any) => moment(receipt.trDateTime).toDate()],
            ["desc"],
          );
        }
        //console.log("sortedRecevieList");

        return sorted;
      }, [receiveList]);

      useEffect(() => {
        if (isChange) {
          const dump = _.cloneDeep(search);
          if (dump.startDateTime === "") {
            let startT = moment().format(YmdFormat.YYYY_MM_DD);

            let addTime = startT + " 00:00:00";
            dump.startDateTime = moment(addTime)
              .subtract("7", "days")
              .format(YmdFormat.WITH_TIME_ZONE);
          }
          if (dump.endDateTime === "") {
            let endT = moment().format(YmdFormat.YYYY_MM_DD);

            let addTime = endT + " 23:59:59";

            dump.endDateTime = moment(addTime).format(YmdFormat.WITH_TIME_ZONE);
          }

          setSearch(dump);
          fetchAccounts(dump);
          firstSettings();
          setDelReceiveList([]);
          setEtc({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isChange]);

      const makeSum = useMemo(() => {
        let s = _.cloneDeep(sum);
        if (receiveList.length > 0) {
          receiveList.forEach((rp: any) => {
            s += Number(rp.receiptAmount);
          });
        }
        if (etc?.receiptAmount && Number(etc?.receiptAmount) !== 0) {
          s = Number(s) + Number(etc.receiptAmount);
        }
        return s;
      }, [etc.receiptAmount, receiveList, sum]);

      const makeOvSum = useMemo(() => {
        let s = _.cloneDeep(ovSum);
        if (receiveList.length > 0) {
          receiveList.forEach((rp: any) => {
            s += Number(rp.overdueAmount);
          });
        }

        return s;
      }, [receiveList, ovSum]);
      const totalSum = useMemo(() => {
        let realtimeOverdueAmount = 0;

        if (row.original.receiptBundle.realtimeOverdueAmount !== undefined) {
          realtimeOverdueAmount += Number(row.original.receiptBundle.realtimeOverdueAmount);
        }

        if (row.original.receiptBundle.accruedOverdueAmount !== undefined) {
          realtimeOverdueAmount += Number(row.original.receiptBundle.accruedOverdueAmount);
        }

        return realtimeOverdueAmount;
      }, [row.original.receiptBundle]);

      const changeFlag = (value: boolean, index: number) => {
        const dump = _.cloneDeep(receiveData);
        const ndump: any = dump.map((dp: any, idx: number) => {
          if (index === idx) {
            dp.acctCredit.isBillCredit = value;
          }
          return dp;
        });

        if (ndump && ndump.length > 0) {
          setReceiveData(ndump);
        }
      };

      return (
        <div>
          {moment(today).isSameOrAfter(moment(billDate)) ? (
            <div>
              <BaseButton
                title={
                  row.original.receiptBundle.receiptList.length > 0
                    ? row.original.receiptBundle.billUnpaidAmount === 0
                      ? "수납확인"
                      : "수정"
                    : "수납처리"
                }
                className="color-white size-small"
                disabled={
                  (row.original.bill.billPayStatus === "PAYMENT_SUCCESS" ||
                    row.original.bill.billPayStatus === "PAYMENT_FAIL" ||
                    row.original.bill.billPayStatus === "PAYMENT_READY") &&
                  (checkPrevStatus === "" || checkPrevStatus === "PAYMENT_SUCCESS")
                    ? false
                    : true || row.original.bill.billTotalAmount === 0
                }
                onClick={() => setIsChange(true)}
              />
              <BaseModal
                className="dialog-modal"
                isOpen={isChange}
                btnLeftTitle="취소"
                btnRightTitle={
                  row.original.bill.billPayStatus === "PAYMENT_SUCCESS"
                    ? "확인"
                    : row.original.receiptBundle.receiptList.length > 0
                    ? "수정"
                    : "수납처리"
                }
                onClose={() => setIsChange(false)}
                onClick={() =>
                  row.original.bill.billPayStatus === "PAYMENT_SUCCESS"
                    ? setIsChange(false)
                    : makeSuccess()
                }
              >
                <>
                  <div className="modal-title">
                    <div className="left-area">
                      <span>빌링 수납처리</span>
                    </div>
                    <div className="right-area"></div>
                  </div>
                  <div className="contents-scroll">
                    <table className="inner-table" width="100%">
                      <tbody>
                        <tr>
                          <td colSpan={2}>
                            <div className="flex-center text-left">
                              <span className="font16">
                                입금/수납 정보
                                <p className="font12 text-primary5">
                                  * 입금내역 선택 후 수납내역의 입금액을 해당 회차의 청구금액으로
                                  수정가능합니다.
                                </p>
                                <p className="font12 text-primary5">
                                  * 입금내역 다중 선택 시,가장 최신일자를 수납일자로 출력합니다.
                                </p>
                                <p className="font12 text-primary5">
                                  * 입금 조회 기간은 최대 3개월 단위로 가능합니다.
                                </p>
                              </span>
                            </div>
                          </td>
                        </tr>

                        {row.original.bill.billPayType === "TAX" && (
                          <tr>
                            <td width="180">
                              <div className="flex-center ">
                                <span className="text-primary3">입금내역</span>
                              </div>
                            </td>
                            <td>
                              <div className="flex-center-between py10 ">
                                <span>
                                  총 <b>{receiveData.length}</b>건
                                </span>
                                <div className="flex-center">
                                  <div className="minmax200">
                                    <BaseDatePicker
                                      name={"searchStart"}
                                      selectedDate={
                                        search.startDateTime
                                          ? moment(search.startDateTime).toDate()
                                          : moment().toDate()
                                      }
                                      setDate={(value: Date) => {
                                        const dump = _.cloneDeep(search);
                                        let startT = moment(value).format(YmdFormat.YYYY_MM_DD);

                                        let addTime = startT + " 00:00:00";

                                        dump.startDateTime = moment(addTime).format(
                                          YmdFormat.WITH_TIME_ZONE,
                                        );

                                        let endT = moment(value)
                                          .add(3, "M")
                                          .subtract(1, "days")
                                          .format(YmdFormat.YYYY_MM_DD);

                                        let today = moment().format(YmdFormat.YYYY_MM_DD);
                                        let endTime = endT + " 23:59:59";
                                        if (moment(endT).isAfter(moment(today))) {
                                          endTime = today + " 23:59:59";
                                        }

                                        dump.endDateTime = moment(endTime).format(
                                          YmdFormat.WITH_TIME_ZONE,
                                        );
                                        setSearch(dump);
                                      }}
                                      maxDate={moment().toDate()}
                                    />
                                  </div>
                                  <span className="mx8">~</span>
                                  <div className="minmax200">
                                    <BaseDatePicker
                                      name={"searchEnd"}
                                      selectedDate={
                                        search.endDateTime
                                          ? moment(search.endDateTime).toDate()
                                          : moment().toDate()
                                      }
                                      setDate={(value: Date) => {
                                        const dump = _.cloneDeep(search);

                                        let endT = moment(value).format(YmdFormat.YYYY_MM_DD);

                                        let addTime = endT + " 23:59:59";

                                        dump.endDateTime = moment(addTime).format(
                                          YmdFormat.WITH_TIME_ZONE,
                                        );
                                        setSearch(dump);
                                      }}
                                      maxDate={
                                        moment().toDate() ||
                                        moment(search.startDateTime).add(3, "M").toDate() ||
                                        undefined
                                      }
                                    />
                                  </div>
                                  <BaseButton
                                    title={"검색"}
                                    className="ml8"
                                    onClick={async () => {
                                      await fetchAccounts(search);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="contents-scroll max-height190 pa0">
                                <table className="inner-table" width="100%">
                                  <thead>
                                    <tr>
                                      <th>선택</th>
                                      <th>은행</th>
                                      <th>계좌번호</th>
                                      <th>예금주</th>
                                      <th>거래일시</th>
                                      <th>입금자명</th>
                                      <th>입금액</th>
                                      <th>입금잔액</th>
                                      <th>연계</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {isChange && receiveData.length > 0 ? (
                                      receiveData.map((dt: any, index: number) => {
                                        return (
                                          <tr key={index}>
                                            <td width="80">
                                              <BaseCheckbox
                                                className="ml15"
                                                id={`check${index}`}
                                                name={`check${index}`}
                                                disabled={
                                                  row.original.bill.billPayStatus ===
                                                  "PAYMENT_SUCCESS"
                                                }
                                                checked={dt.acctCredit.isBillCredit}
                                                onChange={(value: boolean) => {
                                                  let total = 0;
                                                  receiveList.map((re: any) => {
                                                    total += Number(re.receiptAmount);
                                                  });

                                                  if (
                                                    Number(
                                                      row.original.receiptBundle.billTotalAmount,
                                                    ) < total
                                                  ) {
                                                    setIsError(true);
                                                    setError(
                                                      "수납처리 전 잔액 보다 입금처리 금액이 많을 수 없습니다.",
                                                    );
                                                    return;
                                                  }

                                                  if (value) {
                                                    if (
                                                      Number(
                                                        row.original.receiptBundle.billTotalAmount,
                                                      ) === total
                                                    ) {
                                                      setIsError(true);
                                                      setError(
                                                        "수납처리 합계가 청구금액과 동일하여 입금 거래를 추가 선택할 수 없습니다.",
                                                      );
                                                      return;
                                                    }
                                                    console.log("//선택 하였을 때");
                                                    let trDateTime = moment(
                                                      dt.credit.trDateTime,
                                                      "YYYYMMDDHHmmss",
                                                    ).format(YmdFormat.FULL);
                                                    let temp = _.cloneDeep(receiveList);

                                                    let findedDelete: any = delReceiveList.find(
                                                      (receipt: any) => {
                                                        if (
                                                          receipt.acctCreditId ===
                                                            dt.acctCredit.acctCreditId &&
                                                          receipt.receiptId !== 0
                                                        ) {
                                                          return receipt;
                                                        }
                                                      },
                                                    );
                                                    console.log(
                                                      "//기존 등록된 수납내역 중 삭제 처리된 것이 있는지 찾는다.",
                                                    );

                                                    if (findedDelete) {
                                                      console.log(
                                                        "//기존 등록된 수납내역 중 삭제 처리된 것",
                                                        findedDelete,
                                                      );
                                                      findedDelete.isDeleted = "false";

                                                      let temp2 = _.cloneDeep(delReceiveList);
                                                      const temp3: any = temp2.filter((re: any) => {
                                                        if (
                                                          re.acctCreditId !==
                                                          dt.acctCredit.acctCreditId
                                                        ) {
                                                          return re;
                                                        }
                                                      });
                                                      setDelReceiveList([...temp3]);
                                                      console.log(
                                                        "//미삭제로 flag 바꿈",
                                                        findedDelete,
                                                      );
                                                      temp.push(findedDelete);

                                                      setReceiveList(temp);
                                                      const receiveDataTemp =
                                                        _.cloneDeep(receiveData);

                                                      const newTemp: any = receiveDataTemp.map(
                                                        (rd: any) => {
                                                          if (
                                                            findedDelete.acctCreditId ===
                                                            rd.acctCredit.acctCreditId
                                                          ) {
                                                            console.log(
                                                              Number(rd.credit.creditBalance),
                                                              Number(findedDelete.receiptAmount),
                                                            );
                                                            rd.credit.creditBalance = String(
                                                              Number(rd.credit.creditBalance) -
                                                                Number(findedDelete.receiptAmount),
                                                            );
                                                            row.original.receiptBundle.receiptTotalAmount =
                                                              Number(
                                                                row.original.receiptBundle
                                                                  .receiptTotalAmount,
                                                              ) +
                                                              Number(findedDelete.receiptAmount);

                                                            row.original.receiptBundle.billUnpaidAmount =
                                                              Number(
                                                                row.original.receiptBundle
                                                                  .billUnpaidAmount,
                                                              ) -
                                                              Number(findedDelete.receiptAmount);

                                                            rd.acctCredit.isBillCredit = value;
                                                          }
                                                          console.log("rd", rd);
                                                          return rd;
                                                        },
                                                      );
                                                      setReceiveData(newTemp);
                                                    } else {
                                                      if (Number(dt.credit.creditBalance) <= 0) {
                                                        setIsError(true);
                                                        setError(
                                                          "입금잔액이 0보다 커야 선택할 수 있습니다.",
                                                        );
                                                        return;
                                                      }
                                                      console.log(
                                                        "//기존 등록된 수납내역 중 삭제 처리된 것이 없다",
                                                        "신규로 추가",
                                                      );
                                                      let check =
                                                        total + Number(dt.credit.creditBalance);
                                                      let price = 0;

                                                      if (
                                                        Number(
                                                          row.original.receiptBundle
                                                            .billTotalAmount,
                                                        ) < Number(check)
                                                      ) {
                                                        price =
                                                          Number(
                                                            row.original.receiptBundle
                                                              .billTotalAmount,
                                                          ) - total;
                                                      } else {
                                                        price = Number(dt.credit.creditBalance);
                                                      }
                                                      if (price > 0) {
                                                        const insertReceipt: any = {
                                                          receiptId: 0,
                                                          billId: row.original.bill.billId,
                                                          acctCreditId: dt.acctCredit.acctCreditId,
                                                          paymentId: 0,
                                                          receiptAmount: price,
                                                          trDateTime: trDateTime,
                                                          isDeleted: "false",
                                                        };

                                                        temp.push(insertReceipt);
                                                        setReceiveList(temp);
                                                        changeFlag(value, index);
                                                      }
                                                      changeFlag(value, index);
                                                    }
                                                  } else {
                                                    console.log("//해제 하였을 때");
                                                    const temp = _.cloneDeep(receiveList);
                                                    console.log(
                                                      "//수납 내역에서 체크해제 된것이 있는지 확인한다.",
                                                    );
                                                    const find = temp.find((rl: any) => {
                                                      if (
                                                        Number(rl.receiptId) !== 0 &&
                                                        rl.acctCreditId ===
                                                          dt.acctCredit.acctCreditId
                                                      ) {
                                                        return rl;
                                                      }
                                                    });

                                                    if (find) {
                                                      console.log(
                                                        "//수납 내역에서 체크해제 된것",
                                                        find,
                                                      );
                                                      const temp = _.cloneDeep(receiveList);
                                                      find.isDeleted = "true";

                                                      console.log("//삭제로 flag 바꿈", find);
                                                      setDelReceiveList([
                                                        ...delReceiveList,
                                                        ...[find],
                                                      ]);
                                                      let temp2 = temp.filter((receipt: any) => {
                                                        if (
                                                          Number(receipt.receiptId) !== 0 &&
                                                          receipt.acctCreditId !== find.acctCreditId
                                                        ) {
                                                          return receipt;
                                                        }
                                                      });
                                                      setReceiveList(temp2);

                                                      const receiveDataTemp =
                                                        _.cloneDeep(receiveData);

                                                      const newTemp: any = receiveDataTemp.map(
                                                        (rd: any) => {
                                                          if (
                                                            find.acctCreditId ===
                                                            rd.acctCredit.acctCreditId
                                                          ) {
                                                            rd.credit.creditBalance =
                                                              Number(rd.credit.creditBalance) +
                                                              Number(find.receiptAmount);
                                                            row.original.receiptBundle.receiptTotalAmount =
                                                              Number(
                                                                row.original.receiptBundle
                                                                  .receiptTotalAmount,
                                                              ) - Number(find.receiptAmount);
                                                            let reCalculate =
                                                              Number(
                                                                row.original.receiptBundle
                                                                  .billUnpaidAmount,
                                                              ) + Number(find.receiptAmount);
                                                            if (
                                                              Number(reCalculate) >
                                                              Number(
                                                                row.original.receiptBundle
                                                                  .billTotalAmount,
                                                              )
                                                            ) {
                                                              row.original.receiptBundle.billUnpaidAmount =
                                                                Number(
                                                                  row.original.receiptBundle
                                                                    .billTotalAmount,
                                                                );
                                                            } else {
                                                              row.original.receiptBundle.billUnpaidAmount =
                                                                reCalculate;
                                                            }
                                                            rd.acctCredit.isBillCredit = value;
                                                          }

                                                          return rd;
                                                        },
                                                      );
                                                      setReceiveData(newTemp);
                                                    } else {
                                                      console.log(
                                                        "//수납 내역에서 체크해제 된것이 아니다.",
                                                        " 신규로 넣었다가 뺐다. 걍 삭제 처리한다.",
                                                      );
                                                      const temp = _.cloneDeep(receiveList);
                                                      console.log("?????", temp);
                                                      const filtered = temp.filter(
                                                        (receipt: any, idx5: number) =>
                                                          Number(receipt.acctCreditId) !==
                                                          Number(dt.acctCredit.acctCreditId),
                                                      );
                                                      //console.log("filtered", filtered);
                                                      setReceiveList(filtered);
                                                      changeFlag(value, index);
                                                    }
                                                  }
                                                }}
                                              />
                                            </td>
                                            <td width="100">{dt.providerAccount.bankName}</td>
                                            <td width="280">{dt.providerAccount.accountNumber}</td>
                                            <td width="120">{dt.providerAccount.accountHolder}</td>
                                            <td width="280">{dt.credit.trDateTime}</td>
                                            <td width="180">{dt.credit.remark1}</td>
                                            <td width="220">
                                              {numberToStringWithComma(dt.credit.accountIn) + " 원"}
                                            </td>
                                            <td width="220">
                                              {numberToStringWithComma(dt.credit.creditBalance) +
                                                " 원"}
                                            </td>
                                            <td>
                                              {dt.acctCredit.isAnyBillCredit && (
                                                <BaseButton
                                                  title="연계중"
                                                  className="color-white size-small"
                                                  onClick={async () => {
                                                    await getCredit(dt.credit.tid);
                                                    const cl = _.cloneDeep(receiveData);
                                                    cl.forEach((ac: any, idx: number) => {
                                                      if (idx === index) {
                                                        ac.isOpen = true;
                                                      }
                                                    });
                                                    setReceiveData(cl);
                                                  }}
                                                />
                                              )}
                                              <BaseModal
                                                isOpen={dt.isOpen}
                                                className="dialog-modal "
                                                btnRightTitle="확인"
                                                onClick={() => {
                                                  const cl = _.cloneDeep(receiveData);
                                                  cl.forEach((ac: any, idx: number) => {
                                                    if (idx === index) {
                                                      ac.isOpen = false;
                                                    }
                                                  });
                                                  setReceiveData(cl);
                                                }}
                                              >
                                                <div className="contents-scroll">
                                                  <div className="modal-title">
                                                    <div className="left-area align-items-center">
                                                      <span>입금연계수납</span>
                                                    </div>
                                                    <div className="right-area"></div>
                                                  </div>
                                                  <table className="inner-table" width="100%">
                                                    <thead>
                                                      <tr>
                                                        <th>걔약Id</th>
                                                        <th>구분</th>
                                                        <th>청구회차</th>
                                                        <th>청구일</th>
                                                        <th>연결금액</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {relation.length > 0 ? (
                                                        relation.map(
                                                          (contract: any, idx2: number) => {
                                                            return (
                                                              <tr key={idx2}>
                                                                <td>{contract.contractId}</td>

                                                                <td>
                                                                  {contract.bill.supplyType ===
                                                                  "RENTAL"
                                                                    ? "이용료"
                                                                    : "관리비"}
                                                                </td>
                                                                <td>{contract.bill.billOrder}</td>
                                                                <td>
                                                                  {moment(
                                                                    contract.bill.billIssueDate,
                                                                  ).format(YmdFormat.FULL)}
                                                                </td>
                                                                <td>
                                                                  {numberToStringWithComma(
                                                                    contract.creditRelationAmount,
                                                                  ) + " 원"}
                                                                </td>
                                                              </tr>
                                                            );
                                                          },
                                                        )
                                                      ) : (
                                                        <tr>
                                                          <td colSpan={5}>데이터가 없습니다.</td>
                                                        </tr>
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </BaseModal>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan={9}>데이터가 없습니다.</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            <div className="flex-center ">
                              <span className="text-primary3">수납 처리 현황</span>
                            </div>
                          </td>

                          <td>
                            <table className="inner-table" width="100%">
                              <thead>
                                <tr>
                                  <th>수납처리 전 잔액</th>
                                  <th>수납 금액</th>
                                  <th>현재 잔액</th>
                                  <th>현재 잔액의 연체료</th>
                                  <th>전체 연체기간</th>
                                  <th>전체 연체료</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {row.original.receiptBundle.billTotalAmount
                                      ? "-" +
                                        numberToStringWithComma(
                                          row.original.receiptBundle.billTotalAmount,
                                        )
                                      : 0}{" "}
                                    원
                                  </td>
                                  <td>
                                    {row.original.receiptBundle.receiptTotalAmount
                                      ? numberToStringWithComma(
                                          row.original.receiptBundle.receiptTotalAmount,
                                        )
                                      : 0}{" "}
                                    원
                                  </td>
                                  <td>
                                    {row.original.receiptBundle.billUnpaidAmount !== 0
                                      ? "-" +
                                        numberToStringWithComma(
                                          row.original.receiptBundle.billUnpaidAmount
                                            ? row.original.receiptBundle.billUnpaidAmount
                                            : 0,
                                        )
                                      : 0}
                                    원
                                  </td>
                                  <td>
                                    {row.original.receiptBundle.realtimeOverdueAmount
                                      ? numberToStringWithComma(
                                          row.original.receiptBundle.realtimeOverdueAmount,
                                        )
                                      : 0}{" "}
                                    원
                                  </td>

                                  <td>
                                    {row.original.receiptBundle.totalOverdueDays
                                      ? row.original.receiptBundle.totalOverdueDays
                                      : 0}{" "}
                                    일
                                  </td>
                                  <td>{numberToStringWithComma(totalSum) + " 원"}</td>
                                </tr>
                                <tr>
                                  <td colSpan={5}>
                                    <div className="text-left">
                                      <p className="font12 text-primary5">
                                        *현재잔액: 수납처리 전 잔액기준으로 수납처리금액을
                                        제한금액입니다.
                                      </p>
                                      <p className="font12 text-primary5">
                                        *전체연체료: 수납처리 금액의 연체료와 현재잔액의 연체료의
                                        합계금액입니다.
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td width="180">
                            <div className="flex-center ">
                              <span className="text-primary3">수납 처리</span>
                            </div>
                          </td>
                          <td>
                            <table className="inner-table" width="100%">
                              <thead>
                                <tr>
                                  <th>유형</th>
                                  <th>수납 금액</th>
                                  <th>거래일시</th>
                                  <th>연체기간</th>
                                  <th>연체요율</th>
                                  <th>연체료</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {sortedRecevieList.length > 0 &&
                                  sortedRecevieList.map((receive: any, idx3: number) => {
                                    // console.log("receive", receive);

                                    if (
                                      (receive.paymentId !== 0 ||
                                        Number(receive.acctCreditId) !== 0) &&
                                      receive.isDeleted === "false"
                                    ) {
                                      return (
                                        <tr key={idx3}>
                                          <td width={90}>
                                            {Number(receive.acctCreditId) !== 0
                                              ? "입금"
                                              : Number(receive.paymentId) !== 0
                                              ? "결제"
                                              : "기타"}
                                          </td>
                                          <td width={120}>
                                            <BaseInput
                                              type="number"
                                              name={"receiptAmount_" + idx3}
                                              onChange={(value: string) => {
                                                let limit = 0;
                                                if (Number(value) < 0) {
                                                  setIsError(true);
                                                  setError("0보다 큰 금액만 입력 가능합니다.");
                                                  return;
                                                }
                                                //기타인 경우
                                                if (
                                                  Number(receive.acctCreditId) === 0 &&
                                                  Number(receive.paymentId) === 0
                                                ) {
                                                  sortedRecevieList.map(
                                                    (receive: any, ind: number) => {
                                                      if (ind !== idx3) {
                                                        limit += Number(receive.receiptAmount);
                                                      }
                                                    },
                                                  );

                                                  if (
                                                    Number(
                                                      row.original.receiptBundle.billTotalAmount,
                                                    ) <
                                                    Number(limit) + Number(value)
                                                  ) {
                                                    setIsError(true);
                                                    setError(
                                                      "수납처리 전 잔액보다 많은 금액을 입력할 수 없습니다.",
                                                    );
                                                    return;
                                                  }
                                                } else {
                                                  //입금인 경우
                                                  receiveData.map((acc: any) => {
                                                    if (
                                                      acc.acctCredit.acctCreditId ===
                                                      receive.acctCreditId
                                                    ) {
                                                      limit = acc.credit.creditBalance;
                                                    }
                                                  });

                                                  if (Number(value) > Number(limit)) {
                                                    setIsError(true);
                                                    setError(
                                                      "입금 잔액보다 많은 금액을 입력할 수 없습니다.",
                                                    );
                                                    return;
                                                  }
                                                  //console.log("value, limit", value, limit);
                                                  limit = 0;
                                                  sortedRecevieList.map(
                                                    (receive: any, ind: number) => {
                                                      if (ind !== idx3) {
                                                        limit += Number(receive.receiptAmount);
                                                      }
                                                    },
                                                  );
                                                  if (
                                                    Number(value) + Number(limit) >
                                                    Number(
                                                      row.original.receiptBundle.billTotalAmount,
                                                    )
                                                  ) {
                                                    setIsError(true);
                                                    setError(
                                                      "수납 전 잔액보다 많은 금액을 입력할 수 없습니다.",
                                                    );
                                                    return;
                                                  }
                                                }

                                                const temp = _.cloneDeep(receiveList);
                                                temp.forEach((rp: any) => {
                                                  if (
                                                    rp.acctCreditId === receive.acctCreditId &&
                                                    rp.paymentId === receive.paymentId
                                                  ) {
                                                    rp.receiptAmount = Number(value);
                                                  }
                                                });

                                                setReceiveList(temp);
                                              }}
                                              value={Number(receive.receiptAmount)}
                                              onBlur={() => {}}
                                              readonly={
                                                row.original.bill.billPayStatus ===
                                                "PAYMENT_SUCCESS"
                                              }
                                            />
                                          </td>
                                          <td width={200}>
                                            <BaseDatePicker
                                              type={"full"}
                                              readonly={
                                                Number(receive.acctCreditId) > 0 ||
                                                row.original.bill.billPayStatus ===
                                                  "PAYMENT_SUCCESS"
                                              }
                                              placeholder={"YYYY-MM-DD"}
                                              name={"receive.trDateTime"}
                                              selectedDate={
                                                receive.trDateTime
                                                  ? moment(receive.trDateTime).toDate()
                                                  : null
                                              }
                                              setDate={(value: Date) => {
                                                const temp = _.cloneDeep(receiveList);

                                                temp.forEach((rp: any, idx4: number) => {
                                                  if (idx3 === idx4) {
                                                    if (value !== null) {
                                                      rp.trDateTime = Number(value);
                                                    } else {
                                                      rp.trDateTime = null;
                                                    }
                                                  }
                                                });

                                                setReceiveList(temp);
                                              }}
                                              errorText={errorText}
                                            />
                                          </td>
                                          <td width={80}>
                                            {receive.overdueDays ? receive.overdueDays + "일" : "-"}
                                          </td>
                                          <td width={80}>
                                            {receive.overdueRates
                                              ? receive.overdueRates + "%"
                                              : "-"}
                                          </td>
                                          <td width={80}>
                                            {receive.overdueAmount
                                              ? numberToStringWithComma(
                                                  Number(receive.overdueAmount),
                                                ) + " 원"
                                              : "-"}
                                          </td>
                                          <td width={80}>
                                            {row.original.bill.billPayStatus !==
                                              "PAYMENT_SUCCESS" &&
                                              row.original.bill.billPayStatus !== "PAYMENT_FREE" &&
                                              Number(receive.acctCreditId) === 0 &&
                                              Number(receive.paymentId) === 0 && (
                                                <Link
                                                  to={""}
                                                  target={"_blank"}
                                                  className={"text-red600"}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setIsDelete(true);
                                                  }}
                                                >
                                                  삭제
                                                </Link>
                                              )}
                                            <BaseModal
                                              isOpen={isDelete}
                                              btnLeftTitle="취소"
                                              btnRightTitle="확인"
                                              onClose={() => setIsDelete(false)}
                                              onClick={async () => {
                                                setIsDelete(false);
                                                const find = receive;
                                                const temp = _.cloneDeep(receiveList);
                                                find.isDeleted = "true";

                                                console.log("//삭제로 flag 바꿈", find);
                                                setDelReceiveList([...delReceiveList, ...[find]]);
                                                let temp2 = temp.filter(
                                                  (receipt2: any) =>
                                                    Number(receipt2.receiptId) !==
                                                    Number(find.receiptId),
                                                );
                                                setReceiveList(temp2);
                                              }}
                                            >
                                              <p>
                                                삭제 하시겠습니까? 저장 버튼을 눌러야 삭제 처리가
                                                완료됩니다.
                                              </p>
                                            </BaseModal>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })}
                                {row.original.bill.billPayStatus !== "PAYMENT_SUCCESS" && (
                                  <tr>
                                    <td width={90}>기타</td>
                                    <td width={120}>
                                      <div className="mixmax120">
                                        <BaseInput
                                          name="etc_receiptAmount"
                                          type="number"
                                          onChange={(value: string) => {
                                            let limit = 0;
                                            sortedRecevieList.map((receive: any, ind: number) => {
                                              limit += Number(receive.receiptAmount);
                                            });
                                            if (Number(value) < 0) {
                                              setIsError(true);
                                              setError("0보다 큰 금액만 입력 가능합니다.");
                                              return;
                                            }

                                            if (
                                              Number(limit) + Number(value) >
                                              Number(row.original.receiptBundle.billTotalAmount)
                                            ) {
                                              setIsError(true);
                                              setError(
                                                "수납처리 전 잔액보다 많은 금액을 입력할 수 없습니다.",
                                              );
                                              return;
                                            }

                                            const temp = _.cloneDeep(etc);
                                            temp.receiptAmount = Number(value);
                                            setEtc(temp);
                                          }}
                                          onBlur={() => {}}
                                          value={Number(etc?.receiptAmount)}
                                        />
                                      </div>
                                    </td>
                                    <td width={200}>
                                      <BaseDatePicker
                                        type={"full"}
                                        placeholder={"YYYY-MM-DD"}
                                        filterdReverse={true}
                                        filteredTime={moment(etc?.trDateTime).toDate()}
                                        useFilterdTime={
                                          moment(etc?.trDateTime).format(YmdFormat.YYYY_MM_DD) ===
                                          moment().format(YmdFormat.YYYY_MM_DD)
                                        }
                                        maxDate={moment().toDate()}
                                        name={"trDateTime"}
                                        selectedDate={
                                          etc?.trDateTime !== undefined &&
                                          etc?.trDateTime !== null &&
                                          etc?.trDateTime !== ""
                                            ? moment(etc?.trDateTime).toDate()
                                            : null
                                        }
                                        setDate={(value: Date) => {
                                          const temp = _.cloneDeep(etc);
                                          if (value !== null) {
                                            temp.trDateTime = Number(value);
                                          } else {
                                            temp.trDateTime = null;
                                          }

                                          setEtc(temp);
                                        }}
                                        errorText={errorText}
                                      />
                                    </td>
                                    <td width={80}></td>
                                    <td width={80}></td>
                                    <td width={80}></td>
                                  </tr>
                                )}
                                <tr>
                                  <td>합계 :</td>
                                  <td>{numberToStringWithComma(makeSum) + " 원"}</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{numberToStringWithComma(makeOvSum) + " 원"}</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              </BaseModal>
              <BaseModal isOpen={isError} btnRightTitle="확인" onClick={() => setIsError(false)}>
                <p>{error}</p>
              </BaseModal>
            </div>
          ) : (
            <p>-</p>
          )}
        </div>
      );
    },
  },
  {
    Header: "거래일시",
    accessor: "receiptBundle.lastTrDateTime",
    width: 100,
    Cell: ({ value, row }: any) => {
      return value ? moment(value).format(YmdFormat.FULL) : "-";
    },
  },
  {
    Header: "연체 기간(일)",
    accessor: "receiptBundle.totalOverdueDays",
    // sticky: "left",
    width: 110,
    Cell: ({ value }: any) => {
      return <div>{value ? value + "일" : "-"} </div>;
    },
  },
  {
    Header: "연체 금액(원)",
    accessor: "receiptBundle.accruedOverdueAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ value, row }: any) => {
      let realtimeOverdueAmount = 0;
      if (row.original.receiptBundle.realtimeOverdueAmount !== undefined) {
        realtimeOverdueAmount += Number(row.original.receiptBundle.realtimeOverdueAmount);
      }

      if (row.original.receiptBundle.accruedOverdueAmount !== undefined) {
        realtimeOverdueAmount += Number(row.original.receiptBundle.accruedOverdueAmount);
      }
      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(realtimeOverdueAmount))} </div>;
      } else {
        return <p>-</p>;
      }
    },
  },
  {
    Header: "수납 전 금액",
    accessor: "receiptBundle.billTotalAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(value))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(YmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.billTotalAmount);
        }
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },
  {
    Header: "수납금액",
    accessor: "receiptBundle.receiptTotalAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(value))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(YmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.receiptTotalAmount);
        }
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },
  {
    Header: "잔액",
    accessor: "receiptBundle.billUnpaidAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{value > 0 ? "-" + numberToStringWithComma(Number(value)) : "0"} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(YmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.billUnpaidAmount);
        }
      });
      return <p>{sum > 0 ? "-" + numberToStringWithComma(sum) + " 원" : "0 원"}</p>;
    },
  },
  {
    Header: "상태",
    accessor: "bill.billPayStatus",
    // sticky: "left",
    width: 90,
    Cell: ({ value, row }: any) => {
      // console.log(value);
      // PAYMENT_READY : 결제대기
      // PAYMENT_SUCCESS : 성공-결제성공
      // PAYMENT_FAIL : 실패-결제실패
      // PAYMENT_CANCEL : 취소-결제취소
      // CONTRACT_CANCEL : 해지-계약해지 빌링스케줄실행 중지
      // 연체 표시 : -
      let billIssuDay = moment(row.original.bill.billDueDate).format(YmdFormat.YYYY_MM_DD);
      let today = moment().format(YmdFormat.YYYY_MM_DD);

      if (
        (row.original.bill.billPayStatus === "PAYMENT_FAIL" ||
          row.original.bill.billPayStatus === "PAYMENT_READY") &&
        moment(today).isAfter(moment(billIssuDay))
      ) {
        value = "PAYMENT_DELAY";
      }

      return (
        <div
          className={`${
            (value === "PAYMENT_READY" && "chip gray") ||
            (value === "PAYMENT_FREE" && "chip gray") ||
            (value === "PAYMENT_SUCCESS" && "chip green") ||
            (value === "PAYMENT_FAIL" && "chip red") ||
            (value === "CONTRACT_CANCEL" && "chip gray") ||
            (value === "PAYMENT_CANCEL" && "chip gray") ||
            (value === "PAYMENT_DELAY" && "chip red")
          }`}
        >
          {(value === "PAYMENT_READY" && "대기") ||
            (value === "PAYMENT_FREE" && "무료") ||
            (value === "PAYMENT_SUCCESS" && "성공") ||
            (value === "PAYMENT_FAIL" && "실패") ||
            (value === "CONTRACT_CANCEL" && "중지") ||
            (value === "PAYMENT_CANCEL" && "취소") ||
            (value === "PAYMENT_DELAY" && "연체")}
        </div>
      );
    },
  },
  {
    Header: () => {
      return (
        <div className="flex-center">
          중지
          <BaseTooltip
            contents={""}
            tooltip={
              "대기/연체 청구서가 있을 시 빌링스케줄을 중지할 수 없습니다. <신청/계약>이 ‘해지접수’ 상태일 때 빌링 중지 가능합니다."
            }
            type={"normal"}
            className={"tooltip__trigger-icon"}
          />
        </div>
      );
    },
    accessor: "stop",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row, rows, contractBaseInfo, callList, stopObj, scheduleId }: any) => {
      const params = useParams();
      const [isOpen, setIsOpen] = useState(false);

      // console.log(contractBaseInfo, "contractBaseInfo");
      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);

      const trigger = rows.filter(
        (ro: any) =>
          ro.original.bill.billPayStatus !== "PAYMENT_SUCCESS" &&
          moment(today).isBefore(moment(ro.original.bill.billIssueDate).format(YmdFormat.FULL)),
      );

      const onSubmit = (e?: any) => {
        setIsOpen(false);
        callList(Number(scheduleId));
      };

      const openPop = useCallback(() => {
        setIsOpen(true);
      }, []);

      // console.log("stopObj", stopObj);

      if (moment(today).isBefore(moment(billDate))) {
        return (
          <div>
            <BaseButton
              title={
                stopObj?.isContractCancel === "true" &&
                row.original.bill.billOrder === stopObj?.contractCancelStartOrder
                  ? "수정"
                  : "중지"
              }
              className="color-white size-small"
              disabled={
                contractBaseInfo.contractStep === "TERMINATE_RECEIVED" &&
                row.original.bill.billPayStatus !== "PAYMENT_SUCCESS"
                  ? false
                  : true
              }
              onClick={openPop}
            />
            <StopModal
              setIsOpen={setIsOpen}
              round={row.original.bill.billOrder}
              scheduleId={scheduleId}
              totalRound={trigger}
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              onClick={onSubmit}
            />
          </div>
        );
      }
    },
  },
];
export default ScheduleInfoColumns;
