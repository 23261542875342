import { useRef, useState, useCallback, useEffect, ReactNode, useLayoutEffect } from "react";
import { Popover } from "react-tiny-popover";
import { dateFormattingOperation } from "src/utils/common-util";

/**
 * 기본 Hover 툴팁
 * contents: 기본 표시 데이터(날짜형식일 경우 type에 date 필수)
 * tooltip?: 툴팁표시 데이터;
 * className?: 추가 클래스
 * type?: date | 일반 텍스트
 * isSingleLine?: 한줄로 보이고 (길어지면 줄임말 표기) 툴팁 띄우고 싶을경우 true
 */
interface props {
  touchIcon?: "QUESTION" | "EXCLAMATION" | ReactNode; // 터치 아이콘을 정의합니다(기본 옵션 2종 외 커스텀 터치아이콘 추가 가능)
  contents?: string | undefined;
  tooltip?: string;
  className?: string;
  type?: string;
  isSingleLine?: boolean;
  preformatted?: boolean;
  children?: ReactNode;
  size?: number; //default 20;
}

export const BaseTooltip = (props: props) => {
  const touchAreaRef = useRef<HTMLDivElement>(null); // 마우스 오버영역
  const tooltipRef = useRef<HTMLDivElement>(null); // 검정툴팁
  let basicContent = (
    <div
      ref={tooltipRef}
      className={props.preformatted ? "pre-formatted popover-gray" : "popover-gray"}
    >
      <p>{props.tooltip ? props.tooltip : props.contents}</p>
    </div>
  );
  if (props.children) {
    basicContent = (
      <div
        ref={tooltipRef}
        className={props.preformatted ? "pre-formatted popover-gray" : "popover-gray"}
      >
        {props.children}
      </div>
    );
  }

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showDate, setShowDate] = useState("");
  const [fullDate, setFullDate] = useState("");

  useLayoutEffect(() => {
    if (touchAreaRef.current && tooltipRef.current) {
      const tooltipHeight = tooltipRef.current.clientHeight;
      const tooltipWidth = tooltipRef.current.clientWidth;
      const gap = 10;
      const iconSize = 20;

      if (tooltipRef?.current?.parentNode) {
        const tooltipParent: HTMLElement | null = tooltipRef.current.parentNode as HTMLElement;
        const icTooltipRect = touchAreaRef?.current?.getBoundingClientRect();

        const topPosition = Number(icTooltipRect?.y.toFixed(1));
        const leftPosition = Number(icTooltipRect?.x.toFixed(1));

        const adjustTooltipPosition = () => {
          const windowWidth = window.innerWidth;
          const tooltipRightEdge = leftPosition + tooltipWidth;
          const tooltipTopEdge = topPosition - (tooltipHeight + 10);
          const isNearTop = tooltipTopEdge < 0;
          const isNearRight = tooltipRightEdge + 30 > windowWidth;

          if (isNearTop && isNearRight) {
            // 툴팁이 윈도우 탑과 오른쪽에 닿으면 bottom-left 변경
            tooltipParent.style.top = `${topPosition + iconSize + gap}px`;
            tooltipParent.style.left = `${leftPosition - tooltipWidth + iconSize + gap}px`;
          } else if (isNearTop && !isNearRight) {
            // 툴팁이 윈도우 탑에만 닿으면 bottom-right 변경
            tooltipParent.style.top = `${topPosition + iconSize + gap}px`;
            tooltipParent.style.left = `${leftPosition - gap}px`;
          } else if (!isNearTop && isNearRight) {
            // 툴팁이 윈도우 오른쪽에만 닿으면 top-left 변경
            tooltipParent.style.top = `${topPosition - (tooltipHeight + gap)}px`;
            tooltipParent.style.left = `${leftPosition - tooltipWidth + iconSize + gap}px`;
          } else if (!isNearTop && !isNearRight) {
            // 기본 top-center
            tooltipParent.style.top = `${topPosition - (tooltipHeight + gap)}px`;
            tooltipParent.style.left = props.touchIcon
              ? `${leftPosition - tooltipWidth / 2 + iconSize / 2}px`
              : `${leftPosition - tooltipWidth / 2 + icTooltipRect.width / 2}px`;
          }
        };

        adjustTooltipPosition();

        // 윈도우 리사이징이 일어날때도 실시간으로 감지
        const handleResize = () => {
          adjustTooltipPosition();
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
      }
    }
  }, [isPopoverOpen]);

  const isEllipsis = useCallback(() => {
    if (touchAreaRef.current) {
      if (props?.isSingleLine) {
        setIsPopoverOpen(touchAreaRef.current.scrollWidth > touchAreaRef.current.clientWidth);
      } else {
        setIsPopoverOpen(touchAreaRef.current.scrollHeight > touchAreaRef.current.clientHeight);
      }
    }
  }, [props]);

  const checkDate = () => {
    if (props.type && props.type === "date") {
      setIsPopoverOpen(true);
    } else if (props.type && props.type === "normal") {
      setIsPopoverOpen(true);
    } else {
      isEllipsis();
    }
  };

  useEffect(() => {
    if (props.type === "date" && props.contents !== "") {
      const { showDate: sd, fullDate: fd } = dateFormattingOperation(props.contents!);
      setShowDate(sd);
      setFullDate(fd);
    }
  }, [setFullDate, props.contents, props.type, setShowDate, showDate, fullDate]);

  if (props.type === "date") {
    basicContent = (
      <div ref={tooltipRef} className="popover-gray">
        {fullDate}
      </div>
    );
  }

  if (props.type === "normal") {
    basicContent = (
      <div
        ref={tooltipRef}
        className={props.preformatted ? "pre-formatted popover-gray" : "popover-gray"}
      >
        {props.tooltip}
      </div>
    );
  }
  if (props.type === "large") {
    basicContent = (
      <div
        ref={tooltipRef}
        className={props.preformatted ? "pre-formatted popover-gray size-large" : "popover-gray"}
        onMouseOver={() => setIsPopoverOpen(true)}
        onMouseOut={() => setIsPopoverOpen(false)}
      >
        {props.tooltip}
      </div>
    );
  }
  return (
    <Popover isOpen={isPopoverOpen} ref={tooltipRef} content={basicContent}>
      <>
        {props.touchIcon === "QUESTION" && (
          <div
            ref={touchAreaRef}
            onMouseOver={() => {
              setIsPopoverOpen(true);
            }}
            onMouseOut={() => setIsPopoverOpen(false)}
            className={`${props.className ? props.className : ""} ic-question-tooltip`}
          ></div>
        )}
        {props.touchIcon === "EXCLAMATION" && (
          <div
            ref={touchAreaRef}
            onMouseOver={() => {
              setIsPopoverOpen(true);
            }}
            onMouseOut={() => setIsPopoverOpen(false)}
            className={` ${"cursor-pointer"}`}
          >
            <div
              style={{ width: 20, height: 20 }}
              className="flex-center error-icon ic-exclamation"
            ></div>
          </div>
        )}

        {typeof props.touchIcon === "object" && (
          <div
            ref={touchAreaRef}
            onMouseOver={() => {
              setIsPopoverOpen(true);
            }}
            onMouseOut={() => setIsPopoverOpen(false)}
            className={`${props.className ? props.className : ""}`}
          >
            {props.touchIcon}
          </div>
        )}

        {!props.touchIcon && (
          <p
            ref={touchAreaRef}
            className={`${props?.isSingleLine ? "ellipsis" : "ellipsis2"} ${
              props.className ? props.className : ""
            }`}
            onMouseOut={() => setIsPopoverOpen(false)}
            onMouseOver={() => checkDate()}
          >
            {props.type !== "date" ? props.contents : showDate}
          </p>
        )}
      </>
    </Popover>
  );
};
