import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getPartnerESignClient, patchPartnerESignClient } from "src/api/partner";
import { BaseButton, BaseModal } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { ESignOnClient } from "src/types/partner";

const PartnerEsign = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const { executeAsync: getPartnerESignAsync, loading: getClientLoading } =
    useApiOperation(getPartnerESignClient);
  const { executeAsync: putClientAsync } = useApiOperation(patchPartnerESignClient);
  const [client, setClient] = useState<ESignOnClient>();
  const [isDeleted, setIsDeleted] = useState(false);
  const [isError, setIsError] = useState(false);
  const { id } = useParams();

  const getClientData = useCallback(
    async (partnerId: string) => {
      const response: any = await getPartnerESignAsync({ id: partnerId });

      if (response.status >= 200 && response.status <= 300) {
        setClient(response.data.data.eSignonClient);
      }
    },
    [getPartnerESignAsync],
  );

  const onClickDelete = useCallback(
    async (id: string, eSignonClient: ESignOnClient) => {
      if (eSignonClient.businessId) {
        eSignonClient.cmdType = "D";
        setLoadingBar(true);
        const response = await putClientAsync({ id: String(id), eSignonClient });
        if (response) {
          setLoadingBar(false);
          getClientData(id);
          setIsDeleted(false);

          setIsError(true);
        }
      } else {
      }
    },
    [getClientData, putClientAsync, setLoadingBar],
  );

  useEffect(() => {
    if (id) {
      getClientData(id);
    }
  }, [getClientData, id]);

  return (
    <div className="contents-container__wrap-contents">
      <div className="mb10">
        <h2>전자 서명</h2>
      </div>
      <div className="index-tab">
        <span>이싸인온 로그인 정보</span>
      </div>
      <div className="border-gray py16 pr16 px20">
        <section className="flex-center">
          <div className="minmax123">
            <span className=" font13 text-primary3">이메일</span>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <p>{client?.membEmail}</p>
            </div>
          </div>
        </section>
        <section className="flex-center">
          <div className="minmax123">
            <span className=" font13 text-primary3">패스워드</span>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <p>{client?.membEmail ? "************" : ""}</p>
            </div>
          </div>
        </section>

        <section className="flex-center mt10">
          <div className="minmax123"></div>

          <div className="contents-container__grid-contents">
            <span>
              이싸인온 로그인 계정 인증 :{" "}
              <span className={Number(client?.businessId) !== 0 ? "text-purple" : "text-red600"}>
                {Number(client?.businessId) !== 0 ? "성공" : "실패"}
              </span>
            </span>
          </div>
        </section>
        <section className="flex-center mt10">
          <div className="minmax123">
            <span className=" font13 text-primary3">계정정보 초기화</span>
          </div>

          <div className="contents-container__grid-contents">
            <BaseButton
              title="삭제"
              className="color-white"
              onClick={() => setIsDeleted(true)}
              disabled={Number(client?.businessId) === 0}
            />
          </div>
        </section>
        {isDeleted && (
          <BaseModal
            isOpen={isDeleted}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={() => onClickDelete(String(id), client!)}
            onClose={() => setIsDeleted(false)}
          >
            <p>삭제 하시겠습니까?</p>
          </BaseModal>
        )}
        {isError && (
          <BaseModal isOpen={isError} btnRightTitle="확인" onClick={() => setIsError(false)}>
            <p>삭제되었습니다.</p>
          </BaseModal>
        )}
      </div>
    </div>
  );
};

export default PartnerEsign;
