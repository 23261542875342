import moment from "moment";
import { useEffect, useState } from "react";
import { getContractDashboardList } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import DashboardSection from "./components/DashboardSection";
import { CONTRACT_MENU_LIST } from "./constants";

function DashboardContractSection() {
  const { executeAsync: getContractList } = useApiOperation(getContractDashboardList);
  const [menuList, setMenuList] = useState(CONTRACT_MENU_LIST);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getContractList({
          searchStartTime: moment(new Date("2022-07-01")).startOf("day").format(),
          searchEndTime: moment(new Date()).endOf("day").format(),
        });

        const contractCountList = data.data.contractCountList;

        const total = contractCountList.reduce((acc, cur) => {
          return acc + Number(cur.contractCount);
        }, 0);

        const newMenuList = menuList.map((menu) => {
          const { status } = menu;
          const count =
            contractCountList.find((item) => item.contractStep === status)?.contractCount || 0;
          return { ...menu, count };
        });

        newMenuList[0] = { ...newMenuList[0], count: total };

        setMenuList(newMenuList);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return <DashboardSection title={"신청 / 계약"} menuList={menuList} />;
}

export default DashboardContractSection;
