import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import {
  Cell,
  useBlockLayout,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useApiOperation } from "src/api/hooks";
import { getOrgList } from "src/api/organization/org-api";
import { BizType, MbOrg, OrgListParams } from "src/api/organization/org-types";
import { PageMeta, Sort } from "src/api/public-types";
import { BaseInput, BasePagination, BaseSelect, BaseTooltip } from "src/components";
import { Property } from "src/pages/building/building-types";
import { useLoadingBarContext } from "src/pages/hooks";
import TableRadioButton from "src/pages/product/components/TableRadioButton";
import { companyRegistrationHypen } from "src/utils";

type Props = {
  control: Control<Property>;
  setSelectedOwner: Dispatch<SetStateAction<MbOrg | undefined>>;
  partnerId?: string;
};

type QueryParams = {
  page?: number;
  size?: number;
  searchType?: string;
  keyword?: string;
  sort?: Sort;
  search001?: string;
  partnerId?: string;
  classification?: string;
};

const selectOptions = [
  { value: "OWNER", label: "선택 가능만" },
  { value: "", label: "전체" },
];

const OrganizationListPopup = ({ setSelectedOwner, partnerId }: Props) => {
  // 법인 등록번호 중간에 하이픈 처리
  const autoHypenCorp = useCallback((str: string) => {
    let tmp = "";

    str = str.replace(/[^0-9]/g, "");

    if (str.length < 6) {
      return str;
    } else if (str.length < 14) {
      tmp += str.substring(0, 6);
      tmp += "-";
      tmp += str.substring(6);
      return tmp;
    }
  }, []);

  // 관리처 건물 팝업 columns
  const columns: any[] = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
        width: 70,
        Cell: ({ row, value }: Cell<MbOrg>) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "법인명/상호",
        accessor: "name",
        width: 340,
        Cell: (props: Cell<MbOrg>) => {
          return <BaseTooltip contents={props.value} />;
        },
      },
      {
        Header: "사업 형태",
        accessor: "bizType",
        width: 120,
        Cell: (props: Cell<MbOrg>) => {
          return (
            <>
              <div>{props.value === BizType.CORP ? "법인사업자" : "개인사업자"}</div>
            </>
          );
        },
      },
      {
        Header: "사업자 등록번호",
        accessor: "bizRegNo",
        width: 130,
        Cell: (props: Cell<MbOrg>) => {
          return (
            <>
              <div>{companyRegistrationHypen(props.value) || "-"}</div>
            </>
          );
        },
      },
      {
        Header: "법인 등록번호",
        accessor: "corRegNo",
        width: 130,
        Cell: (props: Cell<MbOrg>) => {
          return (
            <>
              <div>{autoHypenCorp(props.value) || "-"}</div>
            </>
          );
        },
      },
    ],

    [],
  );

  const { executeAsync: getOrgListApi } = useApiOperation(getOrgList);
  const [organizations, setOrganizations] = useState<MbOrg[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const [pageNum, setPageNum] = useState(0);

  const { setLoadingBar } = useLoadingBarContext();

  const query: QueryParams = useMemo(() => {
    const _query = {
      page: 0,
      size: 20,
      sort: { orders: [{ property: "id", direction: "DESC" }] },
      partnerId,
      classification: "OWNER",
    };
    return _query;
  }, [partnerId]);

  const { control, getValues } = useForm<QueryParams>({
    defaultValues: query,
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data: organizations || [],
    },
    useBlockLayout,
    useGlobalFilter,
    useSortBy,
    useGlobalFilter,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          id: "selection",
          width: 80,
          Header: () => <div className="">선택</div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
            let currentState = row.getToggleRowSelectedProps();

            const isOwner = row.original.classifications.find((type: string) => type === "OWNER");
            return (
              <div className="minmax100">
                <TableRadioButton
                  id={row.id}
                  disabled={isOwner ? false : true}
                  onClick={() => {
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id, true);
                    setSelectedOwner(row.original);
                  }}
                  name="selectedOrg"
                  // value={}
                  {...currentState}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  useEffect(() => {
    const getOrganizationList = async (orgtListParams: OrgListParams) => {
      setLoadingBar(true);
      const { data } = await getOrgListApi(orgtListParams);
      setOrganizations(data?.data?.content || []);
      setPageMeta(data?.meta?.pageMeta);
      setLoadingBar(false);
    };

    getOrganizationList(getValues());
  }, [getValues]);

  // 검색 / 페이징 처리
  const onPagenationAndSearchList = useCallback(
    async (passParams?: QueryParams, type?: "search" | "page") => {
      const testParmas = getValues();
      let newQueryParams;
      // 검색일 시  params 사용
      newQueryParams = { ...testParmas, ...(passParams || {}) };

      // }
      const page = passParams?.page;
      setPageNum(Number(page));

      const response = await getOrgListApi(newQueryParams);
      if (response.status >= 200 && response.status <= 299) {
        setOrganizations(response.data.data.content);
        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getOrgListApi, getValues],
  );

  return (
    <>
      <div className="modal-title">
        <div className="left-area">
          <span>소유주</span>
        </div>
        <div className="right-area">
          <div className="minmax140 mr8">
            <Controller
              control={control}
              name="classification"
              render={({ field: { onChange, value, name } }) => (
                <BaseSelect
                  stateOptions={selectOptions}
                  setStateValue={(selected: string) => {
                    onChange(selected);
                    onPagenationAndSearchList({ classification: selected });
                  }}
                  value={value}
                />
              )}
            ></Controller>
          </div>
          <div className="minmax260 ">
            <Controller
              control={control}
              name="search001"
              render={({ field: { onChange, value, name } }) => (
                <BaseInput
                  className=""
                  placeholder="검색어를 입력하세요"
                  onChange={(search001: string) => onChange(search001)}
                  value={value}
                  onSearchClick={() => onPagenationAndSearchList({ page: 0 }, "search")}
                  onKeyUp={() => onPagenationAndSearchList({ page: 0 }, "search")}
                />
              )}
            ></Controller>
          </div>
        </div>
      </div>

      <>
        <div className="contents-container__table">
          <div {...getTableProps()} className="base-table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div
                        {...header.getHeaderProps(header.getSortByToggleProps())}
                        className="base-table__th"
                      >
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            {organizations.length && organizations.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {rows.map((row, idx: number) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} className="base-table__tr">
                      {row.cells.map((cell) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                <p className="mt10">목록이 없습니다.</p>
              </>
            )}
          </div>
          <BasePagination
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              onPagenationAndSearchList({ page }, "page");
            }}
          />
        </div>
      </>
    </>
  );
};

export default OrganizationListPopup;
