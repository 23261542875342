import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import React, { useState } from "react";

const UserLogin = () => {
  const { user, isAuthenticated, logout } = useAuth0();

  // const [userMetaData, setUserMetadata] = useState(null);
  // const [accessToken, setAccessToken] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const imageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    //S3 이미지로 바꾸는게 좋을거같은데 s3 객체로드가 너무느려서 일단 샘플이미지 넣어둠..
    event.currentTarget.src =
      "https://images.unsplash.com/photo-1441829266145-6d4bfbd38eb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1183&q=80";
  };
  return (
    <>
      {isAuthenticated && user && (
        <>
          <div className="flex-center">
            <span>{user.email}</span>
            <div className="global-nav__user" onClick={() => setShowDropdown(!showDropdown)}>
              <img
                className="userimg"
                src={user.picture}
                alt="유저 이미지"
                onError={imageOnErrorHandler}
              />
            </div>
          </div>
          {showDropdown && (
            <div className="global-nav__dropdown">
              <ul>
                <li
                  onClick={() => {
                    // Cookies.remove("idToken");
                    logout({
                      returnTo: `${window.location.origin}`,
                    });
                  }}
                >
                  로그아웃
                </li>
              </ul>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserLogin;
