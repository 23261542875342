import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getBuildingAsync } from "src/api/building/building-api";
import {
  BuildingModel,
  MgmtAccountType,
  MgmtOfficeModel,
  MgmtOfficerType,
  MgmtPhoneType,
} from "src/api/building/building-types";
import { getBuildingManagementAsync } from "src/api/building/management-api";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { BaseButton, ContentsIdSection } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import ManagementOfficeInfoSection from "./components/ManagementOfficeInfoSection";
import ManagementOfficeManagerSection from "./components/ManagementOfficeManagerSection";
import ManagementOfficePhoneSection from "./components/ManagementOfficePhoneSection";
import { FrontMgmtOfficeModel } from "./management-type";

const ManagementOfficeForm = () => {
  const { setLoadingBar } = useLoadingBarContext();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );
  // 건물 ID
  const buildingId = useMemo(() => String(queryParams.id), [queryParams.id]);

  // 관리처 상세
  const [managementDetail, setManagementDetail] = useState<MgmtOfficeModel>();

  const [buildingDetail, setBuildingDetail] = useState<BuildingModel>();

  // 변경된 Front DefaultForm (apiId 추가)
  const [frontOriginalData, setFrontOriginalData] = useState<FrontMgmtOfficeModel>();

  // 정산정보 정보
  const [settlementCompany, setSettlementCompany] = useState<ProviderModel>();

  // ======================= API =======================

  // 관리처 관리 상세
  const { executeAsync: getBuildingManagement } = useApiOperation(getBuildingManagementAsync);

  // 정산정보 상세 api
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  // 건물 상세 조회 api
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  //

  // 정산정보 상세 불러오기
  const getProviderDetailApi = async (settlementId: string) => {
    if (settlementId) {
      setLoadingBar(true);
      const response = await getProviderDetail({ providerId: settlementId });
      if (response.status >= 200 && response.status <= 299) {
        setSettlementCompany(response.data.data.provider);
      }
    }
    setLoadingBar(false);
  };

  const getBuildingDetail = useCallback(async () => {
    if (buildingId) {
      const { data: buildingDetail } = await getBuilding({
        buildingId: Number(buildingId),
      });
      if (buildingDetail) {
        setBuildingDetail(buildingDetail.data.content.building);
      }
    }
  }, [buildingId, getBuilding]);

  // 관리처 관리 상세정보 api / form setValue 처리
  const getBuildingManagementApi = useCallback(
    async (id: string) => {
      setLoadingBar(true);

      const response = await getBuildingManagement({ buildingId: String(id) });

      if (response.status >= 200 && response.status <= 299) {
        const detail = response.data.data.content;
        setManagementDetail(detail);

        // 관리처 (정산정보) 표기를 위해 api 호출
        if (detail.providerId) {
          await getProviderDetailApi(String(detail.providerId));
        }

        if (!!detail.id) {
          const officePhoneList = detail?.officePhoneList?.map((item: MgmtPhoneType) => {
            return { ...item, apiId: item.id };
          });

          const officerList = detail.officerList?.map((item: MgmtOfficerType) => {
            return { ...item, apiId: item.id };
          });

          const accountInfoList = detail.accountInfoList?.map((item: MgmtAccountType) => {
            return { ...item, apiId: item.id };
          });

          const dataForCompare: FrontMgmtOfficeModel = {
            id: detail.id,
            buildingId: detail.buildingId,
            providerId: String(detail.providerId),
            officePhoneList,
            isHoliday: detail.isHoliday,
            paymentId: detail.paymentId,
            paymentSecretKey: detail.paymentSecretKey,
            officerList,
            accountInfoList,
            startTime: moment(detail.startTime, "hh:mm").toDate(),
            endTime: moment(detail.endTime, "hh:mm").toDate(),
          };

          setFrontOriginalData(dataForCompare);
        }
      }

      setLoadingBar(false);
    },
    [getBuildingManagement],
  );

  // 건물 상세 api
  useEffect(() => {
    getBuildingDetail();
  }, [getBuildingDetail]);

  // 관리처관리 상세 api - 관리처 신규등록이면 X
  useEffect(() => {
    if (buildingDetail?.mgmtOfficeId !== "0") {
      getBuildingManagementApi(buildingId);
    }
  }, [buildingDetail?.mgmtOfficeId, buildingId, getBuildingManagementApi]);

  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents ">
        <ContentsIdSection title="관리처" id={buildingId} />

        {/* 관리처 정보 */}
        <ManagementOfficeInfoSection
          buildingId={buildingId}
          partnerId={buildingDetail?.partnerId}
          managementDetail={managementDetail}
          frontOriginalData={frontOriginalData}
          settlementCompany={settlementCompany || {}}
          setSettlementCompany={setSettlementCompany}
          getBuildingManagement={getBuildingManagementApi}
        />

        {managementDetail?.id !== null && (
          <>
            {/* 연락처 */}
            <ManagementOfficePhoneSection
              managementDetail={managementDetail}
              buildingId={buildingId}
              frontOriginalData={frontOriginalData}
              getBuildingManagement={getBuildingManagementApi}
            />

            {/* 담당자 */}
            <ManagementOfficeManagerSection
              managementDetail={managementDetail}
              buildingId={buildingId}
              frontOriginalData={frontOriginalData}
              getBuildingManagement={getBuildingManagementApi}
            />
          </>
        )}
      </div>

      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            className="color-white size-large"
            onClick={() => navigate(PagePath.building.list)}
          />
        </div>
      </div>
    </div>
  );
};

export default ManagementOfficeForm;
