function OnlyNormalProductAllow() {
  return (
    <div className="contents-container__wrap flex-center-center">
      <div className="flex-center-center">
        <h1>⚠️ 관리비 계약 상품</h1>
      </div>
    </div>
  );
}
export default OnlyNormalProductAllow;
