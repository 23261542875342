import { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { MetaData } from "src/api/public-types";
import { BaseCheckbox } from "src/components";

// type Props = {
//   productDirection: { value: string; label: string };
//   name: string;
//   onChange: Function;
//   value?: Array<string>;
// };

// type Props = {
//   productDirection: MetaData;
//   name: string;
//   onChange: Function;
//   value?: Array<MetaData>;
// };

type Props = {
  productDirection: MetaData;
  label: string;
  name: string;
  onChange: Function;
  value?: Array<MetaData>;
};

// 공간상품 향 checkbox 컴포넌트
const ProductDirectionCheckBox = ({ productDirection, name, onChange, value, label }: Props) => {
  const location = useLocation();

  const productId = useMemo(() => {
    const paths = location.search.split("?id=");
    const pathProductId = paths[paths.length - 1];
    return pathProductId;
  }, [location]);

  return (
    <BaseCheckbox
      className="mr16"
      key={productDirection.metaItem}
      id={String(productDirection.metaItem)}
      name={name}
      label={label}
      //
      checked={!!(value || []).find((v: any) => v.metaItem === productDirection.metaItem)}
      onChange={(checked: boolean) => {
        // 향 checkbox 선택시 배열 변경
        const filteredList = (value || []).filter(
          (value) => value.metaItem !== productDirection.metaItem,
        );
        if (checked) {
          const newProductDirectionList = [...filteredList, productDirection];

          const convertList = newProductDirectionList.map((li) => {
            const data = {
              metaGroup: li.metaGroup,
              metaItem: li.metaItem,
              orderNums: li.orderNums,
              value1: "true",
            };
            return data;
          });
          onChange(convertList);
          // onChange(newProductDirectionList);
        } else {
          onChange(filteredList);
        }
      }}
    />
  );

  // return (
  //   <BaseCheckbox
  //     className="mr16"
  //     key={productDirection.value}
  //     id={productDirection.value}
  //     name={name}
  //     checked={!!(value || []).find((v: string) => v === productDirection.value)}
  //     label={productDirection.label}
  //     onChange={(checked: boolean) => {
  //       // 향 checkbox 선택시 배열 변경
  //       const filterdList = (value || []).filter((v: string) => v !== productDirection.value);
  //       if (checked) {
  //         const newProductDirectionList = [...filterdList, productDirection.value];
  //         onChange(newProductDirectionList);
  //       } else {
  //         onChange(filterdList);
  //       }
  //     }}
  //   />
  // );
};

export default ProductDirectionCheckBox;
