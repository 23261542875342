import moment from "moment";
import React from "react";
import { numberToStringWithComma, YmdFormat } from "src/utils";
import { ProductModel } from "src/api/product/product-types";

type Props = {
  detail: ProductModel;
};
/* 
  Open Courtß
 */
const OpenCourtDetail = ({ detail }: Props) => {
  return (
    <article className="contents-container__divide-top pb30">
      <div className="contents-container__sub-title">
        <h2>Open Court</h2>
      </div>
      {/* 계약기간 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>계약기간</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <span className="font14 mr10">최소</span>
            <div>
              <span>{detail.minLeasePeriod}</span>
            </div>
            <span className="font14 mx10">개월 ~ 최대</span>
            <div>
              <span>{detail.maxLeasePeriod}</span>
            </div>
            <span className="font14 ml10">개월</span>
          </div>
        </div>
      </section>
      {/* 입주 가능일 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>입주 가능일</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            {detail.isRightAwayMoveIn ? (
              <span>즉시</span>
            ) : (
              <span>{moment(detail.moveInAvailDate).format(YmdFormat.YYYY_MM_DD)}</span>
            )}{" "}
          </div>
        </div>
      </section>
      {/* 보증금 */}
      <section className="contents-container__grid contents-container__1200">
        <div className="contents-container__grid-index">
          <p>보증금</p>
        </div>
        <div className="contents-container__grid-contents">
          <table className="inner-table">
            <thead>
              <tr>
                <th>
                  <div className="">보증금 (원)</div>
                </th>
                <th>
                  <div className="">계약금 (원)</div>
                </th>
                <th>
                  <div className="minmax420">잔금 (원) / 일자</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span>{numberToStringWithComma(Number(detail.deposit))}</span>
                  </div>
                </td>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span>{numberToStringWithComma(Number(detail.earnest))}</span>
                  </div>
                </td>
                <td>
                  <div className="flex-center-center no-wrap">
                    <span>
                      {numberToStringWithComma(Number(detail.deposit) - Number(detail.earnest))}
                    </span>
                    <span className="mx10">/ 계약 후</span>
                    <div className=" text-right">
                      <span>{detail.balanceFullPaymentDate}</span>
                    </div>
                    <span className="mx10">일 이내</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      {/* 보증금 할인 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>보증금 할인</p>
        </div>
        <div className="contents-container__grid-contents">
          <table className="inner-table">
            <thead>
              <tr>
                <th>
                  <div className="">보증금 (원)</div>
                </th>
                <th>
                  <div className="">계약금 (원)</div>
                </th>
                <th>
                  <div className="minmax420">잔금 (원) / 일자</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span className="">{numberToStringWithComma(Number(detail.saleDeposit))}</span>
                  </div>
                </td>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span className="">{numberToStringWithComma(Number(detail.saleEarnest))}</span>
                  </div>
                </td>
                <td>
                  <div className="flex-center-center no-wrap">
                    <span>
                      {numberToStringWithComma(
                        Number(detail.saleDeposit) - Number(detail.saleEarnest),
                      )}
                    </span>
                    <span className="mx10">/ 계약 후</span>
                    <div className=" text-right">
                      <span>{detail.balanceFullPaymentDate}</span>
                    </div>
                    <span className="mx10">일 이내</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      {/* 이용료 */}
      <section className="contents-container__grid contents-container__1200">
        <div className="contents-container__grid-index">
          <p>이용료</p>
        </div>
        <div className="contents-container__grid-contents">
          <table className="inner-table">
            <thead>
              <tr>
                <th>
                  <div>기준일</div>
                </th>
                <th>
                  <div>금액(부가세 별도 / 원)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span className="ml10 mr16 text-center">1개월</span>
                  </div>
                </td>
                <td>
                  <div className="minmax220 flex-center-center no-wrap">
                    <span>
                      {detail.rentalCostList?.length &&
                        numberToStringWithComma(Number(detail.rentalCostList[0].value1))}
                    </span>{" "}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      {/* 이용료 할인 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>이용료 할인</p>
        </div>
        <div className="contents-container__grid-contents">
          <table className="inner-table">
            <thead>
              <tr>
                <th>
                  <div>기준일</div>
                </th>
                <th>
                  <div>금액(부가세 별도 / 원)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span className="ml10 mr16 text-center">1개월</span>
                  </div>
                </td>
                <td>
                  <div className="minmax220 flex-center-center no-wrap">
                    <span>
                      {detail.saleRentalCostList?.length &&
                        numberToStringWithComma(Number(detail.saleRentalCostList[0].value1))}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      {/* 이용료 안내 팝업 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>이용료 안내 팝업</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <span>{detail.isInformPopup ? "설정" : "설정 안 함"}</span>
          </div>
          <div className="minmax400">
            <p className="pre-formatted">{detail.informPopupDesc}</p>
          </div>
        </div>
      </section>

      {/* 사용인원 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>사용인원</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <div className="text-right">
              <span>{detail.useNums}</span>
            </div>
            <span className="ml10 mr16">명</span>

            <span>{detail.isDeskChairIncluded ? "책상/의자 포함" : "책상/의자 없음"}</span>
          </div>
        </div>
      </section>
      {/* 계정 최대 인원 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>계정 최대 인원</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <div className="text-right">
              <span>{detail.maxNums}</span>
            </div>
            <span className="ml10 mr16">명</span>
          </div>
        </div>
      </section>
    </article>
  );
};
export default OpenCourtDetail;
