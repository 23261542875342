import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getContractDetailPopup,
  getContractProductSnapshotDetailPopup,
} from "src/api/contract/contract-api";
import { errorDataHandler, errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { getMemberDetail } from "src/api/member/member-api";
import { getPartner } from "src/api/partner";
import { getProductDetailAsync } from "src/api/product/product-api";
import { ProviderAddModel } from "src/api/provider/provider-types";
import { building_product_errors, contract_bill_user_errors } from "src/api/public-types";
import { BaseButton, ContentsIdSection } from "src/components";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import ChargeMntType from "./component/ChargeMntType";
import FOType from "./component/FOType";
import ServiceType from "./component/ServiceType";

const ContractApplication = ({ basicInfo, application, spaceProductType, rooms }: any) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const { executeAsync: getMemberInfo } = useApiOperation(getMemberDetail);
  const { executeAsync: getSpaceProduct } = useApiOperation(getProductDetailAsync);
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);
  const { executeAsync: getSnapshot } = useApiOperation(getContractProductSnapshotDetailPopup, {
    doNotErrorHandleModal: true,
  });
  const [memberInfo, setMemberInfo] = useState<any>({});
  const [spaceProduct, setSpaceProduct] = useState<any>({});
  const [productDetail, setProductDetail] = useState<any>();
  const [partner, setPartner] = useState<any>({});
  const [, setSelectedPeriodOptions] = useState([]);
  const [providerDetail, setProviderDetail] = useState<ProviderAddModel>();
  const location = useLocation();
  // const params = useParams();
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );

  // 공산상품 기본정보 디테일 api
  const getProductBasicDetail = useCallback(
    async (contractId: number, step?: string) => {
      if (contractId) {
        let response: any = await getSnapshot({ id: Number(id) });

        // console.log("response11111", response);
        if (response.status >= 200 && response.status <= 299) {
          let productData: any = {};
          let rentalCostList: any = [];

          console.log("snapShotResponse", response.data.data.content);
          const filteredProduct = response.data.data.content.filter(
            (product: any) => product.domain === "product",
          );
          const rentalCost = response.data.data.content.find(
            (product: any) => product.domain === "rentalCost",
          );

          filteredProduct.forEach((pr: any) => {
            productData[pr.attrName] = pr.attrValue;
            productData.id = pr.domainId;
          });
          if (rentalCost) {
            rentalCostList.push({ value1: rentalCost.attrValue });
            productData.rentalCostList = rentalCostList;
          }
          console.log("productData", productData);

          setSelectedPeriodOptions([]);
          setSpaceProduct(productData);
          setProductDetail(productData);
        } else {
          let { message, code, errorData }: any = errorDataHandler(
            response,
            building_product_errors,
          );

          openErrorModal(message, code, errorData);
        }
      }
    },
    [getSnapshot, openErrorModal, id],
  );
  const fetchPartner = useCallback(
    async (id: string) => {
      const result = await getPartnerAsync({ id });

      if (result.status > 199 && result.status < 300) {
        setPartner(result.data.data.partner);
      } else {
        let { message, code, errorData }: any = errorDataHandler(result, building_product_errors);

        openErrorModal(message, code, errorData);
      }
    },
    [getPartnerAsync, openErrorModal],
  );

  const callApplicationData = useCallback(async () => {
    // console.log("application", application);
    setLoadingBar(true);
    if (application && application.memberNo !== undefined) {
      const response: any = await getMemberInfo({ id: application.memberNo });
      if (response.status >= 200 && response.status <= 299) {
        setMemberInfo(response.data.data.member);
      } else {
        let message = errorMessageHandler(
          response.status,
          response.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
        openErrorModal(message, code);
      }
    }
    if (application && application.spaceProductId !== undefined) {
      await getProductBasicDetail(Number(id), basicInfo.contractStep);
      setProviderDetail({
        provider: {
          providerId: application.providerId,
          providerName: application.providerName,
        },
      });
    }

    if (application && application.partnerId !== undefined) {
      console.log("application.partnerId", application.partnerId);
      await fetchPartner(application.partnerId);
    }

    setLoadingBar(false);
  }, [application, basicInfo, fetchPartner, getMemberInfo, getProductBasicDetail, id, openErrorModal, setLoadingBar]);

  useEffect(() => {
    callApplicationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  return (
    <div className="contents-container__wrap detail-basic-info">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection
          title="신청"
          id={basicInfo?.contractId}
          noTitle="신청번호"
          no={basicInfo?.contractApplyNumber}
          status={basicInfo?.step}
        />
        {(productDetail === undefined ||
          (
            productDetail?.productType !== "TIME_COURT" && productDetail?.productType !=="MAINTENANCE_FEE")) && (
          <FOType
            application={application}
            memberInfo={memberInfo}
            spaceProduct={spaceProduct}
            productDetail={productDetail}
            providerDetail={providerDetail}
            partner={partner}
            rooms={rooms}
          />
        )}
        {
          productDetail?.productType === "TIME_COURT" && (
            <ServiceType
              application={application}
              memberInfo={memberInfo}
              spaceProduct={spaceProduct}
              productDetail={productDetail}
              providerDetail={providerDetail}
              partner={partner}
            />
          )}
        {productDetail?.productType === "MAINTENANCE_FEE" && (
          <ChargeMntType
            application={application}
            memberInfo={memberInfo}
            spaceProduct={spaceProduct}
            productDetail={productDetail}
            providerDetail={providerDetail}
            partner={partner}
            rooms={rooms}
          />
        )}
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <BaseButton
            title="목록으로"
            onClick={() => {
              delete queryParams.id;
              navigate(
                PagePath.contract.list +
                  "?" +
                  decodeURIComponent(qs.stringify(queryParams, { allowDots: true, encode: true })),
              );
            }}
            className="color-white size-large"
          />
        </div>
        <div className="right-area"></div>
      </div>
    </div>
  );
};

export default ContractApplication;
