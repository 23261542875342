import React from "react";
import { ProductModel } from "src/api/product/product-types";
import { numberToStringWithComma } from "src/utils";

type Props = {
  detail: ProductModel;
};
/* 

/* 
  Service Court
 */
const TimeCourtDetail = ({ detail }: Props) => {
  return (
    <article className="contents-container__divide-top pb30">
      <div className="contents-container__sub-title">
        <h2>Service Court</h2>
      </div>
      {/* 총 좌석 수 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>총 좌석 수</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <div className="ml10">
              <span>{detail.totalSeatNums}</span>
            </div>
            <span className="ml10 mr16">좌석</span>
          </div>
        </div>
      </section>
      {/* 이용료 */}
      <section className="contents-container__grid contents-container__1200">
        <div className="contents-container__grid-index">
          <p>이용료</p>
        </div>
        <div className="contents-container__grid-contents">
          <table className="inner-table">
            <thead>
              <tr>
                <th>
                  <div>기준일</div>
                </th>
                <th>
                  <div>금액(부가세 별도 / 원)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span className="ml10 mr16 text-center">10분</span>
                  </div>
                </td>
                <td>
                  <div className="minmax220 flex-center-center no-wrap">
                    <span>
                      {detail.rentalCostList?.length &&
                        numberToStringWithComma(Number(detail.rentalCostList[0].value1))}
                    </span>{" "}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      {/* 이용료 할인 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>이용료 할인</p>
        </div>
        <div className="contents-container__grid-contents">
          <table className="inner-table">
            <thead>
              <tr>
                <th>
                  <div>기준일</div>
                </th>
                <th>
                  <div>금액(부가세 별도 / 원)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span className="ml10 mr16 text-center">10분</span>
                  </div>
                </td>
                <td>
                  <div className="minmax220 flex-center-center no-wrap">
                    <span>
                      <span>
                        {detail.saleRentalCostList?.length &&
                          numberToStringWithComma(Number(detail.saleRentalCostList[0].value1))}
                      </span>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      {/* 이용료 안내 팝업 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>이용료 안내 팝업</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <span>{detail.isInformPopup ? "설정" : "설정 안 함"}</span>
          </div>
          <div className="minmax400">
            <p className="pre-formatted">{detail.informPopupDesc}</p>
          </div>
        </div>
      </section>

      {/* 이용가능시간 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>이용가능시간</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400 flex-row flex-center-start ">
            <span>{detail.useStartTime}</span>
            <span className="font14 mx10"> ~ </span>
            <span>{detail.useEndTime}</span>
          </div>
        </div>
      </section>
    </article>
  );
};
export default TimeCourtDetail;
