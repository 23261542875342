import { ChangeEvent, useCallback, useReducer } from "react";
import useApiOperation from "src/api/hooks/api-operation";
import { uploadOrgPrivateFilesAsync } from "src/api/organization/org-api";

const logUploadedFile = (num: number, color = "green") => {
  const msg = `%cUploaded ${num} files.`;
  const style = `color:${color};font-weight:bold;`;
  console.log(msg, style);
};

const initialState = {
  files: [],
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "load":
      return { ...state, files: action.files };
    default:
      return state;
  }
};
interface SendFiles {
  setPrivateFiles: any;
  privateFiles: any;
}
const useFileHandlers = ({ privateFiles, setPrivateFiles }: SendFiles) => {
  // console.log('handler props privateFiles',privateFiles);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { executeAsync: postImageFiles } = useApiOperation(uploadOrgPrivateFilesAsync);

  const onChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const uploaded: any = [];
      if (e.target.files && e.target.files.length) {
        const arrFiles = Array.from(e.target.files);

        const files = await arrFiles.map((file, index) => {
          const src = window.URL.createObjectURL(file);
          return { file, id: index, src };
        });

        for (const i in files) {
          const formData = new FormData();
          formData.append("file", files[i].file);

          const res = await postImageFiles(formData);
          if (res && res.status >= 200 && res.status <= 299) {
            const mediaFile = JSON.parse(
              JSON.stringify({ ...res.data.data.media, ...{ type: "PROOF", isDeleted: false } }),
            );
            // console.log('mediaFile',mediaFile);
            uploaded.push(mediaFile);
            logUploadedFile(Number(i), "green");
          }
        }

        await dispatch({ type: "load", files });
      }
      await setPrivateFiles([...privateFiles, ...uploaded]);
    },
    [postImageFiles, privateFiles, setPrivateFiles],
  );

  return {
    ...state,
    onChange,
  };
};

export default useFileHandlers;
