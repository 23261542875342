/* eslint-disable array-callback-return */
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { getContractDetailPopup } from "src/api/contract/contract-api";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { ProductModel } from "src/api/product/product-types";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { contract_bill_user_errors } from "src/api/public-types";
import { BaseModal } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { YmdFormat } from "src/utils";
import TableCurrencyCell from "./components/TableCurrencyCell";
import TableHeader from "./components/TableHeader";
import TableLongTextCell from "./components/TableLongTextCell";
import TableTextCell from "./components/TableTextCell";
import TableTextCellWithBuilding from "./components/TableTextCellWithBuilding";
import TableTextCellWithBuildingTotal from "./components/TableTextCellWithBuildingTotal";
import TableTextWithSup from "./components/TableTextCellWithSup";
import TableTextWithSupTotal from "./components/TableTextCellWithSupTotal";
import TableTextCellWithSwitch from "./components/TableTextCellWithSwitch";
import TableTextWithBuildingFacility from "./components/TableTextWithBuildingFacility";
import TableTextWithBuildingFacilityLimit from "./components/TableTextWithBuildingFacilityLimit";
import TableTextWithParkingLotList from "./components/TableTextWithParkingLotList";
import TableTextWithParkingLotType from "./components/TableTextWithParkingLotType";
type Props = {
  contractId: number;
  isOpen: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const SnapShotModal = ({ contractId, isOpen, onClose }: Props) => {
  const { openErrorModal } = useErrorModalContext();
  const { setLoadingBar } = useLoadingBarContext();
  const [product, setProduct] = useState<ProductModel>();

  const { executeAsync: getSnapshot } = useApiOperation(getContractDetailPopup);

  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  const callSnapShot = useCallback(
    async (contractId: number) => {
      setLoadingBar(true);
      if (contractId) {
        const response: any = await getSnapshot({ id: contractId });

        if (response.status > 199 && response.status < 300) {
          const provider: any = await getProviderDetail({
            providerId: response.data.data.content.providerId,
          });

          if (provider.status > 199 && provider.status < 300) {
            response.data.data.content.providerName = provider.data.data.provider!.providerName;
          }

          setProduct(response.data.data.content);
        } else {
          let message = errorMessageHandler(
            response.status,
            response.data.meta.errorCode,
            contract_bill_user_errors,
          );
          let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.staus;
          openErrorModal(message, code);
        }
      }
      setLoadingBar(false);
    },
    [getProviderDetail, getSnapshot, openErrorModal, setLoadingBar],
  );

  useEffect(() => {
    if (isOpen && contractId) {
      callSnapShot(contractId);
    }
  }, [callSnapShot, contractId, isOpen]);

  return (
    <div>
      <BaseModal
        className="dialog-modal"
        isOpen={isOpen}
        btnRightTitle="확인"
        // btnLeftTitle="취소"
        onClick={onClose}
      >
        <>
          <div className="modal-title">
            <div className="left-area">
              <span>계약 데이터 상세</span>
            </div>
            <div className="right-area"></div>
          </div>
          <div className="contents-scroll">
            {/* 기본정보 */}
            <table className="inner-table" width="100%">
              <TableHeader title={"기본정보"} />
              <tbody>
                <TableTextCell title={"공간상품 id"} value={product?.id} />
                <TableTextCell title={"공간상품명"} value={product?.productName} />
                <TableTextCell title={"1줄 소개"} value={product?.description} />
                <TableLongTextCell
                  title={"건물 명"}
                  value={product?.buildingList
                    ?.map((list: any) => list.building.buildingName)
                    .join(",")}
                />
                <TableLongTextCell
                  title={"층/호실"}
                  value={product?.buildingList
                    ?.map((list: any) => {
                      let text = list.building.buildingName + "(";
                      list.building.buildingFloorList.map((floor: any) => {
                        let inner = "";
                        let fl = floor.floorName + " ";
                        floor.buildingRoomList.map((room: any) => {
                          if (inner === "") {
                            inner = inner + fl + room.roomName;
                          } else {
                            inner = inner + "," + fl + room.roomName;
                          }
                        });
                        text = text + " " + inner + ") ";
                      });

                      return text;
                    })
                    .join(",")}
                />

                {product?.buildingList && product?.buildingList?.length > 1 && (
                  <>
                    <TableTextWithSupTotal
                      title={"총 전용면적"}
                      type={"leasableAreaNet"}
                      array={product?.buildingList}
                    />
                    <TableTextWithSupTotal
                      title={"총 계약면적"}
                      type={"leasableArea"}
                      array={product?.buildingList}
                    />
                    <TableTextCellWithBuildingTotal
                      title={"평균 전용률"}
                      array={product?.buildingList}
                    />
                  </>
                )}

                <TableTextWithSup
                  title={"건물/호실 전용면적"}
                  type={"leasableAreaNet"}
                  array={product?.buildingList}
                />

                <TableTextWithSup
                  title={"건물/호실 계약면적"}
                  type={"leasableArea"}
                  array={product?.buildingList}
                />
                <TableTextCellWithBuilding
                  title={"건물/호실 전용률"}
                  array={product?.buildingList}
                />

                <TableTextCell title={"문의 연락처"} value={product?.questionPhone} />
                <TableTextCell
                  title={"문의 시간"}
                  value={product?.questionStartTime + " ~ " + product?.questionEndTime}
                />
              </tbody>
            </table>
            {/* 상품정보 */}
            <table className="inner-table" width="100%">
              <TableHeader title={"상품정보"} />
              <tbody>
                <TableTextCell title={"프로바이더 명"} value={product?.providerName} />
                <TableTextCell title={"상품타입"} value={product?.productType} />
                <TableTextCell
                  title={"계약기간"}
                  value={
                    "최소 " + product?.minLeasePeriod + "년 ~ 최대" + product?.maxLeasePeriod + "년"
                  }
                />
                <TableTextCell
                  title={"입주가능일"}
                  value={
                    product?.isRightAwayMoveIn === false
                      ? moment(product?.moveInAvailDate).format(YmdFormat.YYYY_MM_DD_HH_MM)
                      : "즉시"
                  }
                />
                <TableCurrencyCell
                  title={"보증금(원)"}
                  value1={product?.saleDeposit}
                  value2={product?.deposit}
                />
                <TableCurrencyCell
                  title={"계약금(원)"}
                  value1={product?.saleEarnest}
                  value2={product?.earnest}
                />
                <TableCurrencyCell
                  title={"잔금(원)"}
                  value1={Number(product?.saleDeposit) - Number(product?.saleEarnest)}
                  value2={Number(product?.deposit) - Number(product?.earnest)}
                />
                <TableCurrencyCell
                  title={"월 이용료(부가세 별도/원)"}
                  value1={String(product?.saleRentalCostList?.map((rental) => rental.value1))}
                  value2={String(product?.rentalCostList?.map((rental) => rental.value1))}
                />

                <TableTextCell
                  title={"관리비"}
                  value={product?.isImpositionFee ? "부과함" : "부과하지않음"}
                />
                <TableTextCell
                  title={"사용인원"}
                  value={
                    product?.useNums +
                    "명" +
                    (product?.isDeskChairIncluded ? "(책상/의자포함)" : "(책상/의자 미포함)")
                  }
                />
                <TableTextCell title={"계정 최댜 인원"} value={product?.maxNums + "명"} />
              </tbody>
            </table>
            {/* 시설/서비스 */}
            <table className="inner-table" width="100%">
              <TableHeader title={"시설/서비스"} />
              <tbody>
                <TableTextCellWithSwitch title={"공조기 난방 타입"} product={product} />
                <TableLongTextCell
                  title={"공조기 기기 정보"}
                  value={product?.productAirConditioner?.airConditionerInfoList
                    ?.map((aircon: any) => {
                      let text = "";
                      if (Boolean(aircon.value1)) {
                        switch (aircon.metaItem) {
                          case "PRODUCT_AIR_CONDITIONER_1":
                            text = "천장형 시스템 냉난방기";
                            break;
                          case "PRODUCT_AIR_CONDITIONER_2":
                            text = "냉난방기";
                            break;
                          case "PRODUCT_AIR_CONDITIONER_3":
                            text = "에어컨";
                            break;
                          case "PRODUCT_AIR_CONDITIONER_4":
                            text = "난방기";
                            break;
                          case "PRODUCT_AIR_CONDITIONER_5":
                            text = aircon.value2;
                            break;
                        }
                      }
                      return text;
                    })
                    .join(", ")}
                />
                <TableTextCell
                  title={"화장실"}
                  value={product?.productToiletList
                    ?.map((toilet: any) => {
                      let text = "";

                      switch (toilet.metaItem) {
                        case "PRODUCT_TOILET_1":
                          text = "실내_남녀분리";
                          break;
                        case "PRODUCT_TOILET_2":
                          text = "실외_남녀분리";
                          break;
                        case "PRODUCT_TOILET_3":
                          text = "실내_남녀공용";
                          break;
                        case "PRODUCT_TOILET_4":
                          text = "실외_남녀공용";
                          break;
                      }
                      return text;
                    })
                    .join(", ")}
                />
                <TableTextCell
                  title={"복합기"}
                  value={product?.productPrinter?.printerOptionList
                    ?.map((printor: any) => {
                      let text = "";
                      if (printor.value1) {
                        switch (printor.metaItem) {
                          case "PRODUCT_PRINTER_1":
                            text = "출력";
                            break;
                          case "PRODUCT_PRINTER_2":
                            text = "복사";
                            break;
                          case "PRODUCT_PRINTER_3":
                            text = "스캔";
                            break;
                          case "PRODUCT_PRINTER_4":
                            text = "팩스";
                            break;
                        }
                      }
                      return text;
                    })
                    .join(", ")}
                />
                <TableTextCell
                  title={"무료 인터넷"}
                  value={product?.productFreeInternet?.freeInternetOptionList
                    ?.map((printor: any) => {
                      let text = "";
                      if (printor.value1) {
                        switch (printor.metaItem) {
                          case "PRODUCT_FREE_INTERNET_1":
                            text = "랜선";
                            break;
                          case "PRODUCT_FREE_INTERNET_2":
                            text = "와이파이";
                            break;
                        }
                      }
                      return text;
                    })
                    .join(", ")}
                />
                <TableTextCell
                  title={"추가 시설/서비스"}
                  value={product?.productOptionalFacilityList
                    ?.map((option: any) => {
                      let text = "";
                      if (option.value1) {
                        switch (option.metaItem) {
                          case "PRODUCT_OPTIONAL_FACILITY_1":
                            text = "공용 라운지";
                            break;
                          case "PRODUCT_OPTIONAL_FACILITY_2":
                            text = "택배/우편물 수령";
                            break;
                          case "PRODUCT_OPTIONAL_FACILITY_3":
                            text = "보안";
                            break;
                          case "PRODUCT_OPTIONAL_FACILITY_4":
                            text = "스낵";
                            break;
                          case "PRODUCT_OPTIONAL_FACILITY_5":
                            text = "인포데스크";
                            break;
                        }
                      }
                      return text;
                    })
                    .join(", ")}
                />
              </tbody>
            </table>
            {/* 공용 공간 */}
            <table className="inner-table" width="100%">
              <TableHeader title={"공용 공간"} />
              <tbody>
                {/* <TableTextCell title={"{주차 연동(방문자)}"} value={"123123"} /> */}
                <TableTextWithParkingLotType
                  title={"주차 유형"}
                  value1={product?.parkingLotFreeDescription}
                  value2={product?.parkingLotPayDescription}
                />
                <TableTextWithParkingLotList title={"주차장 정보"} product={product} />
                <TableTextWithBuildingFacility
                  title={"회의실"}
                  product={product}
                  type={"meetingRoomList"}
                />
                <TableTextWithBuildingFacilityLimit
                  title={"회의실 예약제한"}
                  product={product}
                  type={"meetingRoom"}
                />
                <TableTextWithBuildingFacility
                  title={"좌석"}
                  product={product}
                  type={"deskSpace"}
                />
                <TableTextWithBuildingFacilityLimit
                  title={"좌석 예약제한"}
                  product={product}
                  type={"deskSpace"}
                />
                <TableTextWithBuildingFacility
                  title={"편의시설"}
                  product={product}
                  type={"refreshRoomList"}
                />

                <TableTextWithBuildingFacilityLimit
                  title={"편의시설 예약제한"}
                  product={product}
                  type={"refreshRoom"}
                />
              </tbody>
            </table>
            {/* 공용 공간 */}
            <table className="inner-table" width="100%">
              <TableHeader title={"업데이트 정보"} />
              <tbody>
                <TableTextCell
                  title={"업데이트 일시"}
                  value={moment(product?.modifiedDate).format(YmdFormat.FULL)}
                />
              </tbody>
            </table>
          </div>
        </>
      </BaseModal>
    </div>
  );
};

export default SnapShotModal;
