import { useEffect, useState } from "react";
import { IMenuListStatus, MenuStatus } from "../types";

export default function useSectionMenuList({
  baseMenuList,
  getMenuListCounts,
}: {
  baseMenuList: IMenuListStatus[];
  getMenuListCounts: (status: MenuStatus) => Promise<number>;
}) {
  const [menuList, setMenuList] = useState(baseMenuList);

  const updateMenuListCounts = async () => {
    const result = await Promise.all(
      menuList.map(async (menu) => ({
        ...menu,
        count: await getMenuListCounts({
          status: menu.status,
        }),
      })),
    );
    setMenuList(result);
  };

  useEffect(() => {
    updateMenuListCounts();
  }, []);

  return { menuList };
}
