export const COLLECTIVE_CONTROL_ITEMS = [
  {
    id: 1,
    method: "상태 조회",
    displayName: "전원 상태 조회",
    componentId: "main",
    capabilityId: "switch",
    command: "",
    arguments: "",
    description: "ST 조명기기, 실내기용",
    labels: [
      {
        name: "용도",
        value: "전원",
      },
    ],
  },
  {
    id: 2,
    method: "명령",
    displayName: "전원 켜기",
    componentId: "main",
    capabilityId: "switch",
    command: "on",
    arguments: "",
    description: "ST 조명기기, 실내기용",
    labels: [
      {
        name: "용도",
        value: "전원/켜기",
      },
    ],
  },
  {
    id: 3,
    method: "명령",
    displayName: "전원 끄기",
    componentId: "main",
    capabilityId: "switch",
    command: "off",
    arguments: "",
    description: "ST 조명기기, 실내기용",
    labels: [
      {
        name: "용도",
        value: "전원/끄기",
      },
    ],
  },
  {
    id: 4,
    method: "상태 조회",
    displayName: "밝기 조회",
    componentId: "main",
    capabilityId: "switchLevel",
    command: "",
    arguments: "",
    description: "ST 조명기기용",
    labels: [
      {
        name: "용도",
        value: "전원/레벨",
      },
    ],
  },
  {
    id: 5,
    method: "명령",
    displayName: "밝기 설정",
    componentId: "main",
    capabilityId: "switchLevel",
    command: "setLevel",
    arguments: "",
    description: "ST 조명기기용",
    labels: [
      {
        name: "용도",
        value: "전원/레벨",
      },
    ],
  },
  {
    id: 6,
    method: "상태 조회",
    displayName: "색온도 조회",
    componentId: "main",
    capabilityId: "colorTemperature",
    command: "",
    arguments: "",
    description: "ST 조명기기용",
    labels: [
      {
        name: "용도",
        value: "색온도",
      },
    ],
  },
  {
    id: 7,
    method: "명령",
    displayName: "색온도 설정",
    componentId: "main",
    capabilityId: "colorTemperature",
    command: "setColorTemperature",
    arguments: "",
    description: "ST 조명기기용",
    labels: [
      {
        name: "용도",
        value: "색온도",
      },
    ],
  },
  {
    id: 8,
    method: "상태 조회",
    displayName: "색상 조회",
    componentId: "main",
    capabilityId: "colorControl",
    command: "",
    arguments: "",
    description: "ST 조명기기용",
    labels: [
      {
        name: "용도",
        value: "색상",
      },
    ],
  },
  {
    id: 10,
    method: "명령",
    displayName: "색상 설정 (색조)",
    componentId: "main",
    capabilityId: "colorControl",
    command: "setHue",
    arguments: "",
    description: "ST 조명기기용",
    labels: [
      {
        name: "용도",
        value: "색상/색조",
      },
    ],
  },
  {
    id: 11,
    method: "명령",
    displayName: "색상 설정 (채도)",
    componentId: "main",
    capabilityId: "colorControl",
    command: "setSaturation",
    arguments: "",
    description: "ST 조명기기용",
    labels: [
      {
        name: "용도",
        value: "색상/채도",
      },
    ],
  },
  {
    id: 12,
    method: "상태 조회",
    displayName: "측정 온도 조회",
    componentId: "main",
    capabilityId: "temperatureMeasurement",
    command: "",
    arguments: "",
    description: "ST 실내기용",
    labels: [
      {
        name: "용도",
        value: "측정온도",
      },
    ],
  },
  {
    id: 13,
    method: "상태 조회",
    displayName: "공조 모드 조회",
    componentId: "main",
    capabilityId: "airConditionerMode",
    command: "",
    arguments: "",
    description: "ST 실내기용",
    labels: [
      {
        name: "용도",
        value: "공조/모드",
      },
    ],
  },
  {
    id: 14,
    method: "명령",
    displayName: "공조 모드 설정 (자동)",
    componentId: "main",
    capabilityId: "airConditionerMode",
    command: "setAirConditionerMode",
    arguments: "auto",
    description: "ST 실내기용",
    labels: [
      {
        name: "용도",
        value: "공조/모드/자동",
      },
    ],
  },
  {
    id: 15,
    method: "명령",
    displayName: "공조 모드 설정 (난방)",
    componentId: "main",
    capabilityId: "airConditionerMode",
    command: "setAirConditionerMode",
    arguments: "heat",
    description: "ST 실내기용",
    labels: [
      {
        name: "용도",
        value: "공조/모드/난방",
      },
    ],
  },
  {
    id: 16,
    method: "명령",
    displayName: "공조 모드 설정 (냉방)",
    componentId: "main",
    capabilityId: "airConditionerMode",
    command: "setAirConditionerMode",
    arguments: "cool",
    description: "ST 실내기용",
    labels: [
      {
        name: "용도",
        value: "공조/모드/냉방",
      },
    ],
  },
  {
    id: 17,
    method: "상태 조회",
    displayName: "희망 온도 조회",
    componentId: "main",
    capabilityId: "thermostatCoolingSetpoint",
    command: "",
    arguments: "",
    description: "ST 실내기용",
    labels: [
      {
        name: "용도",
        value: "공조/희망온도",
      },
    ],
  },
  {
    id: 18,
    method: "명령",
    displayName: "희망 온도 설정",
    componentId: "main",
    capabilityId: "thermostatCoolingSetpoint",
    command: "setCoolingSetpoint",
    arguments: "",
    description: "ST 실내기용",
    labels: [
      {
        name: "용도",
        value: "공조/희망온도",
      },
    ],
  },
  {
    id: 19,
    method: "상태 조회",
    displayName: "실내기 상태 조회",
    componentId: "AirConditioner.Indoor",
    capabilityId: "status",
    command: "",
    arguments: "",
    description: "b.IoT 실내기용",
    labels: [
      {
        name: "용도",
        value: "측정온도",
      },
      {
        name: "용도",
        value: "공조/상태",
      },
    ],
  },
  {
    id: 20,
    method: "명령",
    displayName: "전원 제어 (켜기)",
    componentId: "AirConditioner.Indoor.General",
    capabilityId: "operations",
    command: "patch",
    arguments: "On",
    description: "b.IoT 실내기용",
    labels: [
      {
        name: "용도",
        value: "전원/켜기",
      },
    ],
  },
  {
    id: 21,
    method: "명령",
    displayName: "전원 제어 (끄기)",
    componentId: "AirConditioner.Indoor.General",
    capabilityId: "operations",
    command: "patch",
    arguments: "Off",
    description: "b.IoT 실내기용",
    labels: [
      {
        name: "용도",
        value: "전원/끄기",
      },
    ],
  },
  {
    id: 22,
    method: "명령",
    displayName: "공조 모드 설정 (자동)",
    componentId: "AirConditioner.Indoor.General",
    capabilityId: "modes",
    command: "patch",
    arguments: "Auto",
    description: "b.IoT 실내기용",
    labels: [
      {
        name: "용도",
        value: "공조/모드/자동",
      },
    ],
  },
  {
    id: 23,
    method: "명령",
    displayName: "공조 모드 설정 (난방)",
    componentId: "AirConditioner.Indoor.General",
    capabilityId: "modes",
    command: "patch",
    arguments: "Heat",
    description: "b.IoT 실내기용",
    labels: [
      {
        name: "용도",
        value: "공조/모드/난방",
      },
    ],
  },
  {
    id: 24,
    method: "명령",
    displayName: "공조 모드 설정 (냉방)",
    componentId: "AirConditioner.Indoor.General",
    capabilityId: "modes",
    command: "patch",
    arguments: "Cool",
    description: "b.IoT 실내기용",
    labels: [
      {
        name: "용도",
        value: "공조/모드/냉방",
      },
    ],
  },
  {
    id: 25,
    method: "명령",
    displayName: "희망 온도 설정",
    componentId: "AirConditioner.Indoor.Room",
    capabilityId: "temperatures",
    command: "patch",
    arguments: "",
    description: "b.IoT 실내기용",
    labels: [
      {
        name: "용도",
        value: "공조/희망온도",
      },
    ],
  },
];
