interface Props extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
}

function MemberStatus({ label, ...props }: Props) {
  return (
    <div className="flex-center" {...props}>
      <p
        className={`${
          (label === "MEMBER_INVITE" && "chip visible") ||
          (label === "MEMBER_USE" && "chip visible") ||
          (label === "MEMBER_DELETE" && "chip draft") ||
          (label === "MEMBER_CLOSE" && "chip visible") ||
          (label === "MEMBER_EXPIRE" && "chip invisible")
        }`}
      >
        {(label === "MEMBER_INVITE" && "초대") ||
          (label === "MEMBER_USE" && "사용") ||
          (label === "MEMBER_DELETE" && "삭제") ||
          (label === "MEMBER_CLOSE" && "종료") ||
          (label === "MEMBER_EXPIRE" && "만료")}
      </p>
    </div>
  );
}
export default MemberStatus;
