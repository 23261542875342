/* eslint-disable jsx-a11y/anchor-is-valid */
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { addOrg, editOrg, getOrgDetail } from "src/api/organization/org-api";
import {
  BizType,
  SaveMediaDto,
  SaveOrgDto,
  SendBody,
  TaxType
} from "src/api/organization/org-types";
import { getPartner } from "src/api/partner";
import { MediaFile, Modal, Select } from "src/api/public-types";
import {
  BaseButton,
  BaseCheckbox,
  BaseInput,
  BaseModal,
  BaseRadio,
  BaseSelect,
  ContentsIdSection
} from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { Partner } from "src/types/partner";
import { companyRegistrationHypen, corpHypen, onlyNumber, removeHyphens } from "src/utils";
import FileUpload from "./components/FileUpload";
import OrgImagesUpdate from "./components/OrgImagesUpdate";

const individualTaxOption = [
  { value: TaxType.GENERAL, label: "일반" },
  { value: TaxType.SIMPLIFIED_A, label: "간이(세금계산서 발급형)" },
  { value: TaxType.SIMPLIFIED_B, label: "간이(세금계산서 미발급형)" },
  { value: TaxType.FREE, label: "면세" },
];
const corpTaxOption = [
  { value: TaxType.GENERAL, label: "과세" },
  { value: TaxType.FREE, label: "면세" },
];

// 사업자 구분
const classifications = ["GENERAL_BIZ", "OWNER"];

const checkBizRegNoLink = process.env.REACT_APP_BIZ_REG_CHECK_URL || "";

const BasicInfoForm = ({ setOrgData }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  let id = 0;
  const querys = qs.parse(location.search);
  for (const [key, value] of Object.entries(querys)) {
    if (key.includes("id")) {
      id = Number(value);
    }
  }

  const [moveId, setMoveId] = useState();
  const [privateFiles, setPrivateFiles] = useState<SaveMediaDto[]>([]);
  const [uploadMediaFile, setUploadedMediaFile] = useState<SaveMediaDto[]>([]);
  const [uploadFile, setUploadeFile] = useState<MediaFile[]>([]);

  const [copRegNoFlag, setCopRegNoFlag] = useState(false);
  const [openFlag, setOpenFlag] = useState(false);
  const [confirmFlag, setConfirmFlag] = useState(false);
  const [taxOption, setTaxOption] = useState<Select[]>(individualTaxOption);
  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState<boolean>(false);
  const [isPartnerChangeConfirmModalOpen, setIsPartnerChangeConfirmModalOpen] = useState<Modal>({
    isOpen: false,
  });
  const [selectedPartner, setSelectedPartner] = useState<Partner>();
  const [newSelectedPartner, setNewSelectedPartner] = useState<Partner>();
  const { executeAsync: addOrganization } = useApiOperation(addOrg);
  const { executeAsync: getDetail } = useApiOperation(getOrgDetail);
  const { executeAsync: editOrganization } = useApiOperation(editOrg);
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  const { setLoadingBar } = useLoadingBarContext();

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    trigger,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<any>({
    defaultValues: {
      address: "",
      bizCondition: "",
      bizItem: "",
      bizRegNo: "",
      bizType: BizType.SP,
      ceoName: "",
      corRegNo: "",
      name: "",
      taxType: TaxType.GENERAL,
      phoneNumber: "" || null,
      email: "" || null,
      mediaList: [],
      classifications: [],
      partnerId: "",
    },
  });

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    const requiredMessage = "필수 입력 항목입니다";
    register("address", {
      required: requiredMessage,
      // maxLength: { value: 30, message: "30자 이상 입력 불가능 합니다." },
    });
    register("bizCondition", {
      required: requiredMessage,
    });
    register("bizItem", {
      required: requiredMessage,
    });
    register("bizRegNo", {
      required: requiredMessage,
    });
    register("ceoName", {
      required: requiredMessage,
    });
    if (copRegNoFlag) {
      register("corRegNo", {
        required: requiredMessage,
      });
    }
    register("name", {
      required: requiredMessage,
    });

    register("classifications", {
      required: requiredMessage,
    });
    register("mediaList", {
      required: requiredMessage,
    });
    register("partnerId", {
      required: requiredMessage,
    });
  }, [copRegNoFlag, register, dirtyFields]);

  const moveOrgDetail = useCallback(
    (id: number) => {
      const path = PagePath.organization.detail.replace(":id", id!.toString());
      navigate(path);
    },
    [navigate],
  );
 
  //사업자 등록번호 검증
  const checkValidateOrgNumber = useCallback((number: string) => {
    let numberMap: number[] = number
      .replace(/-/gi, "")
      .split("")
      .map(function (d) {
        return parseInt(d, 10);
      });

    let keyList: number[] = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    let chk: number = 0;

    //2.keyList와 합계
    keyList.forEach(function (d, i) {
      chk += d * numberMap[i];
    });

    console.log("keyList와 합계", chk);
    //3.앞 9자리의 마지막 값을 다시 곱하고 10으로 나눕니다.(소수점 제거)
    let last = Math.floor((numberMap[8] * keyList[8]) / 10);
    console.log(numberMap[8] + " * " + keyList[8] + " / 10  = ", last);
    //4.합계에 바로위 값을 더해준다.
    console.log(chk + " + " + last + " = ", chk + last);
    chk = chk + last;
    //5.합계를 10으로 나누어 나머지를 구합니다.
    chk = chk % 10;
    console.log("합계를 10으로 나누어 나머지를 구합니다. = ", chk);
    //6. 10에서 나머지를 뺀다.
    console.log("10에서 나머지를 뺀다. = ", (10 - chk) % 10);
    chk = (10 - chk) % 10;
    // 마지막 자리수와 비교
    return Math.floor(numberMap[9]) === chk;
  }, []);

  //법인번호 검증
  const checkValidateCorpNumber = useCallback((sRegNo: string) => {
    sRegNo = sRegNo.replace("-", "");
    if (sRegNo.length !== 13) {
      return false;
    }

    let arr_regno = sRegNo.trim();
    let arr_wt = [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2];
    let iSum_regno = 0;
    let iCheck_digit = 0;
    for (let i = 0; i < 12; i++) {
      iSum_regno += arr_wt[i] * Number(arr_regno.charAt(i));
    }
    iCheck_digit = (10 - (iSum_regno % 10)) % 10;
    console.log(iCheck_digit, Number(arr_regno.charAt(12)));
    if (iCheck_digit !== Number(arr_regno.charAt(12))) {
      return false;
    }
    return true;
  }, []);

  // 파트너 조회
  const fetchPartner = async (id: string) => {
    setLoadingBar(true);
    const response = await getPartnerAsync({ id });
    if (response.status >= 200 && response.status <= 299) {
      const partner = response.data.data.partner;
      setSelectedPartner(partner);
    }
    setLoadingBar(false);
  };

  const getDetailData = useCallback(() => {
    if (id) {
      getDetail({ id: Number(id) }).then(async (response) => {
        if (response.data.data.organization) {
          const og: any = response.data.data.organization;
          if (og.bizType === BizType.SP) {
            setCopRegNoFlag(false);
            setTaxOption(individualTaxOption);
            setValue("taxType", "");
            setValue("taxType", og.taxType);
          } else {
            setCopRegNoFlag(true);
            setTaxOption(corpTaxOption);
            setValue("taxType", og.taxType);
          }

          setValue("address", og.address);
          setValue("bizCondition", og.bizCondition);
          setValue("bizRegNo", companyRegistrationHypen(og.bizRegNo));
          setValue("bizItem", og.bizItem);
          setValue("bizType", og.bizType);
          setValue("ceoName", og.ceoName);
          setValue("corRegNo", corpHypen(og.corRegNo));
          setValue("name", og.name);
          setValue("email", og.email);
          setValue("phoneNumber", og.phoneNumber);
          setValue("classifications", og.classifications);
          setValue("partnerId", og.partnerId);

          await fetchPartner(og.partnerId);
        }

        if (response.data.data.mediaList && response.data.data.mediaList.length > 0) {
          // console.log(response.data.data.mediaList);
          const logos: any = response.data.data.mediaList.filter((md: any) => md.type === "LOGO");
          const proofs: any = response.data.data.mediaList.filter((md: any) => md.type === "PROOF");
          setPrivateFiles(proofs);
          console.log("logos", logos);
          setUploadedMediaFile(logos);
          setUploadeFile(logos);
        }
        setOrgData(response.data.data);
      });
    }
  }, [getDetail, id, setOrgData, setValue]);

  useEffect(() => {
    getDetailData();
  }, [getDetailData]);

  const sendAddOrg = useCallback(
    (org: SendBody) => {
      if (org) {
        addOrganization(org).then((response: any) => {
          // console.log(response);
          setMoveId(response.data.data.organization.id);
          setOpenFlag(true);
        });
      }
    },
    [addOrganization],
  );

  const sendEditOrg = useCallback(
    (org: SendBody) => {
      if (org) {
        editOrganization(org).then((response: any) => {
          setMoveId(response.data.data.organization.id);
          setOpenFlag(true);
        });
      }
    },
    [editOrganization],
  );

  const onAddSelectPartners = (partners: Partner[]) => {
    if (!selectedPartner || partners[0].id === selectedPartner.id) {
      setSelectedPartner(partners[0]);
      setIsPartnerModalOpen(false);
      setValue("partnerId", Number(partners[0]?.id) || "");
      trigger("partnerId");
    } else {
      setIsPartnerChangeConfirmModalOpen({
        isOpen: true,
        message: "파트너를 변경하시면 입력하신 정보가 초기화됩니다",
      });
      setIsPartnerModalOpen(false);
      setNewSelectedPartner(partners[0]); // 변경 할 파트너 상태를 추가함
    }
  };

  const changePartners = (partner:Partner) => {
    setSelectedPartner(partner)
    setIsPartnerChangeConfirmModalOpen({isOpen:false})
    reset();
    setValue("partnerId", Number(partner.id) || "");
    trigger("partnerId");
  }

  const onSubmit = useCallback(
    async (data: any, e?: any) => {
      console.log("onSubmit data", data, e);
      const check1 = checkValidateOrgNumber(getValues("bizRegNo"));
      if (check1 === false) {
        setError("bizRegNo", {
          type: "custom",
          message: "유효한 사업자 번호가 아닙니다.",
        });
        return;
      } else {
        setError("bizRegNo", {
          type: "custom",
          message: "",
        });
      }
      if (data.bizType === "CORP") {
        const check2 = checkValidateCorpNumber(getValues("corRegNo"));

        if (check2 === false) {
          setError("corRegNo", {
            type: "custom",
            message: "유효한 법인 등록 번호가 아닙니다.",
          });
          return;
        } else {
          setError("corRegNo", {
            type: "custom",
            message: "",
          });
        }
      }

      const emailCheck = getValues("email");
      if (emailCheck !== null && emailCheck !== "") {
        console.log("emailCheck", emailCheck);
        if (emailCheck !== undefined && emailCheck !== "") {
          const pattern = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/g;
          // console.log(pattern.test(emailCheck), "pattern.test(emailCheck)");
          if (pattern.test(emailCheck) === false) {
            setError("email", {
              type: "custom",
              message: "올바르지 않은 이메일 형식입니다.",
            });
            return;
          }
        }
      }

      const phoneCheck = getValues("phoneNumber");
      if (phoneCheck !== null && phoneCheck !== "") {
        console.log("phoneCheck", phoneCheck);
        if (phoneCheck !== undefined && phoneCheck !== "") {
          const patterns = [
            /^[+]82\d{10}$/,
            /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/,
          ];
          const first = patterns[0].test(data.phoneNumber);
          const second = patterns[1].test(data.phoneNumber);
          console.log(first, "first");
          console.log(second, "second");
          if (first === false && second === false) {
            setError("phoneNumber", {
              type: "custom",
              message: "올바르지 않은 휴대폰 번호입니다.",
            });
            return;
          }
        }
      }

      const orgData: SaveOrgDto = {
        bizType: BizType.SP,
        name: "",
        bizRegNo: "",
        corRegNo: "",
        ceoName: "",
        bizCondition: "",
        bizItem: "",
        taxType: data.taxType,
        address: "",
        email: "" || null,
        phoneNumber: "" || null,
        partnerId: "",
      };

      const saveModel: SendBody = {
        organization: orgData,
        mediaList: uploadMediaFile,
      };
      e.preventDefault();
      if (id) {
        orgData.id = Number(id);
      }
      orgData.address = data.address;
      orgData.bizCondition = data.bizCondition;
      orgData.bizItem = data.bizItem;
      orgData.bizRegNo = removeHyphens(data.bizRegNo);
      orgData.bizType = data.bizType;
      orgData.ceoName = data.ceoName;
      orgData.corRegNo = removeHyphens(data.corRegNo);
      orgData.name = data.name;
      orgData.classifications = data.classifications;
      orgData.ceoName = data.ceoName;
      orgData.partnerId = data.partnerId;
      if (data.email !== "") {
        orgData.email = data.email;
      }
      if (data.phoneNumber !== "") {
        orgData.phoneNumber = data.phoneNumber;
      }

      //do somthing....
      saveModel.organization = orgData;
      // console.log(saveModel.organization);
      saveModel.mediaList = [...privateFiles, ...uploadMediaFile];

      // console.log(saveModel);
      if (id) {
        sendEditOrg(saveModel);
      } else {
        sendAddOrg(saveModel);
      }

      e.target.reset();

      return false;
    },
    [
      checkValidateCorpNumber,
      checkValidateOrgNumber,
      getValues,
      id,
      privateFiles,
      sendAddOrg,
      sendEditOrg,
      setError,
      uploadMediaFile,
    ],
  );

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);

    e.preventDefault();

    return false;
  };

  const goOutLink = () => {
    return window.open(checkBizRegNoLink, "_blank")?.focus();
  };

  const moveList = () => {
    if (id) {
      setConfirmFlag(true);
    } else {
      goList();
    }
  };

  const goList = useCallback(() => {
    const path = PagePath.organization.list;
    navigate(path);
  }, [navigate]);

  useEffect(() => {
    setValue("mediaList", privateFiles);
  }, [privateFiles, setValue]);

  //사업자 등록/ 수정 폼
  return (
    <>
      <div className="contents-container__wrap">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="contents-container__wrap-contents">
            <ContentsIdSection title="기본정보" />
            {/* CI/BI */}
            <section className="mb20">
              <div className="contents-container__grid mb10">
                <div className="contents-container__grid-index">
                  <p>CI/BI ({uploadMediaFile.length}/1)</p>
                </div>
              </div>

              <OrgImagesUpdate
                representative={true}
                onlyImg={true}
                fileMaxLength={1}
                images={uploadMediaFile}
                setImages={setUploadedMediaFile}
              />
            </section>
            <section>
              <div className="contents-container__sub-title">
                <h2>사업자 상세 정보</h2>
              </div>
            </section>
            {/* 사업자 형태 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">파트너</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-center flex-row justify-contents-start">
                  {selectedPartner && (
                    <p className="mr8">
                      <a
                        className="text-hilight "
                        href={`${PagePath.partner.detail.replace(
                          ":id",
                          selectedPartner.id!.toString(),
                        )}`}
                        target="_blank"
                        rel="noreferrer"
                      >{`${selectedPartner.name}(${selectedPartner.code})`}</a>
                    </p>
                  )}
                  {!id && (
                    <BaseButton
                      title={"파트너 선택"}
                      className="color-white"
                      onClick={() => {
                        setIsPartnerModalOpen(true);
                      }}
                    />
                  )}
                </div>
                {errors.partnerId && <p className="validation-text">{errors.partnerId.message}</p>}
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <div className="flex-center">
                  <p className="required">구분</p>
                  <div className="tooltip">
                    <div className="tooltip__trigger">
                      <div className="tooltip__trigger-icon"></div>
                    </div>
                    <div className="tooltip__contents">복수선택 가능</div>
                  </div>
                </div>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax200">
                  <div className="d-flex">
                    {classifications.map((classification, idx) => {
                      return (
                        <Controller
                          key={classification}
                          name="classifications"
                          control={control}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <BaseCheckbox
                                id={`${classification}${idx}`}
                                name={`${classification}${idx}`}
                                checked={value?.includes(classification)}
                                onChange={(checked: boolean) => {
                                  let classificationsArr: string[] = watch(`classifications`) || [];

                                  if (checked) {
                                    classificationsArr.push(classification);
                                    setValue("classifications", classificationsArr);
                                    trigger("classifications");
                                  } else {
                                    classificationsArr = classificationsArr.filter(
                                      (item) => classification !== item,
                                    );
                                    setValue("classifications", classificationsArr);
                                    trigger("classifications");
                                  }
                                  setValue("classifications", classificationsArr);
                                  trigger("classifications");
                                }}
                                label={classification === "OWNER" ? "소유주" : "일반 사업자"}
                                className="mr10"
                              />
                            );
                          }}
                        ></Controller>
                      );
                    })}
                  </div>
                </div>
                {errors.classifications && (
                  <p className="validation-text">{errors.classifications.message}</p>
                )}
              </div>
            </section>

            {/* 사업자 형태 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">사업자 형태</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax200">
                  <Controller
                    control={control}
                    name="bizType"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <div className="d-flex">
                        <BaseRadio
                          name={name}
                          id={"SP"}
                          value={"SP"}
                          checked={value === "SP" ? true : false}
                          onChange={() => {
                            onChange("SP");
                            setCopRegNoFlag(false);
                            setTaxOption(individualTaxOption);
                            setValue("taxType", individualTaxOption[0].value);
                          }}
                          label={"개인사업자"}
                          className="mr10"
                        />
                        <BaseRadio
                          name={name}
                          id={"CORP"}
                          value={"CORP"}
                          checked={value === "CORP" ? true : false}
                          onChange={() => {
                            onChange("CORP");
                            setCopRegNoFlag(true);
                            setTaxOption(corpTaxOption);
                            setValue("taxType", corpTaxOption[0].value);
                          }}
                          label={"법인사업자"}
                        />
                      </div>
                    )}
                  ></Controller>
                </div>
              </div>
            </section>
            {/* 사업자명(상호) */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">법인명/상호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax200">
                  <Controller
                    control={control}
                    name="name"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </section>
            {/* 사업자 등록 번호 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">사업자 등록 번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax200">
                  <Controller
                    control={control}
                    name="bizRegNo"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        name={name}
                        value={value}
                        maxLength={12}
                        onChange={(value: string) => {
                          onChange(companyRegistrationHypen(value));
                        }}
                        onBlur={(e: any) => {
                          const check = checkValidateOrgNumber(getValues("bizRegNo"));

                          if (check === false) {
                            setError("bizRegNo", {
                              type: "custom",
                              message: "유효한 사업자 번호가 아닙니다.",
                            });
                          } else {
                            setError("bizRegNo", {
                              type: "custom",
                              message: "",
                            });
                          }
                        }}
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
                <p>
                  <span className="text-red">
                    사업자 등록번호를 조회하여 사업자의 상태를 확인 후 등록해주세요.
                  </span>
                  <a
                    href={checkBizRegNoLink}
                    // onClick={goOutLink}
                    target="_blank"
                    className="ml10 text-hilight"
                    rel="noreferrer"
                  >
                    휴/폐업 조회
                  </a>
                </p>
              </div>
            </section>
            {/* 법인 등록 번호 */}
            {copRegNoFlag && (
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className={copRegNoFlag ? "required" : ""}>법인 등록 번호</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax200">
                    <Controller
                      control={control}
                      name="corRegNo"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseInput
                          name={name}
                          value={value}
                          maxLength={14}
                          onChange={(value: string) => {
                            const formattedValue = corpHypen(value);
                            onChange(formattedValue);
                          }}
                          onBlur={(e: any) => {
                            const check = checkValidateCorpNumber(getValues("corRegNo"));
                            console.log("check", check);
                            if (check === false) {
                              setError("corRegNo", {
                                type: "custom",
                                message: "유효한 법인 등록 번호가 아닙니다.",
                              });
                            } else {
                              setError("corRegNo", {
                                type: "custom",
                                message: "",
                              });
                            }
                          }}
                          errorText={error?.message}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>
            )}
            {/* 대표자/성명 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">대표자/성명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax200">
                  <Controller
                    control={control}
                    name="ceoName"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </section>
            {/* 업태 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">업태</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax200">
                  <Controller
                    control={control}
                    name="bizCondition"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </section>
            {/* 종목 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">종목</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax200">
                  <Controller
                    control={control}
                    name="bizItem"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </section>
            {/* 과세 유형 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">과세 유형</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax200">
                  <Controller
                    control={control}
                    name="taxType"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseSelect
                        name={name}
                        value={value}
                        stateOptions={taxOption}
                        setStateValue={onChange}
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </section>
            {/* 사업장 주소지 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">사업장 주소지</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax300">
                  <Controller
                    control={control}
                    name="address"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </section>
            {/* 사업자 증빙 서류*/}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">사업자 증빙 서류</p>
              </div>
              <Controller
                control={control}
                name="mediaList"
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <FileUpload
                    setPrivateFiles={(file: any) => {
                      setPrivateFiles(file);
                      onChange(file);
                    }}
                    privateFiles={privateFiles}
                    errorText={error?.message}
                  />
                )}
              ></Controller>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>이메일</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax200">
                  <Controller
                    control={control}
                    name="email"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>휴대폰</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax200">
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </section>
          </div>
          {/* 버튼영역 */}
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title="목록으로"
                className="color-white size-large"
                onClick={() => moveList()}
              />
            </div>
            <div className="right-area">
              <BaseButton title="저장" type={"submit"} className=" size-large" />
            </div>
          </div>
        </form>

        {/* 취소, 확인 버튼이 있는 파트너 변경 confirm 모달 */}
        {newSelectedPartner && 
            <BaseModal
            isOpen={isPartnerChangeConfirmModalOpen.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setIsPartnerChangeConfirmModalOpen({ isOpen: false })}
            onClick={() => {
              changePartners(newSelectedPartner)
            }}
          >
            <p>{isPartnerChangeConfirmModalOpen.message}</p>
          </BaseModal>
        }
    

        {isPartnerModalOpen && (
          <PartnerSelectModal
            onCanceled={() => {
              setIsPartnerModalOpen(false);
            }}
            onAdded={onAddSelectPartners}
            defaultValues={selectedPartner ? [selectedPartner] : []}
          />
        )}
        {openFlag && (
          <BaseModal isOpen={openFlag} btnRightTitle="확인" onClick={() => moveOrgDetail(moveId!)}>
            <span>저장 되었습니다.</span>
          </BaseModal>
        )}
        {confirmFlag && (
          <BaseModal
            isOpen={confirmFlag}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={() => goList()}
            onClose={() => setConfirmFlag(false)}
          >
            <div>
              <span>작성중인 내용이 있습니다.</span>
              <span>계속할까요?</span>
            </div>
          </BaseModal>
        )}
      </div>
    </>
  );
};

export default BasicInfoForm;
