import _ from "lodash";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { getContractApply } from "src/api/contract/contract-api";
import { SearchParams } from "src/api/contract/contract-types";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { contract_bill_user_errors, PageMeta } from "src/api/public-types";
import { BaseButton, BasePagination, ContentsIdSection } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { PagePath } from "src/pages/product/details";
import useSpaceProductColumns from "../../columns/UseSpaceProduct";

type Props = {
  orgId: string;
};

const UseProductInfo = ({ orgId }: Props) => {
  const { setLoadingBar } = useLoadingBarContext();
  const navigate = useNavigate();
  const location = useLocation();
  // const { id } = useParams();
  const { openErrorModal } = useErrorModalContext();
  const [data, setData] = useState([]);
  // const classname = "minmax300";

  const [pageMeta, setPateMeta] = useState<PageMeta>();
  const [pageNum, setPageNum] = useState(0);
  const queryParams: SearchParams = useMemo(() => {
    const _queryParams: SearchParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }

    if (!_queryParams?.searchType) {
      _queryParams.searchType = "MB_ORGANIZATION_ID";
    }
    if (!_queryParams?.searchValue) {
      _queryParams.searchValue = orgId;
    }

    return _queryParams;
  }, [location, orgId]);

  //호출부
  const { executeAsync: getContractList } = useApiOperation(getContractApply);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: useSpaceProductColumns,
      data,
      disableSortBy: true,
    },
    useBlockLayout,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  // 신청/계약 목록 api
  const callList = useCallback(
    async (passParams: SearchParams) => {
      setLoadingBar(true);
      // const params: SearchParams = {};
      // params.searchValue = orgId;
      // params.searchType = "MB_ORGANIZATION_ID";
      // params.searchValue = decodeURIComponent(params.searchValue || "");

      let response: any = await getContractList(passParams);

      if (response && response.status >= 200 && response.status <= 299) {
        const sorted: any = _.sortBy(response.data.data.content, [
          "contractStartTime",
          "contractManageId",
        ]).reverse();
        setData(sorted);
        setPateMeta(response.data.meta.pageMeta);
      } else {
        let message = errorMessageHandler(
          response.status,
          response.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
        openErrorModal(message, code);
      }

      setLoadingBar(false);
    },
    [setLoadingBar, getContractList, openErrorModal],
  );

  const goList = useCallback(() => {
    navigate(PagePath.organization.list);
  }, [navigate]);

  useEffect(() => {
    if (data.length === 0) {
      callList(queryParams);
    }
  }, [callList, data.length, queryParams]);

  // 사업자 신청/계약 탭 페이지네이션
  const onPagination = async (page: number) => {
    const pageParams = { ...queryParams, page };
    // const page = pageParams.page;
    setPageNum(Number(page));
    await callList(pageParams);
  };

  return (
    <>
      <div className="contents-container__wrap detail-basic-info">
        {/* <LoadingBar visible={loading}/> */}

        <div className="contents-container__wrap-contents">
          <ContentsIdSection title="신청/계약" id={orgId} />
          <div className="contents-container__1200">
          <div className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">전체 {data.length || 0} </p>
              </div>
            </div>
            <section className="state-change-info inner-tab-table">
              <div>
                <section className="state-change-info inner-tab-table">
                  <div>
                    <div {...getTableProps()} className="base-table sticky">
                      <div className="header">
                        {headerGroups.map((headerGroup) => (
                          <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                            {headerGroup.headers.map((header) => {
                              return (
                                <div {...header.getHeaderProps()} className="base-table__th">
                                  {header.render("Header")}
                                  {/* <div className="ic_sort"></div> */}
                                </div>
                              );
                            })}
                          </div>
                        ))}
                      </div>
                      <div {...getTableBodyProps()} className="body">
                        {rows.map((row: any) => {
                          prepareRow(row);

                          return (
                            <div
                              {...row.getRowProps()}
                              className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                            >
                              {row.cells.map((cell: any) => {
                                return (
                                  <div {...cell.getCellProps()} className="base-table__td">
                                    {cell.render("Cell")}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <BasePagination
                    pageIndex={pageNum || 0}
                    totalPages={Number(pageMeta?.totalPages || 0)}
                    goPage={(pageIndex: number) => {
                      onPagination(Number(pageIndex));
                    }}
                  />
                </section>
              </div>
            </section>
          </div>
        </div>
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              onClick={() => {
                goList();
              }}
              className="color-white size-large"
            />
          </div>
          <div className="right-area"></div>
        </div>
      </div>
    </>
  );
};

export default UseProductInfo;
