import moment from "moment";
import React from "react";
import { Control, Controller, UseFormReturn } from "react-hook-form";
import { MetaData } from "src/api/public-types";
import { BaseDatePicker, BaseInput, BaseRadio, BaseTextarea, BaseToggle } from "src/components";
import { BaseInputNumberFormat } from "src/components/BaseInputNumberFormat";
import { deleteCommas, inputPriceFormat, numberToStringWithComma, onlyNumber } from "src/utils";
import { ProductRent, ProductRentDiscount } from "../../product-types";
import { FormData } from "./ProductInfoForm";

type Props = {
  control: Control<any>;
  watch: UseFormReturn<FormData>["watch"];
  errors: any;
  setValue: Function;
};
/* 
  Full Court
 */

const FullCourt = ({ control, watch, errors, setValue }: Props) => {
  return (
    <>
      <article className="contents-container__divide-top pb30">
        <div className="contents-container__sub-title">
          <h2>Full Court</h2>
        </div>
        {/* 계약기간 */}
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">계약기간</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <span className="font14 mr10">최소</span>
              <div className="minmax60">
                <Controller
                  control={control}
                  name="minLeasePeriod"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                    return (
                      <>
                        <BaseInput
                          type="text"
                          onChange={(text: string) => {
                            const inputValue = onlyNumber(text);
                            onChange(inputValue);
                          }}
                          value={String(value)}
                          name={name}
                        />
                      </>
                    );
                  }}
                ></Controller>
              </div>
              <span className="font14 mx10">개월 ~ 최대</span>
              <div className="minmax60">
                <Controller
                  control={control}
                  name="maxLeasePeriod"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                    return (
                      <BaseInput
                        type="text"
                        onChange={(text: string) => {
                          const inputValue = onlyNumber(text);
                          onChange(inputValue);
                        }}
                        value={String(value)}
                        name={name}
                      />
                    );
                  }}
                ></Controller>
              </div>
              <span className="font14 ml10">개월</span>
            </div>
            {errors?.maxLeasePeriod && (
              <p className="validation-text">{errors?.maxLeasePeriod?.message}</p>
            )}
          </div>
        </section>

        {/* 입주 가능일 */}
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">입주 가능일</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <Controller
                control={control}
                name="isRightAwayMoveIn"
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <>
                      <BaseRadio
                        id={"moveInAvailDate1"}
                        name={name}
                        label="즉시"
                        className="mr16"
                        value="false"
                        checked={value ? true : false}
                        onChange={(checked: boolean, e) => {
                          onChange(true);
                          if (checked) {
                            setValue("moveInAvailDate", "");
                          }
                        }}
                      />
                      <BaseRadio
                        id={"moveInAvailDate2"}
                        name={name}
                        checked={!value ? true : false}
                        onChange={(checked: boolean) => {
                          if (checked) {
                            onChange(false);
                          }
                        }}
                      />
                    </>
                  );
                }}
              ></Controller>

              <div className="minmax130 ml10">
                <Controller
                  control={control}
                  name="moveInAvailDate"
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <BaseDatePicker
                        setDate={onChange}
                        selectedDate={value ? moment(value).toDate() : null}
                        name={name}
                        readonly={watch("isRightAwayMoveIn") === true ? true : false}
                      />
                    );
                  }}
                ></Controller>
              </div>
            </div>
            {errors?.moveInAvailDate && (
              <p className="validation-text">{errors?.moveInAvailDate?.message}</p>
            )}
          </div>
        </section>

        {/*  */}
        {/* 보증금 */}
        <section className="contents-container__grid contents-container__1200">
          <div className="contents-container__grid-index">
            <p className="required">보증금</p>
          </div>
          <div className="contents-container__grid-contents">
            <table className="inner-table">
              <thead>
                <tr>
                  <th>
                    <div className="">보증금 (원)</div>
                  </th>
                  <th>
                    <div className="">계약금 (원)</div>
                  </th>
                  <th>
                    <div className="minmax420">잔금/일자 (원)</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="minmax180 flex-center no-wrap">
                      <Controller
                        control={control}
                        name="deposit"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseInputNumberFormat
                              type="text"
                              onChange={(text: string) => {
                                onChange(text);
                              }}
                              value={inputPriceFormat(value)}
                              name={name}
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </td>
                  <td>
                    <div className="minmax180 flex-center no-wrap">
                      <Controller
                        control={control}
                        name="earnest"
                        render={({
                          field: { onChange, value, name, ref },
                          fieldState: { error },
                        }) => {
                          return (
                            <BaseInputNumberFormat
                              inputRef={ref}
                              type="text"
                              onChange={(text: string) => {
                                onChange(text);
                              }}
                              value={inputPriceFormat(value) || ""}
                              name={name}
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </td>
                  <td>
                    <div className="flex-center no-wrap">
                      <Controller
                        control={control}
                        name="balanceFullPayment"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          let deposit = watch && watch("deposit");
                          let earnest = watch && watch("earnest");

                          if (typeof deposit === "string") {
                            deposit = deleteCommas(deposit);
                          }

                          if (typeof earnest === "string") {
                            earnest = deleteCommas(earnest);
                          }

                          value = Number(deposit) - Number(earnest);

                          return (
                            <BaseInputNumberFormat
                              type="text"
                              //
                              value={numberToStringWithComma(value)}
                              readonly
                            />
                          );
                        }}
                      ></Controller>
                      <span className="mx10">/ 계약 후</span>
                      <div className="minmax60">
                        <Controller
                          control={control}
                          name="balanceFullPaymentDate"
                          render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                            return (
                              <BaseInput
                                type="number"
                                onChange={onChange}
                                value={value}
                                name={name}
                              />
                            );
                          }}
                        ></Controller>
                      </div>
                      <span className="mx10">일 이내</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {(errors?.deposit || errors?.earnest || errors?.balanceFullPaymentDate) && (
              <p className="validation-text">
                {errors?.deposit?.message ||
                  errors?.earnest?.message ||
                  errors?.balanceFullPaymentDate?.message}
              </p>
            )}
          </div>
        </section>

        {/* 보증금 할인 */}
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>보증금 할인</p>
          </div>
          <div className="contents-container__grid-contents">
            <Controller
              control={control}
              name="isUseSaleDeposit"
              render={({ field: { onChange, name, value, ref } }) => {
                return (
                  <>
                    <div>
                      <BaseToggle onChange={onChange} checked={value} />
                    </div>
                    {value && (
                      <table className="inner-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="">보증금 (원)</div>
                            </th>
                            <th>
                              <div className="">계약금 (원)</div>
                            </th>
                            <th>
                              <div className="minmax420">잔금/일자 (원)</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="minmax180 flex-center no-wrap">
                                <Controller
                                  control={control}
                                  name="saleDeposit"
                                  render={({
                                    field: { onChange, value, name, ref },
                                    fieldState: { error },
                                  }) => {
                                    return (
                                      <BaseInputNumberFormat
                                        inputRef={ref}
                                        type="text"
                                        onChange={onChange}
                                        value={inputPriceFormat(value)}
                                        name={name}
                                      />
                                    );
                                  }}
                                ></Controller>
                              </div>
                            </td>
                            <td>
                              <div className="minmax180 flex-center no-wrap">
                                <Controller
                                  control={control}
                                  name="saleEarnest"
                                  render={({
                                    field: { onChange, value, name, ref },
                                    fieldState: { error },
                                  }) => {
                                    return (
                                      <BaseInputNumberFormat
                                        inputRef={ref}
                                        type="text"
                                        onChange={onChange}
                                        value={inputPriceFormat(value)}
                                        name={name}
                                      />
                                    );
                                  }}
                                ></Controller>
                              </div>
                            </td>
                            <td>
                              <div className="flex-center no-wrap">
                                <Controller
                                  control={control}
                                  name="saleBalanceFullPayment"
                                  render={({ field: { onChange, value, name } }) => {
                                    //
                                    let saleDeposit = watch && watch("saleDeposit");
                                    let saleEarnest = watch && watch("saleEarnest");

                                    if (typeof saleDeposit === "string") {
                                      saleDeposit = deleteCommas(saleDeposit);
                                    }

                                    if (typeof saleEarnest === "string") {
                                      saleEarnest = deleteCommas(saleEarnest);
                                    }

                                    value = Number(saleDeposit) - Number(saleEarnest);

                                    return (
                                      <BaseInputNumberFormat
                                        type="text"
                                        value={numberToStringWithComma(value)}
                                        readonly
                                      />
                                    );
                                  }}
                                ></Controller>
                                <span className="mx10"> / 계약 후</span>

                                <div className="minmax60">
                                  <BaseInput
                                    readonly
                                    value={watch && watch("balanceFullPaymentDate")}
                                  />
                                </div>
                                <span className="mx10">일 이내</span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </>
                );
              }}
            ></Controller>
          </div>
        </section>

        {/* 이용료  */}
        <section className="contents-container__grid contents-container__1200">
          <div className="contents-container__grid-index">
            <p className="required">이용료</p>
          </div>
          <div className="contents-container__grid-contents">
            <table className="inner-table">
              <thead>
                <tr>
                  <th>
                    <div>기준일</div>
                  </th>
                  <th>
                    <div>금액(부가세 별도 / 원)</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="minmax180 flex-center-center no-wrap">
                      <span className="ml10 mr16 text-center">1개월</span>
                    </div>
                  </td>
                  <td>
                    <div className="minmax220 flex-center no-wrap">
                      <Controller
                        control={control}
                        name="rentalCostList" //
                        render={({
                          field: { onChange, value, name, ref },
                          fieldState: { error },
                        }) => {
                          return (
                            <BaseInputNumberFormat
                              inputRef={ref}
                              type="text"
                              onChange={(text: string) => {
                                // if (value) {
                                value[0].value1 = text;

                                if (watch("productType") === "TIME_COURT") {
                                  value[0].metaItem = ProductRent.MIUNUTE;
                                }
                                onChange(value);
                                // }
                              }}
                              value={inputPriceFormat(value[0]?.value1)}
                              name={name}
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {errors?.rentalCostList && (
              <p className="validation-text">{errors?.rentalCostList?.message}</p>
            )}
          </div>
        </section>

        {/* 이용료 할인 */}
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>이용료 할인</p>
          </div>
          <div className="contents-container__grid-contents">
            <Controller
              control={control}
              name="isUseSaleRentalCost"
              render={({ field: { onChange, name, value } }) => {
                return (
                  <>
                    <div>
                      <BaseToggle onChange={onChange} checked={value ? true : false} />
                    </div>
                    {value && (
                      <table className="inner-table">
                        <thead>
                          <tr>
                            <th>
                              <div>기준일</div>
                            </th>
                            <th>
                              <div>금액(부가세 별도 / 원)</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="minmax180 flex-center-center no-wrap">
                                <span className="ml10 mr16 text-center">1개월</span>
                              </div>
                            </td>
                            <td>
                              <div className="minmax220 flex-center no-wrap">
                                <Controller
                                  control={control}
                                  name="saleRentalCostList" //
                                  render={({
                                    field: { onChange, value, name, ref },
                                    fieldState: { error },
                                  }) => {
                                    return (
                                      <BaseInputNumberFormat
                                        inputRef={ref}
                                        type="text"
                                        onChange={(text: string) => {
                                          if (value) {
                                            value[0].value1 = text;
                                            if (watch("productType") === "TIME_COURT") {
                                              value[0].metaItem = ProductRentDiscount.MIUNUTE;
                                            }
                                            onChange(value);
                                          }
                                        }}
                                        value={inputPriceFormat(value[0]?.value1)}
                                        name={name}
                                      />
                                    );
                                  }}
                                ></Controller>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </>
                );
              }}
            ></Controller>
          </div>
        </section>

        {/* 이용료 안내 팝업 */}
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">이용료 안내 팝업</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <Controller
                control={control}
                name="isInformPopup"
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  return (
                    <>
                      <BaseRadio
                        id={"isInformPopupFalse"}
                        name={name}
                        label="설정 안 함"
                        className="mr16"
                        value={"false"}
                        checked={!value && true}
                        onChange={() => {
                          onChange(false);
                        }}
                      />
                      <BaseRadio
                        id={"isInformPopupTrue"}
                        name={name}
                        label="설정"
                        value={"true"}
                        checked={value && true}
                        onChange={() => {
                          onChange(true);
                        }}
                      />
                    </>
                  );
                }}
              ></Controller>
            </div>

            <div className="minmax400">
              <Controller
                control={control}
                name="informPopupDesc" //
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  return (
                    <BaseTextarea
                      className="mr8"
                      onChange={onChange}
                      value={value}
                      name={name}
                      maxLength={1000}
                      readonly={watch && watch("isInformPopup") === true ? false : true}
                    />
                  );
                }}
              ></Controller>
            </div>
            {watch && watch("isInformPopup") === true && errors?.informPopupDesc && (
              <p className="validation-text">{errors?.informPopupDesc?.message}</p>
            )}
          </div>
        </section>

        {/* 관리비 */}

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">관리비</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <Controller
                control={control}
                name="isImpositionFee"
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <>
                      <BaseRadio
                        id={"maintenanceFeeFalse"}
                        name={name}
                        label="부과하지 않음"
                        className="mr16"
                        onChange={() => {
                          onChange(false);
                        }}
                        checked={!value ? true : false}
                      />
                      <BaseRadio
                        id={"maintenanceFeeTrue"}
                        name={name}
                        label="부과"
                        onChange={() => {
                          onChange(true);
                        }}
                        checked={value ? true : false}
                      />
                    </>
                  );
                }}
              ></Controller>
              <div className="minmax160 flex-center ml10">
                <Controller
                  control={control}
                  name="maintenanceFee"
                  render={({ field: { onChange, name, value, ref }, fieldState: { error } }) => {
                    return (
                      <BaseInput
                        inputRef={ref}
                        type="text"
                        name={name}
                        value={watch("isImpositionFee") === true ? inputPriceFormat(value) : 0}
                        onChange={onChange}
                        readonly={watch("isImpositionFee") === false}
                      />
                    );
                  }}
                ></Controller>
                <span className="no-wrap ml10">/ 월</span>
              </div>
            </div>

            {errors?.isImpositionFee && (
              <p className="validation-text">{errors?.isImpositionFee?.message}</p>
            )}

            {watch("isImpositionFee") === true && (
              <table className="inner-table mt16">
                <>
                  <thead>
                    <tr>
                      <th>
                        <div>항목</div>
                      </th>
                      <th>
                        <div>구분</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* 리스트 피그마 및 기획서 참고 */}

                    {watch("productMaintenanceFeeList") &&
                      watch("productMaintenanceFeeList").map((item: MetaData, idx: number) => {
                        const names = () => {
                          if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_1") {
                            return "일반 관리";
                          }
                          if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_2") {
                            return "공용부 청소";
                          }
                          if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_3") {
                            return "공동 전기";
                          }
                          if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_4") {
                            return "공동 수도";
                          }
                          if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_5") {
                            return "공동 가스";
                          }
                          if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_6") {
                            return "청소";
                          }
                          if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_7") {
                            return "전기";
                          }
                          if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_8") {
                            return "수도";
                          }
                          if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_9") {
                            return "가스";
                          }
                        };

                        const labelChange = () => {
                          if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_7") {
                            return "실비 청구";
                          } else if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_8") {
                            return "실비 청구";
                          } else if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_9") {
                            return "실비 청구";
                          } else {
                            return "해당사항 없음";
                          }
                        };

                        return (
                          <tr key={item?.orderNums}>
                            <td>
                              <div className="minmax180 flex-center-center no-wrap">
                                <span className="ml10 mr16 text-center">{names()}</span>
                              </div>
                            </td>
                            <td>
                              <div className="minmax260 flex-center no-wrap">
                                <Controller
                                  control={control}
                                  name="productMaintenanceFeeList"
                                  render={({ field: { onChange, name, value } }) => {
                                    return (
                                      <>
                                        <BaseRadio
                                          id={String(idx)}
                                          name={item?.metaItem || ""}
                                          label="관리비 포함"
                                          className="mr16"
                                          checked={value[idx]?.value1 === "true" ? true : false}
                                          value="true"
                                          onChange={(checked: boolean, e) => {
                                            value[idx].value1 = e.target.value;
                                            onChange(value);
                                          }}
                                        />

                                        <BaseRadio
                                          id={String(idx + 10)}
                                          name={item?.metaItem || ""}
                                          label={labelChange()}
                                          checked={value[idx]?.value1 === "false" ? true : false}
                                          value="false"
                                          onChange={(checked: boolean, e) => {
                                            value[idx].value1 = e.target.value;
                                            onChange(value);
                                          }}
                                        />
                                      </>
                                    );
                                  }}
                                ></Controller>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {/* 리스트 피그마 및 기획서 참고 */}
                  </tbody>
                </>
              </table>
            )}
          </div>
        </section>
        {/* 사용인원 */}
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">사용인원</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <div className="minmax80 ml10">
                <Controller
                  control={control}
                  name="useNums"
                  render={({ field: { onChange, name, value, ref }, fieldState: { error } }) => {
                    return (
                      <BaseInput
                        inputRef={ref}
                        type="number"
                        name={name}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                ></Controller>
              </div>
              <span className="ml10 mr16">명</span>

              <Controller
                control={control}
                name="isDeskChairIncluded"
                render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                  return (
                    <>
                      <BaseRadio
                        id={"isDeskChairIncludedTrue"}
                        name={name}
                        label="책상/의자 포함"
                        className="mr16"
                        onChange={() => onChange(true)}
                        checked={value ? true : false}
                      />
                      <BaseRadio
                        id={"isDeskChairIncludedFalse"}
                        name={name}
                        label="책상/의자 없음"
                        onChange={() => onChange(false)}
                        checked={!value ? true : false}
                      />
                    </>
                  );
                }}
              ></Controller>
            </div>
            {(errors?.useNums || errors?.isDeskChairIncluded) && (
              <p className="validation-text">
                {errors?.useNums?.message || errors?.isDeskChairIncluded.message}
              </p>
            )}
          </div>
        </section>
        {/* 계정 최대 인원 */}
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">계정 최대 인원</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <div className="minmax80 ml10">
                <Controller
                  control={control}
                  name="maxNums"
                  render={({ field: { onChange, name, value, ref }, fieldState: { error } }) => {
                    return (
                      <BaseInput
                        inputRef={ref}
                        type="number"
                        name={name}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                ></Controller>
              </div>
              <span className="ml10 mr16">명</span>
            </div>
            {errors?.maxNums && <p className="validation-text">{errors?.maxNums?.message}</p>}
          </div>
        </section>
      </article>
    </>
  );
};
export default FullCourt;
