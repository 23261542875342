import moment from "moment";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { BaseButton, ContentsIdSection } from "src/components";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import pagePath from "src/pagePath.json";
import { checkContractStatus } from "src/pages/contract/contract-util";
import { useTitleOperation } from "src/pages/hooks";
import { YmdFormat } from "src/utils";

const ScheduleBasicInfo = ({
  basicInfo,
  contractBaseInfo,
  schedule,
  scheduleMntList,
  providerDetail,
  partner,
  application,
}: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const billStatusView = (schedule: any) => {
    const today = moment().format(YmdFormat.YYYY_MM_DD);
    const thisMonth = moment().format(YmdFormat.YYYY_MM);
    const checkDay = moment(thisMonth + "-" + schedule.payLimitDay, YmdFormat.YYYY_MM_DD).format(
      YmdFormat.YYYY_MM_DD,
    );
    let rtn = "결제 대기";

    switch (schedule.billPayStatus) {
      case "PAYMENT_SUCCESS":
        rtn = "결제성공";
        break;
      case "PAYMENT_FAIL":
        rtn = "결제실패";
        break;
      case "PAYMENT_CANCEL":
        rtn = "결제취소";
        break;
      case "CONTRACT_CANCEL":
        rtn = "계약해지";
        break;
    }

    //연체 체크
    if (moment(today).isAfter(moment(checkDay))) {
      rtn = "연체";
    }
    //중지 체크
    if (schedule.isContractCancel) {
      rtn = "중지";
    }

    return rtn;
  };
  const goMemberDetail = (id: number) => {
    let path = "";
    if (id) {
      path = pagePath.member.detail.replace(":id", id!.toString());
    }
    return path;
  };
  useTitleOperation(contractBaseInfo?.contractApplyNumber);
  useEffect(() => {}, []);
  return (
    <div className="contents-container__wrap detail-basic-info">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection
          title="빌링스케쥴 정보"
          id={basicInfo?.contractId}
          noTitle="신청번호"
          no={contractBaseInfo?.contractApplyNumber}
          // status={basicInfo?.step}
        />
        <article className="pb20">
          <div className="contents-container__1200">
            <div className="contents-container__sub-title">
              <h2>신청/계약 정보</h2>
            </div>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>신청 번호</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <Link
                      to={pagePath.contract.detail.replace(":id", basicInfo?.contractId)}
                      className={"text-hilight"}
                      target="_blank"
                    >
                      {contractBaseInfo?.contractApplyNumber}
                    </Link>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>계약 상태</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {contractBaseInfo?.contractStep
                      ? checkContractStatus(contractBaseInfo?.contractStep)
                      : "-"}
                  </p>
                </div>
              </section>
            </div>
            <div className="flex-center">
              {/* 회원번호 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>상품 id</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <Link
                      to={pagePath.product.detail.replace(":id", basicInfo?.spaceProductId)}
                      target="_blank"
                      className="text-hilight"
                    >
                      {basicInfo?.spaceProductId}
                    </Link>
                  </div>
                </div>
              </section>
              {/* 이름 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>상품명</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{basicInfo?.spaceProductName}</p>
                </div>
              </section>
            </div>

            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>계약자 회원 번호</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    <Link
                      to={goMemberDetail(basicInfo?.memberNo)}
                      className="text-hilight"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {basicInfo?.memberNo}
                    </Link>
                  </p>
                </div>
              </section>
              {/* 이름 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>계약자 명</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{application?.applicantName}</p>
                </div>
              </section>
            </div>
          </div>
        </article>
        <article className="contents-container__divide-top">
          <div className="contents-container__1200">
            <div className="contents-container__sub-title">
              <h2>신청/계약 빌링 기간</h2>
            </div>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>빌링 시작</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {schedule?.scheduleStartTime
                      ? moment(schedule?.scheduleStartTime).format(YmdFormat.YYYY_MM_DD)
                      : "-"}
                  </p>
                </div>
              </section>
              {/* 보증금 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>전체 회차</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{basicInfo?.totalBillingCount}</p>
                </div>
              </section>
            </div>
            <div className="flex-center">
              {/* 상품명 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>빌링 종료</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {schedule?.scheduleEndTime
                      ? moment(schedule?.scheduleEndTime).format(YmdFormat.YYYY_MM_DD)
                      : "-"}
                  </p>
                </div>
              </section>
            </div>
          </div>
        </article>
        <article className="contents-container__divide-top">
          <div className="contents-container__1200">
            <div className="contents-container__sub-title">
              <h2>이용료 결제 정보</h2>
            </div>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>프로바이더 명</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <Link
                      to={pagePath.provider.detail.replace(
                        ":id",
                        providerDetail?.provider?.providerId,
                      )}
                      target="_blank"
                      className="text-hilight"
                    >
                      {providerDetail?.provider?.providerName}
                    </Link>
                  </div>
                </div>
              </section>

              {/* <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>결제 상태</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{schedule ? billStatusView(schedule) : "N/A"}</p>
                </div>
              </section> */}
            </div>

            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>결제 타입</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {schedule?.contractPayType
                      ? schedule?.contractPayType === "PAY"
                        ? "카드결제형"
                        : "세금계산서형"
                      : "-"}
                  </p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>결제 방법</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {schedule?.regularPgcode === "creditcard" && "신용카드"}
                    {schedule?.regularPgcode === "banktransfer" && "계좌이체"}
                    {schedule?.regularPgcode === "CMS" && "CMS"}
                    {schedule?.regularPgcode === "virtualaccount" && "가상계좌"}
                  </p>
                </div>
              </section>
            </div>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>정기 청구일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {schedule?.payRegularDay + "일"}{" "}
                    {schedule?.payRegularMonth === "PREV_MONTH" && "(전월 기준)"}
                    {schedule?.payRegularMonth === "CURR_MONTH" && "(당월 기준)"}
                    {schedule?.payRegularMonth === "NEXT_MONTH" && "(익월 기준)"}
                  </p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>납부기한</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {schedule?.payLimitDay ? schedule?.payLimitDay + "일" : "-"}
                    {schedule?.payLimitDay &&
                      schedule?.payLimitMonth === "PREV_MONTH" &&
                      "(전월 기준)"}
                    {schedule?.payLimitDay &&
                      schedule?.payLimitMonth === "CURR_MONTH" &&
                      "(당월 기준)"}
                    {schedule?.payLimitDay &&
                      schedule?.payLimitMonth === "NEXT_MONTH" &&
                      "(익월 기준)"}
                  </p>
                </div>
              </section>
            </div>

            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>세금계산서 자동 발행</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {schedule?.contractPayType === "TAX"
                      ? schedule?.isTaxInvoiceIssue
                        ? "발행"
                        : "발행 안함"
                      : "발행 안함"}
                  </p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>세금계산서 발행일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {schedule?.contractPayType === "TAX" && schedule?.taxInvoiceIssueDay + "일"}{" "}
                    {schedule?.contractPayType === "TAX" &&
                      schedule?.taxInvoiceIssueMonth === "PREV_MONTH" &&
                      "(전월 기준)"}
                    {schedule?.contractPayType === "TAX" &&
                      schedule?.taxInvoiceIssueMonth === "CURR_MONTH" &&
                      "(당월 기준)"}
                    {schedule?.contractPayType === "TAX" &&
                      schedule?.taxInvoiceIssueMonth === "NEXT_MONTH" &&
                      "(익월 기준)"}{" "}
                    {schedule?.contractPayType !== "TAX" && "-"}
                  </p>
                </div>
              </section>
            </div>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>빌키</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {schedule?.billkey !== undefined
                      ? schedule?.billkey !== ""
                        ? "등록완료"
                        : "미등록"
                      : "-"}
                  </p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>관리비 청구 유형</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {basicInfo?.isMntContain !== undefined
                      ? basicInfo?.isMntContain
                        ? "관리비 포함"
                        : "관리비 별도"
                      : "-"}
                  </p>
                </div>
              </section>
            </div>
          </div>
        </article>
        {basicInfo?.isMntContain === false && (
          <article className="contents-container__divide-top">
            <div className="contents-container__1200">
              <div className="contents-container__sub-title">
                <h2>관리비 결제 정보</h2>
              </div>
              {scheduleMntList &&
                scheduleMntList.length > 0 &&
                scheduleMntList.map((sd: any, index: number) => {
                  return (
                    <div className="ml20 contents-container__divide-top" key={index}>
                      <div className=" flex-center">
                        <section className="contents-container__grid">
                          <div className="contents-container__grid-index">
                            <p>관리처 명</p>
                          </div>
                          <div className="contents-container__grid-contents">
                            <p>{sd?.providerName ? sd?.providerName : "-"}</p>
                          </div>
                        </section>
                        {/* <section className="contents-container__grid">
                          <div className="contents-container__grid-index">
                            <p>결제 상태</p>
                          </div>
                          <div className="contents-container__grid-contents">
                            <p>{sd ? billStatusView(sd) : "N/A"}</p>
                          </div>
                        </section> */}
                      </div>
                      <div className="flex-center">
                        <section className="contents-container__grid">
                          <div className="contents-container__grid-index">
                            <p>결제 타입</p>
                          </div>
                          <div className="contents-container__grid-contents">
                            <p>
                              {sd?.contractPayType !== undefined
                                ? sd?.contractPayType === "PAY"
                                  ? "일반결제"
                                  : "세금계산서형"
                                : "-"}
                            </p>
                          </div>
                        </section>
                        <section className="contents-container__grid">
                          <div className="contents-container__grid-index">
                            <p>결제 방법</p>
                          </div>
                          <div className="contents-container__grid-contents">
                            <p>
                              {sd?.regularPgcode === "creditcard" && "신용카드"}
                              {sd?.regularPgcode === "banktransfer" && "계좌이체"}
                              {sd?.regularPgcode === "CMS" && "CMS"}
                              {sd?.regularPgcode === "virtualaccount" && "가상계좌"}
                            </p>
                          </div>
                        </section>
                      </div>
                    </div>
                  );
                })}
            </div>
          </article>
        )}
        <article className="contents-container__divide-top">
          <div className="contents-container__1200 ">
            <div className="contents-container__sub-title">
              <h2>파트너 정보</h2>
            </div>

            <div className="flex-center">
              {/* 이용종료 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>파트너</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <Link
                      to={pagePath.partner.detail.replace(":id", partner?.id)}
                      target={"_blank"}
                      className="text-hilight"
                    >
                      {partner?.name}
                    </Link>
                  </div>
                </div>
              </section>

              {/* 빈칸 */}
            </div>
          </div>
        </article>

        <AdminMemoV3
          serviceId={Number(basicInfo?.contractId)}
          serviceType={ServiceTypes.SERVICE_BILLING_SCHEDULE}
          partnerId={String(application?.partnerId)}
        ></AdminMemoV3>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <BaseButton
            title="목록으로"
            onClick={() => navigate(pagePath.billing.list)}
            className="color-white size-large"
          />
        </div>
        {/* <div className="right-area">
          <BaseButton
            title="수정"
            // disabled={spaceProductType === "TIME_COURT"}
            onClick={() =>
              navigate(pagePath.billing.form.replace(":id", String(params.id)) + "?tab=basicInfo")
            }
            className="size-large"
          />
        </div> */}
      </div>
    </div>
  );
};

export default ScheduleBasicInfo;
