import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAccessGroupAsync,
  postAccessGroupAsync,
  putAccessGroupAsync,
} from "src/api/access/ac2-api";
import {
  AccessDeviceModel,
  AccessGroupFormParams,
  AccessGroupModel,
} from "src/api/access/ac2-types";
import { useApiOperation } from "src/api/hooks";
import { getPartnerList } from "src/api/partner";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal, BaseToggle, ContentsIdSection } from "src/components";
import DeviceSelectModal from "src/components/device/DeviceSelectModal";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { Partner } from "src/types/partner";
import DevicesTable from "../components/DevicesTable";
import PartnersTable from "../components/PartnersTable";

/*
  AccessGroupForm 등록 or 수정
*/

const BasicInfo = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openToast } = useToastContext();

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  type SubmitData = {
    accessGroupName: string;
    visitorAccessYn: boolean;
  };

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "accessDevice") {
        navigate(PagePath.accessDevice.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [
    { value: "accessGroup", label: "출입그룹 관리" },
    { value: "accessGroupForm", label: "그룹 등록" },
  ];

  const id: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  const [accessGroup, setAccessGroup] = useState<AccessGroupModel | undefined>();
  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false);
  const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);
  const [submitData, setSubmitData] = useState<AccessGroupFormParams>();
  const [selectedDevices, setSelectedDevices] = useState<AccessDeviceModel[]>([]);
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const { executeAsync: getAccessGroup } = useApiOperation(getAccessGroupAsync);
  const { executeAsync: getPartners } = useApiOperation(getPartnerList);
  const { executeAsync: postAccessGroup } = useApiOperation(postAccessGroupAsync);
  const { executeAsync: putAccessGroup } = useApiOperation(putAccessGroupAsync);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });
  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 작성취소 모달
  const [uncreateModal, setUncreateModal] = useState<Modal>({
    isOpen: false,
  });

  // 저장할 form data

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    watch,
    formState: { errors },
  } = useForm<SubmitData>({
    defaultValues: {
      accessGroupName: "",
      visitorAccessYn: false,
    },
  });

  useEffect(() => {
    // accessgroup 상세 호출
    if (id) {
      const fetchApi = async (id: number) => {
        const { data: accessGroup } = await getAccessGroup({ id });
        const accessGroupData = accessGroup?.data?.accessGroup;
        setAccessGroup(accessGroupData);
        if (accessGroupData.devices) {
          setSelectedDevices(accessGroupData.devices);
        }
      };
      fetchApi(id);
    }
  }, [setLoadingBar, getAccessGroup]);

  //수정시 api 에서 받아온 device 정보로 setValue 처리
  useEffect(() => {
    if (accessGroup) {
      Object.entries(accessGroup).forEach(([name, value]: any) => {
        setValue(name, value);
      });
    }
  }, [accessGroup, setValue]);

  // 파트너 선택
  const onAddSelectPartners = (partners: Partner[]) => {
    setSelectedPartners(partners);
    setIsPartnerModalOpen(false);
  };
  // 파트너 제거
  const handleDeletePartner = (id: string) => {
    const filteredPartners = selectedPartners.filter((partner) => partner.id !== id);
    setSelectedPartners(filteredPartners);
  };
  // 장치 선택
  const onAddSelectDevices = (devices: AccessDeviceModel[]) => {
    setSelectedDevices(devices);
    setIsDeviceModalOpen(false);
  };
  // 장치 제거
  const handleDeleteDevice = (id: string) => {
    const filteredDevices = selectedDevices.filter((device) => device.id !== Number(id));
    setSelectedDevices(filteredDevices);
  };
  useEffect(() => {
    const fetchApi = async (partnersNumber: string) => {
      const data = await getPartners({ id: partnersNumber });
      const partnersResponseData = data?.data?.data?.content;
      setSelectedPartners(partnersResponseData);
    };
    if (accessGroup?.partnerIds && accessGroup?.partnerIds.length > 0) {
      fetchApi(accessGroup?.partnerIds.join());
    }
  }, [getPartners, accessGroup?.partnerIds]);

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    const requiredMessage = "필수입력 항목입니다";
    register("accessGroupName", {
      required: requiredMessage,
    });
  }, [register]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: SubmitData, e?: any) => {
    e.preventDefault();

    const addDevicesIds = selectedDevices.map((device) => Number(device.id)!);
    const addPartnersIds = selectedPartners.map((partner) => Number(partner.id)!);

    const newAddPartnerIds = addPartnersIds.filter(
      (ids) => !accessGroup?.partnerIds?.includes(ids),
    );
    const newAddDeviceIds = addDevicesIds.filter(
      (ids) => !accessGroup?.devices?.map((device) => device.id)?.includes(ids),
    );

    const deleteDevicesIds = accessGroup?.devices
      ?.filter((device) => !addDevicesIds.includes(device.id!))
      .map((device) => device.id);
    const deletePartnersIds = accessGroup?.partnerIds?.filter(
      (partner) => !addPartnersIds.includes(partner),
    );

    if (id) {
      const newSubmitData: AccessGroupFormParams = {
        id: id,
        accessGroupName: data.accessGroupName,
        visitorAccessYn: data.visitorAccessYn,
        deviceAddIds: newAddDeviceIds,
        partnerAddIds: newAddPartnerIds,
        deviceDeleteIds: deleteDevicesIds,
        partnerDeleteIds: deletePartnersIds,
      };
      setSubmitData(newSubmitData);
    } else {
      const newSubmitData: AccessGroupFormParams = {
        accessGroupName: data.accessGroupName,
        visitorAccessYn: data.visitorAccessYn,
        deviceAddIds: addDevicesIds,
        partnerAddIds: addPartnersIds,
      };
      setSubmitData(newSubmitData);
    }

    setConfirmModal({ message: "저장하시겠습니까?", isOpen: true });
  };

  // Modal 확인 버튼 클릭 (submit)
  const clickModalConfirm = async () => {
    setConfirmModal({ isOpen: false });
    setLoadingBar(true);

    if (!submitData) {
      throw Error("저장할 data 없음");
    }

    let result;
    if (id) {
      // 수정
      const updateResult = await putAccessGroup(submitData);
      result = updateResult;
    } else {
      // 등록
      const createResult = await postAccessGroup(submitData);
      result = createResult;
    }
    setLoadingBar(false);

    if (result?.data?.data?.accessGroup) {
      setAlertModal({
        isOpen: true,
        message: "저장 되었습니다.",
        payload: { id: result?.data?.data?.accessGroup.id },
      });
    } else {
      // 에러발생시 아무것도 하지 않음.(api-operation modal)
    }
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    e.preventDefault();
    return false;
  };

  // 데이터를 하나라도 입력했는지?
  const isChangedData = () => {
    const defaultValues = {
      accessGroupName: "",
      visitorAccessYn: false,
    };

    const stringifyDefaultValues = JSON.stringify(defaultValues);
    const stringifyData = JSON.stringify(getValues());

    return stringifyDefaultValues === stringifyData ? false : true;
  };

  useTitleOperation(accessGroup?.accessGroupName);

  return (
    <div>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="contents-container__wrap-contents">
              <ContentsIdSection title="기본정보" id={id} />
              <article className="contents-container__1200">
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">출입그룹 이름</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax240">
                      <Controller
                        control={control}
                        name="accessGroupName"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <BaseInput
                            placeholder=""
                            className="mr8"
                            type="text"
                            onChange={onChange}
                            value={value}
                            name={name}
                            errorText={error?.message}
                          />
                        )}
                      />
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">방문자 허용 여부</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax240">
                      <Controller
                        control={control}
                        name="visitorAccessYn"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <BaseToggle
                            checked={value}
                            onChange={(value: boolean) => {
                              onChange(value);
                            }}
                            name={name}
                          />
                        )}
                      />
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>파트너</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <BaseButton title={"파트너 추가"} onClick={() => setIsPartnerModalOpen(true)} />
                    <PartnersTable
                      type="FORM"
                      partners={selectedPartners || []}
                      handleDeletePartner={handleDeletePartner}
                    />
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>장치</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <BaseButton title={"장치 추가"} onClick={() => setIsDeviceModalOpen(true)} />
                    <DevicesTable
                      type="FORM"
                      devices={selectedDevices || []}
                      handleDeleteDevice={handleDeleteDevice}
                    />
                  </div>
                </section>
              </article>
            </div>
            {/* 버튼영역 */}
            <div className="contents-container__btn-wrap">
              <div className="left-area">
                <BaseButton
                  title="목록으로"
                  className="color-white size-large"
                  onClick={() => {
                    if (!id && isChangedData()) {
                      setUncreateModal({
                        message: "작성중인 내용이 있습니다. 계속 할까요?",
                        isOpen: true,
                      });
                    } else {
                      if (id) {
                        // 수정화면에서는 목록으로 바로이동
                        navigate(PagePath.accessGroup.list);
                      } else {
                        // 등록화면에서는 목록에서 진입했기 때문에 뒤로가기
                        navigate(-1);
                      }
                    }
                  }}
                />
              </div>
              <div className="right-area">
                <BaseButton type="submit" title="저장" className=" size-large" />
              </div>
            </div>
          </form>
          {/* 파트너 선택 모달 */}
          {isPartnerModalOpen && (
            <PartnerSelectModal
              multiSelect
              onCanceled={() => {
                setIsPartnerModalOpen(false);
              }}
              onAdded={onAddSelectPartners}
              defaultValues={selectedPartners || []}
            />
          )}
          {/* 장치 선택 모달 */}
          {isDeviceModalOpen && (
            <DeviceSelectModal
              multiSelect
              onCanceled={() => {
                setIsDeviceModalOpen(false);
              }}
              onAdded={onAddSelectDevices}
              defaultValues={selectedDevices || []}
            />
          )}
          {/* 취소, 확인 버튼이 있는 confirm 모달 */}
          <BaseModal
            isOpen={confirmModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => clickModalConfirm()}
          >
            <p>{confirmModal.message}</p>
          </BaseModal>

          {/* 저장완료시 알럿 - 상세로 이동 */}
          <BaseModal
            isOpen={alertModal.isOpen}
            btnRightTitle="확인"
            onClick={() => {
              const detailPath = `${PagePath.accessGroup.detail.replace(
                ":id",
                `${alertModal.payload.id}`,
              )}`;
              setAlertModal({ isOpen: false });
              openToast(`정상적으로 ${id ? "수정" : "등록"} 되었습니다.`);
              navigate(detailPath);
            }}
          >
            <p>{alertModal.message}</p>
          </BaseModal>

          {/* 저장된것이 있을때 확인하는 모달 */}
          <BaseModal
            isOpen={uncreateModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={() => {
              if (id) {
                // 수정화면에서는 목록으로 바로이동
                navigate(PagePath.accessGroup.list);
              } else {
                // 등록화면에서는 목록에서 진입했기 때문에 뒤로가기
                navigate(-1);
              }
            }}
            onClose={() => setUncreateModal({ isOpen: false })}
          >
            <p>{uncreateModal.message}</p>
          </BaseModal>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
