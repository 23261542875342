import { addMonths } from "date-fns";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { getOrgDetail } from "src/api/organization/org-api";
import { MbOrgDetail, MbOrgMedia } from "src/api/organization/org-types";
import { contract_bill_user_errors, Select } from "src/api/public-types";
import {
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
  BaseInput,
  BaseRadio,
  BaseSelect,
} from "src/components";
import { BaseInputNumberFormat } from "src/components/BaseInputNumberFormat";
import pagePath from "src/pagePath.json";
import SearchContractMemberPopup from "src/pages/commonPopup/SearchContractMemberPopup";
import SearchOrgPopup from "src/pages/commonPopup/SearchOrgPopup";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { numberToStringWithComma, YmdFormat } from "src/utils";
import TextNFileDownload from "../modal/components/TextNFileDownload";
import ModifyAmountModal from "../modal/ModifyAmountModal";
import SnapShotModal from "../modal/SnapShotModal";
import FileUpload from "./FileUpload";
interface ContractMedia {
  id?: number;
  serviceId: number;
  serviceType: string;
  serviceTag: string;
  contentType: string;
  filename: string;
  fileSize: number;
  fileStorageType: string;
  key: string;
  isPrimary: boolean;
  orderNums: number;
  isDeleted: boolean;
}
interface BillContactEdit {
  billContactId?: number;
  contractId?: number;
  receiveNotiType?: string;
  contact?: string;
  email?: string;
  description?: string;
  isDeleted?: boolean;

  errorTextContact?: string;
  errorTextEmail?: string;
}
const ServiceType = ({
  control,
  getValues,
  setValue,
  watch,
  setError,
  register,
  unregister,
  selectedPeriodOption,
  spaceProductType,
  organization,
  contractBasic,
  contractDetail,
  billMessageList,
  bi,
  proof,
  document,
  require,
  talkOfficialdoc,
  taxInVoice,
  member,
  viewContractMember,
  setChangeContractMember,
  setViewContractMember,
  setOrganization,
  setProof,
  setBi,
  setTaxInVoice,
  setTalkOfficialdoc,
  setBillMessageList,
  setRequire,
  setDocument,
}: any) => {
  const [textGrey] = useState("contents-container__grid ");
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();

  const [isSnapShotOpen, setIsSnapShotOpen] = useState(false);
  const [isModifyAmountOpen, setIsModifyAmountOpen] = useState(false);

  const [isChangeContractor, setIsChangeContractor] = useState(false);
  const [isOrgPop, setIsOrgPop] = useState(false);

  const [payStatusOption] = useState([
    { label: "결제대기", value: "PAY_WAIT" },
    { label: "결제완료", value: "PAY_COMPLETE" },
  ]);
  // const [payTypeOption, setPayStypeOption] = useState([
  //   { label: "신용카드", value: "creaditcard" },
  // ]);

  const { executeAsync: getOrganization } = useApiOperation(getOrgDetail);

  const goMemberDetail = (id: number) => {
    let path = "";
    if (id) {
      path = pagePath.member.detail.replace(":id", id!.toString());
    }
    return path;
  };
  const onChangeContractor = useCallback(
    async (member) => {
      if (member) {
        setLoadingBar(true);
        setIsChangeContractor(false);
        // console.log("member", member);
        setChangeContractMember(member);
        setViewContractMember(member);
        setLoadingBar(false);
      }
    },
    [setChangeContractMember, setLoadingBar, setViewContractMember],
  );

  const onChangeOrganization = useCallback(
    async (org) => {
      if (org) {
        setLoadingBar(true);
        setIsOrgPop(false);
        const response: any = await getOrganization({ id: Number(org.id) });
        if (response.status >= 200 && response.status <= 299) {
          const mbOrgDetail: MbOrgDetail = response.data.data;
          // console.log(mbOrgDetail, "mbOrgDetail");
          setOrganization(mbOrgDetail.organization);
          const proofs = mbOrgDetail?.mediaList!.filter(
            (media: MbOrgMedia) => media.type === "PROOF",
          );
          const bi = mbOrgDetail?.mediaList!.filter((media: MbOrgMedia) => media.type !== "PROOF");
          setProof(proofs);
          setBi(bi);
          setValue("contractManage.mbOrganizationId", org.id);
        } else {
          let message = errorMessageHandler(
            response.status,
            response.data.meta.errorCode,
            contract_bill_user_errors,
          );
          let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
          return openErrorModal(message, code);
        }
        setLoadingBar(false);
      }
    },
    [getOrganization, openErrorModal, setLoadingBar, setValue],
  );

  const moveOrg = useCallback((id: number) => {
    let rtn = "";
    if (id) {
      rtn = pagePath.organization.detail.replace(":id", String(id));
    }
    return rtn;
  }, []);

  useEffect(() => {}, []);

  // console.log("contract", contract);

  return (
    <>
      <article className="pb20">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>{"이용기간"}</h2>
            <p className="contents-container__sub-title-info">
              신청/계약자에게 계약일시 알림톡을 발신하시면, 상태값을 ‘계약 예정'으로 변경 후 [저장]
              버튼을 클릭해 주세요 (최초 1회)
            </p>
          </div>

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>신청일</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax160">
                  <Controller
                    control={control}
                    name="contract.contractStartTime"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <BaseDatePicker
                          name={name}
                          type="date"
                          readonly={true}
                          selectedDate={value ? moment(value).toDate() : null}
                          setDate={(date: string) => {
                            onChange(date);
                            if (getValues("startTime") !== "") {
                              const time = moment(getValues("startTime")).format("HH:mm");
                              const full = moment(date).format(YmdFormat.YYYY_MM_DD) + " " + time;
                              setValue(
                                "contract.contractStartTime",
                                moment(full).format(YmdFormat.FULL),
                              );
                            } else {
                              setValue(
                                "contract.contractStartTime",
                                moment(date).format(YmdFormat.YYYY_MM_DD),
                              );
                            }
                            if (getValues("endTime") !== "") {
                              const time = moment(getValues("endTime")).format("HH:mm");
                              const full = moment(date).format(YmdFormat.YYYY_MM_DD) + " " + time;
                              setValue(
                                "contract.contractEndTime",
                                moment(full).format(YmdFormat.FULL),
                              );
                            } else {
                              setValue(
                                "contract.contractEndTime",
                                moment(date).format(YmdFormat.YYYY_MM_DD),
                              );
                            }
                          }}
                          errorText={error && error.message}
                        />
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
            <section className={"contents-container__grid"}>
              <div className="contents-container__grid-index">
                <p>신청 시간</p>
              </div>

              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <div className="minmax120 mr8">
                    <Controller
                      control={control}
                      name="startTime"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseDatePicker
                            name={name}
                            type="time"
                            readonly={true}
                            selectedDate={value ? moment(value).toDate() : null}
                            setDate={(time: string) => {
                              onChange(time);
                              if (getValues("contract.contractStartTime") !== "") {
                                const st =
                                  moment(getValues("contract.contractStartTime")).format(
                                    YmdFormat.YYYY_MM_DD,
                                  ) +
                                  " " +
                                  moment(time).format("HH:mm");
                                console.log("st", st);

                                setValue(
                                  "contract.contractStartTime",
                                  moment(st, YmdFormat.YYYY_MM_DD_HH_MM).format(YmdFormat.FULL),
                                );
                              }
                            }}
                            errorText={error && error.message}
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                  <div className="minmax120 mr8">
                    <Controller
                      control={control}
                      name="endTime"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseDatePicker
                            name={name}
                            type="time"
                            readonly={true}
                            selectedDate={value ? moment(value).toDate() : null}
                            setDate={(time: string) => {
                              onChange(time);
                              if (getValues("contract.contractEndTime") !== "") {
                                const ed =
                                  moment(getValues("contract.contractEndTime")).format(
                                    YmdFormat.YYYY_MM_DD,
                                  ) +
                                  " " +
                                  moment(time).format("HH:mm");
                                console.log("ed", ed);

                                setValue(
                                  "contract.contractEndTime",
                                  moment(ed, YmdFormat.YYYY_MM_DD_HH_MM).format(YmdFormat.FULL),
                                );
                              }
                            }}
                            errorText={error && error.message}
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </article>

      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>빌링 기간</h2>
          </div>

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>빌링 시작</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax160">
                  <Controller
                    control={control}
                    name="schedule.scheduleStartTime"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <BaseDatePicker
                          readonly={
                            contractBasic?.contractStep === "APPLY_CANCEL" ||
                            contractBasic?.contractStep === "CONTRACT_ACCEPT" ||
                            contractBasic?.contractStep === "USE_APPROVAL" ||
                            contractBasic?.contractStep === "USE_PROGRESS" ||
                            contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                            contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                            contractBasic?.contractStep === "USE_COMPLETE"
                          }
                          name={name}
                          type="date"
                          selectedDate={value ? moment(value).toDate() : null}
                          setDate={(value: string) => {
                            onChange(value);
                            let day = Number(moment(value).format("DD"));
                            if (27 < Number(day)) {
                              day = 28;
                            }

                            setValue("schedule.payRegularDay", day);

                            setValue("schedule.taxInvoiceIssueDay", day);
                          }}
                          errorText={error && error.message}
                          minDate={moment(watch("contract.contractStartTime")).toDate()}
                          maxDate={moment(watch("contract.contractEndTime")).toDate()}
                        />
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>빌링 종료</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax160">
                  <Controller
                    control={control}
                    name="schedule.scheduleEndTime"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <BaseDatePicker
                          readonly={
                            spaceProductType === "TIME_COURT" ||
                            contractBasic?.contractStep === "APPLY_CANCEL" ||
                            contractBasic?.contractStep === "CONTRACT_ACCEPT" ||
                            contractBasic?.contractStep === "USE_APPROVAL" ||
                            contractBasic?.contractStep === "USE_PROGRESS" ||
                            contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                            contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                            contractBasic?.contractStep === "USE_COMPLETE"
                          }
                          name={name}
                          type="date"
                          selectedDate={value ? moment(value).toDate() : null}
                          setDate={onChange}
                          errorText={error && error.message}
                          minDate={moment(watch("contract.contractStartTime")).toDate()}
                          maxDate={addMonths(moment(watch("contract.contractEndTime")).toDate(), 0)}
                        />
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>계약자 정보</h2>
            {/* 가이드메시지 수정에서만 노출 */}
            <p className="contents-container__sub-title-info">
              {
                "계약자 정보 변경 시 <공문/알림톡 수신> 및 <세금계산서 수신>의 연락처를 변경되는 계약자 연락처로 수정해 주세요."
              }
            </p>
          </div>
          <div className="flex-center">
            {/* 사업자 등록번호 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>회원 번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax140 mr8">
                    <Link
                      to={goMemberDetail(viewContractMember?.memberNo)}
                      className="text-hilight"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {viewContractMember?.memberNo}
                    </Link>
                  </div>

                  <div className="minmax90">
                    <BaseButton
                      disabled={
                        spaceProductType === "TIME_COURT" ||
                        contractBasic?.contractStep === "APPLY_RECEIVED" ||
                        contractBasic?.contractStep === "APPLY_CANCEL" ||
                        contractBasic?.contractStep === "USE_COMPLETE" ||
                        contractBasic?.contractStep === "TERMINATE_COMPLETE"
                      }
                      title="조회"
                      onClick={() => {
                        setIsChangeContractor(true);
                      }}
                      className="color-white"
                    />
                    <SearchContractMemberPopup
                      contractManageId={contractBasic?.contractManageId}
                      isOpen={isChangeContractor}
                      onClose={() => setIsChangeContractor(false)}
                      onClick={onChangeContractor}
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* 사업자 유형 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>휴대폰</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{viewContractMember?.inviteMobileNumber}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 사업자 등록번호 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>이메일</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{viewContractMember?.email}</p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>사업자 정보</h2>
            {/* 가이드메시지 수정에서만 노출 */}
            <p className="contents-container__sub-title-info">
              계약자 사업자등록증을 참조하시어 사업자 정보를 정확하게 입력해 주세요.
              <br />
              개인카드 결제인 경우, 사업자 정보는 선택입력 값입니다.
            </p>
          </div>
          <div className="flex-center">
            {/* 사업자 등록번호 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>사업자 등록번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax140 mr8">
                    {organization ? (
                      <Link
                        className="text-hilight"
                        to={moveOrg(organization?.id!)}
                        target="_blank"
                      >
                        {organization?.bizRegNo ? organization?.bizRegNo : "-"}
                      </Link>
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                  <div className="minmax90">
                    <BaseButton
                      disabled={
                        spaceProductType === "TIME_COURT" ||
                        contractBasic?.contractStep === "APPLY_RECEIVED" ||
                        contractBasic?.contractStep === "APPLY_CANCEL" ||
                        contractBasic?.contractStep === "USE_COMPLETE" ||
                        contractBasic?.contractStep === "TERMINATE_COMPLETE"
                      }
                      title="조회"
                      onClick={() => {
                        setIsOrgPop(true);
                      }}
                      className="color-white"
                    />
                    <Controller
                      control={control}
                      name="contractManage.mbOrganizationId"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <div className="minmax180 mr8">
                              <BaseInput
                                readonly={
                                  contractBasic?.contractStep === "APPLY_RECEIVED" ||
                                  contractBasic?.contractStep === "APPLY_CANCEL" ||
                                  contractBasic?.contractStep === "USE_COMPLETE" ||
                                  contractBasic?.contractStep === "TERMINATE_COMPLETE"
                                }
                                name={name}
                                type="hidden"
                                value={value}
                                errorText={error && error.message}
                              />
                            </div>
                          </>
                        );
                      }}
                    ></Controller>
                    <SearchOrgPopup
                      isOpen={isOrgPop}
                      onClose={() => setIsOrgPop(false)}
                      onClick={onChangeOrganization}
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* 사업자 유형 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>사업자 유형</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {organization?.bizType
                    ? organization?.bizType === "CORP"
                      ? "법인사업자"
                      : "일반사업자"
                    : "-"}
                </p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 사업자 등록증 첨부 */}
            <TextNFileDownload
              title={"사업자 등록증 첨부"}
              fileList={proof}
              classname={"minmax300"}
            />
            {/* 법인명/상호 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>법인명/상호</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.name ? organization?.name : "-"}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 사업장 주소 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>사업장 주소</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.address ? organization?.address : "-"}</p>
              </div>
            </section>
            {/* 대표자/성명 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>대표자/성명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.ceoName ? organization?.ceoName : "-"}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 과세 유형 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>과세 유형</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {organization &&
                  organization?.taxType! === "GENERAL" &&
                  organization?.bizType! === "SP"
                    ? "일반"
                    : "과세"}
                  {organization &&
                    organization?.taxType! === "SIMPLIFIED_A" &&
                    "간이(세금계산서 발급형)"}
                  {organization &&
                    organization?.taxType! === "SIMPLIFIED_B" &&
                    "간이(세금계산서 미발급형)"}
                  {organization && organization?.taxType! === "FREE" && "면세"}
                  {organization && organization?.taxType! === "ETC" && "기타"}
                  {!organization?.taxType && "-"}
                </p>
              </div>
            </section>
            {/* 업태 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>업태</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.bizCondition ? organization?.bizCondition : "-"}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            <TextNFileDownload
              title={"CI/BI"}
              fileList={bi}
              classname={"minmax300"}
              titleBrFlag={true}
              title2={"첨부(Taap 출력용)"}
            />
            {/* 종목 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>종목</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.bizItem ? organization?.bizItem : "-"}</p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>계약 정보</h2>
            {/* 가이드메시지 수정에서만 노출 */}
            <p className="contents-container__sub-title-info">
              계약 체결 후 계약서와 계약 필요 서류를 등록해 주세요.
            </p>
          </div>
          <div className="flex-center">
            {/* 계약 번호 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약 번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{contractDetail?.contractNumber ? contractDetail?.contractNumber : "-"}</p>
              </div>
            </section>
            {/* 재계약 상태 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>재계약 상태</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <p className="mr8">-</p>
                  <BaseButton
                    title="재계약생성"
                    disabled={true}
                    onClick={() => {}}
                    className="color-white"
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 계약 데이터 상세 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약 데이터 상세</p>
              </div>
              <div className="contents-container__grid-contents">
                {textGrey !== "" && (
                  <BaseButton
                    disabled={
                      spaceProductType === "TIME_COURT" ||
                      contractBasic?.contractStep === "APPLY_RECEIVED" ||
                      contractBasic?.contractStep === "APPLY_CANCEL" ||
                      contractBasic?.contractStep === "USE_APPROVAL" ||
                      contractBasic?.contractStep === "USE_PROGRESS" ||
                      contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                      contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                      contractBasic?.contractStep === "APPLY_CONFIRM" ||
                      contractBasic?.contractStep === "USE_COMPLETE"
                    }
                    title="스냅샷 보기"
                    className="color-white"
                    onClick={() => setIsSnapShotOpen(true)}
                  />
                )}
                <SnapShotModal
                  isOpen={isSnapShotOpen}
                  contractId={contractDetail?.contractId}
                  onClose={() => {
                    setIsSnapShotOpen(false);
                  }}
                />
              </div>
            </section>
            {/* 계약금 */}
            <section className="contents-container__grid not-available">
              <div className="contents-container__grid-index">
                <p>이전 계약 정보</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>-</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 계약금(원) */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약금(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="contract.earnestAmount"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <div className="minmax120 mr8">
                            <BaseInputNumberFormat
                              readonly={
                                spaceProductType === "TIME_COURT" ||
                                contractBasic?.contractStep === "APPLY_RECEIVED" ||
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "USE_APPROVAL" ||
                                contractBasic?.contractStep === "USE_PROGRESS" ||
                                contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE"
                              }
                              name={name}
                              type="text"
                              value={numberToStringWithComma(
                                Number(_.replace(value, new RegExp(",", "g"), "")),
                              )}
                              onChange={(text: string) => {
                                onChange(text);
                              }}
                              errorText={error?.message}
                            />
                          </div>
                        </>
                      );
                    }}
                  ></Controller>
                  <Controller
                    control={control}
                    name="contract.earnestPayStatus"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <div className="minmax120">
                            <BaseSelect
                              isDisabled={
                                contractBasic?.contractStep === "APPLY_RECEIVED" ||
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "USE_APPROVAL" ||
                                contractBasic?.contractStep === "USE_PROGRESS" ||
                                contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE"
                              }
                              value={value}
                              stateOptions={payStatusOption}
                              setStateValue={onChange}
                            />
                          </div>
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
            {/* 잔금 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>잔금(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="contract.balanceAmount"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <div className="minmax120 mr8">
                            <BaseInputNumberFormat
                              readonly={
                                spaceProductType === "TIME_COURT" ||
                                contractBasic?.contractStep === "APPLY_RECEIVED" ||
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "USE_APPROVAL" ||
                                contractBasic?.contractStep === "USE_PROGRESS" ||
                                contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE"
                              }
                              name={name}
                              type="text"
                              value={numberToStringWithComma(
                                Number(_.replace(value, new RegExp(",", "g"), "")),
                              )}
                              onChange={(text: string) => {
                                onChange(text);
                              }}
                              errorText={error?.message}
                            />
                          </div>
                        </>
                      );
                    }}
                  ></Controller>
                  <Controller
                    control={control}
                    name="contract.balancePayStatus"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <div className="minmax120">
                            <BaseSelect
                              isDisabled={
                                spaceProductType === "TIME_COURT" ||
                                contractBasic?.contractStep === "APPLY_RECEIVED" ||
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "USE_APPROVAL" ||
                                contractBasic?.contractStep === "USE_PROGRESS" ||
                                contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE"
                              }
                              value={value}
                              stateOptions={payStatusOption}
                              setStateValue={onChange}
                            />
                          </div>
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
          </div>
          <div className="flex-start">
            {/* 계약서 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약서</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <Controller
                    control={control}
                    name="documents"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <FileUpload
                        title={"파일찾기"}
                        name={name}
                        setPrivateFiles={(file: ContractMedia[]) => {
                          setDocument(file);
                          onChange(file);
                        }}
                        privateFiles={document}
                        errorText={error?.message}
                        limit={10}
                        maxSize={10}
                        accept={".png, .jpg, .pdf"}
                        disabled={
                          spaceProductType === "TIME_COURT" ||
                          contractBasic?.contractStep === "APPLY_CANCEL" ||
                          contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                          contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                          contractBasic?.contractStep === "USE_COMPLETE"
                        }
                      />
                    )}
                  ></Controller>
                </div>
                <p className="font12 text-primary5">
                  * 문서를 카메라로 찍거나 스캔하여 업로드해주세요.
                  <br />
                  * 업로드 가능 이미지 파일타입은 PNG, JPG, PDF입니다.
                  <br />* 업로드 용량은 개당 10MB, 최대 10개까지 가능합니다.
                </p>
              </div>
            </section>
            {/* 계약 필요 서류 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약 필요 서류</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <Controller
                    control={control}
                    name="requires"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <FileUpload
                        disabled={
                          spaceProductType === "TIME_COURT" ||
                          contractBasic?.contractStep === "APPLY_CANCEL" ||
                          contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                          contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                          contractBasic?.contractStep === "USE_COMPLETE"
                        }
                        name={name}
                        title={"파일찾기"}
                        setPrivateFiles={(file: ContractMedia[]) => {
                          setRequire(file);
                          onChange(file);
                        }}
                        privateFiles={require}
                        errorText={error?.message}
                        limit={10}
                        maxSize={10}
                        accept={".png, .jpg, .pdf"}
                      />
                    )}
                  ></Controller>
                </div>
                <p className="font12 text-primary5">
                  * 문서를 카메라로 찍거나 스캔하여 업로드해주세요.
                  <br />
                  * 업로드 가능 이미지 파일타입은 PNG, JPG, PDF입니다.
                  <br />* 업로드 용량은 개당 10MB, 최대 10개까지 가능합니다.
                </p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>결제 정보</h2>
            {/* 가이드메시지 수정에서만 노출 */}
            <p className="contents-container__sub-title-info">
              결제금액 : 계약 시 협의 된 월 이용료(부가세 제외)를 입력해 주세요. <br />
              연체수수료: 기본 연체요율은 18% 입니다. 변경 필요 시 계약체결 전에 연체요율을 변경해
              주세요. <br />
              결제 타입 : 변경 시 변경일 익일부터 빌링스케줄에 적용됩니다.
            </p>
          </div>
          <div className="flex-center">
            {/* 빌링스케줄id */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>빌링스케줄id</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  {contractDetail?.contractId ? (
                    <Link
                      to={pagePath.billing.detail.replace(":id", contractDetail?.contractId)}
                      target="_blank"
                      className="text-hilight"
                    >
                      {contractDetail?.contractId}
                    </Link>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </section>
            {/* 결제 타입 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>자동 결제/수납</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="schedule.isAutoBilling"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <BaseRadio
                            disabled={
                              spaceProductType === "TIME_COURT" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            id={"50"}
                            name={name}
                            label="적용"
                            value="true"
                            className="mr16"
                            checked={String(value) === "true" ? true : false}
                            onChange={(e) => {
                              onChange(e);
                              setValue("schedule.isAutoBilling", true);
                              setValue("schedule.isAutoSend", true);
                            }}
                          />
                          <BaseRadio
                            disabled={
                              spaceProductType === "TIME_COURT" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            id={"60"}
                            name={name}
                            label="적용안함"
                            value="false"
                            checked={String(value) === "false" ? true : false}
                            onChange={(e) => {
                              onChange(e);
                              setValue("schedule.isAutoBilling", false);
                              setValue("schedule.isTaxInvoiceIssue", false);
                              setValue("schedule.isAutoSend", false);
                            }}
                          />
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>정기결제일</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax100">
                    <Controller
                      control={control}
                      name="schedule.payRegularDay"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <BaseSelect
                              isDisabled={
                                spaceProductType === "TIME_COURT" ||
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE" ||
                                watch("schedule.isAutoBilling") === false
                              }
                              name={name}
                              placeholder={""}
                              value={value}
                              stateOptions={[
                                { label: "1일", value: 1 },
                                { label: "2일", value: 2 },
                                { label: "3일", value: 3 },
                                { label: "4일", value: 4 },
                                { label: "5일", value: 5 },
                                { label: "6일", value: 6 },
                                { label: "7일", value: 7 },
                                { label: "8일", value: 8 },
                                { label: "9일", value: 9 },
                                { label: "10일", value: 10 },
                                { label: "11일", value: 11 },
                                { label: "12일", value: 12 },
                                { label: "13일", value: 13 },
                                { label: "14일", value: 14 },
                                { label: "15일", value: 15 },
                                { label: "16일", value: 16 },
                                { label: "17일", value: 17 },
                                { label: "18일", value: 18 },
                                { label: "19일", value: 19 },
                                { label: "20일", value: 20 },
                                { label: "21일", value: 21 },
                                { label: "22일", value: 22 },
                                { label: "23일", value: 23 },
                                { label: "24일", value: 24 },
                                { label: "25일", value: 25 },
                                { label: "26일", value: 26 },
                                { label: "27일", value: 27 },
                                { label: "28일", value: 28 },
                              ]}
                              setStateValue={(value: number) => {
                                setValue("schedule.payRegularDay", value);
                              }}
                            />
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                  <div className="ml10 minmax140">
                    <Controller
                      control={control}
                      name="schedule.payRegularMonth"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <BaseSelect
                              isDisabled={
                                spaceProductType === "TIME_COURT" ||
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE" ||
                                watch("schedule.isAutoBilling") === false
                              }
                              name={name}
                              placeholder={""}
                              value={value}
                              stateOptions={[
                                { label: "전월 기준", value: "PREV_MONTH" },
                                { label: "당월 기준", value: "CURR_MONTH" },
                                { label: "익월 기준", value: "NEXT_MONTH" },
                              ]}
                              setStateValue={(value: string) => {
                                setValue("schedule.payRegularMonth", value);
                              }}
                            />
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </div>
            </section>

            {/* 결제금액(원) */}

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>결제금액(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <div className="minmax140 mr8">
                    <Controller
                      control={control}
                      name="contract.payAmount"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <BaseInputNumberFormat
                              readonly={
                                spaceProductType === "TIME_COURT" ||
                                // contractBasic?.contractStep === "APPLY_RECEIVED" ||
                                // contractBasic?.contractStep === "APPLY_CANCEL" ||
                                // contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                // contractBasic?.contractStep === "CONTRACT_ACCEPT" ||
                                contractBasic?.contractStep === "CONTRACT_CONFIRM" ||
                                contractBasic?.contractStep === "USE_APPROVAL" ||
                                contractBasic?.contractStep === "USE_PROGRESS" ||
                                contractBasic?.contractStep === "USE_COMPLETE" ||
                                contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                watch("schedule.isAutoBilling") === false
                              }
                              name={name}
                              type="text"
                              value={numberToStringWithComma(
                                Number(_.replace(value, new RegExp(",", "g"), "")),
                              )}
                              onChange={onChange}
                            />
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                  <BaseButton
                    disabled={
                      spaceProductType === "TIME_COURT" ||
                      contractBasic?.contractStep === "APPLY_RECEIVED" ||
                      contractBasic?.contractStep === "APPLY_CANCEL" ||
                      contractBasic?.contractStep === "APPLY_CONFIRM" ||
                      contractBasic?.contractStep === "CONTRACT_PLAN" ||
                      watch("schedule.isAutoBilling") === false
                    }
                    title="회차별 금액 수정"
                    className="color-white"
                    onClick={() => setIsModifyAmountOpen(true)}
                  />
                  <ModifyAmountModal
                    isOpen={isModifyAmountOpen}
                    onClose={() => {
                      setIsModifyAmountOpen(false);
                    }}
                    onClick={() => {
                      setIsModifyAmountOpen(false);
                    }}
                    scheduleId={contractDetail?.schedule?.scheduleId}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 결제 타입 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>결제 타입</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="schedule.contractPayType"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <BaseRadio
                            disabled={
                              spaceProductType === "TIME_COURT" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            id={"20"}
                            name={name}
                            label="일반결제"
                            value="PAY"
                            className="mr16"
                            checked={value === "PAY" ? true : false}
                            onChange={(e) => {
                              console.log("e", e);
                              onChange(e);
                              setValue("schedule.contractPayType", "PAY");
                              setValue("schedule.payRegularDay", contractDetail?.payRegularDay);
                              setValue("schedule.isTaxInvoiceIssue", false);
                              const dump = _.cloneDeep(taxInVoice);
                              if (dump.length > 0) {
                                let sendBeDelete = [];
                                for (let i = 0; i < dump.length; i++) {
                                  if (dump[i].billContactId !== undefined) {
                                    if (dump[i].billContactId !== 0) {
                                      dump[i].isDeleted = true;
                                      sendBeDelete.push(dump[i]);
                                    }
                                  }
                                }

                                setTaxInVoice(sendBeDelete);
                              }
                              setValue("contract.pgcode", "creditcard");
                              setError("schedule.taxInvoiceIssueDay", {
                                type: "custom",
                                message: "",
                              });
                              setError("contract.pgcode", {
                                type: "custom",
                                message: "",
                              });
                              unregister("schedule.taxBillReceiveEmail");
                            }}
                          />
                          <BaseRadio
                            disabled={
                              spaceProductType === "TIME_COURT" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            id={"30"}
                            name={name}
                            label="세금계산서 발행"
                            value="TAX"
                            checked={value === "PAY" ? false : true}
                            onChange={(e) => {
                              onChange(e);
                              setValue("schedule.contractPayType", "TAX");
                              setValue("schedule.isAutoSend", true);
                              setValue(
                                "schedule.taxInvoiceIssueDay",
                                getValues("schedule.payRegularDay"),
                              );
                              const firstLine = {
                                billContactId: 0,
                                contractId: contractDetail.contractId,
                                receiveNotiType: "TAX_INVOICE",
                                contact: "",
                                email: member.email,
                                description: "",
                                isDeleted: false,
                                errorTextContact: "",
                                errorTextEmail: "",
                              };
                              setTaxInVoice([firstLine]);

                              setValue("contract.pgcode", "banktransfer");
                              setError("schedule.payRegularDay", {
                                type: "custom",
                                message: "",
                              });
                              setError("contract.pgcode", {
                                type: "custom",
                                message: "",
                              });
                              register("schedule.taxBillReceiveEmail");
                            }}
                          />
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>

            {/* 공문 자동발송 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>세금계산서 자동 발행</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="schedule.isTaxInvoiceIssue"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <BaseRadio
                            id={"12"}
                            disabled={
                              spaceProductType === "TIME_COURT" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE" ||
                              watch("schedule.contractPayType") === "PAY"
                            }
                            name={name}
                            label="발행"
                            value="true"
                            className="mr16"
                            checked={Boolean(value) === true ? true : false}
                            onChange={() => {
                              onChange(true);
                            }}
                          />
                          <BaseRadio
                            disabled={
                              spaceProductType === "TIME_COURT" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE" ||
                              watch("schedule.contractPayType") === "PAY"
                            }
                            id={"22"}
                            name={name}
                            label="발행 안함"
                            value="false"
                            checked={Boolean(value) === false ? true : false}
                            onChange={() => {
                              onChange(false);
                              if (getValues("schedule.contractPayType") === "PAY") {
                                unregister("schedule.taxBillReceiveEmail");
                              } else {
                                register("schedule.taxBillReceiveEmail");
                                setError("schedule.taxBillReceiveEmail", {
                                  type: "custom",
                                  message: "",
                                });
                              }
                            }}
                          />
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>결제 방법</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax140">
                  {watch("schedule.contractPayType") === "PAY" ? (
                    <p>{getValues("contract.pgcode") === "creditcard" ? "신용카드" : "-"}</p>
                  ) : (
                    <Controller
                      control={control}
                      name="contract.pgcode"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseSelect
                            isDisabled={
                              spaceProductType === "TIME_COURT" ||
                              contractBasic?.contractStep === "APPLY_RECEIVED" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              watch("schedule.isAutoBilling") === false
                            }
                            name={name}
                            placeholder="선택"
                            value={String(value)}
                            stateOptions={[
                              { label: "계좌이체", value: "banktransfer", isDisabled: false },
                              { label: "CMS", value: "cms", isDisabled: true },
                              {
                                label: "가상걔좌",
                                value: "virtualaccount",
                                isDisabled: true,
                              },
                            ]}
                            setStateValue={(value: string) => {
                              if (spaceProductType !== "TIME_COURT") {
                                setValue("contract.pgcode", value);
                              }
                              onChange(value);
                            }}
                            errorText={error && error.message}
                          />
                        );
                      }}
                    ></Controller>
                  )}
                </div>
              </div>
            </section>
            {/* 정기결제일 */}

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>세금계산서 발행일</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax100">
                    <Controller
                      control={control}
                      name="schedule.taxInvoiceIssueDay"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <BaseSelect
                              isDisabled={
                                spaceProductType === "TIME_COURT" ||
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE" ||
                                watch("schedule.contractPayType") === "PAY" ||
                                watch("schedule.isAutoBilling") === false
                              }
                              name={name}
                              placeholder={""}
                              value={value}
                              stateOptions={[
                                { label: "1일", value: 1 },
                                { label: "2일", value: 2 },
                                { label: "3일", value: 3 },
                                { label: "4일", value: 4 },
                                { label: "5일", value: 5 },
                                { label: "6일", value: 6 },
                                { label: "7일", value: 7 },
                                { label: "8일", value: 8 },
                                { label: "9일", value: 9 },
                                { label: "10일", value: 10 },
                                { label: "11일", value: 11 },
                                { label: "12일", value: 12 },
                                { label: "13일", value: 13 },
                                { label: "14일", value: 14 },
                                { label: "15일", value: 15 },
                                { label: "16일", value: 16 },
                                { label: "17일", value: 17 },
                                { label: "18일", value: 18 },
                                { label: "19일", value: 19 },
                                { label: "20일", value: 20 },
                                { label: "21일", value: 21 },
                                { label: "22일", value: 22 },
                                { label: "23일", value: 23 },
                                { label: "24일", value: 24 },
                                { label: "25일", value: 25 },
                                { label: "26일", value: 26 },
                                { label: "27일", value: 27 },
                                { label: "28일", value: 28 },
                              ]}
                              setStateValue={(value: number) => {
                                setValue("schedule.taxInvoiceIssueDay", value);
                              }}
                              errorText={error?.message}
                            />
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                  <div className="ml10 minmax140">
                    <Controller
                      control={control}
                      name="schedule.taxInvoiceIssueMonth"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <BaseSelect
                              isDisabled={
                                spaceProductType === "TIME_COURT" ||
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE" ||
                                watch("schedule.isAutoBilling") === false
                              }
                              name={name}
                              placeholder={""}
                              value={value}
                              stateOptions={[
                                { label: "전월 기준", value: "PREV_MONTH" },
                                { label: "당월 기준", value: "CURR_MONTH" },
                                { label: "익월 기준", value: "NEXT_MONTH" },
                              ]}
                              setStateValue={(value: string) => {
                                setValue("schedule.taxInvoiceIssueMonth", value);
                              }}
                            />
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 연체 수수료(%) */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>연체 수수료(%)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="schedule.overdueFeePercent"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <div className="minmax140 mr8">
                            <BaseInput
                              readonly={
                                spaceProductType === "TIME_COURT" ||
                                contractBasic?.contractStep === "USE_APPROVAL" ||
                                contractBasic?.contractStep === "USE_PROGRESS" ||
                                contractBasic?.contractStep === "USE_COMPLETE" ||
                                contractBasic?.contractStep === "TERMINATE_RECEIVED" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                watch("schedule.isAutoBilling") === false
                              }
                              type="number"
                              name={name}
                              value={String(value)}
                              onChange={onChange}
                            />
                          </div>
                          <div className="minmax140">%</div>
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
            {/* 결제 상태 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>결제 상태</p>
              </div>
              <div className="contents-container__grid-contents">
                <p className="mr16">
                  {contractDetail?.billPayStatus === "PAYMENT_READY" && "결제대기"}
                  {contractDetail?.billPayStatus === "PAYMENT_SUCCESS" && "성공"}
                  {contractDetail?.billPayStatus === "PAYMENT_FAIL" && "실패"}
                  {contractDetail?.billPayStatus === "PAYMENT_CANCEL" && "취소"}
                  {contractDetail?.billPayStatus === "CONTRACT_CANCEL" && "해지"}
                  {(contractDetail?.billPayStatus === undefined ||
                    contractDetail?.billPayStatus === "") &&
                    "-"}
                </p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 공문 자동발송 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>공문 자동발송</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <Controller
                    control={control}
                    name="schedule.isAutoSend"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <BaseRadio
                            id={"11"}
                            disabled={
                              spaceProductType === "TIME_COURT" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            name={name}
                            label="발송"
                            value="true"
                            className="mr16"
                            checked={String(value) === "true" ? true : false}
                            onChange={() => {
                              onChange(true);
                            }}
                          />
                          <BaseRadio
                            disabled={
                              spaceProductType === "TIME_COURT" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE"
                            }
                            id={"21"}
                            name={name}
                            label="미발송"
                            value="false"
                            checked={String(value) === "false" ? true : false}
                            onChange={() => {
                              onChange(false);
                              if (getValues("schedule.contractPayType") === "PAY") {
                                unregister("schedule.taxBillReceiveEmail");
                              } else {
                                register("schedule.taxBillReceiveEmail");
                                setError("schedule.taxBillReceiveEmail", {
                                  type: "custom",
                                  message: "",
                                });
                              }
                            }}
                          />
                        </>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>입금자명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax160">
                    <Controller
                      control={control}
                      name="schedule.accountInName"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <BaseInput
                              readonly={
                                spaceProductType === "TIME_COURT" ||
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE" ||
                                watch("schedule.contractPayType") === "PAY"
                              }
                              name={`schedule.accountInName`}
                              value={value}
                              onChange={onChange}
                              // errorText={billContact.errorTextEmail}
                            />
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 공문 자동발송 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>공문 종류</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  {billMessageList?.map((list: any, index: number) => {
                    let classN = "ml10";

                    let label = "";
                    switch (list.messageType) {
                      case "UNPAID_NOTICE":
                        label = "미납 안내";
                        break;
                      case "CONTRACT_ACCEPT_NOTICE":
                        label = "계약체결 안내";
                        break;
                      case "OVERDUE_NOTICE":
                        label = "연체료 안내";
                        break;
                      case "CHARGE_NOTICE":
                        label = "결제일 안내";
                        break;
                    }

                    return (
                      <div key={index}>
                        <BaseCheckbox
                          className={index > 0 ? classN : ""}
                          name={"billMessageList"}
                          checked={list.isSend}
                          id={"billMessageList" + index}
                          label={label}
                          onChange={(value: boolean) => {
                            const dump = _.cloneDeep(billMessageList);
                            const editedList = dump.map((message: any, idx: number) => {
                              if (idx === index) {
                                message.isSend = value;
                              }
                              return message;
                            });

                            setBillMessageList(dump);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>공문/알림톡 수신</h2>
          </div>

          {talkOfficialdoc.length > 0 &&
            talkOfficialdoc.map((billContact: BillContactEdit, index: number) => {
              if (billContact.isDeleted === false) {
                return (
                  <div className="flex-center" key={index}>
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p className={"required"}>공문/수신 이메일</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="minmax240">
                          <BaseInput
                            readonly={
                              spaceProductType === "TIME_COURT" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE" ||
                              watch("schedule.isAutoSend") === false
                            }
                            name={`billContact.email${index}`}
                            value={billContact.email}
                            onChange={(value: string) => {
                              const dump = _.cloneDeep(talkOfficialdoc);
                              const adds = dump.map((talk: any, idx: number) => {
                                if (idx === index) {
                                  talk.errorTextEmail = "";
                                  talk.email = value;
                                }
                                return talk;
                              });
                              setTalkOfficialdoc(adds);
                            }}
                            errorText={billContact.errorTextEmail}
                          />
                        </div>
                      </div>
                    </section>
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p className={"required"}>알림톡 수신 휴대폰</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="minmax240 flex-row flex-center-start">
                          <BaseInput
                            readonly={
                              spaceProductType === "TIME_COURT" ||
                              contractBasic?.contractStep === "APPLY_CANCEL" ||
                              contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                              contractBasic?.contractStep === "APPLY_CONFIRM" ||
                              contractBasic?.contractStep === "USE_COMPLETE" ||
                              watch("schedule.isAutoSend") === false
                            }
                            name={`billContact.contact${index}`}
                            value={billContact.contact}
                            onChange={(value: string) => {
                              const dump = _.cloneDeep(talkOfficialdoc);
                              const adds = dump.map((talk: any, idx: number) => {
                                if (idx === index) {
                                  talk.errorTextContact = "";
                                  talk.contact = value;
                                }
                                return talk;
                              });
                              setTalkOfficialdoc(adds);
                            }}
                            errorText={billContact.errorTextContact}
                          />
                          {index === 0 && (
                            <button
                              disabled={watch("schedule.isAutoSend") === false}
                              className="base-add-btn ml10"
                              onClick={(e: any) => {
                                e.preventDefault();
                                const dump = _.cloneDeep(talkOfficialdoc);
                                const filterd = dump.filter(
                                  (bill: any) => bill.isDeleted === false,
                                );

                                if (filterd.length < 5) {
                                  const anotherLine = {
                                    billContactId: 0,
                                    contractId: contractDetail?.contractId,
                                    receiveNotiType: "TALK_OFFICIALDOC",
                                    contact: "",
                                    email: "",
                                    description: "",
                                    isDeleted: false,
                                    errorText: "",
                                  };

                                  dump.push(anotherLine);
                                  setTalkOfficialdoc(dump);
                                }
                                return;
                              }}
                            />
                          )}
                          {index > 0 && (
                            <button
                              title="삭제"
                              disabled={watch("schedule.isAutoSend") === false}
                              onClick={(e: any) => {
                                e.preventDefault();
                                let dump = _.cloneDeep(talkOfficialdoc);
                                const finded = dump.find((bill: any, idx: number) => {
                                  if (idx === index && Number(bill.billContactId) === 0) {
                                    return bill;
                                  }
                                });

                                if (finded) {
                                  dump = dump.filter((bill: any, idx: number) => idx !== index);
                                } else {
                                  dump.forEach((bill: any, idx: number) => {
                                    if (idx === index && Number(bill.billContactId) !== 0) {
                                      bill.isDeleted = true;
                                    }
                                  });
                                }

                                setTalkOfficialdoc(dump);

                                return;
                              }}
                              className="base-erase-btn size-large ml10"
                            />
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                );
              }
            })}
        </div>
      </article>
      {watch("schedule.contractPayType") === "TAX" && (
        <article className="contents-container__divide-top">
          <div className="contents-container__1200">
            <div className="contents-container__sub-title">
              <h2>세금계산서 수신</h2>
            </div>

            {taxInVoice.length > 0 &&
              taxInVoice.map((billContact: BillContactEdit, index: number) => {
                if (billContact.isDeleted === false) {
                  return (
                    <div className="flex-center" key={index}>
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p className={"required"}>세금계산서 수신 이메일</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <div className="minmax240 flex-row flex-center-start">
                            <BaseInput
                              readonly={
                                spaceProductType === "TIME_COURT" ||
                                contractBasic?.contractStep === "APPLY_CANCEL" ||
                                contractBasic?.contractStep === "TERMINATE_COMPLETE" ||
                                contractBasic?.contractStep === "APPLY_CONFIRM" ||
                                contractBasic?.contractStep === "USE_COMPLETE" ||
                                watch("schedule.contractPayType") === "PAY"
                              }
                              name={`billContact.email${index}`}
                              value={billContact.email}
                              onChange={(value: string) => {
                                const dump = _.cloneDeep(taxInVoice);
                                const adds = dump.map((talk: any, idx: number) => {
                                  if (idx === index) {
                                    talk.errorTextEmail = "";
                                    talk.email = value;
                                  }
                                  return talk;
                                });
                                setTaxInVoice(adds);
                              }}
                              errorText={billContact.errorTextEmail}
                            />
                            {index === 0 && (
                              <button
                                disabled={watch("schedule.contractPayType") === "PAY"}
                                className="base-add-btn ml10"
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  const dump = _.cloneDeep(taxInVoice);
                                  const filterd = dump.filter(
                                    (bill: any) => bill.isDeleted === false,
                                  );

                                  if (filterd.length < 3) {
                                    const anotherLine = {
                                      billContactId: 0,
                                      contractId: contractDetail?.contractId,
                                      receiveNotiType: "TAX_INVOICE",
                                      contact: "",
                                      email: "",
                                      description: "",
                                      isDeleted: false,
                                      errorText: "",
                                    };

                                    dump.push(anotherLine);
                                    setTaxInVoice(dump);
                                  }
                                  return;
                                }}
                              />
                            )}
                            {index > 0 && (
                              <button
                                title="삭제"
                                disabled={watch("schedule.contractPayType") === "PAY"}
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  let dump = _.cloneDeep(taxInVoice);
                                  const finded = dump.find((bill: any, idx: number) => {
                                    if (idx === index && Number(bill.billContactId) === 0) {
                                      return bill;
                                    }
                                  });
                                  if (finded) {
                                    dump = dump.filter((bill: any, idx: number) => idx !== index);
                                  } else {
                                    dump.forEach((bill: any, idx: number) => {
                                      if (idx === index && Number(bill.billContactId) !== 0) {
                                        bill.isDeleted = true;
                                      }
                                    });
                                  }
                                  setTaxInVoice(dump);

                                  return;
                                }}
                                className="base-erase-btn size-large ml10"
                              />
                            )}
                          </div>
                        </div>
                      </section>
                    </div>
                  );
                }
              })}
          </div>
        </article>
      )}
    </>
  );
};

export default ServiceType;
