import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useApiOperation } from "src/api/hooks";
import {
  getIoTRegisteredDevicePartnerListAsync,
  putIoTRegisteredDevicePartnerAsync,
} from "src/api/iot/iot-api";
import { Modal } from "src/api/public-types";
import { BaseButton } from "src/components";
import { Partner as PartnerType } from "src/types/partner";
import { columns } from "./columns/PartnerColumns";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";

type SelectedPartner = {
  id?: string | number;
  name?: string;
} & PartnerType;

const Partner = () => {
  const [partnerList, setPartnerList] = useState<PartnerType[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<SelectedPartner[]>();
  const [partnerModal, setPartnerModal] = useState<Modal>({ isOpen: false });
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams: any = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
  });

  const registeredDeviceId = id || queryParams?.id;

  const { executeAsync: getIoTRegisteredDevicePartnerList } = useApiOperation(
    getIoTRegisteredDevicePartnerListAsync,
  );
  const { executeAsync: putIoTRegisteredDevicePartner } = useApiOperation(
    putIoTRegisteredDevicePartnerAsync,
  );

  const fetchPartnerList = useCallback(
    async (registeredDeviceId: string) => {
      const { data, status } = await getIoTRegisteredDevicePartnerList({
        registeredDeviceId,
        sort: {
          orders: [{ property: "partner.id", direction: "DESC" }],
        },
      });
      if (status >= 200 && status < 300) {
        const responseData = data.data.partners;
        const partners = responseData.map((registeredPartner) => registeredPartner.partner);
        setSelectedPartner(partners);
        setPartnerList(partners);
      }
    },
    [getIoTRegisteredDevicePartnerList],
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns: columns,
      data: partnerList,
      initialState: {
        pageSize: 1000,
      },
      fetchPartnerList,
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  useEffect(() => {
    if (registeredDeviceId) {
      fetchPartnerList(registeredDeviceId);
    }
  }, [registeredDeviceId, fetchPartnerList]);

  return (
    <>
      <div className="contents-container__wrap-contents">
        <div className="py20 pr30" style={{ maxWidth: tableWidth + 30 }}>
          <div className="flex-row flex-center-end">
            <BaseButton title="+ 파트너 추가" onClick={() => setPartnerModal({ isOpen: true })} />
          </div>
        </div>
        <div className="inner-tab-table pr30">
          <div {...getTableProps()} className="base-table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                        {/* <div className="ic_sort"></div> */}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()} className="body">
              {page.map((row: any) => {
                prepareRow(row);

                return (
                  <div
                    {...row.getRowProps()}
                    className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                  >
                    {row.cells.map((cell: any) => {
                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {partnerModal.isOpen && (
          <PartnerSelectModal
            onCanceled={() => setPartnerModal({ isOpen: false })}
            onAdded={async (partner: PartnerType[]) => {
              const partnerIds = partner.map((partner) => String(partner.id));
              setSelectedPartner(partner);
              await putIoTRegisteredDevicePartner({
                registeredDeviceId,
                partnerIds,
              });
              setPartnerModal({ isOpen: false });
              fetchPartnerList(registeredDeviceId);
            }}
            multiSelect
            defaultValues={selectedPartner || []}
          />
        )}
      </div>
      <div className="contents-container__btn-wrap px30">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            type="button"
            className="color-white size-large"
            onClick={() => {
              navigate("/mng/central/iot");
            }}
          />
        </div>
        <div className="right-area"></div>
      </div>
    </>
  );
};

export default Partner;
