import _ from "lodash";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SpaceProductType } from "src/api/contract/contract-types";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { getOrgDetail } from "src/api/organization/org-api";
import { contract_bill_user_errors } from "src/api/public-types";
import { BaseButton, ContentsIdSection } from "src/components";
import pagePath from "src/pagePath.json";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import ChargeMntType from "./component/ChargeMntType";
import FOType from "./component/FOType";
import ServiceType from "./component/ServiceType";

const ContractDetail = ({
  basicInfo,
  application,
  contract,
  setContract,
  productDetail,
  spaceProductType,
}: any) => {
  // console.log("spaceProductType", spaceProductType);
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const navigate = useNavigate();
  const location = useLocation();
  // const params = useParams();
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );

  const [organization, setOrganization] = useState<any>();
  const [proof, setProof] = useState<any>();
  const [bi, setBi] = useState<any>();

  const [document, setDocument] = useState<any>([]);
  const [require, setRequire] = useState<any>([]);

  const { executeAsync: getOrganization } = useApiOperation(getOrgDetail, {
    doNotErrorHandleModal: true,
  });

  const moveEditForm = () => {
    delete queryParams.id;
    navigate(
      pagePath.contract.form +
        "?id=" +
        contract.contractId.toString() +
        "&" +
        decodeURIComponent(qs.stringify(queryParams, { allowDots: true, encode: true })),
    );
  };

  const callDetailInfo = useCallback(
    async (contract: any) => {
      setLoadingBar(true);
      if (contract?.mbOrganizationId! && contract?.mbOrganizationId > 0) {
        const response: any = await getOrganization({ id: Number(contract.mbOrganizationId) });
        console.log("response.data.data", response.data.data);
        if (response.status >= 200 && response.status <= 299) {
          setOrganization(response.data.data.organization);
          const proofs = response.data.data.mediaList.filter(
            (media: any) => media.type === "PROOF",
          );
          const bi = response.data.data.mediaList.filter((media: any) => media.type !== "PROOF");
          setProof(proofs);
          setBi(bi);
        } else {
          let message = errorMessageHandler(
            response.status,
            response.data.meta.errorCode,
            contract_bill_user_errors,
          );
          let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
          openErrorModal(message, code);
        }
      }

      setLoadingBar(false);
    },
    [getOrganization, openErrorModal, setLoadingBar],
  );

  useEffect(() => {
    if (contract) {
      callDetailInfo(contract);
    }
    if (contract?.contractMediaList && contract?.contractMediaList.length > 0) {
      const doc = contract?.contractMediaList.filter(
        (media: any) => media.serviceTag === "CONTRACT_DOCUMENT",
      );
      let req = contract?.contractMediaList.filter(
        (media: any) => media.serviceTag !== "CONTRACT_DOCUMENT",
      );
      req = _.sortBy(req, ["orderNums"]);
      setDocument(doc);
      setRequire(req);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract, spaceProductType]);
  // console.log("product", productDetail);
  return (
    <div className="contents-container__wrap detail-basic-info">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection
          title="계약"
          id={basicInfo?.contractId}
          noTitle="신청번호"
          no={basicInfo?.contractApplyNumber}
          status={basicInfo?.step}
        />
        {spaceProductType !== "TIME_COURT" && spaceProductType !== "MAINTENANCE_FEE" && (
          <FOType
            basicInfo={basicInfo}
            organization={organization}
            contract={contract}
            setContract={setContract}
            proof={proof}
            bi={bi}
            spaceProductType={spaceProductType}
            document={document}
            require={require}
          />
        )}
        {spaceProductType === "TIME_COURT" && (
          <ServiceType
            basicInfo={basicInfo}
            organization={organization}
            contract={contract}
            proof={proof}
            bi={bi}
            spaceProductType={spaceProductType}
            document={document}
            require={require}
          />
        )}
        {spaceProductType === "MAINTENANCE_FEE" && (
          <ChargeMntType
            basicInfo={basicInfo}
            organization={organization}
            contract={contract}
            proof={proof}
            bi={bi}
            spaceProductType={spaceProductType}
            document={document}
            require={require}
          />
        )}
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <BaseButton
            title="목록으로"
            onClick={() => {
              delete queryParams.id;
              navigate(
                pagePath.contract.list +
                  "?" +
                  decodeURIComponent(qs.stringify(queryParams, { allowDots: true, encode: true })),
              );
            }}
            className="color-white size-large"
          />
        </div>
        <div className="right-area">
          {/* {spaceProductType !== "TIME_COURT" && (
            <BaseButton
              title="수정"
              disabled={
                basicInfo?.contractStep === "APPLY_RECEIVED" ||
                basicInfo?.contractStep === "APPLY_CANCEL" ||
                basicInfo?.contractStep === "USE_COMPLETE" ||
                basicInfo?.contractStep === "TERMINATE_COMPLETE"
              }
              onClick={() => moveEditForm()}
              className="size-large"
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ContractDetail;
