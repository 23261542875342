import { AppState, Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

const AuthProviderState = ({
  children,
}: PropsWithChildren<any>): JSX.Element | null => {
  const navigate = useNavigate();

  const domain: string | undefined = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId: string | undefined = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience: string | undefined = process.env.REACT_APP_AUTH0_AUDIENCE;

  const isEnValid = domain && clientId && audience;

  if (!isEnValid) {
    throw new Error("Please check isEnvalid in authProviderState.tsx");
  }

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo || `${window.location.pathname}`);
  };

  return (
    <Auth0Provider
      domain={`https://${domain}`}
      clientId={clientId}
      redirectUri={`${window.location.origin}`}
      audience={audience}
      scope="read:current_user update:current_user_metadata offline_access"
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProviderState;
