import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useApiOperation } from "src/api/hooks";
import { getAuditLogsAsync } from "src/api/log/log-api";
import { AuditLogDto, LogCategoryType } from "src/api/log/log-type";
import { logColumn } from "./LogColumn";
import qs from "qs";

type Props = {
  type: "PARTNER" | "PARTNER_MEMBER" | "ACCESS_GROUP" | "IOT";
};

const LogTable = ({ type }: Props) => {
  const { id } = useParams();
  const location = useLocation();
  const [logData, setLogData] = useState<AuditLogDto[]>([]);
  const { executeAsync: getAuditLogs } = useApiOperation(getAuditLogsAsync);

  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
    decoder: (value) => value,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: logColumn,
      data: logData,
      disableSortBy: true,
    },
    useBlockLayout,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  const findLogPath = (type: string) => {
    let path;
    let pathId = id || queryParams.id;
    if (type === LogCategoryType.PARTNER) {
      path = `partners/${pathId}/`;
    }
    if (type === LogCategoryType.PARTNER_MEMBER) {
      path = `partner-members/${pathId}/`;
    }
    if (type === LogCategoryType.ACCESS_GROUP) {
      path = `access-groups/${pathId}/`;
    }
    if (type === LogCategoryType.IOT) {
      path = `iot/registered-devices/${pathId}/`;
    }
    return path || "";
  };

  useEffect(() => {
    const getLogData = async () => {
      const { data, status } = await getAuditLogs({
        path: findLogPath(type),
        sort: {
          orders: [{ property: "revisionTime", direction: "DESC" }],
        },
      });
      if (status >= 200 && status < 300) {
        const logData = data.data.content;
        setLogData(logData);
      }
    };
    getLogData();
  }, []);

  return (
    <>
      <section className="state-change-info inner-tab-table">
        <section className="state-change-info inner-tab-table">
          <div>
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                          {/* <div className="ic_sort"></div> */}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.map((row: any) => {
                  prepareRow(row);

                  return (
                    <div
                      {...row.getRowProps()}
                      className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
                {rows.length === 0 && (
                  <div className="base-table__tr" style={{ width: tableWidth }}>
                    <div className="base-table__td w-100 text-center">
                      <div className="w-100">
                        <span>데이터가 없습니다.</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default LogTable;
