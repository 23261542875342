import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
// import fileDownload from "js-file-download";

type PropValues = {
  title: string;
  fileList: any;
  classname?: string;
  titleBrFlag?: boolean;
  title2?: string;
};

const TextNFileDownload = ({ title, fileList, classname, titleBrFlag, title2 }: PropValues) => {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");

  const getToken = useCallback(async () => {
    // access token 가져오기
    await getAccessTokenSilently();

    // user IdToken 가져오기 (access token 을 먼저 가져와야 정상적으로 실행됨)
    const response = await getIdTokenClaims();
    const idToken = response?.__raw;
    setToken(idToken!);
  }, [getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    if (token === "") {
      getToken();
    }
  }, [getAccessTokenSilently, getIdTokenClaims, getToken, token]);

  return (
    <>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          {titleBrFlag ? (
            <p>
              {title}
              <br />
              {title2}
            </p>
          ) : (
            <p>{title} </p>
          )}
        </div>
        <div className="contents-container__grid-contents">
          <div className={classname !== undefined ? classname : "minmax240"}>
            {fileList && fileList.length > 0 ? (
              fileList?.map((file: any, index: number) => {
                return (
                  <a
                    target="_blank"
                    className="font14 text-hilight"
                    href={file.url + "?access_token=" + token + "&forceDownload"}
                    rel="noreferrer"
                    key={index}
                  >
                    {file.filename || file.originMediaName}
                  </a>
                );
              })
            ) : (
              <p>-</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TextNFileDownload;
