import React from "react";
import { ProductModel } from "src/api/product/product-types";
import { MetaData } from "src/api/public-types";

type Props = {
  product: ProductModel | null;
};

// 난방타입
const getHeatingTypeText = (product: ProductModel | null): string => {
  if (
    !product?.productAirConditioner ||
    !product?.productAirConditioner?.heatingType ||
    product?.productAirConditioner?.heatingType === "HEATING_UNRECOGNIZED"
  ) {
    return "데이터가 없습니다.";
  }
  let heatingTypeText = "";
  switch (product.productAirConditioner.heatingType) {
    case "CENTRAL_AIR_CONDITIONER":
      heatingTypeText += "중앙난방";
      break;
    case "INDIVIDUAL_AIR_CONDITIONER":
      heatingTypeText += "개별난방";
      break;
    case "DIRECT_HEATING_AIR_CONDITIONER":
      heatingTypeText += product.productAirConditioner?.heatingTypeDesc || "";
      break;
    default:
      heatingTypeText += "데이터가 없습니다.";
      break;
  }
  return heatingTypeText;
};

// 공조기 기기정보
function getAirConditionerInfoText(metaData: MetaData): string {
  switch (metaData.metaItem) {
    case "PRODUCT_AIR_CONDITIONER_1":
      return `천장형 시스템 냉난방기 ${metaData.value2 || 0}대`;
    case "PRODUCT_AIR_CONDITIONER_2":
      return `냉난방기 ${metaData.value2 || 0}대`;
    case "PRODUCT_AIR_CONDITIONER_3":
      return `에어컨 ${metaData.value2 || 0}대`;
    case "PRODUCT_AIR_CONDITIONER_4":
      return `난방기 ${metaData.value2 || 0}대`;
    case "PRODUCT_AIR_CONDITIONER_5":
      return metaData.value2 || "";
  }
  return "";
}

/* 
  공간상품 > 상세 > 시설/서비스 > 공조기 컴포넌트
*/
const AirConditionerSection = ({ product }: Props) => {
  const airConditionerInfoList: Array<MetaData> = (
    product?.productAirConditioner?.airConditionerInfoList || []
  ).sort((a: MetaData, b: MetaData) => Number(a.orderNums || 0) - Number(b.orderNums || 0));

  // 공조기 기기 제공여부: 제공여부가 true 이고, 기기정보 목록이 있을 경우, "제공"
  const isAirConditionerProvided: boolean =
    airConditionerInfoList.length > 0 && !!product?.productAirConditioner?.isAirConditionerProvided;

  return (
    <section className="contents-container__grid contents-container__1200">
      <div className="contents-container__grid-index">
        <p className="">공조기</p>
      </div>
      <div className="contents-container__grid-contents">
        <table className="inner-table" width="100%">
          <tbody>
            <tr>
              <td className="bg-gray text-center" width="140">
                <span className="text-primary3 no-wrap">난방 타입</span>
              </td>
              <td>
                <div className="flex-center px16">{getHeatingTypeText(product)}</div>
              </td>
            </tr>
            <tr>
              <td className="bg-gray text-center" width="140">
                <span className="text-primary3 no-wrap">기기</span>
              </td>
              <td>
                <div className="flex-center px16">
                  {isAirConditionerProvided ? "제공" : "제공하지 않음"}
                </div>
              </td>
            </tr>
            {isAirConditionerProvided && (
              <>
                <tr className="auto-height">
                  <td className="bg-gray text-center" width="140">
                    <span className="text-primary3 no-wrap">기기 정보</span>
                  </td>
                  <td className="auto">
                    {airConditionerInfoList
                      .filter((metaData: MetaData) => Boolean(String(metaData.value1) === "true"))
                      .map((metaData: MetaData, index: number) => {
                        return (
                          <div key={index.toString()} className="h-40 flex-center pl16">
                            <div className="minmax200">{getAirConditionerInfoText(metaData)}</div>
                          </div>
                        );
                      })}
                  </td>
                </tr>
                <tr>
                  <td className="bg-gray text-center" width="140">
                    <span className="text-primary3 no-wrap">추가 설명</span>
                  </td>
                  <td>
                    <div className="w-100 pl16">
                      {product?.productAirConditioner?.airConditionerDescription || ""}
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};
export default AirConditionerSection;
