/* eslint-disable array-callback-return */
const TableTextWithBuildingFacility = ({ title, product, type }: any) => {
  let searchType = type;

  const arr: any = [];
  if (product?.buildingList && product?.buildingList.length > 0) {
    product?.buildingList.map((list: any) => {
      if (searchType === "meetingRoomList") {
        list.building.buildingCommonFacility[searchType]?.map((room: any) => {
          arr.push(room.facilityName + "_" + room.facilityMaxPeopleNums + "인(" + room.id + ")");
        });
      }
      if (searchType === "refreshRoomList") {
        list.building.buildingCommonFacility[searchType]?.map((room: any) => {
          arr.push(room.facilityName + "(" + room.id + ")");
        });
      }
      if (searchType === "deskSpace") {
        list.building.buildingCommonFacility.deskSpace.deskGroupList?.map((room: any) => {
          room.deskList.map((facility: any) => {
            arr.push(facility.facilityName + "(" + facility.id + ")");
          });
        });
      }
    });
  }
  // console.log("arr", arr);

  return (
    <>
      <tr>
        <td width="180">
          <div className="flex-center pl16">
            <span className="text-primary3">{title}</span>
          </div>
        </td>
        <td>
          <div className="w-100 flex-center-start text-left">
            {arr.map((text: string) => text).join(",  ")}
          </div>
        </td>
      </tr>
    </>
  );
};
export default TableTextWithBuildingFacility;
