import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { createProductInfoAsync } from "src/api/product/product-info-api";
import { ProductInfoAddModel, ProductModel, ProductType } from "src/api/product/product-types";
import { getProviderList } from "src/api/provider/provider-api";
import { MetaData, Status } from "src/api/public-types";
import { BaseButton, BaseModal, BaseRadio, ContentsIdSection } from "src/components";
import { useLoadingBarContext, useToastContext } from "src/pages/hooks";
import { deleteCommas } from "src/utils";
import { PagePath } from "../../details";
import {
  Modal,
  productMaintainceFee,
  productRent,
  ProductRent,
  ProductRentDiscount,
  productSaleRent,
} from "../../product-types";
import FullCourt from "./FullCourt";
import NonResident from "./NonResident";
import OpenCourt from "./OpenCourt";
import ProvidersModal from "./ProvidersModal";
import TimeCourt from "./TimeCourt";

export type FormData = {
  providerId: string; //프로바이더 ID
  afterStatus: Status; //신청/계약 후 비공개 처리 여부
  productType: string; //상품타입
  minLeasePeriod: string; //최소계약기간
  maxLeasePeriod: string; //최대계약기간
  isRightAwayMoveIn: boolean; //입구가능여부 즉시/날짜 선택여부
  moveInAvailDate: string; //입주가능일자
  deposit: string; //보증금
  earnest: string; //계약금
  balanceFullPayment: string; //잔금
  balanceFullPaymentDate: string; //잔금일자
  isUseSaleDeposit: boolean | undefined;
  saleDeposit: string; //할인보증금
  saleEarnest: string; //할인계약금
  saleBalanceFullPayment: string; //보증금 할인 잔금
  rentalCostList: Array<MetaData>; //이용료
  isUseSaleRentalCost: boolean | undefined;
  saleRentalCostList: Array<MetaData>; //이용료 할인
  isInformPopup: boolean | undefined; //이용료 안내팝업 설정여부
  informPopupDesc: string; //이용료 안내팝업 내용
  isImpositionFee: boolean; //관리비 부과여부
  maintenanceFee: string; //관리비
  productMaintenanceFeeList: Array<MetaData>; //관리비 내역
  useNums: string; //사용인원
  isDeskChairIncluded: boolean | undefined; //책상_의자포함여부
  maxNums: string; //계정 최대 인원
  totalSeatNums: string | number; //총좌석수
  useStartTime: Date | string; //사용가능시작시간
  useEndTime: Date | string; //사용가능종료시간
};

interface IProps {
  product: ProductModel;
}
/* 
  공간상품 등록 > 등록 or 수정 > 상품정보
 */
const ProductInfoForm = ({ product: productDetail }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });
  const [submitData, setSubmitData] = useState<FormData>();

  const { setLoadingBar } = useLoadingBarContext();
  const { openToast } = useToastContext();

  // 공간상품 상세 조회 api
  // const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync);

  // 공간상품 상품정보 등록 api
  const { executeAsync: createProductInfo } = useApiOperation(createProductInfoAsync);

  //  프로바이더 목록 api
  const { executeAsync: getProviers } = useApiOperation(getProviderList);

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 공간상품 id
  const productId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  // 상품 타입
  const [productType, setProductType] = useState<ProductType>(ProductType.F);

  // 프로바이더 선택 모달 팝업
  const [isOpenProvidersModal, setIsOpenProvidersModal] = useState(false);

  const [selectedProvider, setSelectedProvider] = useState({
    // uid: "92",
    // providerName: "(주)핀포인트",
    providerId: "",
    providerName: "",
    partnerId: "",
  });
  // 저장 컨펌 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // React Hook Form
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      providerId: "",
      afterStatus: "DISABLED",
      productType: productType,

      minLeasePeriod: "",
      maxLeasePeriod: "",
      isRightAwayMoveIn: true,
      moveInAvailDate: "",
      deposit: "",
      earnest: "",
      balanceFullPayment: "",
      balanceFullPaymentDate: "",
      isUseSaleDeposit: false,
      saleDeposit: "",
      saleEarnest: "",
      saleBalanceFullPayment: "",
      rentalCostList: productRent,
      isUseSaleRentalCost: false,
      saleRentalCostList: productSaleRent,
      isInformPopup: false,
      informPopupDesc: "",
      isImpositionFee: false,
      maintenanceFee: "",
      productMaintenanceFeeList: productMaintainceFee,
      useNums: "",
      isDeskChairIncluded: true,
      maxNums: "",
      totalSeatNums: "",
      useStartTime: "",
      useEndTime: "",
    },
  });

  // validation 통과하지 못하고 error 발생시 실행
  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);

  // 수정 시 상세 정보 데이터 setValue
  const detailSetValue = useCallback(
    (detail) => {
      if (detail.providerId !== "0") {
        setProductType(detail.productType); // 어떤 코트 인지 확인

        Object.entries(detail).forEach(([name, value]: any) => {
          if (name === "saleDeposit") {
            if (value !== 0) {
              setValue("isUseSaleDeposit", true);
            }
            setValue("saleDeposit", value);
          } else if (name === "rentalCostList") {
            if ((value || []).length === 0) {
              setValue("rentalCostList", productRent);
            } else {
              setValue("rentalCostList", value);
            }
          } else if (name === "saleRentalCostList") {
            if ((value || []).length === 0) {
              setValue("saleRentalCostList", productSaleRent);
            } else {
              setValue("saleRentalCostList", value);
            }

            if (value[0]?.value1 !== "") {
              setValue("isUseSaleRentalCost", true);
            }
          } else if (name === "maintenanceFee") {
            setValue("maintenanceFee", value);

            if (value !== 0) {
              setValue("isImpositionFee", true);
            }
          } else if (name === "productMaintenanceFeeList") {
            const array = value;
            array.sort(
              (a: { orderNums: number }, b: { orderNums: number }) => a.orderNums - b.orderNums,
            );

            if (value.length === 0) {
              setValue("productMaintenanceFeeList", productMaintainceFee);
            } else {
              setValue("productMaintenanceFeeList", array);
            }
          } else if (name === "useStartTime") {
            // 서버에서 내려온 시간 value 가 유효한지 확인
            const isValidated = moment(value, "hh:mm").isValid(); //유효 - true / 유효 X - false

            if (isValidated) {
              setValue("useStartTime", moment(value, "hh:mm").toDate());
            } else {
              setValue("useStartTime", "");
            }
          } else if (name === "useEndTime") {
            const isValidated = moment(value, "hh:mm").isValid();

            if (isValidated) {
              setValue("useEndTime", moment(value, "hh:mm").toDate());
            } else {
              setValue("useEndTime", "");
            }
          } else {
            setValue(name, value);
          }
        });
      }
    },
    [setValue],
  );

  // 프로바이더명 setState 하기 / 프로바이더 stg PR 후 상세조회 api 로 변경예정
  const getProviderName = useCallback(async () => {
    const response = await getProviers();
    const _providerId = watch("providerId");
    const provider = response.data.data.content.filter(
      (item) => String(item.providerId) === _providerId,
    );

    if (provider.length > 0 && provider[0]) {
      const providerData = {
        providerId: _providerId,
        providerName: String(provider[0].providerName),
        partnerId: String(provider[0].partnerId),
      };

      setSelectedProvider(providerData);
    }
  }, [watch, getProviers]);

  // 공간상품 상세 api
  const getProductDetailApi = useCallback(
    async (id: number) => {
      if (!productDetail) return;

      const result = productDetail;

      detailSetValue(result); // 상품정보 수정시 기본 데이터 setVavlue

      setSelectedProvider({
        providerId: String(result.providerId),
        providerName: "",
        partnerId: String(result.partnerId),
      });

      if (result.productType === "PRODUCT_UNRECOGNIZED") {
        setProductType(ProductType.F);
      }
      // 프로바이더명 목록 api 에서 찾기

      await getProviderName();
    },
    [detailSetValue, getProviderName, productDetail],
  );

  useEffect(() => {
    if (productId) {
      getProductDetailApi(productId);
    }
  }, [getProductDetailApi, productId]);

  //
  // 풀코트 / 오픈코트 / 타임코트 / 비상주 코트에 따른 validation
  const formVaildation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";

    // * 계약기간 - 풀코트 / 오픈코트
    (productType === ProductType.F || productType === ProductType.O) &&
      register("minLeasePeriod", {
        required: { value: true, message: requiredMessage },
      });

    (productType === ProductType.F || productType === ProductType.O) &&
      register("maxLeasePeriod", {
        required: { value: true, message: requiredMessage },
      });

    // // * 입주 가능일 - 풀코트 / 오픈코트

    (productType === ProductType.F || productType === ProductType.O) &&
      register("moveInAvailDate", {
        validate: {
          required: () => {
            let result = true;
            let message = "";

            if (
              getValues("isRightAwayMoveIn") === false &&
              getValues("moveInAvailDate") === ("" || null)
            ) {
              result = false;
              message = requiredMessage;
            }
            return result || message;
          },
        },
      });

    // * 관리비 - 풀코트

    productType === ProductType.F &&
      register("isImpositionFee", {
        validate: {
          required: () => {
            let result = true;
            let message = "";

            if (watch("isImpositionFee") === true) {
              if (!watch("maintenanceFee")) {
                result = false;
                message = requiredMessage;
              }
            }
            return result || message;
          },
        },
      });

    //
    // * 이용료 - 전 코트
    (productType === ProductType.F ||
      productType === ProductType.O ||
      productType === ProductType.T ||
      productType === ProductType.N) &&
      register("rentalCostList", {
        validate: {
          required: (v?: Array<MetaData>) => {
            let result = true;
            let message = "";

            if (v && v[0].value1 === "") {
              result = false;
              message = requiredMessage;
            }
            return result || message;
          },
        },
      });

    // * 보증금 - 풀코트 / 오픈코트
    (productType === ProductType.F || productType === ProductType.O) &&
      register("deposit", {
        required: { value: true, message: requiredMessage },
      });

    (productType === ProductType.F || productType === ProductType.O) &&
      register("earnest", {
        required: { value: true, message: requiredMessage },
      });
    (productType === ProductType.F || productType === ProductType.O) &&
      register("balanceFullPaymentDate", {
        required: { value: true, message: requiredMessage },
      });

    // * 이용료 안내 팝업 설정 시 text 부분 - 풀코트 / 오픈코트

    productType === (ProductType.F || ProductType.O) &&
      register("informPopupDesc", {
        validate: {
          required: () => {
            let result = true;
            let message = "";

            if (getValues("isInformPopup") === true && getValues("informPopupDesc") === "") {
              result = false;
              message = requiredMessage;
            }

            return result || message;
          },
        },
      });

    // * 사용인원 - 풀코트 / 오픈코트
    (productType === ProductType.F || productType === ProductType.O) &&
      register("useNums", {
        required: { value: true, message: "필수입력 및 선택 항목입니다" },
      });

    // * 계정 최대 인원 - 풀코트 / 오픈코트
    (productType === ProductType.F || productType === ProductType.O) &&
      register("maxNums", {
        required: { value: true, message: requiredMessage },
      });

    // * 총 좌석수 - 타임코트
    productType === ProductType.T &&
      register("totalSeatNums", {
        required: { value: true, message: requiredMessage },
      });

    // * 이용 가능시간 - 타임코트 / 비상주 오피스
    (productType === ProductType.T || productType === ProductType.N) &&
      register("useStartTime", {
        required: { value: true, message: requiredMessage },
      });

    (productType === ProductType.T || productType === ProductType.N) &&
      register("useEndTime", {
        required: { value: true, message: requiredMessage },
      });
  }, [getValues, productType, register, watch]);

  useEffect(() => {
    if (productDetail.productCategory === "PRODUCT_CATEGORY_MANAGEMENT") return;

    formVaildation();
  }, [formVaildation]);

  //
  // 유효성 검사 통과 후 서밋
  const onSubmit = useCallback(
    async (data: FormData, e) => {
      e.preventDefault();

      if (productDetail.productCategory === "PRODUCT_CATEGORY_NORMAL") {
        // 보증금 콤마 없애기
        // 타입이 string 이면 콤마가 있다는 뜻

        if (typeof data.deposit === "string") {
          data.deposit = deleteCommas(data.deposit);
        }
        if (typeof data.earnest === "string") {
          data.earnest = deleteCommas(data.earnest);
        }
        if (typeof data.saleDeposit === "string") {
          data.saleDeposit = deleteCommas(data.saleDeposit);
        }
        if (typeof data.saleEarnest === "string") {
          data.saleEarnest = deleteCommas(data.saleEarnest);
        }

        // 이용료 콤마 없애기
        const rentalCost = deleteCommas(data.rentalCostList[0].value1);
        data.rentalCostList[0].value1 = rentalCost;

        // 이용료 할인 콤마 없애기
        const saleRentalCost = deleteCommas(data.saleRentalCostList[0].value1);
        data.saleRentalCostList[0].value1 = saleRentalCost;

        // 경고 팝업

        // 보증금 / 계약금 비교 보증금 계약금 비교
        if (data.deposit && data.earnest) {
          if (Number(data.deposit) < Number(data.earnest)) {
            setAlertModal({
              isOpen: true,
              message: "계약금은 보증금보다 더 적은 금액을 입력해주세요.",
              payload: "earnest",
            });
            setLoadingBar(false);

            return;
          }
        }

        if (data.saleDeposit && data.saleEarnest) {
          if (Number(data.saleDeposit) < Number(data.saleEarnest)) {
            setAlertModal({
              isOpen: true,
              message: "계약금은 보증금보다 더 적은 금액을 입력해주세요.",
              payload: "saleEarnest",
            });
            setLoadingBar(false);
            return;
          }
        }

        // 할인 금액 비교
        if (data.deposit && data.saleDeposit) {
          if (Number(data.deposit) < Number(data.saleDeposit)) {
            setAlertModal({
              isOpen: true,
              message: "할인은 원래 가격보다 더 적은 금액을 입력해주세요.",
              payload: "saleDeposit",
            });
            setLoadingBar(false);
            return;
          }
        }

        if (rentalCost && saleRentalCost) {
          if (Number(rentalCost) < Number(saleRentalCost)) {
            setAlertModal({
              isOpen: true,
              message: "할인은 원래 가격보다 더 적은 금액을 입력해주세요.",
              payload: "saleRentalCostList",
            });
            setLoadingBar(false);
            return;
          }
        }

        if (data.useNums && data.maxNums) {
          if (Number(data.useNums) > Number(data.maxNums)) {
            setAlertModal({
              isOpen: true,
              message: "사용인원은 계정 최대 인원보다 많을 수 없습니다.",
              payload: "maxNums",
            });
            setLoadingBar(false);
            return;
          }
        }

        // ProductType이 타임코트면 Rent Type 변경
        if (productType === ProductType.T) {
          data.rentalCostList[0].metaItem = ProductRent.MIUNUTE;
          data.saleRentalCostList[0].metaItem = ProductRentDiscount.MIUNUTE;
        }
      }
      if (!selectedProvider.providerId) {
        setAlertModal({
          isOpen: true,
          message: "프로바이더를 선택하세요",
          payload: "providerId",
        });
        setLoadingBar(false);
        return;
      }
      // if (!data.providerId) {
      //   setAlertModal({
      //     isOpen: true,
      //     message: "프로바이더를 선택하세요",
      //     payload: "providerId",
      //   });
      //   setLoadingBar(false);
      //   return;
      // }

      setSubmitData(data);

      setConfirmModal({ message: "공간상품 상품정보 저장하시겠습니까?", isOpen: true });
      console.log("create", data);
    },
    [productDetail, productType, selectedProvider],
  );

  const onClickConfirmModal = async (data: FormData) => {
    setLoadingBar(true);

    // 코트 타입 지정
    const type = productType;
    data.productType = type;

    //  이용료 콤마 string -> number로 변경

    // POST 할 data
    const courtData: ProductInfoAddModel = {
      providerId: data.providerId !== "" ? data.providerId : selectedProvider?.providerId,
      afterStatus: data.afterStatus,
      productType: data.productType,
      minLeasePeriod: Number(data.minLeasePeriod),
      maxLeasePeriod: Number(data.maxLeasePeriod),
      moveInAvailDate: data.moveInAvailDate,
      deposit: Number(deleteCommas(data.deposit)),
      earnest: Number(deleteCommas(data.earnest)),
      isRightAwayMoveIn: data.isRightAwayMoveIn,
      isImpositionFee: data.isImpositionFee,
      balanceFullPaymentDate: Number(data.balanceFullPaymentDate),
      saleDeposit:
        Number(deleteCommas(data.saleDeposit)) !== 0 ? Number(deleteCommas(data.saleDeposit)) : 0,
      saleEarnest:
        Number(deleteCommas(data.saleEarnest)) !== 0 ? Number(deleteCommas(data.saleEarnest)) : 0,
      rentalCostList: data.rentalCostList,
      saleRentalCostList: data.saleRentalCostList,
      isInformPopup: data.isInformPopup,
      informPopupDesc: data.informPopupDesc,
      maintenanceFee: data.isImpositionFee ? Number(data.maintenanceFee) : 0,
      productMaintenanceFeeList:
        data.isImpositionFee === true ? data.productMaintenanceFeeList : [],

      useNums: Number(data.useNums),
      isDeskChairIncluded: data.isDeskChairIncluded,
      maxNums: Number(data.maxNums),
      totalSeatNums: data.totalSeatNums,
      useStartTime: moment(data.useStartTime).format("HH:mm").toString(),
      useEndTime: moment(data.useEndTime).format("HH:mm").toString(),
    };

    const createResponse = await createProductInfo({
      productId: String(productId),
      product: courtData,
    });
    setLoadingBar(false);

    if (createResponse.status >= 200 && createResponse.status <= 299) {
      const result = createResponse.data.data.content;

      console.log("result", result);
      openToast(`정상적으로 ${result ? "수정" : "저장"}되었습니다.`);
      navigate(`${PagePath.product.detail.replace(":id", String(productId))}?tab=productInfo`);
    }
  };

  return (
    <div className="contents-container__wrap">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__wrap-contents">
          <ContentsIdSection title="상품정보" id={productId} />
          {/* 프로바이더 */}
          <article>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">프로바이더</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <BaseButton
                    title="선택"
                    className="color-white"
                    onClick={() => setIsOpenProvidersModal(true)}
                  />
                  <span className="ml16">{selectedProvider?.providerName}</span>
                </div>
              </div>
            </section>

            {productDetail?.productCategory === "PRODUCT_CATEGORY_NORMAL" && (
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">
                    신청/계약 후<br />
                    비공개처리
                  </p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <Controller
                      control={control}
                      name="afterStatus"
                      render={({ field: { onChange, name, value } }) => {
                        return (
                          <>
                            <BaseRadio
                              id={"afterStatusFalse"}
                              name={name}
                              label="비공개"
                              className="mr16"
                              onChange={() => onChange("DISABLED")}
                              checked={value === "DISABLED" ? true : false}
                            />
                            <BaseRadio
                              id={"afterStatusTrue"}
                              name={name}
                              label="공개"
                              onChange={() => onChange("ENABLED")}
                              checked={value === "ENABLED" ? true : false}
                            />
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                  {errors?.afterStatus && (
                    <p className="validation-text">{errors?.afterStatus?.message}</p>
                  )}
                </div>
              </section>
            )}
            {/* 상품 타입 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">상품 타입</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="base-segment">
                    <div>
                      <input
                        type="radio"
                        checked={productType === ProductType.F}
                        id="productTypeFullCourt"
                        name="productType"
                        onChange={() => {
                          setProductType(ProductType.F);
                          reset();
                        }}
                        className="base-segment__active"
                      />
                      <label htmlFor="productTypeFullCourt">Full Court</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        checked={productType === ProductType.O}
                        id="productTypeOpenCourt"
                        name="productType"
                        value={ProductType.O}
                        onChange={() => {
                          setProductType(ProductType.O);
                          reset();
                        }}
                        className="base-segment__active"
                      />
                      <label htmlFor="productTypeOpenCourt">Open Court</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        checked={productType === ProductType.T}
                        id="productTypeTimeCourt"
                        name="productType"
                        onChange={() => {
                          setProductType(ProductType.T);
                          reset();
                        }}
                        className="base-segment__active"
                      />
                      <label htmlFor="productTypeTimeCourt">Service Court</label>
                    </div>

                    {/* QA-287 요청에 의한 비상주오피스 버튼 숨김처리 - 삭제 x */}
                    {/* <div>
                      <input
                        type="radio"
                        checked={productType === ProductType.N}
                        id="productTypeNonResident"
                        name="productType"
                        onChange={() => {
                          setProductType(ProductType.N);
                          reset();
                        }}
                        className="base-segment__active"
                      />
                      <label htmlFor="productTypeNonResident">비상주 오피스</label>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
          </article>

          {productDetail?.productCategory === "PRODUCT_CATEGORY_NORMAL" && (
            <>
              {/* 상품타입에 따라서 렌더링 */}
              {productType === ProductType.F && (
                <FullCourt control={control} watch={watch} errors={errors} setValue={setValue} />
              )}
              {productType === ProductType.O && (
                <OpenCourt control={control} watch={watch} errors={errors} setValue={setValue} />
              )}
              {productType === ProductType.T && (
                <TimeCourt
                  control={control}
                  watch={watch}
                  errors={errors}
                  setValue={setValue}
                  type={productType}
                />
              )}
              {productType === ProductType.N && (
                <NonResident control={control} watch={watch} errors={errors} />
              )}
            </>
          )}
          {/* 버튼영역 */}
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title="목록으로"
                className="color-white size-large"
                onClick={() => navigate(PagePath.product.list)}
              />
            </div>
            <div className="right-area">
              <BaseButton type="submit" title="저장" className=" size-large" />
            </div>
          </div>
        </div>
      </form>
      {/* 프로바이더 선택 모달 팝업 */}
      <ProvidersModal
        isOpen={isOpenProvidersModal}
        close={(selectedProvider?) => {
          setIsOpenProvidersModal(false);
          if (selectedProvider) {
            setSelectedProvider(selectedProvider);
            setValue("providerId", selectedProvider.providerId);
          }
        }}
        selectedProvider={selectedProvider}
      />
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => {
          setAlertModal({ isOpen: false });
          setFocus(alertModal.payload);
        }}
      >
        <span>{alertModal.message}</span>
      </BaseModal>

      <>
        <BaseModal
          isOpen={confirmModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => submitData && onClickConfirmModal(submitData)}
        >
          <p>{confirmModal.message}</p>
        </BaseModal>
      </>
    </div>
  );
};
export default ProductInfoForm;
