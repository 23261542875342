import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BuildingFloorModel, BuildingRoomModel } from "src/api/building/building-types";
import { getContractManageByContractApplyNumber } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import { getProductDetailAsync } from "src/api/product/product-api";
import { ProductModel } from "src/api/product/product-types";
import { Address } from "src/api/public-types";
import { getVisitorsByVisitApplyNumberAsync } from "src/api/visitor/visitor-api";
import { VisitorModel } from "src/api/visitor/visitor-types";
import { BaseButton, ContentsIdSection, ContentsTitle } from "src/components";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext } from "src/pages/hooks";
import { Partner } from "src/types/partner";
import VisitorsSection from "./components/VisitorsSection";

const breadCrumbs = [
  { value: "visitor", label: "방문자" },
  { value: "", label: "방문자 초대" },
];

/* 
  방문자 > 방문자 초대 > 상세
*/
const VisitorDetail = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const navigate = useNavigate();

  // router path variable 신청/계약번호, 방문신청번호
  const { contractApplyNumber, visitApplyNumber } = useParams();

  // 공간상품 정보
  const [product, setProduct] = useState<ProductModel | null>(null);

  // 파트너
  const [partner, setPartner] = useState<Partner>();

  // 방문자 목록
  const [visitors, setVisitors] = useState<Array<VisitorModel>>([]);

  // 계약관리 정보 조회 api
  const { executeAsync: getContractManage } = useApiOperation(
    getContractManageByContractApplyNumber,
  );

  // 공간상품 정보 조회 api
  const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync);

  // 파트너 정보 조회 api
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  // 방문자 상세 조회 api
  const { executeAsync: getVisitorsByVisitApplyNumber } = useApiOperation(
    getVisitorsByVisitApplyNumberAsync,
  );

  // 상단 오른쪽 라벨들
  const contentsIdLables: Array<{ key: string; value: string }> = useMemo(() => {
    const labels = [{ key: "신청/계약번호", value: contractApplyNumber || "" }];
    if (!!visitApplyNumber) {
      labels.push({ key: "방문신청번호", value: visitApplyNumber || "" });
    }
    return labels;
  }, [contractApplyNumber, visitApplyNumber]);

  // 계약관리, 공간상품정보 조회 후 데이터 바인딩
  const fetchContractAndProduct = useCallback(
    async (contractApplyNumber: string) => {
      try {
        setLoadingBar(true);
        // 계약관리 정보 조회
        const { data } = await getContractManage({ contractApplyNumber });
        const { spaceProductId } = data?.data?.contractManage;

        if (!!spaceProductId) {
          // 공간상품 정보 조회
          const { data: productData } = await getProductDetail({
            productId: spaceProductId,
          });
          if (productData?.data?.content) {
            setProduct(productData.data.content);
          }
        }
        setLoadingBar(false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBar(false);
      }
    },
    [setLoadingBar, getContractManage, getProductDetail],
  );

  // 방문자 조회
  const fetchVisitors = useCallback(
    async (visitApplyNumber: string) => {
      try {
        setLoadingBar(true);
        const { data } = await getVisitorsByVisitApplyNumber({ visitApplyNumber });
        if (data?.data?.contractVisitor && (data?.data?.contractVisitor || []).length > 0) {
          // 삭제되지 않은 방문자 목록
          const contractVisitorList: Array<VisitorModel> = data.data.contractVisitor
            .filter((contractVisitor: VisitorModel) =>
              Boolean(contractVisitor.isDeleted === false || contractVisitor.isDeleted === "false"),
            )
            .sort(
              (a: VisitorModel, b: VisitorModel) =>
                Number(a.contractVisitorId || 0) - Number(b.contractVisitorId || 0),
            );

          // 방문자 목록 바인딩
          setVisitors(contractVisitorList);
        }
        setLoadingBar(false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBar(false);
      }
    },
    [getVisitorsByVisitApplyNumber, setLoadingBar],
  );

  useEffect(() => {
    if (!!contractApplyNumber) {
      // 공간상품 정보 조회
      fetchContractAndProduct(contractApplyNumber);
    }
    if (!!visitApplyNumber) {
      // 방문자 조회 (수정시)
      fetchVisitors(visitApplyNumber);
    }
  }, [contractApplyNumber, visitApplyNumber, fetchContractAndProduct, fetchVisitors]);

  useEffect(() => {
    (async () => {
      if (product?.partnerId === undefined) return;
      if (product?.partnerId === "0") return;

      const result = await getPartnerAsync({
        id: product.partnerId,
      });

      if (result.status >= 200 && result.status < 300) {
        setPartner(result.data.data.partner);
      }
    })();
  }, [product]);

  // 공간상품의 대표 건물 주소
  const primaryBuildingAddress: string = useMemo(() => {
    if (!product) return "";
    let primaryBuildingAddress = "";
    for (let i = 0; i < (product?.buildingList || []).length; i++) {
      const building = (product?.buildingList || [])[i];
      if (building) {
        // 공간상품의 대표 건물 호실
        const primaryBuildingFloor: BuildingFloorModel | undefined = (
          building?.buildingFloorList || []
        ).find((buildingFloor: BuildingFloorModel) => {
          return (buildingFloor?.buildingRoomList || []).find(
            (buildingRoom: BuildingRoomModel) => buildingRoom.isPrimary, // 대표
          );
        });
        if (!!primaryBuildingFloor) {
          const address: Address = (building?.addressList || [])[0];
          primaryBuildingAddress = `(${address.zonecode}) ${address.address} ${address.addressDetail}`;
        }
      }
    }
    return primaryBuildingAddress;
  }, [product]);

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "visitor") {
        navigate(`${PagePath.visitor.list}?contractApplyNumber=${contractApplyNumber}`);
      }
    },
    [navigate, contractApplyNumber],
  );

  return (
    <div>
      <ContentsTitle title="방문자" breadCrumbs={breadCrumbs} clickBreadCrumb={clickBreadCrumb} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap page-user-form">
          <div className="contents-container__wrap-contents">
            <ContentsIdSection title="방문자 초대" rightLables={contentsIdLables} />
            <article className="pb30">
              <div className="contents-container__sub-title">
                <h2>방문 공간 및 일시</h2>
              </div>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>공간상품명</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{product?.productName}</p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>공간상품 주소</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{primaryBuildingAddress}</p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>방문 시작일시</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{moment(visitors[0]?.visitStartTime).format("YYYY-MM-DD HH:mm")}</p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>방문 종료일시</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{moment(visitors[0]?.visitEndTime).format("YYYY-MM-DD HH:mm")}</p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>만남장소</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{visitors[0]?.meetingPlace ? visitors[0]?.meetingPlace : "-"}</p>
                </div>
              </section>
            </article>
            <article className="contents-container__divide-top">
              <div className="contents-container__sub-title">
                <h2>파트너</h2>
              </div>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>파트너</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>{partner && <span>{`${partner?.name} (${partner?.id})`}</span>}</div>
                </div>
              </section>
            </article>
            <article className="contents-container__divide-top pb30">
              <div className="contents-container__sub-title">
                <h2>방문자</h2>
              </div>
              <VisitorsSection visitors={visitors} />
            </article>
          </div>
          {/* 버튼영역 */}
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title="목록으로"
                className="color-white size-large"
                onClick={() => {
                  const visitorListPath = `${PagePath.visitor.list}?contractApplyNumber=${contractApplyNumber}`;
                  navigate(visitorListPath);
                }}
              />
            </div>
            <div className="right-area">
              {/* <BaseButton
                title="수정"
                className="size-large"
                onClick={() => {
                  const visitorFormPath =
                    PagePath.visitor.form.replace(
                      ":contractApplyNumber",
                      String(contractApplyNumber || ""),
                    ) +
                    "?visitApplyNumber=" +
                    visitApplyNumber;
                  navigate(visitorFormPath);
                }} 
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VisitorDetail;
