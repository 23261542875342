import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { ProviderAddModel, ProviderMediaFile } from "src/api/provider/provider-types";
import { BaseModal } from "src/components";
import { BaseButton } from "src/components/BaseButton";
import useFileHandlers from "src/pages/contract/form/components/contractDetail/component/hooks/useFileHandler";

interface SendFiles {
  setPrivateFiles: any;
  privateFiles: any;
  errorText?: string;
  setValue?: UseFormSetValue<ProviderAddModel>;
  watch?: Function;
}

interface SendFiles {
  title?: string;
  name: string;
  setPrivateFiles: any;
  privateFiles: any;
  errorText?: string;
  accept?: string;
  limit: number;
  maxSize: number;
  disabled?: boolean;
}

const ContractUpload = ({
  title,
  setPrivateFiles,
  privateFiles,
  errorText,
  accept,
  limit,
  maxSize,
  name,
  disabled,
}: SendFiles) => {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");

  const getToken = useCallback(async () => {
    // access token 가져오기
    await getAccessTokenSilently();

    // user IdToken 가져오기 (access token 을 먼저 가져와야 정상적으로 실행됨)
    const response = await getIdTokenClaims();
    const idToken = response?.__raw;
    setToken(idToken!);
  }, [getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    if (token === "") {
      getToken();
    }
  }, [getAccessTokenSilently, getIdTokenClaims, getToken, token]);

  const mb = 1024 * 1024;
  const [isOpen, setIsOpen] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const { onChange } = useFileHandlers({ privateFiles, setPrivateFiles });

  const onDeleteFile = useCallback(
    (e: any, index: number) => {
      e.preventDefault();
      let newArr: any = _.cloneDeep(privateFiles);
      const finded: any = newArr.find((obj: any, i: number) => {
        if (i === index) {
          return obj;
        }
      });
      console.log("finded", finded);
      if (finded && finded.id) {
        for (let i = 0; i < newArr.length; i++) {
          if (i === index) {
            const obj: any = newArr[i];
            obj.isDeleted = true;
          }
        }
      } else {
        newArr = newArr.filter((obj: any, i: number) => i !== index);
      }

      setPrivateFiles(newArr);
    },
    [privateFiles, setPrivateFiles],
  );
  const showFiles = useCallback(() => {
    // console.log("privateFiles", privateFiles);
    return (
      privateFiles &&
      privateFiles.map((file: any, index: number) => {
        if (file.isDeleted === false) {
          return (
            <div key={`thumb${index}`} className="flex-center mb10">
              <a
                className="font14 text-hilight"
                href={file.url + "?access_token=" + token + "&forceDownload"}
                rel="noreferrer"
                target={"_blank"}
              >
                {file.filename}
              </a>
              <button
                className="base-remove ml8 mt4"
                onClick={(e) => onDeleteFile(e, index)}
              ></button>
            </div>
          );
        }
      })
    );
  }, [disabled, onDeleteFile, privateFiles, token]);

  const onChangeFile = useCallback(
    (e) => {
      // console.log(e.target.files);
      const sizeLimit = maxSize * mb;
      let checker = false;
      //파일의 사이즈 체크
      for (let i = 0; i < e.target.files.length; i++) {
        // console.log(e.target.files[i].size, sizeLimit);
        if (e.target.files[i].size > sizeLimit) {
          checker = true;
          break;
        }
      }

      if (checker === false) {
        const nd: any = privateFiles.filter((obj: any) => obj.isDeleted === false);
        let limitChecker = e.target.files.length + nd.length;
        console.log("e.target.files.length", e.target.files.length);
        if (limitChecker <= limit) {
          //파일의 갯수 체크
          onChange(e);
        } else {
          setIsOpen(true);
        }
      } else {
        setIsOpen(true);
      }
      console.log("privateFiles", privateFiles);
    },
    [limit, maxSize, mb, onChange, privateFiles],
  );

  useEffect(() => {
    showFiles();
  }, [showFiles]);

  return (
    <div className="contents-container__grid-contents">
      <div className="">
        <input
          type="file"
          name={name ? name : "img-loader-input"}
          multiple
          ref={fileRef}
          onChange={onChangeFile}
          style={{ display: "none" }}
          accept={accept ? accept : "*"}
        />

        <BaseButton
          title={title ? title : "첨부"}
          className="color-white mb12"
          disabled={disabled ? disabled : false}
          onClick={() => fileRef.current?.click()}
        />

        <div>
          <div className="pt2">{showFiles()}</div>
          {errorText && <p className="validation-text">{errorText}</p>}
        </div>
      </div>
      <BaseModal isOpen={isOpen} btnRightTitle={"확인"} onClick={() => setIsOpen(false)}>
        <p>한 파일당 업로드 용량은 10MB, 최대 {limit}개까지만 가능합니다.</p>
      </BaseModal>
    </div>
  );
};
export default ContractUpload;
