import { ReactChild } from "react";
import { createPortal } from "react-dom";
import { BaseButton } from "../components";
interface state {
  isOpen: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: ReactChild | any;
  title?: string;
  btnLeftTitle?: string;
  btnRightTitle?: string;
  type?: string;
  btnRightType?: "button" | "submit" | "reset" | undefined;
  className?: string;
  btnRightDisable?: boolean;
}

export const BaseModal = (props: state) => {
  const el = document.getElementById("modal");

  if (!props.isOpen) return null;

  return createPortal(
    <>
      <div className="dim">
        <div className={`base-modal ${props.className ? props.className : ""}`}>
          <div className="base-modal__contents">
            {props.title && (
              <div className="base-modal__title keep-all pre-formatted">
                <p>{props.title}</p>
              </div>
            )}
            {props.children && (
              <>
                {/* <h4 className="font16 mb20">타이틀</h4> */}
                {props.children}
              </>
            )}
          </div>
          {(props.btnLeftTitle !== undefined || props.btnRightTitle !== undefined) && (
            <div className="base-modal__btn-wrap">
              {props.btnLeftTitle && (
                <BaseButton
                  title={props.btnLeftTitle}
                  onClick={props.onClose}
                  className="color-white no-outline text-primary3 mr10"
                />
              )}
              {props.btnRightTitle && (
                <BaseButton
                  title={props.btnRightTitle}
                  type={props.btnRightType ? props.btnRightType : undefined}
                  onClick={props.onClick}
                  className=""
                  disabled={props.btnRightDisable}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>,
    el!,
  );
};
