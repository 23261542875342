import React, { useCallback, useEffect, useState } from "react";
import { Control, Controller, UseFormReturn } from "react-hook-form";
import { BuildingModel, CommonFacilityModel } from "src/api/building/building-types";
import { getEslProductDetailAsync } from "src/api/esl/esl-api";
import { EslProduct } from "src/api/esl/esl-types";
import { useApiOperation } from "src/api/hooks";
import { ProductModel } from "src/api/product/product-types";
import { MediaFile } from "src/api/public-types";
import { BaseCheckbox, BaseInput, BaseRadio, BaseToggle, BaseTooltip } from "src/components";
import { showHourMinute } from "src/utils";
import { CommonSpaceFormData } from "../CommonSpaceForm";

type Props = {
  control: Control<any>;
  watch: UseFormReturn<CommonSpaceFormData>["watch"];
  errors: any;
  setValue: Function;
  setIsNoData: Function;
  productDetail?: ProductModel;
  getValues: Function;
  totalOption: any;
  refreshList: CommonFacilityModel[];
  checkedAllItem: Function;
};

interface EslLabel extends EslProduct {
  locationCode: string; // 공간유형 채번
}

const s3Url = process.env.REACT_APP_S3_BASEURL;

const sampleImg =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/No_Cross.svg/1024px-No_Cross.svg.png";

const RefreshRoomFormComp = ({
  control,
  watch,
  errors,
  setValue,
  setIsNoData,
  productDetail,
  getValues,
  totalOption,
  checkedAllItem,
  refreshList,
}: Props) => {
  // ESL 데이터 상세 조회 api
  const { executeAsync: getEslProductDetail } = useApiOperation(getEslProductDetailAsync, {
    doNotErrorHandleModal: true,
  });

  // 조회한 ESL 데이터 목록
  const [eslLabels, setEslLabels] = useState<Array<EslLabel>>([]);

  // esl 라벨 목록 조회
  const fetchAllLabels = useCallback(async (locationCodes: Array<string>) => {
    try {
      if (!locationCodes || locationCodes.length === 0) {
        throw Error("locationCodes.length === 0");
      }

      const eslLabels: Array<EslLabel> = [];
      for (let i = 0; i < locationCodes.length; i++) {
        const locationCode = locationCodes[i];
        const result = await getEslProductDetail({ productId: locationCode });
        if (result?.data?.data && result.status >= 200 && result.status <= 299) {
          if (result?.data?.data?.product) {
            // esl 라벨이 연동되어있을 경우만 조회됨
            eslLabels.push({
              ...result?.data?.data?.product,
              ...{
                locationCode,
              },
            });
          }
        }
      }

      setEslLabels(eslLabels);
    } catch (error) {
      // 에러 발생해도 무시
      console.debug("fetchAllLabels error", error);
    }
  }, []);

  // 수정시 api 에서 받아온 building 정보로 setValue 처리
  useEffect(() => {
    let refreshes: CommonFacilityModel[] = [];

    if (refreshList) {
      for (let j = 0; j < refreshList.length; j++) {
        const meetingItem = refreshList[j];

        refreshes.push(meetingItem);
      }
    }

    if (refreshes) {
      // locaiotn code 로 라벨 목록 조회
      const locationCodes: Array<string> = [];
      for (let i = 0; i < refreshes.length; i++) {
        const locationCode = refreshes[i]?.locationCode;
        if (!!locationCode) {
          locationCodes.push(locationCode);
        }
      }
      if (locationCodes.length > 0) {
        fetchAllLabels(locationCodes);
      }
    }
  }, [refreshList]);

  return (
    <article>
      <div className="contents-container__sub-title">
        <div className="minmax140 pb4">
          <h2>편의시설</h2>
        </div>
        <Controller
          control={control}
          name="isRefreshRoom"
          render={({ field: { onChange, name, value } }) => {
            return (
              <>
                <BaseToggle
                  name={name}
                  // onChange={(value: boolean) => {
                  //   if (value && refreshList.length === 0) {
                  //     setIsNoData(true);
                  //     return;
                  //   }
                  //   onChange(value);
                  // }}
                  checked={value}
                />
              </>
            );
          }}
        ></Controller>
      </div>
      {/* 구분선 없음, 토글시 아래 영역이 노출되지 않음 */}
      {Boolean(watch("isRefreshRoom")) && (
        <div>
          {/* 리프레시 룸 */}
          <section className="contents-container__grid contents-container__1200">
            <div className="contents-container__grid-index">
              <p className="required">편의시설 선택</p>
            </div>
            <div className="contents-container__grid-contents">
              {productDetail?.buildingList &&
                productDetail?.buildingList.map((product: BuildingModel) => {
                  const filterdList = refreshList.filter(
                    (room: CommonFacilityModel) => room.buildingId === product?.id,
                  );

                  return (
                    <div className="pt8 mb10" key={product?.id}>
                      <div className="index-tab">
                        <span>{product?.buildingName}</span>
                      </div>
                      <table className="inner-table" width="100%">
                        <thead>
                          <tr>
                            <th>
                              <BaseCheckbox
                                id={`REFRESH_ROOM${product?.id}`}
                                name={product?.buildingName}
                                checked={checkedAllItem(filterdList)}
                                className="justify-contents-center"
                                // onChange={(checked: boolean) => {
                                //   let commonSpaceArr = watch("selectedCommonFacility") || [];

                                //   if (checked) {
                                //     filterdList?.forEach((item) => {
                                //       if (item.buildingId === product?.id) {
                                //         commonSpaceArr.push(item);
                                //       }
                                //     });
                                //   } else {
                                //     if (filterdList) {
                                //       for (let i = 0; i < filterdList.length; i++) {
                                //         const refreshItem = filterdList[i];

                                //         commonSpaceArr = commonSpaceArr.filter(
                                //           (item: CommonFacilityModel) => item.id !== refreshItem.id,
                                //         );
                                //       }
                                //     }
                                //   }

                                //   setValue("selectedCommonFacility", [...commonSpaceArr]);
                                // }}
                              />
                            </th>
                            <th>
                              <span>이미지</span>
                            </th>
                            <th>
                              <span>편의시설 명</span>
                            </th>
                            <th>
                              <span>위치</span>
                            </th>
                            <th>
                              <span>기자재</span>
                            </th>
                            <th>
                              <span>설명</span>
                            </th>
                            <th>
                              <span>ESL</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterdList &&
                            filterdList.map((refresh: CommonFacilityModel) => {
                              let mediaList: Array<MediaFile> = [];
                              const pramaryImage = refresh?.mediaList;
                              pramaryImage?.forEach((item) => {
                                if (item.isPrimary === true) {
                                  mediaList.push(item);
                                }
                              });

                              let mediaFile =
                                mediaList.length > 0
                                  ? mediaList[0]
                                  : refresh?.mediaList && refresh?.mediaList[0];

                              const findLabel = eslLabels.find(
                                (eslLabel: EslLabel) =>
                                  eslLabel.locationCode === refresh.locationCode,
                              );

                              return (
                                <tr key={refresh.id}>
                                  <td width="70">
                                    <div className="flex-center-center no-wrap">
                                      <BaseCheckbox
                                        id={String(`refresh${refresh.id}`)}
                                        name={""}
                                        value={String(refresh.id)}
                                        checked={
                                          getValues("selectedCommonFacility")?.find(
                                            (item: CommonFacilityModel) =>
                                              String(item.id) === String(refresh.id),
                                          )
                                            ? true
                                            : false
                                        }
                                        onChange={(checked: boolean) => {
                                          let commonFacilityArr: CommonFacilityModel[] =
                                            getValues("selectedCommonFacility") || [];

                                          if (checked) {
                                            commonFacilityArr.push(refresh);
                                          } else {
                                            commonFacilityArr = commonFacilityArr.filter(
                                              (item: CommonFacilityModel) =>
                                                Number(item.id) !== Number(refresh.id),
                                            );
                                          }

                                          setValue("selectedCommonFacility", [
                                            ...commonFacilityArr,
                                          ]);
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td width="60">
                                    <div className="flex-center-center no-wrap">
                                      <img
                                        src={
                                          mediaFile !== undefined
                                            ? s3Url && s3Url + mediaFile?.key
                                            : sampleImg
                                        }
                                        className="inner-table__img"
                                        alt="회의실 이미지"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="flex-center-center no-wrap">
                                      <span>{refresh.facilityName}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="flex-center-center no-wrap">
                                      <span>
                                        {refresh.isGround ? "지상" : "지하"}
                                        {refresh.floorNum}층
                                      </span>
                                    </div>
                                  </td>
                                  <td width="70">
                                    <div className="flex-center-center no-wrap">
                                      <div
                                        className={
                                          refresh?.facilityList?.length === 0 ? "not-available" : ""
                                        }
                                      >
                                        {refresh.facilityList && refresh.facilityList.length > 0 ? (
                                          <BaseTooltip
                                            contents={"입력"}
                                            tooltip={refresh!
                                              .facilityList!.map((value: string) => value)
                                              .join(",")}
                                            type={"normal"}
                                          />
                                        ) : (
                                          <span>미입력</span>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td width="70">
                                    <div className="flex-center-center no-wrap">
                                      <div
                                        className={
                                          refresh.description === "" ? "not-available" : ""
                                        }
                                      >
                                        {refresh.description !== "" ? (
                                          <BaseTooltip
                                            contents={"입력"}
                                            tooltip={refresh.description}
                                            type={"normal"}
                                          />
                                        ) : (
                                          <span>미입력</span>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td width="100">
                                    <div className="flex-center-center no-wrap">
                                      <div
                                        className={findLabel === undefined ? "not-available" : ""}
                                      >
                                        {findLabel !== undefined ? (
                                          <BaseTooltip
                                            contents={"입력"}
                                            tooltip={(findLabel?.assignedLabelCodes || []).join(
                                              ",",
                                            )}
                                            type={"normal"}
                                          />
                                        ) : (
                                          <span>미입력</span>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  );
                })}

              {errors?.selectedCommonFacility &&
                watch("selectedCommonFacility")?.every(
                  (item: CommonFacilityModel) => item.commonFacilityType !== "REFRESH_ROOM",
                ) && <p className="validation-text">필수선택 항목입니다</p>}
            </div>
          </section>
          {/* Court 소개 내용 */}
          <section className="contents-container__grid contents-container__1200">
            <div className="contents-container__grid-index">
              <p>Court 소개 내용</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <div className="minmax140">
                  <span>추가 설명</span>
                </div>
                <Controller
                  control={control}
                  name="refreshRoomDescription"
                  render={({ field: { onChange, name, value } }) => {
                    return (
                      <>
                        <BaseInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          placeholder="COURT에 표시될 편의시설 관련 추가 섦명을 적어주세요"
                        />
                      </>
                    );
                  }}
                ></Controller>
              </div>
            </div>
          </section>
          {/* 예약 설정 */}
          <section className="contents-container__grid contents-container__1200">
            <div className="contents-container__grid-index">
              <p className="required">Taap 예약 설정</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start mb10">
                <div className="minmax140">
                  <span>총 예약 시간/월</span>
                </div>
                <Controller
                  control={control}
                  name="refreshRoomMaxReservTimeMonthIsFlag"
                  render={({ field: { onChange, name, value } }) => {
                    return (
                      <>
                        <BaseRadio
                          id={"refreshReserve9"}
                          value={"false"}
                          name={name}
                          label="제한 없음"
                          className="mr16"
                          checked={
                            Boolean(getValues("refreshRoomMaxReservTimeMonthIsFlag")) === false
                          }
                          onChange={() => {
                            setValue("refreshRoomMaxReservTimeMonthIsFlag", false);
                            onChange(false);
                            setValue("refreshRoomMaxReservTimeMonth", 0);
                          }}
                        />
                        <BaseRadio
                          id={"refreshReserve10"}
                          value={"true"}
                          name={name}
                          label="제한 있음"
                          className="mr16"
                          checked={
                            Boolean(getValues("refreshRoomMaxReservTimeMonthIsFlag")) === true
                          }
                          onChange={() => {
                            onChange(true);
                            setValue("refreshRoomMaxReservTimeMonthIsFlag", true);
                            setValue(
                              "refreshRoomMaxReservTimeMonth",
                              totalOption[totalOption.length - 1].value,
                            );
                          }}
                        />
                      </>
                    );
                  }}
                ></Controller>
                {Boolean(watch("refreshRoomMaxReservTimeMonthIsFlag")) === true && (
                  <>
                    <span className="font13 text-primary5 mr10">{`( 매월 총`}</span>
                    <div className="minmax100">
                      <Controller
                        control={control}
                        name="refreshRoomMaxReservTimeMonth"
                        render={({ field: { name, value, onChange } }) => {
                          return (
                            <>
                              <BaseInput
                                type="number"
                                name={name}
                                value={value && value > 0 ? value : ""}
                                readonly={
                                  !Boolean(getValues("refreshRoomMaxReservTimeMonthIsFlag"))
                                }
                                onChange={onChange}
                              />
                            </>
                          );
                        }}
                      ></Controller>
                    </div>
                    <span className="font13 text-primary5 ml10">{`분 [${showHourMinute(
                      String(watch("refreshRoomMaxReservTimeMonth")),
                    )}] 예약 가능)`}</span>
                  </>
                )}
              </div>
              <div className="flex-row flex-center-start" style={{ minHeight: "36px" }}>
                <div className="minmax140">
                  <span>예약 최대 시간/일</span>
                </div>
                <Controller
                  control={control}
                  name="refreshRoomMaxReservTimeDayIsFlag"
                  render={({ field: { onChange, name, value } }) => {
                    return (
                      <>
                        <BaseRadio
                          id={"refreshReserve11"}
                          value={"false"}
                          name={name}
                          label="제한 없음"
                          className="mr16"
                          checked={
                            Boolean(getValues("refreshRoomMaxReservTimeDayIsFlag")) === false
                          }
                          onChange={() => {
                            setValue("refreshRoomMaxReservTimeDayIsFlag", false);
                            onChange(false);
                            setValue("refreshRoomMaxReservTimeDay", 0);
                          }}
                        />
                        <BaseRadio
                          id={"refreshReserve12"}
                          value={"true"}
                          name={name}
                          label="제한 있음"
                          className="mr16"
                          checked={Boolean(getValues("refreshRoomMaxReservTimeDayIsFlag")) === true}
                          onChange={() => {
                            onChange(true);
                            setValue("refreshRoomMaxReservTimeDayIsFlag", true);
                            setValue("refreshRoomMaxReservTimeDay", 120);
                          }}
                        />
                      </>
                    );
                  }}
                ></Controller>
                {Boolean(getValues("refreshRoomMaxReservTimeDayIsFlag")) === true && (
                  <>
                    <span className="font13 text-primary5 mr10">{`( 1일 총`}</span>
                    <div className="minmax100">
                      <Controller
                        control={control}
                        name="refreshRoomMaxReservTimeDay"
                        render={({ field: { name, value, onChange } }) => {
                          return (
                            <>
                              <BaseInput
                                type="number"
                                name={name}
                                value={value && value > 0 ? value : ""}
                                readonly={!Boolean(getValues("refreshRoomMaxReservTimeDayIsFlag"))}
                                onChange={onChange}
                              />
                            </>
                          );
                        }}
                      ></Controller>
                    </div>
                    <span className="font13 text-primary5 ml10">{`분 [${showHourMinute(
                      String(watch("refreshRoomMaxReservTimeDay")),
                    )}] 예약 가능)`}</span>
                  </>
                )}
              </div>
              {errors?.isRefreshRoom && <p className="validation-text">필수선택 항목입니다</p>}
            </div>
          </section>
        </div>
      )}
    </article>
  );
};

export default RefreshRoomFormComp;
