import React from "react";
import { BrowserRouter } from "react-router-dom";
import AuthProviderState from "./AuthProviderState";
import { GlobalNav, SideBar } from "./components";
import RoutesList from "./routes/Routes";
import { ToastContextProvider } from "src/contexts/ToastContext";
import { LoadingBarContextProvider } from "src/contexts/LoadingBarContext";
import { ErrorModalContextProvider } from "src/contexts/ErrorModalContext";
import UserAuthority from "./components/UserAuthority";
import { RecoilRoot } from "recoil";
import ReactQueryProvider from "./ReactQueryProvider";
import ModalList from "./components/ModalList";
import ToastList from "./components/ToastList";

function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <BrowserRouter>
          <AuthProviderState>
            <ReactQueryProvider>
              <LoadingBarContextProvider>
                <ErrorModalContextProvider>
                  <ToastContextProvider>
                    <UserAuthority />
                    <GlobalNav />
                    <SideBar />
                    <RoutesList />
                    <ModalList />
                    <ToastList />
                  </ToastContextProvider>
                </ErrorModalContextProvider>
              </LoadingBarContextProvider>
            </ReactQueryProvider>
          </AuthProviderState>
        </BrowserRouter>
      </RecoilRoot>
    </div>
  );
}

export default App;
