import { IMenuListStatus } from "../types";
import { PagePath } from "src/pages/product/details";

const BUILDING_BASE_PATH = `${PagePath.building.list}`;
const PRODUCT_BASE_PATH = `${PagePath.product.list}`;

const BUILDING_MENU_LIST: IMenuListStatus[] = [
  {
    title: "총 건물 수",
    count: -1,
    path: BUILDING_BASE_PATH,
    backgroundColor: "bg-dashboard-card",
  },
];

const PRODUCT_MENU_LIST: IMenuListStatus[] = [
  {
    title: "총 상품 수",
    count: -1,
    path: PRODUCT_BASE_PATH,
    backgroundColor: "bg-dashboard-card",
  },
];

export { BUILDING_MENU_LIST, PRODUCT_MENU_LIST };
