import qs from "qs";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { ContentsTitle } from "src/components";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import PagePath from "src/pagePath.json";
import BasicInfoDetail from "./basicInfoDetail/BasicInfoDetail";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  // { value: "memo", label: "관리자 메모" },
];

/* 
  공용공간 예약 > 상세
 */
const ReservationDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "reservation") {
        // 목록으로 이동
        navigate(PagePath.reservation.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "reservation", label: "공용공간 예약" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  return (
    <div className="page-product-access">
      <ContentsTitle
        title="공용공간 예약"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />
      {/* 탭 */}
      <div className="contents-container__scroll">
        {/* 기본정보 */}
        {activeTab?.value === "basicInfo" && <BasicInfoDetail />}
        {/* 관리자 메모 */}
        {/* {activeTab?.value === "memo" && ( //
          <AdminMemoIntegration
            serviceId={Number(queryParams.id || params.id)}
            serviceType={ServiceTypes.SERVICE_RESERVATION}
            viewOnly={false}
          />
        )} */}
      </div>
    </div>
  );
};
export default ReservationDetail;
