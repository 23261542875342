import React from "react";
import { ProviderPopbillModel } from "src/api/provider/provider-types";

type Props = {
  providerPopbill?: ProviderPopbillModel;
};

const ProviderPopbillDetail = ({ providerPopbill }: Props) => {
  return (
    <article className="contents-container__divide-top">
      <div className="contents-container__sub-title flex-column">
        <h2>계좌 입금 확인 / 세금계산서 발행 정보</h2>
      </div>
      <section className="contents-container__1200">
        <div className="mb10">
          <div className="index-tab">
            <span>팝빌 인증정보</span>
          </div>
          <div className="border-gray py16 pr16 px20">
            <section className="flex-center mb10">
              <div className="minmax123">
                <span className="required font13 text-primary3">LinkID</span>
              </div>
              <span className="font13">{providerPopbill?.linkId}</span>
            </section>
            <section className="flex-center">
              <div className="minmax123">
                <span className="required font13 text-primary3">SecretKey</span>
              </div>
              <span className="font13">{providerPopbill?.secretKey?.replace(/./g, "*")}</span>
            </section>
          </div>
        </div>
      </section>
      <section className="contents-container__1200">
        <div className="mb10">
          <div className="index-tab">
            <span>팝빌 인증정보 검증</span>
          </div>
          <div className="border-gray py16 pr16 px20">
            <section className="flex-center mb10"></section>
            <section className="flex-center">
              <div className="minmax200 d-flex">
                <span className="font13 text-primary3">세금 계산서 발행 API : </span>
                <span className=" font13 ml10">
                  {providerPopbill?.isVerifiedTaxInvoice ? "성공" : "실패"}
                </span>
              </div>

              <div className="minmax200">
                <span className="font13 text-primary3">예금주 조회 API : </span>
                <span className=" font13 ml10">
                  {providerPopbill?.isVerifiedAccountInfo ? "성공" : "실패"}
                </span>
              </div>

              <div className="minmax200">
                <span className="font13 text-primary3">계좌 조회 API : </span>
                <span className=" font13 ml10">
                  {providerPopbill?.isVerifiedBankAccount ? "성공" : "실패"}
                </span>
              </div>
            </section>
          </div>
        </div>
      </section>
    </article>
  );
};

export default ProviderPopbillDetail;
