import qs from "qs";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseModal, ContentsTitle } from "src/components";
import { useTitleOperation } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import UseProductInfo from "../detail/components/useProductInfo/UseProductInfo";
import BuildingInfo from "../detail/components/buildingInfo/BuildingInfo";
import BasicInfoForm from "./components/basicInfo/BasicInfoForm";
import OrgAdminMemoTab from "./components/orgAdminMemo/OrgAdminMemoTab";

const OrganizationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [orgData, setOrgData] = useState<any>();
  const [modalOpen, setModalOpen] = useState(false);

  const tabs = [
    { value: "basicInfo", label: "기본정보" },
    { value: "useProduct", label: "신청/계약" },
    { value: "buildingInfo", label: "건물" },
    { value: "adminMemo", label: "관리자 메모" },
  ];

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const orgId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      if (!queryParams.id && tab.value !== "basicInfo") {
        setModalOpen(true);
        return;
      }

      // console.log("clickTab", tab);
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      // console.log("clickBreadCrumb", crumb);
      if (crumb.value === "product") {
        navigate(PagePath.product.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "building", label: "사업자" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  // // title 에 건물명 바인딩
  let name;
  if (orgData && orgData?.organization?.name) {
    name = orgData?.organization?.name;
  }

  useTitleOperation(name);
  //

  return (
    <div>
      {modalOpen && (
        <BaseModal isOpen={modalOpen} btnRightTitle="확인" onClick={() => setModalOpen(false)}>
          <span>기본정보가 저장되지 않았습니다</span>
        </BaseModal>
      )}
      <ContentsTitle
        title="사업자"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />

      <div className="contents-container__scroll">
        {activeTab?.value === "basicInfo" && <BasicInfoForm setOrgData={setOrgData} />}
        {activeTab?.value === "useProduct" && <UseProductInfo orgId={String(orgId)} />}
        {activeTab?.value === "buildingInfo" && <BuildingInfo orgId={String(orgId)} />}
        {activeTab?.value === "adminMemo" && <OrgAdminMemoTab />}
      </div>
    </div>
  );
};

export default OrganizationForm;
