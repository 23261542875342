import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { getAccessLogAsync } from "src/api/access/ac2-api";
import { AccessLogModel } from "src/api/access/ac2-types";
import { useApiOperation } from "src/api/hooks";
import { QueryParams } from "src/api/member/member-types";
import { PageMeta, Sort } from "src/api/public-types";
import { BaseButton, BaseDatePicker, BaseModal, BasePagination } from "src/components";
import BaseScroll from "src/components/BaseScroll";
import { useLoadingBarContext } from "src/pages/hooks";
import { YmdFormat } from "src/utils";
import * as xlsx from "xlsx";

type Props = {
  isOpen: boolean;
  memberId: string; // 회원번호
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

type IParams = {
  startDate: string;
  endDate: string;
  page: number;
  size: number;
  memberId?: string;
  sort?: Sort;
};
const columnHeader: any = [
  {
    Header: "일시",
    accessor: "datetime",
    // sticky: "left",
    width: 220,
    Cell: ({ value }: any) => <p>{value ? moment(value).format(YmdFormat.FULL) : ""}</p>,
  },
  {
    Header: "이용자",
    accessor: "phoneNumberChange",
    width: 250,
  },
  {
    Header: "출입문",
    accessor: "deviceName",
    width: 250,
    Cell: ({ value }: any) => <p className="w-100 text-left">{value ? value : ""}</p>,
  },
  {
    Header: "이벤트",
    accessor: "eventGroupName",
    width: 200,
  },
];

const SearchAccessLog = (props: Props) => {
  const [logs, setLogs] = useState<AccessLogModel[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { setLoadingBar } = useLoadingBarContext();
  const { executeAsync: findAccessLog } = useApiOperation(getAccessLogAsync);

  const [params, setParams] = useState<IParams>({
    startDate: "",
    endDate: "",
    page: 0,
    size: 20,
    sort: { orders: [{ property: "datetime", direction: "DESC" }] },
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, rows } = useTable(
    {
      columns: columnHeader,
      data: logs,
      initialState: { pageSize: 1000 },
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );
  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  const makeName = (eventGroup: string) => {
    let text = "출입 인증 성공";
    switch (eventGroup) {
      case "ACCESS_FAILURE":
        text = "출입 인증 실패";
        break;
      case "ACCESS_REJECTED":
        text = "출입 거부";
        break;

      default:
        break;
    }
    return text;
  };

  const changePhoneNumber = (phoneNumber: string) => {
    let cp = phoneNumber.replace("+82", "0");
    let second = cp.substring(3, 11);
    let middle = second.substring(0, 4);
    let last = second.substring(4, 8);
    return "010-" + middle + "-" + last;
  };

  const fetchLog = useCallback(
    async (param: IParams, type: string) => {
      setLoadingBar(true);
      let response;
      if (type === "search") {
        const data: any = await findAccessLog({ ...param, page: 0 });
        response = { ...data };
      } else {
        const data: any = await findAccessLog({ ...param });
        response = { ...data };
      }

      setLoadingBar(false);
      let array: any = [];
      if (response.data.data.content.length > 0) {
        setTotalElements(response.data.meta.pageMeta.totalElements);
        setPageMeta(response.data.meta.pageMeta);

        response.data.data.content.forEach((obj: any) => {
          if (obj.phoneNumber !== undefined) {
            obj.phoneNumberChange = changePhoneNumber(obj.phoneNumber);
          } else {
            obj.phoneNumberChange = "";
          }

          if (obj.eventGroup !== undefined) {
            obj.eventGroupName = makeName(obj.eventGroup);
          } else {
            obj.eventGroupName = "";
          }
          if (obj.deviceName === undefined) {
            obj.deviceName = "";
          }
          if (obj.datetime === undefined) {
            obj.datetime = "";
          }
        });
        array = _.sortBy(response.data.data.content, ["datetime"]).reverse();
      }

      setLogs(array);
    },
    [findAccessLog, setLoadingBar],
  );

  const download = useCallback(async () => {
    let fileName = "central_출입로그_{startDate}~{endDate}_{today}.xlsx";
    const book = xlsx.utils.book_new();
    const header = {
      A: "일시",
      B: "이용자",
      C: "출입문",
      D: "이벤트",
    };
    let totalExcelData: AccessLogModel[] = [];
    if (pageMeta?.totalPages) {
      setLoadingBar(true);
      const promises = [];
      for (let page = 0; page < pageMeta?.totalPages; page++) {
        promises.push(findAccessLog({ ...params, page }));
      }
      const results = await Promise.all(promises);
      results.forEach(({ data }) => {
        totalExcelData = [...data.data.content, ...totalExcelData];
      });
    }

    const downloadData: any = [header];

    totalExcelData.sort((a, b) => {
      const dateA = moment(a.datetime);
      const dateB = moment(b.datetime);
      return dateB.diff(dateA);
    });

    totalExcelData.forEach((data: any) => {
      const dl: any = {
        A: moment(data.datetime).format("YYYY.MM.DD hh:mm:ss"),
        B: changePhoneNumber(data.phoneNumber),
        C: data.deviceName,
        D: makeName(data.eventGroup),
      };
      downloadData.push(dl);
    });

    const data = xlsx.utils.json_to_sheet(downloadData, {
      header: ["A", "B", "C", "D"],
      skipHeader: true,
    });

    // fileName = fileName.replace("{contractApplyNumber}", props.contractApplyNumber);
    fileName = fileName.replace("{startDate}", moment(params.startDate).format("YYYYMMDD"));
    fileName = fileName.replace("{endDate}", moment(params.endDate).format("YYYYMMDD"));
    fileName = fileName.replace("{today}", moment().format("YYYYMMDDHHmm"));

    xlsx.utils.book_append_sheet(book, data, "ACCESS_LOGS");
    xlsx.writeFile(book, fileName);
    setLoadingBar(false);
  }, [params, pageMeta]);

  useEffect(() => {
    setTotalElements(0);

    if (props.isOpen && props.memberId) {
      const dump = _.cloneDeep(params);

      setParams({ memberId: props.memberId, ...dump });
      let startT = moment().subtract(8, "days").format(YmdFormat.YYYY_MM_DD);
      // let startT = moment("2023-02-09", YmdFormat.YYYY_MM_DD).subtract(8, "days").format(YmdFormat.YYYY_MM_DD);

      let addTime = startT + " 00:00:00";

      dump.startDate = moment(addTime).format(YmdFormat.WITH_TIME_ZONE);

      let endT = moment().format(YmdFormat.YYYY_MM_DD);
      // let endT = moment("2023-02-09", YmdFormat.YYYY_MM_DD).subtract(1, "days").format(YmdFormat.YYYY_MM_DD);

      let endTime = endT + " 23:59:59";

      dump.endDate = moment(endTime).format(YmdFormat.WITH_TIME_ZONE);
      // dump.contractManageId = props.contractManageId;

      setParams({ memberId: props.memberId, ...dump });

      fetchLog({ memberId: props.memberId, ...dump }, "search");
    }
  }, [props.isOpen]);
  return (
    <div>
      <BaseModal
        className="dialog-modal"
        isOpen={props.isOpen}
        btnRightTitle="엑셀 Export"
        btnLeftTitle="닫기"
        onClick={() => download()}
        onClose={props.onClose}
      >
        <>
          <div className="modal-title">
            <div className="left-area flex-end">
              <span>출입로그</span>
              <p className="ml10"> 전체 {totalElements}</p>
            </div>
            <div className="right-area">
              <div className="flex-center">
                <div className="">
                  <div className="flex-row flex-center">
                    <div className="flex-row flex-center">
                      <div className="minmax120">시작일</div>
                      <div className="minmax140">
                        <BaseDatePicker
                          name={"searchStart"}
                          selectedDate={
                            params.startDate ? moment(params.startDate).toDate() : moment().toDate()
                          }
                          setDate={(value: Date) => {
                            const dump = _.cloneDeep(params);
                            let startT = moment(value).format(YmdFormat.YYYY_MM_DD);

                            let addTime = startT + " 00:00:00";

                            dump.startDate = moment(addTime).format(YmdFormat.WITH_TIME_ZONE);

                            setParams(dump);
                          }}
                          maxDate={moment().toDate()}
                        />
                      </div>
                    </div>
                    <div className="flex-row flex-center">
                      <div className="minmax120">종료일</div>
                      <div className="minmax140">
                        <BaseDatePicker
                          name={"paramsEnd"}
                          selectedDate={
                            params.endDate ? moment(params.endDate).toDate() : moment().toDate()
                          }
                          setDate={(value: Date) => {
                            const dump = _.cloneDeep(params);

                            let endT = moment(value).format(YmdFormat.YYYY_MM_DD);

                            let addTime = endT + " 23:59:59";

                            dump.endDate = moment(addTime).format(YmdFormat.WITH_TIME_ZONE);
                            setParams(dump);
                          }}
                          maxDate={moment().toDate()}
                        />
                      </div>
                    </div>
                    <BaseButton
                      title={"검색"}
                      className="ml8"
                      onClick={async () => {
                        await fetchLog(params, "search");
                        setParams({ ...params, page: 0 });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* table */}

          <div className="contents-container__table px30">
            {/* <div className="base-pagination__total mb5 flex-center-start"></div> */}
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <BaseScroll maxHeight={500}>
                <div {...getTableBodyProps()} className="body">
                  {rows.map((row: any) => {
                    prepareRow(row);
                    return (
                      <div
                        {...row.getRowProps()}
                        className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                      >
                        {row.cells.map((cell: any) => {
                          return (
                            <div {...cell.getCellProps()} className="base-table__td">
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                  <div>
                    {rows.length === 0 && (
                      <div
                        className="base-table__tr table-cursor-unset"
                        style={{ width: tableWidth }}
                      >
                        <div className="base-table__td w-100 text-center">
                          <div className="w-100">{<span>데이터가 없습니다.</span>}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </BaseScroll>
            </div>
            <BasePagination
              pageIndex={params.page || 0}
              totalPages={pageMeta?.totalPages || 0}
              goPage={(page: number) => {
                setParams({ ...params, page });
                fetchLog({ ...params, page }, "pagination");
              }}
            />
          </div>
        </>
      </BaseModal>
    </div>
  );
};

export default SearchAccessLog;
