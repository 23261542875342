// 회차별이용

import moment from "moment";
import { numberToStringWithComma, YmdFormat } from "src/utils";

export const columns: any = [
  {
    Header: "회차",
    accessor: "billOrder",
    width: 80,

    //
    Cell: ({ value, row }: any) => {
      if (row.original.isOverdueBill === true) {
        return <div className="ic_ho"></div>;
      } else {
        return <p>{value}</p>;
      }
    },
  },
  {
    Header: "항목명",
    accessor: "billEndTime",
    // sticky: "left",
    width: 140,
    Cell: ({ value, row }: any) => {
      // console.log('row', row.original);
      if (row.original.isOverdueBill === true) {
        return <p>{"연체료"}</p>;
      } else {
        return <p>{row.original.billOrder + "회차 이용료"}</p>;
      }
    },
  },
  {
    Header: "청구일자",
    accessor: "billIssueDate",
    // sticky: "left",
    width: 140,
    Cell: ({ value, row }: any) => {
      // console.log('row', row.original);

      return <p>{moment(value).format(YmdFormat.YYYY_MM_DD)}</p>;
    },
  },
  {
    Header: "공급가액(원)",
    accessor: "baseAmount",
    width: 140,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 d-flex justify-contents-end">{numberToStringWithComma(value)}</div>
      );
    },
  },
  {
    Header: "할인/할증 금액(원)",
    accessor: "adjustAmount",
    width: 140,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 d-flex justify-contents-end">{numberToStringWithComma(value)}</div>
      );
    },
  },

  {
    Header: "청구금액(원)",
    accessor: "totalAmount",
    width: 140,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 d-flex justify-contents-end">{numberToStringWithComma(value)}</div>
      );
    },
  },
];
