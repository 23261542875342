import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { MemberModel } from "src/api/member/member-types";
import PagePath from "src/pagePath.json";

export const MemberUidCell = (props: Cell<MemberModel>) => {
  const detailPath = `${PagePath.member.detail.replace(":id", `${props.row.original.id}`)}`;

  // console.log('detailPath',detailPath);

  return (
    <div>
      <Link to={detailPath} className="text-hilight">
        {props?.value || ""}
      </Link>
    </div>
  );
};
