import { useEffect } from "react";
import { Control, Controller, useFieldArray, UseFormRegister } from "react-hook-form";
import { ProductGuideNetwork, ProductGuideUpdateForm } from "src/api/product/product-types";
import { BaseInput } from "src/components";
import {} from "../guide-types";

type Props = {
  control: Control<ProductGuideUpdateForm>;
  wifiIdx: number;
  targetWifiGuideListName: "customerWifiList" | "visitorWifiList";
  register: UseFormRegister<ProductGuideUpdateForm>;
  errors?: any;
};

/* 
  공간상품 > 등록 or 수정 > 안내 > 와이파이 컴포넌트 > 네트워크 컴포넌트
*/
const FreeInternetNetwork = ({
  control,
  wifiIdx,
  targetWifiGuideListName,
  errors,
  register,
}: Props) => {
  // 네트워크 목록
  const {
    fields: productGuideNetworkList,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `productGuide.${targetWifiGuideListName}.${wifiIdx}.productGuideNetworkList`,
  });

  // 고객용 / 방문자용 네트워크 - 위치 유효성 검사 적용
  useEffect(() => {
    if (productGuideNetworkList.length > 0) {
      productGuideNetworkList.forEach((network, networkIdx: number) => {
        register(
          `productGuide.${targetWifiGuideListName}.${wifiIdx}.productGuideNetworkList.${networkIdx}.wifiName`,
          {
            required: true,
          },
        );

        register(
          `productGuide.${targetWifiGuideListName}.${wifiIdx}.productGuideNetworkList.${networkIdx}.wifiPassword`,
          {
            required: true,
          },
        );
      });
    }
  }, [productGuideNetworkList, register, targetWifiGuideListName, wifiIdx]);

  // 유효성 체크에 따른 에러 메세지 노출
  const visitorNetworkErrorText = (
    networkError: any, // hook-form erros 내용
    targetName: "customerWifiList" | "visitorWifiList",
  ) => {
    let getNetworkError; // 에러를 저장

    console.log("networkError", networkError);
    // 방문자용 일때
    if (targetName && targetName === "visitorWifiList") {
      getNetworkError = networkError?.visitorWifiList && networkError?.visitorWifiList[wifiIdx];
    } else {
      getNetworkError = networkError?.customerWifiList && networkError?.customerWifiList[wifiIdx];
    }

    // 에러안에 productGuideNetworkList length 가 있으면 에러처리
    if (getNetworkError) {
      if (
        getNetworkError?.productGuideNetworkList &&
        getNetworkError?.productGuideNetworkList.length > 0
      ) {
        return (
          <tr>
            <td className="validation-text font12">필수입력 항목입니다</td>
          </tr>
        );
      }
    }
  };

  return (
    <div className="flex-start mb10">
      <div className="minmax140">
        <p className="required">
          네트워크 이름/
          <br />
          비밀번호
        </p>
      </div>
      <table className="inner-table" width="100%">
        <thead>
          <tr>
            <th>
              <span>No</span>
            </th>
            <th>
              <span>네트워크 이름</span>
            </th>
            <th>
              <span>비밀번호</span>
            </th>
            <th>
              <span></span>
            </th>
          </tr>
        </thead>
        <tbody>
          {productGuideNetworkList.map(
            (network: ProductGuideNetwork & { id: string }, index: number) => (
              <tr key={network?.id}>
                <td width="100">
                  <div className="text-center">
                    <span>{index + 1}</span>
                  </div>
                </td>
                <td width="220">
                  <div className="flex-center-center">
                    <Controller
                      control={control}
                      name={`productGuide.${targetWifiGuideListName}.${wifiIdx}.productGuideNetworkList.${index}.wifiName`}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return <BaseInput value={value} name={name} onChange={onChange} />;
                      }}
                    ></Controller>
                  </div>
                </td>
                <td width="auto">
                  <div>
                    <span>
                      <Controller
                        control={control}
                        name={`productGuide.${targetWifiGuideListName}.${wifiIdx}.productGuideNetworkList.${index}.wifiPassword`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return <BaseInput value={value} name={name} onChange={onChange} />;
                        }}
                      ></Controller>
                    </span>
                  </div>
                </td>

                <td width="60">
                  <div className="flex-center-center">
                    {index === 0 ? (
                      <button
                        type="button"
                        className="base-add-btn"
                        onClick={() => {
                          append({
                            orderNums: productGuideNetworkList.length + 1,
                            wifiName: "",
                            wifiPassword: "",
                          });
                        }}
                      ></button>
                    ) : (
                      <button
                        type="button"
                        className="base-erase-btn"
                        onClick={() => {
                          remove(index);
                        }}
                      ></button>
                    )}
                  </div>
                </td>
              </tr>
            ),
          )}
          {errors?.productGuide &&
            visitorNetworkErrorText(errors?.productGuide, targetWifiGuideListName)}
        </tbody>
      </table>
    </div>
  );
};
export default FreeInternetNetwork;
