import moment from "moment";
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { WorkOrderListModel } from "src/api/workOrder/workOrder-types";
import { BaseTooltip } from "src/components";
import { PagePath } from "src/pages/product/details";
import { numberToStringWithComma, onChangeKoreanDays, YmdFormat } from "src/utils";
import { WorkOrderStatus, WorkOrderType } from "../../workOrder-types";

const WorkOrderListColumn = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: ({ row, value }: Cell<WorkOrderListModel>) => {
      const detailPath = PagePath.workOrder.detail.replace(":id", value);
      return (
        <Link to={detailPath} className="text-hilight">
          {value}
        </Link>
      );
    },
  },

  {
    Header: "그룹id",
    accessor: "workOrderSheetGroupId",
    width: 70,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return <span>{value || "-"}</span>;
    },
  },
  {
    Header: "워크시트 번호",
    accessor: "workOrderSheetNo",
    width: 140,
    Cell: ({ row, value }: Cell<WorkOrderListModel>) => {
      const detailPath = PagePath.workOrder.detail.replace(":id", String(row.original.id));
      return (
        <Link to={detailPath} className="text-hilight">
          {`${value.slice(0, 8)}-${value.slice(8, 13)}`}
        </Link>
      );
    },
  },
  {
    // Header: "건물/관리처",
    Header: "건물",
    accessor: "buildingName",
    width: 160,
    Cell: ({ row }: Cell<WorkOrderListModel>) => {
      const buildingName = row.original.buildingName;

      // if (buildingName && mgmtOfficeName) {
      if (buildingName) {
        return (
          <BaseTooltip
            contents={`${buildingName}`}
            isSingleLine={true}
            className="w-100 d-flex justfy-content-start"
          />
        );
      } else {
        return <span>-</span>;
      }
    },
  },

  {
    Header: "제목",
    accessor: "summary",
    width: 220,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return <BaseTooltip contents={value} className="w-100 d-flex justfy-content-start" />;
    },
  },
  {
    Header: "업무 시작일",
    accessor: "startDate",
    width: 120,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return (
        <div>
          <span>{value && moment(value).format(YmdFormat.YYYY_MM_DD)}</span>
          <span className="ml5">{`(${onChangeKoreanDays(value)})`}</span>
        </div>
      );
    },
  },
  {
    Header: "처리기한",
    accessor: "endDate",
    width: 120,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return (
        <div>
          <span>{value && moment(value).format(YmdFormat.YYYY_MM_DD)}</span>
          <span className="ml5">{`(${onChangeKoreanDays(value)})`}</span>
        </div>
      );
    },
  },
  {
    Header: "처리비용",
    accessor: "costSum",
    width: 120,
    Cell: ({ value, row }: Cell<WorkOrderListModel>) => {
      const costCount = row.original.costCount;
      const costSum = numberToStringWithComma(value);
      return (
        <div className="d-flex justify-contents-end w-100">
          <p className="pr5">
            {`${Number(costCount) > 1 ? `(${costCount}건)` : ""} ${
              costSum !== "0" ? costSum : "-"
            }` || "-"}
          </p>
        </div>
      );
    },
  },
  {
    Header: "담당자",
    accessor: "mgmtOfficerName",
    width: 120,
    Cell: ({ value }: Cell<WorkOrderListModel>) => (
      <div className="w-100 d-flex justfy-content-start">
        <span>{value}</span>
      </div>
    ),
  },
  {
    Header: "상태",
    accessor: "status",
    width: 90,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      let status = "";

      switch (value) {
        case WorkOrderStatus.OPEN:
          status = "접수";
          break;

        case WorkOrderStatus.CLOSED:
          status = "종료";
          break;
        case WorkOrderStatus.PROGRESS:
          status = "처리중";
          break;

        case WorkOrderStatus.COMPLETED:
          status = "처리 완료";
          break;

        case WorkOrderStatus.WAITING:
          status = "확인";
          break;

        case WorkOrderStatus.CANCELED:
          status = "취소";
          break;
      }

      return (
        <span
          className={`${
            (value === WorkOrderStatus.OPEN && "chip gray") || // 접수
            (value === WorkOrderStatus.WAITING && "chip green") || //확인
            (value === WorkOrderStatus.PROGRESS && "chip green") || //처리중
            (value === WorkOrderStatus.COMPLETED && "chip red") || //완료
            (value === WorkOrderStatus.CLOSED && "chip gray") || //종료
            (value === WorkOrderStatus.CANCELED && "chip gray") //취소
          }`}
        >
          {status}
        </span>
      );
    },
  },
  {
    Header: "지연 여부",
    accessor: "delay",
    width: 80,
    Cell: ({ row }: Cell<WorkOrderListModel>) => {
      const endDate = row.original.endDate;
      const today = moment().format("YYYY-MM-DD");
      const isAfter = moment(today).isAfter(endDate);
      const status = row.original.status;

      if (
        status === WorkOrderStatus.OPEN ||
        status === WorkOrderStatus.WAITING ||
        status === WorkOrderStatus.PROGRESS
      ) {
        return <span className={isAfter ? "chip red" : ""}>{isAfter ? "지연" : "-"}</span>;
      } else {
        return <span>-</span>;
      }
    },
  },
  {
    Header: "재처리 요청",
    accessor: "isReopened",
    width: 100,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return (
        <span className={value ? "chip red" : ""}>{value === true ? "재처리 요청" : "-"}</span>
      );
    },
  },
  {
    Header: "우선순위",
    accessor: "workOrderPriority",
    width: 90,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      let workOrderPriority = "";

      switch (value) {
        case "1":
          workOrderPriority = "낮음";
          break;

        case "2":
          workOrderPriority = "보통";
          break;

        case "3":
          workOrderPriority = "높음";
          break;

        case "4":
          workOrderPriority = "긴급";
          break;
      }

      return <span>{workOrderPriority}</span>;
    },
  },

  {
    Header: "업무구분",
    accessor: "workOrderType",
    width: 100,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      let workOrderType = "";

      if (value === WorkOrderType.SPACE) {
        workOrderType = "공간 관리";
      } else {
        workOrderType = "서비스 관리";
      }

      return <span>{workOrderType}</span>;
    },
  },
  {
    Header: "등록일",
    accessor: "createdDate",
    width: 100,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return <BaseTooltip contents={value} type="date" />;
    },
  },
  {
    Header: "등록자",
    accessor: "createdBy",
    width: 150,
    Cell: ({ value }: Cell<WorkOrderListModel>) => (
      <div className="w-100 text-left ellipsis2"><p>{value}</p></div>    ),
  },
  {
    Header: "파트너",
    accessor: "partnerId",
    width: 90,
    Cell: ({ row, value }: Cell<WorkOrderListModel>) => {
      return (
        <Link
          to={PagePath.partner.detail.replace(":id", String(row.original.partnerId))}
          target="_blank"
          className="text-hilight"
        >
          <span>{value}</span>
        </Link>
      );
    },
  },
];

export default WorkOrderListColumn;
