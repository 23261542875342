import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "src/api";
import { ApiResponse } from "../public-types";
import {
  PatchVisitorResponseData,
  PostVisitorResponseData,
  VisitorListModel,
  VisitorListQueryParams,
  VisitorModel,
} from "./visitor-types";

/**
 * CCV50. 방문자 목록 페이지 조회
 * @param axios
 * @param params
 * @returns
 */
export async function getVisitorsAsync(
  axios: AxiosInstance,
  params?: VisitorListQueryParams,
): Promise<AxiosResponse<ApiResponse<{ content?: Array<VisitorListModel> }>>> {
  return await axios.get<ApiResponse<{ content?: Array<VisitorListModel> }>>(ApiPath.visitor.list, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/**
 * CCA51b. 방문자 상세 조회
 * 방문 신청한 group 별로 조회되기 때문에, response 는 방문자 목록 배열
 * @param axios
 * @param params
 * @returns
 */
export async function getVisitorsByVisitApplyNumberAsync(
  axios: AxiosInstance,
  params?: {
    visitApplyNumber: string;
  },
): Promise<AxiosResponse<ApiResponse<{ contractVisitor?: Array<VisitorModel> }>>> {
  if (!params?.visitApplyNumber) {
    throw Error("visitApplyNumber is not found.");
  }
  const path = ApiPath.visitor.detail.replace("{visitApplyNumber}", params.visitApplyNumber);
  return axios.get<ApiResponse<{ contractVisitor?: Array<VisitorModel> }>>(path);
}

/**
 * CCA52. 방문자 등록
 * @param axios
 * @param params
 * @returns
 */
export async function postVisitorsAsync(
  axios: AxiosInstance,
  params?: {
    contractManageId: number;
    visitors: Array<VisitorModel>;
    partnerId: number;
  },
): Promise<AxiosResponse<ApiResponse<PostVisitorResponseData>>> {
  if (!params?.contractManageId) {
    throw Error("params?.contractManageId is not found.");
  }
  const path = ApiPath.visitor.add.replace(
    "{contractManageId}",
    params.contractManageId.toString(),
  );
  return await axios.post(path, {
    contractVisitorList: params?.visitors || [],
    partnerId: params?.partnerId,
  });
}

/**
 * CCA53b. 방문자 수정
 * @param axios
 * @param params
 * @returns
 */
export async function patchVisitorsAsync(
  axios: AxiosInstance,
  params?: {
    visitApplyNumber: string;
    contractVisitorList: Array<VisitorModel>;
    partnerId: number;
  },
): Promise<AxiosResponse<ApiResponse<PatchVisitorResponseData>>> {
  if (!params?.visitApplyNumber) {
    throw Error("visitApplyNumber is not found.");
  }
  const path = ApiPath.visitor.edit.replace("{visitApplyNumber}", params.visitApplyNumber);
  return await axios.patch(path, {
    contractVisitorList: params?.contractVisitorList || [],
    partnerId: params?.partnerId,
  });
}

/**
 * CCV50. 방문자 목록 페이지 조회
 * @param axios
 * @param params
 * @returns
 */
export async function getVisitorListAsync(
  axios: AxiosInstance,
  params?: VisitorListQueryParams,
): Promise<AxiosResponse<ApiResponse<{ content?: Array<VisitorListModel> }>>> {
  return await axios.get<ApiResponse<{ content?: Array<VisitorListModel> }>>(ApiPath.visitor.list, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}
