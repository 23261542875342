import React from "react";
import { MemberModel } from "src/api/member/member-types";
import { Select } from "src/api/public-types";
import { BaseButton, BaseSelect } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";

type Props = {
  memberDetail: MemberModel | undefined;
  stateOptions: { value: string; label: string }[];
  setStateValue: Function;
  selectedAccountStatus: string;
  setStatusChangeModal: Function;
  onClick: Function;
};
const ChangeAccountStatusModal = ({
  memberDetail,
  stateOptions,
  setStateValue,
  selectedAccountStatus,
  setStatusChangeModal,
  onClick,
}: Props) => {
  return (
    <BaseAbstractModal isOpen={true} opacity={0.75}>
      <section className="base-abstract-modal__title">
        <h1>계정 상태 변경</h1>
      </section>
      <section className="base-abstract-modal__contents px30">
        <table className="inner-table change-status-modal" width="940">
          <thead>
            <tr>
              <th className="id">id</th>
              <th>회원 번호</th>
              <th>휴대폰 번호</th>
              <th>이메일</th>
              <th className="status">계정 상태</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>{memberDetail?.id}</p>
              </td>
              <td>
                <p>{memberDetail?.memberNo}</p>
              </td>
              <td>
                <p>{memberDetail?.phoneNumber}</p>
              </td>
              <td>
                <p>{memberDetail?.email}</p>
              </td>
              <td>
                <div className="text-left">
                  <BaseSelect
                    stateOptions={stateOptions}
                    setStateValue={setStateValue}
                    value={selectedAccountStatus}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="base-abstract-modal__btn-wrap">
        <BaseButton
          type="button"
          title={"취소"}
          className="color-white flex-center-center"
          onClick={() => setStatusChangeModal({ isOpen: false })}
        />
        <BaseButton
          type="submit"
          title={"확인"}
          className="flex-center-center"
          onClick={onClick}
          disabled={selectedAccountStatus === ""}
        />
      </section>
    </BaseAbstractModal>
  );
};

export default ChangeAccountStatusModal;
