import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "src/api";
import { ApiResponse } from "../public-types";
import {
  AccessGroupModel,
  AccessGroupQueryParams,
  AccessGroupSyncResponseData,
  AccessGroupsModel,
  CardModel,
  EventModel,
  EventQueryParams,
  MbMemberCsn,
  ProductInputModel,
  ProductModel,
  ProductSyncResponseData,
  UserAccessModel,
  UserEventQueryParams,
  UserSyncResponseData,
  VisitorEventQueryParams
} from "./ac-types";

/*
  AC상품 정보 조회
*/
export async function getProductAsync(
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<{ product: ProductModel }>>> {
  const path = ApiPath.ac.productDetail.replace("{id}", params!.id.toString());
  return await axios.get<ApiResponse<{ product: ProductModel }>>(path);
}

/*
  AC상품 정보 수정
*/
export async function putProductAsync(
  axios: AxiosInstance,
  params?: {
    id: number;
    data: ProductInputModel;
  },
): Promise<AxiosResponse<ApiResponse<{ product: ProductModel }>>> {
  const path = ApiPath.ac.productEdit.replace("{id}", params!.id.toString());
  return await axios.put<ApiResponse<{ product: ProductModel }>>(path, params?.data);
}

/*
  AC상품 정보 삭제
*/
export async function deleteProductAsync(
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<ProductModel>>> {
  const path = ApiPath.ac.productDelete.replace("{id}", params!.id.toString());
  return await axios.delete<ApiResponse<ProductModel>>(path);
}

/*
  AC상품 동기화
*/
export async function syncProductAsync(
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<ProductSyncResponseData>>> {
  const path = ApiPath.ac.productSync.replace("{id}", params!.id.toString());
  return await axios.put<ApiResponse<ProductSyncResponseData>>(path);
}

/*
  액세스그룹 조회
*/
export async function getAccessGroupsAsync(
  axios: AxiosInstance,
  params?: AccessGroupQueryParams,
): Promise<AxiosResponse<ApiResponse<AccessGroupsModel>>> {
  const path = ApiPath.ac.accessGroupList;
  return await axios.get<ApiResponse<AccessGroupsModel>>(path, { params });
}

/*
  액세스그룹 상세 조회
*/
export async function getAccessGroupAsync(
  axios: AxiosInstance,
  params?: {
    accessGroupId: number;
    apiServerId: number;
  },
): Promise<AxiosResponse<ApiResponse<{ accessGroup: AccessGroupModel }>>> {
  const path = ApiPath.ac.accessGroupDetail
    .replace("{accessGroupId}", params!.accessGroupId.toString())
    .replace("{apiServerId}", params!.apiServerId.toString());
  return await axios.get<ApiResponse<{ accessGroup: AccessGroupModel }>>(path);
}

/*
  액세스그룹 동기화
*/
export async function syncAccessGroupsAsync(
  axios: AxiosInstance,
): Promise<AxiosResponse<ApiResponse<AccessGroupSyncResponseData>>> {
  const path = ApiPath.ac.accessSync;
  return await axios.put<ApiResponse<AccessGroupSyncResponseData>>(path);
}

/*
  맴버 엑세스 정보 조회
*/
export async function getUserAccessAsync(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
  },
): Promise<AxiosResponse<ApiResponse<{ user: UserAccessModel }>>> {
  const path = ApiPath.ac.user.replace("{memberNo}", params!.memberNo);
  return await axios.get<ApiResponse<{ user: UserAccessModel }>>(path);
}

/*
  맴버 QR 정보 조회
*/
export async function getUserCardAsync(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
  },
): Promise<AxiosResponse<ApiResponse<{ qr: CardModel }>>> {
  const path = ApiPath.ac.cardDetail.replace("{memberNo}", params!.memberNo);
  return await axios.get<ApiResponse<{ qr: CardModel }>>(path);
}

/*
  맴버 QR 갱신
*/
export async function postUserCardAsync(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
  },
): Promise<AxiosResponse<ApiResponse<CardModel>>> {
  const path = ApiPath.ac.cardNew.replace("{memberNo}", params!.memberNo);
  return await axios.post<ApiResponse<CardModel>>(path);
}

/*
  맴버 활성화
*/
export async function putUserActiveAsync(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
  },
): Promise<AxiosResponse<ApiResponse<number>>> {
  const path = ApiPath.ac.userActive.replace("{memberNo}", params!.memberNo);
  return await axios.put<ApiResponse<number>>(path);
}

/*
  맴버 비활성화
*/
export async function putUserDeactiveAsync(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
  },
): Promise<AxiosResponse<ApiResponse<number>>> {
  const path = ApiPath.ac.userDeactive.replace("{memberNo}", params!.memberNo);
  return await axios.put<ApiResponse<number>>(path);
}

/*
  맴버 이벤트 조회
*/
export async function getUserEventsAsync(
  axios: AxiosInstance,
  params?: UserEventQueryParams,
): Promise<AxiosResponse<ApiResponse<{ rows: EventModel[] }>>> {
  const path = ApiPath.ac.eventList.replace("{memberNo}", params!.memberNo!);
  return await axios.get<ApiResponse<{ rows: EventModel[] }>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/*
  맴버 권한 업데이트
*/
export async function putUserSyncAsync(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
  },
): Promise<AxiosResponse<ApiResponse<UserSyncResponseData>>> {
  const path = ApiPath.ac.userSync.replace("{memberNo}", params!.memberNo);
  return await axios.put<ApiResponse<UserSyncResponseData>>(path);
}

/*
  방문자 엑세스 정보 조회
*/
export async function getVisitorAccessAsync(
  axios: AxiosInstance,
  params?: {
    visitorId: number;
  },
): Promise<AxiosResponse<ApiResponse<{ user: UserAccessModel }>>> {
  const path = ApiPath.visitor.ac.access.replace("{visitorId}", params!.visitorId.toString());
  return await axios.get<ApiResponse<{ user: UserAccessModel }>>(path);
}

/*
  방문자 QR 정보 조회
*/
export async function getVisitorCardAsync(
  axios: AxiosInstance,
  params?: {
    visitorId: number;
  },
): Promise<AxiosResponse<ApiResponse<{ qr: CardModel }>>> {
  const path = ApiPath.visitor.ac.qr.replace("{visitorId}", params!.visitorId.toString());
  return await axios.get<ApiResponse<{ qr: CardModel }>>(path);
}

/*
  방문자 QR 갱신
*/
export async function postVisitorCardAsync(
  axios: AxiosInstance,
  params?: {
    visitorId: number;
  },
): Promise<AxiosResponse<ApiResponse<CardModel>>> {
  const path = ApiPath.visitor.ac.qrNew.replace("{visitorId}", params!.visitorId.toString());
  return await axios.post<ApiResponse<CardModel>>(path);
}

/*
  방문자 활성화
*/
export async function putVisitorActiveAsync(
  axios: AxiosInstance,
  params?: {
    visitorId: number;
  },
): Promise<AxiosResponse<ApiResponse<number>>> {
  const path = ApiPath.visitor.ac.active.replace("{visitorId}", params!.visitorId.toString());
  return await axios.put<ApiResponse<number>>(path);
}

/*
  방문자 비활성화
*/
export async function putVisitorDeactiveAsync(
  axios: AxiosInstance,
  params?: {
    visitorId: number;
  },
): Promise<AxiosResponse<ApiResponse<number>>> {
  const path = ApiPath.visitor.ac.deactive.replace("{visitorId}", params!.visitorId.toString());
  return await axios.put<ApiResponse<number>>(path);
}

/*
  방문자 이벤트 조회
*/
export async function getVisitorEventsAsync(
  axios: AxiosInstance,
  params?: VisitorEventQueryParams,
): Promise<AxiosResponse<ApiResponse<{ rows: EventModel[] }>>> {
  const path = ApiPath.visitor.ac.events.replace("{visitorId}", params!.visitorId!.toString());
  return await axios.get<ApiResponse<{ rows: EventModel[] }>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/*
  방문자 권한 업데이트
*/
export async function putVisitorSyncAsync(
  axios: AxiosInstance,
  params?: {
    visitorId: number;
  },
): Promise<AxiosResponse<ApiResponse<UserSyncResponseData>>> {
  const path = ApiPath.visitor.ac.sync.replace("{visitorId}", params!.visitorId.toString());
  return await axios.put<ApiResponse<UserSyncResponseData>>(path);
}

/*
   출입 거부 이벤트 조회
*/
export async function getDenialEventsAsync(
  axios: AxiosInstance,
  params?: EventQueryParams,
): Promise<AxiosResponse<ApiResponse<{ rows: EventModel[] }>>> {
  const path = ApiPath.ac.denialEventList;
  return await axios.get<ApiResponse<{ rows: EventModel[] }>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/*
  고정 Csn 조회
*/
export async function getFixedCsn(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
  },
): Promise<AxiosResponse<ApiResponse<MbMemberCsn>>> {
  const path = ApiPath.ac.fixedCsn.replace("{memberNo}", params!.memberNo.toString());
  return await axios.get<ApiResponse<MbMemberCsn>>(path);
}

/*
   고정 Csn 수정
*/
export async function updateFixedCsn(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
    csn: string;
  },
): Promise<AxiosResponse<ApiResponse<MbMemberCsn>>> {
  const path = ApiPath.ac.fixedCsn.replace("{memberNo}", params!.memberNo.toString());
  return await axios.put<ApiResponse<MbMemberCsn>>(path, params);
}
/*
   고정 Csn 삭제
*/
export async function deleteFixedCsn(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
  },
): Promise<AxiosResponse<ApiResponse<MbMemberCsn>>> {
  const path = ApiPath.ac.fixedCsn.replace("{memberNo}", params!.memberNo.toString());
  return await axios.delete<ApiResponse<MbMemberCsn>>(path);
}
