import { useEffect, useState } from "react";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { getVisitorEventsAsync } from "src/api/access/ac-api";
import { EventModel, VisitorEventQueryParams } from "src/api/access/ac-types";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { BaseModal, BasePagination } from "src/components";
import { UserEventColumns } from "src/pages/member/detail/components/accessInfo/columns/UserEventColumns";

type Props = {
  visitorId: number;
  showModal: boolean;
  setShowModal: Function;
};

const VisitorEventListModal = ({ visitorId, showModal, setShowModal }: Props) => {
  const { executeAsync: getEventList } = useApiOperation(getVisitorEventsAsync);

  // 출입 내역 목록
  const [eventList, setEventList] = useState<EventModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [params, setParams] = useState<VisitorEventQueryParams>({
    visitorId,
    page: 0,
    size: 20
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: UserEventColumns,
      data: eventList
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (showModal) {
      getEventList(params).then((response) => {
        setEventList(response?.data?.data.rows || null);
        setPageMeta(response?.data?.meta?.pageMeta);
      });
    }
  }, [getEventList, params, showModal]);

  return (
    <div>
      <BaseModal
        className="dialog-modal"
        isOpen={showModal}
        btnRightTitle="닫기"
        onClick={() => {
          setShowModal(false);
        }}        
        onClose={() => {
          setShowModal(false);
        }}
      >
        <>
          <div className="modal-title">
            <div className="left-area">
              <span>출입 내역</span>
            </div>
          </div>
          {/* table */}
          <div className="contents-container__table">
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <div
                      {...row.getRowProps()}
                      className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <BasePagination
              pageIndex={params.page || 0}
              totalPages={pageMeta?.totalPages || 0}
              goPage={(page: number) => {
                setParams({ ...params, ...{ page } });
              }}
            />
          </div>
        </>
      </BaseModal>
    </div>
  );
};

export default VisitorEventListModal