import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { CommonFacilityModel } from "src/api/building/building-types";
import { getCommonfacilityAsync } from "src/api/building/commonfacility-api";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import {
  changeReservationStatusAsync,
  getReservationListAsync,
} from "src/api/reservation/reservation-api";
import { ReservationListModel, ReservationListParams } from "src/api/reservation/reservation-types";
import { BaseButton, BaseModal, ContentsIdSection } from "src/components";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import { Partner } from "src/types/partner";
import { Modal, ReservationState, getStatusText } from "../../reservation-types";
import {
  DeskSection,
  MeetingRoomSection,
  RefreshRoomSection,
  ReservationHistorySection,
} from "./components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";

// 공용공간 예약 목록 조회를 위해서 필요한 startDate, endDate 값 고정

/* 
  공용공간 예약 > 상세 > 기본 정보
 */
const BasicInfoDetail = () => {
  const navigate = useNavigate();

  // 공용공간 예약 상세 id (url path variable)
  const { id } = useParams();
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();
  // 토스트
  const { openToast } = useToastContext();

  // 공간상품 예약 목록 조회 api
  const { executeAsync: getReservationList } = useApiOperation(getReservationListAsync);

  // 공용공간 예약 상태변경 api
  const { executeAsync: changeReservationStatus } = useApiOperation(changeReservationStatusAsync);

  // 건물 공용공간 상세 조회 api
  const { executeAsync: getCommonfacility } = useApiOperation(getCommonfacilityAsync);

  // 파트너 조회
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  // 공간상품 예약 상세
  const [reservation, setReservation] = useState<ReservationListModel | null>(null);

  // 건물 공용공간 상세
  const [commonFacility, setCommonFacility] = useState<CommonFacilityModel | null>(null);

  // 상단 오른쪽 라벨들
  const contentsIdLables: Array<{ key: string; value?: string }> = useMemo(() => {
    let labels = [{ key: "예약ID", value: reservation?.id || "" }];

    if (reservation?.parentId) {
      labels.push({ key: "그룹ID", value: reservation?.parentId });
    }
    return labels;
  }, [reservation]);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });
  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 파트너
  const [partner, setPartner] = useState<Partner>();

  // 파트너 조회
  const fetchPartner = async (id: string) => {
    setLoadingBar(true);
    const response = await getPartnerAsync({ id });
    if (response.status >= 200 && response.status <= 299) {
      const partner = response.data.data.partner;
      setPartner(partner);
    }
    setLoadingBar(false);
  };

  // 공용공간 예약 상세 후 데이터 바인딩
  const fetchReservation = useCallback(
    async (reservationId: string) => {
      setLoadingBar(true);

      // 공용공간 예약 목록 api 로 상세 조회 대신함
      const reservationListParams: ReservationListParams = {
        page: 0,
        size: 1,
        id: reservationId,
      };
      const { data } = await getReservationList(reservationListParams);

      const _reservation: ReservationListModel | null = data?.data?.content[0] || null;
      if (_reservation) {
        setReservation(_reservation);
        await fetchPartner(_reservation.partnerId);
        if (!_reservation.facility.building.id) {
          throw Error("reservation.facility.building.id is not found.");
        }
        if (!_reservation.facility.id) {
          throw Error("reservation.facility.id is not found.");
        }
        // 건물 공용공간 상세 조회
        const { data: commonFacilityData } = await getCommonfacility({
          buildingId: String(_reservation.facility.building.id),
          facilityId: String(_reservation.facility.id),
        });

        if (commonFacilityData?.data?.content) {
          const facilityData = commonFacilityData?.data?.content;
          console.log(facilityData);
          setCommonFacility(facilityData);
        }
      }
      setLoadingBar(false);
    },
    [getReservationList, setLoadingBar, getCommonfacility],
  );

  useEffect(() => {
    if (id) {
      fetchReservation(id);
    }
  }, [id, fetchReservation]);

  // Modal 확인 버튼 클릭
  const clickModalConfirm = useCallback(async () => {
    // 모달 닫기
    setConfirmModal({ isOpen: false });

    if (!id) {
      throw Error("reservationId is not found");
    }

    if (confirmModal.type === "CHANGE_STATE") {
      // 상태 변경
      if (!confirmModal.payload?.status) {
        throw Error("confirmModal.payload.status is not found");
      }
      const { data: changeReservationStatusResultData } = await changeReservationStatus({
        reservationId: id,
        status: confirmModal.payload?.status, // 취소 또는 예약완료로 상태 변경
      });
      if (changeReservationStatusResultData?.data?.reservation) {
        openToast(`정상적으로 변경 되었습니다.`);
        await fetchReservation(id);
      }
    }
  }, [
    changeReservationStatus,
    confirmModal.payload?.status,
    confirmModal.type,
    fetchReservation,
    id,
    openToast,
  ]);
  useTitleOperation(reservation?.id);

  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection
          title="기본정보"
          status={getStatusText(reservation?.status.code)}
          rightLables={contentsIdLables}
        />

        {/* 기본정보 */}
        <article className="mb30">
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">상태</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <div className="mr10">
                  <span className="font14">{getStatusText(reservation?.status.code)}</span>
                </div>
              </div>
            </div>
            <div className="contents-container__grid-index">
              <p className="">신청번호</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <Link
                  to={`${PagePath.contract.detail.replace(
                    ":id",
                    String(reservation?.contract.id) || "",
                  )}`}
                  target="_blank"
                  className="text-hilight"
                  rel="noopener noreferrer"
                >
                  {reservation?.contract.applyNumber}
                </Link>
              </div>
            </div>
            <div className="contents-container__grid-index">
              <p className="">파트너</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <p>{partner ? `${partner?.name}(${partner?.code})` : ""}</p>
              </div>
            </div>
          </section>
        </article>

        {/* 예약 내역 */}
        <ReservationHistorySection reservation={reservation} />

        {/* 회의실 */}
        {reservation?.facility.type === "MEETING_ROOM" && (
          <MeetingRoomSection reservation={reservation} commonFacility={commonFacility} />
        )}

        {/* 좌석 */}
        {reservation?.facility.type === "DESK" && <DeskSection commonFacility={commonFacility} />}

        {/* 편의시설 */}
        {reservation?.facility.type === "REFRESH_ROOM" && (
          <RefreshRoomSection commonFacility={commonFacility} />
        )}

        <AdminMemoV3
          serviceId={Number(id)}
          serviceType={ServiceTypes.SERVICE_RESERVATION}
          partnerId={reservation?.partnerId!}
        ></AdminMemoV3>
      </div>

      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <BaseButton
            title="목록으로"
            className="color-white size-large"
            onClick={() => navigate(PagePath.reservation.list)}
          />
        </div>
      </div>

      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
      >
        <p>{confirmModal.message}</p>
      </BaseModal>

      {/* 확인버튼만 있는 alert 모달 */}
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => setAlertModal({ isOpen: false })}
      >
        <p>{alertModal.message}</p>
      </BaseModal>
    </div>
  );
};

export default BasicInfoDetail;
