import { useCallback } from "react";
import { numberToStringWithComma } from "src/utils";

const TableCurrencyCell = ({ title, value1, value2 }: any) => {
  const changeCurrency = useCallback((value: string) => {
    return numberToStringWithComma(Number(value));
  }, []);

  let base = changeCurrency(String(value1));
  let ori = changeCurrency(String(value2));
  if (value1 === undefined || Number(value1) === 0) {
    base = ori;
  }
  return (
    <>
      <tr>
        <td width="180">
          <div className="flex-center pl16">
            <span className="text-primary3">{title}</span>
          </div>
        </td>
        <td>
          <div className="w-100 flex-center-start">
            {base} <span className="ml8 pre-discount">({ori})</span>
          </div>
        </td>
      </tr>
    </>
  );
};
export default TableCurrencyCell;
