import { range } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  BuildingCommonFacilityDeskGroupModel,
  BuildingModel,
  CommonFacilityModel,
} from "src/api/building/building-types";
import { postCommonfacilityAsync } from "src/api/building/commonfacility-api";
import { getEslProductDetailAsync } from "src/api/esl/esl-api";
import { EslProduct } from "src/api/esl/esl-types";
import { useApiOperation } from "src/api/hooks";
import { MediaFile } from "src/api/public-types";
import { BaseButton, BaseModal, BaseToggle, BaseTooltip } from "src/components";
import ImagesUpdate from "src/components/ImagesUpdate";
import {
  DeskSpace,
  floorOptions,
  FrontDeskSpace,
  Modal,
  SelectOption,
  sortingDeskGroupList,
} from "src/pages/building/building-types";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { useLoadingBarContext, useToastContext } from "src/pages/hooks";
import { SaveDeskTable } from "../components";

type Props = {
  building: BuildingModel | null;
  fetchBuilding: (buildingId: number) => void;
};

type FormData = {
  isUsedDeskSpace: boolean;
};

interface EslLabel extends EslProduct {
  locationCode: string; // 공간유형 채번
}

// 좌석 컴포넌트
const DeskSection = ({ building, fetchBuilding }: Props) => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();
  // 토스트
  const { openToast } = useToastContext();

  // 건물 공용공간 관리(등록/수정/삭제) API
  const { executeAsync: postCommonfacility } = useApiOperation(postCommonfacilityAsync, {
    doNotErrorHandleModal: true,
  });

  // ESL 데이터 상세 조회 api
  const { executeAsync: getEslProductDetail } = useApiOperation(getEslProductDetailAsync, {
    doNotErrorHandleModal: true,
  });

  // 배치도 이미지
  const [mediaList, setMediaList] = useState<Array<MediaFile>>(
    building?.buildingCommonFacility?.deskSpace?.mediaList || [],
  );

  // 조회한 ESL 데이터 목록
  const [eslLabels, setEslLabels] = useState<Array<EslLabel>>([]);

  // UI 에서 사용할 좌석목록 배열
  const deskSpaces: Array<DeskSpace> = useMemo(() => {
    // UI 에서 사용할 좌석목록 배열
    const deskSpaces: Array<DeskSpace> = [];

    // 건물 상세조회 api 에서 내려준 좌석 목록 (정렬후)
    const deskGroupList: Array<BuildingCommonFacilityDeskGroupModel> = sortingDeskGroupList(
      building?.buildingCommonFacility?.deskSpace?.deskGroupList || [],
    );

    if (deskGroupList.length > 0) {
      for (let i = 0; i < deskGroupList.length; i++) {
        const commonFacilityDeskGroup: BuildingCommonFacilityDeskGroupModel = deskGroupList[i];

        const isGround: boolean = commonFacilityDeskGroup?.isGround === true; // 지상여부
        const floorNum: number = commonFacilityDeskGroup?.floorNum || 1; // 층
        const floor = `${isGround ? "" : "-"}${floorNum}`;

        // 좌석 그룹
        const deskGroup: DeskSpace = {
          type: "GROUP",
          deskGroupId: commonFacilityDeskGroup?.id,
          floor,
          deskGroupName: commonFacilityDeskGroup.groupName,
          deskGroupDescription: commonFacilityDeskGroup.description,
          deskChartDescription: commonFacilityDeskGroup.deskChartDescription,
          isGray: true,
        };
        deskSpaces.push(deskGroup);

        // 좌석
        (commonFacilityDeskGroup?.deskList || []).forEach(
          (commonFacilityDesk: CommonFacilityModel) => {
            const desk: DeskSpace = {
              type: "DESK",
              deskGroupId: deskGroup.deskGroupId,
              deskId: Number(commonFacilityDesk.id),
              deskName: commonFacilityDesk.facilityName,
              locationCode: commonFacilityDesk.locationCode,
              centerX: commonFacilityDesk.centerX,
              centerY: commonFacilityDesk.centerY,
            };
            deskSpaces.push(desk);
          },
        );
      }
    }
    return deskSpaces;
  }, [building]);

  // 건물 > 기본정보 > 규모 범위 내의 층 (위치) 옵션
  const rangeFloorOptions: Array<SelectOption> = useMemo(() => {
    // 건물 > 기본정보 > 규모 범위
    const floorRange = range(
      Number(building?.undergroundFloorNums || 0) === 0
        ? 0
        : Number(building?.undergroundFloorNums || 0) * -1,
      Number(building?.floorNums || 0) + 1,
    );
    const _rangeFloorOptions: Array<SelectOption> = [];
    for (let i = 0; i < floorRange.length; i++) {
      const floor: number = floorRange[i];
      if (floor !== 0) {
        _rangeFloorOptions.push({
          value: String(floor || ""),
          label:
            floorOptions.find((v: SelectOption) => v.value === String(floor || ""))?.label || "",
        });
      }
    }
    if (_rangeFloorOptions.length === 0) {
      return floorOptions;
    } else {
      return _rangeFloorOptions.sort(
        (a: SelectOption, b: SelectOption) => Number(b.value || 0) - Number(a.value || 0),
      );
    }
  }, [building]);

  // useForm defaultValues
  const defaultValues: FormData = useMemo(() => {
    return {
      isUsedDeskSpace: false,
    };
  }, []);

  const { control, getValues, setValue } = useForm<FormData>({
    defaultValues,
  });

  // esl 라벨 목록 조회
  const fetchAllLabels = useCallback(async (locationCodes: Array<string>) => {
    try {
      if (!locationCodes || locationCodes.length === 0) {
        throw Error("locationCodes.length === 0");
      }

      const eslLabels: Array<EslLabel> = [];
      for (let i = 0; i < locationCodes.length; i++) {
        const locationCode = locationCodes[i];
        const result = await getEslProductDetail({ productId: locationCode });
        if (result?.data?.data && result.status >= 200 && result.status <= 299) {
          if (result?.data?.data?.product) {
            // esl 라벨이 연동되어있을 경우만 조회됨
            eslLabels.push({
              ...result?.data?.data?.product,
              ...{
                locationCode,
              },
            });
          }
        }
      }
      setEslLabels(eslLabels);
    } catch (error) {
      // 에러 발생해도 무시
      console.debug("fetchAllLabels error", error);
    }
  }, []);

  const fetchDeskGroup = useCallback(async () => {
    const deskGroupList = building?.buildingCommonFacility?.deskSpace?.deskGroupList || [];
    const mediaList = building?.buildingCommonFacility?.deskSpace?.mediaList || [];

    // locaiotn code 로 라벨 목록 조회
    const locationCodes: Array<string> = [];
    for (let i = 0; i < deskGroupList.length; i++) {
      const deskList = deskGroupList[i].deskList || [];
      for (let j = 0; j < deskList.length; j++) {
        const locationCode = deskList[j].locationCode;
        if (!!locationCode) {
          locationCodes.push(locationCode);
        }
      }
    }
    if (locationCodes.length > 0) {
      fetchAllLabels(locationCodes);
    }

    setMediaList(mediaList); // 배치도

    // 좌석 목록이 있거나, 좌석의 배치도 이미지 목록이 있을 경우, 좌석 토글 활성화
    if (deskGroupList.length > 0 || mediaList.length > 0) {
      setValue("isUsedDeskSpace", true);
    } else {
      setValue("isUsedDeskSpace", false);
    }
  }, [building, fetchAllLabels, setValue]);

  // 수정시 api 에서 받아온 building 정보로 setValue 처리
  useEffect(() => {
    if (building) {
      fetchDeskGroup();
    }
  }, [building, fetchDeskGroup]);

  // 좌석 입력 정보
  const [deskSpace, setDeskSpace] = useState<DeskSpace>({
    type: "GROUP",
    floor: "1",
  });

  // 수정 버튼 클릭
  const clickEdit = useCallback((rowData: DeskSpace) => {
    // 좌석 input form data 바인딩
    setDeskSpace(rowData);
  }, []);

  // 삭제 버튼 클릭
  const clickDelete = useCallback(
    (rowData: DeskSpace) => {
      // 삭제 가능여부
      let enabledDelete = true;
      if (rowData.type === "GROUP") {
        // 해당 좌석 그룹 하위에 좌석이 있을 경우 층은 삭제 불가능
        const deskList = deskSpaces.filter(
          (d: DeskSpace) => d.type === "DESK" && d.deskGroupId === rowData.deskGroupId,
        );
        enabledDelete = deskList.length === 0;
      }

      if (enabledDelete) {
        setConfirmModal({
          message: "삭제하시겠습니까?",
          isOpen: true,
          type: "DESK_DELETE",
          payload: rowData,
        });
      } else {
        setAlertModal({ message: "해당 좌석 그룹에 좌석이 있습니다.", isOpen: true });
      }
    },
    [deskSpaces],
  );

  // 배치도 저장 버튼 클릭
  const clickSaveLayout = useCallback(() => {
    setConfirmModal({
      message: "저장하시겠습니까?",
      isOpen: true,
      type: "LAYOUT_SAVE",
    });
  }, []);

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "deskGroupId",
        width: 60,
        Cell: ({ row, value }: any) => {
          const isGroupRow = row?.original?.type === "GROUP";
          const deskId = row?.original?.deskId || "";
          return isGroupRow ? value : deskId;
        },
      },
      {
        Header: "위치",
        accessor: "floor",
        width: 80,
        Cell: ({ value }: any) => {
          const floor = floorOptions.find((option: SelectOption) => option.value === value);
          return floor?.label || "";
        },
      },
      {
        Header: "좌석 그룹 명",
        accessor: "deskGroupName",
        width: 100,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "설명",
        accessor: "deskGroupDescription",
        width: 180,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "좌석 수량",
        accessor: "deskCount",
        width: 100,
        Cell: ({ row, data, value }: any) => {
          const isGroupRow = row?.original?.type === "GROUP";
          if (isGroupRow) {
            // 데스크 그룹의 경우, 데스크 합계 갯수를 보여준다
            const deskList = (data || []).filter(
              (d: DeskSpace) => d.type === "DESK" && d.deskGroupId === row?.original?.deskGroupId,
            );
            return <div>{deskList.length}</div>;
          } else {
            return <div className="ic_ho"></div>;
          }
        },
      },
      {
        Header: "좌석 명",
        accessor: "deskName",
        width: 120,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "Location code",
        accessor: "locationCode",
        width: 120,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "ESL",
        accessor: "eslAddress",
        width: 100,
        Cell: ({ row }: any) => {
          if (!!row?.original?.locationCode && eslLabels.length > 0) {
            const findLabel = eslLabels.find(
              (eslLabel: EslLabel) => eslLabel.locationCode === row?.original?.locationCode,
            );
            if (findLabel) {
              return <BaseTooltip contents={(findLabel.assignedLabelCodes || []).join(",")} />;
            }
          }
          return "";
        },
      },
    ],
    [clickEdit, clickDelete, eslLabels],
  );

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // form reset
  const resetForm = useCallback((type?: "GROUP" | "DESK") => {
    if (type) {
      setDeskSpace({
        type,
        deskGroupId: undefined,
        deskId: undefined,
        floor: "1",
        deskGroupName: undefined,
        deskGroupDescription: undefined,
        locationCode: "",
        deskName: undefined,
        isGray: undefined,
      });
    }
  }, []);

  // 좌석 저장 (좌석 그룹 or 좌석)
  const saveDesk = useCallback(
    async (submitData: DeskSpace) => {
      try {
        if (!building?.id) {
          throw Error("building.id is not found.");
        }

        setLoadingBar(true);

        // 주차장/회의실/좌석/편의시설 기본 값
        const meetingRoomList = building?.buildingCommonFacility?.meetingRoomList || [];
        const parkingLotList = building?.buildingCommonFacility?.parkingLotList || [];

        const deskSpace = {
          deskGroupList: building?.buildingCommonFacility?.deskSpace?.deskGroupList || [],
          mediaList: building?.buildingCommonFacility?.deskSpace?.mediaList || [],
        };
        const refreshRoomList = building?.buildingCommonFacility?.refreshRoomList || [];

        // 새롭게 저장할 좌석 목록
        let newDeskGroupList: Array<BuildingCommonFacilityDeskGroupModel> = [];

        // 기존 좌석 목록
        const currentDeskGroupList = JSON.parse(JSON.stringify([...deskSpace.deskGroupList]));

        if (submitData.type === "GROUP") {
          // 좌석 그룹
          if (submitData?.deskGroupId) {
            // deskGroupId 가 있으면 좌석 그룹 수정
            newDeskGroupList = currentDeskGroupList.map(
              (current: BuildingCommonFacilityDeskGroupModel) => {
                // 현재 좌석 그룹의 좌석 목록
                const currentCommonFacilityDeskList: Array<CommonFacilityModel> =
                  currentDeskGroupList.find(
                    (obj: BuildingCommonFacilityDeskGroupModel) =>
                      Number(obj.id) === Number(submitData.deskGroupId),
                  )?.deskList || [];
                if (Number(current.id) === Number(submitData.deskGroupId)) {
                  // id 가 동일한 수정할 좌석 그룹
                  const newDeskGroup: BuildingCommonFacilityDeskGroupModel = {
                    id: Number(submitData.deskGroupId),
                    buildingId: building.id,
                    isGround: Number(submitData.floor) > 0,
                    floorNum: Math.abs(Number(submitData.floor)),
                    groupName: submitData.deskGroupName,
                    deskList: currentCommonFacilityDeskList,
                    description: submitData.deskGroupDescription,
                    deskChartDescription: submitData.deskChartDescription,
                  };
                  return newDeskGroup;
                } else {
                  return current;
                }
              },
            );
          } else {
            // deskGroupId 가 없으면 좌석 그룹 등록
            const newDeskGroup: BuildingCommonFacilityDeskGroupModel = {
              buildingId: building.id,
              isGround: Number(submitData.floor) > 0,
              floorNum: Math.abs(Number(submitData.floor)),
              groupName: submitData.deskGroupName,
              deskList: [],
              description: submitData.deskGroupDescription,
              deskChartDescription: submitData.deskChartDescription,
            };
            currentDeskGroupList.push(newDeskGroup);
            newDeskGroupList = currentDeskGroupList;
          }
        } else {
          // 좌석
          if (!submitData?.deskGroupId) throw Error("좌석 등록 수정시, 좌석그룹 id 가 없습니다.");

          if (submitData?.deskId) {
            // deskId 가 있으면 좌석 수정
            newDeskGroupList = currentDeskGroupList.map(
              (current: BuildingCommonFacilityDeskGroupModel) => {
                const deskIdIncludedCheck = current?.deskList?.some(
                  (desk) => Number(desk.id) === Number(submitData.deskId),
                );

                if (Number(current.id) === Number(submitData.deskGroupId)) {
                  // true 면 기본로직 진행
                  if (deskIdIncludedCheck === true) {
                    const newCommonFacilityDeskList: Array<CommonFacilityModel> = (
                      current?.deskList || []
                    ).map((commonFacilityDesk: CommonFacilityModel, index: number) => {
                      if (Number(commonFacilityDesk.id) === Number(submitData.deskId)) {
                        // id 가 동일한 수정할 좌석
                        const newCommonFacilityDesk: CommonFacilityModel = {
                          id: commonFacilityDesk.id,
                          facilityName: submitData?.deskName,
                          commonFacilityType: "DESK",
                          locationCode: submitData.locationCode,
                          centerX: commonFacilityDesk.centerX,
                          centerY: commonFacilityDesk.centerY,
                        };
                        return newCommonFacilityDesk;
                      }
                      return commonFacilityDesk;
                    });
                    current.deskList = newCommonFacilityDeskList;
                    return current;
                  } else {
                    const newCommonFacilityDesk: CommonFacilityModel = {
                      id: submitData.deskId,
                      facilityName: submitData?.deskName,
                      commonFacilityType: "DESK",
                      locationCode: submitData.locationCode,
                      centerX: submitData.centerX,
                      centerY: submitData.centerY,
                    };
                    current?.deskList?.push(newCommonFacilityDesk);
                  }
                }

                // 그룹 이동 후 기존 deskList에 남아있는 중복값 필터로 제거
                const findDuplicated = current.deskList?.find(
                  (item) =>
                    Number(item.id) === Number(submitData.deskId) &&
                    Number(current.id) !== Number(submitData.deskGroupId),
                );
                if (
                  Number(current.id) === Number(findDuplicated?.buildingCommonFacilityDeskGroupId)
                ) {
                  current.deskList = current.deskList?.filter(
                    (desk) => Number(desk.id) !== Number(findDuplicated?.id),
                  );
                }

                return current;
              },
            );
          } else {
            // deskId 가 없으면 좌석 등록
            newDeskGroupList = currentDeskGroupList.map(
              (current: BuildingCommonFacilityDeskGroupModel) => {
                if (Number(current.id) === Number(submitData.deskGroupId)) {
                  const newCommonFacilityDeskList: Array<CommonFacilityModel> =
                    current?.deskList || [];
                  const newCommonFacilityDesk: CommonFacilityModel = {
                    facilityName: submitData.deskName,
                    commonFacilityType: "DESK",
                    locationCode: submitData.locationCode,
                  };
                  newCommonFacilityDeskList.push(newCommonFacilityDesk);
                  current.deskList = newCommonFacilityDeskList;
                }
                return current;
              },
            );
          }
        }

        // 등록 또는 수정
        const { status, data: upsertResultData } = await postCommonfacility({
          buildingId: building.id,
          parkingLotList,
          meetingRoomList,
          deskSpace: {
            deskGroupList: newDeskGroupList, // 등록 또는 수정
            mediaList: deskSpace.mediaList,
          },
          refreshRoomList,
        });
        if (status >= 200 && status <= 299 && upsertResultData?.data?.content) {
          openToast("정상적으로 저장 되었습니다.");

          // form reset
          resetForm(submitData.type || "GROUP");

          // 건물 다시 상세조회
          await fetchBuilding(building.id);
        } else {
          if (upsertResultData?.meta?.errorCode === "ePR0553") {
            setAlertModal({ isOpen: true, message: "Location code 가 중복입니다." });
          } else {
            setAlertModal({
              isOpen: true,
              message: upsertResultData?.meta?.errorMessage || "에러가 발생했습니다.",
            });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBar(false);
      }
    },
    [building, fetchBuilding, openToast, postCommonfacility, setLoadingBar, resetForm],
  );

  // 좌석 그룹 or 좌석 row 삭제
  const deleteDesk = useCallback(
    async (submitData: DeskSpace) => {
      try {
        if (!building?.id) {
          throw Error("building.id is not found.");
        }

        setLoadingBar(true);

        // 주차장/회의실/좌석/편의시설 기본 값
        const meetingRoomList = building?.buildingCommonFacility?.meetingRoomList || [];
        const parkingLotList = building?.buildingCommonFacility?.parkingLotList || [];
        const duplicateDeskGroupList = JSON.parse(
          JSON.stringify(building?.buildingCommonFacility?.deskSpace?.deskGroupList),
        );

        const deskSpace = {
          deskGroupList: duplicateDeskGroupList || [],
          mediaList: building?.buildingCommonFacility?.deskSpace?.mediaList || [],
        };
        const refreshRoomList = building?.buildingCommonFacility?.refreshRoomList || [];

        // 새롭게 저장할 좌석 목록
        let newDeskGroupList: Array<BuildingCommonFacilityDeskGroupModel> = [];

        // 삭제 클릭된 좌석 목록 (1개)
        // let deletedDeskList: Array<CommonFacilityModel> = []; -> duplicateDeskGroupList 로 해결 (원본데이터 복사)

        if (submitData.type === "GROUP") {
          // 좌석 그룹 삭제
          for (let i = 0; i < deskSpace.deskGroupList.length; i++) {
            const current: BuildingCommonFacilityDeskGroupModel = deskSpace.deskGroupList[i];
            if (Number(current.id) !== Number(submitData.deskGroupId)) {
              // 선택한 좌석 그룹 제외하고 저장
              newDeskGroupList.push(current);
            }
          }
        } else {
          // 좌석 삭제
          for (let i = 0; i < deskSpace.deskGroupList.length; i++) {
            const current: BuildingCommonFacilityDeskGroupModel = deskSpace.deskGroupList[i];
            if (Number(current.id) === Number(submitData.deskGroupId)) {
              const newCommonFacilityDeskList: Array<CommonFacilityModel> = [];
              for (let j = 0; j < (current?.deskList || []).length; j++) {
                const currentCommonFacilityDesk: CommonFacilityModel = (current?.deskList || [])[j];
                if (Number(currentCommonFacilityDesk.id) !== Number(submitData.deskId)) {
                  // 선택한 좌석 제외하고 저장
                  newCommonFacilityDeskList.push(currentCommonFacilityDesk);
                } else {
                  // deletedDeskList.push(currentCommonFacilityDesk);
                }
              }

              current.deskList = newCommonFacilityDeskList;
            }

            newDeskGroupList.push(current);
          }
        }

        // 선택한 좌석 그룹 or 좌석 제외하고 저장 (삭제)
        const { status, data: deleteResultData } = await postCommonfacility({
          buildingId: building.id,
          parkingLotList,
          meetingRoomList,
          deskSpace: {
            deskGroupList: newDeskGroupList,
            mediaList: deskSpace.mediaList,
          },
          refreshRoomList,
        });
        if (status >= 200 && status <= 299 && deleteResultData?.data?.content) {
          openToast("정상적으로 삭제 되었습니다.");

          // form reset
          resetForm(submitData.type || "GROUP");

          // 건물 다시 상세조회
          await fetchBuilding(building.id);
        } else {
          if (deleteResultData?.meta?.errorCode === "ePR0517") {
            const errorData = deleteResultData?.meta?.errorData;
            let errorText = "";

            for (let i in errorData) {
              errorText = `사용중인 공용공간은 삭제할 수 없습니다.\n 해당 공용공간을 하위 단계에서 먼저 삭제해 주세요. \n ${i}: [${errorData[i]}]`;
            }
            setAlertModal({
              isOpen: true,
              message: errorText || "에러가 발생했습니다.",
            });
          } else {
            setAlertModal({
              isOpen: true,
              message: deleteResultData?.meta?.errorMessage || "에러가 발생했습니다.",
            });
          }

          // newDeskGroupList = building?.buildingCommonFacility?.deskSpace?.deskGroupList || [];

          // 에러가 났음에도 front 상에서는 desk 가 삭제된 상태로 남아서 다시 원래 데이터와 같게 push
          // newDeskGroupList.forEach((group) => {
          //   if (Number(group.id) === deletedDeskList[0].buildingCommonFacilityDeskGroupId) {
          //     group?.deskList?.push(deletedDeskList[0]);
          //   }
          // });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBar(false);
      }
    },
    [building, fetchBuilding, openToast, postCommonfacility, setLoadingBar, resetForm],
  );

  // 좌석 초기화 저장 (삭제)
  const deleteAllDesk = useCallback(async () => {
    try {
      if (!building?.id) {
        throw Error("building.id is not found.");
      }

      setLoadingBar(true);

      // 주차장/회의실/좌석/편의시설 기본 값
      const meetingRoomList = building?.buildingCommonFacility?.meetingRoomList || [];
      const parkingLotList = building?.buildingCommonFacility?.parkingLotList || [];
      const refreshRoomList = building?.buildingCommonFacility?.refreshRoomList || [];

      const { status, data: deleteAllResultData } = await postCommonfacility({
        buildingId: building.id,
        parkingLotList,
        meetingRoomList,
        deskSpace: {
          // 초기화
          deskGroupList: [],
          mediaList: [],
        },
        refreshRoomList,
      });
      if (status >= 200 && status <= 299 && deleteAllResultData?.data?.content) {
        openToast("정상적으로 초기화 저장 되었습니다.");

        // form reset
        resetForm();

        // 건물 다시 상세조회
        await fetchBuilding(building.id);
      } else {
        setAlertModal({
          isOpen: true,
          message: deleteAllResultData?.meta?.errorMessage || "에러가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBar(false);
    }
  }, [building, fetchBuilding, openToast, postCommonfacility, setLoadingBar]);

  // 배치도 저장
  const saveLayout = useCallback(async () => {
    try {
      if (!building?.id) {
        throw Error("building.id is not found.");
      }
      setLoadingBar(true);

      // 주차장/회의실/좌석/편의시설 기본 값
      const meetingRoomList = building?.buildingCommonFacility?.meetingRoomList || [];
      const parkingLotList = building?.buildingCommonFacility?.parkingLotList || [];
      const deskSpace = {
        deskGroupList: building?.buildingCommonFacility?.deskSpace?.deskGroupList || [],
        mediaList: building?.buildingCommonFacility?.deskSpace?.mediaList || [],
      };
      const refreshRoomList = building?.buildingCommonFacility?.refreshRoomList || [];

      const { status, data: upsertResultData } = await postCommonfacility({
        buildingId: building.id,
        parkingLotList,
        meetingRoomList,
        deskSpace: {
          deskGroupList: deskSpace.deskGroupList,
          mediaList: mediaList.map((mediaFile: MediaFile) => {
            const newMediaFile: MediaFile = {
              ...mediaFile,
              ...{
                mediaServiceType: "MEDIA_BUILDING_DESK", // 데스크공간
                category1: "",
                category2: "",
              },
            };
            return newMediaFile;
          }),
        },
        refreshRoomList,
      });
      if (status >= 200 && status <= 299 && upsertResultData?.data?.content) {
        openToast("정상적으로 저장 되었습니다.");
      } else {
        // form reset
        resetForm();

        setLoadingBar(false);

        // 건물 다시 상세조회
        await fetchBuilding(building.id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBar(false);
    }
  }, [building, fetchBuilding, openToast, postCommonfacility, setLoadingBar, mediaList]);

  // Modal 확인 버튼 클릭
  const clickModalConfirm = useCallback(async () => {
    setConfirmModal({ isOpen: false });

    try {
      if (confirmModal.type === "DESK_SAVE") {
        // 좌석 저장 (좌석 그룹 or 좌석)
        if (!confirmModal?.payload) throw Error("confirmModal?.payload is not found.");
        await saveDesk(confirmModal?.payload);
      } else if (confirmModal.type === "DESK_ALL_DELETE") {
        // 좌석 초기화 저장 (삭제)
        await deleteAllDesk();
      } else if (confirmModal.type === "DESK_DELETE") {
        // 좌석 그룹 or 좌석 row 삭제
        if (!confirmModal?.payload) throw Error("confirmModal?.payload is not found.");

        await deleteDesk(confirmModal?.payload);
      } else if (confirmModal.type === "LAYOUT_SAVE") {
        // 배치도 저장
        await saveLayout();
      }
    } catch (error) {
      console.error(error);
    }
  }, [confirmModal, saveDesk, deleteAllDesk, deleteDesk, saveLayout]);

  // 토글 버튼 변경
  const changeToggle = (value: boolean) => {
    if (value) {
      setValue("isUsedDeskSpace", true);
    } else {
      setConfirmModal({
        isOpen: true,
        message: "정보가 초기화되어 저장됩니다.",
        type: "DESK_ALL_DELETE",
      });
    }
  };

  return (
    <>
      <Controller
        control={control}
        name="isUsedDeskSpace"
        render={({ field: { value, name } }) => (
          <section className="contents-container__1200 mb30">
            <div className="contents-container__grid mb10">
              <div className="contents-container__grid-index">
                <p>좌석</p>
              </div>
            </div>
            {/* 토글 활성화 되면 노출 */}
            {getValues("isUsedDeskSpace") && (
              <>
                {/* 좌석 저장 테이블 */}
                <SaveDeskTable
                  deskSpace={deskSpace}
                  saveDeskSpace={(deskSpace: FrontDeskSpace) => {
                    setConfirmModal({
                      isOpen: true,
                      message: "저장하시겠습니까?",
                      type: "DESK_SAVE",
                      payload: deskSpace,
                    });
                  }}
                  setDeskSpace={setDeskSpace}
                  deskSpaces={deskSpaces}
                  rangeFloorOptions={rangeFloorOptions}
                />

                <div className="my20">
                  <ViewDataTable columns={columns} data={deskSpaces} />
                </div>

                {/* 좌석 - 배치도 */}
                <div className="mb80">
                  <div className="contents-container__grid mb10">
                    <div className="contents-container__grid-index">
                      <p>배치도 ({mediaList.length}/10)</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        )}
      ></Controller>

      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
      >
        <p>{confirmModal.message}</p>
      </BaseModal>

      {/* 확인버튼만 있는 alert 모달 */}
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => setAlertModal({ isOpen: false })}
      >
        <p className="pre-formatted">{alertModal.message}</p>
      </BaseModal>
    </>
  );
};

export default DeskSection;
