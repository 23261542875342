const TableTextWithParkingLotList = ({ title, product }: any) => {
  let self = "";
  let auto = "";
  if (product?.buildingList && product?.buildingList.length > 0) {
    product?.buildingList?.forEach((list: any) => {
      list.building.buildingCommonFacility.parkingLotList.forEach((parkingLot: any) => {
        self = "자주식 주차(" + parkingLot.selfPropelledParkingLotTotalNums + ")대";
        auto = "자동식 주차(" + parkingLot.mechanicalParkingLotTotalNums + ")대";
      });
    });
  }
  return (
    <>
      <tr>
        <td width="180">
          <div className="flex-center pl16">
            <span className="text-primary3">{title}</span>
          </div>
        </td>
        <td>
          <div className="w-100 flex-center-start">{self + " , " + auto}</div>
        </td>
      </tr>
    </>
  );
};
export default TableTextWithParkingLotList;
