import { useApiOperation } from "src/api/hooks";
import {
  checkPayletterApiConfirm,
  editProviderPayletterAsync,
  postProviderPayletterAsync,
} from "src/api/provider/provider-api";
import { ProviderDetailModel } from "src/api/provider/provider-types";
import AutoPayletter from "./AutoPayletter";
import BasicPayletter from "./BasicPayletter";

type Props = {
  providerId: number;
  providerDetail?: ProviderDetailModel;
  providerDetailApi: Function;
};

const ProviderPayletterForm = ({ providerId, providerDetail, providerDetailApi }: Props) => {
  // 페이레터 등록
  const { executeAsync: postProviderPayletter } = useApiOperation(postProviderPayletterAsync);

  // 페이레터 수정
  const { executeAsync: editProviderPayletter } = useApiOperation(editProviderPayletterAsync);

  return (
    <article className="contents-container__divide-top">
      <BasicPayletter
        providerId={providerId}
        providerDetail={providerDetail}
        postProviderPayletter={postProviderPayletter}
        editProviderPayletter={editProviderPayletter}
        providerDetailApi={providerDetailApi}
      />
      <AutoPayletter
        providerId={providerId}
        providerDetailApi={providerDetailApi}
        editProviderPayletter={editProviderPayletter}
        providerDetail={providerDetail}
      />
    </article>
  );
};

export default ProviderPayletterForm;
