import { createContext, useCallback, useState } from "react";
import { BaseButton } from "src/components";

type ErrorModal = {
  openErrorModal: Function;
};

type ErrorModalState = {
  isOpen: boolean;
  errorCode?: string;
  errorMessage?: string;
  errorData?: any;
};

export const ErrorModalContext = createContext<ErrorModal>({ openErrorModal: () => {} });

export function ErrorModalContextProvider({ children }: { children: React.ReactNode }) {
  const [errorModalState, setErrorModalState] = useState<ErrorModalState>({ isOpen: false });

  const openErrorModal = useCallback(
    (errorMessage?: string, errorCode?: string, errorData?: any) => {
      setErrorModalState({ isOpen: true, errorMessage, errorCode, errorData });
    },
    [setErrorModalState],
  );

  return (
    <ErrorModalContext.Provider value={{ openErrorModal }}>
      {children}
      {errorModalState.isOpen && (
        <div className="dim error">
          <div className={`base-modal`}>
            <div className="base-modal__contents" style={{ wordBreak: "break-all" }}>
              <p>errorCode: {errorModalState.errorCode}</p>
              <div className="mt15 pre-formatted">
                errorMessage: {errorModalState?.errorMessage || "에러가 발생했습니다."}
              </div>
              {errorModalState?.errorData && (
                <div className="mt15">
                  errorData: {JSON.stringify(errorModalState?.errorData, null, 2)}
                </div>
              )}
            </div>
            <div className="base-modal__btn-wrap">
              <BaseButton title="확인" onClick={() => setErrorModalState({ isOpen: false })} />
            </div>
          </div>
        </div>
      )}
    </ErrorModalContext.Provider>
  );
}
