import { createContext, useCallback, useEffect, useState } from "react";

type ToastContext = {
  openToast: Function;
};

type ToastState = {
  isOpen: boolean;
  type?: "SUCCESS" | "FAIL";
  message?: string;
};

export const ToastContext = createContext<ToastContext>({ openToast: () => {} });

export function ToastContextProvider({ children }: { children: React.ReactNode }) {
  const [toastState, setToastState] = useState<ToastState>({ isOpen: false });

  useEffect(() => {
    if (toastState && toastState.isOpen) {
      const timer = setTimeout(() => setToastState({ isOpen: false }), 3000);
      return () => clearTimeout(timer);
    }
  }, [toastState]);

  const openToast = useCallback(
    (message: string, type?: "SUCCESS" | "FAIL") => {
      setToastState({ isOpen: true, message, type });
    },
    [setToastState],
  );

  return (
    <ToastContext.Provider value={{ openToast }}>
      {children}
      {toastState.isOpen && (
        <div className={`base-toast`}>
          <button
            className="base-toast__close"
            onClick={() => setToastState({ isOpen: false })}
          ></button>
          <div className="base-toast__contents">
            <span>{toastState?.type === "FAIL" ? "Fail" : "Success"}</span>
            <p>{toastState?.message}</p>
          </div>
        </div>
      )}
    </ToastContext.Provider>
  );
}
