import moment from "moment";
import qs from "qs";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { ContractStep } from "src/api/contract/contract-types";
import { BaseTooltip } from "src/components";
import PagePath from "src/pagePath.json";
import { YmdFormat } from "src/utils";

const useSpaceProductColumns: any = [
  // {
  //   Header: "No",
  //   accessor: "contractId",
  //   // sticky: "left",
  //   width: 70,
  //   Cell: (props: any) => {
  //     const location = useLocation();
  //     const queryParams = useMemo(
  //       () =>
  //         qs.parse(location.search, {
  //           ignoreQueryPrefix: true,
  //           allowDots: true,
  //           decoder: (c) => c,
  //         }),
  //       [location],
  //     );
  //     queryParams.tab = "basicInfo";

  //     const path =
  //       PagePath.contract.detail.replace(":id", props.row.original.contractId) +
  //       "?" +
  //       decodeURIComponent(qs.stringify(queryParams, { allowDots: true, encode: true }));

  //     // console.log("props.row.original", props.row.original);
  //     return (
  //       <>
  //         <Link to={path} target="_blank" className="text-hilight">
  //           {props.value}
  //         </Link>
  //       </>
  //     );
  //   },
  // },
  {
    Header: "신청번호",
    accessor: "contractApplyNumber",
    width: 110,
    Cell: (props: any) => {
      const path = PagePath.contract.detail.replace(":id", props.row.original.contractId);
      return (
        <>
          <Link to={path} target="_blank" className="text-hilight">
            {props.value}
          </Link>
        </>
      );
    },
  },
  {
    Header: "상품명",
    accessor: "spaceProductName",
    width: 240,
    Cell: ({ value }: any) => {
      return <BaseTooltip contents={value} />;
    },
  },

  {
    Header: "이용시작",
    accessor: "contractStartTime",
    width: 200,
    Cell: ({ row }: any) => {
      const start = row.original.contractStartTime;
      const end = row.original.contractEndTime;
      return (
        <>
          {start ? moment(start).format(YmdFormat.YYYY_MM_DD) : "-"} ~
          {end ? moment(end).format(YmdFormat.YYYY_MM_DD) : "-"}
        </>
      );
    },
  },
  {
    Header: "이용 상태",
    accessor: "contractStep",
    width: 90,
    Cell: ({ value }: any) => {
      return (
        //visible 녹색
        //invisible 빨간색
        //draft = 회색
        <div
          className={`${
            (value === ContractStep.APPLY_RECEIVED && "chip draft") ||
            (value === ContractStep.APPLY_CANCEL && "chip invisible") ||
            (value === ContractStep.APPLY_CONFIRM && "chip draft") ||
            (value === ContractStep.CONTRACT_PLAN && "chip draft") ||
            (value === ContractStep.CONTRACT_ACCEPT && "chip draft") ||
            (value === ContractStep.USE_APPROVAL && "chip visible") ||
            (value === ContractStep.USE_PROGRESS && "chip visible") ||
            (value === ContractStep.USE_COMPLETE && "chip invisible") ||
            (value === ContractStep.TERMINATE_RECEIVED && "chip invisible") ||
            (value === ContractStep.TERMINATE_COMPLETE && "chip invisible") ||
            (value === "DISABLED" && "chip invisible") ||
            (value === "DRAFT" && "chip draft")
          }`}
        >
          {(value === ContractStep.APPLY_RECEIVED && "신청접수") ||
            (value === ContractStep.APPLY_CANCEL && "신청취소") ||
            (value === ContractStep.APPLY_CONFIRM && "신청확인") ||
            (value === ContractStep.CONTRACT_PLAN && "계약예정") ||
            (value === ContractStep.CONTRACT_ACCEPT && "계약체결") ||
            (value === ContractStep.USE_APPROVAL && "이용승인") ||
            (value === ContractStep.USE_PROGRESS && "이용중") ||
            (value === ContractStep.USE_COMPLETE && "이용완료") ||
            (value === ContractStep.TERMINATE_RECEIVED && "해지접수") ||
            (value === ContractStep.TERMINATE_COMPLETE && "해지완료")}
        </div>
      );
    },
  },
  {
    Header: "이용자",
    accessor: "userList",
    width: 110,
    Cell: (props: any) => {
      const contractApplyNumber = props.row.original.contractApplyNumber;
      const path = PagePath.user.list + "?contractApplyNumber=" + contractApplyNumber;
      return (
        <>
          <Link to={path} target="_blank" className="text-hilight">
            바로가기
          </Link>
        </>
      );
    },
  },
];
export default useSpaceProductColumns;
