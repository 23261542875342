import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useApiOperation from "src/api/hooks/api-operation";
import { getIotPlatformAccountListAsync, getIotPlatformDeviceListAsync } from "src/api/iot/iot-api";
import { PaPlatformAccount, PaPlatformDevice } from "src/api/iot/iot-types";
import { BaseButton, BaseModal, BasePagination } from "src/components";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { useLoadingBarContext } from "src/pages/hooks";
import { COLLECTIVE_COLUMNS } from "./columns/Columns";
import { COLLECTIVE_CONTROL_ITEMS } from "./columns/CollectiveControlItems";

// 제어 일괄등록 선택 모달

interface Props {
  onCanceled: () => void;
  onAdded: (platformDevice: PaPlatformDevice[]) => void;
  defaultValues: PaPlatformDevice[];
  multiSelect?: boolean;
  registeredDeviceId: string;
}

function CollectiveControlRegistrationModal({
  onCanceled,
  onAdded,
  defaultValues,
  multiSelect,
  registeredDeviceId,
}: Props) {
  const [page, setPage] = useState({ current: 0, total: 0 });
  const [selectedList, setSelectedList] = useState<any[]>(defaultValues);

  const listTableData = COLLECTIVE_CONTROL_ITEMS.map((item) => {
    const selectedIdList = selectedList.map((item) => item.id);
    return {
      checked: selectedIdList.includes(item.id),
      ...item,
    };
  });

  const onSubmit = (data: any) => {
    // setSelectedList(devices);
  };
  const goPage = (nextPage: number) => setPage({ ...page, current: nextPage });

  const handleCheckboxClick = (checkbox: HTMLDivElement) => {
    const checkedId = checkbox.dataset.checkedId;
    const checked = Boolean(checkbox.dataset.checked === "true");
    const isDisabled = Boolean(checkbox.dataset.disabled === "true");
    if (!isDisabled) {
      if (!checked) {
        const selectedItem = COLLECTIVE_CONTROL_ITEMS.find((item) => String(item.id) === checkedId);

        if (multiSelect) {
          setSelectedList([...selectedList, selectedItem!]);
        } else {
          setSelectedList([selectedItem!]);
        }
      } else {
        const filteredList = selectedList.filter((item) => {
          return String(item.id) !== String(checkedId);
        });

        setSelectedList(filteredList);
      }
    }
  };
  const handleModalContentsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;
    const tr = target.closest(".base-table__tr");
    const td = tr?.children[0];
    const checkbox = td?.children[0] as HTMLDivElement;

    if (checkbox) handleCheckboxClick(checkbox);
  };

  const handleCancelButtonClick = () => onCanceled();
  const handleOnsubmit = () => {
    onAdded(selectedList);
  };

  return (
    <BaseModal isOpen={true} className="dialog-modal">
      <div>
        <div className="modal-title">
          <div className="left-area">
            <span>제어 일괄 등록</span>
          </div>
        </div>
        <div className="base-modal__contents" onClick={handleModalContentsClick}>
          <ViewDataTable columns={COLLECTIVE_COLUMNS} data={listTableData} />
          <BasePagination pageIndex={page.current} totalPages={page.total} goPage={goPage} />
        </div>
        <div className="base-modal__btn-wrap">
          <BaseButton
            title={"취소"}
            className="color-white no-outline text-primary3 mr10"
            onClick={handleCancelButtonClick}
          />
          <BaseButton
            title={"선택 추가"}
            onClick={() => {
              handleOnsubmit();
              onCanceled();
            }}
          />
        </div>
      </div>
    </BaseModal>
  );
}
export default CollectiveControlRegistrationModal;
