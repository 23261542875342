import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useApiOperation } from "src/api/hooks";
import { deletePrImageAsync, postPrImageAsync } from "src/api/product/product-api";
import { MediaFile, MediaServiceType } from "src/api/public-types";
import { BaseButton, BaseModal } from "src/components";
import ImagesUpdate from "src/components/ImagesUpdate";
import { useLoadingBarContext } from "src/pages/hooks";
import { Modal } from "src/pages/product/product-types";

type Props = {
  type: MediaServiceType;
  images: MediaFile[];
  buildingId: number;
  getBuildingImagesApi: Function;
};

const ImageCategory = ({ type, images, buildingId, getBuildingImagesApi }: Props) => {
  const [filterdImages, setFilterdImages] = useState<MediaFile[]>([]);
  const [deleteIds, setDeleteIds] = useState<number[]>([]);
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });
  const [completedModal, setCompletedModal] = useState<Modal>({ isOpen: false });
  const [confirm, setConfirm] = useState<Modal>({ isOpen: false });

  const { setLoadingBar } = useLoadingBarContext();

  // 이미지 등록/수정
  const { executeAsync: postBuildingImage } = useApiOperation(postPrImageAsync);

  // 이미지 삭제
  const { executeAsync: deleteBuildingImages } = useApiOperation(deletePrImageAsync);

  useEffect(() => {
    setFilterdImages(images.filter((i: MediaFile) => i.mediaServiceType === type) || []);
  }, [images]);

  // 이미지 삭제
  const deleteBuildingImagesApi = useCallback(async (ids: Array<number>) => {
    const response = await deleteBuildingImages({ ids });
    console.log("ids", ids);
    if (response.status >= 200 || response.status < 300) {
      console.log(response.data);
    }
  }, []);

  // 이미지 등록/수정
  const postBuildingImageApi = useCallback(
    async (payload) => {
      setLoadingBar(true);

      const payloadImages: Array<MediaFile> = payload.images;
      let newImages: Array<MediaFile> = [];

      // 이미지에 이미지 타입 및 serviceId 추가
      payloadImages.forEach((image: MediaFile, idx: number) => {
        image.orderNums = idx + 1; // 이미지 삭제로 orderNumbs 변경 시, 다시 orderNums를 세팅

        const imageData = {
          ...image,
          serviceId: String(buildingId),
          mediaServiceType: payload.type,
        };
        newImages.push(imageData); // post 할 데이터
      });

      // 등록시 삭제한 것도 같이 진행
      if (deleteIds.length > 0) {
        deleteBuildingImagesApi(deleteIds);
      }

      // 이미지 대표없으면 경고 모달
      const checkPrimary = newImages.some((image) => image.isPrimary === true);
      if (newImages.length > 0 && !checkPrimary) {
        setAlertModal({ isOpen: true });
        setConfirm({ isOpen: false });
        setLoadingBar(false);
        return;
      }

      // 모든 이슈 없으면 등록/수정 진행
      const response = await postBuildingImage(newImages);
      if (response.status >= 200 || response.status < 300) {
        setConfirm({ isOpen: false });
        setCompletedModal({ isOpen: true });
        getBuildingImagesApi(buildingId || 0);
      }

      setLoadingBar(false);
    },
    [deleteIds],
  );

  const getCategoryName = useCallback((category: string) => {
    let typeName = "";

    switch (category) {
      case "MEDIA_BUILDING":
        typeName = "외부";
        break;

      case "MEDIA_BUILDING_INSIDE":
        typeName = "내부";
        break;
      case "MEDIA_BUILDING_FLOOR_ROOM":
        typeName = "층,호실";
        break;
      case "MEDIA_BUILDING_COMMONFACILITY":
        typeName = "공용시설";
        break;

      case "MEDIA_BUILDING_ETC":
        typeName = "기타";
        break;
    }
    return typeName;
  }, []);

  return (
    <div>
      <section className="mb30">
        <div className="flex-center mb12">
          <div className="minmax200">
            <p className="font14 text-primary3">{`건물 / ${type && getCategoryName(type)} (${
              filterdImages.length
            }/10)`}</p>
          </div>
        </div>
        <ImagesUpdate
          categoryName={type}
          fileMaxLength={10}
          isUsedRepresentative={true}
          isUsedSelfDescription={true}
          onChangeMediaFiles={setFilterdImages}
          isUsedDescription={true}
          mediaFiles={filterdImages}
          deleteIds={deleteIds}
          setDeleteIds={setDeleteIds}
        />
      </section>
      <BaseModal
        isOpen={confirm.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirm({ isOpen: false })}
        onClick={(e) => {
          if (confirm.payload || []) {
            postBuildingImageApi(confirm.payload);
          }
        }}
      >
        <p>이미지를 저장하시겠습니까?</p>
      </BaseModal>
      <BaseModal
        isOpen={completedModal.isOpen}
        btnRightTitle="확인"
        onClick={() => setCompletedModal({ isOpen: false })}
      >
        <p>저장이 완료되었습니다.</p>
      </BaseModal>

      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => setAlertModal({ isOpen: false })}
      >
        <p>대표를 선택해주세요.</p>
      </BaseModal>
    </div>
  );
};

export default ImageCategory;
