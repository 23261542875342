/**
 * components
 */
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import useApiOperation from "src/api/hooks/api-operation";
/**
 * api
 */
import { getMembers } from "src/api/member/member-api";
import { PageMeta, Sort } from "src/api/public-types";
import { BaseInput, BaseModal, BaseSelect, ContentsTitle } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { changeListSearchPhoneNumber } from "src/utils";
/**
 * types
 */
import {
  MemberModel,
  MemberSearchStatusOptions,
  MemberSearchTypeOptions,
  QueryParams,
  SearchPageInitData,
} from "../../../api/member/member-types";
import ListTable from "./components/ListTable";

/**
 * const
 */
const initDatas: SearchPageInitData = {
  memberSearchStatusOptions: MemberSearchStatusOptions,
  memberSearchTypeOptions: MemberSearchTypeOptions,
};

/**
 * START FUNCTION
 */

export type MemberParams = {
  page?: number;
  size?: number;
  searchType?: string;
  keyword?: string;
  sort?: Sort;
  status?: string;
  role?: string;
};

const MemberList = () => {
  /**
   * state
   */
  const [initData] = useState<SearchPageInitData>(initDatas);
  const location = useLocation();
  const navigate = useNavigate();

  // console.log('location',location);
  // location search (url query parameter) 를 읽어서 object 로 변환
  // location search (url query parameter) 를 읽어서 object 로 변환

  const queryParams: MemberParams = useMemo(() => {
    const _queryParams: MemberParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }

    if (!_queryParams?.role) {
      _queryParams.role = "ROLE_MEMBER";
    }

    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, [location.search]);

  // const [queryParam, setQueryParam] = useState<QueryParams>({ ...queryParams });

  const [params, setParams] = useState<MemberParams>({ ...queryParams });

  const [members, setMembers] = useState<MemberModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { executeAsync: getMembersApi } = useApiOperation(getMembers);

  const [isOpen, setIsOpen] = useState(false);

  const { setLoadingBar } = useLoadingBarContext();

  const onCloseError = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  /**
   * call back
   */
  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (passParams?: MemberParams) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, params],
  );

  useEffect(() => {
    async function fetchProducts(memberListParams: QueryParams) {
      setLoadingBar(true);

      const { data } = await getMembersApi(memberListParams);
      setMembers(data?.data?.content || []);
      setPageMeta(data?.meta?.pageMeta);

      setLoadingBar(false);
    }

    let memberListParams: QueryParams = {
      page: queryParams.page,
      size: queryParams.size,
      sort: queryParams.sort,
      role: queryParams.role,
    };

    if (queryParams.searchType && queryParams.searchType !== "") {
      if (queryParams.searchType === "id") {
        memberListParams.id = queryParams.keyword;
      } else if (queryParams.searchType === "containsMemberName") {
        memberListParams.containsMemberName = queryParams.keyword;
      } else if (queryParams.searchType === "containsEmail") {
        memberListParams.containsEmail = queryParams.keyword;
      } else if (queryParams.searchType === "containsPhoneNumber") {
        memberListParams.containsPhoneNumber = changeListSearchPhoneNumber(queryParams.keyword!);
        delete memberListParams.search001;
      } else if (queryParams.searchType === "containsMemberNo") {
        memberListParams.containsMemberNo = queryParams.keyword;
      } else if (queryParams.searchType === "containsNickname") {
        memberListParams.containsNickname = queryParams.keyword;
      }
    } else {
      memberListParams.search001 = queryParams.keyword || "";
    }
    if (queryParams.status && queryParams.status !== "") {
      if (queryParams.status === "AVAILABLE") {
        memberListParams.accountStateStatus = queryParams.status;
      } else if (queryParams.status === "LOCKED") {
        memberListParams.accountStateStatus = queryParams.status;
      } else if (queryParams.status === "INACTIVE") {
        memberListParams.accountStateStatus = queryParams.status;
      } else if (queryParams.status === "DELETED") {
        memberListParams.accountStateStatus = queryParams.status;
      }
    }

    fetchProducts(memberListParams);
  }, [getMembersApi, queryParams, setLoadingBar]);

  return (
    <div className="page-product-list">
      {/* <ContentsTitle title="회원목록" /> */}
        <div className="contents-container__search-wrap">
          <div className="left-area">
            {/* <p>
              전체
              <span className="ml5">{pageMeta?.totalElements} </span>
            </p> */}
             <div className="minmax120 mr8">
              <BaseSelect
                value={params.status}
                stateOptions={initData.memberSearchStatusOptions}
                placeholder="계정상태"
                setStateValue={(status: string) => setParams({ ...params, status })}
              />
            </div>
            <div className="minmax120 mr8">
              <BaseSelect
                value={params.searchType}
                stateOptions={initData.memberSearchTypeOptions}
                placeholder="검색대상"
                setStateValue={(value: string) => {
                  console.log("select", value);
                  setParams({ ...params, searchType: value });
                }}
              />
            </div>
            <div className="minmax220">
              <BaseInput
                value={params.keyword || ""}
                className="mr16"
                type="text"
                placeholder="검색어를 입력해주세요"
                onChange={(keyword: string) => setParams({ ...params, keyword })}
                onKeyUp={() => navigateWithQueryParams({ page: 0 })}
                onSearchClick={() => navigateWithQueryParams({ page: 0 })}
              />
            </div>
          </div>
          <div className="right-area">
           
          </div>
        </div>
        {/* <SearchField
          initData={initData}
          // params={queryParam}
          params={params}
          pageInfo={pageMeta}
          // setQueryParams={setQueryParams}
          setParams={setParams}
          navigateWithQueryParams={navigateWithQueryParams}
        /> */}

        <ListTable
          // params={queryParam}
          params={params}
          data={members}
          pageMeta={pageMeta}
          navigateWithQueryParams={navigateWithQueryParams}
        />
      <BaseModal isOpen={isOpen} btnRightTitle={"확인"} onClick={() => onCloseError()}>
        <div>에러가 발생했습니다</div>
      </BaseModal>
    </div>
  );
};
export default MemberList;
