import { Order, Select } from "../public-types";

enum BillPayStep {
  PAYMENT_READY = "PAYMENT_READY",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  PAYMENT_FAIL = "PAYMENT_FAIL",
  PAYMENT_CANCEL = "PAYMENT_CANCEL",
  CONTRACT_CANCEL = "CONTRACT_CANCEL",
  PAYMENT_FREE = "PAYMENT_FREE",
}

const searchStatusOption: Select[] = [
  { label: "전체", value: "" },
  { label: "대기", value: BillPayStep.PAYMENT_READY },
  { label: "성공", value: BillPayStep.PAYMENT_SUCCESS },
  { label: "실패", value: BillPayStep.PAYMENT_FAIL },
  { label: "취소", value: BillPayStep.PAYMENT_CANCEL },
  { label: "중지", value: BillPayStep.CONTRACT_CANCEL },
  { label: "무료", value: BillPayStep.PAYMENT_FREE },
];

enum ApplicationName {
  //신청/계약(계약) id
  CONTRACT_ID = "CONTRACT_ID",
  //공간상품 id
  SPACE_PRODUCT_ID = "SPACE_PRODUCT_ID",
  //공간상품명
  SPACE_PRODUCT_NAME = "SPACE_PRODUCT_NAME",
  //신청/계약자명
  APPLICANT_NAME = "APPLICANT_NAME",
  //신청/계약자 법인명/상호
  APPLICANT_CORPORATION = "APPLICANT_CORPORATION",
  CONTRACT_APPLY_NUMBER = "CONTRACT_APPLY_NUMBER",
  CONTRACT_NUMBER = "CONTRACT_NUMBER",
  MB_ORGANIZATION_ID = "MB_ORGANIZATION_ID",
}
const searchTypeOption: Select[] = [
  { label: "전체", value: "" },
  { label: "신청/계약 id", value: ApplicationName.CONTRACT_ID },
  { label: "상품id", value: ApplicationName.SPACE_PRODUCT_ID },
  { label: "상품명", value: ApplicationName.SPACE_PRODUCT_NAME },
  // { label: "신청/계약자명", value: ApplicationName.APPLICANT_NAME },
  // { label: "법인/사업자 id", value: ApplicationName.MB_ORGANIZATION_ID },
  { label: "신청 번호", value: ApplicationName.CONTRACT_APPLY_NUMBER },
  // { label: "계약 번호", value: ApplicationName.CONTRACT_NUMBER },
];

//목록 검색
interface SearchParams {
  id?: number;
  billPayStatus?: string;
  searchType?: string;
  searchValue?: string;
  //검색일(from) YYYY-MM-DD
  searchStartTime?: string;
  //검색일(to) YYYY-MM-DD
  searchEndTime?: string;
  partnerName?: string;
  partnerId?: number;
  page?: number;
  size?: number;
  sort?: {
    orders: Array<Order>;
  };
}
export type { SearchParams, BillPayStep, ApplicationName };
export { searchStatusOption, searchTypeOption };
