import { range } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Cell } from "react-table";
import { BuildingModel, CommonFacilityModel, SpaceStyle } from "src/api/building/building-types";
import { postCommonfacilityAsync } from "src/api/building/commonfacility-api";
import { getEslProductDetailAsync } from "src/api/esl/esl-api";
import { EslProduct } from "src/api/esl/esl-types";
import { useApiOperation } from "src/api/hooks";
import { MediaFile } from "src/api/public-types";
import {
  BaseButton,
  BaseInput,
  BaseModal,
  BaseSelect,
  BaseToggle,
  BaseTooltip,
} from "src/components";
import ImagesUpdate from "src/components/ImagesUpdate";
import {
  CommonFacility,
  floorOptions,
  Modal,
  SelectOption,
  sortingMeetingRoomList,
  spaceStyleOptions,
} from "src/pages/building/building-types";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { useLoadingBarContext, useToastContext } from "src/pages/hooks";

type Props = {
  building: BuildingModel | null;
  fetchBuilding: (buildingId: number) => void;
};

type FormData = {
  isUsedMeetingRoom: boolean;
  id?: number; // 건물_공용시설_ID (수정시)
  floor: string | null; // 위치 string
  facilityName: string; // 시설명
  spaceStyle: SpaceStyle; // 공간유형
  locationCode: string; // 공간유형 채번
  equipments: string; // 기자재
  facilityMaxPeopleNums: number; // 시설정원(수)
  description: string; // 설명
};

interface EslLabel extends EslProduct {
  locationCode: string; // 공간유형 채번
}

// 회의실 컴포넌트
const MeetingRoomSection = ({ building, fetchBuilding }: Props) => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 토스트
  const { openToast } = useToastContext();

  // API 에서 조회한 회의실 시설 목록
  const [meetingRoomList, setMeetingRoomList] = useState<Array<CommonFacility>>([]);

  // 회의실별 이미지
  const [mediaList, setMediaList] = useState<Array<MediaFile>>([]);

  // 건물 공용공간 관리(등록/수정/삭제) API
  const { executeAsync: postCommonfacility } = useApiOperation(postCommonfacilityAsync, {
    doNotErrorHandleModal: true,
  });

  // ESL 데이터 상세 조회 api
  const { executeAsync: getEslProductDetail } = useApiOperation(getEslProductDetailAsync, {
    doNotErrorHandleModal: true,
  });

  // 조회한 ESL 데이터 목록
  const [eslLabels, setEslLabels] = useState<Array<EslLabel>>([]);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // useForm defaultValues
  const defaultValues: FormData = useMemo(() => {
    return {
      isUsedMeetingRoom: false,
      id: undefined,
      floor: "1",
      facilityName: "",
      spaceStyle: "SPACE_STYLE_UNRECOGNIZED",
      locationCode: "",
      equipments: "",
      facilityMaxPeopleNums: 1,
      description: "",
    };
  }, []);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
  });

  // esl 라벨 목록 조회
  const fetchAllLabels = useCallback(async (locationCodes: Array<string>) => {
    try {
      if (!locationCodes || locationCodes.length === 0) {
        throw Error("locationCodes.length === 0");
      }

      const eslLabels: Array<EslLabel> = [];
      for (let i = 0; i < locationCodes.length; i++) {
        const locationCode = locationCodes[i];
        const result = await getEslProductDetail({ productId: locationCode });
        if (result?.data?.data && result.status >= 200 && result.status <= 299) {
          if (result?.data?.data?.product) {
            // esl 라벨이 연동되어있을 경우만 조회됨
            eslLabels.push({
              ...result?.data?.data?.product,
              ...{
                locationCode,
              },
            });
          }
        }
      }
      setEslLabels(eslLabels);
    } catch (error) {
      // 에러 발생해도 무시
      console.debug("fetchAllLabels error", error);
    }
  }, []);

  const fetchMeetingRoom = useCallback(async () => {
    const meetingRoomList: Array<CommonFacilityModel> = sortingMeetingRoomList(
      building?.buildingCommonFacility?.meetingRoomList || [],
    );

    // locaiotn code 로 라벨 목록 조회
    const locationCodes: Array<string> = [];
    for (let i = 0; i < meetingRoomList.length; i++) {
      const locationCode = meetingRoomList[i].locationCode;
      if (!!locationCode) {
        locationCodes.push(locationCode);
      }
    }
    if (locationCodes.length > 0) {
      fetchAllLabels(locationCodes);
    }

    setMeetingRoomList(meetingRoomList);
    if (meetingRoomList.length > 0) {
      setValue("isUsedMeetingRoom", true);
    } else {
      setValue("isUsedMeetingRoom", false);
    }
  }, [building, setValue]);

  // 수정시 api 에서 받아온 building 정보로 setValue 처리
  useEffect(() => {
    if (building) {
      fetchMeetingRoom();
    }
  }, [building, fetchMeetingRoom]);

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    register("floor", {
      required: "위치은 필수입력 항목입니다.",
    });
    register("facilityName", {
      required: "회의실 명은 필수입력 항목입니다.",
    });
    register("spaceStyle", {
      validate: {
        required: (spaceStyle?: SpaceStyle) => {
          const requiredMessage = "공간유형은 필수입력 항목입니다.";
          return (!!spaceStyle && spaceStyle !== "SPACE_STYLE_UNRECOGNIZED") || requiredMessage;
        },
      },
    });
    register("locationCode", {
      required: "Location code 는 필수입력 항목입니다.",
    });
  }, [register, getValues]);

  // 건물 > 기본정보 > 규모 범위 내의 층 (위치) 옵션
  const rangeFloorOptions: Array<SelectOption> = useMemo(() => {
    // 건물 > 기본정보 > 규모 범위
    const floorRange = range(
      Number(building?.undergroundFloorNums || 0) === 0
        ? 0
        : Number(building?.undergroundFloorNums || 0) * -1,
      Number(building?.floorNums || 0) + 1,
    );
    const _rangeFloorOptions: Array<SelectOption> = [];
    for (let i = 0; i < floorRange.length; i++) {
      const floor: number = floorRange[i];
      if (floor !== 0) {
        _rangeFloorOptions.push({
          value: String(floor || ""),
          label:
            floorOptions.find((v: SelectOption) => v.value === String(floor || ""))?.label || "",
        });
      }
    }
    if (_rangeFloorOptions.length === 0) {
      return floorOptions;
    } else {
      return _rangeFloorOptions.sort(
        (a: SelectOption, b: SelectOption) => Number(b.value || 0) - Number(a.value || 0),
      );
    }
  }, [building]);

  // 수정 버튼 클릭
  const clickEdit = useCallback(
    (rowData: CommonFacilityModel) => {
      clearErrors();

      // 회의실 input form data 바인딩
      const isGround: boolean = rowData?.isGround === true; // 지상여부
      const floorNum: number = rowData?.floorNum || 1; // 위치
      const floor = `${isGround ? "" : "-"}${floorNum}`;

      // 규모 범위 안에 존재하지 않는 층이면 null 로 바인딩되서 선택되어있지 않게 처리
      const isFindFloorOption = !!rangeFloorOptions.find(
        (option: SelectOption) => option.value === floor,
      );

      const formData: FormData = {
        isUsedMeetingRoom: true,
        id: rowData?.id,
        floor: isFindFloorOption ? floor : null,
        facilityName: rowData?.facilityName || "",
        spaceStyle: rowData.spaceStyle || "SPACE_STYLE_UNRECOGNIZED",
        locationCode: rowData?.locationCode || "",
        facilityMaxPeopleNums: rowData?.facilityMaxPeopleNums || 1,
        equipments: (rowData?.facilityList || []).join(","),
        description: rowData?.description || "",
      };

      for (const [name, value] of Object.entries(formData)) {
        setValue(name as keyof FormData, value, {
          shouldDirty: true,
        });
      }

      // 회의실 이미지 바인딩
      setMediaList(rowData?.mediaList || []);
    },
    [clearErrors, setValue, rangeFloorOptions],
  );

  // 삭제 버튼 클릭
  const clickDelete = (rowData: CommonFacilityModel) => {
    setConfirmModal({
      message: "삭제하시겠습니까?",
      isOpen: true,
      type: "MEETING_ROOM_DELETE",
      payload: rowData,
    });
  };

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
        width: 80,
        Cell: ({ value }: Cell<CommonFacilityModel>) => (
          <BaseTooltip contents={value} isSingleLine={true} />
        ),
      },
      {
        Header: "위치",
        accessor: "floor",
        width: 90,
        Cell: ({ row }: any) => {
          const isGround: boolean = row?.original?.isGround; // 지상여부
          const floorNum: number = row?.original?.floorNum; // 위치
          const floor = floorOptions.find(
            (option: SelectOption) => option.value === `${isGround ? "" : "-"}${floorNum}`,
          );
          return floor?.label || "";
        },
      },
      {
        Header: "회의실 명",
        accessor: "facilityName",
        width: 90,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "공간유형",
        accessor: "spaceStyle",
        width: 80,
        Cell: ({ value }: any) => {
          const findOption = spaceStyleOptions.find(
            (option: SelectOption) => option.value === value,
          );
          if (findOption && findOption.value !== "SPACE_STYLE_UNRECOGNIZED") {
            return <BaseTooltip contents={findOption.label} />;
          }
          return "";
        },
      },
      {
        Header: "Location code",
        accessor: "locationCode",
        width: 80,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "정원",
        accessor: "facilityMaxPeopleNums",
        width: 60,
      },
      {
        Header: "기자재",
        accessor: "equipments",
        width: 120,
        Cell: ({ row }: any) => {
          const facilityList: Array<string> = row?.original?.facilityList || []; // 기자재
          return <BaseTooltip contents={facilityList.join(",")} />;
        },
      },
      {
        Header: "설명",
        accessor: "description",
        width: 140,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "ESL",
        accessor: "eslAddress",
        width: 60,
        Cell: ({ row }: any) => {
          if (!!row?.original?.locationCode && eslLabels.length > 0) {
            const findLabel = eslLabels.find(
              (eslLabel: EslLabel) => eslLabel.locationCode === row?.original?.locationCode,
            );
            if (findLabel) {
              return <BaseTooltip contents={(findLabel.assignedLabelCodes || []).join(",")} />;
            }
          }
          return "";
        },
      },
      {
        Header: "이미지",
        accessor: "images",
        width: 60,
        Cell: ({ row }: any) => {
          const imageCount = (row?.original?.mediaList || []).length;
          return <div>{imageCount}</div>;
        },
      },
    ],
    [clickEdit, eslLabels],
  );

  // CommonFacilityModel 으로 변환
  const convertCommonFacilityModel = useCallback(
    (buildingId: number, data: FormData) => {
      const commonFacilityModel: CommonFacilityModel = {
        id: data?.id,
        buildingId: buildingId,
        commonFacilityType: "MEETING_ROOM",
        isGround: Number(data.floor) > 0,
        floorNum: Math.abs(Number(data.floor)),
        facilityName: data.facilityName,
        facilityList: data.equipments.split(","),
        facilityMaxPeopleNums: data.facilityMaxPeopleNums,
        locationCode: data.locationCode,
        description: data.description,
        spaceStyle: data.spaceStyle,
        mediaList: mediaList.map((mediaFile: MediaFile) => {
          const additionalMediaFile: MediaFile = {
            ...mediaFile,
            ...{
              mediaServiceType: "MEDIA_BUILDING_MEETING_ROOM", // 회의실
              category1: "",
              category2: "",
            },
          };
          return additionalMediaFile;
        }),
      };
      return commonFacilityModel;
    },
    [mediaList],
  );

  // 취소 버튼클릭시 위치 form 리셋
  const resetForm = useCallback(() => {
    setMediaList([]);
    clearErrors();
    for (const [name, value] of Object.entries(defaultValues)) {
      if (name !== "isUsedMeetingRoom") {
        // isUsedMeetingRoom 는 제외하고 defaultValues 로 setValue
        setValue(name as keyof FormData, value);
      }
    }
  }, [clearErrors, setValue, defaultValues]);

  // 회의실 저장
  const saveMeetingRoom = useCallback(
    async (submitData: FormData) => {
      try {
        if (!building?.id) {
          throw Error("building.id is not found.");
        }

        setLoadingBar(true);

        // 주차장/회의실/좌석/편의시설 기본 값
        const meetingRoomList = building?.buildingCommonFacility?.meetingRoomList || [];
        const parkingLotList = building?.buildingCommonFacility?.parkingLotList || [];
        const deskSpace = {
          deskGroupList: building?.buildingCommonFacility?.deskSpace?.deskGroupList || [],
          mediaList: building?.buildingCommonFacility?.deskSpace?.mediaList || [],
        };
        const refreshRoomList = building?.buildingCommonFacility?.refreshRoomList || [];

        // 새롭게 저장할 회의실 목록
        let newMeetingRoomList: Array<CommonFacilityModel> = [];

        // 기존 회의실
        newMeetingRoomList = [...meetingRoomList];

        if (submitData?.id) {
          // id 가 있으면, 회의실 수정
          newMeetingRoomList = newMeetingRoomList.map((currentMeetingRoom: CommonFacilityModel) => {
            if (currentMeetingRoom.id === submitData.id) {
              // id 가 동일한 수정할 회의실
              const newMeetingRoom: CommonFacilityModel = convertCommonFacilityModel(
                building.id,
                submitData,
              );
              return newMeetingRoom;
            } else {
              return currentMeetingRoom;
            }
          });
        } else {
          // id 가 없으면 회의실 등록
          const newMeetingRoom: CommonFacilityModel = convertCommonFacilityModel(
            building.id,
            submitData,
          );
          newMeetingRoomList.push(newMeetingRoom);
        }

        // 등록 또는 수정
        const { status, data: upsertResultData } = await postCommonfacility({
          buildingId: building.id,
          parkingLotList,
          meetingRoomList: newMeetingRoomList,
          deskSpace,
          refreshRoomList,
        });

        if (status >= 200 && status <= 299 && upsertResultData?.data?.content) {
          openToast("정상적으로 저장 되었습니다.");

          // form reset
          resetForm();

          // 이미지 reset
          setMediaList([]);

          // 건물 다시 상세조회
          await fetchBuilding(building.id);
        } else {
          if (upsertResultData?.meta?.errorCode === "ePR0553") {
            setAlertModal({ isOpen: true, message: "Location code 가 중복입니다." });
          } else {
            setAlertModal({
              isOpen: true,
              message: upsertResultData?.meta?.errorMessage || "에러가 발생했습니다.",
            });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBar(false);
      }
    },
    [
      building,
      fetchBuilding,
      openToast,
      postCommonfacility,
      setLoadingBar,
      convertCommonFacilityModel,
      resetForm,
    ],
  );

  // 회의실 초기화 저장 (삭제)
  const deleteAllMeetingRoom = useCallback(async () => {
    try {
      if (!building?.id) {
        throw Error("building.id is not found.");
      }

      setLoadingBar(true);

      // 주차장/회의실/좌석/편의시설 기본 값
      const parkingLotList = building?.buildingCommonFacility?.parkingLotList || [];
      const deskSpace = {
        deskGroupList: building?.buildingCommonFacility?.deskSpace?.deskGroupList || [],
        mediaList: building?.buildingCommonFacility?.deskSpace?.mediaList || [],
      };
      const refreshRoomList = building?.buildingCommonFacility?.refreshRoomList || [];

      // 회의실 초기화 저장 (삭제) 확인 모달
      const { status, data: deleteAllResultData } = await postCommonfacility({
        buildingId: building.id,
        parkingLotList,
        meetingRoomList: [], // 초기화
        deskSpace,
        refreshRoomList,
      });
      if (status >= 200 && status <= 299 && deleteAllResultData?.data?.content) {
        openToast("정상적으로 초기화 저장 되었습니다.");

        // form reset
        resetForm();

        // 이미지 reset
        setMediaList([]);

        // 건물 다시 상세조회
        await fetchBuilding(building.id);
      } else {
        setAlertModal({
          isOpen: true,
          message: deleteAllResultData?.meta?.errorMessage || "에러가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBar(false);
    }
  }, [building, fetchBuilding, openToast, postCommonfacility, setLoadingBar, resetForm]);

  // 회의실 row 삭제
  const deleteMeetingRoom = useCallback(
    async (submitData: CommonFacilityModel) => {
      try {
        if (!building?.id) {
          throw Error("building.id is not found.");
        }

        setLoadingBar(true);

        // 주차장/회의실/좌석/편의시설 기본 값
        const meetingRoomList = building?.buildingCommonFacility?.meetingRoomList || [];
        const parkingLotList = building?.buildingCommonFacility?.parkingLotList || [];
        const deskSpace = {
          deskGroupList: building?.buildingCommonFacility?.deskSpace?.deskGroupList || [],
          mediaList: building?.buildingCommonFacility?.deskSpace?.mediaList || [],
        };
        const refreshRoomList = building?.buildingCommonFacility?.refreshRoomList || [];

        // 새롭게 저장할 회의실 목록
        const newMeetingRoomList: Array<CommonFacilityModel> = [];
        for (let i = 0; i < meetingRoomList.length; i++) {
          const currentMeetingRoom: CommonFacilityModel = meetingRoomList[i];
          if (currentMeetingRoom.id !== submitData.id) {
            // 선택한 회의실 제외하고 저장
            newMeetingRoomList.push(currentMeetingRoom);
          }
        }
        // 선택한 회의실 제외하고 저장 (삭제)
        const { status, data: deleteResultData } = await postCommonfacility({
          buildingId: building.id,
          parkingLotList,
          meetingRoomList: newMeetingRoomList,
          deskSpace,
          refreshRoomList,
        });
        if (status >= 200 && status <= 299 && deleteResultData?.data?.content) {
          openToast("정상적으로 삭제 되었습니다.");

          // form reset
          resetForm();

          // 이미지 reset
          setMediaList([]);

          // 건물 다시 상세조회
          await fetchBuilding(building.id);
        } else {
          if (deleteResultData?.meta?.errorCode === "ePR0517") {
            const errorData = deleteResultData?.meta?.errorData;
            let errorText = "";

            for (let i in errorData) {
              errorText = `사용중인 공용공간은 삭제할 수 없습니다.\n 해당 공용공간을 하위 단계에서 먼저 삭제해 주세요. \n ${i}: [${errorData[i]}]`;
            }

            setAlertModal({
              isOpen: true,
              message: errorText || "에러가 발생했습니다.",
            });
          } else {
            setAlertModal({
              isOpen: true,
              message: deleteResultData?.meta?.errorMessage || "에러가 발생했습니다.",
            });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBar(false);
      }
    },
    [building, fetchBuilding, openToast, postCommonfacility, setLoadingBar, resetForm],
  );

  // Modal 확인 버튼 클릭
  const clickModalConfirm = useCallback(async () => {
    setConfirmModal({ isOpen: false });

    try {
      if (confirmModal.type === "MEETING_ROOM_SAVE") {
        // 회의실 저장
        if (!confirmModal?.payload) throw Error("confirmModal?.payload is not found.");
        await saveMeetingRoom(confirmModal.payload);
      } else if (confirmModal.type === "MEETING_ROOM_ALL_DELETE") {
        // 회의실 초기화 저장 (삭제)
        await deleteAllMeetingRoom();
      } else if (confirmModal.type === "MEETING_ROOM_DELETE") {
        // 회의실 row 삭제
        if (!confirmModal?.payload) throw Error("confirmModal?.payload is not found.");
        await deleteMeetingRoom(confirmModal.payload);
      }
    } catch (error) {
      console.error(error);
    }
  }, [confirmModal, saveMeetingRoom, deleteAllMeetingRoom, deleteMeetingRoom]);

  // 회의실 토글 버튼 변경
  const changeToggle = (value: boolean) => {
    if (value) {
      setValue("isUsedMeetingRoom", true);
    } else {
      setConfirmModal({
        isOpen: true,
        message: "정보가 초기화되어 저장됩니다.",
        type: "MEETING_ROOM_ALL_DELETE",
      });
    }
  };

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: FormData, e?: any) => {
    e.preventDefault();
    setConfirmModal({
      message: "저장하시겠습니까?",
      isOpen: true,
      type: "MEETING_ROOM_SAVE",
      payload: data,
    });
    e.target.reset();
    return false;
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    // console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Controller
        control={control}
        name="isUsedMeetingRoom"
        render={({ field: { value, name } }) => (
          <section className="contents-container__1200 mb30">
            <div className="contents-container__grid mb10">
              <div className="contents-container__grid-index">
                <p>회의실</p>
              </div>
            </div>
            {/* 토글 활성화 되면 노출 */}
            {getValues("isUsedMeetingRoom") && (
              <>
                {/* 회의실 - 폼 */}
                <table className="inner-table" width="940">
                  <thead>
                    <tr>
                      <th>
                        <div>
                          <span className="required">위치</span>
                        </div>
                      </th>
                      <th>
                        <div>
                          <span className="required">회의실 명</span>
                        </div>
                      </th>
                      <th>
                        <div>
                          <span className="required">공간유형</span>
                        </div>
                      </th>
                      <th>
                        <div>
                          <span className="required">Location code</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="200px">
                        <Controller
                          control={control}
                          name="floor"
                          render={({ field: { value, name, onChange } }) => (
                            <BaseSelect
                              stateOptions={rangeFloorOptions}
                              setStateValue={onChange}
                              value={value}
                              name={name}
                            />
                          )}
                        ></Controller>
                      </td>
                      <td width="350px">
                        <Controller
                          control={control}
                          name="facilityName"
                          render={({ field: { value, name, onChange } }) => (
                            <BaseInput type="text" value={value} name={name} onChange={onChange} />
                          )}
                        ></Controller>
                      </td>
                      <td width="150px">
                        <Controller
                          control={control}
                          name="spaceStyle"
                          render={({ field: { value, name, onChange } }) => (
                            <BaseSelect
                              stateOptions={spaceStyleOptions}
                              setStateValue={onChange}
                              value={value}
                              name={name}
                            />
                          )}
                        ></Controller>
                      </td>
                      <td width="240px">
                        <Controller
                          control={control}
                          name="locationCode"
                          render={({ field: { value, name, onChange } }) => (
                            <BaseInput value={value} name={name} onChange={onChange} />
                          )}
                        ></Controller>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  className={
                    errors && Object.keys(errors).length > 0 ? "inner-table" : "inner-table mb20"
                  }
                  width="940"
                >
                  <thead>
                    <tr>
                      <th>
                        <div>
                          <span>정원</span>
                        </div>
                      </th>
                      <th>
                        <div>
                          <span>기자재</span>
                        </div>
                      </th>
                      <th>
                        <div>
                          <span>설명</span>
                        </div>
                      </th>
                      <th>
                        <div className="minmax100"></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="100px">
                        <Controller
                          control={control}
                          name="facilityMaxPeopleNums"
                          render={({ field: { value, name, onChange } }) => (
                            <BaseSelect
                              stateOptions={Array.from(Array(100).keys()).map((i: number) =>
                                Object.assign({ label: `${i + 1}`, value: `${i + 1}` }),
                              )}
                              setStateValue={onChange}
                              value={String(value)}
                              name={name}
                            />
                          )}
                        ></Controller>
                      </td>
                      <td width="350px">
                        <Controller
                          control={control}
                          name="equipments"
                          render={({ field: { value, name, onChange } }) => (
                            <BaseInput type="text" name={name} value={value} onChange={onChange} />
                          )}
                        ></Controller>
                      </td>
                      <td width="390px">
                        <Controller
                          control={control}
                          name="description"
                          render={({ field: { value, name, onChange } }) => (
                            <BaseInput type="text" name={name} value={value} onChange={onChange} />
                          )}
                        ></Controller>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {errors && Object.keys(errors).length > 0 && (
                  <p className="validation-text mb20">{Object.entries(errors)[0][1]?.message}</p>
                )}

                {/* 회의실 - 테이블 */}
                <div className="mt20 mb80">
                  <ViewDataTable columns={columns} data={meetingRoomList} />
                </div>
              </>
            )}
          </section>
        )}
      ></Controller>

      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
      >
        <p>{confirmModal.message}</p>
      </BaseModal>

      {/* 확인버튼만 있는 alert 모달 */}
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => setAlertModal({ isOpen: false })}
      >
        <p className="pre-formatted">{alertModal.message}</p>
      </BaseModal>
    </form>
  );
};

export default MeetingRoomSection;
