import { useEffect, useState } from "react";
import { useApiOperation } from "src/api/hooks";
import { getAudiencesAsync } from "src/api/message/message-api";
import { AppPushAudience, MessageListParams } from "src/api/message/message-types";
import { PageMeta } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal, BasePagination } from "src/components";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { appPushAudienceColmns } from "../list/columns";

import * as xlsx from "xlsx";
import moment from "moment";
import { downloadExcel, YmdFormat } from "src/utils";

type AudienceParams = MessageListParams & { searchValue?: string; appNotificationId: string };

type Props = {
  open: boolean;
  appNotiId: string;
  onClose: () => void;
};

const AudienceList = ({ open, onClose, appNotiId }: Props) => {
  const [audiences, setAudiences] = useState<AppPushAudience[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [keyword, setKeyword] = useState("");

  const [params, setParams] = useState<AudienceParams>({
    page: 0,
    size: 20,
    sort: { orders: [{ property: "id", direction: "DESC" }] },
    appNotificationId: appNotiId,
  });

  const { executeAsync: getAudiences } = useApiOperation(getAudiencesAsync);
  useEffect(() => {
    const fetchAudiences = async () => {
      const { data, status } = await getAudiences(params);

      if (status >= 200 && status <= 299) {
        setAudiences(data.data.content);
        setPageMeta(data.meta.pageMeta);
      }
    };
    fetchAudiences();
  }, [params]);

  // 페이지 이동
  const goPage = (page: number) => {
    setParams({ ...params, page });
  };

  //  검색
  const handleSearch = () => {
    setParams({ ...params, page: 0, searchValue: keyword });
  };

  // 엑셀 다운로드
  const onDownloadExcel = async () => {
    const downloadParams = { ...params };
    delete downloadParams.size;

    const { data } = await getAudiences(downloadParams);

    if (data.data.content) {
      const downloadList =
        data.data.content.map((item) => ({
          id: item.id,
          memberNo: item.memberNo,
          createdDate: item.createdDate
            ? moment(item.createdDate).format(YmdFormat.YYYY_MM_DD_HH_MM)
            : "-",
          readDate: item.readDate ? moment(item.readDate).format(YmdFormat.YYYY_MM_DD_HH_MM) : "-",
          pressedDate: item.pressedDate
            ? moment(item.pressedDate).format(YmdFormat.YYYY_MM_DD_HH_MM)
            : "-",
        })) || [];
      await downloadExcel({
        header: appPushAudienceColmns.map((column) => column.Header),
        fileName: `central_수신인 목록_${moment().format("YYYYMMDDHHmm")}.xlsx`,
        data: downloadList,
      });
    }
  };

  return (
    <BaseModal isOpen={true} className="dialog-modal">
      <div>
        <div className="modal-title">
          <div className="left-area">
            <span>수신인 목록</span>
          </div>
          <div className="minmax300">
            <BaseInput
              onKeyUp={handleSearch}
              value={keyword}
              onSearchClick={handleSearch}
              onChange={setKeyword}
            />
          </div>
        </div>
        <div className="base-modal__contents">
          <ViewDataTable columns={appPushAudienceColmns} data={audiences} />
          <BasePagination
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            goPage={goPage}
          />
        </div>
      </div>
      <div className="base-modal__btn-wrap pt0">
        <BaseButton
          title={"닫기"}
          className="color-white no-outline text-primary3 mr10"
          onClick={onClose}
        />
        <BaseButton title={"엑셀 Export"} onClick={onDownloadExcel} />
      </div>
    </BaseModal>
  );
};

export default AudienceList;
