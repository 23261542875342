import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
// import fileDownload from "js-file-download";

type PropValues = {
  title?: string;
  fileList: any;
  classname?: string;
};

const TextNFileDownload = ({ title, fileList, classname }: PropValues) => {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");

  const getToken = useCallback(async () => {
    // access token 가져오기
    await getAccessTokenSilently();

    // user IdToken 가져오기 (access token 을 먼저 가져와야 정상적으로 실행됨)
    const response = await getIdTokenClaims();
    const idToken = response?.__raw;
    setToken(idToken!);
  }, [getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    if (token === "") {
      getToken();
    }
  }, [getAccessTokenSilently, getIdTokenClaims, getToken, token]);

  return (
    <>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>{title} </p>
        </div>
        <div className="contents-container__grid-contents">
          <div className={classname !== undefined ? classname : "minmax240"}>
            {fileList?.map((file: any, index: number) => {
              // console.log("file", file);
              return (
                <a
                  className="font14 text-hilight"
                  href={file.url + "?access_token=" + token + "&forceDownload"}
                  rel="noreferrer"
                  key={index}
                >
                  {file.filename}
                </a>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default TextNFileDownload;
