import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { VisitorListModel } from "src/api/visitor/visitor-types";
import PagePath from "src/pagePath.json";

export const VisitorLinkCell = (props: Cell<VisitorListModel>) => {
  let detailPath = `${PagePath.visitor.detail.replace(
    ":contractApplyNumber",
    `${props.row.original.contractApplyNumber}`,
  )}`;

  detailPath = `${detailPath.replace(
    ":visitApplyNumber",
    `${props.row.original.visitApplyNumber}`,
  )}`;

  return (
    <div>
      <Link to={detailPath} className="text-hilight">
        {props?.value || ""}
      </Link>
    </div>
  );
};
