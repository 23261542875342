import { BaseAbstractModal } from "../BaseAbstractModal";
import { BaseButton } from "../BaseButton";

type Props = {
  type: string;
  data: any;
  setLabelModal: Function;
};
type Label = {
  name: string;
  value: string;
};
const findName = (type: string, data: any) => {
  switch (type) {
    case "AL008-U02":
      return data?.registeredDevice?.internalDisplayName;
    case "AL009-U02":
      return data?.control?.displayName;
    default:
      return "-";
  }
};

const renderOriginalLabels = (type: string, data: any) => {
  switch (type) {
    case "AL008-U02":
      return data?.registeredDevice?.originalLabels?.map((label: Label, index: number) => (
        <tr key={"origin" + index}>
          <td>{label.name}</td>
          <td>{label.value}</td>
        </tr>
      ));
    case "AL009-U02":
      return data?.control?.originalLabels?.map((label: Label, index: number) => (
        <tr key={"origin" + index}>
          <td>{label.name}</td>
          <td>{label.value}</td>
        </tr>
      ));
    default:
      return (
        <tr>
          <td>-</td>
          <td>-</td>
        </tr>
      );
  }
};
const renderMutationLabels = (type: string, data: any) => {
  switch (type) {
    case "AL008-U02":
      return data?.registeredDevice?.mutationLabels?.map((label: Label, index: number) => (
        <tr key={"mutation" + index}>
          <td>{label.name}</td>
          <td>{label.value}</td>
        </tr>
      ));
    case "AL009-U02":
      return data?.control?.mutationLabels?.map((label: Label, index: number) => (
        <tr key={"mutation" + index}>
          <td>{label.name}</td>
          <td>{label.value}</td>
        </tr>
      ));
    default:
      return (
        <tr>
          <td>-</td>
          <td>-</td>
        </tr>
      );
  }
};

export const LogLabelModal = ({ type, data, setLabelModal }: Props) => {
  return (
    <BaseAbstractModal size="medium" isOpen={true}>
      <section className="base-abstract-modal__title">
        <h1>Label 변경 상세</h1>
      </section>
      <section className="base-abstract-modal__contents log-modal px30">
        <div className="flex-start">
          <div className="w-100 pr10">
            <h2 className="mb10">원본</h2>
            <table>
              <thead>
                <tr>
                  <th>
                    <p>이름</p>
                  </th>
                  <th>
                    <p>값</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>DisplayName</td>
                  <td>{findName(type, data)}</td>
                </tr>
                {renderOriginalLabels(type, data)}
              </tbody>
            </table>
          </div>
          <div className="w-100 pl10">
            <h2 className="mb10">수정</h2>
            <table>
              <thead>
                <tr>
                  <th>
                    <p>이름</p>
                  </th>
                  <th>
                    <p>값</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>DisplayName</td>
                  <td>{findName(type, data)}</td>
                </tr>
                {renderMutationLabels(type, data)}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <section className="base-abstract-modal__btn-wrap">
        <BaseButton
          type="button"
          title={"확인"}
          onClick={() => setLabelModal({ isOpen: false })}
          className="flex-center-center"
        />
      </section>
      {/* {value.data.registeredDevice?.originalLabels?.toString()} */}
    </BaseAbstractModal>
  );
};
