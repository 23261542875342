import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { getCommonfacilityListAsync } from "src/api/building/commonfacility-api";
import { useApiOperation } from "src/api/hooks";
import { getPriceAsync } from "src/api/price/price-api";
import { PriceModel } from "src/api/price/price-types";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { Modal, Order, PageMeta, Sort } from "src/api/public-types";
import { BaseInput, BaseTable } from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import ProviderSelectModal from "src/components/provider/ProviderSelectModal";
import { useLoadingBarContext } from "src/pages/hooks";
import BuildingSelectModal from "src/pages/notice/forms/components/BuildingSelectModal";
import { Partner } from "src/types/partner";
import { priceColumns } from "./columns/Columns";

type Building = {
  id?: number;
  buildingId: number;
  buildingName: string;
};
type QueryParams = {
  page?: number;
  size?: number;
  searchType?: string;
  keywordList?: string;
  sort?: Sort;
  classification?: string;
  bizType?: string;
  search001?: string;
  partnerId?: string;
  partnerName?: string;
  providerId?: string;
  providerName?: string;
  buildingId?: string;
  buildingName?: string;
};

export type FormData = {
  providerId: string; //프로바이더 ID
};

const PriceList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { executeAsync: getPrice } = useApiOperation(getPriceAsync);
  const { executeAsync: getProvider } = useApiOperation(getProviderList);
  const { executeAsync: getCommonFacilityList } = useApiOperation(getCommonfacilityListAsync);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);
  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState<boolean>(false);
  const [selectedProvider, setSelectedProvider] = useState({
    providerId: "",
    providerName: "",
    partnerId: "",
  });

  const { setLoadingBar } = useLoadingBarContext();

  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    if (!_queryParams?.partnerId) {
      _queryParams.partnerId = selectedProvider.partnerId;
    }
    return _queryParams;
  }, [location]);
  // React Hook Form
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      providerId: "",
    },
  });

  const [params, setParams] = useState<QueryParams>({ ...queryParams });
  const [priceList, setPriceList] = useState<any>([]);
  const [providerModal, setProviderModal] = useState<Modal>({ isOpen: false });
  const [selectedProviders, setSelectedProviders] = useState<ProviderModel[]>();
  const [buildingModal, setBuildingModal] = useState<Modal>({ isOpen: false });
  const [selectedBuildings, setSelectedBuildings] = useState<Building[]>();
  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      console.log("newQueryParams", newQueryParams);

      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, params],
  );

  const onAddSelectPartners = (partners: Partner[]) => {
    setSelectedPartners(partners);
    setIsPartnerModalOpen(false);
    setParams({
      ...params,
      partnerId: String(partners[0].id),
      partnerName: String(partners[0].name),
    });
  };
  const onAddSelectProviders = (providers: ProviderModel[]) => {
    setSelectedProviders(providers);
    setProviderModal({ isOpen: false });
    setParams({
      ...params,
      providerId: String(providers[0].providerId),
      providerName: String(providers[0].providerName),
    });
  };
  const onAddSelectBuildings = (buildings: Building[]) => {
    console.log("선택", buildings);
    setSelectedBuildings(buildings);
    setBuildingModal({ isOpen: false });
    setParams({
      ...params,
      buildingId: String(buildings[0].buildingId),
      buildingName: String(buildings[0].buildingName),
    });
  };

  useEffect(() => {
    const fetchList = async (params: any) => {
      setLoadingBar(true);
      const { buildingName, partnerName, providerName, ...payload } = params;
      const { data, status } = await getPrice(payload);
      if (status >= 200 && status < 300) {
        setPageMeta(data?.meta?.pageMeta);
        let filterPriceList: PriceModel[] = [];
        const providerIdList = new Set(
          data?.data?.content
            .filter((price) => price.providerId !== null)
            .map((price) => price.providerId),
        );
        const commonfacilityIdList = new Set(
          data?.data?.content
            .filter((price) => price.buildingCommonFacilityId !== null)
            .map((price) => price.buildingCommonFacilityId),
        );
        const removeDuplicateProviderIdList = [...providerIdList].join(",");
        const removeDuplicateCommonfacilityIdList = [...commonfacilityIdList].join(",");
        const priceList = [...data?.data?.content];

        if (removeDuplicateProviderIdList && removeDuplicateProviderIdList.length > 0) {
          const { data: providerListData } = await getProvider({
            providerId: removeDuplicateProviderIdList,
          });
          const providerList = providerListData.data.content;

          if (priceList && providerList && providerList.length > 0) {
            const newPriceList = priceList.map((price) => {
              const matchProvider = providerList.find(
                (provider) => provider.providerId === price.providerId,
              );
              return { ...price, providerName: matchProvider?.providerName };
            });
            filterPriceList = newPriceList || [];
          }
        }

        if (removeDuplicateCommonfacilityIdList && removeDuplicateCommonfacilityIdList.length > 0) {
          const { data: commonFacilityListData } = await getCommonFacilityList({
            searchType: "ID",
            keywordList: removeDuplicateCommonfacilityIdList,
          });
          const commonFacilityList = commonFacilityListData.data.content;
          if (priceList && commonFacilityList && commonFacilityList.length > 0) {
            const newPriceList = filterPriceList.map((price: PriceModel) => {
              const matchFacility = commonFacilityList.find(
                (facility) => facility.id === price.buildingCommonFacilityId,
              );
              return {
                ...price,
                buildingName: matchFacility?.buildingName || "",
                facilityName: matchFacility?.facilityName || "",
              };
            });
            filterPriceList = newPriceList;
          }
        }
        console.log("filterPriceList", filterPriceList);
        setPriceList(filterPriceList);
      }
      setLoadingBar(false);
    };
    fetchList(queryParams);
  }, [queryParams, setLoadingBar, getPrice]);

  return (
    <div className="page-product-list">
      <div className="contents-container__search-wrap">
        <div className="left-area">
          <div className="minmax200 mr8">
            <BaseInput
              readonly
              placeholder="파트너"
              value={selectedPartners[0]?.name || params.partnerName}
              onSearchClick={() => setIsPartnerModalOpen(true)}
              onClearClick={() => {
                setSelectedPartners([]);
                const { partnerId, partnerName, ...rest } = params;
                setParams(rest);
              }}
            />
          </div>
          <div className="minmax140 mr8">
            <BaseInput
              type="text"
              value={decodeURIComponent(params?.providerName || "")}
              placeholder="프로바이더 선택"
              readonly
              onChange={(keyword: string) => {
                setParams({ ...params, ...{ providerId: keyword } });
              }}
              onKeyUp={() => setProviderModal({ isOpen: true })}
              onSearchClick={() => setProviderModal({ isOpen: true })}
            />
            {providerModal.isOpen && (
              <ProviderSelectModal
                onCanceled={() => setProviderModal({ isOpen: false })}
                onAdded={onAddSelectProviders}
                defaultValues={selectedProviders || []}
                search={params.providerName}
              />
            )}
          </div>
          <div className="minmax140 mr8">
            <BaseInput
              type="text"
              // value={decodeURIComponent(params?.buildingName || "")}
              value={decodeURIComponent(params?.buildingName || "")}
              placeholder="건물 선택"
              readonly
              onChange={(keyword: string) => {
                setParams({ ...params, ...{ buildingName: keyword } });
              }}
              onKeyUp={() => setBuildingModal({ isOpen: true })}
              onSearchClick={() => setBuildingModal({ isOpen: true })}
            />
            {buildingModal.isOpen && (
              <BuildingSelectModal
                onCanceled={() => setBuildingModal({ isOpen: false })}
                onAdded={onAddSelectBuildings}
                defaultChecked={selectedBuildings || []}
                searchValue={params.buildingName}
              />
            )}
          </div>
          <div className="minmax220 mr16">
            <BaseInput
              className=""
              name="keyword"
              type="text"
              placeholder="검색어를 입력해주세요"
              value={params?.keywordList || ""}
              onChange={(keywordList: string) => setParams({ ...params, keywordList })}
              onKeyUp={() => navigateWithQueryParams({ page: 0 })}
              onSearchClick={() => navigateWithQueryParams({ page: 0 })}
            />
          </div>
        </div>
        <div className="right-area"></div>
      </div>
      {isPartnerModalOpen && (
        <PartnerSelectModal
          onCanceled={() => {
            setIsPartnerModalOpen(false);
          }}
          onAdded={onAddSelectPartners}
          defaultValues={selectedPartners || []}
        />
      )}

      <BaseTable
        data={priceList}
        columns={priceColumns}
        pageIndex={pageMeta?.pageRequest.page || 0}
        totalPages={pageMeta?.totalPages || 0}
        goPage={(page: number) => {
          navigateWithQueryParams({ page });
        }}
        orders={queryParams.sort?.orders}
        disabledSortHeaders={["providerName", "priceRuleType2", "buildingName", "facilityName"]}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } });
          }
        }}
      />
    </div>
  );
};

export default PriceList;
