import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import ApiPath from "src/api/apiPath.json";
import { ApiResponse } from "../public-types";
import {
  NewProductGuide,
  ProductGuideContainer,
  ProductGuideDetailResponseData,
  ProductGuideListRequest,
  ProductGuideListResponse,
  ProductGuideUpdateForm,
  ProductUpdateResponseData,
  ProductUpdateResponseData2,
} from "./product-types";

/* 
  CPP12. 공간상품 사용안내 상세조회 - Old
*/
export async function getProductGuideDetailAsync(
  axios: AxiosInstance,
  params?: {
    productId: number;
    buildingId?: Array<number>;
  },
): Promise<AxiosResponse<ApiResponse<ProductGuideDetailResponseData>>> {
  if (!params?.productId) {
    throw Error("productId is not found.");
  }
  const path = ApiPath.product.productGuide.detailOld.replace("{id}", params.productId.toString());
  return await axios.get(path);
}

/* 
  CPP11.  공간상품 사용안내 (등록/수정) - Old
*/
export async function postProductGuideAsync(
  axios: AxiosInstance,
  params?: {
    productId: number;
    guides: Array<ProductGuideContainer>;
  },
): Promise<AxiosResponse<ApiResponse<ProductUpdateResponseData>>> {
  const { productId, guides } = params || {};
  if (!productId) {
    throw Error("productId is not found.");
  }
  if (!guides || guides.length === 0) {
    throw Error("guides is not found.");
  }

  const path = ApiPath.product.productGuide.detailOld.replace("{id}", productId.toString());
  return await axios.post(path, guides);
}

/* 
  CPP13. 공간상품 사용안내 삭제
*/
export async function deleteProductGuideAsync(
  axios: AxiosInstance,
  params?: {
    productId: number;
    ids: Array<string>; // 삭제 id
  },
): Promise<AxiosResponse<ApiResponse<ProductUpdateResponseData>>> {
  const { productId, ids } = params || {};
  if (!productId) {
    throw Error("productId is not found.");
  }
  if (!ids || ids.length === 0) {
    throw Error("ids is not found.");
  }

  const path = ApiPath.product.productGuide.detailOld.replace("{id}", productId.toString());
  return await axios.delete(path, { data: { ids } });
}

/* 
   CPP14. 공간상품 사용안내 등록 - New
*/
export async function postProductGuideAsyncNew(
  axios: AxiosInstance,
  params?: {
    productId: number;
    guides: ProductGuideUpdateForm;
  },
): Promise<AxiosResponse<ApiResponse<ProductUpdateResponseData2>>> {
  const { productId, guides } = params || {};
  if (!productId) {
    throw Error("productId is not found.");
  }

  const path = ApiPath.product.productGuide.update.replace("{id}", String(productId));
  return await axios.post(path, guides?.productGuide);
}

/* 
   CPP15. 공간상품 사용안내 수정 - New
*/
export async function eidtProductGuideAsync(
  axios: AxiosInstance,
  params?: {
    productId: number;
    guides: ProductGuideUpdateForm;
  },
): Promise<AxiosResponse<ApiResponse<ProductUpdateResponseData2>>> {
  const { productId, guides } = params || {};
  if (!productId) {
    throw Error("productId is not found.");
  }

  const path = ApiPath.product.productGuide.update.replace("{id}", String(productId));
  return await axios.put(path, guides?.productGuide);
}

// CPP16. 공간상품 사용안내 목록조회

export const getProductGuideListAysnc = async (
  axios: AxiosInstance,
  params?: ProductGuideListRequest,
): Promise<AxiosResponse<ApiResponse<ProductGuideListResponse>>> => {
  if (!params?.productId) {
    throw Error("productId is not found.");
  }

  if (!params.query.buildingId) {
    throw Error("buildingId is not found.");
  }

  return await axios.get(
    `${ApiPath.product.productGuide.list.replace("{id}", String(params?.productId))}`,
    {
      params: params.query,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  );
};

// CPP17. 공간상품 사용안내 상세조회
export const fetchProductGuideDetailAsync = async (
  axios: AxiosInstance,
  params?: {
    guideId: string;
    isOpened: boolean;
  },
): Promise<AxiosResponse<ApiResponse<{ content: NewProductGuide }>>> => {
  return await axios.get(
    `${ApiPath.product.productGuide.detail.replace("{id}", String(params?.guideId))}/?isOpened=${
      params?.isOpened
    }`,
  );
};
