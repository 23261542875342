import {
  MemberAccountStatusChangeReasonType,
  MemberAccountStatusType,
} from "src/api/member/member-types";
import { BaseTooltip } from "src/components/BaseTooltip";
const AccountHistoryColumns: any = [
  {
    Header: "id",
    accessor: "id",
    // sticky: "left",
    width: 100,
  },
  {
    Header: "상태",
    accessor: "status",
    Cell: ({ value }: any) => {
      return (
        <>
          <div
            className={`${
              (value === MemberAccountStatusType.AVAILABLE && "chip green") ||
              (value === MemberAccountStatusType.LOCKED && "chip red") ||
              (value === MemberAccountStatusType.INACTIVE && "chip red") ||
              (value === MemberAccountStatusType.DELETE && "chip gray")
            }`}
          >
            {(value === MemberAccountStatusType.AVAILABLE && "정상") ||
              (value === MemberAccountStatusType.LOCKED && "일시 정지") ||
              (value === MemberAccountStatusType.INACTIVE && "휴면 계정") ||
              (value === MemberAccountStatusType.DELETE && "휴면 계정")}
          </div>
        </>
      );
    },
    width: 180,
  },
  {
    Header: "변경 일시",
    accessor: "statusDate",
    width: 300,
    Cell: (props: any) => {
      return (
        <div>
          <BaseTooltip contents={props.value} type={"date"} />
        </div>
      );
    },
  },
  {
    Header: "변경 사유",
    accessor: "cause",
    width: 300,
    Cell: ({ value }: any) => {
      return (
        <>
          <div>
            {(value === MemberAccountStatusChangeReasonType.JOIN && "신규 회원 가입") ||
              (value === MemberAccountStatusChangeReasonType.LOSS_PHONE_NUMBER_VERIFIED &&
                "휴대폰 점유 인증 상실") ||
              (value === MemberAccountStatusChangeReasonType.RESTRICTION && "이용 중지") ||
              (value === MemberAccountStatusChangeReasonType.OPERATOR_CHANGE_STATE &&
                "관리자에 의한 상태 변경") ||
              (value === MemberAccountStatusChangeReasonType.CHANGE_DEVICE && "기기 변경")}
          </div>
        </>
      );
    },
  },
  {
    Header: "단말기 정보",
    accessor: "login",
    width: 300,
    Cell: ({ row }: any) => {
      const login = row.original?.login?.client;

      if (login) {
        return (
          <div className="">
            <span className="mr5">{login?.device?.family}</span>
            <span className="mr5">
              ({login?.userAgent?.family} {login?.userAgent?.major})
            </span>
            <span>
              {login?.os?.family} {login?.os?.major}
            </span>
          </div>
        );
      }
    },
  },
];
export default AccountHistoryColumns;
