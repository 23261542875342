import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "..";
import { ApiResponse } from "../public-types";
import {
  AdminMemoAddModel,
  AdminMemoAddResponse,
  AdminMemoDeleteModel,
  AdminMemoListParams,
  AdminMemoListReseponse,
  AdminMemosResponse,
  AdminMemoType,
  DeleteAdminMemo,
  InsertOrUpdate,
  Memo,
  RequsetAdminMemo,
} from "./adminmemo-types";

// 관리자 메모 등록
export const AddAdminMemoAsync = async (
  axios: AxiosInstance,
  params?: {
    serviceId: string;
    memo: string;
    adminMemoType: string;
  },
): Promise<AxiosResponse<ApiResponse<AdminMemoAddResponse>>> => {
  if (params?.adminMemoType === "RESERVATION") {
    return await axios.post(ApiPath.reservation.admin.add, params);
  } else {
    return await axios.post(ApiPath.admin.add, params);
  }
};

//  관리자 메모 수정
export const EditAdminMemoAsync = async (
  axios: AxiosInstance,
  params?: {
    id: string;
    serviceId: string;
    memo: string;
    adminMemoType: string;
    // adminMemo?: AdminMemoAddModel;
    // adminMemoType?: AdminMemoType;
  },
): Promise<AxiosResponse<ApiResponse<AdminMemoAddResponse>>> => {
  if (params?.adminMemoType === "RESERVATION") {
    return await axios.put(ApiPath.reservation.admin.edit, params);
  } else {
    return await axios.put(ApiPath.admin.edit, params);
  }
};

//  관리자 메모 목록
export const getAdminMemoListAsync = async (
  axios: AxiosInstance,
  params?: AdminMemoListParams,
): Promise<AxiosResponse<ApiResponse<AdminMemoListReseponse>>> => {
  if (params?.adminMemoType === "RESERVATION") {
    return await axios.get<ApiResponse<AdminMemoListReseponse>>(ApiPath.reservation.admin.list, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    });
  } else {
    return await axios.get<ApiResponse<AdminMemoListReseponse>>(ApiPath.admin.list, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    });
  }
};

//  관리자 메모 삭제
export const DeleteAdminMemoAsync = async (
  axios: AxiosInstance,
  params?: AdminMemoDeleteModel,
): Promise<AxiosResponse<ApiResponse<AdminMemoAddResponse>>> => {
  const data = {
    ids: params?.ids,
    adminMemoType: params?.adminMemoType,
  };
  if (params?.adminMemoType === "RESERVATION") {
    return await axios.delete(ApiPath.reservation.admin.delete, { data });
  } else {
    return await axios.delete(ApiPath.admin.delete, { data });
  }
};

/**
 * 2023-09-12 이 후 톤합 관리자 메모 작업
 * admin memo 통합 API 조회
 * @param axios
 * @param params
 * contentsList[{index}].serviceId, ex) 1
 * contentsList[{index}].serviceType ex) SERVICE_PRODUCT
 * @returns
 */
export const getAdminMemoList = async (
  axios: AxiosInstance,
  params?: RequsetAdminMemo,
): Promise<AxiosResponse<ApiResponse<AdminMemosResponse>>> => {
  return axios.get(ApiPath.memo, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

/**
 * 2023-09-12 이 후 톤합 관리자 메모 작업
 * admin memo 통합 API 등록
 * @param axios
 * @param params InsertOrUpdate
 * @returns
 */
export const addAdminMemo = async (
  axios: AxiosInstance,
  params?: InsertOrUpdate,
): Promise<AxiosResponse<ApiResponse<{ content: Memo }>>> => {
  return axios.post(ApiPath.memo, params!.data);
};

/**
 * 2023-09-12 이 후 톤합 관리자 메모 작업
 * admin memo 통합 API 수정
 * @param axios
 * @param params InsertOrUpdate
 * @returns
 */
export const editAdminMemo = async (
  axios: AxiosInstance,
  params?: InsertOrUpdate,
): Promise<AxiosResponse<ApiResponse<{ content: Memo }>>> => {
  return axios.put(ApiPath.memo, params!.data);
};

/**
 * 2023-09-12 이 후 톤합 관리자 메모 작업
 * admin memo 통합 API 삭제
 * @param axios
 * @param url string
 * @returns
 */
export const deleteAdminMemo = async (
  axios: AxiosInstance,
  params?: DeleteAdminMemo,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  return axios.delete(String(params?.deleteUri!));
};
