import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { getContractFacilityList } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { BaseCheckbox, BaseInput, BaseModal, BasePagination, BaseTooltip } from "src/components";

const columnHeader: any = [
  {
    Header: "선택",
    accessor: "isSelected",
    // sticky: "left",
    width: 90,
    Cell: ({ value, row, contract, setContracts, checkSelectedLength }: any) => {
      const [st, setSt] = useState(false);
      useEffect(() => {
        setSt(value);
      }, [value]);

      const changeTrigger = useCallback(
        (trigger: boolean) => {
          const newArr = JSON.parse(JSON.stringify(contract));
          for (let i = 0; i < newArr.length; i++) {
            const s: any = newArr[i];
            if (s.id === row.original.id) {
              s.isSelected = trigger;
            }
          }

          setContracts(newArr);
          setSt(value);
        },
        [contract, row.original.id, setContracts, value],
      );

      return (
        <div>
          <BaseCheckbox
            id={row.id}
            name={"isSelected"}
            checked={st}
            onChange={(value: boolean) => changeTrigger(value)}
          />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "id",
    // sticky: "left",
    width: 90,
  },
  {
    Header: "공용공간분류",
    accessor: "commonFacilityType",
    width: 100,
    Cell: ({ value }: any) => {
      return <BaseTooltip contents={value} />;
    },
  },
  {
    Header: "그룹명",
    accessor: "groupName",
    width: 90,
    Cell: ({ value }: any) => {
      return <>{value ? value : "-"}</>;
    },
  },
  {
    Header: "공용공간명",
    accessor: "facilityName",
    width: 120,
    Cell: ({ value }: any) => {
      return <BaseTooltip contents={value} />;
    },
  },

  {
    Header: "정원",
    accessor: "facilityMaxPeopleNums",
    width: 90,
    Cell: ({ value }: any) => {
      return <>{value ? value + "인" : "-"}</>;
    },
  },
  {
    Header: "설명",
    accessor: "description",
    width: 120,
    Cell: ({ value }: any) => {
      return <BaseTooltip contents={value} />;
    },
  },
  {
    Header: "지상/지하",
    accessor: "isGround",
    width: 90,
    Cell: ({ value }: any) => {
      return <>{value ? "지상" : "지하"}</>;
    },
  },
  {
    Header: "위치",
    accessor: "floorNum",
    width: 80,
    Cell: ({ value }: any) => {
      return <>{value}층</>;
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 180,
  },
];
type Props = {
  contractId: number;
  type: string;
  operation: any;
  setOperation: any;
  isOpen: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const SearchContractFacilityPopup = (props: Props) => {
  // console.log("props.type", props.type);
  const [contract, setContracts] = useState<any>([]);
  const [isSave, setIsSave] = useState(false);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { executeAsync: getContractFacilities } = useApiOperation(getContractFacilityList);
  const [keyword, setKeyword] = useState("");
  const [params, setParams] = useState<any>({
    page: 0,
    size: 20,
    search001: "",
  });

  const sendArr = useCallback(() => {
    if (props.onClick) {
      props?.onClick(contract);
    }
    setIsSave(false);
  }, [contract, props]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columnHeader,
      data: contract,
      contract,
      setContracts,
      operation: props.operation,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  const onClickData = useCallback(() => {
    setIsSave(true);
  }, []);

  useEffect(() => {
    if (props.isOpen && props.type) {
      const response = props.operation.prProductFacility;
      for (let i = 0; i < response.length; i++) {
        response[i].isSelected = false;
      }
      let commonFacilityTypeList: any = response.filter(
        (pf: any) => pf.commonFacilityType === props.type,
      );
      console.log("commonFacilityTypeList", commonFacilityTypeList);
      let alreadyUsed = props.operation.ctProductFacility.filter(
        (pf: any) => pf.commonFacilityType === props.type,
      );
      console.log("alreadyUsed", alreadyUsed);

      if (commonFacilityTypeList.length > 0) {
        for (let i = 0; i < commonFacilityTypeList.length; i++) {
          const find = alreadyUsed.find(
            (ct: any) => ct.isDeleted === false && ct.id === commonFacilityTypeList[i].id,
          );
          console.log("find", find);
          if (find) {
            commonFacilityTypeList[i].disabled = true;
            commonFacilityTypeList[i].isSelected = true;
          } else {
            commonFacilityTypeList[i].disabled = false;
            commonFacilityTypeList[i].isSelected = false;
          }
        }
      }

      let notDeleted: any = [];
      commonFacilityTypeList.forEach((pr: any) => {
        if (pr.isDeleted === false) {
          notDeleted.push(pr);
        }
      });

      if (notDeleted.length > 0) {
        setContracts(notDeleted);
      } else {
        setContracts([]);
      }
    }
  }, [props]);

  return (
    <div>
      <BaseModal
        className="dialog-modal"
        isOpen={props.isOpen}
        btnRightTitle="선택"
        btnLeftTitle="취소"
        onClick={onClickData}
        onClose={props.onClose}
      >
        <>
          <div className="modal-title">
            <div className="left-area">
              <span>공용공간 추가</span>
            </div>
            <div className="right-area">
              <div className="minmax260">
                <BaseInput
                  type="text"
                  value={keyword || ""}
                  placeholder="검색어를 입력해주세요"
                  onChange={(value: string) => {
                    setKeyword(value);
                  }}
                  onSearchClick={() => {
                    setParams({ ...params, ...{ keyword } });
                  }}
                />
              </div>
            </div>
          </div>
          {/* table */}
          <div className="contents-container__table">
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.length > 0 &&
                  rows.map((row: any) => {
                    prepareRow(row);
                    return (
                      <div
                        {...row.getRowProps()}
                        className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                      >
                        {row.cells.map((cell: any) => {
                          return (
                            <div {...cell.getCellProps()} className="base-table__td">
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                {rows.length === 0 && (
                  <div className="base-table__tr" style={{ width: tableWidth }}>
                    <div className="base-table__td w-100 text-center">
                      <div className="w-100">
                        <span>데이터가 없습니다.</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <BasePagination
              pageIndex={Number(params.page) || 0}
              totalPages={Number(pageMeta?.totalPages) || 0}
              goPage={(page: number) => {
                setParams({ ...params, ...{ page } });
              }}
            />
          </div>
        </>
      </BaseModal>
      <BaseModal
        isOpen={isSave}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setIsSave(false)}
        onClick={() => sendArr()}
      >
        <p>선택항목을 추가 하시겠습니까?</p>
      </BaseModal>
    </div>
  );
};

export default SearchContractFacilityPopup;
