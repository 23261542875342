import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "..";
import { ApiResponse } from "../public-types";

import {
  AppPushAudience,
  AppPushNotification,
  MessageListParams,
  getMessageEmailListResponseData,
  getMessageKakaotalkListResponseData,
  getMessageSMSListResponseData,
} from "./message-types";

/*
   CECM01. 휴대폰 메세지 발신 이력 목록 조회
*/
export async function getMessageSMSListAsync(
  axios: AxiosInstance,
  params?: MessageListParams,
): Promise<AxiosResponse<ApiResponse<getMessageSMSListResponseData>>> {
  const path = ApiPath.message.SMS;
  return await axios.get<ApiResponse<getMessageSMSListResponseData>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/*
   CECM02. 카카오톡 발송 이력 목록 조회
*/
export async function getMessageKakaotalkListAsync(
  axios: AxiosInstance,
  params?: MessageListParams,
): Promise<AxiosResponse<ApiResponse<getMessageKakaotalkListResponseData>>> {
  const path = ApiPath.message.kakaotalk;
  return await axios.get<ApiResponse<getMessageKakaotalkListResponseData>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/*
   CECM03. Email 발송 이력 목록 조회
*/
export async function getMessageEmailListAsync(
  axios: AxiosInstance,
  params?: MessageListParams,
): Promise<AxiosResponse<ApiResponse<getMessageEmailListResponseData>>> {
  const path = ApiPath.message.email;
  return await axios.get<ApiResponse<getMessageEmailListResponseData>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/**
 * CECM04. 앱 푸쉬 알림 발송 이력 목록 조회
 */
export const getMessageAppPushListAsync = async (
  axios: AxiosInstance,
  params?: MessageListParams,
): Promise<AxiosResponse<ApiResponse<AppPushNotification[]>>> => {
  return await axios.get(ApiPath.message.app.appNoti, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

/**
  CECA01. 앱 알림 수신자 정보 목록 조회
 */
export const getAudiencesAsync = async (
  axios: AxiosInstance,
  params?: MessageListParams,
): Promise<AxiosResponse<ApiResponse<{ content: AppPushAudience[] }>>> => {
  return await axios.get(ApiPath.message.app.audiences, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};
