import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiResponse } from "src/api/public-types";
import { WorkOrderRevisionParams } from "src/pages/workOrder/workOrder-types";
import { ApiPath } from "..";
import {
  WorkOrderDetailModel,
  WorkOrderDetailResponse,
  WorkOrderListResponse,
  WorkOrderPostType,
  WorkOrderPreviewRequest,
  WorkOrderPreviewResponse,
  WorkOrderQueryParams,
  WorkOrderRevisionsResponse,
  WorkOrderUpdateModel,
} from "./workOrder-types";

/**CPW01. 워크시트 등록 */
export const WorkOrderPostAsync = async (
  axios: AxiosInstance,
  params?: WorkOrderPostType,
): Promise<AxiosResponse<ApiResponse<{ content: Array<WorkOrderDetailModel> }>>> => {
  return await axios.post(ApiPath.workOrder.add, params);
};

/**CPW02. 워크시트 수정*/
export const WorkOrderEditAsync = async (
  axios: AxiosInstance,
  params?: { id: string; workOrderSheet: WorkOrderUpdateModel },
): Promise<AxiosResponse<ApiResponse<{ content: Array<WorkOrderDetailModel> }>>> => {
  if (!params?.id) {
    throw Error("workOrder id is not found");
  }

  return await axios.put(ApiPath.workOrder.edit.replace("{id}", String(params?.id)), {
    workOrderSheet: params.workOrderSheet,
  });
};

/**CPW04. 워크시트 상세조회 */
export const WorkOrderDetailAsync = async (
  axios: AxiosInstance,
  params?: { id: number },
): Promise<AxiosResponse<ApiResponse<WorkOrderDetailResponse>>> => {
  if (!params?.id) {
    throw Error("workOrder id is not found");
  }
  return await axios.get(ApiPath.workOrder.detail.replace("{id}", String(params?.id)));
};

/**CPW05. 워크시트 목록조회  */
export const WorkOrderListAsync = async (
  axios: AxiosInstance,
  params?: WorkOrderQueryParams,
): Promise<AxiosResponse<ApiResponse<WorkOrderListResponse>>> => {
  return await axios.get(ApiPath.workOrder.list, {
    params,
    paramsSerializer: (param) => qs.stringify(param, { allowDots: true, encode: true }),
  });
};

/**CPW06. 워크시트 반복일정 미리보기 */
export const WorkOrderPreviewAsync = async (
  axios: AxiosInstance,
  params?: WorkOrderPreviewRequest,
): Promise<AxiosResponse<ApiResponse<WorkOrderPreviewResponse>>> => {
  return axios.get(ApiPath.workOrder.preview, { params: params });
};

// CPW07. 워크시트 취소
export const WorkOrderCancelAsync = async (
  axios: AxiosInstance,
  params?: {
    ids?: string;
    groupIds?: string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: number }>>> => {
  // if (!params?.ids || !params?.groupIds) {
  //   throw Error("ids / groupIds are not found");
  // }

  let cancelData;
  let path = "";

  if (params?.ids && !params.groupIds) {
    cancelData = { ids: params?.ids };
    path = `${ApiPath.workOrder.cancel}?ids=${params.ids}`;
  } else {
    cancelData = { groupIds: params?.groupIds };
    path = `${ApiPath.workOrder.cancel}?groupIds=${params?.groupIds}`;
  }

  return axios.patch(path, {
    params: cancelData,
  });
};

/**
 * CPW09. 워크시트 단일 리비전 조회
 * https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/215416868/CPW09. */

export const getWorkOrderRevisionsAsync = (
  axios: AxiosInstance,
  params?: WorkOrderRevisionParams,
): Promise<AxiosResponse<ApiResponse<WorkOrderRevisionsResponse>>> => {
  return axios.get(ApiPath.workOrder.revisions, {
    params,
    paramsSerializer: (param) => qs.stringify(param, { allowDots: true, encode: true }),
  });
};
