import { useCallback, useEffect, useState } from "react";
import { CommonFacilityModel, DeskGroupModel } from "src/api/building/building-types";
import { getDesksAsync } from "src/api/building/deskgroup-api";
import { useApiOperation } from "src/api/hooks";
import { MediaFile } from "src/api/public-types";
import { DeskItem } from "src/pages/building/building-types";
import { useLoadingBarContext } from "src/pages/hooks";

type Props = {
  buildingId: number | undefined;
  deskGroup: DeskGroupModel | undefined;
};

type FormData = {
  mediaFile?: MediaFile | null; // 좌석배치도 이미지
  deskChartDescription?: string; // 좌석배치도 설명
};

const s3Url = process.env.REACT_APP_S3_BASEURL;

/* 
  건물 > 상세 > 좌석 배치도 > 좌석 그룹 선택시 노출되는 하단 컴포넌트
 */
const DeskLayout = ({ buildingId, deskGroup }: Props) => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 좌석 그룹별 좌석 목록 조회 api
  const { executeAsync: getDesks } = useApiOperation(getDesksAsync);

  const [formData, setFormData] = useState<FormData>();

  // 좌석 목록
  const [deskItems, setDeskItems] = useState<DeskItem[]>([]);

  // 배치도에 추가한 좌석 목록
  const [addedDeskItems, setAddedDeskItems] = useState<DeskItem[]>([]);

  // form 리셋
  const resetForm = useCallback(() => {
    setDeskItems([]);
    setAddedDeskItems([]);
  }, []);

  // 좌석 그룹별 좌석 목록 조회
  const fetchDesks = useCallback(
    async (buildingId: number, deskGroupId: number) => {
      setLoadingBar(true);
      const { data } = await getDesks({ buildingId, deskGroupId });
      const _deskGroup = data?.data?.content || null;

      setFormData({
        deskChartDescription: _deskGroup?.deskChartDescription || "", // 좌석 배치도 설명
        mediaFile: (_deskGroup.mediaList || []).length > 0 ? _deskGroup.mediaList[0] : null, // // 좌석 배치도 이미지
      });

      // 좌석 컴포넌트
      const deskList = _deskGroup?.deskList || [];
      const deskItems: DeskItem[] = deskList
        .map((desk: CommonFacilityModel) => {
          const deskItem: DeskItem = {
            id: String(desk?.id || ""),
            name: desk?.facilityName || "",
            left: Number(desk?.centerX || 0),
            top: Number(desk?.centerY || 0),
          };
          return deskItem;
        })
        .sort((a: DeskItem, b: DeskItem) => Number(b.id || 0) - Number(a.id || 0));
      setDeskItems(deskItems);

      // 좌석 배치되어있는 컴포넌트
      const addedDeskItems = deskItems.filter(
        (deskItem: DeskItem) => deskItem.left > 0 && deskItem.top > 0,
      );
      setAddedDeskItems(addedDeskItems);

      setLoadingBar(false);
    },
    [getDesks, setLoadingBar],
  );

  useEffect(() => {
    if (buildingId && deskGroup && deskGroup.id) {
      resetForm();
      fetchDesks(buildingId, Number(deskGroup.id));
    }
  }, [buildingId, resetForm, deskGroup, fetchDesks]);

  if (!deskGroup) return null;

  return (
    <>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">설명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{formData?.deskChartDescription}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">이미지</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-center-start flex-row">
            {formData?.mediaFile?.filename && (
              <div className="image-preview-area responsive">
                {addedDeskItems.map((deskItem: DeskItem) => (
                  <div
                    key={deskItem.id}
                    className="desc-comp"
                    style={{ left: deskItem.left, top: deskItem.top }}
                  >
                    {deskItem.name}
                  </div>
                ))}
                <img
                  src={s3Url + (formData?.mediaFile?.key || "")}
                  alt="컴포넌트"
                  draggable={false}
                />
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="contents-container__grid contents-container__1200">
        <div className="contents-container__grid-index">
          <p>컴포넌트</p>
        </div>
        <div className="contents-container__grid-contents">
          <div>
            <table className="inner-table text-center">
              <thead>
                <tr>
                  <th>
                    <span>상태</span>
                  </th>
                  <th>
                    <span>id</span>
                  </th>
                  <th>
                    <span>좌석명</span>
                  </th>
                  <th>
                    <span>X 좌표 / Y 좌표</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {deskItems.length === 0 && (
                  <tr>
                    <td colSpan={4}>
                      <span>데이터가 없습니다.</span>
                    </td>
                  </tr>
                )}
                {deskItems.map((deskItem: DeskItem) => (
                  <tr key={deskItem.id}>
                    <td width="80">
                      <div className="flex-center-center">
                        {!!addedDeskItems.find((d: DeskItem) => d.id === deskItem.id) ? (
                          <div className="color-chip green">
                            <span>추가됨</span>
                          </div>
                        ) : (
                          <div className="color-chip gray">
                            <span>미추가</span>
                          </div>
                        )}
                      </div>
                    </td>
                    <td width="160">
                      <div>
                        <p>{deskItem.id}</p>
                      </div>
                    </td>
                    <td width="auto">
                      <p>{deskItem.name}</p>
                    </td>
                    <td width="200">
                      <div className="flex-center-center">
                        {Number(deskItem?.left || 0)} / {Number(deskItem?.top || 0)}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeskLayout;
