import React from "react";
import { Cell } from "react-table";
import { ProductModel } from "src/api/product/product-types";

const IsUsedCell = (props: Cell<ProductModel>) => {
  return (
    <>
      <div
        className={`${
          (props?.value === true && "chip green") || (props?.value === false && "chip gray")
        }`}
      >
        {props.value === true ? <span>이용중</span> : <span>미이용</span>}
      </div>
      {/* <div>{props.value === true ? <span>이용중</span> : <span>이용중 X</span>}</div>; */}
    </>
  );
};

export default IsUsedCell;
