import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Cell } from "react-table";
import useApiOperation from "src/api/hooks/api-operation";
import { getPartners } from "src/api/partner";
import { BaseModal, BaseInput, BaseButton, BasePagination, BaseCheckbox } from "src/components";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { Partner } from "src/types/partner";

interface Props {
  onCanceled: () => void;
  onAdded: (partnerList: Partner[]) => void;
  defaultValues: Partner[];
  multiSelect?: boolean;
  disabledIds?: string[];
}

interface CellProps extends Cell<Partner> {
  checked: boolean;
  disabled?: boolean;
}

const STATUS_MAP = {
  STANDBY: {
    name: "대기",
    color: "gray",
  },
  ACTIVE: {
    name: "활성",
    color: "green",
  },
  SHUTDOWN: {
    name: "종료",
    color: "red",
  },
};

const PARTNER_TABLE_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 100,
    Cell: (props: any) => {
      const partnerId = props.row.cells[1].value;
      const isDisabled = props.row.original.disabled;

      return (
        <div data-partner-id={partnerId} data-checked={props.value} className="checkbox">
          <BaseCheckbox id={""} name={""} checked={props.value} disabled={isDisabled} />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "id",
    width: 100,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "파트너 코드",
    accessor: "code",
    width: 250,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "파트너 이름",
    accessor: "name",
    width: 250,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "상태",
    accessor: "status",
    width: 100,
    Cell: (props: CellProps) => {
      const status = STATUS_MAP[props.value as keyof typeof STATUS_MAP];

      return <div className={`color-chip ${status.color}`}>{status.name}</div>;
    },
  },
];

function PartnerSelectModal({
  onCanceled,
  onAdded,
  defaultValues,
  multiSelect,
  disabledIds,
}: Props) {
  const [searchedWord, setSearchedWord] = useState("");
  const [page, setPage] = useState({ current: 0, total: 0 });
  const [partnerList, setPartnerList] = useState<Partner[]>([]);
  const [selectedPartnerList, setSelectedPartnerList] = useState<Partner[]>(defaultValues);

  const partnerListTableData = partnerList.map((partner) => {
    const selectedIdList = selectedPartnerList.map((partner) => partner.id);
    if (disabledIds) {
      return {
        disabled: disabledIds.includes(partner.id!),
        checked: selectedIdList.includes(partner.id),
        ...partner,
      };
    } else
      return {
        checked: selectedIdList.includes(partner.id),
        ...partner,
      };
  });

  const searchFormRef = useRef<HTMLFormElement>(null);
  const { handleSubmit, control } = useForm<{ searchWord: string }>();
  const { executeAsync: getPartnersAsync } = useApiOperation(getPartners);

  const onSubmit = ({ searchWord }: { searchWord: string }) => setSearchedWord(searchWord);
  const goPage = (nextPage: number) => setPage({ ...page, current: nextPage });

  const fetchPartnerList = async (searchWord: string) => {
    if (searchWord !== "") {
      setPage({ current: 0, total: 0 });
    }
    const result = await getPartnersAsync({
      search002: searchWord,
      page: page.current,
      size: 20,
      orders: [
        {
          property: "id",
          direction: "DESC",
        },
      ],
    });

    setPartnerList(result.data.data.content);

    setPage({
      ...page,
      total: result.data.meta.pageMeta?.totalPages || 0,
    });
  };

  const handleCheckboxClick = (checkbox: HTMLDivElement) => {
    const partnerId = checkbox.dataset.partnerId;
    const checked = Boolean(checkbox.dataset.checked === "true");

    if (!checked) {
      const selectedPartner = partnerList.find((partner) => partner.id === partnerId);

      if (multiSelect) {
        setSelectedPartnerList([...selectedPartnerList, selectedPartner!]);
      } else {
        setSelectedPartnerList([selectedPartner!]);
      }
    } else {
      const filteredPartnerList = selectedPartnerList.filter((partner) => partner.id !== partnerId);
      setSelectedPartnerList(filteredPartnerList);
    }
  };
  const handleModalContentsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;

    const tr = target.closest(".base-table__tr");
    const td = tr?.children[0];
    const checkbox = td?.children[0] as HTMLDivElement;

    if (checkbox) handleCheckboxClick(checkbox);
  };
  const handleSearchClick = () => {
    searchFormRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );
  };
  const handleCancelButtonClick = () => onCanceled();
  const handleAddSelectedPartnerButtonClick = () => onAdded(selectedPartnerList);

  useEffect(() => {
    fetchPartnerList(searchedWord);
  }, [page.current, searchedWord]);

  return (
    <BaseModal isOpen={true} className="dialog-modal">
      <div>
        <div className="modal-title">
          <div className="left-area">
            <span>파트너 선택</span>
          </div>
          <form className="right-area" onSubmit={handleSubmit(onSubmit)} ref={searchFormRef}>
            <Controller
              name="searchWord"
              control={control}
              render={({ field }) => (
                <BaseInput
                  placeholder="검색어를 입력하세요"
                  value={field.value}
                  onChange={field.onChange}
                  onSearchClick={handleSearchClick}
                />
              )}
            ></Controller>
          </form>
        </div>
        <div className="base-modal__contents" onClick={handleModalContentsClick}>
          <ViewDataTable columns={PARTNER_TABLE_COLUMNS} data={partnerListTableData} />
          <BasePagination pageIndex={page.current} totalPages={page.total} goPage={goPage} />
        </div>
        <div className="base-modal__btn-wrap">
          <BaseButton
            title={"취소"}
            className="color-white no-outline text-primary3 mr10"
            onClick={handleCancelButtonClick}
          />
          <BaseButton title={"선택 추가"} onClick={handleAddSelectedPartnerButtonClick} />
        </div>
      </div>
    </BaseModal>
  );
}
export default PartnerSelectModal;
