import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { getScheduleRoundList } from "src/api/billingSchedule/billingSchedule-api";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { contract_bill_user_errors } from "src/api/public-types";
import { BaseModal } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { columns } from "./columns/Columns";

type Props = {
  contractId: number;
  isOpen: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ModifyAmountModal = (props: Props) => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const [data, setData] = useState<any>([]);

  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundList, {
    doNotErrorHandleModal: true,
  });

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns,
      data,
      setData,
      initialState: { pageSize: 1000 },
    },
    useBlockLayout,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const callRoundList = useCallback(
    async (id: number) => {
      setLoadingBar(true);
      const response: any = await getRoundList({ scheduleId: Number(id), isAll: true });

      // console.log("response", response.data.data);
      if (response.status >= 200 && response.status <= 299) {
        const sorted = _.sortBy(response.data.data.content, ["billOrder"]);
        setData(sorted);
      } else {
        let message = errorMessageHandler(
          response.status,
          response.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.staus;
        openErrorModal(message, code);
      }
      setLoadingBar(false);
    },
    [getRoundList, openErrorModal, setLoadingBar],
  );
  useEffect(() => {
    if (props.isOpen && props.contractId) {
      callRoundList(Number(props.contractId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen, props.contractId]);

  return (
    <div>
      <BaseModal
        className="dialog-modal"
        isOpen={props.isOpen}
        btnRightTitle="확인"
        onClick={props.onClose}
      >
        <>
          <div className="modal-title">
            <div className="left-area align-items-center">
              <span>회차별 금액 확인</span>
              {/* <p className="sub-title-info ml40">
                청구 금액을 회차별 개별 설정할 수 있습니다. <br />
                부가세를 포함한 정확한 금액으로 입력해주세요.
              </p> */}
            </div>
            <div className="right-area"></div>
          </div>
          <div>
            <div {...getTableProps()} className="base-table sticky overflow-x-hidden">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                          <div className="ic_sort"></div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {page.map((row: any) => {
                  prepareRow(row);

                  return (
                    <div
                      {...row.getRowProps()}
                      className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}
                      ${row.original.isOverdueBill ? "bg-blue-opacity07" : ""}`}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      </BaseModal>
    </div>
  );
};

export default ModifyAmountModal;
