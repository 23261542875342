import moment from "moment";
import { Cell } from "react-table";
import { QuestionAnswerModel, QuestionListModel } from "src/api/question/question-types";
import { BaseTooltip } from "src/components";

import { PagePath } from "src/pages/product/details";
import { YmdFormat, numberToStringWithComma } from "src/utils";
import { siteOptions, statusOptions } from "../../question-types";
import DetailLink from "src/components/DetailLink";
import { Link } from "react-router-dom";

const QuestionColmns = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: ({ row, value }: Cell<QuestionListModel>) => {
      const detailPath = `${PagePath.question.detail.replace(":id", value)}`;
      return (
        <DetailLink to={detailPath} className="text-underline">
          {value}
        </DetailLink>
      );
    },
  },
  {
    Header: "상태",
    accessor: "status",
    width: 80,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      let status = statusOptions.find((option) => option.value === value)?.label;
      let color = statusOptions.find((option) => option.value === value)?.tagColor;

      return <span className={color}>{status}</span>;
    },
  },
  {
    Header: "구분",
    accessor: "csCategoryType",
    width: 80,
  },
  {
    Header: "유입경로",
    accessor: "site",
    width: 100,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      const site = siteOptions.find((data) => data.value === value)?.label;
      return (
        <div>
          <span>{site || "-"}</span>
        </div>
      );
    },
  },

  {
    Header: "유형",
    accessor: "csTypeName",
    width: 100,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <span>{value || "-"}</span>;
    },
  },

  {
    Header: "항목",
    accessor: "summary",
    width: 200,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <BaseTooltip className="text-left w-100" contents={value ?? "-"}></BaseTooltip>;
    },
  },
  {
    Header: "문의 내용",
    accessor: "description",
    width: 280,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <BaseTooltip className="text-left w-100" contents={value ?? "-"}></BaseTooltip>;
    },
  },
  {
    Header: "답변 내용",
    accessor: "answerList",
    width: 280,
    Cell: ({ row }: Cell<QuestionListModel>) => {
      const answer = row?.original?.answerList?.sort(
        (a: QuestionAnswerModel, b: QuestionAnswerModel) => Number(b.id) - Number(a.id),
      )?.[0].description;

      return <BaseTooltip className="text-left w-100" contents={answer ?? "-"}></BaseTooltip>;
    },
  },
  {
    Header: "건물",
    accessor: "buildingName",
    width: 160,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return (
        <BaseTooltip isSingleLine={false} className="text-left w-full" contents={value ?? "-"} />
      );
    },
  },
  {
    Header: "담당자",
    accessor: "assigneeEmail",
    width: 180,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <span className="text-center w-100">{value || "-"}</span>;
    },
  },
  // {
  //   Header: "매출",
  //   accessor: "cost",
  //   width: 100,
  //   Cell: ({ value }: Cell<QuestionListModel>) => {
  //     return (
  //       <span className="text-right w-100">
  //         {value !== 0 ? numberToStringWithComma(value) : "-"}
  //       </span>
  //     );
  //   },
  // },
  // {
  //   Header: "지출",
  //   accessor: "expense",
  //   width: 100,
  //   Cell: ({ value }: Cell<QuestionListModel>) => {
  //     return (
  //       <span className="text-right w-100">
  //         {value !== 0 ? numberToStringWithComma(value) : "-"}
  //       </span>
  //     );
  //   },
  // },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 120,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return (
        <BaseTooltip contents={moment(value).format(YmdFormat.YYYY_MM_DD_HH_MM)} type="date" />
      );
    },
  },
  {
    Header: "최종 수정자",
    accessor: "modifiedBy",
    width: 120,
    Cell: ({ value, row }: Cell<QuestionListModel>) => {
      if (row.original.site === "SITE_TAAP" || row.original.site === "SITE_TAAP_SPACE") {
        return <BaseTooltip contents={row.original.reporterEmail ?? "-"} />;
      }

      return <BaseTooltip contents={value ?? "-"} />;
    },
  },
  {
    Header: "파트너",
    accessor: "partnerId",
    width: 80,
    Cell: ({ value }: Cell<any>) => {
      return (
        <Link
          target={"_blank"}
          to={PagePath.partner.detail.replace(":id", value)}
          className="text-hilight"
        >
          {value}
        </Link>
      );
    },
  },
];

export default QuestionColmns;
