import { useCallback, useMemo } from "react";
import { Control } from "react-hook-form";
import {
  Cell,
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { MgmtOfficerType } from "src/api/building/building-types";
import { Modal } from "src/api/public-types";
import { WorkOrderAddModel } from "src/api/workOrder/workOrder-types";
import { BaseCheckbox, BaseModal } from "src/components";
import { RoleType } from "src/pages/building/building-types";
import { officerTypeList } from "src/pages/building/forms/mgmtOffice/management-type";
import GlobalFilter from "src/pages/product/components/GlobalFilter";
import TableRadioButton from "src/pages/product/components/TableRadioButton";
import { formatPhoneNumber } from "src/utils";

type Props = {
  control: Control<WorkOrderAddModel>;
  setInfoModal: (modal: Modal) => void;
  mgmtOfficerList?: Array<MgmtOfficerType>;
  onSelected: (officer: MgmtOfficerType) => void;
};

const OfficerModal = ({ mgmtOfficerList, onSelected, setInfoModal }: Props) => {
  // 담당자 조회 팝업 - 업무파트 부분
  const getOfficeWorkType = useCallback((workType: string) => {
    if (workType) {
      for (let i = 0; i < officerTypeList.length; i++) {
        const type = officerTypeList[i];
        if (workType === type.value) {
          return type.label;
        }

        if (workType === "OFFICER_UNRECOGNIZED") {
          return "미선택";
        }
      }
    }
  }, []);

  const OfficerColumn: any[] = useMemo(() => {
    return [
      {
        Header: "id",
        accessor: "id",
        width: 50,
      },
      {
        Header: "이름/닉네임",
        accessor: "name",
        width: 140,
      },
      {
        Header: "휴대폰 번호",
        accessor: "phone",
        width: 140,
        Cell: ({ row }: Cell<MgmtOfficerType>) => formatPhoneNumber(String(row.original.phone)),
      },

      {
        Header: "업무 파트",
        accessor: "officerTypeList",
        width: 360,
        Cell: ({ row }: Cell<MgmtOfficerType>) => {
          const officerWorkList = row.original.officerTypeList;

          if (officerWorkList && officerWorkList.length > 0)
            return (
              <div className="flex-center-center">
                {officerWorkList.map((type: string) => {
                  return (
                    <div key={type}>
                      <BaseCheckbox
                        id={``}
                        name={``}
                        className="chip-case mr4"
                        label={getOfficeWorkType(type)}
                        disabled
                      />
                    </div>
                  );
                })}
              </div>
            );
        },
      },
      {
        Header: "매니저 여부",
        accessor: "roleType",
        width: 100,
        Cell: ({ row }: Cell<MgmtOfficerType>) => {
          const roleType = row.original.roleType;
          const isPrimary = row.original.isPrimary;

          if (isPrimary && roleType === RoleType.MANAGER) {
            return <span>대표 매니저</span>;
          }
          return roleType === RoleType.MANAGER ? <span>매니저</span> : "-";
        },
      },
    ];
  }, [getOfficeWorkType]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setGlobalFilter,
    selectedFlatRows,
    state: { globalFilter },
  } = useTable(
    {
      columns: OfficerColumn,
      data: mgmtOfficerList || [],
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    useGlobalFilter,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          id: "selection",
          width: 70,
          Header: () => <div className="">선택</div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
            let currentState = row.getToggleRowSelectedProps();
            return (
              <div className="minmax100">
                <TableRadioButton
                  id={row.id}
                  onClick={() => {
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id, true);
                  }}
                  name="selectedOrg"
                  {...currentState}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  return (
    <BaseModal
      isOpen={true}
      className="dialog-modal"
      btnLeftTitle="취소"
      btnRightTitle={mgmtOfficerList?.length && mgmtOfficerList.length > 0 ? "확인" : ""}
      onClick={() => {
        const selected = selectedFlatRows[0].original;
        onSelected(selected);
      }}
      onClose={() => {
        setInfoModal({ isOpen: false });
      }}
    >
      <>
        <div className="modal-title">
          <div className="left-area">
            <span>담당자 조회</span>
          </div>
          <div className="right-area">
            <div className="minmax260 mr5">
              <GlobalFilter
                filter={globalFilter}
                setFilter={setGlobalFilter}
                placeHolder={"이름 / 닉네임"}
              />
            </div>
          </div>
        </div>

        <>
          <div className="contents-container__table">
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div
                          {...header.getHeaderProps(header.getSortByToggleProps())}
                          className="base-table__th"
                        >
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              {mgmtOfficerList?.length && mgmtOfficerList.length > 0 ? (
                <div {...getTableBodyProps()} className="body">
                  {rows
                    .filter((row) => row.original.isDeleted === false)
                    .map((row, idx: number) => {
                      prepareRow(row);
                      return (
                        <div {...row.getRowProps()} className="base-table__tr">
                          {row.cells.map((cell) => {
                            return (
                              <div {...cell.getCellProps()} className="base-table__td">
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                </div>
              ) : (
                <>
                  <p className="mt10">등록된 담당자가 없습니다.</p>
                </>
              )}
            </div>
          </div>
        </>
      </>
    </BaseModal>
  );
};

export default OfficerModal;
