import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  editContractDetail,
  getContractApplicationInfo,
  getContractDetailBasicInfo,
  getContractInfo,
} from "src/api/contract/contract-api";
import { ContractDetailBasicInfo } from "src/api/contract/contract-types";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { getMemberDetail } from "src/api/member/member-api";
import { getOrgDetail } from "src/api/organization/org-api";
import { MbOrg, MbOrgDetail, MbOrgMedia } from "src/api/organization/org-types";
import { getProductDetailAsync } from "src/api/product/product-api";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { contract_bill_user_errors, Select } from "src/api/public-types";
import { BaseButton, BaseModal, ContentsIdSection } from "src/components";
import pagePath from "src/pagePath.json";
import { checkContractStatus } from "src/pages/contract/contract-util";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { validateEmail, validateMobileNumber, YmdFormat } from "src/utils";
import ChargeMntType from "./component/ChargeMntType";
import FOType from "./component/FOType";
import ServiceType from "./component/ServiceType";

interface Contract {
  contractDate: string;
  contractTerm: number;
  contractStartTime: string;
  contractEndTime: string;
  depositAmount: string;
  earnestAmount: string;
  earnestPayStatus: string;
  balanceAmount: string;
  balancePayStatus: string;
  contractBillTimeType: string;
  payAmount: string;
  pgcode?: string;
  taxAmount?: number;
  totalAmount?: number;
  isMntContain: boolean;
  applicantName?: string;
}
interface Schedule {
  contractId?: number;
  scheduleId?: number;
  billkey?: number;
  contractPayType: string;
  contractCancelDate?: string;
  contractCancelStartOrder?: number;
  contractCancelReason?: string;
  providerId?: string;
  providerName?: string;
  // payRegularDate: number;
  overdueFeePercent: number;
  taxBillReceiveEmail?: string;
  taxBillManageAmount: string;
  // taxBillRegularDate: number;
  payRegularDay: number;
  regularPgcode: string;
  supplyType?: string;
  taxInvoiceIssueDay: number;
  isTaxInvoiceIssue: boolean;
  isAutoBilling: boolean;
  isAutoSend: boolean;
  scheduleStartTime?: string;
  scheduleEndTime?: string;
  payRegularMonth: string;
  accountInName: string;
  taxInvoiceIssueMonth: string;
  isOverdueFee: string;
  payDueDays: number;

  messageList: Array<billMessageList>;
}

interface billMessageList {
  contractId: string;
  createdBy: string;
  createdDate: string;
  isDeleted: boolean;
  isSend: boolean;
  messageId: number;
  messageMedia: string;
  messageType: string;
  modifiedBy: string;
  modifiedDate: string;
}

interface ContractMedia {
  id?: number;
  serviceId: number;
  serviceType: string;
  serviceTag: string;
  contentType: string;
  filename: string;
  fileSize: number;
  fileStorageType: string;
  key: string;
  isPrimary: boolean;
  orderNums: number;
  isDeleted: boolean;
}
interface ContractSaveModel {
  contractId?: number;
  contract: Contract;
  scheduleList: Schedule[];
  contractMediaList: ContractMedia[];
  contractManage?: {
    mbOrganizationId?: number;
  };
  billContactList?: Array<BillContact>;
  billMessageList?: any;
  convertContractor?: ConvertContractor;
}
interface ConvertContractor {
  beforeContractMemberId?: number;
  afterContractMemberId?: number;
}
interface ContractMakeModel {
  contract: Contract;
  schedule: Schedule;
  contractMedia: ContractMedia[];
  contractManage?: {
    mbOrganizationId?: number;
  };
  requires: ContractMedia[];
  documents: ContractMedia[];
  startTime?: string;
  endTime?: string;
  billContactList?: Array<BillContact>;
}
interface BillContact {
  billContactId?: number;
  contractId?: number;
  receiveNotiType?: string;
  contact?: string;
  email?: string;
  description?: string;
  isDeleted?: boolean;
}
interface BillContactEdit {
  billContactId?: number;
  contractId?: number;
  receiveNotiType?: string;
  contact?: string;
  email?: string;
  description?: string;
  isDeleted?: boolean;

  errorTextContact?: string;
  errorTextEmail?: string;
}
const ContractDetail = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const location = useLocation();
  const params = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );
  const navigate = useNavigate();
  // console.log('contract',contract);
  const [step, setStep] = useState("");
  const [spaceProductType, setSpaceProductType] = useState("");

  const [isBillMessageFlag, setIsBillMessageFlag] = useState(false);

  const [saved, setSaved] = useState(false);
  const [oneHour, setOneHour] = useState(false);
  const [textGrey] = useState("contents-container__grid ");
  const [organization, setOrganization] = useState<MbOrg>();
  const [productDetail, setProductDetail] = useState<any>();
  const [proof, setProof] = useState<MbOrgMedia[]>();
  const [bi, setBi] = useState<MbOrgMedia[]>();
  const { executeAsync } = useApiOperation(getContractDetailBasicInfo);
  const { executeAsync: getMemberInfo } = useApiOperation(getMemberDetail);
  const { executeAsync: getApplication } = useApiOperation(getContractApplicationInfo);
  const { executeAsync: getContract } = useApiOperation(getContractInfo);

  const [contractBasic, setBasicInfo] = useState<ContractDetailBasicInfo>();
  const [contractApplication, setApplication] = useState<any>();
  const [contractDetail, setContract] = useState<any>();
  const [billMessageList, setBillMessageList] = useState<any>([]);

  const [document, setDocument] = useState<ContractMedia[]>([]);
  const [require, setRequire] = useState<ContractMedia[]>([]);
  const [billContactList, setBillContactList] = useState<BillContact[]>([]);
  const [talkOfficialdoc, setTalkOfficialdoc] = useState<BillContact[]>([]);
  const [taxInVoice, setTaxInVoice] = useState<BillContact[]>([]);
  const [member, setMember] = useState<any>([]);
  const [contractMember, setContractMember] = useState<any>([]);
  const [changeContractMember, setChangeContractMember] = useState<any>([]);
  const [viewContractMember, setViewContractMember] = useState<any>([]);
  const [provider, setProvider] = useState<any>({});

  const { executeAsync: getOrganization } = useApiOperation(getOrgDetail);
  const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync);
  const { executeAsync: editContract } = useApiOperation(editContractDetail);
  const [selectedPeriodOption, setSelectedPeriodOptions] = useState<Select[]>([]);

  const [messageList, setMessageList] = useState<any>([]);
  const [scheduleList, setScheduleList] = useState<any>([]);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    setError,
    register,
    unregister,
    // formState: { errors },
  } = useForm<ContractMakeModel>({
    defaultValues: {
      contract: {
        contractDate: "",
        contractTerm: 0,
        contractStartTime: "",
        contractEndTime: "",
        depositAmount: "",
        earnestAmount: "",
        earnestPayStatus: "",
        balanceAmount: "",
        balancePayStatus: "",
        contractBillTimeType: "",
        payAmount: "0",
        pgcode: "",
        isMntContain: false,
        applicantName: "",
      },
      schedule: {
        contractPayType: "PAY",
        taxInvoiceIssueDay: 0,
        payRegularDay: 0,
        overdueFeePercent: 0,
        regularPgcode: "",
        taxBillManageAmount: "",
        isTaxInvoiceIssue: false,
        isAutoBilling: false,
        isAutoSend: false,
        payDueDays: 0,
      },
      contractMedia: [],
      contractManage: {
        mbOrganizationId: 0,
      },
      requires: [],
      documents: [],
      startTime: "",
      endTime: "",
    },
  });

  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  const getProduct = useCallback(
    async (productId: number) => {
      const res = await getProductDetail({ productId });
      if (res.status >= 200 && res.status <= 299) {
        // console.log("res", res.data.data.content);
        const result = res.data.data.content;
        const options: Select[] = [];
        // console.log(result);
        // console.log(result.productType);
        if (result.productType === "FULL_COURT") {
          const min = Number(result.minLeasePeriod);
          const max = Number(result.maxLeasePeriod);
          for (let i = min; i <= max; i++) {
            const option = { label: i + "개월", value: String(i) };
            options.push(option);
          }
        }
        if (result.productType === "OPEN_COURT") {
          const min = Number(result.minLeasePeriod);
          const max = Number(result.maxLeasePeriod);
          for (let i = min; i <= max; i++) {
            const option = { label: i + "개월", value: String(i) };
            options.push(option);
          }
        }
        setProductDetail(result);
        setSelectedPeriodOptions(options);
      }
    },
    [getProductDetail],
  );

  const onChangeOrganization = useCallback(
    async (org) => {
      if (org) {
        console.log("org", org);
        setLoadingBar(true);

        const response: any = await getOrganization({ id: Number(org.id) });
        if (response.status >= 200 && response.status <= 299) {
          const mbOrgDetail: MbOrgDetail = response.data.data;
          // console.log(mbOrgDetail, "mbOrgDetail");
          setOrganization(mbOrgDetail.organization);
          const proofs = mbOrgDetail?.mediaList!.filter(
            (media: MbOrgMedia) => media.type === "PROOF",
          );
          const bi = mbOrgDetail?.mediaList!.filter((media: MbOrgMedia) => media.type !== "PROOF");
          setProof(proofs);
          setBi(bi);
          setValue("contractManage.mbOrganizationId", org.id);
        } else {
          let message = errorMessageHandler(
            response.status,
            response.data.meta.errorCode,
            contract_bill_user_errors,
          );
          let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
          return openErrorModal(message, code);
        }
        setLoadingBar(false);
      }
    },
    [getOrganization, openErrorModal, setLoadingBar, setValue],
  );

  const moveDetail = () => {
    let path = pagePath.contract.detail.replace(":id", String(params.id)) + "?tab=contract";
    navigate(path);
  };

  const setDetailData = useCallback(
    async (contractDt: any) => {
      // console.log("contractDt", contractDt);
      setMessageList(contractDt?.schedule?.messageList);
      setScheduleList(contractDt?.scheduleList);
      if (contractDt?.contractStartTime !== "") {
        const start = moment(contractDt?.contractStartTime).format(YmdFormat.YYYY_MM_DD);
        setValue("contract.contractStartTime", start);
      }
      if (contractDt?.contractEndTime !== "") {
        const end = moment(contractDt?.contractEndTime).format(YmdFormat.YYYY_MM_DD);
        setValue("contract.contractEndTime", end);
      }
      if (contractDt?.contractTerm) {
        setValue("contract.contractTerm", contractDt?.contractTerm);
      }

      if (spaceProductType === "TIME_COURT") {
        setValue("startTime", contractDt?.contractStartTime);
        setValue("endTime", contractDt?.contractEndTime);
      }

      if (contractDt?.contractDate) {
        setValue(
          "contract.contractDate",
          moment(contractDt?.contractDate).format(YmdFormat.YYYY_MM_DD_HH_MM),
        );
      }

      if (contractDt?.schedule?.overdueFeePercent) {
        let per = contractDt?.schedule?.overdueFeePercent;

        if (Number(per) > 0) {
          setValue("schedule.overdueFeePercent", Number(per));
        } else {
          setValue("schedule.overdueFeePercent", 0);
        }
      }

      setValue("contract.balanceAmount", String(contractDt?.balanceAmount));

      setValue("contract.pgcode", contractDt?.pgcode);
      setValue("contract.applicantName", contractDt?.applicantName);

      if (contractDt?.earnestPayStatus !== "") {
        setValue("contract.earnestPayStatus", contractDt?.earnestPayStatus);
      }

      setValue("contract.earnestAmount", String(contractDt?.earnestAmount));

      setValue("contract.payAmount", contractDt?.payAmount);
      setValue("contract.isMntContain", String(contractDt?.isMntContain) === "true" ? true : false);

      if (contractDt?.balancePayStatus !== "") {
        setValue("contract.balancePayStatus", contractDt?.balancePayStatus);
      }

      setValue("contractManage.mbOrganizationId", contractDt.mbOrganizationId);

      if (contractDt?.contractMediaList && contractDt?.contractMediaList.length > 0) {
        const doc = contractDt?.contractMediaList.filter(
          (media: ContractMedia) => media.serviceTag === "CONTRACT_DOCUMENT",
        );
        let req = contractDt?.contractMediaList.filter(
          (media: ContractMedia) => media.serviceTag !== "CONTRACT_DOCUMENT",
        );
        req = _.sortBy(req, ["orderNums"]);
        setDocument(doc);
        setRequire(req);
        setValue("requires", req);
        setValue("documents", doc);
      }

      setValue("schedule.payDueDays", contractDt.schedule?.payDueDays);

      setValue("schedule.payRegularDay", contractDt.schedule?.payRegularDay);
      setValue("schedule.taxInvoiceIssueDay", contractDt.schedule?.taxInvoiceIssueDay);

      setValue("schedule.contractPayType", contractDt.schedule?.contractPayType);
      setValue("schedule.regularPgcode", contractDt.schedule?.regularPgcode);
      setValue("schedule.taxBillManageAmount", String(contractDt.schedule?.taxBillManageAmount));

      let isAutoSend = String(contractDt?.schedule?.isAutoSend) === "true";
      let isAutoBilling = String(contractDt?.schedule?.isAutoBilling) === "true";
      let isTaxInvoiceIssue = String(contractDt?.schedule?.isTaxInvoiceIssue) === "true";
      setValue("schedule.isAutoSend", isAutoSend);
      setValue("schedule.isAutoBilling", isAutoBilling);
      setValue("schedule.isTaxInvoiceIssue", isTaxInvoiceIssue);
      setValue("schedule.taxBillReceiveEmail", contractDt?.schedule?.taxBillReceiveEmail);

      setValue("schedule.payRegularMonth", contractDt?.schedule?.payRegularMonth);
      setValue("schedule.taxInvoiceIssueMonth", contractDt?.schedule?.taxInvoiceIssueMonth);

      setValue("schedule.accountInName", contractDt?.schedule?.accountInName);

      if (contractDt?.scheduleStartTime === "") {
        setValue("schedule.scheduleStartTime", contractDt?.contractStartTime);
        setValue("schedule.scheduleEndTime", contractDt?.contractEndTime);
      } else {
        setValue("schedule.scheduleStartTime", contractDt?.schedule?.scheduleStartTime);
        setValue("schedule.scheduleEndTime", contractDt?.schedule?.scheduleEndTime);
      }
      if (contractDt?.schedule?.messageList.length > 0) {
        setBillMessageList(contractDt?.schedule?.messageList);
      }
    },
    [setValue, spaceProductType],
  );

  const fetchData = useCallback(
    async (id: number) => {
      try {
        if (
          id &&
          contractBasic === undefined &&
          contractApplication === undefined &&
          contractDetail === undefined
        ) {
          setLoadingBar(true);
          let contractBs: any = await executeAsync({ id: Number(id) });
          // console.log("contractBs", contractBs.data.data);
          if (contractBs.status >= 200 && contractBs.status <= 299) {
            await setStep(checkContractStatus(contractBs.data.data.contractStep));
            await setSpaceProductType(contractBs.data.data.spaceProductType);
            await setBasicInfo(contractBs.data.data);
            setValue(
              "contract.contractBillTimeType",
              spaceProductType === "TIME_COURT" ? "IMMEDIATE_PAYMENT" : "REGULAR_PAYMENT",
            );
          } else {
            let message = errorMessageHandler(
              contractBs.status,
              contractBs.data.meta.errorCode,
              contract_bill_user_errors,
            );
            let code = contractBs.data.meta.errorCode
              ? contractBs.data.meta.errorCode
              : contractBs.status;
            return openErrorModal(message, code);
          }

          let contractAp: any = await getApplication({ id: Number(id) });
          // console.log("contractAp", contractAp);
          if (contractAp.status >= 200 && contractAp.status <= 299) {
            await setApplication(contractAp.data.data);
            await getProduct(Number(contractAp.data.data.spaceProductId));
          } else {
            let message = errorMessageHandler(
              contractAp.status,
              contractAp.data.meta.errorCode,
              contract_bill_user_errors,
            );
            let code = contractAp.data.meta.errorCode
              ? contractAp.data.meta.errorCode
              : contractAp.status;
            return openErrorModal(message, code);
          }
          const result = await checkProviderDetail(contractAp.data.data?.providerId);
          setProvider(result);

          const memberInfo: any = await getMemberInfo({ id: contractAp.data.data.memberNo });
          setMember(memberInfo.data.data.member);
          let contractDt: any = await getContract({ id: Number(id) });
          console.log("contractDt", contractDt.data.data);
          if (contractDt.status >= 200 && contractDt.status <= 299) {
            const contract = contractDt.data.data;
            const contractMemberInfo: any = {
              contractMemberId: contract.contractorMemberId,
              memberNo: contract.contractorMemberNo,
              inviteMobileNumber: contract.contractorPhoneNumber,
              email: contract.contractorEmail,
            };
            setContractMember(contractMemberInfo);
            setViewContractMember(contractMemberInfo);
            if (contract.scheduleList && contract.scheduleList.length > 0) {
              const rental: any = contract.scheduleList.find(
                (obj: any) => obj.supplyType === "RENTAL",
              );
              contract.schedule = rental;

              // //test
              // const test: any = [];
              // test.push(rental);
              // test.push(rental);
              // test.push(rental);
              // test.push(rental);
              // test.push(rental);
              // contract.scheduleList = test;

              contract.scheduleList = contract.scheduleList.filter((obj: any) => {
                if (obj.supplyType !== "RENTAL") {
                  obj.isModifyOpen = false;
                  return obj;
                }
              });
            }

            await setContract(contract);
            // console.log("????", memberInfo);
            let check1 = contract.billContactList.filter(
              (invoice: any) => invoice.receiveNotiType === "TALK_OFFICIALDOC",
            );
            if (check1.length === 0) {
              const firstLine = {
                billContactId: 0,
                contractId: contract.contractId,
                receiveNotiType: "TALK_OFFICIALDOC",
                contact: memberInfo.data.data.member.phoneNumber.replace("+82", "0"),
                email: memberInfo.data.data.member.email,
                description: "",
                isDeleted: false,
                errorTextContact: "",
                errorTextEmail: "",
              };
              contract.billContactList.push(firstLine);
            }

            let check2 = contract.billContactList.filter(
              (invoice: any) => invoice.receiveNotiType === "TAX_INVOICE",
            );
            if (check2.length === 0) {
              const firstLine = {
                billContactId: 0,
                contractId: contract.contractId,
                receiveNotiType: "TAX_INVOICE",
                contact: "",
                email: memberInfo.data.data.member.email,
                description: "",
                isDeleted: false,
                errorTextContact: "",
                errorTextEmail: "",
              };
              contract.billContactList.push(firstLine);
            }

            setBillContactList(contract.billContactList);
            const talk = contract.billContactList.filter(
              (bill: any) => bill.receiveNotiType === "TALK_OFFICIALDOC",
            );
            const tax = contract.billContactList.filter(
              (bill: any) => bill.receiveNotiType === "TAX_INVOICE",
            );

            console.log("contract", contract);
            setTalkOfficialdoc(talk);
            setTaxInVoice(tax);

            if (Number(contract.mbOrganizationId) > 0) {
              const org = await getOrganization({
                id: Number(contract.mbOrganizationId),
              });
              if (org.status >= 200 && org.status <= 299) {
                // console.log('response orgDetail', response.data.data.organization);
                const mbOrgDetail: MbOrgDetail = org.data.data;
                await setOrganization(mbOrgDetail.organization);
                const proofs = mbOrgDetail.mediaList!.filter(
                  (media: MbOrgMedia) => media.type === "PROOF",
                );
                const bi = mbOrgDetail.mediaList!.filter(
                  (media: MbOrgMedia) => media.type !== "PROOF",
                );
                await setProof(proofs);
                await setBi(bi);
              }
            }
          } else {
            let message = errorMessageHandler(
              contractDt.status,
              contractDt.data.meta.errorCode,
              contract_bill_user_errors,
            );
            let code = contractDt.data.meta.errorCode
              ? contractDt.data.meta.errorCode
              : contractDt.status;
            return openErrorModal(message, code);
          }
        }
      } catch (error) {
        setLoadingBar(false);
      } finally {
        setLoadingBar(false);
      }
    },
    [
      contractApplication,
      contractBasic,
      contractDetail,
      executeAsync,
      getApplication,
      getContract,
      getMemberInfo,
      getOrganization,
      getProduct,
      openErrorModal,
      setLoadingBar,
      setValue,
      spaceProductType,
    ],
  );

  useEffect(() => {
    if (contractDetail) {
      setDetailData(contractDetail);

      // console.log("contractForm", getValues("contract"));
    }
  }, [contractDetail, getValues, setDetailData, setLoadingBar]);

  useEffect(() => {
    if (params.id) {
      fetchData(Number(params.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [billContactList]);

  const eContract = useCallback(
    async (send: ContractSaveModel) => {
      setLoadingBar(true);
      const res: any = await editContract(send);
      // console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        console.log("성공");
        setSaved(true);
      } else {
        let message = errorMessageHandler(
          res.status,
          res.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.status;
        openErrorModal(message, code);
      }

      setLoadingBar(false);
    },
    [editContract, openErrorModal, setLoadingBar],
  );

  const closeAndMove = useCallback(() => {
    setSaved(false);
    const path =
      pagePath.contract.detail.replace(":id", String(params.id)) +
      "?" +
      decodeURIComponent(qs.stringify(params, { allowDots: true, encode: true }));
    navigate(path);
  }, [navigate, params]);

  const checkProviderDetail = useCallback(
    async (providerId: string) => {
      const response: any = await getProviderDetail({ providerId });
      let result: any = {};
      if (response.status >= 200 || response.status <= 299) {
        result = response.data.data;
      }
      return result;
    },
    [getProviderDetail],
  );

  // console.log("contractBasic, contractApplication, contract", contractBasic, contractApplication, contract);
  // validation 통과 후 submit 될때 실행
  const onSubmit = async (data: ContractMakeModel, e?: any) => {
    e.preventDefault();
    // console.log("data", data);

    if (
      (data.contract.contractDate === null || data.contract.contractDate === "") &&
      spaceProductType !== "TIME_COURT"
    ) {
      setError("contract.contractDate", { type: "custom", message: "필수입력 항목입니다." });
      return;
    }

    // if (data.schedule.isAutoSend === true) {
    //   const findTrue = billMessageList.filter((mes: any) => mes.isSend === true);
    //   if (findTrue.length === 0) {
    //     setIsBillMessageFlag(true);
    //     return;
    //   }
    // }

    if (data.schedule.contractPayType === "TAX") {
      // contractApplication.providerId
      const result = await checkProviderDetail(contractApplication?.providerId);
      // console.log("provider!!", result);
      if (result.provider) {
        if (
          result.provider.businessRegistrationNumber === "" ||
          result.provider.providerName === "" ||
          result.provider.representative === ""
        ) {
          openErrorModal(
            "결제타입을 세금계산서로 변경하시려면 ‘사업자 등록번호, 법인명/상호, 대표자/성명’을 모두 등록해 주세요. ",
          );
          return;
        }
      }
    }

    // if (data.schedule.contractPayType === "TAX") {
    // if (data.schedule.taxBillRegularDate <= 0) {
    //   setError("schedule.taxBillRegularDate", {
    //     type: "custom",
    //     message: "세금계산서 선택 시 정기발행일은 필수 입니다.",
    //   });
    //   return;
    // }
    // if (data.schedule.regularPgcode === "creditcard") {
    //   setError("contract.schedule.regularPgcode", {
    //     type: "custom",
    //     message: "세금계산서 선택 시 결제방법을 변경해야합니다.",
    //   });
    //   return;
    // }
    // }

    if (data.schedule.contractPayType === "PAY") {
      if (data.schedule.payRegularDay <= 0) {
        setError("schedule.payRegularDay", {
          type: "custom",
          message: "일반결제 선택 시 정기결제일은 필수 입니다.",
        });
        return;
      }
    }

    if (
      contractBasic?.contractStep !== "APPLY_RECEIVED" &&
      contractBasic?.contractStep !== "APPLY_CONFIRM" &&
      contractBasic?.contractStep !== "USE_COMPLETE" &&
      contractBasic?.contractStep !== "TERMINATE_COMPLETE" &&
      contractDetail?.schedule?.contractPayType !== "TAX" &&
      data.schedule.isAutoSend &&
      talkOfficialdoc.length > 0
    ) {
      const dump = _.cloneDeep(talkOfficialdoc);
      let checker = false;
      const check = dump.map((bill: BillContactEdit, index: number) => {
        if (bill.contact === "") {
          bill.errorTextContact = "필수 입력 항목입니다";
          checker = true;
        } else {
          if (typeof validateMobileNumber(bill.contact) === "string") {
            bill.errorTextContact = String(validateMobileNumber(bill.contact));
            checker = true;
          }
        }
        if (bill.email === "") {
          bill.errorTextEmail = "필수 입력 항목입니다";
          checker = true;
        } else {
          if (validateEmail(String(bill.email)) === false) {
            bill.errorTextEmail = "이메일 형식이 아닙니다.";
            checker = true;
          }
        }

        return bill;
      });
      setTalkOfficialdoc(check);

      const dump2 = _.cloneDeep(taxInVoice);

      const check2 = dump2.map((bill: BillContactEdit, index: number) => {
        if (bill.email === "") {
          bill.errorTextEmail = "필수 입력 항목입니다";
          checker = true;
        } else {
          if (String(validateEmail(String(bill.email))) === "false") {
            bill.errorTextEmail = "이메일 형식이 아닙니다.";
            checker = true;
          }
        }

        return bill;
      });
      setTaxInVoice(check2);
      if (checker) {
        return;
      }
    }

    const contractMedia: ContractMedia[] = [];
    let count = 0;
    if (data.requires && data.requires.length > 0) {
      data.requires.forEach((require: ContractMedia, index: number) => {
        // console.log(require);
        const orderNum = index + 1;
        const saveModel: ContractMedia = {
          id: require.id,
          serviceId: Number(params.id),
          serviceType: "CONTRACT",
          serviceTag: "CONTRACT_REQUIRED",
          filename: require.filename,
          fileSize: require.fileSize,
          fileStorageType: require.fileStorageType,
          contentType: require.contentType,
          key: require.key,
          isPrimary: false,
          orderNums: orderNum,
          isDeleted: require.isDeleted ? require.isDeleted : false,
        };
        count = orderNum;
        contractMedia.push(saveModel);
      });
    }
    if (data.documents && data.documents.length > 0) {
      data.documents.forEach((document: ContractMedia) => {
        // console.log("document", document);
        const saveModel: ContractMedia = {
          id: document.id,
          serviceId: Number(params.id),
          serviceType: "CONTRACT",
          serviceTag: "CONTRACT_DOCUMENT",
          filename: document.filename,
          fileSize: document.fileSize,
          fileStorageType: document.fileStorageType,
          contentType: document.contentType,
          key: document.key,
          isPrimary: false,
          orderNums: count + 1,
          isDeleted: document.isDeleted ? document.isDeleted : false,
        };
        count = count + 1;
        contractMedia.push(saveModel);
      });
    }
    let sm: Contract = { ...data.contract };
    let ss: Schedule = { ...data.schedule };
    // console.log("ss", ss);
    sm.contractStartTime = moment(sm.contractStartTime).format(YmdFormat.WITH_TIME_ZONE);

    let useEndTime =
      moment(sm.contractEndTime, "YYYY-MM-DD").format(YmdFormat.YYYY_MM_DD) + " 23:59:59.999";

    sm.contractEndTime = moment(useEndTime).format(YmdFormat.WITH_TIME_ZONE);
    sm.contractDate = moment(sm.contractDate).format(YmdFormat.WITH_TIME_ZONE);

    let earnestAmount = Number(_.replace(sm.earnestAmount, new RegExp(",", "g"), ""));
    let balanceAmount = Number(_.replace(sm.balanceAmount, new RegExp(",", "g"), ""));

    sm.depositAmount = String(earnestAmount + balanceAmount);

    sm.earnestAmount = _.replace(sm.earnestAmount, new RegExp(",", "g"), "");
    sm.balanceAmount = _.replace(sm.balanceAmount, new RegExp(",", "g"), "");

    sm.payAmount = _.replace(sm.payAmount, new RegExp(",", "g"), "");

    ss.payRegularDay = Number(ss.payRegularDay);
    ss.taxInvoiceIssueDay = Number(ss.taxInvoiceIssueDay);

    ss.scheduleStartTime = moment(ss.scheduleStartTime).format(YmdFormat.WITH_TIME_ZONE);
    ss.scheduleEndTime = moment(ss.scheduleEndTime).format(YmdFormat.WITH_TIME_ZONE);
    ss.messageList = messageList;

    const sendOldSave = _.cloneDeep(contractDetail.schedule);

    delete sendOldSave.billkey;
    delete sendOldSave.providerName;
    delete sendOldSave.supplyType;
    delete sendOldSave.isContractCancel;
    delete sendOldSave.contractCancelReason;
    delete sendOldSave.contractCancelStartOrder;
    delete sendOldSave.contractCancelDate;
    delete sendOldSave.providerName;

    const scData = Object.assign(sendOldSave, ss, {});

    let sendArr = _.cloneDeep(scheduleList);

    sendArr.forEach((old: any) => {
      delete old.billkey;
      delete old.providerName;
      delete old.supplyType;
      delete old.isContractCancel;
      delete old.contractCancelReason;
      delete old.contractCancelStartOrder;
      delete old.contractCancelDate;
      delete old.providerName;
    });

    let saveBillContactList = talkOfficialdoc.concat(taxInVoice);

    const convertContractor: any = {};
    const sl = [...[scData], ...sendArr];

    const send: ContractSaveModel = {
      contractId: Number(params.id),
      scheduleList: sl,
      contract: sm,
      contractManage: {
        mbOrganizationId: Number(data.contractManage!.mbOrganizationId),
      },
      contractMediaList: contractMedia,
      billContactList: saveBillContactList,
    };

    if (changeContractMember.contractMemberId) {
      convertContractor.beforeContractMemberId = contractMember.contractMemberId;
      convertContractor.afterContractMemberId = changeContractMember.contractMemberId;
      send.convertContractor = convertContractor;
    }
    console.log("send", send);
    eContract(send);
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);
    e.preventDefault();

    return false;
  };

  // console.log("contract", contract);

  return (
    <div className="contents-container__wrap detail-basic-info">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__wrap-contents">
          <ContentsIdSection
            title="계약"
            id={contractBasic?.contractId}
            noTitle="신청번호"
            no={contractBasic?.contractApplyNumber}
            status={step}
          />
          {productDetail?.productCategory === "PRODUCT_CATEGORY_NORMAL" &&
            (spaceProductType === "FULL_COURT" || spaceProductType === "OPEN_COURT") && (
              <FOType
                control={control}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
                setError={setError}
                register={register}
                unregister={unregister}
                spaceProductType={spaceProductType}
                organization={organization}
                selectedPeriodOption={selectedPeriodOption}
                contractBasic={contractBasic}
                contractDetail={contractDetail}
                billMessageList={billMessageList}
                bi={bi}
                proof={proof}
                document={document}
                require={require}
                talkOfficialdoc={talkOfficialdoc}
                taxInVoice={taxInVoice}
                member={member}
                viewContractMember={viewContractMember}
                setChangeContractMember={setChangeContractMember}
                setViewContractMember={setViewContractMember}
                setOrganization={setOrganization}
                setProof={setProof}
                setBi={setBi}
                setTaxInVoice={setTaxInVoice}
                setTalkOfficialdoc={setTalkOfficialdoc}
                setBillMessageList={setBillMessageList}
                setRequire={setRequire}
                setDocument={setDocument}
                setContract={setContract}
                messageList={messageList}
                setMessageList={setMessageList}
                scheduleList={scheduleList}
                setScheduleList={setScheduleList}
                contractApplication={contractApplication}
                provider={provider}
              />
            )}
          {productDetail?.productCategory === "PRODUCT_CATEGORY_NORMAL" &&
            spaceProductType === "TIME_COURT" && (
              <ServiceType
                control={control}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
                setError={setError}
                register={register}
                unregister={unregister}
                selectedPeriodOption={selectedPeriodOption}
                spaceProductType={spaceProductType}
                organization={organization}
                contractBasic={contractBasic}
                contractDetail={contractDetail}
                billMessageList={billMessageList}
                bi={bi}
                proof={proof}
                document={document}
                require={require}
                talkOfficialdoc={talkOfficialdoc}
                taxInVoice={taxInVoice}
                member={member}
                viewContractMember={viewContractMember}
                setChangeContractMember={setChangeContractMember}
                setViewContractMember={setViewContractMember}
                setOrganization={setOrganization}
                setProof={setProof}
                setBi={setBi}
                setTaxInVoice={setTaxInVoice}
                setTalkOfficialdoc={setTalkOfficialdoc}
                setBillMessageList={setBillMessageList}
                setRequire={setRequire}
                setDocument={setDocument}
                setContract={setContract}
                contractApplication={contractApplication}
              />
            )}
          {productDetail?.productCategory !== "PRODUCT_CATEGORY_NORMAL" && (
            <ChargeMntType
              control={control}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              setError={setError}
              register={register}
              unregister={unregister}
              selectedPeriodOption={selectedPeriodOption}
              spaceProductType={spaceProductType}
              organization={organization}
              contractBasic={contractBasic}
              contractDetail={contractDetail}
              billMessageList={billMessageList}
              bi={bi}
              proof={proof}
              document={document}
              require={require}
              talkOfficialdoc={talkOfficialdoc}
              taxInVoice={taxInVoice}
              member={member}
              viewContractMember={viewContractMember}
              setChangeContractMember={setChangeContractMember}
              setViewContractMember={setViewContractMember}
              setOrganization={setOrganization}
              setProof={setProof}
              setBi={setBi}
              setTaxInVoice={setTaxInVoice}
              setTalkOfficialdoc={setTalkOfficialdoc}
              setBillMessageList={setBillMessageList}
              setRequire={setRequire}
              setDocument={setDocument}
              setContract={setContract}
              messageList={messageList}
              scheduleList={scheduleList}
              contractApplication={contractApplication}
            />
          )}
        </div>
        <div className="contents-container__btn-wrap">
          <div className="left-area d-flex">
            <BaseButton
              title="목록으로"
              onClick={() => {
                delete params.id;
                navigate(
                  pagePath.contract.list +
                    "?" +
                    decodeURIComponent(qs.stringify(params, { allowDots: true, encode: true })),
                );
              }}
              className="color-white size-large"
            />
            <BaseButton
              title="상세"
              className="color-white size-large ml10"
              onClick={() => moveDetail()}
            />
          </div>
          <div className="right-area">
            <BaseButton title="저장" type="submit" className="size-large" />
          </div>
        </div>
      </form>
      <BaseModal isOpen={saved} btnRightTitle={"확인"} onClick={() => closeAndMove()}>
        <p>저장되었습니다.</p>
      </BaseModal>
      <BaseModal isOpen={oneHour} btnRightTitle="확인" onClick={() => setOneHour(false)}>
        <p>최소 이용시간은 1시간입니다.</p>
      </BaseModal>
      <BaseModal
        isOpen={isBillMessageFlag}
        btnRightTitle="확인"
        onClick={() => setIsBillMessageFlag(false)}
      >
        <p>공문 자동발송 선택 시, 공문 종류를 하나 이상 선택해 주세요.</p>
      </BaseModal>
    </div>
  );
};

export default ContractDetail;
