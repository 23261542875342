import moment from "moment";
import { useApiOperation } from "src/api/hooks";
import { getReservationListAsync } from "src/api/reservation/reservation-api";
import DashboardSection from "./components/DashboardSection";
import { RESERVATION_MENU_LIST } from "./constants";
import { useSectionMenuList } from "./hooks";
import { MenuStatus } from "./types";

function DashboardReservationSection() {
  const { executeAsync: getReservationList } = useApiOperation(getReservationListAsync);

  const getReservationListTotalElements = async ({ status }: MenuStatus) => {
    const { data } = await getReservationList({
      page: 0,
      size: 1,
      start: "2022-07-01T00:00:00+09:00",
      end: moment().endOf("day").format(),
      statusCode: status as string,
    });
    return data.meta.pageMeta?.totalElements ?? -1;
  };

  const { menuList } = useSectionMenuList({
    baseMenuList: RESERVATION_MENU_LIST,
    getMenuListCounts: getReservationListTotalElements,
  });

  return <DashboardSection title={"공용공간 예약"} menuList={menuList} />;
}

export default DashboardReservationSection;
