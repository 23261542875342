import Select from "react-select";
interface BaseSelectState {
  placeholder?: string;
  stateOptions: Array<{
    value: string | number;
    label: string;
    isDisabled?: boolean;
  }>;
  value?: string | number | null;
  name?: string;
  setStateValue: Function;
  className?: string;
  errorText?: string;
  isDisabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const BaseSelect = (props: BaseSelectState) => {
  let selectedValue = null; // null 로 초기화 해야 선택안함이 정상적으로 초기화됨
  if (props.value) {
    selectedValue = props.stateOptions.find(
      (option) => String(option.value) === String(props.value),
    );
  }

  return (
    <div className={`w-100 base-select ${props.className ? props.className : ""}`}>
      <Select
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        placeholder={props?.placeholder || "선택해주세요"}
        isSearchable={false}
        value={selectedValue}
        className={`react-select-container  w-100 `}
        classNamePrefix="react-select"
        onChange={(e) => {
          e && props.setStateValue(e.value);
        }}
        options={props.stateOptions}
        isDisabled={props.isDisabled}
      />
      {props.errorText && <p className="validation-text">{props.errorText}</p>}
    </div>
  );
};
