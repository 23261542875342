import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../public-types";
import { ApiPath } from "..";
import { QuestionDetailModel, QuestionListResponse, QuestionQueryParams } from "./question-types";
import qs from "qs";

/*
  CS 리스트 조회
*/
export async function getQuestionListAsync(
  axios: AxiosInstance,
  params?: QuestionQueryParams,
): Promise<AxiosResponse<ApiResponse<QuestionListResponse>>> {
  return await axios.get<ApiResponse<QuestionListResponse>>(ApiPath.question.list, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}
/*
 CS 상세 조회
*/
export async function getQuestionDetailAsync(
  axios: AxiosInstance,
  params?: { questionId: string },
): Promise<AxiosResponse<ApiResponse<{ content: QuestionDetailModel }>>> {
  return await axios.get<ApiResponse<{ content: QuestionDetailModel }>>(
    ApiPath.question.detail.replace("{questionId}", params?.questionId!),
    {
      params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  );
}

/**
 * CEQ17. 상담관리 상세 목록
 * https://pnpt.atlassian.net/wiki/x/TQB-Jw
 */
export async function getQuestionDetailListAsync(
  axios: AxiosInstance,
  params?: QuestionQueryParams,
): Promise<AxiosResponse<ApiResponse<{ content: QuestionDetailModel[] }>>> {
  return await axios.get(ApiPath.question.details, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}
