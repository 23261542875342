import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import useApiOperation from "src/api/hooks/api-operation";
import { getPartners } from "src/api/partner";
import { getPartnerMemberList } from "src/api/partner-member/api";
import { BaseModal, BaseInput, BaseButton, BasePagination, BaseCheckbox } from "src/components";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { useLoadingBarContext } from "src/pages/hooks";
import { getPartnerMemberDetailPagePath } from "src/pages/partner-memeber/utils";
import { Partner } from "src/types/partner";
import { IPartnerMember } from "src/types/partner-members";

interface Props {
  onCanceled: () => void;
  onAdded: (partnerList: IPartnerMember[]) => void;
  defaultValues: IPartnerMember[];
  multiSelect?: boolean;
  disabledIds?: string[];
  title?: string;
}

interface CellProps extends Cell<Partner> {
  checked: boolean;
  disabled?: boolean;
}

const STATUS_MAP = {
  STANDBY: {
    name: "대기",
    color: "gray",
  },
  ACTIVE: {
    name: "활성",
    color: "green",
  },
  SHUTDOWN: {
    name: "종료",
    color: "red",
  },
};

const PARTNER_TABLE_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 100,
    Cell: (props: any) => {
      const partnerId = props.row.cells[1].value;
      const isDisabled = props.row.original.disabled;

      return (
        <div data-partner-id={partnerId} data-checked={props.value} className="checkbox">
          <BaseCheckbox id={""} name={""} checked={props.value} disabled={isDisabled} />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "id",
    width: 300,
    Cell: (props: Cell<IPartnerMember>) => (
      <div className="w-100 text-left ellipsis2">
        <Link to={getPartnerMemberDetailPagePath(props.value)} className="text-hilight">
          {props.value}
        </Link>
      </div>
    ),
  },
  {
    Header: "이메일",
    accessor: "email",
    width: 200,
    Cell: (props: Cell<IPartnerMember>) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "소속 파트너",
    accessor: "partners",
    width: 220,
    Cell: (props: Cell<IPartnerMember>) => {
      if (props.value.length >= 2)
        return (
          <div className="w-100 text-left ellipsis2">
            {props.value[0].name + " 외 " + (props.value.length - 1) + "개"}
          </div>
        );
      return (
        <div className="w-100 text-left ellipsis2">
          {props.value.map((partner: Partner) => partner.name)}
        </div>
      );
    },
  },
  {
    Header: "상태",
    width: 100,
    accessor: "blocked",
    Cell: (props: Cell<IPartnerMember>) => (
      <div className={`color-chip ${props.value ? "red" : "green"}`}>
        {props.value ? "비활성" : "활성"}
      </div>
    ),
  },
];

function PartnerMemberSelectModal({
  onCanceled,
  onAdded,
  defaultValues,
  multiSelect,
  disabledIds,
  title,
}: Props) {
  const [searchedWord, setSearchedWord] = useState("");
  const [page, setPage] = useState({ current: 0, total: 0 });
  const [partnerList, setPartnerList] = useState<IPartnerMember[]>([]);
  const [selectedPartnerList, setSelectedPartnerList] = useState<IPartnerMember[]>(defaultValues);
  const { setLoadingBar } = useLoadingBarContext();
  const partnerListTableData = partnerList.map((partner) => {
    const selectedIdList = selectedPartnerList.map((partner) => partner.id);
    if (disabledIds) {
      return {
        disabled: disabledIds.includes(String(partner.id)),
        checked: selectedIdList.includes(partner.id),
        ...partner,
      };
    } else
      return {
        checked: selectedIdList.includes(partner.id),
        ...partner,
      };
  });

  const searchFormRef = useRef<HTMLFormElement>(null);
  const { handleSubmit, control, getValues } = useForm<{ searchWord: string }>();
  const { executeAsync: getPartnerMembers } = useApiOperation(getPartnerMemberList);

  const onSubmit = ({ searchWord }: { searchWord: string }) => setSearchedWord(searchWord);
  const goPage = (nextPage: number) => setPage({ ...page, current: nextPage });

  const fetchPartnerList = async (searchWord: string) => {
    if (searchWord !== "") {
      setPage({ current: 0, total: 0 });
    }

    const noSearchPayload = {
      page: page.current,
      size: 20,
      sort: {
        orders: [
          {
            property: "id",
            direction: "DESC",
          },
        ],
      },
    };
    const result = await getPartnerMembers(
      !searchWord ? noSearchPayload : { ...noSearchPayload, containsEmail: searchWord },
    );

    setPartnerList(result.data.data.content);
    setPage({
      ...page,
      total: result.data.meta.pageMeta?.totalPages || 0,
    });
  };

  const handleCheckboxClick = (checkbox: HTMLDivElement) => {
    const partnerId = checkbox.dataset.partnerId;
    const checked = Boolean(checkbox.dataset.checked === "true");

    if (!checked) {
      const selectedPartner = partnerList.find((partner) => partner.id === partnerId);

      if (multiSelect) {
        setSelectedPartnerList([...selectedPartnerList, selectedPartner!]);
      } else {
        setSelectedPartnerList([selectedPartner!]);
      }
    } else {
      const filteredPartnerList = selectedPartnerList.filter((partner) => partner.id !== partnerId);
      setSelectedPartnerList(filteredPartnerList);
    }
  };
  const handleModalContentsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;

    const tr = target.closest(".base-table__tr");
    const td = tr?.children[0];
    const checkbox = td?.children[0] as HTMLDivElement;

    if (checkbox) handleCheckboxClick(checkbox);
  };
  const handleSearchClick = () => {
    getValues("searchWord");
    fetchPartnerList(getValues("searchWord"));
  };
  const handleCancelButtonClick = () => onCanceled();
  const handleAddSelectedPartnerButtonClick = () => onAdded(selectedPartnerList);

  useEffect(() => {
    fetchPartnerList(searchedWord);
  }, [page.current, searchedWord]);

  return (
    <BaseModal isOpen={true} className="dialog-modal">
      <div>
        <div className="modal-title">
          <div className="left-area">
            <span>{title ? title : "파트너"} 선택</span>
          </div>
          <form className="right-area" onSubmit={handleSubmit(onSubmit)} ref={searchFormRef}>
            <Controller
              name="searchWord"
              control={control}
              render={({ field }) => (
                <BaseInput
                  placeholder="검색어를 입력하세요"
                  value={field.value}
                  onChange={field.onChange}
                  onKeyUp={handleSearchClick}
                  onSearchClick={handleSearchClick}
                />
              )}
            ></Controller>
          </form>
        </div>
        <div className="base-modal__contents" onClick={handleModalContentsClick}>
          <ViewDataTable columns={PARTNER_TABLE_COLUMNS} data={partnerListTableData} />
          <BasePagination pageIndex={page.current} totalPages={page.total} goPage={goPage} />
        </div>
        <div className="base-modal__btn-wrap">
          <BaseButton
            title={"취소"}
            type="button"
            className="color-white no-outline text-primary3 mr10"
            onClick={handleCancelButtonClick}
          />
          <BaseButton
            type="button"
            title={"선택 추가"}
            onClick={handleAddSelectedPartnerButtonClick}
          />
        </div>
      </div>
    </BaseModal>
  );
}
export default PartnerMemberSelectModal;
