import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getProductDetailAsync } from "src/api/product/product-api";
import { ProductModel, ProductType } from "src/api/product/product-types";
import { getProviderList } from "src/api/provider/provider-api";
import { BaseButton, ContentsIdSection } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "..";
import FullCourtDetail from "./FullCourtDetail";
import NonResidentDetail from "./NonResidentDetail";
import OpenCourtDetail from "./OpenCourtDetail";
import TimeCourtDetail from "./TimeCourtDetail";

/* 
  공간상품 등록 > 등록 or 수정 > 상품정보
 */
const ProductInfoDetail = ({ product }: { product: ProductModel }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const productId: number | undefined = Number(params.id);

  const { setLoadingBar } = useLoadingBarContext();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const [providerName, setProviderName] = useState("");

  const [productDetail, setProductDetail] = useState<ProductModel>();
  // const [productType, setProductType] = useState("");

  // 공간상품 상세 조회 api
  const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync);
  //  프로바이더 목록 api
  const { executeAsync: getProviers } = useApiOperation(getProviderList);

  const getProviderName = useCallback(async (providerId) => {
    const response = await getProviers();
    const provider = response.data.data.content.filter(
      (item) => String(item.providerId) === providerId,
    );

    if (provider.length > 0 && provider[0]) {
      setProviderName(String(provider[0].providerName));
    }
  }, []);

  const getProductBasicDetail = useCallback(async () => {
    if (!product) return;
    setLoadingBar(true);
    if (productId) {
      // const response = await getProductDetail({ productId });

      // if (response.status >= 200 && response.status <= 299) {
      const result = product;
      setProductDetail(result);
      // setProductType(result.productType);

      await getProviderName(result.providerId);
      setLoadingBar(false);
      // } else {
      // setLoadingBar(false);
      // }
    }
  }, [product, getProductDetail, productId, setLoadingBar]);

  useEffect(() => {
    getProductBasicDetail();
  }, [getProductBasicDetail]);

  const getProductTypeName = useCallback(() => {
    if (productDetail?.productType) {
      if (productDetail?.productType === "FULL_COURT") {
        return "Full Court";
      } else if (productDetail?.productType === "OPEN_COURT") {
        return "Open Court";
      } else if (productDetail?.productType === "TIME_COURT") {
        return "Service Court";
      } else if (productDetail?.productType === "NON_RESIDENT_OFFICE") {
        return "비상주";
      } else {
        return "현재 지정된 상품 타입이 없습니다.";
      }
    }
  }, [productDetail?.productType]);

  // 공간상품 id
  // const productId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection title="상품정보" id={productId} />
        <article>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>프로바이더</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <span>{providerName}</span>

                {/* <span>{productDetail?.providerId}</span> */}
              </div>
            </div>
          </section>
          {/* 이용 후 비공개처리 */}
          {productDetail?.productCategory === "PRODUCT_CATEGORY_NORMAL" && (
            <div>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>
                    신청/계약 후<br />
                    비공개처리
                  </p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <span>{productDetail?.afterStatus === "DISABLED" ? "비공개" : "공개"}</span>
                  </div>
                </div>
              </section>
              {/* 상품 타입 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>상품 타입</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <div>
                      <span>{productDetail?.productType && getProductTypeName()}</span>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
        </article>

        {/* 상품타입에 따라서 렌더링 */}
        {productDetail?.productCategory !== "PRODUCT_CATEGORY_NORMAL" &&
          productDetail?.productType === ProductType.F && (
            <FullCourtDetail detail={productDetail} />
          )}
        {productDetail?.productCategory !== "PRODUCT_CATEGORY_NORMAL" &&
          productDetail?.productType === ProductType.O && (
            <OpenCourtDetail detail={productDetail} />
          )}
        {productDetail?.productCategory !== "PRODUCT_CATEGORY_NORMAL" &&
          productDetail?.productType === ProductType.T && (
            <TimeCourtDetail detail={productDetail} />
          )}
        {productDetail?.productCategory !== "PRODUCT_CATEGORY_NORMAL" &&
          productDetail?.productType === ProductType.N && (
            <NonResidentDetail detail={productDetail} />
          )}
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            className="color-white size-large"
            onClick={() => navigate(PagePath.product.list)}
          />
        </div>
        <div className="right-area">
          {/* <BaseButton
            title="수정"
            className="size-large"
            onClick={() => {
              const formPath = `${PagePath.product.form}?id=${productId}${location.search.replace(
                "?",
                "&",
              )}`;
              navigate(formPath);
            }}
          /> */}
        </div>
      </div>
    </div>
  );
};
export default ProductInfoDetail;
