import { ApiPath } from "src/api";
import { ApiResponse } from "src/api/public-types";
import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import {
  ReservationListParams,
  ReservationListResponse,
  ReservationUpdateResponseData,
  ReservationUsageResponse,
} from "./reservation-types";

/**
 * CRV01. 예약 상세 목록 페이지 조회
 */

export const getReservationListAsync = async (
  axios: AxiosInstance,
  params?: ReservationListParams,
): Promise<AxiosResponse<ApiResponse<ReservationListResponse>>> => {
  return await axios.get<ApiResponse<ReservationListResponse>>(ApiPath.reservation.list, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

/**
 * CRV02. 예약 상태 변경 요청
 */
export async function changeReservationStatusAsync(
  axios: AxiosInstance,
  params?: {
    reservationId: string;
    status: "CANCELLED" | "ACCEPTED";
  },
): Promise<AxiosResponse<ApiResponse<ReservationUpdateResponseData>>> {
  if (!params?.reservationId) {
    throw Error("reservationId is not found.");
  }
  if (!params?.status) {
    throw Error("status is not found.");
  }
  const path = ApiPath.reservation.changeStatus.replace("{id}", params.reservationId.toString());
  return await axios.patch(path, {
    statusCode: params.status,
  });
}

/**
 * CRV03. 예약 소진 시간 조회
 */

export const getReservationUsagesAsync = async (
  axios: AxiosInstance,
  params?: {
    id: string;
  },
): Promise<AxiosResponse<ApiResponse<ReservationUsageResponse>>> => {
  if (!params?.id) {
    throw Error("reservationId is not found.");
  }
  return await axios.get<ApiResponse<ReservationUsageResponse>>(
    ApiPath.reservation.usages.replace("{id}", params.id.toString()),
  );
};

/**
 * RRV04. 예약 소진 시간 목록 조회
 */

export const getReservationUsageListAsync = async (
  axios: AxiosInstance,
  params?: ReservationListParams,
): Promise<AxiosResponse<ApiResponse<ReservationListResponse>>> => {
  return await axios.get<ApiResponse<ReservationListResponse>>(ApiPath.reservation.usagesList, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};
