import { useEffect } from "react";
import { VisitorModel } from "src/api/visitor/visitor-types";
import pagePath from "src/pagePath.json";
import { useTitleOperation } from "src/pages/hooks";
import { formatPhoneNumber } from "src/utils";

type Props = {
  visitors: Array<VisitorModel>;
};

/* 
  방문자 > 방문자 초대 (등록, 수정) > 방문자 등록 목록 컴포넌트
*/
const VisitorsSection = ({ visitors }: Props) => {
  const court = process.env.REACT_APP_COURT_BASSEURL;

  useTitleOperation(visitors[0]?.visitApplyNumber);

  return (
    <>
      {visitors.map((visitor: VisitorModel, index: number) => (
        <section key={index.toString()} className="flex-start mb10">
          <div className="border-gray w-fit pa20">
            <div className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>이름</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax240 flex-center flex-row justify-contents-start">
                  <span className="mr20">{visitor?.visitorName}</span>
                  <a
                    href={
                      court +
                      "/front/court/visitor/detail/" +
                      visitor?.visitApplyNumber +
                      visitor?.contractVisitorId
                    }
                    target="_blank"
                    className="ml10 text-hilight"
                    rel="noreferrer"
                  >
                    QR 링크
                  </a>
                </div>
              </div>
            </div>
            <div className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>휴대폰</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax200">
                  <p>
                    {visitor?.visitorMobileNumber
                      ? formatPhoneNumber(visitor?.visitorMobileNumber)
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="contents-container__grid mb0">
              <div className="contents-container__grid-index">
                <p>차량</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax200">
                  <p>{visitor?.visitorCarNumber}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
    </>
  );
};
export default VisitorsSection;
