import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import {
  editProviderContactAsync,
  postProviderContactAsync,
  providerDetailAsync,
} from "src/api/provider/provider-api";
import { ProviderContactModel, ProviderDetailModel } from "src/api/provider/provider-types";
import { BaseButton, BaseModal, ContentsIdSection } from "src/components";
import { useLoadingBarContext, useToastContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { Modal } from "src/pages/product/product-types";
import { validateEmailText, validateMobileNumber, validatePhoneNumber } from "src/utils";
import { ProviderContactFormType } from "../../provider-types";
import BillingContactForm from "./components/BillingContactForm";
import ContractContactForm from "./components/ContractContactForm";
import CustomerContactForm from "./components/CustomerContactForm";

const ContactInfoForm = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();
  // 토스트
  const { openToast } = useToastContext();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const providerId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  const [submitData, setSubmitData] = useState<ProviderContactFormType>();
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 연락처 삭제 array
  const [deletedContact, setDeletedContact] = useState<Array<ProviderContactModel>>([]);

  //  연락처 등록
  const { executeAsync: postProviderContact } = useApiOperation(postProviderContactAsync);

  //  연락처 등록
  const { executeAsync: editProviderContact } = useApiOperation(editProviderContactAsync);

  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  const defaultValues = useMemo(() => {
    const providerContactList = {
      customerContact: {
        name: "",
        contact: "",
        email: "",
        assignedWorkType: "CS_PROVIDE",
        description: "",
        isDeleted: false,
      },

      contractContactList: [
        {
          name: "",
          contact: "",
          email: "",
          assignedWorkType: "NORMAL_PROVIDE",
          description: "",
          isDeleted: false,
        },
      ],
      billingContact: {
        name: "",
        contact: "",
        email: "",
        assignedWorkType: "TAX_PROVIDE",
        description: "",
        isDeleted: false,
      },
    };

    return providerContactList;
  }, []);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ProviderContactFormType>({
    defaultValues,
  });

  // 프로바이더 상세 조회 후 setValue 처리
  const providerDetailApi = useCallback(async (id: string) => {
    setLoadingBar(true);

    const response = await getProviderDetail({ providerId: id });
    if (response.status >= 200 || response.status <= 299) {
      const detail: ProviderDetailModel = response.data.data;
      if (detail.providerContactList && detail.providerContactList?.length > 0) {
        const contactList = detail.providerContactList;

        contactList.forEach((item: ProviderContactModel) => {
          if (item.assignedWorkType === "CS_PROVIDE") {
            setValue("customerContact", item);
          }

          if (item.assignedWorkType === "TAX_PROVIDE") {
            setValue("billingContact", item);
          }
        });

        const contractList = contactList.filter(
          (item) => item.assignedWorkType === "NORMAL_PROVIDE",
        );

        setValue("contractContactList", contractList);
      }
    }
    setLoadingBar(false);
  }, []);

  useEffect(() => {
    if (providerId) {
      providerDetailApi(String(providerId));
    }
  }, [providerDetailApi, providerId]);

  //  유효성 확인
  const formValidation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";
    const contractList = watch("contractContactList");

    register("customerContact.email", {
      validate: {
        required: (email?: string) => {
          return validateEmailText(email);
        },
      },
    });

    register(`customerContact.contact`, {
      validate: {
        contactNumber: (contactNumber?: string) => {
          return validatePhoneNumber(contactNumber);
        },
      },
    });

    // 이메일
    register("billingContact.email", {
      validate: {
        required: (email?: string) => {
          return validateEmailText(email);
        },
      },
    });

    // 계약 진행

    contractList.forEach((item, idx: number) => {
      register(`contractContactList.${idx}.name`, {
        required: { value: true, message: requiredMessage },
      });

      register(`contractContactList.${idx}.contact`, {
        validate: {
          requried: (contact?: string) => {
            return validateMobileNumber(contact);
          },
        },
      });

      register(`contractContactList.${idx}.email`, {
        validate: {
          requried: (eamil?: string) => {
            return validateEmailText(eamil);
          },
        },
      });
    });
  }, [register, watch("contractContactList")]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  // 저장 팝업 시 확인 누르면 등록/수정 진행
  const onClickConfirmModal = useCallback(
    async (data: ProviderContactFormType) => {
      let contactData: Array<ProviderContactModel> = [];

      if (data?.contractContactList) {
        data.contractContactList = [...data?.contractContactList, ...deletedContact];
      }

      const customer = data.customerContact;
      const contract = data.contractContactList;
      const billing = data.billingContact;

      contactData = [...contract, customer, billing];

      if (customer.contactId) {
        const response = await editProviderContact({
          providerId: Number(providerId),
          providerContactList: contactData,
        });
        if (response.status >= 200 && response.status <= 299) {
          openToast(`정상적으로 ${customer.contactId ? "수정" : "등록"}되었습니다.`);
          navigate(
            PagePath.provider.detail.replace(
              ":id",
              `${response.data.data.providerId}?tab=contactInfo`,
            ),
          );
        }
      } else {
        const response = await postProviderContact({
          providerId: Number(providerId),
          providerContactList: contactData,
        });
        if (response.status >= 200 && response.status <= 299) {
          openToast(`정상적으로 ${customer.contactId ? "수정" : "등록"}되었습니다.`);
          navigate(
            PagePath.provider.detail.replace(
              ":id",
              `${response.data.data.providerId}?tab=contactInfo`,
            ),
          );
        }
      }
    },
    [deletedContact],
  );

  // 유효성 검사후 저장
  const onSubmit = useCallback(
    async (data?: ProviderContactFormType, e?: any) => {
      e.preventDefault();
      setSubmitData(data);
      console.log("data", data);
      setConfirmModal({ isOpen: true, message: "저장 하시겠습니까?" });
    },
    [deletedContact],
  );

  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);

  return (
    <div className="contents-container__wrap detail-basic-info">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__wrap-contents">
          <ContentsIdSection title="연락처 정보" id={providerId} />

          {/* CS/문의 담당 */}

          <CustomerContactForm control={control} errors={errors} />

          {/* 계약 담당 */}
          <ContractContactForm
            control={control}
            deletedContact={deletedContact}
            setDeletedContact={setDeletedContact}
            errors={errors}
            register={register}
          />

          {/* 세금 담당 */}
          <BillingContactForm control={control} errors={errors} />

          {/* 버튼영역 */}
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title="목록으로"
                className="color-white size-large"
                onClick={() => {
                  if (providerId) {
                    navigate(PagePath.provider.list);
                  } else {
                    navigate(PagePath.provider.list);
                  }
                }}
              />
            </div>
            <div className="left-area">
              <BaseButton type="submit" title="저장" className="color-black size-large" />
            </div>
          </div>
        </div>
      </form>

      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => submitData && onClickConfirmModal(submitData)}
      >
        <p>{confirmModal.message}</p>
      </BaseModal>
    </div>
  );
};

export default ContactInfoForm;
