import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useApiOperation } from "src/api/hooks";
import { getPartnerAuthoritiesAsync } from "src/api/partner";
import { postPartnerMemberAsync } from "src/api/partner-member/api";
import { Partner, PartnerMemberAuthority } from "src/api/partner/type";
import { Modal, Order, PageMeta } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal, BasePagination } from "src/components";
import PartnerMemberSelectModal from "src/components/partner/PartnerMemberSelectModal";
import { useLoadingBarContext } from "src/pages/hooks";
import columns from "../components/PartnerAuthColumns";
type QueryParams = {
  page?: number;
  size?: number;
  containsEmail?: string;
  sort?: {
    orders?: Array<Order>;
  };
};

const PartnerAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { control, handleSubmit, setValue } = useForm<QueryParams>();
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });
  const { setLoadingBar } = useLoadingBarContext();
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    return _queryParams;
  }, [location]);
  const [partnerAuthList, setPartnerAuthList] = useState<PartnerMemberAuthority[]>([]);
  const [allPartnersAuthList, setAllPartnersAuthList] = useState<PartnerMemberAuthority[]>([]);
  const [params, setParams] = useState<QueryParams>({ ...queryParams });
  const [pageMeta, setPateMeta] = useState<PageMeta>();
  // 선택된 파트너 정보
  const [partner, setPartner] = useState<Partner>();
  const [pageNum, setPageNum] = useState(0);
  const [addMemberModal, setAddMemberModal] = useState<Modal>({ isOpen: false });
  const { executeAsync: getPartnerAuthorities } = useApiOperation(getPartnerAuthoritiesAsync, {
    doNotHandleLoadingBar: true,
  });
  const { executeAsync: postPartnerMember } = useApiOperation(postPartnerMemberAsync);

  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams) => {
      let data = params;

      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params],
  );
  const formRef = useRef<HTMLFormElement>(null);
  const onSearchClick = () =>
    formRef.current?.dispatchEvent(new Event("submit", { bubbles: true }));

  const fetchApi = useCallback(
    async (id: string) => {
      setLoadingBar(true);
      const { data, status } = await getPartnerAuthorities({ ...queryParams, partnerId: id });
      if (status >= 200 || status < 300) {
        setPartnerAuthList(data.data.content || []);
        setPateMeta(data.meta.pageMeta);

        if (data.meta.pageMeta?.totalPages) {
          let totalList: PartnerMemberAuthority[] = [];

          for (let i = 0; i < data.meta.pageMeta.totalPages; i++) {
            const { data } = await getPartnerAuthorities({
              partnerId: id,
              page: i,
              size: 20,
            });
            totalList.push(...data.data.content);
          }
          setAllPartnersAuthList(totalList);
        }
        setLoadingBar(false);
      }
    },
    [getPartnerAuthorities, queryParams],
  );
  const onSubmit = (data: QueryParams) => {
    navigateWithQueryParams({ page: 0, ...data });
  };
  useEffect(() => {
    if (id) {
      fetchApi(id);
    }
    setValue("containsEmail", params.containsEmail);
  }, []);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columns,
      data: partnerAuthList || [],
      setPartnerAuthList,
      fetchApi,
      setConfirmModal,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <div className="contents-container__wrap-contents">
      <div className="mb10">
        <h2>권한</h2>
      </div>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <div className="permission-header">
          <Controller
            name="containsEmail"
            control={control}
            render={({ field, fieldState }) => (
              <BaseInput
                onSearchClick={onSearchClick}
                onKeyUp={onSearchClick}
                onChange={field.onChange}
                value={field.value}
                className="minmax300"
                placeholder="이메일"
                errorText={fieldState.error?.message}
              />
            )}
            rules={{
              pattern: {
                value: /^.{3,}$/i,
                message: "이메일은 3자 이상 입력해주세요.",
              },
            }}
          />
          <BaseButton
            title={"+ 파트너 회원 추가"}
            onClick={() => setAddMemberModal({ isOpen: true })}
          />
          {addMemberModal.isOpen && (
            <PartnerMemberSelectModal
              title="파트너 회원"
              onCanceled={() => {
                console.log(partnerAuthList);
                setAddMemberModal({ isOpen: false });
              }}
              multiSelect
              onAdded={async (members) => {
                if (id) {
                  setLoadingBar(true);
                  const promiseArray = members.map((member) =>
                    postPartnerMember({
                      memberId: member.id,
                      partnerIds: [String(id)],
                    }),
                  );
                  const result = await Promise.all(promiseArray);
                  if (result) {
                    setTimeout(() => {
                      fetchApi(id);
                      setLoadingBar(false);
                    }, 2000); //임시.. post에 성공해도 이전목록이 내려와 2초뒤 호출
                  }
                }
                setAddMemberModal({ isOpen: false });
              }}
              disabledIds={
                allPartnersAuthList
                  ? allPartnersAuthList.map((partner) => String(partner.memberId))
                  : []
              }
              defaultValues={[]}
            />
          )}
        </div>
        <section className="inner-tab-table permission-table">
          <div className="my20">
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} className={`base-table__tr`}>
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              {rows.length === 0 && (
                <div className="base-table__tr" style={{ width: tableWidth }}>
                  <div className="base-table__td w-100 text-center">
                    <div className="w-100">
                      <span>데이터가 없습니다.</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div style={{ width: tableWidth }}>
            <BasePagination
              pageIndex={Number(params.page) || 0}
              totalPages={Number(pageMeta?.totalPages || 0)}
              goPage={(pageIndex: number) => {
                navigateWithQueryParams({ page: Number(pageIndex) });
                setPageNum(Number(pageIndex));
              }}
            />
          </div>
        </section>

        {confirmModal.isOpen && (
          <BaseModal
            isOpen={confirmModal.isOpen}
            btnRightTitle="확인"
            onClick={() => {
              setConfirmModal({ isOpen: false });
            }}
          >
            <>
              <h3 className="mb10">등록된 휴대폰 번호가 없습니다</h3>
              <p>
                해당 파트너 회원의 휴대폰번호를 먼저 등록한 뒤<br /> 알림톡 수신 설정을 해주세요.
              </p>
            </>
          </BaseModal>
        )}
      </form>
    </div>
  );
};

export default PartnerAuth;
