import { IMenuListStatus } from "../types";
import { PagePath } from "src/pages/product/details";
import { ReservationState } from "src/pages/reservation/reservation-types";

const BASE_PATH = `${PagePath.reservation.list}?page=0&size=20&sort.orders[0].property=id&sort.orders[0].direction=DESC&keyword=&start=2022-07-01T00:00:00+09:00&statusCode=`;

const RESERVATION_MENU_LIST: IMenuListStatus[] = [
  {
    title: "전체",
    count: -1,
    path: BASE_PATH,
    backgroundColor: "bg-dashboard-card",
    status: '',
  },
  {
    title: "접수",
    count: -1,
    path: BASE_PATH + ReservationState.ACCEPTED,
    backgroundColor: "Default",
    status: ReservationState.ACCEPTED,
  },
  {
    title: "점유",
    count: -1,
    path: BASE_PATH + ReservationState.ACKNOWLEDGED,
    backgroundColor: "Default",
    status: ReservationState.ACKNOWLEDGED,
  },
  {
    title: "불완전",
    count: -1,
    path: BASE_PATH + ReservationState.INCOMPLETE,
    backgroundColor: "Default",
    status: ReservationState.INCOMPLETE,
  },
  {
    title: "취소",
    count: -1,
    path: BASE_PATH + ReservationState.CANCELLED,
    backgroundColor: "Default",
    status: ReservationState.CANCELLED,
  },
];

export { RESERVATION_MENU_LIST };
