import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "src/api";
import { ApiResponse } from "../public-types";
import {
  BuildingAddModel,
  BuildingDetailResponseData,
  BuildingEditModel,
  BuildingListResponseData,
  BuildingListParams,
  BuildingStatus,
  BuildingUpdateResponseData,
} from "./building-types";

/* 
  CPB01. 건물 목록 조회
*/
export async function getBuildingsAsync(
  axios: AxiosInstance,
  params?: BuildingListParams,
): Promise<AxiosResponse<ApiResponse<BuildingListResponseData>>> {
  return await axios.get<ApiResponse<BuildingListResponseData>>(ApiPath.building.list, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/* 
  CPB02. 건물 기본정보 상태 변경
*/
export async function changeBuildingStatusAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number;
    status: BuildingStatus;
  },
): Promise<AxiosResponse<ApiResponse<BuildingUpdateResponseData>>> {
  if (!params?.buildingId) {
    throw Error("buildingId is not found.");
  }
  if (!params?.status) {
    throw Error("status is not found.");
  }
  const path = ApiPath.building.status.replace("{id}", params.buildingId.toString());
  return await axios.patch(path, {
    building: { status: params.status },
  });
}

/* 
  CPB03. 건물 기본 정보 등록
*/
export async function createBuildingAsync(
  axios: AxiosInstance,
  params?: {
    building: BuildingAddModel;
  },
): Promise<AxiosResponse<ApiResponse<BuildingUpdateResponseData>>> {
  return await axios.post(ApiPath.building.add, params);
}

/* 
  CPB04. 건물 기본 정보 수정
*/
export async function updateBuildingAsync(
  axios: AxiosInstance,
  params?: {
    building: BuildingEditModel;
  },
): Promise<AxiosResponse<ApiResponse<BuildingUpdateResponseData>>> {
  if (!params?.building?.id) {
    throw Error("id is not found.");
  }
  const path = ApiPath.building.edit.replace("{id}", params.building.id.toString());
  return await axios.put(path, params);
}

/* 
  CPB05. 건물 정보 조회
*/
export async function getBuildingAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number;
  },
): Promise<AxiosResponse<ApiResponse<BuildingDetailResponseData>>> {
  if (!params?.buildingId) {
    throw Error("buildingId is not found.");
  }
  const id = params.buildingId.toString();
  const path = ApiPath.building.detail.replace("{id}", id);
  const result = await axios.get(path);
  if (!result.data?.data?.content?.building) {
    throw Error(`건물 id: ${id} 의 건물 정보 조회를 실패하였습니다.`);
  }
  return result;
}

/* 
  CPB06. 건물 기본정보 삭제
*/
export async function deleteBuildingAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number;
  },
): Promise<AxiosResponse<ApiResponse<BuildingUpdateResponseData>>> {
  if (!params?.buildingId) {
    throw Error("buildingId is not found.");
  }
  const path = ApiPath.building.delete.replace("{id}", params.buildingId.toString());
  return await axios.delete(path);
}
