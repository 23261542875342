import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ContractStep, ContractUseRequestList } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { getMemberDetail } from "src/api/member/member-api";
import { MemberModel } from "src/api/member/member-types";
import { Order, Sort } from "src/api/public-types";
import { getContractUseRequests } from "src/api/user/user-api";
import { BaseButton, BaseTooltip, ContentsIdSection } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { MemberDetailsTable } from "src/pages/member/components/MemberDetailsTable";
import { PagePath } from "src/pages/product/details";

const MemberRequestColumns = [
  {
    Header: "no",
    accessor: "",
    width: 70,
    Cell: ({ row }: any) => {
      return <span>{row.index + 1}</span>;
    },
  },
  {
    Header: "신청번호",
    accessor: "contractApplyNumber",
    width: 100,
    Cell: ({ row }: any) => {
      const contractNumber = row.original.contractId;
      const applyNumber = row.original.contractApplyNumber;
      const contractDetail = `${PagePath.contract.detail.replace(":id", `${contractNumber}`)}`;

      return (
        <div>
          <Link to={contractDetail} target="_blank" className="text-hilight">
            {applyNumber || ""}
          </Link>
        </div>
      );
    },
  },
  {
    Header: "상품명",
    accessor: "spaceProductName",
    width: 200,
  },
  {
    Header: "이용 시작일",
    accessor: "contractStartTime",
    width: 100,
    Cell: ({ row }: any) => {
      return <BaseTooltip contents={row?.original?.contractStartTime} type="date" />;
    },
  },
  {
    Header: "이용 종료일",
    accessor: "contractEndTime",
    width: 100,
    Cell: ({ row }: any) => {
      return <BaseTooltip contents={row?.original?.contractEndTime} type="date" />;
    },
  },
  {
    Header: "신청/계약 상태",
    accessor: "memberStatus",
    width: 100,
    Cell: ({ row }: any) => {
      const status = row.original.contractStep;
      if (status === ContractStep.APPLY_RECEIVED) {
        return <span>신청접수</span>;
      } else if (status === ContractStep.APPLY_CANCEL) {
        return <span>신청취소</span>;
      } else if (status === ContractStep.APPLY_CONFIRM) {
        return <span>신청확인</span>;
      } else if (status === ContractStep.CONTRACT_PLAN) {
        return <span>계약예정</span>;
      } else if (status === ContractStep.CONTRACT_ACCEPT) {
        return <span>계약체결</span>;
      } else if (status === ContractStep.USE_APPROVAL) {
        return <span>이용승인</span>;
      } else if (status === ContractStep.USE_PROGRESS) {
        return <span>이용중</span>;
      } else if (status === ContractStep.USE_COMPLETE) {
        return <span>이용완료</span>;
      } else if (status === ContractStep.TERMINATE_RECEIVED) {
        return <span>해지접수</span>;
      } else {
        return <span>해지완료</span>;
      }
    },
  },
];

type QueryParams = {
  page?: number;
  size?: number;
  memberNo?: string;
  isContractor?: string;
  isAdmin?: string;
  sort?: Sort;
};

const useRequestProduct = ({}) => {
  const param = useParams();
  const memberId = param.id;
  const location = useLocation();
  const navigate = useNavigate();

  const [basicInfo, setBasicInfo] = useState<MemberModel>();

  const [useRequestData, setUseRequestData] = useState<ContractUseRequestList[]>([]);
  const [params, setParams] = useState<any>();

  const { setLoadingBar } = useLoadingBarContext();

  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    if (!_queryParams?.memberNo) {
      _queryParams.memberNo = String(basicInfo?.memberNo);
    }

    if (!_queryParams?.isContractor) {
      _queryParams.isContractor = "true";
    }
    if (!_queryParams?.isAdmin) {
      _queryParams.isAdmin = "true";
    }
    return _queryParams;
  }, [basicInfo?.memberNo, location.search]);

  //
  // 목록 api
  const { executeAsync: getUseProductsRequest } = useApiOperation(getContractUseRequests);

  const { executeAsync: getMemberDetailAsync } = useApiOperation(getMemberDetail);

  const getMemberDetailApi = useCallback(async (id: string) => {
    if (memberId) {
      setLoadingBar(true);
      const response = await getMemberDetailAsync({ id });
      if (response.status >= 200 && response.status <= 299) {
        const memberData = response?.data?.data?.member;

        setBasicInfo(memberData || null);
      }
      setLoadingBar(false);
    }
  }, []);
  useEffect(() => {
    getMemberDetailApi(String(memberId));
  }, [getMemberDetailApi, memberId]);

  const callUseProductsRequest = useCallback(async () => {
    setLoadingBar(true);

    const response = await getUseProductsRequest(queryParams);

    if (response.status >= 200 && response.status <= 299) {
      const result = response?.data?.data?.content;
      setUseRequestData(result);
      setLoadingBar(false);
    } else {
      setLoadingBar(false);
    }
  }, [getUseProductsRequest, queryParams, setLoadingBar]);

  useEffect(() => {
    if (basicInfo?.memberNo !== undefined) {
      callUseProductsRequest();
    }
  }, [basicInfo?.memberNo, callUseProductsRequest]);

  return (
    <div className="contents-container__wrap detail-basic-info">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection
          title="신청/계약 내역"
          id={basicInfo?.id}
          noTitle="회원번호"
          no={basicInfo?.memberNo}
        />

        <div className="mt20">
          <MemberDetailsTable
            data={useRequestData}
            columns={MemberRequestColumns}
            orders={params?.orders}
            disabledSortHeaders={[
              "no",
              "contractApplyNumber",
              "spaceProductName",
              "memberStatus",
              "inviteDate",
              "useStartTime",
              "useEndTime",
              "contractStartTime",
              "contractEndTime",
            ]}
            setOrders={(orders?: Array<Order>) => {
              setParams({ orders });
            }}
          />
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            onClick={() => navigate(PagePath.member.list)}
            className="color-white size-large"
          />
        </div>
        <div className="right-area"></div>
      </div>
    </div>
  );
};

export default useRequestProduct;
