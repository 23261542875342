import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MbOrg } from "src/api/organization/org-types";
import { BaseButton, BaseInput, BaseModal, BaseSelect } from "src/components";
import {
  floorHoTypeOptions,
  Modal,
  Property,
  SelectOption,
} from "src/pages/building/building-types";
import { calculatePyeong } from "src/utils";
import OrganizationListPopup from "./OrganizationListPopup";

type Props = {
  property?: Property;
  saveProperty: Function;
  setProperty: Function;
  properties?: Array<Property>;
  rangeFloorOptions: Array<SelectOption>;
  partnerId?: string;
};

// 호 저장 테이블 컴포넌트
const SaveHo = ({
  property,
  setProperty,
  saveProperty,
  properties,
  rangeFloorOptions,
  partnerId,
}: Props) => {
  // useForm defaultValues

  // 소유주 팝업에서 선택된 값
  const [selectedOwner, setSelectedOwner] = useState<MbOrg>();
  const defaultValues: Property = useMemo(() => {
    return {
      type: "HO",
      id: undefined,
      floor: undefined,
      floorName: undefined,
      hoId: undefined,
      ho: 0,
      hoName: "",
      dedicatedArea: undefined,
      dedicatedAreaPyeong: undefined,
      contractArea: undefined,
      contractAreaPyeong: undefined,
      rent: undefined,
    };
  }, []);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<Property>({
    defaultValues,
  });
  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    register("floor", {
      validate: {
        // 존재하는 층을 선택했는지 확인
        isExistFloor: (v) => {
          let isExist = false;
          if (properties && properties.length > 0) {
            isExist = !!properties.find((p: Property) => p.floor === v);
          }
          return isExist || "존재하지 않은 층 입니다. 층 부터 저장해주세요.";
        },
      },
    });
    register("ho", {
      required: "호는 필수입력 항목 입니다.",
      validate: {
        // 중복인 호를 등록하는지 확인
        isExistHo: (v?: number) => {
          if (!!getValues("hoId")) return true; // 수정 모드에서는 중복체크 하지 않는다.
          let isExist = false;
          if (properties && properties.length > 0) {
            isExist = !!properties.find((p: Property) => p.ho === v);
          }
          return !isExist || "이미 존재하는 호 입니다.";
        },
      },
      valueAsNumber: true,
      setValueAs: (v) => parseInt(v),
    });
    register("dedicatedArea", {
      required: "전용면적은 필수입력 항목 입니다.",
      valueAsNumber: true,
      setValueAs: (v) => parseInt(v),
    });
    register("contractArea", {
      required: "계약면적은 필수입력 항목 입니다.",
      valueAsNumber: true,
      setValueAs: (v) => parseInt(v),
    });
    register("rent", {
      required: "임대료는 필수입력 항목 입니다.",
      valueAsNumber: true,
      setValueAs: (v) => parseInt(v),
    });
  }, [register, properties, property, getValues]);

  // 수정시 api 에서 받아온 property 정보로 setValue 처리
  useEffect(() => {
    if (property) {
      Object.entries(property).forEach(([name, value]: any) => {
        if (name === "floor") {
          // 규모 범위 안에 존재하지 않는 층이면 null 로 바인딩되서 선택되어있지 않게 처리
          const isFindFloorOption = !!rangeFloorOptions.find(
            (option: SelectOption) => option.value === value,
          );
          setValue(name, isFindFloorOption ? value : null);
        } else if (name === "dedicatedArea") {
          setValue("dedicatedAreaPyeong", calculatePyeong(Number(getValues("dedicatedArea") || 0)));
          setValue(name, value);
        } else if (name === "contractArea") {
          setValue("contractAreaPyeong", calculatePyeong(Number(getValues("contractArea") || 0)));
          setValue(name, value);
        } else {
          setValue(name, value);
        }
      });
    }
  }, [setValue, property, getValues, rangeFloorOptions]);

  useEffect(() => {
    // 수정 버튼으로 propterty 변경시 validation error clear
    clearErrors();
  }, [property, clearErrors]);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 소유주 모달
  const [ownerModal, setOwnerModal] = useState<Modal>({
    isOpen: false,
  });

  // Modal 확인 버튼 클릭
  const clickModalConfirm = useCallback(async () => {
    setConfirmModal({ isOpen: false });

    if (!confirmModal?.payload) throw Error("confirmModal?.payload is not found.");
    await saveProperty(confirmModal.payload);
  }, [confirmModal, saveProperty]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: any, e?: any) => {
    e.preventDefault();
    console.log("data", data);
    setConfirmModal({ message: "저장하시겠습니까?", isOpen: true, payload: data });
    return false;
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    // console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  // 취소 버튼클릭시 층 form 리셋
  const resetForm = () => {
    setProperty({ type: "HO" });

    clearErrors();
    for (const [name, value] of Object.entries(defaultValues)) {
      setValue(name as keyof Property, value);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <table
          className={errors && Object.keys(errors).length > 0 ? "inner-table" : "inner-table mb20"}
          width="1200"
        >
          <thead>
            <tr>
              <th>
                <div>구분</div>
              </th>
              <th>
                <div>층</div>
              </th>
              <th>
                <div>호</div>
              </th>
              <th>
                <div>호 명칭</div>
              </th>
              <th>
                <div>전용면적(m2)</div>
              </th>
              <th>
                <div>전용면적(평)</div>
              </th>
              <th>
                <div>계약면적(m2)</div>
              </th>
              <th>
                <div>계약면적(평)</div>
              </th>
              <th>
                <div>임대료(원)</div>
              </th>
              <th>
                <div>소유주</div>
              </th>
              <th>
                <div className="minmax100"></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="100px">
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseSelect
                      value={value}
                      name={name}
                      stateOptions={floorHoTypeOptions}
                      setStateValue={(type: string) => {
                        setProperty({ ...property, type });
                      }}
                    />
                  )}
                ></Controller>
              </td>
              <td width={120}>
                <Controller
                  control={control}
                  name="floor"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseSelect
                      value={value}
                      name={name}
                      stateOptions={rangeFloorOptions}
                      setStateValue={onChange}
                    />
                  )}
                ></Controller>
              </td>
              <td width={80}>
                <Controller
                  control={control}
                  name="ho"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseInput
                      type="number"
                      value={value}
                      onChange={(ho: string) => {
                        setValue("ho", Number(ho), {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                      name={name}
                      readonly={!!getValues("hoId")} // 수정이면 readonly
                    />
                  )}
                ></Controller>
              </td>
              <td>
                <Controller
                  control={control}
                  name="hoName"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseInput type="text" name={name} value={value} onChange={onChange} />
                  )}
                ></Controller>
              </td>
              <td>
                <Controller
                  control={control}
                  name="dedicatedArea"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseInput
                      type="number"
                      name={name}
                      value={value}
                      onChange={(dedicatedArea: string) => {
                        setValue("dedicatedArea", Number(dedicatedArea), {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                    />
                  )}
                ></Controller>
              </td>
              <td>
                <Controller
                  control={control}
                  name="dedicatedAreaPyeong"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseInput
                      type="text"
                      name={name}
                      value={calculatePyeong(Number(getValues("dedicatedArea") || 0))}
                      readonly
                    />
                  )}
                ></Controller>
              </td>
              <td>
                <Controller
                  control={control}
                  name="contractArea"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseInput
                      type="number"
                      name={name}
                      value={value}
                      onChange={(contractArea: string) => {
                        setValue("contractArea", Number(contractArea), {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                    />
                  )}
                ></Controller>
              </td>
              <td>
                <Controller
                  control={control}
                  name="contractAreaPyeong"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseInput
                      type="text"
                      name={name}
                      value={calculatePyeong(Number(getValues("contractArea") || 0))}
                      readonly
                    />
                  )}
                ></Controller>
              </td>
              <td>
                <Controller
                  control={control}
                  name="rent"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseInput
                      type="number"
                      name={name}
                      value={value}
                      onChange={(rent: string) => {
                        setValue("rent", Number(rent), {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                    />
                  )}
                ></Controller>
              </td>
              {/* 소유주 */}
              <td width={150}>
                <Controller
                  control={control}
                  name="ownerName"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseInput
                      name={name}
                      value={value}
                      onChange={(rent: string) => {}}
                      readonly
                      onSearchClick={() => {
                        setOwnerModal({ isOpen: true, type: "owner" });
                      }}
                    />
                  )}
                ></Controller>
              </td>
              <td>
                <div className="flex-center">
                  <BaseButton type="submit" title="저장" className="mr2" />
                  <BaseButton title="취소" className="color-white" onClick={() => resetForm()} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {/* {errors && Object.keys(errors).length > 0 && (
        <p className="validation-text mb20">{errors[Object.keys(errors)[0]].message}</p>
      )} */}
        {errors && Object.keys(errors).length > 0 && (
          <p className="validation-text mb20">{Object.entries(errors)[0][1]?.message}</p>
        )}
      </form>
      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
      >
        <p>{confirmModal.message}</p>
      </BaseModal>

      <BaseModal
        isOpen={ownerModal.isOpen}
        className="dialog-modal"
        btnLeftTitle="취소"
        btnRightTitle="선택"
        onClose={() => setOwnerModal({ isOpen: false })}
        onClick={() => {
          setValue("ownerId", String(selectedOwner?.id));
          setValue("ownerName", selectedOwner?.name);
          setOwnerModal({ isOpen: false });
        }}
      >
        <OrganizationListPopup
          control={control}
          setSelectedOwner={setSelectedOwner}
          partnerId={partnerId}
        />
      </BaseModal>
    </>
  );
};

export default SaveHo;
