import { useCallback, useEffect, useState } from "react";
import { useApiOperation } from "src/api/hooks";
import { fetchProductGuideDetailAsync } from "src/api/product/product-guide-api";
import {
  NewProductGuide,
  ProductGuideType,
  ProductGuideTypes,
} from "src/api/product/product-types";
import MarkdownViewer from "src/components/MarkdownViewer";
import { useLoadingBarContext } from "src/pages/hooks";
import FreeInternetSection from "./FreeInternetSection";

type Props = {
  productGuideType: ProductGuideType;
  guideId: string;
};

const ProductGuideTypeDetail = ({ productGuideType, guideId }: Props) => {
  const { setLoadingBar } = useLoadingBarContext();

  const [guideDetail, setGuideDetail] = useState<NewProductGuide>();

  const { executeAsync: fetchProductGuide } = useApiOperation(fetchProductGuideDetailAsync);

  // 이용안내 상세 api
  const fetchProductGuideApi = useCallback(
    async (id: string) => {
      const response = await fetchProductGuide({ guideId: id, isOpened: false });

      setLoadingBar(true);
      if (response.status >= 200 && response.status <= 299) {
        const reulst = response.data.data.content;
        setGuideDetail(reulst);
      }
      setLoadingBar(false);
    },

    [fetchProductGuide, setLoadingBar],
  );

  useEffect(() => {
    fetchProductGuideApi(guideId);
  }, [fetchProductGuideApi, guideId]);

  return (
    <>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>노출여부</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="">
            <span>{guideDetail?.isDisplayed ? "노출" : "미노출"}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>정렬 가중치</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax100">
            <span>{guideDetail?.orderNums}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>항목명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="">
            <span>{guideDetail?.subject}</span>
          </div>
        </div>
      </section>

      {/* +항목추가 / +주차 관련 안내 추가 일시 Editor form 노출 */}
      {(productGuideType === "PRODUCT_GUIDE_ETC2" ||
        productGuideType === "PRODUCT_GUIDE_PARKINGLOT2") && (
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">상세내용</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="">
              {/* {drawViewer()} */}
              {guideDetail?.description && (
                <MarkdownViewer value={guideDetail?.description || ""} />
              )}
            </div>
          </div>
        </section>
      )}

      {/* +네트워크 연결 추가 section */}
      {productGuideType === ProductGuideTypes.GUIDE_WIFI && (
        <FreeInternetSection networkCatalogList={guideDetail?.networkCatalogList || []} />
      )}

      {/* 버튼영역 */}
    </>
  );
};

export default ProductGuideTypeDetail;
