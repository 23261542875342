import { useCallback, useEffect, useMemo, useState } from "react";
import { CommonFacilityModel } from "src/api/building/building-types";
import { getEslProductDetailAsync } from "src/api/esl/esl-api";
import { EslProduct } from "src/api/esl/esl-types";
import { useApiOperation } from "src/api/hooks";
import { MediaFile } from "src/api/public-types";
import { BaseTooltip } from "src/components";
import ImagesDetail from "src/components/ImagesDetail";
import { floorOptions, SelectOption } from "src/pages/building/building-types";
import ViewDataTable from "src/pages/building/components/ViewDataTable";

type Props = {
  commonFacility: CommonFacilityModel | null;
};
interface EslLabel extends EslProduct {
  locationCode: string; // 공간유형 채번
}

/* 
  공용공간 예약 상세 > 좌석 컴포넌트
 */
const DeskSection = ({ commonFacility }: Props) => {
  // ESL 데이터 상세 조회 api
  const { executeAsync: getEslProductDetail } = useApiOperation(getEslProductDetailAsync, {
    doNotErrorHandleModal: true,
  });

  // 조회한 ESL 데이터 목록
  const [eslLabels, setEslLabels] = useState<Array<EslLabel>>([]);

  // 좌석 목록
  const [deskList, setDeskList] = useState<Array<CommonFacilityModel>>([]);

  // esl 라벨 목록 조회
  const fetchAllLabels = useCallback(async (locationCodes: Array<string>) => {
    try {
      if (!locationCodes || locationCodes.length === 0) {
        throw Error("locationCodes.length === 0");
      }

      const eslLabels: Array<EslLabel> = [];
      for (let i = 0; i < locationCodes.length; i++) {
        const locationCode = locationCodes[i];
        const result = await getEslProductDetail({ productId: locationCode });
        if (result?.data?.data && result.status >= 200 && result.status <= 299) {
          if (result?.data?.data?.product) {
            // esl 라벨이 연동되어있을 경우만 조회됨
            eslLabels.push({
              ...result?.data?.data?.product,
              ...{
                locationCode,
              },
            });
          }
        }
      }
      setEslLabels(eslLabels);
    } catch (error) {
      // 에러 발생해도 무시
      console.debug("fetchAllLabels error", error);
    }
  }, []);

  useEffect(() => {
    const deskList: Array<CommonFacilityModel> = commonFacility ? [commonFacility] : [];
    if (deskList && deskList.length > 0) {
      // locaiotn code 로 라벨 목록 조회
      const locationCodes: Array<string> = [];
      for (let i = 0; i < deskList.length; i++) {
        const locationCode = deskList[i].locationCode;
        if (!!locationCode) {
          locationCodes.push(locationCode);
        }
      }
      if (locationCodes.length > 0) {
        fetchAllLabels(locationCodes);
      }
      setDeskList(deskList);
    }
  }, [commonFacility, fetchAllLabels]);

  // 배치도 배열
  const mediaList: Array<MediaFile> = useMemo(() => {
    return commonFacility?.mediaList || [];
  }, [commonFacility]);

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
        width: 80,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} isSingleLine={true} />;
        },
      },
      {
        Header: "좌석 명",
        accessor: "facilityName",
        width: 200,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "위치",
        accessor: "floor",
        width: 90,
        Cell: ({ row }: any) => {
          const isGround: boolean = row?.original?.isGround; // 지상여부
          const floorNum: number = row?.original?.floorNum; // 위치
          const floor = floorOptions.find(
            (option: SelectOption) => option.value === `${isGround ? "" : "-"}${floorNum}`,
          );
          return floor?.label || "";
        },
      },
      {
        Header: "Location code",
        accessor: "locationCode",
        width: 200,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "ESL",
        accessor: "eslAddress",
        width: 140,
        Cell: ({ row }: any) => {
          if (!!row?.original?.locationCode && eslLabels.length > 0) {
            const findLabel = eslLabels.find(
              (eslLabel: EslLabel) => eslLabel.locationCode === row?.original?.locationCode,
            );
            if (findLabel) {
              return <BaseTooltip contents={(findLabel.assignedLabelCodes || []).join(",")} />;
            }
          }
          return "";
        },
      },
    ],
    [eslLabels],
  );

  return (
    <article className="mb30">
      <div className="contents-container__sub-title">
        <div className="minmax140 pb4">
          <h2>좌석</h2>
        </div>
      </div>
      <ViewDataTable columns={columns} data={deskList} />
      {/* 배치도 */}
      {/* <div className="contents-container__grid mt20">
        <div className="contents-container__grid-index">
          <p className="">배치도</p>
        </div>
      </div>
      <div className="mt20">
        <ImagesDetail images={mediaList} />
      </div> */}
    </article>
  );
};

export default DeskSection;
