import { AxiosInstance, AxiosResponse } from "axios";
import {
  AccessCodeModel,
  AccessDeviceFormParams,
  AccessDeviceListParams,
  AccessDeviceListResponseData,
  AccessDeviceModel,
  AccessGroupFormParams,
  AccessGroupListParams,
  AccessGroupListResponseData,
  AccessGroupModel,
  AccessLogModel,
  AccessLogParams,
  DeviceLabelModel,
  ExternalServerParams,
  ExternalServerResponseData,
} from "./ac2-types";
import { ApiResponse } from "../public-types";
import { ApiPath } from "..";
import qs from "qs";

/*
   (CAD11) 출입장치 디바이스 페이지(목록) 조회
*/
export async function getAccessDeviceListAsync(
  axios: AxiosInstance,
  params?: AccessDeviceListParams,
): Promise<AxiosResponse<ApiResponse<AccessDeviceListResponseData>>> {
  const path = ApiPath.ac2.accessDevice.list;
  return await axios.get<ApiResponse<AccessDeviceListResponseData>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/*
     (CAD01) 외부출입시스템 페이지(목록) 조회
*/
export async function getExternalServersAsync(
  axios: AxiosInstance,
  params?: ExternalServerParams,
): Promise<AxiosResponse<ApiResponse<ExternalServerResponseData>>> {
  const path = ApiPath.ac2.externalServer.list;
  return await axios.get<ApiResponse<ExternalServerResponseData>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/*
     (CAD12) 출입장치 디바이스 상세 조회
*/
export async function getAccessDeviceAsync(
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<{ device: AccessDeviceModel }>>> {
  const path = ApiPath.ac2.accessDevice.detail.replace("{id}", params!.id.toString());
  return await axios.get<ApiResponse<{ device: AccessDeviceModel }>>(path);
}
/*
     (CAD13) 출입장치 디바이스 생성
*/
export async function postAccessDeviceAsync(
  axios: AxiosInstance,
  params?: AccessDeviceFormParams,
): Promise<AxiosResponse<ApiResponse<{ device: { id: number } }>>> {
  const path = ApiPath.ac2.accessDevice.add;
  return await axios.post<ApiResponse<{ device: { id: number } }>>(path, {
    ...params,
  });
}

/*
     (CAD14) 출입장치 디바이스 수정
*/
export async function putAccessDeviceAsync(
  axios: AxiosInstance,
  params?: AccessDeviceFormParams,
): Promise<AxiosResponse<ApiResponse<{ device: { id: number } }>>> {
  if (!params?.id) {
    throw Error("params.id is not found");
  }
  const path = ApiPath.ac2.accessDevice.edit.replace("{id}", params.id.toString());
  return await axios.put<ApiResponse<{ device: { id: number } }>>(path, { ...params });
}

/*
     (CAD15) 출입장치 디바이스 삭제
*/
export async function deleteAccessDeviceAsync(
  axios: AxiosInstance,
  params?: {
    id: string;
  },
): Promise<AxiosResponse<ApiResponse<{ device: { id: number } }>>> {
  if (!params?.id) {
    throw Error("params.id is not found");
  }
  const path = ApiPath.ac2.accessDevice.delete.replace("{id}", params.id.toString());
  return await axios.delete(path, { params });
}

/*
   (CAD21) 출입그룹 페이지(목록) 조회
*/
export async function getAccessGroupListAsync(
  axios: AxiosInstance,
  params?: AccessGroupListParams,
): Promise<AxiosResponse<ApiResponse<AccessGroupListResponseData>>> {
  const path = ApiPath.ac2.accessGroup.list;
  return await axios.get<ApiResponse<AccessGroupListResponseData>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/*
    (CAD22) 출입그룹 상세 조회
*/
export async function getAccessGroupAsync(
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<{ accessGroup: AccessGroupModel }>>> {
  const path = ApiPath.ac2.accessGroup.detail.replace("{id}", params!.id.toString());
  return await axios.get<ApiResponse<{ accessGroup: AccessGroupModel }>>(path);
}

/*
    (CAD23) 출입그룹 생성
*/
export async function postAccessGroupAsync(
  axios: AxiosInstance,
  params?: AccessGroupFormParams,
): Promise<AxiosResponse<ApiResponse<{ accessGroup: { id: number } }>>> {
  const path = ApiPath.ac2.accessGroup.add;
  return await axios.post<ApiResponse<{ accessGroup: { id: number } }>>(path, {
    ...params,
  });
}

/*
     (CAD24) 출입그룹 수정
*/
export async function putAccessGroupAsync(
  axios: AxiosInstance,
  params?: AccessGroupFormParams,
): Promise<AxiosResponse<ApiResponse<{ accessGroup: { id: number } }>>> {
  if (!params?.id) {
    throw Error("params.id is not found");
  }
  const path = ApiPath.ac2.accessGroup.edit.replace("{id}", params.id.toString());
  return await axios.put<ApiResponse<{ accessGroup: { id: number } }>>(path, { ...params });
}

/*
     (CAD25) 출입그룹 삭제
*/
export async function deleteAccessGroupAsync(
  axios: AxiosInstance,
  params?: {
    id: string;
  },
): Promise<AxiosResponse<ApiResponse<{ accessGroup: { id: number } }>>> {
  if (!params?.id) {
    throw Error("params.id is not found");
  }
  const path = ApiPath.ac2.accessGroup.delete.replace("{id}", params.id.toString());
  return await axios.delete(path, { params });
}

/*
  (CAD41) 고정 CSN 조회
*/
export async function getFixedCsnAsync(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
  },
): Promise<AxiosResponse<ApiResponse<{ accessCode: AccessCodeModel }>>> {
  const path = ApiPath.ac2.accessCode.fixedCsn.replace("{memberNo}", params!.memberNo.toString());
  return await axios.get<ApiResponse<{ accessCode: AccessCodeModel }>>(path);
}

/*
   (CAD42) 고정 CSN 저장
*/
export async function updateFixedCsnAsync(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
    accessToken: string;
  },
): Promise<AxiosResponse<ApiResponse<{ accessCode: { id: number } }>>> {
  const path = ApiPath.ac2.accessCode.fixedCsn.replace("{memberNo}", params!.memberNo.toString());
  return await axios.put<ApiResponse<{ accessCode: { id: number } }>>(path, params);
}

/*
   (CAD43) 고정 CSN 삭제
*/
export async function deleteFixedCsnAsync(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
  },
): Promise<AxiosResponse<ApiResponse<{ accessCode: { id: number } }>>> {
  const path = ApiPath.ac2.accessCode.fixedCsn.replace("{memberNo}", params!.memberNo.toString());
  return await axios.delete(path, { params });
}
/*
   (CAD44) 얼굴인식 조회
*/
export async function getFixedFaceAsync(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
  },
): Promise<AxiosResponse<ApiResponse<{ accessCode: AccessCodeModel }>>> {
  const path = ApiPath.ac2.accessCode.fixedFace.replace("{memberNo}", params!.memberNo);
  return await axios.get(path);
}

/*
   (CAD46) 얼굴인식 삭제
*/
export async function deleteFixedFaceAsync(
  axios: AxiosInstance,
  params?: {
    memberNo: string;
  },
): Promise<AxiosResponse<ApiResponse<{ accessCode: { id: number } }>>> {
  const path = ApiPath.ac2.accessCode.deleteFixedFace.replace(
    "{memberNo}",
    params!.memberNo.toString(),
  );
  return await axios.delete(path);
}
/*
   (CAD51) 회원에 대한 출입로그 조회
*/
export async function getAccessLogAsync(
  axios: AxiosInstance,
  params?: AccessLogParams,
): Promise<AxiosResponse<ApiResponse<{ content: AccessLogModel[] }>>> {
  if (!params?.memberId) {
    throw Error("params.memberId is not found");
  }
  // console.log('로그조회')
  const path = ApiPath.ac2.access.log.replace("{memberId}", params.memberId.toString());
  return await axios.get<ApiResponse<{ content: AccessLogModel[] }>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/*
   (CAD61) 디바이스 라벨 목록 조회
*/
export async function getDeviceLabelsAsync(
  axios: AxiosInstance,
  params?: {
    deviceId: number;
  },
): Promise<AxiosResponse<ApiResponse<{ labels: DeviceLabelModel[] }>>> {
  if (!params?.deviceId) {
    throw Error("params.deviceId is not found");
  }

  const path = ApiPath.ac2.accessDevice.labels.replace("{deviceId}", params?.deviceId?.toString());
  return await axios.get<ApiResponse<{ labels: DeviceLabelModel[] }>>(path, {
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}
/*
   (CAD62) 디바이스 라벨 저장 처리
*/
export async function putDeviceLabelsAsync(
  axios: AxiosInstance,
  params?: {
    deviceId: number;
    labels: DeviceLabelModel[];
  },
): Promise<AxiosResponse<ApiResponse<{ deviceLabels: DeviceLabelModel[] }>>> {
  if (!params?.deviceId) {
    throw Error("params.deviceId is not found");
  }

  const path = ApiPath.ac2.accessDevice.labels.replace("{deviceId}", params?.deviceId?.toString());
  return await axios.put<ApiResponse<{ deviceLabels: DeviceLabelModel[] }>>(path, {
    labels: params.labels,
    paramsSerializer: (params: DeviceLabelModel[]) =>
      qs.stringify(params, { allowDots: true, encode: true }),
  });
}
