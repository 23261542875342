import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { Modal, Order, PageMeta, Sort, Status } from "src/api/public-types";
import { BaseButton, BaseInput, BaseSelect, BaseTable, ContentsTitle } from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { Partner } from "src/types/partner";
import { ProviderTypeOptions } from "../provider-types";
import ProviderColumns from "./columns/ProviderColumns";

type QueryParams = {
  page?: number;
  size?: number;
  sort?: Sort;
  searchValue?: string;
  supplyType?: string;
  partnerId?: string;
  partnerName?: string;
};

const ProviderList = () => {
  const [providers, setProviders] = useState<Array<ProviderModel>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const navigate = useNavigate();
  const location = useLocation();

  // 선택된 파트너 정보
  const [partner, setPartner] = useState<Partner>();

  const [partnerModal, setPartnerModal] = useState<Modal>({
    isOpen: false,
  });

  const { setLoadingBar } = useLoadingBarContext();

  // 프롤바이더 목록 api
  const { executeAsync: getProviers } = useApiOperation(getProviderList);

  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }

    if (!_queryParams.size) {
      _queryParams.size = 20;
    }

    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "providerId", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, [location]);

  const [params, setParams] = useState<QueryParams>({ ...queryParams });

  const getProviderListApi = useCallback(async (param) => {
    setLoadingBar(true);

    const response = await getProviers(param);
    if (response.status >= 200 && response.status <= 299) {
      setProviders(response.data.data.content);
      setPageMeta(response.data.meta.pageMeta);
    }
    setLoadingBar(false);
  }, []);

  useEffect(() => {
    getProviderListApi(queryParams);
  }, [getProviderListApi, queryParams]);

  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  return (
    <div className="page-product-list">
      {/* 로딩바 */}
      {/* <ContentsTitle title="정산정보 관리" /> */}
      <div className="contents-container__search-wrap">
        <div className="left-area">
          {/* <p>
              전체 <span>{pageMeta?.totalElements || 0}</span>
            </p> */}
          <div className="minmax200 mr8 cursor-pointer">
            <BaseInput
              readonly
              placeholder="파트너"
              value={(partner?.name && partner?.name) || params.partnerName}
              onSearchClick={() => setPartnerModal({ isOpen: true })}
              onClearClick={() => {
                const editedParams = { ...params };
                delete editedParams.partnerId;
                delete editedParams.partnerName;
                setParams(editedParams);
              }}
            />
          </div>
          <div className="minmax150 mr8">
            <BaseSelect
              placeholder="구분"
              stateOptions={ProviderTypeOptions}
              value={params.supplyType}
              setStateValue={(supplyType: Status) => {
                setParams({ ...params, supplyType });
              }}
            />
          </div>
          <div className="minmax220">
            <BaseInput
              value={params?.searchValue || ""}
              className="mr16"
              type="text"
              placeholder="검색어를 입력해주세요."
              onChange={(searchValue: string) => setParams({ ...params, searchValue })}
              onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
              onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
            />
          </div>
        </div>
      </div>
      <BaseTable
        data={providers}
        columns={ProviderColumns}
        pageIndex={Number(params?.page || 0)}
        totalPages={pageMeta?.totalPages || 0}
        goPage={(page: number) => {
          navigateWithQueryParams({ page }, "pagination");
        }}
        orders={params?.sort?.orders}
        disabledSortHeaders={["rentalCost", "image"]}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } }, "search");
          }
        }}
      />{" "}
      {partnerModal.isOpen && (
        <PartnerSelectModal
          onCanceled={() => setPartnerModal({ isOpen: false })}
          onAdded={(partner) => {
            setPartner(partner[0]);
            setPartnerModal({ isOpen: false });
            setParams({
              ...params,
              partnerId: String(partner[0].id),
              partnerName: String(partner[0].name),
            });
          }}
          defaultValues={[]}
        />
      )}
    </div>
  );
};

export default ProviderList;
