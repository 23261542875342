import { AxiosInstance, AxiosResponse } from "axios";
import ApiPath from "src/api/apiPath.json";
import { ApiResponse } from "../public-types";
import { ProductFacilityEditModel, ProductUpdateResponseData } from "./product-types";

/* 
  CPP08. 공간상품 시설/서비스 (등록/수정)
*/
export async function postProductfacilityAsync(
  axios: AxiosInstance,
  params?: {
    productId: number;
    productFacilityEditModel: ProductFacilityEditModel;
  },
): Promise<AxiosResponse<ApiResponse<ProductUpdateResponseData>>> {
  const { productId, productFacilityEditModel } = params || {};
  if (!productId) {
    throw Error("productId is not found.");
  }
  if (!productFacilityEditModel) {
    throw Error("productFacilityEditModel is not found.");
  }

  const path = ApiPath.product.productFacility.replace("{id}", productId.toString());
  return await axios.post(path, {
    product: productFacilityEditModel,
  });
}
