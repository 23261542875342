import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import {
  getIotRegisteredDeviceDescriptionAsync,
  getIotRegisteredDeviceLabelsAsync,
  getIotRegisteredDeviceListAsync,
} from "src/api/iot/iot-api";
import { PaIotLabel, PaRegisteredDevice } from "src/api/iot/iot-types";
import { BaseButton, BaseTextarea, ContentsIdSection } from "src/components";
import { PagePath } from "src/pages/product/details";
import { iotPlatformToString } from "../../iot-types";
import { useLoadingBarContext } from "src/pages/hooks";
import { LabelsModal } from "../../components/LabelsModal";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";

export const BasicInfoDetail = () => {
  const [registeredDevice, setRegisteredDevice] = useState<PaRegisteredDevice>();
  const [description, setDescription] = useState<string>("");
  const [labels, setLabels] = useState<PaIotLabel[]>([]);
  const [isLabelsModalOpen, setIsLabelsModalOpen] = useState<boolean>(false);
  const { executeAsync: getIotRegisteredDeviceList } = useApiOperation(
    getIotRegisteredDeviceListAsync,
  );
  const { executeAsync: getIotRegisteredDeviceDescription } = useApiOperation(
    getIotRegisteredDeviceDescriptionAsync,
  );
  const { executeAsync: getIotRegisteredDeviceLabels } = useApiOperation(
    getIotRegisteredDeviceLabelsAsync,
  );
  const navigate = useNavigate();
  const { id } = useParams();
  const { setLoadingBar } = useLoadingBarContext();

  useEffect(() => {
    const fetchApi = async (id: string) => {
      setLoadingBar(true);
      const { data, status } = await getIotRegisteredDeviceList({ id });
      if (status >= 200 && status < 300) {
        if (data.data.content.length > 0) {
          setRegisteredDevice(data.data.content[0]);
        }
      }
      const { data: labelData, status: labelStatus } = await getIotRegisteredDeviceLabels({
        registeredDeviceId: id,
      });
      if (labelStatus >= 200 && labelStatus < 300) {
        if (labelData.data?.labels?.length > 0) {
          setLabels(labelData.data?.labels);
        }
      }
      setLoadingBar(false);
    };
    if (id) {
      fetchApi(id);
    }
  }, [id, getIotRegisteredDeviceList, getIotRegisteredDeviceDescription]);

  const fetchDescription = async () => {
    const { data: descriptionData, status: descriptionStatus } =
      await getIotRegisteredDeviceDescription({ registeredDeviceId: id });
    if (descriptionStatus >= 200 && descriptionStatus < 300) {
      setDescription(descriptionData.data.result);
    }
  };

  const handleJsonParsing = (description?: string) => {
    if (description) {
      try {
        const data = JSON.stringify(JSON.parse(description), null, 4);
        return data;
      } catch (error) {
        return description;
      }
    } else return "";
  };

  return (
    <>
      <div className="contents-container__wrap">
        <div className="contents-container__wrap-contents">
          <ContentsIdSection title="" id={id} />
          <section className="contents-container__grid">
            <div className="contents-container__grid-index minmax200">
              <p className="">IoT 구분</p>
            </div>
            <div className="contents-container__grid-contents">
              <p>
                {iotPlatformToString(
                  registeredDevice?.platformDevice?.platformAccount.platform || "-",
                )}
              </p>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">기기명</p>
            </div>
            <div className="contents-container__grid-contents">
              <p>{registeredDevice?.internalDisplayName}</p>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">계정</p>
            </div>
            <div className="contents-container__grid-contents">
              <p>{registeredDevice?.platformDevice?.platformAccount.email}</p>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">기기 정보</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="pt_grid">
                <div className="flex-center">
                  <div className="minmax80">
                    <span className="font14 text-primary3">Device ID :</span>
                  </div>
                  <p>{registeredDevice?.platformDevice?.id}</p>
                </div>
                <div className="flex-center pt10">
                  <div className="minmax80">
                    <span className="font14 text-primary3">Name :</span>
                  </div>
                  <p>{registeredDevice?.platformDevice?.name}</p>
                </div>
                <div className="flex-center pt10">
                  <div className="minmax80">
                    <span className="font14 text-primary3">Type :</span>
                  </div>
                  <p>{registeredDevice?.platformDevice?.type}</p>
                </div>
                {registeredDevice?.platformDevice?.platformAccount.platform === "ST" && (
                  <div className="flex-center pt10">
                    <div className="minmax80">
                      <span className="font14 text-primary3">ST-Label :</span>
                    </div>
                    <p>{registeredDevice?.platformDevice?.label}</p>
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className="contents-container__grid contents-container__1200">
            <div className="contents-container__grid-index">
              <p className="">기기 설명</p>
            </div>
            <div className="contents-container__grid-contents">
              <BaseButton title="조회" className="color-white" onClick={() => fetchDescription()} />
              <BaseTextarea
                height={220}
                className="mt10 pre-formatted"
                value={handleJsonParsing(description)}
              />
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">Labels</p>
            </div>
            <div className="contents-container__grid-contents">
              {labels?.map((labels, i) => (
                <p className="" key={labels.name + i}>
                  {labels.name} : {labels.value}
                </p>
              ))}
              <BaseButton
                title="수정"
                className={`color-white ${labels.length > 0 ? "mt10" : ""}`}
                onClick={() => setIsLabelsModalOpen(true)}
              />
              {id && isLabelsModalOpen && (
                <LabelsModal
                  types="DEVICE"
                  registeredDeviceId={id}
                  setIsLabelModalOpen={setIsLabelsModalOpen}
                  setLabels={setLabels}
                />
              )}
            </div>
          </section>
          <AdminMemoV3
            serviceId={Number(id)}
            serviceType={ServiceTypes.SERVICE_IOT_DEVICE}
            partnerId={"0"}
          ></AdminMemoV3>
        </div>
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              type="button"
              className="color-white size-large"
              onClick={() => {
                navigate("/mng/central/iot");
              }}
            />
          </div>
          <div className="right-area">
            <BaseButton
              type="button"
              title="수정"
              className="size-large"
              onClick={() => {
                navigate(PagePath.iot.form + `?id=${id}&tab=basicInfo`);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicInfoDetail;
