import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../public-types";
import {
  NoticeListParams,
  NoticeListResponseData,
  NoticeAddModel,
  NoticeCreateResponseData,
  NoticeEditModel,
  NoticeUpdateResponseData,
  NoticeDeleteResponseData,
  NoticeMediaModel,
  NoticeDetailResponseData,
  NoticeModel
} from "./notice-types";
import { ApiPath } from "src/api";
import qs from "qs";

/*
  공지사항 조회
*/
export async function getNoticesAsync(
  axios: AxiosInstance,
  params?: NoticeListParams,
): Promise<AxiosResponse<ApiResponse<NoticeListResponseData>>> {
  return await axios.get<ApiResponse<NoticeListResponseData>>(ApiPath.notice.list, { 
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true })
  })
}

/*
  공지사항 정보
*/
export async function getNoticeAsync(
  axios: AxiosInstance,
  params?: {
    noticeId: number
  }
): Promise<AxiosResponse<ApiResponse<NoticeDetailResponseData>>> {
  const path = ApiPath.notice.detail.replace("{id}", params!.noticeId.toString())
  return await axios.get<ApiResponse<NoticeDetailResponseData>>(path);
}

/*
  CET03. 공지사항 등록
*/
export async function postNoticeAsync(
  axios: AxiosInstance,
  params?: {
    notice: NoticeAddModel
    medias?: NoticeMediaModel[]
  }
): Promise<AxiosResponse<ApiResponse<{ notice: Array<NoticeAddModel>; medias: any }>>> {
  return await axios.post(ApiPath.notice.add, params)
}

/*
  CET04. 공지사항 수정
*/
export async function putNoticeAsync(
  axios: AxiosInstance,
  params?: {
    id:string;
    notice: NoticeEditModel
    medias?: NoticeMediaModel[]
  }
): Promise<AxiosResponse<ApiResponse<{ notice: NoticeModel; medias: any }>>> {
  const payload = {
    notice:params?.notice
  }
  return await axios.put(ApiPath.notice.edit.replace("{id}", params!.id.toString()), payload);
}

/*
  공지사항 삭제
*/
export async function deleteNoticeAsync(
  axios: AxiosInstance,
  params?: {
    id: number
  }
): Promise<AxiosResponse<ApiResponse<NoticeDeleteResponseData>>> {
  if (!params?.id) {
    throw Error("noticeId is not found.");
  }

  const path = ApiPath.notice.delete.replace("{id}", params?.id.toString())
  return await axios.delete(path)
}