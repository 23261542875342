import qs from "qs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Cell } from "react-table";
import { useApiOperation } from "src/api/hooks";
import { getPartnerList } from "src/api/partner";
import { Order } from "src/api/public-types";
import { ContentsTitle, BaseTable, BaseTooltip, LoadingBar, BaseButton } from "src/components";
import { Partner } from "src/types/partner";
import {
  getPartnerAddPage,
  getPartnerDetailPage,
  getPartnerListPage,
  getPartnerPageBreadCrumb,
} from "../utils";
import PartnerSearchForm from "./SearchForm";

type PartnerStatus = "STANDBY" | "ACTIVE" | "SHUTDOWN";
type SearchTarget = "id" | "containsCode" | "containsName";

interface IPartner {
  id?: string;
  code: string;
  name: string;
  useStartDate: string;
  useEndDate: string;
  contractUrl?: string;
  status?: PartnerStatus;
  statusDate?: string;
  createdDate?: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
}

interface IPartnerListSearchForm {
  status: PartnerStatus;
  searchTarget: SearchTarget;
  searchWord: string;
}

interface ISearchParams extends IPartnerListSearchForm {
  orders?: Order[];
  page?: number;
  size?: number;
}

const STATUS_MAP = {
  STANDBY: {
    label: "대기",
    color: "gray",
  },
  ACTIVE: {
    label: "활성",
    color: "green",
  },
  SHUTDOWN: {
    label: "종료",
    color: "red",
  },
};

const TABLE_COLUMNS = [
  {
    Header: "id",
    accessor: "id",
    width: 100,
    Cell: (props: Cell<Partner>) => (
      <Link to={getPartnerDetailPage(props.value)} className="text-hilight">
        {props.value}
      </Link>
    ),
  },
  {
    Header: "파트너 코드",
    accessor: "code",
    width: 280,
    Cell: (props: Cell<Partner>) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "파트너 명",
    accessor: "name",
    width: 200,
    Cell: (props: Cell<Partner>) => {
      const partnerId = props.row.cells[0].value;

      return (
        <div className="w-100 text-left ellipsis2">
          <p>
            <Link to={getPartnerDetailPage(partnerId)} className="text-hilight">
              {props.value}
            </Link>
          </p>
        </div>
      );
    },
  },
  {
    Header: "이용 시작일",
    accessor: "useStartDate",
    width: 100,
    Cell: (props: Cell<Partner>) => <BaseTooltip contents={props.value} type="date" />,
  },
  {
    Header: "이용 종료일",
    accessor: "useEndDate",
    width: 100,
    Cell: (props: Cell<Partner>) => <BaseTooltip contents={props.value} type="date" />,
  },
  {
    Header: "생성일",
    accessor: "createdDate",
    width: 100,
    Cell: (props: Cell<Partner>) => <BaseTooltip contents={props.value} type="date" />,
  },
  {
    Header: "최종 수정일",
    accessor: "lastModifiedDate",
    width: 100,
    Cell: (props: Cell<Partner>) => <BaseTooltip contents={props.value} type="date" />,
  },
  {
    Header: "상태 적용일",
    accessor: "statusDate",
    width: 100,
    Cell: (props: Cell<Partner>) => <BaseTooltip contents={props.value} type="date" />,
  },
  {
    Header: "상태",
    accessor: "status",
    width: 100,
    Cell: (props: Cell<Partner>) => {
      const status = STATUS_MAP[props.value as keyof typeof STATUS_MAP];

      return <div className={`color-chip ${status.color}`}>{status.label}</div>;
    },
  },
];

function PartnerList() {
  const [partnerList, setPartnerList] = useState<IPartner[]>([]);
  const [totalElements, setTotalElements] = useState(0);

  const { handleSubmit, control, setValue, getValues } = useForm<IPartnerListSearchForm>();

  const navigate = useNavigate();

  const [orders, setOrders] = useState<Order[]>([]);

  const [pageMeta, setPageMeta] = useState({
    currentPage: 0,
    totalPages: 0,
  });

  const searchWithParams = (params: ISearchParams) =>
    navigate(`${getPartnerListPage()}?${qs.stringify(params, { encode: false })}`);

  const { executeAsync: getPartnerListAsync, loading: loadingGetPartnerList } =
    useApiOperation(getPartnerList);

  const onSearchFormSubmit = (data: IPartnerListSearchForm) =>
    searchWithParams({ ...data, page: 0, size: 20, orders: orders });

  const goPage = (page: number) =>
    searchWithParams({ ...getValues(), page, size: 20, orders: orders });

  const handleOrderChange = (orders: Order[]) =>
    searchWithParams({ ...getValues(), page: pageMeta.currentPage, size: 20, orders });

  const handleAddbuttonClick = () => navigate(getPartnerAddPage());

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const params = qs.parse(searchParams.toString(), { allowDots: true });

    const page = params.page ? Number(params.page) : 0;
    const size = params.size ? Number(params.size) : 20;
    const orders = params.orders
      ? (params.orders as unknown as Order[])
      : [
          {
            property: "id",
            direction: "DESC",
          },
        ];
    const status = params.status ? (params.status as PartnerStatus) : undefined;
    const searchTarget = params.searchTarget ? (params.searchTarget as SearchTarget) : undefined;
    const searchWord = params.searchWord ? (params.searchWord as string) : undefined;

    setValue("status", status!);
    setValue("searchTarget", searchTarget!);
    setValue("searchWord", searchWord!);
    setOrders(orders);

    const searchOnlyWord = { page, size, orders, status, search001: searchWord };

    (async () => {
      const result = await getPartnerListAsync(
        searchTarget ? { page, size, orders, status, [searchTarget]: searchWord } : searchOnlyWord,
      );

      if (result.status === 200) {
        setPartnerList(result.data.data.content);
        setTotalElements(result.data.meta.pageMeta?.totalElements || 0);
        setPageMeta({
          currentPage: page,
          totalPages: result.data.meta.pageMeta?.totalPages || 0,
        });
      } else {
      }
    })();
  }, []);

  if (loadingGetPartnerList) return <LoadingBar visible={true} />;

  return (
    <div>
      {/* <ContentsTitle
        title="파트너 목록"
        breadCrumbs={getPartnerPageBreadCrumb(["list"])}
        clickBreadCrumb={(crumb: { value: string; label: string }) => navigate(crumb.value)}
      /> */}
      <div className="contents-container__search-wrap">
        <div className="left-area">
          {/* <p>
              전체 <span>{totalElements}</span>
            </p> */}
          <PartnerSearchForm control={control} onSubmit={handleSubmit(onSearchFormSubmit)} />
        </div>
        <div className="right-area">
          <BaseButton title={"파트너 등록"} onClick={handleAddbuttonClick} className="ml8" />
        </div>
      </div>
      <BaseTable
        data={partnerList}
        columns={TABLE_COLUMNS}
        pageIndex={pageMeta.currentPage}
        totalPages={pageMeta.totalPages}
        goPage={goPage}
        orders={orders}
        setOrders={handleOrderChange}
      />
    </div>
  );
}
export default PartnerList;
