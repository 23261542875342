// 회차별이용

import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { numberToStringWithComma, YmdFormat } from "src/utils";

export const columns: any = [
  {
    Header: "회차",
    accessor: "billOrder",
    width: 80,

    //
    Cell: ({ value, row }: any) => {
      // console.log("row", row.original);
      if (row.original.isOverdueBill === true) {
        return <div className="ic_ho"></div>;
      } else {
        return <p>{value}</p>;
      }
    },
  },
  {
    Header: "항목명",
    accessor: "billEndTime",
    // sticky: "left",
    width: 140,
    Cell: ({ value, row }: any) => {
      // console.log('row', row.original);
      if (row.original.isOverdueBill === true) {
        return <p>{"연체료"}</p>;
      } else {
        return <p>{row.original.billOrder + "회차 이용료"}</p>;
      }
    },
  },
  {
    Header: "청구일자",
    accessor: "billIssueDate",
    // sticky: "left",
    width: 140,
    Cell: ({ value, row }: any) => {
      // console.log('row', row.original);

      return <p>{moment(value).format(YmdFormat.YYYY_MM_DD)}</p>;
    },
  },
  {
    Header: "공급가액(원)",
    accessor: "baseAmount",
    width: 140,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 d-flex justify-contents-end">{numberToStringWithComma(value)}</div>
      );
    },
  },
  {
    Header: "할인/할증 금액(원)",
    accessor: "adjustAmount",
    width: 180,
    Cell: ({ row, data, setData }: any) => {
      const [discount, setDiscount] = useState<number>(0);
      useEffect(() => {
        setDiscount(Number(row.original.adjustAmount));
      }, [row.original.adjustAmount]);

      const checkDiscountNumber = useCallback(
        (e: any) => {
          let value = e.target.value;
          // console.log("value", value);
          const before = row.original.baseAmount;
          if (Number(value) >= 0) {
            console.log("입력값이 0보다 크거나 같아");

            row.original.totalAmount = Number(before) + Number(value);

            const nData: any = JSON.parse(JSON.stringify(data));
            //기본 공급가 * 60
            let checker = before * 60;
            //공급가 기준으로 처리 0원 보다 큰 경우
            if (Number(before) === 0) {
              checker = 200000000;
            }

            for (let i = 0; i < nData.length; i++) {
              if (nData[i].billId === row.original.billId) {
                nData[i].adjustAmount = Number(value);
                nData[i].totalAmount = row.original.totalAmount;
                //총 금액 (공급가 + 할인/할증 금액) > (공급가 *60)
                if (row.original.totalAmount > checker) {
                  //할인/할증 금액 = (공급가 *60) - 공급가
                  nData[i].adjustAmount = checker - before;
                  value = String(checker - before);
                  //총 금액 = 공급가 * 60
                  nData[i].totalAmount = checker;
                } else {
                  nData[i].adjustAmount = Number(value);
                  nData[i].totalAmount = row.original.totalAmount;
                }
                console.log("공급가", before);
                console.log("최대 제한 금액", checker);
                console.log("할인/할증 금액", value);
                console.log("총금액", row.original.totalAmount);
              }
            }
            setData(nData);
            setDiscount(value);
          } else {
            const checker = Number(value * -1);

            if (Number(before) > checker || Number(before) === checker) {
              console.log("마이너스다", before, value);
              row.original.totalAmount = Number(before) + Number(value);

              const nData: any = JSON.parse(JSON.stringify(data));
              for (let i = 0; i < nData.length; i++) {
                if (nData[i].billId === row.original.billId) {
                  nData[i].adjustAmount = Number(value);
                  nData[i].totalAmount = row.original.totalAmount;
                }
              }
              setData(nData);
              setDiscount(value);
            } else {
              row.original.totalAmount = 0;

              const nData: any = JSON.parse(JSON.stringify(data));
              for (let i = 0; i < nData.length; i++) {
                if (nData[i].billId === row.original.billId) {
                  nData[i].adjustAmount = Number(-before);
                  nData[i].totalAmount = row.original.totalAmount;
                }
              }
              console.log(nData);
              setData(nData);
              setDiscount(-before);
            }
          }
        },
        [data, row.original, setData],
      );
      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.billIssueDate).format(YmdFormat.FULL);
      // const billSendPost = moment(billDate).subtract(10, "days").format(YmdFormat.FULL);
      let flag = true;
      if (row.original.isOverdueBill === false) {
        flag = moment(billDate).isBefore(moment(today));
      }
      // console.log("flag", flag);
      return (
        <>
          <div className={"base-input"}>
            <input
              type="number"
              name="adjustAmount"
              value={discount}
              className={"text-right"}
              readOnly={row.original.billPayStatus === "PAYMENT_SUCCESS" || flag}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                checkDiscountNumber(e);
              }}
            />
          </div>
        </>
      );
    },
  },

  {
    Header: "청구금액(원)",
    accessor: "totalAmount",
    width: 140,
    Cell: ({ value, row }: any) => {
      return (
        <div className="w-100 d-flex justify-contents-end">{numberToStringWithComma(value)}</div>
      );
    },
  },
];
