/* eslint-disable array-callback-return */
import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getContractApplicationInfo,
  getContractManageByContractApplyNumber,
  getContractOperationInfo,
} from "src/api/contract/contract-api";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import { getProductDetailAsync } from "src/api/product/product-api";
import { ProductModel } from "src/api/product/product-types";
import { contract_bill_user_errors, errorStatus } from "src/api/public-types";
import { addUsers } from "src/api/user/user-api";
import { UserInsertModel } from "src/api/user/user-types";
import { BaseButton, BaseMobileNumberInput, BaseModal, ContentsTitle } from "src/components";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import { Partner } from "src/types/partner";
import * as xlsx from "xlsx";

const UserForm = () => {
  const navigate = useNavigate();
  const { setLoadingBar } = useLoadingBarContext();
  const location = useLocation();
  const pathParams = useParams();

  const params = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  interface UserMakeModel extends UserInsertModel {
    status: string;
    errorMessage?: string;
    errorCode?: string;
  }

  const breadCrumbs = [
    { value: "user", label: "이용자" },
    { value: "", label: "이용자 초대" },
  ];

  // console.log(params);
  const fileRef = useRef<HTMLInputElement>(null);
  // const [contract, setContract] = useState<any>({});
  const [product, setProduct] = useState<ProductModel>();
  const [operation, setOperation] = useState<any>({});

  const [users, setUsers] = useState<UserMakeModel[]>([
    { inviteMobileNumber: "", status: "READY" },
  ]);

  const [limitOver, setLimitOver] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isError2, setIsError2] = useState(false);
  const [message, setMessage] = useState("");
  const [contract, setContract] = useState<any>();
  const [partner, setPartner] = useState<Partner>();

  const { executeAsync: add } = useApiOperation(addUsers, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getApplication } = useApiOperation(getContractApplicationInfo, {
    doNotErrorHandleModal: true,
  });
  // const { executeAsync: getContract } = useApiOperation(getContractDetailBasicInfo);
  const { executeAsync: getOperation } = useApiOperation(getContractOperationInfo, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync, {
    doNotErrorHandleModal: true,
  });

  const { executeAsync: checkContract } = useApiOperation(getContractManageByContractApplyNumber, {
    doNotErrorHandleModal: true,
  });

  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  const checkContractBasic = useCallback(
    async (contractApplyNumber: string) => {
      setLoadingBar(true);
      const res = await checkContract({ contractApplyNumber });
      console.log("move", res.data.data);
      if (res.status >= 200 && res.status <= 299) {
        setContract(res.data.data);
      } else {
        console.log("error", res);
      }
      setLoadingBar(false);
    },
    [checkContract, setLoadingBar],
  );

  //TODO 신청 정보 상세 조회
  //TODO 운영 정보 상세 조회
  //  조합하여 초대공간 정보를 보여준다.

  const insertUsers = useCallback(
    async (contractMemberList: UserMakeModel[], operation: any) => {
      const contractId = Number(contract?.contractManage?.contractManageId);
      //전화번호를 보낼째 010을 +8210으로 변경하여 전송

      setIsConfirm(false);
      setLoadingBar(true);

      // console.log("operation", operation);
      let checkTotalUserCount = Number(operation?.memberNums) + Number(contractMemberList.length);
      // console.log("checkTotalUserCount", operation?.memberNums, contractMemberList.length);
      if (Number(checkTotalUserCount) > Number(operation?.memberMaxNums)) {
        setLimitOver(true);
        setIsConfirm(false);
        setLoadingBar(false);
        return;
      }

      for (let i = 0; i < contractMemberList.length; i++) {
        const response: any = await add({
          contractId,
          contractMemberList: [contractMemberList[i]],
          partnerId: partner?.id,
        });

        if (response.status >= 200 && response.status <= 299) {
          // console.log("res", response);
          contractMemberList[i] = Object.assign(contractMemberList[i], {
            status: "SUCCESS",
            errorMessage: "",
          });
        } else {
          // console.log(response?.data?.meta.errorCode);
          let mes = "";
          if (
            response.data.meta.errorCode &&
            response.data.meta.errorCode !== "" &&
            response.data.meta.errorCode !== "eCT601"
          ) {
            mes = errorMessageHandler(
              response.status,
              response.data.meta.errorCode,

              contract_bill_user_errors,
            );
          }

          if (response.data.meta.errorCode === "eCT601") {
            contractMemberList[i] = Object.assign(contractMemberList[i], {
              status: "FAIL",
              errorMessage: errorMessageHandler(
                response.status,
                response.data.meta.errorCode,

                contract_bill_user_errors,
              ),
              errorCode: response.data.meta.errorCode,
            });
            setUsers(contractMemberList);
            break;
          }

          contractMemberList[i] = Object.assign(contractMemberList[i], {
            status: "FAIL",
            errorMessage: mes,
            errorCode: response.data.meta.errorCode,
          });
        }
        setUsers(contractMemberList);
      }
      setLoadingBar(false);
      setIsSuccess(true);
    },
    [add, contract?.contractManage?.contractManageId, setLoadingBar],
  );

  const moveList = useCallback(() => {
    setIsSuccess(false);

    const path = PagePath.user.list + "?" + qs.stringify(params, { allowDots: true, encode: true });
    navigate(path);
  }, [navigate, params]);

  const addUserRow = useCallback(() => {
    const newArr: any = [];
    const user: any = {
      inviteMobileNumber: "",
      status: "READY",
    };
    newArr.push(user);
    const con = [...users, ...newArr];

    setUsers(con);
  }, [users]);

  const deleteUsers = useCallback(
    (index: number) => {
      const newArr: UserMakeModel[] = users.filter(
        (d: UserInsertModel, idx: number) => idx !== index,
      );

      setUsers(newArr);
    },
    [users],
  );

  const changeUserMobileNumber = useCallback(
    (value: string, index: number) => {
      users[index].inviteMobileNumber = value;
      setUsers([...users]);
    },
    [users],
  );

  const onSaveUsers = useCallback(() => {
    if (users.length === 0) {
      console.log("validatetion non pass4 ");
      return;
    }

    let checker = false;
    users.forEach((user: UserInsertModel) => {
      if (user.inviteMobileNumber === "") {
        checker = true;
      }
    });

    if (checker) {
      console.log("validatetion non pass5 ");

      return;
    }
    console.log("validatetion pass");
    setIsConfirm(true);
  }, [users]);
  // const sampleImg =
  //   "https://images.unsplash.com/photo-1486175060817-5663aacc6655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80";

  const getContractInfos = useCallback(async () => {
    setLoadingBar(true);

    checkContractBasic(String(params.contractApplyNumber));

    const res: any = await getApplication({ id: Number(pathParams.contractManageId) });
    console.log("asdsad", res.data.data);
    if (res.status >= 200 && res.status <= 299) {
      // console.log("app", res.data);
      const response: any = await getProductDetail({
        productId: Number(res.data.data.spaceProductId),
      });
      // console.log("product", response.data.data.content);
      if (response.status >= 200 && response.status <= 299) {
        await setProduct(response.data.data.content);
      } else {
        setMessage(
          errorMessageHandler(
            response.status,
            response.data.meta.errorCode,

            contract_bill_user_errors,
          ),
        );
        setIsError2(true);
      }
    } else {
      setMessage(
        errorMessageHandler(
          res.status,
          res.data.meta.errorCode,

          contract_bill_user_errors,
        ),
      );
      setIsError2(true);
    }

    const resOper: any = await getOperation({ id: Number(pathParams.contractManageId) });
    // console.log("oper", res.data.data.content.productManage);
    if (resOper) {
      await setOperation(resOper.data.data.content.productManage);
    } else {
      setMessage(
        errorMessageHandler(
          resOper.status,
          resOper.data.meta.errorCode,

          contract_bill_user_errors,
        ),
      );
      setIsError2(true);
    }

    setLoadingBar(false);
  }, [
    checkContractBasic,
    getApplication,
    getOperation,
    getProductDetail,
    params.contractApplyNumber,
    pathParams.contractManageId,
    setLoadingBar,
  ]);

  useEffect(() => {
    if (pathParams?.contractManageId!) {
      getContractInfos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (product?.partnerId === undefined) return;

      const result = await getPartnerAsync({
        id: product?.partnerId,
      });

      if (result.status >= 200 && result.status <= 299) {
        setPartner(result.data.data.partner);
      }
    })();
  }, [product]);

  const readUploadFile = (e: any) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        let arrayValues: any = [];

        json.map((obj: any, index: number) => {
          Object.entries(obj).map(([key, value]) => {
            arrayValues.push({ inviteMobileNumber: value, status: "READY" });
          });
        });

        setUsers(arrayValues);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  useTitleOperation(String(params.contractApplyNumber));

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "user") {
        navigate(`${PagePath.user.list}?contractApplyNumber=${params.contractApplyNumber}`);
      }
    },
    [navigate, params.contractApplyNumber],
  );

  return (
    <>
      <div>
        <ContentsTitle
          title="이용자 초대"
          breadCrumbs={breadCrumbs}
          clickBreadCrumb={clickBreadCrumb}
        />

        <div className="contents-container__scroll">
          <div className="contents-container__wrap page-user-form">
            <div className="contents-container__wrap-contents">
              <article className="pb30">
                <div className="contents-container__sub-title">
                  <h2>초대할 공간</h2>
                </div>
                {/* <div className="invitation-card"> */}
                {/* <img src={sampleImg} alt="이용자 초대 공간 이미지" /> */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>공간상품명</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>{product?.productName}</p>
                  </div>
                </section>

                {product?.buildingList && product.buildingList.length === 1 && (
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>주소</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>{product?.buildingList[0]?.addressList?.[0].address}</p>
                    </div>
                  </section>
                )}

                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>이용중 인원</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    {/* 서울 강남구 압구정로 8길 루피움빌딩 7층 */}
                    {/* <br /> */}
                    <p>
                      {operation?.memberNums !== undefined ? operation?.memberNums : "-"}명
                      이용중(총{" "}
                      {operation?.memberMaxNums !== undefined ? operation?.memberMaxNums : "-"}명
                      가능)
                    </p>
                  </div>
                </section>
              </article>

              <article className="contents-container__divide-top">
                <div className="contents-container__sub-title">
                  <h2>파트너</h2>
                </div>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>파트너</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>{`${partner?.name} (${partner?.id})`}</p>
                  </div>
                </section>
              </article>

              <article className="contents-container__divide-top pb30">
                <div className="contents-container__sub-title">
                  <h2>초대할 이용자</h2>
                </div>
                {/* 휴대폰 번호 */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>휴대폰 번호</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    {users.length > 0 &&
                      users.map((user: any, index: number) => {
                        return (
                          <div key={index}>
                            <div className="flex-center mb10">
                              <div className="minmax200 mr10">
                                <BaseMobileNumberInput
                                  maxLength={20}
                                  onChange={(value: string) => changeUserMobileNumber(value, index)}
                                  value={user.inviteMobileNumber}
                                />
                              </div>
                              {index === 0 && (
                                <div>
                                  <button className="base-add-btn mr10" onClick={addUserRow} />
                                  <button
                                    className="base-file-btn"
                                    onClick={() => fileRef.current?.click()}
                                  ></button>
                                  <input
                                    type="file"
                                    name="upload"
                                    id="upload"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    ref={fileRef}
                                    onChange={readUploadFile}
                                    style={{ display: "none" }}
                                  />
                                </div>
                              )}
                              {index !== 0 && (
                                <button
                                  title="삭제"
                                  onClick={() => deleteUsers(index)}
                                  className="base-erase-btn size-large"
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </section>
              </article>
            </div>
            <div className="contents-container__btn-wrap">
              <div className="left-area d-flex">
                <BaseButton
                  title="목록으로"
                  onClick={() => moveList()}
                  className="color-white size-large"
                />
              </div>
              <div className="right-area">
                <BaseButton title="저장" onClick={onSaveUsers} className="size-large" />
              </div>
            </div>
          </div>
          <BaseModal
            isOpen={isConfirm}
            btnLeftTitle={"취소"}
            btnRightTitle={"확인"}
            onClose={() => setIsConfirm(false)}
            onClick={() => insertUsers(users, operation)}
          >
            <div>
              초대하시겠습니까?
              <p>
                초대 받은 이용자들에게 알림톡이 발송되며, 앱 설치 및 회원가입을 하면 공간에
                이용권한이 부여됩니다..
              </p>
            </div>
          </BaseModal>
          <BaseModal
            isOpen={isUpdate}
            btnLeftTitle={"취소"}
            btnRightTitle={"확인"}
            onClose={() => setIsUpdate(false)}
            onClick={() => {}}
          >
            <div>
              수정하시겠습니까?
              <p>해당 이용자들에게 수정된 내용으로 다시 알림톡이 발송됩니다.</p>
            </div>
          </BaseModal>
          <BaseModal
            className="dialog-modal small-size"
            isOpen={isSuccess}
            btnRightTitle={"확인"}
            onClick={() => moveList()}
          >
            <div className="contents-scroll">
              <div className="modal-title">
                <div className="left-area">
                  <span>초대완료</span>
                </div>
                <div className="right-area">
                  <div className="minmax260 text-right">
                    성공 : {users.filter((user: UserMakeModel) => user.status === "SUCCESS").length}
                    건 / 실패 :{" "}
                    {users.filter(
                      (user: UserMakeModel) =>
                        user.errorCode === "eCT601" && user.status === "FAIL",
                    ).length > 0
                      ? users.length
                      : users.filter((user: UserMakeModel) => user.status === "FAIL").length}{" "}
                    건
                  </div>
                </div>
              </div>

              {users.filter((user: UserMakeModel) => user.status === "FAIL").length > 0 && (
                <>
                  <pre>실패 상세</pre>
                  <div>
                    {users.map((user: UserMakeModel) => {
                      if (user.errorCode === "eCT601" && user.status === "FAIL") {
                        return <p className="mb8">{user.errorMessage}</p>;
                      }
                      if (user.errorCode !== "eCT601" && user.status === "FAIL") {
                        return (
                          <p>
                            - {user.inviteMobileNumber} : {user.errorMessage}
                          </p>
                        );
                      }
                    })}
                  </div>
                </>
              )}
            </div>
          </BaseModal>
          <BaseModal isOpen={isError} btnRightTitle={"확인"} onClick={() => setIsError(false)}>
            <div>
              일시적인 오류가 발생하였습니다.
              <p>나중에 다시 시도해 주세요</p>
            </div>
          </BaseModal>
          <BaseModal isOpen={limitOver} btnRightTitle={"확인"} onClick={() => setLimitOver(false)}>
            <div>
              초대에 실패하였습니다.
              <p>
                최대 인원을 초과하여 이용자 초대에 실패하였습니다. 기존의 이용자를 삭제 후 초대해
                주세요.
              </p>
            </div>
          </BaseModal>

          <BaseModal isOpen={isError2} btnRightTitle={"확인"} onClick={() => setIsError2(false)}>
            <p>{message}</p>
          </BaseModal>
        </div>
      </div>
    </>
  );
};
export default UserForm;
