import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { getBuildingsAsync } from "src/api/building/building-api";
import { useApiOperation } from "src/api/hooks";
import { getCsItemLabelsAsync, getCsServiceItemsAsync, getVocListAsync } from "src/api/voc/voc-api";
import { CsItemModel, CsLabels, CsServiceItemsParams, VocQueryParams } from "src/api/voc/voc-types";
import { BaseButton } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import { ArticleSection } from "src/components/ArticleSection";
import GoToListButton from "src/components/GoToListButton";
import RegistrationInformation from "src/components/RegistrationInformation";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import { PagePath } from "src/pages/product/details";
import { LabelsModal } from "./LabelsModal";

type Props = {
  vocId: string;
};

const BasicInfoDetail = ({ vocId }: Props) => {
  const [vocDetail, setVocDetail] = useState<CsItemModel>();
  const [buildings, setBuildings] = useState<{ id: string; buildingName: string }[]>([]);
  const [labels, setLabels] = useState<CsLabels[]>([]);
  const [labelsModal, setLabelsModal] = useState<Modal>({ isOpen: false });
  // 등록/수정

  const { executeAsync: getVocItems } = useApiOperation(getVocListAsync);
  const { executeAsync: getCsItemLabels } = useApiOperation(getCsItemLabelsAsync);

  // csService 목록 api (건물 > voc탭 목록)

  // 건물 목록 api
  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync);

  // csService 목록 api (건물 > voc탭 목록)
  const { executeAsync: getCsService } = useApiOperation(getCsServiceItemsAsync);

  const fetchBuildings = useCallback(
    async (ids: string[]) => {
      const buldingData = {
        page: 0,
        size: 999,
        id: ids.join(","),
      };
      const { data, status } = await getBuildings(buldingData);

      if (status >= 200 && status <= 299) {
        const buildingList = data.data.content.map((buildng) => {
          return {
            id: String(buildng.id),
            buildingName: buildng.buildingName,
          };
        });

        setBuildings(buildingList);
      }
    },
    [getBuildings],
  );

  // voc항목이 등록된 건물의 ids 가져오기
  const fetchCsService = useCallback(async () => {
    const param: CsServiceItemsParams = {
      searchType: "CS_ITEM_ID",
      keywordList: vocId,
      serviceType: ServiceTypes.SERVICE_BUILDING,
      sort: {
        orders: [{ property: "id", direction: "DESC" }],
      },
    };

    const { data, status } = await getCsService(param);

    if (status >= 200 && status <= 299) {
      const vocServiceIds = data.data.content.map((item) => item.serviceId);
      if (vocServiceIds.length > 0) {
        await fetchBuildings(vocServiceIds);
      }
    }
  }, [getCsService]);
  const fetchVocLabels = useCallback(async () => {
    const { data, status } = await getCsItemLabels({ csItemId: Number(vocId) });

    if (status >= 200 && status <= 299) {
      setLabels(data.data.labels);
    }
  }, []);

  // 1. voc 항목 상세 호출
  // 2. voc항목이 등록된 건물의 voc 호출
  useEffect(() => {
    const data = {
      searchType: "id",
      keywordList: vocId,
    };
    const fetchVocList = async (params: VocQueryParams) => {
      const { data, status } = await getVocItems(params);

      if (status >= 200 && status <= 299) {
        await fetchCsService();
        setVocDetail(data.data.content[0]);
      }
    };

    fetchVocList(data);
    fetchVocLabels();
  }, [getVocItems]);

  console.log("vocDetail :>> ", vocDetail);

  return (
    <div>
      {true && (
        <div className="contents-container__scroll">
          <div className="contents-container__wrap px0">
            <ArticleSection title="VoC 정보">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>유형</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{vocDetail?.csTypeName || "-"}</span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>항목</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{vocDetail?.csItemName || "-"}</span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>적용된 건물</p>
                </div>
                <div className="contents-container__grid-contents flex-center ">
                  <div className="flex-row flex-center-start flex-wrap ">
                    {buildings.length > 0 ? (
                      buildings.map((building, index) => (
                        <div className="mr15 h-36 flex-center " key={building.id}>
                          <span>{`${building.buildingName} (id:`} </span>
                          <Link
                            className="text-underline"
                            target="_blank"
                            to={PagePath.building.detail.replaceAll(":id", building.id)}
                          >
                            {building.id}
                          </Link>
                          <span className="ic-target-blank"></span>
                          <span>{`)${index < buildings.length - 1 ? "," : ""}`}</span>
                        </div>
                      ))
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </div>
              </section>

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>파트너</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>
                      {vocDetail?.partnerId ? (
                        <>
                          <Link
                            className="text-underline"
                            target="_blank"
                            to={PagePath.partner.detail.replaceAll(
                              ":id",
                              String(vocDetail?.partnerId || ""),
                            )}
                          >
                            {vocDetail?.partnerId}
                          </Link>
                          <span className="ic-target-blank"></span>
                        </>
                      ) : (
                        "-"
                      )}
                    </span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">Labels</p>
                </div>
                <div className="contents-container__grid-contents">
                  {labels?.map((labels, i) => (
                    <p className="" key={labels.name + i}>
                      {labels.name} : {labels.value}
                    </p>
                  ))}
                  <BaseButton
                    title="수정"
                    className={`color-white ${labels.length > 0 ? "mt10" : ""}`}
                    onClick={() => setLabelsModal({ isOpen: true })}
                  />
                  {vocId && labelsModal.isOpen && (
                    <LabelsModal
                      vocId={Number(vocId)}
                      setLabelsModal={setLabelsModal}
                      setLabels={setLabels}
                      fetchLabels={fetchVocLabels}
                    />
                  )}
                </div>
              </section>
            </ArticleSection>

            <article className="contents-container__divide-top">
              <RegistrationInformation data={vocDetail} />
            </article>

            <div className="pl30">
              <AdminMemoV3
                serviceId={Number(vocId)}
                serviceType={ServiceTypes.SERVICE_CS_ITEM}
                partnerId={vocDetail?.partnerId!}
              ></AdminMemoV3>
            </div>
          </div>
        </div>
      )}

      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap pl30">
        <div className="left-area">
          <GoToListButton />
        </div>
      </div>
    </div>
  );
};

export default BasicInfoDetail;
