import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getParkingLotAsync, postParkingLotAsync } from "src/api/partner";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { PartnerFeatures } from "src/types/partner";
type Props = {
  features?: PartnerFeatures[];
};

type SubmitData = {
  parkingLotKey: string;
};
const PartnerParking = ({ features }: Props) => {
  const { executeAsync: getParkingLot } = useApiOperation(getParkingLotAsync);
  const { executeAsync: postParkingLot } = useApiOperation(postParkingLotAsync);
  // const [parkingLotKey, setParkingLotKey] = useState<string>("");
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });
  const { id } = useParams();
  const navigate = useNavigate();
  const { setLoadingBar } = useLoadingBarContext();

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<SubmitData>({
    defaultValues: {
      parkingLotKey: "",
    },
  });

  const fetchApi = useCallback(
    async (id: string) => {
      const { data, status } = await getParkingLot({ partnerId: id });
      if (status >= 200 && status < 300) {
        if (data.data?.parkinglot?.parkinglotKey) {
          setValue("parkingLotKey", data.data?.parkinglot?.parkinglotKey);
        }
      } else {
        throw Error();
      }
    },
    [getParkingLot, setValue],
  );

  useEffect(() => {
    if (id) {
      fetchApi(id);
    }
  }, [id, fetchApi]);

  useEffect(() => {
    const requiredMessage = "필수 입력 항목입니다.";
    register("parkingLotKey", {
      required: requiredMessage,
    });
  }, [register]);

  const onSubmit = (data: SubmitData) => {
    const isAllowedParkingFeature = features?.find(
      (item) => item.name === "FEATURE_PARKING_KPS",
    )?.active;
    if (isAllowedParkingFeature) {
      setConfirmModal({
        isOpen: true,
        type: "notAllowed",
        message: "",
        payload: data,
      });
    } else {
      setConfirmModal({
        isOpen: true,
        type: "allowed",
        payload: data,
      });
    }
  };

  const handleModalConfirm = async (data: SubmitData) => {
    let parkinglot = {
      parkinglotKey: data.parkingLotKey,
      cmdType: "U",
    };
    if (id) {
      setLoadingBar(true);
      const { data, status } = await postParkingLot({ partnerId: id, ...parkinglot });
      if (status >= 200 && status < 300) {
        fetchApi(id);
      } else {
        fetchApi(id);
      }
      setLoadingBar(false);
      setConfirmModal({ isOpen: false });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-partner-parking contents-container__wrap-contents">
          <div className="mb10">
            <h2>주차 연동</h2>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index minmax300">
                <p className="required">주차장 키(parkingIot-key)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-center-start flex-row">
                  <div className="minmax300">
                    <Controller
                      control={control}
                      name="parkingLotKey"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <BaseInput
                          value={value}
                          placeholder="입력해 주세요"
                          maxLength={15}
                          onChange={onChange}
                          errorText={error?.message}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </div>
            </section>
          </div>

          {confirmModal.isOpen && (
            <BaseModal
              isOpen={true}
              btnLeftTitle="취소"
              btnRightTitle="확인"
              onClick={() => {
                handleModalConfirm(confirmModal.payload);
              }}
              onClose={() => setConfirmModal({ isOpen: false })}
            >
              <>
                <h4 className="base-modal-title">저장하시겠습니까?</h4>
                {confirmModal.type === "allowed" && (
                  <p className="mt20">
                    [FEATURE_PARKING_KPS] feature가
                    <br /> 활성화되어 있어야 주차 연동이 정상 동작합니다.
                  </p>
                )}
              </>
            </BaseModal>
          )}
        </div>
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              type="button"
              className="color-white size-large"
              onClick={() => {
                navigate("/mng/central/partner");
              }}
            />
          </div>
          <div className="right-area">
            <BaseButton
              type="button"
              title="취소"
              className="size-large color-white mr8"
              onClick={() => {
                id
                  ? navigate(`${PagePath.partner.detail.replace(":id", id)}?tab=parking`)
                  : console.log("");
              }}
            />
            <BaseButton title="저장" type="submit" className="size-large" />
          </div>
        </div>
      </form>
    </>
  );
};

export default PartnerParking;
