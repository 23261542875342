import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getPrImageAsync } from "src/api/product/product-api";
import { MediaFile, MediaServiceType } from "src/api/public-types";
import { BaseButton } from "src/components";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "../../details";
import { productMediaServiceType } from "../../product-types";
import ImageCategory from "./components/ImageCategory";

const mediaServiceTypes: MediaServiceType[] = productMediaServiceType;

const ImageForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoadingBar } = useLoadingBarContext();

  //========= Space product ============

  const [images, setImages] = useState<Array<MediaFile>>([]);

  // 이미지 불러오기
  const { executeAsync: getProductImages } = useApiOperation(getPrImageAsync);

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 공간상품 id
  const productId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  // 이미지 불러오기
  const getProductImagesApi = useCallback(
    async (serviceId: number) => {
      setLoadingBar(true);

      const data = {
        serviceId,
        mediaServiceTypes: productMediaServiceType,
      };

      const response = await getProductImages(data);
      if (response.status >= 200 || response.status < 300) {
        const result = response.data.data.content;

        setImages(result || []);
      }

      setLoadingBar(false);
    },
    [setLoadingBar],
  );

  useEffect(() => {
    if (productId) {
      getProductImagesApi(productId);
    }
  }, [getProductImagesApi, productId]);

  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection title="이미지" />

        {mediaServiceTypes.map((type: MediaServiceType) => (
          <ImageCategory
            key={type}
            type={type}
            images={images}
            productId={productId || 0}
            getProductImagesApi={getProductImagesApi}
          />
        ))}
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            className="color-white size-large"
            onClick={() => navigate(PagePath.product.list)}
          />
        </div>
      </div>
    </div>
  );
};
export default ImageForm;
