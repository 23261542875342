import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { uploadFilesAsync } from "src/api/file/file-api";
import { useApiOperation } from "src/api/hooks";
import { SaveMediaDto } from "src/api/organization/org-types";
import { BaseModal, ResizedImage } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";

interface DragDropState {
  draggedFrom: number | null;
  draggedTo: number | null;
  isDragging: boolean;
  originalOrder: SaveMediaDto[];
  updatedOrder: SaveMediaDto[];
}
interface ImagesUpdateProps {
  urlKey?: string; // url 로 사용하는 key 명칭. 없으면 기본 'key'
  representative?: boolean; // 대표여부
  isTwoInput?: boolean; // 두개 input 노출 필요시
  images?: SaveMediaDto[]; // 부모에 저장된 image 전달
  setImages?: any; // 부모에서 이미지 저장할 setState
  className?: string;
  onlyImg?: boolean; // input X 이미지만 등록시
  categoryName?: string; // 여러 ImageUpdate 컴포넌트 사용시 대표 radio 구분
  fileMaxLength?: number; // 이미지 최대 저장 length
}

// const PUBLIC = "public";
// const PRIVATE = "private";

const s3Url = process.env.REACT_APP_S3_BASEURL;

const OrgImagesUpdate = memo(
  ({
    urlKey = "key",
    representative,
    setImages,
    className,
    isTwoInput,
    onlyImg,
    categoryName,
    images,
    fileMaxLength,
  }: ImagesUpdateProps) => {
    const { setLoadingBar } = useLoadingBarContext();
    // console.log("images", images);
    const [previewImage, setPreviewImage] = useState<SaveMediaDto[]>([...(images || [])]);
    // console.log("previewImage", previewImage);
    const fileRef = useRef<HTMLInputElement>(null);
    const [flag, setFlag] = useState(true);
    const { error, executeAsync: postImageFile } = useApiOperation(uploadFilesAsync, {
      doNotErrorHandleModal: true,
    });
    const [modalOpen, setModalOpen] = useState(false);

    // 드래그로 변경될 내용들을 담아줄 객체
    const [dragAndDrop, setDragAndDrop] = useState<DragDropState>({
      draggedFrom: null, //드래그가 시작하는 index
      draggedTo: null, // 변경될 드래그 index
      isDragging: false,
      originalOrder: [],
      updatedOrder: [],
    });

    useEffect(() => {
      if (previewImage.length !== images?.length) {
        setPreviewImage(images!);
      }
    }, [images, previewImage]);

    // 이미지 업로드시 s3 저장
    const s3ImageUpload = useCallback(
      async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileArray = event.target.files!;

        if (fileArray.length > 0) {
          setLoadingBar(true);
        } else {
          setLoadingBar(false);
        }

        //입력 받은 이미지 갯수
        //이미 등록된 이미지 갯수

        //파일 제한 갯수 =< 입략+ 이미

        const inputCount = fileArray.length;
        const sum = inputCount + previewImage.length;

        if (sum > fileMaxLength!) {
          setModalOpen(true);
          setLoadingBar(false);
          return;
        }

        //이미지 등록 버튼 상태 변경(show/hide)
        if (sum === fileMaxLength) {
          setFlag(false);
        } else {
          setFlag(true);
        }

        let s3Array: Array<any> = [];

        if (fileArray) {
          for (let i = 0; i < inputCount; i++) {
            let file = fileArray[i];

            // 이미지 s3 저장소로 자동저장
            const formData = new FormData();
            formData.append("file", file);
            const res = await postImageFile({ file: formData });

            if (res && res.status >= 200 && res.status <= 299) {
              // console.log("res", res);
              const data = JSON.parse(
                JSON.stringify({ ...res.data.data.media, ...{ type: "LOGO" } }),
              );
              // setFiles(s3Array);
              s3Array.push(data);
            } else {
              // console.log(res);
            }

            const newArra: any = [...images!, ...s3Array];
            await setImages(newArra);
            await setPreviewImage(newArra);
          }
        }
        setLoadingBar(false);
      },
      [previewImage.length, fileMaxLength, setLoadingBar, postImageFile, images, setImages],
    );

    // 이미지 삭제
    const onImageClear = (idx: number) => {
      const removeFile = JSON.parse(JSON.stringify([...previewImage]));
      removeFile.splice(idx, 1);
      setPreviewImage(removeFile);
      setImages(removeFile);
      //이미지 파일 삭제 시 등록 버튼 보이도록
      if (removeFile.length === fileMaxLength) {
        setFlag(false);
      } else {
        setFlag(true);
      }
    };

    //

    //
    //
    // 사용자가 객체(object)를 드래그하려고 시작할 때 발생함.
    const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
      event.currentTarget.style.opacity = "0.4";
      const initialPosition = Number(event.currentTarget.dataset.position);

      setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: initialPosition,
        originalOrder: previewImage,
      });
    };

    //
    //
    //
    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      let newList = dragAndDrop.originalOrder;
      const draggedFrom = dragAndDrop.draggedFrom; // 드래그 되는 항목의 인덱스(시작)
      const draggedTo = Number(event.currentTarget.dataset.position); // 놓을 수 있는 영역의 인덱스(끝)
      const itemDragged = newList[draggedFrom || 0]; //

      const remainingItems = newList.filter((item, idx) => idx !== draggedFrom); //draggedFrom(시작) 항목 제외한 배열 목록

      newList = [
        ...remainingItems.slice(0, draggedTo),
        itemDragged,
        ...remainingItems.slice(draggedTo),
      ];

      //
      // 놓을 수 있는 영역이 변경 되면 객체를 변경해줌
      if (draggedTo !== dragAndDrop.draggedTo) {
        setDragAndDrop({
          ...dragAndDrop,
          updatedOrder: newList,
          draggedTo: draggedTo,
        });
      }
    };

    //
    // onDrop 잡은 Item을 적절한 곳에 놓았을 때 발생하는 이벤트 (다른 item이랑 겹쳐졌을 때)
    const onDrop = () => {
      setPreviewImage(dragAndDrop.updatedOrder);
      setImages(dragAndDrop.updatedOrder);
      setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: null,
        draggedTo: null,
      });
    };

    //
    // onDragLeave 범위를 벗어나면 발생하는 이벤트
    const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
      event.currentTarget.classList.remove("over");
      setDragAndDrop({
        ...dragAndDrop,
        draggedTo: null,
      });
    };

    //
    //
    // 다른 item이랑 겹쳐졌을 때 발생하는 이벤트 (1번만)
    const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
      event.currentTarget.classList.add("over");
    };

    //
    //
    // 잡은 Item을 놓았을 때 발생하는 이벤트 (다른 item이랑 겹치지 않아도 발생함)
    const onDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
      event.currentTarget.style.opacity = "1";

      const array = [...previewImage];

      const listItems = document.querySelectorAll(".draggable");
      listItems.forEach((item) => {
        item.classList.remove("over");
      });

      array.map((t, i) => (t.id = i));
      setPreviewImage(array);
    };

    useEffect(() => {}, [setPreviewImage, setImages, images, previewImage]);

    if (error) {
      <div>{error}</div>;
    }

    return (
      <div className="flex-row justify-contents-start align-items-center overflow-scroll">
        <div className="image-scroll">
          <div>{/* <button onClick={testFun}>저장</button> */}</div>
          {previewImage &&
            previewImage.map((item, idx) => {
              return (
                <div
                  className={`image-container ${isTwoInput ? "two-input-case" : ""}${
                    onlyImg ? "only-img" : ""
                  }`}
                  key={idx}
                  draggable={true}
                  data-position={idx}
                  onDragStart={onDragStart}
                  onDragOver={onDragOver}
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}
                  onDragEnter={onDragEnter}
                  onDragEnd={onDragEnd}
                >
                  <div className="image-container__img-wrap">
                    <span className="index-number">{idx + 1}</span>

                    <ResizedImage
                      url={
                        item.url
                          ? item.url
                          : item.key?.includes("http")
                          ? item.key
                          : s3Url + item?.key!
                      }
                      alt="LOGO"
                    />

                    <button
                      type="button"
                      className="image-container__erase-btn"
                      onClick={() => onImageClear(idx)}
                    ></button>

                    {/* {representative && (
                      <BaseRadio
                        // id={idx.toString()}
                        id={item!.id!.toString()}
                        name={categoryName ? categoryName : ""}
                        className="president"
                        checked={item.isPrimary}
                        onChange={(checked: boolean, e: React.ChangeEvent<HTMLInputElement>) =>
                          onRadioChange(e, item.id)
                        }
                      />
                    )} */}
                  </div>
                  {/* {!onlyImg && (
                    <div>
                      {isTwoInput && (
                        <BaseInput
                          className="mt8"
                          type="text"
                          placeholder="직접입력"
                          // value={item.selfDescription}
                          onChange={(value: string) => onSelfDescChange(value, idx)}
                        />
                      )}
                      <BaseInput
                        className="mt8"
                        type="text"
                        placeholder="이미지 설명을 적어주세요"
                        value={item.description}
                        onChange={(value: string) => onDescChange(value, idx)}
                      />
                    </div>
                  )} */}
                </div>
              );
            })}

          {}

          <div
            className={
              "image-container empty" +
              (isTwoInput ? " two-input-case" : "") +
              (onlyImg ? " only-img" : "") +
              (flag === false ? " d-none" : "")
            }
          >
            <input
              ref={fileRef}
              type="file"
              style={{ display: "none" }}
              accept=".png, .jpg"
              onChange={s3ImageUpload}
              // onChange={onSaveImages}
              multiple
            />

            <button
              type="button"
              className="image-container__plus-btn"
              onClick={() => fileRef.current?.click()}
            />
          </div>
        </div>
        <BaseModal isOpen={modalOpen} btnRightTitle="확인" onClick={() => setModalOpen(false)}>
          <p>이미지 파일은 최대 {fileMaxLength}개 까지 등록이 가능합니다.</p>
        </BaseModal>
      </div>
    );
  },
);

export default React.memo(OrgImagesUpdate);
