import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse, MediaFile } from "../public-types";
import { ProductCommonSpaceEditModel } from "./product-types";

/* 
  CPP009. 공간상품 공용공간 관리(등록/수정/삭제)
*/
export async function postCommonfacilityAsync(
  axios: AxiosInstance,
  // body?: any,
  body?: ProductCommonSpaceEditModel,
): Promise<AxiosResponse<ApiResponse<any>>> {
  console.log("body", body);
  const path = "/api/central/pr/products/{id}/commonFacility".replace("{id}", String(body?.id));

  return await axios.post(path, {
    product: {
      ...body,
    },
  });
}
