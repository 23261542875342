import React from "react";
import { Link } from "react-router-dom";
import { IMenu } from "../types";

function DashboardMenu({ title, path, backgroundColor, count }: IMenu) {
  return (
    <Link to={path}>
      <div
        className={`dashboard-grid__card ${
          backgroundColor !== "Default" && `${backgroundColor}`
        }`}
      >
        <p>{title}</p>
        <p>{count >= 0 && count}</p>
      </div>
    </Link>
  );
}

export default DashboardMenu;
