import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { editScheduleStop, getScheduleStop } from "src/api/billingSchedule/billingSchedule-api";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { contract_bill_user_errors } from "src/api/public-types";
import { BaseButton, BaseDatePicker, BaseModal, BaseSelect, BaseTextarea } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";

type Props = {
  round: string;
  totalRound: any;
  isOpen: boolean;
  scheduleId: string;
  setIsOpen: any;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick: any;
};

const StopModal = (props: Props) => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const params = useParams();
  const { executeAsync: scheduleStop } = useApiOperation(editScheduleStop, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: scheduleCheckStop } = useApiOperation(getScheduleStop, {
    doNotErrorHandleModal: true,
  });
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      scheduleId: 0,
      schedule: {
        isContractCancel: true,
        contractCancelDate: "",
        contractCancelStartOrder: "0",
        contractCancelReason: "",
      },
    },
  });

  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [isSave, setIsSave] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [stopObj, setStopObj] = useState<any>();

  const checkStop = useCallback(async () => {
    const res: any = await scheduleCheckStop({ scheduleId: props.scheduleId });

    if (res.status >= 200 && res.status <= 299) {
      setStopObj(res.data.data);
      if (res.data.data.isContractCancel === "true") {
        setValue("schedule.isContractCancel", res.data.data.isContractCancel);
        setIsSaved(true);

        setValue("schedule.contractCancelDate", res.data.data.contractCancelDate);
        setValue("schedule.contractCancelReason", res.data.data.contractCancelReason);
        setValue(
          "schedule.contractCancelStartOrder",
          String(res.data.data.contractCancelStartOrder),
        );
      } else {
        setIsSaved(false);
        setValue("schedule.contractCancelStartOrder", String(props.round));
      }
    } else {
      let message = errorMessageHandler(
        res.status,
        res.data.meta.errorCode,
        contract_bill_user_errors,
      );
      let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.status;
      openErrorModal(message, code);
    }
  }, [openErrorModal, props.round, props.scheduleId, scheduleCheckStop, setValue]);

  const updateScheduleStop = useCallback(
    async (data: FormData) => {
      let copy: any = _.cloneDeep(data);
      copy.scheduleId = props.scheduleId;
      setLoadingBar(true);
      const response: any = await scheduleStop(copy);
      if (response.status >= 200 && response.status <= 299) {
        setIsSuccess(true);
      } else {
        let message = errorMessageHandler(
          response.status,
          response.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
        openErrorModal(message, code);
      }
      setLoadingBar(false);
      await setIsSave(false);
    },
    [openErrorModal, props.scheduleId, scheduleStop, setLoadingBar],
  );

  const submit = useCallback(() => {
    props.onClick(stopObj.data, stopObj.e);
    updateScheduleStop(stopObj.data);
  }, [props, stopObj, updateScheduleStop]);

  const confirm = useCallback((data: FormData, e?: any) => {
    setStopObj({ data, e });
    setIsSave(true);
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      const message = "필수 입력 사항입니다.";
      const option: any = [];
      props.totalRound.forEach((row: any) => {
        option.push({
          label: String(row.original.bill.billOrder),
          value: String(row.original.bill.billOrder),
        });
      });
      setOptions(option);

      register("schedule.isContractCancel");

      register("schedule.contractCancelStartOrder", {
        required: message,
      });

      checkStop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    //console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };
  return (
    <div>
      <>
        <BaseModal className="dialog-modal" isOpen={props.isOpen}>
          <>
            <form onSubmit={handleSubmit(confirm, onError)}>
              <div className="modal-title">
                <div className="left-area align-items-center">
                  <span>빌링스케쥴 중지</span>
                  <p className="sub-title-info ml40">
                    계약자와 협의 후 빌링스케줄 중지 일자, 중지 시작 회차, 중지 사유를 입력해
                    주세요.
                  </p>
                </div>
                <div className="right-area"></div>
              </div>
              <div className="text-left px30 pt20 pb100">
                <section className="flex-center-start mb20">
                  <div className="minmax140">
                    <p className="text-primary3 required">선택 회차</p>
                  </div>
                  <div className="">
                    <div className="">
                      <Controller
                        control={control}
                        name="schedule.contractCancelStartOrder"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseSelect
                              name={name}
                              value={String(value)}
                              stateOptions={options}
                              setStateValue={onChange}
                              errorText={error?.message}
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </div>
                </section>
                <section className="flex-center-start mb20">
                  <div className="minmax140">
                    <p className="text-primary3">중지 일시</p>
                  </div>
                  <div className="minmax300">
                    <Controller
                      control={control}
                      name="schedule.contractCancelDate"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseDatePicker
                            timeIntervals={10}
                            type="date"
                            selectedDate={value ? moment(value).toDate() : null}
                            setDate={onChange}
                            name={name}
                            errorText={error?.message}
                          />
                        );
                      }}
                    ></Controller>
                    {errors.remodelingYmd?.message && (
                      <p className="validation-text">{errors.remodelingYmd.message}</p>
                    )}
                  </div>
                </section>
                <section className="flex-center-start">
                  <div className="minmax140">
                    <p className="text-primary3">중지 사유</p>
                  </div>
                  <div className="minmax300">
                    <Controller
                      control={control}
                      name="schedule.contractCancelReason"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseTextarea
                          placeholder="중지사유를 입력해주세요"
                          className="mr8"
                          onChange={onChange}
                          value={value}
                          name={name}
                          maxLength={1000}
                          errorText={error?.message}
                        />
                      )}
                    ></Controller>
                  </div>
                </section>
              </div>
              <div className="base-modal__btn-wrap">
                <BaseButton
                  title={"취소"}
                  onClick={props.onClose}
                  className="color-white no-outline text-primary3 mr10"
                />

                <BaseButton title="저장" type="submit" />
              </div>
            </form>
          </>
        </BaseModal>
        <BaseModal
          isOpen={isSave}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => setIsSave(false)}
          onClick={() => submit()}
        >
          <p>저장하시겠습니까?</p>
        </BaseModal>
        <BaseModal isOpen={isSuccess} btnRightTitle="확인" onClick={() => setIsSuccess(false)}>
          <p>저장되었습니다.</p>
        </BaseModal>
      </>
    </div>
  );
};

export default StopModal;
