import qs from "qs";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContentsTitle } from "src/components";
import BasicInfoDetail from "./components/BasicInfoDetail";

const VocDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: vocId } = useParams();

  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
  });

  const tabs = [{ value: "basicInfo", label: "기본정보" }];

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [location, queryParams],
  );

  return (
    <div>
      <ContentsTitle title="VoC 항목" tabs={tabs} activeTab={activeTab} clickTab={clickTab} />
      <div className="contents-container__scroll">
        {/* 기본정보 */}
        {activeTab?.value === "basicInfo" && <BasicInfoDetail vocId={String(vocId)} />}
      </div>
    </div>
  );
};

export default VocDetail;
