import React from "react";
import { ProductModel } from "src/api/product/product-types";
import { MetaData } from "src/api/public-types";

type Props = {
  product: ProductModel | null;
};

/* 
  공간상품 > 상세 > 시설/서비스 > 추가 시설/서비스 컴포넌트
*/
const OptionalFacilitySection = ({ product }: Props) => {
  const productOptionalFacilityList: Array<MetaData> = (
    product?.productOptionalFacilityList || []
  ).sort((a: MetaData, b: MetaData) => Number(a.orderNums || 0) - Number(b.orderNums || 0));
  return (
    <section className="contents-container__grid contents-container__1200">
      <div className="contents-container__grid-index">
        <p className="">추가 시설/서비스</p>
      </div>
      <div className="contents-container__grid-contents">
        <table className="inner-table" width="100%">
          <thead>
            <tr>
              <th>
                <span>종류</span>
              </th>
              <th>
                <span>제공여부</span>
              </th>
              <th>
                <span>추가 설명</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {productOptionalFacilityList.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <div className="w-100 text-center">
                    <span>데이터가 없습니다.</span>
                  </div>
                </td>
              </tr>
            )}
            {productOptionalFacilityList.map((metaData: MetaData, index: number) => {
              let label = "";
              if (metaData.metaItem === "PRODUCT_OPTIONAL_FACILITY_1") {
                label = "공용 라운지";
              } else if (metaData.metaItem === "PRODUCT_OPTIONAL_FACILITY_2") {
                label = "택배/우편물 수령";
              } else if (metaData.metaItem === "PRODUCT_OPTIONAL_FACILITY_3") {
                label = "보안";
              } else if (metaData.metaItem === "PRODUCT_OPTIONAL_FACILITY_4") {
                label = "스낵";
              } else if (metaData.metaItem === "PRODUCT_OPTIONAL_FACILITY_5") {
                label = "인포데스크";
              }
              return (
                <tr key={index.toString()}>
                  <td width="120" className="text-center">
                    <span>{label}</span>
                  </td>
                  <td width="220">
                    <div className="flex-center-center">
                      {Boolean(String(metaData?.value1) === "true") ? "제공" : "제공하지 않음"}
                    </div>
                  </td>
                  <td>
                    <div className="w-100 px16">{metaData?.value2 || ""}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};
export default OptionalFacilitySection;
