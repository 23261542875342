import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getOrgList } from "src/api/organization/org-api";
import { MbOrg, OrgListParams } from "src/api/organization/org-types";
import { getPartner } from "src/api/partner";
import { Order, PageMeta, Sort } from "src/api/public-types";
import { BaseButton, BaseInput, BaseSelect, BaseTable } from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { Partner } from "src/types/partner";
import OrgColumns from "./columns/OrganizationColumns";

const searchTypes = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "법인명/상호",
    value: "containsName",
  },
  {
    label: "사업자등록번호",
    value: "containsBizRegNo",
  },
  {
    label: "법인등록번호",
    value: "containsCorRegNo",
  },
];

const classificationOptions = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "일반 사업자",
    value: "GENERAL_BIZ",
  },
  {
    label: "소유주",
    value: "OWNER",
  },
];

const businessOptions = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "법인 사업자",
    value: "CORP",
  },
  {
    label: "개인 사업자",
    value: "SP",
  },
];

type QueryParams = {
  page?: number;
  size?: number;
  searchType?: string;
  keyword?: string;
  sort?: Sort;
  classification?: string;
  bizType?: string;
  containsName?: string;
  containsBizRegNo?: string;
  containsCorRegNo?: string;
  search001?: string;
  partnerId?: string;
  partnerName?: string;
};

const OrganizaionList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { executeAsync: getOrgListApi } = useApiOperation(getOrgList);
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);
  const [organizations, setOrganizations] = useState<MbOrg[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);
  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState<boolean>(false);

  // console.log("organizations", organizations);

  const { setLoadingBar } = useLoadingBarContext();

  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, [location]);

  const [params, setParams] = useState<QueryParams>({ ...queryParams });

  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      console.log("newQueryParams", newQueryParams);
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, params],
  );

  // 파트너 조회
  const fetchPartner = async (id: string) => {
    const response = await getPartnerAsync({ id });
    if (response.status >= 200 && response.status <= 299) {
      const partner = response.data.data.partner;
      setSelectedPartners([partner]);
    }
  };

  const onAddSelectPartners = (partners: Partner[]) => {
    setSelectedPartners(partners);
    setIsPartnerModalOpen(false);
    setParams({
      ...params,
      partnerId: String(partners[0].id),
      partnerName: String(partners[0].name),
    });
  };

  useEffect(() => {
    async function fetchProducts(orgtListParams: OrgListParams) {
      setLoadingBar(true);

      const { data } = await getOrgListApi(orgtListParams);
      setOrganizations(data?.data?.content || []);
      setPageMeta(data?.meta?.pageMeta);

      if (params.partnerId) {
        // 파트너아이디 있음
        await fetchPartner(String(params.partnerId));
      }
      setLoadingBar(false);
    }

    // let orgListParams: OrgListParams = {
    //   page: queryParams.page,
    //   size: queryParams.size,
    //   sort: queryParams.sort,
    // };
    if (queryParams.searchType && queryParams.searchType !== "") {
      if (queryParams.searchType === "containsName") {
        queryParams.containsName = queryParams.keyword || "";
      } else if (queryParams.searchType === "containsBizRegNo") {
        queryParams.containsBizRegNo = queryParams.keyword || "";
      } else if (queryParams.searchType === "containsCorRegNo") {
        queryParams.containsCorRegNo = queryParams.keyword || "";
      }
    } else {
      queryParams.search001 = queryParams.keyword || "";
    }

    // if (queryParams.searchType && queryParams.searchType !== "") {
    //   if (queryParams.searchType === "containsName") {
    //     orgListParams.containsName = queryParams.keyword || "";
    //   } else if (queryParams.searchType === "containsBizRegNo") {
    //     orgListParams.containsBizRegNo = queryParams.keyword || "";
    //   } else if (queryParams.searchType === "containsCorRegNo") {
    //     orgListParams.containsCorRegNo = queryParams.keyword || "";
    //   }
    // } else {
    //   orgListParams.search001 = queryParams.keyword || "";
    // }
    fetchProducts(queryParams);
  }, [getOrgListApi, queryParams, setLoadingBar]);

  return (
    <div className="page-product-list">
      <div className="contents-container__search-wrap">
        <div className="left-area">
          {/* <p>
            전체 <span>{pageMeta?.totalElements || 0}</span>
          </p> */}
          <div className="minmax200 mr8">
            <BaseInput
              readonly
              placeholder="파트너"
              value={selectedPartners[0]?.name || params.partnerName}
              onSearchClick={() => setIsPartnerModalOpen(true)}
              onClearClick={() => {
                setSelectedPartners([]);
                const { partnerId, partnerName, ...rest } = params;
                setParams(rest);
              }}
            />
          </div>
          <div className="minmax140 mr8">
            <BaseSelect
              value={params.classification}
              stateOptions={classificationOptions}
              placeholder="구분"
              setStateValue={(type?: string) => {
                console.log("type", type);
                setParams({ ...params, classification: type });
              }}
            />
          </div>
          <div className="minmax140 mr8">
            <BaseSelect
              value={params.bizType}
              stateOptions={businessOptions}
              placeholder="사업형태"
              setStateValue={(type?: string) => {
                setParams({ ...params, bizType: type });
              }}
            />
          </div>
          <div className="minmax160 mr8">
            <BaseSelect
              value={params.searchType}
              stateOptions={searchTypes}
              placeholder="검색대상"
              setStateValue={(type?: string) => {
                setParams({ ...params, searchType: type });
              }}
            />
          </div>
          <div className="minmax220 mr16">
            <BaseInput
              className=""
              name="keyword"
              type="text"
              placeholder="검색어를 입력해주세요"
              value={params?.keyword || ""}
              onChange={(keyword: string) => setParams({ ...params, keyword })}
              onKeyUp={() => navigateWithQueryParams({ page: 0 })}
              onSearchClick={() => navigateWithQueryParams({ page: 0 })}
            />
          </div>
        </div>
        <div className="right-area">
        </div>
      </div>
      {isPartnerModalOpen && (
        <PartnerSelectModal
          onCanceled={() => {
            setIsPartnerModalOpen(false);
          }}
          onAdded={onAddSelectPartners}
          defaultValues={selectedPartners || []}
        />
      )}

      <BaseTable
        data={organizations}
        columns={OrgColumns}
        pageIndex={pageMeta?.pageRequest.page || 0}
        totalPages={pageMeta?.totalPages || 0}
        goPage={(page: number) => {
          navigateWithQueryParams({ page });
        }}
        orders={queryParams.sort?.orders}
        // disabledSortHeaders={["imagePath"]}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } });
          }
        }}
      />
    </div>
  );
};

export default OrganizaionList;
