/* eslint-disable array-callback-return */
import { calculateRateOfUse } from "src/utils";

const TableTextCellWithBuildingTotal = ({ title, array }: any) => {
  let totalLeasableAreaNet = 0;
  let totalLeasableArea = 0;
  let total = 0;
  array?.map((list: any, index: number) => {
    list.building.buildingFloorList.map((floor: any) => {
      floor.buildingRoomList.map((room: any) => {
        totalLeasableAreaNet += Number(room.leasableAreaNet);
        totalLeasableArea += Number(room.leasableArea);
      });
    });
  });

  total = Number(calculateRateOfUse(totalLeasableAreaNet, totalLeasableArea));

  return (
    <>
      <tr>
        <td width="180">
          <div className="flex-center pl16">
            <span className="text-primary3">{title}</span>
          </div>
        </td>
        <td>
          <div className="w-100 flex-center-start text-left">
            <span>{total + "%"}</span>
          </div>
        </td>
      </tr>
    </>
  );
};
export default TableTextCellWithBuildingTotal;
