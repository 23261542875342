import React from "react";
import { Control, UseFormReturn, Controller } from "react-hook-form";
import {
  CommonFacilityModel,
  BuildingModel,
  BuildingParkingLotModel,
} from "src/api/building/building-types";
import { ProductModel } from "src/api/product/product-types";
import { BaseToggle, BaseCheckbox, BaseInput } from "src/components";
import ProductParkingSection from "src/pages/product/components/ProductParkingSection";
import { CommonSpaceFormData, SpaceBuildingParkingLotModel } from "../CommonSpaceForm";

type Props = {
  control: Control<any>;
  watch: UseFormReturn<CommonSpaceFormData>["watch"];
  errors: any;
  setValue: Function;
  setIsNoData: Function;
  productDetail?: ProductModel;
  parkingLotList: BuildingParkingLotModel[];
};

const ParkingLostFormComp = ({
  control,
  watch,
  errors,
  setValue,
  setIsNoData,
  productDetail,
  parkingLotList,
}: Props) => {
  return (
    <article>
      <div className="contents-container__sub-title">
        <div className="minmax140 pb4">
          <h2>주차</h2>
        </div>
        <Controller
          control={control}
          name="isParkingLot"
          render={({ field: { onChange, name, value } }) => {
            return (
              <>
                <BaseToggle
                  name={name}
                  // onChange={(value: boolean) => {
                  //   if (value && parkingLotList.length === 0) {
                  //     setIsNoData(true);
                  //     return;
                  //   }
                  //   onChange(value);
                  // }}
                  checked={value}
                />
              </>
            );
          }}
        ></Controller>
      </div>
      {/* 토글을 닫으면 contents-container__divide-bottom 영역이 노출되지 않음 */}
      {Boolean(watch("isParkingLot")) && (
        <div className="contents-container__divide-bottom">
          {/* 상세 설명 */}
          <section className="contents-container__grid contents-container__1200">
            <div className="contents-container__grid-index">
              <p className="required">상세 설명</p>
            </div>
            <div className="contents-container__grid-contents">
              <table className="inner-table" width="100%">
                <thead>
                  <tr>
                    <th>
                      <span>선택</span>
                    </th>
                    <th>
                      <span>구분</span>
                    </th>
                    <th>
                      <span>설명</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="100">
                      <div className="flex-center-center no-wrap">
                        <Controller
                          control={control}
                          name="isParkingLotFree"
                          render={({ field: { onChange, name, value } }) => {
                            return (
                              <>
                                <BaseCheckbox
                                  id={"isParkingLotFree1"}
                                  name={name}
                                  // onChange={(checked: boolean) => {
                                  //   if (checked) {
                                  //     onChange(true);
                                  //   } else {
                                  //     onChange(false);
                                  //     setValue("parkingLotFreeDescription", "");
                                  //   }
                                  // }}
                                  checked={value === true ? true : false}
                                />
                              </>
                            );
                          }}
                        ></Controller>
                      </div>
                    </td>
                    <td width="100">
                      <div className="flex-center-center no-wrap">
                        <span>무료</span>
                      </div>
                    </td>
                    <td>
                      <div className="flex-center no-wrap">
                        <Controller
                          control={control}
                          name="parkingLotFreeDescription"
                          render={({ field: { onChange, name, value } }) => {
                            return (
                              <>
                                <BaseInput
                                  name={name}
                                  value={value}
                                  onChange={(value: string) => {
                                    onChange(value);
                                  }}
                                  readonly={watch("isParkingLotFree") ? false : true}
                                />
                              </>
                            );
                          }}
                        ></Controller>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="100">
                      <div className="flex-center-center no-wrap">
                        <Controller
                          control={control}
                          name="isParkingLotPay"
                          render={({ field: { onChange, name, value } }) => {
                            return (
                              <>
                                <BaseCheckbox
                                  id={"isParkingLotFree2"}
                                  name={name}
                                  // onChange={(checked: boolean) => {
                                  //   if (checked) {
                                  //     onChange(true);
                                  //   } else {
                                  //     onChange(false);
                                  //     setValue("parkingLotPayDescription", "");
                                  //   }
                                  // }}
                                  checked={value === true ? true : false}
                                />
                              </>
                            );
                          }}
                        ></Controller>
                      </div>
                    </td>
                    <td width="100">
                      <div className="flex-center-center no-wrap">
                        <span>유료</span>
                      </div>
                    </td>
                    <td>
                      <div className="flex-center no-wrap">
                        <Controller
                          control={control}
                          name="parkingLotPayDescription"
                          render={({ field: { onChange, name, value } }) => {
                            return (
                              <>
                                <BaseInput
                                  name={name}
                                  value={value}
                                  onChange={(value: string) => {
                                    onChange(value);
                                  }}
                                  readonly={watch("isParkingLotPay") ? false : true}
                                />
                              </>
                            );
                          }}
                        ></Controller>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              {errors?.isParkingLot && (
                <p className="validation-text">{errors.isParkingLot.message}</p>
              )}
            </div>
          </section>
          {/* 주차장 정보 */}
          <section className="contents-container__grid contents-container__1200">
            <div className="contents-container__grid-index">
              <p>주차장 정보</p>
            </div>

            <div className="contents-container__grid-contents">
              {productDetail?.buildingList &&
                parkingLotList.length > 0 &&
                productDetail?.buildingList.map((product: BuildingModel) => {
                  const filterdList = parkingLotList.filter((parking: BuildingParkingLotModel) => {
                    return parking.buildingId === product?.id;
                  });

                  return filterdList.map(
                    (parkingLot: SpaceBuildingParkingLotModel, index: number) => {
                      const buildingData = product;
                      return (
                        <div className="pt8 mb10" key={index}>
                          <div className="index-tab">
                            <span>{parkingLot.buildingName}</span>
                          </div>
                          <ProductParkingSection building={buildingData} />
                        </div>
                      );
                    },
                  );
                })}
            </div>
          </section>
        </div>
      )}
    </article>
  );
};

export default ParkingLostFormComp;
