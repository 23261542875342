import React from "react";
import { useApiOperation } from "src/api/hooks";
import { putPartnerPrivilegeAsync } from "src/api/partner";
import { BaseToggle } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { Privilege, PrivilegeCode } from "src/types/partner";
type Props = {
  code:
    | "BASIC"
    | "BUILDING"
    | "PRODUCT"
    | "WORKORDER"
    | "CS"
    | "CONTRACT"
    | "RESERVATION"
    | "VISITOR"
    | "NOTICE";
  partnerId: string | undefined;
  memberId: string;
  privileges: Privilege[];
  fetchApi: any;
};
const Permissions = ({ code, partnerId, memberId, privileges, fetchApi }: Props) => {
  const { setLoadingBar } = useLoadingBarContext();
  const { executeAsync: putPartnerPrivilege } = useApiOperation(putPartnerPrivilegeAsync, {
    doNotHandleLoadingBar: true,
  });

  const findPermissionBoolean = (code: string, type: string) => {
    const findPrivilege = privileges?.find((privilege: Privilege) => privilege.code === code);
    return findPrivilege?.permissions.includes(type) || false;
  };

  const PermissionsPayload = (code: string, type: string) => {
    const findPrivilege = privileges.find((privilege: Privilege) => privilege.code === code);
    if (findPrivilege) {
      let newPermission = [...findPrivilege.permissions];
      newPermission = newPermission.includes(type)
        ? newPermission.filter((item) => item !== type)
        : [...newPermission, type];

      return newPermission;
    } else return [type];
  };
  return (
    <div className="flex-column">
      <div className="flex-center mb4">
        <p className="rwd-index">R:</p>
        <BaseToggle
          checked={findPermissionBoolean(code, "r")}
          onChange={async () => {
            if (partnerId) {
              setLoadingBar(true);
              const response = await putPartnerPrivilege({
                partnerId,
                memberId,
                privilege: code,
                permissions: PermissionsPayload(code, "r"),
              });
              if (response.status >= 200 && response.status <= 299) {
                // 다시재호출
                await fetchApi(partnerId);
              }
              setLoadingBar(false);
            }
          }}
        />
      </div>
      <div className="flex-center mb4">
        <p className="rwd-index">W:</p>
        <BaseToggle
          checked={findPermissionBoolean(code, "w")}
          onChange={async () => {
            if (partnerId) {
              setLoadingBar(true);
              const response = await putPartnerPrivilege({
                partnerId,
                memberId,
                privilege: code,
                permissions: PermissionsPayload(code, "w"),
              });
              if (response.status >= 200 && response.status <= 299) {
                // 다시재호출
                await fetchApi(partnerId);
              }
              setLoadingBar(false);
            }
          }}
        />
      </div>
      <div className="flex-center mb4">
        <p className="rwd-index">D:</p>
        <BaseToggle
          checked={findPermissionBoolean(code, "d")}
          onChange={async () => {
            if (partnerId) {
              setLoadingBar(true);
              const response = await putPartnerPrivilege({
                partnerId,
                memberId,
                privilege: code,
                permissions: PermissionsPayload(code, "d"),
              });
              if (response.status >= 200 && response.status <= 299) {
                await fetchApi(partnerId);
              }
              setLoadingBar(false);
            }
          }}
        />
      </div>
    </div>
  );
};

export default Permissions;
