import { AxiosResponse } from "axios";
import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Address } from "react-daum-postcode";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createBuildingAsync,
  getBuildingAsync,
  updateBuildingAsync,
} from "src/api/building/building-api";
import {
  BuildingAddModel,
  BuildingCommonFacilityDeskGroupModel,
  BuildingEditModel,
  BuildingFloorModel,
  BuildingModel,
  BuildingUpdateResponseData,
  CommonFacilityModel,
  HeatingFacilityType,
} from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import { ApiResponse, HeatingType, MetaData } from "src/api/public-types";
import {
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
  BaseInput,
  BaseModal,
  BaseSelect,
  BaseTextarea,
  ContentsIdSection,
  DaumAddress,
} from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import PagePath from "src/pagePath.json";
import {
  BasicInfoFormData,
  BuildingStructure,
  buildingStructureOptions,
  exitDirectionOptions,
  freightElevOptions,
  heatingFacilityTypeOptions,
  heatingTypeOptions,
  isHeatingOptions,
  Modal,
  passengerElevOptions,
  Restroom,
  restroomCountOptions,
  restroomFloorOptions,
  restroomTypeOptions,
  SelectOption,
} from "src/pages/building/building-types";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import { Partner } from "src/types/partner";
import { calculatePyeong, calculateRateOfUse } from "src/utils";
import { AirConditioning, BuildingStructures, Elevator, Restrooms } from "./components";

/* 
  건물 > 등록 or 수정 > 기본정보
 */
const BasicInfoForm = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 토스트
  const { openToast } = useToastContext();

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 건물 상세 조회 (수정일 경우)
  const buildingId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  // 건물 상세
  const [building, setBuilding] = useState<BuildingModel | null>(null);

  // 선택된 파트너 정보
  const [partner, setPartner] = useState<Partner>();

  // 건물 상세 조회 api hook
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  // 건물 등록 api hook
  const {
    executeAsync: createBuilding,
    error: createBuildingError,
    setError: setCreateBuildingError,
  } = useApiOperation(createBuildingAsync, {
    doNotErrorHandleModal: true,
  });

  // 건물 수정 api hook
  const {
    executeAsync: updateBuilding,
    error: updateBuildingError,
    setError: setUpdateBuildingError,
  } = useApiOperation(updateBuildingAsync, {
    doNotErrorHandleModal: true,
  });

  // 파트너 상세조회
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  const fetchPartner = async (id: string) => {
    setLoadingBar(true);
    const response = await getPartnerAsync({ id });

    if (response.status >= 200 && response.status <= 299) {
      const result = response.data.data.partner;
      setPartner(result);
    }
    setLoadingBar(false);
  };

  useEffect(() => {
    if (buildingId) {
      async function fetchBuilding(buildingId: number) {
        setLoadingBar(true);
        const { data } = await getBuilding({ buildingId });
        setBuilding(data?.data?.content?.building || null);

        // 건물 상세를 불러오면서 파트너 상세 정보도 같이 호출
        await fetchPartner(String(data.data.content.building.partnerId));
        setLoadingBar(false);
      }
      fetchBuilding(buildingId);
    }
  }, [buildingId, getBuilding, setLoadingBar]);

  // title 에 건물명 바인딩
  let buildingName;
  if (buildingId && building?.buildingName) {
    buildingName = building?.buildingName;
  }
  useTitleOperation(buildingName);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });
  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 파트너 변경 경고 모달
  const [partnerChangeModal, setPartnerChangeModal] = useState<Modal>({
    isOpen: false,
  });

  // 파트너 목록 노출 모달
  const [partnerModal, setPartnerModal] = useState<Modal>({
    isOpen: false,
  });

  // 저장할 form data
  const [submitData, setSubmitData] = useState<BasicInfoFormData>();

  // 리모델링 년도
  const years = useMemo(() => {
    const stop = 1950;
    const start = new Date().getFullYear();
    const step = -1;
    const range = Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    const years: Array<{ value: string; label: string }> = range.map((year: number) =>
      Object.assign({
        value: `${year}`,
        label: `${year}`,
      }),
    );

    return years;
  }, []);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    reset,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<BasicInfoFormData>({
    defaultValues: {
      buildingName: "",
      addressData: undefined,
      zonecode: "",
      address: "",
      addressDetail: "",
      locationCode: "",
      description: "",
      feature: "",
      floorNums: 1,
      undergroundFloorNums: 1,
      completionYmd: "",
      remodelingYmd: "",
      buildingStructureTypes: [{ seq: 1, facility: undefined, use: undefined }],
      leasableArea: 0,
      leasableAreaNet: 0,
      rateOfUse: "",
      isHoliday: false,
      setIsUsedRestoom: false,
      isUsedRestroom: false,
      restrooms: [
        {
          seq: 1,
          floor: restroomFloorOptions[0].value,
          type: restroomTypeOptions[0].value,
          manCount: 1,
          womanCount: 1,
          count: 1,
        },
      ],
      isUsedElevator: false,
      passengerElev: passengerElevOptions[0].value,
      freightElev: freightElevOptions[0].value,
      isUsedAirConditioning: false,
      heatingType: heatingTypeOptions[0].value,
      heatingTypeDesc: "",
      heatingFacilityType: heatingFacilityTypeOptions[0].value,
      isHeating: isHeatingOptions[0].value,
      isHeatingEquipment: true,
      heatingFacilityTypeDesc: "",
      heatingFacilityTypeAddDesc: "",
    },
  });

  // 기준 층 임대면적, 기준 층 전용면적 watch
  const [leasableArea, leasableAreaNet] = useWatch({
    name: ["leasableArea", "leasableAreaNet"],
    control,
  });
  useEffect(() => {
    // 기준 층 임대면적 평 계산
    if (Number(leasableArea || 0) > 0) {
      setValue("leasableAreaPyeong", calculatePyeong(Number(leasableArea || 0)), {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
    // 기준 층 전용면적 평 계산
    if (Number(leasableAreaNet || 0) > 0) {
      setValue("leasableAreaNetPyeong", calculatePyeong(Number(leasableAreaNet || 0)), {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
    // 기준 층 전용률 계산
    if (Number(leasableAreaNet || 0) > 0 && Number(leasableArea || 0) > 0) {
      const rateOfUse = calculateRateOfUse(Number(leasableAreaNet || 0), Number(leasableArea || 0));
      setValue("rateOfUse", rateOfUse, { shouldDirty: true, shouldValidate: true });
    }
  }, [leasableArea, leasableAreaNet, setValue]);

  // 수정시 api 에서 받아온 building 정보로 setValue 처리
  useEffect(() => {
    if (building) {
      Object.entries(building).forEach(([name, value]: any) => {
        if (name === "addressList") {
          // 주소
          if (value && value.length > 0) {
            const addressData = value[0];
            setValue("zonecode", addressData.zonecode);
            setValue("address", addressData.address);
            setValue("addressDetail", addressData.addressDetail);
            setValue("addressData", addressData, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }
        } else if (name === "remodelingYmd") {
          // 리모델링 년도
          if (value && String(value).length === 4) {
            // 년도 일 경우만 바인딩
            setValue("remodelingYmd", value);
          }
        } else if (name === "entryStartTime") {
          // 출입시간 시작
          if (value) {
            setValue("entryStartTimeDate", moment(value, "hh:mm").toDate());
          }
        } else if (name === "entryEndTime") {
          // 출입시간 종료
          if (value) {
            setValue("entryEndTimeDate", moment(value, "hh:mm").toDate());
          }
        } else if (name === "completionYmd") {
          // 사용 승인일
          if (value) {
            if (moment(value).isValid()) {
              setValue("completionYmd", moment(value).format("YYYY-MM-DD"));
            }
          }
        } else if (name === "buildingStructureTypeList") {
          // 건축물 용도
          const buildingStructureTypes = value.map((metaData: MetaData, index: number) => {
            const buildingStructure: BuildingStructure = {
              id: metaData.id,
              seq: Number(metaData?.id || 0),
              facility: metaData.metaItem,
              use: metaData.value1,
            };
            return buildingStructure;
          });
          setValue("buildingStructureTypes", buildingStructureTypes);
        } else if (name === "buildingToiletList") {
          // 화장실
          if (value && value.length > 0) {
            setValue("isUsedRestroom", true);

            const restrooms: Array<Restroom> = value.map((metaData: MetaData, index: number) => {
              // 지하 or 지상
              const floorType =
                metaData.metaItem === "BUILDING_TOILET_1" ||
                metaData.metaItem === "BUILDING_TOILET_3"
                  ? "UNDERGROUND_FLOOR"
                  : "FLOOR";

              // 구분 (남여분리 or 남여공용)
              const type =
                metaData.metaItem === "BUILDING_TOILET_1" ||
                metaData.metaItem === "BUILDING_TOILET_2"
                  ? "SEPARATION"
                  : "PUBLIC";

              // 층
              const floor = metaData?.value1 ? `${floorType}_${metaData.value1}` : "";

              const restroom: Restroom = {
                id: metaData?.id,
                seq: Number(metaData?.id || 0),
                floor,
                type,
                manCount: type === "SEPARATION" ? Number(metaData?.value2 || 0) : 0,
                womanCount: type === "SEPARATION" ? Number(metaData?.value3 || 0) : 0,
                count: type === "PUBLIC" ? Number(metaData?.value2 || 0) : 0,
              };
              return restroom;
            });

            setValue("restrooms", restrooms);
          }
        } else if (name === "passengerElev" || name === "freightElev") {
          // 엘리베이터
          if (
            (name === "passengerElev" && value > 0) ||
            (name === "freightElev" && (value === 0 || value > -1))
          ) {
            setValue("isUsedElevator", true);
            setValue(name, String(value));
          }
        } else if (name === "heatingType") {
          // 냉난방 방식
          if (value && value !== "HEATING_UNRECOGNIZED") {
            setValue("isUsedAirConditioning", true);
            setValue(name, value);
          }
        } else if (name === "isHeating") {
          setValue(name, String(value));
        } else {
          setValue(name, value);
        }
      });
    }
  }, [setValue, building]);

  const formValidation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";
    register("buildingName", {
      required: requiredMessage,
      // maxLength: { value: 30, message: "30자 이상 입력 불가능 합니다." },
    });
    register("zonecode", {
      required: requiredMessage,
    });
    register("address", {
      required: requiredMessage,
    });
    register("locationCode", {
      required: requiredMessage,
    });
    register("description", {
      required: requiredMessage,
    });
    register("feature", {
      required: requiredMessage,
    });
    register("floorNums", {
      required: requiredMessage,
    });
    register("undergroundFloorNums", {
      required: requiredMessage,
    });
    register("completionYmd", {
      required: requiredMessage,
    });
    register("buildingStructureTypes", {
      validate: {
        // 건축물 용도 모든 select box 값 선택되어있는지 확인
        allRequired: (v) => {
          const result = v
            .map((v) => {
              return !!v.facility && !!v.use;
            })
            .every((v) => v === true);
          return result || requiredMessage;
        },
      },
    });
    register("exitDirection", {
      required: requiredMessage,
    });
    register("entryStartTimeDate", {
      required: requiredMessage,
    });
    register("entryEndTimeDate", {
      required: requiredMessage,
    });

    register("partnerId", {
      required: requiredMessage,
    });
  }, [register]);

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    formValidation();
  }, [formValidation, dirtyFields]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: BasicInfoFormData, e?: any) => {
    e.preventDefault();
    setSubmitData(data);
    setConfirmModal({ message: "저장하시겠습니까?", isOpen: true });
  };

  const convertBuildingAddModel = useCallback(
    (data: BasicInfoFormData) => {
      // 건물 등록 model
      let buildingAddModel: BuildingAddModel = {
        buildingName: data.buildingName,
        addressList: data.addressData
          ? [Object.assign(data.addressData, { addressDetail: data?.addressDetail })]
          : [],
        locationCode: data.locationCode,
        description: data.description,
        feature: data.feature,
        floorNums: data.floorNums,
        undergroundFloorNums: data.undergroundFloorNums,
        completionYmd: moment(data.completionYmd).format("YYYY-MM-DD"),
        remodelingYmd: data.remodelingYmd,
        buildingStructureTypeList: data.buildingStructureTypes.map(
          (bs: BuildingStructure, index: number) => {
            // 선택한 시설 옵션
            const findFacilityOption: SelectOption | undefined = buildingStructureOptions.find(
              (option: SelectOption) => option.value === bs.facility,
            );
            const findUseOption = (findFacilityOption?.subOptions || []).find(
              (option: SelectOption) => option.value === bs.use,
            );
            // MetaData 타입으로 변경
            const metaData: MetaData = {
              id: bs?.id,
              metaGroup: "BUILDING_STRUCTURE", // 건물 건축물 용도
              metaItem: bs.facility,
              orderNums: index + 1,
              value1: bs.use || undefined,
              value2: findUseOption?.label,
            };
            return metaData;
          },
        ),
        entryStartTime: moment(data.entryStartTimeDate).format("HH:mm"),
        entryEndTime: moment(data.entryEndTimeDate).format("HH:mm"),
        isHoliday: Boolean(data?.isHoliday),
        leasableArea: Number(data.leasableArea),
        leasableAreaNet: Number(data.leasableAreaNet),
        exitDirection: data.exitDirection,
        partnerId: partner?.id,
      };

      // 화장실
      if (data.isUsedRestroom) {
        buildingAddModel.buildingToiletList = data.restrooms.map(
          (restroom: Restroom, index: number) => {
            // MetaItemType: BUILDING_TOILET_{number}
            let metaItem = undefined;
            if (restroom.floor.split("_")[0] === "UNDERGROUND") {
              // 지하 (남녀분리 or 남녀공용)
              metaItem = restroom.type === "SEPARATION" ? "BUILDING_TOILET_1" : "BUILDING_TOILET_3";
            } else if (restroom.floor.split("_")[0] === "FLOOR") {
              // 지상 (남녀분리 or 남녀공용)
              metaItem = restroom.type === "SEPARATION" ? "BUILDING_TOILET_2" : "BUILDING_TOILET_4";
            }
            // 층
            const value1 = restroom.floor.split("_")[2] || restroom.floor.split("_")[1];

            // 남자 화장실 수 or 공용 화장실 수
            let value2 = "";
            // 여자 화장실 수
            let value3 = "";
            if (metaItem === "BUILDING_TOILET_1" || metaItem === "BUILDING_TOILET_2") {
              // 남녀분리
              value2 = String(restroom.manCount || "");
              value3 = String(restroom.womanCount || "");
            } else if (metaItem === "BUILDING_TOILET_3" || metaItem === "BUILDING_TOILET_4") {
              // 남녀공용
              value2 = String(restroom.count || "");
            }
            // MetaData 타입으로 변경
            const metaData: MetaData = {
              id: restroom?.id,
              metaGroup: "BUILDING_TOILET", // 건물 화장실
              metaItem,
              orderNums: index + 1,
              value1,
              value2,
              value3,
            };
            return metaData;
          },
        );
      } else {
        buildingAddModel.buildingToiletList = [];
      }
      // 엘리베이터
      if (data.isUsedElevator) {
        buildingAddModel.passengerElev = Number(data.passengerElev);
        buildingAddModel.freightElev = Number(data.freightElev);
      } else {
        buildingAddModel.passengerElev = undefined;
        buildingAddModel.freightElev = undefined;
      }

      // 냉난방 방식
      if (data.isUsedAirConditioning) {
        buildingAddModel.heatingType = data?.heatingType as HeatingType;
        buildingAddModel.heatingTypeDesc = data?.heatingTypeDesc;
        buildingAddModel.isHeating = data?.isHeating === "true" ? true : false;
        buildingAddModel.heatingFacilityType = data?.heatingFacilityType as HeatingFacilityType;
        buildingAddModel.heatingFacilityTypeDesc = data?.heatingFacilityTypeDesc;
        buildingAddModel.heatingFacilityTypeAddDesc = data?.heatingFacilityTypeAddDesc;
      } else {
        buildingAddModel.heatingType = undefined;
        buildingAddModel.heatingTypeDesc = undefined;
        buildingAddModel.isHeating = undefined;
        buildingAddModel.heatingFacilityType = undefined;
        buildingAddModel.heatingFacilityTypeDesc = undefined;
        buildingAddModel.heatingFacilityTypeAddDesc = undefined;
      }

      return buildingAddModel;
    },
    [partner?.id],
  );

  // Modal 확인 버튼 클릭
  const clickModalConfirm = async () => {
    setConfirmModal({ isOpen: false });
    if (buildingId) {
      // 수정
      await editBuilding();
    } else {
      // 등록
      await addBuilding();
    }
  };

  // 건물 등록
  const addBuilding = useCallback(async () => {
    try {
      setLoadingBar(true);

      if (!submitData) {
        throw Error("저장할 data 없음");
      }

      const data: BasicInfoFormData = submitData;
      const buildingAddModel: BuildingAddModel = convertBuildingAddModel(data);

      // 등록
      const createResult = await createBuilding({
        building: buildingAddModel,
      });
      setLoadingBar(false);

      if (
        createResult.status >= 200 &&
        createResult.status <= 299 &&
        createResult?.data?.data?.content
      ) {
        openToast(`정상적으로 등록 되었습니다.`);
        const detailPath = `${PagePath.building.detail.replace(
          ":id",
          `${createResult?.data?.data?.content}`,
        )}`;
        navigate(detailPath);
      }
    } catch (error) {
      console.error(error);
    }
  }, [setLoadingBar, convertBuildingAddModel, createBuilding, openToast, navigate, submitData]);

  // 건물 수정
  const editBuilding = useCallback(async () => {
    try {
      setLoadingBar(true);

      if (!submitData) {
        throw Error("저장할 data 없음");
      }

      if (!buildingId) {
        throw Error("buildingId 없음");
      }

      const data: BasicInfoFormData = submitData;
      const buildingAddModel: BuildingAddModel = convertBuildingAddModel(data);

      // 규모 범위의 층(위치)가 사용되고있는지 확인
      const range = _.range(
        Number(buildingAddModel.undergroundFloorNums || 0) === 0
          ? 0
          : Number(buildingAddModel.undergroundFloorNums || 0) * -1,
        Number(buildingAddModel.floorNums || 0) + 1,
      );

      // 건물 > 프로퍼티 > 층 규모 범위에서 사용중인지 확인
      const isIncludeFloorRange = (building?.buildingFloorList || []).every(
        (floor: BuildingFloorModel) => range.includes(Number(floor.floorNum || 0)),
      );
      if (!isIncludeFloorRange) {
        setAlertModal({ isOpen: true, message: "사용중인 층(위치) 정보가 있습니다." });
        return;
      }

      // 건물 > 공용공간 > 회의실 > 위치 규모 범위에서 사용중인지 확인
      const isIncludeMeetingRoomRange = (
        building?.buildingCommonFacility?.meetingRoomList || []
      ).every((meetingRoom: CommonFacilityModel) => {
        const floor =
          Number(meetingRoom.floorNum || 0) === 0
            ? 0
            : Number(meetingRoom.floorNum || 0) * (meetingRoom.isGround ? 1 : -1);
        return range.includes(floor);
      });
      if (!isIncludeMeetingRoomRange) {
        setAlertModal({ isOpen: true, message: "사용중인 층(위치) 정보가 있습니다." });
        return;
      }

      // 건물 > 공용공간 > 좌석그룹 > 위치 규모 범위에서 사용중인지 확인
      const isIncludeDeskGroupRange = (
        building?.buildingCommonFacility?.deskSpace?.deskGroupList || []
      ).every((deskGroup: BuildingCommonFacilityDeskGroupModel) => {
        const floor =
          Number(deskGroup.floorNum || 0) === 0
            ? 0
            : Number(deskGroup.floorNum || 0) * (deskGroup.isGround ? 1 : -1);
        return range.includes(floor);
      });
      if (!isIncludeDeskGroupRange) {
        setAlertModal({ isOpen: true, message: "사용중인 층(위치) 정보가 있습니다." });
        return;
      }

      // 건물 > 공용공간 > 좌석그룹 > 위치 규모 범위에서 사용중인지 확인
      const isIncludeRefreshRoomRange = (
        building?.buildingCommonFacility?.refreshRoomList || []
      ).every((refreshRoom: CommonFacilityModel) => {
        const floor =
          Number(refreshRoom.floorNum || 0) === 0
            ? 0
            : Number(refreshRoom.floorNum || 0) * (refreshRoom.isGround ? 1 : -1);
        return range.includes(floor);
      });
      if (!isIncludeRefreshRoomRange) {
        setAlertModal({ isOpen: true, message: "사용중인 층(위치) 정보가 있습니다." });
        return;
      }

      // 수정
      const buildingEditModel: BuildingEditModel = Object.assign(
        {
          id: buildingId,
          buildingName: data.buildingName,
        },
        buildingAddModel,
      );
      const updateResult = await updateBuilding({
        building: buildingEditModel,
      });
      setLoadingBar(false);

      if (
        updateResult.status >= 200 &&
        updateResult.status <= 299 &&
        updateResult?.data?.data?.content
      ) {
        openToast(`정상적으로 수정 되었습니다.`);
        const detailPath = `${PagePath.building.detail.replace(
          ":id",
          `${updateResult?.data?.data?.content}`,
        )}`;
        navigate(detailPath);
      }
    } catch (error) {
      console.error("editBuilding error", error);
    } finally {
      setLoadingBar(false);
    }
  }, [
    setLoadingBar,
    convertBuildingAddModel,
    updateBuilding,
    openToast,
    building,
    buildingId,
    navigate,
    submitData,
  ]);

  // 등록, 수정 에러 모달 띄우기
  const openUpsertBuildingErrorModal = useCallback(
    (errorResponse: AxiosResponse<ApiResponse<BuildingUpdateResponseData>>) => {
      // 에러발생시 중복체크 에러일 경우 메세지 alert
      if (errorResponse?.data?.meta?.errorCode === "ePR0551") {
        setFocus("addressDetail");
        setAlertModal({ isOpen: true, message: "주소가 중복입니다." });
      } else if (errorResponse?.data?.meta?.errorCode === "ePR0552") {
        setFocus("buildingName");
        setAlertModal({ isOpen: true, message: "건물 명이 중복입니다." });
      } else if (errorResponse?.data?.meta?.errorCode === "ePR0553") {
        setFocus("locationCode");
        setAlertModal({ isOpen: true, message: "Location code 가 중복입니다." });
      } else {
        setAlertModal({
          isOpen: true,
          message: errorResponse?.data?.meta?.errorMessage || "에러가 발생했습니다.",
        });
      }
    },
    [setFocus],
  );

  useEffect(() => {
    // 등록 에러 발생시
    if (createBuildingError) {
      console.log(`createBuildingError`, createBuildingError);
      // 에러 메세지 모달 띄우기
      openUpsertBuildingErrorModal(createBuildingError);
      // error clear
      setCreateBuildingError(undefined);
    }
  }, [createBuildingError, setCreateBuildingError, openUpsertBuildingErrorModal]);

  useEffect(() => {
    // 수정 에러 발생시
    if (updateBuildingError) {
      console.log(`updateBuildingError`, updateBuildingError);
      // 에러 메세지 모달 띄우기
      openUpsertBuildingErrorModal(updateBuildingError);
      // error clear
      setUpdateBuildingError(undefined);
    }
  }, [updateBuildingError, setUpdateBuildingError, openUpsertBuildingErrorModal]);

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  return (
    <div className="contents-container__wrap">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__wrap-contents">
          <ContentsIdSection title="기본정보" id={buildingId} />
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">파트너</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <span className={partner && `mr10`}>
                  {partner && `${partner?.name} (${partner.code})`}
                </span>
                {!buildingId && (
                  <BaseButton
                    title="파트너 선택"
                    className="color-white"
                    onClick={() => {
                      if (partner) {
                        setPartnerModal({
                          isOpen: true,
                          message: "파트너를 변경하시면 입력하신 정보가 초기화됩니다.",
                        });
                      } else {
                        setPartnerModal({ isOpen: true });
                      }
                    }}
                  />
                )}
              </div>
              <p className="validation-text">{errors.partnerId?.message}</p>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">건물 명</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <Controller
                  control={control}
                  name="buildingName"
                  render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                    <BaseInput
                      inputRef={ref}
                      placeholder="건물 명을 입력해 주세요"
                      className="mr8"
                      type="text"
                      onChange={onChange}
                      value={value}
                      name={name}
                      errorText={error?.message}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">주소</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400 d-flex flex-row mb10">
                <Controller
                  control={control}
                  name="addressData"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <DaumAddress
                      setAddress={(newAddressData: Address) => {
                        const currentAddressData = getValues("addressData");
                        const currentAddressId = currentAddressData?.id;
                        // 주소 수정의 경우,기존의  주소 id 가 있어야 정상적으로 수정되기 때문에 추가
                        const addressDataWithId = {
                          ...newAddressData,
                          ...(currentAddressId && { id: currentAddressId }),
                        };
                        setValue("addressData", addressDataWithId, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                        setValue("zonecode", newAddressData?.zonecode || "", {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                        setValue("address", newAddressData?.address || "", {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                    />
                  )}
                ></Controller>
                <Controller
                  control={control}
                  name="zonecode"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseInput
                      placeholder="우편번호"
                      type="text"
                      onChange={onChange}
                      value={value}
                      name={name}
                      readonly
                    />
                  )}
                ></Controller>
              </div>
              <div className="minmax400 d-flex flex-row mb10">
                <Controller
                  control={control}
                  name="address"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseInput
                      placeholder="주소"
                      type="text"
                      onChange={onChange}
                      value={value}
                      name={name}
                      readonly
                    />
                  )}
                ></Controller>
              </div>
              <div className="minmax400 d-flex flex-row">
                <Controller
                  control={control}
                  name="addressDetail"
                  render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                    <BaseInput
                      inputRef={ref}
                      type="text"
                      onChange={onChange}
                      value={value}
                      name={name}
                      placeholder="상세주소"
                    />
                  )}
                ></Controller>
              </div>
              {(errors.zonecode || errors.address || errors.addressDetail) && (
                <p className="validation-text">
                  {errors.zonecode?.message ||
                    errors.address?.message ||
                    errors.addressDetail?.message}
                </p>
              )}
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">Location code</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <Controller
                  control={control}
                  name="locationCode"
                  render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                    <BaseInput
                      inputRef={ref}
                      className="mr8"
                      type="text"
                      onChange={onChange}
                      value={value}
                      name={name}
                      errorText={error?.message}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">소개</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseTextarea
                      placeholder="건물의 소개글을 남겨주세요"
                      className="mr8"
                      onChange={onChange}
                      value={value}
                      name={name}
                      maxLength={1000}
                      errorText={error?.message}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">지리적 특징</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <Controller
                  control={control}
                  name="feature"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseTextarea
                      placeholder="지리적 특징을 남겨주세요"
                      className="mr8"
                      onChange={onChange}
                      value={value}
                      name={name}
                      maxLength={1000}
                      errorText={error?.message}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">규모</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400 flex-row align-items-center">
                <span className="font14 mx10 no-wrap">지상</span>
                <Controller
                  control={control}
                  name="floorNums"
                  render={({ field: { onChange, value, name } }) => (
                    <BaseSelect
                      className="mr10"
                      value={value}
                      name={name}
                      stateOptions={Array.from(Array(30).keys()).map((i: number) =>
                        Object.assign({ label: i + 1, value: i + 1 }),
                      )}
                      setStateValue={onChange}
                    />
                  )}
                ></Controller>
                <span className="font14 mx10 no-wrap">지하</span>
                <Controller
                  control={control}
                  name="undergroundFloorNums"
                  render={({ field: { onChange, value, name } }) => (
                    <BaseSelect
                      value={value}
                      name={name}
                      stateOptions={Array.from(Array(11).keys()).map((i: number) =>
                        Object.assign({ label: i, value: i }),
                      )}
                      setStateValue={onChange}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">사용 승인일</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <Controller
                  control={control}
                  name="completionYmd"
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <BaseDatePicker
                        selectedDate={value ? moment(value).toDate() : null}
                        setDate={onChange}
                      />
                    );
                  }}
                ></Controller>
                {errors.completionYmd?.message && (
                  <p className="validation-text">{errors.completionYmd.message}</p>
                )}
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">리모델링 년도</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <Controller
                  control={control}
                  name="remodelingYmd"
                  render={({ field: { onChange, value, name } }) => (
                    <BaseSelect
                      className="mr10"
                      value={`${value || 1}`}
                      name={name}
                      stateOptions={years}
                      setStateValue={onChange}
                    />
                  )}
                ></Controller>
                {errors.remodelingYmd?.message && (
                  <p className="validation-text">{errors.remodelingYmd.message}</p>
                )}
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">건축물 용도</p>
            </div>
            <div className="contents-container__grid-contents">
              <div>
                <Controller
                  control={control}
                  name="buildingStructureTypes"
                  render={({ field: { onChange, value, name } }) => (
                    <BuildingStructures
                      buildingStructureTypes={value}
                      buildingStructureOptions={buildingStructureOptions}
                      setBuildingStructureTypes={onChange}
                    />
                  )}
                ></Controller>
                {errors.buildingStructureTypes && (
                  <p className="validation-text">필수입력항목입니다</p>
                )}
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">출입시간</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400 flex-row align-items-center">
                <Controller
                  control={control}
                  name="entryStartTimeDate"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                    return (
                      <BaseDatePicker
                        timeIntervals={30}
                        type="time"
                        selectedDate={value}
                        setDate={onChange}
                        name={name}
                      />
                    );
                  }}
                ></Controller>
                <span className="font14 mx10">~</span>
                <Controller
                  control={control}
                  name="entryEndTimeDate"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseDatePicker
                      className="mr10"
                      timeIntervals={30}
                      type="time"
                      selectedDate={value}
                      setDate={onChange}
                      name={name}
                    />
                  )}
                ></Controller>
                <Controller
                  control={control}
                  name="isHoliday"
                  render={({ field: { onChange, value, name } }) => (
                    <BaseCheckbox
                      id={name}
                      label="공휴일"
                      name={name}
                      checked={value}
                      onChange={onChange}
                    />
                  )}
                ></Controller>
              </div>
              {(errors.entryStartTimeDate?.message || errors.entryEndTimeDate?.message) && (
                <p className="validation-text">
                  {errors.entryStartTimeDate?.message || errors.entryEndTimeDate?.message}
                </p>
              )}
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <div className="flex-center">
                <p className="">기준 층 임대면적</p>
                <div className="tooltip">
                  <div className="tooltip__trigger">
                    <div className="tooltip__trigger-icon"></div>
                  </div>
                  <div className="tooltip__contents">임대차 계약 상 전유 면적 기준</div>
                </div>
              </div>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400 justify-contents-start align-items-center flex-row">
                <div className="minmax200 mr10">
                  <Controller
                    control={control}
                    name="leasableArea"
                    render={({ field: { onChange, value, name } }) => (
                      <BaseInput type="number" onChange={onChange} value={value} name={name} />
                    )}
                  ></Controller>
                </div>
                <span className="font12">
                  m<sup>2</sup>
                </span>
                <div className="base-chip ml20">
                  <span>{getValues("leasableAreaPyeong")}평</span>
                </div>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <div className="flex-center">
                <p className="">기준 층 전용면적</p>
                <div className="tooltip">
                  <div className="tooltip__trigger">
                    <div className="tooltip__trigger-icon"></div>
                  </div>
                  <div className="tooltip__contents">임대차 계약 상 면적 기준</div>
                </div>
              </div>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400 justify-contents-start align-items-center flex-row">
                <div className="minmax200 mr10">
                  <Controller
                    control={control}
                    name="leasableAreaNet"
                    render={({ field: { onChange, value, name } }) => (
                      <BaseInput type="number" onChange={onChange} value={value} name={name} />
                    )}
                  ></Controller>
                </div>
                <span className="font12">
                  m<sup>2</sup>
                </span>
                <div className="base-chip ml20">
                  <span>{getValues("leasableAreaNetPyeong")}평</span>
                </div>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <div className="flex-center">
                <p className="">기준 층 전용률</p>
                <div className="tooltip">
                  <div className="tooltip__trigger">
                    <div className="tooltip__trigger-icon"></div>
                  </div>
                  <div className="tooltip__contents">
                    (임대차계약상전유면적÷임대차계약상면적)✕100, 소숫점2이상버림=전용율
                  </div>
                </div>
              </div>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400 justify-contents-start align-items-center flex-row">
                <div className="minmax200 mr10">
                  <Controller
                    control={control}
                    name="rateOfUse"
                    render={({ field: { onChange, value, name } }) => (
                      <BaseInput
                        type="text"
                        value={value}
                        name={name}
                        onChange={onChange}
                        readonly
                      />
                    )}
                  ></Controller>
                </div>
                <span className="font12">%</span>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">주 출입구 방향</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax200 flex-row align-items-center">
                <Controller
                  control={control}
                  name="exitDirection"
                  render={({ field: { onChange, value, name } }) => (
                    <BaseSelect
                      stateOptions={exitDirectionOptions}
                      setStateValue={onChange}
                      value={value}
                      name={name}
                    />
                  )}
                ></Controller>
              </div>
              {errors.exitDirection?.message && (
                <p className="validation-text">{errors.exitDirection.message}</p>
              )}
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>화장실</p>
            </div>
            <div className="contents-container__grid-contents">
              <Restrooms
                control={control}
                getValues={getValues}
                setValue={setValue}
                floorOptions={restroomFloorOptions}
                restroomTypeOptions={restroomTypeOptions}
                restroomCountOptions={restroomCountOptions}
              />
            </div>
          </section>
        </div>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>엘리베이터</p>
          </div>
          <div className="contents-container__grid-contents">
            <Elevator
              control={control}
              passengerElevOptions={passengerElevOptions}
              freightElevOptions={freightElevOptions}
            />
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>냉난방 방식</p>
          </div>
          <div className="contents-container__grid-contents">
            <AirConditioning
              control={control}
              getValues={getValues}
              setValue={setValue}
              heatingTypeOptions={heatingTypeOptions}
              isHeatingOptions={isHeatingOptions}
              heatingFacilityTypeOptions={heatingFacilityTypeOptions}
            />
          </div>
        </section>

        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              className="color-white size-large"
              onClick={() => {
                navigate(PagePath.building.list);
              }}
            />
          </div>
          <div className="right-area">
            <BaseButton type="submit" title="저장" className="size-large" />
          </div>
        </div>
      </form>

      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
      >
        <p>{confirmModal.message}</p>
      </BaseModal>

      {/* 확인버튼만 있는 alert 모달 */}
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        btnLeftTitle=""
        onClick={() => setAlertModal({ isOpen: false })}
      >
        <p>{alertModal.message}</p>
      </BaseModal>

      <BaseModal
        isOpen={partnerChangeModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClick={() => {
          reset();
          setPartner(partnerChangeModal.payload);
          setValue("partnerId", partnerChangeModal.payload.id);
          setPartnerChangeModal({ isOpen: false });
          setPartnerModal({ isOpen: false });
        }}
        onClose={() => setPartnerChangeModal({ isOpen: false })}
      >
        {partnerChangeModal.message}
      </BaseModal>

      {partnerModal.isOpen && (
        <PartnerSelectModal
          onCanceled={() => setPartnerModal({ isOpen: false })}
          onAdded={(selected) => {
            const selectedPartner = selected[0];

            if (partner && partner.id !== selectedPartner.id) {
              setPartnerChangeModal({
                isOpen: true,
                message: "파트너를 변경하시면 입력하신 정보가 초기화됩니다.",
                payload: selectedPartner,
              });
              setPartnerModal({ isOpen: false });
            } else {
              setPartner(selectedPartner);
              setValue("partnerId", selectedPartner.id);
              setPartnerModal({ isOpen: false });
            }
          }}
          defaultValues={partner !== undefined ? [partner] : []}
        />
      )}
    </div>
  );
};

export default BasicInfoForm;
