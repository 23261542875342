import { showHourMinute } from "src/utils";

const TableTextWithBuildingFacilityLimit = ({ title, product, type }: any) => {
  const productDetail = product;

  let text = "";
  let totalTime = "";
  let totalTimeText = "총예약시간(월): ";
  // let '예약가능날짜(제한없음)'
  let maxTime = "";
  let maxTimeText = "예약최대시간(일): ";

  switch (type) {
    case "meetingRoom":
      totalTime = productDetail?.meetingRoomMaxReservTimeMonth;
      maxTime = productDetail?.meetingRoomMaxReservTimeDay;

      break;
    case "deskSpace":
      totalTime = productDetail?.deskMaxReservTimeMonth;
      maxTime = productDetail?.deskMaxReservTimeDay;
      break;
    case "refreshRoom":
      totalTime = productDetail?.refreshRoomMaxReservTimeMonth;
      maxTime = productDetail?.refreshRoomMaxReservTimeDay;
      break;
  }

  text =
    totalTimeText +
    totalTime +
    "분" +
    (totalTime ? "(" + showHourMinute(totalTime) + ") / " : " / ") +
    maxTimeText +
    maxTime +
    "분" +
    (maxTime ? "(" + showHourMinute(maxTime) + ")" : "");

  return (
    <>
      <tr>
        <td width="180">
          <div className="flex-center pl16">
            <span className="text-primary3">{title}</span>
          </div>
        </td>
        <td>
          <div className="w-100 flex-center-start">{text}</div>
        </td>
      </tr>
    </>
  );
};
export default TableTextWithBuildingFacilityLimit;
