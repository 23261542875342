import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Cell,
  useBlockLayout,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { PageMeta, Sort } from "src/api/public-types";
import { BaseInput, BaseModal, BasePagination } from "src/components";
import TableRadioButton from "src/pages/product/components/TableRadioButton";

type ProviderQueryParams = {
  page?: number;
  size?: number;
  sort?: Sort;
  searchValue?: string;
  supplyType?: string;
};

type Props = {
  onSelected: (management: ProviderModel) => void;
  onClose: Function;
  partnerId: string;
};

const ManagementListPopup = ({ onSelected, onClose, partnerId }: Props) => {
  // 정산정보 목록
  const [providers, setProviders] = useState<Array<ProviderModel>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  // pagination 숫자
  const [pageNum, setPageNum] = useState(0);

  const [selectedManagement, setSelectedManagement] = useState<ProviderModel>();

  const queryParams: ProviderQueryParams = useMemo(() => {
    const _queryParams = {
      page: 0,
      size: 20,
      partnerId,
      sort: {
        orders: [{ property: "providerId", direction: "DESC" }],
      },
      supplyType: "MNT",
    };

    return _queryParams;
  }, [partnerId]);

  const [params, setParams] = useState<ProviderQueryParams>({ ...queryParams });

  // 정산정보 목록 api
  const { executeAsync: getProviers } = useApiOperation(getProviderList);

  // 접수자 팝업 테이블 Columns
  const ProviderColumns: any[] = useMemo(() => {
    return [
      {
        Header: "id",
        accessor: "id",
        width: 180,
        Cell: ({ row }: Cell<ProviderModel>) => {
          return (
            <div className="cursor-pointer text-hilight" onClick={() => {}}>
              <span>{row?.original?.providerId}</span>
            </div>
          );
        },
      },

      {
        Header: "법인명",
        accessor: "providerName",
        width: 400,
      },
      {
        Header: "사업자 등록번호",
        accessor: "businessRegistrationNumber",
        width: 200,
      },
    ];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: ProviderColumns,
      data: providers,
    },
    useBlockLayout,
    useGlobalFilter,
    useSortBy,
    useGlobalFilter,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          id: "selection",
          width: 120,
          Header: () => <div className="">선택</div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
            let currentState = row.getToggleRowSelectedProps();
            return (
              <div className="minmax100">
                <TableRadioButton
                  id={row.id}
                  onClick={() => {
                    const selected = row.original;
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id, true);
                    setSelectedManagement(selected);
                  }}
                  name="selectedOrg"
                  // value={}
                  {...currentState}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  const getProviderListApi = useCallback(
    async (param) => {
      const response = await getProviers(param);
      if (response.status >= 200 && response.status <= 299) {
        setProviders(response.data.data.content);
        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getProviers],
  );

  useEffect(() => {
    getProviderListApi(queryParams);
  }, [getProviderListApi, queryParams]);

  const onSearchAndPagination = async (passParams: ProviderQueryParams) => {
    const newPassParam = { ...params, ...(passParams || {}) };
    setPageNum(Number(newPassParam.page));

    const response = await getProviers(newPassParam);
    if (response.status >= 200 && response.status <= 299) {
      setProviders(response.data.data.content);
      setPageMeta(response.data.meta.pageMeta);
    }
  };

  return (
    <BaseModal
      isOpen={true}
      className="dialog-modal"
      onClick={() => onSelected(selectedManagement || {})}
      onClose={() => onClose()}
      btnLeftTitle="취소"
      btnRightTitle="선택"
    >
      <>
        <div className="modal-title">
          <div className="left-area">
            <span>정산정보 관리처 선택</span>
          </div>
          <div className="d-flex">
            <div className="minmax200 mr10">
              <BaseInput
                placeholder="검색어를 입력하세요"
                onChange={(searchValue: string) => setParams({ ...params, searchValue })}
                value={params.searchValue}
                onKeyUp={() => {
                  onSearchAndPagination({ page: 0 });
                }}
                onSearchClick={() => {
                  onSearchAndPagination({ page: 0 });
                }}
              />
            </div>
          </div>
        </div>
        <div className="contents-container__table">
          <div {...getTableProps()} className="base-table sticky ">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div
                        {...header.getHeaderProps(header.getSortByToggleProps())}
                        className="base-table__th"
                      >
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            {providers.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {rows.map((row, idx: number) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} className="base-table__tr">
                      {row.cells.map((cell) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td ">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                <p className="mt10">관리처가 없습니다.</p>
              </>
            )}
          </div>
          <div style={{ padding: "-20px" }}>
            <BasePagination
              pageIndex={pageNum || 0}
              totalPages={pageMeta?.totalPages || 0}
              goPage={(page: number) => {
                onSearchAndPagination({ page });
              }}
            />
          </div>
        </div>
      </>
    </BaseModal>
  );
};

export default ManagementListPopup;
