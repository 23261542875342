/* eslint-disable react/jsx-no-undef */
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  InsertAdminMemo,
  Memo,
  ServiceTypes,
  UpdateAdminMemo,
} from "src/api/adminMemo/adminmemo-types";
import { BaseButton, BaseTextarea } from "src/components";
import GoToListButton from "../GoToListButton";

import { AdminMemoIntegrationFormData } from "./adminmemo-types";
import AdminMemoTable from "./AdminMemoTable";
import useAdminMemo from "./useAdminMemo";

/**
 * 관리자 메모 공통 사용방법 정의
 * <AdminMemoIntegration
 * serviceId={Number(detailData?.contract?.contractId)}  // 해당 서비스 ID로 BE와 논의 된 ID 사용 ex> 계약의 경우 contractId
 * serviceType={ServiceTypes.SERVICE_CONTRACT} // 서비스 타입 지정 ServiceTypes enum 참조할 것
 * viewOnly={true}  // 기본정보에서 최근 3건만 수정/삭제 모드 없이 사용 , 관리자 메모 페이지에서 사용
 * />
 */

type Props = {
  serviceId: number;
  serviceType: ServiceTypes;
  viewOnly?: boolean;
};
const AdminMemoIntegration = ({ serviceId, serviceType, viewOnly }: Props) => {
  const { partnerId } = useParams();
  const { getList, add, edit, delMemo } = useAdminMemo();

  const [memoList, setMemoList] = useState<Array<Memo>>([]);

  const {
    setValue,
    register,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<AdminMemoIntegrationFormData>({
    defaultValues: {
      id: "",
      memo: "",
      serviceId,
      serviceType,
    },
  });

  //API에서 페이징 처리가 안되기 때문에 viewOnly일 경우 내려온 데이터에서 임의로 3개로 제한함
  const callList = useCallback(async () => {
    const list = await getList(Number(serviceId), serviceType, viewOnly);
    if (list.status > 199 && list.status < 300) {
      if (viewOnly === undefined || viewOnly === false) {
        setMemoList(list.data.data.content);
      } else {
        setMemoList(list.data.data.content.slice(0, 3));
      }
    }
  }, [getList, serviceId, serviceType, viewOnly]);

  useEffect(() => {
    if (serviceId) {
      callList();
    }
  }, [serviceId, viewOnly]);

  // 관리자 메모 input 유효성
  const formVaildation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";
    register("memo", {
      required: { value: true, message: requiredMessage },
    });
  }, [register]);

  //  관리자 메모 등록/수정
  const onSubmit = useCallback(
    async (data: AdminMemoIntegrationFormData) => {
      if (getValues("id") === "") {
        const addMemoData: InsertAdminMemo = {
          partnerId: partnerId ? String(partnerId) : "0",
          serviceId: String(serviceId),
          memo: data.memo,
          serviceType,
        };
        const response = await add(addMemoData);
        if (response.status >= 200 && response.status <= 299) {
          callList();
          setValue("memo", "");
          setValue("id", "");
        }
      } else {
        const editMemoData: UpdateAdminMemo = {
          id: String(data.id),
          partnerId: partnerId ? String(partnerId) : "0",
          serviceId: String(data.serviceId),
          memo: data.memo,
          serviceType,
        };

        const response = await edit(editMemoData);
        if (response.status >= 200 && response.status <= 299) {
          callList();
          setValue("memo", "");
          setValue("id", "");
        }
      }
    },
    [add, callList, edit, getValues, partnerId, serviceId, serviceType, setValue],
  );

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  return (
    <>
      {viewOnly === undefined ||
        (viewOnly === false && (
          <>
            <div className="contents-container__wrap-contents pt30 px30">
              {(viewOnly === undefined || viewOnly === false) && (
                <article className="contents-container__wrap-article">
                  <div className="contents-container__sub-title">
                    <h2>등록하기</h2>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <section className="contents-container__1070">
                      <div className="flex-row flex-start">
                        <Controller
                          control={control}
                          name="memo"
                          render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <>
                              <BaseTextarea
                                className="admin-memo mr8"
                                name={name}
                                value={value}
                                placeholder="메모를 남길 수 있습니다."
                                maxLength={1000}
                                onChange={onChange}
                                // errorText={error?.message}
                              />
                              <div className="h-100">
                                <BaseButton
                                  title="저장"
                                  type="submit"
                                  className="size-fixed mb8"
                                  onClick={formVaildation}
                                />
                                <BaseButton
                                  title="취소"
                                  className="color-white size-fixed "
                                  onClick={() => {
                                    setValue("memo", "");
                                  }}
                                />
                              </div>
                            </>
                          )}
                        ></Controller>
                      </div>
                      {errors && errors.memo && (
                        <p className="validation-text">{errors.memo.message}</p>
                      )}
                    </section>
                  </form>
                </article>
              )}
              <article className="contents-container__wrap-article pt30">
                <div className="contents-container__sub-title">
                  <h2>내역보기</h2>
                </div>
                <AdminMemoTable
                  data={memoList}
                  callList={callList}
                  delMemo={delMemo}
                  setValue={setValue}
                  viewOnly={viewOnly}
                />
              </article>
            </div>
            {(viewOnly === undefined || viewOnly === false) && (
              <div className="contents-container__btn-wrap px30">
                <div className="left-area">
                  <GoToListButton />
                </div>
                <div className="right-area"></div>
              </div>
            )}
          </>
        ))}

      {/* 기본정보 3개 줄만 보이는 영역 */}
      {viewOnly === true && (
        <section>
          <div className="contents-container__sub-title">
            <h2>관리자 메모</h2>
          </div>
          <AdminMemoTable data={memoList} setValue={setValue} viewOnly={viewOnly} />
        </section>
      )}
    </>
  );
};
export default AdminMemoIntegration;
