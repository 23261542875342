import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Cell, Column } from "react-table";
import { AuditLogDto, logPrintName, renderLogMessage } from "src/api/log/log-type";
import { Modal } from "src/api/public-types";
import PagePath from "src/pagePath.json";
import { YmdFormat } from "src/utils";
import { BaseAbstractModal } from "../BaseAbstractModal";
import { BaseButton } from "../BaseButton";
import { LogLabelModal } from "./LogLabelModal";

export const logColumn: any = [
  {
    Header: "시간",
    accessor: "revisionTime",
    width: 150,
    Cell: ({ value }: Cell<AuditLogDto>) => {
      return <p>{moment(value).format(YmdFormat.FULL)}</p>;
    },
  },
  {
    Header: "구분",
    accessor: "category",
    width: 140,
    Cell: ({ value }: Cell<AuditLogDto>) => {
      return <p className="text-left w-100">{value ? logPrintName[value] : ""}</p>;
    },
  },
  {
    Header: "메시지",
    accessor: "message",
    width: 412,
    Cell: ({ value }: Cell<AuditLogDto>) => {
      const [labelModal, setLabelModal] = useState<Modal>({ isOpen: false });

      return (
        <div className="text-left w-100">
          {value ? renderLogMessage(value.type, value.data, setLabelModal) : "-"}
          {labelModal.isOpen && (
            <LogLabelModal type={value.type} data={value.data} setLabelModal={setLabelModal} />
          )}
        </div>
      );
    },
  },
  {
    Header: "사용자",
    accessor: "user",
    width: 235,
    Cell: ({ value }: Cell<AuditLogDto>) => {
      return <p className="w-100 text-left">{value.displayName}</p>;
    },
  },
];
