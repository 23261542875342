import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { ProductModel } from "src/api/product/product-types";
import { getPartnerDetailPage } from "src/pages/partner/utils";
import IsUsedCell from "./IsUsedCell";
import ProductDateCell from "./ProductDateCell";
import ProductDepositCell from "./ProductDepositCell";
import ProductRentalCostCell from "./ProductRentalCostCell";
import { ProductTypeCell } from "./ProductTypeCell";
import { SpaceProductNameCell } from "./SpaceProductNameCell";
import { StatusCell } from "./StatusCell";

/* 
  상품 테이블 columns
*/
const ProductColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: (props: Cell<ProductModel>) => <SpaceProductNameCell {...props} />,
  },

  {
    Header: "상품명",
    accessor: "productName",
    width:240,
    Cell: (props: Cell<ProductModel>) => <div className="w-100 text-left"><SpaceProductNameCell {...props} /></div>,
  },
  // {
  //   Header: "상품 유형",
  //   accessor: "productCategory",
  //   width: 120,
  //   Cell: (props: Cell<ProductModel>) => (
  //     <div>{PRODUCT_CATEGORY_MAP[props.value as keyof typeof PRODUCT_CATEGORY_MAP]}</div>
  //   ),
  // },
  {
    Header: "상품 유형",
    accessor: "productType",
    width: 90,
    Cell: (props: Cell<ProductModel>) => <ProductTypeCell {...props} />,
  },
  {
    Header: "보증금",
    accessor: "saleDeposit",
    Cell: (props: Cell<ProductModel>) => <ProductDepositCell {...props} />,
    width: 100,
  },
  {
    Header: "이용료",
    accessor: "rentalCost",
    Cell: (props: Cell<ProductModel>) => <ProductRentalCostCell {...props} />,
    width: 120,
  },

  {
    Header: "공개여부",
    accessor: "status",
    Cell: (props: Cell<ProductModel>) => <StatusCell {...props} />,
    width: 85,
  },
  {
    Header: "이용여부",
    accessor: "isUsed",
    width: 90,

    Cell: (props: Cell<ProductModel>) => <IsUsedCell {...props} />,
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 170,
    Cell: ({value}: Cell<ProductModel>) => 
    <div className="w-100 text-left"><p>{value}</p></div>,
  },
  {
    Header: "주소",
    accessor: "address",
    width: 250,
    Cell: ({value}: Cell<ProductModel>) => 
    <div className="w-100 text-left"><p>{value}</p></div>,
  },
  {
    Header: "등록ID",
    accessor: "createdBy",
    width: 150,
    Cell: ({ value }: Cell<ProductModel>) => <div className="w-100 text-left ellipsis2">{value}</div>,
  },
  {
    Header: "생성일",
    accessor: "createdDate",
    Cell: (props: Cell<ProductModel>) => {
      return <ProductDateCell {...props} />;
    },
    width: 90,
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    Cell: (props: Cell<ProductModel>) => <ProductDateCell {...props} />,
    width: 100,
  },
  {
    Header: "파트너",
    accessor: "partnerId",
    Cell: (props: Cell<ProductModel>) => {
      if (props.value === "0") return <>-</>;

      return (
        <Link to={getPartnerDetailPage(props.value)} className="text-hilight" target={"_blank"}>
          {props.value}
        </Link>
      );
    },
    width: 100,
  },
];

export default ProductColumns;
