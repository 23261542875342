import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { YmdFormat } from "src/utils";
import { PagePath } from "src/pages/product/details";
const MemberRestrictionColumns: any = [
  {
    Header: "id",
    accessor: "index",
    // sticky: "left",
    width: 100,
  },
  {
    Header: "이용 제한 번호",
    accessor: "id",
    // sticky: "left",
    width: 200,
    Cell: (props: any) => {
      const params = useParams();
      // console.log("params", params);
      const memberId = params.memberId ? params.memberId : params.id;
      const detailPath = `${
        PagePath.restriction.detail
          .replace(":id", `${props.row.original.id}`)
          .replace(":memberId", `${memberId}`) + "?tab=MemberRestrictionInfo"
      }`;

      return (
        <div>
          <Link to={detailPath} className="text-hilight">
            #{props?.value || ""}
          </Link>
        </div>
      );
    },
  },
  {
    Header: "변경상태",
    accessor: "status",
    Cell: (props: any) => {
      let rtnValue = "이용제한변경";
      const updatedDate = props.row.original.lastModifiedDate;
      const createdDate = props.row.original.createdDate;

      if (createdDate === updatedDate) {
        rtnValue = "이용제한등록";
      }

      return (
        <>
          <div>{rtnValue}</div>
        </>
      );
    },
    width: 180,
  },
  {
    Header: "등록/변경 일시",
    accessor: "createdDate",
    width: 300,
    Cell: (props: any) => {
      const formatting = YmdFormat.YYYY_MM_DD_HH_MM;
      const updatedDate = props.row.original.lastModifiedDate;
      const createdDate = props.value;

      let returnValue = moment(createdDate).format(formatting);

      if (updatedDate) {
        returnValue = moment(updatedDate).format(formatting);
      }
      return <div>{returnValue}</div>;
    },
  },
  {
    Header: "설정 기간",
    accessor: "inclusiveStartDate",
    width: 300,
    Cell: (props: any) => {
      const inclusiveEndDate = props.row.original.inclusiveEndDate;
      const inclusiveStartDate = props.value;
      let formatting = YmdFormat.YYYY_MM_DD;
      let returnValue = ``;
      if (inclusiveStartDate && inclusiveEndDate) {
        const start = moment(inclusiveStartDate).format(formatting);
        const end = moment(inclusiveEndDate).format(formatting);
        returnValue = `${start} ~ ${end}`;
      }
      return <div>{returnValue}</div>;
    },
  },
  {
    Header: "사유",
    accessor: "comment",
    width: 300,
  },
];
export default MemberRestrictionColumns;
