// 건물 Table Columns
import moment from "moment";
import qs from "qs";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Cell } from "react-table";
import PagePath from "src/pagePath.json";
import { getPartnerDetailPage } from "src/pages/partner/utils";
import { YmdFormat, formatPhoneNumber } from "src/utils";
export const columns = [
  {
    Header: "id",
    accessor: "contractMemberId",
    // sticky: "left",
    width: 100,
    Cell: (props: any) => {
      const location = useLocation();
      const queryParams = useMemo(
        () =>
          qs.parse(location.search, {
            ignoreQueryPrefix: true,
            allowDots: true,
          }),
        [location],
      );

      if (!queryParams.contractManageId) {
        queryParams.contractManageId = props.row.original.contractManageId;
      }

      const partnerId = props.row.cells[props.row.cells.length - 1].value;

      if (!queryParams.partnerId) {
        queryParams.partnerId = partnerId;
      }

      const path =
        PagePath.user.detail.replace(":memberId", props.value) +
        "?" +
        qs.stringify(queryParams, { allowDots: true, encode: true });

      // console.log('path',path);
      return (
        <>
          <Link to={path} className="text-hilight">
            {props.value}
          </Link>
        </>
      );
    },
  },

  {
    Header: "신청번호",
    accessor: "contractApplyNumber",
    width: 120,
  },

  {
    Header: "회원번호",
    accessor: "memberNo",
    width: 120,
  },
  {
    Header: "상태",
    accessor: "memberStatus",
    width: 90,
    Cell: ({ value, row }: any) => {
      let changeValue = value;

      return (
        //visible 녹색
        //invisible 빨간색
        //draft = 회색
        <div className="flex-center">
          <p
            className={`${
              (changeValue === "MEMBER_INVITE" && "chip visible") ||
              (changeValue === "MEMBER_USE" && "chip visible") ||
              (changeValue === "MEMBER_DELETE" && "chip draft") ||
              (changeValue === "MEMBER_CLOSE" && "chip visible") ||
              (changeValue === "MEMBER_EXPIRE" && "chip invisible")
            }`}
          >
            {(changeValue === "MEMBER_INVITE" && "초대") ||
              (changeValue === "MEMBER_USE" && "사용") ||
              (changeValue === "MEMBER_DELETE" && "삭제") ||
              (changeValue === "MEMBER_CLOSE" && "종료") ||
              (changeValue === "MEMBER_EXPIRE" && "만료")}
          </p>
        </div>
      );
    },
  },
  {
    Header: "휴대폰번호",
    accessor: "phoneNumber",
    width: 140,
    Cell: ({ row }: any) => {
      // console.log("row", row.original);
      return (
        <>
          {row.original?.phoneNumber
            ? formatPhoneNumber(row.original?.phoneNumber)
            : row.original?.inviteMobileNumber
            ? formatPhoneNumber(row.original?.inviteMobileNumber)
            : "-"}
        </>
      );
    },
  },
  {
    Header: "이메일",
    accessor: "email",
    width: 140,
    Cell: ({ value }: any) => {
      return <>{value !== undefined ? <div>{value}</div> : "-"}</>;
    },
  },
  {
    Header: "이메일 인증",
    accessor: "isEmailVerified",
    width: 120,
    Cell: ({ value }: any) => {
      return (
        <>
          {value !== undefined ? (
            <div
              className={`${
                (value === true && "chip visible") || (value === false && "chip draft")
              }`}
            >
              {(value === true && "인증") || (value === false && "미인증")}
            </div>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "초대일시",
    accessor: "inviteDate",
    width: 160,
    Cell: ({ value }: any) => {
      return <>{value ? moment(value).format(YmdFormat.FULL) : "-"}</>;
    },
  },
  {
    Header: "가입일시",
    accessor: "signUpTime",
    width: 160,
    Cell: ({ value }: any) => {
      return <>{value ? moment(value).format(YmdFormat.FULL) : "-"}</>;
    },
  },

  {
    Header: "캘린더연동",
    accessor: "isWatchingCalendar",
    width: 140,
    Cell: ({ row }: any) => {
      return (
        <div
          className={`${
            (row.original.isWatchingCalendar === true && "chip visible") ||
            (row.original.isWatchingCalendar === false && "chip draft")
          }`}
        >
          {(row.original.isWatchingCalendar === true && "연동") ||
            (row.original.isWatchingCalendar === false && "미연동")}
        </div>
      );
    },
  },
  {
    Header: "캘린더 동기화",
    accessor: "isExistsIssues",
    width: 140,
    Cell: ({ row }: any) => {
      let text = "-";

      if (row.original.isExistsIssues !== undefined && row.original.isExistsIssues) {
        if (row.original.isResolvable !== undefined && row.original.isResolvable) {
          text = "실패(재 연동 필요)";
        } else {
          text = "실패(관리자 문의)";
        }
      }
      return (
        <div
          className={`${
            row.original.isExistsIssues !== undefined &&
            row.original.isExistsIssues === true &&
            "chip draft"
          }`}
        >
          {text}
        </div>
      );
    },
  },
  {
    Header: "권한",
    accessor: "role",
    width: 140,
    Cell: ({ row }: any) => {
      let roles = "";
      if (row.original.isContractor === true) {
        roles = "계약자 ";
      }
      if (row.original.isAdmin === true) {
        if (roles !== "") {
          roles += ", 마스터";
        } else {
          roles += "마스터";
        }
      }

      return <div>{roles}</div>;
    },
  },
  {
    Header: "파트너",
    accessor: "partnerId",
    width: 140,
    Cell: (
      props: Cell<{
        partnerId: string;
      }>,
    ) => (
      <Link to={getPartnerDetailPage(props.value)} className="text-hilight" target={"_blank"}>
        {props.value}
      </Link>
    ),
  },
];
