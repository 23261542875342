import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { getBuildingsAsync } from "src/api/building/building-api";
import { BuildingListParams, BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { Modal, Order, PageMeta } from "src/api/public-types";
import { BaseButton, BaseInput, BaseSelect, BaseTable } from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext } from "src/pages/hooks";
import { Partner } from "src/types/partner";
import { buildingColumns } from "./columns";

// 공개 상태
const searchStatuses = [
  { value: "", label: "전체" },
  { value: "ENABLED", label: "공개" },
  { value: "DISABLED", label: "비공개" },
];

// 검색 대상
// const searchTargets = [
//   { value: "", label: "전체" },
//   { value: "buildingName", label: "건물명" },
// ];

// url query parameter 타입
type QueryParams = {
  page?: number;
  size?: number;
  status?: string;
  searchTarget?: string;
  keyword?: string;
  isDeleted?: string;
  sort?: {
    orders?: Array<Order>;
  };
  partnerId?: string;
  partnerName?: string;
};

/* 
  건물 > 목록 화면
 */
const BuildingList = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const navigate = useNavigate();
  const location = useLocation();

  // 선택된 파트너 정보
  const [partner, setPartner] = useState<Partner>();

  const [partnerModal, setPartnerModal] = useState<Modal>({
    isOpen: false,
  });

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (_queryParams.isDeleted !== "true" && _queryParams.isDeleted !== "false") {
      _queryParams.isDeleted = "false";
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, [location]);

  // 건물 목록 조회
  const [buildings, setBuildings] = useState<BuildingModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync);

  useEffect(() => {
    async function fetchBuildings(buildingListParams: BuildingListParams) {
      setLoadingBar(true);

      const { data } = await getBuildings(buildingListParams);
      setBuildings(data?.data?.content || []);
      setPageMeta(data?.meta?.pageMeta);

      setLoadingBar(false);
    }

    // 건물 목록 조회후 데이터 바인딩
    fetchBuildings(queryParams);
  }, [queryParams, getBuildings, setLoadingBar]);

  // 받아온 query parameter 로 해당 컴포넌트에서 변경가능하기 위한 state 선언
  const [params, setParams] = useState<QueryParams>({ ...queryParams });

  // 쿼리파라미터 정보와 함께 네비게이션

  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };

      // delete newQueryParams.partnerName;

      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  return (
    <div className="page-product-list">
      {/* <ContentsTitle title="건물" breadCrumbs={[{ value: "building", label: "건물" }]} />/ */}
      <div className="contents-container__search-wrap">
        <div className="left-area">
          {/* <p>
              전체 <span>{pageMeta?.totalElements || 0}</span>
            </p> */}
          <div
            className="minmax200 mr8 cursor-pointer"
            // onClick={() => setPartnerModal({ isOpen: true })}
          >
            <BaseInput
              // className="cursor-pointer"
              readonly
              placeholder="파트너"
              value={(partner?.name && partner?.name) || params.partnerName}
              onSearchClick={() => setPartnerModal({ isOpen: true })}
              onClearClick={() => {
                const editedParams = { ...params };
                delete editedParams.partnerId;
                delete editedParams.partnerName;
                setParams(editedParams);
              }}
            />
          </div>
          <div className="minmax120 mr8">
            <BaseSelect
              placeholder="공개 상태"
              value={params?.status}
              stateOptions={searchStatuses}
              setStateValue={(status: string) => setParams({ ...params, status })}
            />
          </div>
          <BaseInput
            value={params?.keyword || ""}
            className="minmax220 mr8"
            type="text"
            placeholder="건물명을 입력해주세요"
            onChange={(keyword: string) => setParams({ ...params, keyword })}
            onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
            onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
          />
        </div>
      </div>

      <BaseTable
        data={buildings}
        columns={buildingColumns}
        pageIndex={Number(params?.page || 0)}
        totalPages={pageMeta?.totalPages || 0}
        goPage={(page: number) => {
          navigateWithQueryParams({ page }, "pagination");
        }}
        orders={params?.sort?.orders}
        disabledSortHeaders={["size"]}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } }, "search");
          }
        }}
      />
      {partnerModal.isOpen && (
        <PartnerSelectModal
          onCanceled={() => setPartnerModal({ isOpen: false })}
          onAdded={(partner) => {
            setPartner(partner[0]);
            setPartnerModal({ isOpen: false });
            setParams({
              ...params,
              partnerId: String(partner[0].id),
              partnerName: String(partner[0].name),
            });
          }}
          defaultValues={[]}
        />
      )}
    </div>
  );
};
export default BuildingList;
