
// modal 공통
export type Modal = {
  isOpen: boolean;
  message?: string;
  type?: string;
  payload?: any;
};

export const ReservationState = {
  ACCEPTED: "ACCEPTED", // 접수
  ACKNOWLEDGED: "ACKNOWLEDGED", // 점유
  INCOMPLETE: "INCOMPLETE", // 불완전
  CANCELLED: "CANCELLED", // 취소
} as const;

//  예약초대 상태
export const ReservationAttendeeResponseStatus = {
  NONE: 'NONE', //응답없음
  DECLINED: 'DECLINED', //거부
  TENTATIVE: 'TENTATIVE', // 임시적 수락
  ACCEPTED: 'ACCEPTED' //수락
};

// 공용공간 예약 status 한글 변환
export const getStatusText = (status?: string, endDate?: Date) => {
  const accepted = ReservationState.ACCEPTED;
  const cancelled = ReservationState.CANCELLED;
  const acknowledged = ReservationState.ACKNOWLEDGED;
  const incomplete = ReservationState.INCOMPLETE;
  switch (status) {
    case accepted:
      return "접수";
    case acknowledged:
      return "점유";
    case incomplete:
      return "불완전";
    case cancelled:
      return "취소";
    default:
      return "-";
  }
};

export const ExternalServiceType = {
  EXTERNALSERVICE_UNRECOGNIZED: "EXTERNALSERVICE_UNRECOGNIZED", //정의되지 않은 타입
  TAAP: "TAAP",
  GC: "GC", // Google Calendar
  OC: "OC", //Outlook Calendar
} as const;

export type ReservationExternalTypes = "TAAP" | "GC" | "OC";


// 공용공간예약 엑셀 다운로드 헤더
export const reservationHeaders = [
  { label: "id", key: "id" },
  { label: "group id", key: "reservationGroupId" },
  { label: "상품 id", key: "productId" },
  { label: "신청/계약 번호", key: "contractApplyNumber" },
  { label: "예약경로", key: "externalServiceType" },
  { label: "건물명", key: "buildingName" },
  { label: "위치 (층)", key: "buildingCommonFacilityFloorNum" },
  { label: "위치 (지상여부)", key: "buildingCommonFacilityIsGround" },
  { label: "위치 (id)", key: "buildingCommonFacilityId" },
  { label: "위치 (LocationCode)", key: "buildingCommonFacilityLocationCode" },
  { label: "공간타입", key: "commonFacilityType" },
  { label: "공간명", key: "buildingCommonFacilityName" },
  { label: "예약기간 (시작일시)", key: "start" },
  { label: "예약기간 (종료일시)", key: "end" },
  { label: "예약자 (회원번호)", key: "memberNo" },
  { label: "예약자 (이메일)", key: "memberEmail" },
  { label: "예약 제목", key: "summary" },
  { label: "생성일", key: "createdDate" },
  { label: "상태", key: "status" },
];

// 공용공간예약 소진시간 엑셀 다운로드 헤더
export const reservationUsageHeaders = [
  { label: "id", key: "reservationId" },
  { label: "신청/계약 번호", key: "contract.applyNumber" },
  { label: "건물명", key: "facility.building.name" },
  { label: "공간타입", key: "facility.type" },
  { label: "공간id", key: "facility.id" },
  { label: "공간명", key: "facility.name" },
  { label: "예약기간 (시작일시)", key: "start" },
  { label: "예약기간 (종료일시)", key: "end" },
  { label: "예약자 (회원번호)", key: "organizer.memberNo" },
  { label: "예약자 (휴대폰번호)", key: "organizer.phoneNumber" },
  { label: "예약자 (이메일)", key: "organizer.userEmail" },
];
