/* eslint-disable array-callback-return */
import { calculatePyeong } from "src/utils";

const TableTextWithSupTotal = ({ title, array, type }: any) => {
  let count = 0;
  array?.map((list: any, index: number) => {
    list.building.buildingFloorList.map((floor: any) => {
      floor.buildingRoomList.map((room: any) => {
        count += Number(room[type]);
      });
    });
  });

  return (
    <>
      <tr>
        <td width="180">
          <div className="flex-center pl16">
            <span className="text-primary3">{title}</span>
          </div>
        </td>
        <td>
          <div className="w-100 flex-center-start text-left">
            <span>{count}</span>
            <span className="font12">
              m<sup>2</sup>
            </span>
            <div className="base-chip ml16">
              <span>{calculatePyeong(count)} 평</span>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};
export default TableTextWithSupTotal;
