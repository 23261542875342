import moment from "moment";
import { ContractStep } from "src/api/contract/contract-types";

const checkContractStatus = (status: string) => {
  let rtnString = "";
  switch (status) {
    case ContractStep.APPLY_RECEIVED:
      rtnString = "신청접수";
      break;
    case ContractStep.APPLY_CANCEL:
      rtnString = "신청취소";
      break;
    case ContractStep.APPLY_CONFIRM:
      rtnString = "신청확인";
      break;
    case ContractStep.CONTRACT_PLAN:
      rtnString = "계약예정";
      break;
    case ContractStep.CONTRACT_ACCEPT:
      rtnString = "계약체결";
      break;
    case ContractStep.USE_APPROVAL:
      rtnString = "이용승인";
      break;
    case ContractStep.USE_PROGRESS:
      rtnString = "이용중";
      break;
    case ContractStep.USE_COMPLETE:
      rtnString = "이용완료";
      break;
    case ContractStep.TERMINATE_RECEIVED:
      rtnString = "해지접수";
      break;
    case ContractStep.TERMINATE_COMPLETE:
      rtnString = "해지완료";
      break;
    default:
      break;
  }
  return rtnString;
};

const getHours = (start: string, end: string) => {
  let rtnValue = "";

  const st = moment(start);
  const ed = moment(end);

  let duration = moment.duration(ed.diff(st)).asMinutes();

  rtnValue = Math.floor(duration / 60) + "시간" + (duration % 60) + "분";

  return rtnValue;
};

export { checkContractStatus, getHours };
