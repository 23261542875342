import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../public-types";
import { ApiPath } from "src/api";
import qs from "qs";
import { PriceModel, PriceParams } from "./price-types";
/*
  요금 조회
*/
export async function getPriceAsync(
  axios: AxiosInstance,
  params?: PriceParams,
): Promise<AxiosResponse<ApiResponse<{ content: PriceModel[] }>>> {
  return await axios.get<ApiResponse<{ content: PriceModel[] }>>(ApiPath.price.list, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}
/*
  요금 상세 조회
*/
export async function getPriceDetailAsync(
  axios: AxiosInstance,
  params?: PriceParams,
): Promise<AxiosResponse<ApiResponse<{ content: PriceModel[] }>>> {
  return await axios.get<ApiResponse<{ content: PriceModel[] }>>(ApiPath.price.detail, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}
