import { getBuildingsAsync } from "src/api/building/building-api";
import { useApiOperation } from "src/api/hooks";
import { getProductListAsync } from "src/api/product/product-api";
import DashboardSection from "./components/DashboardSection";
import { BUILDING_MENU_LIST, PRODUCT_MENU_LIST } from "./constants";
import { useSectionMenuList } from "./hooks";
import { MenuStatus } from "./types";

function DashboardBuildingProductSection() {
  const { executeAsync: getBuldingList } = useApiOperation(getBuildingsAsync);
  const { executeAsync: getProductList } = useApiOperation(getProductListAsync);

  const getBuldingListTotalElements = async ({ status }: MenuStatus) => {
    const { data } = await getBuldingList({
      page: 0,
      size: 1,
    });
    return data.meta.pageMeta?.totalElements ?? -1;
  };

  const getProductListTotalElements = async ({ status }: MenuStatus) => {
    const { data } = await getProductList({
      page: 0,
      size: 1,
    });
    return data.meta.pageMeta?.totalElements ?? -1;
  };

  const { menuList: buildingMenuList } = useSectionMenuList({
    baseMenuList: BUILDING_MENU_LIST,
    getMenuListCounts: getBuldingListTotalElements,
  });

  const { menuList: ProductMenuList } = useSectionMenuList({
    baseMenuList: PRODUCT_MENU_LIST,
    getMenuListCounts: getProductListTotalElements,
  });

  return (
    <DashboardSection
      title={"건물 / 공간 상품"}
      menuList={[...buildingMenuList, ...ProductMenuList]}
    />
  );
}

export default DashboardBuildingProductSection;
