import qs from "qs";
import { useMemo } from "react";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

type Props = {
  path: string;
  value: string;
  ellipsis?: boolean;
  className?: string;
  isAddChevronPositionRight?: boolean; // 꺾새 모양 '>' 아이콘을 우측에 붙일지 여부
};

const BaseNewTabLink = (props: Props) => {
  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
    decoder: (value) => value,
  });

  const encodeQueryParams = useMemo(() => encodeURIComponent(qs.stringify(queryParams, { allowDots: true })), [queryParams]);

  if (props.ellipsis) {
    return (
      <div className={props.className ? `${props.className} flex-center` : "flex-center"}>
        <ReactRouterLink
          className={`text-hilight target-blank ellipsis d-block`}
          target="_blank"
          to={encodeQueryParams ? props.path + `${props.path.includes("?") ? "&" : "?"}list=${encodeQueryParams}` : props.path}
        >
          {props.value || "-"}
        </ReactRouterLink>
        <div className="ic-target-blank"></div>
      </div>
    );
  } else
    return (
      <>
        <ReactRouterLink
          className="text-hilight target-blank"
          target="_blank"
          to={encodeQueryParams ? props.path + `${props.path.includes("?") ? "&" : "?"}list=${encodeQueryParams}` : props.path}
        >
          {props.value || "-"}
        </ReactRouterLink>
        <div className="ic-target-blank"></div>
        {props.isAddChevronPositionRight && <div className="ic-chevron-right mr4"></div>}
      </>
    );
};

export default BaseNewTabLink;
