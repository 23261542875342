import { Cell } from "react-table";
import { MemberModel, MemberAccountStatusType } from "src/api/member/member-types";

export const MemberStatusCell = (props: Cell<MemberModel>) => {
  // console.log('header',props?.value?.status)
  return (
    <div
            className={`${
              (props?.value?.status === MemberAccountStatusType.AVAILABLE && "chip green") ||
              (props?.value?.status === MemberAccountStatusType.INACTIVE && "chip red") ||
              (props?.value?.status === MemberAccountStatusType.DELETE && "chip red") ||
              (props?.value?.status === MemberAccountStatusType.LOCKED && "chip gray")
            }`}
          >
            {(props?.value?.status === MemberAccountStatusType.AVAILABLE && "정상") ||
              (props?.value?.status === MemberAccountStatusType.INACTIVE && "휴면 계정") ||
              (props?.value?.status === MemberAccountStatusType.DELETE && "회원 탈퇴") ||
              (props?.value?.status === MemberAccountStatusType.LOCKED && "일시 정지")
              }
          </div>
    
  );
};
