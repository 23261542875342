import React from "react";

type Props = {
  title: string;
  id?: string | number;
  noTitle?: string;
  no?: string | number;
  thirdTitle?: string;
  thirdNo?: string | number;
  status?: string;
  rightLables?: Array<{ key: string; value?: string }>; // 오른쪽 회색 영역. 이 배열을 쓸 경우, title 제외하고 입력하지 마세요.
};

export const ContentsIdSection = (props: Props) => {
  return (
    <section className="contents-container__title">
      <h2 className="no-wrap">{props.title}</h2>
      <div className="flex-center">
        {props.status && (
          <div className="base-chip pt6 pb7 px12 mr10">
            <span className="font14">{props.status}</span>
          </div>
        )}
        {props.id && (
          <div className="base-chip pt6 pb7 px12">
            <p className="font14">
              id : <b className={props.noTitle || props.thirdTitle ? "mr10" : ""}>{props.id}</b>
              {props.noTitle && (
                <>
                  {props.noTitle} : <b className={props.thirdTitle ? "mr10" : ""}>{props.no}</b>
                </>
              )}
              {props.thirdTitle && (
                <>
                  {props.thirdTitle} : <b>{props.thirdNo}</b>
                </>
              )}
            </p>
          </div>
        )}
        {(props?.rightLables || []).length > 0 && (
          <div className="base-chip pt6 pb7 px12">
            <div className="flex-center font14">
              {(props.rightLables || []).map(
                (label: { key: string; value?: string }, index: number) => (
                  <p key={index.toString()} className={index > 0 ? "ml10" : ""}>
                    {label.key}
                    {label.value && (
                      <>
                        {" "}
                        : <b>{label.value}</b>
                      </>
                    )}
                  </p>
                ),
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
