import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Cell } from "react-table";
import { MgmtOfficeModel, MgmtPhoneType } from "src/api/building/building-types";
import {
  editBuildingManagementAsync,
  postBuildingManagementAsync,
} from "src/api/building/management-api";
import { useApiOperation } from "src/api/hooks";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseCheckbox, BaseInput, BaseModal } from "src/components";
import { useLoadingBarContext, useToastContext } from "src/pages/hooks";
import { onlyNumber } from "src/utils";
import { FrontMgmtOfficeModel, FrontMgmtPhoneType } from "../management-type";
import OfficerPhoneTable from "./phone-table/OfficerPhoneTable";

type Props = {
  managementDetail?: MgmtOfficeModel;
  frontOriginalData?: FrontMgmtOfficeModel;
  buildingId?: string;
  getBuildingManagement: Function;
};

const ManagementOfficePhoneSection = ({
  managementDetail,
  frontOriginalData,
  buildingId,
  getBuildingManagement,
}: Props) => {
  //
  const { setLoadingBar } = useLoadingBarContext();
  // 토스트
  const { openToast } = useToastContext();

  // 경고 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 컨펌 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  // 관리처 관리 등록
  const { executeAsync: postBuildingManagement } = useApiOperation(postBuildingManagementAsync);

  // 관리처 관리 수정
  const { executeAsync: editBuildingManagement } = useApiOperation(editBuildingManagementAsync);

  const defaultValues = useMemo(() => {
    const officeData: FrontMgmtOfficeModel = {
      // buildingId,
      officePhoneList: [
        {
          isPrimary: true,
          number: "",
          cmdType: "C",
        },
      ],
    };

    return officeData;
  }, []);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,

    formState: { errors },
  } = useForm<FrontMgmtOfficeModel>({
    defaultValues,
  });

  // 저장된 연락처 목록
  const OfficerPhonse: any[] = useMemo(() => {
    return [
      {
        Header: "대표",
        accessor: "isPrimary",
        width: 100,
        Cell: ({ value }: Cell<MgmtPhoneType>) => {
          if (value === true) {
            return (
              <BaseCheckbox
                label="대표"
                className="chip-case"
                disabled
                checked={value}
                id={"isPrimary"}
                name={"isPrimary"}
              />
            );
          }
        },
      },

      {
        Header: "연락처",
        accessor: "number",
        width: 360,
        Cell: ({ value }: Cell<MgmtPhoneType>) => {
          return <p>{value}</p>;
        },
      },
    ];
  }, [setValue]);

  useEffect(() => {
    // 연락처 목록에 대표가 이미 있을 시 , 등록 폼의 대표 false
    if (managementDetail?.officePhoneList) {
      const officePhoneList = managementDetail.officePhoneList;
      const isPrimary = officePhoneList.some((phone) => phone.isPrimary === true);
      if (isPrimary) {
        setValue("officePhoneList.0.isPrimary", false);
      }
    }
  }, [managementDetail]);

  // 유효성 확인
  const formValidation = useCallback(() => {
    // 연락처 패턴
    const patterns = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/;

    // 연락처
    register(`officePhoneList.0`, {
      validate: {
        required: (value) => {
          let message = "연락처를 입력해 주세요.";
          let result = true;

          const havePrimary = managementDetail?.officePhoneList?.some(
            (item) => item.isPrimary === true,
          );
          console.log(`value`, value);
          if (havePrimary === false && value.isPrimary === false) {
            message = "1개 이상의 대표번호가 필요합니다.";
            result = false;
          }

          // 전화번호 형식 체크
          const isPhonePattern = patterns.test(String(value.number));
          // 대표여부 체크 / 수정 시 대표인데 삭제된것도 체크

          if (value.number && isPhonePattern === false)
            message = "휴대폰 번호 형식에 맞게 입력해주세요.";
          return (result && isPhonePattern) || message;
        },
      },
    });
  }, [managementDetail?.officePhoneList, register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  // 수정 데이터 만들기
  const makeRequestEditData = useCallback((data: FrontMgmtOfficeModel) => {
    let editData = {};
    const officePhoneList = data.officePhoneList;

    editData = {
      ...editData,
      officePhoneList: officePhoneList?.map((item: FrontMgmtPhoneType) => {
        if (item.cmdType === "C" || item.cmdType === "D") {
          return item;
        } else {
          return { ...item, cmdType: "U" };
        }
      }),
    };

    return editData;
  }, []);

  // 컨펌모달 저장 클릭시 등록/수정
  const onClickConfirmModal = useCallback(
    async (data: FrontMgmtOfficeModel) => {
      //
      setLoadingBar(true);
      // id null 이면 신규등록 / 아니면 수정
      if (managementDetail?.id === null) {
        const postData: MgmtOfficeModel = {
          ...data,
          startTime: moment(data?.startTime).format("HH:mm"),
          endTime: moment(data?.endTime).format("HH:mm"),
          officerList: data?.officerList?.map((office) => {
            // 관리처 등록시 업무파트 미선택이면 "OFFICER_UNRECOGNIZED" 추가
            if (office.officerTypeList && office.officerTypeList.length < 1) {
              return { ...office, officerTypeList: ["OFFICER_UNRECOGNIZED"] };
            }
            return office;
          }),
        };

        console.log("postData", postData);

        // 등록
        const response = await postBuildingManagement({ office: postData });
        if (response.status >= 200 && response.status <= 200) {
          openToast(`정상적으로 ${"등록"}되었습니다.`);
          setConfirmModal({ isOpen: false });
        }
      } else {
        // 수정 api 통신
        const editData = makeRequestEditData(data);
        // const editData = data;
        console.log(`edit`, editData);
        const response = await editBuildingManagement({
          id: String(managementDetail?.id),
          office: editData,
        });
        if (response.status >= 200 && response.status <= 200) {
          openToast(`정상적으로 ${"수정"}되었습니다.`);
          setConfirmModal({ isOpen: false });
          reset();
        }
      }
      setLoadingBar(false);
      await getBuildingManagement(String(buildingId));
    },
    [
      buildingId,
      editBuildingManagement,
      getBuildingManagement,
      makeRequestEditData,
      managementDetail?.id,
      postBuildingManagement,
    ],
  );

  // 유효성 검사후 저장
  const onSubmit = useCallback(
    async (data?: FrontMgmtOfficeModel, e?: any) => {
      e.preventDefault();
      const phoneList = managementDetail?.officePhoneList;

      // 경고창 모달 부분

      // 기존 담당자 수정이 아니고 새로 대표가 선택된 경우

      //  새로 등록된 담당자
      const willBePrimaryPhone = data?.officePhoneList![0] || {};
      // 기존 대표 담당자
      const wasPrimaryPhone = phoneList?.find((item) => item.isPrimary);

      if (
        wasPrimaryPhone?.isPrimary === true &&
        willBePrimaryPhone?.isPrimary === true &&
        wasPrimaryPhone.id !== willBePrimaryPhone.id
      ) {
        let newData = { ...data };
        newData.officePhoneList?.push({ ...wasPrimaryPhone, isPrimary: false, cmdType: "U" });
        setConfirmModal({
          isOpen: true,
          message: "이미 대표번호가 있습니다. \n\n 대표 번호를 변경하시겠습니까?",
          payload: newData,
        });
        return;
      }

      setConfirmModal({ isOpen: true, message: "저장하시겠습니까?", payload: data });
    },
    [managementDetail?.officePhoneList],
  );

  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);

  return (
    <article className="contents-container__divide-top">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>연락처</h2>
          </div>
          <table className="inner-table" width="540">
            <thead>
              <tr>
                <th>
                  <p className="text-center">대표여부</p>
                </th>
                <th>
                  <p className="text-center">연락처</p>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {defaultValues.officePhoneList?.map((phone) => (
                <tr key={phone.cmdType}>
                  <td width="120px">
                    <div className="flex-center-center">
                      <Controller
                        control={control}
                        name={`officePhoneList.0.isPrimary`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseCheckbox
                              id={`phoneNumber`}
                              name={name}
                              className="chip-case"
                              label="대표"
                              checked={value}
                              onChange={(checked: boolean) => {
                                onChange(checked);
                              }}
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </td>
                  <td>
                    <div className="flex-center-center">
                      <Controller
                        control={control}
                        name={`officePhoneList.0.number`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseInput
                              onChange={(text: string) => {
                                onChange(text);
                              }}
                              value={onlyNumber(value)}
                              name={name}
                              maxLength={11}
                              placeholder="연락처를 입력해주세요."
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="validation-text">
            {errors.officePhoneList && errors.officePhoneList[0]?.message}
          </p>
        </div>
      </form>

      <div className="mt20">
        <OfficerPhoneTable columns={OfficerPhonse} data={managementDetail?.officePhoneList || []} />
      </div>

      <>
        <BaseModal
          isOpen={confirmModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => confirmModal.payload && onClickConfirmModal(confirmModal.payload)}
        >
          <p>{confirmModal.message}</p>
        </BaseModal>
        <BaseModal
          isOpen={alertModal.isOpen}
          btnRightTitle="확인"
          onClick={() => setAlertModal({ isOpen: false })}
        >
          <p className="pre-formatted">{alertModal.message}</p>
        </BaseModal>
      </>
    </article>
  );
};

export default ManagementOfficePhoneSection;
