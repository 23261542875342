import qs from "qs";
import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentsTitle } from "src/components";
import { PagePath } from "src/pages/product/details";
import WorkOrderAdminMemo from "../form/components/WorkOrderAdminMemo";
import WorkOrderHistory from "../form/components/WorkOrderHistory";
import BasicInfoDetail from "./components/BasicInfoDetail";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "history", label: "변경내역" },
  // { value: "memo", label: "관리자 메모" },
];

const WorkOrderDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "workorder") {
        // 공간상품 목록으로 이동
        navigate("/mng/central/workorder");
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "workorder", label: "워크오더" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  return (
    <div className="page-product-access">
      <ContentsTitle
        title="워크오더"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />
      <div className="contents-container__scroll">
        {activeTab?.value === "basicInfo" && <BasicInfoDetail />}

        {/* 변경내역 정보 */}
        {activeTab?.value === "history" && <WorkOrderHistory />}

        {/* 관리자 메모 */}
        {/* {activeTab?.value === "memo" && <WorkOrderAdminMemo />} */}
      </div>
    </div>
  );
};

export default WorkOrderDetail;
