import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { useApiOperation } from "src/api/hooks";
import { getOrgList } from "src/api/organization/org-api";
import { BaseInput, BaseModal, BasePagination, BaseRadio, BaseTooltip } from "src/components";
import { useLoadingBarContext } from "../hooks";

const columnHeader: any = [
  {
    Header: "선택",
    accessor: "isSelected",
    width: 80,
    Cell: ({ row, setSelectedOrg }: any) => {
      const changeTrigger = useCallback(() => {
        setSelectedOrg(row.original);
      }, [row.original, setSelectedOrg]);

      return (
        <BaseRadio
          id={`selector${row.original.id}`}
          name={"isSelected"}
          onChange={changeTrigger}
          value={row.original.isSeleted}
        />
      );
    },
  },
  {
    Header: "id",
    accessor: "id",
    width: 80,
  },
  {
    Header: "법인명/상호",
    accessor: "name",
    width: 150,
    Cell: ({ value }: any) => {
      return <BaseTooltip contents={value} />;
    },
  },
  {
    Header: "대표자/성명",
    accessor: "ceoName",
    width: 120,
  },
  {
    Header: "사업자 등록번호",
    accessor: "bizRegNo",
    width: 180,
  },
  {
    Header: "등록일",
    accessor: "createdDate",
    width: 120,
    Cell: ({ value }: any) => {
      return <BaseTooltip contents={value} type="date" />;
    },
  },
  {
    Header: "수정일",
    accessor: "lastModifiedDate",
    width: 120,
    Cell: ({ value }: any) => {
      return <BaseTooltip contents={value} type="date" />;
    },
  },
];
type Props = {
  isOpen: boolean;
  name?: string;
  columns?: any;
  partnerId?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const SearchOrgPopup = (props: Props) => {
  const { setLoadingBar } = useLoadingBarContext();
  const [data, setData] = useState<any>([]);
  const [selectedOrg, setSelectedOrg] = useState<any>();
  const [pageMeta, setPageMeta] = useState<any>({});
  const [search001, setSearch001] = useState("");
  const [params, setParams] = useState<any>({
    page: 0,
    size: 20,
    search001: "",
    sort: {
      orders: [{ property: "id", direction: "DESC" }],
    },
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: props.columns ? props.columns : columnHeader,
      data: data,
      setSelectedOrg,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );

  //조회
  const { executeAsync: executeAsyncAll } = useApiOperation(getOrgList, {
    doNotErrorHandleModal: true,
  });

  const onClickOrg = useCallback(() => {
    if (props.onClick) {
      props.onClick(selectedOrg);
    }
  }, [props, selectedOrg]);

  const fetchData = useCallback(
    async (params: any) => {
      setLoadingBar(true);
      const response: any = await executeAsyncAll(params);
      if (response.status >= 200 && response.status <= 299) {
        console.log(response);
        for (let i = 0; i < response?.data?.data?.content.length; i++) {
          // response.data.data.content[i].isSelected = false;
          response.data.data.content[i] = Object.assign(response.data.data.content[i], {
            isSelected: false,
          });
        }
        setData(response?.data?.data?.content || []);
        setPageMeta(response?.data?.meta?.pageMeta);
      } else {
      }
      setLoadingBar(false);
    },
    [executeAsyncAll, setLoadingBar],
  );

  useEffect(() => {
    if (props.isOpen) {
      let prs = _.cloneDeep(params);
      prs.page = 0;
      prs.search001 = props.name ? decodeURIComponent(props.name) : "";
      props.name ? setSearch001(decodeURIComponent(props.name)) : setSearch001("");
      if (props.partnerId) {
        prs.partnerId = String(props.partnerId);
      }
      fetchData(prs);
      setParams(prs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <div>
      <BaseModal
        className="dialog-modal"
        isOpen={props.isOpen}
        btnRightTitle="선택"
        btnLeftTitle="취소"
        onClick={() => onClickOrg()}
        onClose={props.onClose}
      >
        <>
          <div className="modal-title">
            <div className="left-area">
              <span>사업자</span>
            </div>
            <div className="right-area">
              <div className="minmax260">
                <BaseInput
                  type="text"
                  value={search001 || ""}
                  placeholder="검색어를 입력해주세요"
                  onChange={(value: string) => {
                    setSearch001(value);
                  }}
                  onKeyUp={() => {
                    setParams({ ...params, ...{ search001 } });
                    fetchData({ ...params, ...{ search001 }, ...{ page: 0 } });
                  }}
                  onSearchClick={() => {
                    setParams({ ...params, ...{ search001 } });
                    fetchData({ ...params, ...{ search001 }, ...{ page: 0 } });
                  }}
                />
              </div>
            </div>
          </div>
          {/* table */}
          <div className="contents-container__table">
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <div
                      {...row.getRowProps()}
                      className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <BasePagination
              pageIndex={Number(params.page) || 0}
              totalPages={Number(pageMeta.totalPages) || 0}
              goPage={(page: number) => {
                setParams({ ...params, ...{ page } });
                fetchData({ ...params, ...{ page } });
              }}
            />
          </div>
        </>
      </BaseModal>
    </div>
  );
};

export default SearchOrgPopup;
