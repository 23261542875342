import { ko } from "date-fns/locale";
import React, { useCallback } from "react";
import DatePicker from "react-datepicker";
import { createPortal } from "react-dom";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

interface BaseDatePickerState {
  selectedDate?: Date | null;
  setDate: Function;
  type?: string;
  className?: string;
  disabled?: boolean;
  errorText?: string;
  timeIntervals?: number;
  data?: any;
  setData?: Function;
  name?: string;
  validation?: boolean;
  readonly?: boolean;
  placeholder?: string;
  filteredTime?: Date | undefined;
  useFilterdTime?: boolean;
  excludeTimes?: any[];
  maxDate?: Date;
  minDate?: Date;
  filterdReverse?: Boolean;
}

export const BaseDatePicker = (props: BaseDatePickerState) => {
  const getDateString = (date: Date | null) => {
    const year = date?.getFullYear();
    const month = ("0" + (date && date.getMonth() + 1)).slice(-2);
    const day = ("0" + date?.getDate()).slice(-2);

    let selectedDate = `${year}${month}${day}`;

    return selectedDate;
  };
  const getDateTimeString = (date: Date | null) => {
    let selectedDate = date?.toISOString;
    return selectedDate;
  };

  const getTimeValue = useCallback(
    (date: Date | null) => {
      props.setDate(date);
    },
    [props],
  );

  const getDateValue = useCallback(
    (date: Date | null) => {
      props.setDate(date);
      const obj = { ...props.data };
      obj.completionYmd = getDateString(date);
      if (props.setData) {
        props.setData(obj);
      }
    },
    [props],
  );

  const getFullDateTimeValue = useCallback(
    (date: Date | null) => {
      props.setDate(date);
      // props.setDate(getDateTimeString(date))
    },
    [props],
  );

  const CalendarContainer: React.FC = ({ children }) => {
    const el = document.getElementById("calendar");

    if (el === null) return null;

    return createPortal(<>{children}</>, el!);
  };

  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    const test_now_hour = moment(currentDate).format("HH");
    const test_now_minute = moment(currentDate).format("mm");
    const test_option_hour = moment(selectedDate).format("HH");
    const test_option_minute = moment(selectedDate).format("mm");

    let flag = true;
    if (props.useFilterdTime) {
      if (Number(test_now_hour) <= Number(test_option_hour)) {
        if (Number(test_now_hour) === Number(test_option_hour)) {
          if (Number(test_now_minute) >= 0 && Number(test_now_minute) <= 29) {
            // console.log("현재 분이 0~30분 이전", test_now_minute);

            if (Number(test_option_minute) === 0) {
              //현재 분이 0~30분 이전이고 셀렉트 가 00분일 경우
              // console.log("셀렉트 가 00분일 경우", test_option_minute);
              flag = true;
            }
            if (Number(test_option_minute) === 30) {
              //현재 시간이 30~59분 사이이고
              // console.log("셀렉트 가 30분일 경우", test_option_minute);
              flag = true;
            }
            // flag = true;
          }
          if (Number(test_now_minute) >= 30 && Number(test_now_minute) <= 59) {
            // console.log("현재 분이 30~59분", test_now_minute);
            if (Number(test_option_minute) === 0) {
              //현재 분이 0~30분 이전이고 셀렉트 가 00분일 경우
              // console.log("셀렉트 가 00분일 경우", test_option_minute);
              flag = false;
            }
            if (Number(test_option_minute) === 30) {
              // console.log("셀렉트 가 30분일 경우", test_option_minute);
              //현재 시간이 30~59분 사이이고
              flag = true;
            }
          }
        }
      } else {
        flag = false;
      }
      if (props.filterdReverse) {
        flag = !flag;
      }
    }
    // console.log(moment(selectedDate).format("HH:mm"), flag);
    return flag;
  };

  return (
    <>
      {props?.type === "full" ? (
        <>
          <div className={`base-input ${props.className ? props.className : ""}`}>
            <DatePicker
              selected={props.selectedDate}
              closeOnScroll={true}
              onChange={(date: Date) => getFullDateTimeValue(date)}
              locale={ko}
              dateFormat="yyyy.MM.dd aa h:mm"
              showPopperArrow={false}
              calendarClassName="base-datepicker full-datepicker"
              placeholderText={props.placeholder ? props.placeholder : "날짜와 시간을 선택해주세요"}
              showTimeSelect
              filterTime={props.useFilterdTime ? filterPassedTime : undefined}
              excludeTimes={props.excludeTimes ? props.excludeTimes : []}
              maxDate={props.maxDate}
              minDate={props.minDate}
              readOnly={props.readonly ? true : false}
              popperContainer={CalendarContainer}
            />
            {props.errorText && !props.selectedDate && (
              <p className="validation-text">{props.errorText}</p>
            )}
          </div>
        </>
      ) : props?.type === "time" ? (
        <>
          <div className={`base-input ${props.className ? props.className : ""}`}>
            <DatePicker
              selected={props.selectedDate}
              onChange={(date) => getTimeValue(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={props.timeIntervals ? props.timeIntervals : 10}
              timeCaption="Time"
              dateFormat="HH:mm"
              filterTime={filterPassedTime}
              excludeTimes={props.excludeTimes}
              calendarClassName="base-datepicker"
              placeholderText={props.placeholder ? props.placeholder : "시간을 선택해주세요"}
              readOnly={props.readonly ? true : false}
              popperContainer={CalendarContainer}
            />
            {props.errorText && !props.selectedDate && (
              <p className="validation-text">{props.errorText}</p>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={`base-input ${props.className ? props.className : ""}`}>
            <DatePicker
              selected={props.selectedDate}
              closeOnScroll={true}
              onChange={(date: Date) => getDateValue(date)}
              locale={ko}
              dateFormat="yyyy-MM-dd"
              showPopperArrow={false}
              calendarClassName="base-datepicker"
              placeholderText={props.placeholder ? props.placeholder : "날짜를 선택해주세요"}
              readOnly={props.readonly ? true : false}
              maxDate={props.maxDate}
              minDate={props.minDate}
              popperContainer={CalendarContainer}
              disabled={props.disabled}
            />
            {props.errorText && !props.selectedDate && (
              <p className="validation-text">{props.errorText}</p>
            )}
          </div>
        </>
      )}
    </>
  );
};
