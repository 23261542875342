import React from "react";
import { ContentsTitle } from "src/components";
import BuildingProductSection from "./DashboardBuildingProductSection";
import ContractSection from "./DashboardContractSection";
import MemberSection from "./DashboardMemberSection";
import ReservationSection from "./DashboardReservationSection";
import { ISection } from "./types";

const Main = () => {
  return (
    <div>
      {/* <ContentsTitle title="대시보드" /> */}
      <div className="contents-container__scroll dashboard bg-gray">
        <article className="page-dashboard">
          <MemberSection />
          <ContractSection />
          <ReservationSection />
          <BuildingProductSection />
        </article>
      </div>
    </div>
  );
};

export default Main;

// 로그인 페이지 -> 유저가 로그인 false -->loginWithRedirect()
// 로그인 성공시 -> redirect page 로 이동
