import React, { useCallback, useEffect, useRef, useState } from "react";
import { useOnClickOutsideClose } from "src/pages/hooks/useOnClickOutsideClose";
import { BaseCheckbox } from "./BaseCheckbox";
type Selected = {
  value: string;
  label: string;
  isDisabled?: boolean;
};

type Props = {
  placeholder?: string;
  stateOptions: Array<Selected>;
  value: string[];
  name?: string;
  setStateValue: Function;
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  errorText?: string;
  isDisabled?: boolean;
};

/**
 * 멀티셀렉트박스
 * 사용법은 BaseSelect와 유사합니다.
 * value가 ''인 option이 있으면, 전체 버튼으로 인식합니다.
 * 체크박스 클릭시, 선택된 options 배열을 리턴합니다.
 * 전체 클릭시 드롭다운을 닫습니다.
 * 체크박스 클릭시 드롭다운을 닫지 않습니다.
 */

const BaseMultiSelect = (props: Props) => {
  const [selected, setSelected] = useState<Selected[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState<Boolean>(false);
  const multiRef = useRef(null);
  useOnClickOutsideClose(multiRef, () => setIsMenuOpen(() => false));

  useEffect(() => {
    const findInitSelected = () => {
      let initSelected = [];
      initSelected = props.stateOptions.filter((option) => props.value.includes(option.value));
      return initSelected;
    };
    setSelected(findInitSelected());
  }, [props]);

  return (
    <div className="base-multi-select-wrap" ref={multiRef}>
      <div
        className={props.isDisabled ? "base-multi-select disabled" : "base-multi-select"}
        onClick={() => setIsMenuOpen((prev) => !prev)}
      >
        {props.placeholder && selected.length === 0 && (
          <p className="base-multi-select__placeholder">{props.placeholder}</p>
        )}
        <p className="base-multi-select__value">
          {selected.map((option) => option.label).join(", ")}
        </p>
        <div className="base-multi-select__chevron"></div>
      </div>
      {isMenuOpen && (
        <div className="base-multi-select__menu">
          <ul>
            {props.stateOptions.map((option) => {
              return option.value === "" ? (
                <li
                  key={option.value}
                  className="base-multi-select__menu__menu-list list-all"
                  onClick={() => {
                    setSelected([]);
                    props.setStateValue([]);
                    setIsMenuOpen(false);
                  }}
                >
                  {option.label}
                </li>
              ) : (
                <li
                  key={option.value}
                  className="base-multi-select__menu__menu-list"
                  onClick={() => {
                    const newOptions = selected.includes(option)
                      ? selected.filter((obj) => obj !== option)
                      : [...selected, option];
                    props.setStateValue(newOptions);
                    setSelected(newOptions);
                  }}
                >
                  <BaseCheckbox
                    wrap
                    id={""}
                    name={""}
                    checked={selected.includes(option)}
                    label={option.label}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default BaseMultiSelect;
