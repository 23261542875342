import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import { number } from "prop-types";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BuildingFloorModel, BuildingRoomModel } from "src/api/building/building-types";
import { getContractManageByContractApplyNumber } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import { getProductDetailAsync } from "src/api/product/product-api";
import { ProductModel } from "src/api/product/product-types";
import { Address } from "src/api/public-types";
import {
  getVisitorsByVisitApplyNumberAsync,
  patchVisitorsAsync,
  postVisitorsAsync,
} from "src/api/visitor/visitor-api";
import { VisitorModel } from "src/api/visitor/visitor-types";
import {
  BaseButton,
  BaseDatePicker,
  BaseInput,
  BaseModal,
  ContentsIdSection,
  ContentsTitle,
} from "src/components";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import { Partner } from "src/types/partner";
import { validateCarNumber, validateMobileNumber, YmdFormat } from "src/utils";
import { Modal, Visitor, VisitorFormData } from "../visitor-types";
import VisitorsSection from "./components/VisitorsSection";

const breadCrumbs = [
  { value: "visitor", label: "방문자" },
  { value: "", label: "방문자 초대" },
];

/* 
  방문자 > 방문자 초대 (등록, 수정)
*/
const VisitorForm = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 토스트
  const { openToast } = useToastContext();

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // router path variable 신청/계약번호
  const { contractApplyNumber } = useParams();

  // 방문신청 번호 (수정일 경우에만 있음)
  const visitApplyNumber: string | undefined = queryParams?.visitApplyNumber
    ? String(queryParams.visitApplyNumber)
    : undefined;

  // 계약관리 정보 조회시 얻어온 계약 관리 id
  const [contractManageId, setContractManageId] = useState<string>();

  // 공간상품 정보
  const [product, setProduct] = useState<ProductModel | null>(null);

  // 파트너
  const [partner, setPartner] = useState<Partner>();

  // (수정시) 조회한 방문자 초대 목록
  const [contractVisitorList, setContractVisitorList] = useState<Array<VisitorModel>>([]);

  // 건물 id
  const [buildingId, setBuildingId] = useState("");

  // 계약관리 정보 조회 api
  const { executeAsync: getContractManage } = useApiOperation(
    getContractManageByContractApplyNumber,
  );

  // 공간상품 정보 조회 api
  const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync);

  // 방문자 상세 조회 api
  const { executeAsync: getVisitorsByVisitApplyNumber } = useApiOperation(
    getVisitorsByVisitApplyNumberAsync,
  );

  // 방문자 등록 api
  const { executeAsync: postVisitors } = useApiOperation(postVisitorsAsync);

  // 방문자 수정 api
  const { executeAsync: patchVisitors } = useApiOperation(patchVisitorsAsync);

  // 파트너 세부 조회 api
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<VisitorFormData>({
    defaultValues: {
      visitors: [
        {
          contractVisitorId: "0",
          visitorName: "",
          visitorMobileNumber: "",
          visitorCarNumber: "",
        },
      ],
    },
  });

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    const requiredMessage = "필수입력항목입니다";
    register("visitStartDate", {
      validate: {
        validVisitStartDate: (visitStartDate?: Date) => {
          if (!visitStartDate) return requiredMessage;
          const message = "방문 시작일시는 방문 종료일시 보다 같거나 이전이여야 합니다.";

          const visitStartTime = getValues("visitStartTime");
          const visitEndDate = getValues("visitEndDate");
          const visitEndTime = getValues("visitEndTime");

          if (!visitStartTime) return true;

          // 시작일시 날짜 문자열
          const startDateString = `${moment(visitStartDate, "YYYY-MM-DD").format(
            "YYYY-MM-DD",
          )} ${moment(visitStartTime, "HH:mm").format("HH:mm")}`;
          const startDate = moment(startDateString, "YYYY-MM-DD HH:mm:ss");

          // 종료일시 날짜 문자열
          const endDateString = `${moment(visitEndDate, "YYYY-MM-DD").format(
            "YYYY-MM-DD",
          )} ${moment(visitEndTime, "HH:mm").format("HH:mm")}`;
          const endDate = moment(endDateString, "YYYY-MM-DD HH:mm:ss");

          const isSameOrBefore: boolean = moment(startDate).isSameOrBefore(endDate);
          return isSameOrBefore || message;
        },
      },
    });
    register("visitStartTime", {
      required: requiredMessage,
    });
    register("visitEndDate", {
      validate: {
        validVisitEndDate: (visitEndDate?: Date) => {
          if (!visitEndDate) return requiredMessage;
          const message = "방문 종료일시는 방문 시작일시 보다 같거나 이후여야 합니다.";

          const visitStartDate = getValues("visitStartDate");
          const visitStartTime = getValues("visitStartTime");
          const visitEndTime = getValues("visitEndTime");

          if (!visitEndTime) return true;

          // 시작일시 날짜 문자열
          const startDateString = `${moment(visitStartDate, "YYYY-MM-DD").format(
            "YYYY-MM-DD",
          )} ${moment(visitStartTime, "HH:mm").format("HH:mm")}`;
          const startDate = moment(startDateString, "YYYY-MM-DD HH:mm:ss");

          // 종료일시 날짜 문자열
          const endDateString = `${moment(visitEndDate, "YYYY-MM-DD").format(
            "YYYY-MM-DD",
          )} ${moment(visitEndTime, "HH:mm").format("HH:mm")}`;
          const endDate = moment(endDateString, "YYYY-MM-DD HH:mm:ss");

          const isSameOrBefore: boolean = moment(startDate).isSameOrBefore(endDate);
          return isSameOrBefore || message;
        },
      },
    });
    register("visitEndTime", {
      required: requiredMessage,
    });
    // register("visitMeetingPlace", {
    //   required: requiredMessage,
    // });
    register("visitors.0.visitorName", {
      required: requiredMessage,
    });
    register("visitors.0.visitorMobileNumber", {
      validate: {
        visitorMobileNumber: (visitorMobileNumber?: string) => {
          return validateMobileNumber(visitorMobileNumber);
        },
      },
    });
    register("visitors.0.visitorCarNumber", {
      validate: {
        visitorCarNumber: (visitorCarNumber?: string) => {
          return validateCarNumber(visitorCarNumber);
        },
      },
    });
  }, [register, getValues]);

  // 상단 오른쪽 라벨들
  const contentsIdLables: Array<{ key: string; value: string }> = useMemo(() => {
    const labels = [{ key: "신청/계약번호", value: contractApplyNumber || "" }];
    if (!!visitApplyNumber) {
      labels.push({ key: "방문신청번호", value: visitApplyNumber || "" });
    }
    return labels;
  }, [contractApplyNumber, visitApplyNumber]);

  // 계약관리, 공간상품정보 조회 후 데이터 바인딩
  const fetchContractAndProduct = useCallback(
    async (contractApplyNumber: string) => {
      try {
        setLoadingBar(true);
        // 계약관리 정보 조회
        const { data } = await getContractManage({ contractApplyNumber });
        if (
          !data?.data?.contractManage ||
          !data?.data?.contractManage.contractManageId ||
          data?.data?.contractManage.contractManageId === "0" ||
          !data?.data?.contractManage.spaceProductId ||
          data?.data?.contractManage.spaceProductId === "0"
        ) {
          throw Error(
            `신청/계약번호: '${contractApplyNumber}'는 존재하지 않는 번호입니다. 다른번호로 조회해주세요.`,
          );
        }
        setBuildingId(data.data.contractManage.buildingId);

        const { contractManageId, spaceProductId } = data.data.contractManage;
        if (!!contractManageId && contractManageId !== "0") {
          // 계약 관리 id
          setContractManageId(contractManageId);
        }
        if (!!spaceProductId && spaceProductId !== "0") {
          // 공간상품 정보 조회
          const { data: productData } = await getProductDetail({
            productId: spaceProductId,
          });
          if (productData?.data?.content) {
            setProduct(productData.data.content);
          }
        }
        setLoadingBar(false);
      } catch (error: any) {
        console.error(error);
        setAlertModal({
          isOpen: true,
          type: "INVALID_CONTRACT_APPLY_NUMBER",
          message:
            error?.message ||
            `신청/계약번호: '${contractApplyNumber}'로 계약관리 조회하는데 실패했습니다.`,
        });
      } finally {
        setLoadingBar(false);
      }
    },
    [setLoadingBar, getContractManage, getProductDetail],
  );

  // 방문자 조회
  const fetchVisitors = useCallback(
    async (visitApplyNumber: string) => {
      try {
        setLoadingBar(true);
        const { data } = await getVisitorsByVisitApplyNumber({ visitApplyNumber });
        if (data?.data?.contractVisitor && (data?.data?.contractVisitor || []).length > 0) {
          // 삭제되지 않은 방문자 목록
          const contractVisitorList: Array<VisitorModel> = data.data.contractVisitor.filter(
            (contractVisitor: VisitorModel) =>
              Boolean(contractVisitor.isDeleted === false || contractVisitor.isDeleted === "false"),
          );

          // 방문자 목록 바인딩
          setContractVisitorList(contractVisitorList);

          // form 에 바인딩
          const visitors: Array<Visitor> = contractVisitorList
            .sort(
              (a: VisitorModel, b: VisitorModel) =>
                Number(a.contractVisitorId || 0) - Number(b.contractVisitorId || 0),
            )
            .map((contractVisitor: VisitorModel) => {
              const visitor: Visitor = {
                contractVisitorId: contractVisitor.contractVisitorId,
                visitorName: contractVisitor.visitorName,
                visitorMobileNumber: contractVisitor.visitorMobileNumber,
                visitorCarNumber: contractVisitor.visitorCarNumber,
                buildingId: contractVisitor.buildingId,
              };
              return visitor;
            });

          setValue("visitors", visitors);

          // 방문 시작일시 바인딩 (방문자 모두 동일)
          const visitStartDateString = contractVisitorList[0]?.visitStartTime;
          if (visitStartDateString) {
            setValue("visitStartDate", moment(visitStartDateString).toDate());
            setValue("visitStartTime", moment(visitStartDateString).toDate());
          }

          // 방문 종료일시 바인딩 (방문자 모두 동일)
          const visitEndDateString = contractVisitorList[0]?.visitEndTime;
          if (visitEndDateString) {
            setValue("visitEndDate", moment(visitEndDateString).toDate());
            setValue("visitEndTime", moment(visitEndDateString).toDate());
          }
          //만남장소 모두 동일
          const visitMeetingPlaceString = contractVisitorList[0]?.meetingPlace;
          if (visitMeetingPlaceString) {
            setValue("visitMeetingPlace", visitMeetingPlaceString);
          }
        }
        setLoadingBar(false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBar(false);
      }
    },
    [getVisitorsByVisitApplyNumber, setLoadingBar, setValue],
  );

  useEffect(() => {
    if (!!contractApplyNumber) {
      // 공간상품 정보 조회
      fetchContractAndProduct(contractApplyNumber);
    }
    if (!!visitApplyNumber) {
      // 방문자 조회 (수정시)
      fetchVisitors(visitApplyNumber);
    }
  }, [contractApplyNumber, visitApplyNumber, fetchContractAndProduct, fetchVisitors]);

  // 공간상품의 대표 건물 주소
  const primaryBuildingAddress: string = useMemo(() => {
    if (!product) return "";
    let primaryBuildingAddress = "";
    for (let i = 0; i < (product?.buildingList || []).length; i++) {
      const building = (product?.buildingList || [])[i];
      if (building) {
        // 공간상품의 대표 건물 호실
        const primaryBuildingFloor: BuildingFloorModel | undefined = (
          building?.buildingFloorList || []
        ).find((buildingFloor: BuildingFloorModel) => {
          return (buildingFloor?.buildingRoomList || []).find(
            (buildingRoom: BuildingRoomModel) => buildingRoom.isPrimary, // 대표
          );
        });
        if (!!primaryBuildingFloor) {
          const address: Address = (building?.addressList || [])[0];
          primaryBuildingAddress = `(${address.zonecode}) ${address.address} ${address.addressDetail}`;
        }
      }
    }
    return primaryBuildingAddress;
  }, [product]);

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });
  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "visitor") {
        navigate(`${PagePath.visitor.list}?contractApplyNumber=${contractApplyNumber}`);
      }
    },
    [navigate, contractApplyNumber],
  );

  // 방문자 등록
  const addVisitors = useCallback(
    async (submitData: VisitorFormData) => {
      try {
        if (!contractManageId) throw Error("contractManageId is not found.");
        if (!contractApplyNumber) throw Error("contractApplyNumber is not found.");

        setLoadingBar(true);

        const visitStartString = `${moment(submitData.visitStartDate).format(
          "YYYY-MM-DD",
        )} ${moment(submitData.visitStartTime).format("HH:mm")}:00`;
        const visitStartTime = moment(visitStartString).format("YYYY-MM-DDTHH:mm:ssZ");
        const visitEndString = `${moment(submitData.visitEndDate).format("YYYY-MM-DD")} ${moment(
          submitData.visitEndTime,
        ).format("HH:mm")}:00`;
        const visitEndTime = moment(visitEndString).format("YYYY-MM-DDTHH:mm:ssZ");

        const { data } = await postVisitors({
          contractManageId: Number(contractManageId),
          visitors: (submitData.visitors || []).map((visitor: Visitor) => {
            const visitorModel: VisitorModel = {
              visitorName: visitor.visitorName,
              visitorMobileNumber: visitor.visitorMobileNumber,
              visitorCarNumber: visitor.visitorCarNumber,
              visitStartTime,
              visitEndTime,
              meetingPlace: submitData.visitMeetingPlace,
            };
            return visitorModel;
          }),
          partnerId: Number(partner?.id),
        });

        setLoadingBar(false);

        if (data?.data?.visitApplyNumber) {
          openToast("초대 완료되었습니다.");
          const detailPath = `${PagePath.visitor.detail
            .replace(":contractApplyNumber", contractApplyNumber)
            .replace(":visitApplyNumber", data.data.visitApplyNumber)}`;
          navigate(detailPath);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBar(false);
      }
    },
    [
      contractManageId,
      contractApplyNumber,
      setLoadingBar,
      postVisitors,
      navigate,
      openToast,
      partner,
    ],
  );

  // 방문자 수정
  const editVisitors = useCallback(
    async (submitData: VisitorFormData) => {
      try {
        if (!visitApplyNumber) throw Error("visitApplyNumber is not found.");
        if (!contractApplyNumber) throw Error("contractApplyNumber is not found.");

        console.log(`submitData`, submitData);
        setLoadingBar(true);

        // 방문일시
        const visitStartString = `${moment(submitData.visitStartDate).format(
          "YYYY-MM-DD",
        )} ${moment(submitData.visitStartTime).format("HH:mm")}:00`;
        const visitStartTime = moment(visitStartString).format("YYYY-MM-DDTHH:mm:ssZ");
        const visitEndString = `${moment(submitData.visitEndDate).format("YYYY-MM-DD")} ${moment(
          submitData.visitEndTime,
        ).format("HH:mm")}:00`;
        const visitEndTime = moment(visitEndString).format("YYYY-MM-DDTHH:mm:ssZ");

        // 새로 저장하는 방문자 목록
        const newContractVisitorList: Array<VisitorModel> = [];
        for (let i = 0; i < submitData.visitors.length; i++) {
          const formVisitor: Visitor = submitData.visitors[i];
          const visitorModel: VisitorModel = {
            contractVisitorId: formVisitor.contractVisitorId,
            visitorName: formVisitor.visitorName,
            visitorMobileNumber: formVisitor.visitorMobileNumber,
            visitorCarNumber: formVisitor.visitorCarNumber,
            visitStartTime,
            visitEndTime,
            meetingPlace: submitData.visitMeetingPlace ? submitData.visitMeetingPlace : "",
            buildingId,
          };
          newContractVisitorList.push(visitorModel);
        }

        if (contractVisitorList.length > 0) {
          // 기존의 방문자 목록이랑 비교해서 찾은 삭제헤야되는 방문자 목록
          for (let i = 0; i < contractVisitorList.length; i++) {
            const currentContractVisitor: VisitorModel = contractVisitorList[i];
            const findVisitor = (submitData.visitors || []).find(
              (visitor: Visitor) =>
                visitor.contractVisitorId !== "0" && // 신규추가가 아니고
                Number(visitor.contractVisitorId) ===
                  Number(currentContractVisitor.contractVisitorId),
            );
            if (!findVisitor) {
              // 기존에는 있었지만 삭제된 방문자
              const deleteVisitorModel: VisitorModel = {
                contractVisitorId: currentContractVisitor.contractVisitorId,
                visitorName: currentContractVisitor.visitorName,
                visitorMobileNumber: currentContractVisitor.visitorMobileNumber || "01011111111", // 휴대폰 번호 없을때 api validation 에 걸려서 삭제처리 안되는것 방지
                visitorCarNumber: currentContractVisitor.visitorCarNumber,
                visitStartTime: currentContractVisitor.visitStartTime,
                visitEndTime: currentContractVisitor.visitEndTime,
                isDeleted: true, //삭제
              };
              newContractVisitorList.push(deleteVisitorModel);
            }
          }
        }

        const { data } = await patchVisitors({
          visitApplyNumber: String(visitApplyNumber || visitApplyNumber),
          contractVisitorList: newContractVisitorList,
          partnerId: Number(partner?.id),
        });

        setLoadingBar(false);

        if (data?.data?.contractVisitorId) {
          openToast("정상적으로 수정 되었습니다.");
          const detailPath = `${PagePath.visitor.detail
            .replace(":contractApplyNumber", contractApplyNumber)
            .replace(":visitApplyNumber", visitApplyNumber)}`;
          navigate(detailPath);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBar(false);
      }
    },
    [
      visitApplyNumber,
      contractApplyNumber,
      setLoadingBar,
      contractVisitorList,
      patchVisitors,
      buildingId,
      openToast,
      navigate,
      partner,
    ],
  );

  // alert 모달 확인 버튼 클릭
  const clickAlertModalConfirm = () => {
    // 모달 닫기
    setAlertModal({ isOpen: false });

    if (alertModal.type === "INVALID_CONTRACT_APPLY_NUMBER") {
      // 유효하지 않은 신청/계약번호 이기 때문에 목록화면으로 이동
      const listPath = `${PagePath.visitor.list}?contractApplyNumber=${contractApplyNumber}`;
      navigate(listPath);
    }
  };

  // confirm 모달 확인 버튼 클릭
  const clickConfirmModalConfirm = async () => {
    // 모달 닫기
    setConfirmModal({ isOpen: false });

    if (!confirmModal?.payload) throw Error("confirmModal?.payload is not found.");

    if (confirmModal.type === "VISITOR_EDIT") {
      // 방문자 초대 수정
      await editVisitors(confirmModal.payload);
    } else if (confirmModal.type === "VISITOR_ADD") {
      // 방문자 초대 등록
      await addVisitors(confirmModal.payload);
    }
  };

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: VisitorFormData, e?: any) => {
    e.preventDefault();
    console.log(`data`, data);
    if (!!visitApplyNumber) {
      // 방문신청번호 있는 경우는 수정
      setConfirmModal({
        isOpen: true,
        type: "VISITOR_EDIT",
        children: (
          <div>
            수정하시겠습니까?
            <p>해당 방문자들에게 수정된 내용으로 다시 알림톡이 발송됩니다.</p>
          </div>
        ),
        payload: data,
      });
    } else {
      // 방문신청번호 없는 경우는 등록
      setConfirmModal({
        isOpen: true,
        type: "VISITOR_ADD",
        children: (
          <div>
            초대하시겠습니까?
            <p>
              초대 받은 방문자들에게는, 알림톡으로 사무실 출입을 위한 임시 QR코드가 전달되며, 주차
              지원 가능 시 자동 적용됩니다.
            </p>
          </div>
        ),
        payload: data,
      });
    }
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  // 파트너 상세 조회
  useEffect(() => {
    (async () => {
      if (!product?.partnerId) return;

      const result = await getPartnerAsync({
        id: product?.partnerId,
      });

      if (result.status >= 200 && result.status < 300) {
        setPartner(result.data.data.partner);
      }
    })();
  }, [product]);
  useTitleOperation(visitApplyNumber);

  return (
    <div>
      <ContentsTitle title="방문자" breadCrumbs={breadCrumbs} clickBreadCrumb={clickBreadCrumb} />
      <div className="contents-container__scroll">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="contents-container__wrap page-user-form">
            <div className="contents-container__wrap-contents">
              <ContentsIdSection title="방문자 초대" rightLables={contentsIdLables} />
              <article className="pb30">
                <div className="contents-container__sub-title">
                  <h2>방문 공간 및 일시</h2>
                </div>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>공간상품명</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>{product?.productName}</p>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>공간상품 주소</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>{primaryBuildingAddress}</p>
                  </div>
                </section>
                {/* 등록일 경우에만 방문 시작일시, 중료일시 입력 가능 */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">방문 시작일시</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div>
                      <div className="flex-center">
                        <div className="minmax140 mr10">
                          <Controller
                            control={control}
                            name="visitStartDate"
                            render={({ field: { value, name } }) => {
                              return (
                                <BaseDatePicker
                                  name={name}
                                  selectedDate={value ? moment(value).toDate() : null}
                                  minDate={moment().toDate()}
                                  readonly={!!visitApplyNumber} // 등록일 경우에만 방문 시작일시, 중료일시 입력 가능
                                  setDate={(date: Date) => {
                                    setValue("visitStartDate", date);
                                    trigger([
                                      "visitStartDate",
                                      "visitStartTime",
                                      "visitEndDate",
                                      "visitEndTime",
                                    ]);
                                  }}
                                />
                              );
                            }}
                          ></Controller>
                        </div>
                        <div className="minmax140">
                          <Controller
                            control={control}
                            name="visitStartTime"
                            render={({ field: { value, name } }) => {
                              return (
                                <BaseDatePicker
                                  type="time"
                                  timeIntervals={30}
                                  selectedDate={value}
                                  filteredTime={moment(value).toDate()}
                                  useFilterdTime={
                                    moment(getValues("visitStartDate")).format(
                                      YmdFormat.YYYY_MM_DD,
                                    ) === moment().format(YmdFormat.YYYY_MM_DD)
                                      ? true
                                      : false
                                  }
                                  readonly={!!visitApplyNumber} // 등록일 경우에만 방문 시작일시, 중료일시 입력 가능
                                  setDate={(date: Date) => {
                                    setValue("visitStartTime", date);
                                    trigger([
                                      "visitStartDate",
                                      "visitStartTime",
                                      "visitEndDate",
                                      "visitEndTime",
                                    ]);
                                  }}
                                  name={name}
                                />
                              );
                            }}
                          ></Controller>
                        </div>
                      </div>
                      {(errors.visitStartDate || errors.visitStartTime) && (
                        <p className="validation-text">
                          {errors.visitStartDate?.message || errors.visitStartTime?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className={visitApplyNumber ? "" : "required"}>방문 종료일시</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div>
                      <div className="flex-center">
                        <div className="minmax140 mr10">
                          <Controller
                            control={control}
                            name="visitEndDate"
                            render={({ field: { value, name } }) => {
                              return (
                                <BaseDatePicker
                                  name={name}
                                  selectedDate={value ? moment(value).toDate() : null}
                                  minDate={moment().toDate()}
                                  readonly={!!visitApplyNumber} // 등록일 경우에만 방문 시작일시, 중료일시 입력 가능
                                  setDate={(date: Date) => {
                                    setValue("visitEndDate", date);
                                    trigger([
                                      "visitStartDate",
                                      "visitStartTime",
                                      "visitEndDate",
                                      "visitEndTime",
                                    ]);
                                  }}
                                />
                              );
                            }}
                          ></Controller>
                        </div>
                        <div className="minmax140">
                          <Controller
                            control={control}
                            name="visitEndTime"
                            render={({ field: { value, name } }) => {
                              return (
                                <BaseDatePicker
                                  type="time"
                                  timeIntervals={30}
                                  selectedDate={value}
                                  useFilterdTime={
                                    moment(getValues("visitEndDate")).format(
                                      YmdFormat.YYYY_MM_DD,
                                    ) === moment().format(YmdFormat.YYYY_MM_DD)
                                      ? true
                                      : false
                                  }
                                  readonly={!!visitApplyNumber} // 등록일 경우에만 방문 시작일시, 중료일시 입력 가능
                                  setDate={(date: Date) => {
                                    setValue("visitEndTime", date);
                                    trigger([
                                      "visitStartDate",
                                      "visitStartTime",
                                      "visitEndDate",
                                      "visitEndTime",
                                    ]);
                                  }}
                                  filteredTime={moment(value).toDate()}
                                  excludeTimes={
                                    moment(getValues("visitEndDate")).format(
                                      YmdFormat.YYYY_MM_DD,
                                    ) === moment().format(YmdFormat.YYYY_MM_DD)
                                      ? [
                                          setHours(
                                            setMinutes(
                                              new Date(),
                                              Number(
                                                moment(getValues("visitStartTime")).format("mm"),
                                              ),
                                            ),
                                            Number(
                                              moment(getValues("visitStartTime")).format("HH"),
                                            ),
                                          ),
                                        ]
                                      : []
                                  }
                                  name={name}
                                />
                              );
                            }}
                          ></Controller>
                        </div>
                      </div>
                      {(errors.visitEndDate || errors.visitEndTime) && (
                        <p className="validation-text">
                          {errors.visitEndDate?.message || errors.visitEndTime?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </section>

                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>만남장소</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div>
                      <div className="flex-center">
                        <div className="minmax290">
                          <Controller
                            control={control}
                            name="visitMeetingPlace"
                            render={({ field: { value, name } }) => {
                              return (
                                <BaseInput
                                  name={name}
                                  value={value}
                                  maxLength={20}
                                  onChange={(place: string) => {
                                    setValue("visitMeetingPlace", place);
                                    trigger([
                                      "visitStartDate",
                                      "visitStartTime",
                                      "visitEndDate",
                                      "visitEndTime",
                                      "visitMeetingPlace",
                                    ]);
                                  }}
                                />
                              );
                            }}
                          ></Controller>
                        </div>
                      </div>
                      {errors.visitMeetingPlace && (
                        <p className="validation-text">{errors.visitMeetingPlace?.message}</p>
                      )}
                    </div>
                  </div>
                </section>
              </article>
              <article className="contents-container__divide-top">
                <div className="contents-container__sub-title">
                  <h2>파트너</h2>
                </div>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>파트너</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div>
                      <span>{`${partner?.name} (${partner?.id})`}</span>
                    </div>
                  </div>
                </section>
              </article>
              <article className="contents-container__divide-top pb30">
                <div className="contents-container__sub-title">
                  <h2>방문자</h2>
                </div>
                <VisitorsSection control={control} register={register} />
              </article>
            </div>
            {/* 버튼영역 */}
            <div className="contents-container__btn-wrap">
              <div className="left-area">
                <BaseButton
                  title="목록으로"
                  className="color-white size-large"
                  onClick={() => {
                    const visitorListPath = `${PagePath.visitor.list}?contractApplyNumber=${contractApplyNumber}`;
                    navigate(visitorListPath);
                  }}
                />
              </div>
              <div className="right-area">
                <BaseButton type="submit" title="저장" className="size-large" />
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* 확인버튼만 있는 alert 모달 */}
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => clickAlertModalConfirm()}
      >
        {alertModal?.children ? alertModal?.children : <p>{alertModal.message}</p>}
      </BaseModal>

      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickConfirmModalConfirm()}
      >
        {confirmModal?.children ? confirmModal?.children : <p>{confirmModal.message}</p>}
      </BaseModal>
    </div>
  );
};
export default VisitorForm;
