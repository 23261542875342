import { BaseTable } from "src/components/BaseTable";
import MemberColumns from "../columns/MemberColumns";
/**
 * types
 */
import { Order } from "../../../../api/member/member-types";

const ListTable = ({ params, data, pageMeta, navigateWithQueryParams }: any) => {
  // console.log("pageMeta", pageMeta);
  return (
    <BaseTable
      data={data}
      columns={MemberColumns}
      pageIndex={Number(params?.page || 0)}
      totalPages={pageMeta?.totalPages || 0}
      goPage={(page: number) => {
        navigateWithQueryParams({ page });
      }}
      disabledSortHeaders={["organizations", "states", "accountState", "emailVerified"]}
      orders={params?.sort?.orders}
      setOrders={(orders?: Array<Order>) => {
        navigateWithQueryParams({ sort: { orders } });
      }}
    />
  );
};
export default ListTable;
