import React, { useMemo } from "react";
import { BuildingModel, BuildingParkingLotModel } from "src/api/building/building-types";
import { numberToStringWithComma } from "src/utils";

type Props = {
  building: BuildingModel | null;
};

const ProductParkingSection = ({ building }: Props) => {
  const parking: BuildingParkingLotModel | null = useMemo(() => {
    const parkingLotList = building?.buildingCommonFacility?.parkingLotList || [];
    return parkingLotList.length > 0 ? parkingLotList[0] : null;
  }, [building]);

  return (
    <section className="contents-container__grid contents-container__1200 border-gray">
      <div className="contents-container__grid-contents">
        <div className=" mb10 w-100">
          <div className="pa16">
            {parking ? (
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">
                    {`자주식${
                      parking?.selfPropelledParkingLotNums || 0
                    } (차량 ${numberToStringWithComma(
                      parking?.selfPropelledParkingLotTotalNums || 0,
                    )}대), 기계식${
                      parking?.mechanicalParkingLotNums || 0
                    } (차량 ${numberToStringWithComma(
                      parking?.mechanicalParkingLotTotalNums || 0,
                    )}대)`}
                  </span>
                </div>
                <div className="minmax400">
                  <span className="font14 pre-formatted">{parking?.description}</span>
                </div>
              </div>
            ) : (
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">데이터가 없습니다.</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductParkingSection;
