export const iotPlatformToString = (platform: string) => {
  switch (platform) {
    case "ST":
      return "SmartThings";
    case "B_IOT":
      return "b.IoT";
    default:
      return "-";
  }
};
export const smartThingsControlMethodToString = (method: string) => {
  switch (method) {
    case "READ_STATUS":
      return "상태 조회";
    case "EXEC_COMMANDS":
      return "명령";
    default:
      return "-";
  }
};
