import {
  QuestionCategory,
  QuestionSite,
  QuestionStatus,
  QuestionType,
} from "src/api/question/question-types";

// 상담관리 유형 옵션
export const questionTypeOptions = [
  { label: "전체", value: "" },
  {
    label: "1:1문의",
    value: QuestionType.QNA,
  },
  {
    label: "견적문의",
    value: QuestionType.QUOTECONTACT,
  },
  {
    label: "상담신청",
    value: QuestionType.COUNSELING,
  },
  {
    label: "투어신청",
    value: QuestionType.TOUR,
  },
  {
    label: "VoC 일반",
    value: QuestionType.VOC_NOMAL,
  },
  {
    label: "VoC 워크오더",
    value: QuestionType.VOC_WORKORDER,
  },
  {
    label: "공용공간 예약",
    value: QuestionType.QUESTIONTYPE_RESERVATION,
  },
];

// 등록방식 옵션
export const questionCategoryOptions = [
  { label: "전체", value: "" },
  { label: "온라인", value: QuestionCategory.ONLINE },
  { label: "오프라인", value: QuestionCategory.OFFLINE },
];

// 상태 옵션
export const statusOptions = [
  { label: "전체", value: "" },
  {
    label: "접수",
    value: QuestionStatus.WAIT,
    tagColor: "status A",
    color: "color-blue",
    isDisabled: false,
  },
  {
    label: "처리중",
    value: QuestionStatus.PROGRESS,
    tagColor: "status B",
    color: "color-green",
    isDisabled: false,
  },
  {
    label: "보류",
    value: QuestionStatus.HOLD,
    tagColor: "status C",
    color: "color-red",
    isDisabled: false,
  },
  {
    label: "완료",
    value: QuestionStatus.COMPLETE,
    tagColor: "status D",
    color: "color-gray",
    isDisabled: false,
  },
  {
    label: "취소",
    value: QuestionStatus.CANCELED,
    tagColor: "status D",
    color: "color-gray",
    isDisabled: false,
  },
];

// 유입경로 옵션
export const siteOptions = [
  { label: "전체", value: "" },
  { label: "웹사이트", value: QuestionSite.SITE_HOMEPAGE },
  { label: "외부 플랫폼", value: QuestionSite.EXTERNAL },
  { label: "기타", value: QuestionSite.SITE_ETC },
  { label: "TaapSpace", value: QuestionSite.SITE_TAAP_SPACE },
  { label: "Taap", value: QuestionSite.TAAP },
];

// 검색옵션
export const questionSearchTypes = [
  { value: "", label: "전체" },
  { value: "SUMMARY", label: "항목" },
  { value: "QUESTION_REPORTER_NAME", label: "문의자" },
  { value: "QUESTION_DESCRIPTION", label: "문의내용" },
  { value: "QUESTION_ASSIGNEE_EMAIL", label: "담당자" },
  { value: "NAME", label: "최종 수정자" },
];

// 구분 옵션
export const csCategoryOptions = [
  { label: "전체", value: "" },
  { label: "VoC", value: "VoC" },
  { label: "문의", value: "문의" },
];

export const MIN_TEXTAREA_HEIGHT = 160;

// 답변 방식
export const answerGubunOptions = [
  { label: "이메일", value: "1" },
  { label: "유선", value: "2" },
  { label: "답변 제외(기록용)", value: "3" },
];
