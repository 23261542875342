import { useApiOperation } from "src/api/hooks";
import { getMembers } from "src/api/member/member-api";
import DashboardSection from "./components/DashboardSection";
import { MEMBER_MENU_LIST } from "./constants";
import { useSectionMenuList } from "./hooks";
import { MenuStatus } from "./types";

function DashboardMemberSection() {
  const { executeAsync: getMemberList } = useApiOperation(getMembers);

  const getMemberListTotalElements = async ({ status }: MenuStatus) => {
    const { data } = await getMemberList({
      page: 0,
      size: 1,
      role: "ROLE_MEMBER",
      accountStateStatus: status as string,
    });
    return data.meta.pageMeta?.totalElements ?? -1;
  };

  const { menuList } = useSectionMenuList({
    baseMenuList: MEMBER_MENU_LIST,
    getMenuListCounts: getMemberListTotalElements,
  });

  return <DashboardSection title={"회원"} menuList={menuList} />;
}

export default DashboardMemberSection;
