import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { useApiOperation } from "src/api/hooks";
import { getMembers } from "src/api/member/member-api";
import { BaseInput, BaseModal, BasePagination, BaseRadio } from "src/components";

const columnHeader: any = [
  {
    Header: "",
    accessor: "isSelected",
    // sticky: "left",
    width: 130,
    Cell: ({ row, setSelectedMember }: any) => {
      const changeTrigger = useCallback(() => {
        setSelectedMember(row.original);
      }, [row.original, setSelectedMember]);
      return (
        <div>
          <BaseRadio
            id={`selector${row.original.id}`}
            name={"isSelected"}
            onChange={changeTrigger}
          />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "id",
    // sticky: "left",
    width: 130,
  },
  {
    Header: "회원번호",
    accessor: "memberNo",
    width: 200,
  },
  {
    Header: "휴대폰번호",
    accessor: "phoneNumber",
    width: 200,
  },
  {
    Header: "이메일",
    accessor: "email",
    width: 200,
  },
];
type Props = {
  isOpen: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const SearchMemberPopup = (props: Props) => {
  const [data, setData] = useState<any>([]);
  const [selectedMember, setSelectedMember] = useState<any>();
  const [pageMeta, setPageMeta] = useState<any>({});
  const [search001, setSearch001] = useState("");
  const [params, setParams] = useState<any>({
    page: 0,
    size: 20,
    search001: "",
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columnHeader,
      data: data,
      setSelectedMember,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );

  //조회
  const { executeAsync: executeAsyncAll } = useApiOperation(getMembers);

  const onClickUser = useCallback(() => {
    if (props.onClick) {
      props.onClick(selectedMember);
    }
  }, [props, selectedMember]);

  const getMemberList = useCallback(

    async (param: any) => {
      const response: any = await executeAsyncAll(param);
      if (response) {

        for (let i = 0; i < response?.data?.data?.content.length; i++) {
          response.data.data.content[i] = Object.assign(response.data.data.content[i], {
            isSelected: false,
          });
        }
        setData(response?.data?.data?.content || []);
        setPageMeta(response?.data?.meta?.pageMeta);

      } else {

      }
    },
    [executeAsyncAll],
  );

  useEffect(() => {
    if (props.isOpen) {

      let send = _.cloneDeep(params);
      send.notEmptyMemberNo = true;
      send.accountStateStatus = "AVAILABLE";
      send.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
      send.page = 0;
      send.search001 = "";
      setSearch001("");
      getMemberList(send);
      setParams(send);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <div>
      <BaseModal
        className="dialog-modal"
        isOpen={props.isOpen}
        btnRightTitle="선택"
        btnLeftTitle="취소"
        onClick={() => onClickUser()}
        onClose={props.onClose}
      >
        <>
          <div className="modal-title">
            <div className="left-area">
              <span>회원 선택</span>
            </div>
            <div className="right-area">
              <div className="minmax260">
                <BaseInput
                  type="text"
                  value={search001 || ""}
                  placeholder="검색어를 입력해주세요"
                  onChange={(value: string) => {
                    setSearch001(value);
                    setParams({ ...params, ...{ search001: value, page: 0 } });
                  }}
                  onKeyUp={() => {
                    getMemberList({ ...params });
                    setParams({ ...params });
                    setParams({ ...params, ...{ page: 0 } });
                  }}

                  onSearchClick={() => {
                    getMemberList({ ...params, ...{ search001, page: 0 } });
                    setParams({ ...params, ...{ search001, page: 0 } });

                  }}
                />
              </div>
            </div>
          </div>
          {/* table */}
          <div className="contents-container__table">
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <div
                      {...row.getRowProps()}
                      className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <BasePagination
              pageIndex={params.page || 0}
              totalPages={pageMeta.totalPages || 0}
              goPage={(page: number) => {
                setParams({ ...params, ...{ page } });
                getMemberList({ ...params, ...{ page } });
              }}
            />
          </div>
        </>
      </BaseModal>
    </div>
  );
};

export default SearchMemberPopup;
