import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import pagePath from "../../pagePath.json";

/* 
  html title 바인딩 hook.

  사용예시
  import useTitleOperation from "src/pages/hooks/title-operation";
  useTitleOperation("루피움빌딩"); // 항목명

  Central 앞에 올 {항목명} 문자열만 넘겨주면됨
  - 목록, 등록 title: {메뉴명} | Central
  예시) '건물 | Central'
  - 상세, 수정 title: {메뉴명} | {항목명} | Central
  예시) '건물 | 루피움빌딩 | Central'
 */
export default function useTitleOperation(item?: string) {
  const location = useLocation();
  const CENTRAL = `Central`;

  const findEnv = () => {
    switch (process.env.REACT_APP_ENV) {
      case "local":
        return `local ${CENTRAL}`;
      case "dev":
        return `dev ${CENTRAL}`;
      case "stg":
        return `stg ${CENTRAL}`;
      case "prod":
        return `${CENTRAL}`;
      default:
        return `${CENTRAL}`;
    }
  };

  const addOrEditCheck = (locationPath: any) => {
    let result = "";

    if (
      !locationPath.search.includes("?id=") &&
      !locationPath.search.includes("?visitApplyNumber=")
    ) {
      result = "등록 | ";
    }

    return result;
  };

  const findPageType = (locationPath: any, mainTitle: string) => {
    let title = "";

    if (locationPath.pathname.includes("form") || locationPath.pathname.includes("add")) {
      title = addOrEditCheck(locationPath) + mainTitle;
    } else {
      title = mainTitle;
    }
    return title;
  };

  useEffect(() => {
    let titles = [];
    if (location.pathname.includes(pagePath.maintenance.access)) {
      titles.push(findPageType(location, "BS2 모니터링"));
    } else if (location.pathname.includes(pagePath.building.list)) {
      titles.push(findPageType(location, "건물"));
    } else if (location.pathname.includes(pagePath.workOrder.list)) {
      titles.push(findPageType(location, "워크오더"));
    } else if (location.pathname.includes(pagePath.product.list)) {
      titles.push(findPageType(location, "공간상품"));
    } else if (location.pathname.includes(pagePath.billing.list)) {
      titles.push(findPageType(location, "빌링 스케쥴"));
    } else if (location.pathname.includes(pagePath.provider.list)) {
      titles.push(findPageType(location, "정산정보"));
    } else if (location.pathname.includes(pagePath.reservation.list)) {
      titles.push(findPageType(location, "공용공간 예약"));
    } else if (location.pathname.includes(pagePath.contract.list)) {
      titles.push(findPageType(location, "신청/계약"));
    } else if (location.pathname.includes(pagePath.member.list)) {
      titles.push(findPageType(location, "회원"));
    } else if (location.pathname.includes(pagePath.organization.list)) {
      titles.push(findPageType(location, "사업자"));
    } else if (location.pathname.includes(pagePath.user.list)) {
      titles.push(findPageType(location, "이용자"));
    } else if (location.pathname.includes(pagePath.visitor.list)) {
      titles.push(findPageType(location, "방문자"));
    } else if (location.pathname.includes(pagePath.notice.list)) {
      titles.push(findPageType(location, "공지사항"));
    } else if (location.pathname.includes(pagePath.main)) {
      titles.push(findPageType(location, "대시보드"));
    } else if (
      location.pathname.includes(pagePath.partner.list) &&
      !location.pathname.includes("partner-member")
    ) {
      titles.push(findPageType(location, "파트너"));
    } else if (location.pathname.includes(pagePath.partnerMember.list)) {
      titles.push(findPageType(location, "파트너 회원"));
    } else if (location.pathname.includes(pagePath.accessDevice.list)) {
      titles.push(findPageType(location, "출입장치 관리"));
    } else if (location.pathname.includes(pagePath.accessGroup.list)) {
      titles.push(findPageType(location, "출입그룹 관리"));
    }
    // 항목

    if (item && item !== undefined && item !== "" && Number(item) !== 0) {
      titles.push(item);
    }

    document.title = [...titles, findEnv()].join(" | ");
  }, [item, location.pathname]);
}
