import { AxiosInstance, AxiosResponse } from "axios";
import { ApiPath } from "src/api";
import { ApiResponse } from "../public-types";
import { ProductInfoAddModel } from "./product-types";

// CPP07. 공간상품 상품정보 (등록/수정)
export async function createProductInfoAsync(
  axios: AxiosInstance,
  params?: { productId: number | string; product: ProductInfoAddModel },
): Promise<AxiosResponse<ApiResponse<{ content?: string }>>> {
  const path = ApiPath.product.add.productInfo.replace("{id}", String(params?.productId));
  return await axios.post(path, params);
}
