import { useContext } from "react";
import { ToastContext } from "src/contexts/ToastContext";

/* 
  토스트 context hook

  사용예시
  import useToastContext from "src/pages/hooks/toast-context";
  const { openToast } = useToastContext();

  openToast("정상적으로 등록되었습니다.");
*/
export default function useToastContext() {
  return useContext(ToastContext);
}
