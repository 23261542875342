import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getPrImageAsync } from "src/api/product/product-api";
import { MediaFile, MediaServiceType } from "src/api/public-types";
import { BaseButton, ContentsIdSection } from "src/components";
import { PagePath } from "src/pages/product/details";
import { buildingMediaServiceType } from "../../building-types";
import ImageCategoryDetail from "./components/ImageCategoryDetail";

const buildingMediaTypes: MediaServiceType[] = buildingMediaServiceType;

const BuildingImagesDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const buildingId: number | undefined = Number(params.id);

  //========= Building ============
  const [images, setImages] = useState<Array<MediaFile>>([]);

  const { executeAsync: getBuildingImages } = useApiOperation(getPrImageAsync);

  // 이미지 불러오기
  const getProductImagesApi = useCallback(async (serviceId: number) => {
    const data = {
      serviceId,
      mediaServiceTypes: buildingMediaServiceType,
    };

    const response = await getBuildingImages(data);
    if (response.status >= 200 || response.status < 300) {
      const result = response.data.data.content;

      setImages(result || []);
    }
  }, []);

  useEffect(() => {
    if (buildingId) {
      getProductImagesApi(buildingId);
    }
  }, [getProductImagesApi, buildingId]);

  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection title="이미지" />

        {buildingMediaTypes &&
          buildingMediaTypes.map((type) => (
            <ImageCategoryDetail key={type} type={type} images={images} />
          ))}

        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              className="color-white size-large"
              onClick={() => navigate(PagePath.building.list)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingImagesDetail;
