import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Order } from "src/api/public-types";
import { CsTypeOptions, VocQueryParams } from "src/api/voc/voc-types";
import { BaseInput, BaseSelect, BaseTable } from "src/components";
import BaseMultiSelect from "src/components/BaseMultiSelect";
import { useVocList } from "../hooks/useVocList";
import VocColumns from "./columns/VocColumns";
import { useCsOptions } from "../hooks/useCsOptions";

const VocList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams: VocQueryParams = useMemo(() => {
    const query: VocQueryParams = qs.parse(location.search, {
      allowDots: true,
      ignoreQueryPrefix: true,
    });
    if (!query.page) {
      query.page = 0;
    }
    if (!query.size) {
      query.size = 20;
    }

    if (!query?.sort) {
      query.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    if (query?.keywordList) {
      query.keywordList = decodeURIComponent(query.keywordList);
    }

    return query;
  }, [location]);

  const [params, setParams] = useState<VocQueryParams>();

  // voc 유형
  const { options: vocOptions } = useCsOptions("VoC");

  // voc 리스트
  const { list: vocList, pageMeta } = useVocList(queryParams);

  useEffect(() => {
    setParams(queryParams);
  }, [queryParams]);

  const navigateWithQueryParams = useCallback(
    (passParams?: VocQueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      if (newQueryParams.frontCsTypeIdList) {
        delete newQueryParams.frontCsTypeIdList;
      }
      if (newQueryParams.csTypeIdList === "") {
        delete newQueryParams.csTypeIdList;
      }

      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });

      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [location, params, queryParams],
  );

  return (
    <div className="page-voc-list">
      <div className="contents-container__search-wrap">
        <div className="left-area">
          <div className="minmax200 mr8">
            <BaseMultiSelect
              stateOptions={[{ label: "전체", value: "" }, ...CsTypeOptions]}
              value={(queryParams?.csTypeIdList?.split(",").map(String) as string[]) || []}
              setStateValue={(csTypeIdList: Array<{ value: string; label: string }>) => {
                navigateWithQueryParams({
                  ...params, //
                  csTypeIdList: csTypeIdList.map((item) => item.value).join(","),
                  page: 0,
                });
              }}
              placeholder="유형"
            />
          </div>
          <div className="minmax120 mr8">
            <BaseSelect
              placeholder="검색 옵션"
              value={params?.searchType}
              stateOptions={[{ label: "전체", value: "" }, ...vocOptions]}
              setStateValue={(searchType: string) => setParams({ ...params, searchType })}
            />
          </div>

          <BaseInput
            value={params?.keywordList || ""}
            className="minmax220 mr8"
            type="text"
            placeholder="검색어를 입력하세요"
            onChange={(keywordList: string) => setParams({ ...params, keywordList })}
            onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
            onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
          />
        </div>
      </div>
      <BaseTable
        data={vocList}
        pageIndex={Number(queryParams?.page || 0)}
        // totalElements={pageMeta?.totalElements}
        totalPages={pageMeta?.totalPages || 0}
        goPage={(page: number) => {
          navigateWithQueryParams({ page }, "pagination");
        }}
        orders={queryParams?.sort?.orders}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } }, "search");
          }
        }}
        disabledSortHeaders={["csTypeName"]}
        columns={VocColumns}
      />
    </div>
  );
};

export default VocList;
