import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SpaceProductType } from "src/api/contract/contract-types";
import { BaseButton, BaseCheckbox } from "src/components";
import pagePath from "src/pagePath.json";
import { findPgCode, formatPhoneNumber, numberToStringWithComma, YmdFormat } from "src/utils";
import TextNFileDownload from "./../modal/components/TextNFileDownload";
import ModifyAmountModal from "./../modal/ModifyAmountModal";
import SnapShotModal from "./../modal/SnapShotModal";
import OverdueFeePopup from "./OverdueFeePopup";

const ChargeMntType = ({
  basicInfo,
  organization,
  contract,
  proof,
  bi,
  document,
  require,
  spaceProductType,
}: any) => {
  const [textGrey, setTextGrey] = useState("contents-container__grid ");
  const [isSnapShotOpen, setIsSnapShotOpen] = useState(false);
  const [isModifyAmountOpen, setIsModifyAmountOpen] = useState(false);
  const [isOverdueFeeOpen, setIsOverdueFeeOpen] = useState(false);
  const [overdueFeeList, setOverdueFeeList] = useState<any>([]);

  const goMemberDetail = (id: number) => {
    let path = "";
    if (id) {
      path = pagePath.member.detail.replace(":id", id!.toString());
    }
    return path;
  };

  const moveOrg = useCallback((id: number) => {
    let rtn = "";
    if (id) {
      rtn = pagePath.organization.detail.replace(":id", String(id));
    }
    return rtn;
  }, []);

  useEffect(() => {
    if (spaceProductType === SpaceProductType.TIME_COURT) {
      setTextGrey("contents-container__grid not-available");
    } else {
      setTextGrey("contents-container__grid ");
    }
    // console.log("contract", contract);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log("contract", contract);
  return (
    <>
      <article className="pb20">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>{"이용기간"}</h2>
          </div>
          {/* 이용 시작 */}

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>이용 시작</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {contract?.contractStartTime
                    ? moment(contract?.contractStartTime).format(YmdFormat.YYYY_MM_DD)
                    : "-"}
                </p>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>기간(MM)</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{contract?.contractTerm ? contract?.contractTerm + "개월" : "-"}</p>
              </div>
            </section>
          </div>

          <div className="flex-center">
            {/* 이용종료 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>이용 종료</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {contract?.contractEndTime
                    ? moment(contract?.contractEndTime).format(YmdFormat.YYYY_MM_DD)
                    : "-"}
                </p>
              </div>
            </section>
            {/* 계약일시 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약일시</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {contract?.contractDate
                    ? moment(contract?.contractDate).format(YmdFormat.YYYY_MM_DD_HH_MM)
                    : "-"}
                </p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>빌링 기간</h2>
            {/* 가이드메시지 수정에서만 노출 */}
            {/* <p className="contents-container__sub-title-info">
                계약자 사업자등록증을 참조하시어 사업자 정보를 정확하게 입력해 주세요.
              </p> */}
          </div>
          <div className="flex-center">
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>빌링 시작</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <p>{moment(contract?.scheduleStartTime).format(YmdFormat.YYYY_MM_DD)}</p>
                </div>
              </div>
            </section>
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>빌링 종료</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <p>{moment(contract?.scheduleEndTime).format(YmdFormat.YYYY_MM_DD)}</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>계약자 정보</h2>
            {/* 가이드메시지 수정에서만 노출 */}
            {/* <p className="contents-container__sub-title-info">
                계약자 사업자등록증을 참조하시어 사업자 정보를 정확하게 입력해 주세요.
              </p> */}
          </div>
          <div className="flex-center">
            {/* 사업자 등록번호 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>회원 번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <Link
                    to={goMemberDetail(contract?.contractorMemberNo)}
                    className="text-hilight"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {contract?.contractorMemberNo}
                  </Link>
                </div>
              </div>
            </section>
            {/* 사업자 유형 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>휴대폰</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{formatPhoneNumber(contract?.contractorPhoneNumber)}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 사업자 등록번호 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>이메일</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{contract?.contractorEmail}</p>
              </div>
            </section>
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>계약자명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{contract?.applicantName}</p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>사업자 정보</h2>
            {/* 가이드메시지 수정에서만 노출 */}
            {/* <p className="contents-container__sub-title-info">
                계약자 사업자등록증을 참조하시어 사업자 정보를 정확하게 입력해 주세요.
              </p> */}
          </div>
          <div className="flex-center">
            {/* 사업자 등록번호 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>사업자 등록번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  {organization ? (
                    <Link className="text-hilight" to={moveOrg(organization?.id)} target="_blank">
                      {organization?.bizRegNo ? organization?.bizRegNo : "-"}
                    </Link>
                  ) : (
                    <p>-</p>
                  )}
                </div>
              </div>
            </section>
            {/* 사업자 유형 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>사업자 유형</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {organization?.bizType
                    ? organization?.bizType === "CORP"
                      ? "법인사업자"
                      : "일반사업자"
                    : "-"}
                </p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 사업자 등록증 첨부 */}
            <TextNFileDownload
              title={"사업자 등록증 첨부"}
              fileList={proof}
              classname={"minmax300"}
            />
            {/* 법인명/상호 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>법인명/상호</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.name ? organization?.name : "-"}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 사업장 주소 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>사업장 주소</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.address ? organization?.address : "-"}</p>
              </div>
            </section>
            {/* 대표자/성명 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>대표자/성명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.ceoName ? organization?.ceoName : "-"}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 과세 유형 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>과세 유형</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {organization?.taxType === "GENERAL" && "일반"}
                  {organization?.taxType === "SIMPLE" && "간이"}
                  {organization?.taxType === "FREE" && "면세"}
                  {organization?.taxType === "ETC" && "기타"}
                  {!organization?.taxType && "-"}
                </p>
              </div>
            </section>
            {/* 업태 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>업태</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.bizCondition ? organization?.bizCondition : "-"}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            <TextNFileDownload
              title={"CI/BI"}
              fileList={bi}
              classname={"minmax300"}
              titleBrFlag={true}
              title2={"첨부(Taap 출력용)"}
            />
            {/* 종목 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>종목</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{organization?.bizItem ? organization?.bizItem : "-"}</p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>계약 정보</h2>
            {/* 가이드메시지 수정에서만 노출 */}
            {/* <p className="contents-container__sub-title-info">
                계약 체결 후 계약서와 계약 필요 서류를 등록해 주세요.
              </p> */}
          </div>
          <div className="flex-center">
            {/* 계약 번호 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약 번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{contract?.contractNumber ? contract?.contractNumber : "-"}</p>
              </div>
            </section>
            {/* 재계약 상태 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>재계약 상태</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 계약 데이터 상세 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약 데이터 상세</p>
              </div>
              <div className="contents-container__grid-contents">
                {textGrey !== "" && (
                  <BaseButton
                    title="스냅샷 보기"
                    className="color-white"
                    disabled={
                      basicInfo?.contractStep === "APPLY_RECEIVED" ||
                      basicInfo?.contractStep === "APPLY_CANCEL" ||
                      basicInfo?.contractStep === "APPLY_CONFIRM"
                    }
                    onClick={() => setIsSnapShotOpen(true)}
                  />
                )}
                <SnapShotModal
                  isOpen={isSnapShotOpen}
                  contractId={contract?.contractId}
                  onClose={() => {
                    setIsSnapShotOpen(false);
                  }}
                />
              </div>
            </section>
            {/* 계약금 */}
            <section className="contents-container__grid not-available">
              <div className="contents-container__grid-index">
                <p>이전 계약 정보</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 계약금(원) */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>계약금(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
            {/* 잔금 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>잔금(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
          </div>
          <div className="flex-start">
            {/* 계약서 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>계약서</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>N/A</div>
              </div>
            </section>
            {/* 계약 필요 서류 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>계약 필요 서류</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>N/A</div>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>이용료 결제 정보</h2>
          </div>
          <div className="flex-center">
            {/* 빌링스케줄id */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>빌링스케줄id</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>N/A</div>
              </div>
            </section>
            {/* 결제 타입 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>자동 결제/수납</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>적용안함</p>
              </div>
            </section>
          </div>

          <div className="flex-center">
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>정기청구일</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>

            {/* 결제금액(원) */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>결제금액(원)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <span className="mr16">N/A</span>
                  <BaseButton
                    title="회차별 금액 확인"
                    className="color-white"
                    disabled={
                      basicInfo?.contractStep === "APPLY_RECEIVED" ||
                      basicInfo?.contractStep === "APPLY_CANCEL" ||
                      basicInfo?.contractStep === "APPLY_CONFIRM" ||
                      basicInfo?.contractStep === "CONTRACT_PLAN"
                    }
                    onClick={() => setIsModifyAmountOpen(true)}
                  />
                  <ModifyAmountModal
                    contractId={contract?.contractId}
                    isOpen={isModifyAmountOpen}
                    onClose={() => {
                      setIsModifyAmountOpen(false);
                    }}
                    onClick={() => {}}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>납부기한</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>

            {/* 결제금액(원) */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>관리비 청구 유형</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{contract?.isMntContain ? "관리비 포함" : "관리비 별도"}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>결제 방법</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
            {/* 결제 타입 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>결제 타입</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 결제 방법 */}
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>공문 자동 발송</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>

            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>세금계산서 자동 발행</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>공문 종류</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <p>N/A</p>
                </div>
              </div>
            </section>

            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>세금계산서 발행일</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>N/A</p>
              </div>
            </section>
          </div>

          <div className="flex-center">
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>알림톡 종류</p>
              </div>
              <div>
                <p>N/A</p>
              </div>
            </section>
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>결제 상태</p>
              </div>
              <div className="contents-container__grid-contents">
                <p className="mr16">N/A</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>연체 요율</p>
              </div>
              <div className="contents-container__grid-contents">
                <BaseButton
                  title="연체 요율 관리"
                  className="color-white"
                  disabled={true}
                  onClick={() => setIsOverdueFeeOpen(true)}
                />
                {/* <OverdueFeePopup
                  isOpen={isOverdueFeeOpen}
                  data={"day"}
                  overdueFeeList={overdueFeeList}
                  setOverdueFeeList={setOverdueFeeList}
                /> */}
              </div>
            </section>
            <section className={textGrey}>
              <div className="contents-container__grid-index">
                <p>입금자명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p className="mr16">N/A</p>
              </div>
            </section>
          </div>
        </div>
      </article>

      <article className={`contents-container__divide-top`}>
        <div className="contents-container__1200">
          <div
            className={`contents-container__sub-title ${
              contract?.isMntContain === false ? "not-available" : ""
            }`}
          >
            <h2>관리비 결제 정보</h2>
          </div>

          {
            // contract?.isMntContain &&
            contract?.scheduleList.length > 0 &&
              contract?.scheduleList?.map((schedule: any, index: number) => {
                return (
                  <div className="ml30 contents-container__divide-top" key={index}>
                    <div className="flex-center">
                      {/* 프로바이더id */}
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>프로바이더id</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <div>
                            <Link
                              to={pagePath.provider.detail.replace(":id", schedule?.providerId)}
                              target="_blank"
                              className="text-hilight"
                            >
                              {schedule?.providerId}
                            </Link>
                          </div>
                        </div>
                      </section>
                      {/* 결제 타입 */}
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>관리처 명</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <p className="text-red600">
                            {schedule?.isAutoBilling
                              ? schedule?.isAutoBilling === "true"
                                ? "적용"
                                : "적용안함"
                              : "-"}
                          </p>
                        </div>
                      </section>
                    </div>

                    <div className="flex-center">
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>결제방법</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <p>
                            {schedule?.regularPgcode ? findPgCode(schedule?.regularPgcode) : "-"}
                          </p>
                        </div>
                      </section>

                      {/* 결제금액(원) */}
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>결제금액(원)</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <div className="flex-row flex-center-start ">
                            <span className="mr16">
                              {schedule?.payAmount
                                ? numberToStringWithComma(schedule?.payAmount)
                                : "-"}
                            </span>
                            <BaseButton
                              title="회차별 금액 확인"
                              className="color-white"
                              disabled={
                                basicInfo?.contractStep === "APPLY_RECEIVED" ||
                                basicInfo?.contractStep === "APPLY_CANCEL" ||
                                basicInfo?.contractStep === "APPLY_CONFIRM"
                              }
                              onClick={() => setIsModifyAmountOpen(true)}
                            />
                            <ModifyAmountModal
                              contractId={schedule?.contractId}
                              isOpen={isModifyAmountOpen}
                              onClose={() => {
                                setIsModifyAmountOpen(false);
                              }}
                              onClick={() => {}}
                            />
                          </div>
                        </div>
                      </section>
                    </div>
                    <div className="flex-center">
                      {/* 결제 타입 */}
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>결제 타입</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <p>
                            {schedule?.contractPayType
                              ? schedule?.contractPayType === "PAY"
                                ? "일반결제"
                                : "세금계산서 발행"
                              : "-"}
                          </p>
                        </div>
                      </section>

                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>결제 상태</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <p className="mr16">
                            {contract?.billPayStatus === "PAYMENT_READY" && "결제대기"}
                            {contract?.billPayStatus === "PAYMENT_SUCCESS" && "성공"}
                            {contract?.billPayStatus === "PAYMENT_FAIL" && "실패"}
                            {contract?.billPayStatus === "PAYMENT_CANCEL" && "취소"}
                            {contract?.billPayStatus === "CONTRACT_CANCEL" && "해지"}
                            {(contract?.billPayStatus === undefined ||
                              contract?.billPayStatus === "") &&
                              "-"}
                          </p>
                        </div>
                      </section>
                    </div>

                    <div className="flex-center">
                      {/* 공문 자동발송 */}
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>공문 자동발송</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <p className="mr16">
                            {String(schedule?.isAutoSend) === "true" ? "발송" : "미발송"}
                          </p>
                        </div>
                      </section>
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>입금자명</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <p className="mr16">
                            {schedule?.accountInName ? schedule?.accountInName : "-"}
                          </p>
                        </div>
                      </section>
                    </div>
                    <div className="flex-center">
                      {/* 공문 자동발송 */}
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>공문 종류</p>
                        </div>
                        <div>
                          <p>{schedule?.messageList?.length === 0 && "-"}</p>

                          {schedule?.messageList?.map((list: any, idx3: number) => {
                            // let classN = "ml10";

                            let label = "";
                            if (list.messageMedia !== "KAKAOTALK") {
                              switch (list.messageType) {
                                case "UNPAID_NOTICE":
                                  label = "미납 안내";
                                  break;
                                case "CONTRACT_ACCEPT_NOTICE":
                                  label = "계약체결 안내";
                                  break;
                                case "OVERDUE_NOTICE":
                                  label = "연체료 안내";
                                  break;
                                case "CHARGE_NOTICE":
                                  label = "결제일 안내";
                                  break;
                              }

                              return (
                                <div key={idx3}>
                                  <BaseCheckbox
                                    className={`mb5 ${idx3 === 0 ? "mt10" : ""}`}
                                    name={"innerBillMessageList"}
                                    checked={list.isSend}
                                    id={"innerBillMessageList" + idx3}
                                    label={label}
                                    disabled={true}
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      </section>
                      {/* 연체 수수료(%) */}
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>연체료 적용</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <p>{schedule?.isOverdueFee ? "적용" : "적용 안함"}</p>
                        </div>
                      </section>
                    </div>
                    <div className="flex-center">
                      {/* 연체 수수료(%) */}
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>알림톡 종류</p>
                        </div>
                        <div>
                          <p>{schedule?.messageList?.length === 0 && "-"}</p>

                          {schedule?.messageList?.map((list: any, idx4: number) => {
                            // let classN = "ml10";

                            let label = "";
                            if (list.messageMedia === "KAKAOTALK") {
                              switch (list.messageType) {
                                case "UNPAID_NOTICE":
                                  label = "미납 안내";
                                  break;
                                case "CONTRACT_ACCEPT_NOTICE":
                                  label = "계약체결 안내";
                                  break;
                                case "OVERDUE_NOTICE":
                                  label = "연체료 안내";
                                  break;
                                case "CHARGE_NOTICE":
                                  label = "결제일 안내";
                                  break;
                              }

                              return (
                                <div key={idx4}>
                                  <BaseCheckbox
                                    className={`mb5 ${idx4 === 0 ? "mt10" : ""}`}
                                    name={"talkBillMessageList"}
                                    checked={list.isSend}
                                    id={"talkBillMessageList" + idx4}
                                    label={label}
                                    disabled={true}
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      </section>

                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>연체 요율</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <p>연체 요율 팝업</p>
                        </div>
                      </section>
                    </div>
                  </div>
                );
              })
          }
        </div>
      </article>

      <article className="contents-container__divide-top">
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>공문/알림톡 수신</h2>
          </div>
          {contract?.billContactList?.map((list: any, index: number) => {
            return (
              <div className="flex-center" key={index}>
                {list.receiveNotiType === "TALK_OFFICIALDOC" && (
                  <>
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p>공문 수신 이메일</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <p>{list?.email ? list?.email : "-"}</p>
                      </div>
                    </section>
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p>알림톡 수신 휴대폰</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <p>{list?.contact ? formatPhoneNumber(list?.contact) : "-"}</p>
                      </div>
                    </section>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </article>
      {contract?.contractPayType === "TAX" && (
        <article className="contents-container__divide-top">
          <div className="contents-container__1200">
            <div className="contents-container__sub-title">
              <h2>세금계산서 수신</h2>
            </div>

            {contract &&
              contract?.billContactList.length > 0 &&
              contract?.billContactList?.map((list: any, index: number) => {
                return (
                  <div key={index}>
                    {list.receiveNotiType !== "TALK_OFFICIALDOC" && (
                      <>
                        <section className="contents-container__grid">
                          <div className="contents-container__grid-index">
                            <p>세금계산서 수신 이메일</p>
                          </div>
                          <div className="contents-container__grid-contents">
                            <p>{list?.email ? list?.email : "-"}</p>
                          </div>
                        </section>
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        </article>
      )}
    </>
  );
};

export default ChargeMntType;
