// 건물 Table Columns
import moment from "moment";
import qs from "qs";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { ContractStep } from "src/api/contract/contract-types";
import { BaseTooltip } from "src/components";
import PagePath from "src/pagePath.json";
import { YmdFormat } from "src/utils";

export const columns = [
  {
    Header: "id",
    accessor: "contractId",
    // sticky: "left",
    width: 80,
    Cell: (props: any) => {
      const location = useLocation();
      const queryParams = useMemo(
        () =>
          qs.parse(location.search, {
            ignoreQueryPrefix: true,
            allowDots: true,
            decoder: (c) => c,
          }),
        [location],
      );
      queryParams.tab = "basicInfo";

      const path =
        PagePath.contract.detail.replace(":id", props.row.original.contractId) +
        "?" +
        decodeURIComponent(qs.stringify(queryParams, { allowDots: true, encode: true }));

      // console.log("props.row.original", props.row.original);
      return (
        <>
          <Link to={path} className="text-hilight">
            {props.value}
          </Link>
        </>
      );
    },
  },

  {
    Header: "신청번호",
    accessor: "contractApplyNumber",
    width: 110,
    Cell: (props: any) => {
      const path = PagePath.contract.detail.replace(":id", props.row.original.contractId);
      return (
        <>
          <Link to={path} className="text-hilight">
            {props.value}
          </Link>
        </>
      );
    },
  },
  {
    Header: "상태",
    accessor: "contractStep",
    width: 90,
    Cell: ({ value }: any) => {
      return (
        //visible 녹색
        //invisible 빨간색
        //draft = 회색
        <div
          className={`${
            (value === ContractStep.APPLY_RECEIVED && "chip draft") ||
            (value === ContractStep.APPLY_CANCEL && "chip invisible") ||
            (value === ContractStep.APPLY_CONFIRM && "chip draft") ||
            (value === ContractStep.CONTRACT_PLAN && "chip draft") ||
            (value === ContractStep.CONTRACT_ACCEPT && "chip draft") ||
            (value === ContractStep.USE_APPROVAL && "chip visible") ||
            (value === ContractStep.USE_PROGRESS && "chip visible") ||
            (value === ContractStep.USE_COMPLETE && "chip invisible") ||
            (value === ContractStep.TERMINATE_RECEIVED && "chip invisible") ||
            (value === ContractStep.TERMINATE_COMPLETE && "chip invisible") ||
            (value === "DISABLED" && "chip invisible") ||
            (value === "DRAFT" && "chip draft")
          }`}
        >
          {(value === ContractStep.APPLY_RECEIVED && "신청접수") ||
            (value === ContractStep.APPLY_CANCEL && "신청취소") ||
            (value === ContractStep.APPLY_CONFIRM && "신청확인") ||
            (value === ContractStep.CONTRACT_PLAN && "계약예정") ||
            (value === ContractStep.CONTRACT_ACCEPT && "계약체결") ||
            (value === ContractStep.USE_APPROVAL && "이용승인") ||
            (value === ContractStep.USE_PROGRESS && "이용중") ||
            (value === ContractStep.USE_COMPLETE && "이용완료") ||
            (value === ContractStep.TERMINATE_RECEIVED && "해지접수") ||
            (value === ContractStep.TERMINATE_COMPLETE && "해지완료")}
        </div>
      );
    },
  },
  {
    Header: "법인명/상호",
    accessor: "mbOrganizationName",
    width: 130,
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left"><BaseTooltip contents={value} /></div>;
    },
  },
  {
    Header: "계약자명",
    accessor: "applicantName",
    width: 130,
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left"><BaseTooltip contents={value} /></div>;
    },
  },

  {
    Header: "상품명",
    accessor: "spaceProductName",
    width: 120,
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left"><BaseTooltip contents={value} /></div>;
    },
  },
  {
    Header: "상품구분",
    accessor: "isMntProduct",
    width: 90,
    Cell: (props: any) => {
      let text = "일반";

      if (props.value) {
        text = "관리비 상품";
      }
      return <>{text}</>;
    },
  },
  {
    Header: "타입",
    accessor: "spaceProductType",
    width: 90,
    Cell: (props: any) => {
      let text = "PRODUCT_UNRECOGNIZED";
      if (props.value === "FULL_COURT") {
        text = "FULL";
      }
      if (props.value === "OPEN_COURT") {
        text = "OPEN";
      }
      if (props.value === "TIME_COURT") {
        text = "SERVICE";
      }
      return <>{text}</>;
    },
  },
  {
    Header: "상품id",
    accessor: "spaceProductId",
    width: 70,
    Cell: (props: any) => {
      // console.log("rops.row.original", props.row.original);
      const path = PagePath.product.detail.replace(":id", props.row.original.spaceProductId);
      return (
        <>
          <Link to={path} target="_blank" className="text-hilight">
            {props.value}
          </Link>
        </>
      );
    },
  },
  {
    Header: `이용자(현재/최대)`,
    accessor: "useNums",
    width: 140,
    Cell: ({ value, row }: any) => {
      const now = row.original.memberTotCount;
      const total = row.original.memberMaxNums;
      const path = PagePath.user.list + "?contractApplyNumber=" + row.original?.contractApplyNumber;
      return (
        <Link to={path} target="_blank" className="text-hilight">
          {now + "명 / " + total + "명"}
        </Link>
      );
    },
  },

  {
    Header: "이용시작",
    accessor: "contractStartTime",
    width: 145,
    Cell: ({ value }: any) => {
      return <>{value ? moment(value).format(YmdFormat.FULL) : "-"}</>;
    },
  },
  {
    Header: "이용종료",
    accessor: "contractEndTime",
    width: 145,
    Cell: ({ value }: any) => {
      return <>{value ? moment(value).format(YmdFormat.FULL) : "-"}</>;
    },
  },
  {
    Header: "기간",
    accessor: "contractTerm",
    width: 80,
    Cell: ({ value }: any) => {
      return (
        <>
          <p>{value ? value + "개월" : "-"}</p>
        </>
      );
    },
  },

  // {
  //   Header: "이름",
  //   accessor: "applicantName",
  //   width: 100,
  //   Cell: ({ value }: any) => {
  //     return <BaseTooltip contents={value} />;
  //   },
  // },
  {
    Header: "생성일",
    accessor: "createdDate",
    width: 90,
    Cell: ({ value }: any) => {
      return (
        <>
          <BaseTooltip contents={value} type={"date"} />
        </>
      );
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 100,
    Cell: ({ value }: any) => {
      return (
        <>
          <BaseTooltip contents={value} type={"date"} />
        </>
      );
    },
  },
  {
    Header: "파트너",
    accessor: "partnerId",
    width: 100,
    Cell: ({ value }: any) => {
      const path = PagePath.partner.detail.replace(":id", value);
      return (
        <Link to={path} target="_blank" className="text-hilight">
          {value}
        </Link>
      );
    },
  },
];
