import moment from "moment";
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import {
  PriceModel,
  PriceRuleOptionTypeToText,
  PriceRuleTypeToText,
} from "src/api/price/price-types";
import { PagePath } from "src/pages/product/details";
import { numberToStringWithComma, YmdFormat } from "src/utils";

export const priceColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: (props: Cell<PriceModel>) => {
      const detailPath = `${PagePath.price.detail.replace(":id", `${props.row.original.id}`)}`;
      return (
        <Link to={detailPath} className="text-hilight">
          {props?.value || ""}
        </Link>
      );
    },
  },

  {
    Header: "프로바이더",
    accessor: "providerName",
    width: 230,
    Cell: (props: Cell<PriceModel>) => {
      const detailPath = `${PagePath.provider.detail.replace(
        ":id",
        `${props.row.original.providerId}`,
      )}`;
      return (
        <div className="w-100 text-left">
          <Link to={detailPath} target="_blank" className="text-hilight ellipsis2">
            {props?.value || ""}
          </Link>
        </div>
      );
    },
  },
  {
    Header: "요금구분",
    accessor: "priceRuleType",
    width: 140,
    Cell: (props: Cell<PriceModel>) => {
      return (
        <div className="">
          <p>{props.value ? PriceRuleTypeToText[props.value] : "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "기준요금 유형",
    accessor: "priceRuleType2",
    width: 270,
    Cell: (props: Cell<PriceModel>) => {
      const option = props.row.original.priceRuleType;
      return <>{<div className="">{option ? PriceRuleOptionTypeToText[option] : "-"}</div>}</>;
    },
  },
  {
    Header: "건물",
    accessor: "buildingName",
    width: 200,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{value || "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "공용공간",
    accessor: "facilityName",
    width: 150,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="w-100 text-left">
          <p>{value || "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "요금정책명",
    accessor: "name",
    width: 200,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{value || "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "적용일자",
    accessor: "startDate",
    width: 150,
    Cell: (props: Cell<any>) => {
      return (
        <div>
          {props.value ? moment(props.value).format(YmdFormat.YYYY_MM_DD) : ""} - <br />
          {props.row.original.endDate
            ? moment(props.row.original.endDate).format(YmdFormat.YYYY_MM_DD)
            : ""}
        </div>
      );
    },
  },
  {
    Header: "적용시간",
    accessor: "endTime",
    width: 150,
    Cell: (props: Cell<any>) => {
      return (
        <div>
          {props.row.original.startTime ? props.row.original.startTime : ""} -
          {props.value ? props.value : ""}
        </div>
      );
    },
  },
  {
    Header: "원가 (원/30분)",
    accessor: "cost",
    width: 130,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="text-right w-100">
          <p>{numberToStringWithComma(value)}원</p>
        </div>
      );
    },
  },
  {
    Header: "판매가 (원/30분)",
    accessor: "price",
    width: 130,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="text-right w-100">
          <p>{numberToStringWithComma(value)}원</p>
        </div>
      );
    },
  },
  {
    Header: "파트너",
    accessor: "partnerId",
    width: 80,
    Cell: ({ value }: Cell<any>) => {
      return (
        <Link
          target={"_blank"}
          to={PagePath.partner.detail.replace(":id", value)}
          className="text-hilight"
        >
          {value}
        </Link>
      );
    },
  },
];
