import LogTable from "src/components/log/LogTable";
import { PartnerFeatures } from "src/types/partner";

const PartnerLog = () => {
  return (
    <div className="contents-container__wrap-contents">
      <div className="mb10">
        <h2></h2>
      </div>
      <LogTable type="PARTNER" />
    </div>
  );
};

export default PartnerLog;
