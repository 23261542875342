import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useApiOperation from "src/api/hooks/api-operation";
import { getIotPlatformAccountListAsync, getIotPlatformDeviceListAsync } from "src/api/iot/iot-api";
import { PaPlatformAccount, PaPlatformDevice } from "src/api/iot/iot-types";
import {
  BaseModal,
  BaseInput,
  BaseButton,
  BasePagination,
  BaseCheckbox,
  BaseSelect,
} from "src/components";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { useLoadingBarContext } from "src/pages/hooks";
import { ST_COLUMNS, B_IOT_COLUMNS } from "./columns/Columns";

// 기기(ST, B_IOT) 선택 모달

interface Props {
  onCanceled: () => void;
  onAdded: (platformDevice: PaPlatformDevice[]) => void;
  defaultValues: PaPlatformDevice[];
  multiSelect?: boolean;
  platform: string; // Iot구분
}

function PlatformDeviceSelectModal({
  platform,
  onCanceled,
  onAdded,
  defaultValues,
  multiSelect,
}: Props) {
  const [page, setPage] = useState({ current: 0, total: 0 });
  const [list, setList] = useState<PaPlatformDevice[]>([]);
  const [selectedList, setSelectedList] = useState<PaPlatformDevice[]>(defaultValues);
  const [accountOption, setAccountOption] = useState<{ value: string; label: string }[]>([
    { value: "", label: "" },
  ]);
  const { setLoadingBar } = useLoadingBarContext();
  const listTableData = list.map((item) => {
    const selectedIdList = selectedList.map((item) => item.id);
    return {
      checked: selectedIdList.includes(item.id),
      ...item,
    };
  });

  const searchFormRef = useRef<HTMLFormElement>(null);
  const { handleSubmit, control, watch, setValue } = useForm<{
    platformAccount: { email: string };
  }>();
  const { executeAsync: getIotPlatformAccountList } = useApiOperation(
    getIotPlatformAccountListAsync,
  );
  const { executeAsync: getIotPlatformDeviceList } = useApiOperation(getIotPlatformDeviceListAsync);

  const onSubmit = (data: any) => {
    // setSelectedList(devices);
  };
  const goPage = (nextPage: number) => setPage({ ...page, current: nextPage });

  // 계정목록 조회
  const fetchAccountApi = useCallback(async () => {
    const { data: platformAccountListResponse, status: platformAccountStatus } =
      await getIotPlatformAccountList();
    const platformAccountList = platformAccountListResponse.data.accounts;
    if (platformAccountStatus >= 200 && platformAccountStatus <= 299) {
      const STemailListOption = platformAccountList
        .filter((account: PaPlatformAccount) => account.platform === "ST")
        .map((account) => {
          return {
            value: account.email,
            label: account.email,
          };
        });
      const B_IOT_emailListOption = platformAccountList
        .filter((account: PaPlatformAccount) => account.platform === "B_IOT")
        .map((account) => {
          return {
            value: account.email,
            label: account.email,
          };
        });
      const options = platform === "ST" ? STemailListOption : B_IOT_emailListOption;
      const sortedOptions = options.sort((a:{ value: string; label: string },b:{ value: string; label: string })=>{
        if (a.value < b.value) {
          return -1;
        } else if (a.value > b.value) {
          return 1;
        } else {
          return 0;
        }
      })
      setAccountOption(sortedOptions);
    }
  }, [getIotPlatformAccountList]);

  // 선택된 계정으로 디바이스 조회
  const fetchDeviceApi = useCallback(
    async (email: string) => {
      setLoadingBar(true);
      const { data: platformDeviceListResponse, status: platformDeviceStatus } =
        await getIotPlatformDeviceList({
          page: page.current,
          size: 20,
          email,
          platform,
        });
      if (platformDeviceStatus >= 200 && platformDeviceStatus <= 299) {
        const platformDeviceList = platformDeviceListResponse.data.devices;
        setList(platformDeviceList);
        setPage({
          ...page,
          total: platformDeviceListResponse.meta.pageMeta?.totalPages || 0,
        });
      }
      setLoadingBar(false);
    },
    [getIotPlatformDeviceList, page, setLoadingBar],
  );

  const handleCheckboxClick = (checkbox: HTMLDivElement) => {
    const checkedId = checkbox.dataset.checkedId;
    const checked = Boolean(checkbox.dataset.checked === "true");
    const isDisabled = Boolean(checkbox.dataset.disabled === "true");
    if (!isDisabled) {
      if (!checked) {
        const selectedItem = list.find((item) => item.id === checkedId);

        if (multiSelect) {
          setSelectedList([...selectedList, selectedItem!]);
        } else {
          setSelectedList([selectedItem!]);
        }
      } else {
        const filteredList = selectedList.filter((item) => item.id !== checkedId);
        setSelectedList(filteredList);
      }
    }
  };
  const handleModalContentsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;
    const tr = target.closest(".base-table__tr");
    const td = tr?.children[0];
    const checkbox = td?.children[0] as HTMLDivElement;

    if (checkbox) handleCheckboxClick(checkbox);
  };

  const handleCancelButtonClick = () => onCanceled();
  const handleOnsubmit = () => {
    onAdded(selectedList);
  };

  useEffect(() => {
    fetchAccountApi();
  }, [fetchAccountApi, watch]);

  return (
    <BaseModal isOpen={true} className="dialog-modal">
      <div>
        <div className="modal-title">
          <div className="left-area">
            <span>{platform === "ST" ? "SmartThings" : "b.IoT"} 기기 선택</span>
          </div>
          <form
            className="right-area text-left"
            onSubmit={handleSubmit(onSubmit)}
            ref={searchFormRef}
          >
            <Controller
              name="platformAccount.email"
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  placeholder="계정을 선택해주세요"
                  value={value}
                  setStateValue={(value: any) => {
                    setValue("platformAccount.email", value);
                    fetchDeviceApi(value);
                  }}
                  stateOptions={accountOption}
                  className="minmax280 mr10"
                />
              )}
            ></Controller>
          </form>
        </div>
        <div className="base-modal__contents" onClick={handleModalContentsClick}>
          <ViewDataTable
            columns={platform === "ST" ? ST_COLUMNS : B_IOT_COLUMNS}
            data={listTableData}
          />
          <BasePagination pageIndex={page.current} totalPages={page.total} goPage={goPage} />
        </div>
        <div className="base-modal__btn-wrap">
          <BaseButton
            title={"취소"}
            className="color-white no-outline text-primary3 mr10"
            onClick={handleCancelButtonClick}
          />
          <BaseButton
            title={"선택 추가"}
            onClick={() => {
              handleOnsubmit();
              onCanceled();
            }}
          />
        </div>
      </div>
    </BaseModal>
  );
}
export default PlatformDeviceSelectModal;
