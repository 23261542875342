import React from "react";
import { MgmtOfficeModel, MgmtPhoneType } from "src/api/building/building-types";
import { BaseCheckbox } from "src/components";
import { formatPhoneNumber } from "src/utils";

type Props = {
  managementDetail: MgmtOfficeModel;
};
const MgmtContactSectionDetail = ({ managementDetail }: Props) => {
  return (
    <article className="contents-container__divide-top">
      <div className="contents-container__1200">
        <div className="contents-container__sub-title">
          <h2>연락처</h2>
        </div>
        <table className="inner-table" width="540">
          <thead>
            <tr>
              <th>
                <p className="text-center">대표여부</p>
              </th>
              <th>
                <p className="text-center">연락처</p>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {managementDetail?.officePhoneList?.map((phone: MgmtPhoneType) => (
              <tr key={phone.id}>
                <td width="120px">
                  <div className="flex-center-center">
                    <BaseCheckbox
                      id={"t"}
                      name={"t"}
                      className="chip-case"
                      label="대표"
                      checked={phone.isPrimary}
                      disabled
                    />
                  </div>
                </td>
                <td>
                  <div className="flex-center-center">
                    <span className="font14">
                      {phone.number ? formatPhoneNumber(phone.number) : "-"}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </article>
  );
};

export default MgmtContactSectionDetail;
