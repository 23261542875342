import { ContractStep } from "src/api/contract/contract-types";
import { BaseButton } from "./BaseButton";
import { BaseModal } from "./BaseModal";

const NoEditStateNoS2: Array<string> = [
  ContractStep.APPLY_CANCEL,
  ContractStep.TERMINATE_RECEIVED,
  ContractStep.TERMINATE_COMPLETE,
];
const NoEditStateS2: Array<string> = NoEditStateNoS2.concat([]);

type props = {
  title: string;
  isAuthorityReadonly?: boolean | undefined;
  className?: string;
  children?: React.ReactNode;
  isEditable?: boolean;
  setIsEditable?: Function;
  onClickSave?: Function;
  commonModals?: any;
  popupState?: boolean;
  setPopupState?: any;
  editConfrim?: boolean;
  setEditConfrim?: any;
  disabledState?: [status: string, productType?: string];
};

export const ArticleSection = ({
  title,
  className,
  children,
  isEditable,
  setIsEditable,
  onClickSave,
  commonModals,
  popupState,
  setPopupState,
  editConfrim,
  setEditConfrim,
  disabledState,
  isAuthorityReadonly,
}: props) => {
  // const { isAuthorityReadonly } = usePartnerAuthority();
  // console.log("disabledState", disabledState);
  if (disabledState === undefined) {
    disabledState = ["", ""];
  }

  // console.log("disabledState", disabledState);
  return (
    <article
      className={
        className
          ? `${className} contents-container__wrap-article full-width`
          : "contents-container__wrap-article full-width"
      }
    >
      {isAuthorityReadonly === false && onClickSave && setIsEditable && (
        <div className="contents-container__edit-area">
          {isEditable ? (
            <div className="flex-center">
              <BaseButton
                type="button"
                className="size-small color-white mr10"
                title={"취소"}
                onClick={() => setIsEditable(false)}
              />
              <BaseButton
                type="button"
                className="size-small"
                title={"저장"}
                onClick={() => setEditConfrim(true)}
              />
            </div>
          ) : (
            <button
              className="base-edit-btn"
              disabled={
                disabledState[1] !== "TIME_COURT"
                  ? NoEditStateNoS2.includes(disabledState[0])
                    ? true
                    : false
                  : NoEditStateNoS2.includes(disabledState[0])
                  ? true
                  : false
              }
              onClick={() => setIsEditable(true)}
            />
          )}
        </div>
      )}
      <div className="contents-container__sub-title">
        <h2>{title}</h2>
      </div>
      <section className="contents-container__1070">{children}</section>
    </article>
  );
};
