import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getParkingLotAsync } from "src/api/partner";
import { BaseModal } from "src/components";
import { PartnerFeatures } from "src/types/partner";
type Props = {
  features?: PartnerFeatures[];
};

const PartnerParking = ({ features }: Props) => {
  const { executeAsync: getParkingLot } = useApiOperation(getParkingLotAsync);

  const [parkingIotKey, setParkingIotKey] = useState<string>("");
  const [confirmModal, setConfirmModal] = useState({ isOpen: false });
  const { id } = useParams();

  useEffect(() => {
    const fetchApi = async (id: string) => {
      const { data, status } = await getParkingLot({ partnerId: id });
      if (status >= 200 && status < 300) {
        if (data.data?.parkinglot?.parkinglotKey) {
          setParkingIotKey(data.data?.parkinglot?.parkinglotKey);
        }
      } else {
        throw Error();
      }
    };
    if (id) {
      fetchApi(id);
    }
  }, []);

  return (
    <div className="page-partner-parking contents-container__wrap-contents">
      <div className="mb10">
        <h2>주차 연동</h2>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index minmax300">
            <p className="">주차장 키(parkingIot-key)</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-center-start flex-row">
              <div className="minmax400 font14">{parkingIotKey || "-"}</div>
            </div>
            {features?.find((item) => item.name === "FEATURE_PARKING_KPS")?.active &&
              !parkingIotKey && (
                <p className="text-red">
                  *유효한 주차장 키가 등록되어야 주차 연동이 정상 동작합니다.
                </p>
              )}
          </div>
        </section>
      </div>

      {confirmModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClick={() => {}}
          onClose={() => setConfirmModal({ isOpen: false })}
        >
          <p>네?</p>
        </BaseModal>
      )}
    </div>
  );
};

export default PartnerParking;
