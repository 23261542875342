import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BuildingCommonFacilityDeskGroupModel,
  BuildingModel,
  CommonFacilityModel,
} from "src/api/building/building-types";
import { getEslProductDetailAsync } from "src/api/esl/esl-api";
import { EslProduct } from "src/api/esl/esl-types";
import { useApiOperation } from "src/api/hooks";
import { MediaFile } from "src/api/public-types";
import { BaseTooltip } from "src/components";
import ImagesDetail from "src/components/ImagesDetail";
import {
  DeskSpace,
  floorOptions,
  SelectOption,
  sortingDeskGroupList,
} from "src/pages/building/building-types";
import ViewDataTable from "src/pages/building/components/ViewDataTable";

type Props = {
  building: BuildingModel | null;
};

interface EslLabel extends EslProduct {
  locationCode: string; // 공간유형 채번
}

/* 
  좌석 컴포넌트
 */
const DeskSection = ({ building }: Props) => {
  // ESL 데이터 상세 조회 api
  const { executeAsync: getEslProductDetail } = useApiOperation(getEslProductDetailAsync, {
    doNotErrorHandleModal: true,
  });

  // 조회한 ESL 데이터 목록
  const [eslLabels, setEslLabels] = useState<Array<EslLabel>>([]);

  // esl 라벨 목록 조회
  const fetchAllLabels = useCallback(async (locationCodes: Array<string>) => {
    try {
      if (!locationCodes || locationCodes.length === 0) {
        throw Error("locationCodes.length === 0");
      }

      const eslLabels: Array<EslLabel> = [];
      for (let i = 0; i < locationCodes.length; i++) {
        const locationCode = locationCodes[i];
        const result = await getEslProductDetail({ productId: locationCode });
        if (result?.data?.data && result.status >= 200 && result.status <= 299) {
          if (result?.data?.data?.product) {
            // esl 라벨이 연동되어있을 경우만 조회됨
            eslLabels.push({
              ...result?.data?.data?.product,
              ...{
                locationCode,
              },
            });
          }
        }
      }
      setEslLabels(eslLabels);
    } catch (error) {
      // 에러 발생해도 무시
      console.debug("fetchAllLabels error", error);
    }
  }, []);

  useEffect(() => {
    const deskGroupList: Array<BuildingCommonFacilityDeskGroupModel> =
      building?.buildingCommonFacility?.deskSpace?.deskGroupList || [];
    if (deskGroupList) {
      // locaiotn code 로 라벨 목록 조회
      const locationCodes: Array<string> = [];
      for (let i = 0; i < deskGroupList.length; i++) {
        const deskList = deskGroupList[i].deskList || [];
        for (let j = 0; j < deskList.length; j++) {
          const locationCode = deskList[j].locationCode;
          if (!!locationCode) {
            locationCodes.push(locationCode);
          }
        }
      }
      if (locationCodes.length > 0) {
        fetchAllLabels(locationCodes);
      }
    }
  }, [building, fetchAllLabels]);

  // UI 에서 사용할 좌석목록 배열
  const deskSpaces: Array<DeskSpace> = useMemo(() => {
    // UI 에서 사용할 좌석목록 배열
    const deskSpaces: Array<DeskSpace> = [];

    // 건물 상세조회 api 에서 내려준 좌석 목록 (정렬후)
    const deskGroupList: Array<BuildingCommonFacilityDeskGroupModel> = sortingDeskGroupList(
      building?.buildingCommonFacility?.deskSpace?.deskGroupList || [],
    );

    if (deskGroupList.length > 0) {
      for (let i = 0; i < deskGroupList.length; i++) {
        const commonfacility: BuildingCommonFacilityDeskGroupModel = deskGroupList[i];

        const isGround: boolean = commonfacility?.isGround === true; // 지상여부
        const floorNum: number = commonfacility?.floorNum || 1; // 층
        const floor = `${isGround ? "" : "-"}${floorNum}`;

        // 좌석 그룹
        const deskGroup: DeskSpace = {
          type: "GROUP",
          deskGroupId: commonfacility?.id,
          floor,
          deskGroupName: commonfacility.groupName,
          deskGroupDescription: commonfacility.description,
          isGray: true,
        };
        deskSpaces.push(deskGroup);

        // 좌석
        (commonfacility?.deskList || []).forEach((commonFacilityDesk: CommonFacilityModel) => {
          const desk: DeskSpace = {
            type: "DESK",
            deskGroupId: deskGroup.deskGroupId,
            deskId: Number(commonFacilityDesk.id),
            deskName: commonFacilityDesk.facilityName,
            locationCode: commonFacilityDesk.locationCode,
          };
          deskSpaces.push(desk);
        });
      }
    }
    return deskSpaces;
  }, [building?.buildingCommonFacility?.deskSpace?.deskGroupList]);

  // 배치도 배열
  const mediaList: Array<MediaFile> = useMemo(() => {
    return building?.buildingCommonFacility?.deskSpace?.mediaList || [];
  }, [building?.buildingCommonFacility?.deskSpace?.mediaList]);

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "deskGroupId",
        width: 60,
        Cell: ({ row, value }: any) => {
          const isGroupRow = row?.original?.type === "GROUP";
          const deskId = row?.original?.deskId || "";
          return isGroupRow ? value : deskId;
        },
      },
      {
        Header: "위치",
        accessor: "floor",
        width: 80,
        Cell: ({ value }: any) => {
          const floor = floorOptions.find((option: SelectOption) => option.value === value);
          return floor?.label || "";
        },
      },
      {
        Header: "좌석 그룹 명",
        accessor: "deskGroupName",
        width: 130,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "설명",
        accessor: "deskGroupDescription",
        width: 150,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "좌석 수량",
        accessor: "deskCount",
        width: 90,
        Cell: ({ row, data, value }: any) => {
          const isGroupRow = row?.original?.type === "GROUP";
          if (isGroupRow) {
            // 데스크 그룹의 경우, 데스크 합계 갯수를 보여준다
            const deskList = (data || []).filter(
              (d: DeskSpace) => d.type === "DESK" && d.deskGroupId === row?.original?.deskGroupId,
            );
            return <div>{deskList.length}</div>;
          } else {
            return <div className="ic_ho"></div>;
          }
        },
      },
      {
        Header: "좌석 명",
        accessor: "deskName",
        width: 130,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "Location code",
        accessor: "locationCode",
        width: 160,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "ESL",
        accessor: "eslAddress",
        width: 140,
        Cell: ({ row }: any) => {
          if (!!row?.original?.locationCode && eslLabels.length > 0) {
            const findLabel = eslLabels.find(
              (eslLabel: EslLabel) => eslLabel.locationCode === row?.original?.locationCode,
            );
            if (findLabel) {
              return <BaseTooltip contents={(findLabel.assignedLabelCodes || []).join(",")} />;
            }
          }
          return "";
        },
      },
    ],
    [eslLabels],
  );

  return (
    <section className="mt40">
      {deskSpaces.length > 0 || mediaList.length > 0 ? (
        <>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">좌석</p>
            </div>
          </div>
          <ViewDataTable columns={columns} data={deskSpaces} />
          {/* 배치도 */}
          <div className="contents-container__grid mt20">
            <div className="contents-container__grid-index">
              <p className="">배치도</p>
            </div>
          </div>
          <div className="mt20">
            <ImagesDetail images={mediaList} isUsedDescription={true} />
          </div>
        </>
      ) : (
        <div className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">좌석</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">데이터가 없습니다.</span>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default DeskSection;
