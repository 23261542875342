import { ProductGuideNetwork } from "src/api/product/product-types";

type Props = {
  wifiList: Array<ProductGuideNetwork>;
};

/* 
  공간상품 > 상세 > 안내 > 와이파이 컴포넌트 > 네트워크 컴포넌트
*/
const FreeInternetNetwork = ({ wifiList }: Props) => {
  return (
    <div className="flex-start mb10">
      <div className="minmax140">
        <p>
          네트워크 이름/
          <br />
          비밀번호
        </p>
      </div>
      <table className="inner-table" width="100%">
        <thead>
          <tr>
            <th>
              <span>No</span>
            </th>
            <th>
              <span>네트워크 이름</span>
            </th>
            <th>
              <span>비밀번호</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {wifiList &&
            wifiList
              .sort(
                (a: ProductGuideNetwork, b: ProductGuideNetwork) =>
                  Number(a.id || 0) - Number(b.id || 0),
              )
              .map((productGuideNetwork: ProductGuideNetwork, index: number) => (
                <tr key={index.toString()}>
                  <td width="100">
                    <div className="text-center">
                      <span>{index + 1}</span>
                    </div>
                  </td>
                  <td width="220">
                    <div className="flex-center-center">
                      <span className="font14">{productGuideNetwork.wifiName}</span>
                    </div>
                  </td>
                  <td width="auto">
                    <div className="text-center">
                      <span className="font14">{productGuideNetwork.wifiPassword}</span>
                    </div>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};
export default FreeInternetNetwork;
