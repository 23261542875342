import { useRef } from "react";
import { Control, Controller } from "react-hook-form";
import { BaseInput, BaseSelect } from "src/components";

type PartnerStatus = "STANDBY" | "ACTIVE" | "SHUTDOWN";
type SearchTarget = "id" | "containsCode" | "containsName";

interface IPartnerListSearchForm {
  status: PartnerStatus;
  searchTarget: SearchTarget;
  searchWord: string;
}

interface IProps {
  control: Control<IPartnerListSearchForm>;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const STATUS_SELECT_OPTIONS = [
  {
    value: "",
    label: "전체",
  },
  {
    value: "STANDBY",
    label: "대기",
  },
  {
    value: "ACTIVE",
    label: "활성",
  },
  {
    value: "SHUTDOWN",
    label: "종료",
  },
];

const SEARCH_TARGET_SELECT_OPTIONS = [
  {
    value: "",
    label: "전체",
  },
  {
    value: "id",
    label: "아이디",
  },
  {
    value: "containsCode",
    label: "코드",
  },
  {
    value: "containsName",
    label: "이름",
  },
];

function PartnerSearchForm({ control, onSubmit }: IProps) {
  const formRef = useRef<HTMLFormElement>(null);

  const onSearch = () =>
    formRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );

  return (
    <form className={"flex-start"} ref={formRef} onSubmit={onSubmit}>
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <BaseSelect
            stateOptions={STATUS_SELECT_OPTIONS}
            value={field.value}
            placeholder="상태"
            setStateValue={field.onChange}
            className="minmax100 mr8"
          />
        )}
      />
      <Controller
        name="searchTarget"
        control={control}
        render={({ field }) => (
          <BaseSelect
            stateOptions={SEARCH_TARGET_SELECT_OPTIONS}
            value={field.value}
            placeholder="검색 대상"
            setStateValue={field.onChange}
            className="minmax150 mr8"
          />
        )}
      />
      <Controller
        name="searchWord"
        control={control}
        render={({ field }) => (
          <BaseInput
            value={field.value}
            onChange={field.onChange}
            onSearchClick={onSearch}
            placeholder="검색어를 입력해주세요."
            className="minmax220"
          />
        )}
      />
    </form>
  );
}
export default PartnerSearchForm;
