import { createContext, useCallback, useState } from "react";

type LoadingBar = {
  setVisible: (value: boolean) => void;
};

export const LoadingBarContext = createContext<LoadingBar>({
  setVisible: (value: boolean) => {},
});

export function LoadingBarContextProvider({ children }: { children: React.ReactNode }) {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <LoadingBarContext.Provider value={{ setVisible }}>
      {visible && (
        <div className="loading-dim top-level">
          <div className="base-loading-bar">
            <div className="base-loading-bar__loading-img"></div>
          </div>
        </div>
      )}
      {children}
    </LoadingBarContext.Provider>
  );
}
