import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Cell,
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { getBuildingsAsync } from "src/api/building/building-api";
import { BuildingListParams, BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { Modal, Order, PageMeta } from "src/api/public-types";
import { BaseInput, BaseModal, BasePagination, BaseTooltip } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import TableRadioButton from "src/pages/product/components/TableRadioButton";
import { PagePath } from "src/pages/product/details";
import { Partner } from "src/types/partner";

type Props = {
  setInfoModal: (modal: Modal) => void;
  partner?: Partner;
  onSelected: (building: BuildingModel) => void;
};

type BuilindsQueryParams = {
  page?: number;
  size?: number;
  status?: string;
  searchTarget?: string;
  keyword?: string;
  isDeleted?: string;
  sort?: {
    orders?: Array<Order>;
  };
  partnerId?: string;
};

const ManagementBuildingModal = ({ setInfoModal, partner, onSelected }: Props) => {
  // 경고모달

  const [isMgmtOffice, setIsMgmtOffice] = useState(true);

  // 관리처 건물 팝업 columns
  const columns: any[] = useMemo(
    () => [
      {
        Header: "건물ID",
        accessor: "id",
        width: 60,
        Cell: ({ row }: Cell<BuildingModel>) => {
          return (
            <div>
              <span>{row?.original?.id}</span>
            </div>
          );
        },
      },
      {
        Header: "건물명",
        accessor: "buildingName",
        width: 220,
        Cell: ({ row }: Cell<BuildingModel>) => {
          const buildingName = row.original.buildingName;
          return (
            <div className="text-hilight cursor-pointer">
              <Link
                target={"_blank"}
                to={PagePath.building.detail.replace(":id", String(row.original.id))}
              >
                <BaseTooltip contents={buildingName} />
              </Link>
            </div>
          );
        },
      },
      {
        Header: "주소",
        accessor: "address",
        width: 260,
        Cell: ({ value }: Cell<BuildingModel>) => <BaseTooltip contents={value} />,
      },
      {
        Header: "관리처ID",
        accessor: "mgmtProviderId",
        width: 90,
        Cell: ({ row }: Cell<BuildingModel>) => {
          return (
            <span>
              {row?.original?.mgmtProviderId !== "0" ? row?.original?.mgmtProviderId : "-"}
            </span>
          );
        },
      },
      {
        Header: "관리처명",
        accessor: "mgmtOfficeName",
        width: 120,
        Cell: ({ row }: Cell<BuildingModel>) => {
          return <span>{row?.original?.mgmtOfficeName ? row?.original?.mgmtOfficeName : "-"}</span>;
        },
      },
    ],

    [],
  );

  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const location = useLocation();

  const queryParams: BuilindsQueryParams = useMemo(() => {
    const _queryParams: BuilindsQueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (_queryParams.isDeleted !== "true" && _queryParams.isDeleted !== "false") {
      _queryParams.isDeleted = "false";
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    if (!_queryParams?.partnerId) {
      _queryParams.partnerId = partner?.id;
    }
    return _queryParams;
  }, [location.search, partner]);

  const [buildings, setBuildings] = useState<BuildingModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [params, setParams] = useState<BuilindsQueryParams>({ ...queryParams });
  const [pageNum, setPageNum] = useState(0);

  // 건물 관리처 목록 api
  const { executeAsync: getBuildings, loading } = useApiOperation(getBuildingsAsync);

  // 프로바이더 목록 api
  const { executeAsync: getProviers } = useApiOperation(getProviderList);

  useEffect(() => {
    async function fetchBuildings(buildingListParams: BuildingListParams) {
      setLoadingBar(true);

      const { data } = await getBuildings(buildingListParams);
      const buildingList = data.data.content;

      const providerIds = buildingList.map((item) => item.mgmtProviderId);

      const { data: providers } = await getProviers({ providerId: providerIds.join(", ") });

      const newBuildings = buildingList.map((building) => {
        providers.data.content.forEach((provider) => {
          if (String(building.mgmtProviderId) === String(provider.providerId)) {
            building = { ...building, mgmtOfficeName: provider.providerName };
          }
        });
        return building;
      });
      setBuildings(newBuildings || []);

      setPageMeta(data?.meta?.pageMeta);

      setLoadingBar(false);
    }
    // 건물 목록 조회후 데이터 바인딩
    fetchBuildings(queryParams);
  }, [queryParams, getBuildings, setLoadingBar, getProviers]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, selectedFlatRows } =
    useTable(
      {
        columns,
        data: buildings,
      },
      useBlockLayout,
      useGlobalFilter,
      useFilters,
      useSortBy,
      useGlobalFilter,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns: Array<any>) => [
          {
            id: "selection",
            width: 70,
            Header: () => <div className="">선택</div>,
            Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
              let currentState = row.getToggleRowSelectedProps();

              return (
                <div className="minmax100">
                  <TableRadioButton
                    id={row.id}
                    onClick={() => {
                      console.log(`selected`, row && row.original);

                      toggleAllRowsSelected(false);
                      toggleRowSelected(row.id, true);
                    }}
                    name="selected"
                    {...currentState}
                  />
                </div>
              );
            },
          },
          ...columns,
        ]);
      },
    );

  // 팝업내 목록 검색
  const onSearchList = useCallback(
    async (page?: number) => {
      queryParams.page = page;
      queryParams.keyword = params.keyword;

      const response = await getBuildings(queryParams);

      if (response.status >= 200 && response.status <= 299) {
        const buildingList = response.data.data.content;
        const providerIds = buildingList.map((item) => item.mgmtProviderId);

        const { data: providers } = await getProviers({ providerId: providerIds.join(", ") });

        const newBuildings = buildingList.map((building) => {
          providers.data.content.forEach((provider) => {
            if (String(building.mgmtProviderId) === String(provider.providerId)) {
              building = { ...building, mgmtOfficeName: provider.providerName };
            }
          });
          return building;
        });
        setBuildings(newBuildings || []);

        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getBuildings, getProviers, params.keyword, queryParams],
  );

  // 팝업 빌딩 목록 페이지네이션
  const buildingPagenation = useCallback(
    (passParams?: BuilindsQueryParams) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const page = newQueryParams.page;
      setPageNum(Number(page));
      onSearchList(page);
    },
    [onSearchList, params],
  );

  if (loading) {
    return <p>로딩중...</p>;
  }
  return (
    <BaseModal
      isOpen={true}
      className="dialog-modal"
      btnLeftTitle="취소"
      btnRightTitle="확인"
      onClick={() => {
        const selected = selectedFlatRows[0].original;

        const mgmtOfficeId = selected.mgmtOfficeId;
        const mgmtProviderId = selected.mgmtProviderId;
        if (mgmtProviderId === "0" || mgmtOfficeId === "") {
          setIsMgmtOffice(false);
          return;
        } else {
          onSelected(selected);
        }
      }}
      onClose={() => {
        setInfoModal({ isOpen: false });
      }}
    >
      <>
        <div className="modal-title">
          <div className="left-area">
            <span>건물 조회</span>
            {!isMgmtOffice && (
              <div className="flex-center-center ml10">
                <p className="validation-text">{"*관리처가 등록된 건물만 선택 가능합니다."}</p>
              </div>
            )}
          </div>
          <div className=" right-area">
            <div className="minmax260 mr5">
              <BaseInput
                className=""
                placeholder="검색어를 입력하세요"
                onChange={(keyword: string) => setParams({ ...params, keyword })}
                value={params.keyword}
                onSearchClick={() => buildingPagenation({ page: 0 })}
                onKeyUp={() => buildingPagenation({ page: 0 })}
              />
            </div>
          </div>
        </div>

        <div className="page-product-list">
          <div className="contents-container__table">
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div
                          {...header.getHeaderProps(header.getSortByToggleProps())}
                          className="base-table__th"
                        >
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              {buildings.length && buildings.length > 0 ? (
                <div {...getTableBodyProps()} className="body">
                  {rows.map((row, idx: number) => {
                    prepareRow(row);
                    return (
                      <div {...row.getRowProps()} className="base-table__tr">
                        {row.cells.map((cell) => {
                          return (
                            <div {...cell.getCellProps()} className="base-table__td">
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <>
                  <p className="mt10">목록이 없습니다.</p>
                </>
              )}
            </div>
            <BasePagination
              pageIndex={pageNum || 0}
              totalPages={pageMeta?.totalPages || 0}
              goPage={(page: number) => buildingPagenation({ page })}
            />
          </div>
        </div>
      </>
    </BaseModal>
  );
};

export default ManagementBuildingModal;
