import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { getBuildingAsync } from "src/api/building/building-api";
import { BuildingModel, MgmtOfficerType } from "src/api/building/building-types";
import { getBuildingManagementAsync } from "src/api/building/management-api";
import { useApiOperation } from "src/api/hooks";
import { getMemberDetail } from "src/api/member/member-api";
import { getPartner } from "src/api/partner";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { MediaServiceType, Modal } from "src/api/public-types";
import { WorkOrderCancelAsync, WorkOrderDetailAsync } from "src/api/workOrder/workOrder-api";
import { WorkOrderDetailModel, WorkOrderSheetCostType } from "src/api/workOrder/workOrder-types";
import { BaseButton, BaseModal, ContentsIdSection } from "src/components";
import ImagesDetail from "src/components/ImagesDetail";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import { RoleType } from "src/pages/building/building-types";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { Partner } from "src/types/partner";
import {
  YmdFormat,
  formatPhoneNumber,
  numberToStringWithComma,
  onChangeKoreanDays,
} from "src/utils";
import { WorkOrderStatus, WorkOrderType } from "../../workOrder-types";
import WorkOrderGroupListSection from "./workOrderGroup/WorkOrderGroupListSection";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";

const BasicInfoDetail = () => {
  const navigate = useNavigate();

  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const params = useParams();
  const workOrderId = useMemo(() => {
    return params.id;
  }, [params]);

  // 컨트롤룸 baseUrl
  const ctrlUrl = useMemo(() => process.env.REACT_APP_CTRLROOM_BASSEURL, []);

  // 경고창 팝업
  const [cancelModal, setCancelModal] = useState<Modal>({ isOpen: false });

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 워크오더 상세 데이터
  const [workOrderDetail, setWorkOrderDetail] = useState<WorkOrderDetailModel>();

  // 회원정보 text
  const [reporterPhoneEmail, setReporterPhoneEmail] = useState("");

  // 관리처 상세 / 건물 상세에서 가져온 건물/관리처  value
  const [mgmtBuildingName, setMgmtBuildingName] = useState("");

  // 담당자 text Value
  const [officerNamePhone, setOfficerNamePhone] = useState({ value: "", deleted: false });

  // 선택된 파트너 정보
  const [partner, setPartner] = useState<Partner>();

  // ================== API ==================

  // 관리처 관리 상세
  const { executeAsync: getBuildingManagement } = useApiOperation(getBuildingManagementAsync);

  // 건물 상세 조회 api
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  //회원 상세 조회 API
  const { executeAsync: getMemberDetailAsync } = useApiOperation(getMemberDetail);

  // 워크오더 상세 api
  const { executeAsync: getWorkOrderDetail } = useApiOperation(WorkOrderDetailAsync);

  // 워크오더 취소 api
  const { executeAsync: workOrderCancel } = useApiOperation(WorkOrderCancelAsync);

  // 파트너 상세조회
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  // 프로바이더 상세조회
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  // 담당자 input value 노출
  const officerInfoText = useCallback((officerId: string, officerList: MgmtOfficerType[]) => {
    //
    // 담당자 목록 && 팝업에서 선택된 담당자ID 가 있으면
    if (officerId && officerList) {
      const officer = officerList.find((item) => item.id === officerId);

      let officerValue = "";

      if (officer?.isPrimary) {
        officerValue = `대표매니저 / ${officer.name} / ${formatPhoneNumber(officer?.phone || "")}`;
      } else if (!officer?.isPrimary && officer?.roleType === RoleType.MANAGER) {
        officerValue = `매니저 / ${officer.name} / ${formatPhoneNumber(officer?.phone || "")}`;
      } else if (!officer?.isPrimary && officer?.roleType === RoleType.USER) {
        officerValue = `${officer?.name} / ${formatPhoneNumber(officer?.phone || "")}`;
      }
      setOfficerNamePhone({ value: officerValue, deleted: officer?.isDeleted || false });
    }
  }, []);
  // 파트너 상세
  const fetchPartner = async (id: string) => {
    setLoadingBar(true);
    const response = await getPartnerAsync({ id });

    if (response.status >= 200 && response.status <= 299) {
      const result = response.data.data.partner;
      setPartner(result);
    }
    setLoadingBar(false);
  };

  // 관리처 관리 상세 / 건물 상세 api
  const getBuildingManagementApi = useCallback(
    async (workOrder?: WorkOrderDetailModel) => {
      setLoadingBar(true);

      // 관리처 상세 조회 response
      const mgmtBuildingResponse = await getBuildingManagement({ id: workOrder?.mgmtOfficeId });

      if (mgmtBuildingResponse.status >= 200 && mgmtBuildingResponse.status <= 299) {
        const managementBuilding = mgmtBuildingResponse.data.data.content;

        // 관리처 상세에 있는 buildingId
        const buildingId = managementBuilding.buildingId;

        // 관리처 상세에 있는 정산정보 ID
        const providerId = managementBuilding.providerId;

        // buildingId로 건물 상세 조회
        if (buildingId) {
          const buildingResponse = await getBuilding({ buildingId: Number(buildingId) });
          const building: BuildingModel = buildingResponse.data?.data?.content?.building;
          let providerName = "없음";

          // 정산정보 상세에서 providerName 가져와서 표시 (관리처명)

          if (providerId !== null) {
            const { data } = await getProviderDetail({ providerId: String(providerId) });
            if (data) {
              providerName = String(data.data.provider?.providerName);
              // 건물/관리처 input value setState
            }
          }
          const mgmtBuildingName = `${building.buildingName} / ${providerName}`;
          setMgmtBuildingName(mgmtBuildingName);

          // 관리처 담당자 정보 setTate
          officerInfoText(String(workOrder?.mgmtOfficerId), managementBuilding.officerList || []);
        }
      }

      setLoadingBar(false);
    },
    [getBuilding, getBuildingManagement, getProviderDetail, officerInfoText],
  );

  // 회원 상세 api
  const getMemberDetailApi = useCallback(
    async (workOrder?: WorkOrderDetailModel) => {
      setLoadingBar(true);

      const reporterId = workOrder?.reporterId;
      const reporterName = workOrder?.reporterName;
      // const reporterPhone = workOrder?.reporterPhone;
      // const reporterEmail = workOrder?.reporterEmail;

      let reporterValue = "";

      // reporterId 가 있으면 회원
      if (reporterId) {
        const response = await getMemberDetailAsync({ id: reporterId });
        if (response.status >= 200 && response.status <= 299) {
          const member = response?.data?.data?.member;
          reporterValue = `회원 / ${formatPhoneNumber(member.phoneNumber)} / ${member.email}`;

          // reporterValue = `회원 / ${member.phoneNumber} / ${member.email}`;
          setReporterPhoneEmail(reporterValue);
        }
        // reporterId 없고 다른 접수자 정보가 있으면 비회원
      } else if (!reporterId && reporterName) {
        reporterValue = `비회원${workOrder?.reporterName && " / " + workOrder?.reporterName} ${
          workOrder?.reporterPhone && " / " + formatPhoneNumber(workOrder?.reporterPhone || "")
        }${workOrder?.reporterEmail && " / " + workOrder?.reporterEmail}`;
        setReporterPhoneEmail(reporterValue);
      } else {
        // id, 접수가 정보가 없으면 접수자 없이 워크오더 등록
        setReporterPhoneEmail("-");
      }
      setLoadingBar(false);
    },
    [getMemberDetailAsync, setLoadingBar],
  );

  //워크오더 상세 api
  const getWorkOrderDetailApi = useCallback(
    async (id: string) => {
      if (id) {
        const response = await getWorkOrderDetail({ id: Number(id) });
        if (response.status >= 200 && response.status <= 299) {
          const workOrder = response.data.data.content;

          // 워크오더 상세 데이터 저장
          setWorkOrderDetail(workOrder);

          // 접수자 value 표기하기 위한 api 호출
          getMemberDetailApi(workOrder);

          // 건물/관리처를 표기하기 위한 api 호출
          getBuildingManagementApi(workOrder);

          // 파트너 정보 호출
          fetchPartner(String(workOrder.partnerId));
        }
      }
    },
    [getBuildingManagementApi, getMemberDetailApi, getWorkOrderDetail],
  );

  // // 워크오더 취소 api
  const workOrderCancelApi = useCallback(
    async (id: string, type: string) => {
      setLoadingBar(true);

      // 그룹삭제
      if (id && type === "group") {
        const response = await workOrderCancel({ groupIds: String(id) });

        if (response.status >= 200 && response.status <= 299) {
          // await getWorkOrderDetailApi(String(workOrderId));
          setAlertModal({ isOpen: true, message: "취소 상태로 변경되었습니다." });
          setCancelModal({ isOpen: false });
        }
      }
      // 단건 삭제
      else {
        const response = await workOrderCancel({ ids: String(id) });

        if (response.status >= 200 && response.status <= 299) {
          // await getWorkOrderDetailApi(String(workOrderId));
          setAlertModal({ isOpen: true, message: "취소 상태로 변경되었습니다." });
          setCancelModal({ isOpen: false });
        }
      }
      setLoadingBar(false);
    },
    [setLoadingBar, workOrderCancel],
  );

  useEffect(() => {
    if (workOrderId) {
      getWorkOrderDetailApi(workOrderId);
    }
  }, [getWorkOrderDetailApi, workOrderId]);

  const workOrderMediasList = useCallback(
    (mediaType: MediaServiceType) => {
      if (workOrderDetail?.mediaList && workOrderDetail.mediaList.length > 0) {
        const mediaList = workOrderDetail.mediaList;

        switch (mediaType) {
          // 업무내용 이미지 노출
          case "MEDIA_WORK_ORDER_SHEET":
            const workSheetMedias = mediaList.filter(
              (media) => media.mediaServiceType === mediaType,
            );
            return workSheetMedias.length > 0 ? <ImagesDetail images={workSheetMedias} /> : "-";

          // 처리내용 이미지 노출
          case "MEDIA_WORK_ORDER_SHEET_COMPLETED_REPORT":
            const workCompletedMedias = mediaList.filter(
              (media) => media.mediaServiceType === mediaType,
            );
            return workCompletedMedias.length > 0 ? (
              <ImagesDetail images={workCompletedMedias} />
            ) : (
              "-"
            );

          // 처리비용 이미지가 있으면 노출
          case "MEDIA_WORK_ORDER_SHEET_COST":
            const workCostMedias = mediaList.filter(
              (media) => media.mediaServiceType === mediaType,
            );
            return workCostMedias.length > 0 ? <ImagesDetail images={workCostMedias} /> : "-";
        }
      } else {
        return "-";
      }
    },
    [workOrderDetail?.mediaList],
  );
  // const titleLengthChecker = (titleName: string) => {
  //   let name = titleName;
  //   console.log(titleName, titleName.length);
  //   if (titleName && titleName.length > 10) {
  //     name = titleName.substring(0, 9) + "...";
  //   }
  //   return name;
  // };
  // let title = titleLengthChecker(workOrderDetail?.summary || "");
  // let title = workOrderDetail?.summary;

  useTitleOperation(workOrderDetail?.summary);

  return (
    <div className="contents-container__wrap">
      {workOrderDetail && (
        <div className="contents-container__wrap-contents">
          <ContentsIdSection
            title="워크시트 상세"
            id={workOrderId}
            noTitle={workOrderDetail.workOrderSheetGroupId && "그룹id"}
            no={workOrderDetail.workOrderSheetGroupId}
            thirdTitle="워크시트번호"
            thirdNo={`${workOrderDetail?.workOrderSheetNo?.slice(
              0,
              8,
            )}-${workOrderDetail?.workOrderSheetNo?.slice(8, 13)}`}
          />
          {/*기본정보 페이지 내에 건물/호실 테이블 */}
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>업무 구분</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax200">
                <span>
                  {workOrderDetail?.workOrderType === WorkOrderType.SERVICE
                    ? "서비스 관리"
                    : "공간 관리"}
                </span>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>우선 순위</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax200">
                <span>
                  {workOrderDetail?.workOrderPriority === "1"
                    ? "낮음"
                    : workOrderDetail?.workOrderPriority === "2"
                    ? "보통"
                    : workOrderDetail?.workOrderPriority === "3"
                    ? "높음"
                    : "긴급"}
                </span>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>상태</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <div className="minmax80">
                  <span>
                    {workOrderDetail?.status === WorkOrderStatus.OPEN
                      ? "접수"
                      : workOrderDetail?.status === WorkOrderStatus.CANCELED
                      ? "취소"
                      : workOrderDetail?.status === WorkOrderStatus.CLOSED
                      ? "처리종료"
                      : workOrderDetail?.status === WorkOrderStatus.COMPLETED
                      ? "처리완료"
                      : workOrderDetail?.status === WorkOrderStatus.PROGRESS
                      ? "처리중"
                      : workOrderDetail?.status === WorkOrderStatus.WAITING
                      ? "확인"
                      : "-"}
                  </span>
                </div>
                <div>
                  {/* 재처리 비용 노출 */}
                  {workOrderDetail.isReopened && (
                    <span className="text-red  border-gray mr10  px10 py5">재처리 요청</span>
                  )}

                  {/* 지연여부 노출 - 오늘 날짜, endDate 비교 */}
                  {moment(moment().format("YYYY-MM-DD")).isAfter(workOrderDetail.endDate) &&
                    (workOrderDetail.status === WorkOrderStatus.OPEN ||
                      workOrderDetail.status === WorkOrderStatus.WAITING ||
                      workOrderDetail.status === WorkOrderStatus.PROGRESS) && (
                      <span className="text-purple border-gray px10 py5">처리지연</span>
                    )}
                </div>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>접수자</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <span>{reporterPhoneEmail || "-"}</span>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>접수자 위치</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                {workOrderDetail?.reporterBuildingName && workOrderDetail?.reporterBuildingAddr ? (
                  <span>{`${workOrderDetail?.reporterBuildingName} (${workOrderDetail?.reporterBuildingAddr})`}</span>
                ) : (
                  "-"
                )}
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>건물/관리처</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <Link
                  className="text-hilight"
                  target={"_blank"}
                  to={PagePath.building.detail.replace(":id", String(workOrderDetail.buildingId))}
                >
                  {mgmtBuildingName || "-"}
                </Link>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>담당자</p>
            </div>
            <div className="contents-container__grid-contents">
              {officerNamePhone?.value && (
                <div className="flex-row flex-center-start">
                  <span>{officerNamePhone?.value}</span>
                  {officerNamePhone.deleted && (
                    <span className="contents-container__sub-title-info_r icon-red text-red600 ml50">
                      삭제된 담당자입니다.
                    </span>
                  )}
                </div>
              )}
              {workOrderDetail.isSendAlarm && (
                <ul className=" text-primary3 font12 ">
                  <li>업무 알림톡 발송 ON</li>
                </ul>
              )}
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>업무 일정</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400 d-flex">
                <span>
                  {`${
                    moment(workOrderDetail?.startDate).format(YmdFormat.YYYY_MM_DD) || "-"
                  } (${onChangeKoreanDays(workOrderDetail?.startDate)}) ~
                  ${
                    moment(workOrderDetail?.endDate).format(YmdFormat.YYYY_MM_DD) || "-"
                  } (${onChangeKoreanDays(workOrderDetail?.endDate)}) `}
                </span>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>워크시트 제목</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <span>{workOrderDetail?.summary}</span>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>워크시트 내용</p>
            </div>
            <div className="contents-container__grid-contents contents-container__1200">
              <div>
                <p className="font14 pre-formatted">{workOrderDetail?.description || "-"}</p>
              </div>
            </div>
          </section>

          {/* 워크시트 내용 이미지  */}

          <section className="contents-container__grid">
            <div className="contents-container__grid-index"></div>
            <div className="contents-container__grid-contents">
              {workOrderMediasList("MEDIA_WORK_ORDER_SHEET")}
            </div>
          </section>

          {/* 그룹 워크오더시 리스트 노출 */}
          {workOrderDetail.workOrderSheetGroupId && (
            // <section className="contents-container__grid">
            <section className="contents-container__grid-contents">
              <div className="contents-container__grid-index">
                <p>그룹 일정</p>
              </div>
              <div className="mt20">
                <WorkOrderGroupListSection workOrder={workOrderDetail} />
              </div>
            </section>
          )}

          {/* 상태 - 처리중 / 처리완료 / 처리종료 일시 노출 */}
          {(workOrderDetail.status === WorkOrderStatus.PROGRESS ||
            workOrderDetail.status === WorkOrderStatus.COMPLETED ||
            workOrderDetail.status === WorkOrderStatus.CLOSED) && (
            <>
              {/* 처리내용 */}
              <section className="contents-container__grid mt50">
                <div className="contents-container__grid-index">
                  <p>처리 내용</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400 pt7">
                    <p className="font14 pre-formatted">
                      {workOrderDetail?.completionReport || "-"}
                    </p>
                  </div>
                </div>
              </section>
              {/* 처리내용 증빙자료 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index"></div>

                <div className="contents-container__grid-contents">
                  {workOrderMediasList("MEDIA_WORK_ORDER_SHEET_COMPLETED_REPORT")}
                </div>
              </section>

              {/* 처리비용 */}
              <section className="contents-container__grid mt50">
                <div className="contents-container__grid-index">
                  <p>처리 비용</p>
                </div>
                <table className="inner-table" width="600">
                  <thead>
                    <tr>
                      <th>
                        <div>
                          <span>비용 발생 사유</span>
                        </div>
                      </th>
                      <th>
                        <span>금액 (원)</span>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {workOrderDetail?.workOrderSheetCostList &&
                    workOrderDetail?.workOrderSheetCostList.length > 0 ? (
                      workOrderDetail.workOrderSheetCostList
                        .sort((a, b) => Number(a?.id) - Number(b?.id))
                        .map((cost: WorkOrderSheetCostType) => (
                          <tr key={cost.id}>
                            <td width="350px">
                              <div className="flex-center-center">{cost.description || "-"}</div>
                            </td>
                            <td>
                              <div className="flex-center-center">
                                <div className="flex-center-center">
                                  {Number(cost.cost) === 0
                                    ? "-"
                                    : numberToStringWithComma(cost.cost) || "-"}
                                  {/* {} */}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td width="350px" colSpan={2}>
                          <div className="flex-center-center">데이터가 없습니다</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </section>

              {/* 처리비용 이미지 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  {" "}
                  <p>증빙 자료</p>
                </div>
                <div className="contents-container__grid-contents">
                  {workOrderMediasList("MEDIA_WORK_ORDER_SHEET_COST")}
                </div>
              </section>
            </>
          )}

          {/* 고객답변 */}
          {(workOrderDetail.status === WorkOrderStatus.COMPLETED ||
            workOrderDetail.status === WorkOrderStatus.CLOSED) && (
            <section className="contents-container__grid mt50">
              <div className="contents-container__grid-index">
                <p className="pre-fomatter">고객 답변</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400 pt7">
                  <span className="font14 pre-formatted">
                    {workOrderDetail?.customerNotification || "-"}
                  </span>
                </div>
              </div>
            </section>
          )}

          <section className="contents-container__grid mt50">
            <div className="contents-container__grid-index">
              <p>모바일웹 URL</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="pt7 ">
                <span className="font14 pre-formatted">
                  <a
                    className="text-hilight "
                    href={`${ctrlUrl}/mng/ctrl/public/workOrder/${workOrderDetail.workOrderSheetNo}`}
                    target="_blank"
                    rel="noreferrer"
                  >{`${ctrlUrl}/mng/ctrl/public/workOrder/${workOrderDetail.workOrderSheetNo}`}</a>
                </span>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>등록일</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <span className="font14">
                  {moment(workOrderDetail?.createdDate).format(YmdFormat.FULL) || "-"}
                </span>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>최종 수정일</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <span className="font14">
                  {moment(workOrderDetail?.modifiedDate).format(YmdFormat.FULL) || "-"}
                </span>{" "}
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>최종 수정자</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <span>
                  {workOrderDetail?.modifiedBy === "product"
                    ? "모바일웹에서 변경됨"
                    : workOrderDetail?.modifiedBy || "-"}
                </span>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>파트너</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <span>
                  {partner?.name && partner?.code && `${partner?.name} (${partner.code})`}
                </span>
              </div>
            </div>
          </section>
        </div>
      )}

      <AdminMemoV3
        serviceId={Number(workOrderDetail?.id)}
        serviceType={ServiceTypes.SERVICE_WORKORDER}
        partnerId={workOrderDetail?.partnerId!}
      ></AdminMemoV3>

      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            className="color-white size-large"
            onClick={() => {
              navigate("/mng/central/workOrder");
            }}
          />
        </div>
        {workOrderDetail?.status === "WORK_ORDER_CANCELED" ? (
          <p className="text-red font16">취소된 워크시트 입니다.</p>
        ) : workOrderDetail?.status === "WORK_ORDER_CLOSED" ? (
          <p className="text-red font16">종료된 워크시트 입니다.</p>
        ) : (
          ""
        )}
      </div>
      <BaseModal
        isOpen={cancelModal.isOpen}
        onClose={() => setCancelModal({ isOpen: false })}
        onClick={() => {
          workOrderCancelApi(cancelModal.payload, cancelModal.type || "");
        }}
        btnLeftTitle="취소"
        btnRightTitle="확인"
      >
        <p className="pre-formatted"> {cancelModal.message}</p>
      </BaseModal>

      <BaseModal
        isOpen={alertModal.isOpen}
        onClick={() => {
          setAlertModal({ isOpen: false });
          navigate(PagePath.workOrder.detail.replace(":id", String(workOrderId)));
        }}
        btnRightTitle="확인"
      >
        <p className="pre-formatted"> {alertModal.message}</p>
      </BaseModal>
    </div>
  );
};

export default BasicInfoDetail;
