import { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { BaseSelect, BaseInput } from "src/components";
import { Partner } from "src/types/partner";
import { IPartnerMember } from "src/types/partner-members";
import { IMemberListSearchForm } from "../types";

const BLOCKED_SELECT_OPTIONS = [
  {
    value: "",
    label: "전체",
  },
  {
    value: "true",
    label: "차단",
  },
];

interface IProps {
  onSubmit: (data: IMemberListSearchForm) => void;
  data?: IMemberListSearchForm;
  partners: Partner[];
}

function PartnerMemeberListSearchForm({ partners, onSubmit, data }: IProps) {
  const { control, handleSubmit, setValue } = useForm<IMemberListSearchForm>();

  const formRef = useRef<HTMLFormElement>(null);
  const onSearchClick = () =>
    formRef.current?.dispatchEvent(new Event("submit", { bubbles: true }));

  const PARTNERS_SELECT_OPTIONS = [
    {
      label: "전체",
      value: "",
    },
    ...partners?.map((partner) => ({
      label: partner.name,
      value: partner.id!,
    })),
  ];

  useEffect(() => {
    setValue("blocked", data?.blocked || "");
    setValue("partnerId", data?.partnerId || "");
    setValue("containsEmail", data?.containsEmail || "");
  }, [data]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={"flex-start"} ref={formRef}>
      <Controller
        name="blocked"
        control={control}
        render={({ field }) => (
          
          <BaseSelect
            stateOptions={BLOCKED_SELECT_OPTIONS}
            setStateValue={field.onChange}
            value={field.value}
            className="minmax100 mr8"
            placeholder="상태"
          />
          
        )}
      />
      <Controller
        name="partnerId"
        control={control}
        render={({ field }) => (
          <BaseSelect
            stateOptions={PARTNERS_SELECT_OPTIONS}
            setStateValue={field.onChange}
            value={field.value}
            className={"minmax150 mr8"}
            placeholder="소속 파트너"
          />
        )}
      />
      <Controller
        name="containsEmail"
        control={control}
        render={({ field, fieldState }) => (
          <BaseInput
            onSearchClick={onSearchClick}
            onChange={field.onChange}
            value={field.value}
            className="minmax300"
            placeholder="이메일"
            errorText={fieldState.error?.message}
          />
        )}
        rules={{
          pattern: {
            value: /^.{3,}$/i,
            message: "이메일은 3자 이상 입력해주세요.",
          },
        }}
      />
    </form>
  );
}
export default PartnerMemeberListSearchForm;
