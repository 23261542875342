import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { WorkOrderListModel } from "src/api/workOrder/workOrder-types";
import { BaseTooltip } from "src/components";
import { PagePath } from "src/pages/product/details";
import { WorkOrderStatus } from "src/pages/workOrder/workOrder-types";
import { onChangeKoreanDays, YmdFormat } from "src/utils";

const WorkOrderGroupColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 100,
    Cell: ({ row, value }: Cell<WorkOrderListModel>) => {
      return <span>{value}</span>;
    },
  },

  {
    Header: "그룹id",
    accessor: "workOrderSheetGroupId",
    width: 100,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return <span>{value || "-"}</span>;
    },
  },
  {
    Header: "워크시트 번호",
    accessor: "workOrderSheetNo",
    width: 200,
    Cell: ({ row, value }: Cell<WorkOrderListModel>) => {
      const detailPath = PagePath.workOrder.detail.replace(":id", String(row.original.id));
      return (
        <Link to={detailPath} className="text-hilight">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "상태",
    accessor: "status",
    width: 100,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      let status = "";

      switch (value) {
        case WorkOrderStatus.OPEN:
          status = "접수";
          break;

        case WorkOrderStatus.CLOSED:
          status = "종료";
          break;

        case WorkOrderStatus.COMPLETED:
          status = "처리 완료";
          break;

        case WorkOrderStatus.WAITING:
          status = "확인";
          break;

        case WorkOrderStatus.CANCELED:
          status = "취소";
          break;

        case WorkOrderStatus.PROGRESS:
          status = "처리중";
          break;
      }

      return (
        <span
          className={`${
            (value === WorkOrderStatus.OPEN && "chip gray") || // 접수
            (value === WorkOrderStatus.WAITING && "chip green") || //확인
            (value === WorkOrderStatus.PROGRESS && "chip green") || //처리중
            (value === WorkOrderStatus.COMPLETED && "chip red") || //완료
            (value === WorkOrderStatus.CLOSED && "chip gray") || //종료
            (value === WorkOrderStatus.CANCELED && "chip gray") //취소
          }`}
        >
          {status}
        </span>
      );
    },
  },
  {
    Header: "업무 시작일",
    accessor: "startDate",
    width: 180,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return (
        <div>
          <span>{value && moment(value).format(YmdFormat.YYYY_MM_DD)}</span>
          <span className="ml5">{`(${onChangeKoreanDays(value)})`}</span>
        </div>
      );
    },
  },
  {
    Header: "처리기한",
    accessor: "endDate",
    width: 180,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return (
        <div>
          <span>{value && moment(value).format(YmdFormat.YYYY_MM_DD)}</span>
          <span className="ml5">{`(${onChangeKoreanDays(value)})`}</span>
        </div>
      );
    },
  },
  {
    Header: "담당자",
    accessor: "mgmtOfficerName",
    width: 90,
  },
];

export default WorkOrderGroupColumns;
