import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import { BuildingRoomsByOwnerModel } from "src/api/building/building-types";
import { getRoomsByOwnerAsync } from "src/api/building/property-api";
import { useApiOperation } from "src/api/hooks";
import { PageMeta, Sort } from "src/api/public-types";
import { BaseButton, BasePagination, ContentsIdSection } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import roomsByOwnerListColumns from "../../columns/RoomsByOwnerListColumns";

type Props = {
  orgId: string;
};
type QueryParams = {
  page?: number;
  size?: number;
  searchType?: string;
  sort?: Sort;
};


const BuildingInfo = ({ orgId }: Props) => {
  const { setLoadingBar } = useLoadingBarContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState<BuildingRoomsByOwnerModel[]>([]);

  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    // if (!_queryParams?.sort) {
    //   _queryParams.sort = {
    //     orders: [{ property: "startDate", direction: "ASC" }],
    //   };
    // }
    return _queryParams;
  }, [location]);

  
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [pageNum, setPageNum] = useState(0);
  const [params, setParams] = useState<QueryParams>({ ...queryParams });
  
  const { executeAsync: getRoomsByOwner } = useApiOperation(getRoomsByOwnerAsync);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: roomsByOwnerListColumns,
      data,
      disableSortBy: true,
    },
    useBlockLayout,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  useEffect(() => {
    const fetchRoomList = async (orgId: string) => {
      setLoadingBar(true);
      const response = await getRoomsByOwner({ownerId:orgId, ...{page:0, size:20}});
      if (response.status >= 200 && response.status <= 299) {
        if(response.data.data){
          const roomsListData = response.data.data.content;
          setData(roomsListData)
          setPageMeta(response.data.meta.pageMeta);
        }
        console.log("roomsListData", response);
      }
      setLoadingBar(false);
    };

    fetchRoomList(orgId);
  }, [orgId, getRoomsByOwner]);

  const goList = useCallback(() => {
    navigate(PagePath.organization.list);
  }, [navigate]);

  // 페이지네이션
  const onPagination = useCallback(
    async (passParams?: QueryParams) => {
      const newQueryParams = { ...(passParams || {}) };
      const page = newQueryParams.page;
      const searchParams: QueryParams = {
        page,
        size: queryParams.size,
        sort: newQueryParams.sort,
      };
      console.log('searchParams',searchParams)
      const response = await getRoomsByOwner({ownerId:"46", ...searchParams});
      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content;
        setData(result);
      }

      setPageNum(Number(page));
    },
    [getRoomsByOwner, queryParams],
  );


  return (
    <>
      <div className="contents-container__wrap detail-basic-info">
        <div className="contents-container__wrap-contents">
          <ContentsIdSection title="건물" id={orgId} />
          <div className="contents-container__1200">
            <div className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">전체 {data.length || 0} </p>
              </div>
            </div>
            <section className="state-change-info inner-tab-table">
              <section className="state-change-info inner-tab-table">
                <div>
                  <div {...getTableProps()} className="base-table sticky">
                    <div className="header">
                      {headerGroups.map((headerGroup) => (
                        <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                          {headerGroup.headers.map((header) => {
                            return (
                              <div {...header.getHeaderProps()} className="base-table__th">
                                {header.render("Header")}
                                {/* <div className="ic_sort"></div> */}
                              </div>
                            );
                          })}
                        </div>
                      ))}
                    </div>
                    <div {...getTableBodyProps()} className="body">
                      {rows.map((row: any) => {
                        prepareRow(row);

                        return (
                          <div
                            {...row.getRowProps()}
                            className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                          >
                            {row.cells.map((cell: any) => {
                              return (
                                <div {...cell.getCellProps()} className="base-table__td">
                                  {cell.render("Cell")}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <BasePagination
                  pageIndex={pageNum || 0}
                  totalPages={Number(pageMeta?.totalPages || 0)}
                  goPage={(page: number) => {
                    onPagination({page, sort: { orders: params.sort?.orders }});
                  }}
                />
              </section>
            </section>
          </div>
        </div>
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              onClick={() => {
                goList();
              }}
              className="color-white size-large"
            />
          </div>
          <div className="right-area"></div>
        </div>
      </div>
    </>
  );
};

export default BuildingInfo;
