import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { Sort, UnionCmdType } from "../public-types";

// Voc 등록/수정/삭제 type
export interface VocModelUpdate {
  id?: string; //CS항목 (Voc항목) ID
  csItemName?: string; //CS항목 (Voc항목) 명
  partnerId?: string; //파트너ID
  csCategoryType?: string; //CS구분 (VoC유형타입)
  csTypeId?: string; //CS유형 (Voc유형) ID
  csTypeName?: string; //CS유형 (Voc유형) 명
  cmdType?: UnionCmdType; //요청타입
  isDeleted?: boolean; //삭제여부
  createdDate?: string; //생성일
  createdBy?: string; //등록ID
  modifiedDate?: string; //수정일
  modifiedBy?: string; //수정ID
}

// voc 목록 item type
export interface CsItemModel {
  id: string; //	CS유형 (Voc 항목) ID
  csTypeName: string; //	CS유형 (Voc유형) 명
  csCstegoryType: string; //	CS구분 (VoC유형타입)
  csTypeId: string; // Voc 유형 id (고장문의 등)
  csItemName: string;
  isDeleted: boolean; //	삭제여부
  createdDate: string; //	생성일
  createdBy: string; //	등록ID
  modifiedDate: string; //	수정일
  modifiedBy: string; //	수정ID
  partnerId?: string;
}

export interface VocModelList {
  content: CsItemModel[];
}

//voc 유형 목록 api - voc 메뉴에 [voc 유형 selectBox]
export interface VocOptionsType {
  id?: string; //	CS유형 (VoC유형) ID
  csTypeName: string; //		CS유형 (Voc유형) 명
  csCstegoryType: string; //		CS구분 (VoC유형타입)
  isDeleted: boolean; //		삭제여부
  createdDate: string; //		생성일
  createdBy: string; //		등록ID
  modifiedDate: string; //		수정일
  modifiedBy: string; //		수정ID
}

// voc 목록 queryParams
export interface VocQueryParams {
  page?: number;
  size?: number;
  sort?: Sort;
  csTypeIdList?: string;
  frontCsTypeIdList?: string[];
  searchType?: string;
  keywordList?: string;
}

export interface CsServiceItemsParams {
  page?: number;
  size?: number;
  sort?: Sort;
  serviceId?: string;
  serviceType?: UnionServiceType;
  keywordList?: string;
  csTypeIdList?: string;
  searchType?: string;
}
export interface CsLabels {
  id?: string;
  csItemId?: string;
  name: string;
  value: string;
  isDisplayed?: string;
  createdDate?: string;
  createBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
}

// Voc 항목 (건물탭)
export interface CsServiceItemModel {
  id?: string; //서비스별 CS항목(VoC항목)ID
  serviceType: UnionServiceType; //ServiceType		서비스타입
  serviceId: string; //		서비스ID
  csCategoryType?: string; //		CS구분 (VoC유형타입)
  csTypeId?: string; //CS유형 (Voc유형) ID
  csTypeName?: string; //CS유형 (Voc유형) 명
  csItemId: string; //CS항목 (Voc항목) ID
  csItemName?: string; //	CS항목 (Voc항목) 명
  isFrequent: boolean; //		자주하는질문설정여부
  cmdType: UnionCmdType; //		요청타입
  isDeleted?: boolean; //	삭제여부
  createdDate?: string; //	생성일
  createdBy?: string; //등록ID
  modifiedDate?: string; //	수정일
  modifiedBy?: string; //	수정ID
  checked?: boolean;
}

export const CsCategoryType = {
  VOC: "VoC",
  CS: "문의",
} as const;

export type CsCategoryTypeUnionType = typeof CsCategoryType[keyof typeof CsCategoryType] | "";

export const CsTypeOptions = [
  {
    label: "고장문의",
    value: "1",
  },
  {
    label: "리필문의",
    value: "2",
  },
  {
    label: "사용문의",
    value: "3",
  },
  {
    label: "주차문의",
    value: "4",
  },
  {
    label: "출입문의",
    value: "5",
  },
  {
    label: "오류문의",
    value: "6",
  },
  {
    label: "분실문의",
    value: "7",
  },
];
