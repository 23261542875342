import React, { useMemo } from "react";
import { chunk } from "lodash";

interface Props {
  pageIndex: number;
  totalPages: number;
  goPage: Function;
}

const ITEMS_PER_PAGE = 10;

export const BasePagination = ({ pageIndex, totalPages, goPage }: Props) => {
  const pageChunk = useMemo(() => {
    const pages = Array.from(Array(totalPages).keys());
    return chunk(pages, ITEMS_PER_PAGE);
  }, [totalPages]);

  const blockIndex = useMemo(() => {
    return (
      Math.floor((pageIndex + 1) / ITEMS_PER_PAGE) -
      ((pageIndex + 1) % ITEMS_PER_PAGE === 0 ? 1 : 0)
    );
  }, [pageIndex]);

  if (!totalPages || totalPages === 0) {
    return (
      <div className="base-pagination">
        <button className="base-pagination__previous" disabled></button>
        <div>
          <button className={`base-pagination__number`} disabled>
            1
          </button>
        </div>
        <button className="base-pagination__next" disabled></button>
      </div>
    );
  }

  return (
    <div className="base-pagination">
      {/* 한개씩 이동 */}
      <button
        className="base-pagination__previous prev-all"
        onClick={() => {
          goPage(0);
        }}
        disabled={pageIndex === 0}
      ></button>
      {/* 10개씩 이동 */}
      <button
        className="base-pagination__previous prev-10"
        onClick={() => {
          goPage(pageChunk[blockIndex][0] - 1);
        }}
        disabled={blockIndex === 0}
      ></button>
      {/* 끝으로 이동 */}
      <button
        className="base-pagination__previous"
        onClick={() => {
          goPage(pageIndex - 1);
        }}
        disabled={pageIndex === 0}
      ></button>

      {pageChunk[blockIndex]?.map((i) => {
        const pageNumber = i + 1;
        return (
          <div key={pageNumber}>
            <button
              className={`base-pagination__number`}
              onClick={() => {
                goPage(pageNumber - 1);
              }}
              disabled={pageIndex + 1 === pageNumber}
            >
              {pageNumber}
            </button>
          </div>
        );
      })}

      {/* 한개씩 이동 */}
      <button
        className="base-pagination__next"
        onClick={() => {
          goPage(pageIndex + 1);
        }}
        disabled={pageIndex + 1 === totalPages}
      ></button>
      {/* 10개씩 이동 */}
      <button
        className="base-pagination__next next-10"
        onClick={() => {
          goPage(pageChunk[blockIndex][9] + 1);
        }}
        disabled={blockIndex === pageChunk.length - 1}
      ></button>
      {/* 끝으로 이동 */}
      <button
        className="base-pagination__next next-all"
        onClick={() => {
          goPage(totalPages - 1);
        }}
        disabled={pageIndex === totalPages - 1}
      ></button>
    </div>
  );
};
