import { Cell } from "react-table";
import { ProductModel } from "src/api/product/product-types";

export const ProductTypeCell = (props: Cell<ProductModel>) => {
  const courtName = () => {
    if (props) {
      if (props.value === "FULL_COURT") {
        return "공간 임대";
      } else if (props.value === "OPEN_COURT") {
        return "공간 임대";
      } else if (props.value === "PRODUCT_UNRECOGNIZED") {
        return "-";
      } else if (props.value === "TIME_COURT") {
        return "공간예약";
      } else if (props.value === "NON_RESIDENT_OFFICE") {
        return "비상주";
      } else {
        return "-";
      }
    }
  };

  return (
    <div>
      <span>{courtName()}</span>
    </div>
  );
};
