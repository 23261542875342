import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import moment from "moment";
import numeral from "numeral";

interface RefObject<T> {
  readonly current: T | null;
}

/* 
  number 를 받아서 천단위 콤마를 찍어서 string 으로 리턴
  ex) numberToStringWithComma(1000) => "1,000"
  ex) numberToStringWithComma(1000.34) => "1,000"
  ex) numberToStringWithComma(1000.34, "0,0.00") => "1,000.34"
  ex) numberToStringWithComma(1000.34, "0,0.0") => "1,000.3"
*/
function numberToStringWithComma(num?: number, format: string = "0,0"): string {
  // return (num || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return numeral(num).format(format);
}

/* 
  소수점 두자리까지 반올림
  ex) roundToTwo(45.666) => 45.67
*/
function roundToTwo(num?: number): number {
  const value = num || 0;
  const m = Number((Math.abs(value) * 100).toPrecision(15));
  const round = (Math.round(m) / 100) * Math.sign(value);
  return round;
}

/* 
  소수점 두자리까지 버림
  ex) floorToTwo(45.666) => 45.66
*/
function floorToTwo(num?: number): number {
  const value = num || 0;
  const m = Number((Math.abs(value) * 100).toPrecision(15));
  const floor = (Math.floor(m) / 100) * Math.sign(value);
  return floor;
}

/* 
  평수 계산 (m2 x 0.3025, 소수점 2자리 반올림). 천단위 콤마 처리
  ex) calculatePyeong(1000000) => "302,500.00"
  ex) calculatePyeong(1000000, "0,0") => "302,500"
 */
function calculatePyeong(num?: number, format: string = "0,0.00"): string {
  const pyeong = (num || 0) * 0.3025;
  return numeral(pyeong).format(format);
}

/* 
  평수 계산 (m2 x 0.3025, 소수점 2자리 반올림)
  ex) calculatePyeongNum(444444) => 134444.31
 */
function calculatePyeongNum(num?: number): number {
  const pyeong = (num || 0) * 0.3025;
  return roundToTwo(pyeong);
}

// 전용률 구하기 - 전용면적 / 계약면적 * 100
function calculateRateOfUse(
  leasableAreaNet: string | number | undefined,
  leasableArea: string | number | undefined,
  format: string = "0,0.00",
): string {
  const value = (Number(leasableAreaNet || 0) / Number(leasableArea || 0)) * 100;
  const floorValue = floorToTwo(value);
  const result = numeral(floorValue).format(format);
  return result;
}

enum YmdFormat {
  YYYY = "YYYY",
  MM = "MM",
  YYYY_MM = "YYYY-MM",
  YYYY_MM_DD = "YYYY-MM-DD",
  TIME = "HH:mm:ss",
  YYYY_MM_DD_HH_MM = "YYYY-MM-DD HH:mm",
  FULL = "YYYY-MM-DD HH:mm:ss",
  WITH_TIME_ZONE = "YYYY-MM-DDTHH:mm:ss.SSS+09:00",
}

/**
 * table header date view Rule
 * 대부분 목록에서만 사용
 * @param date
 * @returns { showDate = 현재 보여줘야하는 포멧 , fullDate = mouseHoverDate}
 */
const dateFormattingOperation = (date: string) => {
  //기본 포멧은 년월일
  let formatting = YmdFormat.YYYY_MM_DD;
  let returnValue = { showDate: "", fullDate: "" };
  if (date) {
    //오늘 날짜
    const today = moment().format(YmdFormat.YYYY_MM_DD);
    //비교날짜
    const compareDate = moment(date).format(YmdFormat.YYYY_MM_DD);
    //오늘과 같은가
    if (moment(compareDate).isSame(today)) {
      //같으면 시간 포멧으로
      formatting = YmdFormat.TIME;
    }
    returnValue.showDate = moment(date).format(formatting);
    //마우스오버용 년월일 시분초 포멧
    returnValue.fullDate = moment(date).format(YmdFormat.FULL);
  }
  return returnValue;
};

// 전화번호 구성 변경 formatter
const PhoneFomatter = (number: string = "", type?: boolean) => {
  let formatNum = "";
  const num = number || "";

  if (num.length > 11) {
    if (num.indexOf("+82") === 0) {
      formatNum = num.replace(/(\d{4})(\d{4})(\d{4})/, "$1-$2-$3");
    }
  } else if (num.length === 11) {
    if (type) {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-****-$3");
    } else {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
  } else if (num.length === 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, "$1-$2");
  } else {
    if (num.indexOf("02") === 0) {
      if (type) {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-****-$3");
      } else {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
      }
    } else {
      if (type) {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-***-$3");
      } else {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      }
    }
  }
  return formatNum;
};

// 비용 단위 천 / 억 으로 변환
const getKoreanCost = (number: number) => {
  const koreanUnits = ["원", "만원", "억", "조"];
  let answer = "";
  let unit = 10000;
  let index = 0;
  let division = Math.pow(unit, index);

  while (Math.floor(number / division) > 0) {
    const mod = Math.floor((number % (division * unit)) / division);
    if (mod) {
      const modToString = mod.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      answer = `${modToString}${koreanUnits[index]} ` + answer;
    }
    // division = Math.pow(unit, ++index);
    division = Math.pow(unit, ++index);
  }
  return `${answer}`;
};

// 숫자만 입력
const onlyNumber = (str: any) => {
  return str.replace(/[^0-9.]/g, "").replace(/(\.*)\./g, "$1");
};

// 숫자 / 특수기호 입력
const onlyNumberAndSign = (str: any) => {
  return str.replace(/[^0-9. +]/g, "").replace(/(\.*)\./g, "$1");
};

// 세자리수 콤마 찍기
const inputPriceFormat = (str: any) => {
  const comma = (str: any) => {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
  };
  const uncomma = (str: any) => {
    str = String(str);
    return str.replace(/[^\d]+/g, "");
  };
  return comma(uncomma(str));
};

// 콤마 삭제
const deleteCommas = (str: any) => {
  if (typeof str === "number") {
    return;
  } else {
    return str.replace(/,/g, "");
  }
  // return str.replace(/,/g, "");
};

/**
 * 휴대폰 번호 validation ("+821012345678" || "01012345678")
 * @param mobileNumber 입력한 휴대폰 번호
 * @param requiredMessage 필수입력 에러 메세지 (없으면 "필수입력항목입니다")
 * @param formatMessage 휴대폰 번호 포맷 에러 메세지 (없으면 "휴대폰 번호 형식에 맞게 입력해주세요")
 * @returns
 */
const validateMobileNumber = (
  mobileNumber?: string,
  requiredMessage?: string,
  formatMessage?: string,
): boolean | string => {
  if (!mobileNumber) return requiredMessage || "필수 입력 항목입니다";
  const patterns = [/^010\d{8}$/, /^[+]82\d{10}$/];
  const first = patterns[0].test(mobileNumber);
  const second = patterns[1].test(mobileNumber);
  return first || second || formatMessage || "휴대폰 번호 형식에 맞게 입력해주세요";
};

/**
 * 전화 번호 validation ("+821012345678" || "01012345678")
 * @param phoneNumber 입력한 휴대폰 번호
 * @param requiredMessage 필수입력 에러 메세지 (없으면 "필수입력항목입니다")
 * @param formatMessage 휴대폰 번호 포맷 에러 메세지 (없으면 "휴대폰 번호 형식에 맞게 입력해주세요")
 * @returns
 */

// 전화번호
const validatePhoneNumber = (
  phoneNumber?: string,
  requiredMessage?: string,
  formatMessage?: string,
): boolean | string => {
  if (!phoneNumber) return requiredMessage || "필수 입력 항목입니다";
  const patterns = [/^[+]82\d{10}$/, /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/];
  const first = patterns[0].test(phoneNumber);
  const second = patterns[1].test(phoneNumber);

  return first || second || formatMessage || "전화번호 형식에 맞게 입력해주세요";
};

/**
 * 차량 번호 validation ("12가1234" || "123가1234")
 * @param carNumber 입력한 차량번호
 * @param isRequired 필수입력 여부 (필수입력 아닐 경우 값을 입력했을 때만 포맷 validation 체크)
 * @param requiredMessage 필수입력 에러 메세지 (없으면 "필수입력항목입니다")
 * @param formatMessage 차량번호 포맷 에러 메세지 (없으면 "차량 번호 형식에 맞게 입력해주세요")
 * @returns
 */
const validateCarNumber = (
  carNumber?: string,
  isRequired?: boolean,
  requiredMessage?: string,
  formatMessage?: string,
): boolean | string => {
  if (isRequired) {
    if (!carNumber) return requiredMessage || "필수입력항목입니다";
  }
  if (carNumber) {
    const pattern = /^\d{2,3}[가-힣]{1}\d{4}$/;
    return pattern.test(carNumber) || formatMessage || "차량 번호 형식에 맞게 입력해주세요";
  }
  return true;
};

// 모달에서 page 이동 시 scroll은 최상단으로 이동
// ref 타입을 HTMLElement 로 했는데 current를 잡지 못해 any로 놔두었습니다
// 사용법 - scroll을 상단으로 올릴 DOM element 를 useRef 로 잡아서 인자로 전달
const onMoveScrollTop = (element: any) => {
  element.current.scrollTo(0, 0);
};

// 엑셀 다운로드
const downloadExcel = async <T>({
  data,
  fileName,
  header,
}: {
  data: T[];
  fileName: string;
  header: string[];
}) => {
  const XLSX = await import("xlsx");

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
  XLSX.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `${fileName}.xlsx`);

  return true;
};

// 분 -> 시간 변환
const showHourMinute = (onlyMinutes: string) => {
  let rtn = "";
  let hour = "";
  let minute: string | number = "";
  if (onlyMinutes) {
    if (Number(onlyMinutes) === -1) {
      hour = "제한없음";
    } else {
      if (Number(onlyMinutes) > 0) {
        hour =
          numberToStringWithComma(Number(Math.floor(Number(onlyMinutes) / 60).toFixed(0))) +
          "시간 ";
        minute = Number(Math.floor(Number(onlyMinutes) % 60).toFixed(0));
        if (minute > 0) {
          hour = hour + minute + "분";
        }
      }
    }

    rtn = hour;
  }

  return rtn;
};

const validateEmail = (email: string) => {
  const pattern = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return pattern.test(email);
};

const validateEmailText = (
  email?: string,
  requiredMessage?: string,
  formatMessage?: string,
): boolean | string => {
  if (!email) return requiredMessage || "필수 입력 항목입니다";
  const pattern = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return pattern.test(email) || formatMessage || "이메일 형식에 맞게 입력해주세요";
};

const findPgCode = (pgcode: string) => {
  let rtn = "";
  switch (pgcode) {
    case "creditcard":
      rtn = "신용카드";
      break;
    case "banktransfer":
      rtn = "계좌이체";
      break;
    case "cms":
      rtn = "CMS";
      break;
    case "virtualaccount":
      rtn = "가상걔좌";
      break;
  }
  return rtn;
};

const validatePopBillGovCode = (taxInvoiceNumber: string) => {
  const pattern = /^[a-zA-Z0-9]{8}[-\s]?[a-zA-Z0-9]{8}[-\s]?[a-zA-Z0-9]{8}$/;
  return pattern.test(taxInvoiceNumber);
};

// 시압지 등록번호 중간에 하이픈 처리
const companyRegistrationHypen = (str: string) => {
  let tmp = "";

  str = str.replace(/[^0-9]/g, "");

  if (str.length < 4) {
    return str;
  } else if (str.length < 6) {
    tmp += str.substring(0, 3);
    tmp += "-";
    tmp += str.substring(3, 5);
    return tmp;
  } else if (str.length < 11) {
    tmp += str.substring(0, 3);
    tmp += "-";
    tmp += str.substring(3, 5);
    tmp += "-";
    tmp += str.substring(5);
    return tmp;
  }
};

// 법인 등록번호 중간에 하이픈 처리
const corpHypen = (str: string) => {
  let tmp = "";

  // 모든 숫자와 하이픈을 제외한 문자를 제거합니다.
  str = str.replace(/[^0-9-]/g, "");

  // 하이픈을 제거하고 숫자만으로 이루어진 문자열을 생성합니다.
  const numberOnly = str.replace(/-/g, "");

  if (numberOnly.length <= 6) {
    return numberOnly;
  } else if (numberOnly.length < 14) {
    tmp += numberOnly.substring(0, 6) + "-";
    tmp += numberOnly.substring(6);
    return tmp;
  }
};

// 문자열에서 모든 하이픈 제거.
const removeHyphens = (str: string) => {
  return str.replace(/-/g, "");
};

// date(날짜) 를 moment를 이용해 요일로 변경
const onChangeKoreanDays = (date?: string) => {
  if (date) {
    const day = moment(date).format("dddd");
    if (day) {
      let koreanDay = "";
      switch (day) {
        case "Monday":
          koreanDay = "월";
          break;
        case "Tuesday":
          koreanDay = "화";
          break;
        case "Wednesday":
          koreanDay = "수";
          break;
        case "Thursday":
          koreanDay = "목";
          break;
        case "Friday":
          koreanDay = "금";
          break;
        case "Saturday":
          koreanDay = "토";
          break;
        case "Sunday":
          koreanDay = "일";
          break;
      }
      return koreanDay;
    }
  }
};
const changeListSearchPhoneNumber = (str: string) => {
  let rtnString = str;
  const patterns = [
    /^[+]82\d{10}$/,
    /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/,
    /^820\d{10}$/,
    /^[+]820\d{10}$/,
  ];
  //1. -제거
  rtnString = rtnString.replaceAll("-", "");
  //2. trim 처리
  rtnString = rtnString.replaceAll(" ", "");
  //3. (전화번호 형식이면)첫자리 0이면 +82로 치환
  if (patterns[1].test(rtnString)) {
    rtnString = "+82" + rtnString.replace("0", "");
  }
  if (patterns[2].test(rtnString)) {
    rtnString = rtnString.replace("820", "+82");
  }
  if (patterns[3].test(rtnString)) {
    rtnString = rtnString.replace("+820", "+82");
  }
  return rtnString;
};

// 핸드폰번호 +82 E.164 포맷으로 변경
const parsedPhoneNumber = (phone: string) => {
  let phoneNumber;
  if (phone) {
    if (isValidPhoneNumber(phone, "KR") === false) {
      return phone;
    }
    phoneNumber = parsePhoneNumber(phone, "KR").number;
  }
  return phoneNumber;
};

// E.164 포맷 -> 일반 핸드폰 포맷으로 변경 ex)010-1111-2222
const formatPhoneNumber = (phone: string) => {
  let phoneNumber;
  if (phone) {
    if (isValidPhoneNumber(phone, "KR") === false) {
      return phone;
    }
    phoneNumber = parsePhoneNumber(phone, "KR").formatNational();
  }
  return phoneNumber;
};

enum ViewYmdFormat {
  YYYY = "YYYY",
  MM = "MM",
  YYYY_MM = "YYYY.MM",
  YYYY_MM_DD = "YYYY.MM.DD",
  HH_MM = "HH:mm",
  TIME = "HH:mm:ss",
  YYYY_MM_DD_HH_MM = "YYYY.MM.DD HH:mm",
  FULL = "YYYY.MM.DD HH:mm:ss",
  YYYY_MM_DD_WEEK = "YYYY.MM.DD (ddd)",
  YYYY_MM_DD_WEEK_HH_MM = "YYYY.MM.DD(ddd) HH:mm",
}
export {
  numberToStringWithComma,
  roundToTwo,
  floorToTwo,
  calculatePyeong,
  calculatePyeongNum,
  dateFormattingOperation,
  PhoneFomatter,
  YmdFormat,
  ViewYmdFormat,
  getKoreanCost,
  calculateRateOfUse,
  onlyNumber,
  deleteCommas,
  onlyNumberAndSign,
  inputPriceFormat,
  validateMobileNumber,
  validateCarNumber,
  onMoveScrollTop,
  showHourMinute,
  findPgCode,
  validateEmail,
  validateEmailText,
  validatePopBillGovCode,
  companyRegistrationHypen,
  corpHypen,
  removeHyphens,
  validatePhoneNumber,
  onChangeKoreanDays,
  changeListSearchPhoneNumber,
  parsedPhoneNumber,
  formatPhoneNumber,
  downloadExcel,
};
