import { useEffect } from "react";
import { Control, Controller, useFieldArray, UseFormRegister } from "react-hook-form";
import { ProductGuideNetworkCatalog, ProductGuideUpdateForm } from "src/api/product/product-types";
import { BaseButton, BaseInput, BaseTextarea } from "src/components";
import FreeInternetNetwork from "./FreeInternetNetwork";

type Props = {
  control: Control<ProductGuideUpdateForm>;
  errors?: any;
  register: UseFormRegister<ProductGuideUpdateForm>;
};

/* 
  공간상품 > 등록 or 수정 > 안내 > 와이파이 컴포넌트
*/
const FreeInternetSection = ({ control, errors, register }: Props) => {
  // 고객용 네트워크 와이파이 목록
  const {
    fields: customerWifiesGuide,
    append: appendCustomerNetworkGuide,
    remove: removeCustomerNetworkGuide,
  } = useFieldArray({
    control,
    name: "productGuide.customerWifiList",
  });

  // 방문자용 네트워크 와이파이 목록
  const {
    fields: visitorWifiesGuide,
    append: appendVisitorNetworkGuide,
    remove: removeVisitorNetworkGuide,
  } = useFieldArray({
    control,
    name: `productGuide.visitorWifiList`,
  });

  // 고객용 / 방문자용 네트워크 - 위치 유효성 검사 적용
  useEffect(() => {
    if (customerWifiesGuide.length > 0) {
      customerWifiesGuide.forEach((wifi, wifiIdx: number) => {
        register(`productGuide.customerWifiList.${wifiIdx}.location`, {
          required: true,
        });
      });
    }

    if (visitorWifiesGuide.length > 0) {
      visitorWifiesGuide.forEach((wifi, wifiIdx: number) => {
        register(`productGuide.visitorWifiList.${wifiIdx}.location`, {
          required: true,
        });
      });
    }
  }, [customerWifiesGuide, visitorWifiesGuide, register]);

  return (
    <>
      <section className="contents-container__grid contents-container__1200 mb30">
        <div className="contents-container__grid-index">
          <p className="required">상세내용</p>
        </div>
        <div className="contents-container__grid-contents">
          {/* 고객용 네트워크 */}
          <div className="mb60">
            <div className="py16">
              <span className="text-primary5">고객용 네트워크</span>
            </div>
            {customerWifiesGuide.map((network: ProductGuideNetworkCatalog, wifiIdx: number) => {
              return (
                <div className="border-gray mb10" key={network.id}>
                  <div className="pa16">
                    <div className="flex-center mb10">
                      <div className="minmax140 required">
                        <span>위치</span>
                      </div>
                      <Controller
                        control={control}
                        name={`productGuide.customerWifiList.${wifiIdx}.location`}
                        render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                          return (
                            <BaseInput
                              name={name}
                              value={value}
                              onChange={onChange}
                              placeholder="다수의 wifi 공유기를 제공할 경우 위치를 입력해주세요."
                              errorText={error && "필수입력 항목입니다."}
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                    <FreeInternetNetwork
                      control={control}
                      wifiIdx={wifiIdx}
                      targetWifiGuideListName="customerWifiList"
                      errors={errors}
                      register={register}
                    />
                    <div className="flex-center mb10">
                      <div className="minmax140">
                        <span>설명</span>
                      </div>
                      <Controller
                        control={control}
                        name={`productGuide.customerWifiList.${wifiIdx}.description`}
                        render={({ field: { onChange, name, value }, fieldState: { error } }) => (
                          <BaseTextarea
                            maxLength={1000}
                            placeholder="wifi 사용 관련 안내 사항을 입력해주세요."
                            name={name}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      ></Controller>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="flex-center-end">
              <BaseButton
                title="추가"
                className="color-white mr10"
                onClick={() => {
                  // wifi Item 추가
                  appendCustomerNetworkGuide({
                    // id: "", //이용안내 네트워크 ID
                    isOpened: false,
                    location: "",
                    productGuideNetworkList: [
                      {
                        orderNums: 1,

                        wifiName: "",
                        wifiPassword: "",
                      },
                    ],
                    description: "",
                  });
                }}
              />
              <BaseButton
                title="삭제"
                className="color-white"
                onClick={() => removeCustomerNetworkGuide(customerWifiesGuide.length - 1)}
                disabled={customerWifiesGuide.length === 1}
              />
            </div>
          </div>

          {/* 방문자 네트워크 */}
          <div>
            <div className="py16">
              <span className="text-primary5">방문자용 네트워크</span>
            </div>
            {visitorWifiesGuide?.map((network, wifiIdx: number) => (
              <div className="border-gray mb10" key={network.id}>
                <div className="pa16">
                  <div className="flex-center mb10">
                    <div className="minmax140">
                      <span>위치</span>
                    </div>
                    <Controller
                      control={control}
                      name={`productGuide.visitorWifiList.${wifiIdx}.location`}
                      render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                        return (
                          <BaseInput
                            name={name}
                            value={value}
                            onChange={onChange}
                            placeholder="다수의 wifi 공유기를 제공할 경우 위치를 입력해주세요."
                            errorText={error && "필수입력 항목입니다."}
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                  <FreeInternetNetwork
                    control={control}
                    wifiIdx={wifiIdx}
                    targetWifiGuideListName="visitorWifiList"
                    errors={errors}
                    register={register}
                  />
                  <div className="flex-center mb10">
                    <div className="minmax140">
                      <span>설명</span>
                    </div>
                    <Controller
                      control={control}
                      name={`productGuide.visitorWifiList.${wifiIdx}.description`}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseTextarea
                          maxLength={1000}
                          placeholder="wifi 사용 관련 안내 사항을 입력해주세요."
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </div>
            ))}

            <div className="flex-center-end">
              <BaseButton
                title="추가"
                className="color-white mr10"
                onClick={() => {
                  appendVisitorNetworkGuide({
                    isOpened: true,
                    location: "",
                    productGuideNetworkList: [
                      {
                        orderNums: 1,
                        wifiName: "",
                        wifiPassword: "",
                      },
                    ],
                    description: "",
                  });
                }}
              />
              {visitorWifiesGuide.length !== 0 && (
                <BaseButton
                  title="삭제"
                  className="color-white"
                  onClick={() => removeVisitorNetworkGuide(visitorWifiesGuide.length - 1)}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default FreeInternetSection;
