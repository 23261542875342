import React, { useCallback } from "react";
import { Control, UseFormGetValues, UseFormSetValue, useWatch } from "react-hook-form";
import { BaseInput, BaseRadio, BaseSelect } from "src/components";
import { FacilityFormData, Restroom, restroomLocationOptions } from "../facility-types";

type Props = {
  control: Control<FacilityFormData>;
  getValues: UseFormGetValues<FacilityFormData>;
  setValue: UseFormSetValue<FacilityFormData>;
};

/* 
  공간상품 > 등록 or 수정 > 시설/서비스 > 화장실 컴포넌트
*/
const RestroomsSection = ({ control, getValues, setValue }: Props) => {
  // 화장실
  const restrooms: Array<Restroom> = useWatch({
    control,
    name: "restrooms",
  });

  // 추가 버튼 클릭
  const clickAddRestroom = useCallback(() => {
    const maxSeq = Math.max.apply(
      Math,
      restrooms.map((o) => o.seq),
    );

    const newRestrooms: Array<Restroom> = [
      ...restrooms,
      {
        seq: maxSeq + 1,
        location: "INDOOR",
        type: "SEPARATION",
        description: "",
      },
    ];
    setValue("restrooms", newRestrooms, { shouldDirty: true, shouldValidate: true });
  }, [restrooms, setValue]);

  // 삭제 버튼 클릭
  const clickDeleteRestroom = useCallback(
    (seq: number) => {
      const newRestrooms: Array<Restroom> = restrooms.filter((restroom) => restroom.seq !== seq);
      setValue("restrooms", newRestrooms, { shouldDirty: true, shouldValidate: true });
    },
    [restrooms, setValue],
  );

  // 위치 선택
  const changeRestroomLocation = useCallback(
    (selectedValue: "INDOOR" | "OUTDOOR", seq: number) => {
      const currentRestrooms: Array<Restroom> = [...restrooms];
      const newRestrooms: Array<Restroom> = [];
      for (let i = 0; i < currentRestrooms.length; i++) {
        const current = currentRestrooms[i];
        if (current.seq === seq) {
          newRestrooms.push({
            id: current?.id,
            seq: current.seq,
            location: selectedValue,
            type: current.type,
            description: current.description,
          });
        } else {
          newRestrooms.push(current);
        }
      }
      setValue("restrooms", newRestrooms, { shouldDirty: true, shouldValidate: true });
    },
    [restrooms, setValue],
  );

  // 구분 선택
  const changeRestroomType = (selectedValue: "PUBLIC" | "SEPARATION", seq: number) => {
    const currentRestrooms: Array<Restroom> = [...restrooms];
    const newRestrooms: Array<Restroom> = [];
    for (let i = 0; i < currentRestrooms.length; i++) {
      const current = currentRestrooms[i];
      if (current.seq === seq) {
        newRestrooms.push({
          id: current?.id,
          seq: current.seq,
          location: current.location,
          type: selectedValue,
          description: current.description,
        });
      } else {
        newRestrooms.push(current);
      }
    }
    setValue("restrooms", newRestrooms, { shouldDirty: true, shouldValidate: true });
  };

  // 설명 변경
  const changeRestroomDescription = (value: string, seq: number) => {
    const currentRestrooms: Array<Restroom> = [...restrooms];
    const newRestrooms: Array<Restroom> = [];
    for (let i = 0; i < currentRestrooms.length; i++) {
      const current = currentRestrooms[i];
      if (current.seq === seq) {
        newRestrooms.push({
          id: current?.id,
          seq: current.seq,
          location: current.location,
          type: current.type,
          description: value,
        });
      } else {
        newRestrooms.push(current);
      }
    }
    setValue("restrooms", newRestrooms, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <section className="contents-container__grid contents-container__1200 mb30">
      <div className="contents-container__grid-index">
        <p className="required">화장실</p>
      </div>
      <div className="contents-container__grid-contents">
        <table className="inner-table" width="100%">
          <thead>
            <tr>
              <th>
                <span>위치</span>
              </th>
              <th>
                <span>구분</span>
              </th>
              <th>
                <span>설명</span>
              </th>
              <th>
                <span></span>
              </th>
            </tr>
          </thead>
          <tbody>
            {restrooms.map((restroom: Restroom, index: number) => (
              <tr key={index}>
                <td width="120">
                  <BaseSelect
                    stateOptions={restroomLocationOptions}
                    setStateValue={(value: string) =>
                      changeRestroomLocation(value as "INDOOR" | "OUTDOOR", restroom.seq)
                    }
                    value={restroom.location}
                  />
                </td>
                <td width="220">
                  <div className="flex-center-center">
                    <BaseRadio
                      id={`restroom_type_${index}_SEPARATION`}
                      name={`restroom_type_${index}`}
                      value="SEPARATION"
                      label="남녀 분리"
                      className="mr16"
                      checked={restroom.type === "SEPARATION"}
                      onChange={(checked: boolean) => {
                        if (checked) {
                          changeRestroomType("SEPARATION", restroom.seq);
                        }
                      }}
                    />
                    <BaseRadio
                      id={`restroom_type_${index}_PUBLIC`}
                      name={`restroom_type_${index}`}
                      value="PUBLIC"
                      label="남녀 통합"
                      checked={restroom.type === "PUBLIC"}
                      onChange={(checked: boolean) => {
                        if (checked) {
                          changeRestroomType("PUBLIC", restroom.seq);
                        }
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div className="w-100">
                    <BaseInput
                      onChange={(value: string) => changeRestroomDescription(value, restroom.seq)}
                      value={restroom.description}
                      name={`restroom_description_${index}`}
                    />
                  </div>
                </td>
                <td>
                  <div className="flex-center-center">
                    {index === 0 ? (
                      <button
                        type="button"
                        className="base-add-btn"
                        onClick={() => clickAddRestroom()}
                      ></button>
                    ) : (
                      <button
                        type="button"
                        className="base-erase-btn"
                        onClick={() => clickDeleteRestroom(restroom.seq)}
                      ></button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};
export default RestroomsSection;
