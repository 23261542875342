import qs from "qs";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContentsTitle } from "src/components/ContentsTitle";
import PagePath from "../../../pagePath.json";
import MemberAdminMemo from "./components/adminMemo/MemberAdminMemo";
import MemberBasicInfo from "./components/baseInfo/MemberBasicInfo";
import MemberRestrictionInfo from "./components/restrictionInfo/MemberRestrictionInfo";
import UseProduct from "./components/useProductInfo/useProduct";
import UseRequestProduct from "./components/useRequestInfo/useRequestProduct";
import MemberAccessInfoV2 from "./components/accessInfo/MemberAccessInfoV2";

const MemberDetail = () => {
  const tabs = [
    { label: "회원 기본 정보", value: "MemberBasicInfo" },
    { label: "신청/계약 내역", value: "MemberProductUseRequestInfo" },
    { label: "상품 이용 내역", value: "MemberProductUseInfo" },
    { label: "이용 제한 내역", value: "MemberRestrictionInfo" },
    { label: "엑세스 권한(V2)", value: "MemberAccessInfoV2" },
    // { label: "관리자 메모", value: "MemberAdminMemoInfo" },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();

  // console.log(location);

  // //계정연결 조회 API
  // const { executeAsync } = useApiOperation(getMemberDetail);
  // //기본정보
  // const [basicInfo, setBasicInfo] = useState<MemberModel>();
  // const [loginInfo, setLoginInfo] = useState<MemberLoginModel>();

  // path variable 에서 productId 추출
  const id = useMemo(() => {
    const cutString = "/mng/central/member/detail/";
    const urlString = location.pathname.replace(cutString, "");
    const paths = urlString.split("/");
    const pathMemberId = paths[0];
    return pathMemberId ? Number(pathMemberId) : undefined;
  }, [location]);
  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      let tb = queryParams?.tab.toString();
      let splitTabs = [];
      if (tb.includes("/")) {
        splitTabs = tb.split("/");
        // console.log(splitTabs);
        tb = splitTabs[0];
      }
      return tabs.find((tab) => tab.value === tb);
    }
    return tabs[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };

      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      let pathname = location.pathname;
      if (newQueryParams.tab !== "MemberRestrictionInfo") {
        const front = pathname.split("/restriction");
        pathname = front[0];
      }
      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      // console.log("clickBreadCrumb", crumb);
      if (crumb.value === "member") {
        // 공간상품 목록으로 이동
        navigate(PagePath.product.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "member", label: "회원 상세 정보" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  // // title 에 건물명 바인딩
  // let memberNo;
  // if (basicInfo?.memberNo) {
  //   memberNo = basicInfo?.memberNo;
  // }
  // useTitleOperation(memberNo);

  return (
    <div className="page-member-detail">
      <ContentsTitle
        title="회원 상세 정보"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />
      <div className="contents-container__scroll">
        {activeTab?.value === "MemberBasicInfo" && <MemberBasicInfo />}
        {activeTab?.value === "MemberProductUseRequestInfo" && <UseRequestProduct />}
        {activeTab?.value === "MemberProductUseInfo" && <UseProduct />}
        {activeTab?.value === "MemberRestrictionInfo" && <MemberRestrictionInfo />}
        {activeTab?.value === "MemberAccessInfoV2" && <MemberAccessInfoV2 />}
        {/* {activeTab?.value === "MemberAdminMemoInfo" && <MemberAdminMemo />} */}
      </div>
    </div>
  );
};
export default MemberDetail;
