import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { BuildingModel } from "src/api/building/building-types";
import { BaseTooltip } from "src/components";
import { PagePath } from "src/pages/product/details";
import { BuildingDateCell } from "./BuildingDateCell";
import { BuildingLinkCell } from "./BuildingLinkCell";

/* 
  건물 테이블 columns
*/
const BuildingColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: (props: Cell<BuildingModel>) => <BuildingLinkCell {...props} />,
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 200,
    Cell: (props: Cell<BuildingModel>) => (
      <div className="w-100 text-left">
        <BuildingLinkCell {...props} />
      </div>
    ),
  },
  {
    Header: "주소",
    accessor: "address",
    width: 240,
    Cell: ({ value }: Cell<BuildingModel>) => <div className="w-100 text-left"><BaseTooltip contents={value} /></div>,
  },
  {
    Header: "규모",
    accessor: "size",
    width: 130,
    Cell: (props: Cell<BuildingModel>) => {
      return (
        <div>
          {`지하${props.row.original.undergroundFloorNums}층 / 지상${props.row.original.floorNums}층`}
        </div>
      );
    },
  },
  {
    Header: "공개여부",
    accessor: "status",
    width: 110,
    Cell: ({ value }: Cell<BuildingModel>) => {
      return (
        <div className={value === "ENABLED" ? "chip visible" : "chip invisible"}>
          {value === "ENABLED" ? "공개중" : "비공개"}
        </div>
      );
    },
  },
  {
    Header: "생성일",
    accessor: "createdDate",
    width: 110,
    Cell: (props: Cell<BuildingModel>) => <BuildingDateCell {...props} />,
  },
  {
    Header: "등록ID",
    accessor: "createdBy",
    width: 160,
    Cell: ({ value }: Cell<BuildingModel>) => <div className="w-100 text-left ellipsis2">{value}</div>,
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 110,
    Cell: (props: Cell<BuildingModel>) => <BuildingDateCell {...props} />,
  },
  {
    Header: "최종 작성자",
    accessor: "modifiedBy",
    width: 160,
    Cell: ({ value }: Cell<BuildingModel>) => <div className="w-100 text-left ellipsis2">{value}</div>,
  },
  {
    Header: "파트너",
    accessor: "partnerId",
    width: 80,
    Cell: ({ value }: Cell<BuildingModel>) => {
      return (
        <Link
          target={"_blank"}
          to={PagePath.partner.detail.replace(":id", value)}
          className="text-hilight"
        >
          {value}
        </Link>
      );
    },
  },
];

export default BuildingColumns;
