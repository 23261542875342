import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getMemberRestriction } from "src/api/member/member-api";
import { BaseButton, LoadingBar } from "src/components";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import { PagePath } from "src/pages/product/details";
import { YmdFormat } from "src/utils";
import TextAttribute from "./components/TextAttribure";

// type FormData = {
//   startDateTime: Date;
//   endDateTime: Date;
//   comment: string;
// };

// 등록
const MemberRestrictionDetail = ({ basicInfo }: any) => {
  const { memberId, id } = useParams();

  const navigate = useNavigate();
  const [body, setBody] = useState<any>();

  const { loading, executeAsync: restrictionDetail } = useApiOperation(getMemberRestriction);

  const goRestricionList = useCallback(() => {
    const path =
      PagePath.restriction.list.replace(":memberId", basicInfo?.id.toString()) +
      "?tab=MemberRestrictionInfo";
    navigate(path);
  }, [basicInfo, navigate]);
  const goRestricionEdit = useCallback(() => {
    const path =
      PagePath.restriction.form.replace(":memberId", basicInfo?.id.toString()) +
      "?tab=MemberRestrictionInfo&id=" +
      id?.toString();
    navigate(path);
  }, [basicInfo?.id, id, navigate]);
  // console.log(basicInfo);

  useEffect(() => {
    if (memberId && id) {
      restrictionDetail({ memberId: Number(memberId), id: Number(id) }).then((response) => {
        const { restriction }: any = response!.data!.data!;
        // console.log(restriction);
        setBody(restriction);
      });
    }
  }, [id, memberId, restrictionDetail]);

  // if (error) {
  //   return <div>에러가 발생했습니다</div>;
  // }

  return (
    <>
      <LoadingBar visible={loading} />
      <div className="contents-container__wrap detail-usage-restriction">
        <div className="contents-container__wrap-contents">
          <ContentsIdSection
            title="이용 제한 상세"
            id={basicInfo?.id}
            noTitle="회원번호"
            no={basicInfo?.memberNo}
          />
          <section>
            <div className="contents-container__sub-title">
              <h2>회원정보</h2>
            </div>
            <TextAttribute title={"id"} value={basicInfo?.id} />
            <TextAttribute title={"회원번호"} value={basicInfo?.memberNo} />
            <TextAttribute title={"휴대폰번호"} value={basicInfo?.phoneNumber} />
            <TextAttribute title={"이메일"} value={basicInfo?.email} />
          </section>

          <section className="contents-container__divide-top">
            <div className="contents-container__sub-title">
              <h2>이용 제한 내용</h2>
            </div>
            <div className="contents-container__grid mb20">
              <div className="contents-container__grid-index">
                <p>이용 제한 기간</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  <span>시작일: {moment(body?.inclusiveStartDate).format(YmdFormat.FULL)}</span>
                  <span className="ml20">
                    종료일: {moment(body?.inclusiveEndDate).format(YmdFormat.FULL)}
                  </span>
                </p>
              </div>
            </div>
            <div className="contents-container__grid mb30">
              <div className="contents-container__grid-index">
                <p>제한사유</p>
              </div>
              <div className="contents-container__grid-contents">
                {/* <p>제한사유내용</p> */}
                <div className="minmax400">
                  <p>{body?.comment}</p>
                </div>
              </div>
            </div>

            <TextAttribute
              title={"생성일"}
              value={moment(body?.createdDate).format(YmdFormat.FULL)}
              mb={"mb30"}
            />
            <TextAttribute
              title={"최종 수정일"}
              value={moment(body?.lastModifiedDate).format(YmdFormat.FULL)}
              mb={"mb30"}
            />
            <TextAttribute title={"최종 작성자"} value={body?.lastModifiedBy} mb={"mb30"} />
          </section>
        </div>
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              onClick={() => goRestricionList()}
              className="color-white size-large"
            />
          </div>
          <div className="right-area">
            <BaseButton
              title="수정"
              onClick={() => goRestricionEdit()}
              className="color-black size-large"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberRestrictionDetail;
