import React, { useCallback, useEffect, useState } from "react";
import { useApiOperation } from "src/api/hooks";
import { getBankCodeListAsync } from "src/api/provider/provider-api";
import { BankCodeModel, ProviderAccountModel } from "src/api/provider/provider-types";
import { BaseCheckbox } from "src/components";

type Props = {
  providerAccount?: Array<ProviderAccountModel>;
};

const ProviderAccountDetail = ({ providerAccount }: Props) => {
  // 프로바이더 은행코드 리스트

  const [bankList, setBankList] = useState<Array<BankCodeModel>>([]);

  const { executeAsync: getBankCodeList } = useApiOperation(getBankCodeListAsync);

  // 은행코드 리스트
  const getBankCodeListApi = useCallback(async () => {
    const response = await getBankCodeList({ codeGroup: "POPBILL_BANK_CODE" });
    if (response.status >= 200 || response.status <= 200) {
      const result = response.data.data.content;

      setBankList(result);
    }
  }, []);

  useEffect(() => {
    getBankCodeListApi();
  }, [getBankCodeListApi]);

  const getBankName = useCallback(
    (bankCode: string) => {
      const bankName = bankList.find((item) => item.code === bankCode);
      return bankName?.desc;
    },
    [bankList],
  );

  return (
    <article className="pb30 contents-container__divide-top">
      <div className="contents-container__sub-title mb20">
        <h2>계좌 정보</h2>
      </div>
      <section className="contents-container__1200">
        {providerAccount && providerAccount.length > 0 ? (
          providerAccount?.map((account: ProviderAccountModel) => {
            return (
              <div
                key={account.accountId}
                className="border-gray py16 pr16 px20 position-relative mb10"
              >
                <section className="flex-center mb10">
                  <div className="minmax123">
                    <span className=" font13 text-primary3">용도</span>
                  </div>
                  <div className="flex-center h-36">
                    {account.accountType === "DEPOSIT_ACCOUNT" ? (
                      <span className="font13">보증금</span>
                    ) : account.accountType === "RENT_ACCOUNT" ? (
                      <span className="font13">임대료</span>
                    ) : (
                      <span className="font13">관리비</span>
                    )}
                  </div>
                </section>
                <section className="flex-center mb10">
                  <div className="minmax123">
                    <span className=" font13 text-primary3">은행</span>
                  </div>
                  <div className="minmax160 font13">{getBankName(account.bankCode || "")}</div>
                </section>
                <section className="flex-center mb10">
                  <div className="minmax123">
                    <span className=" font13 text-primary3">계좌번호</span>
                  </div>
                  <div className="minmax400 font13">
                    <span>{account.accountNumber}</span>
                  </div>
                </section>
                <section className="flex-center">
                  <div className="minmax123">
                    <span className=" font13 text-primary3">예금주</span>
                  </div>
                  <div className="flex-center minmax400 font13">
                    <span>{account.accountHolder}</span>
                  </div>
                </section>
              </div>
            );
          })
        ) : (
          <p className="font14">등록된 계좌 정보가 없습니다.</p>
        )}
      </section>
    </article>
  );
};

export default ProviderAccountDetail;
