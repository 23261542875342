import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useBlockLayout, useRowSelect, useTable } from "react-table";
import { getBuildingsAsync } from "src/api/building/building-api";
import { BuildingListParams, BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { Order, PageMeta } from "src/api/public-types";
import { BaseInput, BasePagination } from "src/components";
import { Partner } from "src/types/partner";
import { BuilidingPopupColumn } from "../modalPopup/columns/BuilidingPopupColumn";
import TableRadioButton from "./TableRadioButton";

type QueryParams = {
  page?: number;
  size?: number;
  keyword?: string;
  isDeleted?: string;
  sort?: {
    orders?: Array<Order>;
  };
  partnerId?: string;
};

type Props = {
  setSelectedBuilding?: Function;
  partner: Partner;
  // getBuildingsId?: Function;
};

const ProductBuildingListModal = ({ setSelectedBuilding, partner }: Props) => {
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse("", {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (_queryParams.isDeleted !== "true" && _queryParams.isDeleted !== "false") {
      _queryParams.isDeleted = "false";
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, []);

  const [buildingList, setBuildingList] = useState<BuildingModel[]>([]); // 빌딩 목록
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const [params, setParams] = useState<QueryParams>({ ...queryParams });
  const [pageNum, setPageNum] = useState(0);

  // 건물 목록 조회 api
  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync);

  const columns = useMemo(() => BuilidingPopupColumn, []);
  const divRef = useRef<HTMLDivElement>(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,

    state: { selectedRowIds },
  } = useTable(
    {
      columns: columns as any,
      data: buildingList,
      autoResetSelectedRows: true,
    },
    useBlockLayout,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
            let currentState = row.getToggleRowSelectedProps();

            return (
              <div>
                <TableRadioButton
                  id={row.id}
                  onClick={() => {
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id, true);
                    setSelectedBuilding && setSelectedBuilding(row.original);
                  }}
                  name="buiding"
                  {...currentState}
                />
              </div>
            );
          },
          width: 70,
        },

        ...columns,
      ]);
    },
  );

  // 빌딩 목록 조회
  useEffect(() => {
    async function fetchBuildings(buildingListParams: BuildingListParams) {
      const { data } = await getBuildings(buildingListParams);
      setBuildingList(data?.data?.content || []);
      setPageMeta(data?.meta?.pageMeta);
    }

    queryParams.partnerId = partner.id;

    // 건물 목록 조회후 데이터 바인딩
    fetchBuildings(queryParams);
  }, [queryParams, getBuildings]);

  //===================================================
  // 쿼리파라미터 정보와 함께 네비게이션

  // 팝업내에서 건물 리스트 검색
  const onSearchList = useCallback(
    async (page?: number) => {
      queryParams.page = page;
      queryParams.keyword = params.keyword;
      queryParams.partnerId = partner.id;

      const response = await getBuildings(queryParams);

      if (response.status >= 200 && response.status <= 299) {
        setBuildingList(response.data.data.content);
        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getBuildings, params.keyword, queryParams],
  );

  // 팝업 빌딩 목록 페이지네이션
  const buildingPagenation = useCallback(
    (passParams?: QueryParams) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const page = newQueryParams.page;
      setPageNum(Number(page));
      onSearchList(page);
    },
    [onSearchList, params],
  );

  return (
    <>
      <div className="modal-title">
        <div className="left-area">
          <span>건물</span>
        </div>
        <div className="right-area">
          <div className="minmax260">
            <BaseInput
              type="text"
              placeholder="검색어를 입력해주세요"
              onSearchClick={() => buildingPagenation({ page: 0 })}
              onChange={(keyword: string) => {
                setParams({ ...params, keyword });
              }}
              onKeyUp={() => buildingPagenation({ page: 0 })}
              value={params.keyword}
            />
          </div>
        </div>
      </div>

      {/* 테이블 부분 */}
      <div className="page-product-list">
        <div className="contents-container__table">
          <div className="contents-container__search-wrap"></div>

          {/* 테이블 부분 */}
          <div className="contents-container__table">
            <div {...getTableProps()} className="base-table sticky" ref={divRef}>
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.map((row, idx: number) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} className="base-table__tr">
                      {row.cells.map((cell) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <BasePagination
          pageIndex={pageNum || 0}
          totalPages={pageMeta?.totalPages || 0}
          goPage={(page: number) => buildingPagenation({ page })}
        />
      </div>
    </>
  );
};

export default ProductBuildingListModal;
