import qs from "qs";
import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentsTitle } from "src/components";
import { PagePath } from "src/pages/product/details";
import AccountInfoDetail from "./accountInfoDetail/AccountInfoDetail";
import BasicInfoDetail from "./basicInfoDetail/BasicInfoDetail";
import ContractInfoDetail from "./contactInfoDetail/ContractInfoDetail";
import ProviderAdminMemo from "./providerAdminMemo/ProviderAdminMemo";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "contactInfo", label: "연락처 정보" },
  { value: "accountInfo", label: "연동 정보" },
  // { value: "memo", label: "관리자 메모" },
];

const ProviderDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "provider") {
        // 공간상품 목록으로 이동
        navigate(PagePath.provider.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "provider", label: "프로바이더" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  return (
    <div className="page-product-access">
      <ContentsTitle
        title="정산정보"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />
      {/* 탭 */}
      <div className="contents-container__scroll">
        {/* 기본정보 */}
        {activeTab?.value === "basicInfo" && <BasicInfoDetail />}
        {activeTab?.value === "contactInfo" && <ContractInfoDetail />}
        {activeTab?.value === "accountInfo" && <AccountInfoDetail />}

        {/* 관리자메모 */}
        {/* {activeTab?.value === "memo" && <ProviderAdminMemo />} */}
      </div>
    </div>
  );
};

export default ProviderDetail;
