import { AxiosResponse } from "axios";
import React, { ReactChild, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import {
  BuildingFloorModel,
  BuildingModel,
  BuildingRoomModel,
} from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import {
  deleteProductsAsync,
  getProductDetailAsync,
  productStatusAsync,
} from "src/api/product/product-api";
import { ProductModel, ProductUpdateResponseData } from "src/api/product/product-types";
import { ApiResponse, ApiResponseMeta, MetaData } from "src/api/public-types";
import { BaseButton, BaseModal, BaseTooltip } from "src/components";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext, useToastContext } from "src/pages/hooks";
import useTitleOperation from "src/pages/hooks/title-operation";
import BuildingAndHoTable from "src/pages/product/modalPopup/table/BuildingAndHoTable";
import { BuildingFloorAndHo, Modal, PRODUCT_CATEGORY_MAP } from "src/pages/product/product-types";
import { Partner } from "src/types/partner";
import {
  calculatePyeong,
  calculateRateOfUse,
  numberToStringWithComma,
  PhoneFomatter,
} from "src/utils";

// 향
const productDirections = [
  { value: "PRODUCT_DIRECTION_E", label: "동" },
  { value: "PRODUCT_DIRECTION_W", label: "서" },
  { value: "PRODUCT_DIRECTION_S", label: "남" },
  { value: "PRODUCT_DIRECTION_N", label: "북" },
  { value: "PRODUCT_DIRECTION_SE", label: "남동" },
  { value: "PRODUCT_DIRECTION_SW", label: "남서" },
  { value: "PRODUCT_DIRECTION_NE", label: "북동" },
  { value: "PRODUCT_DIRECTION_NW", label: "북서" },
];

// // 관리자메모 QueryParams
// type QueryParams = {
//   serviceId?: number;
//   adminMemoType?: AdminMemoType;
// };

/* 
  공간상품 > 상세 > 기본 정보
 */
const BasicInfoDetail = ({ product }: { product: ProductModel }) => {
  const navigate = useNavigate();
  const { setLoadingBar } = useLoadingBarContext();
  const [partner, setPartner] = useState<Partner>();

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "대표",
        accessor: "isPrimary",
        Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
          return (
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                id={row.id}
                onClick={() => {
                  //모든 라디오 초기화
                  toggleAllRowsSelected(false);
                  toggleRowSelected(row.id, true);
                }}
              />
            </div>
          );
        },
        width: 80,
      },
      {
        Header: "건물 ID",
        accessor: "buildingId",

        width: 70,
      },
      {
        Header: "건물 명",
        accessor: "buildingName",

        width: 180,
      },
      {
        Header: "호실",
        accessor: "roomNum",
        width: 100,
        Cell: ({ value }) => {
          if (value < 0) {
            return <span>{String(value).replace("-", "")}</span>;
          } else {
            return <span>{value}</span>;
          }
        },
      },
      {
        Header: "층",
        accessor: "floorNum",
        width: 100,
        Cell: ({ value }) => {
          if (value < 0) {
            return <span>지하 {String(value).replace("-", "")}</span>;
          } else {
            return <span>지상 {value}</span>;
          }
        },
      },
      {
        Header: "전용면적(m2)",
        accessor: "leasableAreaNet",
        width: 120,
        Cell: ({ value }: any) => {
          return (
            <>
              <BaseTooltip contents={`${numberToStringWithComma(value)}`} isSingleLine={true} />
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((item: any) => (sum += Number(item.original.leasableAreaNet)));
          return <BaseTooltip contents={`${numberToStringWithComma(sum)}`} isSingleLine={true} />;
        },
      },
      {
        Header: "전용면적(평)",
        accessor: "",
        width: 120,
        Cell: ({ row }: any) => {
          const value = row.original.leasableAreaNet;
          return (
            <>
              <BaseTooltip contents={`${calculatePyeong(value)}`} isSingleLine={true} />
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((item: any) => (sum += Number(item.original.leasableAreaNet)));
          return (
            <BaseTooltip
              contents={`${calculatePyeong(sum)}`}
              isSingleLine={true}
              className="font-weight-bold"
            />
          );
        },
      },
      {
        Header: "임대면적(m2)",
        accessor: "leasableArea",
        width: 120,
        Cell: ({ value }: any) => {
          return (
            // TODO :: 세번째 숫자 , 찍기
            <>
              <BaseTooltip contents={`${numberToStringWithComma(value)}`} isSingleLine={true} />
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;

          rows.map((item: any) => (sum += Number(item.original.leasableArea)));
          return (
            // 임대면적 SUM leasableArea

            <BaseTooltip
              contents={`${numberToStringWithComma(sum)}`}
              isSingleLine={true}
              className="font-weight-bold"
            />
          );
        },
      },
      {
        Header: "임대면적(평)",
        accessor: "",
        width: 120,
        Cell: ({ row }: any) => {
          const value = row.original.leasableArea;
          return (
            // TODO :: 세번째 숫자 , 찍기
            <>
              <BaseTooltip contents={`${calculatePyeong(value)}`} isSingleLine={true} />
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;

          rows.map((item: any) => (sum += Number(item.original.leasableArea)));
          return (
            // 임대면적 SUM leasableArea

            <BaseTooltip
              contents={`${calculatePyeong(sum)}`}
              isSingleLine={true}
              className="font-weight-bold"
            />
          );
        },
      },
      {
        Header: "전용률(%)",
        accessor: "rateOfUse",
        width: 100,
        Cell: ({ row }: any) => {
          // let rateOfUse = 0;

          const rateOfUse = calculateRateOfUse(
            row?.original?.leasableAreaNet,
            row?.original?.leasableArea,
          );

          return (
            // TODO :: 세번째 숫자 , 찍기
            <>
              <BaseTooltip contents={`${rateOfUse}`} isSingleLine={true} />
            </>
          );
        },
        Footer: ({ rows }: any) => {
          //let sum = 0
          let sumLeasableAreaNet = 0;
          let sumLeasableArea = 0;

          rows.map((row: any) => {
            return (sumLeasableAreaNet += Number(row.original.leasableAreaNet));
          });

          rows.map((row: any) => {
            return (sumLeasableArea += Number(row.original.leasableArea));
          });

          const rateOfUse = calculateRateOfUse(sumLeasableAreaNet, sumLeasableArea);

          return (
            // TODO :: 세번째 숫자 , 찍기
            <BaseTooltip
              contents={`${rateOfUse}`}
              isSingleLine={true}
              className="font-weight-bold"
            />
          );
        },
      },
    ],
    [],
  );

  const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = useRef<HTMLInputElement>();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <div className="d-flex president">
          <input
            type="radio"
            id={rest.id}
            ref={resolvedRef}
            name="buildingAndHo"
            // checked={}
            {...rest}
            disabled
          />
          <label htmlFor={rest.id} className="d-flex">
            <div className="base-radio"></div>
          </label>
        </div>
      </>
    );
  });

  const params = useParams();
  const [viewTable, setViewTable] = useState<Array<BuildingFloorAndHo>>([]);
  const { openToast } = useToastContext();
  const [buildingStatus, setBuildingStatus] = useState("");
  const [selectedRow, setSelectedRow] = useState({});

  const productId = Number(params.id);

  // 공간상품 상세 조회
  const [productDetail, setProductDetail] = useState<ProductModel>();

  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  const [deleteModal, setDeleteModal] = useState<Modal>({ isOpen: false });

  // // 관리자 메모 목록
  // const [memoList, setMemoList] = useState<Array<AdminMemoAddModel>>([]);

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // // 관리자 메모 목록 api
  // const { executeAsync: getAdminMemoList } = useApiOperation(getAdminMemoListAsync);

  // 공간상품 상세 조회 api
  const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync);

  // 공간상품 상세 삭제 api
  const { executeAsync: deleteProductDetail } = useApiOperation(deleteProductsAsync);

  // 공간상품 상태 변경 api
  const { executeAsync: changeProductStatus } = useApiOperation(productStatusAsync, {
    doNotErrorHandleModal: true,
  });

  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  // 대표건물
  const wasCheckedPrimary = useCallback((table, id) => {
    let pri: any = {};
    // if (primaryIdx && (params.id || productId)) {
    if (id) {
      const primaryIdx = table.findIndex((item: BuildingFloorAndHo) => item.isPrimary === true);
      const wasPrimary = Object.assign(pri, { [primaryIdx]: true });
      setSelectedRow(wasPrimary);
    } else {
      const wasPrimary = Object.assign(pri, { 0: true });
      setSelectedRow(wasPrimary);
    }
  }, []);

  // 공산상품 기본정보 디테일 api
  const getProductBasicDetail = useCallback(async () => {
    if (!product) return;

    setLoadingBar(true);
    if (productId) {
      const result = product;
      setProductDetail(result);

      setLoadingBar(false);

      // viewTable 용 setState
      const buildingList: Array<BuildingModel> = result.buildingList || [];

      let buildingTable: Array<BuildingFloorAndHo> = [];

      buildingList.forEach((item: any) => {
        const building = item;
        setBuildingStatus(building.status); // building의 상태값 저장 (공간상품 상태변경 확인을 위해)

        building?.buildingFloorList.forEach((floor: BuildingFloorModel) => {
          const roomList = floor?.buildingRoomList;

          roomList?.forEach((room: BuildingRoomModel) => {
            let data = {
              buildingId: building.id,
              buildingName: building.buildingName,
              floorId: floor.id,
              floorNum: floor.floorNum,
              roomId: room.id,
              roomNum: room.roomNum,
              isPrimary: room.isPrimary,
              leasableArea: room.leasableArea,
              leasableAreaNet: room.leasableAreaNet,
              rateOfUse: calculateRateOfUse(
                Number(room.leasableAreaNet),
                Number(room.leasableArea),
              ),
            };
            buildingTable.push(data as any);
            setViewTable(buildingTable);
            setLoadingBar(false);
          });

          wasCheckedPrimary(buildingTable, result.id);
        });
      });
    }
  }, [product, productId, setLoadingBar, wasCheckedPrimary]);

  useTitleOperation(productDetail?.productName);

  useEffect(() => {
    getProductBasicDetail();
  }, [getProductBasicDetail]);

  useEffect(() => {
    (async () => {
      if (!productDetail?.partnerId) return;
      if (productDetail.partnerId === "0") return;

      const result = await getPartnerAsync({
        id: productDetail.partnerId,
      });

      if (result.status >= 200 && result.status <= 299) {
        setPartner(result.data.data.partner);
      }
    })();
  }, [productDetail]);

  //
  // Modal 확인 버튼 클릭
  const clickModalConfirm = async () => {
    // 모달 닫기
    setConfirmModal({ isOpen: false });

    // 공간상품 상태변경
    if (confirmModal.type === "PRODUCT_ENABLED") {
      const data = {
        productId,
        status: confirmModal.payload,
      };
      const response: AxiosResponse<ApiResponse<ProductUpdateResponseData>> =
        await changeProductStatus(data);
      if (response.status >= 200 && response.status <= 299) {
        await getProductBasicDetail();
        // setStatus(true);
        openToast(`공개 상태로 변경 되었습니다.`);
      } else {
        openErrorModal(response?.data?.meta);
      }
    } else if (confirmModal.type === "PRODUCT_DISABLED") {
      const data = {
        productId,
        status: confirmModal.payload,
      };
      const response: AxiosResponse<ApiResponse<ProductUpdateResponseData>> =
        await changeProductStatus(data);
      if (response.status >= 200 && response.status <= 299) {
        await getProductBasicDetail();
        // setStatus(false);
        openToast(`비공개 상태로 변경 되었습니다.`);
      } else {
        openErrorModal(response?.data?.meta);
      }
    } else if (confirmModal.type === "PRODUCT_LINK_ENABLED") {
      const data = {
        productId,
        status: confirmModal.payload,
      };
      const response: AxiosResponse<ApiResponse<ProductUpdateResponseData>> =
        await changeProductStatus(data);
      if (response.status >= 200 && response.status <= 299) {
        await getProductBasicDetail();
        // setStatus(false);
        openToast(`링크 공개 상태로 변경 되었습니다.`);
      } else {
        openErrorModal(response?.data?.meta);
      }
    }
  };

  // 에러메세지 모달 띄우기
  const openErrorModal = ({ errorCode, errorMessage, errorData }: ApiResponseMeta) => {
    if (errorCode === "ePR0511") {
      // 필수 파라미터 누락 에러메세지 바인딩
      const errorMessageElement: ReactChild = (
        <div className="text-left">
          <h4 className="mb15">상태를 변경할 수 없습니다.</h4>
          <p className="mb15">필수 정보를 모두 입력하지 않았습니다. [{errorCode}]</p>
          {errorData && (
            <div className="mb15" style={{ wordBreak: "break-all" }}>
              {JSON.stringify(errorData, null, 2)}
            </div>
          )}
        </div>
      );
      setAlertModal({ isOpen: true, children: errorMessageElement });
    } else {
      setAlertModal({ isOpen: true, message: errorMessage || "에러가 발생했습니다." });
    }
  };

  const onDeleteProductDetail = useCallback(async () => {
    if (deleteModal.type === "PRODUCT_DELETE") {
      // 공간상품 삭제
      const response = await deleteProductDetail({ productId });
      if (response.status >= 200 && response.status <= 299) {
        openToast(`정상적으로 삭제 되었습니다.`);
        navigate(PagePath.product.list);
      }
    }
  }, [deleteModal.type, deleteProductDetail, navigate, openToast, productId]);

  const onChangeStatus = useCallback(() => {
    const status = productDetail?.status;
    setConfirmModal({
      isOpen: true,
      message: `상태를 ${status === "ENABLED" ? "비공개" : "전체 공개"}로 변경하시겠습니까? `,
      type: `${status === "ENABLED" ? "PRODUCT_DISABLED" : "PRODUCT_ENABLED"}`,
      payload: `${status === "ENABLED" ? "DISABLED" : "ENABLED"}`,
    });
  }, [productDetail?.status]);

  const statusPopupMessage = useCallback(
    (status) => {
      if (buildingStatus === "DISABLED") {
        return (
          <p>
            필수 정보를 모두 입력하지 않았거나 건물이 비공개일 경우 공개 상태로 변경이 불가능합니다
          </p>
        );
      }
      if (status === "LINK_ENABLED") {
        return (
          <div className="text-left">
            <h4 className="mb15">상태를 '링크 공개'로 변경하시겠습니까?</h4>
            <p className="mb15">COURT에서 아래와 같이 변경됩니다</p>
            <p className="mb5">- 리스트 : 비공개</p>
            <p className="mb5">- 상세 페이지 : 공개</p>
            <p className="mb5">- 검색 : 비공개</p>
          </div>
        );
      } else if (status === "ENABLED") {
        return (
          <div className="text-left">
            <h4 className="mb15">상태를 '전체 공개'로 변경하시겠습니까?</h4>
            <p className="mb15">COURT에서 아래와 같이 변경됩니다</p>
            <p className="mb5">- 리스트 : 공개</p>
            <p className="mb5">- 상세 페이지 : 공개</p>
            <p className="mb5">- 검색 : 공개</p>
          </div>
        );
      } else {
        return (
          <div className="text-left">
            <h4 className="mb15">상태를 '비공개'로 변경하시겠습니까?</h4>
            <p className="mb15">COURT에서 아래와 같이 변경됩니다</p>
            <p className="mb5">- 리스트 : 비공개</p>
            <p className="mb5">- 상세 페이지 : 비공개</p>
            <p className="mb5">- 검색 : 비공개</p>
          </div>
        );
      }
    },
    [buildingStatus],
  );
  useTitleOperation(productDetail?.productName);

  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection title="기본정보" id={productId} />
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>구분</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">
                {PRODUCT_CATEGORY_MAP[productDetail?.productCategory!]}
              </span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>건물/호실</p>
          </div>
        </section>
        <div className="contents-container__grid-contents">
          <div className="">
            <BuildingAndHoTable
              viewTable={viewTable}
              readOnly={true}
              selectedRow={selectedRow}
              onSelectedRowsChange={setSelectedRow}
              detailColumn={columns}
            />
          </div>
        </div>
        <div className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">공간상품명</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">{productDetail?.productName}</span>
            </div>
          </div>
        </div>

        <div>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">Location Code</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <span className="font14">{productDetail?.locationCode}</span>
              </div>
            </div>
          </div>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">테마 색상</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row d-flex justify-contents-start flex-start product-theme">
                <div className={`product-theme__selected ${productDetail?.colorName}`}></div>
              </div>
            </div>
          </div>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">1줄 소개</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <span className="font14">{productDetail?.introduce}</span>
              </div>
            </div>
          </div>

          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">향</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <span className="font14">
                  {(productDetail?.productDirectionList || [])
                    .map(
                      (direction: MetaData) =>
                        productDirections.find(
                          (v: { value: string; label: string }) => v.value === direction.metaItem,
                        )?.label,
                    )
                    .join(", ")}
                </span>
              </div>
            </div>
          </div>

          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">설명</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <span className="font14 pre-formatted">{productDetail?.description}</span>
              </div>
            </div>
          </div>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">문의 연락처</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <span className="font14">{PhoneFomatter(productDetail?.questionPhone)}</span>
              </div>
            </div>
          </div>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">문의 시간</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <span className="font14">
                  {productDetail?.questionStartTime} ~ {productDetail?.questionEndTime}
                </span>
              </div>
            </div>
          </div>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">VOC 링크</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <span className="font14">{productDetail?.vocUrl}</span>
              </div>
            </div>
          </div>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">생성일</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <BaseTooltip contents={productDetail?.createdDate} type="date" />
              </div>
            </div>
          </div>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">최종 수정일</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <BaseTooltip contents={productDetail?.modifiedDate} type="date" />
              </div>
            </div>
          </div>

          {/* 공개여부 */}
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>상태</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <div className="minmax60 mr10">
                  <span>
                    {(productDetail?.status === "ENABLED" && "전체 공개") ||
                      (productDetail?.status === "DISABLED" && "비공개") ||
                      (productDetail?.status === "LINK_ENABLED" && "링크 공개")}
                  </span>
                </div>
                <BaseButton
                  title={productDetail?.status === "ENABLED" ? "비공개" : "전체 공개"}
                  className="color-white mr10"
                  onClick={onChangeStatus}
                />

                <BaseButton
                  title={productDetail?.status === "LINK_ENABLED" ? "비공개" : "링크 공개"}
                  className="color-white"
                  onClick={(e: any) => {
                    const status = productDetail?.status;

                    setConfirmModal({
                      isOpen: true,

                      type: `${
                        status === "LINK_ENABLED" ? "PRODUCT_DISABLED" : "PRODUCT_LINK_ENABLED"
                      }`,
                      payload: `${status === "LINK_ENABLED" ? "DISABLED" : "LINK_ENABLED"}`,
                    });
                  }}
                />
              </div>
            </div>
          </section>

          {/* 이용여부 */}
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>이용여부</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <div className="minmax60 mr10">
                  <span>{productDetail?.isUsed ? "이용중" : "이용 중 X"}</span>
                </div>

                {/* <BaseButton
                title={productDetail?.isUsed ? "판매 등록" : "비공개"}
                className="color-white"
                onClick={(e: any) => {
                  const status = productDetail?.status;

                  setConfirmModal({
                    isOpen: true,

                    type: `${
                      status === "LINK_ENABLED" ? "PRODUCT_DISABLED" : "PRODUCT_LINK_ENABLED"
                    }`,
                    payload: `${status === "LINK_ENABLED" ? "DISABLED" : "LINK_ENABLED"}`,
                  });
                }}
              /> */}
              </div>
            </div>
          </section>

          {/* 파트너 */}
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>파트너</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <div className="minmax200 mr10">
                  {partner !== undefined && <span>{`${partner.name} (${partner.id})`}</span>}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* {productDetail?.productCategory === "PRODUCT_CATEGORY_NORMAL" && (
      )} */}
      {/* <div className="contents-container__grid-contents">
        <div className="">
          <AdminMemoIntegration
            serviceId={productId}
            serviceType={ServiceTypes.SERVICE_PRODUCT}
            viewOnly={true}
          />
        </div>
      </div> */}

      <AdminMemoV3
        serviceId={productId}
        serviceType={ServiceTypes.SERVICE_PRODUCT}
        partnerId={productDetail?.partnerId!}
      ></AdminMemoV3>

      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            className="color-white size-large"
            onClick={() => navigate(PagePath.product.list)}
          />
          {/* <BaseButton
            title="삭제"
            className="no-outline color-white size-large text-red ml10"
            onClick={() =>
              setDeleteModal({
                isOpen: true,
                message: "정말 삭제 처리하시겠습니까?",
                type: "PRODUCT_DELETE",
              })
            }
          /> */}
        </div>
        <div className="right-area">
          {/* <BaseButton
            title="수정"
            className="size-large"
            onClick={() => {
              const formPath = `${PagePath.product.form}?id=${params.id}`;
              navigate(formPath);
            }}
          /> */}
        </div>
      </div>

      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle={buildingStatus === "DISABLED" ? "" : "취소"}
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() =>
          buildingStatus !== "DISABLED" ? clickModalConfirm() : setConfirmModal({ isOpen: false })
        }
      >
        {statusPopupMessage(confirmModal.payload)}
      </BaseModal>

      <BaseModal
        isOpen={deleteModal.isOpen}
        btnLeftTitle={"취소"}
        btnRightTitle="확인"
        onClose={() => setDeleteModal({ isOpen: false })}
        onClick={() => onDeleteProductDetail()}
      >
        {deleteModal.message}
      </BaseModal>

      {/* 확인버튼만 있는 alert 모달 */}
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => setAlertModal({ isOpen: false })}
      >
        {alertModal?.children ? alertModal.children : <p>{alertModal.message}</p>}
      </BaseModal>
    </div>
  );
};

export default BasicInfoDetail;
