import _ from "lodash";
import { forwardRef, useEffect, useState } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { checkPartnerESignClient } from "src/api/partner";
import { contract_bill_user_errors } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { ESignOnClient } from "src/types/partner";
import { validateEmailText } from "src/utils/common-util";

interface IClientForm {
  control: Control<ESignOnClient>;
}

type Ref = HTMLFormElement;

interface IProps {
  onSubmit: (data: ESignOnClient) => void;
  setDefaultValue: any;
  setSubmitDisable: any;
  defaultValue?: ESignOnClient;
}
function PartnerESignForm(
  { onSubmit, defaultValue, setDefaultValue, setSubmitDisable }: IProps,
  ref: React.Ref<Ref>,
) {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const { control, handleSubmit, setValue, watch, getValues } = useForm<ESignOnClient>();
  const [client, setClient] = useState<ESignOnClient>();
  const [isValid, setIsValid] = useState(false);
  const { executeAsync: checkClient } = useApiOperation(checkPartnerESignClient);
  const { id } = useParams();
  const PARTNER_ADD_FORM_MAP = {
    code: {
      label: "이메일",
      render: (data: IClientForm) => (
        <Controller
          control={data.control}
          render={({ field, fieldState }) => (
            <BaseInput
              value={field.value}
              onChange={(value: string) => {
                field.onChange(value);
                const temp: ESignOnClient = _.cloneDeep(client!);
                temp.membEmail = value;
                setDefaultValue(temp);
              }}
              errorText={fieldState.error?.message}
              placeholder={"이싸인온에서 생성한 ID를 입력해주세요"}
            />
          )}
          name={"membEmail"}
          rules={{
            required: {
              value: true,
              message: "필수입력항목입니다.",
            },
          }}
        />
      ),
      required: true,
    },
    name: {
      label: "패스워드",
      render: (data: IClientForm) => (
        <Controller
          control={data.control}
          render={({ field, fieldState }) => (
            <BaseInput
              value={field.value}
              type={"password"}
              onChange={(value: string) => {
                field.onChange(value);
                const temp: ESignOnClient = _.cloneDeep(client!);
                temp.membPwd = value;
                setDefaultValue(temp);
              }}
              errorText={fieldState.error?.message}
              placeholder={"이싸인온에서 생성한 PW를 입력해주세요"}
            />
          )}
          name={"membPwd"}
          rules={{
            required: {
              value: true,
              message: "필수입력항목입니다.",
            },
          }}
        />
      ),
      required: true,
    },
  };

  const [modal, setModal] = useState({
    success: {
      visible: false,
      title: "",
      message: "",
    },
    fail: {
      visible: false,
      title: "",
      message: "",
    },
  });

  //검증 API
  const onCheckEsign = async (data: ESignOnClient) => {
    if (getValues("membEmail") === "" || getValues("membPwd") === "") return;
    const returnData = validateEmailText(getValues("membEmail"));

    if (typeof returnData === "string") {
      setIsValid(false);
      setModal({
        ...modal,
        fail: {
          visible: true,
          title: "",
          message: "이메일 형식에 맞게 입력해 주세요.",
        },
      });
      return;
    }

    const sendParams = {
      partnerId: String(id),
      eSignonClient: {
        partnerId: String(id),
        membEmail: getValues("membEmail"),
        membPwd: getValues("membPwd"),
      },
    };
    setLoadingBar(true);
    const result: any = await checkClient(sendParams);

    if (result.status > 199 && result.status < 300) {
      if (result.data.data.isResult) {
        setIsValid(true);
        setSubmitDisable(false);
        setModal({
          ...modal,
          success: {
            visible: true,
            title: "인증에 성공하였습니다.",
            message: "이싸인온에 등록된 로그인 정보와 일치합니다.",
          },
        });
      } else {
        setIsValid(false);
        setSubmitDisable(true);
        setModal({
          ...modal,
          fail: {
            visible: true,
            title: "인증에 실패하였습니다.",
            message:
              "이싸인온에 등록된 로그인 정보와 일치하지 않습니다. 로그인 정보 연속 5회 오류 시 5분간 이용이 제한됩니다.",
          },
        });
      }
    } else {
      let message = errorMessageHandler(
        result.status,
        result.data.meta.errorCode,
        contract_bill_user_errors,
      );
      let code = result.data.meta.errorCode ? result.data.meta.errorCode : result.status;
      setSubmitDisable(true);
      openErrorModal(message, code);
    }
    setLoadingBar(false);
  };

  useEffect(() => {
    setValue("membEmail", defaultValue?.membEmail || "");
    setValue("membPwd", defaultValue?.membPwd || "");
    setClient(defaultValue);
    setIsValid(Number(defaultValue?.businessId) !== 0 ? true : false);
  }, [defaultValue]);
  // if (clientCheckLoadingPut || clientCheckLoadingPatch) return <LoadingBar visible={true} />;

  return (
    <form className="contents-container__wrap-contents" onSubmit={handleSubmit(onSubmit)} ref={ref}>
      <div className="contents-container__sub-title">
        <h2>전자 서명</h2>
        <p className="contents-container__sub-title-info">
          전자 서명을 연동하기 위해서는{" "}
          <a
            className="text-hilight"
            href={"https://docs.esignon.net/login"}
            target={"_blank"}
            rel="noreferrer"
          >
            이싸인온
          </a>
          에서 부서를 생성 후 해당 부서의 로그인 이메일과 패스워드를 입력해 주세요.
        </p>
      </div>
      <div className="mb10">
        <div className="index-tab">
          <span>이싸인온 로그인 정보</span>
        </div>
        <div className="border-gray py16 pr16 px20">
          {Object.keys(PARTNER_ADD_FORM_MAP).map((key) => {
            const section = PARTNER_ADD_FORM_MAP[key as keyof typeof PARTNER_ADD_FORM_MAP];

            return (
              <section className="flex-center" key={key}>
                <div className="minmax123">
                  <span className="required font13 text-primary3">{section.label}</span>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    {section.render({
                      control,
                    })}
                  </div>
                </div>
              </section>
            );
          })}
          <section className="flex-center mt10">
            <div className="minmax123"></div>
            <div className="contents-container__grid-contents">
              <BaseButton
                title="인증"
                disabled={watch("membEmail") === "" || watch("membPwd") === ""}
                onClick={onCheckEsign}
              />
            </div>
          </section>
          <section className="flex-center mt10">
            <div className="minmax123"></div>

            <div className="contents-container__grid-contents">
              <span>
                이싸인온 로그인 계정 인증 :{" "}
                <span className={isValid ? "text-purple" : "text-red600"}>
                  {isValid ? "성공" : "실패"}
                </span>
              </span>
            </div>
          </section>
        </div>
      </div>
      <BaseModal
        isOpen={modal.success.visible}
        onClick={() => setModal({ ...modal, success: { visible: false, title: "", message: "" } })}
        btnRightTitle="확인"
      >
        <>
          <p>{modal.success.title}</p>
          <p>{modal.success.message}</p>
        </>
      </BaseModal>
      <BaseModal
        isOpen={modal.fail.visible}
        onClick={() => setModal({ ...modal, fail: { visible: false, title: "", message: "" } })}
        btnRightTitle="확인"
      >
        <div>
          <p>{modal.fail.title}</p>
          <p>{modal.fail.message}</p>
        </div>
      </BaseModal>
    </form>
  );
}
export default forwardRef<Ref, IProps>(PartnerESignForm);
