import qs from "qs";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Cell } from "react-table";
import { useApiOperation } from "src/api/hooks";
import { getPartnerList } from "src/api/partner";
import { getPartnerMemberList } from "src/api/partner-member/api";
import { ContentsTitle, BaseTable, LoadingBar, BaseTooltip } from "src/components";
import { PagePath } from "src/pages/product/details";
import { Partner } from "src/types/partner";
import { IPartnerMember, IPartnerMemberOrder } from "src/types/partner-members";
import { IMemberListSearchForm } from "../types";
import { getPartnerMemberDetailPagePath, getPartnerMemberListPagePath } from "../utils";
import PartnerMemeberListSearchForm from "./SearchForm";
import { formatPhoneNumber } from "src/utils";

const COLUMNS = [
  {
    Header: "id",
    accessor: "id",
    width: 300,
    Cell: (props: Cell<IPartnerMember>) => (
      <div className="w-100 text-left ellipsis2">
        <Link to={getPartnerMemberDetailPagePath(props.value)} className="text-hilight">
          {props.value}
        </Link>
      </div>
    ),
  },
  {
    Header: "이메일",
    accessor: "email",
    width: 200,
    Cell: (props: Cell<IPartnerMember>) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "휴대폰 번호",
    accessor: "phoneNumber",
    width: 140,
    Cell: (props: Cell<IPartnerMember>) => (
      <p>{props.value ? formatPhoneNumber(props.value) : "-"}</p>
    ),
  },
  {
    Header: "소속 파트너",
    accessor: "partners",
    width: 220,
    Cell: (props: Cell<IPartnerMember>) => {
      if (props.value.length >= 2)
        return (
          <div className="w-100 text-left ellipsis2">
            {props.value[0].name + " 외 " + (props.value.length - 1) + "개"}
          </div>
        );
      return (
        <div className="w-100 text-left ellipsis2">
          {props.value.map((partner: Partner) => partner.name)}
        </div>
      );
    },
  },
  {
    Header: "생성일",
    width: 100,
    accessor: "createdDate",
    Cell: (props: Cell<IPartnerMember>) => <BaseTooltip contents={props.value} type="date" />,
  },
  {
    Header: "최근 로그인",
    width: 100,
    accessor: "lastLoginDate",
    Cell: (props: Cell<IPartnerMember>) => <BaseTooltip contents={props.value} type="date" />,
  },
  {
    Header: "상태",
    width: 100,
    accessor: "blocked",
    Cell: (props: Cell<IPartnerMember>) => (
      <div className={`color-chip ${props.value ? "red" : "green"}`}>
        {props.value ? "비활성" : "활성"}
      </div>
    ),
  },
];

interface IUrlParams extends IMemberListSearchForm {
  page: number;
  size: number;
  orders: IPartnerMemberOrder[];
}

const DISABLED_SORT_HEADERS = ["partners", "blocked"];

const PAGE_SIZE = 20;
function PartnerMemberList() {
  const [partnerMembers, setPartnerMembers] = useState<IPartnerMember[]>([]);
  const [partnerList, setPartnerList] = useState<Partner[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [orders, setOrders] = useState<IPartnerMemberOrder[]>();
  const [searchFormData, setSearchFormData] = useState<IMemberListSearchForm>();

  const navigate = useNavigate();

  const navigateToPartnerMemberList = (params: Partial<IUrlParams>) =>
    navigate(`${PagePath.partnerMember.list}?${qs.stringify(params)}`);

  const { executeAsync: getPartnerMemberListAsync, loading: partnerMemberListLoading } =
    useApiOperation(getPartnerMemberList);

  const { executeAsync: getPartnerListAsync, loading: partnerListLoading } =
    useApiOperation(getPartnerList);

  const onMovePage = (page: number) =>
    navigateToPartnerMemberList({ ...searchFormData, page, size: PAGE_SIZE, orders });

  const onSetOrders = (orders: IPartnerMemberOrder[]) =>
    navigateToPartnerMemberList({ ...searchFormData, page: pageIndex, size: PAGE_SIZE, orders });

  const onSearchFormSubmit = (values: IMemberListSearchForm) =>
    navigateToPartnerMemberList({ ...values, page: 0, size: PAGE_SIZE, orders });

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const params = qs.parse(searchParams.toString(), { allowDots: true });

    const page = params["page"];
    const blocked = params["blocked"];
    const partnerId = params["partnerId"];
    const containsEmail = params["containsEmail"];
    const orders = params["orders"]
      ? (params.orders as unknown as IPartnerMemberOrder[])
      : [
          {
            direction: "DESC",
            property: "createdDate",
          },
        ];

    setSearchFormData({
      blocked: blocked ? String(blocked) : "",
      partnerId: partnerId ? String(partnerId) : "",
      containsEmail: containsEmail ? String(containsEmail) : "",
    });

    setPageIndex(page ? Number(page) : 0);
    setOrders(orders as unknown as IPartnerMemberOrder[]);

    const fetchPartnerMemberList = async () => {
      const { data } = await getPartnerMemberListAsync({
        blocked: blocked === "true" ? true : undefined,
        partnerId: partnerId ? Number(partnerId) : undefined,
        containsEmail: containsEmail ? String(containsEmail) : undefined,
        page: page ? Number(page) : 0,
        size: 20,
        orders: orders as unknown as IPartnerMemberOrder[],
      });

      setPartnerMembers(data.data.content);

      setTotalPageCount(Number(data.meta.pageMeta?.totalPages));
      setTotalElements(Number(data.meta.pageMeta?.totalElements));
    };

    const fetchPartnerList = async () => {
      const result = await getPartnerListAsync();

      setPartnerList(result.data.data.content);
    };

    fetchPartnerMemberList();
    fetchPartnerList();
  }, []);

  if (partnerMemberListLoading || partnerListLoading) return <LoadingBar visible={true} />;

  return (
    <div>
      {/* <ContentsTitle
        title="파트너 회원"
        breadCrumbs={[
          {
            value: getPartnerMemberListPagePath(),
            label: "파트너 회원",
          },
        ]}
        clickBreadCrumb={(value: { value: string; label: string }) => navigate(value.value)}
      /> */}
      <div className="contents-container__search-wrap">
        <div className="left-area">
          {/* <p>
              전체 <span>{totalElements}</span>
            </p> */}
          <PartnerMemeberListSearchForm
            onSubmit={onSearchFormSubmit}
            data={searchFormData}
            partners={partnerList}
          />
        </div>
        <div className="right-area"></div>
      </div>

      <BaseTable
        data={partnerMembers}
        columns={COLUMNS}
        pageIndex={pageIndex}
        totalPages={totalPageCount}
        goPage={onMovePage}
        orders={orders}
        setOrders={onSetOrders}
        disabledSortHeaders={DISABLED_SORT_HEADERS}
      />
    </div>
  );
}
export default PartnerMemberList;
