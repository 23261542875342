import { useCallback, useEffect, useState } from "react";
import { Control, Controller, UseFormReturn } from "react-hook-form";
import { BuildingModel, CommonFacilityModel } from "src/api/building/building-types";
import { getEslProductDetailAsync } from "src/api/esl/esl-api";
import { EslProduct } from "src/api/esl/esl-types";
import { useApiOperation } from "src/api/hooks";
import { ProductModel } from "src/api/product/product-types";
import { MediaFile } from "src/api/public-types";
import {
  BaseCheckbox,
  BaseInput,
  BaseRadio,
  BaseToggle,
  BaseTooltip,
  ResizedImage,
} from "src/components";
import { showHourMinute } from "src/utils";
import { CommonSpaceFormData } from "../CommonSpaceForm";

type Props = {
  control: Control<any>;
  watch: UseFormReturn<CommonSpaceFormData>["watch"];
  errors: any;
  setValue: Function;
  setIsNoData: Function;
  productDetail?: ProductModel;
  getValues: Function;
  totalOption: any;
  meetingRoomList: CommonFacilityModel[];
  onProvideMeetingRooms: Function;
  checkedAllItem: Function;
};

interface EslLabel extends EslProduct {
  locationCode: string; // 공간유형 채번
}

const s3Url = process.env.REACT_APP_S3_BASEURL;

const sampleImg =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/No_Cross.svg/1024px-No_Cross.svg.png";

const MeetingRoomFormComp = ({
  control,
  watch,
  errors,
  setValue,
  setIsNoData,
  productDetail,
  getValues,
  totalOption,
  meetingRoomList,
  onProvideMeetingRooms,
  checkedAllItem,
}: Props) => {
  const [conferenceRooms, setConferenceRooms] = useState<Array<CommonFacilityModel>>([]);

  // ESL 데이터 상세 조회 api
  const { executeAsync: getEslProductDetail } = useApiOperation(getEslProductDetailAsync, {
    doNotErrorHandleModal: true,
  });

  // 조회한 ESL 데이터 목록
  const [eslLabels, setEslLabels] = useState<Array<EslLabel>>([]);

  // esl 라벨 목록 조회
  const fetchAllLabels = useCallback(async (locationCodes: Array<string>) => {
    try {
      if (!locationCodes || locationCodes.length === 0) {
        throw Error("locationCodes.length === 0");
      }

      const eslLabels: Array<EslLabel> = [];
      for (let i = 0; i < locationCodes.length; i++) {
        const locationCode = locationCodes[i];
        const result = await getEslProductDetail({ productId: locationCode });
        if (result?.data?.data && result.status >= 200 && result.status <= 299) {
          if (result?.data?.data?.product) {
            // esl 라벨이 연동되어있을 경우만 조회됨
            eslLabels.push({
              ...result?.data?.data?.product,
              ...{
                locationCode,
              },
            });
          }
        }
      }

      setEslLabels(eslLabels);
    } catch (error) {
      // 에러 발생해도 무시
      console.debug("fetchAllLabels error", error);
    }
  }, []);

  // 수정시 api 에서 받아온 building 정보로 setValue 처리
  useEffect(() => {
    let meetings: CommonFacilityModel[] = [];

    if (meetingRoomList) {
      for (let j = 0; j < meetingRoomList.length; j++) {
        const meetingItem = meetingRoomList[j];

        meetings.push(meetingItem);
      }
    }

    if (meetings) {
      // locaiotn code 로 라벨 목록 조회
      const locationCodes: Array<string> = [];
      for (let i = 0; i < meetings.length; i++) {
        const locationCode = meetings[i]?.locationCode;
        if (!!locationCode) {
          locationCodes.push(locationCode);
        }
      }
      if (locationCodes.length > 0) {
        fetchAllLabels(locationCodes);
      }
    }
  }, [meetingRoomList]);

  return (
    <article>
      <div className="contents-container__sub-title">
        <div className="minmax140 pb4">
          <h2>회의실</h2>
        </div>
        <Controller
          control={control}
          name="isMeetingRoom"
          render={({ field: { onChange, name, value } }) => {
            return (
              <>
                <BaseToggle
                  name={name}
                  // onChange={(value: boolean) => {
                  //   if (value && meetingRoomList.length === 0) {
                  //     setIsNoData(true);
                  //     return;
                  //   }

                  //   onChange(value);
                  // }}
                  checked={value}
                />
              </>
            );
          }}
        ></Controller>
      </div>

      {/* 토글을 닫으면 contents-container__divide-bottom 영역이 노출되지 않음 */}
      {Boolean(watch("isMeetingRoom")) && (
        <div className="contents-container__divide-bottom">
          {/* 회의실 선택 */}
          <section className="contents-container__grid contents-container__1200">
            <div className="contents-container__grid-index">
              <p className="required">회의실 선택</p>
            </div>
            <div className="contents-container__grid-contents">
              {productDetail?.buildingList &&
                productDetail?.buildingList.map((product: BuildingModel) => {
                  const filterdList = meetingRoomList.filter(
                    (room: CommonFacilityModel) => room.buildingId === product?.id,
                  );

                  return (
                    <div className="pt8 mb10" key={product?.id}>
                      <div className="index-tab">
                        <span>{product?.buildingName}</span>
                      </div>
                      <table className="inner-table" width="100%">
                        <thead>
                          <tr>
                            <th>
                              <BaseCheckbox
                                id={`MEETING_ROOM${product?.id}`}
                                name={product?.buildingName}
                                className="justify-contents-center"
                                checked={checkedAllItem(filterdList)}
                                // onChange={(checked: boolean) => {
                                //   let commonSpaceArr = watch("selectedCommonFacility") || [];

                                //   if (checked) {
                                //     for (let i = 0; i < filterdList.length; i++) {
                                //       const meetingItem = filterdList[i];

                                //       if (meetingItem.buildingId === product?.id) {
                                //         commonSpaceArr.push(meetingItem);
                                //       }
                                //     }
                                //   } else {
                                //     for (let i = 0; i < filterdList.length; i++) {
                                //       const meetingItem = filterdList[i];

                                //       commonSpaceArr = commonSpaceArr.filter(
                                //         (item: CommonFacilityModel) => item.id !== meetingItem.id,
                                //       );
                                //     }
                                //   }

                                //   setValue("selectedCommonFacility", [...commonSpaceArr]);
                                // }}
                              />
                            </th>
                            <th>
                              <span>이미지</span>
                            </th>
                            <th>
                              <span>회의실 명</span>
                            </th>
                            <th>
                              <span>위치</span>
                            </th>
                            <th>
                              <span>정원</span>
                            </th>
                            <th>
                              <span>기자재</span>
                            </th>
                            <th>
                              <span>설명</span>
                            </th>
                            <th>
                              <span>ESL</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterdList.map((meetingRoom: CommonFacilityModel) => {
                            let mediaList: Array<MediaFile> = [];

                            const pramaryImage = meetingRoom?.mediaList;
                            pramaryImage?.forEach((item) => {
                              if (item.isPrimary === true) {
                                mediaList.push(item);
                              }
                            });

                            let mediaFile =
                              mediaList.length > 0
                                ? mediaList[0]
                                : meetingRoom?.mediaList && meetingRoom?.mediaList[0];

                            const findLabel = eslLabels.find(
                              (eslLabel: EslLabel) =>
                                eslLabel.locationCode === meetingRoom.locationCode,
                            );

                            return (
                              <tr key={meetingRoom.id}>
                                <td width="70">
                                  <div className="flex-center-center no-wrap">
                                    <BaseCheckbox
                                      id={String(meetingRoom.id)}
                                      name={""}
                                      value={String(meetingRoom.id)}
                                      checked={
                                        watch("selectedCommonFacility")?.find(
                                          (item: CommonFacilityModel) =>
                                            String(item.id) === String(meetingRoom.id),
                                        )
                                          ? true
                                          : false
                                      }
                                      // onChange={(checked: boolean) => {
                                      //   let commonFacilityArr: CommonFacilityModel[] =
                                      //     getValues("selectedCommonFacility") || [];

                                      //   // 만약 체크를 하면

                                      //   if (checked) {
                                      //     commonFacilityArr.push(meetingRoom);

                                      //     setConferenceRooms([...conferenceRooms, meetingRoom]);
                                      //   } else {
                                      //     commonFacilityArr = commonFacilityArr.filter(
                                      //       (item: CommonFacilityModel) =>
                                      //         String(item.id) !== String(meetingRoom.id),
                                      //     );

                                      //     const filtered = conferenceRooms.filter(
                                      //       (item) => item.id !== meetingRoom.id,
                                      //     );
                                      //     setConferenceRooms(filtered);
                                      //   }

                                      //   setValue("selectedCommonFacility", [...commonFacilityArr]);
                                      // }}
                                    />
                                  </div>
                                </td>
                                <td width="60">
                                  <div className="flex-center-center no-wrap">
                                    {mediaFile?.key ? (
                                      <ResizedImage
                                        url={s3Url + mediaFile.key}
                                        className="inner-table__img"
                                        alt="회의실 이미지"
                                      />
                                    ) : (
                                      <img
                                        src={sampleImg}
                                        className="inner-table__img"
                                        alt="회의실 이미지"
                                      />
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="flex-center-center no-wrap">
                                    <span>{meetingRoom.facilityName}</span>
                                  </div>
                                </td>
                                <td>
                                  <div className="flex-center-center no-wrap">
                                    <span>
                                      {meetingRoom.isGround ? "지상" : "지하"}
                                      {meetingRoom.floorNum}층
                                    </span>{" "}
                                  </div>
                                </td>
                                <td width="70">
                                  <div className="flex-center-center no-wrap">
                                    <span>{meetingRoom.facilityMaxPeopleNums}</span>
                                  </div>
                                </td>
                                <td width="70">
                                  <div className="flex-center-center no-wrap">
                                    <div
                                      className={
                                        meetingRoom?.facilityList?.length === 0
                                          ? "not-available"
                                          : ""
                                      }
                                    >
                                      {meetingRoom?.facilityList?.length &&
                                      meetingRoom?.facilityList?.length > 0 ? (
                                        <BaseTooltip
                                          contents={"입력"}
                                          tooltip={meetingRoom!
                                            .facilityList!.map((metaData: string) => metaData)
                                            .join(",")}
                                          type={"normal"}
                                        />
                                      ) : (
                                        <span>미입력</span>
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td width="70">
                                  <div className="flex-center-center no-wrap">
                                    <div
                                      className={
                                        meetingRoom.description === "" ? "not-available" : ""
                                      }
                                    >
                                      {meetingRoom.description !== "" ? (
                                        <BaseTooltip
                                          contents={"입력"}
                                          tooltip={meetingRoom.description}
                                          type={"normal"}
                                        />
                                      ) : (
                                        <span>미입력</span>
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td width="100">
                                  <div className="flex-center-center no-wrap">
                                    <div className={findLabel === undefined ? "not-available" : ""}>
                                      {findLabel !== undefined ? (
                                        <BaseTooltip
                                          contents={"입력"}
                                          tooltip={(findLabel?.assignedLabelCodes || []).join(",")}
                                          type={"normal"}
                                        />
                                      ) : (
                                        <span>미입력</span>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  );
                })}

              {errors?.selectedCommonFacility &&
                watch("selectedCommonFacility")?.every(
                  (item: CommonFacilityModel) => item.commonFacilityType !== "MEETING_ROOM",
                ) && <p className="validation-text">필수선택 항목입니다</p>}
            </div>
          </section>
          {/* Court 소개 내용 */}
          <section className="contents-container__grid contents-container__1200">
            <div className="contents-container__grid-index">
              <p>Court 소개 내용</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start mb10">
                <div className="minmax140">
                  <span>제공 회의실</span>
                </div>
                {onProvideMeetingRooms()}
              </div>
              <div className="flex-row flex-center-start">
                <div className="minmax140">
                  <span>추가 설명</span>
                </div>
                <Controller
                  control={control}
                  name="meetingRoomDescription"
                  render={({ field: { onChange, name, value } }) => {
                    return (
                      <>
                        <BaseInput
                          placeholder="COURT에 표시될 회의실 관련 추가 설명을 적어주세요."
                          onChange={onChange}
                          name={name}
                          value={value}
                        />
                      </>
                    );
                  }}
                ></Controller>
              </div>
            </div>
          </section>
          {/*회의실 예약 설정 */}
          <section className="contents-container__grid contents-container__1200">
            <div className="contents-container__grid-index">
              <p className="required">Taap 예약 설정</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start mb10">
                <div className="minmax140">
                  <span>총 예약 시간/월</span>
                </div>
                <Controller
                  control={control}
                  name="meetingRoomMaxReservTimeMonthFlag"
                  render={({ field: { onChange, name, value } }) => {
                    return (
                      <>
                        <BaseRadio
                          id={"meetingReserve1"}
                          value={"false"}
                          name={name}
                          label="제한 없음"
                          className="mr16"
                          checked={
                            Boolean(getValues("meetingRoomMaxReservTimeMonthFlag")) === false
                          }
                          onChange={() => {
                            setValue("meetingRoomMaxReservTimeMonthFlag", false);
                            onChange(false);
                            setValue("meetingRoomMaxReservTimeMonth", 0);
                          }}
                        />
                        <BaseRadio
                          id={"meetingReserve2"}
                          value={"true"}
                          name={name}
                          label="제한 있음"
                          className="mr16"
                          checked={Boolean(getValues("meetingRoomMaxReservTimeMonthFlag")) === true}
                          onChange={() => {
                            onChange(true);
                            setValue("meetingRoomMaxReservTimeMonthFlag", true);
                            if (totalOption.length > 0) {
                              setValue(
                                "meetingRoomMaxReservTimeMonth",
                                totalOption[totalOption.length - 1].value,
                              );
                            }
                          }}
                        />
                      </>
                    );
                  }}
                ></Controller>

                {Boolean(watch("meetingRoomMaxReservTimeMonthFlag")) === true && (
                  <>
                    <span className="font13 text-primary5 mr10">{`( 매월 총`}</span>
                    <div className="minmax100">
                      <Controller
                        control={control}
                        name="meetingRoomMaxReservTimeMonth"
                        render={({ field: { name, value, onChange } }) => {
                          return (
                            <>
                              <BaseInput
                                type="number"
                                name={name}
                                value={value && value > 0 ? value : ""}
                                readonly={!Boolean(getValues("meetingRoomMaxReservTimeMonthFlag"))}
                                onChange={onChange}
                              />
                            </>
                          );
                        }}
                      ></Controller>
                    </div>

                    <span className="font13 text-primary5 ml10">{`분 [${showHourMinute(
                      String(watch("meetingRoomMaxReservTimeMonth")),
                    )}] 예약 가능)`}</span>
                  </>
                )}
              </div>
              <div className="flex-row flex-center-start" style={{ minHeight: "36px" }}>
                <div className="minmax140">
                  <span>예약 최대 시간/일</span>
                </div>
                <Controller
                  control={control}
                  name="meetingRoomMaxReservTimeDayFlag"
                  render={({ field: { onChange, name, value } }) => {
                    return (
                      <>
                        <BaseRadio
                          id={"meetingReserve3"}
                          value={"false"}
                          name={name}
                          label="제한 없음"
                          className="mr16"
                          checked={Boolean(getValues("meetingRoomMaxReservTimeDayFlag")) === false}
                          onChange={() => {
                            setValue("meetingRoomMaxReservTimeDayFlag", false);
                            onChange(false);
                            setValue("meetingRoomMaxReservTimeDay", 0);
                          }}
                        />
                        <BaseRadio
                          id={"meetingReserve4"}
                          value={"true"}
                          name={name}
                          label="제한 있음"
                          className="mr16"
                          checked={Boolean(getValues("meetingRoomMaxReservTimeDayFlag")) === true}
                          onChange={() => {
                            onChange(true);
                            setValue("meetingRoomMaxReservTimeDayFlag", true);
                            setValue("meetingRoomMaxReservTimeDay", 240);
                          }}
                        />
                      </>
                    );
                  }}
                ></Controller>

                {Boolean(watch("meetingRoomMaxReservTimeDayFlag")) === true && (
                  <>
                    <span className="font13 text-primary5 mr10">{`( 1일 총`}</span>
                    <div className="minmax100">
                      <Controller
                        control={control}
                        name="meetingRoomMaxReservTimeDay"
                        render={({ field: { name, value, onChange } }) => {
                          return (
                            <>
                              <BaseInput
                                type="number"
                                name={name}
                                value={value && value > 0 ? value : ""}
                                readonly={!Boolean(getValues("meetingRoomMaxReservTimeDayFlag"))}
                                onChange={onChange}
                              />
                            </>
                          );
                        }}
                      ></Controller>
                    </div>
                    <span className="font13 text-primary5 ml10">{`분 [${showHourMinute(
                      String(watch("meetingRoomMaxReservTimeDay")),
                    )}] 예약 가능)`}</span>
                  </>
                )}
              </div>
              {errors?.isMeetingRoom && <p className="validation-text">필수선택 항목입니다</p>}
            </div>
          </section>
        </div>
      )}
    </article>
  );
};

export default MeetingRoomFormComp;
