import React, { useCallback } from "react";
import { MgmtOfficeModel, MgmtOfficerType } from "src/api/building/building-types";
import { BaseCheckbox } from "src/components";
import { RoleType } from "src/pages/building/building-types";
import { officerTypeList } from "src/pages/building/forms/mgmtOffice/management-type";
import { formatPhoneNumber } from "src/utils";

type Props = {
  managementDetail: MgmtOfficeModel;
};

const MgmtOfficerSectionDetail = ({ managementDetail }: Props) => {
  const getOfficeWorkType = useCallback((workType: string) => {
    if (workType) {
      for (let i = 0; i < officerTypeList.length; i++) {
        const type = officerTypeList[i];
        if (workType === type.value) {
          return type.label;
        }

        if (workType === "OFFICER_UNRECOGNIZED") {
          return "미선택";
        }
      }
    }
  }, []);

  return (
    <article className="contents-container__divide-top">
      <div className="contents-container__1200">
        <div className="contents-container__sub-title">
          <h2>담당자</h2>
        </div>
        <table className="inner-table" width="100%">
          <thead>
            <tr>
              <th>
                <div>
                  <span>이름(닉네임)</span>
                </div>
              </th>
              <th>
                <span>연락처</span>
              </th>
              <th>
                <span>매니저</span>
              </th>
              <th>
                <span>업무파트</span>
              </th>
              <th>
                <span className="no-wrap">세부 업무내용</span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {managementDetail?.officerList
              ?.filter((officer) => !officer.isDeleted)
              .map((officer: MgmtOfficerType) => (
                <tr key={officer.id}>
                  {/* 이름 */}
                  <td width="120px">
                    <div className="flex-center-center">
                      <span>{officer.name}</span>
                    </div>
                  </td>
                  {/* 연락처 */}
                  <td width="120px">
                    <div className="flex-center-center">
                      <span>{officer.phone ? formatPhoneNumber(officer.phone) : "-"}</span>
                    </div>
                  </td>
                  {/* 매니저 */}
                  <td width="120px">
                    <div className="flex-center-center">
                      {officer.isPrimary && (
                        <BaseCheckbox
                          id={"b"}
                          name={"b"}
                          className="chip-case mr4"
                          label="대표"
                          disabled
                        />
                      )}

                      {officer.roleType === RoleType.MANAGER ? (
                        <BaseCheckbox
                          id={"b2"}
                          name={"b2"}
                          className="chip-case"
                          label="매니저"
                          disabled
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                  <td width="300px">
                    <div className="flex-center-center">
                      {officer.officerTypeList &&
                        officer.officerTypeList.length > 0 &&
                        officer.officerTypeList.map((type: string, typeIdx: number) => {
                          return (
                            <div key={type}>
                              <BaseCheckbox
                                id={``}
                                name={``}
                                className="chip-case mr4"
                                label={getOfficeWorkType(type)}
                                disabled
                              />
                            </div>
                          );
                        })}
                    </div>
                  </td>
                  <td>
                    <div className="flex-center-center">
                      <span>{officer.description}</span>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </article>
  );
};

export default MgmtOfficerSectionDetail;
