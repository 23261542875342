import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { BuildingModel } from "src/api/building/building-types";
import { BaseTooltip } from "src/components";
import PagePath from "src/pagePath.json";

export const BuildingLinkCell = (props: Cell<BuildingModel>) => {
  const detailPath = `${PagePath.building.detail.replace(":id", `${props.row.original.id}`)}`;

  return (
    <div>
      <Link to={detailPath} className="text-hilight">
        <BaseTooltip contents={props?.value || ""} />
      </Link>
    </div>
  );
};
