import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { Order, PageMeta } from "src/api/public-types";
import { WorkOrderListAsync } from "src/api/workOrder/workOrder-api";
import {
  WorkOrderDetailModel,
  WorkOrderListModel,
  WorkOrderQueryParams,
} from "src/api/workOrder/workOrder-types";
import { useLoadingBarContext } from "src/pages/hooks";
import WorkOrderGroupTable from "./WorkOrderGroupTable";

type Props = {
  workOrder: WorkOrderDetailModel;
};

const WorkOrderGroupList = ({ workOrder }: Props) => {
  const location = useLocation();

  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const queryParams: WorkOrderQueryParams = useMemo(() => {
    const _queryParams: WorkOrderQueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
      decoder: (value) => value,
    });

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 10;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "startDate", direction: "ASC" }],
      };
    }
    if (!_queryParams.keywordList) {
      _queryParams.keywordList = workOrder.workOrderSheetGroupId;
    }

    if (!_queryParams?.searchType) {
      _queryParams.searchType = "GROUP_ID";
    }

    return _queryParams;
  }, [location.search, workOrder.workOrderSheetGroupId]);

  // 워크오더 리스트
  const [workOrders, setWorkOrders] = useState<Array<WorkOrderListModel>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [pageNum, setPageNum] = useState(0);

  const [params, setParams] = useState<WorkOrderQueryParams>({ ...queryParams });

  // 워크오더 목록 조회 api
  const { executeAsync: getWorkOrderList } = useApiOperation(WorkOrderListAsync);

  const getWorkOrderListApi = useCallback(
    async (workOrderParams) => {
      setLoadingBar(true);
      const response = await getWorkOrderList(workOrderParams);

      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content;
        setWorkOrders(result || []);

        setPageMeta(response.data.meta.pageMeta);
      }
      setLoadingBar(false);
    },
    [getWorkOrderList, setLoadingBar],
  );

  useEffect(() => {
    getWorkOrderListApi(queryParams);
  }, [getWorkOrderListApi, queryParams]);

  const groupWorkOrderPagination = useCallback(
    async (passParams?: WorkOrderQueryParams) => {
      const newQueryParams = { ...(passParams || {}) };
      const page = newQueryParams.page;
      const searchParams: WorkOrderQueryParams = {
        page,
        size: queryParams.size,
        sort: newQueryParams.sort,
        searchType: queryParams.searchType,
        keywordList: queryParams.keywordList,
      };

      const response = await getWorkOrderList(searchParams);
      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content;
        setWorkOrders(result);
      }

      setPageNum(Number(page));
    },
    [getWorkOrderList, queryParams],
  );

  return (
    <div>
      <WorkOrderGroupTable
        data={workOrders}
        workOrderId={workOrder.id}
        pageIndex={pageNum}
        totalPages={Number(pageMeta?.totalPages) || 0}
        goPage={(page: number) =>
          groupWorkOrderPagination({ page, sort: { orders: params.sort?.orders } })
        }
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            setParams({ ...params, sort: { orders } });
            groupWorkOrderPagination({ sort: { orders }, page: pageNum });
          }
        }}
        orders={params?.sort?.orders}
        disabledSortHeaders={[
          "id",
          "workOrderSheetGroupId",
          "workOrderSheetNo",
          "status",
          // "endDate",
          "mgmtOfficerName",
        ]}
      />
    </div>
  );
};

export default WorkOrderGroupList;
