import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, FieldValues, UseFormReturn, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import {
  getIotRegisteredDeviceLabelsAsync,
  getIotRegisteredDeviceListAsync,
  patchRegisteredDeviceAsync,
  postIotRegisteredDeviceAsync,
} from "src/api/iot/iot-api";
import { PaIotLabel, PaPlatformDevice, PaRegisteredDevice } from "src/api/iot/iot-types";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal, BaseRadio } from "src/components";
import PlatformDeviceSelectModal from "src/pages/iot/components/PlatformDeviceSelectModal";
import { PagePath } from "src/pages/product/details";
import { iotPlatformToString } from "../../iot-types";
export const BasicInfoForm = () => {
  const [registeredDevice, setRegisteredDevice] = useState<PaRegisteredDevice>();
  const [confirmModalOpen, setConfirmModalOpen] = useState<Modal>({ isOpen: false });
  const [labels, setLabels] = useState<PaIotLabel[]>();
  const [platformDeviceSelectModalOpen, setPlatformDeviceSelectModalOpen] = useState<Modal>({
    isOpen: false,
  });
  const [selected, setSelected] = useState<PaPlatformDevice[]>([]);
  const { executeAsync: getIotRegisteredDeviceList } = useApiOperation(
    getIotRegisteredDeviceListAsync,
  );
  const { executeAsync: getIotRegisteredDeviceLabels } = useApiOperation(
    getIotRegisteredDeviceLabelsAsync,
  );
  const { executeAsync: postIotRegisteredDevice } = useApiOperation(postIotRegisteredDeviceAsync);
  const { executeAsync: patchRegisteredDevice } = useApiOperation(patchRegisteredDeviceAsync);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const id: string | undefined = queryParams?.id ? String(queryParams.id) : undefined;

  const defaultValues = useMemo(() => {
    const IoTFormData: any = {
      platformAccount: {
        email: "",
        platform: "ST",
        principalName: "",
      },
      internalDisplayName: "",
    };

    return IoTFormData;
  }, []);

  // const { formState: errors }: any = useForm();
  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    register,
    formState: { dirtyFields, errors },
  }: UseFormReturn<any> = useForm<any>({
    defaultValues,
    mode: "onChange",
  });
  const onSubmit = useCallback(async (data: any, e?: any) => {
    e.preventDefault();
    let payload = { ...data };
    setConfirmModalOpen({ isOpen: true, message: "저장하시겠습니까?", payload });
  }, []);

  const onError = useCallback((errors: any, e?: any) => {
    throw Error("onError", errors);
  }, []);

  const onClickConfirmModal = async (payload: any) => {
    if (id) {
      const originInfo: string =
        String(registeredDevice?.platformDevice?.id) +
        registeredDevice?.platformDevice?.platformAccount.email;
      const changedInfo: string = String(payload.platformDeviceId) + payload.platformAccount.email;
      const params =
        originInfo !== changedInfo
          ? {
              registeredDeviceId: id,
              internalDisplayName: payload.internalDisplayName,
              platformDevice: {
                id: payload.platformDeviceId,
                platformAccount: {
                  platform: payload.platformAccount.platform,
                  principalName: payload.platformAccount.principalName,
                },
              },
            }
          : { registeredDeviceId: id, internalDisplayName: payload.internalDisplayName };

      const { data, status } = await patchRegisteredDevice(params);
      if (status >= 200 && status < 300) {
        navigate(PagePath.iot.detail.replace(":id", id));
      }
    } else {
      const { status } = await postIotRegisteredDevice(payload);
      if (status >= 200 && status <= 299) {
        navigate("/mng/central/iot");
      }
    }
  };
  const handleDeleteST = (id: string) => {
    const filteredDeletedST = selected.filter((device) => device.id !== id);
    setSelected(filteredDeletedST);
    if (filteredDeletedST.length === 0) {
      setValue("platformAccount.email", "");
      setValue("platformAccount.principalName", "");
      setValue("platformDeviceId", "");
    }
  };

  useEffect(() => {
    const requiredMessage = "필수입력 항목입니다";
    register("internalDisplayName", {
      required: requiredMessage,
    });
    if (!id) {
      register("platformAccount.email", {
        required: requiredMessage,
      });
    }
  }, [register]);

  useEffect(() => {
    if (id) {
      const fetchApi = async () => {
        const { data, status } = await getIotRegisteredDeviceList({ id });
        if (status >= 200 && status < 300) {
          if (data.data.content.length > 0) {
            setRegisteredDevice(data.data.content[0]);
            setValue(
              "platformAccount.platform",
              data.data.content[0].platformDevice?.platformAccount.platform,
            );
            setValue(
              "platformAccount.email",
              data.data.content[0].platformDevice?.platformAccount.email,
            );
            setValue("internalDisplayName", data.data.content[0].internalDisplayName);
            setValue(
              "platformDeviceId",
              data.data.content[0].platformDevice ? data.data.content[0].platformDevice.id : "",
            );
            setValue(
              "platformDevice.label",
              data.data.content[0].platformDevice ? data.data.content[0].platformDevice.label : "",
            );
          }
        }
        const { data: labelData, status: labelStatus } = await getIotRegisteredDeviceLabels({
          registeredDeviceId: id,
        });
        if (labelStatus >= 200 && labelStatus < 300) {
          if (labelData.data?.labels?.length > 0) {
            setLabels(labelData.data?.labels);
          }
        }
      };
      fetchApi();
    }
  }, [id, getIotRegisteredDeviceList, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-contents">
            <section className="contents-container__grid pt30">
              <div className="contents-container__grid-index">
                <p className={`${id ? "" : "required"}`}>IoT 구분</p>
              </div>
              <div className="contents-container__grid-contents">
                {id ? (
                  <p>
                    {iotPlatformToString(
                      registeredDevice?.platformDevice?.platformAccount.platform || "-",
                    )}
                  </p>
                ) : (
                  <div className="minmax200">
                    <Controller
                      control={control}
                      name={"platformAccount.platform"}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <div className="flex-center">
                          <BaseRadio
                            id={`ST`}
                            name={name}
                            value={"ST"}
                            checked={value === "ST" ? true : false}
                            onChange={(_, e) => {
                              setValue("platformAccount.platform", e.currentTarget.value);
                            }}
                            label="SmartThings"
                            className="mr10"
                          />
                          <BaseRadio
                            id={`B_IOT`}
                            name={name}
                            value={"B_IOT"}
                            checked={value === "B_IOT" ? true : false}
                            onChange={(_, e) => {
                              setValue("platformAccount.platform", e.currentTarget.value);
                            }}
                            label="b.IoT"
                          />
                        </div>
                      )}
                    ></Controller>
                  </div>
                )}
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">기기명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax240">
                  <Controller
                    control={control}
                    name={"internalDisplayName"}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        placeholder="기기명을 입력해주세요"
                        value={value}
                        onChange={onChange}
                        maxLength={200}
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">계정</p>
              </div>
              <div className="contents-container__grid-contents">
                {id ? (
                  // <p>{registeredDevice?.platformDevice?.platformAccount.email}</p>
                  <Controller
                    control={control}
                    name={"platformAccount.email"}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        placeholder="계정 입력해주세요"
                        value={value}
                        onChange={onChange}
                        maxLength={200}
                        readonly
                        className="minmax240"
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                ) : (
                  <div>
                    <p>
                      {watch("platformAccount.email") === "" ? (
                        <span className="text-primary5">* 기기를 선택하면 표기됩니다</span>
                      ) : (
                        watch("platformAccount.email")
                      )}
                    </p>
                  </div>
                )}
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className={!id ? "required" : ""}>기기</p>
              </div>
              <div className="contents-container__grid-contents">
                {id ? (
                  <div className="pt_grid">
                    <div className="flex-center">
                      <BaseButton
                        title="선택"
                        className="color-white"
                        onClick={() => setPlatformDeviceSelectModalOpen({ isOpen: true })}
                      />
                    </div>
                    <div className="flex-center pt10">
                      <div className="minmax80">
                        <span className="font14 text-primary3">Device ID :</span>
                      </div>
                      <Controller
                        control={control}
                        name={"platformDeviceId"}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <div className="minmax320">
                            <BaseInput
                              placeholder="Device ID를 입력해주세요"
                              value={value}
                              readonly
                              onChange={onChange}
                              maxLength={200}
                              errorText={error?.message}
                            />
                          </div>
                        )}
                      ></Controller>
                    </div>
                    {registeredDevice?.platformDevice?.platformAccount.platform === "ST" && (
                      <div className="flex-center pt10">
                        <div className="minmax80">
                          <span className="font14 text-primary3">ST-Label :</span>
                        </div>
                        <Controller
                          control={control}
                          name={"platformDevice.label"}
                          render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <div className="minmax320">
                              <BaseInput
                                placeholder="ST-Label을 입력해주세요"
                                value={value}
                                readonly
                                onChange={onChange}
                                maxLength={200}
                                errorText={error?.message}
                              />
                            </div>
                          )}
                        ></Controller>
                      </div>
                    )}

                    <div className="flex-center pt10">
                      <div className="minmax80">
                        <span className="font14 text-primary3">Name :</span>
                      </div>
                      <p>{registeredDevice?.platformDevice?.name}</p>
                    </div>
                    <div className="flex-center pt10">
                      <div className="minmax80">
                        <span className="font14 text-primary3">Type :</span>
                      </div>
                      <p>{registeredDevice?.platformDevice?.type}</p>
                    </div>
                  </div>
                ) : (
                  <>
                    <BaseButton
                      title="선택"
                      type="button"
                      className="size-medium color-white"
                      onClick={() => setPlatformDeviceSelectModalOpen({ isOpen: true })}
                    />
                    <p className="validation-text">
                      {(errors?.platformAccount as any)?.email
                        ? (errors?.platformAccount as any)?.email?.message
                        : ""}
                    </p>
                    <div className="flex-files pt10">
                      {selected?.map((item) => (
                        <div className="flex-files__wrap" key={item.id}>
                          <p>{item.id}</p>
                          <div
                            className="delete-btn-x"
                            onClick={() => handleDeleteST(String(item.id))}
                          ></div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </section>
            {id && (
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">Labels</p>
                </div>
                <div className="contents-container__grid-contents">
                  {labels?.map((labels, i) => (
                    <p key={labels.name + i}>
                      {labels.name} : {labels.value}
                    </p>
                  ))}
                </div>
              </section>
            )}
          </div>
          <BaseModal
            isOpen={confirmModalOpen.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setConfirmModalOpen({ isOpen: false })}
            onClick={() => {
              onClickConfirmModal(confirmModalOpen.payload);
              setConfirmModalOpen({ isOpen: false });
            }}
          >
            {confirmModalOpen.message}
          </BaseModal>
        </div>
        <div className="contents-container__btn-wrap px30">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              type="button"
              className="color-white size-large"
              onClick={() => {
                navigate("/mng/central/iot");
              }}
            />
          </div>
          <div className="right-area">
            <BaseButton type="submit" title="저장" className="size-large" />
          </div>
        </div>
      </form>
      {platformDeviceSelectModalOpen.isOpen && (
        <PlatformDeviceSelectModal
          onCanceled={() => setPlatformDeviceSelectModalOpen({ isOpen: false })}
          onAdded={(selected) => {
            setSelected(selected);
            if (selected.length === 0) {
              setValue("platformAccount.email", "");
              setValue("platformAccount.principalName", "");
              setValue("platformDeviceId", registeredDevice?.platformDevice?.id);
              setValue("platformDevice.label", registeredDevice?.platformDevice?.label);
            } else {
              setValue("platformAccount.email", selected[0].platformAccount.email);
              setValue("platformDeviceId", selected[0].id);
              setValue("platformDevice.label", selected[0].label);
              setValue("platformAccount.principalName", selected[0].platformAccount.principalName);
            }
          }}
          platform={getValues("platformAccount.platform")}
          defaultValues={selected !== undefined ? [...selected] : []}
        />
      )}
    </>
  );
};

export default BasicInfoForm;
