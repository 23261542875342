import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import {
  ProductGuideType,
  ProductGuideTypes,
  ProductGuideUpdateForm,
} from "src/api/product/product-types";
import { BaseInput, BaseToggle } from "src/components";
import MarkdownEditor from "src/components/MarkdownEditor";
import { onlyNumber } from "src/utils";
import FreeInternetSection from "./FreeInternetSection";

type Props = {
  productGuideType: ProductGuideType;
  guideId?: string;
  control: Control<ProductGuideUpdateForm>;
  setValue: UseFormSetValue<ProductGuideUpdateForm>;
  getValues: UseFormGetValues<ProductGuideUpdateForm>;
  errors?: any;
  register: UseFormRegister<ProductGuideUpdateForm>;
};

const GuideUploadForms = ({ productGuideType, control, getValues, errors, register }: Props) => {
  return (
    <>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>노출여부</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="">
            <Controller
              control={control}
              name="productGuide.isDisplayed"
              render={({ field: { onChange, value, name } }) => {
                return (
                  <BaseToggle
                    onChange={(checked: boolean) => {
                      onChange(checked);
                    }}
                    checked={value}
                    name={name}
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">정렬 가중치</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax100">
            <Controller
              control={control}
              name="productGuide.orderNums"
              render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    type="text"
                    name={name}
                    value={value}
                    onChange={(text: string) => onChange(onlyNumber(text))}
                    errorText={error?.message}
                    maxLength={3}
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">항목명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="">
            <Controller
              control={control}
              name="productGuide.subject"
              render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    type="text"
                    name={name}
                    value={value}
                    onChange={onChange}
                    errorText={error?.message}
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>

      {/* +항목추가 / +주차 관련 안내 추가 일시 Editor form 노출 */}
      {(productGuideType === ProductGuideTypes.ETC2 ||
        productGuideType === ProductGuideTypes.PARKINGLOT2) && (
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">상세내용</p>
          </div>
          <div className="contents-container__grid-contents">
            {!getValues("productGuide.description") && (
              <div>
                <Controller
                  control={control}
                  name="productGuide.description"
                  render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                    return (
                      <MarkdownEditor
                        value={value}
                        onChange={onChange}
                        errorText={error?.message}
                      />
                    );
                  }}
                ></Controller>
              </div>
            )}

            {getValues("productGuide.description") && (
              <div>
                <Controller
                  control={control}
                  name="productGuide.description"
                  render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                    return (
                      <MarkdownEditor
                        value={value}
                        onChange={onChange}
                        errorText={error?.message}
                      />
                    );
                  }}
                ></Controller>
              </div>
            )}
          </div>
        </section>
      )}

      {/* +네트워크 연결 추가 section */}
      {productGuideType === "PRODUCT_GUIDE_WIFI" && (
        <FreeInternetSection control={control} errors={errors} register={register} />
      )}

      {/* 버튼영역 */}
    </>
  );
};

export default GuideUploadForms;
