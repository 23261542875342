import React, { useCallback, useEffect, useState } from "react";
import { Control, UseFormReturn, Controller } from "react-hook-form";
import {
  BuildingModel,
  BuildingCommonFacilityDeskGroupModel,
  CommonFacilityModel,
} from "src/api/building/building-types";
import { getEslProductDetailAsync } from "src/api/esl/esl-api";
import { EslProduct } from "src/api/esl/esl-types";
import { useApiOperation } from "src/api/hooks";
import { ProductModel } from "src/api/product/product-types";
import { BaseToggle, BaseCheckbox, BaseTooltip, BaseInput, BaseRadio } from "src/components";
import ImagesDetail from "src/components/ImagesDetail";
import { showHourMinute } from "src/utils";
import { CommonSpaceFormData, DeskMediaType } from "../CommonSpaceForm";

type Props = {
  control: Control<any>;
  watch: UseFormReturn<CommonSpaceFormData>["watch"];
  errors: any;
  setValue: Function;
  setIsNoData: Function;
  productDetail?: ProductModel;
  getValues: Function;
  totalOption: any;
  deskList: Array<BuildingCommonFacilityDeskGroupModel>;
  deskMedia: Array<DeskMediaType>;
  checkedAllItem: Function;
};

interface EslLabel extends EslProduct {
  locationCode: string; // 공간유형 채번
}

const DeskFormComp = ({
  control,
  watch,
  errors,
  setValue,
  setIsNoData,
  productDetail,
  getValues,
  totalOption,
  deskList,
  deskMedia,
  checkedAllItem,
}: Props) => {
  // ESL 데이터 상세 조회 api
  const { executeAsync: getEslProductDetail } = useApiOperation(getEslProductDetailAsync, {
    doNotErrorHandleModal: true,
  });

  // 조회한 ESL 데이터 목록
  const [eslLabels, setEslLabels] = useState<Array<EslLabel>>([]);

  // esl 라벨 목록 조회
  const fetchAllLabels = useCallback(async (locationCodes: Array<string>) => {
    try {
      if (!locationCodes || locationCodes.length === 0) {
        throw Error("locationCodes.length === 0");
      }

      const eslLabels: Array<EslLabel> = [];
      for (let i = 0; i < locationCodes.length; i++) {
        const locationCode = locationCodes[i];
        const result = await getEslProductDetail({ productId: locationCode });
        if (result?.data?.data && result.status >= 200 && result.status <= 299) {
          if (result?.data?.data?.product) {
            // esl 라벨이 연동되어있을 경우만 조회됨
            eslLabels.push({
              ...result?.data?.data?.product,
              ...{
                locationCode,
              },
            });
          }
        }
      }

      setEslLabels(eslLabels);
    } catch (error) {
      // 에러 발생해도 무시
      console.debug("fetchAllLabels error", error);
    }
  }, []);

  // 수정시 api 에서 받아온 building 정보로 setValue 처리
  useEffect(() => {
    let desks: CommonFacilityModel[] = [];

    if (deskList) {
      for (let j = 0; j < deskList.length; j++) {
        const deskArr = deskList[j];

        if (deskArr.deskList) {
          for (let k = 0; k < deskArr.deskList.length; k++) {
            const deskItem = deskArr.deskList[k];
            desks.push(deskItem);
          }
        }
      }

      if (desks) {
        // locaiotn code 로 라벨 목록 조회
        const locationCodes: Array<string> = [];
        for (let i = 0; i < desks.length; i++) {
          const locationCode = desks[i]?.locationCode;
          if (!!locationCode) {
            locationCodes.push(locationCode);
          }
        }
        if (locationCodes.length > 0) {
          fetchAllLabels(locationCodes);
        }
      }
    }
  }, [deskList]);

  return (
    <article>
      <div className="contents-container__sub-title">
        <div className="minmax140 pb4">
          <h2>좌석</h2>
        </div>
        <Controller
          control={control}
          name="isDesk"
          render={({ field: { onChange, name, value } }) => {
            return (
              <>
                <BaseToggle
                  name={name}
                  // onChange={(value: boolean) => {
                  //   if (value && deskList.length === 0) {
                  //     setIsNoData(true);
                  //     return;
                  //   }
                  //   onChange(value);
                  // }}
                  checked={value}
                />
              </>
            );
          }}
        ></Controller>
      </div>

      {/* 토글을 닫으면 contents-container__divide-bottom 영역이 노출되지 않음 */}
      {Boolean(watch("isDesk")) && (
        <div className="contents-container__divide-bottom">
          {/* 좌석 선택 */}
          <section className="contents-container__grid contents-container__1200">
            <div className="contents-container__grid-index">
              <p className="required">좌석 선택</p>
            </div>
            <div className="contents-container__grid-contents">
              {productDetail?.buildingList &&
                deskList.length > 0 &&
                productDetail?.buildingList.map((product: BuildingModel) => {
                  const filterdList = deskList.filter(
                    (desk: BuildingCommonFacilityDeskGroupModel) => desk.buildingId === product?.id,
                  );

                  return (
                    <div className="pt8 mb10" key={product.id}>
                      <div className="index-tab">
                        <span>{product?.buildingName}</span>
                      </div>
                      {filterdList.map(
                        (deskGroup: BuildingCommonFacilityDeskGroupModel, index: number) => {
                          return (
                            <div className="border-gray" key={index}>
                              <div className="pa16">
                                <div className="flex-center mb10">
                                  <div className="minmax140">
                                    <span>위치</span>
                                  </div>
                                  <span>
                                    {`${deskGroup.isGround ? "지상" : "지하"} ${
                                      deskGroup.floorNum
                                    }`}
                                    층
                                  </span>
                                </div>
                                <div className="flex-center mb10">
                                  <div className="minmax140">
                                    <span>좌석 그룹 명</span>
                                  </div>
                                  <span>{deskGroup.groupName}</span>
                                </div>
                                <div className="flex-center">
                                  <div className="minmax140">
                                    <span>설명</span>
                                  </div>
                                  <span>{deskGroup.description}</span>
                                </div>
                              </div>
                              <table className="inner-table" width="100%">
                                {deskGroup.deskList && deskGroup.deskList?.length > 0 ? (
                                  <thead>
                                    <tr>
                                      <th>
                                        <div>
                                          <BaseCheckbox
                                            id={`DESK${deskGroup.id}`}
                                            name={product?.buildingName}
                                            className="justify-contents-center"
                                            checked={checkedAllItem(deskGroup.deskList)}
                                            // onChange={(checked: boolean) => {
                                            //   let commonSpaceArr =
                                            //     watch("selectedCommonFacility") || [];

                                            //   if (checked) {
                                            //     deskGroup?.deskList?.forEach((desk) => {
                                            //       commonSpaceArr.push(desk);
                                            //     });

                                            //     //
                                            //   } else {
                                            //     if (deskGroup.deskList) {
                                            //       for (
                                            //         let i = 0;
                                            //         i < deskGroup.deskList.length;
                                            //         i++
                                            //       ) {
                                            //         const deskItem = deskGroup.deskList[i];

                                            //         commonSpaceArr = commonSpaceArr.filter(
                                            //           (item: CommonFacilityModel) =>
                                            //             item.id !== deskItem.id,
                                            //         );
                                            //       }
                                            //     }
                                            //   }

                                            //   setValue("selectedCommonFacility", [
                                            //     ...commonSpaceArr,
                                            //   ]);
                                            // }}
                                          />
                                        </div>
                                      </th>
                                      <th>
                                        <span>No</span>
                                      </th>
                                      <th>
                                        <span>좌석 명</span>
                                      </th>
                                      <th>
                                        <span>ESL</span>
                                      </th>
                                    </tr>
                                  </thead>
                                ) : (
                                  <thead>
                                    <tr>
                                      <th>등록된 좌석이 없습니다.</th>
                                    </tr>
                                  </thead>
                                )}
                                <tbody>
                                  {deskGroup.deskList &&
                                    deskGroup.deskList.length > 0 &&
                                    deskGroup.deskList?.map(
                                      (desk: CommonFacilityModel, idx: number) => {
                                        const findLabel = eslLabels.find(
                                          (eslLabel: EslLabel) =>
                                            eslLabel.locationCode === desk.locationCode,
                                        );

                                        return (
                                          <tr key={idx}>
                                            <td width="70">
                                              <div className="flex-center-center no-wrap">
                                                <BaseCheckbox
                                                  id={String(desk.id)}
                                                  name={""}
                                                  checked={
                                                    getValues("selectedCommonFacility")?.find(
                                                      (item: CommonFacilityModel) =>
                                                        String(item.id) === String(desk.id),
                                                    )
                                                      ? true
                                                      : false
                                                  }
                                                  // onChange={(checked: boolean) => {
                                                  //   let commonFacilityArr: any[] =
                                                  //     getValues("selectedCommonFacility") || [];

                                                  //   // 체크를 하면
                                                  //   if (checked) {
                                                  //     // arr.push(String(desk.id));
                                                  //     commonFacilityArr.push(desk); // object 넘기기
                                                  //   } else {
                                                  //     commonFacilityArr = commonFacilityArr.filter(
                                                  //       (item: CommonFacilityModel) =>
                                                  //         String(item.id) !== String(desk.id),
                                                  //     );
                                                  //   }

                                                  //   setValue("selectedCommonFacility", [
                                                  //     ...commonFacilityArr,
                                                  //   ]);
                                                  // }}
                                                />
                                              </div>
                                            </td>
                                            <td>
                                              <div className="flex-center-center no-wrap">
                                                <span>{idx + 1}</span>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="flex-center-center no-wrap">
                                                <span>{desk.facilityName}</span>
                                              </div>
                                            </td>
                                            <td width="100">
                                              <div className="flex-center-center no-wrap">
                                                <div
                                                  className={
                                                    findLabel === undefined ? "not-available" : ""
                                                  }
                                                >
                                                  {findLabel !== undefined ? (
                                                    <BaseTooltip
                                                      contents={"입력"}
                                                      tooltip={(
                                                        findLabel?.assignedLabelCodes || []
                                                      ).join(",")}
                                                      type={"normal"}
                                                    />
                                                  ) : (
                                                    <span>미입력</span>
                                                  )}
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      },
                                    )}
                                </tbody>
                              </table>
                            </div>
                          );
                        },
                      )}
                      <div className="contents-container__grid-index mt20 mb10">
                        <p>배치도</p>
                      </div>
                      <div className="contents-container__grid-contents mb60">
                        {deskMedia.map((item) => {
                          return (
                            item.id === product?.id &&
                            (item.media.length !== 0 ? (
                              <div key={item.id}>
                                <ImagesDetail images={item.media} />
                              </div>
                            ) : (
                              <p className="font13">현재 등록된 배치도가 없습니다</p>
                            ))
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              {errors?.selectedCommonFacility &&
                watch("selectedCommonFacility")?.every(
                  (item: CommonFacilityModel) => item.commonFacilityType !== "DESK",
                ) && <p className="validation-text">필수선택 항목입니다</p>}
            </div>
          </section>
          {/* Court 소개 내용 */}
          <section className="contents-container__grid contents-container__1200">
            <div className="contents-container__grid-index">
              <p>Court 소개 내용</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <div className="minmax140">
                  <span>설명</span>
                </div>
                <Controller
                  control={control}
                  name="deskDescription"
                  render={({ field: { onChange, name, value } }) => {
                    return (
                      <>
                        <BaseInput
                          placeholder="COURT에 표시될 좌석 관련 추가 설명을 적어주세요."
                          onChange={onChange}
                          name={name}
                          value={value}
                        />
                      </>
                    );
                  }}
                ></Controller>
              </div>
            </div>
          </section>
          {/* 예약 설정 */}
          <section className="contents-container__grid contents-container__1200">
            <div className="contents-container__grid-index">
              <p className="required">Taap 예약 설정</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start mb10">
                <div className="minmax140">
                  <span>총 예약 시간/월</span>
                </div>
                <Controller
                  control={control}
                  name="deskMaxReservTimeMonthIsFlag"
                  render={({ field: { onChange, name, value } }) => {
                    return (
                      <>
                        <BaseRadio
                          id={"desk5"}
                          value={"false"}
                          name={name}
                          label="제한 없음"
                          className="mr16"
                          checked={Boolean(getValues("deskMaxReservTimeMonthIsFlag")) === false}
                          onChange={() => {
                            setValue("deskMaxReservTimeMonthIsFlag", false);
                            onChange(false);
                            setValue("deskMaxReservTimeMonth", 0);
                          }}
                        />
                        <BaseRadio
                          id={"desk6"}
                          value={"true"}
                          name={name}
                          label="제한 있음"
                          className="mr16"
                          checked={Boolean(getValues("deskMaxReservTimeMonthIsFlag")) === true}
                          onChange={() => {
                            onChange(true);
                            setValue("deskMaxReservTimeMonthIsFlag", true);
                            setValue(
                              "deskMaxReservTimeMonth",
                              totalOption[totalOption.length - 1].value * 4,
                            );
                            //좌석은 4시간 추가로 곱하기 (기획서 내용)
                          }}
                        />
                      </>
                    );
                  }}
                ></Controller>

                {Boolean(watch("deskMaxReservTimeMonthIsFlag")) === true && (
                  <>
                    <span className="font13 text-primary5 mr10">{`( 매월 총`}</span>
                    <div className="minmax100">
                      <Controller
                        control={control}
                        name="deskMaxReservTimeMonth"
                        render={({ field: { name, value, onChange } }) => {
                          return (
                            <>
                              <BaseInput
                                type="number"
                                name={name}
                                value={value && value > 0 ? value : ""}
                                readonly={!Boolean(getValues("deskMaxReservTimeMonthIsFlag"))}
                                onChange={onChange}
                              />
                            </>
                          );
                        }}
                      ></Controller>
                    </div>
                    <span className="font13 text-primary5 ml10">{`분 [${showHourMinute(
                      String(watch("deskMaxReservTimeMonth")),
                    )}] 예약 가능)`}</span>
                  </>
                )}
              </div>
              <div className="flex-row flex-center-start" style={{ minHeight: "36px" }}>
                <div className="minmax140">
                  <span>예약 최대 시간/일</span>
                </div>
                <Controller
                  control={control}
                  name="deskMaxReservTimeDayIsFlag"
                  render={({ field: { onChange, name, value } }) => {
                    return (
                      <>
                        <BaseRadio
                          id={"desk7"}
                          value={"false"}
                          name={name}
                          label="제한 없음"
                          className="mr16"
                          checked={Boolean(getValues("deskMaxReservTimeDayIsFlag")) === false}
                          onChange={() => {
                            setValue("deskMaxReservTimeDayIsFlag", false);
                            onChange(false);
                            setValue("deskMaxReservTimeDay", 0);
                          }}
                        />
                        <BaseRadio
                          id={"desk8"}
                          value={"true"}
                          name={name}
                          label="제한 있음"
                          className="mr16"
                          checked={Boolean(getValues("deskMaxReservTimeDayIsFlag")) === true}
                          onChange={() => {
                            onChange(true);
                            setValue("deskMaxReservTimeDayIsFlag", true);
                            setValue("deskMaxReservTimeDay", 600);
                          }}
                        />
                      </>
                    );
                  }}
                ></Controller>

                {Boolean(watch("deskMaxReservTimeDayIsFlag")) === true && (
                  <>
                    <span className="font13 text-primary5 mr10">{`( 1일 총`}</span>
                    <div className="minmax100">
                      <Controller
                        control={control}
                        name="deskMaxReservTimeDay"
                        render={({ field: { name, value, onChange } }) => {
                          return (
                            <>
                              <BaseInput
                                type="number"
                                name={name}
                                value={value && value > 0 ? value : ""}
                                readonly={!Boolean(getValues("deskMaxReservTimeDayIsFlag"))}
                                onChange={onChange}
                              />
                            </>
                          );
                        }}
                      ></Controller>
                    </div>
                    <span className="font13 text-primary5 ml10">{`분 [${showHourMinute(
                      String(watch("deskMaxReservTimeDay")),
                    )}] 예약 가능)`}</span>
                  </>
                )}
              </div>
              {errors?.isDesk && <p className="validation-text">필수선택 항목입니다</p>}
            </div>
          </section>
        </div>
      )}
    </article>
  );
};

export default DeskFormComp;
