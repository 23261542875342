import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { Memo } from "src/api/adminMemo/adminmemo-types";
import { Modal } from "src/api/public-types";
import { BaseModal, BaseTooltip } from "src/components";
import { AdminMemoIntegrationFormData } from "./adminmemo-types";

type Props = {
  data: Array<Memo>;
  delMemo?: any;
  callList?: any;
  setValue?: UseFormSetValue<AdminMemoIntegrationFormData>;
  viewOnly?: boolean;
};

// 공간상품 기본정보 form (건물/호실) 부분 테이블
const AdminMemoTable = ({ data, delMemo, callList, setValue, viewOnly }: Props) => {
  const { user } = useAuth0();
  const [deleteModal, setDeleteModal] = useState<Modal>({ isOpen: false, payload: 0 });
  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",

        width: 120,
      },
      {
        Header: "내용",
        accessor: "memo",
        width: 490,
        Cell: ({ value }: any) => (
          <div className={"w-100 text-left pre-formatted"}>
            <BaseTooltip
              type={value.length > 500 ? "large" : ""}
              preformatted={true}
              tooltip={value}
              contents={value || ""}
            />
          </div>
        ),
      },
      {
        Header: "등록 일시",
        accessor: "modifiedDate",
        width: 150,
        Cell: ({ value }: any) => <BaseTooltip contents={value || ""} type="date" />,
      },
      {
        Header: "작성자",
        accessor: "modifiedBy",
        width: 200,
      },
      {
        Header: "수정/삭제",
        accessor: "action",
        width: 110,
        Cell: ({ row, setDeleteModal }: any) => {
          const adminId = row.original.id;
          const adminMemo = row.original.memo;
          return (
            <>
              {user?.email === row.original?.createdBy && (
                <div className="minmax50 flex-center">
                  <button
                    className="base-edit-btn mr4"
                    onClick={() => {
                      if (setValue) {
                        setValue("memo", adminMemo);
                        setValue("id", adminId);
                      }
                    }}
                  ></button>
                  <button
                    className="base-trash-btn"
                    onClick={() => {
                      setDeleteModal({ isOpen: true, payload: adminId });
                    }}
                  ></button>
                </div>
              )}
            </>
          );
        },
      },
    ],
    [],
  );

  //가변헤더 사용 viewOnly로 수정/삭제 컬럼 제어
  const settingHeader = useMemo(() => {
    let head = _.cloneDeep(columns);
    if (viewOnly !== undefined && viewOnly === true) {
      head[1].width = 600;
      head = head.slice(0, head.length - 1);
    } else {
      head[1].width = 490;
    }
    // console.log(viewOnly, head);
    return head;
  }, [columns, viewOnly]);

  const { getTableProps, getTableBodyProps, headerGroups, page, rows, prepareRow } = useTable(
    {
      columns: settingHeader,
      data: data,
      setDeleteModal,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  // 관리자 메모 삭제
  const onDeleteAdminMemoApi = useCallback(async (memoId: number) => {
    if (memoId !== 0) {
      let idList: Array<number> = [];
      idList.push(Number(memoId));

      const response = await delMemo(idList);
      if (response.status >= 200 && response.status <= 299) {
        callList();
      }
      setDeleteModal({ isOpen: false, payload: 0 });
    }
  }, []);

  useEffect(() => {}, []);

  return (
    <section className="inner-tab-table">
      <div className="">
        <div {...getTableProps()} className="base-table sticky">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {rows.length === 0 && (
            <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
              <div className="base-table__td w-100 text-center">
                <div className="w-100">
                  <span>데이터가 없습니다.</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {deleteModal.isOpen && (
        <BaseModal
          isOpen={deleteModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClick={() => {
            onDeleteAdminMemoApi(Number(deleteModal.payload));
          }}
          onClose={() => setDeleteModal({ isOpen: false })}
        >
          <p>{"정말로 삭제하시겠습니까?"}</p>
        </BaseModal>
      )}
    </section>
  );
};

export default AdminMemoTable;
