import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getContractApplicationInfo,
  getContractOperationInfo,
} from "src/api/contract/contract-api";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { getMemberDetail } from "src/api/member/member-api";
import { getPartner } from "src/api/partner";
import { contract_bill_user_errors } from "src/api/public-types";
import {
  addUsers,
  calendarStatus,
  delUser,
  getUser,
  resolveWatchCalendar,
  retryWatchCalendar,
  unWatchCalendar,
  watchCalendar,
} from "src/api/user/user-api";
import { MemberStatus, UserDetailInvietee, UserDetailModel } from "src/api/user/user-types";
import { BaseButton, BaseModal, ContentsIdSection, ContentsTitle } from "src/components";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { Partner } from "src/types/partner";
import { YmdFormat, formatPhoneNumber } from "src/utils";

const UserDetail = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const { openToast } = useToastContext();
  const navigate = useNavigate();
  const location = useLocation();
  const breadCrumbs = [
    { value: "user", label: "이용자" },
    { value: "", label: "이용자 상세" },
  ];
  const query = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );
  const { memberId: contractMemberId } = useParams();

  // console.log('id', visitorId);
  const [isDelete, setIsDelete] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isUpdateCheck, setIsUpdateCheck] = useState(false);
  const [isMaster, setIsMaster] = useState(false);
  const [isNotMaster, setIsNotMaster] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [showIssue, setShowIssue] = useState("-");
  const [isRetryInvite, setIsRetryInvite] = useState(false);

  const [partnerId, setPartnerId] = useState("");

  const [user, setUser] = useState<UserDetailModel>();
  const [isWatchCalendar, setIsWatchCalendar] = useState(false);
  const [mId, setMId] = useState<Number>();
  const [viewStatus, setViewStatus] = useState("");
  const [operation, setOperation] = useState<any>();
  const [partner, setPartner] = useState<Partner>();

  const { executeAsync: detail } = useApiOperation(getUser, { doNotErrorHandleModal: true });
  const { executeAsync: dUser } = useApiOperation(delUser, { doNotErrorHandleModal: true });
  const { executeAsync: changeCalendar } = useApiOperation(watchCalendar, {
    doNotErrorHandleModal: true,
  });

  const { executeAsync: changeCalendar2 } = useApiOperation(unWatchCalendar, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getCalStatue } = useApiOperation(calendarStatus, {
    doNotErrorHandleModal: true,
  });

  const { executeAsync: retryRelationCalendar } = useApiOperation(retryWatchCalendar, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: resolveRelationCalendar } = useApiOperation(resolveWatchCalendar, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: memberDetail } = useApiOperation(getMemberDetail, {
    doNotErrorHandleModal: true,
  });

  const { executeAsync: getOperation } = useApiOperation(getContractOperationInfo, {
    doNotErrorHandleModal: true,
  });

  const { executeAsync: getApplication } = useApiOperation(getContractApplicationInfo, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: addUser } = useApiOperation(addUsers, {
    doNotErrorHandleModal: true,
  });

  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  useEffect(() => {
    (async () => {
      if (query.partnerId === undefined) return;

      const result = await getPartnerAsync({
        id: query.partnerId as string,
      });

      if (result.status >= 200 && result.status <= 299) {
        setPartner(result.data.data.partner);
      }
    })();
  }, [query]);

  const callOperation = useCallback(
    async (id: number) => {
      setLoadingBar(true);
      const res: any = await getOperation({ id: Number(id) });
      if (res.status >= 200 && res.status <= 299) {
        console.log("????", res.data.data.content);

        const length = res.data.data.content.productManage.clientSecretValue.length;

        res.data.data.content.productManage.clientSecretValueBlocked = "*".repeat(length);
        setOperation(res.data.data.content);
      } else {
        let message = errorMessageHandler(
          res.status,
          res.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.staus;
        openErrorModal(message, code);
      }

      setLoadingBar(false);
    },
    [getOperation, openErrorModal, setLoadingBar],
  );

  const callMemberDetail = useCallback(
    async (id: string) => {
      const response: any = await memberDetail({ id });
      if (response.status >= 200 && response.status <= 299) {
        setMId(response.data.data.member.id);
      }
    },
    [memberDetail],
  );

  const callDetail = useCallback(
    async (contractMemberId: number) => {
      setLoadingBar(true);
      const res: any = await detail({ contractMemberId });
      res.data.data.contractManageId = query.contractManageId;
      console.log("call user detail", res);
      if (res.status >= 200 && res.status <= 299) {
        if (res.data.data.content.memberNo) {
          callMemberDetail(res.data.data.content.memberNo);
        }
        if (
          res.data.data?.hrUser?.isExistsIssues !== undefined &&
          String(res.data.data?.hrUser?.isExistsIssues) === "true"
        ) {
          let text = "실패(";

          if (res.data.data?.hrUser?.isResolvable === true) {
            text = text + "재 연동 필요 ";
          } else {
            text = text + "관리자 문의 ";
          }
          text += moment(res.data.data?.hrUser?.issueOccurredDate).format(YmdFormat.FULL) + ")";
          setShowIssue(text);
        }

        setUser(res.data.data);
        callOperation(res.data.data.content.contractId);
        setViewStatus(res.data.data.content.memberStatus);

        const res2: any = await getApplication({ id: Number(res.data.data.content.contractId) });
        setPartnerId(res2.data.data.partnerId);
      } else {
        let message = errorMessageHandler(
          res.status,
          res.data.meta.errorCode,

          contract_bill_user_errors,
        );
        let code = res.status;
        if (res.data.meta.errorCode !== "") {
          code = res.data.meta.errorCode;
        }
        openErrorModal(message, code);
      }

      setLoadingBar(false);
    },

    [
      callMemberDetail,
      callOperation,
      detail,
      getApplication,
      openErrorModal,
      query.contractManageId,
      setLoadingBar,
    ],
  );

  const moveList = useCallback(() => {
    const path = PagePath.user.list + "?" + qs.stringify(query, { allowDots: true, encode: true });
    navigate(path);
  }, [navigate, query]);

  const retryCalendar = useCallback(async () => {
    setLoadingBar(true);
    const response: any = retryRelationCalendar({
      contractId: Number(user?.hrUser.contractId),
      memberNo: String(user?.content.memberNo),
      userEmail: String(user?.hrUser.email),
    });
    if (response.status >= 200 && response.status <= 299) {
      setSuccess("캘린더 연동에 성공하였습니다.");
      callDetail(Number(contractMemberId));
    }
    setLoadingBar(false);
  }, [callDetail, contractMemberId, retryRelationCalendar, setLoadingBar, user]);
  const resolveCalendar = useCallback(async () => {
    setLoadingBar(true);
    const response: any = resolveRelationCalendar({
      contractId: Number(user?.hrUser.contractId),
      memberNo: String(user?.content.memberNo),
      userEmail: String(user?.hrUser.email),
    });
    if (response.status >= 200 && response.status <= 299) {
      setSuccess("캘린더 연동에 성공하였습니다.");
      callDetail(Number(contractMemberId));
    }
    setLoadingBar(false);
  }, [
    callDetail,
    contractMemberId,
    resolveRelationCalendar,
    setLoadingBar,
    user?.content.memberNo,
    user?.hrUser.contractId,
    user?.hrUser.email,
  ]);

  const retryInvite = useCallback(async () => {
    setIsRetryInvite(false);
    setLoadingBar(true);

    // const res: any = await retryInviteU({ contractMemberId: user?.content.contractMemberId });

    const res: any = await addUser({
      contractManageId: user?.contractManageId,
      partnerId: partnerId,
      contractMemberList: [{ inviteMobileNumber: user?.content.inviteMobileNumber }],
    });
    console.log("res", res);
    if (res.status >= 200 && res.status <= 299) {
      openToast("재초대 되었습니다.");
      callDetail(Number(contractMemberId));
    } else {
      let message = errorMessageHandler(
        res.status,
        res.data.meta.errorCode,

        contract_bill_user_errors,
      );
      let code = res.status;
      if (res.data.meta.errorCode !== "") {
        code = res.data.meta.errorCode;
      }
      openErrorModal(message, code);
    }
    setLoadingBar(false);
  }, [addUser, callDetail, contractMemberId, openErrorModal, partnerId, setLoadingBar, user]);

  const onClickUpdate = useCallback(async () => {
    // if (user?.hrUser.isEmailVerified === undefined && user?.content.isWatchingCalendar === false) {
    //   setMessage("이메일 인증이 필요합니다.");
    //   setIsUpdateCheck(true);
    //   return;
    // }

    // console.log(user);

    setLoadingBar(true);
    const res: any = await changeCalendar({
      contractId: Number(user?.hrUser.contractId),
      memberNo: String(user?.content.memberNo),
      userEmail: String(user?.hrUser.email),
    });
    if (res.status >= 200 && res.status <= 299) {
      setIsDelete(false);
      setSuccess("캘린더 연동에 성공하였습니다.");
      setIsDeleted(true);
    } else {
      let message = errorMessageHandler(
        res.status,
        res.data.meta.errorCode,
        contract_bill_user_errors,
      );
      let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.status;
      message = res.data.meta.errorMessage;
      openErrorModal(message, code);
    }

    setLoadingBar(false);
  }, [changeCalendar, openErrorModal, setLoadingBar, user]);

  const onClickFalseUpdate = useCallback(async () => {
    setLoadingBar(true);
    const res: any = await changeCalendar2({
      contractId: Number(user?.hrUser.contractId),
      memberNo: String(user?.content.memberNo),
      userEmail: String(user?.hrUser.email),
    });
    if (res.status >= 200 && res.status <= 299) {
      setIsDelete(false);
      setSuccess("캘린더 미연동에 성공하였습니다.");
      setIsDeleted(true);
    } else {
      let message = errorMessageHandler(
        res.status,
        res.data.meta.errorCode,
        contract_bill_user_errors,
      );
      let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.status;
      openErrorModal(message, code);
    }

    setLoadingBar(false);
  }, [
    changeCalendar2,
    openErrorModal,
    setLoadingBar,
    user?.content.memberNo,
    user?.hrUser.contractId,
    user?.hrUser.email,
  ]);

  const onMasterToggle = useCallback(
    async (isAdmin: boolean | undefined) => {
      setLoadingBar(true);
      console.log("isSetAdmin", isAdmin);
      setIsMaster(false);
      setIsNotMaster(false);
      const res: any = await dUser({
        contractMemberId: Number(contractMemberId),
        contractManageId: Number(query?.contractManageId),
        isAdmin: isAdmin,
        delete: false,
      });
      if (res.status >= 200 && res.status <= 299) {
        setSuccess("권한이 변경되었습니다.");
        setIsDeleted(true);
      } else {
        let message = errorMessageHandler(
          res.status,
          res.data.meta.errorCode,

          contract_bill_user_errors,
        );
        let code = res.status;
        if (res.data.meta.errorCode !== "") {
          code = res.data.meta.errorCode;
        }
        openErrorModal(message, code);
      }

      setLoadingBar(false);
    },
    [contractMemberId, dUser, openErrorModal, query?.contractManageId, setLoadingBar],
  );

  const onDelete = useCallback(async () => {
    setLoadingBar(true);
    const res: any = await dUser({
      contractMemberId: Number(contractMemberId),
      contractManageId: Number(query?.contractManageId),
      delete: true,
    });
    if (res.status >= 200 && res.status <= 299) {
      setIsDelete(false);
      setSuccess("삭제 되었습니다.");
      setIsDeleted(true);
    } else {
      let message = errorMessageHandler(
        res.status,
        res.data.meta.errorCode,

        contract_bill_user_errors,
      );
      let code = res.status;
      if (res.data.meta.errorCode !== "") {
        code = res.data.meta.errorCode;
      }
      openErrorModal(message, code);
    }

    setLoadingBar(false);
  }, [contractMemberId, dUser, openErrorModal, query?.contractManageId, setLoadingBar]);

  const onDeleted = useCallback(() => {
    // const path = PagePath.user.list + "?contractApplyNumber=" + query.contractApplyNumber;
    // navigate(path);
    setIsDeleted(false);
    setIsEditable(false);
    callDetail(Number(contractMemberId));
  }, []);

  const sendMemberDetail = useCallback((inviteeInfo?: UserDetailInvietee) => {
    let rtn = "";
    if (inviteeInfo && inviteeInfo?.inviteeMemberId) {
      const path = PagePath.member.detail.replace(":id", String(inviteeInfo?.inviteeMemberId));
      rtn = path;
    }
    return rtn;
  }, []);

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "user") {
        navigate(`${PagePath.user.list}?contractApplyNumber=${query.contractApplyNumber}`);
      }
    },
    [navigate, query.contractApplyNumber],
  );

  useEffect(() => {
    if (contractMemberId) {
      callDetail(Number(contractMemberId));
    }
  }, [contractMemberId]);

  useTitleOperation(String(user?.content.memberNo));

  return (
    <div>
      <ContentsTitle
        title="이용자 상세"
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />

      <div className="contents-container__wrap">
        <div className="contents-container__scroll">
          <div className="contents-container__wrap-contents">
            <ContentsIdSection
              title="이용자 상세"
              id={contractMemberId}
              noTitle="신청번호"
              no={Number(query?.contractApplyNumber)}
            />
            {/* 회원번호 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>회원번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {user?.content.memberNo ? (
                    <Link
                      to={PagePath.member.detail.replace(":id", String(mId))}
                      className="text-hilight"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {user?.content.memberNo}
                    </Link>
                  ) : (
                    "-"
                  )}
                </p>
              </div>
            </section>
            {/* 휴대폰 번호 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>휴대폰 번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {user?.content?.inviteMobileNumber
                    ? formatPhoneNumber(user.content.inviteMobileNumber.toString())
                    : "-"}
                </p>
              </div>
            </section>
            {/* 이메일 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>이메일</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  {user?.hrUser.email ? (
                    <Link
                      to={PagePath.member.detail.replace(":id", String(mId))}
                      className="text-hilight"
                    >
                      <p>{user?.hrUser.email ? user?.hrUser.email : "-"}</p>
                    </Link>
                  ) : (
                    <p>{"-"}</p>
                  )}
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>이메일 인증</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  {user?.hrUser.isEmailVerified ? (
                    <p>{user?.hrUser.isEmailVerified ? "인증" : "미인증"}</p>
                  ) : (
                    <p>{"-"}</p>
                  )}
                </div>
              </div>
            </section>
            {/* 상태 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>상태</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-center-start flex-row">
                  <p className="text12">
                    {viewStatus === MemberStatus.MEMBER_INVITE && "초대"}
                    {viewStatus === MemberStatus.MEMBER_USE && "사용"}
                    {viewStatus === MemberStatus.MEMBER_DELETE && "삭제"}
                    {viewStatus === MemberStatus.MEMBER_CLOSE && "종료"}
                    {viewStatus === MemberStatus.MEMBER_EXPIRE && "만료"}
                  </p>
                  {viewStatus === MemberStatus.MEMBER_EXPIRE && (
                    <BaseButton
                      title="재 초대"
                      onClick={() => setIsRetryInvite(true)}
                      className="color-white ml10"
                    />
                  )}
                </div>
              </div>
            </section>
            {/* 초대일시 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>초대일시</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {user?.content?.inviteDate
                    ? moment(user?.content?.inviteDate).format(YmdFormat.FULL)
                    : "-"}
                </p>
              </div>
            </section>
            {/* 가입일시 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>가입일시</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {user?.hrUser?.signUpTime
                    ? moment(user?.hrUser?.signUpTime).format(YmdFormat.FULL)
                    : "-"}
                </p>
              </div>
            </section>
            {/* 파트너 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>파트너</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{`${partner?.name} (${partner?.id})`}</p>
              </div>
            </section>
            {/* 초대자 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>초대자</p>
              </div>
              <div className="contents-container__grid-contents">
                <Link
                  to={sendMemberDetail(user?.inviteeInfo || undefined)}
                  className="text-hilight"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {user?.inviteeInfo?.inviteeMemberId
                    ? user?.inviteeInfo?.inviteeMemberId
                    : user?.inviteeInfo?.inviteeMemberNo
                    ? user?.inviteeInfo?.inviteeMemberNo
                    : "-"}
                </Link>
                <span className="ml10">
                  (
                  {user?.inviteeInfo?.inviteeMemberId
                    ? "Central"
                    : user?.inviteeInfo?.inviteePhoneNumber
                    ? user?.inviteeInfo?.inviteePhoneNumber
                    : "-"}
                  )
                </span>
              </div>
            </section>
            {operation && operation?.productManage?.isUseCalendar === true && (
              <div>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>캘린더 연동</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    {isEditable === false ? (
                      <div>
                        <p>{user?.hrUser?.isWatchingCalendar ? "연동" : "미연동"}</p>
                      </div>
                    ) : (
                      <div className="flex-center-start flex-row">
                        <span className="mr10">
                          {user?.hrUser?.isWatchingCalendar ? "연동" : "미연동"}
                        </span>

                        <BaseButton
                          title={user?.hrUser?.isWatchingCalendar ? "미연동" : "연동"}
                          onClick={() =>
                            user?.hrUser?.isWatchingCalendar === false
                              ? onClickUpdate()
                              : onClickFalseUpdate()
                          }
                          className="color-white"
                        />
                      </div>
                    )}
                  </div>
                </section>
                {user?.hrUser?.isWatchingCalendar && (
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>캘린더 동기화</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      {isEditable === false ? (
                        <div>
                          <p>{showIssue}</p>
                        </div>
                      ) : (
                        <div className="flex-center-start flex-row">
                          <span className="mr10">
                            <p>{showIssue}</p>
                          </span>

                          {user?.hrUser?.isExistsIssues !== undefined &&
                            user?.hrUser?.isExistsIssues === false && (
                              <BaseButton
                                title="재시도"
                                onClick={() => retryCalendar()}
                                className="color-white"
                              />
                            )}
                          {user?.hrUser?.isExistsIssues !== undefined &&
                            user?.hrUser?.isExistsIssues === true &&
                            user?.hrUser?.isResolvable === true && (
                              <BaseButton
                                title="재시도"
                                onClick={() => resolveCalendar()}
                                className="color-white"
                              />
                            )}
                        </div>
                      )}
                    </div>
                  </section>
                )}
              </div>
            )}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>권한</p>
              </div>
              <div className="contents-container__grid-contents">
                {isEditable === false ? (
                  <div>
                    <p>
                      {user?.content?.isAdmin === false &&
                        user?.content?.isContractor === false &&
                        "없음"}
                      {user?.content?.isAdmin === false &&
                        user?.content?.isContractor === true &&
                        "계약자"}
                      {user?.content?.isAdmin === true &&
                        user?.content?.isContractor === true &&
                        "계약자, 마스터"}
                      {user?.content?.isAdmin === true &&
                        user?.content?.isContractor === false &&
                        "마스터"}
                    </p>
                  </div>
                ) : (
                  <div className="flex-center-start flex-row">
                    <span className="mr10">
                      <div className="flex-center-start flex-row">
                        <div>
                          {user?.content?.isAdmin === false &&
                            user?.content?.isContractor === false &&
                            "없음"}
                        </div>

                        <div className="flex-center">
                          {user?.content?.isAdmin === false &&
                            user?.content?.isContractor === true &&
                            "계약자"}
                        </div>

                        <div className="flex-center">
                          {user?.content?.isAdmin === true &&
                            user?.content?.isContractor === true &&
                            "계약자, 마스터"}
                        </div>

                        <div className="flex-center">
                          {user?.content?.isAdmin === true &&
                            user?.content?.isContractor === false &&
                            "마스터"}
                        </div>
                        {user?.content?.isContractor === false && (
                          <BaseButton
                            title={user?.content?.isAdmin === false ? "마스터 지정" : "마스터 해제"}
                            onClick={() => {
                              if (user?.content?.isAdmin === true) {
                                setIsNotMaster(true);
                              } else {
                                setIsMaster(true);
                              }
                            }}
                            className="ml10 color-white"
                          />
                        )}
                      </div>
                    </span>
                  </div>
                )}
              </div>
            </section>
          </div>
          <div className="contents-container__btn-wrap">
            <div className="left-area d-flex">
              <BaseButton
                title="목록으로"
                onClick={() => moveList()}
                className="color-white size-large"
              />
              <div className="right-area">
                {/* {user?.content?.isContractor !== true ? (
                  (user?.content?.memberStatus === MemberStatus.MEMBER_USE ||
                    user?.content?.memberStatus === MemberStatus.MEMBER_INVITE) && (
                    <BaseButton
                      title="삭제"
                      onClick={() => setIsDelete(true)}
                      className="no-outline color-white size-large text-red ml10"
                    />
                  )
                ) : (
                  <div className="ml40">
                    <p className="contents-container__sub-title-info">
                      {"계약자는 이용자 삭제가 되지 않습니다."}
                    </p>
                  </div>
                )} */}
              </div>
            </div>
            <div className="right-area">
              {/* {user?.content?.memberStatus === MemberStatus.MEMBER_USE && isEditable === false && (
                <BaseButton
                  title="수정"
                  onClick={() => setIsEditable(true)}
                  className="size-large"
                />
              )}
              {user?.content?.memberStatus === MemberStatus.MEMBER_USE && isEditable === true && (
                <BaseButton
                  title="상세"
                  className="color-white size-large ml10"
                  onClick={() => setIsEditable(false)}
                />
              )} */}
            </div>
          </div>
          <BaseModal
            isOpen={isDelete}
            btnLeftTitle={"취소"}
            btnRightTitle={"확인"}
            onClose={() => setIsDelete(false)}
            onClick={onDelete}
          >
            <div>
              <p>해당 정보를 삭제하시겠습니까?</p>
            </div>
          </BaseModal>

          <BaseModal isOpen={isDeleted} btnRightTitle={"확인"} onClick={onDeleted}>
            <div>
              <p>{success}</p>
            </div>
          </BaseModal>
          <BaseModal
            isOpen={isUpdateCheck}
            btnRightTitle={"확인"}
            onClick={() => setIsUpdateCheck(false)}
          >
            <div>
              <p>{message}</p>
            </div>
          </BaseModal>
          <BaseModal
            isOpen={isMaster}
            btnLeftTitle={"취소"}
            btnRightTitle={"확인"}
            onClose={() => setIsMaster(false)}
            onClick={() => onMasterToggle(true)}
          >
            <div>
              <p>마스터 지정하시겠습니까?</p>
              <p>
                해당 이용자는 마스터가 되면, 다른 이용자를 초대/삭제할 수 있는 권한을 가지게 됩니다.
              </p>
            </div>
          </BaseModal>
          <BaseModal
            isOpen={isNotMaster}
            btnLeftTitle={"취소"}
            btnRightTitle={"확인"}
            onClose={() => setIsNotMaster(false)}
            onClick={() => onMasterToggle(false)}
          >
            <div>
              <p>마스터 해제하시겠습니까?</p>
              <p>해당 이용자는 즉시 다른 이용자를 초대/삭제할 수 있는 권한이 없어지게 됩니다.</p>
            </div>
          </BaseModal>
          <BaseModal
            isOpen={isRetryInvite}
            btnLeftTitle={"취소"}
            btnRightTitle={"확인"}
            onClose={() => setIsRetryInvite(false)}
            onClick={() => retryInvite()}
          >
            <div>
              <p>재 초대를 하시겠습니까?</p>
            </div>
          </BaseModal>
        </div>
      </div>
    </div>
  );
};
export default UserDetail;
