import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import { useMemo, useRef, useState } from "react";
import { uploadFilesAsync } from "src/api/file/file-api";
import { useApiOperation } from "src/api/hooks";
import { Modal } from "src/api/public-types";
import { BaseModal } from "./BaseModal";

type Props = {
  value?: string;
  onChange?: Function;
  errorText?: string;
};

const s3Url = process.env.REACT_APP_S3_BASEURL;

const MarkdownEditor = (props: Props) => {
  const editorRef = useRef<Editor>(null);

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const colorSyntaxOptions = useMemo(() => {
    return {
      preset: [
        "#FF695B",
        "#26A687",
        "#FFD335",
        "#191919",
        "#525252",
        "#767676",
        "#999999",
        "#DBDBDB",
        "#E0E0E0",
        "#F2F4F6",
        "#F8F8FA",
        "#FFFFFF",
      ],
    };
  }, []);

  // 파일 업로드 api hook
  const { executeAsync: postImageFile } = useApiOperation(uploadFilesAsync, {
    doNotErrorHandleModal: true,
  });

  const onChangeValue = () => {
    const editorInstance = editorRef.current?.getInstance();

    // editor value를 markdown 형식으로 onChnage
    const markDown = editorInstance?.getMarkdown();
    if (props.onChange) {
      props.onChange(markDown);
    }
  };

  return (
    <div className="base-markdown">
      {/* @ts-ignore*/}
      <Editor
        ref={editorRef}
        initialValue={props?.value || " "}
        previewStyle="tab"
        height="600px"
        initialEditType="wysiwyg"
        useCommandShortcut={true}
        // plugins={[colorSyntax]}
        plugins={[[colorSyntax, colorSyntaxOptions]]}
        onChange={onChangeValue}
        usageStatistics={false}
        hooks={{
          addImageBlobHook: async (blob, callback) => {
            // console.log("blob", blob);
            // if (blob) {
            //   setAlertModal({
            //     isOpen: true,
            //     message: `파일업로드 기능은 현재 "준비중" 입니다. \n\n URL 링크 기능을 사용하여 주세요.`,
            //   });
            // }

            // 파일 업로드시 S3 로 업로드 하는 기능 (현재 비활성화)
            const formData = new FormData();
            formData.append("file", blob);
            const response = await postImageFile({
              file: formData,
              type: "public",
            });
            if (response.status >= 200 && response.status <= 299) {
              callback(`${s3Url + (response.data.data.media.key || "")}`);
            }
          },
        }}
      />
      {props.errorText && <p className="validation-text">{props.errorText}</p>}

      <>
        <BaseModal
          isOpen={alertModal.isOpen}
          btnRightTitle="확인"
          onClick={() => setAlertModal({ isOpen: false })}
        >
          <p className="pre-formatted">{alertModal.message}</p>
        </BaseModal>
      </>
    </div>
  );
};

export default MarkdownEditor;
