import { Sort } from "../public-types";

export type IPriceRuleType =
  | "PRICE_RULE_UNRECOGNIZED"
  | "PRICE_RULE_PROVIDER"
  | "PRICE_RULE_FACILITY"
  | "PRICE_RULE_SPECIFIC";

export const PriceRuleType = {
  PRICE_RULE_UNRECOGNIZED: "PRICE_RULE_UNRECOGNIZED", // 정의되지 않은 상태
  PRICE_RULE_PROVIDER: "PRICE_RULE_PROVIDER", // 프로바이더별 룰
  PRICE_RULE_FACILITY: "PRICE_RULE_FACILITY", // 프로바이더 공용시설별 요금 룰
  PRICE_RULE_SPECIFIC: "PRICE_RULE_SPECIFIC", // 세부요금 룰
} as const;
export const PriceRuleTypeToText = {
  PRICE_RULE_UNRECOGNIZED: "-", // 정의되지 않은 상태
  PRICE_RULE_PROVIDER: "기준요금", // 프로바이더별 룰
  PRICE_RULE_FACILITY: "기준요금", // 프로바이더 공용시설별 요금 룰
  PRICE_RULE_SPECIFIC: "개별요금", // 세부요금 룰
} as any;
export const PriceRuleOptionTypeToText = {
  PRICE_RULE_UNRECOGNIZED: "-", // 정의되지 않은 상태
  PRICE_RULE_PROVIDER: "프로바이더", // 프로바이더별 룰
  PRICE_RULE_FACILITY: "공용공간", // 프로바이더 공용시설별 요금 룰
  PRICE_RULE_SPECIFIC: "-", // 세부요금 룰
} as any;
/*
  공용공간 요금 목록
*/
export interface PriceModel {
  id?: string;
  providerId?: string;
  buildingCommonFacilityId?: string; // 건물 공용시설 ID
  priceRuleType?: IPriceRuleType;
  name?: string; // 룰 이름
  startDate?: string;
  endDate?: string;
  price?: number; // 판매가격
  cost?: number; // 원가
  isDeleted?: boolean;
  createdBy?: string;
  createdDate?: string;
  modifiedBy?: string;
  startTime?: string; // 시작시간(30분단위)
  endTime?: string; // 종료시간(30분단위)
  modifiedDate?: string;
  partnerId?: string;
}

/*
  공용공간 요금 쿼리파람
*/
export interface PriceParams {
  partnerId?: string;
  keywordList?: string;
  searchType?: string;
  providerId?: string;
  buildingId?: string;
  priceRuleIdList?: string;
  buildingCommonFacilityId?: string;
  page?: string;
  size?: string;
  sort?: Sort;
}
