import { useContext } from "react";
import { LoadingBarContext } from "src/contexts/LoadingBarContext";

/* 
  로딩바 context hook

  사용예시
  import useLoadingBarContext from "src/pages/hooks/loading-bar-context";
  const { setLoadingBar } = useLoadingBarContext();

  setLoadingBar(true); // 로딩바 보이게
  ..
  setLoadingBar(false); // 로딩바 안보이게
*/
export default function useLoadingBarContext() {
  const { setVisible } = useContext(LoadingBarContext);
  return { setLoadingBar: setVisible };
}
