import React from "react";
import { ProviderMediaFile, ProviderModel } from "src/api/provider/provider-types";
import { BaseTooltip } from "src/components";
import TextNFileDownload from "src/pages/organization/detail/components/TextNFileDownload";
import { Partner } from "src/types/partner";
import { companyRegistrationHypen } from "src/utils";

type Props = {
  partner?: Partner;
  providerBasic?: ProviderModel;
  contractMedia?: Array<ProviderMediaFile>;
};

const ProviderBasicDetail = ({ providerBasic, contractMedia, partner }: Props) => {
  return (
    <article className="pb30 contents-container__1200">
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>구분</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{providerBasic?.supplyType === "RENTAL" ? "프로바이더" : "관리처"}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>법인명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{providerBasic?.providerName}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>사업자 등록번호</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>
              {companyRegistrationHypen(String(providerBasic?.businessRegistrationNumber))}
            </span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>대표자 성명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{providerBasic?.representative}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>주소</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{providerBasic?.address}</span>
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">업태</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{providerBasic?.bizType}</span>
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">종목</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{providerBasic?.bizClass}</span>
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          {/* <p>사업자등록증 사본</p> */}
          <TextNFileDownload fileList={contractMedia} title="사업자등록증 사본" />
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">생성일</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <BaseTooltip contents={providerBasic?.createdDate} type="date" />
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">최종 수정일</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <BaseTooltip contents={providerBasic?.modifiedDate} type="date" />
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">최종 작성자</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{providerBasic?.modifiedBy}</span>
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>파트너</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{partner?.name && partner?.code && `${partner?.name} (${partner.code})`}</span>
          </div>
        </div>
      </section>
    </article>
  );
};

export default ProviderBasicDetail;
