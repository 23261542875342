import { useNavigate } from "react-router-dom";
import { BaseButton } from "src/components";
import LogTable from "src/components/log/LogTable";
import { PagePath } from "src/pages/product/details";

const PartnerMemberLog = () => {
  const navigate = useNavigate();
  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents">
        <div className="mb10">
          <h2></h2>
        </div>
        <LogTable type="PARTNER_MEMBER" />
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title={"목록"}
            onClick={() => navigate(PagePath.partnerMember.list)}
            className="color-white size-large"
          />
        </div>
        <div className="right-area"></div>
      </div>
    </div>
  );
};

export default PartnerMemberLog;
