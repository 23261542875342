import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { BaseButton, BaseInput, BaseSelect } from "src/components";
import { DeskSpace, deskSpaceTypeOptions, SelectOption } from "src/pages/building/building-types";

type Props = {
  deskSpace?: DeskSpace;
  saveDeskSpace: Function;
  setDeskSpace: Function;
  deskSpaces?: Array<DeskSpace>;
  rangeFloorOptions: Array<SelectOption>;
};

// 좌석 그룹 테이블 컴포넌트
const SaveDeskGroup = ({
  deskSpace,
  setDeskSpace,
  saveDeskSpace,
  deskSpaces,
  rangeFloorOptions,
}: Props) => {
  // useForm defaultValues
  const defaultValues: DeskSpace = useMemo(() => {
    return {
      type: "GROUP",
      deskGroupId: undefined,
      deskId: undefined,
      floor: "1",
      deskGroupName: undefined,
      deskGroupDescription: undefined,
      deskName: undefined,
      eslAddress: undefined,
    };
  }, []);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<DeskSpace>({
    defaultValues,
  });

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    register("floor", {
      required: "위치는 필수입력항목입니다.",
    });
    register("deskGroupName", {
      required: "좌석 그룹 명은 필수입력항목입니다.",
    });
  }, [register, deskSpaces]);

  // 수정시 api 에서 받아온 deskSpace 정보로 setValue 처리
  useEffect(() => {
    if (deskSpace) {
      Object.entries(deskSpace).forEach(([name, value]: any) => {
        if (name === "floor") {
          // 규모 범위 안에 존재하지 않는 층이면 null 로 바인딩되서 선택되어있지 않게 처리
          const isFindFloorOption = !!rangeFloorOptions.find(
            (option: SelectOption) => option.value === value,
          );
          setValue(name, isFindFloorOption ? value : null);
        } else {
          setValue(name, value);
        }
      });
    }
  }, [setValue, deskSpace, rangeFloorOptions]);

  useEffect(() => {
    // 수정 버튼으로 deskSpace 변경시 validation error clear
    clearErrors();
  }, [deskSpace, clearErrors]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: any, e?: any) => {
    e.preventDefault();
    saveDeskSpace(data);
    return false;
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    // console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  // 취소 버튼클릭시 층 form 리셋
  const resetForm = () => {
    setDeskSpace({ type: "GROUP" });

    clearErrors();
    for (const [name, value] of Object.entries(defaultValues)) {
      setValue(name as keyof DeskSpace, value);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <table
        className={errors && Object.keys(errors).length > 0 ? "inner-table" : "inner-table mb20"}
        width="940"
      >
        <thead>
          <tr>
            <th>
              <span>구분</span>
            </th>
            <th>
              <span>id</span>
            </th>
            <th>
              <span className="required">위치</span>
            </th>
            <th>
              <span className="required">좌석 그룹 명</span>
            </th>
            <th>
              <span>설명</span>
            </th>
            <th>
              <div></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width="150px">
              <BaseSelect
                value={deskSpace?.type}
                stateOptions={deskSpaceTypeOptions}
                setStateValue={(type: string) => {
                  setDeskSpace({ type });
                }}
              />
            </td>
            <td width="100px">
              <Controller
                control={control}
                name="deskGroupId"
                render={({ field: { onChange, value, name } }) => (
                  <BaseInput type="text" readonly value={value} name={name} onChange={onChange} />
                )}
              ></Controller>
            </td>
            <td width="160px">
              <Controller
                control={control}
                name="floor"
                render={({ field: { onChange, value, name } }) => (
                  <BaseSelect
                    stateOptions={rangeFloorOptions}
                    setStateValue={onChange}
                    value={value}
                    name={name}
                  />
                )}
              ></Controller>
            </td>
            <td width="200px">
              <Controller
                control={control}
                name="deskGroupName"
                render={({ field: { onChange, value, name } }) => (
                  <BaseInput type="text" value={value} name={name} onChange={onChange} />
                )}
              ></Controller>
            </td>
            <td width="230px">
              <Controller
                control={control}
                name="deskGroupDescription"
                render={({ field: { onChange, value, name } }) => (
                  <BaseInput type="text" value={value} name={name} onChange={onChange} />
                )}
              ></Controller>
            </td>
          </tr>
        </tbody>
      </table>
      {errors && Object.keys(errors).length > 0 && (
        <p className="validation-text mb20">{Object.entries(errors)[0][1]?.message}</p>
      )}
    </form>
  );
};

export default SaveDeskGroup;
