import React, { useCallback } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldNamesMarkedBoolean,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  useWatch,
} from "react-hook-form";
import { BaseCheckbox, BaseInput, BaseRadio } from "src/components";
import { FacilityFormData, PrinterOption } from "../facility-types";

type Props = {
  control: Control<FacilityFormData>;
  getValues: UseFormGetValues<FacilityFormData>;
  setValue: UseFormSetValue<FacilityFormData>;
  defaultValues: FacilityFormData;
  errors: FieldErrors;
  trigger: UseFormTrigger<FacilityFormData>;
  dirtyFields: FieldNamesMarkedBoolean<FacilityFormData>;
};

/* 
  공간상품 > 등록 or 수정 > 시설/서비스 > 복합기 컴포넌트
*/
const PrinterSection = ({
  control,
  getValues,
  setValue,
  defaultValues,
  errors,
  trigger,
  dirtyFields,
}: Props) => {
  // 복합기 옵션
  const printerOptionList: Array<PrinterOption> = useWatch({
    control,
    name: "printerOptionList",
  });

  // 복합기 옵션 체크박스 선택 변경
  const changeCheckedPrinterOption = useCallback(
    (metaItem: string, checked: boolean) => {
      const newPrinterOptionList = [...printerOptionList];
      for (let i = 0; i < newPrinterOptionList.length; i++) {
        if (newPrinterOptionList[i].metaItem === metaItem) {
          newPrinterOptionList[i].checked = checked;
        }
      }
      setValue("printerOptionList", newPrinterOptionList, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [printerOptionList, setValue],
  );

  return (
    <section className="contents-container__grid contents-container__1200 mb30">
      <div className="contents-container__grid-index">
        <p className="required">복합기</p>
      </div>
      <div className="contents-container__grid-contents">
        <table className="inner-table" width="100%">
          <tbody>
            <tr>
              <td className="bg-gray text-center" width="140">
                <span className="text-primary3 no-wrap">제공 여부</span>
              </td>
              <td>
                <div className="flex-center px16">
                  <Controller
                    control={control}
                    name="isPrinterProvided"
                    render={({ field: { onChange, value, name } }) => (
                      <>
                        <BaseRadio
                          id="isPrinterProvided_TRUE"
                          name={name}
                          value={"true"}
                          label="제공"
                          className="mr16"
                          checked={Boolean(String(value) === "true")}
                          onChange={(checked: boolean, e) => {
                            onChange(e.target.value);
                            if (dirtyFields?.isPrinterProvided) {
                              // 제공여부 값이 바뀐적이 있거나 validation 이 작동한적이있으면 옵션 validation trigger
                              trigger("printerOptionList");
                            }
                          }}
                        />
                        <BaseRadio
                          id="isPrinterProvided_FALSE"
                          name={name}
                          value={"false"}
                          label="제공하지 않음"
                          checked={Boolean(String(value) === "false")}
                          onChange={(checked: boolean, e) => {
                            // 복합기 제공여부 변경
                            onChange(e.target.value);
                            // 복합기 옵션 reset
                            setValue("printerOptionList", defaultValues.printerOptionList, {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                            // 복합기 추가설명 reset
                            setValue("printerDescription", "");
                          }}
                        />
                      </>
                    )}
                  ></Controller>
                </div>
              </td>
            </tr>
            {/* 복합기 제공여부가 제공일 경우만 노출 */}
            {String(getValues("isPrinterProvided")) === "true" && (
              <>
                <tr>
                  <td className="bg-gray text-center" width="140">
                    <span className="text-primary3 no-wrap">옵션</span>
                  </td>
                  <td>
                    <div className="flex-center px16">
                      {printerOptionList.map((option: PrinterOption, index: number) => (
                        <BaseCheckbox
                          key={index.toString()}
                          id={option.metaItem}
                          name={option.metaItem}
                          label={option.label}
                          className={index !== 3 ? "mr16" : ""}
                          checked={option.checked}
                          onChange={(checked: boolean) =>
                            changeCheckedPrinterOption(option.metaItem, checked)
                          }
                        />
                      ))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="bg-gray text-center" width="140">
                    <span className="text-primary3 no-wrap">추가 설명</span>
                  </td>
                  <td>
                    <div className="w-100 pl16">
                      <Controller
                        control={control}
                        name="printerDescription"
                        render={({ field: { onChange, value, name } }) => (
                          <BaseInput onChange={onChange} value={value} name={name} />
                        )}
                      ></Controller>
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
        {/* validation error message */}
        {errors && errors.printerOptionList && (
          <p className="validation-text mb20">{errors.printerOptionList?.message}</p>
        )}
      </div>
    </section>
  );
};
export default PrinterSection;
