import { useEffect, useState } from "react";
import { MbOrgMedia } from "src/api/organization/org-types";
import { BaseRadio } from "src/components/BaseRadio";
import { BaseTooltip } from "src/components/BaseTooltip";
import { ResizedImage } from "src/components/ResizedImage";

const ImagesDetail = ({ logo }: { logo: MbOrgMedia[] }) => {
  const [array, setArray] = useState<MbOrgMedia[]>([]);

  useEffect(() => {
    if (logo.length > 0) {
      setArray(logo || []);
    }
  }, [logo, setArray]);

  return (
    <div className="flex-row justify-contents-start align-items-center overflow-scroll">
      <div className="image-scroll">
        {array.map((item: any, idx) => {
          return (
            <div
              className={`image-container detail`}
              key={idx}
              onClick={() => {
                // 이미지 원본 링크 새창 열기
                if (item.url) {
                  window.open(item.url, "_blank");
                }
              }}
            >
              <div className="image-container__img-wrap">
                <span className="index-number">{idx + 1}</span>
                <ResizedImage url={item.url} alt={item.filename} />
                {item.isPrimary && (
                  <BaseRadio
                    id={idx.toString()}
                    name="president"
                    className="president"
                    checked={true}
                  />
                )}
              </div>
              <div className="desc-wrap">
                <div className="font13 mt8 ">
                  <BaseTooltip contents={item.filename} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ImagesDetail;
