// 워크오더 리스트 부분

import { SearchMemberType } from "src/api/member/member-types";
import { Sort } from "src/api/public-types";
import { WorkOrderAddModel } from "src/api/workOrder/workOrder-types";

//
export const CmdType = {
  C: "C", // 등록
  U: "U", // 수정
  D: "D", // 삭제
} as const;

export type UnionCmdType = typeof CmdType[keyof typeof CmdType];

// 리스트 노출 개수
export const workListSelectOptions = [
  { value: 20, label: "20" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

// 검색 대상
export const searchTypes = [
  { value: "ALL", label: "전체" },
  { value: "SUBJECT", label: "제목" },
  { value: "OFFICER_NAME", label: "담당자" },
  { value: "CREATED_BY", label: "등록자" },
  { value: "NUMBER", label: "워크시트 번호" },
  { value: "GROUP_ID", label: "그룹 ID" },
];

// 반복요일 타입
export const DayOfWeekType = {
  MON: "MON",
  TUE: "TUE",
  THU: "THU",
  WED: "WED",
  FRI: "FRI",
  SAT: "SAT",
  SUN: "SUN",
} as const;

export type UnionDayOfWeekType = typeof DayOfWeekType[keyof typeof DayOfWeekType];

// 반복 요일
export const dayOptions = [
  { value: DayOfWeekType.MON, label: "월요일" },
  { value: DayOfWeekType.TUE, label: "화요일" },
  { value: DayOfWeekType.THU, label: "수요일" },
  { value: DayOfWeekType.WED, label: "목요일" },
  { value: DayOfWeekType.FRI, label: "금요일" },
  { value: DayOfWeekType.SAT, label: "토요일" },
  { value: DayOfWeekType.SUN, label: "일요일" },
];

// 반복 주기

export const RecurrenceType = {
  UNRECOGNIZED: "UNRECOGNIZED",
  SINGLE: "SINGLE",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
};

export type UnionRecurrenceType = typeof RecurrenceType[keyof typeof RecurrenceType];

export const cycleOptions = [
  {
    value: RecurrenceType.DAILY,
    label: "매일",
  },
  {
    value: RecurrenceType.WEEKLY,
    label: "매주",
  },
  {
    value: RecurrenceType.MONTHLY,
    label: "매월",
  },
];

// 업무구분 타입
export const WorkOrderType = {
  SPACE: "WORK_ORDER_SPACE",
  SERVICE: "WORK_ORDER_SERVICE",
} as const;

export type UnionWorkOrderType = typeof WorkOrderType[keyof typeof WorkOrderType];

// 업무구분 옵션
export const workTypeOptions = [
  { value: WorkOrderType.SERVICE, label: "서비스 관리" },
  { value: WorkOrderType.SPACE, label: "공간 관리" },
];

// 워크오더 우선순위 옵션
export const workOrderPriorityOptions = [
  { value: 1, label: "낮음" },
  { value: 2, label: "보통" },
  { value: 3, label: "높음" },
  { value: 4, label: "긴급" },
];

// 회원여부 타입
export const ReporterType = {
  GUEST: "REPORTER_GUEST",
  MEMBER: "REPORTER_MEMBER",
} as const;

export type UnionReporterType = typeof ReporterType[keyof typeof ReporterType];

// 워크오더 접수자 팝업 - 접수자 유형 options
export const reporterOptions = [
  {
    value: ReporterType.MEMBER,
    label: "회원",
  },
  {
    value: ReporterType.GUEST,
    label: "비회원",
  },
];

// 워크오더 접수자 팝업 - 회원 검색 options
export const MemberSearchTypeOptions = [
  { label: "전체", value: "" },
  { label: "이메일", value: SearchMemberType.CONTAINS_EMAIL },
  { label: "휴대폰번호", value: SearchMemberType.CONTAINS_PHONE_NUMBER },
  { label: "회원번호", value: SearchMemberType.CONTAINS_MEMBER_NUMBER },
];

// 워크오더 상태 타입
export const WorkOrderStatus = {
  OPEN: "WORK_ORDER_OPEN",
  PROGRESS: "WORK_ORDER_PROGRESS",
  COMPLETED: "WORK_ORDER_COMPLETED",
  CLOSED: "WORK_ORDER_CLOSED",
  WAITING: "WORK_ORDER_WAITING",
  CANCELED: "WORK_ORDER_CANCELED",
} as const;

// 워크오더 미디어 타입
export const WorkOrderMediaType = {
  WORK: "MEDIA_WORK_ORDER_SHEET", //워크오더>워크시트>업무내용
  COMPLETED: "MEDIA_WORK_ORDER_SHEET_COMPLETED_REPORT", //워크오더>워크시트>처리내용
  COST: "MEDIA_WORK_ORDER_SHEET_COST", //워크오더>워크시트>처리비용
};

// Front type
export interface FrontWorkOrderUpdateModel extends WorkOrderAddModel {
  id?: number;
  workOrderSheetGroupId?: string | null;
  customerNotification?: string; // 고객알림내용
  completionReport?: string; //처리내용
  workOrderSheetCostList?: Array<FrontWorkOrderSheetCostType>;
  workOrderSheetNo?: string;
  status?: string;
  isReopened?: boolean;
  createdDate?: string;
}
export interface FrontWorkOrderSheetCostType {
  id?: string; //워크시트 처리비용 id
  description?: string; //비용 발생 사유
  cost?: number; // 금액
  cmdType?: UnionCmdType; // 요청타입
  apiId?: string;
}

// 워크오더 상태
export const workOrderStatus = [
  { value: "", label: "전체" },
  {
    value: WorkOrderStatus.OPEN,
    label: "접수",
  },
  {
    value: WorkOrderStatus.WAITING,
    label: "확인",
  },
  {
    value: WorkOrderStatus.PROGRESS,
    label: "처리중",
  },
  {
    value: WorkOrderStatus.COMPLETED,
    label: "처리완료",
  },
  {
    value: WorkOrderStatus.CLOSED,
    label: "처리종료",
  },

  {
    value: WorkOrderStatus.CANCELED,
    label: "취소",
  },
];

export type KoreanDays =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";

export type RevisionType = "INSERT" | "UPDATE" | "DELETE";

export type WorkOrderRevisionParams = {
  page?: number;
  size?: number;
  id?: number;
  sort?: Sort;
};

export const workOrderTooltips = {
  alam: [
    "업무 시작일이 오늘 또는 내일이면 알림톡이 즉시 발송됩니다.",
    "업무 시작일이 내일 이후면 업무 시작일 하루전 오후 5시에 알림톡이 발송됩니다.",
    "업무 진행 중이면 담당자 변경시 변경된 담당자에게 알림톡이 즉시 발송됩니다.",
  ],
  reporter: `VOC를 접수한 고객의 정보를 조회합니다. \n 휴대폰 번호로 고객 답변 알림톡을 발송할 수 있습니다.`,
  holiday: [
    "포함 선택시, 주말/공휴일을 포함하여 반복일정을 생성합니다.",
    "포함 미선택시, 반복 일정의 업무 시작일을 주말/공휴일을 제외한 가장 빠른 날짜로 지정합니다.",
  ],
};
