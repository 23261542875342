import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import pagePath from "src/pagePath.json";
import { useTitleOperation } from "../hooks";

const Login = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(pagePath.main);
    }
  }, [isAuthenticated, navigate]);

  useTitleOperation("로그인");
  return (
    <div className="page-login">
      <div className="grid-login">
        <div>
          <div className="grid-login__logo"></div>
          <div>
            <h1>로그인</h1>
            <p>사업자 구글 계정을 통해 로그인해주세요.</p>
            <button className="login-btn" onClick={loginWithRedirect}>
              Login
            </button>
          </div>
          <p>*권한이 없으신 분은 서비스 기획팀에 요청해주세요.</p>
        </div>
      </div>
      <div className="grid-bg"></div>
    </div>
  );
};

export default Login;
