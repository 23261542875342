import React, { FocusEventHandler, KeyboardEventHandler } from "react";

interface BaseInputState {
  placeholder?: string;
  type?: string;
  readonly?: boolean;
  onChange?: Function;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  className?: string;
  disabled?: boolean;
  value?: string | number;
  name?: string;
  errorText?: string;
  onKeyUp?: Function;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  maxLength?: number;
  validation?: boolean;
  onSearchClick?: Function;
  onIconClick?: Function;
  rightIconClassName?: string;
  onClearClick?: Function;
  inputRef?: any;
}

export const BaseInput = (props: BaseInputState) => {
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      const targetValue = e.target.value;
      if (props.type === "number" && props.maxLength) {
        // type number 일때 maxLength 안먹혀서 추가
        if (targetValue.length > props.maxLength) {
          const sliceValue = targetValue.slice(0, props.maxLength);
          props.onChange(sliceValue, e);
        } else {
          props.onChange(targetValue, e);
        }
      } else {
        props.onChange(targetValue, e);
      }
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.key === "Enter") {
      if (props.onKeyUp) {
        props.onKeyUp();
      }
    }
  };

  const onSearchClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.onSearchClick) {
      props.onSearchClick();
    }
  };

  const onIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.onIconClick) {
      props.onIconClick();
    }
  };

  const onClearClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.onClearClick) {
      props.onClearClick();
    }
  };

  return (
    <div
      className={`base-input ${props.className ? props.className : ""} ${
        props.onSearchClick ? "base-search" : ""
      } ${props.onIconClick ? "base-right-icon" : ""}`}
    >
      {props.type !== "number" && String(props?.value || "").length > 0 && props.onClearClick && (
        <button className="base-clear-btn" onClick={onClearClick}></button>
      )}
      <input
        ref={props?.inputRef}
        type={props.type}
        name={props.name}
        className={``}
        placeholder={props.placeholder}
        onChange={onChangeValue}
        onKeyUp={onKeyUp}
        onKeyDown={props.onKeyDown}
        onBlur={(e) => {
          props.onChange && props.onChange(e.target.value.trim(), e);
          props.onBlur && props.onBlur(e);
        }}
        value={props.value || ""}
        readOnly={props.readonly ? true : false}
        maxLength={props.maxLength || 100}
        disabled={props.disabled ? true : false}
      />

      {props.onSearchClick && <button className="base-search-btn" onClick={onSearchClick}></button>}
      {props.onIconClick && (
        <button
          className={`base-icon-btn ${props.rightIconClassName ? props.rightIconClassName : ""}`}
          onClick={onIconClick}
        ></button>
      )}
      {props.errorText && <p className="validation-text">{props.errorText}</p>}
    </div>
  );
};
