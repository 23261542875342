import { useCallback, useEffect, useMemo, useState } from "react";
import { uploadMediaFilesAsync } from "src/api/file/file-api";
import { useApiOperation } from "src/api/hooks";
import { MediaFile, MediaGroup } from "src/api/public-types";

interface MediasData {
  mediaList: MediaFile[];
}

const useMediasUpdate = ({ mediaList = [] }: MediasData) => {
  const [requestMedias, setRequestMedias] = useState<MediaGroup[]>([]);
  // REM02. 공통 미디어파일 등록/수정/삭제
  const { executeAsync: uploadMediaFiles } = useApiOperation(uploadMediaFilesAsync);
  useEffect(() => {
    let result = [];
    let newMedias: MediaGroup = { mediaList: [], cmdType: "C" };
    // let editMedias: MediaGroup = { mediaList: [], cmdType: "U" };
    let removeMedias: MediaGroup = { mediaList: [], cmdType: "D" };

    if (mediaList.length > 0) {
      mediaList.forEach((media) => {
        if (!media.id) {
          newMedias.mediaList.push(media);
        }

        // if (media.cmdType === "U") {
        //   delete media.cmdType;
        //   editMedias.mediaList.push(media);
        // }

        if (media.isRemoved) {
          removeMedias.mediaList.push(media);
        }
      });
    }

    result = [newMedias, removeMedias];

    if (newMedias.mediaList.length === 0) {
      result = result.filter((item) => item.cmdType !== "C");
    }

    if (removeMedias.mediaList.length === 0) {
      result = result.filter((item) => item.cmdType !== "D");
    }

    setRequestMedias(result);
  }, [mediaList]);

  const updateMedias = useCallback(
    async (serviceId?: string, partnerId?: string) => {
      const sendMediasData = requestMedias.map((media) => {
        if (media.cmdType === "C" && serviceId) {
          media.mediaList = media.mediaList.map((item: MediaFile & { blobUrl?: string }) => {
            if (item.blobUrl) {
              delete item.blobUrl;
            }
            return { ...item, partnerId: partnerId, serviceIdList: [serviceId] };
          });
        } else {
          // 미디어 삭제시, 임시로 사용한 isRemoved 데이터 삭제 후 api 요청
          media.mediaList = media.mediaList.map((item: MediaFile) => {
            delete item.isRemoved;

            return item;
          });
        }
        return media;
      });
      return await uploadMediaFiles(sendMediasData);
    },
    [requestMedias, uploadMediaFiles],
  );

  return { requestMedias, updateMedias };
};

export default useMediasUpdate;
