import _ from "lodash";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getScheduleDetail,
  getScheduleRoundNOverdueList,
  getScheduleStop,
} from "src/api/billingSchedule/billingSchedule-api";
import {
  getContractApplicationInfo,
  getContractDetailBasicInfo,
} from "src/api/contract/contract-api";
import { errorDataHandler, errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderAddModel } from "src/api/provider/provider-types";
import { building_product_errors, contract_bill_user_errors } from "src/api/public-types";
import { ContentsTitle } from "src/components";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import BillingAdminMemo from "./components/adminMemo/billingAdminMemo";
import ScheduleBasicInfo from "./components/basicInfo/ScheduleBasicInfo";
import ScheduleInfo from "./components/billingScheduleInfo/ScheduleInfo";
import MaintenanaceBillingForm from "./components/maintenanaceBillingForm/MaintenanaceBillingForm";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";

const tabs = [
  { value: "basicInfo", label: "빌링스케쥴 정보" },
  { value: "schedule", label: "이용료 빌링/연체 현황" },
  { value: "maintenance", label: "관리비 빌링/연체 현황" },
  { value: "adminMemo", label: "관리자 메모" },
];

const BillingScheduleDetail = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [scheduleId, setScheduleId] = useState(0);
  const [basicInfo, setBasicInfo] = useState<any>();
  const [application, setApplication] = useState<any>();
  const [roundList, setRoundList] = useState<any>([]);
  const [overdueList, setOverdueList] = useState<any>([]);
  const [contractBaseInfo, setContractBaseInfo] = useState<any>({});

  const [schedule, setSchedule] = useState<any>();
  const [scheduleMntList, setScheduleMntList] = useState<any>();

  const [spaceProductType, setSpaceProductType] = useState("");
  const [providerDetail, setProviderDetail] = useState<ProviderAddModel>();
  const [editable, setEditable] = useState(false);
  const [partner, setPartner] = useState<any>({});

  const { executeAsync } = useApiOperation(getScheduleDetail, { doNotErrorHandleModal: true });
  // const { executeAsync: getProviderDetail } = useApiOperation(, { doNotErrorHandleModal: true });
  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundNOverdueList, {
    doNotErrorHandleModal: true,
  });

  const { executeAsync: contractBasicInfo } = useApiOperation(getContractDetailBasicInfo, {
    doNotErrorHandleModal: true,
  });

  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  const providerDetailApi = useCallback(
    async (id: string) => {
      // setLoadingBar(true);

      const response: any = await getProviderDetail({ providerId: id });

      if (response.status >= 200 || response.status <= 299) {
        const result = response.data.data;
        setProviderDetail(result);
      }
      // setLoadingBar(false);
    },
    [getProviderDetail],
  );

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      // console.log("clickTab", tab);
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      let path = location.pathname;
      newQueryParams.tab = tab.value;
      if (tab.value === "basicInfo") {
        path = PagePath.billing.detail.replace(":id", String(id));
      }
      console.log("location.pathname", location, id, newQueryParams);
      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(path + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      // console.log("clickBreadCrumb", crumb);
      if (crumb.value === "billing") {
        navigate(PagePath.billing.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "billing", label: "빌링스케쥴 상세" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  const { executeAsync: scheduleCheckStop } = useApiOperation(getScheduleStop, {
    doNotErrorHandleModal: true,
  });
  const [stopObj, setStopObj] = useState<any>();
  const checkStop = useCallback(
    async (id: number) => {
      // console.log("scheduleId", id);
      const res: any = await scheduleCheckStop({ scheduleId: Number(id) });
      // console.log("scheduleCheckStop", res);
      if (res.status >= 200 && res.status <= 299) {
        setStopObj(res.data.data);
        if (res.data.data.isContractCancel === "true") {
        }
      } else {
        let message = errorMessageHandler(
          res.status,
          res.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.status;
        openErrorModal(message, code);
      }
    },
    [openErrorModal, scheduleCheckStop],
  );

  const callList = useCallback(
    async (id: number) => {
      if (id !== 0) {
        // setLoadingBar(true);
        const response: any = await getRoundList({ scheduleId: Number(id) });
        console.log("scheduleList", response.data.data);
        if (response && response.status >= 200 && response.status <= 299) {
          const sorted = _.sortBy(response.data.data.content, ["bill.billOrder"]);

          const normal = sorted.filter((sort) => {
            if (sort.bill.isOverdueBill === false) {
              return sort;
            }
          });
          const overdues = sorted.filter((sort) => {
            if (sort.bill.isOverdueBill) {
              return sort;
            }
          });
          // console.log("overdues", overdues);
          setRoundList(normal);
          setOverdueList(overdues);
        } else {
          let message = errorMessageHandler(
            response.status,
            response.data.meta.errorCode,
            contract_bill_user_errors,
          );
          let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
          openErrorModal(message, code);
        }
        // setLoadingBar(false);
      }
    },

    [getRoundList, openErrorModal],
  );

  const { executeAsync: getApplication } = useApiOperation(getContractApplicationInfo, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  const fetchPartner = useCallback(
    async (id: string) => {
      const result = await getPartnerAsync({ id });

      if (result.status > 199 && result.status < 300) {
        setPartner(result.data.data.partner);
      } else {
        let { message, code, errorData }: any = errorDataHandler(result, building_product_errors);

        openErrorModal(message, code, errorData);
      }
    },
    [getPartnerAsync, openErrorModal],
  );

  const fetchBillDate = useCallback(
    async (id: number) => {
      setLoadingBar(true);
      const response: any = await executeAsync({ contractId: Number(id) });
      console.log("contract", response.data.data);
      if (response && response.status >= 200 && response.status <= 299) {
        setSpaceProductType(response.data.data.spaceProductType);
        if (response.data.data.billPayStatus === "PAYMENT_READY") {
          response.data.data.step = "대기";
        }
        if (response.data.data.billPayStatus === "PAYMENT_SUCCESS") {
          response.data.data.step = "성공";
        }
        if (response.data.data.billPayStatus === "PAYMENT_FAIL") {
          response.data.data.step = "실패";
        }
        if (response.data.data.billPayStatus === "CONTRACT_CANCEL") {
          response.data.data.step = "취소";
        }
        if (response.data.data.billPayStatus === "PAYMENT_CANCEL") {
          response.data.data.step = "중지";
        }

        const sd = response.data.data.scheduleList.find(
          (schedule: any) => schedule.supplyType === "RENTAL",
        );

        setSchedule(sd);
        console.log("sd", sd);
        const sdList = response.data.data.scheduleList.filter(
          (schedule: any) => schedule.supplyType !== "RENTAL",
        );
        setScheduleMntList(sdList);
        response.data.data.billkey = sd.billKey;
        setBasicInfo(response.data.data);
        setScheduleId(sd.scheduleId);
        await callList(sd.scheduleId);
        await checkStop(sd.scheduleId);
        await providerDetailApi(response.data.data.providerId);
      }

      const res: any = await contractBasicInfo({ id: Number(id) });

      if (res && res.status >= 200 && res.status <= 299) {
        if (
          res.data.data.contractStep === "USE_COMPLETE" ||
          res.data.data.contractStep === "APPLY_CANCEL" ||
          res.data.data.contractStep === "TERMINATE_COMPLETE"
        ) {
          setEditable(true);
        }
        setContractBaseInfo(res.data.data);
      } else {
        let message = errorMessageHandler(
          res.status,
          res.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.status;
        openErrorModal(message, code);
      }

      const res2: any = await getApplication({ id: Number(id) });
      // console.log("res2", res2);

      if (res2.status >= 200 && res2.status <= 299) {
        setApplication(res2.data.data);
        await fetchPartner(res2.data.data.partnerId);
      } else {
        let message = errorMessageHandler(
          res2.status,
          res2.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = res2.data.meta.errorCode ? res2.data.meta.errorCode : res2.status;

        openErrorModal(message, code);
      }

      setLoadingBar(false);
    },
    [
      callList,
      checkStop,
      contractBasicInfo,
      executeAsync,
      fetchPartner,
      getApplication,
      openErrorModal,
      providerDetailApi,
      setLoadingBar,
    ],
  );

  useEffect(() => {
    if (id) {
      fetchBillDate(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // title 에 건물명 바인딩
  let contractApplyNumber;
  if (contractBaseInfo?.contractApplyNumber) {
    contractApplyNumber = contractBaseInfo?.contractApplyNumber;
  }
  useTitleOperation(contractApplyNumber);

  return (
    <div className="page-contract-detail">
      <ContentsTitle
        title="빌링스케쥴 상세"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />
      <div className="contents-container__scroll">
        {activeTab?.value === "basicInfo" && (
          <ScheduleBasicInfo
            basicInfo={basicInfo}
            contractBaseInfo={contractBaseInfo}
            spaceProductType={spaceProductType}
            providerDetail={providerDetail}
            schedule={schedule}
            scheduleMntList={scheduleMntList}
            partner={partner}
            application={application}
          />
        )}
        {activeTab?.value === "schedule" && (
          <ScheduleInfo
            basicInfo={basicInfo}
            callList={callList}
            contractBaseInfo={contractBaseInfo}
            roundList={roundList}
            overdueList={overdueList}
            spaceProductType={spaceProductType}
            setLoadingBar={setLoadingBar}
            stopObj={stopObj}
            editable={editable}
            scheduleId={scheduleId}
          />
        )}

        {activeTab?.value === "maintenance" && (
          <MaintenanaceBillingForm
            basicInfo={basicInfo}
            contractBaseInfo={contractBaseInfo}
            scheduleMntList={scheduleMntList}
            stopObj={stopObj}
            editable={editable}
            scheduleId={scheduleId}
          />
        )}

        {activeTab?.value === "adminMemo" && (
          <AdminMemoIntegration
            serviceId={Number(id || queryParams.id)}
            serviceType={ServiceTypes.SERVICE_BILLING_SCHEDULE}
            viewOnly={false}
          />
          // <BillingAdminMemo basicInfo={basicInfo} contractBaseInfo={contractBaseInfo} />
        )}
      </div>
    </div>
  );
};

export default BillingScheduleDetail;
