import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BuildingModel, BuildingParkingLotModel } from "src/api/building/building-types";
import { postCommonfacilityAsync } from "src/api/building/commonfacility-api";
import { useApiOperation } from "src/api/hooks";
import {
  BaseButton,
  BaseInput,
  BaseModal,
  BaseSelect,
  BaseTextarea,
  BaseToggle,
} from "src/components";
import { Modal, parkingLotNumOptions } from "src/pages/building/building-types";
import { useLoadingBarContext, useToastContext } from "src/pages/hooks";

type Props = {
  building: BuildingModel | null;
  fetchBuilding: (buildingId: number) => void;
};

type FormData = {
  isUsedParking: boolean;
  id?: number;
  selfPropelledParkingLotNums: number;
  selfPropelledParkingLotTotalNums: number;
  mechanicalParkingLotNums: number;
  mechanicalParkingLotTotalNums: number;
  description: string;
};

// 주차 컴포넌트
const ParkingSection = ({ building, fetchBuilding }: Props) => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 토스트
  const { openToast } = useToastContext();

  // 건물 공용공간 관리(등록/수정/삭제) API
  const { executeAsync: postCommonfacility } = useApiOperation(postCommonfacilityAsync);

  // useForm defaultValues
  const defaultValues = useMemo(() => {
    return {
      isUsedParking: false,
      selfPropelledParkingLotNums: 0,
      selfPropelledParkingLotTotalNums: 0,
      mechanicalParkingLotNums: 0,
      mechanicalParkingLotTotalNums: 0,
      description: "",
    };
  }, []);

  const { control, handleSubmit, getValues, setValue, reset } = useForm<FormData>({
    defaultValues,
  });

  // 수정시 api 에서 받아온 building 정보로 setValue 처리
  useEffect(() => {
    if (building) {
      const parkingLotList = building?.buildingCommonFacility?.parkingLotList || [];
      if (parkingLotList.length > 0) {
        const parkingObj = parkingLotList[0];
        Object.entries(parkingObj).forEach(([name, value]: any) => {
          setValue(name, value);
        });
        setValue("isUsedParking", true);
      } else {
        reset(defaultValues);
        setValue("isUsedParking", false);
      }
    }
  }, [setValue, building, reset, defaultValues]);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 주차 저장
  const saveParking = useCallback(
    async (submitData: FormData) => {
      try {
        if (!building?.id) {
          throw Error("building.id is not found.");
        }
        setLoadingBar(true);

        // 주차장 외의 회의실/좌석/편의시설 기본 값
        const meetingRoomList = building?.buildingCommonFacility?.meetingRoomList || [];
        const deskSpace = {
          deskGroupList: building?.buildingCommonFacility?.deskSpace?.deskGroupList || [],
          mediaList: building?.buildingCommonFacility?.deskSpace?.mediaList || [],
        };
        const refreshRoomList = building?.buildingCommonFacility?.refreshRoomList || [];

        const buildingParkingLotModel: BuildingParkingLotModel = {
          buildingId: building.id,
          selfPropelledParkingLotNums: submitData?.selfPropelledParkingLotNums,
          selfPropelledParkingLotTotalNums: submitData?.selfPropelledParkingLotTotalNums,
          mechanicalParkingLotNums: submitData?.mechanicalParkingLotNums,
          mechanicalParkingLotTotalNums: submitData?.mechanicalParkingLotTotalNums,
          description: submitData?.description,
        };
        const { data: createCommonfacilityResultData } = await postCommonfacility({
          buildingId: building.id,
          parkingLotList: [buildingParkingLotModel],
          meetingRoomList,
          deskSpace,
          refreshRoomList,
        });
        if (createCommonfacilityResultData?.data?.content) {
          openToast("정상적으로 저장 되었습니다.");
        }
        setLoadingBar(false);

        // 건물 다시 상세조회
        await fetchBuilding(building.id);
      } catch (error) {
        console.error(error);
      }
    },
    [building, setLoadingBar, openToast, postCommonfacility, fetchBuilding],
  );

  // 주차 초기화 저장 (삭제)
  const deleteAllParking = useCallback(async () => {
    try {
      if (!building?.id) {
        throw Error("building.id is not found.");
      }
      setLoadingBar(true);

      // 주차장 외의 회의실/좌석/편의시설 기본 값
      const meetingRoomList = building?.buildingCommonFacility?.meetingRoomList || [];
      const deskSpace = {
        deskGroupList: building?.buildingCommonFacility?.deskSpace?.deskGroupList || [],
        mediaList: building?.buildingCommonFacility?.deskSpace?.mediaList || [],
      };
      const refreshRoomList = building?.buildingCommonFacility?.refreshRoomList || [];

      const { data: deleteCommonfacilityResultData } = await postCommonfacility({
        buildingId: building.id,
        parkingLotList: [], // 빈배열로 업데이트
        meetingRoomList,
        deskSpace,
        refreshRoomList,
      });
      if (deleteCommonfacilityResultData?.data?.content) {
        openToast("정상적으로 초기화 저장 되었습니다.");
      }
      setLoadingBar(false);

      // 건물 다시 상세조회
      await fetchBuilding(building.id);
    } catch (error) {
      console.error(error);
    }
  }, [building, setLoadingBar, openToast, postCommonfacility, fetchBuilding]);

  // Modal 확인 버튼 클릭
  const clickModalConfirm = useCallback(async () => {
    setConfirmModal({ isOpen: false });

    try {
      if (confirmModal.type === "PARKING_SAVE") {
        // 주차 저장
        if (!confirmModal?.payload) throw Error("confirmModal?.payload is not found.");
        await saveParking(confirmModal.payload);
      } else if (confirmModal.type === "PARKING_DELETE") {
        // 주차 초기화 저장 (삭제)
        await deleteAllParking();
      }
    } catch (error) {
      console.error(error);
    }
  }, [confirmModal, saveParking, deleteAllParking]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: FormData, e?: any) => {
    e.preventDefault();
    setConfirmModal({
      message: "저장하시겠습니까?",
      isOpen: true,
      type: "PARKING_SAVE",
      payload: data,
    });
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    // console.log("onError errors", errors);
    return false;
  };

  // 주차 토글 버튼 변경
  const changeToggle = (value: boolean) => {
    if (value) {
      setValue("isUsedParking", true);
    } else {
      setConfirmModal({
        message: "정보가 초기화되어 저장됩니다.",
        isOpen: true,
        type: "PARKING_DELETE",
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Controller
          control={control}
          name="isUsedParking"
          render={({ field: { value, name } }) => (
            <section className="contents-container__grid mb30">
              <div className="contents-container__grid-index">
                <p>주차</p>
              </div>
              <div className="contents-container__grid-contents">
                {/* 토글 활성화 되면 노출 */}
                {getValues("isUsedParking") && (
                  <>
                    <div className="minmax400 flex-center mb10">
                      <div className="minmax50 font14 no-wrap mr10">자주식</div>
                      <Controller
                        control={control}
                        name="selfPropelledParkingLotNums"
                        render={({ field: { onChange, value, name } }) => (
                          <>
                            <BaseSelect
                              placeholder="설비 수량"
                              className="mr10"
                              value={String(value)}
                              name={name}
                              stateOptions={parkingLotNumOptions}
                              setStateValue={(v: string) => onChange(v ? parseInt(v) : null)}
                            />
                            <Controller
                              control={control}
                              name="selfPropelledParkingLotTotalNums"
                              render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                              }) => (
                                <BaseInput
                                  type="number"
                                  placeholder="주차 대수"
                                  onChange={(v: string) => onChange(v ? parseInt(v) : null)}
                                  value={value}
                                  name={name}
                                  readonly={
                                    Number(getValues("selfPropelledParkingLotNums") || 0) === 0
                                  }
                                />
                              )}
                            ></Controller>
                          </>
                        )}
                      ></Controller>
                    </div>
                    <div className="minmax400 flex-center mb10">
                      <div className="minmax50 font14 no-wrap mr10">기계식</div>
                      <Controller
                        control={control}
                        name="mechanicalParkingLotNums"
                        render={({ field: { onChange, value, name } }) => (
                          <>
                            <BaseSelect
                              placeholder="설비 수량"
                              className="mr10"
                              value={String(value)}
                              name={name}
                              stateOptions={parkingLotNumOptions}
                              setStateValue={(v: string) => onChange(v ? parseInt(v) : null)}
                            />
                            <Controller
                              control={control}
                              name="mechanicalParkingLotTotalNums"
                              render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                              }) => (
                                <BaseInput
                                  type="number"
                                  placeholder="주차 대수"
                                  onChange={(v: string) => onChange(v ? parseInt(v) : null)}
                                  value={value}
                                  name={name}
                                  readonly={
                                    Number(getValues("mechanicalParkingLotNums") || 0) === 0
                                  }
                                />
                              )}
                            ></Controller>
                          </>
                        )}
                      ></Controller>
                    </div>
                    <div className="minmax400 flex-center mb10">
                      <div className="minmax50 font14 no-wrap mr10">설명</div>
                      <Controller
                        control={control}
                        name="description"
                        render={({ field: { onChange, value, name } }) => (
                          <BaseTextarea
                            placeholder={`규정 정보, 사용조건 및 비용정보를 입력해주세요
예시)
무료주차 : 임대 50py 당 1대
유료주차 : 월 150,000원(vat 별도)`}
                            onChange={onChange}
                            maxLength={1000}
                            value={value}
                            name={name}
                          />
                        )}
                      ></Controller>
                    </div>
                  </>
                )}
              </div>
            </section>
          )}
        ></Controller>
      </form>

      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
      >
        <p>{confirmModal.message}</p>
      </BaseModal>
    </>
  );
};

export default ParkingSection;
