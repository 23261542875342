import { Cell } from "react-table";
import { MemberModel, MemberStatusType } from "src/api/member/member-types";
import { BaseTooltip } from "src/components/BaseTooltip";

export const MemberJoinDateCell = (props: Cell<MemberModel>) => {
  const filterJoinDate = props.value.find((state: any) => state.status === MemberStatusType.JOINED);
  let rtn = '';
  if(filterJoinDate && filterJoinDate.statusDate && filterJoinDate.statusDate !==''){
    // console.log('filterJoinDate',filterJoinDate);
    rtn = filterJoinDate.statusDate;
  }
  return (
    <BaseTooltip
      contents={rtn}
      type={'date'}
    />
  );
};
