import React from "react";
import { Cell } from "react-table";
import { BuildingModel } from "src/api/building/building-types";
import { BaseTooltip } from "src/components";
import { BuildingDateCell } from "src/pages/building/list/columns/BuildingDateCell";

export const BuilidingPopupColumn = [
  {
    Header: "id",
    accessor: "id",
    width: 50,
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 150,
    Cell: ({ value }: any) => <BaseTooltip contents={value || ""} />,
  },
  {
    Header: "주소",
    accessor: "address",
    width: 220,
    Cell: ({ value }: any) => {
      return (
        <>
          <span className="ellipsis2">{value}</span>
        </>
      );
    },
  },
  {
    Header: "규모",
    accessor: "size",
    width: 150,
    Cell: (props: Cell<BuildingModel>) => {
      return (
        <div>
          {`지하${props.row.original.undergroundFloorNums}층 / 지상${props.row.original.floorNums}층`}
        </div>
      );
    },
  },
  {
    Header: "공개여부",
    accessor: "status",
    width: 110,
    Cell: ({ value }: Cell<BuildingModel>) => {
      return (
        <div
          className={`${
            (value === "ENABLED" && "chip visible") ||
            (value === "DISABLED" && "chip invisible") ||
            (value === "DRAFT" && "chip draft")
          }`}
        >
          {(value === "ENABLED" && "공개중") ||
            (value === "DISABLED" && "비공개") ||
            (value === "DRAFT" && "작성중")}
        </div>
      );
    },
  },

  {
    Header: "수정일",
    accessor: "modifiedDate",
    width: 110,
    Cell: (props: Cell<BuildingModel>) => <BuildingDateCell {...props} />,
  },
];
