import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getBuildingAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, ContentsIdSection } from "src/components";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import { DeskSection, MeetingRoomSection, ParkingSection, RefreshRoomSection } from "./components";

/* 
  건물 > 상세 > 공용공간
 */
const FacilityDetail = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const navigate = useNavigate();
  const location = useLocation();

  // path variable 에서 buildingId 추출
  const buildingId = useMemo(() => {
    const paths = location.pathname.split("/");
    const pathBuildingId = paths[paths.length - 1];
    return Number(pathBuildingId);
  }, [location]);

  // 건물 상세
  const [building, setBuilding] = useState<BuildingModel | null>(null);

  // 건물 상세 조회 api hook
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  useEffect(() => {
    if (buildingId) {
      async function fetchBuilding(buildingId: number) {
        setLoadingBar(true);
        const { data } = await getBuilding({ buildingId });
        setBuilding(data?.data?.content?.building || null);
        setLoadingBar(false);
      }
      fetchBuilding(buildingId);
    }
  }, [buildingId, getBuilding, setLoadingBar]);

  // title 에 건물명 바인딩
  let buildingName;
  if (buildingId && building?.buildingName) {
    buildingName = building?.buildingName;
  }
  useTitleOperation(buildingName);

  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection title="공용공간" id={buildingId} />

        {/* 주차 */}
        <ParkingSection building={building} />

        {/* 회의실 */}
        <MeetingRoomSection building={building} />

        {/* 좌석 */}
        <DeskSection building={building} />

        {/* 편의시설 */}
        <RefreshRoomSection building={building} />
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            className="color-white size-large"
            onClick={() => {
              navigate(PagePath.building.list);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FacilityDetail;
