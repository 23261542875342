import React from "react";
import { Link } from "react-router-dom";
import { CommonFacilityModel } from "src/api/building/building-types";
import { PriceModel, PriceRuleOptionTypeToText } from "src/api/price/price-types";
import { PagePath } from "src/pages/product/details";

type Props = {
  priceDetail: PriceModel | undefined;
  facility: CommonFacilityModel | undefined;
};
const GrayBox = ({ priceDetail, facility }: Props) => {
  return (
    <div className="gray-box">
      <div className="gray-box__line">
        <div className="left">
          <p>기준요금 유형</p>
        </div>
        <div className="right">
          <p>
            {priceDetail?.priceRuleType
              ? PriceRuleOptionTypeToText[priceDetail.priceRuleType]
              : "-"}
          </p>
        </div>
      </div>
      <div className="gray-box__line">
        <div className="left">
          <p>공용공간</p>
        </div>
        <div className="right">
          {facility?.buildingName && facility?.facilityName ? (
            <div className="flex-center">
              <Link
                className=""
                to={`${PagePath.building.detail.replace(":id", `${facility.buildingId}`)}`}
                target="_blank"
              >
                {facility.buildingName || "-"}
              </Link>
              <span className="ic-target-blank"></span>
              <p className="mx10">{`>`}</p>
              <Link
                to={`${PagePath.building.detail.replace(
                  ":id",
                  `${facility.buildingId}`,
                )}?tab=facility`}
              >
                {facility.facilityName || "-"}
              </Link>
              <span className="ic-target-blank"></span>
            </div>
          ) : (
            <p>-</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default GrayBox;
