/* eslint-disable array-callback-return */
import { calculateRateOfUse } from "src/utils";

const TableTextCellWithBuilding = ({ title, array }: any) => {
  return (
    <>
      <tr>
        <td width="180">
          <div className="flex-center pl16">
            <span className="text-primary3">{title}</span>
          </div>
        </td>
        <td>
          {array?.length > 0 &&
            array?.map((list: any, index: number) => {
              let count = 0;

              list.building.buildingFloorList.map((floor: any) => {
                floor.buildingRoomList.map((room: any) => {
                  count = Number(calculateRateOfUse(room.leasableAreaNet, room.leasableArea));
                });
              });

              return (
                <div className="w-100 flex-center-start text-left" key={index}>
                  <span>{list.building.buildingName + "(" + count + "%)"}</span>
                </div>
              );
            })}
        </td>
      </tr>
    </>
  );
};
export default TableTextCellWithBuilding;
