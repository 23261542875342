import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import { getProductListAsync } from "src/api/product/product-api";
import {
  ProductCategory,
  ProductListParams,
  ProductModel,
  ProductStatus,
  ProductType,
} from "src/api/product/product-types";
import { Order, PageMeta, Sort } from "src/api/public-types";
import { BaseButton, BaseInput, BaseSelect, BaseTable } from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import PagePath from "src/pagePath.json";
import { Partner } from "src/types/partner";
import { useLoadingBarContext } from "../hooks";
import { productColumns } from "./columns";

// 검색대상
const searchTypes = [
  // { value: "", label: "검색 대상" },
  { value: "ALL", label: "전체" },
  { value: "PRODUCT_NAME", label: "공간상품명" },
  { value: "ADDRESS", label: "주소" },
  { value: "BUILDING_NAME", label: "건물명" },
  { value: "CREATED_BY", label: "등록ID" },
];

// 상태
const statuses = [
  { value: "", label: "전체" },
  { value: ProductStatus.ENABLED, label: "전체공개" },
  { value: ProductStatus.DISABLED, label: "비공개" },
  { value: ProductStatus.LINK_ENABLED, label: "링크공개" },
];

const productCategory = [
  { value: "", label: "전체" },
  { value: "PRODUCT_CATEGORY_NORMAL", label: "일반" },
  { value: "PRODUCT_CATEGORY_MANAGEMENT", label: "관리비" },
];

// 타입
const productTypes = [
  { value: "", label: "전체" },
  { value: ProductType.F, label: "Full Court" },
  { value: ProductType.O, label: "Open Court" },
  { value: ProductType.T, label: "Time Court" },
  { value: ProductType.N, label: "비상주" },
];

// 이용여부
const isProductsUsed = [
  { value: "", label: "전체" },
  { value: "true", label: "이용중" },
  { value: "false", label: "이용중 X" },
];

// url query parameter 타입
type QueryParams = {
  page?: number;
  size?: number;
  searchType?: string;
  status?: string;
  productType?: string;
  keyword?: string;
  isDeleted?: string;
  isUsed?: string;
  partnerId?: string;
  sort?: Sort;
  productCategory?: ProductCategory;
};

/* 
  공간상품 > 목록 화면
 */
const ProductList = () => {
  const navigate = useNavigate();
  const { setLoadingBar } = useLoadingBarContext();

  const location = useLocation();

  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (_queryParams.isDeleted !== "true" && _queryParams.isDeleted !== "false") {
      _queryParams.isDeleted = "false";
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }

    return _queryParams;
  }, [location]);

  // 공간상품 목록 조회
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [partner, setPartner] = useState<Partner>();
  const [modal, setModal] = useState({
    partnerSelectModal: {
      visible: false,
    },
  });
  const { executeAsync: getProductList } = useApiOperation(getProductListAsync);
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  useEffect(() => {
    async function fetchProducts(productListParams: ProductListParams) {
      setLoadingBar(true);

      const { data } = await getProductList(productListParams);
      if (queryParams.partnerId) {
        const { data: partnerData } = await getPartnerAsync({
          id: queryParams.partnerId,
        });

        setPartner(partnerData.data.partner);
      }

      setProducts(data?.data?.content || []);
      setPageMeta(data?.meta?.pageMeta);

      setLoadingBar(false);
    }

    // 건물 목록 조회후 데이터 바인딩
    fetchProducts(queryParams);
  }, [queryParams, setLoadingBar, getProductList]);

  // 받아온 query parameter 로 해당 컴포넌트에서 변경가능하기 위한 state 선언
  const [params, setParams] = useState<QueryParams>({ ...queryParams });

  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  const onPartnerSelectModalCancel = () => {
    setModal({
      ...modal,
      partnerSelectModal: {
        visible: false,
      },
    });
  };

  const onPartnerSelectModalAdded = (partnerList: Partner[]) => {
    if (partnerList.length > 0) {
      setPartner(partnerList[0]);
      setParams({ ...params, partnerId: partnerList[0].id! });
    } else {
      setPartner(undefined);
      setParams({ ...params, partnerId: undefined });
    }

    setModal({
      ...modal,
      partnerSelectModal: {
        visible: false,
      },
    });
  };

  const handleClickPartnerSearchInput = () =>
    setModal({
      ...modal,
      partnerSelectModal: {
        visible: true,
      },
    });

  return (
    <div className="page-product-list">
      {/* 로딩바 */}
      {/* <ContentsTitle title="공간상품" /> */}
      <div className="contents-container__search-wrap">
        <div className="left-area">
          {/* <p>
              전체 <span>{pageMeta?.totalElements || 0}</span>
            </p> */}
          <div className="minmax200 mr8">
            <BaseInput
              placeholder="파트너"
              onSearchClick={handleClickPartnerSearchInput}
              value={partner?.name}
              disabled
            />
          </div>
          <div className="minmax120 mr8">
            <BaseSelect
              value={params?.isUsed}
              stateOptions={isProductsUsed}
              setStateValue={(isUsed: string) => setParams({ ...params, isUsed })}
              placeholder="이용여부"
            />
          </div>
          <div className="minmax120 mr8">
            <BaseSelect
              value={params?.status}
              stateOptions={statuses}
              setStateValue={(status: string) => setParams({ ...params, status })}
              placeholder="공개상태"
            />
          </div>
          <div className="minmax120 mr8">
            <BaseSelect
              value={params?.productCategory}
              stateOptions={productCategory}
              setStateValue={(productCategory: ProductCategory) =>
                setParams({ ...params, productCategory })
              }
              placeholder="구분"
            />
          </div>
          <div className="minmax120 mr8">
            <BaseSelect
              value={params?.productType}
              stateOptions={productTypes}
              setStateValue={(productType: string) => setParams({ ...params, productType })}
              placeholder="타입"
            />
          </div>
          <div className="minmax120 mr8">
            <BaseSelect
              value={params?.searchType}
              stateOptions={searchTypes}
              setStateValue={(searchType: string) => setParams({ ...params, searchType })}
              placeholder="검색대상"
            />
          </div>
          <BaseInput
            value={params?.keyword || ""}
            className="mr16"
            type="text"
            placeholder="검색어를 입력해주세요"
            onChange={(keyword: string) => setParams({ ...params, keyword })}
            onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
            onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
          />
        </div>
        <div className="right-area">
          {/* <BaseButton title="+ 상품 등록" onClick={() => navigate(PagePath.product.form)} /> */}
        </div>
      </div>

      <BaseTable
        data={products}
        columns={productColumns}
        pageIndex={Number(params?.page || 0)}
        totalPages={pageMeta?.totalPages || 0}
        goPage={(page: number) => {
          navigateWithQueryParams({ page }, "pagination");
        }}
        orders={params?.sort?.orders}
        disabledSortHeaders={["rentalCost", "image"]}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } }, "search");
          }
        }}
      />

      {modal.partnerSelectModal.visible && (
        <PartnerSelectModal
          onCanceled={onPartnerSelectModalCancel}
          onAdded={onPartnerSelectModalAdded}
          defaultValues={partner ? [partner] : []}
        />
      )}
    </div>
  );
};
export default ProductList;
