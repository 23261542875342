/* eslint-disable array-callback-return */
import { calculatePyeong } from "src/utils";

const TableTextWithSup = ({ title, array, type }: any) => {
  return (
    <>
      <tr>
        <td width="180">
          <div className="flex-center pl16">
            <span className="text-primary3">{title}</span>
          </div>
        </td>
        <td>
          {array?.length > 0 &&
            array?.map((list: any, index: number) => {
              let count = 0;
              let text = list.building.buildingName + "(";
              list.building.buildingFloorList.map((floor: any) => {
                floor.buildingRoomList.map((room: any) => {
                  count += Number(room[type]);
                });
              });
              text = text + count + ")";
              return (
                <div className="w-100 flex-center-start text-left" key={index}>
                  <span>{text}</span>
                  <span className="font12">
                    m<sup>2</sup>
                  </span>
                  <div className="base-chip ml16">
                    <span>{calculatePyeong(count)} 평</span>
                  </div>
                </div>
              );
            })}
        </td>
      </tr>
    </>
  );
};
export default TableTextWithSup;
