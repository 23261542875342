const TableTextWithParkingLotType = ({ title, value1, value2 }: any) => {
  let rtnValue = "";
  if (value2) {
    if (rtnValue !== "") {
      rtnValue = rtnValue + ", ";
    }
    rtnValue = rtnValue + "유료 " + value2;
  }
  if (value1) {
    if (rtnValue !== "") {
      rtnValue = rtnValue + ", ";
    }
    rtnValue = rtnValue + "무료 " + value1;
  }
  if (value1 === "" && value2 === "") {
    rtnValue = "없음";
  }

  return (
    <>
      <tr>
        <td width="180">
          <div className="flex-center pl16">
            <span className="text-primary3">{title}</span>
          </div>
        </td>
        <td>
          <div className="w-100 flex-center-start">{rtnValue}</div>
        </td>
      </tr>
    </>
  );
};
export default TableTextWithParkingLotType;
