import { useMemo, useState } from "react";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import MaintenanceLateFeeColumns from "../columns/MaintenanceLateFeeColumns";

const MaintenanceLateFeeSection = ({
  contractBaseInfo,
  callList,
  setLoadingBar,
  roundList,
  basicInfo,
  stopObj,
  editable,
  overdueList,
  scheduleId,
}: any) => {
  const [data, setData] = useState<any>([]);
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } =
    useTable(
      {
        columns: MaintenanceLateFeeColumns,
        data: data,
        contractBaseInfo,
        callList,
        setLoadingBar,
        roundList,
        basicInfo,
        stopObj,
        editable,
        overdueList,
        scheduleId,
      },
      useBlockLayout,
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect,
      useSticky,
    );

  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <div className="mt20">
      <div className="contents-container__sub-title mt20 ">
        <h2>관리비 연체 현황</h2>
        <p className="contents-container__sub-title-info">
          연체금액의 할인이 있을 시, [수납처리] 후 메모에 연체 회차 및 할인금액을 입력해 주세요.
        </p>
      </div>
      <div {...getTableProps()} className="base-table sticky">
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
              {headerGroup.headers.map((header) => {
                return (
                  <div {...header.getHeaderProps()} className="base-table__th">
                    {header.render("Header")}
                    {/* <div className="ic_sort"></div> */}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="body">
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <div
                {...row.getRowProps()}
                className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
              >
                {row.cells.map((cell: any) => {
                  return (
                    <div {...cell.getCellProps()} className="base-table__td">
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
          {rows.length === 0 && (
            <div className="base-table__tr" style={{ width: tableWidth }}>
              <div className="base-table__td w-100 text-center">
                <div className="w-100">
                  <span>데이터가 없습니다.</span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {data &&
            data.length > 0 &&
            footerGroups.map((group) => {
              return (
                <div {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <div {...column.getFooterProps()} className="base-table__td base-table-footer">
                      {column.render("Footer")}
                    </div>
                  ))}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default MaintenanceLateFeeSection;
