import { Cell } from "react-table";
import { MemberModel } from "src/api/member/member-types";
import { MemberStatusCell } from "src/pages/member/components/MemberStatusCell";


/* 
  회원 테이블 columns
*/
const PopupListMemberColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 150,
  },
  {
    Header: "회원번호",
    accessor: "memberNo",
    width: 150,
  },
  {
    Header: "휴대폰 번호",
    accessor: "phoneNumber",
    width: 150,
  },
  {
    Header: "이메일",
    accessor: "email",
    width: 200,
  },
  {
    Header: "계정상태",
    width: 150,
    accessor: "accountState", // api 스펙에 없음
    Cell: (props: Cell<MemberModel>) => <MemberStatusCell {...props} />,
  },
];

export default PopupListMemberColumns;
