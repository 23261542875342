/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from "lodash";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getContractManageByContractApplyNumber } from "src/api/contract/contract-api";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import { contract_bill_user_errors, Order, PageMeta, Select } from "src/api/public-types";
import { getIssueCount, getUsers } from "src/api/user/user-api";
import { MemberStatus, Search, UserListModel } from "src/api/user/user-types";
import {
  BaseButton,
  BaseInput,
  BaseModal,
  BaseSelect,
  BaseTable,
  ContentsTitle,
} from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import PagePath from "src/pagePath.json";
import SearchContractUserPopup from "src/pages/commonPopup/SearchContractUserPopup";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { Partner } from "src/types/partner";
import { changeListSearchPhoneNumber } from "src/utils";
import { columns } from "./columns/Columns";
//정렬기능 숨김
const disabledSortHeaders = [
  // "contractMemberId",
  "contractApplyNumber",
  "contractManageId",
  "memberNo",
  "email",
  "signUpTime",
  "memberStatus",
];
const searchOption1: Select[] = [
  { label: "전체", value: "" },
  { label: "초대", value: MemberStatus.MEMBER_INVITE },
  { label: "사용", value: MemberStatus.MEMBER_USE },
  { label: "삭제", value: MemberStatus.MEMBER_DELETE },
  { label: "종료", value: MemberStatus.MEMBER_CLOSE },
  { label: "만료", value: MemberStatus.MEMBER_EXPIRE },
];

const calendarOptions = [
  { label: "전체", value: "" },
  { label: "연동", value: "true" },
  { label: "미연동", value: "false" },
];

const searchTypeUserOption: Select[] = [
  { label: "회원번호", value: "MEMBER_NO" },
  { label: "휴대폰번호", value: "MEMBER_MOBILE_NUMBER" },
];
// select sample

const defaultParams: Search = {
  page: 0,
  size: 20,
  sort: { orders: [{ property: "contractMemberId", direction: "DESC" }] },
};
const UserList = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [partnerSelectModal, setPartnerSelectModal] = useState({
    visible: false,
  });

  //호출부
  const { executeAsync: getUser } = useApiOperation(getUsers, { doNotErrorHandleModal: true });
  const { executeAsync: getIssueUserCount } = useApiOperation(getIssueCount, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: checkContract } = useApiOperation(getContractManageByContractApplyNumber, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const [params, setParams] = useState<Search>({ ...defaultParams, ...queryParams });
  const [partner, setPartner] = useState<Partner>();
  const [data, setData] = useState<UserListModel[]>([]);
  const [pagination, setPagination] = useState<PageMeta>();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [issueOccurredChannelsCount, setIssueOccurredChannelsCount] = useState(0);
  const [contractManageId, setContractManageId] = useState(0);
  const failUserCount = useCallback(
    async (data: any) => {
      const contractManageId = data[0].contractManageId;
      const send: any = { contractManageId: Number(contractManageId) };
      const res: any = await getIssueUserCount(send);

      if (res && res.status >= 200 && res.status <= 299) {
        setIssueOccurredChannelsCount(res.data.data.issueOccurredChannelsCount);
      }
    },
    [getIssueUserCount],
  );

  const findAsyncUsers = useCallback(
    async (params: any) => {
      setLoadingBar(true);
      // if (params.contractApplyNumber && params.contractApplyNumber !== "") {
      let cloneParam = _.cloneDeep(params);
      if (cloneParam.searchType === "MEMBER_MOBILE_NUMBER") {
        cloneParam.searchValue = changeListSearchPhoneNumber(cloneParam.searchValue!);
      }

      const response: any = await getUser(cloneParam);
      if (response && response.status >= 200 && response.status <= 299) {
        setData(response.data.data || []);
        if (response.data.data && response.data.data.length > 0) {
          setContractManageId(response.data.data[0].contractManageId);
          failUserCount(response.data.data);
        }

        setPagination(response.data.meta.pageMeta);
      } else {
        let message = errorMessageHandler(
          response.status,
          response.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = response.status;
        if (response.data.meta.errorCode !== "") {
          code = response.data.meta.errorCode;
        }
        openErrorModal(message, code);
      }
      setLoadingBar(false);
      // }
    },
    [failUserCount, getUser, openErrorModal, setLoadingBar],
  );

  const navigateWithQueryParams = useCallback(
    async (passParams?: Search) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      newQueryParams.searchValue = newQueryParams?.searchValue?.replace(/[^0-9]/g, "");

      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [params, navigate, location.pathname],
  );

  const checkContractBasic = useCallback(
    async (contractApplyNumber: string) => {
      setLoadingBar(true);
      const res = await checkContract({ contractApplyNumber });

      if (res.status >= 200 && res.status <= 299) {
        if (Number(res.data.data.contractId) > 0) {
          const path =
            PagePath.user.form.replace(":contractManageId", res.data.data.contractId) +
            "?" +
            qs.stringify(queryParams, { allowDots: true, encode: true });
          navigate(path);
        } else {
          setIsOpen(true);
        }
      } else {
        console.log("error", res);
      }
      setLoadingBar(false);
    },
    [checkContract, navigate, queryParams, setLoadingBar],
  );

  const moveForm = useCallback(() => {
    if (params.contractApplyNumber && params.contractApplyNumber !== "") {
      checkContractBasic(params.contractApplyNumber);
    }
  }, [checkContractBasic, params.contractApplyNumber]);

  const handleClickPartnerSelectSearchButton = () => setPartnerSelectModal({ visible: true });

  const onAddedPartnerSelectModal = (partnerList: Partner[]) => {
    const partner = partnerList[0];
    setPartner(partner);
    setParams({ ...params, partnerId: partner.id });
    setPartnerSelectModal({ visible: false });
  };
  const onCanceledPartnerSelectModal = () => setPartnerSelectModal({ visible: false });

  useEffect(() => {
    (async () => {
      console.log("params", params);

      if (params.partnerId !== undefined) {
        const result = await getPartnerAsync({
          id: params.partnerId,
        });
        if (result.status >= 200 && result.status <= 299) {
          setPartner(result.data.data.partner);
        }
      }
      findAsyncUsers(params);
    })();
  }, [params]);

  return (
    <div className="page-product-list">
      {/* <ContentsTitle title="이용자" /> */}
      <div className="contents-container__search-wrap">
        <div className="left-area">
          {/* <p>
              전체 <span>{pagination?.totalElements}</span>
            </p>
             */}
          <div className="minmax200 mr8">
            <BaseInput
              type="text"
              value={partner?.name}
              placeholder="파트너"
              onSearchClick={handleClickPartnerSelectSearchButton}
              disabled
            />
          </div>
          <div className="minmax140 mr8">
            <BaseInput
              type="text"
              value={params.contractApplyNumber}
              placeholder="신청번호(필수)"
              onChange={(contractApplyNumber: any) =>
                setParams({ ...params, ...{ contractApplyNumber } })
              }
            />
          </div>
          <div className="minmax80 mr8">
            <BaseSelect
              value={params.memberStatus}
              placeholder="상태"
              stateOptions={searchOption1}
              setStateValue={(memberStatus: string) => {
                setParams({ ...params, ...{ memberStatus } });
              }}
            />
          </div>
          <div className="minmax140 mr8">
            <BaseSelect
              value={String(params.watchingCalendar)}
              placeholder="캘린더 연동"
              stateOptions={calendarOptions}
              setStateValue={(watchingCalendar: boolean) => {
                setParams({ ...params, ...{ watchingCalendar } });
              }}
            />
          </div>
          <div className="minmax140 mr8">
            <BaseSelect
              value={params.searchType}
              placeholder="검색대상"
              stateOptions={searchTypeUserOption}
              setStateValue={(searchType: string) => {
                setParams({ ...params, ...{ searchType } });
              }}
            />
          </div>
          <div className="minmax220 mr16">
            <BaseInput
              type="text"
              value={params.searchValue}
              placeholder="검색어를 입력해주세요"
              onChange={(searchValue: any) => setParams({ ...params, ...{ searchValue } })}
              onKeyUp={() => navigateWithQueryParams({ page: 0 })}
              onSearchClick={() => navigateWithQueryParams({ page: 0 })}
            />
          </div>
          <span>
            캘린더 동기화 실패
            <a
              href=""
              className="text-hilight ml5"
              onClick={(e: any) => {
                e.preventDefault();

                if (
                  params.contractApplyNumber &&
                  data.length > 0 &&
                  issueOccurredChannelsCount > 0
                ) {
                  setIsOpen2(true);
                }
              }}
            >
              {issueOccurredChannelsCount}
            </a>
            <SearchContractUserPopup
              contractManageId={contractManageId}
              contractApplyNumber={String(params.contractApplyNumber)}
              isOpen={isOpen2}
              onClick={() => setIsOpen2(false)}
            />
          </span>
        </div>
        <div className="right-area">
          {/* <BaseButton title="+ 이용자 초대" onClick={() => moveForm()} /> */}
        </div>
      </div>
      {/* 샘플 */}
      <BaseTable
        data={data}
        columns={columns}
        pageIndex={Number(params.page) || 0}
        totalPages={Number(pagination?.totalPages) || 0}
        goPage={(page: number) => navigateWithQueryParams({ page })}
        disabledSortHeaders={disabledSortHeaders}
        orders={params.sort?.orders}
        setOrders={(orders?: Array<Order>) => {
          navigateWithQueryParams({ sort: { orders } });
        }}
      />
      <BaseModal isOpen={isOpen} btnRightTitle={"확인"} onClick={() => setIsOpen(false)}>
        <p>등록되지 않은 신청/계약번호입니다. 신청/계약번호는 필수입니다.</p>
      </BaseModal>
      <BaseModal isOpen={isError} btnRightTitle={"확인"} onClick={() => setIsError(false)}>
        <p>{message}</p>
      </BaseModal>
      {partnerSelectModal.visible && (
        <PartnerSelectModal
          onCanceled={onCanceledPartnerSelectModal}
          onAdded={onAddedPartnerSelectModal}
          defaultValues={partner ? [partner] : []}
        />
      )}
    </div>
  );
};

export default UserList;
