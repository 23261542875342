import { useState, useEffect } from "react";
import { useApiOperation } from "src/api/hooks";
import { getPartnerMember, getPartnerPrivileges } from "src/api/partner-member/api";
import { IPartnerMember } from "src/types/partner-members";

function usePartnerMember(id: string | number) {
  const [partnerMember, setPartnerMember] = useState<IPartnerMember>();
  const [privileges, setPrivileges] = useState<any>();
  const { executeAsync: getPartnerMemberAsync, loading } = useApiOperation(getPartnerMember);
  const { executeAsync: getPartnerPrivilegesAsync } = useApiOperation(getPartnerPrivileges);

  useEffect(() => {
    (async () => {
      if (!id) return;

      const { data: result, status } = await getPartnerMemberAsync({
        id: String(id),
      });

      if (status >= 200 && status < 300) {
        const { data: Privileges } = await getPartnerPrivilegesAsync({ id: String(id) });
        setPrivileges(Privileges.data.privileges);
        setPartnerMember(result.data.member);
      }
    })();
  }, []);

  return { partnerMember, privileges, setPartnerMember, setPrivileges, loading };
}

export default usePartnerMember;
