import React from "react";
import { ProductModel } from "src/api/product/product-types";
import { MetaData } from "src/api/public-types";

type Props = {
  product: ProductModel | null;
};

/* 
  공간상품 > 상세 > 시설/서비스 > 무료 인터넷 컴포넌트
*/
const FreeInternetSection = ({ product }: Props) => {
  const freeInternetOptionList: Array<MetaData> = (
    product?.productFreeInternet?.freeInternetOptionList || []
  )
    .filter((metaData: MetaData) => Boolean(String(metaData.value1) === "true")) // 제공하는 것
    .sort((a: MetaData, b: MetaData) => Number(a.orderNums || 0) - Number(b.orderNums || 0));

  // 무료 인터넷 제공여부: 제공여부가 true 이고, 옵션 목록이 있을 경우, "제공"
  const isFreeInternetProvided: boolean =
    freeInternetOptionList.length > 0 && !!product?.productFreeInternet?.isFreeInternetProvided;

  return (
    <section className="contents-container__grid contents-container__1200">
      <div className="contents-container__grid-index">
        <p className="">무료 인터넷</p>
      </div>
      <div className="contents-container__grid-contents">
        <table className="inner-table" width="100%">
          <tbody>
            <tr>
              <td className="bg-gray text-center" width="140">
                <span className="text-primary3 no-wrap">제공 여부</span>
              </td>
              <td>
                <div className="flex-center px16">
                  {isFreeInternetProvided ? "제공" : "제공하지 않음"}
                </div>
              </td>
            </tr>
            {isFreeInternetProvided && (
              <>
                <tr>
                  <td className="bg-gray text-center" width="140">
                    <span className="text-primary3 no-wrap">옵션</span>
                  </td>
                  <td>
                    <div className="flex-center px16">
                      {freeInternetOptionList
                        .map((metaData: MetaData) => {
                          let text = "";
                          if (metaData.metaItem === "PRODUCT_FREE_INTERNET_1") {
                            text = "랜선";
                          } else if (metaData.metaItem === "PRODUCT_FREE_INTERNET_2") {
                            text = "와이파이";
                          }
                          return text;
                        })
                        .join(", ")}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="bg-gray text-center" width="140">
                    <span className="text-primary3 no-wrap">추가 설명</span>
                  </td>
                  <td>
                    <div className="w-100 pl16">
                      {product?.productFreeInternet?.freeInternetDescription || ""}
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};
export default FreeInternetSection;
