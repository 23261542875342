import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getContractApply } from "src/api/contract/contract-api";
import {
  ContractManageList,
  searchStatusOption,
  searchTypeOption,
} from "src/api/contract/contract-types";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { Order, PageMeta, contract_bill_user_errors } from "src/api/public-types";
import {
  BaseDatePicker,
  BaseInput,
  BaseRadio,
  BaseSelect,
  BaseTable,
  BaseTooltip
} from "src/components";
import BaseMultiSelect from "src/components/BaseMultiSelect";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import SearchBuildingPopup from "src/pages/commonPopup/SearchBuildingPopup";
import SearchOrgPopup from "src/pages/commonPopup/SearchOrgPopup";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { YmdFormat } from "src/utils";
import { columns } from "./columns/Columns";

const ContractList = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [building, setBuilding] = useState<any>({ buildingName: "" });
  const [isBuildingOpen, setIsBuildingOpen] = useState(false);
  const [org, setOrg] = useState<any>({ name: "" });
  const [isOrgOpen, setIsOrgOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [partners, setPartners] = useState<any>([]);
  const orgColumns: any = [
    {
      Header: "선택",
      accessor: "isSelected",
      width: 80,
      Cell: ({ row, setSelectedOrg }: any) => {
        const changeTrigger = useCallback(() => {
          setSelectedOrg(row.original);
        }, [row.original, setSelectedOrg]);

        return (
          <BaseRadio
            id={`selector${row.original.id}`}
            name={"isSelected"}
            onChange={changeTrigger}
            value={row.original.isSeleted}
          />
        );
      },
    },
    {
      Header: "id",
      accessor: "id",
      width: 80,
    },
    {
      Header: "법인명/상호",
      accessor: "name",
      width: 300,
      Cell: ({ value }: any) => {
        return <BaseTooltip contents={value} />;
      },
    },
  ];

  // console.log(location.search, "location.search");
  const queryParams: any = useMemo(() => {
    const _queryParams: any = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
      decoder: (c) => c,
    });

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }

    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "contractManageId", direction: "DESC" }],
      };
    }
    if (_queryParams?.id) {
      delete _queryParams?.id;
    }

    return _queryParams;
  }, [location]);
  const [params, setParams] = useState<any>({ ...queryParams });
  const [data, setData] = useState<ContractManageList[]>([]);
  const [pagination, setPagination] = useState<PageMeta>();

  //호출부
  const { executeAsync } = useApiOperation(getContractApply);

  const callList = useCallback(
    async (param: any) => {
      setLoadingBar(true);
      if (param.buildingName === "") {
        delete param.buildingId;
      }
      if (param.orgName === "") {
        delete param.mbOrganizationId;
      }
      param.searchValue = decodeURIComponent(param.searchValue || "");
      let response: any = await executeAsync(param);

      if (response && response.status >= 200 && response.status <= 299) {
        console.log("response", response.data);
        setData(response.data.data.content);
        setPagination(response.data.meta.pageMeta);
        setParams({ ...params, ...param });
      } else {
        let message = errorMessageHandler(
          response.status,
          response.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
        openErrorModal(message, code);
      }

      setLoadingBar(false);
    },
    [executeAsync, openErrorModal, params, setLoadingBar],
  );

  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (passParams?: any) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true, encode: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [params, navigate, location.pathname],
  );

  const settingBuilding = useCallback(
    (bd: any) => {
      setIsBuildingOpen(false);
      setBuilding(bd);
      setParams({ ...params, ...{ buildingId: bd.id, buildingName: bd.buildingName } });
      console.log(bd);
    },
    [params],
  );

  const settingOrg = useCallback(
    (org: any) => {
      setIsOrgOpen(false);
      setOrg(org);
      setParams({ ...params, ...{ mbOrganizationId: org.id, orgName: org.name } });
    },
    [params],
  );

  const settingPartners = useCallback(
    (partners: any) => {
      console.log("partners", partners);
      setPartners(partners[0]);
      setParams({ ...params, ...{ partnerId: partners[0].id, partnerName: partners[0].name } });
      setVisible(false);
    },
    [params],
  );

  const closePartnerPopup = () => {
    setVisible(false);
  };

  useEffect(() => {
    callList(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const returnContractSteps = useMemo(() => {
    let rtn: any = [];
    // console.log("params.contractStep", params.contractStep);
    if (params.contractStep !== undefined && params.contractStep !== "") {
      rtn = params.contractStep.split(",");
    }

    return rtn;
  }, [params.contractStep]);

  // console.log("pagination", pagination);

  return (
    <div className="page-product-list">
      {/* <ContentsTitle title="신청/계약" /> */}
      <div className="contents-container__search-wrap">
        <div className="left-area">
          {/* <p>
              전체 <span>{pagination?.totalElements}</span>
            </p> */}
          <div className="minmax140 mr8">
            <BaseInput
              type="text"
              value={decodeURIComponent(params?.partnerName || "")}
              placeholder="파트너명"
              readonly={true}
              onKeyUp={() => setVisible(true)}
              onClearClick={() => {
                const temp = _.cloneDeep(params);
                delete temp.partnerName;
                delete temp.partnerId;
                setParams({ ...temp });
              }}
              onSearchClick={() => setVisible(true)}
            />
            {visible && (
              <PartnerSelectModal
                defaultValues={partners || []}
                onAdded={settingPartners}
                onCanceled={closePartnerPopup}
              />
            )}
          </div>
          <div className="minmax140 mr8">
            <BaseInput
              type="text"
              value={decodeURIComponent(params?.buildingName || "")}
              placeholder="건물 선택"
              onChange={(keyword: string) => {
                const temp = _.cloneDeep(building);
                if (temp) {
                  temp.buildingName = keyword;
                  setBuilding(temp);
                } else {
                  const temp2: any = {
                    buildingName: keyword,
                  };
                  setBuilding(temp2);
                }
                setParams({ ...params, ...{ buildingName: keyword } });
              }}
              onKeyUp={() => setIsBuildingOpen(true)}
              onSearchClick={() => setIsBuildingOpen(true)}
            />
            <SearchBuildingPopup
              isOpen={isBuildingOpen}
              buildingName={params?.buildingName}
              onClick={settingBuilding}
              onClose={() => setIsBuildingOpen(false)}
            />
          </div>
          <div className="minmax140 mr8">
            <BaseInput
              type="text"
              value={decodeURIComponent(params?.orgName || "")}
              placeholder="사업자 선택"
              onChange={(keyword: string) => {
                const temp = _.cloneDeep(org);
                if (temp) {
                  temp.name = keyword;
                  setOrg(temp);
                } else {
                  const temp2: any = {
                    name: keyword,
                  };
                  setOrg(temp2);
                }
                setParams({ ...params, ...{ orgName: keyword } });
              }}
              onKeyUp={() => setIsOrgOpen(true)}
              onSearchClick={() => setIsOrgOpen(true)}
            />
            <SearchOrgPopup
              isOpen={isOrgOpen}
              name={params?.orgName}
              columns={orgColumns}
              onClick={settingOrg}
              onClose={() => setIsOrgOpen(false)}
            />
          </div>
          <div className="minmax120 mr8">
            <BaseMultiSelect
              placeholder="계약상태"
              stateOptions={searchStatusOption}
              value={returnContractSteps || []}
              setStateValue={(options: Array<{ value: string; label: string }>) => {
                let status = "";
                options.map((obj: { value: string; label: string }) => {
                  if (status === "") {
                    status = obj.value;
                  } else {
                    status += "," + obj.value;
                  }
                });
                let dump = _.cloneDeep(params);
                dump.page = 0;
                dump.contractStep = status;
                navigateWithQueryParams(dump);
              }}
            />
          </div>
          <div className="minmax140 mr8">
            <BaseSelect
              value={params.searchType}
              stateOptions={searchTypeOption}
              setStateValue={(searchType: string) => setParams({ ...params, searchType })}
            />
          </div>
          <div className="minmax240 flex-center mr8">
            <BaseDatePicker
              name="searchStartTime"
              type="date"
              selectedDate={
                params.searchStartTime &&
                params.searchStartTime !== null &&
                params.searchStartTime !== ""
                  ? moment(params.searchStartTime).toDate()
                  : null
              }
              setDate={(searchStartTime: string) => {
                let select = "";
                if (
                  searchStartTime !== "" &&
                  searchStartTime !== null &&
                  searchStartTime !== "Invalid date"
                ) {
                  select =
                    moment(searchStartTime).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00";
                }

                setParams({
                  ...params,
                  ...{ searchStartTime: select },
                });
              }}
            />
            <span className="mx8 font14">~</span>
            <BaseDatePicker
              name="searchEndTime"
              type="date"
              selectedDate={
                params.searchEndTime && params.searchEndTime !== ""
                  ? moment(params.searchEndTime).subtract("9", "hours").toDate()
                  : null
              }
              setDate={(searchEndTime: string) => {
                let select = "";
                if (
                  searchEndTime !== "" &&
                  searchEndTime !== null &&
                  searchEndTime !== "Invalid date"
                ) {
                  select =
                    moment(searchEndTime).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00";
                }

                setParams({
                  ...params,
                  ...{
                    searchEndTime: select,
                  },
                });
              }}
            />
          </div>
          <div className="minmax220 mr16">
            <BaseInput
              type="text"
              value={decodeURIComponent(params?.searchValue || "")}
              placeholder="검색어를 입력해주세요"
              onChange={(searchValue: string) => setParams({ ...params, searchValue })}
              onKeyUp={() => navigateWithQueryParams({ page: 0 })}
              onSearchClick={() => navigateWithQueryParams({ page: 0 })}
            />
          </div>
        </div>
        <div className="right-area">
          {/* <BaseButton title="+ 신청/계약 생성" onClick={() => navigate("form")} /> */}
        </div>
      </div>
      {/* 샘플 */}
      <BaseTable
        data={data}
        columns={columns}
        pageIndex={Number(params?.page) || 0}
        totalPages={Number(pagination?.totalPages) || 0}
        goPage={(page: number) => {
          navigateWithQueryParams({ page });
        }}
        disabledSortHeaders={
          [
            // "contractApplyNumber",
            // "spaceProductId",
            // "createdDate",
            // "modifiedDate",
          ]
        }
        orders={params?.sort?.orders}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } });
          }
        }}
      />
    </div>
  );
};

export default ContractList;
