import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "src/api";
import { ImageRequestModal } from "src/pages/product/product-types";
import { MediaServiceType } from "../contract/contract-types";
import { ApiResponse, MediaFile } from "../public-types";
import {
  ProductAddModel,
  ProductDetailType,
  ProductEditModel,
  ProductListData,
  ProductListParams,
  ProductMediaFileList,
  ProductUpdateResponseData,
} from "./product-types";

// CPP01. 공간상품 기본정보 목록
export async function getProductListAsync(
  axios: AxiosInstance,
  params?: ProductListParams,
): Promise<AxiosResponse<ApiResponse<ProductListData>>> {
  return await axios.get<ApiResponse<ProductListData>>(ApiPath.product.list, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/* 
  CPP06. 공간상품 정보 조회 
*/
export async function getProductDetailAsync(
  axios: AxiosInstance,
  params?: {
    productId: number;
  },
): Promise<AxiosResponse<ApiResponse<ProductDetailType>>> {
  if (!params?.productId) {
    throw Error("productId is not found.");
  }
  const path = ApiPath.product.detail.replace("{id}", params.productId.toString());
  return await axios.get(path);
}

/* 
  CPP03. 공간상품 기본정보 등록
*/
export async function createProductAsync(
  axios: AxiosInstance,
  params?: {
    product: ProductAddModel;
  },
): Promise<AxiosResponse<ApiResponse<{ content?: string }>>> {
  return await axios.post(ApiPath.product.add.basicInfo, params);
}

/* 
  CPP04. 공간상품 정보 수정
*/
export async function updateProductAsync(
  axios: AxiosInstance,
  params?: {
    product: ProductEditModel;
  },
): Promise<AxiosResponse<ApiResponse<ProductUpdateResponseData>>> {
  if (!params?.product.id) {
    throw Error("id is not found.");
  }
  const path = ApiPath.product.edit.replace("{id}", params.product.id.toString());
  return await axios.put(path, params);
}

// CPP02. 공간상품 기본정보 상태 변경

export async function productStatusAsync(
  axios: AxiosInstance,
  params?: {
    productId: number;
    status: string;
  },
): Promise<AxiosResponse<ApiResponse<ProductUpdateResponseData>>> {
  const path = ApiPath.product.status.replace("{id}", String(params?.productId));
  return await axios.patch(path, { product: { status: params?.status } });
}

// CPP06. 공간상품 기본정보 삭제
export async function deleteProductsAsync(
  axios: AxiosInstance,
  params?: {
    productId: any;
  },
): Promise<AxiosResponse<ApiResponse<ProductUpdateResponseData>>> {
  const path = ApiPath.product.delete.replace("{id}", String(params?.productId));
  return await axios.delete(path);
}

// 공간상품 이미지 등록

export const postPrImageAsync = async (
  axios: AxiosInstance,
  parmas?: Array<MediaFile>,
): Promise<AxiosResponse<ApiResponse<Array<MediaFile>>>> => {
  return await axios.post("/api/central/pr/mediaFile", parmas);
};

// 공간상품 이미지 목록
export const getPrImageAsync = async (
  axios: AxiosInstance,
  params?: ImageRequestModal,
): Promise<AxiosResponse<ApiResponse<ProductMediaFileList>>> => {
  const imageParams = {
    serviceId: Number(params?.serviceId),
    mediaServiceType: String(params?.mediaServiceTypes.join()),
  };

  return axios.get(`/api/central/pr/mediaFile`, { params: imageParams });
};

// 공간상품 이미지 삭제
export const deletePrImageAsync = async (
  axios: AxiosInstance,
  params?: {
    ids: Array<number>;
  },
) => {
  const { ids } = params || {};

  return axios.delete("/api/central/pr/mediaFile", { data: { ids } });
};
