import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { BaseButton } from "src/components/BaseButton";
import useFileHandlers from "./hooks/useFileHandler";

interface SendFiles {
  setPrivateFiles: any;
  privateFiles: any;
  errorText?: string;
}

const FileUpload = ({ setPrivateFiles, privateFiles, errorText }: SendFiles) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const { onChange } = useFileHandlers({ privateFiles, setPrivateFiles });
  const [token, setToken] = useState("");
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();

  const onDeleteFile = useCallback(
    (e: any, index: number) => {
      e.preventDefault();
      const newArr: any = privateFiles.filter((fe: any, idx: number) => idx !== index);
      setPrivateFiles(newArr);
    },
    [privateFiles, setPrivateFiles],
  );

  const getToken = useCallback(async () => {
    // access token 가져오기
    await getAccessTokenSilently();

    // user IdToken 가져오기 (access token 을 먼저 가져와야 정상적으로 실행됨)
    const response = await getIdTokenClaims();
    const idToken = response?.__raw;
    setToken(idToken!);
  }, [getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    // setPrivateFiles(files);
    if (token === "") {
      getToken();
    }
  }, [getToken, privateFiles, setPrivateFiles, token]);

  return (
    <div className="contents-container__grid-contents">
      <div className="">
        <input
          type="file"
          name="img-loader-input"
          multiple
          ref={fileRef}
          onChange={onChange}
          style={{ display: "none" }}
        />

        <BaseButton
          title="첨부"
          className="color-white mb12"
          onClick={() => fileRef.current?.click()}
        />

        <div>
          <div className="pt2">
            {privateFiles &&
              privateFiles.map((file: any, index: number) => (
                <div key={`thumb${index}`} className="flex-center mb10">
                  {/* <Link to="" className="font14 text-hilight">
                    {file.filename}
                  </Link> */}
                  <a
                    className="font14 text-hilight"
                    href={file.url + "?access_token=" + token + "&forceDownload"}
                    rel="noreferrer"
                    key={index}
                  >
                    {file.filename}
                  </a>
                  <button
                    className="base-remove ml8 mt4"
                    onClick={(e) => onDeleteFile(e, index)}
                  ></button>
                </div>
              ))}
          </div>
          {errorText && <p className="validation-text">{errorText}</p>}
        </div>
      </div>
    </div>
  );
};
export default FileUpload;
