// 방문자 Table Columns
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { VisitorListModel } from "src/api/visitor/visitor-types";
import { BaseTooltip } from "src/components";
import { getPartnerDetailPage } from "src/pages/partner/utils";
import { VisitorDateCell } from "./VisitorDateCell";
import { VisitorLinkCell } from "./VisitorLinkCell";
import { formatPhoneNumber } from "src/utils";

export const columns = [
  {
    Header: "방문신청번호",
    accessor: "visitApplyNumber",
    width: 130,
    Cell: (props: Cell<VisitorListModel>) => <VisitorLinkCell {...props} />,
  },

  {
    Header: "신청번호",
    accessor: "contractApplyNumber",
    width: 120,
    Cell: (props: Cell<VisitorListModel>) => <VisitorLinkCell {...props} />,
  },
  {
    Header: "방문자 이름",
    accessor: "visitorName",
    width: 130,
    Cell: ({ value }: Cell<VisitorListModel>) => (
      <BaseTooltip contents={value} isSingleLine={true} />
    ),
  },
  {
    Header: "상태",
    accessor: "visitStatus",
    width: 90,
    Cell: ({ value }: Cell<VisitorListModel>) => {
      return (
        // visible 녹색
        // invisible 빨간색
        // draft = 회색
        <div
          className={`${
            (value === "VISIT_USE" && "chip visible") ||
            (value === "VISIT_PAUSE" && "chip draft") ||
            (value === "VISIT_DELETE" && "chip draft") ||
            (value === "VISIT_CLOSE" && "chip invisible")
          }`}
        >
          {(value === "VISIT_USE" && "사용") ||
            (value === "VISIT_PAUSE" && "일시정지") ||
            (value === "VISIT_DELETE" && "삭제") ||
            (value === "VISIT_CLOSE" && "종료")}
        </div>
      );
    },
  },
  {
    Header: "방문자 휴대폰번호",
    accessor: "visitorMobileNumber",
    width: 160,
    Cell: ({ value }: Cell<VisitorListModel>) => <p>{value ? formatPhoneNumber(value) : "-"}</p>,
  },
  {
    Header: "방문자 차량",
    accessor: "visitorCarNumber",
    width: 140,
    Cell: ({ value }: Cell<VisitorListModel>) => (
      <BaseTooltip contents={value} isSingleLine={true} />
    ),
  },
  {
    Header: "방문 일시",
    accessor: "visitStartTime",
    width: 110,
    Cell: (props: Cell<VisitorListModel>) => <VisitorDateCell {...props} />,
  },
  {
    Header: "공간 상품명",
    accessor: "spaceProductName",
    width: 180,
    Cell: ({ value }: Cell<VisitorListModel>) => <BaseTooltip contents={value} />,
  },
  {
    Header: "생성일",
    accessor: "createdDate",
    width: 110,
    Cell: (props: Cell<VisitorListModel>) => <VisitorDateCell {...props} />,
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 110,
    Cell: (props: Cell<VisitorListModel>) => <VisitorDateCell {...props} />,
  },

  {
    Header: "파트너",
    accessor: "partnerId",
    width: 110,
    Cell: (props: Cell<VisitorListModel>) => {
      if (!props.value) return <div>-</div>;
      return (
        <Link to={getPartnerDetailPage(props.value)} className="text-hilight" target={"_blank"}>
          {props.value}
        </Link>
      );
    },
  },
];
