import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, Row, useBlockLayout, useRowSelect, useTable } from "react-table";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { PageMeta, Sort } from "src/api/public-types";
import { BaseInput, BaseModal, BasePagination, BaseTooltip } from "src/components";
import TableRadioButton from "../../components/TableRadioButton";

type Props = {
  isOpen: boolean;
  close: (selectedProvider?: any) => void;
  selectedProvider?: any;
};

type QueryParams = {
  page?: number;
  size?: number;
  sort?: Sort;
  searchValue?: string;
  partnerId?: string;
  supplyType?: string;
};

const ProvidersModal = ({ isOpen, close, selectedProvider }: Props) => {
  const [providers, setProviders] = useState<Array<ProviderModel>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [pageNum, setPageNum] = useState(0);

  // 프롤바이더 목록 api
  const { executeAsync: getProviers } = useApiOperation(getProviderList);

  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse("", {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }

    if (!_queryParams.size) {
      _queryParams.size = 20;
    }

    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "providerId", direction: "DESC" }],
      };
    }

    if (!_queryParams.supplyType) {
      _queryParams.supplyType = "RENTAL";
    }

    if (!_queryParams?.partnerId) {
      _queryParams.partnerId = selectedProvider.partnerId;
    }

    return _queryParams;
  }, [selectedProvider]);

  const [params, setParams] = useState<QueryParams>({ ...queryParams });

  const getProviderListApi = useCallback(
    async (param) => {
      const response = await getProviers(param);
      if (response.status >= 200 && response.status <= 299) {
        setProviders(response.data.data.content);
        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getProviers],
  );

  useEffect(() => {
    if (isOpen) {
      getProviderListApi(queryParams);
    }
  }, [getProviderListApi, queryParams, isOpen]);

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "providerId",
        width: 100,
      },
      {
        Header: "프로바이더 명",
        accessor: "providerName",
        width: 180,
      },
      {
        Header: "사업자 등록 번호",
        accessor: "businessRegistrationNumber",
        width: 200,
      },
      {
        Header: "등록일",
        accessor: "createdDate",
        width: 150,
        Cell: (props: Cell<ProviderModel>) => {
          return <BaseTooltip contents={props.value} type="date" />;
        },
      },
      {
        Header: "최종 수정일",
        accessor: "modifiedDate",
        width: 150,
        Cell: (props: Cell<ProviderModel>) => {
          return <BaseTooltip contents={props.value} type="date" />;
        },
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    rows,
    toggleRowSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: providers,

      initialState: { selectedRowIds: { 0: true } },
    },
    useBlockLayout,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          id: "selection",
          width: 100,
          Header: () => <div className="">선택</div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
            let currentState = row.getToggleRowSelectedProps();
            return (
              <div className="minmax100">
                <TableRadioButton
                  id={row.id}
                  onClick={() => {
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id, true);
                    // setSelectedBuilding(row.original);
                  }}
                  name="selectedProvider"
                  // value={}
                  {...currentState}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  useEffect(() => {
    // TODO: 이미 선택되어있는 row index 찾아서 바인딩
    if (selectedProvider && selectedProvider.uid) {
      const selectedRow = rows.find((row: Row<any>) => row.original.uid === selectedProvider.uid);
      if (selectedRow) {
        toggleRowSelected(selectedRow.id, true);
      }
    }
  }, [selectedProvider]);

  const onSearchList = useCallback(
    async (page?: number) => {
      queryParams.page = page;
      queryParams.searchValue = params.searchValue;
      const response = await getProviers(queryParams);

      if (response.status >= 200 && response.status <= 299) {
        setProviders(response.data.data.content);
        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getProviers, params.searchValue, queryParams],
  );

  const providerPagination = useCallback(
    (passParams?: QueryParams) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const page = newQueryParams.page;
      setPageNum(Number(page));
      onSearchList(page);
    },
    [onSearchList, params],
  );

  return (
    <BaseModal
      className="dialog-modal"
      isOpen={isOpen}
      btnRightTitle="선택"
      btnLeftTitle="취소"
      onClose={() => close()}
      onClick={() => {
        if (selectedFlatRows && selectedFlatRows.length > 0) {
          close(selectedFlatRows[0].original);
        } else {
          close();
        }
      }}
    >
      <>
        <div className="modal-title">
          <div className="left-area">
            <span>프로바이더 선택</span>
          </div>
          <div className="right-area">
            <div className="minmax260">
              <BaseInput
                type="text"
                placeholder="프로바이더 명 또는 사업자 등록번호 입력"
                onChange={(value: string) => {
                  setParams({ ...params, searchValue: value });
                }}
                value={params.searchValue}
                onSearchClick={() => providerPagination({ page: 0 })}
                onKeyUp={() => providerPagination({ page: 0 })}
              />
            </div>
          </div>
        </div>

        <div className="page-product-list">
          <div className="contents-container__table">
            <div className="contents-container__search-wrap"></div>
            {/* 테이블 부분 */}
            <div className="contents-container__table">
              <div {...getTableProps()} className="base-table sticky">
                <div className="header">
                  {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                      {headerGroup.headers.map((header) => {
                        return (
                          <div {...header.getHeaderProps()} className="base-table__th">
                            {header.render("Header")}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
                <div {...getTableBodyProps()} className="body">
                  {rows.map((row, idx: number) => {
                    prepareRow(row);
                    return (
                      <div {...row.getRowProps()} className="base-table__tr">
                        {row.cells.map((cell) => {
                          return (
                            <div {...cell.getCellProps()} className="base-table__td">
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <BasePagination
          pageIndex={pageNum}
          totalPages={pageMeta?.totalPages || 0}
          goPage={(page: number) => {
            providerPagination({ page });
          }}
        />
      </>
    </BaseModal>
  );
};
export default ProvidersModal;
