import React from "react";
import { ProviderAssignedWorkType, ProviderContactModel } from "src/api/provider/provider-types";
import { formatPhoneNumber } from "src/utils";

type Props = { providerContacts?: Array<ProviderContactModel>; type: ProviderAssignedWorkType };

const ProviderContactTable = ({ providerContacts, type }: Props) => {
  return (
    <article className="contents-container__1200 mb30">
      <div className="contents-container__sub-title mb20">
        <h2>
          {type === "CS_PROVIDE"
            ? "CS/문의"
            : type === "NORMAL_PROVIDE"
            ? "계약 진행 담당"
            : "세금 계산서 발행"}
        </h2>
      </div>
      <section className="contents-container__1200">
        <table className="inner-table" width="100%">
          <thead>
            <tr>
              <th>
                <span>No</span>
              </th>
              <th>
                <span>이름</span>
              </th>
              <th>
                <span>연락처</span>
              </th>
              <th>
                <span>이메일</span>
              </th>
              <th>
                <span>비고</span>
              </th>
              <th>
                <span></span>
              </th>
            </tr>
          </thead>
          <tbody>
            {providerContacts ? (
              providerContacts.map((contact: ProviderContactModel, idx: number) => (
                <tr key={contact.contactId}>
                  {contact.assignedWorkType === type && (
                    <>
                      <td width="100">
                        <div className="text-center">
                          <span>{idx + 1}</span>
                        </div>
                      </td>
                      <td width="100">
                        <div className="flex-center-center">{contact.name}</div>
                      </td>
                      <td width="150">
                        <div className="text-center">
                          <p>{contact.contact ? formatPhoneNumber(contact.contact) : "-"}</p>
                        </div>
                      </td>
                      <td width="200">
                        <div className="text-center minmax200">
                          <p>{contact.email}</p>
                        </div>
                      </td>
                      <td width="auto">
                        <div className="text-center ">
                          <p>{contact.description}</p>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              ))
            ) : (
              <p>정보가 없습니다</p>
            )}
          </tbody>
        </table>
      </section>
    </article>
  );
};

export default ProviderContactTable;
