import moment from "moment";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import PagePath from "src/pagePath.json";
import { getHours } from "src/pages/contract/contract-util";
import { formatPhoneNumber, numberToStringWithComma, YmdFormat } from "src/utils";

const ServiceType = ({
  application,
  memberInfo,
  spaceProduct,
  productDetail,
  providerDetail,
  partner,
}: any) => {
  const goMemberDetail = (id: number) => {
    let path = "";
    if (id) {
      path = PagePath.member.detail.replace(":id", id!.toString());
    }
    return path;
  };
  const goProductDetail = (id: number) => {
    let path = "";
    if (id) {
      path = PagePath.product.detail.replace(":id", id!.toString());
    }
    return path;
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <article className="pb20">
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>신청자 정보</h2>
          </div>
          <div className="flex-center">
            {/* 회원번호 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>회원번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  <Link
                    to={goMemberDetail(memberInfo?.id)}
                    className="text-hilight"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {memberInfo?.memberNo}
                  </Link>
                </p>
              </div>
            </section>

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>휴대폰</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{formatPhoneNumber(memberInfo?.phoneNumber)}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 이메일 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>이메일</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{memberInfo?.email}</p>
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200">
          <div className="contents-container__sub-title">
            <h2>상품 정보</h2>
          </div>
          <div className="flex-center">
            {/* 상품 id */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>상품 id</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  <Link
                    to={goProductDetail(spaceProduct?.id)}
                    className="text-hilight"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {spaceProduct?.id}
                  </Link>
                </p>
              </div>
            </section>

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>
                  이용료
                  <br />
                  (10분당/부가세포함/원)
                </p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  { productDetail?.rentalCostList?.[0]?.value1
                    ? numberToStringWithComma(
                        Number(productDetail?.rentalCostList?.[0]?.value1) +
                          Number(productDetail?.rentalCostList?.[0]?.value1 / 10),
                      )
                    : "0"}
                </p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 상품명 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>상품명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{spaceProduct?.productName}</p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 타입 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>타입</p>
              </div>
              <div className="contents-container__grid-contents">
                {productDetail?.productType === "TIME_COURT" && "공간예약"}
              </div>
            </section>
          </div>
          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>프로바이더 명</p>
              </div>
              <div className="contents-container__grid-contents">
                {productDetail?.providerId ? (
                  <div>
                    <Link
                      to={PagePath.provider.detail.replace(":id", productDetail?.providerId)}
                      target="_blank"
                      className="text-hilight"
                    >
                      {providerDetail?.provider?.providerName}
                    </Link>
                  </div>
                ) : (
                  <p>-</p>
                )}
              </div>
            </section>
          </div>
        </div>
      </article>
      <article className="contents-container__divide-top">
        <div className="contents-container__1200 ">
          <div className="contents-container__sub-title">
            <h2>신청기간</h2>
          </div>
          <div className="flex-center">
            {/* 이용 시작 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>신청일</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{moment(application?.useStartTime).format(YmdFormat.YYYY_MM_DD)}</p>
              </div>
            </section>
            {/* 계약기간(MM) */}

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>신청 시간</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {application?.useStartTime && application?.useEndTime
                    ? moment(application?.useStartTime).format("HH:mm") +
                      " ~ " +
                      moment(application?.useEndTime).format("HH:mm") +
                      " (" +
                      getHours(application?.useStartTime, application?.useEndTime) +
                      ")"
                    : "-"}
                </p>
              </div>
            </section>
          </div>
        </div>
      </article>
    </>
  );
};

export default ServiceType;
