import { useEffect, useState } from "react";
import { AccessDeviceModel } from "src/api/access/ac2-types";
import { BaseButton } from "src/components";

type Props = {
  devices: AccessDeviceModel[];
  type?: "DETAIL" | "FORM"; // 상세, 수정혹은등록
  handleDeleteDevice?: Function;
};

/*
 * 출입그룹 관리 > 상세, 수정, 등록 > 장치 목록 테이블
 */
const DevicesTable = ({ devices, type = "DETAIL", handleDeleteDevice }: Props) => {
  return (
    <article className="contents-container__1200 mb30">
      <section className="contents-container__1200">
        <table className="inner-table access-group-table mt10">
          <thead>
            <tr>
              <th>
                <span>id</span>
              </th>
              <th>
                <span>장치 id</span>
              </th>
              <th>
                <span>장치 이름</span>
              </th>
              {type === "FORM" && <th></th>}
            </tr>
          </thead>
          <tbody>
            {devices.length > 0 ? (
              devices.map((device: AccessDeviceModel, idx: number) => (
                <tr key={device.id}>
                  <>
                    <td>
                      <div className="text-center">
                        <span>{device.id}</span>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <p>{device.externalDeviceId}</p>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <p>{device.deviceName}</p>
                      </div>
                    </td>
                    {type === "FORM" && handleDeleteDevice && (
                      <td>
                        <button
                          className="base-trash-btn"
                          onClick={() => handleDeleteDevice(device.id!)}
                        ></button>
                      </td>
                    )}
                  </>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>
                  <p className="flex-center-center w-100">데이터가 없습니다</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </article>
  );
};

export default DevicesTable;
