import React, { useCallback, useEffect, useState } from "react";
import { MediaFile } from "src/api/public-types";
import ImagesDetail from "src/components/ImagesDetail";

type Props = {
  type: string;
  images: MediaFile[];
};

const ImageCategoryDetail = ({ type, images }: Props) => {
  const [filteredImage, setFilteredImage] = useState<Array<MediaFile>>([]);

  useEffect(() => {
    if (images) {
      setFilteredImage(images.filter((image) => image.mediaServiceType === type));
    }
  }, [images]);

  const getCategoryName = useCallback((category: string) => {
    let typeName = "";

    switch (category) {
      case "MEDIA_PRODUCT_OFFICE":
        typeName = "사무공간";
        break;

      case "MEDIA_PRODUCT_FLOOR_PLAN":
        typeName = "평면도";
        break;
      case "MEDIA_PRODUCT_MEETING_ROOM":
        typeName = "회의실";
        break;
      case "MEDIA_PRODUCT_TOILET":
        typeName = "화장실";
        break;
      case "MEDIA_PRODUCT_DESK":
        typeName = "좌석";
        break;
      case "MEDIA_PRODUCT_REFRESH_ROOM":
        typeName = "편의시설";
        break;
      case "MEDIA_PRODUCT_TOILET":
        typeName = "화장실";
        break;
      case "MEDIA_PRODUCT_PARKING_LOT":
        typeName = "주차장";
        break;
      case "MEDIA_PRODUCT_FACILITY":
        typeName = "시설";
        break;
      case "MEDIA_PRODUCT_ETC":
        typeName = "기타";
        break;
    }
    return typeName;
  }, []);

  return (
    <div>
      <section className="mb30">
        <div className="contents-container__grid ">
          <div className="contents-container__grid-index ">
            <p className="no-wrap">{`공간상품 / ${type && getCategoryName(type)} (${
              filteredImage.length
            }/10)`}</p>
          </div>
        </div>
        {filteredImage.length ? (
          <ImagesDetail
            type={type}
            images={filteredImage}
            isUsedRepresentative={true}
            isUsedDescription={true}
            isUsedSelfDescription={true}
          />
        ) : (
          <p className="font13">등록된 이미지가 없습니다</p>
        )}
      </section>
    </div>
  );
};

export default ImageCategoryDetail;
