import React from "react";

interface Props {
  className?: string;
  value?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  errorText?: string;
  maxLength?: number;
  onChange?: Function;
  onKeyUpEnter?: Function;
  onSearchClick?: Function;
}

/* 
  휴대폰번호 입력 input
  '+', 숫자만 입력가능.
  input, output 둘다 string 타입
*/
export const BaseMobileNumberInput = ({
  className,
  value,
  name,
  placeholder,
  disabled,
  readonly,
  errorText,
  maxLength,
  onChange,
  onKeyUpEnter,
  onSearchClick,
}: Props) => {
  // +, 숫자만 입력가능
  const replaceNumberWithPlusMinus = (str: string) => {
    return str.replace(/[^\d+]+/g, "");
  };

  return (
    <div
      className={`base-input ${className ? className : ""} ${onSearchClick ? "base-search" : ""}`}
    >
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (onChange) {
            const targetValue = e.target.value;
            const replacedValue = replaceNumberWithPlusMinus(targetValue);
            onChange(replacedValue, e);
          }
        }}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
          e.preventDefault();
          if (e.key === "Enter") {
            if (onKeyUpEnter) {
              onKeyUpEnter();
            }
          }
        }}
        value={value || ""}
        readOnly={readonly}
        maxLength={maxLength || 100}
        disabled={disabled}
      />
      {onSearchClick && (
        <button
          className="base-search-btn"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            if (onSearchClick) {
              onSearchClick();
            }
          }}
        ></button>
      )}
      {errorText && <p className="validation-text">{errorText}</p>}
    </div>
  );
};
