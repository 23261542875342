import moment from "moment";
import { Link } from "react-router-dom";
import { ContractStep } from "src/api/contract/contract-types";
import { BaseTooltip } from "src/components";
import PagePath from "src/pagePath.json";
import { numberToStringWithComma, YmdFormat } from "src/utils/common-util";

const BillIngScheduleColumns: any = [
  {
    Header: "id",
    accessor: "contractId",

    sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "contractId",
        width: 80,
        Cell: ({ value, row }: any) => {
          // console.log('contractId:',value);
          const path = PagePath.billing.detail.replace(":id", row.original.contractId);
          return (
            <>
              <Link to={path} className={"text-hilight"}>
                {value}
              </Link>
            </>
          );
        },
      },
    ],
    Cell: ({ value, row }: any) => {},
  },
  {
    Header: "신청번호",
    accessor: "contractApplyNumber",

    sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "contractApplyNumber",
        width: 120,
        Cell: ({ value, row }: any) => {
          // console.log('contractId:',value);
          const path = PagePath.contract.detail.replace(":id", row.original.contractId);
          return (
            <>
              {/* <Link to={path} target="_blank" className={"text-hilight"}> */}
              {value}
              {/* </Link> */}
            </>
          );
        },
      },
    ],
    Cell: ({ value, row }: any) => {},
  },
  {
    Header: "상태",
    accessor: "contractStep",
    width: 80,
    sticky: "left",
    columns: [
      {
        Header: "",
        width: 100,
        accessor: "contractStep",
        Cell: ({ value, row }: any) => {
          return (
            //visible 녹색
            //invisible 빨간색
            //draft = 회색
            <div
              className={`${
                (value === ContractStep.APPLY_RECEIVED && "chip gray") ||
                (value === ContractStep.APPLY_CANCEL && "chip gray") ||
                (value === ContractStep.APPLY_CONFIRM && "chip blue") ||
                (value === ContractStep.CONTRACT_PLAN && "chip draft") ||
                (value === ContractStep.CONTRACT_ACCEPT && "chip blue") ||
                (value === ContractStep.USE_APPROVAL && "chip visible") ||
                (value === ContractStep.USE_PROGRESS && "chip visible") ||
                (value === ContractStep.USE_COMPLETE && "chip gray") ||
                (value === ContractStep.TERMINATE_RECEIVED && "chip invisible") ||
                (value === ContractStep.TERMINATE_COMPLETE && "chip gray") ||
                (value === "DISABLED" && "chip invisible") ||
                (value === "DRAFT" && "chip draft")
              }`}
            >
              {(value === ContractStep.APPLY_RECEIVED && "신청접수") ||
                (value === ContractStep.APPLY_CANCEL && "신청취소") ||
                (value === ContractStep.APPLY_CONFIRM && "신청확인") ||
                (value === ContractStep.CONTRACT_PLAN && "계약예정") ||
                (value === ContractStep.CONTRACT_ACCEPT && "계약체결") ||
                (value === ContractStep.USE_APPROVAL && "이용승인") ||
                (value === ContractStep.USE_PROGRESS && "이용중") ||
                (value === ContractStep.USE_COMPLETE && "이용완료") ||
                (value === ContractStep.TERMINATE_RECEIVED && "해지접수") ||
                (value === ContractStep.TERMINATE_COMPLETE && "해지완료")}
            </div>
          );
        },
      },
    ],
    Cell: ({ value }: any) => {},
  },
  {
    Header: "법인명/상호",
    accessor: "mbOrganizationName",
    sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "mbOrganizationName",
        width: 160,
        Cell: ({ value, row }: any) => {
          return <div className="w-100 text-left"><p>{value ? value : "-"}</p></div>;
        },
      },
    ],
    Cell: ({ value, row }: any) => {},
  },
  {
    Header: "계약자명",
    accessor: "applicantName",

    sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "applicantName",
        width: 120,
        Cell: ({ value, row }: any) => {
          return <div className="w-100 text-left"><p>{value ? value : "-"}</p></div>;
        },
      },
    ],
    Cell: ({ value, row }: any) => {},
  },
  {
    Header: "상품명",
    accessor: "spaceProductName",

    sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "spaceProductName",
        width: 160,
        Cell: ({ value }: any) => {
          return (
            <div className="w-100 text-left ellipsis2">
              <BaseTooltip contents={value} />
            </div>
          );
        },
      },
    ],
    Cell: ({ value }: any) => {},
  },

  {
    Header: "이용 기간",
    accessor: "contractStartTime",

    sticky: "",
    columns: [
      {
        Header: "",
        accessor: "contractStartTime",
        width: 240,
        Cell: ({ value, row }: any) => {
          const start = moment(row.original.contractStartTime).format(YmdFormat.YYYY_MM_DD);
          const end = moment(row.original.contractEndTime).format(YmdFormat.YYYY_MM_DD);
          return <p>{start + " ~ " + end}</p>;
        },
      },
    ],
    Cell: ({ value, row }: any) => {},
  },

  {
    Header: "이용료",
    accessor: "billAmount",
    sticky: "",

    columns: [
      {
        Header: "청구금액",
        accessor: "billAmount",
        width: 180,
        Cell: ({ value }: any) => {
          return <p>{numberToStringWithComma(value)}</p>;
        },
      },
      {
        Header: "상태",
        accessor: "billPayStatus",
        width: 80,
        Cell: ({ value, row }: any) => {
          // console.log(value);
          // PAYMENT_READY : 결제대기
          // PAYMENT_SUCCESS : 성공-결제성공
          // PAYMENT_FAIL : 실패-결제실패
          // PAYMENT_CANCEL : 취소-결제취소
          // CONTRACT_CANCEL : 해지-계약해지 빌링스케줄실행 중지
          return (
            <div
              className={`${
                (value === "PAYMENT_READY" && "chip gray") ||
                (value === "PAYMENT_FREE" && "chip gray") ||
                (value === "PAYMENT_SUCCESS" && "chip green") ||
                (value === "PAYMENT_FAIL" && "chip red") ||
                (value === "CONTRACT_CANCEL" && "chip red") ||
                (value === "PAYMENT_CANCEL" && "chip red")
              }`}
            >
              {value
                ? (value === "PAYMENT_READY" && "대기") ||
                  (value === "PAYMENT_FREE" && "무료") ||
                  (value === "PAYMENT_SUCCESS" && "성공") ||
                  (value === "PAYMENT_FAIL" && "실패") ||
                  (value === "CONTRACT_CANCEL" && "중지") ||
                  (value === "PAYMENT_CANCEL" && "취소")
                : "-"}
            </div>
          );
        },
      },
      {
        Header: "연체여부",
        accessor: "isOverdue",
        width: 80,
        Cell: ({ value, row }: any) => {
          // console.log(value);
          // PAYMENT_READY : 결제대기
          // PAYMENT_SUCCESS : 성공-결제성공
          // PAYMENT_FAIL : 실패-결제실패
          // PAYMENT_CANCEL : 취소-결제취소
          // CONTRACT_CANCEL : 해지-계약해지 빌링스케줄실행 중지
          // value = true;
          return (
            <div
              className={`${
                (value === undefined && "chip green") ||
                (String(value) === "false" && "chip green") ||
                (String(value) === "true" && "chip red")
              }`}
            >
              {String(value) === "true" ? "연체" : "정상"}
            </div>
          );
        },
      },
    ],
    Cell: ({ value }: any) => {},
  },
];

export default BillIngScheduleColumns;
