import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { DeviceLabelModel } from "src/api/access/ac2-types";
import { useApiOperation } from "src/api/hooks";
import { Modal } from "src/api/public-types";
import { getCsItemLabelsAsync, putCsItemLabelsAsync } from "src/api/voc/voc-api";
import { CsLabels } from "src/api/voc/voc-types";
import { BaseButton, BaseInput, BaseModal, BaseTextarea } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { useLoadingBarContext } from "src/pages/hooks";
import { v4 as uuidv4 } from "uuid";

type Props = {
  vocId: number;
  setLabelsModal: Dispatch<SetStateAction<Modal>>;
  setLabels: Dispatch<SetStateAction<DeviceLabelModel[]>>;
  fetchLabels?: any;
};
type FieldArray = {
  labels: CsLabels[];
};
export const LabelsModal = ({ setLabelsModal, setLabels, vocId, fetchLabels }: Props) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<Modal>({ isOpen: false });
  const [labelJSON, setLabelJSON] = useState<string>("");
  const { executeAsync: getCsItemLabels } = useApiOperation(getCsItemLabelsAsync);
  const { executeAsync: putCsItemLabels } = useApiOperation(putCsItemLabelsAsync);

  const { setLoadingBar } = useLoadingBarContext();

  const initialLabels: CsLabels[] = useMemo(() => [{ name: "", value: "" }], []);

  const {
    setValue,
    register,
    control,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldArray>({
    defaultValues: {
      // labels : []
    },
  });
  const {
    fields: lableList,
    append,
    remove,
    update,
  } = useFieldArray({
    control: control,
    name: "labels",
  });

  const setData = (status: number, labels: CsLabels[]) => {
    if (status >= 200 && status < 300) {
      if (labels.length > 0) {
        setValue("labels", labels);
        setLabelJSON(JSON.stringify(labels, null, 2));
      } else {
        setValue("labels", initialLabels);
      }
    }
  };

  useEffect(() => {
    const fetchApi = async (vocId: number) => {
      setLoadingBar(true);
      const { data, status } = await getCsItemLabels({
        csItemId: vocId,
      });
      const labelList = data.data?.labels;
      const filteredData = labelList?.map(({ name, value }) => ({ name, value }));
      setData(status, filteredData);

      setLoadingBar(false);
    };

    if (vocId) {
      fetchApi(vocId);
    }
  }, []);

  const editLabels = async (labels: CsLabels[]) => {
    setLoadingBar(true);
    const payload = labels ? labels : [];
    const { data, status } = await putCsItemLabels({
      csItemId: Number(vocId),
      labels: payload,
    });
    if (status >= 200 && status < 300) {
      setIsConfirmModalOpen({ isOpen: false });
      setLabelsModal({ isOpen: false });
      fetchLabels();
    }
    setLoadingBar(false);
  };

  const handleChangeKeyValue = () => {
    try {
      const parsingLabels = JSON.parse(labelJSON);
      if (typeof parsingLabels === "object") {
        setValue("labels", parsingLabels);
      }
    } catch (e) {
      setIsConfirmModalOpen({
        isOpen: true,
        message: "올바른 JSON 형식이 아닙니다.",
        type: "checkJSON",
      });
    }
  };
  const handleChangeJSON = () => {
    try {
      const stringifyLabels = JSON.stringify(getValues("labels"), null, 2);
      setLabelJSON(stringifyLabels);
    } catch (e) {
      return false;
    }
  };

  const onSubmit = (data: FieldArray) => {
    setIsConfirmModalOpen({
      isOpen: true,
      message: "키 값으로 수정됩니다. 수정하시겠습니까?",
      payload: data.labels,
    });
  };
  return (
    <BaseAbstractModal isOpen={true} className="labels-modal">
      <form className="abstract-form-scroll" onSubmit={handleSubmit(onSubmit)}>
        <section className="base-abstract-modal__title">
          <h1>Labels</h1>
        </section>
        <section className="base-abstract-modal__contents">
          <div className="container">
            <div className="container__left">
              <div className="key-value-title">
                <h3 className="minmax250">키</h3>
                <div className="flex-center">
                  <h3 className="minmax220">값</h3>
                  <button
                    type="button"
                    className="base-add-btn ml10"
                    onClick={() => {
                      append(initialLabels);
                    }}
                  ></button>
                </div>
              </div>
              <div className="key-value-line">
                {lableList.map((lable: CsLabels, index: number) => {
                  return (
                    <div className="flex-center mb10" key={uuidv4() + lable.name}>
                      <div className="flex-center minmax250">
                        <Controller
                          control={control}
                          name={`labels.${index}.name`}
                          render={({
                            field: { onChange, value, name },
                            fieldState: { error, isDirty },
                          }) => {
                            return <BaseInput value={value} onChange={onChange} />;
                          }}
                        ></Controller>
                        <div className="minmax30 font14 pb3 flex-center-center">:</div>
                      </div>
                      <div className="flex-center">
                        <Controller
                          control={control}
                          name={`labels.${index}.value`}
                          render={({
                            field: { onChange, value, name },
                            fieldState: { error, isDirty },
                          }) => {
                            return (
                              <BaseInput className="minmax220" value={value} onChange={onChange} />
                            );
                          }}
                        ></Controller>
                        <button
                          type="button"
                          className="base-erase-btn ml10"
                          onClick={() => {
                            remove(index);
                          }}
                        ></button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="container__center">
              <button type="button" className="to-left" onClick={handleChangeKeyValue}></button>
              <button type="button" className="to-right" onClick={handleChangeJSON}></button>
            </div>
            <div className="container__right">
              <h3>JSON으로 확인</h3>
              <BaseTextarea
                value={labelJSON}
                height={200}
                className="mt20 pre-formatted"
                onChange={(value: string) => {
                  setLabelJSON(value);
                }}
              ></BaseTextarea>
            </div>
          </div>
        </section>
        <section className="base-abstract-modal__btn-wrap ">
          <BaseButton
            title={"취소"}
            className="color-white flex-center-center"
            onClick={() => setLabelsModal({ isOpen: false })}
          />
          <BaseButton title={"확인"} type="submit" className="flex-center-center" />
        </section>
        {isConfirmModalOpen.isOpen && (
          <BaseModal
            isOpen={true}
            btnLeftTitle={isConfirmModalOpen.type === "checkJSON" ? "" : "취소"}
            btnRightTitle={isConfirmModalOpen.type === "checkJSON" ? "확인" : "수정"}
            onClose={() => setIsConfirmModalOpen({ isOpen: false })}
            onClick={
              isConfirmModalOpen.type === "checkJSON"
                ? () => {
                    setIsConfirmModalOpen({ isOpen: false });
                  }
                : () => {
                    editLabels(isConfirmModalOpen.payload);
                    setIsConfirmModalOpen({ isOpen: false });
                  }
            }
          >
            <p>{isConfirmModalOpen.message}</p>
          </BaseModal>
        )}
      </form>
    </BaseAbstractModal>
  );
};
