import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import { Order, PageMeta, Select } from "src/api/public-types";
import { getVisitorsAsync } from "src/api/visitor/visitor-api";
import { VisitorListModel } from "src/api/visitor/visitor-types";
import {
  BaseButton,
  BaseInput,
  BaseModal,
  BaseSelect,
  BaseTable,
  ContentsTitle,
} from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext } from "src/pages/hooks";
import { Partner } from "src/types/partner";
import { changeListSearchPhoneNumber } from "src/utils";
import { Modal } from "../visitor-types";
import { columns } from "./columns/Columns";

// url query parameter 타입
type QueryParams = {
  page?: number;
  size?: number;
  sort?: {
    orders?: Array<Order>;
  };
  visitorStatus?: string;
  searchType?: string;
  searchValue?: string;
  contractApplyNumber?: string;
  partnerId?: string;
};

// 방문상태 options
const visitStatusOptions: Select[] = [
  { label: "전체", value: "" },
  { label: "사용", value: "VISITOR_USE" },
  { label: "일시정지", value: "VISITOR_PAUSE" },
  { label: "종료", value: "VISITOR_CLOSE" },
];

// 검색대상 options

const searchTypeOptions: Select[] = [
  { label: "전체", value: "" },
  { label: "방문자 이름", value: "VISITOR_NAME" },
  { label: "방문자 휴대폰번호", value: "VISITOR_MOBILE_NUMBER" },
  { label: "방문자 차량번호", value: "VISITOR_CAR_NUMBER" },
  { label: "공간상품 id", value: "SPACE_PRODUCT_ID" },
  { label: "공간상품명", value: "SPACE_PRODUCT_NAME" },
];

// 정렬기능 숨김
const disabledSortHeaders: string[] = [
  "contractApplyNumber",
  "visitorName",
  "visitorMobileNumber",
  "visitorCarNumber",
  // "visitStartTime",
  "spaceProductName",
  "createdDate",
  "modifiedDate",
  "visitStatus",
];

/* 
  방문자 > 목록화면
*/
const VisitorList = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [
          {
            property: "visitStartTime",
            direction: "DESC",
            ignoreCase: false,
            nullHandling: "NATIVE",
          },
        ],
      };
    } else {
      const newOrders = (_queryParams.sort.orders || []).map((order: Order) =>
        Object.assign(order, { ignoreCase: false, nullHandling: "NATIVE" }),
      );
      _queryParams.sort = { orders: newOrders };
    }
    return _queryParams;
  }, [location]);

  // 방문자 목록 조회 api
  const { executeAsync: getVisitors } = useApiOperation(getVisitorsAsync);

  // 파트너 상세 조회 api
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  const [partnerSelectModal, setPartnerSelectModal] = useState<Modal>({
    isOpen: false,
  });

  const [visitors, setVisitors] = useState<VisitorListModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [partner, setPartner] = useState<Partner>();
  const [isOpen, setIsOpen] = useState(false);

  // 받아온 query parameter 로 해당 컴포넌트에서 변경가능하기 위한 state 선언
  const [params, setParams] = useState<QueryParams>({ ...queryParams });

  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, params],
  );

  // 방문자 목록 조회 후 데이터 바인딩
  const fetchVisitors = useCallback(
    async (params?: QueryParams) => {
      let cloneParams = { ...params };
      setLoadingBar(true);
      if (cloneParams.searchType === "VISITOR_MOBILE_NUMBER") {
        cloneParams.searchValue = changeListSearchPhoneNumber(cloneParams.searchValue!);
      }

      const { data } = await getVisitors(cloneParams);
      if (queryParams.partnerId) {
        const { data: partnerData } = await getPartnerAsync({
          id: queryParams.partnerId!,
        });

        setPartner(partnerData.data.partner);
      }

      setVisitors(data?.data?.content || []);
      setPageMeta(data?.meta?.pageMeta);

      setLoadingBar(false);
    },
    [setLoadingBar, getVisitors],
  );

  // 검색 (신청/계약번호는 필수)
  const searchVisitors = useCallback(() => {
    if (!params?.contractApplyNumber) {
      setAlertModal({
        isOpen: true,
        message: "신청번호를 입력해주세요.",
      });
    } else {
      // 현재 params 로 1페이지로 검색
      navigateWithQueryParams({ page: 0 });
    }
  }, [navigateWithQueryParams, params?.contractApplyNumber]);

  // 방문자 초대 버튼 클릭
  const clickVisitorInvitation = useCallback(() => {
    if (!params?.contractApplyNumber || params?.contractApplyNumber.trim().length === 0) {
      setAlertModal({
        isOpen: true,
        message: "신청번호를 입력해주세요.",
      });
    } else {
      const visitorFormPath = PagePath.visitor.form.replace(
        ":contractApplyNumber",
        params.contractApplyNumber.trim(),
      );
      navigate(visitorFormPath);
    }
  }, [params?.contractApplyNumber, navigate]);

  const onCanceldPartnerSelectModal = () => setPartnerSelectModal({ isOpen: false });
  const onAddedPartnerSelectModal = (partnerList: Partner[]) => {
    if (partnerList.length > 0) {
      setPartner(partnerList[0]);
      setParams({ ...params, ...{ partnerId: partnerList[0].id } });
    } else {
      setPartner(undefined);
      setParams({ ...params, ...{ partnerId: undefined } });
    }

    setPartnerSelectModal({
      isOpen: false,
    });
  };

  const handleClickPartnerSearchButton = () => {
    setPartnerSelectModal({
      isOpen: true,
    });
  };

  useEffect(() => {
    // if (queryParams.contractApplyNumber) {
    fetchVisitors(queryParams);
    // }
  }, [queryParams, fetchVisitors]);

  return (
    <div className="page-product-list">
      {/* <ContentsTitle title="방문자" /> */}
      <div className="contents-container__search-wrap">
        <div className="left-area">
          {/* <p>
              전체 <span>{pageMeta?.totalElements || 0}</span>
            </p> */}
          <div className="minmax200 mr8">
            <BaseInput
              placeholder="파트너"
              disabled
              onSearchClick={handleClickPartnerSearchButton}
              value={partner?.name}
            />
          </div>
          <div className="minmax140 mr8">
            <BaseInput
              type="text"
              value={params.contractApplyNumber}
              placeholder="신청번호(필수)"
              onChange={(contractApplyNumber: any) =>
                setParams({ ...params, ...{ contractApplyNumber } })
              }
            />
          </div>
          <div className="minmax80 mr8">
            <BaseSelect
              placeholder="상태"
              value={params.visitorStatus}
              stateOptions={visitStatusOptions}
              setStateValue={(visitorStatus: string) => {
                setParams({ ...params, ...{ visitorStatus } });
              }}
            />
          </div>
          <div className="minmax140 mr8">
            <BaseSelect
              placeholder="검색 대상"
              value={params.searchType}
              stateOptions={searchTypeOptions}
              setStateValue={(searchType: string) => {
                setParams({ ...params, ...{ searchType } });
              }}
            />
          </div>
          <div className="minmax220 mr16">
            <BaseInput
              type="text"
              value={params?.searchValue || ""}
              placeholder="검색어를 입력해주세요"
              onChange={(searchValue: any) => setParams({ ...params, ...{ searchValue } })}
              onKeyUp={() => searchVisitors()}
              onSearchClick={() => searchVisitors()}
            />
          </div>
        </div>
        <div className="right-area">
          {/* <BaseButton title="+ 방문자 초대" onClick={() => clickVisitorInvitation()} /> */}
        </div>
      </div>
      <BaseTable
        data={visitors}
        columns={columns}
        pageIndex={Number(params?.page || 0)}
        totalPages={pageMeta?.totalPages || 0}
        goPage={(page: number) => navigateWithQueryParams({ page })}
        disabledSortHeaders={disabledSortHeaders}
        orders={params?.sort?.orders}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } });
          }
        }}
      />

      <BaseModal isOpen={isOpen} btnRightTitle={"확인"} onClick={() => setIsOpen(false)}>
        <p>등록되지 않은 신청번호입니다. 신청번호는 필수입니다.</p>
      </BaseModal>

      {/* 확인버튼만 있는 alert 모달 */}
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => setAlertModal({ isOpen: false })}
      >
        <p>{alertModal.message}</p>
      </BaseModal>
      {partnerSelectModal.isOpen && (
        <PartnerSelectModal
          onCanceled={onCanceldPartnerSelectModal}
          onAdded={onAddedPartnerSelectModal}
          defaultValues={partner ? [partner] : []}
        />
      )}
    </div>
  );
};

export default VisitorList;
