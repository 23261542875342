import React from "react";
import { Cell } from "react-table";
import { ProductModel } from "src/api/product/product-types";
import { getKoreanCost } from "src/utils";

const ProductDepositCell = (props: Cell<ProductModel>) => {
  const deposit = props?.row?.original?.deposit;

  return (
    <div>
      <span>{Number(deposit) > 0 ? getKoreanCost(Number(deposit)) : "-"}</span>
    </div>
  );
};

export default ProductDepositCell;
