import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getReservationUsagesAsync } from "src/api/reservation/reservation-api";
import {
  ReservationListModel,
  ReservationUsageResponse,
} from "src/api/reservation/reservation-types";
import { BaseTooltip } from "src/components";
import { PagePath } from "src/pages/product/details";
import { PhoneFomatter, YmdFormat, formatPhoneNumber } from "src/utils/common-util";

type Props = {
  reservation: ReservationListModel | null;
};

/* 
  공용공간 예약 상세 > 예약 내역 컴포넌트
 */
const ReservationHistorySection = ({ reservation }: Props) => {
  const [usage, setUsage] = useState<ReservationUsageResponse>();
  const { executeAsync: getReservationUsages } = useApiOperation(getReservationUsagesAsync); // 소진시간
  const reservationData = useCallback(
    (type: string) => {
      switch (type) {
        // 예약경로
        case "inboundChannel":
          return reservation?.inboundChannel || "-";
        // 건물명
        case "buildingName":
          const buildingName = reservation?.facility.building.name;
          if (buildingName) {
            return (
              <span className="no-wrap">
                <Link
                  to={`${PagePath.building.detail.replace(
                    ":id",
                    String(reservation?.facility.building.id),
                  )}?tab=facility`}
                  target="_blank"
                  className="text-hilight"
                >
                  {buildingName}
                </Link>
              </span>
            );
          }

          return "-";
        // 위치
        case "location":
          let isGround = "";
          const floorNum = reservation?.facility.floor;

          if (floorNum && floorNum > 0) {
            isGround = "지상";
          } else {
            isGround = "지하";
          }

          if (floorNum) {
            return (
              <span>
                {isGround}
                {floorNum}층
              </span>
            );
          }
          return "-";
        // 공간타입

        case "spaceType":
          let spaceType = "";

          if (reservation?.facility.type === "MEETING_ROOM") {
            spaceType = "회의실";
          } else if (reservation?.facility.type === "DESK") {
            spaceType = "좌석";
          } else if (reservation?.facility.type === "REFRESH_ROOM") {
            spaceType = "편의시설";
          } else {
            spaceType = "-";
          }

          if (spaceType) {
            return `${spaceType}`;
          }

          return "-";

        // 공용공간명
        case "spaceName":
          if (reservation?.facility) {
            return `${reservation?.facility.name}`;
          }

          return "-";

        // 예약일
        case "reserveDate":
          const date = moment(reservation?.start).format(YmdFormat.YYYY_MM_DD);
          if (reservation?.start && reservation?.end) {
            return `${date}`;
          }

          return "-";
        // 예약시간
        case "reserveTime":
          const startDate = moment(reservation?.start).format("HH:mm");
          const endDate = moment(reservation?.end).format("HH:mm");

          if (reservation?.start && reservation?.end) {
            return `${startDate} ~ ${endDate}`;
          }

          return "-";

        // 예약자 ID
        case "memberNo":
          if (reservation?.organizer.memberNo) {
            return <>{`${reservation?.organizer.memberNo}`}</>;
          }
          return "-";

        // 이메일
        case "email":
          if (reservation?.organizer.userEmail) {
            return <>{`${reservation?.organizer.userEmail}`}</>;
          }
          return "-";

        // 생성일
        case "createdDate":
          if (reservation?.createdDate) {
            return <>{moment(reservation?.createdDate).format(YmdFormat.FULL)}</>;
          }
          return "-";

        // 휴대폰번호
        case "phone":
          if (reservation?.organizer.phoneNumber) {
            return <>{formatPhoneNumber(reservation?.organizer.phoneNumber)}</>;
          }
          return "-";
      }
    },
    [reservation],
  );
  const { id } = useParams();
  useEffect(() => {
    const fetchUsage = async (id: string) => {
      const { data } = await getReservationUsages({ id });
      if (data.data) {
        setUsage(data.data);
      }
    };
    if (id && reservation) {
      fetchUsage(id);
    }
  }, [getReservationUsages, id, reservation]);
  return (
    <article className="mb30">
      <div className="contents-container__sub-title">
        <div className="minmax140 pb4">
          <h2>예약 내역</h2>
        </div>
      </div>
      <section className="contents-container__grid mt20">
        <div className="contents-container__grid-index">
          <p className="">예약경로</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("inboundChannel")}</span>
          </div>
        </div>
        <div className="contents-container__grid-index">
          <p className="">건물명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("buildingName")}</span>
          </div>
        </div>
        <div className="contents-container__grid-index">
          <p className="">위치</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("location")}</span>
          </div>
        </div>
        <div className="contents-container__grid-index">
          <p className="">공간타입</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("spaceType")}</span>
          </div>
        </div>
        <div className="contents-container__grid-index">
          <p className="">공간명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("spaceName")}</span>
          </div>
        </div>
        <div className="contents-container__grid-index">
          <p className="">예약일</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("reserveDate")}</span>
          </div>
        </div>
        <div className="contents-container__grid-index">
          <p className="">예약시간</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("reserveTime")}</span>
          </div>
        </div>
        <div className="contents-container__grid-index">
          <p className="">소진시간</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">
              {usage && Number(usage.duration) >= 0 ? `${String(usage.duration)} 분` : "-"}
            </span>
          </div>
        </div>
        <div className="contents-container__grid-index">
          <p className="">회원번호</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("memberNo")}</span>
          </div>
        </div>
        <div className="contents-container__grid-index">
          <p className="">휴대폰번호</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("phone")}</span>
          </div>
        </div>
        <div className="contents-container__grid-index">
          <p className="">이메일</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("email")}</span>
          </div>
        </div>
        <div className="contents-container__grid-index">
          <p className="">생성일</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("createdDate")}</span>
          </div>
        </div>
      </section>
    </article>
  );
};

export default ReservationHistorySection;
