import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAccessDeviceListAsync, getExternalServersAsync } from "src/api/access/ac2-api";
import { AccessDeviceListParams, AccessDeviceModel } from "src/api/access/ac2-types";
import { useApiOperation } from "src/api/hooks";
import { Order, PageMeta, Select } from "src/api/public-types";
import { BaseButton, BaseInput, BaseSelect, BaseTable, ContentsTitle } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import AccessDeviceListColumns from "./columns/AccessDeviceListColumns";

// feature :: 출입 시스템 셀렉트옵션은 외부출입시스템 조회 후 그려주므로 useEffect 내부에서 생성

// 검색 대상
const searchTargets = [
  { value: "", label: "전체" },
  { value: "externalDeviceId", label: "장치 id" },
  { value: "deviceName", label: "장치명" },
];

// 정렬기능 숨김
const disabledSortHeaders: string[] = ["externalServerNames"];

// url query parameter 타입
type QueryParams = AccessDeviceListParams & {
  page?: number;
  size?: number;
  externalServerId?: number | string;
  searchType?: string;
  searchValue?: string;
  sort?: {
    orders?: Array<Order>;
  };
};

/*
  출입장치 관리 목록 화면
*/
const AccessDeviceList = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const navigate = useNavigate();
  const location = useLocation();
  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "createdDate", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, [location]);

  const [accessDevices, setAccessDevices] = useState<AccessDeviceModel[]>([]);
  const [externalOptions, setExternalOptions] = useState<Select[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { executeAsync: getAccessList } = useApiOperation(getAccessDeviceListAsync);
  const { executeAsync: getExternalServerList } = useApiOperation(getExternalServersAsync);

  useEffect(() => {
    async function fetchApi(params: QueryParams) {
      setLoadingBar(true);

      // 외부출입시스템 목록 조회
      const { data: externalServerList } = await getExternalServerList();
      const externalServerData = externalServerList?.data?.content;

      let searchAccessSystemTypes = [{ value: "", label: "전체" }];
      const newSearchAccessSystemTypes = externalServerData.map((server) => {
        return { value: String(server.id!), label: server.serverName! };
      });

      searchAccessSystemTypes = [...searchAccessSystemTypes, ...newSearchAccessSystemTypes];
      setExternalOptions(searchAccessSystemTypes);

      // 디바이스 목록 조회
      const { data: accessDeviceList } = await getAccessList(params);
      const accessDeviceListData = accessDeviceList?.data?.content;
      const newAccessDeviceListData = accessDeviceListData.map((accessDevice) => {
        const findServerNames = externalServerData
          .filter((server) => {
            return server.id === accessDevice?.externalServer?.id;
          })
          .map((server) => server.serverName);
        return { ...accessDevice, externalServerNames: findServerNames.join("") };
      });

      setAccessDevices(newAccessDeviceListData);
      setPageMeta(accessDeviceList?.meta?.pageMeta);
      setLoadingBar(false);
    }
    fetchApi(queryParams);
  }, [queryParams, getAccessList, setLoadingBar, getExternalServerList]);

  // 받아온 query parameter 로 해당 컴포넌트에서 변경가능하기 위한 state 선언
  const [params, setParams] = useState<QueryParams>({ ...queryParams });

  const findDirection = () => {
    const findDirection = params?.sort?.orders?.find(
      (order: { property: string; direction: string }) => order.property === "createdDate",
    );
    return findDirection?.direction;
  };

  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  useEffect(() => {
    findDirection();
  });

  return (
    <div className="page-product-list">
      {/* <ContentsTitle title="출입장치 관리" /> */}
        <div className="contents-container__search-wrap">
          <div className="left-area">
            {/* <p>
              전체 <span>{pageMeta?.totalElements || 0}</span>
            </p> */}
             <div className="minmax120 mr8">
              <BaseSelect
                placeholder="출입 시스템"
                value={params?.externalServerId}
                stateOptions={externalOptions}
                setStateValue={(externalServerId: string) => {
                  setParams({ ...params, externalServerId });
                }}
              />
            </div>
            <div className="minmax120 mr8">
              <BaseSelect
                placeholder="검색 대상"
                value={params?.searchType}
                stateOptions={searchTargets}
                setStateValue={(searchType: string) => {
                  setParams({ ...params, searchType });
                }}
              />
            </div>
            <BaseInput
              value={params?.searchValue || ""}
              className="minmax220 mr16"
              type="text"
              placeholder="검색어를 입력해주세요"
              onChange={(searchValue: string) => {
                setParams({ ...params, searchValue });
              }}
              onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
              onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
            />
          </div>
          <div className="right-area">
            <BaseButton title="+ 장치 등록" onClick={() => navigate(PagePath.accessDevice.form)} />
          </div>
        </div>

        <BaseTable
          data={accessDevices}
          columns={AccessDeviceListColumns}
          pageIndex={Number(params?.page || 0)}
          totalPages={pageMeta?.totalPages || 0}
          goPage={(page: number) => {
            navigateWithQueryParams({ page }, "pagination");
          }}
          orders={params?.sort?.orders}
          disabledSortHeaders={disabledSortHeaders}
          setOrders={(orders?: Array<Order>) => {
            if (orders) {
              navigateWithQueryParams({ sort: { orders } }, "search");
            }
          }}
        />
    </div>
  );
};

export default AccessDeviceList;
