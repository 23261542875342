import _ from "lodash";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { useApiOperation } from "src/api/hooks";
import { getIssueCountUser, getIssueUserDetail, getUser, getUsers } from "src/api/user/user-api";

import { BaseButton, BaseInput, BaseModal, BasePagination, BaseRadio } from "src/components";
import MemberStatus from "src/components/userInfo/MemberStatus";
import { PagePath } from "../product/details";

const columnHeader: any = [
  {
    Header: "회원번호",
    accessor: "memberNo",
    width: 250,
  },
  {
    Header: "이메일",
    accessor: "userEmail",
    width: 250,
  },
  {
    Header: "상세화면",
    accessor: "channelId",
    // sticky: "left",
    width: 200,
    Cell: ({ value, row, contractManageId, contractApplyNumber }: any) => {
      const { executeAsync: getUserDetail } = useApiOperation(getIssueUserDetail);
      const navigate = useNavigate();
      const changeTrigger = useCallback(async () => {
        const res: any = await getUserDetail({
          contractManageId,
          memberNo: row?.original?.memberNo,
        });
        console.log("res", res);
        if (res.status > 199 && res.status < 300) {
          const path =
            PagePath.user.detail.replace(":memberId", res.data.data.content.contractMemberId) +
            "?contractApplyNumber=" +
            contractApplyNumber;
          window.open(path, "_blank", "noopener,noreferrer");
        }
      }, [contractApplyNumber, contractManageId, getUserDetail, row?.original?.memberNo]);
      return (
        <div>
          <BaseButton
            className=""
            title={"바로가기"}
            onClick={() => {
              changeTrigger();
            }}
          />
        </div>
      );
    },
  },
  {
    Header: "상태",
    accessor: "memberStatus",
    width: 200,
    Cell: ({ value }: any) => <MemberStatus label={value} />,
  },
];
type Props = {
  isOpen: boolean;
  contractManageId: number;
  contractApplyNumber: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const SearchContractUserPopup = (props: Props) => {
  const [data, setData] = useState<any>([]);

  const [pageMeta, setPageMeta] = useState<any>({});
  const [search, setSearch001] = useState("");
  const [params, setParams] = useState<any>({
    page: 0,
    size: 20,
    contractManageId: props.contractManageId,
    search001: "",
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columnHeader,
      data: data,
      contractManageId: props.contractManageId,
      contractApplyNumber: props.contractApplyNumber,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );

  //조회
  const { executeAsync: executeAsyncAll } = useApiOperation(getIssueCountUser);
  const { executeAsync: executeAsyncUsers } = useApiOperation(getUsers);

  const callList = useCallback(
    async (param: any) => {
      const response: any = await executeAsyncAll(param);
      if (response.status > 199 && response.status < 300) {
        if (response.data.data) {
          const data = response?.data?.data;
          const memberNoList = data.map((item: any) => item.memberNo);

          const usersResult = await executeAsyncUsers({
            contractApplyNumber: props.contractApplyNumber,
            memberNo: memberNoList.join(","),
          });

          const users = usersResult?.data?.data;

          const mappedData = data.map((item: any) => {
            const user = users.find((user: any) => user.memberNo === item.memberNo);
            return {
              ...item,
              memberStatus: user?.memberStatus,
            };
          });

          setData(mappedData);

          setPageMeta(response?.data?.meta?.pageMeta || []);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [executeAsyncAll],
  );

  useEffect(() => {
    if (props.isOpen) {
      let prs = _.cloneDeep(params);
      prs.sort = {
        orders: [{ property: "memberNo", direction: "DESC" }],
      };
      prs.contractManageId = props.contractManageId;
      prs.search001 = "";
      setSearch001("");
      callList(prs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <div>
      <BaseModal
        className="dialog-modal"
        isOpen={props.isOpen}
        btnRightTitle="닫힘"
        onClick={props.onClick}
      >
        <>
          <div className="modal-title">
            <div className="left-area">
              <span>캘린더 동기화 실패 조회</span>
            </div>
            <div className="right-area flex-center">
              <div className="mr16">
                <span>전체 {pageMeta?.totalElements}</span>
              </div>
              <div className="">
                <div className="minmax260"></div>
                <div className="minmax260">
                  <BaseInput
                    type="text"
                    value={search || ""}
                    placeholder="검색어를 입력해주세요"
                    onChange={(value: string) => {
                      setSearch001(value);
                    }}
                    onKeyUp={() => setParams({ ...params, ...{ search } })}
                    onSearchClick={() => {
                      setParams({ ...params, ...{ search } });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="contents-container__table">
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <div
                      {...row.getRowProps()}
                      className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <BasePagination
              pageIndex={Number(params?.page) || 0}
              totalPages={Number(pageMeta?.totalPages) || 0}
              goPage={(page: number) => {
                setParams({ ...params, ...{ page } });
                callList({ ...params, ...{ page } });
              }}
            />
          </div>
        </>
      </BaseModal>
    </div>
  );
};

export default SearchContractUserPopup;
