import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderAddModel } from "src/api/provider/provider-types";
import { BaseButton, ContentsIdSection } from "src/components";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { Partner } from "src/types/partner";
import ProviderBasicDetail from "./components/ProviderBasicDetail";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";

const BasicInfoDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const providerId = params.id;

  // 프로바이더 상세
  const [providerDetail, setProviderDetail] = useState<ProviderAddModel>();

  // 선택된 파트너 정보
  const [partner, setPartner] = useState<Partner>();

  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  // 파트너 상세조회
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  const { setLoadingBar } = useLoadingBarContext();

  // 파트너 api
  const fetchPartner = async (id: string) => {
    setLoadingBar(true);
    const response = await getPartnerAsync({ id });

    if (response.status >= 200 && response.status <= 299) {
      const result = response.data.data.partner;
      setPartner(result);
    }
    setLoadingBar(false);
  };

  const providerDetailApi = useCallback(
    async (id: string) => {
      setLoadingBar(true);

      const response = await getProviderDetail({ providerId: id });
      if (response.status >= 200 || response.status <= 299) {
        const result = response.data.data;
        setProviderDetail(result);
        fetchPartner(String(result.provider?.partnerId));
      }
      setLoadingBar(false);
    },
    [getProviderDetail],
  );

  useEffect(() => {
    if (providerId) {
      providerDetailApi(providerId);
    }
  }, [providerDetailApi, providerId]);
  useTitleOperation(providerDetail?.provider?.providerName);

  return (
    <div className="contents-container__wrap detail-basic-info">
      <div className="contents-container__wrap-contents">
        {providerDetail && (
          <>
            <ContentsIdSection title="기본정보" id={providerId} />

            {/* 프로바이더 기본정보 */}
            <ProviderBasicDetail
              partner={partner}
              providerBasic={providerDetail.provider}
              contractMedia={providerDetail.contractMediaList}
            />
          </>
        )}

        <AdminMemoV3
          serviceId={Number(providerId)}
          serviceType={ServiceTypes.SERVICE_PROVIDER}
          partnerId={partner?.id!}
        ></AdminMemoV3>

        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              className="color-white size-large"
              onClick={() => {
                if (providerId) {
                  navigate(PagePath.provider.list);
                } else {
                  navigate(PagePath.provider.list);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfoDetail;
