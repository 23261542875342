import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { CommonFacilityModel } from "src/api/building/building-types";
import { getCommonfacilityListAsync } from "src/api/building/commonfacility-api";
import { useApiOperation } from "src/api/hooks";
import { getPriceDetailAsync } from "src/api/price/price-api";
import {
  PriceModel,
  PriceRuleOptionTypeToText,
  PriceRuleType,
  PriceRuleTypeToText,
} from "src/api/price/price-types";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { Sort } from "src/api/public-types";
import { BaseButton, ContentsIdSection } from "src/components";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import GrayBox from "./GrayBox";
import moment from "moment";
import { numberToStringWithComma, YmdFormat } from "src/utils";
import RegistrationInformation from "src/components/RegistrationInformation";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import { getPartner } from "src/api/partner";
import { Partner } from "src/types/partner";

type QueryParams = {
  page?: number;
  size?: number;
  sort?: Sort;
};

const OrgBaseInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: detailId } = useParams();

  const { setLoadingBar } = useLoadingBarContext();

  const { executeAsync: getPriceDetail } = useApiOperation(getPriceDetailAsync);
  const { executeAsync: getProvider } = useApiOperation(getProviderList);
  const { executeAsync: getCommonFacilityList } = useApiOperation(getCommonfacilityListAsync);
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);
  const [priceDetail, setPriceDetail] = useState<PriceModel>();
  const [partner, setPartner] = useState<Partner>();
  const [provider, setProvider] = useState<ProviderModel>();
  const [facility, setFacility] = useState<CommonFacilityModel>();

  //   const queryParams: QueryParams = useMemo(() => {
  //     const _queryParams: QueryParams = qs.parse(location.search, {
  //       ignoreQueryPrefix: true,
  //       allowDots: true,
  //     });

  //     if (!_queryParams.page) {
  //       _queryParams.page = 0;
  //     }
  //     if (!_queryParams.size) {
  //       _queryParams.size = 3;
  //     }
  //     if (!_queryParams?.sort) {
  //       _queryParams.sort = {
  //         orders: [{ property: "adminNote.id", direction: "DESC" }],
  //       };
  //     }
  //     return _queryParams;
  //   }, [location]);

  const fetchPartner = useCallback(async (id: string) => {
    setLoadingBar(true);
    const response = await getPartnerAsync({ id });

    if (response.status >= 200 && response.status <= 299) {
      const result = response.data.data.partner;
      setPartner(result);
    }
    setLoadingBar(false);
  }, []);

  const goList = useCallback(() => {
    navigate(PagePath.organization.list);
  }, [navigate]);

  useEffect(() => {
    const fetchApi = async (detailId: string) => {
      const { data, status } = await getPriceDetail({ priceRuleIdList: detailId });
      if (status >= 200 && status < 300) {
        if (data.data.content[0]) {
          const detail = data.data.content[0];
          setPriceDetail(detail);
          const { data: providerListData } = await getProvider({
            providerId: detail.providerId,
          });
          setProvider(providerListData.data.content[0]);
          await fetchPartner(String(providerListData.data.content[0].partnerId));
          if (detail.buildingCommonFacilityId) {
            const buildingFacilityResponse = await getCommonFacilityList({
              searchType: "ID",
              keywordList: detail?.buildingCommonFacilityId?.toString(),
            });
            if (buildingFacilityResponse.status >= 200 && buildingFacilityResponse.status <= 299) {
              if (
                buildingFacilityResponse?.data?.data?.content &&
                buildingFacilityResponse?.data?.data?.content.length > 0
              ) {
                setFacility(buildingFacilityResponse?.data?.data?.content[0]);
              }
            }
          }
        }
      }
    };
    if (detailId) {
      fetchApi(detailId);
    }
  }, [detailId, getPriceDetail]);

  return (
    <>
      <div className="contents-container__wrap detail-basic-info page-price">
        <div className="contents-container__wrap-contents">
          <article className="contents-container__divide-bottom px30">
            <ContentsIdSection title="기본정보" id={String(priceDetail?.id)} />
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>프로바이더</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    <Link
                      to={`${PagePath.provider.detail.replace(
                        ":id",
                        `${priceDetail?.providerId}`,
                      )}`}
                      target="_blank"
                      className="text-hilight"
                    >
                      {provider?.providerName}
                    </Link>
                  </p>
                </div>
              </section>
            </div>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>요금구분</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {priceDetail?.priceRuleType
                      ? PriceRuleTypeToText[priceDetail.priceRuleType]
                      : "-"}
                  </p>
                  {priceDetail?.priceRuleType !== PriceRuleType.PRICE_RULE_SPECIFIC && (
                    <GrayBox priceDetail={priceDetail} facility={facility} />
                  )}
                </div>
              </section>
            </div>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>요금정책명</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{priceDetail?.name || "-"}</p>
                </div>
              </section>
            </div>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>적용일자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {priceDetail?.startDate
                      ? `${moment(priceDetail?.startDate).format(YmdFormat.YYYY_MM_DD)} ~ ${moment(
                          priceDetail?.endDate,
                        ).format(YmdFormat.YYYY_MM_DD)}`
                      : "-"}
                  </p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>적용시간</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {priceDetail?.startTime
                      ? `${priceDetail?.startTime} ~ ${priceDetail?.endTime}`
                      : "-"}
                  </p>
                </div>
              </section>
            </div>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>원가 (원/30분)</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {priceDetail?.cost !== undefined
                      ? `${numberToStringWithComma(priceDetail?.cost)}원`
                      : "-"}
                  </p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>판매가 (원/30분)</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {priceDetail?.cost !== undefined
                      ? `${numberToStringWithComma(priceDetail?.price)}원`
                      : "-"}
                  </p>
                </div>
              </section>
            </div>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>파트너</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{partner ? partner.name : "-"}</p>
                </div>
              </section>
            </div>
          </article>
          <article className="contents-container__divide-bottom">
            <RegistrationInformation data={priceDetail} />
          </article>
          <div className="px30">
            <AdminMemoV3
              serviceId={Number(detailId)}
              serviceType={ServiceTypes.SERVICE_PRICE_RULE}
              partnerId={priceDetail?.partnerId!}
            ></AdminMemoV3>
          </div>
        </div>

        <div className="contents-container__btn-wrap px30">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              onClick={() => goList()}
              className="color-white size-large"
            />
          </div>
          <div className="right-area"></div>
        </div>
      </div>
    </>
  );
};

export default OrgBaseInfo;
