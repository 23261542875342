/* eslint-disable array-callback-return */
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  changeRecieve,
  connectReceive,
  getCreditRelation,
} from "src/api/billingSchedule/billingSchedule-api";
import { getProviderAccountCredits } from "src/api/contract/contract-api";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { contract_bill_user_errors } from "src/api/public-types";
import {
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
  BaseInput,
  BaseModal,
  BaseTooltip,
} from "src/components";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { numberToStringWithComma, YmdFormat } from "src/utils";

const MainternanceBillingColmns: any = [
  {
    Header: "구분",
    accessor: "bill.billPayType",
    sticky: "left",
    width: 100,
    Cell: ({ value, row }: any) => {
      return <p>{value === "PAY" ? "일반결제" : "세금계산"}</p>;
    },
  },
  {
    Header: "건물명",
    accessor: "bill.buildingName",
    sticky: "left",
    width: 70,
    Cell: ({ value, row }: any) => {
      if (row.original.bill !== null && row.original.bill.buildingName) {
        return <p>{value}</p>;
      } else {
        return <p>-</p>;
      }
    },
  },
  {
    Header: "회차",
    accessor: "bill.billOrder",
    sticky: "left",
    width: 70,
    Cell: ({ value, row }: any) => {
      return <div>{value ? value : "-"} </div>;
    },
  },
  {
    Header: "사용연월",
    accessor: "bill.billEndTime",
    sticky: "left",
    width: 90,
    Cell: ({ value, row }: any) => {
      // console.log("row", row.original);
      const useYear = moment(row.original.bill.billStartTime).format(YmdFormat.YYYY_MM);
      return <p>{useYear}</p>;
    },
  },
  {
    Header: "사용기간",
    accessor: "bill.billStartTime",
    // sticky: "left",
    width: 170,
    Cell: ({ value, row }: any) => {
      // console.log("row", row.original);
      const start = moment(row.original.bill.billStartTime).format(YmdFormat.YYYY_MM_DD);
      const end = moment(row.original.bill.billEndTime).format(YmdFormat.YYYY_MM_DD);
      return <p>{start + " ~ " + end}</p>;
    },
    Footer: ({ rows }: any) => {
      return <p>합계 : </p>;
    },
  },
  // {
  //   Header: () => {
  //     return (
  //       <p>
  //         기준금액
  //         <br />
  //         (원)
  //       </p>
  //     );
  //   },
  //   accessor: "bill.baseAmount",
  //   // sticky: "left",
  //   width: 120,
  //   Cell: ({ value }: any) => {
  //     return <p>{numberToStringWithComma(value)}</p>;
  //   },
  //   Footer: ({ rows }: any) => {
  //     let sum = 0;
  //     rows.map((row: any) => {
  //       sum = sum + Number(row.original.bill.baseAmount);
  //     });
  //     return <p>{numberToStringWithComma(sum) + " 원"}</p>;
  //   },
  // },
  // {
  //   Header: () => {
  //     return (
  //       <p>
  //         할인/할증
  //         <br />
  //         금액(원)
  //       </p>
  //     );
  //   },
  //   accessor: "bill.adjustAmount",
  //   // sticky: "left",
  //   width: 120,
  //   Cell: ({ value }: any) => {
  //     return <p>{numberToStringWithComma(value)}</p>;
  //   },
  //   Footer: ({ rows }: any) => {
  //     let sum = 0;
  //     rows.map((row: any) => {
  //       sum = sum + Number(row.original.bill.adjustAmount);
  //     });
  //     return <p>{numberToStringWithComma(sum) + " 원"}</p>;
  //   },
  // },
  {
    Header: () => {
      return (
        <p>
          청구금액
          <br />
          (원)
        </p>
      );
    },
    accessor: "bill.totalAmount",
    // sticky: "left",
    width: 120,
    Cell: ({ value }: any) => {
      return <p>{numberToStringWithComma(value)}</p>;
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.map((row: any) => {
        sum = sum + Number(row.original.bill.totalAmount);
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },

  // {
  //   Header: "청구번호",
  //   accessor: "bill.billNumber",
  //   width: 120,
  //   Cell: ({ value, row }: any) => {
  //     return <div>{value ? value : "-"} </div>;
  //   },
  // },
  {
    Header: "청구일자",
    accessor: "bill.billIssueDate",
    // sticky: "left",
    width: 100,
    Cell: ({ value }: any) => {
      return <div>{value ? moment(value).format(YmdFormat.YYYY_MM_DD) : "-"} </div>;
    },
  },
  {
    Header: "납부기한",
    accessor: "bill.billDueDate",
    // sticky: "left",
    width: 100,
    Cell: ({ row, value }: any) => {
      let payLimitDay = row.original.bill.billDueDate;
      return <div>{payLimitDay ? moment(payLimitDay).format(YmdFormat.YYYY_MM_DD) : "-"} </div>;
    },
  },
  {
    Header: () => {
      return (
        <p>
          세금계산서
          <br />
          승인번호
        </p>
      );
    },

    accessor: "billInvoiceList",
    width: 120,
    Cell: ({ value, row }: any) => {
      const [view, setView] = useState(false);
      const [taxInvoice, setTaxInvoice] = useState<any>({ invoiceNumber: "" });
      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
      const checker = moment(today).isSameOrAfter(moment(billDate));

      useEffect(() => {
        if (row.original.billInvoiceList !== null && row.original.billInvoiceList.length > 0) {
          const tax_none = row.original.billInvoiceList.find(
            (invoice: any) => invoice.invoiceType === "TAX_PLUS",
          );
          setTaxInvoice(tax_none);
        }
      }, [row.original.billInvoiceList]);
      return (
        <div className="d-flex flex-column">
          <span>
            {taxInvoice && taxInvoice.invoiceNumber !== undefined && taxInvoice.invoiceNumber !== ""
              ? taxInvoice.invoiceNumber
              : "-"}
          </span>
        </div>
      );
    },
  },
  // {
  //   Header: () => {
  //     return (
  //       <p>
  //         계산서
  //         <br />
  //         승인번호
  //       </p>
  //     );
  //   },

  //   accessor: "billInvoice.ntsConfirmNum",
  //   width: 120,
  //   Cell: ({ value, row }: any) => {
  //     return <div>{value ? value : "-"} </div>;
  //   },
  // },

  {
    Header: "전송일자",
    accessor: "sendDate",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row }: any) => {
      let show = "-";

      if (row.original.billInvoice !== undefined && row.original.billInvoice !== null) {
        if (row.original.billInvoice.invoiceType === "TAX") {
          show = row.original.billInvoice.createdDate;
        }
      }
      return show !== "-" ? <BaseTooltip contents={show} type={"date"} /> : show;
    },
  },
  // {
  //   Header: "MgtKey",
  //   accessor: "bill.MgtKey",
  //   // sticky: "left",
  //   width: 100,
  //   Cell: ({ value, row }: any) => {
  //     return <div>{value ? value : "-"} </div>;
  //   },
  // },
  // {
  //   Header: "국세청 승인번호",
  //   accessor: "bill.ntsConfirmNum",
  //   // sticky: "left",
  //   width: 130,
  //   Cell: ({ value, row }: any) => {
  //     return <div>{value ? value : "-"} </div>;
  //   },
  // },
  // {
  //   Header: "tid",
  //   accessor: "payment.tid",
  //   // sticky: "left",
  //   width: 100,
  //   Cell: ({ value, row }: any) => {
  //     return <div>{value ? value : "-"} </div>;
  //   },
  // },
  // {
  //   Header: "cid",
  //   accessor: "payment.cid",
  //   // sticky: "left",
  //   width: 100,
  //   Cell: ({ value, row }: any) => {
  //     return <div>{value ? value : "-"} </div>;
  //   },
  // },
  // {
  //   Header: "즉시결제",
  //   accessor: "instantPayment",
  //   // sticky: "left",
  //   width: 100,
  //   Cell: ({ value, row, callList, contractBaseInfo, setLoadingBar, basicInfo }: any) => {
  //     const params = useParams();
  //     const { openErrorModal } = useErrorModalContext();
  //     let message = "";
  //     const [error, setError] = useState("");
  //     const [isError, setIsError] = useState(false);
  //     const [isChange, setIsChange] = useState(false);

  //     const { executeAsync: rtp } = useApiOperation(retryPayment, { doNotErrorHandleModal: true });

  //     const today = moment().format(YmdFormat.FULL);
  //     const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);

  //     const retryPay = useCallback(async () => {
  //       // console.log("row.original.bill.id", row.original);
  //       const askReturn: any = await rtp({ billId: Number(row.original.bill.billId) });
  //       if (askReturn.status >= 200 && askReturn.status <= 299) {
  //         message = "즉시결제가 정상처리 되었습니다.";
  //         await callList(Number(params.id));
  //         setIsChange(false);
  //         setError(message);
  //         setIsError(true);
  //       } else {
  //         let message = errorMessageHandler(
  //           askReturn.status,
  //           askReturn.data.meta.errorCode,
  //           contract_bill_user_errors,
  //         );
  //         let code = askReturn.data.meta.errorCode
  //           ? askReturn.data.meta.errorCode
  //           : askReturn.status;
  //         openErrorModal(message, code);
  //       }
  //     }, [row.original, rtp]);

  //     const onClickPgnBillKeyCheck = useCallback(() => {
  //       if (basicInfo.pgcode === "" || basicInfo.billkey === "") {
  //         setError("사용자가 결제 수단을 아직 등록하지 않아 즉시 결제를 할 수 없는 상태입니다.");
  //         setIsError(true);
  //         return;
  //       }
  //       setIsChange(true);
  //     }, [basicInfo]);

  //     const instantPayment = useCallback(async () => {
  //       try {
  //         setLoadingBar(true);
  //         await retryPay();
  //       } catch (e) {
  //         setLoadingBar(false);
  //       } finally {
  //         setLoadingBar(false);
  //       }
  //     }, [retryPay, setLoadingBar]);

  //     if (moment(today).isSameOrAfter(moment(billDate))) {
  //       return (
  //         <div>
  //           <p>-</p>

  //           <BaseModal
  //             isOpen={isChange}
  //             btnLeftTitle="취소"
  //             btnRightTitle="확인"
  //             onClose={() => setIsChange(false)}
  //             onClick={() => instantPayment()}
  //           >
  //             <div>
  //               <p>즉시 결제하시겠습니까?</p>
  //             </div>
  //           </BaseModal>
  //           <BaseModal isOpen={isError} btnRightTitle="확인" onClick={() => setIsError(false)}>
  //             <p>{error}</p>
  //           </BaseModal>
  //         </div>
  //       );
  //     }
  //   },
  // },

  {
    Header: "수납확인",
    accessor: "recieve",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row, callList, contractBaseInfo, setLoadingBar, scheduleId }: any) => {
      const params = useParams();
      const { openErrorModal } = useErrorModalContext();
      const { executeAsync: changeSuccess } = useApiOperation(changeRecieve, {
        doNotErrorHandleModal: true,
      });
      const { executeAsync: getAccountCredits } = useApiOperation(getProviderAccountCredits, {
        doNotErrorHandleModal: true,
      });
      const { executeAsync: addAccountCredits } = useApiOperation(connectReceive, {
        doNotErrorHandleModal: true,
      });

      const { executeAsync: getAccCredit } = useApiOperation(getCreditRelation, {
        doNotErrorHandleModal: true,
      });

      let message = "";

      const [search, setSearch] = useState({
        billId: Number(row.original.bill.billId),
        scheduleId: scheduleId,
        startDateTime: "",
        endDateTime: "",
      });
      // const sorted = _.sortBy(sample, "trDateTime").reverse();
      const [receiveData, setReceiveData] = useState<any>([]);
      const [error, setError] = useState("");
      const [isChange, setIsChange] = useState(false);
      const [isError, setIsError] = useState(false);
      const [relation, setRelation] = useState<any>([]);
      const [receiveList, setReceiveList] = useState<any>([]);
      const [etc, setEtc] = useState<any>({});

      const [errorText, setErrorText] = useState("");
      const [errorTax, setErrorTax] = useState("");

      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);

      const [sum, setSum] = useState(0);
      const [ovSum, setOvSum] = useState(0);

      const fetchAccounts = useCallback(
        async (search: any) => {
          //console.log("search", search);
          const response: any = await getAccountCredits(search);
          //console.log("getAccount", response);
          if (response.status >= 200 && response.status <= 299) {
            response.data.data.content.forEach((accData: any) => (accData.isOpen = false));
            setReceiveData(response.data.data.content);
          }
        },
        [getAccountCredits, setReceiveData],
      );

      const getCredit = useCallback(
        async (tid: any) => {
          const response: any = await getAccCredit({ tid });
          //console.log("getCreadit", response);
          if (response.status >= 200 && response.status <= 299) {
            setRelation(response.data.data.content.creditRelationBundle);
          }
        },
        [getAccCredit],
      );

      const addCredits = useCallback(
        async (credits: any) => {
          return await addAccountCredits({
            contractId: contractBaseInfo.contractId,
            body: credits,
          });
        },
        [addAccountCredits, contractBaseInfo],
      );

      const makeSuccess = useCallback(async () => {
        const temp = _.cloneDeep(receiveList);
        temp.forEach((save: any) => {
          if (save.trDateTime === "") {
            setErrorText("일시는 필수 입력 정보입니다. 연월일시를 정확히 입력해 주세요");
            return;
          } else {
            setErrorText("");
          }
        });

        const saveCreadits: any = [];

        temp.forEach((save: any) => {
          save.trDateTime = moment(save.trDateTime).format(YmdFormat.WITH_TIME_ZONE);
        });
        //console.log("trDateTime", etc.trDateTime);
        if (
          Number(etc.receiptAmount) !== 0 &&
          etc.trDateTime !== undefined &&
          etc.trDateTime !== ""
        ) {
          const insertReceipt: any = {
            receiptId: 0,
            billId: row.original.bill.billId,
            acctCreditId: 0,
            paymentId: 0,
            receiptAmount: Number(etc.receiptAmount),
            trDateTime: moment(etc.trDateTime).format(YmdFormat.WITH_TIME_ZONE),
          };
          temp.push(insertReceipt);
        }

        const send: any = {
          scheduleId: Number(scheduleId),
          body: temp,
        };
        //console.log("send", send);
        setLoadingBar(true);
        const response: any = await changeSuccess(send);
        // console.log(response);
        if (response.status >= 200 && response.status <= 299) {
          message = "수납처리가 완료되었습니다.";
          await callList(scheduleId);

          const res: any = await addCredits({ saveCreadits });

          if (res.status >= 200 && res.status <= 299) {
            setIsChange(false);
            setError(message);
            setIsError(true);
          }
        } else {
          let message = errorMessageHandler(
            response.status,
            response.data.meta.errorCode,
            contract_bill_user_errors,
          );
          let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
          openErrorModal(message, code);
        }

        setLoadingBar(false);
      }, [changeSuccess, setLoadingBar, callList, params]);

      const sortedRecevieList = useMemo(() => {
        const temp = _.cloneDeep(receiveList);

        const sorted: any = _.orderBy(
          temp,
          [(receipt: any) => moment(receipt.trDateTime).toDate()],
          ["desc"],
        );

        return sorted;
      }, [receiveList]);

      useEffect(() => {
        if (isChange) {
          const dump = _.cloneDeep(search);
          if (dump.startDateTime === "") {
            dump.startDateTime = moment().subtract("7", "days").format(YmdFormat.WITH_TIME_ZONE);
          }
          if (dump.endDateTime === "") {
            let endT = moment().format(YmdFormat.YYYY_MM_DD);

            let addTime = endT + " 23:59:59";

            dump.endDateTime = moment(addTime).format(YmdFormat.WITH_TIME_ZONE);
          }

          setSearch(dump);
          fetchAccounts(dump);

          setReceiveList(row.original.receiptBundle.receiptList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isChange]);

      const makeSum = useMemo(() => {
        let s = _.cloneDeep(sum);
        if (receiveList.length > 0) {
          receiveList.forEach((rp: any) => {
            s += Number(rp.receiptAmount);
          });
        }
        if (etc?.receiptAmount && Number(etc?.receiptAmount) !== 0) {
          s = Number(s) + Number(etc.receiptAmount);
        }
        return s;
      }, [etc.receiptAmount, receiveList, sum]);

      const makeOvSum = useMemo(() => {
        let s = _.cloneDeep(ovSum);
        if (receiveList.length > 0) {
          receiveList.forEach((rp: any) => {
            s += Number(rp.overdueAmount);
          });
        }

        return s;
      }, [receiveList, ovSum]);

      const totalSum = useMemo(() => {
        let realtimeOverdueAmount = 0;

        if (row.original.receiptBundle.realtimeOverdueAmount !== undefined) {
          realtimeOverdueAmount += Number(row.original.receiptBundle.realtimeOverdueAmount);
        }

        if (row.original.receiptBundle.accruedOverdueAmount !== undefined) {
          realtimeOverdueAmount += Number(row.original.receiptBundle.accruedOverdueAmount);
        }

        return realtimeOverdueAmount;
      }, [row.original.receiptBundle]);

      return (
        <div>
          {row.original.bill.billPayStatus === "PAYMENT_SUCCESS" &&
          moment(today).isSameOrAfter(moment(billDate)) ? (
            <BaseButton
              title={"수납확인"}
              className="color-white size-small"
              disabled={
                row.original.billReceipt !== null
                  ? false
                  : row.original.bill.billPayStatus === "PAYMENT_FAIL" ||
                    row.original.bill.billPayStatus === "PAYMENT_READY"
                  ? false
                  : true
              }
              onClick={() => setIsChange(true)}
            />
          ) : (
            "-"
          )}
          <BaseModal
            className="dialog-modal"
            isOpen={isChange}
            btnRightTitle={"확인"}
            onClick={() => setIsChange(false)}
          >
            <>
              <div className="modal-title">
                <div className="left-area">
                  <span>빌링 수납확인</span>
                </div>
                <div className="right-area"></div>
              </div>
              <div className="contents-scroll">
                <table className="inner-table" width="100%">
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <div className="flex-center text-left">
                          <span className="font16">
                            입금/수납 정보
                            <p className="font12 text-primary5">
                              * 입금내역 선택 후 수납내역의 입금액을 해당 회차의 청구금액으로
                              수정가능합니다.
                            </p>
                            <p className="font12 text-primary5">
                              * 입금내역 다중 선택 시,가장 최신일자를 수납일자로 출력합니다.
                            </p>
                            <p className="font12 text-primary5">
                              * 수납내역의 거래일시는 최초등록 값으로 청구서에 출력됩니다.이후에는
                              수정이 불가합니다.
                            </p>
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td width="180">
                        <div className="flex-center ">
                          <span className="text-primary3">입금내역</span>
                        </div>
                      </td>
                      <td>
                        <div className="flex-center-between py10 ">
                          <span>
                            총 <b>{receiveData.length}</b>건
                          </span>
                          <div className="flex-center">
                            <div className="minmax200">
                              <BaseDatePicker
                                name={"searchStart"}
                                readonly={true}
                                selectedDate={
                                  search.startDateTime
                                    ? moment(search.startDateTime).toDate()
                                    : moment().toDate()
                                }
                                setDate={(value: Date) => {
                                  const dump = _.cloneDeep(search);
                                  dump.startDateTime = moment(value).format(
                                    YmdFormat.WITH_TIME_ZONE,
                                  );
                                  setSearch(dump);
                                }}
                              />
                            </div>
                            <span className="mx8">~</span>
                            <div className="minmax200">
                              <BaseDatePicker
                                name={"searchEnd"}
                                readonly={true}
                                selectedDate={
                                  search.endDateTime
                                    ? moment(search.endDateTime).toDate()
                                    : moment().toDate()
                                }
                                setDate={(value: Date) => {
                                  const dump = _.cloneDeep(search);

                                  let endT = moment(value).format(YmdFormat.YYYY_MM_DD);

                                  let addTime = endT + " 23:59:59";

                                  dump.endDateTime = moment(addTime).format(
                                    YmdFormat.WITH_TIME_ZONE,
                                  );
                                  setSearch(dump);
                                }}
                              />
                            </div>
                            <BaseButton
                              title={"검색"}
                              disabled={true}
                              className="ml8"
                              onClick={async () => {
                                await fetchAccounts(search);
                              }}
                            />
                          </div>
                        </div>
                        <div className="contents-scroll max-height190 pa0">
                          <table className="inner-table" width="100%">
                            <thead>
                              <tr>
                                <th>선택</th>
                                <th>은행</th>
                                <th>계좌번호</th>
                                <th>예금주</th>
                                <th>거래일시</th>
                                <th>입금자명</th>
                                <th>입금액</th>
                                <th>입금잔액</th>
                                <th>연계</th>
                              </tr>
                            </thead>
                            <tbody>
                              {isChange && receiveData.length > 0 ? (
                                receiveData.map((dt: any, index: number) => {
                                  //console.log("dt", dt);
                                  return (
                                    <tr key={index}>
                                      <td width="80">
                                        <BaseCheckbox
                                          className="ml15"
                                          id={`check${index}`}
                                          name={`check${index}`}
                                          disabled={true}
                                          checked={dt.acctCredit.isBillCredit}
                                          onChange={(value: boolean) => {
                                            const dump = _.cloneDeep(receiveData);
                                            if (dump.length > 0) {
                                              const ndump: any = dump.map(
                                                (dp: any, idx: number) => {
                                                  if (index === idx) {
                                                    dp.acctCredit.isBillCredit = value;
                                                  }
                                                  return dp;
                                                },
                                              );

                                              if (ndump && ndump.length > 0) {
                                                setReceiveData(ndump);
                                              }
                                              if (value) {
                                                //console.log("aa", dt.credit.trDateTime);
                                                let trDateTime = moment(
                                                  dt.credit.trDateTime,
                                                  "YYYYMMDDHHmmss",
                                                ).format(YmdFormat.FULL);

                                                const insertReceipt: any = {
                                                  receiptId: 0,
                                                  billId: row.original.bill.billId,
                                                  acctCreditId: dt.acctCredit.acctCreditId,
                                                  paymentId: 0,
                                                  receiptAmount: dt.credit.creditBalance
                                                    ? Number(dt.credit.creditBalance)
                                                    : 0,
                                                  trDateTime: trDateTime,
                                                };
                                                row.original.receiptBundle.receiptList.push(
                                                  insertReceipt,
                                                );
                                              } else {
                                                const find =
                                                  row.original.receiptBundle.receiptList.find(
                                                    (rl: any) => {
                                                      if (
                                                        rl.acctCreditId ===
                                                        dt.acctCredit.acctCreditId
                                                      ) {
                                                        return rl;
                                                      }
                                                    },
                                                  );
                                                if (find && find.receiptId !== 0) {
                                                  row.original.receiptBundle.receiptList.forEach(
                                                    (receipt: any) => {
                                                      if (receipt.receiptId === find.receiptId) {
                                                        receipt.isDeleted = true;
                                                      }
                                                    },
                                                  );
                                                } else {
                                                  const filtered =
                                                    row.original.receiptBundle.receiptList.filter(
                                                      (receipt: any, idx5: number) =>
                                                        index !== idx5,
                                                    );
                                                  row.original.receiptBundle.receiptList = filtered;
                                                }
                                              }
                                            }
                                          }}
                                        />
                                      </td>
                                      <td width="100">{dt.providerAccount.bankName}</td>
                                      <td width="280">{dt.providerAccount.accountNumber}</td>
                                      <td width="120">{dt.providerAccount.accountHolder}</td>
                                      <td width="280">{dt.credit.trDateTime}</td>
                                      <td width="180">{dt.credit.remark1}</td>
                                      <td width="220">
                                        {numberToStringWithComma(dt.credit.accountIn) + " 원"}
                                      </td>
                                      <td width="220">
                                        {numberToStringWithComma(dt.credit.creditBalance) + " 원"}
                                      </td>
                                      <td>
                                        {dt.acctCredit.isAnyBillCredit && (
                                          <BaseButton
                                            title="연계중"
                                            className="color-white size-small"
                                            onClick={async () => {
                                              await getCredit(dt.credit.tid);
                                              const cl = _.cloneDeep(receiveData);
                                              cl.forEach((ac: any, idx: number) => {
                                                if (idx === index) {
                                                  ac.isOpen = true;
                                                }
                                              });
                                              setReceiveData(cl);
                                            }}
                                          />
                                        )}
                                        <BaseModal
                                          isOpen={dt.isOpen}
                                          className="dialog-modal "
                                          btnRightTitle="확인"
                                          onClick={() => {
                                            const cl = _.cloneDeep(receiveData);
                                            cl.forEach((ac: any, idx: number) => {
                                              if (idx === index) {
                                                ac.isOpen = false;
                                              }
                                            });
                                            setReceiveData(cl);
                                          }}
                                        >
                                          <div className="contents-scroll">
                                            <div className="modal-title">
                                              <div className="left-area align-items-center">
                                                <span>입금연계수납</span>
                                              </div>
                                              <div className="right-area"></div>
                                            </div>
                                            <table className="inner-table" width="100%">
                                              <thead>
                                                <tr>
                                                  <th>걔약Id</th>
                                                  <th>구분</th>
                                                  <th>청구회차</th>
                                                  <th>청구일</th>
                                                  <th>연결금액</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {relation.length > 0 ? (
                                                  relation.map((contract: any, idx2: number) => {
                                                    return (
                                                      <tr key={idx2}>
                                                        <td>{contract.contractId}</td>

                                                        <td>
                                                          {contract.bill.supplyType === "RENTAL"
                                                            ? "이용료"
                                                            : "관리비"}
                                                        </td>
                                                        <td>{contract.bill.billOrder}</td>
                                                        <td>
                                                          {moment(
                                                            contract.bill.billIssueDate,
                                                          ).format(YmdFormat.FULL)}
                                                        </td>
                                                        <td>
                                                          {numberToStringWithComma(
                                                            contract.creditRelationAmount,
                                                          ) + " 원"}
                                                        </td>
                                                      </tr>
                                                    );
                                                  })
                                                ) : (
                                                  <tr>
                                                    <td colSpan={5}>데이터가 없습니다.</td>
                                                  </tr>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </BaseModal>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={9}>데이터가 없습니다.</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="flex-center ">
                          <span className="text-primary3">수납 처리 현황</span>
                        </div>
                      </td>

                      <td>
                        <table className="inner-table" width="100%">
                          <thead>
                            <tr>
                              <th>수납처리 전 잔액</th>
                              <th>수납 금액</th>
                              <th>현재 잔액</th>
                              <th>현재 잔액의 연체료</th>
                              <th>전체 연체기간</th>
                              <th>전체 연체료</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {"-" +
                                  numberToStringWithComma(
                                    row.original.receiptBundle.billTotalAmount
                                      ? row.original.receiptBundle.billTotalAmount
                                      : 0,
                                  ) +
                                  " 원"}
                              </td>
                              <td>
                                {numberToStringWithComma(
                                  row.original.receiptBundle.receiptTotalAmount
                                    ? row.original.receiptBundle.receiptTotalAmount
                                    : 0,
                                ) + " 원"}
                              </td>
                              <td>
                                {row.original.receiptBundle.billUnpaidAmount !== 0
                                  ? "-" +
                                    numberToStringWithComma(
                                      row.original.receiptBundle.billUnpaidAmount
                                        ? row.original.receiptBundle.billUnpaidAmount
                                        : 0,
                                    )
                                  : 0 + " 원"}
                              </td>
                              <td>
                                {numberToStringWithComma(
                                  row.original.receiptBundle.realtimeOverdueAmount
                                    ? row.original.receiptBundle.realtimeOverdueAmount
                                    : 0,
                                ) + " 원"}
                              </td>

                              <td>
                                {row.original.receiptBundle.totalOverdueDays
                                  ? row.original.receiptBundle.totalOverdueDays
                                  : 0 + " 일"}
                              </td>
                              <td>{numberToStringWithComma(totalSum) + " 원"}</td>
                            </tr>
                            <tr>
                              <td colSpan={5}>
                                <div className="text-left">
                                  <p className="font12 text-primary5">
                                    *현재잔액: 수납처리 전 잔액기준으로 수납처리금액을
                                    제한금액입니다.
                                  </p>
                                  <p className="font12 text-primary5">
                                    *전체연체료: 수납처리 금액의 연체료와 현재잔액의 연체료의
                                    합계금액입니다.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td width="180">
                        <div className="flex-center ">
                          <span className="text-primary3">수납 처리</span>
                        </div>
                      </td>
                      <td>
                        <table className="inner-table" width="100%">
                          <thead>
                            <tr>
                              <th>유형</th>
                              <th>수납 금액</th>
                              <th>거래일시</th>
                              <th>연체기간</th>
                              <th>연체요율</th>
                              <th>연체료</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sortedRecevieList.length > 0 &&
                              sortedRecevieList.map((receive: any, idx3: number) => {
                                //console.log("receive", receive);
                                if (receive.paymentId !== 0 || Number(receive.acctCreditId) !== 0) {
                                  return (
                                    <tr key={idx3}>
                                      <td width={90}>
                                        {Number(receive.acctCreditId) !== 0
                                          ? "입금"
                                          : Number(receive.paymentId) !== 0
                                          ? "결제"
                                          : "기타"}
                                      </td>
                                      <td width={120}>
                                        <BaseInput
                                          type="number"
                                          readonly={true}
                                          name={"receiptAmount_" + idx3}
                                          onChange={(value: string) => {
                                            const temp = _.cloneDeep(receiveList);
                                            temp.forEach((rp: any) => {
                                              if (
                                                rp.acctCreditId === receive.acctCreditId &&
                                                rp.paymentId === receive.paymentId
                                              ) {
                                                rp.receiptAmount = Number(value);
                                              }
                                            });

                                            setReceiveList(temp);
                                          }}
                                          value={Number(receive.receiptAmount)}
                                          onBlur={() => {}}
                                        />
                                      </td>
                                      <td width={200}>
                                        <BaseDatePicker
                                          type={"full"}
                                          readonly={true}
                                          placeholder={"YYYY-MM-DD"}
                                          name={"receive.trDateTime"}
                                          selectedDate={
                                            receive.trDateTime
                                              ? moment(receive.trDateTime).toDate()
                                              : null
                                          }
                                          setDate={(value: Date) => {
                                            const temp = _.cloneDeep(receiveList);
                                            temp.forEach((rp: any, idx4: number) => {
                                              if (idx3 === idx4) {
                                                rp.trDateTime = value;
                                              }
                                            });

                                            setReceiveList(temp);
                                          }}
                                          errorText={errorText}
                                        />
                                      </td>
                                      <td width={80}>{receive.overdueDays + "일"}</td>
                                      <td width={80}>{receive.overdueRates + "%"}</td>
                                      <td width={80}>
                                        {numberToStringWithComma(Number(receive.overdueAmount)) +
                                          " 원"}
                                      </td>
                                    </tr>
                                  );
                                }
                              })}

                            {/* <tr>
                                <td width={90}>기타</td>
                                <td width={120}>
                                  <div className="mixmax120">
                                    <BaseInput
                                      name="etc_receiptAmount"
                                      type="number"
                                      onChange={(value: string) => {
                                        const temp = _.cloneDeep(etc);
                                        temp.receiptAmount = Number(value);
                                        setEtc(temp);
                                      }}
                                      onBlur={() => {
                                        
                                      }}
                                      value={Number(etc?.receiptAmount)}
                                    />
                                  </div>
                                </td>
                                <td width={140}>
                                  <BaseDatePicker
                                    type={"full"}
                                    placeholder={"YYYY-MM-DD"}
                                    name={"trDateTime"}
                                    selectedDate={
                                      etc?.trDateTime !== undefined && etc?.trDateTime !== ""
                                        ? moment(etc?.trDateTime).toDate()
                                        : null
                                    }
                                    setDate={(value: Date) => {
                                      const temp = _.cloneDeep(etc);
                                      temp.trDateTime = Number(value);
                                      setEtc(temp);
                                    }}
                                    errorText={errorText}
                                  />
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr> */}
                            <tr>
                              <td>합계 :</td>
                              <td>{numberToStringWithComma(makeSum) + " 원"}</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>{numberToStringWithComma(makeOvSum) + " 원"}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          </BaseModal>
          <BaseModal isOpen={isError} btnRightTitle="확인" onClick={() => setIsError(false)}>
            <p>{error}</p>
          </BaseModal>
        </div>
      );
    },
  },
  {
    Header: "거래일시",
    accessor: "receiptBundle.lastTrDateTime",
    width: 100,
    Cell: ({ value, row }: any) => {
      return value ? moment(value).format(YmdFormat.FULL) : "-";
    },
  },
  {
    Header: "연체 기간(일)",
    accessor: "receiptBundle.totalOverdueDays",
    // sticky: "left",
    width: 110,
    Cell: ({ value }: any) => {
      return <div>{Number(value) !== 0 ? value + "일" : "-"} </div>;
    },
  },
  {
    Header: "연체 금액(원)",
    accessor: "receiptBundle.accruedOverdueAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ value, row }: any) => {
      let realtimeOverdueAmount = 0;
      if (row.original.receiptBundle.realtimeOverdueAmount !== undefined) {
        realtimeOverdueAmount += Number(row.original.receiptBundle.realtimeOverdueAmount);
      }

      if (row.original.receiptBundle.accruedOverdueAmount !== undefined) {
        realtimeOverdueAmount += Number(row.original.receiptBundle.accruedOverdueAmount);
      }
      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(realtimeOverdueAmount))} </div>;
      } else {
        return <p>-</p>;
      }
    },
  },
  {
    Header: "수납 전 금액",
    accessor: "receiptBundle.billTotalAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(value))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(YmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.billTotalAmount);
        }
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },
  {
    Header: "수납금액",
    accessor: "receiptBundle.receiptTotalAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(value))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(YmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.receiptTotalAmount);
        }
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },
  // {
  //   Header: "선수금",
  //   accessor: "bill.overdueAmount2",
  //   // sticky: "left",
  //   width: 110,
  //   Cell: ({ row, value }: any) => {
  //     const today = moment().format(YmdFormat.FULL);
  //     const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
  //     if (moment(today).isSameOrAfter(moment(billDate))) {
  //       return <div>{numberToStringWithComma(Number(value))} </div>;
  //     } else {
  //       return <p>-</p>;
  //     }
  //   },
  // },
  // {
  //   Header: "미수금",
  //   accessor: "bill.overdueAmount3",
  //   // sticky: "left",
  //   width: 110,
  //   Cell: ({ row, value }: any) => {
  //     const today = moment().format(YmdFormat.FULL);
  //     const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
  //     if (moment(today).isSameOrAfter(moment(billDate))) {
  //       return <div>{numberToStringWithComma(Number(value))} </div>;
  //     } else {
  //       return <p>-</p>;
  //     }
  //   },
  // },
  {
    Header: "잔액",
    accessor: "receiptBundle.billUnpaidAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(YmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{value > 0 ? "-" + numberToStringWithComma(Number(value)) : "0"} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(YmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(YmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.billUnpaidAmount);
        }
      });
      return <p>{sum > 0 ? "-" + numberToStringWithComma(sum) + " 원" : "0 원"}</p>;
    },
  },
  {
    Header: "상태",
    accessor: "bill.billPayStatus",
    // sticky: "left",
    width: 90,
    Cell: ({ value, row }: any) => {
      // console.log(value);
      // PAYMENT_READY : 결제대기
      // PAYMENT_SUCCESS : 성공-결제성공
      // PAYMENT_FAIL : 실패-결제실패
      // PAYMENT_CANCEL : 취소-결제취소
      // CONTRACT_CANCEL : 해지-계약해지 빌링스케줄실행 중지
      // 연체 표시 : -
      let billIssuDay = moment(row.original.bill.billDueDate).format(YmdFormat.YYYY_MM_DD);
      let today = moment().format(YmdFormat.YYYY_MM_DD);

      if (
        (row.original.bill.billPayStatus === "PAYMENT_FAIL" ||
          row.original.bill.billPayStatus === "PAYMENT_READY") &&
        moment(today).isAfter(moment(billIssuDay))
      ) {
        value = "PAYMENT_DELAY";
      }

      return (
        <div
          className={`${
            (value === "PAYMENT_READY" && "chip gray") ||
            (value === "PAYMENT_FREE" && "chip gray") ||
            (value === "PAYMENT_SUCCESS" && "chip green") ||
            (value === "PAYMENT_FAIL" && "chip red") ||
            (value === "CONTRACT_CANCEL" && "chip gray") ||
            (value === "PAYMENT_CANCEL" && "chip gray") ||
            (value === "PAYMENT_DELAY" && "chip red")
          }`}
        >
          {(value === "PAYMENT_READY" && "대기") ||
            (value === "PAYMENT_FREE" && "무료") ||
            (value === "PAYMENT_SUCCESS" && "성공") ||
            (value === "PAYMENT_FAIL" && "실패") ||
            (value === "CONTRACT_CANCEL" && "중지") ||
            (value === "PAYMENT_CANCEL" && "취소") ||
            (value === "PAYMENT_DELAY" && "연체")}
        </div>
      );
    },
  },
  // {
  //   Header: "중지",
  //   accessor: "stop",
  //   // sticky: "left",
  //   width: 100,
  //   Cell: ({ value, row, stopObj }: any) => {
  //     if (
  //       stopObj?.isContractCancel === "true" &&
  //       row.original.bill.billOrder === stopObj?.contractCancelStartOrder
  //     ) {
  //       return (
  //         <div>
  //           <p>중지</p>
  //         </div>
  //       );
  //     } else {
  //       return <div>-</div>;
  //     }
  //   },
  //   // },
  // },
];
export default MainternanceBillingColmns;
