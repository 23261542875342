import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderDetailModel } from "src/api/provider/provider-types";
import { BaseButton, ContentsIdSection } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import MntTypeAccountForm from "../components/MntTypeAccountForm";
import ProviderAccountForm from "../components/ProviderAccountForm";
import ProviderPayletterForm from "../components/ProviderPayletterForm";
import ProviderPopbillForm from "../components/ProviderPopbillForm";
import ProviderTypeAccountForm from "../components/ProviderTypeAccountForm";

const AccountInfoForm = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const providerId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  const [providerDetail, setProviderDetail] = useState<ProviderDetailModel>();

  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  // 프로바이더 상세 조회 후 setValue 처리
  const providerDetailApi = useCallback(
    async (id: string) => {
      setLoadingBar(true);
      const response = await getProviderDetail({ providerId: id });
      if (response.status >= 200 || response.status <= 299) {
        const detail: ProviderDetailModel = response.data.data;

        setProviderDetail(detail);
      }
      setLoadingBar(false);
    },
    [getProviderDetail, setLoadingBar],
  );

  useEffect(() => {
    providerDetailApi(String(providerId));
  }, [providerDetailApi, providerId]);

  return (
    <div className="contents-container__wrap detail-basic-info">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection title="연동정보" id={providerId} />

        {/* 계좌 입금 확인 / 세금계산서 발행 정보 */}
        <ProviderPopbillForm
          providerId={providerId || 0}
          providerDetail={providerDetail}
          providerDetailApi={providerDetailApi}
        />

        {/* 계좌 정보 */}
        {providerDetail?.provider?.supplyType === "RENTAL" && (
          <ProviderTypeAccountForm
            providerId={providerId || 0}
            providerDetail={providerDetail}
            providerDetailApi={providerDetailApi}
          />
        )}
        {providerDetail?.provider?.supplyType === "MNT" && (
          <MntTypeAccountForm
            providerId={providerId || 0}
            providerDetail={providerDetail}
            providerDetailApi={providerDetailApi}
          />
        )}

        {/* 기존 원본 계좌정보 */}
        {/* <ProviderAccountForm
          providerId={providerId || 0}
          providerDetail={providerDetail}
          providerDetailApi={providerDetailApi}
        /> */}

        {/* 카드결제 지원 */}

        <ProviderPayletterForm
          providerId={providerId || 0}
          providerDetail={providerDetail}
          providerDetailApi={providerDetailApi}
        />

        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              className="color-white size-large"
              onClick={() => {
                if (providerId) {
                  navigate(PagePath.provider.list);
                } else {
                  navigate(PagePath.provider.list);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountInfoForm;
