import { useCallback } from "react";
import { ApiPath } from "src/api";
import {
  addAdminMemo,
  deleteAdminMemo,
  editAdminMemo,
  getAdminMemoList,
} from "src/api/adminMemo/adminmemo-api";
import { InsertAdminMemo } from "src/api/adminMemo/adminmemo-types";
import { useApiOperation } from "src/api/hooks";
import { Sort } from "src/api/public-types";

//관리자 메모 데이터 전송용 Hook
const useAdminMemo = () => {
  const { executeAsync: getServiceAdminMemoList } = useApiOperation(getAdminMemoList);
  const { executeAsync: addMemo } = useApiOperation(addAdminMemo);
  const { executeAsync: editMemo } = useApiOperation(editAdminMemo);
  const { executeAsync: deleteMemo } = useApiOperation(deleteAdminMemo);

  const getList = useCallback(
    async (serviceId: number, serviceType: string, viewOnly?: boolean, sort?: Sort) => {
      let getParams: any = {
        contentsList: [
          {
            serviceId,
            serviceType: serviceType,
          },
        ],
        sort: { orders: [{ property: "id", direction: "DESC" }] },
      };
      if (viewOnly === undefined || viewOnly === false) {
        delete getParams.page;
        delete getParams.size;
      } else {
        getParams.page = 0;
        getParams.size = 3;
      }

      if (sort) {
        getParams.sort = sort;
      }

      const response: any = await getServiceAdminMemoList(getParams);

      return response;
    },
    [getServiceAdminMemoList],
  );

  const add = useCallback(
    async (addModel: InsertAdminMemo) => {
      const response = await addMemo({ data: addModel });

      return response;
    },
    [addMemo],
  );

  const edit = useCallback(
    async (editModel: InsertAdminMemo) => {
      const response = await editMemo({ data: editModel });

      return response;
    },
    [editMemo],
  );

  const remove = useCallback(
    async (idList: Array<number>) => {
      let ids = idList.join(",");
      let path = ApiPath.memo + "?idList=" + ids;

      const response = await deleteMemo({ deleteUri: String(path) });
      return response;
    },
    [deleteMemo],
  );

  const delMemo = useCallback(
    async (idList: Array<number>) => {
      let ids = idList.join(",");
      let path = ApiPath.memo + "?idList=" + ids;

      const response = await deleteMemo({ deleteUri: String(path) });
      return response;
    },
    [deleteMemo],
  );

  return { getList, add, edit, remove, delMemo };
};
export default useAdminMemo;
