import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderDetailModel } from "src/api/provider/provider-types";
import { BaseButton, ContentsIdSection } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import ProviderContactDetail from "../basicInfoDetail/components/ProviderContactDetail";

const ContractInfoDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const providerId = params.id;
  const location = useLocation();

  const [providerDetail, setProviderDetail] = useState<ProviderDetailModel>();

  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  const { setLoadingBar } = useLoadingBarContext();

  const providerDetailApi = useCallback(async (id: string) => {
    setLoadingBar(true);

    const response = await getProviderDetail({ providerId: id });
    if (response.status >= 200 || response.status <= 299) {
      const result = response.data.data;
      setProviderDetail(result);
    }
    setLoadingBar(false);
  }, []);

  useEffect(() => {
    if (providerId) {
      providerDetailApi(providerId);
    }
  }, [providerDetailApi, providerId]);
  return (
    <div className="contents-container__wrap detail-basic-info">
      <div className="contents-container__wrap-contents">
        {providerDetail && (
          <>
            <ContentsIdSection title="연락처 정보" id={providerId} />
            {/* 연락처 */}
            <ProviderContactDetail providerContacts={providerDetail?.providerContactList} />
          </>
        )}

        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              className="color-white size-large"
              onClick={() => {
                if (providerId) {
                  navigate(PagePath.provider.list);
                } else {
                  navigate(PagePath.provider.list);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractInfoDetail;
