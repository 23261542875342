type PartnerStatus = "STANDBY" | "ACTIVE" | "SHUTDOWN";
type PartnerESignType = "FALSE" | "TRUE";

interface Partner {
  id?: string;
  code: string;
  name: string;
  useStartDate: string;
  useEndDate: string;
  contractUrl?: string;
  status?: PartnerStatus;
  statusDate?: string;
  createdDate?: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
  electronicSignatureIntegrationType?: PartnerESignType;
  features?: PartnerFeatures[];
  email?: string;
}

export interface PartnerFeatures {
  name: string;
  active: boolean;
}

interface ESignOnClient {
  partnerId?: string;
  businessId?: string;
  membEmail?: string;
  membPwd?: string;
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  cmdType?: string;
}

export const PrivilegeCode = {
  BASIC: "BASIC",
  BUILDING: "BUILDING",
  PRODUCT: "PRODUCT",
  WORKORDER: "WORKORDER",
  CS: "CS",
  CONTRACT: "CONTRACT",
  RESERVATION: "RESERVATION",
  VISITOR: "VISITOR",
  NOTICE: "NOTICE",
} as const;

export type Privilege = {
  code: string;
  permissions: string[];
};

export type { Partner, PartnerStatus, PartnerESignType, ESignOnClient };
