import { PagePath } from "../product/details";

const getPartnerMemberListPagePath = () => PagePath.partnerMember.list;

const getPartnerMemberDetailPagePath = (id: string | number) =>
  PagePath.partnerMember.detail.replace(":id", String(id));

const getPartnerMemberEditPath = (id: string | number) =>
  PagePath.partnerMember.edit.replace(":id", String(id));

const BREAD_CRUMB_MAP = {};

export { getPartnerMemberListPagePath, getPartnerMemberDetailPagePath, getPartnerMemberEditPath };
