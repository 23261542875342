import { Cell } from "react-table";
import { ProductModel, ProductStatus } from "src/api/product/product-types";

export const StatusCell = (props: Cell<ProductModel>) => {
  return (
    <div
      className={`${
        (props?.value === "ENABLED" && "chip green") ||
        (props?.value === "USE" && "chip green dot") ||
        (props?.value === "DISABLED" && "chip red") ||
        (props?.value === "LINK_ENABLED" && "chip draft") ||
        (props?.value === "DRAFT" && "chip gray")
      }`}
    >
      {(props?.value === ProductStatus.ENABLED && "전체공개") ||
        (props?.value === ProductStatus.DISABLED && "비공개") ||
        (props?.value === ProductStatus.DRAFT && "작성중") ||
        (props?.value === ProductStatus.LINK_ENABLED && "링크공개")}
    </div>
  );
};
