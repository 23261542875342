import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteAccessDeviceAsync,
  getAccessDeviceAsync,
  getDeviceLabelsAsync,
} from "src/api/access/ac2-api";
import { AccessDeviceModel, DeviceLabelModel } from "src/api/access/ac2-types";
import { useApiOperation } from "src/api/hooks";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseModal, ContentsIdSection, ContentsTitle } from "src/components";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { YmdFormat } from "src/utils";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { LabelsModal } from "./LabelsModal";

/*
  출입장치 관리 상세
*/
const AccessDeviceDetail = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openToast } = useToastContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { openErrorModal } = useErrorModalContext();
  const [labels, setLabels] = useState<DeviceLabelModel[]>([]);
  const [accessDevice, setAccessDevice] = useState<AccessDeviceModel>();
  const [labelsModal, setLabelsModal] = useState<Modal>({ isOpen: false });
  const { executeAsync: getDeviceLabels } = useApiOperation(getDeviceLabelsAsync);
  const { executeAsync: getAccessDevice } = useApiOperation(getAccessDeviceAsync);
  const { executeAsync: deleteAccessDevice } = useApiOperation(deleteAccessDeviceAsync, {
    doNotErrorHandleModal: true,
  });

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "accessDevice") {
        navigate(PagePath.accessDevice.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "accessDevice", label: "출입장치 관리" }];

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 출입장치 삭제
  const handelEraseConfirm = async () => {
    if (id) {
      const { data } = await deleteAccessDevice({ id });
      setConfirmModal({ isOpen: false });
      if (data?.data?.device.id) {
        setConfirmModal({ isOpen: true, message: "삭제 되었습니다", type: "SUCCESS_DELETE" });
      } else if (data?.meta?.errorCode === "eAC101") {
        setAlertModal({
          isOpen: true,
          message: "삭제할 수 없습니다. \n 해당 장치를 쓰고 있는 출입그룹이 있습니다.",
        });
      } else {
        let errorMessage = data?.meta?.errorMessage;
        let errorCode = data?.meta?.errorCode;
        let errorData = data?.meta?.errorData;
        openErrorModal(errorMessage, errorCode, errorData);
      }
    }
  };
  const fetchLabels = useCallback(async () => {
    const { data: labelsData, status } = await getDeviceLabels({ deviceId: Number(id) });
    if (status <= 200 && status < 300) {
      const labels = labelsData.data.labels;
      setLabels(labels);
    }
  }, []);

  useEffect(() => {
    if (id) {
      async function fetchApi(id: string) {
        setLoadingBar(true);
        const { data } = await getAccessDevice({ id: Number(id) });
        const accessDevice = data?.data?.device;
        fetchLabels();
        setAccessDevice(accessDevice);

        setLoadingBar(false);
      }
      fetchApi(id);
    }
  }, [getAccessDevice, setLoadingBar, id]);
  useTitleOperation(accessDevice?.deviceName);

  return (
    <div>
      <ContentsTitle
        title="출입장치 관리"
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-contents">
            <ContentsIdSection title="장치 상세" id={id} />
            <article>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>id</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <span className="font14">{accessDevice?.id || "-"}</span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>장치 id</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <span className="font14">{accessDevice?.externalDeviceId || "-"}</span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <div className="flex-center">
                    <p>장치 이름</p>
                  </div>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <div className="flex-center flex-wrap">
                      <span className="font14">{accessDevice?.deviceName || "-"}</span>
                    </div>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>출입시스템</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax300">
                    <span className="font14">
                      {accessDevice?.externalServer?.serverName || "-"}
                    </span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">Labels</p>
                </div>
                <div className="contents-container__grid-contents">
                  {labels?.map((labels, i) => (
                    <p className="" key={labels.name + i}>
                      {labels.name} : {labels.value}
                    </p>
                  ))}
                  <BaseButton
                    title="수정"
                    className={`color-white ${labels.length > 0 ? "mt10" : ""}`}
                    onClick={() => setLabelsModal({ isOpen: true })}
                  />
                  {id && labelsModal.isOpen && (
                    <LabelsModal
                      deviceId={Number(id)}
                      setLabelsModal={setLabelsModal}
                      setLabels={setLabels}
                      fetchLabels={fetchLabels}
                    />
                  )}
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>생성일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax300">
                    <span className="font14">
                      {moment(accessDevice?.createdDate).format(YmdFormat.FULL) || "-"}
                    </span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최종 수정일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax300">
                    <span className="font14">
                      {moment(accessDevice?.lastModifiedDate).format(YmdFormat.FULL) || "-"}
                    </span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최종 작성자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax300">
                    <span className="font14">{accessDevice?.lastModifiedBy || "-"}</span>
                  </div>
                </div>
              </section>
            </article>
          </div>
          {/* 버튼영역 */}
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title="목록으로"
                className="color-white size-large"
                onClick={() => {
                  if (id) {
                    // 수정화면에서는 목록으로 바로이동
                    navigate(PagePath.accessDevice.list);
                  } else {
                    // 등록화면에서는 목록에서 진입했기 때문에 뒤로가기
                    navigate(-1);
                  }
                }}
              />
              <BaseButton
                title="삭제"
                className="no-outline color-white size-large text-red ml10"
                onClick={() =>
                  setConfirmModal({
                    isOpen: true,
                    type: "CONFIRM_DELETE",
                  })
                }
              />
            </div>
            <div className="right-area">
              <BaseButton
                title="수정"
                className="size-large"
                onClick={() => {
                  const formPath = `${PagePath.accessDevice.form}?id=${id}`;
                  navigate(formPath);
                }}
              />
            </div>
          </div>

          {/* 취소/확인 모달 */}
          <BaseModal
            isOpen={confirmModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => {
              confirmModal.type === "CONFIRM_DELETE" && handelEraseConfirm();
              confirmModal.type === "SUCCESS_DELETE" && navigate(PagePath.accessDevice.list);
            }}
          >
            <>
              {confirmModal.type === "CONFIRM_DELETE" && <p>삭제 하시겠습니까?</p>}
              {confirmModal.type === "SUCCESS_DELETE" && <p>삭제 되었습니다.</p>}
            </>
          </BaseModal>

          {/* 확인버튼만 있는 alert 모달 */}
          <BaseModal
            isOpen={alertModal.isOpen}
            btnRightTitle="확인"
            onClick={() => setAlertModal({ isOpen: false })}
          >
            <p>{alertModal.message}</p>
          </BaseModal>
        </div>
      </div>
    </div>
  );
};

export default AccessDeviceDetail;
