import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useApiOperation } from "src/api/hooks";
import { memberEmailUpdateAsync } from "src/api/member/member-api";
import { MemberModel } from "src/api/member/member-types";
import { BaseButton, BaseInput, BaseModal } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { Modal } from "src/pages/building/building-types";
import { useLoadingBarContext } from "src/pages/hooks";
import { validateEmail, validateEmailText } from "src/utils";

type Props = {
  memberDetail?: MemberModel;
  accountStatus?: string;
  saveEmailModal: Modal;
  setSaveEmailModal: Dispatch<SetStateAction<Modal>>;
  setEditEmailModal: Dispatch<SetStateAction<Modal>>;
  memberId?: string;
  getMemberDetailApi: Function;
  setEmailError: Dispatch<SetStateAction<boolean>>;
  newEmail: string;
  setNewEmail: Dispatch<SetStateAction<string>>;
  onClose: Function;
  onClick: Function;
};

const MemberEmailUpdate = ({
  memberDetail,
  accountStatus,
  saveEmailModal,
  setSaveEmailModal,
  memberId,
  getMemberDetailApi,
  setEditEmailModal,
  setEmailError,
  newEmail,
  setNewEmail,
  onClose,
  onClick,
}: Props) => {
  // 이메일 수정 모달
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();
  // const [newEmail, setNewEmail] = useState(memberDetail?.email || "");

  // openErrorModal 이 BaseModal 보다 뒤에 생성되어 별도 에러모달 띄우기
  const [errorModal, setErrorModal] = useState<Modal>({ isOpen: false, payload: true });
  //
  const { executeAsync: updateMemberEmail } = useApiOperation(memberEmailUpdateAsync, {
    doNotErrorHandleModal: !errorModal.payload ? false : true,
  });

  // 회원 이메일 변경 api
  const updateMemberEmailApi = useCallback(
    async (key, email) => {
      if (key && email) {
        setLoadingBar(true);
        const response = await updateMemberEmail({ key, email });

        if (response.status >= 200 && response.status <= 299) {
          const result = response.data.data.member;
          getMemberDetailApi(result.id);
          setSaveEmailModal({ isOpen: false });
          setEditEmailModal({ isOpen: false });
        } else {
          const errorCode = response.data.meta.errorCode;
          if (errorCode === "EMAIL_ALREADY_IN_USE") {
            setErrorModal({ isOpen: true, message: "이미 사용중인 이메일 입니다." });
            setSaveEmailModal({ isOpen: false });
          } else {
            setErrorModal({ isOpen: false, payload: false });
            setSaveEmailModal({ isOpen: false });
          }
        }

        setLoadingBar(false);
      }
    },
    [getMemberDetailApi, setErrorModal],
  );

  const emailValidation = useCallback(
    (email) => {
      if (validateEmail(email)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
      return <p className="validation-text">{validateEmailText(email)}</p>;
    },
    [setEmailError],
  );

  return (
    <BaseAbstractModal isOpen={true}>
      <section className="base-abstract-modal__title">
        <h1>이메일 변경</h1>
      </section>
      <section className="base-abstract-modal__contents px30">
        <table className="inner-table change-email-modal" width="940">
          <thead>
            <tr>
              <th className="id">id</th>
              <th>회원 번호</th>
              <th>휴대폰 번호</th>
              <th>이메일</th>
              <th>계정 상태</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>{memberDetail?.id}</div>
              </td>
              <td>
                <div>{memberDetail?.memberNo}</div>
              </td>
              <td>
                <div>{memberDetail?.phoneNumber}</div>
              </td>
              <td>
                <BaseInput
                  onChange={(text: string) => {
                    setNewEmail(text);
                  }}
                  value={newEmail}
                  // errorText={}
                />
              </td>
              <td>
                <div>{accountStatus}</div>
              </td>
            </tr>
          </tbody>
        </table>

        <div>{emailValidation(newEmail)}</div>

        <div className="flex-start py10">
          <p className="font13 text-red600">* 이메일 변경 시 계정의 이메일 인증이 해제됩니다.</p>
        </div>
      </section>
      <section className="base-abstract-modal__btn-wrap">
        <BaseButton
          type="button"
          title={"취소"}
          className="color-white flex-center-center"
          onClick={onClose}
        />
        <BaseButton
          type="submit"
          title={"확인"}
          className="flex-center-center"
          disabled={newEmail === memberDetail?.email ? true : false}
          onClick={onClick}
        />
      </section>
      <>
        <BaseModal
          isOpen={saveEmailModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="저장"
          onClose={() => setSaveEmailModal({ isOpen: false })}
          onClick={() => {
            updateMemberEmailApi(memberId, newEmail);
          }}
        >
          <span>{saveEmailModal.message}</span>
        </BaseModal>
      </>

      <>
        <BaseModal
          isOpen={errorModal.isOpen}
          btnRightTitle="확인"
          onClick={() => setErrorModal({ isOpen: false })}
        >
          <span>{errorModal.message}</span>
        </BaseModal>
      </>
    </BaseAbstractModal>
  );
};

export default MemberEmailUpdate;
