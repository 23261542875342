import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getProductDetailAsync } from "src/api/product/product-api";
import { ProductModel } from "src/api/product/product-types";
import { BaseButton, ContentsIdSection } from "src/components";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext } from "src/pages/hooks";
import OnlyNormalProductAllow from "../../components/OnlyNormalProductAllow";
import {
  AirConditionerSection,
  FreeInternetSection,
  OptionalFacilitySection,
  PrinterSection,
  RestroomsSection,
} from "./components";

/* 
  공간상품 > 상세 > 시설/서비스
*/
const FacilityDetail = ({ productDetail }: { productDetail: ProductModel }) => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const navigate = useNavigate();
  const location = useLocation();

  // path variable 에서 productId 추출
  const productId = useMemo(() => {
    const paths = location.pathname.split("/");
    const pathProductId = paths[paths.length - 1];
    return Number(pathProductId);
  }, [location]);

  // 공간상품 상세
  const [product, setProduct] = useState<ProductModel | null>(null);

  // 공간상품 상세 조회 api
  const { executeAsync: getProduct } = useApiOperation(getProductDetailAsync);

  // 공간상품 상세조회 후 데이터 바인딩
  const fetchProduct = useCallback(
    async (productId: number) => {
      setLoadingBar(true);
      setProduct(productDetail);
      setLoadingBar(false);
    },
    [getProduct, setLoadingBar],
  );

  useEffect(() => {
    fetchProduct(productId);
  }, [productId, fetchProduct]);

  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection title="시설/서비스" id={productId} />
        {/* 공조기 */}
        <AirConditionerSection product={product} />
        {/* 화장실 */}
        <RestroomsSection product={product} />
        {/* 복합기 */}
        <PrinterSection product={product} />
        {/* 무료 인터넷 */}
        <FreeInternetSection product={product} />
        {/* 추가 시설/서비스 */}
        <OptionalFacilitySection product={product} />
        {/* 기타 시설/서비스 */}
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>기타 시설/서비스</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14 pre-formatted">{product?.etcService}</span>
            </div>
          </div>
        </section>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            className="color-white size-large"
            onClick={() => {
              navigate(PagePath.product.list);
            }}
          />
        </div>
        <div className="right-area">
          {/* <BaseButton
            title="수정"
            className="size-large"
            onClick={() => {
              const formPath = `${PagePath.product.form}?id=${productId}&tab=facility`;
              navigate(formPath);
            }}
          /> */}
        </div>
      </div>
    </div>
  );
};
export default FacilityDetail;
