import React, { useEffect, useRef, useState } from "react";
import { Popover } from "react-tiny-popover";

type Props = React.ComponentProps<"div"> & {
  tooltip?: string;
  size?: number; // default 20;
  position?: "top-left" | "top-right" | "bottom-left" | "bottom-right"; // default 위쪽 중앙에 생성
};

const BaseQuestionMarkTooltip = (props: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltipHeight = tooltipRef.current.clientHeight;
      const gap = 10;
      const size = props.size || 20;
      if (props.position) {
        tooltipRef.current.style.position = "absolute";
        switch (props.position) {
          case "top-left":
            tooltipRef.current.style.top = `-${tooltipHeight + gap}px`;
            tooltipRef.current.style.left = `-${gap * 2}px`;
            break;
          case "top-right":
            tooltipRef.current.style.top = `-${tooltipHeight + gap}px`;
            tooltipRef.current.style.right = `-${gap * 2}px`;
            break;
          case "bottom-left":
            tooltipRef.current.style.bottom = `-${tooltipHeight + gap * 2 + size}px`;
            tooltipRef.current.style.left = `-${gap * 2}px`;
            break;

          case "bottom-right":
            tooltipRef.current.style.bottom = `-${tooltipHeight + gap * 2 + size}px`;
            tooltipRef.current.style.right = `-${gap * 2}px`;
            break;

          default:
            return;
        }
      }
    }
  }, [isPopoverOpen, props.size, props.position]);

  return (
    <Popover
      isOpen={isPopoverOpen}
      content={
        <>
          {props.children ? (
            <div ref={tooltipRef} className={"pre-formatted popover ic-question minmax300"}>
              {props.children}
            </div>
          ) : (
            <div ref={tooltipRef} className={"pre-formatted popover ic-question "}>
              <p>{props.tooltip}</p>
            </div>
          )}
        </>
      }
    >
      <div
        onMouseOver={() => setIsPopoverOpen(true)}
        onMouseOut={() => setIsPopoverOpen(false)}
        className={`${props.className} ic-question-tooltip`}
        style={{ width: props.size, height: props.size }}
      ></div>
    </Popover>
  );
};

export default BaseQuestionMarkTooltip;
