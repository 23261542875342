import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import {
  getContractApplicationInfo,
  getContractBuildingRooms,
  getContractDetailBasicInfo,
  getContractInfo,
  getContractOperationInfo,
} from "src/api/contract/contract-api";
import { ContractDetailBasicInfo, ContractStep } from "src/api/contract/contract-types";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { contract_bill_user_errors } from "src/api/public-types";
import { BaseModal, ContentsTitle } from "src/components";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { checkContractStatus } from "../contract-util";
import ContractAdminMemo from "./components/adminMemo/ContractAdminMemo";
import ContractApplication from "./components/application/ContractApplication";
import ContractBasicInfo from "./components/basicInfo/ContractBasicInfo";
import ContractDetail from "./components/contractDetail/ContractDetail";
import ContractFormManage from "./components/formManage/ContractFormManage";
const tabs = [
  { value: "basicInfo", label: "기본정보", disabled: false },
  { value: "application", label: "신청", disabled: false },
  { value: "contract", label: "계약", disabled: false },
  { value: "manage", label: "운영", disabled: false },
  { value: "adminMemo", label: "관리자 메모", disabled: false },
];

const ContractFormMain = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const navigate = useNavigate();
  const location = useLocation();

  let id = 0;

  const [basicInfo, setBasicInfo] = useState<ContractDetailBasicInfo>();
  const [application, setApplication] = useState<any>();
  const [contract, setContract] = useState<any>();
  const [isOperationCheck, setIsOperationCheck] = useState(false);

  const [message, setMessage] = useState("");
  const [spaceProductType, setSpaceProductType] = useState("");

  const { executeAsync } = useApiOperation(getContractDetailBasicInfo, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getApplication } = useApiOperation(getContractApplicationInfo, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getContract } = useApiOperation(getContractInfo, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getOperation } = useApiOperation(getContractOperationInfo, {
    doNotErrorHandleModal: true,
  });
  const [rooms, setRooms] = useState<any>();
  const { executeAsync: getRooms } = useApiOperation(getContractBuildingRooms);

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );

  if (queryParams.id) {
    id = Number(queryParams.id);
    tabs[0].disabled = false;
    tabs[2].disabled = false;
    tabs[3].disabled = false;
    tabs[4].disabled = false;
  } else {
    tabs[0].disabled = true;
    tabs[2].disabled = true;
    tabs[3].disabled = true;
    tabs[4].disabled = true;
  }

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    if (queryParams?.id === undefined) {
      return tabs[1];
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    async (tab: { value: string; label: string; disabled: boolean }) => {
      if (tab.disabled === false) {
        const newQueryParams = { ...queryParams };

        if (
          tab.value === "manage" &&
          (basicInfo?.contractStep === ContractStep.APPLY_RECEIVED ||
            basicInfo?.contractStep === ContractStep.APPLY_CONFIRM)
        ) {
          setIsOperationCheck(true);
          setMessage(
            "현재 계약 상태에서는 운영 데이터가 생성되지 않아 운영탭을 열람할 수 없습니다.",
          );
          return;
        } else {
          if (basicInfo?.contractStep === ContractStep.APPLY_CANCEL && tab.value === "manage") {
            let res: any = await getOperation({ id: Number(queryParams.id) });
            if (res.status >= 200 && res.status <= 299) {
              if (newQueryParams?.tab) {
                delete newQueryParams.tab;
              }
              newQueryParams.tab = tab.value;
              // query parameter 에 tab 추가
              const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
              navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
            } else {
              setIsOperationCheck(true);
              setMessage(
                "현재 계약 상태에서는 운영 데이터가 생성되지 않아 운영탭을 열람할 수 없습니다.",
              );
              return;
            }
          } else {
            if (newQueryParams?.tab) {
              delete newQueryParams.tab;
            }
            let path = location.pathname;
            newQueryParams.tab = tab.value;

            if (tab.value === "application") {
              path = PagePath.contract.detail.replace(":id", String(queryParams?.id));
            }
            console.log("path.pathname", path);
            // query parameter 에 tab 추가
            const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
            navigate(path + "?" + decodeURIComponent(newQueryParamStr));
          }
        }
      } else {
        setIsOperationCheck(true);
        setMessage("신규생성 시 신청정보를 저장하지 않으면 이동할 수 없습니다.");

        return;
      }
    },
    [queryParams, basicInfo?.contractStep, getOperation, navigate, location.pathname],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      // console.log("clickBreadCrumb", crumb);
      if (crumb.value === "contract") {
        navigate(PagePath.contract.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "contract", label: "신청/계약" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  const baseDataInfo = useCallback(
    async (id: number) => {
      setLoadingBar(true);
      const response: any = await executeAsync({ id: Number(id) });
      if (response.status >= 200 && response.status <= 299) {
        response.data.data.step = checkContractStatus(response.data.data.contractStep);
        setSpaceProductType(response.data.data.spaceProductType);
        setBasicInfo(response.data.data);
      } else {
        let message = errorMessageHandler(
          response.status,
          response.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
        openErrorModal(message, code);
      }

      const res2: any = await getRooms({ contractId: id });
      console.log("res2", res2);

      if (res2.status > 199 && res2.status < 300) {
        setRooms(res2.data.data.content);
      } else {
        let message = errorMessageHandler(
          res2.status,
          res2.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = res2.data.meta.errorCode ? res2.data.meta.errorCode : res2.status;

        openErrorModal(message, code);
      }

      const responseApp: any = await getApplication({ id: Number(id) });
      if (responseApp.status >= 200 && response.status <= 299) {
        setApplication(responseApp.data.data);
      } else {
        let message = errorMessageHandler(
          responseApp.status,
          responseApp.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = responseApp.data.meta.errorCode
          ? responseApp.data.meta.errorCode
          : responseApp.status;
        openErrorModal(message, code);
      }

      const responseDetail: any = await getContract({ id: Number(id) });

      if (responseDetail.status >= 200 && response.status <= 299) {
        if (
          responseDetail.data.data.scheduleList &&
          responseDetail.data.data.scheduleList.length > 0
        ) {
          const rental: any = responseDetail.data.data.scheduleList.find(
            (obj: any) => obj.supplyType === "RENTAL",
          );
          responseDetail.data.data.schedule = rental;
          responseDetail.data.data.scheduleList = responseDetail.data.data.scheduleList.filter(
            (obj: any) => {
              if (obj.supplyType !== "RENTAL") {
                obj.isModifyOpen = false;
                obj.isOverdueOpen = false;
                return obj;
              }
            },
          );
        }

        console.log("responseDetail.data.data", responseDetail.data.data);
        setContract(responseDetail.data.data);
      } else {
        let message = errorMessageHandler(
          responseDetail.status,
          responseDetail.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = responseDetail.data.meta.errorCode
          ? responseDetail.data.meta.errorCode
          : responseDetail.status;
        openErrorModal(message, code);
      }
      setLoadingBar(false);
    },
    [executeAsync, getApplication, getContract, getRooms, openErrorModal, setLoadingBar],
  );

  useEffect(() => {
    if (id) {
      baseDataInfo(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // title 에 건물명 바인딩
  let contractApplyNumber;
  if (basicInfo?.contractApplyNumber) {
    contractApplyNumber = basicInfo?.contractApplyNumber;
  }

  useTitleOperation(String(contractApplyNumber));

  return (
    <div className="page-contract-detail">
      <ContentsTitle
        title="신청/계약"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />
      <div className="contents-container__scroll">
        {activeTab?.value === "basicInfo" && (
          <ContractBasicInfo
            basicInfo={basicInfo}
            application={application}
            contract={contract}
            spaceProductType={spaceProductType}
          />
        )}
        {activeTab?.value === "application" && (
          <ContractApplication
            basicInfo={basicInfo}
            setApplication={setApplication}
            application={application}
            spaceProductType={spaceProductType}
            rooms={rooms}
          />
        )}
        {activeTab?.value === "contract" && <ContractDetail />}
        {/* 운영 */}
        {activeTab?.value === "manage" && (
          <ContractFormManage
            basicInfo={basicInfo}
            application={application}
            spaceProductType={spaceProductType}
          />
        )}
        {/* 관리자메모 */}
        {activeTab?.value === "adminMemo" && (
          <AdminMemoIntegration
            serviceId={Number(basicInfo?.contractId)}
            serviceType={ServiceTypes.SERVICE_CONTRACT}
            viewOnly={false}
          />
        )}
      </div>
      <BaseModal
        isOpen={isOperationCheck}
        btnRightTitle="확인"
        onClick={() => setIsOperationCheck(false)}
      >
        <p>{message}</p>
      </BaseModal>
    </div>
  );
};

export default ContractFormMain;
