import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import {
  getPartner,
  getPartnerESignClient,
  patchPartnerESignClient,
  postPartnerESignClient,
  putPartner,
} from "src/api/partner";
import { BaseButton, BaseModal, ContentsTitle } from "src/components";
import { useTitleOperation } from "src/pages/hooks";
import { ESignOnClient, Partner } from "src/types/partner";
import PartnerAuth from "../Detail/PartnerAuth";
import PartnerFeature from "../Detail/PartnerFeature";
import PartnerParking from "../Edit/PartnerParking";
import { PartnerForm } from "../components";
import {
  getPartnerDetailPage,
  getPartnerListPage,
  getPartnerPageBreadCrumb,
  sortFeaturesByName,
} from "../utils";
import PartnerESignForm from "./PartnerESignForm";
import { Modal } from "src/api/public-types";
import PartnerLog from "../Detail/PartnerLog";
const tabs = [
  { value: "basicInfo", label: "기본정보", disabled: false },
  { value: "feature", label: "feature", disabled: false },
  { value: "authorities", label: "권한", disabled: false },
  { value: "esign", label: "전자서명", disabled: false },
  { value: "parking", label: "주차 연동", disabled: false },
  { value: "log", label: "로그", disabled: false },
];
function PartnerEdit() {
  const { executeAsync: putPartnerAsync } = useApiOperation(putPartner);
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);
  const { executeAsync: putClientAsync } = useApiOperation(patchPartnerESignClient);
  const { executeAsync: addClientAsync } = useApiOperation(postPartnerESignClient);

  const { executeAsync: getPartnerESignAsync } = useApiOperation(getPartnerESignClient);
  const [client, setClient] = useState<ESignOnClient>();
  const { id } = useParams();

  const getClientData = useCallback(
    async (partnerId: string) => {
      const response: any = await getPartnerESignAsync({ id: partnerId });

      if (response.status >= 200 && response.status <= 300) {
        setClient(response.data.data.eSignonClient);
      }
    },
    [getPartnerESignAsync],
  );

  const [partner, setPartner] = useState<Partner>();
  const [isValidButton, setIsValidButton] = useState(true);
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const formRef = useRef<HTMLFormElement>(null);
  const formRef2 = useRef<HTMLFormElement>(null);
  const formRef3 = useRef<HTMLFormElement>(null);

  const navigate = useNavigate();
  const urlParams = useParams();

  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };

      // if (tab.value === "parking") {
      //   const availableParking = partner?.features?.find(
      //     (features) => features.name === "FEATURE_PARKING_KPS",
      //   )?.active;

      //   if (!availableParking) {
      //     setAlertModal({ isOpen: true, message: "FEATURE_PARKING_KPS feature가 비활성입니다" });
      //     return;
      //   }
      // }

      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  const [modal, setModal] = useState({
    success: {
      visible: false,
    },
    fail: {
      visible: false,
      message: "",
    },
  });

  const onSubmit = async (data: Partner) => {
    const result = await putPartnerAsync({
      id: urlParams.id!,
      data,
    });

    if (result.status >= 200 && result.status < 300) {
      setModal({
        ...modal,
        success: {
          visible: true,
        },
      });
    } else {
      setModal({
        ...modal,
        fail: {
          visible: true,
          message: result.data.meta.errorMessage!,
        },
      });
    }
  };
  const onSubmit2 = async (data: ESignOnClient) => {
    let result: any = undefined;
    data.partnerId = urlParams.id!;
    if (client?.businessId) {
      data.cmdType = "U";
      result = await putClientAsync({
        id: urlParams.id!,
        eSignonClient: data,
      });
    } else {
      data.cmdType = "C";
      result = await addClientAsync({
        id: urlParams.id!,
        eSignonClient: data,
      });
    }

    if (result.status >= 200 && result.status < 300) {
      setModal({
        ...modal,
        success: {
          visible: true,
        },
      });
    } else {
      setModal({
        ...modal,
        fail: {
          visible: true,
          message: result.data.meta.errorMessage!,
        },
      });
    }
  };

  const handleClickSuccessModalOkButton = () =>
    navigate(getPartnerDetailPage(urlParams.id!, String(activeTab?.value)));

  const handleClickFailModalOkButton = () =>
    setModal({ ...modal, fail: { visible: false, message: "" } });
  const handleClickFailModalCancelButton = () =>
    setModal({ ...modal, fail: { visible: false, message: "" } });

  const handleClickGoListButton = () => navigate(getPartnerListPage());

  const handleSaveButtonClick = () =>
    formRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );

  const handleSaveButtonClick2 = () =>
    formRef2.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );
  const handleSaveButtonClick3 = () =>
    formRef3.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );
  const callClientData = async () => {
    await getClientData(String(id));
  };

  useEffect(() => {
    (async () => {
      const result = await getPartnerAsync({
        id: urlParams.id!,
      });

      if (result.status >= 200 && result.status < 300) {
        setPartner(result.data.data.partner);
      } else {
        setModal({
          ...modal,
          fail: {
            visible: true,
            message: result.data.meta.errorMessage!,
          },
        });
      }
      callClientData();
    })();
  }, []);
  useTitleOperation(partner?.name);

  return (
    <div>
      <ContentsTitle
        title="파트너 정보 수정"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={getPartnerPageBreadCrumb(["list", "add", "edit"], urlParams.id!)}
        clickBreadCrumb={(crumb: { value: string; label: string }) => navigate(crumb.value)}
      />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          {activeTab?.value === "basicInfo" && (
            <PartnerForm onSubmit={onSubmit} ref={formRef} defaultValue={partner} />
          )}
          {activeTab?.value === "feature" && (
            <PartnerFeature
              features={sortFeaturesByName(partner?.features || [])}
              setPartner={setPartner}
            />
          )}
          {activeTab?.value === "esign" && (
            <PartnerESignForm
              defaultValue={client}
              setSubmitDisable={setIsValidButton}
              setDefaultValue={setClient}
              onSubmit={onSubmit2}
              ref={formRef2}
            />
          )}
          {activeTab?.value === "parking" && <PartnerParking features={partner?.features || []} />}
          {activeTab?.value === "authorities" && <PartnerAuth />}
          {activeTab?.value === "log" && <PartnerLog />}
          {activeTab?.value !== "parking" && ( // 주차연동의 경우 컴포넌트 내부에서 처리
            <>
              <div className="contents-container__btn-wrap">
                <div className="left-area">
                  <BaseButton
                    title={"목록"}
                    onClick={handleClickGoListButton}
                    className="color-white size-large"
                  />
                </div>
                <div className="right-area">
                  {activeTab?.value !== "authorities" &&
                    activeTab?.value !== "feature" &&
                    activeTab?.value !== "log" && (
                      <BaseButton
                        title={"취소"}
                        onClick={handleClickSuccessModalOkButton}
                        className="size-large color-white mr8"
                      />
                    )}
                  {activeTab?.value === "basicInfo" && (
                    <BaseButton
                      title={"저장"}
                      onClick={handleSaveButtonClick}
                      className="size-large"
                    />
                  )}
                  {activeTab?.value === "esign" && (
                    <BaseButton
                      title={"저장"}
                      disabled={isValidButton}
                      onClick={handleSaveButtonClick2}
                      className="size-large"
                    />
                  )}
                  {activeTab?.value === "parking" && (
                    <BaseButton
                      title={"저장"}
                      disabled={isValidButton}
                      onClick={handleSaveButtonClick3}
                      className="size-large"
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <BaseModal
        isOpen={modal.success.visible}
        onClick={handleClickSuccessModalOkButton}
        btnRightTitle="확인"
      >
        <p>저장 되었습니다.</p>
      </BaseModal>
      <BaseModal
        isOpen={modal.fail.visible}
        onClick={handleClickFailModalCancelButton}
        btnRightTitle="확인"
      >
        <div>
          <p>저장을 할 수 없습니다.</p>
        </div>
      </BaseModal>
      {alertModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnRightTitle={"확인"}
          onClick={() => setAlertModal({ isOpen: false })}
        >
          <p>{alertModal.message}</p>
        </BaseModal>
      )}
    </div>
  );
}
export default PartnerEdit;
