import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getIotRegisteredDeviceListAsync } from "src/api/iot/iot-api";
import { PaPlatformDeviceParams, PaRegisteredDevice } from "src/api/iot/iot-types";
import { Modal, Order, PageMeta } from "src/api/public-types";
import { BaseButton, BaseInput, BaseSelect, BaseTable } from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import PagePath from "src/pagePath.json";
import { useLoadingBarContext } from "src/pages/hooks";
import { Partner } from "src/types/partner";
import iotColumns from "./columns/IotColumns";

// 검색 옵션
const searchOption = [
  { value: "", label: "전체" },
  { value: "containsPlatformAccountEmail", label: "계정" },
  { value: "containsDisplayName", label: "기기명" },
  { value: "containsPlatformDeviceId", label: "Device ID" },
  { value: "containsPlatformDeviceLabel", label: "Label" },
  { value: "containsPlatformDeviceName", label: "Name" },
];

type SelectedPartner = {
  id?: string | number;
  name?: string;
};

// url query parameter 타입
type QueryParams = PaPlatformDeviceParams & {
  keyword?: string;
  option?: string;
  partnerId?: string;
  partnerName?: string;
};

/* 
  iot > 목록 화면
 */
const IotList = () => {
  const { setLoadingBar } = useLoadingBarContext();

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, [location]);

  // 목록 조회
  const [registeredDeviceList, setRegisteredDeviceList] = useState<PaRegisteredDevice[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [partner, setPartner] = useState<SelectedPartner>();
  const [partnerModal, setPartnerModal] = useState<Modal>({ isOpen: false });
  const [params, setParams] = useState<QueryParams>({ ...queryParams });
  const { executeAsync: getIotRegisteredDeviceList } = useApiOperation(
    getIotRegisteredDeviceListAsync,
  );

  useEffect(() => {
    setParams({ ...queryParams }); // input 초기화

    const fetchApi = async () => {
      let newQueryParams = { ...queryParams };
      if (newQueryParams.option) {
        newQueryParams = { ...newQueryParams, [newQueryParams.option]: newQueryParams.search001 };
        delete newQueryParams.search001;
      }
      const { option, ...payload } = newQueryParams;
      setLoadingBar(true);
      const { data, status } = await getIotRegisteredDeviceList(payload);
      if (status >= 200 && status <= 299) {
        setRegisteredDeviceList(data.data.content || []);
        setPageMeta(data.meta?.pageMeta);
      }
      setLoadingBar(false);
    };
    fetchApi();
  }, [queryParams, setLoadingBar, getIotRegisteredDeviceList]);

  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }
      let newQueryParams = { ...data, ...(passParams || {}) };

      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  return (
    <div className="page-iot-list">
      <div className="contents-container__search-wrap">
        <div className="left-area">
          {/* <p>
              전체 <span>{pageMeta?.totalElements || 0}</span>
            </p> */}
          <div
            className="minmax200 mr8 cursor-pointer"
            // onClick={() => setPartnerModal({ isOpen: true })}
          >
            <BaseInput
              // className="cursor-pointer"
              readonly
              placeholder="파트너"
              value={partner?.name || params.partnerName}
              onSearchClick={() => setPartnerModal({ isOpen: true })}
              onClearClick={() => {
                setPartner({});
                const { partnerId, partnerName, ...rest } = params;
                setParams(rest);
              }}
            />
          </div>
          <div className="minmax120 mr8">
            <BaseSelect
              placeholder="검색 옵션"
              value={params?.option}
              stateOptions={searchOption}
              setStateValue={(option: string) => setParams({ ...params, option })}
            />
          </div>
          <BaseInput
            value={params?.search001 || ""}
            className="minmax220 mr8"
            type="text"
            placeholder="검색어를 입력해주세요"
            onChange={(search001: string) => setParams({ ...params, search001 })}
            onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
            onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
          />
        </div>
        <div className="right-area">
          <BaseButton title="+ 기기 등록" onClick={() => navigate(PagePath.iot.form)} />
        </div>
      </div>

      <BaseTable
        data={registeredDeviceList}
        columns={iotColumns}
        pageIndex={Number(params?.page || 0)}
        totalPages={pageMeta?.totalPages || 0}
        goPage={(page: number) => {
          navigateWithQueryParams({ page }, "pagination");
        }}
        orders={params?.sort?.orders}
        disabledSortHeaders={[
          "size",
          "platformDevice.platformAccount.platform",
          "platformDevice.platformAccount.email",
          "displayName",
          "platformDevice.id",
          "platformDevice.label",
          "platformDevice.name",
          "partnerIds",
        ]}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } }, "search");
          }
        }}
      />
      {partnerModal.isOpen && (
        <PartnerSelectModal
          onCanceled={() => setPartnerModal({ isOpen: false })}
          onAdded={(partner: Partner[]) => {
            let selectedPartner = {
              id: partner[0].id || "",
              name: partner[0].name || "",
            };
            setPartner(selectedPartner);
            setPartnerModal({ isOpen: false });
            setParams({
              ...params,
              partnerId: String(partner[0].id),
              partnerName: String(partner[0].name),
            });
          }}
          defaultValues={[]}
        />
      )}
    </div>
  );
};
export default IotList;
