import { useEffect, useState } from 'react';
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { getDenialEventsAsync, syncAccessGroupsAsync } from 'src/api/access/ac-api';
import { EventModel, EventQueryParams } from 'src/api/access/ac-types';
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseModal, ContentsIdSection, ContentsTitle } from 'src/components';
import { useLoadingBarContext, useToastContext } from 'src/pages/hooks';
import { UserEventColumns } from 'src/pages/member/detail/components/accessInfo/columns/UserEventColumns';
import { Modal } from '../maintenance-types';

function AccessMonitor() {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 토스트
  const { openToast } = useToastContext();

  // 출입 내역 목록
  const [events, setEvents] = useState<EventModel[]>([]);
  const [params, setParams] = useState<EventQueryParams>({
    page: 0,
    size: 50
  });

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns: UserEventColumns,
      data: events,
      initialState: {
        pageSize: params.size
      }
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  // 출입 내역 목록 api hook
  const { executeAsync: getEvents } = useApiOperation(getDenialEventsAsync);

  // 액세스그룹 동기화 api hook
  const { executeAsync: syncAccessGroups } = useApiOperation(syncAccessGroupsAsync);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false })

  // 출입그룹 동기화 Modal 확인 버튼 클릭
  const clickModalConfirm = async () => {
    setConfirmModal({ isOpen: false });

    // 출입그룹 동기화
    if (confirmModal.type === "SYNC_ACCESS_GROUPS") {
      try {
        const { data } = await syncAccessGroups();
        if (data.data.accessGroup) {
          let invalidCount = data.data.accessGroup.invalidCount;
          openToast(`${invalidCount} 건의 출입그룹이 동기화 되었습니다.`);
        }
      } catch (error) {
        console.error("syncAccessGroup error", error);
      } finally {
        setLoadingBar(false);
      }
    }
  }

  useEffect(() => {
    async function fetchEvents(params: EventQueryParams) {
      const { data } = await getEvents(params);
      setEvents(data?.data?.rows);
    }

    fetchEvents(params);
  }, [getEvents, params]);

  return (
    <div>
      {/* <ContentsTitle title=" " /> */}
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-contents">

            <ContentsIdSection title="동기화 관리" />
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>설정 메뉴</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <BaseButton
                    title="출입그룹 동기화"
                    className="color-block"
                    onClick={() => {
                      setConfirmModal({
                        isOpen: true,
                        message: "출입그룹을 동기화 하시겠습니까?",
                        type: "SYNC_ACCESS_GROUPS",
                        payload: "MANUAL",
                      });
                    }}
                  />
                </div>
              </div>
            </section>

            <section className="contents-container__divide-top">
              <div className="contents-container__sub-title">
                <h2>출입 거부 내역 (최근 50건)</h2>
              </div>
              <div className="mt20 inner-tab-table">
                <div {...getTableProps()} className="base-table sticky">
                  <div className="header">
                    {headerGroups.map((headerGroup) => (
                      <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                        {headerGroup.headers.map((header) => {
                          return (
                            <div {...header.getHeaderProps()} className="base-table__th">
                              {header.render("Header")}
                              <div className="ic_sort"></div>
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                  <div {...getTableBodyProps()} className="body">
                    {page.map((row: any) => {
                      prepareRow(row);

                      return (
                        <div
                          {...row.getRowProps()}
                          className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                        >
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className="base-table__td">
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>

          </div>
          {/* 취소, 확인 버튼이 있는 confirm 모달 */}
          <BaseModal
            isOpen={confirmModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => clickModalConfirm()}
          >
            <p>{confirmModal.message}</p>
          </BaseModal>

        </div>
      </div>      
    </div>
  );
}

export default AccessMonitor;