import { Dispatch } from "react";
import { Cell } from "react-table";
import {
  AppPushCategory,
  AppPushNotification,
  EcMessageStatusCodes,
  MessageEmailDetail,
  MessageKakaotalkDetail,
  MessageSMSDetail,
} from "src/api/message/message-types";
import { Modal } from "src/api/public-types";
import { BaseTooltip } from "src/components";

export const col_id = {
  Header: "id",
  accessor: "id",
  width: 80,
  Cell: (props: Cell<MessageEmailDetail | MessageKakaotalkDetail | MessageSMSDetail>) => {
    return props?.value ?? "-";
  },
};

export const col_usageNo = {
  Header: "발신 관리번호",
  accessor: "usageNo",
  width: 200,
  Cell: (props: Cell<MessageEmailDetail | MessageKakaotalkDetail | MessageSMSDetail>) => {
    return <div className={`${props.value ?? "text-left w-100 "}`}>{props.value ?? "-"}</div>;
  },
};

export const col_createdDate = {
  Header: "발신 요청시각",
  accessor: "createdDate",
  width: 100,
  Cell: (props: Cell<MessageEmailDetail | MessageKakaotalkDetail | MessageSMSDetail>) => {
    return <BaseTooltip contents={props.value} type={"date"} />;
  },
};

export const col_receiverPhoneNumber = {
  Header: "수신번호",
  accessor: "receiverPhoneNumber",
  width: 120,
  Cell: (props: Cell<MessageEmailDetail | MessageKakaotalkDetail | MessageSMSDetail>) => {
    return props.value ?? "-";
  },
};

export const col_template = {
  Header: "메시지 템플릿",
  accessor: "template",
  width: 200,
  Cell: (props: Cell<MessageEmailDetail | MessageKakaotalkDetail | MessageSMSDetail>) => {
    return (
      <div className={props.row.original.template && "text-left w-100"}>
        {props.row.original.template ?? "-"}
      </div>
    );
  },
};

export const col_service = {
  Header: "발신 서비스",
  accessor: "service",
  width: 100,
  Cell: (props: Cell<MessageEmailDetail | MessageKakaotalkDetail | MessageSMSDetail>) => {
    return props.row.original.service;
  },
};

export const col_status = {
  Header: "발신 결과",
  accessor: "status",
  width: 150,
  Cell: (props: Cell<MessageEmailDetail | MessageKakaotalkDetail | MessageSMSDetail>) => {
    if (props.row.original.status?.code) {
      return (
        <div className={props.row.original.status?.code && "text-left w-100"}>
          {EcMessageStatusCodes[props.row.original.status?.code]}
        </div>
      );
    }

    return "-";
  },
};

export const col_kakao_content = {
  Header: "메시지 내용",
  accessor: "content",
  width: 200,
  Cell: (props: Cell<MessageEmailDetail | MessageKakaotalkDetail | MessageSMSDetail>) => {
    return (
      <BaseTooltip className={props.value && "text-left w-100"} contents={props.value ?? "-"} />
    );
  },
};

export const col_type = {
  Header: "메시지 유형",
  accessor: "type",
  width: 100,
  Cell: (props: Cell<MessageKakaotalkDetail>) => {
    const TypeLabels = {
      ATS: "알림톡",
      FTS: "친구톡(텍스트)",
      FMS: "친구톡(이미지)",
    };

    return props.value ? TypeLabels[props.row.original.type as keyof typeof TypeLabels] : "-";
  },
};

export const col_altSendType = {
  Header: "대체 발송 여부 ",
  accessor: "altSendType",
  width: 200,
  Cell: (props: Cell<MessageKakaotalkDetail>) => {
    const AltSendTypeLabel = {
      C: "알림톡 또는 친구톡 내용으로 전송",
      A: "대체문자 내용으로 전송",
    };
    return props.value
      ? AltSendTypeLabel[props.row.original.altSendType as keyof typeof AltSendTypeLabel]
      : "-";
  },
};

export const col_from = {
  Header: "발신 주소",
  accessor: "from",
  width: 200,
  Cell: (props: Cell<MessageEmailDetail>) => {
    return (
      <div className={props.row.original.from?.email && "text-left w-100"}>
        {props.row.original.from?.email ?? "-"}
      </div>
    );
  },
};

export const col_to = {
  Header: "수신 주소",
  accessor: "to",
  width: 200,
  Cell: (props: Cell<MessageEmailDetail>) => {
    const str = props?.row?.original?.to?.map((data) => data.email).join(" , ");
    const to = str === "" ? "-" : str;

    return <BaseTooltip className={str && "text-left w-100"} contents={to} />;
  },
};

export const col_cc = {
  Header: "참조 주소",
  accessor: "cc",
  width: 200,
  Cell: (props: Cell<MessageEmailDetail>) => {
    const str = props?.row?.original?.cc?.map((data) => data.email).join(" , ");
    const cc = str === "" ? "-" : str;
    console.log(`str`, str);
    return <BaseTooltip className={str && ` text-left w-100`} contents={cc} />;
  },
};

export const col_bcc = {
  Header: "비밀참조 주소",
  accessor: "bcc",
  width: 200,
  Cell: (props: Cell<MessageEmailDetail>) => {
    const str = props?.row?.original?.bcc?.map((data) => data.email).join(" , ");
    const bcc = str === "" ? "-" : str;

    return <BaseTooltip className={str && "text-left w-100"} contents={bcc} />;
  },
};

export const col_email_subject = {
  Header: "제목",
  accessor: "subject",
  width: 200,
  Cell: (props: Cell<MessageEmailDetail>) => {
    return (
      <BaseTooltip className={props.value && "text-left w-100"} contents={props.value ?? "-"} />
    );
  },
};

export const col_app_createdBy = {
  Header: "발신인",
  accessor: "createdBy",
  width: 180,
  Cell: ({ value }: Cell<AppPushNotification>) => {
    return <span className={value && "w-100 text-left"}>{value ?? "-"}</span>;
  },
};

export const col_audience_count = {
  Header: "수신인 수",
  accessor: "audienceCount",
  width: 100,
  Cell: (
    props: Cell<AppPushNotification> & { setOpenModal: Dispatch<React.SetStateAction<Modal>> },
  ) => {
    const { row, value, setOpenModal } = props;

    return (
      <span
        className="w-100 text-center cursor-pointer text-underline text-blue"
        onClick={() => {
          setOpenModal({ isOpen: true, payload: row.original.id });
        }}
      >
        {value ?? "-"}
      </span>
    );
  },
};

export const col_app_title = {
  Header: "제목",
  accessor: "title",
  width: 250,
  Cell: ({ value }: Cell<AppPushNotification>) => {
    return <span className={value && "w-100 text-left"}>{value ?? "-"}</span>;
  },
};

export const col_app_category = {
  Header: "유형",
  accessor: "category",
  width: 120,
  Cell: ({ value }: Cell<AppPushNotification>) => {
    return AppPushCategory[value as keyof typeof AppPushCategory] ?? "-";
  },
};

export const col_app_code = {
  Header: "메시지 템플릿",
  accessor: "code",
  width: 120,
  Cell: ({ value }: Cell<AppPushNotification>) => {
    return <span className={value && "w-100 text-left"}>{value ?? "-"}</span>;
  },
};

export const col_audience_memberNo = {
  Header: "회원 번호",
  accessor: "memberNo",
  width: 220,
  Cell: ({ value }: Cell<AppPushNotification>) => {
    return <span className="">{value ?? "-"}</span>;
  },
};

export const col_col_audience_press_createdDate = {
  Header: "생성 일시",
  accessor: "createdDate",
  width: 220,
  Cell: ({ value }: Cell<MessageEmailDetail | MessageKakaotalkDetail | MessageSMSDetail>) => {
    return <BaseTooltip contents={value ?? "-"} type={value && "date"} />;
  },
};

export const col_audience_read = {
  Header: "알림센터 열람 일시",
  accessor: "readDate",
  width: 220,
  Cell: ({ value }: Cell<AppPushNotification>) => {
    return <BaseTooltip contents={value ?? "-"} type={value && "date"} />;
  },
};

export const col_audience_press = {
  Header: "알림 확인 일시",
  accessor: "pressedDate",
  width: 220,
  Cell: ({ value }: Cell<AppPushNotification>) => {
    return <BaseTooltip contents={value ?? "-"} type={value && "date"} />;
  },
};

export const smsColumns = [
  col_id,
  col_usageNo,
  col_createdDate,
  col_receiverPhoneNumber,
  col_template,
  col_service,
  col_status,
];

export const kakaotalkColumns = [
  col_id,
  col_usageNo,
  col_createdDate,
  col_receiverPhoneNumber,
  col_kakao_content,
  col_type,
  col_altSendType,
  col_template,
  col_service,
  col_status,
];

export const emailColumns = [
  col_id,
  col_usageNo,
  col_createdDate,
  col_from,
  col_to,
  col_cc,
  col_bcc,
  col_email_subject,
  col_template,
  col_service,
  col_status,
];

// 앱푸쉬 목록 컬럼
export const appPushColumns = [
  col_id, // id
  col_usageNo, // 발신 관리번호
  col_createdDate, // 요청시각
  col_app_createdBy, // 발신인
  col_audience_count, //수신인 수
  col_app_title, //제목
  col_app_category, //유형
  col_app_code, //템플릿
  col_service, //발신 서비스
  col_status, // 전송 상태
];

// 앱푸쉬 수신인 컬럼
export const appPushAudienceColmns = [
  col_id, // id
  col_audience_memberNo,
  col_col_audience_press_createdDate,
  col_audience_read,
  col_audience_press,
];

export const messageListColumns = {
  SMS: smsColumns,
  kakaotalk: kakaotalkColumns,
  email: emailColumns,
  app: appPushColumns,
};
