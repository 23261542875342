import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { PaRegisteredDevice } from "src/api/iot/iot-types";
import { PagePath } from "src/pages/product/details";
import { iotPlatformToString } from "../../iot-types";

const iotColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: (props: Cell<PaRegisteredDevice>) => {
      const path = PagePath.iot.detail.replace(":id", props.value);
      return (
        <Link to={path} className="text-hilight">
          {props.value}
        </Link>
      );
    },
  },
  {
    Header: "IoT 구분",
    accessor: "platformDevice.platformAccount.platform",
    width: 100,
    Cell: (props: Cell<PaRegisteredDevice>) => <p>{iotPlatformToString(props.value)}</p>,
  },
  {
    Header: "계정",
    accessor: "platformDevice.platformAccount.email",
    width: 180,
    Cell: (props: Cell<PaRegisteredDevice>) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "기기명",
    accessor: "internalDisplayName",
    width: 260,
    Cell: (props: Cell<PaRegisteredDevice>) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "Device ID",
    accessor: "platformDevice.id",
    width: 180,
    Cell: (props: Cell<PaRegisteredDevice>) => <p>{props.value}</p>,
  },
  {
    Header: "Name",
    accessor: "platformDevice.name",
    width: 120,
    Cell: (props: Cell<PaRegisteredDevice>) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },
  {
    Header: "ST-Label",
    accessor: "platformDevice.label",
    width: 270,
    Cell: (props: Cell<PaRegisteredDevice>) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value}</p>
      </div>
    ),
  },

  {
    Header: "파트너",
    accessor: "partnerIds",
    width: 140,
    Cell: (props: Cell<PaRegisteredDevice>) => {
      const firstPartnerId = props.value[0];
      const path = PagePath.partner.detail.replace(":id", firstPartnerId);
      if (props.value.length > 0) {
        return (
          <div>
            <Link to={path} target="_blank" className="text-hilight mr4">
              {firstPartnerId}
            </Link>
            {props.value.length !== 1 && <span>외 {props.value.length - 1}</span>}
          </div>
        );
      } else return <p>-</p>;
    },
  },
];

export default iotColumns;
