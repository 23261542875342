import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getBuildingAsync } from "src/api/building/building-api";
import {
  BuildingFloorModel,
  BuildingModel,
  BuildingRoomModel,
} from "src/api/building/building-types";
import { getPropertiesAsync } from "src/api/building/property-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseTooltip, ContentsIdSection } from "src/components";
import PagePath from "src/pagePath.json";
import {
  floorOptions,
  Property,
  SelectOption,
  sortingProperties,
  sumByFloor,
} from "src/pages/building/building-types";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import {
  calculatePyeong,
  calculatePyeongNum,
  numberToStringWithComma,
  roundToTwo,
} from "src/utils";

/* 
  건물 > 상세 > 프로퍼티
 */
const PropertyDetail = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const navigate = useNavigate();
  const location = useLocation();

  // path variable 에서 buildingId 추출
  const buildingId = useMemo(() => {
    const paths = location.pathname.split("/");
    const pathBuildingId = paths[paths.length - 1];
    return pathBuildingId ? Number(pathBuildingId) : undefined;
  }, [location]);

  // 물 기본정보
  const [building, setBuilding] = useState<BuildingModel | null>(null);

  // 건물과 층과 호실정보 테이블 목록
  const [properties, setProperties] = useState<Array<Property>>([]);

  // 건물 상세 조회 api hook
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  // 건물 프로퍼티 목록 조회 api hook
  const { executeAsync: getProperties } = useApiOperation(getPropertiesAsync);

  useEffect(() => {
    if (buildingId) {
      async function fetchBuilding(buildingId: number) {
        setLoadingBar(true);
        const { data } = await getBuilding({ buildingId });
        setBuilding(data?.data?.content?.building || null);

        const { data: getPropertiesData } = await getProperties({ buildingId });
        const buildingFloors = getPropertiesData?.data?.content || [];

        const properties: Array<Property> = [];
        for (let i = 0; i < buildingFloors.length; i++) {
          const bf: BuildingFloorModel = buildingFloors[i];

          // 층
          const floorProperty: Property = {
            type: "FLOOR",
            id: bf?.id,
            floor: String(bf?.floorNum || ""),
            floorName: bf.floorName,
            isGray: true,
          };
          properties.push(floorProperty);

          // 호
          (bf?.buildingRoomList || []).forEach((br: BuildingRoomModel) => {
            const hoProperty: Property = {
              type: "HO",
              id: floorProperty.id,
              floor: floorProperty.floor,
              hoId: br?.id,
              ho: Number(br?.roomNum || 0),
              hoName: br.roomName,
              dedicatedArea: Number(br?.leasableAreaNet || 0),
              contractArea: Number(br?.leasableArea || 0),
              rent: br?.rent,
              ownerId: br.ownerId,
              ownerName: br.ownerName,
            };
            properties.push(hoProperty);
          });
        }
        setProperties(sortingProperties(properties));
        setLoadingBar(false);
      }
      fetchBuilding(buildingId);
    }
  }, [buildingId, getBuilding, getProperties, setLoadingBar]);

  // title 에 건물명 바인딩
  let buildingName;
  if (buildingId && building?.buildingName) {
    buildingName = building?.buildingName;
  }
  useTitleOperation(buildingName);

  // 건물의 층과 호실정보 테이블 컬럼과 셀
  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "층",
        accessor: "floor",
        width: 100,
        Cell: ({ value, row }: any) => {
          if (row?.original?.type === "FLOOR") {
            const floor = floorOptions.find((option: SelectOption) => option.value === value);
            return floor?.label || "";
          } else {
            return <div className="ic_ho"></div>;
          }
        },
      },
      {
        Header: "층 명칭",
        accessor: "floorName",
        width: 90,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "호",
        accessor: "ho",
        width: 60,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "호 명칭",
        accessor: "hoName",
        width: 90,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "전용면적(m2)",
        accessor: "dedicatedArea",
        width: 105,
        Cell: ({ row, data, value }: any) => {
          const isFloorRow = row?.original?.type === "FLOOR";
          if (isFloorRow) {
            // 층의 경우, 호의 전용면적 값을 모두 더한 값을 보여준다.
            const sum = sumByFloor(row?.original?.floor, "dedicatedArea", data);
            return <div>{sum}</div>;
          } else {
            return <div>{numberToStringWithComma(value, "0,0.00")}</div>;
          }
        },
      },
      {
        Header: "전용면적(평)",
        accessor: "dedicatedAreaPyeong",
        width: 105,
        Cell: ({ row, data, value }: any) => {
          const isFloorRow = row?.original?.type === "FLOOR";
          if (isFloorRow) {
            // 층의 경우, 호의 전용면적(평) 값을 모두 더한 값을 보여준다.
            const hoList = (data || []).filter(
              (d: Property) => d.type === "HO" && d.floor === row?.original?.floor,
            );
            const sum = hoList.reduce(
              (acc: number, cur: any) => acc + calculatePyeongNum(Number(cur.dedicatedArea || 0)),
              0,
            );
            return <div>{numberToStringWithComma(roundToTwo(sum), "0,0.00")}</div>;
          } else {
            return <div>{calculatePyeong(Number(row?.original?.dedicatedArea || 0))}</div>;
          }
        },
      },
      {
        Header: "임대면적(m2)",
        accessor: "contractArea",
        width: 105,
        Cell: ({ row, data, value }: any) => {
          const isFloorRow = row?.original?.type === "FLOOR";
          if (isFloorRow) {
            // 층의 경우, 호의 임대면적 값을 모두 더한 값을 보여준다.
            const sum = sumByFloor(row?.original?.floor, "contractArea", data);
            return <div>{sum}</div>;
          } else {
            return <div>{numberToStringWithComma(value, "0,0.00")}</div>;
          }
        },
      },
      {
        Header: "임대면적(평)",
        accessor: "contractAreaPyeong",
        width: 105,
        Cell: ({ row, data, value }: any) => {
          const isFloorRow = row?.original?.type === "FLOOR";
          if (isFloorRow) {
            // 층의 경우, 호의 임대면적(평) 값을 모두 더한 값을 보여준다.
            const hoList = (data || []).filter(
              (d: Property) => d.type === "HO" && d.floor === row?.original?.floor,
            );
            const sum = hoList.reduce(
              (acc: number, cur: any) => acc + calculatePyeongNum(Number(cur.contractArea || 0)),
              0,
            );
            return <div>{numberToStringWithComma(roundToTwo(sum), "0,0.00")}</div>;
          } else {
            return <div>{calculatePyeong(Number(row?.original?.contractArea || 0))}</div>;
          }
        },
      },
      {
        Header: "임대료(원)",
        accessor: "rent",
        width: 120,
        Cell: ({ row, data, value }: any) => {
          const isFloorRow = row?.original?.type === "FLOOR";
          if (isFloorRow) {
            // 층의 경우, 호의 임대료 값을 모두 더한 값을 보여준다.
            const sum = sumByFloor(row?.original?.floor, "rent", data, "0,0");
            return <div>{sum}</div>;
          } else {
            return <div>{numberToStringWithComma(value)}</div>;
          }
        },
      },
      {
        Header: "호실",
        accessor: "hosil",
        width: 60,
        Cell: ({ row, data, value }: any) => {
          const isFloorRow = row?.original?.type === "FLOOR";
          if (isFloorRow) {
            /// 층의 경우, 호실 합계 갯수를 보여준다
            const hoList = (data || []).filter(
              (d: Property) => d.type === "HO" && d.floor === row?.original?.floor,
            );
            return <div>{hoList.length}</div>;
          } else {
            return <div></div>;
          }
        },
      },
      {
        Header: "소유주",
        accessor: "ownerName",
        width: 170,
        Cell: ({ value }: any) => {
          return <div>{value || "-"}</div>;
        },
      },
    ],
    [],
  );

  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection title="프로퍼티" id={buildingId} />
        <section>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">건물의 층과 호실정보</p>
            </div>
          </div>

          {/* 층 호실 저장된 목록 테이블 */}
          <ViewDataTable columns={columns} data={properties} />
        </section>
      </div>

      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            className="color-white size-large"
            onClick={() => navigate(PagePath.building.list)}
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyDetail;
