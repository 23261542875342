import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { BaseButton, BaseInput, BaseSelect } from "src/components";
import {
  DeskSpace,
  deskSpaceTypeOptions,
  FrontDeskSpace,
  SelectOption,
} from "src/pages/building/building-types";

type Props = {
  deskSpace?: DeskSpace;
  saveDeskSpace: Function;
  setDeskSpace: Function;
  deskSpaces?: Array<DeskSpace>;
};

// 좌석 테이블 컴포넌트
const SaveDesk = ({ deskSpace, setDeskSpace, saveDeskSpace, deskSpaces }: Props) => {
  // useForm defaultValues

  // const defaultValues: DeskSpace = useMemo(() => {
  const defaultValues: DeskSpace = useMemo(() => {
    return {
      type: "DESK",
      deskGroupId: undefined,
      deskId: undefined,
      floor: undefined,
      deskGroupName: undefined,
      deskGroupDescription: undefined,
      deskName: undefined,
      locationCode: "",
    };
  }, []);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm<DeskSpace>({
    defaultValues,
  });
  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    register("deskGroupId", {
      required: "좌석 그룹 id는 필수입력항목입니다.",
    });
    register("deskName", {
      required: "좌석 명은 필수입력항목입니다.",
    });
    register("locationCode", {
      required: "Location code 는 필수입력 항목입니다.",
    });
  }, [register, deskSpaces]);

  // 수정시 api 에서 받아온 deskSpace 정보로 setValue 처리
  useEffect(() => {
    if (deskSpace) {
      Object.entries(deskSpace).forEach(([name, value]: any) => {
        setValue(name, value);
      });
    }
  }, [setValue, deskSpace]);

  useEffect(() => {
    // 수정 버튼으로 deskSpace 변경시 validation error clear
    clearErrors();
  }, [deskSpace, clearErrors]);

  // 등록되어있는 좌석 그룹 id 선택 옵션 배열
  const deskGroupIdOptions: Array<SelectOption> = useMemo(() => {
    return (deskSpaces || [])
      .filter((deskSpace: DeskSpace) => deskSpace.type === "GROUP")
      .map((deskSpace: DeskSpace) => {
        const selectOption: SelectOption = {
          value: `${deskSpace.deskGroupId}`,
          label: `${deskSpace.deskGroupId}`,
        };
        return selectOption;
      });
  }, [deskSpaces]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: any, e?: any) => {
    e.preventDefault();
    console.log("data", data);
    saveDeskSpace(data);
    return false;
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    // console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  // 취소 버튼클릭시 층 form 리셋
  const resetForm = () => {
    setDeskSpace({ type: "DESK" });

    clearErrors();
    for (const [name, value] of Object.entries(defaultValues)) {
      setValue(name as keyof DeskSpace, value);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <table
          className={errors && Object.keys(errors).length > 0 ? "inner-table" : "inner-table mb20"}
          width="940"
        >
          <thead>
            <tr>
              <th>
                <span>구분</span>
              </th>
              <th>
                <span className="required">좌석 그룹 id</span>
              </th>
              <th>
                <span className="required">좌석 명</span>
              </th>
              <th>
                <span className="required">Location code</span>
              </th>
              <th>
                <div></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="150px">
                <BaseSelect
                  value={deskSpace?.type}
                  stateOptions={deskSpaceTypeOptions}
                  setStateValue={(type: string) => {
                    setDeskSpace({ type });
                  }}
                />
              </td>
              <td width="150px">
                <Controller
                  control={control}
                  name="deskGroupId"
                  render={({ field: { onChange, value, name } }) => (
                    <BaseSelect
                      stateOptions={deskGroupIdOptions}
                      setStateValue={onChange}
                      value={String(value || "")}
                      name={name}
                      // isDisabled={!!getValues("deskId")} // 수정일 경우 disabled
                    />
                  )}
                ></Controller>
              </td>
              <td width="240px">
                <Controller
                  control={control}
                  name="deskName"
                  render={({ field: { onChange, value, name } }) => (
                    <BaseInput type="text" value={value} name={name} onChange={onChange} />
                  )}
                ></Controller>
              </td>
              <td width="300px">
                <Controller
                  control={control}
                  name="locationCode"
                  render={({ field: { value, name, onChange } }) => (
                    <BaseInput value={value} name={name} onChange={onChange} />
                  )}
                ></Controller>
              </td>
              <td width="100px">
                <div className="flex-center">
                  <BaseButton type="submit" title="저장" className="mr2" />
                  <BaseButton title="취소" className="color-white" onClick={() => resetForm()} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {errors && Object.keys(errors).length > 0 && (
          <p className="validation-text mb20">{Object.entries(errors)[0][1]?.message}</p>
        )}
      </form>
    </>
  );
};

export default SaveDesk;
