import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApiOperation from "src/api/hooks/api-operation";
import { getOrgDetail } from "src/api/organization/org-api";
import { MbOrgDetail } from "src/api/organization/org-types";
import { ContentsTitle } from "src/components";
import { useTitleOperation } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import OrgAdminMemoTab from "../form/components/orgAdminMemo/OrgAdminMemoTab";
import OrgAdminMemo from "./components/adminMemo/OrgAdminMemo";
import OrgBaseInfo from "./components/baseInfo/OrgBaseInfo";
import BuildingInfo from "./components/buildingInfo/BuildingInfo";
import UseProductInfo from "./components/useProductInfo/UseProductInfo";

const OrganizationDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: orgId } = useParams();

  const tabs = [
    { value: "basicInfo", label: "기본정보" },
    { value: "useProduct", label: "신청/계약" },
    { value: "buildingInfo", label: "건물" },
    // { value: "adminMemo", label: "관리자 메모" }, // api 만들어지면 노출예정
  ];

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      // console.log("clickTab", tab);
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      // console.log("clickBreadCrumb", crumb);
      if (crumb.value === "product") {
        navigate(PagePath.product.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "organization", label: "사업자" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  return (
    <div>
      <ContentsTitle
        title="사업자"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />

      <div className="contents-container__scroll">
        {activeTab?.value === "basicInfo" && <OrgBaseInfo />}
        {activeTab?.value === "useProduct" && <UseProductInfo orgId={String(orgId)} />}
        {activeTab?.value === "buildingInfo" && <BuildingInfo orgId={String(orgId)} />}
        {/* {activeTab?.value === "adminMemo" && <OrgAdminMemoTab />} */}
      </div>
    </div>
  );
};

export default OrganizationDetail;
