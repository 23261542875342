import React from "react";
import { useMemo } from "react";
import { useBlockLayout, useGlobalFilter, usePagination, useTable } from "react-table";
import { MgmtPhoneType } from "src/api/building/building-types";

type Props = {
  data: Array<MgmtPhoneType>;
  columns: any;
};

const OfficerViewTable = ({ columns, data }: Props) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
    useGlobalFilter,
    usePagination,
  );

  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <section className="inner-tab-table">
      {/* 테이블 헤더 */}
      <div {...getTableProps()} className="base-table sticky">
        <div className="header text-center">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
              {headerGroup.headers.map((header) => {
                return (
                  <div {...header.getHeaderProps()} className="base-table__th flex-center-center">
                    {header.render("Header")}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        {/* 테이블 바디 */}
        <div {...getTableBodyProps()} className="body">
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <div
                {...row.getRowProps()}
                // isGray: 회색 바탕 여부
                className={`base-table__tr ${row.original?.isGray ? "bg-gray" : ""}`}
              >
                {row.cells.map((cell: any) => {
                  return (
                    <div {...cell.getCellProps()} className="base-table__td flex-center-center">
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
          {rows.length === 0 && (
            <div className="base-table__tr" style={{ width: tableWidth }}>
              <div className="base-table__td w-100 text-center">
                <div className="w-100">
                  <span>데이터가 없습니다.</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default OfficerViewTable;
