import { useCallback, useEffect, useMemo, useState } from "react";
import { Cell } from "react-table";
import { BuildingModel, CommonFacilityModel } from "src/api/building/building-types";
import { getEslProductDetailAsync } from "src/api/esl/esl-api";
import { EslProduct } from "src/api/esl/esl-types";
import { useApiOperation } from "src/api/hooks";
import { MediaFile } from "src/api/public-types";
import { BaseTooltip } from "src/components";
import ImagesDetail from "src/components/ImagesDetail";
import {
  floorOptions,
  SelectOption,
  sortingRefreshRoomList,
  spaceStyleOptions,
} from "src/pages/building/building-types";
import ViewDataTable from "src/pages/building/components/ViewDataTable";

type Props = {
  building: BuildingModel | null;
};

interface EslLabel extends EslProduct {
  locationCode: string; // 공간유형 채번
}

/* 
  편의시설 컴포넌트
 */
const RefreshRoomSection = ({ building }: Props) => {
  // ESL 데이터 상세 조회 api
  const { executeAsync: getEslProductDetail } = useApiOperation(getEslProductDetailAsync, {
    doNotErrorHandleModal: true,
  });

  // 건물 상세조회에서 얻어온 편의시설 목록
  const refreshRoomList: Array<CommonFacilityModel> = useMemo(() => {
    return sortingRefreshRoomList(building?.buildingCommonFacility?.refreshRoomList || []);
  }, [building]);

  const [mediaList, setMediaList] = useState<Array<MediaFile>>([]);

  // 조회한 ESL 데이터 목록
  const [eslLabels, setEslLabels] = useState<Array<EslLabel>>([]);

  // esl 라벨 목록 조회
  const fetchAllLabels = useCallback(async (locationCodes: Array<string>) => {
    try {
      if (!locationCodes || locationCodes.length === 0) {
        throw Error("locationCodes.length === 0");
      }

      const eslLabels: Array<EslLabel> = [];
      for (let i = 0; i < locationCodes.length; i++) {
        const locationCode = locationCodes[i];
        const result = await getEslProductDetail({ productId: locationCode });
        if (result?.data?.data && result.status >= 200 && result.status <= 299) {
          if (result?.data?.data?.product) {
            // esl 라벨이 연동되어있을 경우만 조회됨
            eslLabels.push({
              ...result?.data?.data?.product,
              ...{
                locationCode,
              },
            });
          }
        }
      }
      setEslLabels(eslLabels);
    } catch (error) {
      // 에러 발생해도 무시
      console.debug("fetchAllLabels error", error);
    }
  }, []);

  // 수정시 api 에서 받아온 building 정보로 setValue 처리
  useEffect(() => {
    if (refreshRoomList) {
      // locaiotn code 로 라벨 목록 조회
      const locationCodes: Array<string> = [];
      for (let i = 0; i < refreshRoomList.length; i++) {
        const locationCode = refreshRoomList[i].locationCode;
        if (!!locationCode) {
          locationCodes.push(locationCode);
        }
      }
      if (locationCodes.length > 0) {
        fetchAllLabels(locationCodes);
      }
    }
  }, [refreshRoomList, fetchAllLabels]);

  // 이미지 갯수 클릭
  const clickImageCount = useCallback((rowData: CommonFacilityModel) => {
    setMediaList(rowData?.mediaList || []);
    return false;
  }, []);

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
        width: 80,
        Cell: ({ value }: Cell<CommonFacilityModel>) => (
          <BaseTooltip contents={value} isSingleLine={true} />
        ),
      },
      {
        Header: "위치",
        accessor: "floor",
        width: 100,
        Cell: ({ row }: any) => {
          const isGround: boolean = row?.original?.isGround; // 지상여부
          const floorNum: number = row?.original?.floorNum; // 위치
          const floor = floorOptions.find(
            (option: SelectOption) => option.value === `${isGround ? "" : "-"}${floorNum}`,
          );
          return floor?.label || "";
        },
      },
      {
        Header: "공간 명",
        accessor: "facilityName",
        width: 100,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "공간유형",
        accessor: "spaceStyle",
        width: 80,
        Cell: ({ value }: any) => {
          const findOption = spaceStyleOptions.find(
            (option: SelectOption) => option.value === value,
          );
          if (findOption && findOption.value !== "SPACE_STYLE_UNRECOGNIZED") {
            return <BaseTooltip contents={findOption.label} />;
          }
          return "";
        },
      },
      {
        Header: "Location code",
        accessor: "locationCode",
        width: 120,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "기자재",
        accessor: "equipments",
        width: 170,
        Cell: ({ row }: any) => {
          const facilityList: Array<string> = row?.original?.facilityList || []; // 기자재
          return <BaseTooltip contents={facilityList.join(",")} />;
        },
      },
      {
        Header: "설명",
        accessor: "description",
        width: 140,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "ESL",
        accessor: "eslAddress",
        width: 80,
        Cell: ({ row }: any) => {
          if (!!row?.original?.locationCode && eslLabels.length > 0) {
            const findLabel = eslLabels.find(
              (eslLabel: EslLabel) => eslLabel.locationCode === row?.original?.locationCode,
            );
            if (findLabel) {
              return <BaseTooltip contents={(findLabel.assignedLabelCodes || []).join(",")} />;
            }
          }
          return "";
        },
      },
      {
        Header: "이미지",
        accessor: "images",
        width: 60,
        Cell: ({ row }: any) => {
          const imageCount = (row?.original?.mediaList || []).length;
          return (
            <div>
              <a
                className="text-hilight cursor-pointer"
                onClick={() => clickImageCount(row?.original)}
              >
                {imageCount}
              </a>
            </div>
          );
        },
      },
    ],
    [clickImageCount, eslLabels],
  );

  return (
    <section className="mt40">
      {refreshRoomList.length > 0 ? (
        <>
          <div className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">편의시설</p>
            </div>
          </div>
          <ViewDataTable columns={columns} data={refreshRoomList} />
          <div className="mt20">
            <ImagesDetail images={mediaList} />
          </div>
        </>
      ) : (
        <div className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">편의시설</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">데이터가 없습니다.</span>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default RefreshRoomSection;
