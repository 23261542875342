import { OfficerType, UnionCmdType, UnionOfficerType, UnionRoleType } from "../../building-types";

export interface FrontMgmtOfficeModel {
  id?: string; // 관리처 ID
  buildingId?: string; // 건물 id
  // mgmtOfficeName?: string; // 관리자명
  providerId?: string;
  officePhoneList?: Array<FrontMgmtPhoneType>; //관리자 연락처
  startTime?: Date; // 시작시간
  endTime?: Date; // 종료시간
  isHoliday?: boolean; // 공휴일 여부
  paymentId?: string; // 결제서비스 ID
  paymentSecretKey?: string; //결제서비스 secret Key
  officerList?: Array<FrontMgmtOfficerType>; // 관리처 담당자 ;
  accountInfoList?: Array<FrontMgmtAccountType>; // 관리처 수납 계좌 ;
}

export interface FrontMgmtOfficeFormType {
  office: FrontMgmtOfficeModel;
}

// 관리처 연락처 타입
export interface FrontMgmtPhoneType {
  id?: string;
  isPrimary?: boolean; //대표여부
  number?: string; //번호
  cmdType?: UnionCmdType; //처리요청
  apiId?: string;
}

// 관리처 담당자 타입
export interface FrontMgmtOfficerType {
  id?: string;
  name?: string; //담당자명
  phone?: string; //연락처
  isPrimary?: boolean; //대표 매니저 여부
  roleType?: UnionRoleType; // 역할타입
  officerTypeList?: Array<UnionOfficerType>; //업무타입
  description?: string; //업무내용
  cmdType?: UnionCmdType; //처리요청
  apiId?: string;
  isDeleted?: boolean;
}

// 관리처 은행 타입
export interface FrontMgmtAccountType {
  id?: string;
  bankCode?: string; // 은행코드
  accountNumber?: string; //계좌번호
  accountHolder?: string; //예금자명
  cmdType?: UnionCmdType; // 처리요청
  apiId?: string;
}

// 관리처관리 담당자 리스트
export const officerTypeList = [
  { label: "관리", value: OfficerType.MANAGEMENT },
  { label: "시설", value: OfficerType.FACILITY },
  { label: "미화", value: OfficerType.CLEANER },
  { label: "보안과 경비", value: OfficerType.SECURITY },
  { label: "생활편의", value: OfficerType.CONVENIENCE },
  { label: "CS", value: OfficerType.CS },
];
