import _ from "lodash";
import moment from "moment";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { MgmtOfficeModel } from "src/api/building/building-types";
import {
  editBuildingManagementAsync,
  postBuildingManagementAsync,
} from "src/api/building/management-api";
import { useApiOperation } from "src/api/hooks";
import { ProviderModel } from "src/api/provider/provider-types";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseCheckbox, BaseDatePicker, BaseModal } from "src/components";
import { useLoadingBarContext, useToastContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { FrontMgmtOfficeModel } from "../management-type";
import ManagementListPopup from "./ManagementListPopup";

type Props = {
  settlementCompany: ProviderModel;
  setSettlementCompany: Dispatch<SetStateAction<ProviderModel | undefined>>;
  managementDetail?: MgmtOfficeModel;
  frontOriginalData?: FrontMgmtOfficeModel;
  buildingId?: string;
  getBuildingManagement: Function;
  partnerId?: string;
};

const ManagementOfficeInfoSection = ({
  settlementCompany,
  setSettlementCompany,
  managementDetail,
  frontOriginalData,
  buildingId,
  partnerId,
}: Props) => {
  const { setLoadingBar } = useLoadingBarContext();
  // 토스트
  const { openToast } = useToastContext();

  const navigate = useNavigate();

  const [mntModal, setMntModal] = useState<Modal>({ isOpen: false });

  // 경고 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 컨펌 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  // ========================== API ==========================
  //
  // 관리처 관리 등록
  const { executeAsync: postBuildingManagement } = useApiOperation(postBuildingManagementAsync);

  // 관리처 관리 수정
  const { executeAsync: editBuildingManagement } = useApiOperation(editBuildingManagementAsync);

  // useForm default
  const defaultValues = useMemo(() => {
    const officeData: FrontMgmtOfficeModel = {
      buildingId,
      providerId: "",
      isHoliday: false,
    };

    return officeData;
  }, [buildingId]);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FrontMgmtOfficeModel>({
    defaultValues,
  });

  useEffect(() => {
    if (managementDetail?.id) {
      setValue("startTime", moment(managementDetail.startTime, "hh:mm").toDate());
      setValue("endTime", moment(managementDetail.endTime, "hh:mm").toDate());
      setValue("isHoliday", managementDetail.isHoliday);
      setValue("providerId", managementDetail.providerId);
    }
  }, [managementDetail]);

  // 유효성 확인
  const formValidation = useCallback(() => {
    // 연락처 패턴

    // 정산정보 관리처
    register(`providerId`, {
      required: { value: true, message: "관리처를 선택해 주세요." },
    });

    // 출입시간
    register(`startTime`, {
      required: { value: true, message: "정보를 입력해 주세요." },
    });

    register(`endTime`, {
      required: { value: true, message: "정보를 입력해 주세요." },
    });
  }, [register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  // 수정 데이터 만들기
  const makeRequestEditData = useCallback(
    (data: FrontMgmtOfficeModel) => {
      // 인자로 넘어온 data와 original 데이터를 비교해서 변경된 것만 editData에 추가
      const original = frontOriginalData;

      let editData = {};

      // 변경된 여부 확인 (Boolean return)
      const isProviderId = _.isEqual(original?.providerId, data.providerId);

      const isStartTime = _.isEqual(original?.startTime, data.startTime);
      const isEndTime = _.isEqual(original?.endTime, data.endTime);
      const isIsHoliday = _.isEqual(original?.isHoliday, data.isHoliday);

      if (!isProviderId) editData = { ...editData, providerId: data.providerId };

      //startTime 수정
      if (!isStartTime)
        editData = { ...editData, startTime: moment(data?.startTime).format("HH:mm") };

      //endTime 수정
      if (!isEndTime) editData = { ...editData, endTime: moment(data?.endTime).format("HH:mm") };

      // 공휴일 수정
      if (!isIsHoliday) editData = { ...editData, isHoliday: data.isHoliday };

      return editData;
    },
    [frontOriginalData],
  );

  // 컨펌모달 저장 클릭시 등록/수정
  const onClickConfirmModal = useCallback(
    async (data: FrontMgmtOfficeModel) => {
      //
      setLoadingBar(true);
      // id null 이면 신규등록 / 아니면 수정
      if (managementDetail?.id === null) {
        const postData: MgmtOfficeModel = {
          ...data,
          startTime: moment(data?.startTime).format("HH:mm"),
          endTime: moment(data?.endTime).format("HH:mm"),
        };

        console.log("postData", postData);

        // 등록
        const response = await postBuildingManagement({ office: postData });
        if (response.status >= 200 && response.status <= 200) {
          openToast(`정상적으로 ${"등록"}되었습니다.`);
          setConfirmModal({ isOpen: false });
          navigate(`${PagePath.building.form}?id=${buildingId}&tab=mgmtOffice`);
        }
      } else {
        // 수정 api 통신

        const editData = makeRequestEditData(data);
        console.log("editData", editData);

        const response = await editBuildingManagement({
          id: String(managementDetail?.id),
          office: editData,
        });
        if (response.status >= 200 && response.status <= 200) {
          openToast(`정상적으로 ${"수정"}되었습니다.`);
          setConfirmModal({ isOpen: false });
        }
      }
      setLoadingBar(false);
    },
    [
      buildingId,
      editBuildingManagement,
      makeRequestEditData,
      managementDetail?.id,
      openToast,
      postBuildingManagement,
    ],
  );

  // 유효성 검사후 저장
  const onSubmit = useCallback(async (data?: FrontMgmtOfficeModel, e?: any) => {
    e.preventDefault();

    // 경고창 모달 부분
    // 업무시간 설정 모달 - startTime > endTime 일경우
    if (data?.startTime && data?.endTime) {
      if (data.startTime > data.endTime) {
        setAlertModal({
          isOpen: true,
          message: "업무시간 설정을 확인해주세요. \n\n 종료시간은 시작시간보다 이전일 수 없습니다.",
        });
        return;
      }
    }

    setConfirmModal({ isOpen: true, message: "저장하시겠습니까?", payload: data });
  }, []);

  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);

  return (
    <article>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <div className="contents-container__sub-title">
              <p className="required">관리처</p>
            </div>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <div className="flex-center-start">
                <span className={settlementCompany?.providerName && "mr10"}>
                  {settlementCompany?.providerName}
                </span>
              </div>
            </div>
            <p className="validation-text">{errors.providerId && errors.providerId.message}</p>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">업무시간</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400 flex-row align-items-center">
              {/* 데이터피커 */}
              <Controller
                control={control}
                name={`startTime`}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  return (
                    <BaseDatePicker
                      setDate={onChange}
                      type="time"
                      name={name}
                      selectedDate={value}
                    />
                  );
                }}
              ></Controller>
              <span className="font14 mx10">~</span>
              <Controller
                control={control}
                name={`endTime`}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  return (
                    <BaseDatePicker
                      setDate={onChange}
                      type="time"
                      name={name}
                      selectedDate={value}
                    />
                  );
                }}
              ></Controller>
              <div className="ml10">
                <Controller
                  control={control}
                  name={`isHoliday`}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                    return (
                      <BaseCheckbox
                        id={name}
                        name={name}
                        label="공휴일"
                        onChange={onChange}
                        checked={value}
                      />
                    );
                  }}
                ></Controller>
              </div>
            </div>
            {(errors.startTime || errors.endTime) && (
              <p className="validation-text">정보를 입력해 주세요.</p>
            )}
          </div>
        </section>
      </form>
      <>
        <BaseModal
          isOpen={confirmModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => confirmModal.payload && onClickConfirmModal(confirmModal.payload)}
        >
          <p>{confirmModal.message}</p>
        </BaseModal>
        <BaseModal
          isOpen={alertModal.isOpen}
          btnRightTitle="확인"
          onClick={() => setAlertModal({ isOpen: false })}
        >
          <p className="pre-formatted">{alertModal.message}</p>
        </BaseModal>
      </>

      {mntModal.isOpen && (
        <ManagementListPopup
          partnerId={String(partnerId) || ""}
          onSelected={(settlement) => {
            setSettlementCompany(settlement);
            setValue("providerId", String(settlement.providerId), {
              shouldDirty: true,
              shouldTouch: true,
            });
            setMntModal({ isOpen: false });
          }}
          onClose={() => setMntModal({ isOpen: false })}
        />
      )}
    </article>
  );
};

export default ManagementOfficeInfoSection;
