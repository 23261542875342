import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "src/api";
import { ApiResponse } from "../public-types";

const getScheduleList = (
  axios: AxiosInstance,
  params?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.list;

  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

const getScheduleDetail = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.scheduleDetail.replace(
    "{contractId}",
    params!.contractId!.toString(),
  );
  return axios.get<ApiResponse<any>>(path, { params });
};

const updateScheduleDetail = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    body: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.scheduleDetail.replace("{contractId}", String(params!.contractId));
  return axios.patch<ApiResponse<any>>(path, params?.body);
};
//
const getScheduleRoundList = (
  axios: AxiosInstance,
  params?: {
    scheduleId: number;
    isAll?: boolean;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.roundList.replace("{scheduleId}", params!.scheduleId!.toString());
  return axios.get<ApiResponse<any>>(path, { params });
};
const getScheduleRoundNOverdueList = (
  axios: AxiosInstance,
  params?: {
    scheduleId: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.roundNOverdueList.replace(
    "{scheduleId}",
    String(params!.scheduleId),
  );
  return axios.get<ApiResponse<any>>(path, { params });
};

const editScheduleRoundList = (
  axios: AxiosInstance,
  body?: {
    scheduleId: number;
    billList: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  //console.log("body", body);
  const path = ApiPath.billing.roundList.replace("{scheduleId}", String(body?.scheduleId));
  return axios.patch<ApiResponse<any>>(path, { billList: body?.billList });
};

const editScheduleStop = (
  axios: AxiosInstance,
  body?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('body',body);
  const path = ApiPath.billing.scheduleStop.replace("{scheduleId}", String(body!.scheduleId));
  return axios.patch<ApiResponse<any>>(path, { schedule: body.schedule! });
};
const getScheduleStop = (
  axios: AxiosInstance,
  body?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log("body", body);
  const path = ApiPath.billing.scheduleStop.replace("{scheduleId}", String(body!.scheduleId));
  return axios.get<ApiResponse<any>>(path);
};

const getCreditRelation = (
  axios: AxiosInstance,
  body?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log("body", body);
  const path = ApiPath.billing.creaditRelation.replace("{tid}", String(body!.tid));
  return axios.get<ApiResponse<any>>(path);
};

const editTaxInvoiceNumber = (
  axios: AxiosInstance,
  body?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('body',body);
  const path = ApiPath.billing.changeTaxInvoiceNumber.replace(
    "{scheduleId}",
    String(body!.scheduleId),
  );
  return axios.patch<ApiResponse<any>>(path, { ...body.data });
};

/**
 * 관리자 메모 조회
 * @param axios
 * @param params
 * @returns
 */
const getContractMemoList = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const changeId = ApiPath.contract.detail.memo.list.replace("{serviceId}", params!.id!.toString());
  const path = changeId.replace("{serviceType}", "CT_CONTRACT");
  return axios.get<ApiResponse<any>>(path, { params });
};

const addContractMemo = (
  axios: AxiosInstance,
  body?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('body',body);
  const send = { memoContent: body.contractMemo };
  const changeId = ApiPath.contract.detail.memo.add.replace(
    "{serviceId}",
    body!.contractManageId!.toString(),
  );
  const path = changeId.replace("{serviceType}", "CT_CONTRACT");
  return axios.post<ApiResponse<any>>(path, { contractMemo: { ...send } });
};

const editContractMemo = (
  axios: AxiosInstance,
  body?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('body',body);
  const send = {
    contractMemoId: body.contractMemoId,
    memoContent: body.contractMemo,
    isDeleted: "false",
  };
  const path = ApiPath.contract.detail.memo.edit.replace(
    "{memoId}",
    body!.contractMemoId!.toString(),
  );
  return axios.patch<ApiResponse<any>>(path, { contractMemo: send });
};

/**
 * 회원의 관리자 메모 삭제
 * @param axios
 * @param body
 * @returns
 */
const deleteContractMemo = async (
  axios: AxiosInstance,
  body?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const send = {
    id: body.contractMemoId,
    isDeleted: "true",
  };
  const path = ApiPath.contract.detail.memo.edit.replace(
    "{memoId}",
    body!.contractMemoId!.toString(),
  );
  return axios.patch<ApiResponse<any>>(path, { contractMemo: send });
};
const retryPayment = (
  axios: AxiosInstance,
  params?: {
    billId: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log("params", params);
  const path = ApiPath.billing.retryPayment.replace("{billId}", String(params?.billId));

  return axios.post<ApiResponse<any>>(path, {});
};
const changeRecieve = (
  axios: AxiosInstance,
  params?: {
    scheduleId: number;
    body: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log("params", params);
  const path = ApiPath.billing.receipt.replace("{scheduleId}", String(params?.scheduleId));

  return axios.patch<ApiResponse<any>>(path, { receiptList: params?.body });
};

const connectReceive = (
  axios: AxiosInstance,
  params?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log("params", params);
  const path = ApiPath.billing.creaditConfirm.replace("{contractId}", String(params?.contractId));

  return axios.patch<ApiResponse<any>>(path, [...params?.body.saveCreadits]);
};

export {
  getScheduleList,
  getScheduleDetail,
  updateScheduleDetail,
  getScheduleRoundList,
  getScheduleRoundNOverdueList,
  editScheduleRoundList,
  editScheduleStop,
  getScheduleStop,
  getContractMemoList,
  addContractMemo,
  editContractMemo,
  deleteContractMemo,
  retryPayment,
  changeRecieve,
  connectReceive,
  getCreditRelation,
  editTaxInvoiceNumber,
};
