/* 
  공간상품 > 시설/서비스 - UI 에서 사용하는 type 들
 */

import { HeatingType } from "src/api/public-types";

// select box 공통 option
export type SelectOption = {
  value: string;
  label: string;
  index?: number;
};

// modal 공통
export type Modal = {
  isOpen: boolean;
  message?: string;
  type?: string;
  payload?: any;
};

// 시설서비스 form data
export type FacilityFormData = {
  heatingType: HeatingType; // 난방타입
  heatingTypeDesc?: string; // 난방타입설명
  isAirConditionerProvided: boolean; // 공조기제공여부
  airConditionerDescription?: string; // 공조기 추가 설명
  airConditionerInfoList: Array<AirConditionerInfo>; // 공조기 기기정보
  restrooms: Array<Restroom>; // 화장실
  isPrinterProvided: boolean; // 복합기 제공여부
  printerDescription?: string; // 복합기 추가 설명
  printerOptionList: Array<PrinterOption>; // 복합기 옵션
  isFreeInternetProvided: boolean; // 무료인터넷 제공여부
  freeInternetDescription?: string; // 무료인터넷 추가 설명
  freeInternetOptionList: Array<FreeInternet>; // 무료인터넷 옵션
  productOptionalFacilityList: Array<OptionalFacility>; // 추가 시설/서비스
  etcService?: string; // 기타시설서비스
};

// 공조기 기기정보
export type AirConditionerInfo = {
  id?: number; // MetaData id
  metaItem: string; // 공조기 기기정보 metaItem
  label: string; // 표기 label
  checked: boolean; // 체크 여부
  value?: number | string; // 기기 대수 또는 직접입력 텍스트
};

// 화장실
export type Restroom = {
  seq: number;
  id?: number; // MetaData id
  location: "INDOOR" | "OUTDOOR"; // 실내 or 실외
  type: "PUBLIC" | "SEPARATION"; // 남여 통합 or 남여 분리
  description?: string; // 설명
};

// 복합기 옵션
export type PrinterOption = {
  id?: number; // MetaData id
  metaItem: string; // 복합기 옵션 metaItem
  label: string; // 표기 label
  checked: boolean; // 체크 여부
};

// 무료인터넷 옵션
export type FreeInternet = {
  id?: number; // MetaData id
  metaItem: string; // 무료인터넷 옵션 metaItem
  label: string; // 표기 label
  checked: boolean; // 체크 여부
};

// 추가 시설/서비스
export type OptionalFacility = {
  id?: number; // MetaData id
  metaItem: string; // 추가 시설/서비스 종류 metaItem
  label: string; // 표기 label
  isProvided: boolean; // 제공 여부
  description?: string; // 추가 설명
};

// 화장실 위치
export const restroomLocationOptions: Array<SelectOption> = [
  { value: "INDOOR", label: "실내" },
  { value: "OUTDOOR", label: "실외" },
];
