import { useMemo } from "react";
import {
  ProductGuide,
  ProductGuideContainer,
  ProductGuideNetworkCatalog,
  ProductModel,
} from "src/api/product/product-types";
import { MetaData } from "src/api/public-types";
import FreeInternetNetwork from "./FreeInternetNetwork";

type Props = {
  networkCatalogList: Array<ProductGuideNetworkCatalog> | null;
  guide?: ProductGuideContainer;
};

/* 
  공간상품 > 상세 > 안내 > 와이파이 컴포넌트
*/
const FreeInternetSection = ({ networkCatalogList, guide }: Props) => {
  // 무료 인터넷 제공여부

  const customerWifiGuideList = useMemo(() => {
    return networkCatalogList && networkCatalogList.filter((item) => item.isOpened === false);
  }, [networkCatalogList]);

  const visitorWifiGuideList = useMemo(() => {
    return networkCatalogList && networkCatalogList.filter((item) => item.isOpened === true);
  }, [networkCatalogList]);

  return (
    <section className="contents-container__grid contents-container__1200 mb30">
      <div className="contents-container__grid-index">
        <p>상세내용</p>
      </div>

      <div className="contents-container__grid-contents">
        <div>
          <div className="py16">
            <span className="text-primary5">고객용 네트워크</span>
          </div>
          {customerWifiGuideList?.map(
            (customerWifiGuide: ProductGuideNetworkCatalog, index: number) => (
              <div key={index.toString()} className="border-gray mb10">
                <div className="pa16">
                  <div className="flex-center mb10">
                    <div className="minmax140">
                      <span>위치</span>
                    </div>
                    <div>
                      <span className="font14">{customerWifiGuide.location}</span>
                    </div>
                  </div>
                  {/* 네트워크 */}
                  <FreeInternetNetwork
                    wifiList={customerWifiGuide?.productGuideNetworkList || []}
                  />
                  <div className="flex-center mb10">
                    <div className="minmax140">
                      <span>설명</span>
                    </div>
                    <div>
                      <span className="font14 pre-formatted">{customerWifiGuide?.description}</span>
                    </div>
                  </div>
                </div>
              </div>
            ),
          )}
        </div>

        <div className="mt70">
          <div className="py16">
            <span className="text-primary5">방문자용 네트워크</span>
          </div>
          {visitorWifiGuideList?.map(
            (visitorWifiGuide: ProductGuideNetworkCatalog, index: number) => (
              <div key={index.toString()} className="border-gray mb10">
                <div className="pa16">
                  <div className="flex-center mb10">
                    <div className="minmax140">
                      <span>위치</span>
                    </div>
                    <div>
                      <span className="font14">{visitorWifiGuide.location}</span>
                    </div>
                  </div>
                  {/* 네트워크 */}
                  <FreeInternetNetwork wifiList={visitorWifiGuide?.productGuideNetworkList || []} />
                  <div className="flex-center mb10">
                    <div className="minmax140">
                      <span>설명</span>
                    </div>
                    <div>
                      <span className="font14 pre-formatted">
                        {visitorWifiGuide?.description || ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </section>
  );
};
export default FreeInternetSection;
