import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Cell,
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { getProductAsync } from "src/api/access/ac-api";
import { useApiOperation } from "src/api/hooks";
import { getProductDetailAsync, getProductListAsync } from "src/api/product/product-api";
import { ProductModel } from "src/api/product/product-types";
import { PageMeta } from "src/api/public-types";
import { BaseInput, BaseModal, BasePagination, BaseRadio, BaseTooltip } from "src/components";
import { ImagePathCell } from "../product/columns/ImagePathCell";
import ProductDepositCell from "../product/columns/ProductDepositCell";
import ProductRentalCostCell from "../product/columns/ProductRentalCostCell";
import { ProductTypeCell } from "../product/columns/ProductTypeCell";
import { SpaceProductNameCell } from "../product/columns/SpaceProductNameCell";
import { StatusCell } from "../product/columns/StatusCell";
import pagePath from "src/pagePath.json";
import _ from "lodash";

const columnHeader: any = [
  {
    Header: "",
    accessor: "isSelected",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row, setSelected }: any) => {
      const changeTrigger = useCallback(() => {
        setSelected(row.original);
      }, [row.original, setSelected]);
      return (
        <div>
          <BaseRadio
            id={`selector${row.original.id}`}
            name={"isSelected"}
            onChange={changeTrigger}
          />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "id",
    // sticky: "left",
    width: 70,
  },
  // {
  //   Header: "이미지",
  //   accessor: "image",
  //   Cell: (props: Cell<ProductModel>) => <ImagePathCell {...props} />,
  //   width: 100,
  // },
  {
    Header: "공간상품명",
    accessor: "productName",
    Cell: (props: Cell<ProductModel>) => {
      const detailPath = `${pagePath.product.detail.replace(":id", `${props.row.original.id}`)}`;

      return (
        <div>
          <Link to={detailPath} target={"_blank"} className="text-hilight">
            <BaseTooltip contents={props?.value || ""} />
          </Link>
        </div>
      );
    },
  },
  {
    Header: "타입",
    accessor: "productType",
    width: 120,
    Cell: (props: Cell<ProductModel>) => <ProductTypeCell {...props} />,
  },
  {
    Header: "보증금",
    accessor: "saleDeposit",
    Cell: (props: Cell<ProductModel>) => <ProductDepositCell {...props} />,
    width: 100,
  },
  {
    Header: "이용료",
    accessor: "rentalCost",
    Cell: (props: Cell<ProductModel>) => <ProductRentalCostCell {...props} />,
    width: 120,
  },
  {
    Header: "주소",
    accessor: "address",
    width: 250,
  },
  {
    Header: "건물명",
    accessor: "buildingName", // api 스펙에 없음
  },
  {
    Header: "상태",
    accessor: "status",
    Cell: (props: Cell<ProductModel>) => <StatusCell {...props} />,
    width: 85,
  },
];
type Props = {
  isOpen: boolean;
  partnerId?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const SearchSpaceProductPopup = (props: Props) => {
  const [selected, setSelected] = useState<any>();

  const [products, setProducts] = useState<ProductModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { executeAsync: getProductList } = useApiOperation(getProductListAsync);
  const [keyword, setKeyword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const { executeAsync: getProduct } = useApiOperation(getProductAsync, {
    doNotErrorHandleModal: false,
  });

  // 공간상품 상세 조회 api
  const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync);
  
  const fetchProduct = useCallback(
    async (id: number) => {
      return await getProduct({ id });
    },
    [getProduct],
  );
  const [params, setParams] = useState<any>({
    page: 0,
    size: 20,

    searchValue: "",
    // status: 'ENABLED',
    sort: {
      orders: [
        {
          property: "id",
          direction: "DESC",
        },
      ],
    },
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columnHeader,
      data: products,
      setSelected,
    },

    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );


  const fetchProductDetail = useCallback(async (productId: number) =>{
    const response: any = await getProductDetail({productId});
    console.log('getProductDetail', response);
    let check = false;
    if(response.data.data.content){

      const buildings = response.data.data.content.buildingList;

      buildings.map((bd: any)=>{
        console.log('mgmtProviderId',bd.building.mgmtProviderId)
        if(Number(bd.building.mgmtProviderId)=== 0){
          check = true;
        }
      })

    }
    return check;
  },[getProductDetail])

  const onClickData = useCallback(async () => {
    setMessage("");
    console.log("selected", selected);

    const mgmtProviderCheck: Boolean = await fetchProductDetail(selected.id);

    if(mgmtProviderCheck){
      setMessage("해당 상품의 건물에 관리처 정보 등록이 필요합니다.");
      setError(true);
      return;
    }
    if (
      selected.providerId === undefined ||
      selected.providerId === null ||
      Number(selected.providerId) === 0 
    ) {
      setMessage("해당 상품의 프로바이더 정보 등록이 필요합니다.");
      setError(true);
      return;
    }

    if (selected && selected.productType === "PRODUCT_UNRECOGNIZED") {
      setMessage("상품타입이 없는 상품은 선택할 수 없습니다.");
      setError(true);
      return;
    }

    if (props.onClick && selected && selected.productType !== "PRODUCT_UNRECOGNIZED") {
      const getAc: any = await fetchProduct(selected.id);
      // console.log('getAc',getAc);
      if (getAc.status >= 200 && getAc.status <= 299 && getAc.data.data.product !== null) {
        props.onClick(selected);
      } else {
        setMessage("해당 상품의 액세스 그룹 설정이 필요합니다.");
        setError(true);
        return;
      }
    }
  }, [fetchProduct, fetchProductDetail, props, selected]);

  const callList = useCallback(
    async (param: any) => {
      const response: any = await getProductList(param);
      if (response.status > 199 && response.status < 300) {
        // console.log("response?.data?.data?.content.length", response?.data?.data?.content);
        for (let i = 0; i < response?.data?.data?.content.length; i++) {
          // response.data.data.content[i].isSelected = false;
          response.data.data.content[i] = Object.assign(response.data.data.content[i], {
            isSelected: false,
          });
          // console.log("response.data.data.content[i]", response.data.data.content[i].providerId);
        }
        setProducts(response?.data?.data?.content || []);
        setPageMeta(response?.data?.meta?.pageMeta);
      }
    },
    [getProductList],
  );



  useEffect(() => {
    if (props.isOpen) {
      let prs = _.cloneDeep(params);
      prs.page = 0;
      prs.keyword = "";
      if (props.partnerId !== undefined) {
        prs.partnerId = props.partnerId;
      }

      setKeyword("");
      callList(prs);
      setParams(prs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <div>
      <BaseModal
        className="dialog-modal"
        isOpen={props.isOpen}
        btnRightTitle="선택"
        btnLeftTitle="취소"
        onClick={() => onClickData()}
        onClose={props.onClose}
      >
        <>
          <div className="modal-title">
            <div className="left-area">
              <span>공간상품 선택</span>
            </div>
            <div className="right-area">
              <div className="minmax260">
                <BaseInput
                  type="text"
                  value={keyword || ""}
                  placeholder="검색어를 입력해주세요"
                  onKeyUp={() => {
                    callList({ ...params, ...{ keyword }, ...{ page: 0 } });
                    setParams({ ...params, ...{ keyword }, ...{ page: 0 } });
                  }}
                  onChange={(value: string) => {
                    setKeyword(value);
                  }}
                  onSearchClick={() => {
                    callList({ ...params, ...{ keyword }, ...{ page: 0 } });
                    setParams({ ...params, ...{ keyword }, ...{ page: 0 } });
                  }}
                />
              </div>
            </div>
          </div>
          {/* table */}
          <div className="contents-container__table">
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <div
                      {...row.getRowProps()}
                      className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <BasePagination
              pageIndex={params.page || 0}
              totalPages={pageMeta?.totalPages || 0}
              goPage={(page: number) => {
                setParams({ ...params, ...{ page } });
                callList({ ...params, ...{ page } });
              }}
            />
          </div>
        </>
      </BaseModal>
      <BaseModal isOpen={error} btnRightTitle={"확인"} onClick={() => setError(false)}>
        <p>{message}</p>
      </BaseModal>
    </div>
  );
};

export default SearchSpaceProductPopup;
