import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { ProviderModel } from "src/api/provider/provider-types";
import { BaseTooltip } from "src/components";
import { PagePath } from "src/pages/product/details";
import { companyRegistrationHypen } from "src/utils";

const ProviderColumns = [
  {
    Header: "id",
    accessor: "providerId",
    width: 100,
    Cell: ({ row, value }: Cell<ProviderModel>) => {
      const detailPath = `${PagePath.provider.detail.replace(
        ":id",
        String(row.original.providerId),
      )}`;

      return (
        <Link to={detailPath} className="text-hilight">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "구분",
    accessor: "supplyType",
    width: 100,
    Cell: ({ row, value }: Cell<ProviderModel>) => {
      return value === "RENTAL" ? "프로바이더" : "관리처";
    },
  },
  {
    Header: "법인명",
    accessor: "providerName",
    width: 180,
    Cell: ({ value }: Cell<ProviderModel>) => {
      return <div className="w-100 text-left"><p>{value}</p></div>
    },
    
  },
  {
    Header: "사업자 등록 번호",
    accessor: "businessRegistrationNumber",
    width: 200,
    Cell: ({ value }: Cell<ProviderModel>) => {
      return companyRegistrationHypen(value);
    },
  },
  {
    Header: "등록일",
    accessor: "createdDate",
    width: 150,
    Cell: (props: Cell<ProviderModel>) => {
      return <BaseTooltip contents={props.value} type="date" />;
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 150,
    Cell: (props: Cell<ProviderModel>) => {
      return <BaseTooltip contents={props.value} type="date" />;
    },
  },
  {
    Header: "파트너",
    accessor: "partnerId",
    width: 80,
    Cell: ({ value }: Cell<ProviderModel>) => {
      return (
        <Link
          target={"_blank"}
          to={PagePath.partner.detail.replace(":id", value)}
          className="text-hilight"
        >
          {value}
        </Link>
      );
    },
  },
];

export default ProviderColumns;
