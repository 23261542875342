import { createPortal } from "react-dom";
interface Props {
  visible?: boolean;
}

export const LoadingBar = (props: Props) => {
  const el = document.getElementById("loading");

  if (!props.visible) return null;

  return createPortal(
    <>
      <div className="loading-dim">
        <div className="base-loading-bar">
          <div className="base-loading-bar__loading-img"></div>
        </div>
      </div>
    </>,
    el!,
  );
};

export default LoadingBar;
