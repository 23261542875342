import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Cell } from "react-table";
import { getBuildingsAsync } from "src/api/building/building-api";
import useApiOperation from "src/api/hooks/api-operation";
import { BaseButton, BaseInput, BaseModal, BasePagination, BaseRadio } from "src/components";
import ViewDataTable from "src/pages/building/components/ViewDataTable";

type Building = {
  id?: number;
  buildingId: number;
  buildingName: string;
};

interface Props {
  onCanceled: () => void;
  onAdded: (selected: Building[]) => void;
  defaultChecked: Building[];
  searchValue?: string;
}

interface CellProps extends Cell<Building> {
  checked: boolean;
}

const TABLE_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 80,
    Cell: (props: CellProps) => {
      const buildingId = props.row.original.id;
      let disabled = false;
      return (
        <div
          data-data-id={buildingId}
          data-checked={props.value}
          className="checkbox"
          data-disabled={disabled}
        >
          <BaseRadio id={""} name={""} checked={props.value} disabled={disabled} />
        </div>
      );
    },
  },
  {
    Header: "건물id",
    accessor: "id",
    width: 80,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 320,
    Cell: (props: CellProps) => {
      const id = props.row.cells[1].value;

      return <div className="w-100 text-left">{props.value}</div>;
    },
  },
  {
    Header: "주소",
    accessor: "address",
    width: 420,
    Cell: (props: CellProps) => <div className="w-100">{props.value ? props.value : "-"}</div>,
  },
];

function BuildingSelectModal({ onCanceled, onAdded, defaultChecked, searchValue }: Props) {
  const [page, setPage] = useState({ current: 0, total: 0, totalElements: 0 });
  const [buildingList, setBuildingList] = useState<any[]>([]);
  const [selectedBuildingList, setSelectedBuildingList] = useState<Building[]>(defaultChecked);
  const { handleSubmit, getValues, control, setValue } = useForm<{ keyword: string }>();

  const searchFormRef = useRef<HTMLFormElement>(null);

  const buildingListTableData = buildingList.map((building) => {
    const selectedIdList = selectedBuildingList?.map((building) => building.buildingId);

    return {
      checked: selectedIdList.includes(building.id),
      ...building,
    };
  });

  const { executeAsync: getBuildingList } = useApiOperation(getBuildingsAsync);
  const search = ({ keyword, page }: { page: number; keyword?: string }) => {
    fetchBuildingList({ page, keyword });
  };

  const onSubmit = ({ keyword }: { keyword: string }) => search({ page: 0, keyword });
  const goPage = (nextPage: number) => search({ page: nextPage, keyword: getValues("keyword") });

  const fetchBuildingList = async ({ keyword, page }: { page: number; keyword?: string }) => {
    const result = await getBuildingList({
      page,
      keyword,
      size: 20,
      sort: {
        orders: [
          {
            property: "id",
            direction: "DESC",
          },
        ],
      },
    });
    setBuildingList(result.data.data.content);

    setPage({
      current: result.data.meta.pageMeta?.pageRequest.page || 0,
      total: result.data.meta.pageMeta?.totalPages || 0,
      totalElements: result.data.meta.pageMeta?.totalElements || 0,
    });
  };
  const select = (id: string) => {
    const selectedBuilding = buildingList.find((building) => String(building.id) === id);

    setSelectedBuildingList([
      // ...selectedBuildingList,
      // 멀티셀렉일때 주석해제
      {
        buildingId: selectedBuilding?.id || 0,
        buildingName: selectedBuilding?.buildingName || "",
      },
    ]);
  };
  const unSelect = (buildingId: string) => {
    const filteredBuildingList = selectedBuildingList.filter(
      (building) => String(building.buildingId) !== buildingId,
    );
    setSelectedBuildingList(filteredBuildingList);
  };
  const _onAdded = () => onAdded(selectedBuildingList);

  const handleSearchClick = () => {
    searchFormRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );
  };

  const handleCheckboxClick = (checkbox: HTMLDivElement) => {
    const dataId = checkbox.dataset.dataId;
    const disabled = Boolean(String(checkbox.dataset.disabled) === "true");
    const checked = Boolean(checkbox.dataset.checked === "true");
    if (disabled !== undefined && !disabled) {
      if (!checked) {
        select(dataId!);
      } else {
        unSelect(dataId!);
      }
    }
  };

  const handleModalContentsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;
    const checkbox = target.closest(".checkbox") as HTMLDivElement;
    if (checkbox) handleCheckboxClick(checkbox);
  };

  useEffect(() => {
    fetchBuildingList({
      page: 0,
      keyword: searchValue ? searchValue : "",
    });
    if (searchValue) {
      setValue("keyword", searchValue);
    }
  }, []);

  return (
    <BaseModal isOpen={true} className="dialog-modal">
      <div>
        <div className="modal-title">
          <div className="left-area">
            <span>건물 선택</span>
          </div>
          <form className="right-area" onSubmit={handleSubmit(onSubmit)} ref={searchFormRef}>
            <Controller
              name="keyword"
              control={control}
              render={({ field }) => (
                <BaseInput
                  placeholder="검색어를 입력하세요"
                  value={field.value}
                  onChange={field.onChange}
                  onSearchClick={handleSearchClick}
                />
              )}
            ></Controller>
          </form>
        </div>
        <div className="base-modal__contents" onClick={handleModalContentsClick}>
          <ViewDataTable columns={TABLE_COLUMNS} data={buildingListTableData} />
          <BasePagination pageIndex={page.current} totalPages={page.total} goPage={goPage} />
        </div>
        <div className="base-modal__btn-wrap">
          <BaseButton
            title={"취소"}
            className="color-white no-outline text-primary3 mr10"
            onClick={onCanceled}
          />
          <BaseButton title={"선택 추가"} onClick={_onAdded} />
        </div>
      </div>
    </BaseModal>
  );
}
export default BuildingSelectModal;
