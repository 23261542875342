import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import {
  editScheduleRoundList,
  getScheduleRoundList,
  getScheduleRoundNOverdueList,
} from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";
import { BaseModal } from "src/components";
import { YmdFormat } from "src/utils";
import { columns } from "./columns/Columns";

type Props = {
  scheduleId: number;
  isOpen: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ModifyAmountModal = (props: Props) => {
  const [data, setData] = useState<any>([]);
  const [confirm, setConfirm] = useState(false);
  const [suc, setSuc] = useState(false);

  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundList);
  //회차별 할인 수정
  const { executeAsync: updateRoundList } = useApiOperation(editScheduleRoundList);

  const upadteSchedules = useCallback(() => {
    const sendData: any = [];
    data.forEach((row: any) => {
      const today = moment().format(YmdFormat.FULL);

      const billDate = moment(row.billIssueDate).format(YmdFormat.FULL);
      const billSendPost = moment(billDate).format(YmdFormat.FULL);

      //TODO 기능 살리기 위해서 임시로 0번째 저장
      //TODO 차 후 멀티라인으로 변경되면 변경에 해당되는 내역만 수정되도록 작업해야함
      row.billDetailList[0].adjustAmount = row.adjustAmount;
      if (row.billPayStatus !== "PAYMENT_SUCCESS" && row.isOverdueBill === true) {
        sendData.push({
          billId: row.billId,
          billDetailList: row.billDetailList,
        });
      } else {
        if (
          row.billPayStatus !== "PAYMENT_SUCCESS" &&
          moment(today).isBefore(moment(billSendPost))
        ) {
          sendData.push({
            billId: row.billId,
            billDetailList: row.billDetailList,
          });
        }
      }
    });
    // console.log("sendData", sendData);

    updateRoundList({ scheduleId: props.scheduleId, billList: sendData }).then((res) => {
      // console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        setSuc(true);
      }
    });
  }, [data, props.scheduleId, updateRoundList]);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns,
      data,
      setData,
      initialState: { pageSize: 1000 },
    },
    useBlockLayout,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const saveBtn = useCallback(() => {
    setConfirm(true);
  }, []);

  const confirmBtn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      upadteSchedules();
      setConfirm(false);
      if (props.onClick) return props.onClick(e);
    },
    [props, upadteSchedules],
  );

  const callScheduleList = useCallback(
    async (scheduleId: number) => {
      const response: any = await getRoundList({ scheduleId: Number(scheduleId), isAll: true });
      if (response.status > 199 && response.status < 300) {
        const sorted = _.sortBy(response.data.data.content, ["billOrder"]);
        setData(sorted);
        console.log(response.data.data.content);
      }
    },
    [getRoundList],
  );

  useEffect(() => {
    if (props.isOpen && props.scheduleId) {
      callScheduleList(props.scheduleId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div>
      <BaseModal
        className="dialog-modal"
        isOpen={props.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="적용"
        onClick={saveBtn}
        onClose={props.onClose}
      >
        <>
          <div className="modal-title">
            <div className="left-area align-items-center">
              <span>회차별 금액 수정</span>
              <p className="sub-title-info ml40">
                * 청구일자가 주말 또는 공휴일인 경우 다음 영업일로 적용됩니다. <br />* 공급가액,
                할인/할증 금액, 청구금액은 부가세 포함 금액입니다.
              </p>
            </div>
            <div className="right-area"></div>
          </div>
          <div>
            <div {...getTableProps()} className="base-table sticky overflow-x-hidden">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {page.map((row: any) => {
                  prepareRow(row);

                  return (
                    <div
                      {...row.getRowProps()}
                      className={`base-table__tr ${row.values.floor ? "bg-gray" : ""} ${
                        row.original.isOverdueBill ? "bg-blue-opacity07" : ""
                      }`}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      </BaseModal>
      <BaseModal
        isOpen={confirm}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => confirmBtn(e)}
        onClose={() => {
          setConfirm(false);
        }}
      >
        <p>변경된 금액으로 빌링스케쥴을 저장하시겠습니까?</p>
      </BaseModal>
      <BaseModal
        isOpen={suc}
        btnRightTitle="확인"
        onClick={() => {
          setSuc(false);
        }}
      >
        <p>저장되었습니다.</p>
      </BaseModal>
    </div>
  );
};

export default ModifyAmountModal;
