import { Dispatch, SetStateAction, useCallback } from "react";
import { Control, Controller, useFieldArray, UseFormWatch } from "react-hook-form";
import { MediaFile } from "src/api/public-types";
import { BaseInput, BaseTextarea } from "src/components";
import ImagesUpdate from "src/components/ImagesUpdate";
import { onlyNumber } from "src/utils";
import {
  FrontWorkOrderSheetCostType,
  FrontWorkOrderUpdateModel,
  WorkOrderMediaType,
  WorkOrderStatus,
} from "../../workOrder-types";

type Props = {
  control: Control<FrontWorkOrderUpdateModel>;
  workOrderCompletedMedias?: Array<MediaFile>;
  setWorkOrderCompletedMedias: Dispatch<SetStateAction<Array<MediaFile>>>;
  workOrderCostMedias?: Array<MediaFile>;
  setWorkOrderCostMedias: Dispatch<SetStateAction<Array<MediaFile>>>;
  watch: UseFormWatch<FrontWorkOrderUpdateModel>;
  workOrderDetail?: FrontWorkOrderUpdateModel;
};

const WorkOrderEditSection = ({
  control,
  workOrderCompletedMedias,
  setWorkOrderCompletedMedias,
  workOrderCostMedias,
  setWorkOrderCostMedias,
  watch,
  workOrderDetail,
}: Props) => {
  //
  const {
    fields: workOrderCostList,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: "workOrderSheetCostList",
  });

  const isCustomerDisabled = useCallback((reporterName: string, customerNotification: string) => {
    if (reporterName === "") return true;
    if (customerNotification !== "") return true;

    return false;
  }, []);

  return (
    <div>
      {watch("status") !== WorkOrderStatus.OPEN && watch("status") !== WorkOrderStatus.WAITING && (
        <>
          <section className="contents-container__grid mt50">
            <div className="contents-container__grid-index">
              <p>처리 내용</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <Controller
                  control={control}
                  name="completionReport"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseTextarea
                      className="mr8"
                      maxLength={1000}
                      placeholder="상세 내용을 입력하세요."
                      onChange={(value: string) => onChange(value)}
                      value={value || ""}
                      name={name}
                      errorText={error?.message}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </section>

          {/* 처리내용 이미지 등록 */}
          <section className="contents-container__grid">
            <div className="contents-container__grid-index"></div>
            <div className="contents-container__grid-contents">
              <ImagesUpdate
                fileMaxLength={3}
                mediaFiles={workOrderCompletedMedias}
                onChangeMediaFiles={setWorkOrderCompletedMedias}
                categoryName={WorkOrderMediaType.COMPLETED}
                mediaServiceType="MEDIA_WORK_ORDER_SHEET_COMPLETED_REPORT"
              />
            </div>
          </section>

          <section className="contents-container__grid mt50">
            <div className="contents-container__grid-index">
              <p>처리 비용</p>
            </div>
            <table className="inner-table" width="600">
              <thead>
                <tr>
                  <th>
                    <div>
                      <span>비용 발생 사유</span>
                    </div>
                  </th>
                  <th>
                    <span>금액 (원)</span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {workOrderCostList.map(
                  (cost: FrontWorkOrderSheetCostType & { id: string }, costIdx: number) => (
                    <tr key={cost.id} className={cost.cmdType === "D" ? "d-none" : ""}>
                      <td width="350px">
                        <div className="flex-center-center">
                          <Controller
                            control={control}
                            name={`workOrderSheetCostList.${costIdx}.description`}
                            render={({
                              field: { onChange, value, name },
                              fieldState: { error },
                            }) => {
                              return (
                                <BaseInput
                                  onChange={(text: string) => {
                                    onChange(text);
                                  }}
                                  value={value || ""}
                                  name={name}
                                  placeholder="지출 내용을 입력하세요."
                                  errorText={error && error.message}
                                />
                              );
                            }}
                          ></Controller>
                        </div>
                      </td>
                      <td>
                        <div className="flex-center-center">
                          <Controller
                            control={control}
                            name={`workOrderSheetCostList.${costIdx}.cost`}
                            render={({
                              field: { onChange, value, name },
                              fieldState: { error },
                            }) => {
                              return (
                                <BaseInput
                                  onChange={(text: string) => {
                                    onChange(onlyNumber(text));
                                  }}
                                  value={value || ""}
                                  name={name}
                                  placeholder="금액을 입력하세요."
                                  errorText={error && error.message}
                                  maxLength={7}
                                />
                              );
                            }}
                          ></Controller>
                        </div>
                      </td>
                      <td width="60px">
                        <div className="flex-center-center">
                          {costIdx < 1 ? (
                            <button
                              className="base-add-btn mr5"
                              onClick={(e) => {
                                e.preventDefault();

                                append({
                                  description: "",
                                  cost: 0,
                                  cmdType: "C",
                                });
                              }}
                            />
                          ) : (
                            <button
                              className="base-erase-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                if (cost.cmdType === "C") {
                                  remove(costIdx);
                                } else {
                                  update(costIdx, { ...cost, id: cost.apiId, cmdType: "D" });
                                }
                              }}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </section>

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>증빙 자료</p>
            </div>
            <div className="contents-container__grid-contents">
              <ImagesUpdate
                fileMaxLength={3}
                mediaFiles={workOrderCostMedias}
                onChangeMediaFiles={setWorkOrderCostMedias}
                categoryName={WorkOrderMediaType.COST}
                mediaServiceType="MEDIA_WORK_ORDER_SHEET_COST"
              />
            </div>
          </section>
        </>
      )}

      {(watch("status") === WorkOrderStatus.COMPLETED ||
        watch("status") === WorkOrderStatus.CLOSED) && (
        <section className="contents-container__grid mt50">
          <div className="contents-container__grid-index">
            <p>고객답변</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <Controller
                control={control}
                name={"customerNotification"}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <BaseTextarea
                    className="mr8"
                    maxLength={1000}
                    placeholder="답변을 입력하세요."
                    onChange={(value: string) => onChange(value)}
                    value={value || ""}
                    name={name}
                    errorText={error?.message}
                    disabled={isCustomerDisabled(
                      watch("reporterName") || "",
                      workOrderDetail?.customerNotification || "",
                    )}
                  />
                )}
              ></Controller>
            </div>
            <p className="contents-container__sub-title-info mr40">
              접수자를 지정하지 않은 경우 입력할 수 없습니다.
              <br /> 입력한 내용으로 접수자에게 알림톡이 발송되며, 최초 발송 후 수정할 수 없습니다.
            </p>
          </div>
        </section>
      )}
    </div>
  );
};

export default WorkOrderEditSection;
