import React from "react";

interface BaseRadioState {
  id: string;
  name: string;
  label?: string;
  className?: string;
  value?: string;
  checked?: boolean;
  onChange?: (checked: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  readOnly?: boolean;
}

export const BaseRadio = (props: BaseRadioState) => {
  return (
    <div className={`d-flex ${props.className ? props.className : ""}`}>
      <input
        id={props.id}
        type="radio"
        name={props.name}
        checked={props.checked}
        value={props.value}
        readOnly={props.readOnly}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (props.onChange) {
            props.onChange(e.target.checked, e);
          }
        }}
        disabled={props.disabled}
      ></input>
      <label htmlFor={props.id} className="d-flex">
        <div className="base-radio"></div>
        {props.label && <div className="ml8 font13 no-wrap">{props.label}</div>}
      </label>
    </div>
  );
};
