import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useApiOperation from "src/api/hooks/api-operation";
import { getProductDetailAsync } from "src/api/product/product-api";
import { ProductCategory, ProductModel } from "src/api/product/product-types";
import { BaseModal, ContentsTitle, LoadingBar } from "src/components";
import PagePath from "src/pagePath.json";
import ProductAdminMemo from "./details/adminMemo/ProductAdminMemo";
import { AccessGroupForm, BasicInfoForm, CommonSpaceForm, ProductInfoForm } from "./forms";
import FacilityForm from "./forms/facilityForm/FacilityForm";
import GuideForm from "./forms/guideForm/GuideForm";
import OldGuideForm from "./forms/guideFormOld/OldGuideForm";
import ImageForm from "./forms/imageForm/ImageForm";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "productInfo", label: "상품정보" },
  { value: "facility", label: "시설/서비스" },
  { value: "commonSpace", label: "공용공간" },
  { value: "image", label: "이미지" },
  { value: "accessGroup", label: "엑세스그룹" },
  { value: "guide", label: "이용안내" },
  // { value: "oldGuide", label: "안내 (old)" },
  { value: "memo", label: "관리자 메모" },
];

/* 
  공간상품 > 등록 or 수정
 */
const ProductForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const [product, setProduct] = useState<ProductModel>();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const slicedFilter =
    product?.productCategory === "PRODUCT_CATEGORY_NORMAL"
      ? tabs
      : [...tabs.slice(0, 2), ...tabs.slice(-1)];

  // 공간상품 상세 get Api
  const { executeAsync: getProductDetail, loading: getProductDetailLoading } =
    useApiOperation(getProductDetailAsync);

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 상세 product 조회
  useEffect(() => {
    (async () => {
      if (!productId) return;

      const result = await getProductDetail({
        productId,
      });

      if (result.status >= 200 && result.status < 300) {
        setProduct(result.data.data.content);
      }
    })();
  }, []);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      if (!queryParams.id && tab.value !== "basicInfo") {
        setModalOpen(true);
        return;
      }

      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가

      // 기존 old newQueryParamStr
      // const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });

      // 각 탭 클릭시 선택된 tab 명 까지만 url 표시
      const newQueryParamStr = `id=${newQueryParams.id}&tab=${newQueryParams.tab}`;
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams, product],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "product") {
        // 공간상품 목록으로 이동
        navigate(PagePath.product.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "product", label: "공간상품" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  const productId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  const changeProductCategory = (category: ProductCategory) => {
    setProduct({ ...product!, productCategory: category });
  };

  if (getProductDetailLoading || (productId && !product)) return <LoadingBar visible={true} />;

  return (
    <div className="page-product-access">
      {modalOpen && (
        <BaseModal isOpen={modalOpen} btnRightTitle="확인" onClick={() => setModalOpen(false)}>
          <span>기본정보가 저장되지 않았습니다</span>
        </BaseModal>
      )}
      <ContentsTitle
        title="공간상품"
        tabs={slicedFilter}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />
      {/* 탭 */}
      <div className="contents-container__scroll">
        {/* 기본정보 */}
        {activeTab?.value === "basicInfo" && (
          <BasicInfoForm product={product!} changeProductCategory={changeProductCategory} />
        )}
        {/* 상품정보 */}
        {activeTab?.value === "productInfo" && <ProductInfoForm product={product!} />}
        {/* 시설/서비스 */}
        {activeTab?.value === "facility" && <FacilityForm product={product!} />}
        {/* 공용공간 */}
        {activeTab?.value === "commonSpace" && <CommonSpaceForm product={product!} />}

        {/* 이미지 */}
        {activeTab?.value === "image" && <ImageForm />}
        {/* 엑세스그룹 */}
        {activeTab?.value === "accessGroup" && <AccessGroupForm />}
        {/* 이용안내 */}
        {activeTab?.value === "guide" && <GuideForm product={product!} />}

        {/* 안내 (구버전) */}
        {/* {activeTab?.value === "oldGuide" && <OldGuideForm product={product!} />} */}
        {/* 관리자메모 */}
        {activeTab?.value === "memo" && <ProductAdminMemo />}
      </div>
    </div>
  );
};
export default ProductForm;
