import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { getContractNumbers } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { BaseInput, BaseModal, BasePagination, BaseRadio } from "src/components";

const columnHeader: any = [
  {
    Header: "",
    accessor: "isSelected",
    // sticky: "left",
    width: 130,
    Cell: ({ rows, row, setSelectedMember }: any) => {
      const changeTrigger = useCallback(() => {
        console.log("row.original.id", row.original);
        setSelectedMember(row.original);
      }, [row.original, setSelectedMember]);

      return (
        <div>
          <BaseRadio
            id={`selector_${row.original.index}`}
            name={"isSelected"}
            onChange={changeTrigger}
          />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "contractMemberId",
    // sticky: "left",
    width: 130,
  },
  {
    Header: "회원번호",
    accessor: "memberNo",
    width: 200,
  },
  {
    Header: "휴대폰번호",
    accessor: "inviteMobileNumber",
    width: 200,
  },
  {
    Header: "이메일",
    accessor: "email",
    width: 200,
  },
];
type Props = {
  contractManageId?: number;
  isOpen: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const SearchContractMemberPopup = (props: Props) => {
  const [data, setData] = useState<any>([]);
  const [selectedMember, setSelectedMember] = useState<any>();
  const [pageMeta, setPageMeta] = useState<any>();
  const [searchValue, setSearchValue] = useState("");
  const [params, setParams] = useState<any>({
    page: 0,
    size: 20,
    contractManageId: props.contractManageId,
    searchValue: "",
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columnHeader,
      data: data,
      setSelectedMember,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );

  //조회
  const { executeAsync: executeAsyncAll } = useApiOperation(getContractNumbers);

  const onClickUser = useCallback(() => {
    if (props.onClick) {
      props.onClick(selectedMember);
    }
  }, [props, selectedMember]);

  const callContractUsers = useCallback(
    async (params: any) => {
      console.log("params", params);
      const response: any = await executeAsyncAll(params);

      if (response.status > 199 && response.status < 300) {
        if (response?.data?.data && response?.data?.data.length > 0) {
          for (let i = 0; i < response?.data?.data?.length; i++) {
            response.data.data[i] = Object.assign(response.data.data[i], {
              isSelected: false,
              index: i,
            });
          }
        }
        setData(response?.data?.data);
        setPageMeta(response?.data?.meta?.pageMeta);
      }
    },
    [executeAsyncAll],
  );

  useEffect(() => {
    if (props.isOpen) {
      let prs = _.cloneDeep(params);
      setSearchValue("");
      prs.contractManageId = props.contractManageId;
      prs.searchValue = "";
      prs.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
      callContractUsers(prs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <div>
      <BaseModal
        className="dialog-modal"
        isOpen={props.isOpen}
        btnRightTitle="선택"
        btnLeftTitle="취소"
        onClick={() => onClickUser()}
        onClose={props.onClose}
      >
        <>
          <div className="modal-title">
            <div className="left-area">
              <span>계약자 선택</span>
            </div>
            <div className="right-area">
              <div className="minmax260">
                <BaseInput
                  type="text"
                  value={searchValue || ""}
                  placeholder="회원번호 또는 휴대폰 번호 검색"
                  onChange={(value: string) => {
                    setSearchValue(value);

                    setParams({ ...params, ...{ searchValue: value } });
                  }}
                  onKeyUp={() => {
                    setParams({ ...params, ...{ searchValue }, ...{ page: 0 } });
                    callContractUsers({ ...params, ...{ searchValue }, ...{ page: 0 } });
                  }}
                  onSearchClick={() => {
                    setParams({ ...params, ...{ searchValue }, ...{ page: 0 } });
                    callContractUsers({ ...params, ...{ searchValue }, ...{ page: 0 } });
                  }}
                />
              </div>
            </div>
          </div>
          {/* table */}
          <div className="contents-container__table">
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <div
                      {...row.getRowProps()}
                      className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <BasePagination
              pageIndex={Number(params?.page) || 0}
              totalPages={Number(pageMeta?.totalPages) || 0}
              goPage={(page: number) => {
                const temp = _.cloneDeep(params);
                temp.page = Number(page);
                setParams(temp);
                callContractUsers(temp);
              }}
            />
          </div>
        </>
      </BaseModal>
    </div>
  );
};

export default SearchContractMemberPopup;
