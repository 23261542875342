import axios, { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "src/api";
import { ApiResponse } from "../public-types";
import {
  MbOrg,
  MbOrgDetail,
  OrgAdminMemoListParams,
  OrgAdminMemoListResponse,
  RequestInfo,
  SendBody,
} from "./org-types";

const getOrgList = (
  axios: AxiosInstance,
  params?: RequestInfo,
): Promise<AxiosResponse<ApiResponse<{ content: MbOrg[] }>>> => {
  const path = ApiPath.organization.list;
  return axios.get<ApiResponse<{ content: MbOrg[] }>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

const getOrgDetail = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<MbOrgDetail>>> => {
  const path = ApiPath.organization.detail.replace("{id}", params!.id!.toString());
  return axios.get<ApiResponse<MbOrgDetail>>(path, { params });
};

interface FileData {
  fileStorageType: string;
  key: string;
  filename: string;
  contentType: string;
  filesize: number;
}

// public 업록드
async function uploadOrgPublicFilesAsync(
  axios: AxiosInstance,
  param: any,
): Promise<AxiosResponse<ApiResponse<FileData>>> {
  const path = "/api/central/fs/public/files";
  return await axios.post(path, param);
}

// private 업로드
async function uploadOrgPrivateFilesAsync(
  axios: AxiosInstance,
  param: any,
): Promise<AxiosResponse<ApiResponse<{ media: FileData }>>> {
  const path = "/api/central/fs/private/files";
  return await axios.post(path, param);
}

const addOrg = (
  axios: AxiosInstance,
  params?: SendBody,
): Promise<AxiosResponse<ApiResponse<MbOrgDetail>>> => {
  const path = ApiPath.organization.add;
  return axios.post<ApiResponse<MbOrgDetail>>(path, params);
};

const editOrg = (
  axios: AxiosInstance,
  params?: SendBody,
): Promise<AxiosResponse<ApiResponse<MbOrgDetail>>> => {
  let path = "";
  if (params && params.organization.id) {
    path = ApiPath.organization.edit.replace("{id}", params?.organization?.id?.toString());
  }

  return axios.put<ApiResponse<MbOrgDetail>>(path, params);
};

const proofFileDownload = (
  axios: AxiosInstance,
  params?: {
    url: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  return axios.get<ApiResponse<any>>(params!.url);
};

/** 사업자 관리자메모 목록 api*/
export const getOrgAdminMemoAsync = async (
  axios: AxiosInstance,
  params?: {
    orgId: number;
    queryParams?: OrgAdminMemoListParams;
  },
): Promise<AxiosResponse<ApiResponse<OrgAdminMemoListResponse>>> => {
  return await axios.get(
    ApiPath.organization.adminMemo.list.replace("{orgId}", String(params?.orgId)),
    {
      params: params?.queryParams,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true, encode: true }),
    },
  );
};

/** 사업자 관리자메모 등록 api*/

export const postOrgAdminMemoAsync = async (
  axios: AxiosInstance,
  params?: {
    orgId: number;
    content: string;
  },
) => {
  return await axios.post(
    ApiPath.organization.adminMemo.add.replace("{orgId}", String(params?.orgId)),
    {
      content: params?.content,
    },
  );
};

/** 사업자 관리자메모 수정 api*/

export const editOrgAdminMemoAsync = async (
  axios: AxiosInstance,
  params?: {
    orgId: number;
    memoId: number;
    content: string;
  },
) => {
  return await axios.put(`/api/central/mb/organizations/${params?.orgId}/notes/${params?.memoId}`, {
    content: params?.content,
  });
};

/** 사업자 관리자메모 삭제 api*/
export const removeOrgAdminMemoAsync = async (
  axios: AxiosInstance,
  params?: {
    orgId: number;
    memoId: number;
  },
): Promise<AxiosResponse<ApiResponse<OrgAdminMemoListResponse>>> => {
  return await axios.delete(
    `/api/central/mb/organizations/${params?.orgId}/notes/${params?.memoId}`,
  );
};

export {
  getOrgList,
  getOrgDetail,
  uploadOrgPublicFilesAsync,
  uploadOrgPrivateFilesAsync,
  addOrg,
  editOrg,
  proofFileDownload,
};
