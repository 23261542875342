import { Address, MediaFile, MeetingRoom, Order, PakringLot, Select } from "../public-types";

enum SpaceProductType {
  FULL_COURT = "FULL_COURT",
  OPEN_COURT = "OPEN_COURT",
  TIME_COURT = "TIME_COURT",
}

enum ContractStep {
  // 신청접수
  APPLY_RECEIVED = "APPLY_RECEIVED",
  // 신청취소
  APPLY_CANCEL = "APPLY_CANCEL",
  // 신청확인
  APPLY_CONFIRM = "APPLY_CONFIRM",
  // 계약예정
  CONTRACT_PLAN = "CONTRACT_PLAN",
  // 계약체결
  CONTRACT_ACCEPT = "CONTRACT_ACCEPT",
  // 이용승인
  USE_APPROVAL = "USE_APPROVAL",
  // 이용중
  USE_PROGRESS = "USE_PROGRESS",
  // 이용완료
  USE_COMPLETE = "USE_COMPLETE",
  // 해지접수
  TERMINATE_RECEIVED = "TERMINATE_RECEIVED",
  // 해지완료
  TERMINATE_COMPLETE = "TERMINATE_COMPLETE",
}

const searchStatusOption: Select[] = [
  { label: "전체", value: "" },
  { label: "신청접수", value: ContractStep.APPLY_RECEIVED },
  { label: "신청취소", value: ContractStep.APPLY_CANCEL },
  { label: "신청확인", value: ContractStep.APPLY_CONFIRM },
  { label: "계약예정", value: ContractStep.CONTRACT_PLAN },
  { label: "계약체결", value: ContractStep.CONTRACT_ACCEPT },
  { label: "이용승인", value: ContractStep.USE_APPROVAL },
  { label: "이용중", value: ContractStep.USE_PROGRESS },
  { label: "이용완료", value: ContractStep.USE_COMPLETE },
  { label: "해지접수", value: ContractStep.TERMINATE_RECEIVED },
  { label: "해지완료", value: ContractStep.TERMINATE_COMPLETE },
];

enum ApplicationName {
  //신청/계약(계약) id
  CONTRACT_ID = "CONTRACT_ID",
  //공간상품 id
  SPACE_PRODUCT_ID = "SPACE_PRODUCT_ID",
  //공간상품명
  SPACE_PRODUCT_NAME = "SPACE_PRODUCT_NAME",
  //신청/계약자명
  APPLICANT_NAME = "APPLICANT_NAME",
  //신청/계약자명
  CONTRACT_MEMBER_NO = "CONTRACT_MEMBER_NO",
  //신청/계약자 법인명/상호
  APPLICANT_CORPORATION = "APPLICANT_CORPORATION",
  CONTRACT_APPLY_NUMBER = "CONTRACT_APPLY_NUMBER",
  CONTRACT_NUMBER = "CONTRACT_NUMBER",
  MB_ORGANIZATION_ID = "MB_ORGANIZATION_ID",
}

const searchTypeOption: Select[] = [
  { label: "전체", value: "" },
  { label: "신청/계약 id", value: ApplicationName.CONTRACT_ID },
  { label: "상품id", value: ApplicationName.SPACE_PRODUCT_ID },
  { label: "상품명", value: ApplicationName.SPACE_PRODUCT_NAME },
  { label: "계약자명", value: ApplicationName.APPLICANT_NAME },
  { label: "계약자 회사명", value: ApplicationName.APPLICANT_CORPORATION },
  { label: "신청 번호", value: ApplicationName.CONTRACT_APPLY_NUMBER },
];

enum MediaServiceType {
  // 정의되지 않은 타입
  MEDIASERVICE_UNRECOGNIZED = "MEDIASERVICE_UNRECOGNIZED",
  // 건물
  BUILDING = "BUILDING",
  // 건물 층
  BUILDING_FLOOR = "BUILDING_FLOOR",
  // 건물 호실
  BUILDING_ROOM = "BUILDING_ROOM",
  // 회의실
  BUILDING_MEETING_ROOM = "BUILDING_MEETING_ROOM",
  // 데스크공간
  BUILDING_DESK_SPACE = "BUILDING_DESK_SPACE",
  // 리프레쉬공간
  BUILDING_REFRESH = "BUILDING_REFRESH",
  // 상품서비스
  PRODUCT = "PRODUCT",
  // 공간상품평면도
  PRODUCT_FLOOR_PLAN = "PRODUCT_FLOOR_PLAN",
  // 공간상품 시설/공용공간
  PRODUCT_FACILITY = "PRODUCT_FACILITY",
}

//목록 검색
interface SearchParams {
  id?: number;
  contractStep?: string;
  searchType?: string;
  searchValue?: string;
  //검색일(from) YYYY-MM-DD
  searchStartTime?: string;
  //검색일(to) YYYY-MM-DD
  searchEndTime?: string;
  page?: number;
  size?: number;
  sort?: {
    orders: Array<Order>;
  };
  contractIds?: string;
}

interface ContractManageList {
  contractManageId: number;
  contractId: number;
  spaceProductName: string;
  spaceProductId: number;
  applicantName: string;
  applicantCorporation: string;
  contractStartTime: string;
  contractEndTime: string;
  contractTerm: string;
  createdDate: string;
  modifiedDate: string;
  contractStep: string;
  contractMemberNo: string;
  contractMemberId: string;
  contractApplyNumber: string;
  contractNumber: string;
}

//CCA19. 계약(이용) 중인 신청/계약 목록 조회

interface ContractUseRequestModel {
  contractManageId: number;
  contractApplyNumber: string;
  contractId: number;
  contractMemberId: number;
  memberNo: string;
  spaceProductId: number;
  spaceProductType: string;
  spaceProductName: string;
  contractStep: string;
  useTerm: number;
  useStartTime: string;
  useEndTime: string;
  contractTerm: number;
  contractStartTime: string;
  contractEndTime: string;
  inviteDate: string;
  isContractor: boolean;
  isAdmin: boolean;
  memberTotCount: number;
  memberMaxNums: number;
}

interface ContractUseRequestList {
  content: Array<ContractUseRequestModel>;
}

interface ContractDetailBasicInfo {
  contractId: number;
  contractManageId: number;
  contractApplyNumber?: string;
  contractStep: string;
  createdDate: string;
  modifiedDate: string;
  modifiedBy?: string;
}

//계약서
interface CtMediaFile {
  id: number;
  serviceId: string;
  serviceType: string;
  serviceTag: string;
  mediaType: string;
  originMediaName: string;
  description: string;
  mediaPath: string;
  isPrimary: boolean;
  orderNums: number;
  isDeleted: boolean;
}

//계약상품
interface CotractProduct {
  buildingUid: number;
  buildingName: string;
  description: string;
  floorNums: number;
  undergroundFloorNums: number;
  feature: string;
  completionYmd: string;
  entryStartTime: string;
  entryEndTime: string;
  passengerElev: number;
  freightElev: number;
  fileList: Array<MediaFile>;
  meetingRoomList: Array<MeetingRoom>;
  parkingLotList: Array<PakringLot>;
  address: Address;
}

enum MemberStatus {
  //초대
  MEMBER_INVITE = "MEMBER_INVITE",
  //사용
  MEMBER_USE = "MEMBER_USE",
  //삭제
  MEMBER_DELETE = "MEMBER_DELETE",
  //종료
  MEMBER_CLOSE = "MEMBER_CLOSE",
}

//이용자
interface CtMember {
  contractMemberId: number;
  contractManageId: number;
  inviteMobileNumber: number;
  memberNo: number;
  isContractor: boolean;
  isAdmin: boolean;
  inviteDate: string;
  memberStatus: string;
  useTime: string;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}

enum VisitStatus {
  // 사용
  VISIT_USE = "VISIT_USE",
  // 일시정지
  VISIT_PAUSE = "VISIT_PAUSE",
  // 삭제
  VISIT_DELETE = "VISIT_DELETE",
  // 종료
  VISIT_CLOSE = "VISIT_CLOSE",
}
// 방문자 관리
interface CtVisitor {
  visitorId: number;
  contractManageId: number;
  visitorName: string;
  visitorMobileNumber: string;
  visitorCarNumber: string;
  visitStartTime: string;
  visitEndTime: string;
  visitStatus: string;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}

//계약관리
interface CtContractManage {
  contractManageId: number;
  contractId: number;
  contractApplyType: string;
  useStartTime: string;
  useEndTime: string;
  applicantName: string;
  applicantCorporation: string;
  isAgreeTerms: boolean;
  businessRegNumber: string;
  spaceProductId: number;
  spaceProductName: string;
  accessGroupId: number;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}

//계약 메모
interface CtContractMemo {
  contractMemoId: number;
  contractManageId: number;
  contractMemo: string;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}

// 계약
interface CtContract {
  contractId: number;
  contractManageId: number;
  contractStep: string;
  contractDate: string;
  contractTerm: number;
  contractStartTime: string;
  contractEndTime: string;
  depositAmount: string;
  earnestAmount: string;
  earnestPayStatus: string;
  balanceAmount: string;
  balancePayStatus: string;
  isContractCancel: boolean;
  contractCancelDate: string;
  contractCancelStartOrder: number;
  contractCancelReason: string;
  contractBillTimeType: string;
  contractPayType: string;
  pgcode: string;
  payAmount: string;
  payRegularDate: number;
  billkey: string;
  taxBillReceiveEmail: string;
  taxBillChargeAmount: string;
  taxBillManageAmount: string;
  taxBillRegularDate: number;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}

enum PayStatus {
  //결제 대기
  PAY_WAIT = "PAY_WAIT",
  //결제 완료
  PAY_COMPLETE = "PAY_COMPLETE",
}

enum BillTimeType {
  //즉시결제
  IMMEDIATE_PAYMENT = "IMMEDIATE_PAYMENT",
  //정기결제
  REGULAR_PAYMENT = "REGULAR_PAYMENT",
}

enum PayType {
  // 일반결제형
  PAY = "PAY",
  // 세금계산서 발행
  TAX = "TAX",
}

enum PgCodeType {
  // 신용카드
  CREADIT_CARD = "creditcard",
  //가상계좌
  VIRTUAL_ACCOUNT = "virtualaccount",
}

interface CtBill {
  billId: number;
  contractId: number;
  billNumber: string;
  billTimeType: string;
  billPayType: string;
  billCustomerName: string;
  billOrder: number;
  billIssueDate: string;
  billStartTime: string;
  billEndTime: string;
  beforeDiscountAmount: number;
  discountAmount: number;
  billAmount: number;
  billPayStatus: string;
  isDeleted: boolean;
}

interface CtPayment {
  payId: number;
  billId: number;
  pgcode: string;
  clientId: string;
  serviceName: string;
  userId: string;
  userName: string;
  orderNo: string;
  amount: string;
  taxfreeAmount: string;
  taxAmount: string;
  productName: string;
  emailFlag: boolean;
  emailAddr: string;
  autopayFlag: boolean;
  receiptFlag: boolean;
  keyinFlag: boolean;
  customParameter: string;
  returnUrl: string;
  callbackUrl: string;
  cancelUrl: string;
  inappFlag: string;
  appReturnUrl: string;
  appCancelUrl: string;
  token: string;
  onlineUrl: string;
  mobileUrl: string;
  billkey: string;
  ipAddr: string;
  tid: string;
  cid: string;
  payInfo: string;
  domesticFlag: boolean;
  transactionDate: string;
  installMonth: number;
  cardInfo: string;
  payhash: string;
  code: string;
  message: string;
}

interface CtVirtualAcount {
  payId: number;
  accountNo: string;
  accountName: string;
  accountHolder: string;
  bankCode: string;
  bankName: string;
  issueTid: string;
  expireDate: string;
  expireTime: string;
}

interface CtCashReceipt {
  payId: number;
  cashReceiptCid: string;
  cashReceiptDealNo: string;
  cashReceiptIssueType: boolean;
  cashReceiptPayerSid: string;
  cashReceiptType: string;
}

interface CtPaymentCancel {
  cancelId: number;
  payId: number;
  userId: string;
  ipAddr: string;
  tid: string;
  cid: string;
  cancelAmount: string;
  partialAmount: string;
  taxfreeAmount: string;
  cancelDate: string;
  code: string;
  message: string;
}

export interface GetContractDashboardListParams {
  partnerId?: number;
  searchStartTime?: string;
  searchEndTime?: string;
}

export interface ContractCount {
  contractStep: ContractStep;
  contractCount: number;
}

export type {
  CtMember,
  SearchParams,
  CtMediaFile,
  CotractProduct,
  CtVisitor,
  CtContractManage,
  CtContractMemo,
  CtContract,
  CtBill,
  CtPayment,
  CtVirtualAcount,
  CtCashReceipt,
  CtPaymentCancel,
  ContractManageList,
  ContractDetailBasicInfo,
  ContractUseRequestList,
};
export {
  SpaceProductType,
  ContractStep,
  ApplicationName,
  MediaServiceType,
  MemberStatus,
  VisitStatus,
  PayStatus,
  BillTimeType,
  PayType,
  PgCodeType,
  searchStatusOption,
  searchTypeOption,
};
