import moment from "moment";
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { NoticeModel, NoticeType } from "src/api/notice/notice-types";
import { BaseTooltip } from "src/components";
import { PagePath } from "src/pages/product/details";
import { YmdFormat } from "src/utils";

const NoticeColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: (props: Cell<NoticeModel>) => {
      const detailPath = `${PagePath.notice.detail.replace(":id", `${props.row.original.id}`)}`;
      return (
        <Link to={detailPath} className="text-hilight">
          {props?.value || ""}
        </Link>
      );
    },
  },

  {
    Header: "제목",
    accessor: "title",
    width: 300,
    Cell: (props: Cell<NoticeModel>) => {
      const detailPath = `${PagePath.notice.detail.replace(":id", `${props.row.original.id}`)}`;
      return (
        <div className="w-100 text-left">
          <Link to={detailPath} className="text-hilight ellipsis2">
            {props?.value || ""}
          </Link>
        </div>
      );
    },
  },
  {
    Header: "신청/계약",
    accessor: "contractProductNames",
    width: 270,
    Cell: (props: Cell<NoticeModel>) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip
            contents={props.value && props.value.length > 0 ? props.value.join(", ") : "-"}
          />
        </div>
      );
    },
  },
  {
    Header: "건물",
    accessor: "buildingNames",
    width: 270,
    Cell: (props: Cell<NoticeModel>) => {
      return (
        <>
          {
            <div className="w-100 text-left">
              {props.value && props.value.length > 0 ? props.value.join(", ") : "-"}
            </div>
          }
        </>
      );
    },
  },
  {
    Header: "공개 여부",
    accessor: "isDisplayed",
    width: 90,
    Cell: (props: Cell<NoticeModel>) => {
      return (
        <>
          <div className={`${(props.value && "chip green") || (!props?.value && "chip gray")}`}>
            {props.value ? <span>공개</span> : <span>비공개</span>}
          </div>
        </>
      );
    },
  },
  {
    Header: "공개 기간",
    accessor: "displayStartDate",
    width: 240,
    Cell: (props: Cell<NoticeModel>) => {
      if (!props.row.original.displayStartDate || !props.row.original.displayEndDate) return "-";
      const start = moment(props.row.original.displayStartDate).format(YmdFormat.YYYY_MM_DD_HH_MM);
      const end = moment(props.row.original.displayEndDate).format(YmdFormat.YYYY_MM_DD_HH_MM);
      return <p>{`${start} ~ ${end}`}</p>;
    },
  },
  {
    Header: "등록일",
    accessor: "createdDate",
    width: 100,
    Cell: (props: Cell<NoticeModel>) => {
      return (
        <>
          <BaseTooltip contents={props.value} type={"date"} />
        </>
      );
    },
  },
  {
    Header: "공지 유형",
    accessor: "type",
    width: 120,
    Cell: (props: Cell<NoticeModel>) => {
      return <>{props.value === NoticeType.SERVICE ? "서비스 이용" : "공간 이용"}</>;
    },
  },
  {
    Header: "파트너 id",
    accessor: "partnerId",
    width: 100,
    Cell: (props: Cell<NoticeModel>) => {
      return Number(props.value) === 0 ? (
        <span>{props.value}</span>
      ) : (
        <Link
          target={"_blank"}
          to={PagePath.partner.detail.replace(":id", props.value)}
          className="text-hilight"
        >
          {props.value}
        </Link>
      );
    },
  },
];

export default NoticeColumns;
