import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  useMountedLayoutEffect,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { BaseModal, BaseTooltip } from "src/components";
import { calculatePyeong, calculateRateOfUse, numberToStringWithComma } from "src/utils";
import { ProductFormData } from "../../forms/basicInfoForm/BasicInfoForm";
import { BuildingFloorAndHo, Modal } from "../../product-types";

type Props = {
  viewTable: Array<BuildingFloorAndHo>;
  setViewTable?: Function;
  errorText?: string;
  readOnly?: boolean;
  selectedRow?: any; // 건물/호실 테이블에서 체크된 데이터
  onSelectedRowsChange?: Function;
  detailColumn?: any;
  setBuildingAndHo?: Function;
  isPrimaryBuildingChecked?: Function;
  getLocationCode?: Function;
  setValue?: UseFormSetValue<ProductFormData>;
  getValues?: UseFormGetValues<ProductFormData>;
};

// 공간상품 기본정보 form (건물/호실) 부분 테이블
const BuildingAndHoTable = ({
  viewTable,
  setViewTable,
  errorText,
  readOnly,
  selectedRow,
  onSelectedRowsChange,
  detailColumn,
  setBuildingAndHo,
  isPrimaryBuildingChecked,
  getLocationCode,
  setValue,
  getValues,
}: Props) => {
  const [deleteModal, setDeleteModal] = useState<Modal>({ isOpen: false });

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "대표",
        accessor: "isPrimary",
        Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
          return (
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                id={row.id}
                onClick={() => {
                  //모든 라디오 초기화
                  toggleAllRowsSelected(false);
                  toggleRowSelected(row.id, true);
                  getLocationCode && getLocationCode(row.original.buildingId);
                }}
              />
            </div>
          );
        },
        width: 80,
      },
      {
        Header: "건물 ID",
        accessor: "buildingId",

        width: 80,
      },
      {
        Header: "건물 명",
        accessor: "buildingName",

        width: 180,
      },
      {
        Header: "호실",
        accessor: "roomNum",
        width: 80,
        Cell: ({ value }) => {
          if (value < 0) {
            return <span>{String(value).replace("-", "")}</span>;
          } else {
            return <span>{value}</span>;
          }
        },
      },
      {
        Header: "층",
        accessor: "floorNum",
        width: 80,
        Cell: ({ value }) => {
          if (value < 0) {
            return <span>지하 {String(value).replace("-", "")}</span>;
          } else {
            return <span>지상 {value}</span>;
          }
        },
      },
      {
        Header: "전용면적(m2)",
        accessor: "leasableAreaNet",
        width: 120,
        Cell: ({ value }: any) => {
          return (
            <>
              <BaseTooltip contents={`${numberToStringWithComma(value)}`} isSingleLine={true} />
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((item: any) => (sum += Number(item.original.leasableAreaNet)));
          return (
            <BaseTooltip
              contents={`${numberToStringWithComma(sum)}`}
              isSingleLine={true}
              className="font-weight-bold"
            />
          );
        },
      },
      {
        Header: "전용면적(평)",
        accessor: "",
        width: 120,
        Cell: ({ row }: any) => {
          const value = row.original.leasableAreaNet;
          return (
            <>
              <BaseTooltip contents={`${calculatePyeong(value)}`} isSingleLine={true} />
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((item: any) => (sum += Number(item.original.leasableAreaNet)));
          return (
            <BaseTooltip
              contents={`${calculatePyeong(sum)}`}
              isSingleLine={true}
              className="font-weight-bold"
            />
          );
        },
      },
      {
        Header: "임대면적(m2)",
        accessor: "leasableArea",
        width: 120,
        Cell: ({ value }: any) => {
          return (
            // TODO :: 세번째 숫자 , 찍기
            <>
              <BaseTooltip contents={`${numberToStringWithComma(value)}`} isSingleLine={true} />
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;

          rows.map((item: any) => (sum += Number(item.original.leasableArea)));
          return (
            // 임대면적 SUM leasableArea

            <BaseTooltip
              contents={`${numberToStringWithComma(sum)}`}
              isSingleLine={true}
              className="font-weight-bold"
            />
          );
        },
      },
      {
        Header: "임대면적(평)",
        accessor: "",
        width: 120,
        Cell: ({ row }: any) => {
          const value = row.original.leasableArea;
          return (
            // TODO :: 세번째 숫자 , 찍기
            <>
              <BaseTooltip contents={`${calculatePyeong(value)}`} isSingleLine={true} />
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;

          rows.map((item: any) => (sum += Number(item.original.leasableArea)));
          return (
            // 임대면적 SUM leasableArea

            <BaseTooltip
              contents={`${calculatePyeong(sum)}`}
              isSingleLine={true}
              className="font-weight-bold"
            />
          );
        },
      },
      {
        Header: "전용률(%)",
        accessor: "rateOfUse",
        width: 100,
        Cell: ({ row }: any) => {
          // let rateOfUse = 0;

          const rateOfUse = calculateRateOfUse(
            row?.original?.leasableAreaNet,
            row?.original?.leasableArea,
          );

          return (
            // TODO :: 세번째 숫자 , 찍기
            <>
              <BaseTooltip contents={`${rateOfUse}`} isSingleLine={true} />
            </>
          );
        },
        Footer: ({ rows }: any) => {
          //let sum = 0
          let sumLeasableAreaNet = 0;
          let sumLeasableArea = 0;

          rows.map((row: any) => {
            return (sumLeasableAreaNet += Number(row.original.leasableAreaNet));
          });

          rows.map((row: any) => {
            return (sumLeasableArea += Number(row.original.leasableArea));
          });

          const rateOfUse = calculateRateOfUse(sumLeasableAreaNet, sumLeasableArea);

          return (
            // TODO :: 세번째 숫자 , 찍기
            <BaseTooltip
              contents={`${rateOfUse}`}
              isSingleLine={true}
              className="font-weight-bold"
            />
            // <div className="flex-center">
            //   <p>
            //     <b>
            //       <span>{rateOfUse}</span>%
            //     </b>
            //   </p>
            // </div>
          );
        },
      },

      {
        Header: "삭제",
        accessor: "product",
        Cell: ({ row }: any) => {
          const room: BuildingFloorAndHo = row.original;

          return (
            <div className="minmax50 flex-center">
              <button
                className="base-trash-btn"
                disabled={readOnly ? readOnly : false}
                onClick={(e) => {
                  e.preventDefault();
                  setDeleteModal({
                    isOpen: true,
                    message: `[${room.buildingName}] / [${room.roomNum}호] 를 삭제하시겠습니까?`,
                    payload: room,
                  });
                }}
              ></button>
            </div>
          );
        },
        width: 60,
      },
    ],
    [readOnly],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns: detailColumn ? detailColumn : columns,
      data: viewTable,
      initialState: { selectedRowIds: selectedRow },
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const handleLocatinoCode = useCallback(
    (id: any) => {
      getLocationCode && getLocationCode(Number(id));
    },
    [getLocationCode],
  );

  //
  // 등록됐던 대표여부 Checked
  useMountedLayoutEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange(selectedRowIds);
  }, [onSelectedRowsChange, selectedRowIds]);

  // table default 대표여부 / 대표 클릭시 isprimary = true

  const isPrimaryBuildingCheck = useCallback(
    (buildings) => {
      if (buildings.length > 0) {
        for (let i = 0; i < buildings.length; i++) {
          const buildingItem: BuildingFloorAndHo = buildings[i];

          for (let j = 0; j < selectedFlatRows.length; j++) {
            const row = selectedFlatRows[j];
            if (buildingItem.roomId === row.original.roomId) {
              buildingItem.isPrimary = true;
            } else {
              buildingItem.isPrimary = false;
            }
          }
        }

        setValue && setValue("buildingList", buildings);
      }
    },
    [selectedFlatRows, setValue],
  );

  useEffect(() => {
    if (getValues) {
      const buildingList = getValues("buildingList");
      isPrimaryBuildingCheck(buildingList);
    }
  }, [getValues, isPrimaryBuildingCheck]);

  //============== viewTable 리스트 삭제 ==============
  // 건물/호실 삭제
  const onDeleteProperty = useCallback(
    (room: BuildingFloorAndHo) => {
      const viewConcat = [...viewTable];

      const viewTableDelete = viewConcat.filter((item) => {
        return item.roomId !== room.roomId; //item.id = 호실 id
      });

      setViewTable && setViewTable(viewTableDelete);
      setBuildingAndHo && setBuildingAndHo(viewTableDelete);

      if (room.isPrimary) {
        isPrimaryBuildingChecked && isPrimaryBuildingChecked(viewTableDelete);
        handleLocatinoCode(viewTableDelete[0]?.buildingId);
      }

      setDeleteModal({ isOpen: false });
    },
    [handleLocatinoCode, setBuildingAndHo, setViewTable, viewTable, isPrimaryBuildingChecked],
  );

  const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = useRef<HTMLInputElement>();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <div className="d-flex president">
          <input
            type="radio"
            id={rest.id}
            ref={resolvedRef}
            name="buildingAndHo"
            {...rest}
            disabled={readOnly ? true : false}
          />
          <label htmlFor={rest.id} className="d-flex">
            <div className="base-radio"></div>
          </label>
        </div>
      </>
    );
  });

  return (
    <section className="inner-tab-table">
      {errorText && <p className="validation-text">{errorText}</p>}
      <div className="my20">
        <div {...getTableProps()} className="base-table sticky">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div>
            {viewTable &&
              viewTable.length > 0 &&
              footerGroups.map((group) => {
                return (
                  <div {...group.getFooterGroupProps()}>
                    {group.headers.map((column) => (
                      <div
                        {...column.getFooterProps()}
                        className="base-table__td base-table-footer"
                      >
                        {column.render("Footer")}
                      </div>
                    ))}
                  </div>
                );
              })}
          </div>
        </div>
        <BaseModal
          isOpen={deleteModal.isOpen}
          onClick={() => {
            onDeleteProperty(deleteModal.payload);
          }}
          onClose={() => setDeleteModal({ isOpen: false })}
          btnLeftTitle="취소"
          btnRightTitle="확인"
        >
          {deleteModal.message}
        </BaseModal>
      </div>
    </section>
  );
};

export default BuildingAndHoTable;
