import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Cell } from "react-table";
import { useApiOperation } from "src/api/hooks";
import { putPartnerMember, putPartnerMemberAsync } from "src/api/partner-member/api";
import { ContentsTitle, BaseButton, LoadingBar, BaseModal, BaseInput } from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { useTitleOperation } from "src/pages/hooks";
import { Partner } from "src/types/partner";
import { IPartnerMember } from "src/types/partner-members";
import {
  changeListSearchPhoneNumber,
  onlyNumberAndSign,
  validateMobileNumber,
  YmdFormat,
} from "src/utils";
import usePartnerMember from "../hooks/usePartnerMember";
import {
  getPartnerMemberDetailPagePath,
  getPartnerMemberEditPath,
  getPartnerMemberListPagePath,
} from "../utils";
import { PaPartnerMemberPrivileges } from "src/api/partner-member/types";
import { PagePath } from "src/pages/product/details";
import BaseGaryTooltip from "src/components/BaseGaryTooltip";

const PARTNER_COLUMNS = [
  {
    Header: "id",
    accessor: "id",
    width: 70,
    Cell: ({ value, row }: Cell<Partner>) => (
      <div>
        <Link
          target={"_blank"}
          to={`${PagePath.partner.detail.replace(
            ":id",
            value,
          )}?tab=authorities&page=0&size=20&containsEmail=${row.original.email}`}
          className="text-hilight"
        >
          {value}
        </Link>
      </div>
    ),
  },
  {
    Header: "파트너 코드",
    accessor: "code",
    width: 300,
    Cell: ({ value }: Cell<Partner>) => <p>{value}</p>,
  },
  {
    Header: "파트너 이름",
    accessor: "name",
    width: 300,
    Cell: ({ value }: Cell<Partner>) => <p>{value}</p>,
  },
  {
    Header: "권한",
    accessor: "privileges",
    width: 100,
    Cell: ({ value }: Cell<Partner>) => {
      const renderPrivileges = (privileges: { code: string; permissions: string }[]) => {
        return (
          <>
            {privileges ? (
              privileges?.map((item) => (
                <p>
                  {item.code} : {item.permissions ? String(item.permissions).toUpperCase() : ""}
                </p>
              ))
            ) : (
              <>권한이 없습니다</>
            )}
          </>
        );
      };
      return (
        <div>
          <BaseGaryTooltip touchIcon="INFO" children={<>{renderPrivileges(value)}</>} />
        </div>
      );
    },
  },
  {
    Header: "",
    accessor: "DELETE",
    width: 50,
    Cell: ({ row }: Cell<Partner>) => (
      <div className="base-trash-btn" data-delete-id={row.cells[0].value} />
    ),
  },
];

const EDIT_MAP = {
  id: {
    label: "id",
    render: (value: IPartnerMember) => <p>{value.id}</p>,
    width: 400,
  },
  email: {
    label: "이메일",
    render: (value: IPartnerMember) => <p>{value.email}</p>,
    width: 400,
  },
  phoneNumber: {
    label: "휴대폰",
    render: (value: IPartnerMember, _: any, onChange: Function, phone: string) => (
      <BaseInput
        name={"phone"}
        type="text"
        value={phone}
        onChange={(e: any) => onChange(onlyNumberAndSign(e))}
        errorText={
          validateMobileNumber(phone, " ") !== true
            ? validateMobileNumber(phone, " ").toString()
            : " "
        }
      />
    ),
    width: 200,
  },
  connections: {
    label: "연결된 계정",
    render: (value: IPartnerMember) => <p>{value.connections?.join(", ")}</p>,
    width: 400,
  },
  partners: {
    label: "소속 파트너",
    render: (value: IPartnerMember, privileges: PaPartnerMemberPrivileges[]) => {
      const _partners = value.partners?.map((partner) => {
        const findPrivileges = privileges?.find((item) => Number(item.partnerId === partner.id));
        return {
          id: partner.id,
          code: partner.code,
          name: partner.name,
          email: value.email,
          privileges: findPrivileges?.privileges,
        };
      });
      return (
        <div>
          <BaseButton title={"파트너 추가"} className="mb8" />
          <ViewDataTable columns={PARTNER_COLUMNS} data={_partners || []} />
        </div>
      );
    },
    width: 1000,
  },
  createdDate: {
    label: "생성일",
    render: (value: IPartnerMember) => <p>{moment(value.createdDate).format(YmdFormat.FULL)}</p>,
    width: 300,
  },
  lastLoginDate: {
    label: "최근 로그인",
    render: (value: IPartnerMember) => <p>{moment(value.lastLoginDate).format(YmdFormat.FULL)}</p>,
    width: 300,
  },
};

function PartnerMemeberEdit() {
  const params = useParams();
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const {
    partnerMember,
    setPartnerMember,
    privileges,
    loading: getPartnerMemberLoading,
  } = usePartnerMember(params.id!);

  useEffect(() => {
    if (partnerMember) {
      setPhone(partnerMember?.phoneNumber || "");
    }
  }, [partnerMember]);

  const { executeAsync: putPartnerMember, loading: putPartnerMemberLoading } =
    useApiOperation(putPartnerMemberAsync);

  const editPartnerMemberPartners = async (partnerMember: IPartnerMember) => {
    if (!partnerMember || !partnerMember.partners) return;
    const result = await putPartnerMember({
      id: partnerMember?.id!,
      partnerIds: partnerMember.partners
        .filter((partner) => partner.id)
        .map((partner) => partner.id!),
      phoneNumber: phone ? changeListSearchPhoneNumber(phone) : null,
    });

    if (result.status >= 200 && result.status < 300) {
      setPartnerMember(result.data.data.member);
      setPhone(result.data.data.member?.phoneNumber || "");
      setModal({
        ...modal,
        successModal: { visible: true },
      });
    } else {
      setModal({
        ...modal,
        failModal: { visible: true, message: result.data.meta.errorMessage! },
      });
    }
  };

  const [modal, setModal] = useState({
    partnerSelectModal: {
      visible: false,
    },
    successModal: {
      visible: false,
    },
    failModal: {
      visible: false,
      message: "",
    },
  });

  const handleListButtonClick = () => navigate(getPartnerMemberListPagePath());

  const handleSaveButtonClick = () => editPartnerMemberPartners(partnerMember!);

  const handleContentsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement;

    if (target.className === "base-trash-btn")
      handleDeletePartnerButtonClick(target.dataset.deleteId!);

    if (target.textContent === "파트너 추가") handleAddPartnerButtonClick();
  };

  const handleAddPartnerButtonClick = () => {
    setModal({ ...modal, partnerSelectModal: { visible: true } });
  };

  const handleDeletePartnerButtonClick = (id: string) => {
    if (!partnerMember) return;

    const partners = partnerMember?.partners?.filter((partner) => partner.id !== id);

    setPartnerMember({
      ...partnerMember,
      partners,
    });
  };

  const handleSuccessModalOkButtonClick = () => {
    setModal({ ...modal, successModal: { visible: false } });
    navigate(getPartnerMemberDetailPagePath(partnerMember!.id!));
  };

  const handleFailModalOkButtonClick = () =>
    setModal({ ...modal, failModal: { visible: false, message: "" } });

  const handleFailModalCloseButtonClick = () =>
    setModal({ ...modal, failModal: { visible: false, message: "" } });

  const onAddSelectPartners = (partners: Partner[]) => {
    setPartnerMember({ ...partnerMember!, partners });
    setModal({ ...modal, partnerSelectModal: { visible: false } });
  };

  const onCanceledPartnerSelectModal = () =>
    setModal({ ...modal, partnerSelectModal: { visible: false } });
  useTitleOperation(partnerMember?.email);
  if (getPartnerMemberLoading || putPartnerMemberLoading) return <LoadingBar visible />;

  const onChange = (e: any) => {
    setPhone(e);
  };

  return (
    <div>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-contents pt30" onClick={handleContentsClick}>
            {partnerMember &&
              Object.keys(EDIT_MAP).map((key, index) => {
                const editData = EDIT_MAP[key as keyof typeof EDIT_MAP];
                return (
                  <section className="contents-container__grid" key={key}>
                    <div className="contents-container__grid-index">
                      <p>{editData.label}</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className={`minmax${editData.width}`}>
                        {editData.render(partnerMember, privileges, onChange, phone)}
                      </div>
                    </div>
                  </section>
                );
              })}
          </div>
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title={"목록"}
                onClick={handleListButtonClick}
                className="color-white size-large"
              />
            </div>
            <div className="right-area">
              <BaseButton
                title={"저장"}
                disabled={validateMobileNumber(phone) !== true && phone !== ""}
                onClick={handleSaveButtonClick}
                className="size-large"
              />
            </div>
          </div>
        </div>
        {modal.partnerSelectModal.visible && (
          <PartnerSelectModal
            onCanceled={onCanceledPartnerSelectModal}
            onAdded={onAddSelectPartners}
            defaultValues={partnerMember?.partners || []}
            multiSelect
          />
        )}
        <BaseModal
          isOpen={modal.successModal.visible}
          onClick={handleSuccessModalOkButtonClick}
          btnRightTitle="확인"
        >
          <span>저장 되었습니다.</span>
        </BaseModal>
        <BaseModal
          isOpen={modal.failModal.visible}
          onClick={handleFailModalOkButtonClick}
          onClose={handleFailModalCloseButtonClick}
          btnRightTitle="확인"
        >
          저장을 할 수 없습니다.
        </BaseModal>
      </div>
    </div>
  );
}

export default PartnerMemeberEdit;
