import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApiOperation from "src/api/hooks/api-operation";
import { getMemberDetail, getMemberRestrictionList } from "src/api/member/member-api";
import { MemberModel, MemberRestriction, QueryParams } from "src/api/member/member-types";
import { PageMeta } from "src/api/public-types";
import { BaseButton } from "src/components/BaseButton";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import PagePath from "src/pagePath.json";
import MemberRestrictionList from "./components/MemberRestrictionList";
import MemberRestrictionDetail from "./MemberRestrictionDetail";
import MemberRestrictionForm from "./MemberRestrictionForm";

type Props = {
  memberId?: string;
};

const MemberRestrictionInfo = ({}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const param = useParams();

  const memberId = param.memberId ? param.memberId : param.id;

  const { executeAsync } = useApiOperation(getMemberRestrictionList);
  const [params, setParams] = useState<QueryParams>({});
  const [restrictions, setRestrictions] = useState<MemberRestriction[]>([]);
  const [pageMetas, setPageMetas] = useState<PageMeta>();

  const [formFlag, setFormFlag] = useState(false);
  const [listFlag, setListFlag] = useState(false);
  const [detailFlag, setDetailFlag] = useState(false);

  const [basicInfo, setBasicInfo] = useState<MemberModel>();

  //계정연결 조회 API
  const { executeAsync: getMemberDetailAsync } = useApiOperation(getMemberDetail);

  const getMemberDetailApi = useCallback(
    async (id: string) => {
      const response = await getMemberDetailAsync({ id });
      if (response.status >= 200 && response.status <= 299) {
        const memberData = response?.data?.data?.member;

        setBasicInfo(memberData || null);
      }
    },
    [getMemberDetailAsync],
  );
  useEffect(() => {
    console.log("memberId", memberId);
    if (memberId) {
      getMemberDetailApi(String(memberId));
    }
  }, [getMemberDetailApi, memberId]);

  // path variable 에서 productId 추출
  const page = useMemo(() => {
    // console.log('location',location);
    const cutString = "/mng/central/member/detail/";
    const urlString = location.pathname.replace(cutString, "");
    const paths = urlString.split("/");
    //내부 페이비 path 처리
    //detail or form
    if (paths[2] !== undefined) {
      if (paths[2] === "form") {
        // 입력
        // console.log('입력',paths[2]);
        setFormFlag(true);
      } else {
        // 상세
        // console.log('상세',paths[2]);
        setDetailFlag(true);
      }
    } else {
      //list
      // console.log('목록',paths[1]);
      setListFlag(true);
    }

    // console.log('paths',paths);
  }, [location]);

  const moveAddForm = useCallback(() => {
    setDetailFlag(false);
    setListFlag(false);
    setFormFlag(true);
    console.log("basicInfo?.id", basicInfo?.id);
    const path =
      PagePath.restriction.form.replace(":memberId", String(basicInfo?.id)) +
      "?tab=MemberRestrictionInfo";
    navigate(path);
  }, [basicInfo, navigate]);

  useEffect(() => {
    if (basicInfo && String(basicInfo.id) !== "" && listFlag) {
      if (params.size === undefined) {
        setParams({ size: 1000 });
      }
      executeAsync({ memberId: Number(basicInfo.id) }).then((response) => {
        // console.log('restriction',response?.data?.data.content);
        if (response?.data?.data?.content) {
          response?.data?.data?.content.map((c: any, index: number) => (c.index = index + 1));
          setRestrictions(response?.data?.data.content || []);
          setPageMetas(response?.data?.meta.pageMeta);
        }
      });
    }
    // console.log(page);
  }, [basicInfo, executeAsync, listFlag, page, params]);

  return (
    <>
      {listFlag ? (
        <div className="contents-container__wrap detail-basic-info">
          <div className="contents-container__wrap-contents">
            {/* title */}
            <ContentsIdSection
              title="이용 제한내역"
              id={basicInfo?.id}
              noTitle="회원번호"
              no={basicInfo?.memberNo}
            />
            <div className="flex-center-end">
              <div className="mb10">
                <BaseButton title="+ 이용 제한 등록" onClick={() => moveAddForm()} />
              </div>
            </div>

            <MemberRestrictionList params={params} data={restrictions} pageMeta={pageMetas} />
          </div>
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title="목록으로"
                onClick={() => navigate(PagePath.member.list)}
                className="color-white size-large"
              />
            </div>
            <div className="right-area"></div>
          </div>
        </div>
      ) : null}
      {formFlag ? (
        <>
          {/* title */}
          <MemberRestrictionForm basicInfo={basicInfo} />
        </>
      ) : null}
      {detailFlag ? (
        <>
          <MemberRestrictionDetail basicInfo={basicInfo} />
        </>
      ) : null}
    </>
  );
};

export default MemberRestrictionInfo;
